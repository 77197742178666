'use strict';

const moment = require('moment');

/**
 * Returns the fiscal year end string as `MMMM DD` from a month number
 * starting from 1 (January).
 *
 * @param {number} month       - the month num starting from 1 (Jan) tp 12 (Dec)
 * @return {string}               - fiscal year end as `MMMM DD` (eg. December 31)
 */
export function getFyeFromMonthNum(month = null) {
  const monthNum = parseInt(month);

  if (monthNum < 0 || monthNum > 12) {
    throw new Error('Month must be between 1 and 12');
  }

  // Moment requires months to start from a 0 index (where 0 is January)
  return moment()
    .month(month - 1)
    .endOf('month')
    .format('MMMM DD');
}

/**
 * Returns the next projected AGM date based on fiscal year end string as `MMMM DD`
 * from a month number starting from 1 (January).
 *
 * @param {number} month          - the month num starting from 1 (Jan) tp 12 (Dec)
 * @param {number} offset         - the number of months after FYE that AGM needs to occur (default 6)
 * @return {string}               - projected AGM as `MMMM DD` (eg. December 31)
 */
export function getAgmProjectedDateFromMonth(month = null, offset = 6) {
  const monthNum = parseInt(month);

  if (monthNum < 0 || monthNum > 12) {
    throw new Error('Month must be between 1 and 12');
  }

  if (offset < 0) {
    throw new Error('Offset must be greater than 0');
  }

  // Moment requires months to start from a 0 index (where 0 is January)
  return moment()
    .month(month - 1)
    .add('months', offset)
    .endOf('month')
    .format('MMMM DD');
}
