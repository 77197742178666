<template>
  <v-container fluid grid-list-lg>
    <v-layout justify-end>
      <span
        data-v-step="vbm-view-tour"
        class="caption"
        style="cursor: pointer;"
        @click="dialog.welcome = true"
        >View Product Tour <v-icon small>help</v-icon></span
      >
    </v-layout>

    <v-layout row wrap v-if="loading.meeting">
      <v-flex xs12 class="h-flex">
        <v-progress-circular indeterminate color="primary" />
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="!loading.meeting && currentMeeting">
      <v-flex xs12 sm12 md5>
        <!-- Members Container 
          Use empty array mock data for units-list-data when tour is active
          Otherwise import button is not visible
        -->
        <meeting-vbm-members
          data-v-step="vbm-members-container"
          :current-meeting="currentMeeting"
          :short-code="shortCode"
          :units-list-data="
            tourActive ? mockDataMeetingUnitsListData : meetingUnitsListData
          "
        />
      </v-flex>
      <v-flex xs12 sm12 md7>
        <v-layout>
          <v-flex>
            <!-- Your Meeting Container -->
            <meeting-vbm-your-meeting
              :meeting-date="currentMeeting.meetingDate"
              :meeting-duration="currentMeeting.duration"
              :meeting-name="currentMeeting.name"
              :meeting-timezone="currentMeeting.meetingTimezone"
              :meeting-meta="currentMeeting.meetingMeta"
            />
          </v-flex>

          <v-flex>
            <!-- Spectators container -->
            <meeting-vbm-spectators
              data-v-step="vbm-spectator-container"
              :short-code="shortCode"
            />
          </v-flex>
        </v-layout>
        <!-- Temp hidden -->
        <!-- <v-layout>
          <v-flex>
            <meeting-vbm-minute-services
              :meeting-meta="currentMeeting.meetingMeta"
              :meeting-name="currentMeeting.name"
              :is-admin="isAdmin"
            />
          </v-flex>
        </v-layout> -->
        <v-layout>
          <v-flex>
            <!-- Documents container -->
            <meeting-vbm-documents />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <!-- Voting matters container -->
        <meeting-vbm-business
          :current-meeting="currentMeeting"
          :tour-active="tourActive"
        /> </v-flex
    ></v-layout>

    <!-- Dialog -->
    <welcome-dialog
      v-model="dialog.welcome"
      @start="startVbmTour"
      @close="dialog.welcome = false"
    />

    <!-- v-tour component -->
    <v-tour name="vbmTour" :steps="vbmSteps" :callbacks="vueTourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="header">
                <p class="font-weight-bold">
                  {{ tour.steps[tour.currentStep].header.title }}
                </p>
              </div>
              <div slot="content">
                {{ tour.steps[tour.currentStep].content }}
                <img
                  height="100%"
                  width="100%"
                  :src="tour.steps[tour.currentStep].imgUrl"
                />
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import checkScope from '@/lib/check-user-scopes';

import MeetingVbmMembers from './MeetingVbmMembers.vue';
import MeetingVbmYourMeeting from './MeetingVbmYourMeeting.vue';
import MeetingVbmSpectators from './MeetingVbmSpectators.vue';
import MeetingVbmDocuments from './MeetingVbmDocuments.vue';
import MeetingVbmBusiness from './MeetingVbmBusiness.vue';
// import MeetingVbmMinuteServices from './MeetingVbmMinuteServices';
import WelcomeDialog from '@/views/meetings/meeting/vbm/components/MeetingVbmWelcomeDialog';

export default {
  name: 'MeetingVbmOverview',
  components: {
    MeetingVbmMembers,
    MeetingVbmYourMeeting,
    MeetingVbmSpectators,
    MeetingVbmDocuments,
    MeetingVbmBusiness,
    // MeetingVbmMinuteServices
    WelcomeDialog
  },
  data() {
    return {
      loading: {
        meeting: true
      },
      dialog: {
        welcome: false
      },
      shortCode: this.$route.params.shortcode,
      tourActive: false,
      vueTourCallbacks: {
        // Set whether the tour is active
        // Currently toggles demo state for MeetingVbmVotingMatters
        onStart: () => {
          this.tourActive = true;
        },
        onStop: () => {
          this.tourActive = false;
        },
        onNextStep: currentStep => {
          // Trigger scroll back to top on last step (20)
          // Otherwise product tour button overlaps with toolbar
          if (currentStep === 18) {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        },
        onPreviousStep: currentStep => {
          // Trigger manual scroll to top for step 15 (vbm-private-documents-tab)
          if (currentStep === 13) {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        }
      }
    };
  },
  mounted() {
    // Check if welcome tour has been seen before
    const seenVbmWelcome = JSON.parse(localStorage.getItem(`seen-vbm-welcome`));

    if (!seenVbmWelcome) {
      // Launch welcome dialog
      this.dialog.welcome = true;
    }
  },
  computed: {
    ...mapGetters('v2/meetings', ['meetingByShortCode']),
    ...mapGetters('meetings/units', ['meetingUnitsListData']),
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('vTour', ['vbmSteps', 'mockDataMeetingUnitsListData']),
    canSeeSecretTallyData() {
      // Currently admins and collaborators (moderators/partners) can see the tally tables
      // Even when set as a secret question
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early')
      );
    },
    currentMeeting() {
      return this.meetingByShortCode(this.shortCode);
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('v2/meetings', ['fetchMeeting']),
    ...mapActions('meetings/webcast', ['fetchWebcastDetails']),
    async init() {
      try {
        this.loading.meeting = true;
        await this.fetchMeeting({ shortCode: this.shortCode });
        await this.fetchWebcastDetails({ shortCode: this.shortCode });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      } finally {
        this.loading.meeting = false;
      }
    },
    startVbmTour() {
      // close welcome dialog
      this.dialog.welcome = false;

      // start tour
      this.$tours['vbmTour'].start();

      // set flag in local storage that welcome dialog has been seen
      localStorage.setItem('seen-vbm-welcome', true);
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}
</style>

<style>
/* Required style for v-tour to highlight specific target */
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
  outline: 5px solid rgba(106, 145, 209, 0.5) !important;
}

/* Larger tooltip container to image embed */
.v-step {
  max-width: 450px !important;
}
</style>
