<template>
  <v-dialog :value="isOpen" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Print Jobs Prices</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-model="prices.pricePerPage"
                  label="Price per page"
                  type="number"
                  @input="emitUpdate('pricePerPage', $event)"
                />
              </v-flex>
              <v-flex xs6 class="d-flex">
                <v-text-field
                  v-model="prices.pricePerPackage"
                  label="Price per package"
                  type="number"
                  @input="emitUpdate('pricePerPackage', $event)"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="close">Close</v-btn>
        <v-btn color="primary" @click.native="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddPrintJobsPricesDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    prices: {
      type: Object,
      required: true,
      default() {
        return { pricePerPage: 0, pricePerPackage: 0 };
      }
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    emitUpdate(key, value) {
      this.$emit('update', { key, value: Number(value) });
    },
    save() {
      this.$emit('save');
    }
  }
};
</script>

<style scoped></style>
