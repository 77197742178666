<template>
  <v-dialog :value="value" max-width="80%" persistent>
    <v-card>
      <v-card>
        <v-card-title>
          <h2>Filter Accounts:</h2>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <!-- CSM Team -->
            <v-flex>
              <h4>By Preferred CSM</h4>
              <v-layout column v-for="(mgr, index) in csTeam" :key="index">
                <v-checkbox
                  :value="mgr.email"
                  v-model="preferredManagerFilters"
                >
                  <template slot="label">
                    <span>
                      {{ mgr.name }}
                    </span>
                  </template>
                </v-checkbox>
              </v-layout>
            </v-flex>

            <!-- Other Stuff -->
            <v-flex>
              <!-- Unassigned Accounts -->
              <h4>Unassigned Accounts</h4>
              <v-layout column>
                <v-checkbox
                  :value="unassignedCsm.email"
                  v-model="preferredManagerFilters"
                >
                  <template slot="label">
                    <span>
                      Accounts with no Preferred CSM
                    </span>
                  </template>
                </v-checkbox>
              </v-layout>

              <!-- Subscription Filters -->
              <h4 class="mt-3">Subscription</h4>
              <v-layout column>
                <v-radio-group v-model="subscriptionFilter">
                  <v-radio label="All Accounts" value="all"></v-radio>
                  <v-radio label="All Subscribed" value="subscribed"></v-radio>
                  <v-radio
                    label="Unpaid Invoice"
                    value="unpaid-invoice"
                  ></v-radio>
                  <v-radio label="Paid Invoice" value="paid-invoice"></v-radio>
                  <v-radio label="No Invoice" value="no-invoice"></v-radio>
                </v-radio-group>
              </v-layout>

              <!-- Agents Filters -->
              <h4 class="mt-3">Agents</h4>
              <v-layout column>
                <v-radio-group v-model="agentsFilter" row>
                  <v-radio label="All" value="all"></v-radio>
                  <v-radio label="No Agents" value="none"></v-radio>
                </v-radio-group>
              </v-layout>
            </v-flex>

            <!-- Other Team Members -->
            <v-flex v-if="showAll">
              <h4>Other Team Members</h4>
              <v-layout column v-for="(mgr, index) in otherTeam" :key="index">
                <v-checkbox
                  :value="mgr.email"
                  v-model="preferredManagerFilters"
                >
                  <template slot="label">
                    <span>
                      {{ mgr.name }}
                    </span>
                  </template>
                </v-checkbox>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="close">close</v-btn>
          <v-btn flat @click="showAll = !showAll">{{
            showAll ? 'hide other team members' : 'show other team members'
          }}</v-btn>
          <v-spacer />
          <v-btn color="red" flat @click="clear">clear filters</v-btn>
          <v-btn color="blue" flat @click="update">apply filters</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FilterAccountsDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    currentFilters: {
      type: Array,
      default() {
        return [];
      }
    },
    currentSubFilter: {
      type: String,
      default() {
        return 'all';
      }
    },
    currentAgentsFilter: {
      type: String,
      default() {
        return 'all';
      }
    }
  },
  watch: {
    currentFilters() {
      this.preferredManagerFilters = this.currentFilters;
    },
    currentSubFilter() {
      this.subscriptionFilter = this.currentSubFilter;
    },
    currentAgentsFilter() {
      this.agentsFilter = this.currentAgentsFilter;
    }
  },
  created() {
    this.preferredManagerFilters = this.currentFilters;
    this.subscriptionFilter = this.currentSubFilter;
    this.agentsFilter = this.currentAgentsFilter;
  },
  data() {
    return {
      preferredManagerFilters: [],
      subscriptionFilter: 'all',
      agentsFilter: 'all',
      showAll: false
    };
  },
  computed: {
    csTeam() {
      return this.options.filter(a => a.data.team === 'cs');
    },
    otherTeam() {
      return this.options.filter(
        a => a.data.team !== 'cs' && a.data.team !== 'vm'
      );
    },
    unassignedCsm() {
      return { name: 'Accounts with no Preferred CSM', email: 'missingCsm' };
    }
  },
  methods: {
    update() {
      this.$emit('update', {
        filters: this.preferredManagerFilters,
        subFilter: this.subscriptionFilter,
        agentsFilter: this.agentsFilter
      });
      this.$emit('close');
    },
    clear() {
      this.preferredManagerFilters = [];
      this.subscriptionFilter = 'all';
      this.agentsFilter = 'all';
      this.showAll = false;
      this.update();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
