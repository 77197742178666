<template>
  <v-container grid-list-sm>
    <v-layout row>
      <v-flex xs12>
        <v-combobox
          clearable
          label="Select a Neighbourhood"
          :value="data.neighbourhood"
          :items="neighbourhoodList"
          @input="emitUpdate('data.neighbourhood', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs6>
        <v-text-field
          label="Building Name"
          :value="data.nickname"
          hint="eg. World on Yonge, The Shangri-la"
          @input="emitUpdate('data.nickname', $event)"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          label="Number Of Units"
          :value="data.numberOfUnits"
          hint="eg. 10, 50, 100"
          mask="######"
          @input="emitUpdate('data.numberOfUnits', $event)"
        />
      </v-flex>
    </v-layout>
    <v-text-field
      append-icon="link"
      label="URL"
      :value="data.url"
      hint="Optional url"
      @input="emitUpdate('data.url', $event)"
    />
    <v-flex>
      <fiscal-year-end-selector
        :month="data.fiscalYearEndMonth"
        @update="emitUpdate('data.fiscalYearEndMonth', $event)"
      />
    </v-flex>
    <v-select
      item-text="text"
      item-value="value"
      :value="data.timezone"
      label="Timezone"
      @change="emitUpdate('data.timezone', $event)"
    />
    <v-textarea
      label="Billing instructions"
      :value="data.billingContact.instructions"
      hint="Optional"
      @input="emitUpdate('data.billingContact.intructions', $event)"
    />

    <v-layout row>
      <v-flex xs12>
        <div class="title grey--text text--darken-4 mb-1">Client Logo</div>
        <input-link-add-images @uploaded="emitUpload" />
        <img
          v-if="uploadedImage"
          :src="uploadedImage"
          width="100"
          class="mb-4"
          alt="Client Logo"
        />
        <v-tooltip right>
          <span slot="activator">
            <v-icon
              v-if="uploadedImage"
              color="red"
              small
              style="position:absolute"
              @click="removeImage"
              >close</v-icon
            >
          </span>
          <span>Remove Image</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-flex class="mt-2">
      <div class="title grey--text text--darken-4 mb-1">Notice Signature</div>
      <div class="body-1 grey--text text--darken-2">
        Used for
        <code>signature</code> merge tag in email notices
      </div>
      <rich-text-editor
        :hide-merge-menu="true"
        :body="data.signature"
        @update="onChange('data.signature', $event)"
      />
    </v-flex>
  </v-container>
</template>

<script>
import { pm, neighbourhood } from '@/lib/default-account-lists';
import _ from 'lodash';
import moment from 'moment';
import timezones from '@/lib/timezones';
import FiscalYearEndSelector from '@/components/FiscalYearEndSelector';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import InputLinkAddImages from '@/components/input/InputLinkAddImages';

export default {
  name: 'InputCorpMeta',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    logoUrl: {
      type: String,
      default: ''
    }
  },
  components: {
    FiscalYearEndSelector,
    RichTextEditor,
    InputLinkAddImages
  },
  data() {
    return {
      pmlist: [],
      neighbourhoodList: [],
      uploadedImage: this.logoUrl,
      menu: false,
      timezones
    };
  },
  created() {
    // import lists for autocomplete
    this.pmlist = pm;
    this.neighbourhoodList = neighbourhood;
  },
  computed: {
    fiscalYearEnd() {
      return _.get(this.data, 'fiscalYearEnd', 'null');
    },
    dateFormatted() {
      let fye = this.fiscalYearEnd;
      if (!_.isEmpty(fye)) {
        return moment(this.data.fiscalYearEnd).format('MMMM DD');
      } else {
        return 'n/a';
      }
    }
  },
  methods: {
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    emitUpload(res) {
      this.uploadedImage = res[0];
      this.emitUpdate('logoUrl', this.uploadedImage);
    },
    removeImage() {
      this.uploadedImage = undefined;
      this.onChange('logoUrl', this.uploadedImage);
    }
  }
};
</script>

<style scoped></style>
