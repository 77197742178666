'use strict';

/**
 * @fileoverview API calls for /Invoices
 */

import http from './requestType';

/**
 * Get meta data about invoices (eg. next invoice number)
 *
 * @return {Promise}  invoices meta data
 */
export function getInvoicesMeta() {
  return http._get('Invoices/meta');
}

/**
 * Returns list of invoices with optional meeting code filter
 * @param  {Object} params              - parameters
 * @param  {string} [params.shortCode]  - optional meeting code
 * @param  {string} [params.accountKey] - optional account key
 * @param  {string} [params.status]     - optional status filter (draft, unpaid, paid, all)
 * @param  {string} [params.searchText] - optional search the text for inv. num, meetingCode, sent date, created date, and notes
 * @param  {string} [params.sortBy]     - optional sort by property (invoiceDate, sentAt, totalPrice, amountOwing, invoiceNumber) (default sentAt)
 * @param  {string} [params.ascending]  - optional sort by ascending if `true` (default false)
 * @param  {number} [params.page]       - optional paging
 * @param  {number} [params.size]       - optional page size
 * @return {Promise}                    - the list of invoices
 */
export function getInvoiceList({
  shortCode,
  accountKey,
  status,
  searchText,
  sortBy,
  ascending,
  page,
  size
} = {}) {
  // `all` should map to 'undefined' as per API docs
  const statusFilter = status === 'all' ? undefined : status;
  const search = searchText === '' ? undefined : searchText;

  return http._get(`Invoices`, {
    shortCode,
    accountKey,
    status: statusFilter,
    searchText: search,
    sortBy,
    ascending,
    page,
    size
  });
}

/**
 * Return specific invoice
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - the invoice
 */
export function getInvoice(invoiceKey) {
  return http._get(`Invoices/${invoiceKey}`, {});
}

/**
 * Adds a new invoice
 * @param  {Object} payload   - the invoice definitions
 * @param  {Object} params    - additional params
 * @return {Promise} - the invoice
 */
export function addInvoice(payload, params) {
  return http._post(`Invoices`, payload, params);
}

/**
 * Update invoice details. Only changes definitions (invoicenum,
 * date, notes, etc), not the individual expenses, taxes, payments etc.
 *
 * @param  {string} invoiceKey - the invoiceKey
 * @param  {Object} payload - the payload to send in an update
 * @return {Promise} - the invoice
 */
export function editInvoice(invoiceKey, payload) {
  return http._put(`Invoices/${invoiceKey}`, payload);
}

/**
 * Patch invoice details. Only changes definitions (invoicenum,
 * date, notes, etc), not the individual expenses, taxes, payments etc.
 *
 * @param  {string} invoiceKey  - invoiceKey
 * @param  {string} accountKey  - accountKey
 * @param  {string} meetingCode - meetingCode
 * @param  {Object} payload - the payload to send in an update
 * @return {Promise} - the invoice
 */
export function patchInvoice(invoiceKey, accountKey, meetingCode, payload) {
  const data = { ...payload, accountKey, meetingCode };
  return http._patch(`Invoices/${invoiceKey}`, data);
}

/**
 * Delete an invoice
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - the invoice
 */
export function deleteInvoice(invoiceKey) {
  return http._delete(`Invoices/${invoiceKey}`, {});
}

/**
 * Mark Invoice Review
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - promise
 */
export function markInvoiceReview(invoiceKey) {
  return http._post(`Invoices/${invoiceKey}/mark-review`, {});
}

/**
 * Mark Invoice Sent
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - promise
 */
export function markInvoiceSent(invoiceKey) {
  return http._post(`Invoices/${invoiceKey}/mark-sent`, {});
}

/**
 * Mark Invoice Viewed
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - promise
 */
export function markInvoiceViewed(invoiceKey) {
  return http._post(`Invoices/${invoiceKey}/mark-viewed`, {});
}

/**
 * Preview Invoice HTML
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} - promise
 */
export function previewInvoice(invoiceKey) {
  return http._get(`Invoices/${invoiceKey}/preview`, {});
}

/**
 * Send the invoice email
 * @param  {string} invoiceKey - the invoiceKey
 * @param  {Object} payload - the payload to send
 * @return {Promise} confirmation of send
 */
export function sendInvoiceEmail(invoiceKey, payload) {
  return http._post(`Invoices/${invoiceKey}/send-invoice`, payload);
}

/**
 * Export Invoice as PDF
 * @param  {string} invoiceKey - the invoiceKey
 * @return {Promise} download details
 */
export function createInvoiceExport(invoiceKey) {
  return http._post(`Invoices/${invoiceKey}/pdf`, {});
}

/**
 * Add an item to the invoice
 *
 * @param  {string} invoiceKey - invoice identifier
 * @param  {string} itemType   - invoice item type
 * @param  {Object} payload    - payload
 * @return {Promise} - Promise
 */
export function addInvoiceItem(invoiceKey, itemType, payload) {
  return http._post(`Invoices/${invoiceKey}/items`, {
    type: itemType,
    ...payload
  });
}

/**
 * Removes an item from the invoice
 *
 * @param  {string} invoiceKey - invoice identifier
 * @param  {string} itemKey    - item identifier
 * @return {Promise} - Promise
 */
export function deleteInvoiceItem(invoiceKey, itemKey) {
  return http._delete(`Invoices/${invoiceKey}/items/${itemKey}`);
}

/**
 * Edut an invoice item
 *
 * @param  {string} invoiceKey - invoice identifier
 * @param  {string} itemKey    - item identifier
 * @param  {Object} payload    - payload
 * @return {Promise} - Promise
 */
export function editInvoiceItem({ invoiceKey, itemKey, payload }) {
  return http._put(`Invoices/${invoiceKey}/items/${itemKey}`, {
    ...payload
  });
}

/**
 * Get invoice stats
 *
 * @param  {Boolean}  clearCache  - whether cache should be force cleared
 * @return {Promise}              - the stats
 */
export function getInvoiceStats(clearCache = false) {
  return http._get(`Invoices/stats`, { clearCache });
}

/**
 * Send a 'Payment Received' confirmation email
 * @param  {String} invoiceKey  - the invoiceKey
 * @param  {Object} payload     - the payload to send
 * @return {Promise}              confirmation of send
 */
export function sendInvoicePaymentEmail(invoiceKey, payload) {
  return http._post(
    `Invoices/${invoiceKey}/send-payment-confirmation`,
    payload
  );
}

/**
 *  Get download url for Chargebee invoice as PDF
 * @param  {String} invoiceId   - the invoice id
 * @param  {Object} payload     - the payload to send
 * @return {Promise}              confirmation of send
 */
export function getChargebeeInvoiceDownloadUrl(invoiceId) {
  return http._get(`Invoices/${invoiceId}/pdf`);
}
