<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Reminder System Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Enable or disable automated reminders. Manage the frequency and timing of
      automated reminders.
    </div>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="meetingReminders"
          @change="onChange('enableReminders', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Automatic Reminders
        </div>
        <div class="grey--text text--darken-2">
          Click this to turn on the automated reminder system. You will be able
          to manage the frequency and timing of reminder emails and the 'Join
          Portal' email below.
        </div>
      </div>
    </v-layout>

    <v-divider class="ma-3 mt-4" />

    <v-layout v-if="meetingReminders" row align-center class="mt-3">
      <v-flex xs6 md6>
        <date-picker
          label="Reminder Start Date"
          hint="Automatic reminders send-outs will be calculated based on this date."
          clearable
          :iso-date="meetingReminderStartDate"
          @update="onChange('reminderStartDate', $event)"
        />
      </v-flex>
      <v-flex xs6 md6>
        <v-text-field
          label="Send reminders every:"
          v-model="meetingReminderFrequency"
          hint="Leave blank to disable auto-scheduling of reminders."
          prepend-icon="event"
          persistent-hint
          type="number"
          suffix="days"
          min="0"
          max="30"
          clearable
          @input="onChange('reminderFrequency', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout v-if="meetingReminders" row class="mt-3">
      <v-flex xs12 md12>
        <v-text-field
          label="Schedule the 'Join Portal' notice:"
          v-model="meetingJoinReminder"
          hint="Leave blank to disable auto-scheduling of Join Portal notice."
          prepend-icon="watch"
          persistent-hint
          type="number"
          suffix="mins before meeting"
          min="0"
          max="1440"
          clearable
          @input="onChange('joinReminder', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout v-if="meetingReminders" class="mt-3"
      ><v-flex xs12 md12>
        <v-text-field
          label="Replies to automatic reminders will go to ..."
          :value="meetingReminderReplyTo"
          :placeholder="`notices+${$route.params.shortcode}@getquorum.com`"
          hint="Uses default if left blank"
          persistent-hint
          @change="onChange('reminderReplyTo', $event)"/></v-flex
    ></v-layout>

    <v-alert
      v-if="meetingReminders && showAlert"
      class="mt-3"
      :value="true"
      outline
      color="info"
    >
      <v-layout>
        <!-- Column 1 - Reminder dates -->
        <!-- Case A) Reminders will be sent -->
        <v-flex xs6 v-if="reminderDates.length > 0">
          <div class="mb-1">
            Based on these settings, reminders will be sent on:
          </div>
          <li v-for="(reminder, index) in reminderDates" :key="index">
            <span>{{ reminder | dateFormatReadable }}</span>
            <span v-if="isWeekend(reminder)" class="ml-2"
              ><v-tooltip right
                ><v-icon slot="activator" small color="warning"
                  >info_outline</v-icon
                ><span>This date is a weekend.</span></v-tooltip
              ></span
            >
          </li>
          <div class="mt-1">
            All reminders will be sent between 7am - 3pm
            {{ timezoneAbbreviation }}.
          </div>
        </v-flex>
        <!-- Case B) No reminders will be sent based on current settings -->
        <v-flex xs6 v-if="showMeetingDateWarning">
          <div class="mb-1">
            <div>Based on these settings, no reminders will be sent.</div>
            <div>
              Please check the meeting date, the reminder start date, and the
              reminder frequency settings.
            </div>
          </div></v-flex
        >

        <!-- Column 2 - Join Portal date -->
        <v-flex xs6 v-if="meetingJoinReminder">
          <div class="mb-1">
            Based on these settings, the Join Portal notice will be sent on:
          </div>
          <li>
            {{ joinPortalDate | dateFormatReadable(true, meetingTimezone) }}
          </li>
        </v-flex>
      </v-layout>
    </v-alert>

    <v-expansion-panel
      v-if="
        meetingReminders && (meetingReminderFrequency || meetingJoinReminder)
      "
      class="mt-4"
    >
      <v-expansion-panel-content>
        <template v-slot:header v-if="meetingReminderFrequency">
          <div class="body-2">Reminder Template</div>
        </template>
        <v-layout v-if="meetingReminderFrequency" class="px-3">
          <v-flex xs12 md12>
            <v-text-field
              label="Subject Line"
              v-model="meetingReminderSubject"
              :placeholder="`[CORP_NAME] - Your Proxy`"
              @change="onChange('reminderSubject', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout v-if="meetingReminderFrequency">
          <v-flex xs12 md12>
            <rich-text-editor
              :show-custom-fields="true"
              custom-label="Custom fields defined in settings"
              :custom-fields="customFields"
              :show-button-options="true"
              :body="meetingReminderTemplate"
              :key="renderKey.reminder"
              @update="onChange('reminderTemplate', $event)"/></v-flex
        ></v-layout>

        <v-layout v-if="meetingReminders">
          <v-spacer />
          <v-btn flat @click.native="getDefaultAutoReminder"
            >use default reminder template</v-btn
          >
          <v-btn flat @click.native="getPreview('reminder')">
            <v-icon left>remove_red_eye</v-icon>Preview
          </v-btn>
        </v-layout>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <template v-slot:header v-if="meetingJoinReminder">
          <div class="body-2">Join Portal Template</div>
        </template>
        <v-layout v-if="meetingJoinReminder" class="px-3">
          <v-flex xs12 md12>
            <v-text-field
              label="Subject Line"
              v-model="meetingJoinSubject"
              :placeholder="`Meeting and Voting Portal Link`"
              @change="onChange('joinSubject', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout v-if="meetingJoinReminder"
          ><v-flex xs12 md12>
            <rich-text-editor
              :show-custom-fields="true"
              custom-label="Custom fields defined in settings"
              :custom-fields="customFields"
              :show-button-options="true"
              :body="meetingJoinTemplate"
              :key="renderKey.join"
              @update="onChange('joinTemplate', $event)"/></v-flex
        ></v-layout>

        <v-layout v-if="meetingReminders">
          <v-spacer />
          <v-btn flat @click.native="getDefaultJoinPortal"
            >use default join portal template</v-btn
          >
          <v-btn flat @click.native="getPreview('join')">
            <v-icon left>remove_red_eye</v-icon>Preview
          </v-btn>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <preview-dialog
      v-model="dialog.preview"
      :recipientCount="0"
      :recipientTotal="0"
      :subject="automatedTemplatePreview.subject"
      :html="automatedTemplatePreview.content"
      :recipientInfoHidden="true"
      containerStyles="px-4"
      @close="dialog.preview = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';

import { dateFormatReadable } from '@/filters';
import { getRecipe } from '@/api/recipes';
import getReminderDates from '@/lib/get-auto-reminder-dates';

import DatePicker from '@/components/DatePicker';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';

export default {
  name: 'MeetingSettingsReminderSystemInput',
  components: { DatePicker, RichTextEditor, PreviewDialog },
  filters: { dateFormatReadable },
  props: {
    meetingDate: { type: String, default: '' },
    meetingTimezone: { type: String, default: '' },
    options: {
      type: Object,
      required: true
    },
    reminderSettings: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters('meetings', ['automatedTemplatePreview']),
    timezoneAbbreviation() {
      return moment()
        .tz(this.meetingTimezone)
        .format('z');
    },
    reminderDates() {
      if (!this.meetingReminderStartDate || !this.meetingReminderFrequency) {
        return [];
      }

      const reminderStartDate = this.meetingReminderStartDate;
      const reminderFrequency = this.meetingReminderFrequency;
      const meetingDate = this.meetingDate;

      return getReminderDates(
        reminderStartDate,
        reminderFrequency,
        meetingDate
      );
    },
    joinPortalDate() {
      return moment(this.meetingDate).subtract(
        this.meetingJoinReminder,
        'minutes'
      );
    },
    showAlert() {
      // 'Join Portal' email scheduled - show alert
      if (this.meetingJoinReminder) {
        return true;
      }
      // 1+ Reminder scheduled - show alert
      if (this.reminderDates.length > 0) {
        return true;
      }
      //
      if (this.meetingReminderFrequency && this.meetingReminderStartDate) {
        return true;
      }

      return false;
    },
    showMeetingDateWarning() {
      if (
        this.reminderDates.length === 0 &&
        this.meetingReminderStartDate &&
        this.meetingReminderFrequency
      ) {
        return true;
      }
      return false;
    },
    customFields() {
      const customUnitFields = this.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      meetingReminders: false,
      meetingReminderFrequency: null,
      meetingJoinReminder: null,
      meetingReminderReplyTo: null,
      meetingReminderStartDate: null,
      renderKey: {
        reminder: 0,
        join: 0
      },
      meetingReminderTemplate: null,
      meetingReminderSubject: null,
      meetingJoinTemplate: null,
      meetingJoinSubject: null,
      dialog: {
        preview: false
      }
    };
  },
  methods: {
    ...mapActions('meetings', ['getReminderTemplatePreview']),
    ...mapActions('meetings/notices', ['getNoticeFields']),
    async initState() {
      await this.getNoticeFields();

      this.meetingReminders = this.reminderSettings.enableReminders || false;
      this.meetingReminderFrequency =
        this.reminderSettings.reminderFrequency || null;
      this.meetingJoinReminder = this.reminderSettings.joinReminder || null;
      this.meetingReminderReplyTo =
        this.reminderSettings.reminderReplyTo || null;
      this.meetingReminderStartDate = this.reminderSettings.reminderStartDate;
      this.meetingReminderTemplate = this.reminderSettings.reminderTemplate;
      this.meetingReminderSubject = this.reminderSettings.reminderSubject;
      this.meetingJoinTemplate = this.reminderSettings.joinTemplate;
      this.meetingJoinSubject = this.reminderSettings.joinSubject;
    },
    async onChange(key, value) {
      if (key === 'enableReminders' && value && !this.reminderTemplate) {
        await this.getDefaultAutoReminder();
      }
      if (key === 'enableReminders' && value && !this.joinTemplate) {
        await this.getDefaultJoinPortal();
      }

      if (key === 'reminderStartDate') {
        const defaultTime = value + ' ' + '19:00';

        const formattedValue = moment
          .tz(
            defaultTime,
            this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
          )
          .toISOString();

        this.meetingReminderStartDate = formattedValue;

        this.$emit('update', { key, value: formattedValue });
      } else if (
        (key === 'joinReminder' || key === 'reminderFrequency') &&
        value === ''
      ) {
        this.$emit('update', { key, value: null });
      } else {
        this.$emit('update', { key, value });
      }
    },
    isWeekend(date) {
      let day = moment(date).format('ddd');
      return day === 'Sat' || day === 'Sun';
    },
    async getPreview(previewType) {
      try {
        await this.getReminderTemplatePreview({
          shortCode: this.$route.params.shortcode,
          type: previewType
        });

        this.dialog.preview = true;
      } catch (err) {
        console.log(err);
      }
    },
    async getDefaultAutoReminder() {
      try {
        // First, get default email body
        const recipe = await getRecipe('meetings.templates.auto_reminder');
        const template = recipe.data.data.template;

        this.$emit('update', { key: 'reminderTemplate', value: template });

        this.meetingReminderTemplate = template;
        this.renderKey.reminder++;

        // Second, get default subject line
        const defaultReminderSubject = '[CORP_NAME] - Your Proxy';
        this.$emit('update', {
          key: 'reminderSubject',
          value: defaultReminderSubject
        });
        this.meetingReminderSubject = defaultReminderSubject;
      } catch (err) {
        console.log(err);
      }
    },
    async getDefaultJoinPortal() {
      try {
        // First, get default email body
        const recipe = await getRecipe('meetings.templates.auto_join_portal');
        const template = recipe.data.data.template;

        this.$emit('update', { key: 'joinTemplate', value: template });

        this.meetingJoinTemplate = template;
        this.renderKey.join++;

        // Second, get default subject line
        const defaultJoinSubject = 'Meeting and Voting Portal Link';
        this.$emit('update', {
          key: 'joinSubject',
          value: defaultJoinSubject
        });
        this.meetingJoinSubject = defaultJoinSubject;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style></style>
