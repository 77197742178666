export default [
  {
    label: 'Meetings and/or Voting (AGMs, SGMs, etc.)',
    value: 'meeting',
    icon: 'visibility',
    iconText: 'Visible on a users dashboard'
  },
  {
    label: 'Budget Distributions',
    value: 'budget',
    icon: 'visibility_off',
    iconText: 'Hidden from users dashboard'
  },
  {
    label: 'Cert Distribution',
    value: 'certs',
    icon: 'visibility_off',
    iconText: 'Hidden from users dashboard'
  },
  {
    label: 'Custom Distributions',
    value: 'other',
    icon: 'visibility_off',
    iconText: 'Hidden from users dashboard'
  }
];
