'use strict';

/**
 * Checks if user has a scope (i.e., permission) for
 * a specific campaign.
 *
 * @param  {Object} userScopes      - all scopes for the user
 * @param  {Object} shortCode       - the campaign to check
 * @param  {Object} scopeToCheck    - specific scope name to check
 * @return {Boolean}
 */
export default function checkScope(userScopes, shortCode, scopeToCheck) {
  if (
    userScopes &&
    userScopes.campaigns[shortCode] &&
    userScopes.campaigns[shortCode].actions.includes(scopeToCheck)
  ) {
    return true;
  } else {
    return false;
  }
}
