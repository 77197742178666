<template>
  <div>
    <p class="body-2">List of Consents / Rejections</p>
    <v-toolbar dark color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        :value="consentsSearchText"
        @input="setConsentsSearchText"
      />
      <v-spacer />
      <v-btn
        flat
        icon
        @click.native="refreshData"
        :loading="!dataLastFetchedAt"
        color="secondary"
      >
        <v-icon>cached</v-icon>
      </v-btn>
      <div v-if="selected.length > 0">
        <v-btn color="error" dark @click.native="dialog.delete = true">
          Delete Selected Consents ({{ emails.length }})
        </v-btn>
      </div>
      <v-btn
        v-if="currentConsentsData.length !== 0"
        class="light"
        color="blue"
        @click.native="exportClicked"
        >Export as CSV</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="currentConsentsData"
      :search="consentsSearchText"
      v-model="selected"
      select-all
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="config.pagination"
      item-key="email"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td>
          <v-checkbox primary hide-details v-model="props.selected" />
        </td>
        <td class="text-xs-left">{{ props.item.email }}</td>
        <td class="text-xs-left">
          <consent-status-chip :status="props.item.status" size="small" />
        </td>
        <td class="text-xs-left">
          <consent-method-chip :method="props.item.type" />
        </td>
        <td class="text-xs-left">{{ formatDate(props.item.updatedAt) }}</td>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.delete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-alert outline color="error" icon="warning" :value="true">
            <strong>WARNING</strong>: You are about to
            <strong>delete ({{ emails.length }})</strong> email consent(s)
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.delete = false">Close</v-btn>
          <v-btn class="error" @click.native="deleteConsent">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { downloadCSV, convertArrayOfObjectsToCSV, formatDate } from '@/helpers';
import ConsentStatusChip from '@/components/ConsentStatusChip';
import ConsentMethodChip from '@/components/ConsentMethodChip';
export default {
  name: 'AdminAccountConsentsViewList',
  components: {
    ConsentStatusChip,
    ConsentMethodChip
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'updatedAt',
          descending: true
        }
      },
      headers: [
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Method', value: 'type', align: 'left' },
        { text: 'Last Modified', value: 'updatedAt', align: 'left' }
      ],
      selected: [],
      dialog: {
        delete: false
      },
      emails: []
    };
  },
  computed: {
    ...mapGetters('admin/accounts', ['consentsSearchText']),
    currentConsentsData() {
      return this.currentAccount.consentsListData || [];
    }
  },
  watch: {
    selected: function(objArray) {
      this.emails = objArray.map(row => row.email);
    }
  },
  methods: {
    ...mapActions('admin/accounts', ['setConsentsSearchText']),
    ...mapActions('accounts', ['getAccount', 'deleteAccountConsents']),
    refreshData() {
      return this.getAccount({
        key: this.currentAccount.key,
        includeConsents: true
      });
    },
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : 'never';
    },
    async deleteConsent() {
      try {
        await this.deleteAccountConsents({
          key: this.$route.params.accountKey,
          emails: _.uniq(this.emails)
        });
        this.clearEmails();
        this.dialog.delete = false;
      } catch (err) {
        this.error = err.response.data.details[0].message;
      }
    },
    clearEmails() {
      while (this.emails.length > 0) {
        this.emails.pop();
        this.selected.pop();
      }
    },
    exportClicked() {
      // Clone so we don't mutate data
      let consentData = _.cloneDeep(this.currentConsentsData);
      let formattedConsentData = this.formatExportData(consentData);
      let CSVData = convertArrayOfObjectsToCSV(formattedConsentData);
      downloadCSV(`${this.currentAccount.nameShort} Consent Data.csv`, CSVData);
    },
    formatDateReadable(value) {
      return formatDate(value, 'America/Toronto', 'YYYY-MM-DD HH:mm');
    },
    formatExportData(consentData) {
      return consentData.map(consent => {
        let newConsentObj = {
          email: consent.email,
          status: consent.status,
          type: consent.type,
          createdAt: this.formatDateReadable(consent.createdAt),
          updatedAt: this.formatDateReadable(consent.updatedAt)
        };
        return newConsentObj;
      });
    }
  }
};
</script>
