<template>
  <v-dialog max-width="800px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Add Single Checklist</span>
      </v-card-title>
      <v-card-text>
        <!-- Checklist Group -->
        <v-layout row>
          <v-flex xs12>
            <v-select
              :items="checklistGroups"
              item-text="name"
              item-value="key"
              label="Checklist Group"
              v-model="groupKey"
            />
          </v-flex>
        </v-layout>

        <!-- Checklist Stage -->
        <v-layout row>
          <v-flex xs12>
            <v-select
              v-model="stage"
              :items="stages"
              label="Checklist Stage"
              @input="updateStage($event)"
            />
          </v-flex>
        </v-layout>

        <!-- Checklist Name / Due At -->
        <v-layout>
          <v-flex xs12>
            <checklist-input-item
              :meeting-date="meetingDate"
              :meeting-timezone="meetingTimezone"
              :name="name"
              :nameList="names"
              :corpNameShort="corpNameShort"
              :due-at="dueAt"
              :stage="stage"
              :group="group"
              :meta="meta"
              action-type="addSingle"
              @update="emitUpdate"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.stop="emitClose">cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.native="emitAdd">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDefaultDate, payloadDateFormat } from '@/lib/checklist-helpers';

import ChecklistInputItem from '@/components/ChecklistInputItem';

export default {
  name: 'MeetingChecklistDialogAddSingle',
  components: {
    ChecklistInputItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    meetingDate: {
      type: String,
      default: null
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    corpNameShort: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    dueAt: {
      type: String,
      default: null
    },
    meta: {
      type: Object,
      default() {
        return {};
      }
    },
    checklistGroups: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      groupKey: '',
      stage: ''
    };
  },
  computed: {
    // Find the group selected from the array of checklist groups
    group() {
      return this.groupKey
        ? this.checklistGroups.find(group => group.key === this.groupKey)
        : null;
    },
    // Create an array of checklist stages belonging to this group
    stages() {
      return this.group
        ? this.group.checklistTemplates.map(checklist => checklist.stage)
        : [];
    },
    // Create an array of checklist names based on the group and stage
    names() {
      return this.group
        ? this.group.checklistTemplates
            .filter(checklist => this.stage.includes(checklist.stage))
            .map(checklist => checklist.name)
        : [];
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitUpdate(obj) {
      obj.prop = 'newChecklist';
      this.$emit('update', obj);
    },
    emitAdd() {
      this.$emit('add');
      this.groupKey = '';
      this.stage = '';
      this.emitClose();
    },
    updateStage(stage) {
      // to clear the name and dueAt after the stage is changed
      this.emitUpdate({ key: 'name', value: '' });
      this.emitUpdate({ key: 'dueAt', value: null });
      this.emitUpdate({ key: 'stage', value: stage });
    }
  }
};
</script>

<style scoped></style>
