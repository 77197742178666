<template>
  <v-dialog :value="value" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Attach Meeting Documents</div>
      </v-card-title>
      <v-card-text>
        <v-layout
          >Select document(s) from the list below to attach to this
          {{ title }}:</v-layout
        >
        <v-layout>
          <v-select
            v-model="selected"
            :items="documents"
            item-text="name"
            item-value="Key"
            return-object
            multiple
            chips
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.name }} ({{ (item.size / 1000000).toFixed(2) }} mb)
            </template>
          </v-select>
        </v-layout>

        <v-layout v-if="exceedFileLimit" row>
          <v-flex xs12>
            <v-alert small outline color="red" icon="warning" :value="true">
              Warning: total attachment size exceeds 10 MB
            </v-alert>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-spacer />
        <v-btn
          class="primary"
          @click.native="emitUpdate"
          :disabled="exceedFileLimit"
        >
          {{ buttonText }}
          <v-icon v-if="attachmentType === 'email'" right>send</v-icon>
          <v-icon v-if="attachmentType === 'print'" right>attachment</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AttachFilesDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    attachments: {
      type: Array,
      default() {
        return [];
      }
    },
    meetingDocuments: {
      type: Array,
      default() {
        return [];
      }
    },
    attachmentType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: [],
      exceedFileLimit: false
    };
  },
  watch: {
    value() {
      this.selected = this.attachments;
      this.exceedFileLimitHandler();
    },
    selected() {
      // Count file size in total
      // if exceeds 9.5mb, not allow to send
      this.exceedFileLimitHandler();
    }
  },
  computed: {
    title() {
      if (this.attachmentType === 'print') {
        return 'print job';
      } else if (this.attachmentType === 'email') {
        return 'email or send without attachments';
      } else {
        return 'notice';
      }
    },
    buttonText() {
      if (this.attachmentType === 'email') {
        return 'Send';
      } else {
        return 'Attach';
      }
    },
    documents() {
      if (this.meetingDocuments) {
        return this.meetingDocuments.map(d => d.document);
      } else {
        return [];
      }
    }
  },
  methods: {
    emitUpdate(file) {
      this.$emit('update', this.selected);
    },
    emitClose() {
      this.$emit('close');
    },
    exceedFileLimitHandler() {
      if (this.selected && this.selected.length > 0) {
        let total = 0; // in b
        this.selected.forEach(s => {
          total += s.size;
        });

        if ((total / 1000000).toFixed(2) > 9.9) {
          this.exceedFileLimit = true;
        } else {
          this.exceedFileLimit = false;
        }
      } else {
        this.exceedFileLimit = false;
      }
    }
  }
};
</script>

<style></style>
