<template>
  <v-layout row nowrap>
    <v-flex xs12>
      <v-toolbar dense class="elevation-1">
        <v-text-field
          light
          prepend-icon="search"
          label="Filter by campaign name..."
          single-line
          hide-details
          clearable
          :value="searchText"
          @input="updateSearch"
        />
        <v-spacer />
        <v-btn
          flat
          color="blue"
          class="white--text"
          @click.stop="dialog.filters = true"
        >
          <v-icon left dark>filter_list</v-icon>Filters
        </v-btn>

        <span class="mr-3">Show:</span>

        <v-btn-toggle
          :value="toggles"
          multiple
          class="elevation-1"
          @change="updateToggles"
        >
          <v-btn flat value="showBilled">
            Billed
          </v-btn>
          <v-btn flat value="showIgnored">
            Ignored
          </v-btn>
        </v-btn-toggle>

        <v-btn
          flat
          icon
          @click.native="fetchData"
          :loading="loading"
          color="secondary"
        >
          <v-icon>cached</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="campaignListData"
        :loading="loading"
        :rows-per-page-items="pagination.rowsPerPageItems"
        :total-items="pagination.totalItems"
        :pagination.sync="pagination"
        @update:pagination="handlePagination"
        item-key="shortCode"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td class="avatar-cell">
              <campaign-avatar
                :campaign-type="props.item.type"
                :hide-billing="props.item.disableBilling"
              />
            </td>
            <td class="name-cell nowrap">
              <router-link
                :to="{
                  name: 'meetingOverview',
                  params: { shortcode: props.item.shortCode }
                }"
                target="_blank"
              >
                <b>{{ props.item.name }}</b> <v-icon small>open_in_new</v-icon>
              </router-link>
              <br />
              <span class="secondary-label">{{ props.item.type }}</span>
            </td>
            <td class="nowrap">
              <router-link
                :to="{
                  name: 'editAccount',
                  params: { accountKey: props.item.account.key }
                }"
              >
                {{ props.item.account.nameShort }}
              </router-link>
            </td>
            <td class="nowrap">
              {{ props.item.subscription?.status }}
            </td>
            <td class="nowrap">
              {{ props.item.meetingDate | formatDateHumanReadable }}
            </td>
            <td>
              <invoice-status-chip
                :stats="props.item.invoiceStats"
                :billing-enabled="props.item.billingEnabled"
                :route="getInvoicesRoute(props.item.shortCode)"
              />
            </td>
            <td>
              <v-tooltip bottom>
                <template slot="activator">
                  <span>{{
                    props.item.billingSettings.totalAmount > 0
                      ? `$${props.item.billingSettings.totalAmount}`
                      : ''
                  }}</span>
                </template>
                <span>
                  subtotal: ${{ props.item.billingSettings.subtotal }}<br />
                  tax: ${{ props.item.billingSettings.taxAmount }}<br />
                  total: ${{ props.item.billingSettings.totalAmount }}
                </span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template slot="activator">
                  <span>{{
                    props.item.invoices.total > 0
                      ? '$' + getInvoiceTotal(props.item.invoices.invoices)
                      : ''
                  }}</span>
                </template>
                <span>
                  <span
                    v-for="item in props.item.invoices.invoices"
                    :key="item.key"
                  >
                    <span v-if="isValidInvStatus(item.status)"
                      >{{ item.invoiceNumber }}: ${{ item.totalPrice }}<br
                    /></span>
                  </span>
                </span>
              </v-tooltip>
            </td>
            <td class="nowrap">
              <print-status-chips :stats="props.item.printStats" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>

    <!-- Dialog -->
    <filter-campaigns-dialog
      :value="dialog.filters"
      :current-sub-filter="subFilter"
      @update="updateFilters"
      @close="dialog.filters = false"
    />
  </v-layout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatDateHumanReadable } from '@/filters';

import InvoiceStatusChip from '@/components/InvoiceStatusChip';
import MeetingStageChip from '@/components/MeetingStageChip';
import PrintStatusChips from '@/components/PrintStatusChips';
import CampaignAvatar from '@/components/CampaignAvatar';

import FilterCampaignsDialog from './FilterCampaignsDialog.vue';

export default {
  name: 'AdminCampaignsViewList',
  components: {
    InvoiceStatusChip,
    MeetingStageChip,
    PrintStatusChips,
    CampaignAvatar,
    FilterCampaignsDialog
  },
  props: {},
  filters: {
    formatDateHumanReadable
  },
  data() {
    return {
      headers: [
        { sortable: false, width: '40px' },
        { text: 'EVENT NAME', value: 'name', sortable: false },
        { text: 'ACCOUNT NAME', value: 'accountNameShort', sortable: false },
        { text: 'SUBSCRIPTION', value: 'subscriptionStatus', sortable: false },
        { text: 'EVENT DATE', value: 'meetingDate' },
        { text: 'BILLING', value: 'invoiceStats', sortable: false },
        { text: 'BILL TOTAL', value: 'billingSettings', sortable: false },
        { text: 'INVOICE TOTAL', value: 'invoices', sortable: false },
        { text: 'PRINT JOBS', value: 'printStats', sortable: false }
      ],
      searchText: '',
      dialog: {
        filters: false
      },
      pagination: {
        descending: true,
        sortBy: 'meetingDate',
        search: '',
        page: 1,
        rowsPerPage: 15,
        totalItems: 0,
        rowsPerPageItems: [15, 30, 50, 100]
      }
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('campaigns', [
      'campaignListData',
      'toggles',
      'dataLastFetchedAt',
      'subFilter'
    ]),
    loading() {
      return !this.dataLastFetchedAt;
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'getCampaignList',
      'updateToggles',
      'setSearchText',
      'setFilters',
      'setPagination'
    ]),
    fetchData() {
      this.getCampaignList();
    },
    getInvoicesRoute(shortCode) {
      return { name: 'meetingBilling', params: { shortcode: shortCode } };
    },
    getInvoiceTotal(items) {
      const initialValue = 0;

      // Get the total invoice amount based on valid status
      const totalInvoiced = items.reduce((accumulator, item) => {
        if (this.isValidInvStatus(item.status)) {
          return accumulator + item.totalPrice;
        }
        return accumulator;
      }, initialValue);

      // Round invoice amount
      const roundedInvoiceTotal = parseFloat(totalInvoiced.toFixed(2));
      return roundedInvoiceTotal;
    },
    isValidInvStatus(status) {
      // List of invoice statuses to include (no 'voided' or 'draft')
      const validInvStatus = ['paid', 'sent', 'posted', 'payment_due'];
      return validInvStatus.includes(status.toLowerCase());
    },
    updateSearch: _.debounce(function(value) {
      this.setSearchText({ searchText: value });
      this.fetchData();
    }, 350),
    async updateFilters({ subFilter }) {
      try {
        this.setFilters({ subFilter });
        this.dialog.filters = false;
        this.fetchData();
      } catch (err) {
        this.$events.$emit('toastEvent', err.message);
      }
    },
    handlePagination(options) {
      this.setPagination(options);
      this.fetchData();
    }
  }
};
</script>

<style scoped>
.name-cell {
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.secondary-label {
  opacity: 0.74;
  font-size: 12px;
}
.checkbox {
  color: black;
}
</style>
