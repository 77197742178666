<template>
  <v-dialog max-width="700px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Add New Contact</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <contact-select
          ref="contactSelect"
          label="Contact"
          :multiple="true"
          :chips="true"
          @update="emitUpdate"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose" :disabled="loading">Cancel</v-btn>
        <v-btn
          class="primary"
          @click.native="onAdd()"
          :loading="loading"
          :disabled="!isSelected"
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactSelect from '../ContactSelect.vue';
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'AddContactAssociationDialog',
  components: {
    ContactSelect
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelected() {
      return !_.isEmpty(this.selected);
    }
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey,
      selected: [],
      loading: false
    };
  },
  methods: {
    ...mapActions('accounts', ['associateContacts']),
    resetSelected() {
      this.selected = [];
    },
    emitClose() {
      this.$emit('close');
      this.$refs.contactSelect.clearInput();
    },
    async onAdd() {
      this.loading = true;
      try {
        const contactIds = this.selected.map(({ id }) => id);
        await this.associateContacts({ key: this.accountKey, contactIds });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
        this.resetSelected();
        this.emitClose();
      }
    },
    emitUpdate(values) {
      this.selected = values;
    }
  }
};
</script>

<style scoped></style>
