<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="title">Taxes</div>
        <v-spacer />
        <div>
          <v-btn
            outline
            @click.native="addTaxClicked"
            color="primary">Add Tax</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="data.details"
          class="elevation-0"
          hide-headers
          hide-actions>
          <template
            slot="items"
            slot-scope="props">
            <td class="text-xs-left capitalize">{{ props.item.description }}</td>
            <td class="text-xs-right">{{ props.item.value * 100 }}%</td>
            <td class="text-xs-right table-cell--values-width">
              {{ formatValue(props.item.amount) }}
              <v-btn
                flat
                icon
                color="grey"
                @click="deleteInvoiceItemClicked(props.item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </template>
          <template slot="footer">
            <td
              colspan="3"
              class="text-xs-center grey lighten-5">
              <strong>Total Taxes: {{ formatValue(data.total) }}</strong>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-item-dialog
      :is-open="showDeleteDialog"
      resource-name="invoiceItem"
      :resource-key="itemToDelete.key"
      @delete="handleDeleteItem"
      @close-dialog="showDeleteDialog = false">

      <span slot="title">Delete Tax?</span>
      <span slot="description">
        <p class="capitalize"><b>{{ itemToDelete.type }}:</b> {{ itemToDelete.category }}</p>
        <p><b>Description:</b> {{ itemToDelete.description }}</p>
        <p><b>Value:</b> {{ itemToDelete.value }}</p>
      </span>
    </delete-item-dialog>

    <v-dialog
      v-model="showAddDialog"
      persistent
      max-width="600px">
      <add-item-dialog
        :invoice-key="invoiceKey"
        :item-options="itemOptions"
        @close="closeDialogs" />
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {formatCurrencyValue} from '@/helpers';
import selectOptions from '@/lib/billing-select-options.json';

import AddItemDialog from './AdminInvoiceViewTaxesAdd';
import DeleteItemDialog from '@/components/dialogs/DeleteResourceDialog';

export default {
  name: 'AdminInvoiceViewTaxesTable',
  components: {
    AddItemDialog,
    DeleteItemDialog
  },
  props: {
    invoiceKey: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDeleteDialog: false,
      showAddDialog: false,
      itemToDelete: {key: ''},
      itemOptions: [],
      headers: [
        {text: 'Desc', value: 'description', align: 'left'},
        {text: '%', value: 'value', align: 'left'},
        {text: 'Amount', value: 'amount', align: 'center'}
      ]
    };
  },
  created() {
    this.itemOptions = selectOptions['tax'];
  },
  methods: {
    ...mapActions('invoices', ['deleteItemFromInvoice']),
    formatValue(value) {
      return formatCurrencyValue(value);
    },
    addTaxClicked() {
      this.showAddDialog = true;
    },
    deleteInvoiceItemClicked(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    closeDialogs() {
      this.showDeleteDialog = false;
      this.showAddDialog = false;
      this.itemToDelete = {key: ''};
    },
    async handleDeleteItem(itemKey) {
      try {
        await this.deleteItemFromInvoice({
          itemKey,
          key: this.invoiceKey
        });
        this.$events.$emit('toastEvent', 'Item Deleted');
        this.closeDialogs();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.table-icon {
  font-size: 16px;
}

.button-cells {
  width: 70px;
}

.value-cells {
  width: 100px;
}

.subtotal-row {
  background-color: #efefef;
  font-weight: 600;
}
</style>
