<template>
  <v-dialog max-width="700px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Add New Conversation</span>
        <v-spacer />
        <v-flex class="text-xs-right" xs6>
          <!-- Filter by Conversation Type -->

          <v-menu offset-y transition="scale-transition">
            <v-btn slot="activator" light flat color="blue">
              <v-icon>filter_list</v-icon>filter
            </v-btn>
            <v-list
              dense
              v-for="template in filterGroups"
              :key="template.group"
            >
              <v-list-tile @click="filterBy(template.group)">
                <v-list-tile-content>
                  <v-chip
                    :outline="!isTemplateFilter(template.group)"
                    label
                    small
                    class="white--text"
                    :color="getChipColor(template.group)"
                    ><strong>{{ template.group }}</strong></v-chip
                  >
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
      </v-card-title>

      <v-card-text>
        <v-combobox
          :items="sortedConversationSamples"
          :value="selected.name"
          item-text="name"
          item-value="name"
          label="Select Conversation Template"
          @input="setSampleConversation($event)"
        >
          <template v-slot:item="data">
            <td width="18%">
              <v-chip
                v-if="hasTemplateType(data.item.meta)"
                label
                small
                class="white--text"
                :color="getChipColor(data.item.meta.templateType)"
                ><strong>{{ data.item.meta.templateType }}</strong></v-chip
              >
            </td>
            <td>
              {{ data.item.name }}
            </td>
          </template>
        </v-combobox>

        <div v-if="isSelected && !isEmptyTemplateSelected">
          <div class="subject">
            <strong>Subject line:</strong> {{ selected.subject }}
          </div>
          <div class="html-preview" v-html="selected.body" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Go back</v-btn>
        <v-btn
          class="primary"
          @click.native="emitAdd(selected)"
          :disabled="!isSelected && !isEmptyTemplateSelected"
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getChecklistColor } from '@/lib/checklist-helpers';
import replaceSubjectMergeFields from '@/lib/replace-subject-merge-fields';
import _ from 'lodash';

export default {
  name: 'AddConversationDialog',
  props: {
    conversationSamples: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelected() {
      return !_.isEmpty(this.selected);
    },
    currentConversationSamples() {
      return this.conversationSamples;
    },
    sortedConversationSamples() {
      let filteredTemplates = this.currentConversationSamples;
      // Filter by conversation type
      if (this.conversationType) {
        filteredTemplates = _.filter(filteredTemplates, template => {
          if (template.meta.templateType === this.conversationType) {
            return template;
          }
        });
      }
      const sortedFilteredTemplates = _.sortBy(filteredTemplates, [
        'meta.templateType',
        'name'
      ]);

      // Add the empty template option
      sortedFilteredTemplates.unshift({
        name: 'No template',
        subject: '',
        body: '',
        segment: 'all',
        meta: {
          templateType: 'empty'
        }
      });
      return sortedFilteredTemplates;
    }
  },
  data() {
    return {
      filterGroups: [
        { group: 'cs-onboard', colour: 'green' },
        { group: 'pre-consent', colour: 'orange' },
        { group: 'pre-notice', colour: 'blue' },
        { group: 'service', colour: 'red' },
        { group: 'vm', colour: 'purple' },
        { group: 'vm-post', colour: 'cyan' },
        { group: 'other', colour: 'grey' }
      ],
      selected: {},
      conversationType: '',
      isEmptyTemplateSelected: false
    };
  },
  methods: {
    resetSelected() {
      this.selected = {};
    },
    isTemplateFilter(templateType) {
      return this.conversationType === templateType;
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitAdd(selected) {
      if (this.isEmptyTemplateSelected) {
        this.$emit('add', {});
      } else {
        this.$emit('add', selected);
      }
    },
    setSampleConversation(conversation) {
      if (!conversation) {
        return;
      }
      conversation.subject = replaceSubjectMergeFields({
        subject: conversation.subject,
        meeting: this.currentMeeting
      });

      if (conversation.meta.templateType === 'empty') {
        this.isEmptyTemplateSelected = true;
      } else {
        this.isEmptyTemplateSelected = false;
      }
      this.selected = conversation;
    },
    hasTemplateType(meta) {
      return _.get(meta, 'templateType', false);
    },
    getChipColor(templateType) {
      return getChecklistColor(templateType);
    },
    filterBy(conversationType) {
      if (this.conversationType === conversationType) {
        this.conversationType = '';
      } else {
        this.conversationType = conversationType;
      }
      this.resetSelected();
    }
  }
};
</script>

<style scoped>
.html-preview {
  display: block;
  padding: 10px;
  border: none;
  border: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 10px;
  font-family: 'Monaco', courier, monospace;

  overflow-y: scroll;
  max-height: 28vh;
}
.subject {
  background-color: #eceff1;
  color: #0d47a1;
  font-size: 12px;
  padding: 9px;
}
</style>
