<template>
  <v-dialog max-width="450px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Reset Ballots?</span>
      </v-card-title>
      <v-card-text>
        You are about to reset
        <b>{{ selected.length }} ballot(s)</b>. Click RESET to continue.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="error" @click.native="emitDelete">RESET</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingRtvBallotsDeleteDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('deleteBallot');
    }
  }
};
</script>

<style scoped></style>
