<template>
  <v-card class="elevation-1 my-1 message-box">
    <v-layout row>
      <v-flex xs11>
        <div>
          <span class="pr-1">
            <strong>{{ note.nickname }}</strong> </span
          ><br />
          <span class="grey--text caption">{{
            note.date | formatDateHumanReadableAlt(true)
          }}</span>
        </div>
        <div class="message" v-if="!toggleEdit">{{ note.message }}</div>
        <div v-else>
          <v-textarea
            label="Edit Message"
            class="edit-textarea"
            :value="note.message"
            v-model="localNoteObj.message"
            light
          />
          <div class="button-align">
            <v-btn class="white" flat small light @click="toggleEdit = false"
              >Cancel</v-btn
            >
            <v-btn class="primary" small dark @click="emitEditMessage"
              >Save</v-btn
            >
          </div>
        </div>
      </v-flex>
      <v-flex v-if="!hideEditBtn">
        <v-menu v-if="!toggleEdit" bottom left class="ma-0 pa-0">
          <v-btn small icon slot="activator" light>
            <v-icon small>more_vert</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile @click="toggleEdit = !toggleEdit">
              <v-list-tile-content>Edit</v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="emitDelete">
              <v-list-tile-content class="error--text"
                >Delete</v-list-tile-content
              >
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';

export default {
  name: 'NoteMessage',
  filters: {
    ...filters
  },
  props: {
    note: {
      type: Object,
      default() {
        return {};
      }
    },
    index: { type: Number, default: 0 },
    hideEditBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggleEdit: false,
      localNoteObj: _.cloneDeep(this.note)
    };
  },
  methods: {
    emitEditMessage() {
      this.toggleEdit = false;
      this.$emit('editMessage', {
        index: this.index,
        noteObj: this.localNoteObj
      });
    },
    emitDelete() {
      this.$emit('deleteMessage', { index: this.index });
    }
  }
};
</script>

<style scoped>
.message-box {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
}
.message {
  white-space: pre-wrap;
}
.button-align {
  display: flex;
  justify-content: flex-end;
}
.edit-textarea {
  font-size: 14px;
}
</style>
