<template>
  <div>
    <!-- File upload area, no package exists -->
    <div v-if="processing" class="dz-area">
      <div class="dz-message">
        <p>Uploading...</p>
        <v-progress-circular :size="50" indeterminate color="primary" />
      </div>
    </div>
    <div v-else class="dz-area">
      <div class="dz-message">
        <v-icon color="grey" large>backup</v-icon>
        <span class="highlight">
          Click or Drag and Drop files here to upload
        </span>
        <span class="text--secondary"
          >Only accepts images, videos, PDF, Excel, Word and Powerpoint
          files.</span
        >
      </div>
      <div class="input-area">
        <input
          type="file"
          multiple
          @change.prevent="onFileChange"
          accept="audio/*,image/*,video/*,.pdf,.doc*,.xls*,.pptx,.txt,.csv"
        />
      </div>
    </div>
    <div class="mt-2">
      <p v-if="isAdmin" class="text--secondary">
        Maximum upload file size: 500MB
      </p>
      <p v-else class="text--secondary">Maximum upload file size: 50MB</p>
    </div>
    <upload-file-size-error-dialog
      :is-open="triggerError"
      @close-dialog="triggerError = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import bluebird from 'bluebird';
import { mapActions, mapGetters } from 'vuex';
import getFileExt from '@/lib/get-file-ext';
import UploadFileSizeErrorDialog from '@/components/dialogs/UploadFileSizeErrorDialog';

export default {
  name: 'MeetingFilesUploader',
  components: { UploadFileSizeErrorDialog },
  props: {
    icon: {
      type: String,
      default: 'insert_drive_file'
    },
    shortCode: {
      type: String,
      required: true
    },
    apiVersion: {
      type: String,
      default() {
        return 'v1';
      }
    }
  },
  data() {
    return {
      processing: false,
      triggerError: false,
      maxFileSize: 52428800 // non admin users: 50MB
    };
  },
  created() {
    if (this.isAdmin) {
      this.maxFileSize = 524288000; // admin users: 500MB;
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    packageExists() {
      return !_.isEmpty(this.attachment);
    }
  },
  methods: {
    // Map both v1 and v2 actions for getPostPreSignedUrl
    ...mapActions('meetings/files', {
      getPostPreSignedUrlV1: 'getPostPreSignedUrl'
    }),
    ...mapActions('v2/meetingsFiles', {
      getPostPreSignedUrlV2: 'getPostPreSignedUrl'
    }),
    async onFileChange(e) {
      try {
        this.processing = true; // Trigger progress animation
        // Grab file object
        const files = e.target.files || e.dataTransfer.files;

        let jobs = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (file.size > this.maxFileSize) {
            this.triggerError = true;
            return;
          }

          let name = _.replace(file.name, new RegExp(',', 'g'), '_');

          // Determine api version to use
          const getPostPreSignedUrl =
            this.apiVersion === 'v2'
              ? this.getPostPreSignedUrlV2
              : this.getPostPreSignedUrlV1;

          const res = await getPostPreSignedUrl({
            shortCode: this.shortCode,
            fileName: name,
            folder: 'files'
          });

          const url = res.url;

          const fileExt = getFileExt(name);
          // Check if we need to force the Content-Type of .csv files to `text/csv`.
          // If the user's computer has MS Office installed,  it can cause the
          // browser to treat .csv files with a content-type of
          // `application/vnd.ms-excel` when S3 is expecting it to be `text/csv`.
          const contentType = fileExt === 'csv' ? 'text/csv' : file.type;

          const config = {
            headers: { 'Content-Type': contentType },
            onUploadProgress: function(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(percentCompleted);
            }
          };

          let formData = new FormData();
          Object.keys(res.fields).forEach(key => {
            formData.append(key, res.fields[key]);
          });

          formData.append('file', file);

          jobs.push(
            await axios({
              method: 'post',
              url,
              data: formData,
              ...config
            })
          );
        }

        await bluebird.all(jobs);
      } catch (err) {
        console.error('Error uploading', err);
      } finally {
        // Clear to detect any change event
        e.target.value = '';
        this.processing = false;

        this.$emit('update');
      }
    }
  }
};
</script>

<style scoped>
.dz-area {
  position: relative;
  display: flex;
  justify-content: center;
  height: 150px;
  cursor: pointer;
  border: 2px dashed #e8e8e8;
}
.dz-area > .dz-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #97a8be;
}
.dz-area > .dz-message > .highlight {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #20a0ff;
}
.dz-area .input-area {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dz-area .input-area input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
  cursor: hand;
}
</style>
