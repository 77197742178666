<template>
  <v-dialog :value="value" max-width="700px" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Export Voter Registration Pages?</div>
      </v-card-title>
      <v-card-text>
        <v-layout justify-center
          ><v-flex xs10
            ><p>
              Do you want to export registration cover pages for the following
              units? <br />
              A registration cover page will be generated for each voter.
            </p>
          </v-flex>
        </v-layout>
        <v-layout v-for="(u, key) in sendList" :key="key" justify-center>
          <v-flex xs10>
            <div>
              <b>{{ u.unit }}</b> - ({{ u.voters }})
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-spacer />
        <v-btn class="primary" :loading="loading" @click.native="emitSend"
          >send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsBulkRegistrationPageDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sendList() {
      let list = [];

      // First, create a list of recipients
      // (Each unit selected may have multiple email recipients)
      this.selected.forEach(unit => {
        const voters = unit.voters;
        list.push({
          unitId: unit.id,
          unit: unit.unit,
          voters: voters.map(v => v.name).join(', ')
        });
      });

      // Second, sort the list of recipients by unit
      const sortedList = _.sortBy(list, 'unit');
      return sortedList;
    }
  },
  methods: {
    emitSend() {
      this.$emit('send', this.sendList);
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>
