<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <agent-view-info
          :agent="currentAgent"
          @update="handleUpdate"
          @submit="updateAgentData"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <agent-view-accounts :accounts="currentAgent.accounts" class="mt-4" />
        <br />
        <agent-view-users :users="currentAgent.users" class="mt-4" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

// Components
import AgentViewInfo from './AdminAgentViewInfo.vue';
import AgentViewUsers from './AdminAgentViewUsersTable.vue';
import AgentViewAccounts from './AdminAgentViewAccountsTable.vue';
export default {
  name: 'AdminAgentView',
  props: {
    currentAgent: {
      type: Object,
      required: true
    }
  },
  components: {
    AgentViewInfo,
    AgentViewUsers,
    AgentViewAccounts
  },
  data() {
    return {
      state: {}
    };
  },
  created() {
    this.setState(this.currentAgent);
  },
  methods: {
    ...mapActions('agents', ['updateAgent']),
    setState(currentAgent) {
      this.state = _.cloneDeep(currentAgent);
    },
    handleUpdate(obj) {
      _.set(this.state, obj.key, obj.value);
    },
    async updateAgentData() {
      try {
        await this.updateAgent({
          key: this.$route.params.agentKey,
          payload: this.state
        });
        this.$emit('reload');
        this.$events.$emit('toastEvent', 'Agent Updated');
      } catch (err) {
        console.log(err);
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>
