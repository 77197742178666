import http from './requestType';

/** Returns all proxies submitted for this meeting
 * @param  {Object}   params
 * @param  {String}   params.shortCode         - the meeting shortcode
 * @param  {String}   params.sortBy            - the property to sort on
 * @param  {String}   params.sortDir           - the direction to sort on
 * @param  {String[]} [params.includeStatuses] - statuses to include
 * @param  {String[]} [params.includeSources]  - source types to include
 * @return {Promise}                          - the list of proxies
 */
export function getMeetingProxyList({
  shortCode,
  sortBy,
  sortDir,
  includeStatuses,
  includeSources,
  includeBoxes
}) {
  const params = {
    sort_by: sortBy,
    sort_direction: sortDir,
    include_statuses: includeStatuses,
    include_sources: includeSources,
    include_boxes: includeBoxes
  };
  return http._get(`Meetings/${shortCode}/proxies`, params);
}

/**
 * Get the details of one meeting proxy
 *
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} proxyKey - the proxy identifier
 * @return {Promise} - Promise
 */
export function getMeetingProxy(shortCode, proxyKey) {
  return http._get(`Meetings/${shortCode}/proxies/${proxyKey}`);
}

/**
 * Get the question and answer details of one meeting proxy
 *
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} proxyKey  - the proxy identifier
 * @return {Promise} - Promise
 */
export function getProxyAnswers({ shortCode, proxyKey }) {
  return http._get(`Meetings/${shortCode}/proxies/${proxyKey}/answers`);
}

/**
 * Revoke a proxy
 *
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} proxyKey - the proxy identifier
 * @return {Promise} - Promise
 */
export function revokeMeetingProxy(shortCode, proxyKey) {
  return http._delete(`Meetings/${shortCode}/proxies/${proxyKey}`);
}

/**
 * Verify a proxy
 *
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} proxyKey - the proxy identifier
 * @return {Promise} - Promise
 */
export function verifyMeetingProxy(shortCode, proxyKey) {
  return http._post(`Meetings/${shortCode}/proxies/${proxyKey}/verify`);
}

/**
 *
 *
 * @param  {String} {shortCode}           - meeting code
 * @param  {String} {questionKey}         - the advance question key
 * @param  {String} {vote}                - the vote option
 * @param  {String} {liveQuestionKey}     - the live question key
 * @return {Promise} - Promise
 *
 */
export function deleteMeetingPaperProxy(params) {
  const { shortCode, questionKey, vote, liveQuestionKey } = params;
  return http._patch(
    `Meetings/${shortCode}/proxies/actions/delete-paper-proxy`,
    { questionKey, vote, liveQuestionKey }
  );
}

/**
 *
 *
 * @param  {String} {shortCode}     - meeting code
 * @param  {String} {questionKey}   - the advance question key
 * @param  {Array}  {paperProxies}  - the array of paper proxy vote objects with required vote totals
 * @param  {String}  {liveQuestionKey}  - live question key
 * @return {Promise} - Promise
 *
 */
export function saveMeetingPaperProxies(params) {
  const { shortCode, questionKey, paperProxies, liveQuestionKey } = params;

  return http._post(
    `Meetings/${shortCode}/proxies/actions/save-paper-proxies`,
    { questionKey, paperProxies, liveQuestionKey }
  );
}

/**
 *
 *
 * @param  {String} {shortCode}     - meeting code
 * @param  {String} {questionKey}   - the advance question key
 * @param  {String} {vote}          - the target write in vote value
 * @return {Promise} - Promise
 *
 */
export function listMeetingProxyWriteIns(params) {
  const { shortCode, questionKey, vote } = params;
  return http._get(`Meetings/${shortCode}/proxies/actions/write-ins`, {
    questionKey,
    vote
  });
}

/**
 *
 * @param  {String}  {shortCode}    - meeting code
 * @param  {Object}  {proxy}        - payload of the new proxy submission
 * @return {Promise} - Promise
 *
 */
export function submitMeetingProxy({ shortCode, proxy }) {
  return http._post(`Meetings/${shortCode}/proxies`, proxy);
}
