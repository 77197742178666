<template>
  <div>
    <v-container grid-list-md fluid v-if="currentAgentLoaded">
      <v-layout row>
        <div>
          <span class="headline">
            {{ currentAgent.name }}
          </span>
          <p class="secondary--text">
            View and manage the agent information for
            <b>{{ currentAgent.name }}</b>
          </p>
        </div>
        <v-spacer></v-spacer>
        <preferred-managers :current-agent="currentAgent" />
      </v-layout>

      <transition name="slide-fade" mode="out-in">
        <router-view :current-agent="currentAgent" @reload="refresh" />
      </transition>
    </v-container>
  </div>
</template>

<script>
import PreferredManagers from './AdminAgentPreferredManagers.vue';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AdminAgentMain',
  components: {
    PreferredManagers
  },
  data() {
    return {
      agentKey: this.$route.params.agentKey
    };
  },
  async created() {
    await this.refresh();
  },
  computed: {
    ...mapGetters('agents', ['agentByKey']),
    currentAgent() {
      return this.agentByKey(this.agentKey);
    },
    currentAgentLoaded() {
      return !!this.currentAgent;
    }
  },
  methods: {
    ...mapActions('agents', ['getAgent']),
    // Upon editing or updating data, refresh for recent data changes
    async refresh() {
      await this.getAgent(this.agentKey);
    }
  }
};
</script>

<style scoped></style>
