<template>
  <div>
    <div class="body-2 pt-3">
      Account Notes
      <v-btn icon small @click="dialog = true">
        <v-icon small>add</v-icon>
      </v-btn>
    </div>

    <div v-if="notes.length > 0">
      <div
        class="account-notes-message"
        v-for="(note, index) in notes"
        :key="`key-${index}`"
      >
        <accounts-notes-message
          :note="note"
          :index="index"
          @editMessage="editMessage"
          @deleteMessage="deleteMessage"
        />
      </div>
    </div>
    <div v-else class="body-2 grey--text">There are no account notes</div>

    <!-- Add new note dialog -->
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Create new note</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Enter note message here"
            v-model="newNote.message"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog = false">Close</v-btn>
          <v-btn class="primary" @click.native="addNewNote">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import AccountsNotesMessage from '@/components/NoteMessage';

export default {
  name: 'AdminAccountViewNotes',
  components: {
    AccountsNotesMessage
  },
  props: {
    notes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dialog: false,
      profile: JSON.parse(sessionStorage.getItem('profile')),
      newNote: {
        message: '',
        nickname: '',
        date: ''
      }
    };
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    addNewNote() {
      // Current Date
      this.newNote.date = moment();
      this.newNote.nickname = `${this.profile.firstName} ${this.profile.lastName}`;

      let noteArray = _.clone(this.notes);
      noteArray.push(this.newNote);
      this.onChange('data.notes', noteArray);

      // Close dialog and reset form
      this.dialog = false;
      this.newNote = {
        message: '',
        nickname: '',
        date: ''
      };
    },
    editMessage(editObj) {
      let noteArray = _.clone(this.notes);
      noteArray[editObj.index] = editObj.noteObj;
      this.onChange('data.notes', noteArray);
    },
    deleteMessage(editObj) {
      let noteArray = _.clone(this.notes);
      noteArray.splice(editObj.index, 1);
      this.onChange('data.notes', noteArray);
    }
  }
};
</script>

<style scoped></style>
