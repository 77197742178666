<template>
  <!-- Account Users -->
  <v-layout>
    <v-flex xs12>
      <div class="gq-cell--section-title mb-4">
        <h4>Account Users (clients)</h4>
        <p>
          These users have access to all campaigns under
          {{ account.nameShort }}. Account users can only be updated from the
          Admin Accounts Page .
        </p>
      </div>

      <v-toolbar dark dense color="grey lighten-4 elevation-1">
        <v-spacer />
        <router-link
          :to="{ name: 'editAccount', params: { accountKey: account.key } }"
          target="_blank"
          replace
        >
          EDIT ACCOUNT USERS
        </router-link>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="users"
        :rows-per-page-items="config.rowsPerPageItems"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <td>
            <span class="name">
              {{ props.item.user.name }}
            </span>
            <br />
            <span>
              <router-link
                :to="{
                  name: 'adminUserEdit',
                  params: { userKey: props.item.userKey }
                }"
                target="_blank"
              >
                {{ props.item.user.email }}
              </router-link>
            </span>
          </td>
          <td><user-status-chip :status="isDisabled(props.item)" /></td>
          <td>
            {{ props.item.lastLoginAt | formatNow }}
          </td>
          <td>{{ props.item.createdAt | formatNow }}</td>
          <td class="text-xs-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon
                    class="mr-1"
                    :disabled="!!props.item.disabledAt"
                    @click="sendWelcome(props.item.user)"
                  >
                    mail
                  </v-icon>
                </span>
              </template>
              <span v-if="props.item.disabledAt">
                Cannot send welcome to disabled user
              </span>
              <span v-else>Resend welcome email</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';

import UserStatusChip from '@/components/UserStatusChip';

export default {
  name: 'MeetingUsersViewAccountUsersTable',
  components: {
    UserStatusChip
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      default() {
        return {};
      }
    },
    users: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    formatNow(date) {
      return date ? moment(date).fromNow() : 'Never';
    }
  },
  data() {
    return {
      headers: [
        { text: 'User', value: 'user.name' },
        { text: 'status', value: 'disabledAt' },
        { text: 'Last Login', value: 'lastLoginAt' },
        { text: 'Added', value: 'createdAt' },
        { text: '', sortable: false, align: 'right' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },
  computed: {},
  created() {},
  methods: {
    sendWelcome({ email, key }) {
      const isConfirm = confirm(`Re-send a welcome email to ${email}?`);
      if (!isConfirm) {
        return '';
      }
      this.$emit('welcome', key);
    },
    isDisabled({ disabledAt }) {
      return disabledAt ? 'disabled' : 'active';
    }
  }
};
</script>

<style scoped>
.file-link {
  margin-right: 5px;
  white-space: nowrap;
}
.name {
  font-weight: bold;
  text-transform: capitalize;
}
</style>
