'use strict';

/**
 * Convert array into query string parameter for request
 *
 * @param {String} key - key
 * @param {Array} arr - array
 * @return {Array} a new array of data that matches the searchKey
 */
export default function parameterizeArray(key, arr) {
  arr = arr.map(encodeURIComponent);
  return '?' + key + '[]=' + arr.join('&' + key + '[]=');
}
