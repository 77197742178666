<template>
  <section>
    <v-text-field
      label="Template Group Name"
      :value="name"
      :disabled="isDisabled"
      :rules="[v => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('name', $event)"
    />
    <v-text-field
      label="Description"
      :value="description"
      :disabled="isDisabled"
      :rules="[v => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('description', $event)"
    />
  </section>
</template>

<script>
export default {
  name: 'InputNoticeInfo',
  props: {
    name: { type: String, default: '' },
    description: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped></style>
