<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Meeting Date and Submission Deadlines
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Determine when online voting will be closed. Notices cannot be sent once
      past the meeting date
    </div>

    <v-layout row class="mt-4">
      <v-flex xs6 md6>
        <h5 class="subheading">
          Meeting Time Zone
        </h5>
        <p class="grey--text text--darken-2">
          Set the time zone for all dates in this meeting.
        </p>
      </v-flex>
      <v-flex xs6 md6 class="pl-3">
        <v-select
          :items="timezones"
          item-text="text"
          item-value="value"
          v-model="timezone"
          solo
          label="Timezones"
          @change="onChange('meetingTimezone', $event)"
        />
      </v-flex>
    </v-layout>

    <v-divider class="ma-3 mt-4" />
    <date-time-pickers
      label="What is the date of the meeting?"
      :timezone="timezone"
      :iso-date="meetingDate"
      @update="onChange('meetingDate', $event)"
    />
    <v-layout row>
      <v-flex xs6 md6>
        <v-text-field
          prepend-icon="watch"
          label="Duration"
          :value="duration"
          type="number"
          suffix="minutes"
          min="0"
          max="360"
          @input="onChange('duration', $event)"
        />
      </v-flex>
    </v-layout>

    <v-divider class="ma-3 mt-4" />

    <date-time-pickers
      label="What is the deadline for voters to make their submissions?"
      :iso-date="expiryDate"
      :timezone="timezone"
      hint="All Florida Meetings won't have expiry date or time by default."
      @update="onChange('expiryDate', $event)"
    />

    <v-divider class="ma-3 mt-4" />

    <date-time-pickers
      label="What is the deadline to SELF-REVOKE submissions in the Proxy tool?"
      :iso-date="revocationDeadlineDate"
      :timezone="timezone"
      hint="Voters will be unable to self-revoke their submissions after this date. Leave blank to disable self-revocations. Admins can still revoke using the Proxies tab. NOTE: this date only applies to proxy revocations inside the Proxy tool."
      @update="onChange('revocationDeadlineDate', $event)"
    />

    <v-divider class="ma-3 mt-4" />

    <date-time-pickers
      label="What is the candidate nominations deadline?"
      :iso-date="nominationExpiryDate"
      :timezone="timezone"
      hint="Voters will be able to submit candidacy through Nominations App up to this date. Leave blank to disable nominations."
      @update="onChange('nominationExpiryDate', $event)"
    />

    <v-divider class="ma-3 mt-4" />

    <date-time-pickers
      label="What is the consent deadline?"
      :iso-date="consentDeadlineDate"
      :timezone="timezone"
      hint="Owners will be able to submit their consent through the Consent App up to this date. Leave blank if there is no deadline."
      @update="onChange('consentDeadlineDate', $event)"
    />

    <v-divider class="ma-3 mt-4" />

    <v-layout class="mt-3" align-content-start>
      <div>
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="enableBreakdownDatePicker"
          @change="
            onChange('options.enableVoteBreakdown', $event);
            $refs.voteBreakdownPicker.emitClear();
          "
        />
      </div>
      <div>
        <div class="subheading">Enable Vote Breakdown</div>
        <div class="grey--text text--darken-2">
          Choose a specific date and time when the Vote Breakdown will be
          available to Account Users. <br />
          If left disabled, these results are never made available.
        </div>
      </div>
    </v-layout>

    <date-time-pickers
      ref="voteBreakdownPicker"
      :iso-date="voteBreakdownIso"
      label="Date"
      timeLabel="Time"
      :isDisabled="!enableBreakdownDatePicker"
      :timezone="timezone"
      @update="onChange('options.enableVoteBreakdownDate', $event)"
    />
  </v-container>
</template>

<script>
import DateTimePickers from '@/components/DateTimePicker';
import timezones from '@/lib/timezones';

export default {
  name: 'MeetingSettingsDateInput',
  components: {
    DateTimePickers
  },
  props: {
    meetingDate: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      default: ''
    },
    expiryDate: {
      type: String,
      default: ''
    },
    nominationExpiryDate: {
      type: String,
      default: ''
    },
    duration: {
      type: Number
    },
    revocationDeadlineDate: {
      type: String,
      default: ''
    },
    corpShortname: {
      type: String,
      default: ''
    },
    enableVoteBreakdown: {
      type: Boolean,
      default: false
    },
    enableVoteBreakdownDate: {
      type: String,
      default: null
    },
    consentDeadlineDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      timezones,
      timezone: this.meetingTimezone,
      enableBreakdownDatePicker: this.enableVoteBreakdown
    };
  },
  watch: {
    enableVoteBreakdown(value) {
      this.enableBreakdownDatePicker = value;
    }
  },
  computed: {
    hasMeetingTimezone() {
      return Boolean(this.timezone);
    },
    voteBreakdownIso() {
      if (this.enableBreakdownDatePicker) {
        return this.enableVoteBreakdownDate
          ? this.enableVoteBreakdownDate
          : this.expiryDate;
      } else {
        return null;
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped></style>
