<template>
  <div>
    <v-alert outline color="info" icon="info" :value="displayAlert">
      The Consent Template currently selected is
      <strong class="capitalize">{{ consentTemplateDisplayName }}</strong
      >.
      {{
        consentTemplateName !== 'custom' ? 'This template is not editable.' : ''
      }}
    </v-alert>

    <iframe class="preview-html" :srcdoc="htmlPreview" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingSettingsConsentOptionsInputPreview',
  components: {},
  props: {
    consentTemplateSelect: {
      type: String,
      default: ''
    },
    consentTemplateName: {
      type: String,
      default: ''
    },
    consentFormatSelect: {
      type: String,
      default: ''
    }
  },
  watch: {
    // Immediately run watcher on component creation
    consentTemplateSelect: {
      handler: 'updatePreview',
      immediate: true
    },
    consentFormatSelect: {
      handler: 'updatePreview',
      immediate: true
    }
  },
  computed: {
    ...mapGetters('meetings/consentTemplates', ['htmlPreview']),
    displayAlert() {
      return this.consentFormatSelect !== 'multi-consent';
    },
    consentTemplateDisplayName() {
      // Specifically for default consent format and default template name, display "Ontario"
      return this.consentFormatSelect === 'default' &&
        this.consentTemplateName === 'default'
        ? 'Ontario'
        : this.consentTemplateName;
    }
  },
  methods: {
    ...mapActions('meetings/consentTemplates', ['getConsentTemplatePreview']),
    async updatePreview() {
      try {
        const previewParams = {
          shortCode: this.$route.params.shortcode,
          template: this.consentTemplateSelect,
          consentFormat: this.consentFormatSelect
        };

        if (this.consentFormatSelect === 'multi-consent') {
          previewParams.noticeConsent = true;
          previewParams.votingConsent = true;
        }

        await this.getConsentTemplatePreview(previewParams);
      } catch (err) {
        this.$events.$emit(
          'showErrorDialog',
          err.response ? err.response : 'An unexpected error occurred'
        );
      }
    }
  }
};
</script>

<style scoped>
.preview-html {
  width: 100%;
  height: 70vh;
  max-height: 700px;
}
@media only screen and (max-width: 599px) {
  .preview-html {
    height: 40vh;
  }
}
</style>
