<template>
  <div>
    <v-layout row align-center justify-start fill-height class="my-3">
      <v-btn
        data-v-step="vbm-add-voting-matter-btn"
        color="primary"
        class="round-button"
        @click="editQuestion(undefined)"
      >
        <v-icon left>add</v-icon>New
      </v-btn>
      <v-alert
        :value="true"
        color="#0F64A9"
        icon="info"
        outline
        class="ml-3"
        style="border-radius: 4px; padding: 6px 12px;"
      >
        <span style="font-size: 12px;">
          Members will receive a link to their Meeting Portal in their
          invitations to access live voting. We recommend that Members keep
          their Meeting Portal open throughout the meeting as it will
          automatically refresh when you launch each vote. Feel free to resend
          their invite if they've lost it.
        </span>
      </v-alert>
    </v-layout>

    <!-- Data table -->
    <v-data-table
      id="vbm-voting-matter-table"
      class="elevation-2 round-card mb-5"
      :headers="headers"
      :items="questions"
      :loading="loading"
      hide-actions
    >
      <template slot="items" slot-scope="props">
        <tr :key="props.item.id" data-v-step="vbm-voting-matter-table-row">
          <td class="pointer label-cell py-2" @click="editQuestion(props.item)">
            <span>{{ props.item.label }}</span>
          </td>
          <td class="pointer text-center" @click="editQuestion(props.item)">
            <status-chip
              :startTime="props.item.startAt"
              :endTime="props.item.endAt"
              :timeLeft="props.item.timeLeft"
            />
          </td>
          <td class="pointer" @click="editQuestion(props.item)">
            <v-icon class="px-1" color="primary">how_to_vote</v-icon>
            <b>{{ totalBallotsCast(props.item) }}</b> Ballots cast
          </td>
          <td>
            <live-vote-button
              :question="props.item"
              @start="openDialog('start', props.item)"
              @stop="openDialog('stop', props.item)"
              @closed="$emit('refresh')"
            />
          </td>
          <td>
            <!-- Add div for data-v-step to avoid disabled button box-shadow issues -->
            <div data-v-step="voting-matter-timer-btn" class="text-xs-center">
              <v-btn
                :outline="!props.item.active"
                icon
                :disabled="!props.item.active"
                color="warning"
                @click="openDialog('extend', props.item)"
              >
                <v-icon>alarm_add</v-icon>
              </v-btn>
            </div>
          </td>
          <td class="text-xs-right">
            <v-btn
              data-v-step="vbm-voting-matter-results-btn"
              round
              outline
              color="primary"
              @click="props.expanded = !props.expanded"
            >
              {{ props.expanded ? 'HIDE' : 'SEE' }} RESULTS
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:expand="props">
        <business-details :question="props.item" />
      </template>
    </v-data-table>

    <!-- Dialogs -->

    <!-- Add / Edit voting matter -->
    <edit-question-dialog
      :is-open="dialog.edit"
      :value="question"
      :action="dialog.action"
      @cancel="dialog.edit = false"
      @save="handleCreateQuestion"
      @update="handleSaveQuestion"
      @delete="handleDeleteQuestion"
    />

    <!-- Start / Extend Dialog -->
    <add-time-dialog
      v-if="question"
      v-model="dialog.add"
      :action="dialog.action"
      :question="question"
      :current-meeting="currentMeeting"
      @start="openVoting"
      @extend="extendVoting"
      @close="dialog.add = false"
    />

    <!-- Close Dialog -->
    <close-vote-dialog
      v-if="question"
      v-model="dialog.stop"
      :question="question"
      @stop="closeVoting"
      @close="dialog.stop = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import StatusChip from './components/MeetingVbmStatusChip.vue';
import EditQuestionDialog from './components/MeetingVbmEditQuestionDialog.vue';
import BusinessDetails from './components/MeetingVbmBusinessDetails.vue';
import LiveVoteButton from '@/components/LiveVoteButton';
import CloseVoteDialog from '@/components/LiveVoteCloseDialog';
import AddTimeDialog from '@/components/LiveVoteAddTimeDialog';

export default {
  name: 'VotingMatters',
  components: {
    StatusChip,
    EditQuestionDialog,
    BusinessDetails,
    LiveVoteButton,
    CloseVoteDialog,
    AddTimeDialog
  },
  props: {
    questions: {
      type: Array,
      default() {
        return [];
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    tourActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      loading: false,
      question: {},
      refreshInterval: 0,
      interval: null,
      dialog: {
        add: false,
        action: '',
        stop: false,
        edit: false
      },
      headers: [
        {
          text: 'Question',
          align: 'left',
          value: 'name',
          sortable: false
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false
        },
        {
          text: 'Ballots cast',
          value: 'ballots',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
        {
          text: '',
          sortable: false,
          align: 'right'
        }
      ]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/rtvQuestions', [
      'getMeetingRTVQuestions',
      'createMeetingRTVQuestion',
      'updateMeetingRTVQuestion',
      'deleteMeetingRTVQuestion',
      'openVotingRTVQuestion',
      'closeVotingRTVQuestion',
      'extendVotingRTVQuestion'
    ]),
    async init() {
      try {
        this.loading = true;
        await this.getMeetingRTVQuestions({ shortCode: this.shortCode });
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    totalBallotsCast(question) {
      const tallyTotal = question.tally.reduce((sum, tally) => {
        const totalVotes = isNaN(tally.numVotes) ? 0 : tally.numVotes;
        return sum + totalVotes;
      }, 0);

      return _.round(tallyTotal, 2);
    },
    editQuestion(val) {
      this.dialog.action = !val ? 'add' : 'edit';
      this.question = val;
      this.dialog.edit = true;
    },
    openDialog(dialogType, val) {
      this.question = val;

      switch (dialogType) {
        case 'start':
          this.dialog.action = 'start';
          this.dialog.add = true;
          break;
        case 'extend':
          this.dialog.action = 'extend';
          this.dialog.add = true;
          break;
        case 'stop':
          this.dialog.stop = true;
          break;
        default:
          console.log(`Dialog type ${dialogType} not recognized`);
      }
    },
    async openVoting(question, votingPeriod, notifyVoters) {
      try {
        question.actionLogTag = 'start';

        // Refresh vote data on every minute
        this.refreshInterval = votingPeriod;
        this.interval = setInterval(() => {
          this.getMeetingRTVQuestions({ shortCode: this.shortCode });
          --this.refreshInterval;
          if (this.refreshInterval === 0) {
            clearInterval(this.interval);
          }
        }, 60000);

        await this.openVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question,
          deadlineType: 'minutes',
          deadline: votingPeriod,
          notifyVoters
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
        this.$events.$emit('toastEvent', 'Voting matter started');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async extendVoting(question, votingPeriod) {
      try {
        question.actionLogTag = 'extend';
        this.refreshInterval += votingPeriod;

        await this.extendVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question,
          time: votingPeriod
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
        this.$events.$emit('toastEvent', 'Voting matter extended');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async closeVoting(question) {
      try {
        question.actionLogTag = 'stop';
        question.active = false;

        await this.closeVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question
        });

        clearInterval(this.interval);

        this.dialog.stop = false;
        this.dialog.question = {};
        this.$events.$emit('toastEvent', 'Voting matter stopped');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleCreateQuestion(payload) {
      try {
        this.loading = true;

        const questionPayload = {
          label: payload.label,
          choices: payload.choices,
          maxChoices: payload.maxChoices,
          startAt: null,
          endAt: null,
          meta: {},
          ownerOccupied: false
        };

        await this.createMeetingRTVQuestion({
          shortCode: this.shortCode,
          question: questionPayload
        });
        // Emit to parent component
        //this.$emit('save', payload);
        this.dialog.edit = false;
        this.$events.$emit('toastEvent', 'Successfully created');
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async handleSaveQuestion(payload) {
      try {
        this.loading = true;

        const questionPayload = {
          label: payload.label,
          choices: payload.choices,
          maxChoices: payload.maxChoices,
          startAt: null,
          endAt: null,
          meta: {},
          ownerOccupied: false
        };

        await this.updateMeetingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: payload.key,
          question: questionPayload
        });
        // Emit to parent component
        //this.$emit('save', payload);
        this.dialog.edit = false;
        this.$events.$emit('toastEvent', 'Saved');
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteQuestion(questionKey) {
      try {
        this.loading = true;

        await this.deleteMeetingRTVQuestion({
          shortCode: this.shortCode,
          questionKey
        });
        this.dialog.edit = false;
        this.$events.$emit('toastEvent', 'Successfully deleted');
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.fade {
  opacity: 0.74;
}
.text-center {
  text-align: center;
}
.label-cell {
  width: 350px;
}
.round-card {
  border-radius: 7px;
}
</style>

<style>
#vbm-voting-matter-table > div.v-table__overflow {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
</style>
