<template>
  <v-dialog max-width="700px" :value="value" @input="emitInput" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Send Notification</span>
      </v-card-title>
      <v-card-text>
        <div>
          <div class="subject">
            <strong>Send to:</strong> {{ contactEmail }}
          </div>
          <rich-text-editor
            v-if="bodyLoaded"
            :body="notificationTemplate"
            @update="handleUpdate('notificationTemplate', $event)"
            :hide-merge-menu="true"
          ></rich-text-editor>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Cancel</v-btn>
        <v-btn class="primary" @click.native="emitSend()">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { getRecipe } from '@/api/recipes';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

export default {
  name: 'RecordingsNotificationDialog',
  components: {
    RichTextEditor
  },
  props: {
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contactEmail() {
      return _.get(this.currentMeeting, ['contact', 'email']);
    }
  },
  mounted() {
    this.getNotificationTemplate();
  },
  data() {
    return {
      notificationTemplate: '',
      bodyLoaded: false,
      editor: null
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSend() {
      this.$emit('send', this.notificationTemplate);
    },
    handleUpdate(key, value) {
      this.$set(this, key, value);
    },
    async getNotificationTemplate() {
      try {
        const recipe = await getRecipe('recordings.notifications.templates');
        const template = recipe.data.data;

        let baseurl =
          process.env.NODE_ENV === 'production'
            ? `https://dashboard.getquorum.com`
            : 'http://localhost:8080';

        let link = `${baseurl}/meetings/${this.currentMeeting.shortCode}/files`;

        const contact = _.get(this.currentMeeting, 'contact');

        this.notificationTemplate = template.default
          .replaceAll('{{{campaignName}}}', this.currentMeeting.name)
          .replaceAll('{{{link}}}', link)
          .replaceAll(
            '{{{contactPreferredName}}}',
            contact.preferredName ? contact.preferredName : contact.name
          );
      } catch (err) {
        console.error('Error getting the template', err);
        this.notificationTemplate = '';
      }
      this.bodyLoaded = true;
    }
  }
};
</script>

<style scoped>
.html-preview {
  display: block;
  padding: 10px;
  border: none;
  border: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 10px;
  font-family: 'Monaco', courier, monospace;

  overflow-y: scroll;
  max-height: 28vh;
}
.subject {
  background-color: #eceff1;
  color: #0d47a1;
  font-size: 12px;
  padding: 9px;
  margin-bottom: 10px;
}
.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
