<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Corporation and Contact Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Configuration settings relating to the client
    </div>

    <!-- Legal Name -->
    <v-layout>
      <v-flex xs6>
        <v-text-field
          label="Legal Name"
          :value="corporation.legalName"
          :rules="[v => v.length <= 255 || 'Max 255 characters']"
          :counter="255"
          @input="onChange('corporation.legalName', $event)"
        />
      </v-flex>
      <v-flex xs6 class="ml-3">
        <v-text-field
          label="Legal Short Name"
          :value="corporation.legalNameShort"
          :rules="[v => v.length <= 255 || 'Max 255 characters']"
          :counter="255"
          @input="onChange('corporation.legalNameShort', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs6>
        <v-text-field
          label="Primary Contact Name"
          hint="Default 'From' name in notice emails"
          persistent-hint
          :value="contact.name"
          :rules="[v => v.length <= 255 || 'Max 255 characters']"
          :counter="255"
          @input="onChange('contact.name', $event)"
        />
      </v-flex>
      <v-flex xs6 class="ml-3">
        <v-text-field
          label="Preferred Name"
          :value="contact.preferredName"
          @input="onChange('contact.preferredName', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs6 class="mt-4">
        <v-text-field
          label="Primary Contact Email"
          :value="contact.email"
          @input="onChange('contact.email', $event)"
        />
      </v-flex>

      <v-flex xs6 class="mt-4">
        <v-text-field label="Account Region" :value="accountRegion" disabled />
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout>
      <v-flex xs12>
        <div class="title grey--text text--darken-4 mb-1">Meeting Logo</div>
        <div class="body-1 grey--text text--darken-2">
          <input-link-add-images @uploaded="emitUpload" />
          <img
            v-if="uploadedImage"
            :src="uploadedImage"
            width="100"
            alt="Client Logo"
          />
          <v-tooltip right>
            <span slot="activator">
              <v-icon
                v-if="uploadedImage"
                color="red"
                small
                style="position:absolute"
                @click="removeImage"
                >close</v-icon
              >
            </span>
            <span>Remove Image</span>
          </v-tooltip>
        </div>
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <div class="title grey--text text--darken-4 mb-1">
      Notice Reply To Header
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Set the optional 'replyTo' email address here for certain types of notice
      emails.
    </div>

    <v-layout>
      <v-flex xs6>
        <v-text-field
          label="Reply To Email Address"
          hint="Uses primary contact email by default"
          :placeholder="contact.email"
          persistent-hint
          :value="replyTo"
          @input="onChange('options.replyToEmail', $event)"
        />
      </v-flex>
      <v-flex xs6></v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout>
      <v-flex xs12>
        <div class="title grey--text text--darken-4 mb-1">
          Notice Signature
        </div>
        <div class="body-1 grey--text text--darken-2">
          Used for
          <code>signature</code> merge tag in email notices
        </div>
        <rich-text-editor
          :body="signature"
          :hide-merge-menu="true"
          @update="onChange('options.signature', $event)"
        />
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout>
      <v-flex xs12>
        <div class="title grey--text text--darken-4 mb-1">
          Building Addresses
        </div>
        <div class="body-1 grey--text text--darken-2 mb-4">
          One address per line, or comma delimited. Provides address options on
          ownership page in proxy.
        </div>
        <div class="codemirror-container">
          <code-mirror
            :code-array="addresses"
            @update="onChange('addresses', $event)"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout>
      <v-flex xs12>
        <div class="title grey--text text--darken-4 mb-1">
          Notification Settings
        </div>
        <div class="body-1 grey--text text--darken-2 mb-4">
          Change email notification settings here.
        </div>

        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-layout>
              <div class="pr-3">
                <v-select
                  label="Select notification frequency to send"
                  :items="notificationFrequencyItems"
                  v-model="notificationFrequency"
                  item-text="label"
                  item-value="value"
                  solo
                  @change="onChange('options.notificationFrequency', $event)"
                />
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6 class="pl-3">
            <v-text-field
              label="Email address to send voting notifications"
              :hint="'Defaults to primary contact email if blank'"
              :placeholder="contact.email"
              persistent-hint
              :value="proxyNotification"
              :counter="100"
              :disabled="notificationFrequency === 'noEmail'"
              @input="onChange('options.proxyNotificationEmail', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mt-4">
          <v-flex xs12 md6>
            <h5 class="subheading">
              Vote Confirmation Email Template (Advanced)
            </h5>
            <p class="grey--text text--darken-2">
              Set the template used for the confirmation email sent to voters on
              vote submission. By default, it uses the "proxy" template.
            </p>
          </v-flex>
          <v-flex xs12 md6 class="pl-3">
            <v-select
              label="Select voter notification template"
              :items="notificationTemplateItems"
              v-model="notificationTemplate"
              item-text="label"
              item-value="value"
              solo
              @change="onChange('options.notificationTemplate', $event)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';

import CodeMirror from '@/components/CodeMirror';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import InputLinkAddImages from '@/components/input/InputLinkAddImages';

export default {
  name: 'MeetingSettingsCondoInput',
  components: {
    CodeMirror,
    RichTextEditor,
    InputLinkAddImages
  },
  props: {
    corporation: {
      type: Object,
      default() {
        return {};
      }
    },
    logoUrl: {
      type: String,
      default: ''
    },
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    addresses: {
      type: Array,
      default() {
        return [];
      }
    },
    signature: {
      type: String,
      default: ''
    },
    proxyNotification: {
      type: String,
      default: ''
    },
    replyTo: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      required: true
    },
    region: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      notificationsEnabled: true,
      notificationTemplate: 'owner',
      uploadedImage: this.logoUrl,
      notificationTemplateItems: [
        { label: 'Proxy (Default)', value: 'owner' },
        { label: 'E-Vote / Amendment', value: 'ownerSimple' }
      ],
      notificationFrequency: 'dailySummary',
      notificationFrequencyItems: [
        { label: 'Daily Summary (Default)', value: 'dailySummary' },
        { label: 'On Every Submission', value: 'everySubmission' },
        { label: 'No Email', value: 'noEmail' }
      ],
      regions: {
        AB: 'Alberta',
        BC: 'British Columbia',
        Caribbean: 'Caribbean',
        FL: 'Florida',
        IL: 'Illinois',
        ON: 'Ontario',
        NC: 'North Carolina',
        'other-can': 'Other CAN',
        'other-usa': 'Other USA'
      }
    };
  },
  watch: {
    options(newOptions) {
      this.setState(newOptions);
    }
  },
  mounted() {
    this.setState(this.options);
  },
  computed: {
    accountRegion() {
      return this.regions[this.region] || 'n/a';
    }
  },
  methods: {
    setState(currentOptions) {
      const {
        notificationFrequency = 'dailySummary',
        notificationTemplate = 'owner'
      } = currentOptions;

      this.notificationFrequency = notificationFrequency;
      this.notificationTemplate = notificationTemplate;
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    emitUpload(res) {
      this.uploadedImage = res[0];
      this.onChange('logoUrl', this.uploadedImage);
    },
    removeImage() {
      this.uploadedImage = undefined;
      this.onChange('logoUrl', this.uploadedImage);
    }
  }
};
</script>

<style lang="scss" scoped></style>
