<template>
  <div>
    <v-card fill-height>
      <v-menu v-if="isAdmin" bottom left class="menu">
        <v-btn slot="activator" icon> <v-icon>more_vert</v-icon> </v-btn>

        <v-list class="ma-0 pa-0">
          <v-list-tile
            class="ma-0 pa-0"
            avatar
            small
            :disabled="processing || stats.totalProxies <= 0"
            @click="handleDownload('individual');"
          >
            <v-list-tile-avatar> <v-icon>save_alt</v-icon> </v-list-tile-avatar>

            <v-list-tile-content class="body-1"
              >Export Individually (admins only)</v-list-tile-content
            >
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-container align-center>
        <div class="center">
          <v-icon color="light-blue" dark class="icon-size pr-2"
            >description</v-icon
          >
          <div class="center">
            <div class="number">{{ stats.totalProxies }}</div>
            <div class="stat-label">Proxies Submitted</div>
          </div>
        </div>

        <div v-if="stats.unverifiedProxyCount > 0" class="center">
          <div class="unverified-text" @click="dialog.unverified = true;">
            <v-icon class="pr-2" color="error" small>error</v-icon>
            <div>
              <span class="body-2">{{ stats.unverifiedProxyCount }}</span>
              proxies require additional verification <br />(
              <span class="unverified-help">what does this mean</span>? )
            </div>
          </div>
        </div>
      </v-container>

      <v-divider />

      <v-card-actions class="center">
        <v-btn
          :loading="processing"
          :disabled="processing || stats.totalProxies <= 0"
          @click.native="handleDownload('merged');"
          color="primary"
          class="white--text"
          >Download Proxies</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog.unverified" max-width="500px">
      <v-card>
        <v-card-text>
          <v-alert outline color="error" icon="error" :value="true">
            <h3>
              {{ stats.unverifiedProxyCount }} of the submitted proxies are
              unverified.
            </h3>
            <p>
              These proxies will be downloaded in a separate folder and should
              be reviewed to confirm they are valid.
            </p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" flat @click.native="dialog.unverified = false;"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { postExportProxies } from '@/api/meetings-exports';

export default {
  name: 'MeetingProxyStatCard',
  components: {},
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      }
    },
    quorumMinimum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      processing: false,
      dialog: {
        unverified: false,
        download: false
      }
    };
  },
  computed: {
    ...mapGetters(['login', 'isAdmin'])
  },
  methods: {
    handleDownload(type) {
      this.$emit('export-clicked', { type: 'proxies', mode: type });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stat-label {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  width: 100px;
  position: relative;
  top: 20px;
}
.number {
  padding-right: 15px;
  font-size: 55px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}
.center {
  display: flex;
  justify-content: center;
}
.unverified-text {
  display: flex;
  font-size: 12px;
  width: 255px;
  color: rgba(0, 0, 0, 0.6);
}
.icon-size {
  position: relative;
  top: 2px;
  font-size: 45px;
}
.unverified-help {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: pointer;
  color: #757575;
}
.menu {
  position: absolute;
  right: 0px;
}
</style>
