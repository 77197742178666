<template>
  <v-card>
    <v-card-title>
      <v-layout justify-center>
        <div class="subheading">What's your next step?</div>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs6>
          <v-card 
            flat
            hover
            @click.native="emitGoNextPage('editAccount')">
            <div class="column-box">
              <v-icon 
                color="blue"
                x-large>account_box</v-icon>
              <div>Go to accounts page</div>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs6>
          <v-card 
            flat          
            hover
            @click.native="emitGoNextPage('addMeeting')">
            <div class="column-box">
              <v-icon 
                color="blue"
                x-large>event</v-icon>
              <div>Create a meeting</div>            
            </div>  
          </v-card>        
        </v-flex>         
      </v-layout>
    </v-card-text>      
  </v-card>
</template>

<script>
export default {
  name: 'AddAccountDoneDialog',
  methods: {
    emitGoNextPage(value) {
      this.$emit('nextPage', value);
    }
  }
};
</script>

<style scoped>
.column-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
</style>