<template>
  <v-container grid-list-lg fluid>
    <!-- Proxy Holder summary table -->
    <div class="mb-5">
      <proxy-holder-summary
        :proxy-holders="meetingProxyHoldersList"
        :options="currentMeeting.options"
        :timezone="meetingTimezone"
      />
    </div>

    <!-- Proxies table -->
    <div class="gq-cell--section-title">Proxies</div>
    <div class="table-subtitle">
      <div class="gq-cell--section-subheading mb-2">
        Expand a row to see proxy details and perform actions (view PDF, revoke,
        and/or verify).
      </div>
    </div>

    <v-toolbar color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />

      <v-btn
        color="primary"
        :disabled="meetingProxies.length === 0"
        @click.native="exportClicked"
        >Export CSV</v-btn
      >
      <router-link :to="{ name: 'meetingProxiesCreate' }">
        <v-btn v-if="showAddProxyBtn" color="primary">Add Proxy</v-btn>
      </router-link>
      <v-btn color="primary" @click="filterDrawer = !filterDrawer">
        <v-icon class="mr-1">filter_list</v-icon>Filters
      </v-btn>
      <template v-slot:extension v-if="allFilters.length > 0">
        <v-chip
          dark
          outline
          color="blue"
          v-for="filter in allFilters"
          :key="filter"
          close
          @input="closeChip(filter)"
        >
          {{ labelByFilter(filter) }}
        </v-chip>
      </template>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="meetingProxies"
      :search="search"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr @click="expandProxyRow(props)">
          <td class="text-xs-left">
            {{ formatDateReadable(props.item.createdAt) }}
          </td>
          <td class="text-xs-left">{{ props.item.unit }}</td>
          <td class="text-xs-left">{{ props.item.address }}</td>
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.email }}</td>
          <td class="text-xs-left">
            <v-chip
              small
              label
              outline
              :color="setTagStatusType(props.item.status)"
              >{{ props.item.status }}</v-chip
            >
            <v-chip
              color="blue"
              small
              label
              outline
              v-if="props.item.source === 'admin'"
              >admin</v-chip
            >
            <v-chip
              color="purple"
              small
              label
              outline
              v-if="props.item.source === 'user'"
              >user</v-chip
            >
          </td>
        </tr>
      </template>
      <template slot="expand" slot-scope="props">
        <v-card flat>
          <v-card-text>
            <v-layout row="false">
              <v-flex>
                <p>
                  <b>Unit / Address</b>
                  : {{ props.item.unit }} - {{ props.item.address }}
                </p>
                <!-- If the proxy was manually submitted through the dashboard, use the source name and email -->
                <p>
                  <b>Submitted By:</b>
                  <span v-if="props.item.source !== 'voter'">
                    {{ props.item.metaData.source.name }} &lt;{{
                      props.item.metaData.source.email
                    }}&gt;
                    <v-chip
                      color="blue"
                      disabled
                      small
                      label
                      outline
                      v-if="props.item.source !== 'voter'"
                      >Created via dashboard</v-chip
                    >
                  </span>
                  <span v-else>
                    {{ props.item.name }} &lt;{{ props.item.email }}&gt;
                  </span>
                </p>
                <p>
                  <b>Date of Submission:</b>
                  {{ formatDateReadable(props.item.createdAt) }}
                </p>
                <p>
                  <b>Name of Proxy Holder:</b>
                  {{ props.item.holderName }}
                </p>

                <p v-if="props.item.backupHolderName">
                  <b>Name of Backup Proxy Holder:</b>
                  {{ props.item.backupHolderName }}
                </p>
                <p>
                  <b>Opt-in to Email:</b>
                  {{ props.item.emailOptIn }}
                </p>
                <p>
                  <b>IP Address:</b>
                  {{ props.item.ipAddress }}
                </p>
                <p v-if="props.item.status === 'verified'">
                  <b>Verified:</b>
                  {{ formatDateReadable(props.item.verifiedOn) }}
                  <b>by</b>
                  {{ props.item.verifiedBy }}
                </p>
                <p v-if="props.item.status === 'revoked'">
                  <b>Revoked:</b>
                  {{ formatDateReadable(props.item.revokedOn) }}
                </p>
              </v-flex>
              <v-flex class="answers-list">
                <div
                  v-for="question in proxyQuestions"
                  :key="question.question_id"
                >
                  <p class="caption mb-1">
                    <b>{{ question.question }}</b>
                  </p>
                  <p class="pl-3">{{ question.answers.join(', ') }}</p>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pl-3">
            <view-pdf-button :url="props.item.fileUrl" />
            <v-btn
              v-if="isAdmin"
              depressed
              small
              :disabled="props.item.status !== 'unverified'"
              class="primary"
              @click="verifyProxy(props.item.key)"
              >Verify</v-btn
            >
            <v-btn
              depressed
              small
              :disabled="props.item.status === 'revoked'"
              class="error"
              @click="revokeProxy(props.item.key)"
              >Revoke</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-data-table>

    <v-navigation-drawer
      v-model="filterDrawer"
      fixed
      temporary
      right
      width="300"
    >
      <proxies-filter-drawer :is-bill="isBill106" @update="init" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/helpers';
import { downloadCSV, convertArrayOfObjectsToCSV } from '@/helpers';
import ViewPdfButton from '@/components/ViewPdfButton';
import ProxyHolderSummary from '@/components/ProxyHolderSummaryTable';
import ProxiesFilterDrawer from '@/components/ProxiesFilterDrawer';

export default {
  name: 'MeetingProxiesView',
  components: {
    ViewPdfButton,
    ProxyHolderSummary,
    ProxiesFilterDrawer
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      search: '',
      headers: [
        { text: 'Date', value: 'createdAt', align: 'left' },
        { text: 'Unit', value: 'unit', align: 'left' },
        { text: 'Address', value: 'address', align: 'left' },
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }]
      },
      dialog: false,
      filterDrawer: null
    };
  },
  computed: {
    ...mapGetters('meetings/proxies', [
      'meetingProxyListData',
      'meetingProxyHoldersList',
      'proxyQuestions',
      'filterStatuses',
      'filterSources',
      'filterBoxes',
      'allFilters',
      'labelByFilter',
      'STATUSES',
      'SOURCES',
      'BOXES'
    ]),
    ...mapGetters(['isAdmin', 'getScopeByAction']),
    meetingProxies() {
      return this.meetingProxyListData;
    },
    hasPermissions() {
      return this.isAdmin;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    showAddProxyBtn() {
      if (this.isAdmin) {
        return true;
      }

      return this.getScopeByAction(
        this.$route.params.shortcode,
        'meeting.proxies.create'
      );
    },
    isBill106() {
      return (
        this.currentMeeting.options &&
        this.currentMeeting.options.proxyTemplate === 'on-bill-106'
      );
    },
    isAlberta() {
      return this.currentMeeting.region === 'AB';
    }
  },
  created() {
    /*
      Typical usage of init() should be in the parent component (MeetingProxiesMain) when component is nested
      In this case, getMeetingProxyList() relies on local state data properties for filter parameters
    */
    this.init();
  },
  methods: {
    ...mapActions('meetings/proxies', [
      'getMeetingProxyList',
      'revokeMeetingProxy',
      'verifyMeetingProxy',
      'listMeetingProxyHolders',
      'getProxyAnswers',
      'clearFilter'
    ]),
    async init() {
      try {
        // Either include all options or only filtered option(s)
        const includeStatuses =
          this.filterStatuses.length === 0
            ? [...this.STATUSES]
            : [...this.filterStatuses];
        const includeSources =
          this.filterSources.length === 0
            ? [...this.SOURCES]
            : [...this.filterSources];
        const includeBoxes =
          this.filterBoxes.length === 0
            ? [...this.BOXES]
            : [...this.filterBoxes];

        await this.getMeetingProxyList({
          shortCode: this.shortCode,
          includeStatuses,
          includeSources,
          includeBoxes
        });

        await this.listMeetingProxyHolders({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    formatDateReadable(value) {
      switch (value) {
        case null:
          return '';
        default:
          return formatDate(value, this.meetingTimezone, 'YYYY-MM-DD HH:mm zz');
      }
    },
    getDefaultHolder(proxyHolder) {
      // First, get "primary" default holder
      const primaryDefault = this.currentMeeting.defaultHolders[0];

      // Next, get "secondary" default holder (if one exists)
      const secondaryDefault =
        this.currentMeeting.defaultHolders.length > 1
          ? this.currentMeeting.defaultHolders[1]
          : null;

      // Case A:
      // Voter's chosen proxy holder is not the primary default;
      // Return the primary default
      if (proxyHolder !== primaryDefault) {
        return primaryDefault;
      }

      // Case B:
      // Voter's chosen holder is the primary default;
      // If a secondary fallback exists, return secondary fallback
      if (secondaryDefault) {
        return secondaryDefault;
      }

      // Case C:
      // Voter's chosen holder is the primary default;
      // No secondary default exists, so no default holder for this proxy record
      return null;
    },
    setTagStatusType(status) {
      switch (status) {
        case 'verified':
          return 'green';
        case 'revoked':
          return 'red';
        default:
          return 'orange';
      }
    },
    exportClicked() {
      // Clone so we don't mutate data
      let proxyArray = _.cloneDeep(this.meetingProxies);
      let formattedProxyData = this.formatExportData(proxyArray);
      let CSVData = convertArrayOfObjectsToCSV(formattedProxyData);
      downloadCSV(`${this.currentMeeting.shortCode} Proxy Data.csv`, CSVData);
    },
    formatExportData(proxyArray) {
      return proxyArray.map(proxy => {
        let newProxyobj = {
          unit: proxy.unit,
          address: proxy.address,
          name: proxy.name,
          email: proxy.email,
          verifiedOn: this.formatDateReadable(proxy.verifiedOn),
          verifiedBy: proxy.verifiedBy,
          status: proxy.status,
          revokedOn: this.formatDateReadable(proxy.revokedOn),
          holderName: proxy.holderName,
          fallbackHolderName: proxy.backupHolderName,
          submittedDate: this.formatDateReadable(proxy.createdAt),
          receipt_code: proxy.metaData?.trackingCode,
          ipAddress: proxy.ipAddress,
          source: proxy.source,
          source_email: proxy.metaData?.source
            ? proxy.metaData.source.email
            : '',
          shares: proxy.shares,
          ineligible_reason: proxy.ineligibleReason,
          is_test_voter: proxy.isTestVoter,
          default_holder: this.getDefaultHolder(proxy.holderName)
        };
        // Only return additional proxy/unit info for Alberta campaigns
        return this.isAlberta
          ? newProxyobj
          : _.omit(newProxyobj, [
              'shares',
              'ineligible_reason',
              'is_test_voter',
              'default_holder'
            ]);
      });
    },
    async revokeProxy(proxyKey) {
      const isConfirm = confirm('Are you sure you want to revoke this proxy?');
      if (!isConfirm) {
        return;
      }

      try {
        await this.revokeMeetingProxy({ shortCode: this.shortCode, proxyKey });
        return this.$events.$emit('toastEvent', 'Proxy Revoked');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async verifyProxy(proxyKey) {
      try {
        await this.verifyMeetingProxy({ shortCode: this.shortCode, proxyKey });
        return this.$events.$emit('toastEvent', 'Proxy Verified');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    expandProxyRow(props) {
      props.expanded = !props.expanded;
      this.getProxyAnswers({
        shortCode: this.shortCode,
        proxyKey: props.item.key
      });
    },
    closeChip(filterName) {
      this.clearFilter({ filterName });
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';
.text-highlight {
  color: #e91e63;
}

.answers-list {
  flex-grow: 3;
}

.table-subtitle {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .table-subtitle {
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
