<template>
  <v-dialog max-width="700px" persistent :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Edit Ballot</span>
        <v-spacer />
        <v-btn icon flat @click.stop="emitClose"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <div class="ballot-status">
        <v-chip v-if="hasBallot" label class="green-alert elevation-0">
          <v-avatar>
            <v-icon color="green darken-3">check_circle</v-icon>
          </v-avatar>
          <span>{{ getStatus }}</span>
        </v-chip>
        <v-chip
          v-else-if="hasClaimRequest"
          label
          class="yellow-alert elevation-0"
        >
          <v-avatar>
            <v-icon color="amber darken-3">warning</v-icon>
          </v-avatar>
          <span>{{ getStatus }}</span>
        </v-chip>
        <v-chip v-else label class="red-alert elevation-0">
          <v-avatar>
            <v-icon color="error">error</v-icon>
          </v-avatar>
          <span>{{ getStatus }}</span>
        </v-chip>
      </div>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field label="Email" v-model="state.ballot.email" readonly />
          </v-flex>
          <v-flex v-if="hasBallot" xs12>
            <v-text-field label="Name" v-model="state.ballot.name" readonly />
          </v-flex>
          <v-flex v-if="hasClaimRequest" xs10>
            <v-text-field
              label="Voting Url"
              id="thisBallotUrl"
              :value="this.ballotLink"
              readonly
            />
          </v-flex>
          <v-flex v-if="hasClaimRequest" xs2
            ><v-btn flat @click="handleCopy()">
              <v-icon>file_copy</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <div v-if="hasBallot">
        <v-card-title>
          <span class="subtitle">&nbsp;</span>
        </v-card-title>
        <v-container class="pt-0">
          <v-layout row wrap justify-start>
            <v-card
              v-for="unit in allUnits"
              :key="unit.unitKey"
              class="mb-2 mr-2"
            >
              <v-list class="pa-0" outlined>
                <v-card-title class="unit-top">
                  <span class="unit-title">UNIT</span>
                  <v-spacer />
                  <v-btn
                    v-if="unit.status === 'AVAILABLE'"
                    icon
                    class="ma-0"
                    @click.native="confirmAdd(unit.unitKey)"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="unit.status === 'CLAIMED'"
                    icon
                    class="ma-0"
                    @click.native="confirmRemove(unit.unitKey)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-title>
                <div class="unit-number">
                  {{ unit.unit }}
                </div>

                <div :class="unit.statusCard">
                  <span
                    class="overline font-weight"
                    :class="unit.statusTextColor"
                  >
                    {{ unit.status }} </span
                  ><br />
                  <span class="caption">
                    {{ unit.statusNote }}
                  </span>
                </div>
              </v-list>
            </v-card>
          </v-layout>
        </v-container>
        <v-divider />
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn
          outline
          class="ma-2"
          color="primary lighten-1"
          @click.native="dialog.send = true"
        >
          <v-icon>email</v-icon>Send Ballot
        </v-btn>
        <v-btn
          v-if="hasBallot"
          flat
          class="accent"
          @click.native="dialog.reset = true"
        >
          <v-icon>refresh</v-icon>Reset Ballot
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Send Ballot Dialog -->
    <v-dialog v-model="dialog.send" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text>Click 'Send' to confirm.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.send = false">close</v-btn>
          <v-btn class="primary" @click.native="emitSendBallot">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Unit Dialog -->
    <v-dialog v-model="dialog.add" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text>Click 'Claim Unit' to confirm.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.add = false">close</v-btn>
          <v-btn class="primary" @click.native="emitAddUnit">Claim Unit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remove Unit Dialog -->
    <v-dialog v-model="dialog.remove" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text v-if="claimedUnits.length == 1"
          >If this unit claim is removed, the voter's ballot will be
          reset.</v-card-text
        >
        <v-card-text>Click 'Unclaim Unit' to confirm.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.remove = false">close</v-btn>
          <v-btn class="primary" @click.native="emitRemoveUnit"
            >Unclaim Unit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reset Dialog -->
    <v-dialog v-model="dialog.reset" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text>Click 'Reset Ballot' to confirm.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.reset = false">close</v-btn>
          <v-btn class="primary" @click.native="emitDelete">Reset Ballot</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MeetingRtvBallotEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    ballot: {
      type: Object,
      default() {
        return {};
      }
    },
    ballotLink: {
      type: String,
      default: null
    },
    ballotUnitListData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      state: {
        ballot: {
          name: '',
          email: '',
          ballotUrl: '',
          claimRequestKey: ''
        }
      },
      menu: false,
      targetUnitKey: '',
      dialog: {
        send: false,
        add: false,
        remove: false,
        reset: false
      }
    };
  },

  watch: {
    ballot: function(value) {
      this.init(value);
    }
  },
  computed: {
    hasBallot() {
      if (this.state.ballot.ballotKey) {
        return true;
      }
      return false;
    },
    hasClaimRequest() {
      if (this.state.ballot.claimRequestKey) {
        return true;
      }
      return false;
    },
    getStatus() {
      if (this.state.ballot.ballotKey) {
        return 'Ballot Issued';
      } else if (this.state.ballot.claimRequestKey) {
        return 'Waiting for voter to confirm units';
      } else {
        return 'Not registered';
      }
    },
    claimedUnits() {
      const filteredClaimed = this.ballotUnitListData;
      return _.filter(filteredClaimed, function(unit) {
        return unit.ballotKey === unit.unitClaimBallotKey;
      });
    },
    allUnits() {
      let units = [...this.ballotUnitListData];

      units.forEach(function(row) {
        if (row.unitClaimBallotKey === null) {
          row.status = 'AVAILABLE';
          row.statusCard = 'unit-status-available';
          row.statusTextColor = 'blue--text';
          row.statusNote = 'Can be claimed';
        } else {
          row.status =
            row.unitClaimBallotKey === row.ballotKey
              ? 'CLAIMED'
              : 'UNAVAILABLE';
          row.statusTextColor =
            row.unitClaimBallotKey === row.ballotKey
              ? 'green--text'
              : 'red--text';
          row.statusCard =
            row.unitClaimBallotKey === row.ballotKey
              ? 'unit-status-claimed'
              : 'unit-status-unavailable';
          row.statusNote =
            row.unitClaimBallotKey === row.ballotKey
              ? ''
              : 'Claimed by co-owner';
        }
      });
      return units;
    }
  },
  methods: {
    init(value) {
      this.state.ballot = _.cloneDeep(value);
    },
    getUnitKeyValue(unit, key) {
      return unit[key];
    },
    emitInput() {
      this.$emit('input');
    },
    emitSendBallot() {
      this.$emit('sendBallot', [{ ...this.ballot }]);
      this.$emit('close');
    },
    emitAddUnit() {
      this.dialog.add = false;
      const unitClaim = {
        ballotKey: this.state.ballot.ballotKey,
        email: this.state.ballot.email,
        unitKey: this.targetUnitKey
      };
      this.$emit('addUnit', unitClaim);
    },
    emitRemoveUnit() {
      this.dialog.remove = false;
      const unitClaim = {
        ballotKey: this.state.ballot.ballotKey,
        email: this.state.ballot.email,
        unitKey: this.targetUnitKey
      };
      this.$emit('removeUnit', unitClaim);
    },
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.dialog.reset = false;
      this.$emit('deleteBallot', { ballotKey: this.state.ballot.ballotKey });
      this.$emit('close');
    },
    confirmAdd(unitKey) {
      this.targetUnitKey = unitKey;
      this.dialog.add = true;
    },
    confirmRemove(unitKey) {
      this.targetUnitKey = unitKey;
      this.dialog.remove = true;
    },
    handleCopy() {
      var copyText = document.querySelector('#thisBallotUrl');
      copyText.select();
      document.execCommand('copy');
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 16px;
  padding-right: 20px;
}
.subtitle {
  font-size: 15px;
  padding-right: 20px;
}
.unit-top {
  padding: 0px;
  height: 35px;
}
.unit-title {
  font-size: 12px;
  padding-left: 8px;
  padding-top: 7px;
}
.unit-number {
  font-size: 19px;
  padding-left: 8px;
  padding-bottom: 6px;
}
.unit-status-available {
  padding: 8px;
  width: 140px;
  height: 60px;
  background-color: #e0e0e0;
}
.unit-status-claimed {
  padding: 8px;
  width: 140px;
  height: 60px;
  background-color: rgba(76, 175, 80, 0.12);
}
.unit-status-unavailable {
  padding: 8px;
  width: 140px;
  height: 60px;
  background-color: rgba(255, 82, 82, 0.12);
}
.url-input {
  min-width: 350px;
}
.ballot-status > .red-alert {
  width: 98%;
  background-color: rgba(255, 82, 82, 0.12);
}
.ballot-status > .yellow-alert {
  width: 98%;
  background-color: rgba(255, 152, 0, 0.12);
}
.ballot-status > .green-alert {
  width: 98%;
  background-color: rgba(76, 175, 80, 0.12);
}
</style>
