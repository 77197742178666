<template>
  <div class="text-xs-center">
    <v-dialog :value="dialog" :width="width" @input="handleClose">
      <v-card :class="{ 'round-card': roundDialog }">
        <v-card-title class="headline lighten-2 font-weight-bold" primary-title>
          {{ title }}
        </v-card-title>

        <v-card-text>{{ description }}</v-card-text>

        <v-card-text>
          <v-text-field
            v-model="value"
            label=""
            box
            placeholder="Type 'UNLINK' to confirm."
          />
        </v-card-text>

        <v-card-actions>
          <v-btn v-if="showDeclineBtn" flat text @click="handleCancel">
            {{ declineText }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            @click="handleConfirm"
            :disabled="!isValid"
          >
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmTextDialog',
  props: {
    width: {
      type: String,
      default: '500'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    declineText: {
      type: String,
      default: 'Cancel'
    },
    roundDialog: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    showDeclineBtn: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    };
  },
  computed: {
    isValid() {
      return this.value.toLowerCase() === 'unlink';
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm', true);
    },
    handleCancel() {
      this.$emit('confirm', false);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}
</style>
