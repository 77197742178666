<template>
  <div>
    <div class="mb-3 ml-2">
      <h3>Service Log</h3>
    </div>
    <div class="event-container">
      <v-timeline dense clipped>
        <v-timeline-item
          v-for="element in eventList"
          :key="element.id"
          :class="
            `${
              ['approved', 'denied'].includes(element.event)
                ? 'flex-column'
                : 'mb-3'
            }`
          "
          color="grey"
          icon-color="white"
          :icon="element.icon"
          :hide-dot="element.icon ? false : true"
          right
          fill-dot
          small
        >
          <v-layout align-center>
            <!-- event open -->
            <v-flex xs12 v-if="element.event === 'open'"
              ><span
                ><b>{{ element.createdBy }}</b> created this request on
                {{ element.createdAt | formatDate }}</span
              ></v-flex
            >
            <!-- event add reivewer -->
            <v-flex xs12 v-else-if="element.event === 'add-csm'"
              ><span>
                <b>{{ element.data.csm.name }}</b> was assigned to this request
                on {{ element.createdAt | formatDate }}</span
              ></v-flex
            >
            <!-- event edit request -->
            <v-flex xs12 v-else-if="element.event === 'edit'"
              ><span
                ><b>{{ element.createdBy }}</b> edited on
                {{ element.createdAt | formatDate }}</span
              >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outline
                    small
                    @click.native="viewChanges(element.data)"
                    >View</v-btn
                  >
                </template>
                <span>View changes</span>
              </v-tooltip>
            </v-flex>

            <!-- event approve with comment -->
            <v-flex
              xs12
              v-else-if="element.event === 'approved' && element.data.comment"
            >
              <div class="approve-event-container">
                <div class="approve px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="green">thumb_up_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> approved this request on
                    {{ element.createdAt | formatDate }}</span
                  >
                </div>
                <div class="approve-comment mt-2 mb-3">
                  <span>{{ element.data.comment }}</span>
                </div>
              </div></v-flex
            >
            <!-- event approve without comment-->
            <v-flex xs12 v-else-if="element.event === 'approved'">
              <div class="approve-event-container d-flex">
                <div class="approve px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="green">thumb_up_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> approved this request on
                    {{ element.createdAt | formatDate }}</span
                  >
                </div>
              </div></v-flex
            >

            <!-- event close with comment -->
            <v-flex
              xs12
              v-else-if="element.event === 'denied' && element.data.comment"
            >
              <div class="approve-event-container">
                <div class="close px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="red">do_not_disturb_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> denied this request on
                    {{ element.createdAt | formatDate }}</span
                  >
                </div>
                <div class="approve-comment mt-2 mb-3">
                  <span>{{ element.data.comment }}</span>
                </div>
              </div></v-flex
            >
            <!-- event close without comment-->
            <v-flex xs12 v-else-if="element.event === 'denied'">
              <div class="approve-event-container d-flex">
                <div class="close px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="red">do_not_disturb_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> denied this request on
                    {{ element.createdAt | formatDate }}</span
                  >
                </div>
              </div></v-flex
            >
          </v-layout>
        </v-timeline-item>
      </v-timeline>

      <v-dialog v-model="changeDialog" width="350px">
        <v-card :elevation="0">
          <div class="px-3 py-3">
            <p v-for="(value, key) in changeDetails" :key="key">
              <span class="font-weight-bold">{{ key }}</span
              >: {{ value }}
            </p>
          </div>

          <v-card-actions>
            <v-btn color="primary" block @click="changeDialog = false"
              >Close Dialog</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

const eventIcon = {
  'add-csm': 'person',
  open: 'article',
  edit: 'edit'
};

export default {
  name: 'ActivityFeed',
  props: {
    isApproved: {
      type: Boolean,
      default: false
    },
    isDenied: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    comment: '',
    fileUploaded: null,
    showFile: false,
    processing: false,
    changeDialog: false,
    changeDetails: null
  }),
  filters: {
    formatDate(date) {
      return moment(date).format('MMM, DD, YYYY hh:mm a zz');
    }
  },
  computed: {
    ...mapGetters(['login']),
    ...mapGetters(['isAdmin']),
    eventList() {
      return _.map(this.events, event => {
        return { ...event, icon: eventIcon[event.event] };
      }).reverse();
    }
  },
  methods: {
    historicalLink(link) {
      return `${link}?redirect=false`;
    },
    viewChanges(data) {
      let details = {
        ...data,
        ...data.options,
        ...data.meetingMeta
      };

      details = _.omit(details, ['options', 'meetingMeta']);

      const newObj = {};
      for (const [key, value] of Object.entries(details)) {
        const newKey = _.startCase(key);
        if (value === true) {
          newObj[newKey] = 'Yes';
        } else if (value === false) {
          newObj[newKey] = 'No';
        } else if (key === 'meetingDate' || key === 'expiryDate') {
          newObj[newKey] = this.$options.filters.formatDate(value);
        } else {
          newObj[newKey] = value;
        }
      }

      this.changeDetails = newObj;

      this.changeDialog = true;
    }
  }
};
</script>

<style scoped>
.flex-column {
  flex-direction: column !important;
}
.approve-event-container {
  background-color: white;
  border: 2px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}

.approve-comment {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  white-space: pre-wrap;
}

.approve {
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #e6f0e4;
}

.archive {
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #e3dfd4;
}

.close {
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #f0e4e4;
}

.event-container {
  display: flex;
  flex-direction: column-reverse;
}

.hidden-input {
  display: none;
}
</style>
