<template>
  <v-container grid-list-lg fluid>
    <v-layout row>
      <v-flex xs12>
        <p class="title">Campaigns</p>
        <p class="body-1 secondary--text">
          Access current, future, and past GetQuorum campaigns and their billing
          status. Defaults to last 30 days. Billed and ignored campaigns are
          hidden by default.
        </p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs6 md3 class="pr-2">
        <date-picker
          label="From"
          hide-icon
          box
          :iso-date="fromDate"
          @update="setFromDate"
          dateFormat="MMMM DD, YYYY"
        />
      </v-flex>
      <v-flex xs6 md3 class="pl-2">
        <date-picker
          label="To"
          hide-icon
          box
          :iso-date="toDate"
          @update="setToDate"
          dateFormat="MMMM DD, YYYY"
        />
      </v-flex>

      <v-flex xs6 md3 class="pl-2">
        <v-btn
          color="primary"
          outline
          :loading="loading"
          @click.native="getCampaignList"
          >Run</v-btn
        >
      </v-flex>
    </v-layout>

    <v-layout row align-center>
      <v-spacer />
      <div class="mr-2">
        <span>Subscriptions: </span>
        <v-chip small>{{ subFilter }}</v-chip>
      </div>
    </v-layout>

    <admin-campaigns-list />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DatePicker from '@/components/DatePicker';
import AdminCampaignsList from './AdminCampaignsViewList';

export default {
  name: 'AdminCampaignsView',
  components: {
    AdminCampaignsList,
    DatePicker
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('campaigns', [
      'fromDate',
      'toDate',
      'dataLastFetchedAt',
      'subFilter'
    ]),
    loading() {
      return !this.dataLastFetchedAt;
    }
  },
  methods: {
    ...mapActions('campaigns', ['getCampaignList', 'updateSearchDates']),
    setFromDate(fromDate) {
      this.updateSearchDates({ fromDate });
    },
    setToDate(toDate) {
      this.updateSearchDates({ toDate });
    }
  }
};
</script>

<style scoped></style>
