import http from './requestType';

/** Returns a list of business specific to the meeting including tallies
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingBusinessList(shortCode) {
  return http._get(`Meetings/${shortCode}/business`);
}

/**
 * Create a new business for this meeting. A business is an election for directors or a yes/no vote for a question.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {object} input - business object to post
 * @return {Promise} - Promise
 */
export function postMeetingBusiness(shortCode, input) {
  return http._post(`Meetings/${shortCode}/business`, input);
}

/**
 * Updates a meeting business. Be careful using if proxies have already been collected as it may change future proxies.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @param  {object} input - updated meeting business object
 * @return {Promise} - Promise
 */
export function putMeetingBusiness(shortCode, questionId, input) {
  return http._put(`Meetings/${shortCode}/business/${questionId}`, input);
}

/**
 * Deletes a Business from the Meeting. Any votes cast for this question from
 * previous proxies will still be present on the PDF proxy, however will no longer be tallied.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @param  {object} input - updated meeting business object
 * @return {Promise} - Promise
 */
export function deleteMeetingBusiness(shortCode, questionId) {
  return http._delete(`Meetings/${shortCode}/business/${questionId}`);
}

/**
 * Returns the specific business of the identified meeting along with the tallies.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @return {Promise} - Promise
 */
export function getMeetingBusiness(shortCode, questionId) {
  return http._get(`Meetings/${shortCode}/business/${questionId}`);
}

/**
 * Get Meeting Vote data for specified business
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @return {Promise} - Promise
 */
export function getMeetingBusinessData(shortCode, questionId) {
  return http._get(`Meetings/${shortCode}/business/${questionId}/data`);
}

/**
 * Get Meeting Vote data for specified business
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @return {Promise} - Promise
 */
export function getMeetingBusinessDataV2(shortCode, questionId) {
  return http._get(
    `Meetings/${shortCode}/business/${questionId}/combined-data`
  );
}

/**
 * Changes the order in which the questions for this meeting should be presented to users.
 * This is done by sending an array with the questionIds in the order they should be presented.
 * Eg. [101, 100, 102]. NOTE - you can do partial reorderings by excluding questionIds, but
 * this may lead to unintended reorders. Because of this do not recommend partial reordering.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {object} payload - array of re-ordered questions
 * @return {Promise} - Promise
 */
export function postMeetingBusinessOrder(shortCode, payload) {
  return http._post(
    `Meetings/${shortCode}/business/actions/change-order`,
    payload
  );
}

/**
 * Toggles a meeting business status to enabled/disabled. Business that is
 * disabled will not be asked on a proxy, but will still be displayed in the tallies.
 * This is useful for hiding unnecessary business when collecting additional proxies for adjourned meetings.
 * @param  {string} shortCode - shortcode
 * @param  {string} questionId - questionId
 * @param  {object} payload - array of re-ordered questions
 * @return {Promise} - Promise
 */
export function postMeetingBusinessStatus(shortCode, questionId, payload) {
  return http._post(
    `Meetings/${shortCode}/business/${questionId}/actions/toggle-status`,
    payload
  );
}

/**
 * Get custom restrictions for specified business
 * @param  {string} shortCode - the meeting shortcode
 * @param  {integer} questionId - the ID of the question
 * @return {Promise} - Promise
 */
export function getMeetingBusinessRestrictions(shortCode, questionId) {
  return http._get(`Meetings/${shortCode}/business/${questionId}/restrictions`);
}

/**
 * Creates a new custom restrictions for specified business
 * @param  {string} shortCode - shortcode
 * @param  {string} questionId - questionId
 * @param  {object} payload - array of re-ordered questions
 * @return {Promise} - Promise
 */
export function postMeetingBusinessRestriction(shortCode, questionId, payload) {
  return http._post(
    `Meetings/${shortCode}/business/${questionId}/restrictions`,
    payload
  );
}

/**
 * Deletes a custom restriction from specified business
 * @param  {string}  shortCode      - the meeting shortcode
 * @param  {integer} questionId     - the ID of the question
 * @param  {string}  customField    - restriction custom field
 * @param  {string}  fieldValue     - restriction value
 * @return {Promise} - Promise
 */
export function deleteMeetingBusinessRestriction(
  shortCode,
  questionId,
  customField,
  fieldValue
) {
  return http._delete(
    `Meetings/${shortCode}/business/${questionId}/restrictions/${customField}/${fieldValue}`
  );
}
