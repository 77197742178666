<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <file-upload-form
          :attachment="attachments"
          :multiple-files="true"
          icon="attach_file"
          @upload="emitUpload"
          :disabled="isDisabled"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import FileUploadForm from '@/components/FileUploadForm';

export default {
  name: 'MeetingConversationFileAttachments',
  props: {
    attachments: {
      type: Array,
      default() {
        return [];
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileUploadForm
  },
  computed: {
    isFileAttached() {
      return !_.isEmpty(this.attachments);
    }
  },
  methods: {
    emitUpload(file) {
      this.$emit('update', { key: 'attachments', value: file });
    }
  }
};
</script>

<style scoped></style>
