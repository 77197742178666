<template>
  <v-dialog persistent :value="value" max-width="950px">
    <v-card flat>
      <v-card-title class="grey lighten-2" primary-title>
        <span class="title">Validating Write-In Votes</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" dark v-on="on">info</v-icon>
          </template>
          <span
            >Listed below are proxy submissions where the voter has submitted
            write-in votes when asked. <br /><br />
            In these situations, we want to ensure that they are not submitting
            multiple votes for the same candidate.
            <br /><br />
            If you discern that the owner has multiple votes for the same
            candidate, i.e. "Joseph Green" and "Joseph Greene", we are looking
            to 'REVOKE' these proxies.</span
          >
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <span class="subtitle">
          {{ questionHeader }}
        </span>
        <p class="mt-3">
          Proxies with write-in votes matching:
          <strong>{{ writeInVote }}</strong>
        </p>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="currentProxyList"
        :sort-by="['unit']"
        :pagination.sync="pagination"
        :item-key="`table-key-${currentProxyList.key}`"
        hide-actions
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <!-- START: EXISTING - For options that include advance or live vote entries -->
          <tr>
            <td class="text-xs-left">{{ props.item.unit }}</td>
            <td class="text-xs-left">
              {{ props.item.name }}<br />{{ props.item.email }}
            </td>
            <td class="text-xs-left">
              <strong>{{
                !props.item.voteFull ? 'N/A' : props.item.voteFull.join(', ')
              }}</strong>
            </td>
            <td class="text-xs-left">
              <v-chip
                small
                label
                outline
                :color="setTagStatusType(props.item.status)"
                >{{ props.item.status }}</v-chip
              >
            </td>
            <td class="text-xs-center">
              <v-tooltip bottom :disabled="!!props.item.fileUrl">
                <v-btn
                  slot="activator"
                  class="ma-0"
                  flat
                  :disabled="!props.item.fileUrl"
                  @click="clickHandler(props.item.fileUrl)"
                >
                  <v-icon>topic</v-icon>
                </v-btn>
                <span>
                  No document supplied
                </span>
              </v-tooltip>
            </td>
            <td>
              <v-btn
                v-if="props.item.status !== 'revoked'"
                depressed
                small
                class="error"
                @click="revokeProxy(props.item.key)"
                >Revoke</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions class="pr-4">
        <v-spacer />
        <v-btn flat @click="emitClose">done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WriteInVoteDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    proxyList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      questionHeader: '',
      writeInVote: '',
      headers: [
        {
          text: 'Unit',
          value: 'unit',
          width: '10%'
        },
        {
          text: 'Voter',
          value: 'name'
        },
        {
          text: 'Voted',
          sortable: false,
          value: ''
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%'
        },
        {
          text: 'Download Proxy',
          sortable: false,
          align: 'center',
          width: '10%'
        },
        {
          text: '',
          sortable: false,
          align: 'right',
          width: '10%'
        }
      ],
      pagination: {
        sortBy: 'unit',
        descending: true,
        rowsPerPage: -1
      }
    };
  },
  watch: {
    proxyList: function() {
      this.setHeaders();
    }
  },

  computed: {
    currentProxyList() {
      return this.proxyList;
    }
  },
  methods: {
    setHeaders() {
      this.questionHeader = this.proxyList[0].questionHeader;
      this.writeInVote = this.proxyList[0].vote;
    },
    setTagStatusType(status) {
      switch (status) {
        case 'verified':
          return 'green';
        case 'revoked':
          return 'red';
        default:
          return 'orange';
      }
    },
    revokeProxy(proxyKey) {
      const isConfirm = confirm('Are you sure you want to revoke this proxy?');
      if (!isConfirm) {
        return;
      }
      this.$emit('revoke-proxy', { proxyKey });
    },
    clickHandler(proxyUrl) {
      // Open proxy in a new window
      return window.open(proxyUrl, '_blank');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 16px;
  padding-right: 20px;
}
.subtitle {
  font-size: 15px;
  padding-right: 20px;
}
</style>
