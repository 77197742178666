<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Checklist Templates</p>
        <p class="secondary--text">
          Manage common checklist templates used throughout campaigns.
        </p>
      </v-flex>
    </v-layout>

    <v-layout class="ml-3 mr-3 mb-3" row>
      <v-flex xs12>
        <admin-checklist-table
          :state="checklistTemplatesListData"
          @open-add-new-dialog="dialog.newChecklist = true"
          @row-clicked="goChecklistTemplate"
        />
      </v-flex>
    </v-layout>

    <!-- Dialogs -->
    <v-dialog v-model="dialog.newChecklist" max-width="300px">
      <v-card>
        <v-card-title class="title">Create New Checklist</v-card-title>
        <v-card-text>
          <v-text-field
            label="Name of Checklist"
            v-model="newChecklist.name"
            placeholder="eg. AGM/SGM Fulfilment Checklist"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.newChecklist = false">Cancel</v-btn>
          <v-btn class="primary" @click.native="addNewChecklistClicked"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdminChecklistTable from './AdminChecklistTemplatesTable';

export default {
  name: 'AdminChecklistTemplatesListView',
  components: {
    AdminChecklistTable
  },
  props: {
    checklistTemplatesList: {
      type: Object,
      required: true
    },
    checklistTemplatesData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  data() {
    return {
      state: {},
      newChecklist: {
        name: ''
      },
      dialog: {
        newChecklist: false
      }
    };
  },
  computed: {
    ...mapGetters('checklistTemplates', ['checklistTemplatesListData'])
  },
  created() {},
  methods: {
    ...mapActions('checklistTemplates', ['createChecklistTemplate']),
    async addNewChecklistClicked() {
      try {
        const checklistTemplateId = await this.createChecklistTemplate({
          name: this.newChecklist.name
        });
        this.$events.$emit('toastEvent', 'New Checklist Created');
        this.$router.push({
          name: 'editChecklistTemplate',
          params: { checklistTemplateId }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    goChecklistTemplate(checklistTemplateId) {
      this.$router.push({
        name: 'editChecklistTemplate',
        params: {
          checklistTemplateId
        }
      });
    }
  }
};
</script>

<style scoped></style>
