<template>
  <v-dialog
    persistent
    :value="isOpen"
    :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <span class="title capitalize">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="label"
          type="email"
          name="input-sendTo"
          required
          :hint="hint"
          :persistent-hint="!!hint"
          v-model="state.toEmail" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          color="primary"
          @click.native="handleClose">Cancel</v-btn>
        <v-btn
          flat
          color="primary"
          @click.native="handleSendEmail">Send Email</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendEmailDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '600px;';
      }
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default() {
        return null;
      }
    },
    toEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: {
        toEmail: ''
      }
    };
  },
  computed: {},
  mounted() {
    this.resetState();
  },
  methods: {
    handleSendEmail() {
      this.$emit('send-email', this.state);
      this.handleClose();
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    resetState() {
      this.state = {
        toEmail: this.toEmail
      };
    }
  }
};
</script>

<style scoped>
</style>
