<template>
  <v-card class="round-card">
    <v-layout row wrap class="pa-3">
      <v-flex xs9 sm9 md5 class="h-flex">
        <v-icon class="pr-3 handle">drag_handle</v-icon>
        <div>
          <p class="caption mb-0 grey--text">{{ question.name }}</p>
          <p class="mb-0">{{ question.label }}</p>
        </div>
      </v-flex>
      <v-flex xs3 sm3 md2 class="h-flex">
        <v-icon class="px-1" color="primary">how_to_vote</v-icon>
        <p class="mb-0">
          <b>{{ totalBallotsCast }}</b> Ballots Cast
        </p>
      </v-flex>
      <v-flex xs6 sm6 md2 class="h-flex">
        <live-vote-button
          :question="question"
          :is-disabled="disableVoteBtn"
          @start="openDialog('start')"
          @stop="openDialog('stop')"
          @closed="$emit('refresh')"
        />

        <v-btn
          :outline="!question.active"
          icon
          :disabled="!question.active"
          color="warning"
          @click="openDialog('extend')"
        >
          <v-icon>alarm_add</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm6 md3 class="h-flex flex-end">
        <v-btn outline round color="primary">
          <!-- <v-btn
          outline
          round
          color="primary"
          @click="toggleQuestionExpand({ id: question.id })"
          > -->
          {{ question.isExpanded ? 'Hide' : 'See' }} Results
          <v-icon
            right
            class="expand-icon"
            :class="{ active: question.isExpanded }"
          >
            expand_more
          </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-expand-transition>
      <div v-show="question.isExpanded" class="grey lighten-4">
        <v-divider />
        <v-toolbar dense class="elevation-0">
          <p class="mb-0 subheading font-weight-bold">Results</p>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              color="blue"
              flat
              :disabled="!hasTallyData"
              @click="exportClicked"
            >
              Download Summary
            </v-btn>
            <v-tooltip bottom>
              <template slot="activator">
                <v-btn
                  v-if="canViewEarly || options.enableVoteBreakdown"
                  color="blue"
                  flat
                  :disabled="!hasTallyData || !checkVoteBreakdownDate"
                  @click="viewDataClicked"
                >
                  View Data
                </v-btn>
              </template>
              <span>
                Business data will be made available <br />
                {{ parseVoteBreakdownDate }}
              </span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <vote-tally-combined-table-v3
          flatTable
          :question="question"
          :display-rounded-results="options.roundedResults"
          :rounded-results-precision="options.roundedResultsPrecision"
          :display-vote-results-toggle="options.displayVoteResultsToggle"
          :metric="metric"
          :can-view-early="canViewEarly"
          @save-additional-vote="saveAdditionalVote"
          @delete-additional-vote="onDeleteAdditionalVote"
          @toggle-exclude-vote="toggleExcludeVote"
          @add-option="addBlankRow"
          @cancel-option="cancelOption"
          @enable-save-changes="enableSaveOptionChanges"
          @save-option-changes-v3="saveOptionChanges"
          @cancel-option-changes="cancelOptionChanges"
          @view-write-in-vote="emitViewWriteInVote"
        />
      </div>
    </v-expand-transition>

    <!-- Dialog boxes -->
    <!-- Start / Extend Dialog -->
    <add-time-dialog
      v-model="dialog.add"
      :action="dialog.action"
      :question="question"
      @start="openVoting"
      @extend="extendVoting"
      @close="dialog.add = false"
    />

    <!-- Close Dialog -->
    <close-vote-dialog
      v-model="dialog.stop"
      :question="question"
      @stop="closeVoting"
      @close="dialog.stop = false"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import {
  saveMeetingAdditionalVote,
  deleteAdditionalVote
} from '@/api/meetings-additional-votes';
import exportTallyToCSV from '@/lib/export-vote-tally';
import LiveVoteButton from '@/components/LiveVoteButton';
import CloseVoteDialog from '@/components/LiveVoteCloseDialog';
import AddTimeDialog from '@/components/LiveVoteAddTimeDialog';
import VoteTallyCombinedTableV3 from '@/components/VoteTallyCombinedTableV3';

export default {
  name: 'MeetingBusinessCard',
  components: {
    LiveVoteButton,
    VoteTallyCombinedTableV3,
    AddTimeDialog,
    CloseVoteDialog
  },
  props: {
    question: {
      type: Object,
      default() {
        return null;
      }
    },
    disableVoteBtn: {
      type: Boolean,
      default: false
    },
    metric: {
      type: String,
      default: 'shares'
    },
    canViewEarly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: {
        add: false,
        action: 'add',
        stop: false
      },
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('v2/meetings', ['meetingByShortCode']),
    currentMeeting() {
      return this.meetingByShortCode(this.shortCode);
    },
    options() {
      return this.currentMeeting.options ? this.currentMeeting.options : {};
    },
    totalBallotsCast() {
      const tallyTotal = this.question.tally.reduce((sum, tally) => {
        const totalVotes = isNaN(tally.numVotesTotal) ? 0 : tally.numVotesTotal;
        return sum + totalVotes;
      }, 0);

      return _.round(tallyTotal, 2);
    },
    checkVoteBreakdownDate() {
      return (
        this.canViewEarly ||
        moment(this.options.enableVoteBreakdownDate).isBefore()
      );
    },
    parseVoteBreakdownDate() {
      return moment(this.options.enableVoteBreakdownDate).format(
        'dddd, MMMM Do YYYY, h:mm:ss a'
      );
    },
    hasTallyData() {
      return this.question.tally.length > 0;
    }
  },
  methods: {
    // These vuex actions/API routes can be removed
    // if/when we sunset the V2 Overview page.
    //
    // ...mapActions('v2/questions', [
    // 'toggleQuestionExpand'
    // 'toggleVoteExclusion'
    // ]),
    ...mapActions('meetings/rtvQuestions', [
      'openVotingRTVQuestion',
      'closeVotingRTVQuestion',
      'extendVotingRTVQuestion'
    ]),
    openDialog(dialogType) {
      switch (dialogType) {
        case 'start':
          this.dialog.action = 'start';
          this.dialog.add = true;
          break;
        case 'extend':
          this.dialog.action = 'extend';
          this.dialog.add = true;
          break;
        case 'stop':
          this.dialog.stop = true;
          break;
        default:
          console.log(`Dialog type ${dialogType} not recognized`);
      }
    },
    async openVoting(question, votingPeriod) {
      try {
        question.actionLogTag = 'start';

        // Check that question has an rtvQuestionKey
        if (question.rtvQuestionKey === null) {
          this.$events.$emit('showErrorDialog', {
            data: 'Business has not been copied to live questions'
          });
          return;
        }

        await this.openVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.rtvQuestionKey,
          question,
          time: votingPeriod
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async extendVoting(question, votingPeriod) {
      try {
        question.actionLogTag = 'extend';

        await this.extendVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.rtvQuestionKey,
          question,
          time: votingPeriod
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async closeVoting(question) {
      try {
        question.actionLogTag = 'stop';
        question.active = false;

        await this.closeVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.rtvQuestionKey,
          question
        });

        this.dialog.stop = false;
        this.dialog.question = {};
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    viewDataClicked() {
      this.$router.push({
        name: 'meetingData',
        params: {
          shortcode: this.shortCode,
          questionId: this.question.id
        }
      });
    },
    emitViewWriteInVote(params) {
      this.$emit('view-write-in-vote', params);
    },
    exportClicked() {
      exportTallyToCSV({
        name: this.question.name,
        tally: this.question.tally,
        version: 2 // component used only for dashboard v2
      });
    },
    // Additional Votes
    async saveAdditionalVote(payload) {
      // Save the changes - applied on the advance question reference
      try {
        await saveMeetingAdditionalVote({
          shortCode: this.shortCode,
          questionKey: payload.questionKey,
          vote: payload.vote,
          voteItem: payload.voteItem,
          createdAtName: payload.createdAtName,
          total: payload.total,
          metric: payload.metric
        });
        this.$events.$emit('toastEvent', 'Changes saved');
        this.$emit('refresh');
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be saved, invalid input found'
        );
      }
    },
    async onDeleteAdditionalVote({
      additionalVoteKey,
      message,
      deletedAtName
    }) {
      try {
        await deleteAdditionalVote({
          shortCode: this.shortCode,
          additionalVoteKey,
          message,
          deletedAtName
        });
        this.$events.$emit('toastEvent', 'Additional note deleted');
        this.$emit('refresh');
      } catch {
        this.$events.$emit('toastEvent', 'delete error');
      }
    },
    // Vote Exclusion
    async toggleExcludeVote({ questionKey, exclusion }) {
      try {
        // await this.toggleVoteExclusion({
        //   shortCode: this.shortCode,
        //   questionKey,
        //   exclusion
        // });
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Could not toggle exclusion, please try again'
        );
      }
    },
    addBlankRow() {
      //Add a new blank tally option
      this.question.tally = [
        ...this.question.tally,
        {
          newOptionKey: `new-${this.question.tally.length}`,
          vote: '',
          voteItem: '',
          total: null
        }
      ];
    },
    cancelOption({ newOptionKey }) {
      this.question.tally = this.question.tally.filter(
        tally => tally.newOptionKey != newOptionKey
      );
    },
    async cancelOptionChanges() {
      // Reset and remove unsaved new line items
      this.question.tally = _.filter(this.question.tally, 'answerId');
      this.question.canSave = false;
      this.$events.$emit('toastEvent', 'Changes have been reset');
    },
    async enableSaveOptionChanges(questionId) {
      // Enable the save button for the target question
      if (questionId) {
        this.question.canSave = true;
      } else {
        this.question.canSave = false;
      }
    },
    async saveOptionChanges({ createdAtName }) {
      try {
        const questionKey = this.question.questionKey;
        //Filter out newOptions to save to AdditionalVotes table
        const newOptions = this.question.tally.filter(
          tally => tally.newOptionKey
        );
        const saveVotePromises = [];

        // "VoteItem" is blank, as that's a subitem for a vote
        // "Total" will always be zero when saving a new option
        newOptions.forEach(option => {
          const payload = {
            shortCode: this.shortCode,
            vote: option.vote,
            voteItem: option.voteItem,
            total: _.toInteger(option.total),
            metric: this.metric,
            questionKey,
            createdAtName
          };
          saveVotePromises.push(this.saveAdditionalVote(payload));
        });

        await Promise.all(saveVotePromises);
        this.$events.$emit('toastEvent', 'Changes saved');
      } catch {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be saved, invalid input found'
        );
      } finally {
        this.$emit('refresh');
      }
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}

.h-flex {
  display: flex;
  align-items: center;
}

.expand-icon.active {
  transform: rotate(-180deg);
}

.flex-end {
  justify-content: flex-end;
}
</style>
