<template>
  <v-card>
    <v-card-title>
      <span class="title capitalize">Add {{ itemTypeFormatted }}</span>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 class="ml-2 mr-2 mb-4">
          <v-btn-toggle v-model="tag" @change="resetState">
            <v-btn flat value="2022">2022</v-btn>
            <v-btn flat value="2019">2019</v-btn>
            <v-btn flat value="2018">2018</v-btn>
          </v-btn-toggle>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 class="ml-2 mr-2">
          <v-select
            :items="itemsFilteredByTag"
            v-model="e1"
            label="Quick Select"
            item-text="label"
            item-value="key"
            persistent-hint
            @input="onQuickSelect"
          />
        </v-flex>
      </v-layout>

      <v-layout row nowrap v-show="e1">
        <v-flex xs12>
          <v-layout row nowrap>
            <v-flex xs12 class="ma-2">
              <span><b>Customize</b></span>
            </v-flex>
          </v-layout>

          <!-- Show the print calculator only for calculated printing -->
          <print-price-calculator
            v-if="itemType === 'expense' && state.key === 'print-calc'"
            :item="state"
            @update="updatePrintPriceCalculator"
          />

          <v-layout row nowrap>
            <v-flex xs4 class="ml-2 mr-2">
              <v-text-field
                type="number"
                name="input-base-value"
                label="Base Value"
                id="input-base-value"
                min="0"
                v-model="state.baseValue"
                :disabled="state.key === 'print-calc'"
                @input="updateAmount"
              />
            </v-flex>
            <v-flex xs4 class="ml-2 mr-2" v-if="itemType === 'expense'">
              <v-text-field
                type="number"
                name="input-margin"
                label="Gross Margin (%)"
                id="input-margin"
                min="0"
                v-model="state.margin"
                :disabled="state.category !== 'printing'"
                @input="updateAmount"
              />
            </v-flex>
            <v-flex xs4 class="ml-2 mr-2">
              <v-text-field
                type="number"
                name="input-value"
                label="Amount"
                id="input-value"
                v-model="state.value"
                disabled
              />
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12 class="ml-2 mr-2">
              <v-textarea
                name="input-description"
                label="Description"
                id="input-description"
                v-model="state.description"
                rows="2"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row v-if="error">
        <v-flex xs12 class="ma-2">
          <v-icon>warning</v-icon> Error adding item, please fix and try again.
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
      <v-btn flat color="primary" @click.native="handleAddItem">Add Item</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

import { addMarginValue } from '@/helpers';

import PrintPriceCalculator from '@/components/PrintPriceCalculator';

export default {
  name: 'AdminInvoiceViewItemsAdd',
  components: {
    PrintPriceCalculator
  },
  props: {
    invoiceKey: {
      type: String,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    itemOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      e1: null,
      error: null,
      tag: '2022',
      state: {
        key: '',
        category: '',
        description: '',
        baseValue: 0,
        margin: 0,
        value: 0
      }
    };
  },
  computed: {
    itemTypeFormatted() {
      return _.startCase(this.itemType);
    },
    itemsFilteredByTag() {
      return this.itemOptions.filter(i => i.tags.includes(this.tag));
    }
  },
  mounted() {
    this.state = {};
  },
  methods: {
    ...mapActions('invoices', ['addItemToInvoice']),
    async handleAddItem() {
      try {
        this.error = false;
        await this.addItemToInvoice({
          key: this.invoiceKey,
          type: this.itemType,
          payload: this.state
        });
        this.resetState();
        this.tag = '2022';
        this.$emit('close');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.error = true;
        throw err;
      }
    },
    handleClose() {
      this.resetState();
      this.tag = '2022';
      this.$emit('close');
    },
    resetState() {
      this.state = { category: '', description: '', value: 0 };
      this.e1 = null;
    },
    onQuickSelect(e) {
      const item = this.itemOptions.filter(k => k.key === e)[0];
      this.state = {
        ...item,
        key: item.key,
        category: item.category,
        baseValue: item.value,
        description: item.description,
        margin: item.margin,
        value: this.calculateMargin(item.margin, item.value),
        quickSelect: e
      };
    },
    calculateMargin(m, v) {
      if (!m || m <= 0) {
        return v;
      }
      return addMarginValue(m, v);
    },
    updateAmount() {
      const marginDec = _.round(this.state.margin / 100, 2);
      const price = this.calculateMargin(marginDec, this.state.baseValue);
      this.state.value = price;
    },
    updatePrintPriceCalculator({ baseValue, description }) {
      this.state.baseValue = baseValue;
      this.state.description = description;
      this.updateAmount();
    }
  }
};
</script>

<style scoped></style>
