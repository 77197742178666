<template>
  <v-container grid-list-xl>
    <v-layout class="mx-2 mb-5" align-center>
      <v-flex xs12>
        <meeting-approvals-table
          :loading="loading"
          @refresh="fetchData"
          @create="dialog.create = true"
          :current-meeting="currentMeeting"
        />
      </v-flex>
    </v-layout>

    <create-request-dialog
      :is-open="dialog.create"
      :state="state"
      :loading="loading"
      @update="handleUpdate"
      @save="onSave"
      @close="handleClose"
    />
  </v-container>
</template>

<script>
// import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import MeetingApprovalsTable from './MeetingApprovalsTable.vue';
import CreateRequestDialog from './CreateRequestDialog';

export default {
  name: 'MeetingApprovalsView',
  components: { MeetingApprovalsTable, CreateRequestDialog },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      shortCode: this.$route.params.shortcode,
      dialog: {
        create: false
      },
      state: {
        name: ''
      }
    };
  },
  computed: {
    ...mapGetters('meetings/approvals', ['approvalsListData'])
  },
  async created() {
    this.loading = true;
    this.dataLoaded = false;
    await this.fetchData();
  },
  methods: {
    ...mapActions('meetings/approvals', [
      'getMeetingApprovalsList',
      'createApproval'
    ]),
    async fetchData() {
      try {
        this.loading = true;
        await this.getMeetingApprovalsList({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
        this.dataLoaded = true;
      }
    },
    handleClose() {
      this.dialog.create = false;
      this.state = { name: '' };
    },
    handleUpdate(obj) {
      this.$set(this.state, obj.key, obj.value);
    },
    async onSave() {
      try {
        this.loading = true;
        await this.createApproval({
          shortCode: this.shortCode,
          name: this.state.name
        });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
        this.dataLoaded = true;
        this.handleClose();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
