<template>
  <v-container>
    <!-- Check restrictions before displaying actual question -->
    <div v-if="!question.eligible">
      <p class="subheading font-weight-bold">
        <span> {{ index + 1 }}. {{ question.header }}</span>
      </p>
      <v-alert
        :value="true"
        class="ma-0 py-2"
        outline
        type="error"
        color="red accent-2"
        icon="error"
      >
        <!-- Conditional check for question restrictions
        Not expected to happen, because an ineligible question must have restrictions
        But we can prevent errors from occuring in 'getRestriction' should it not exist
        -->
        <div v-if="questionRestrictions.length > 0">
          Question is restricted to voters with one of the following criteria:
          <ul>
            <li
              v-for="(restriction, index) in questionRestrictions"
              :key="index"
            >
              {{ getRestriction(restriction) }}
            </li>
          </ul>
        </div>
        <div v-else>
          <!-- Default text if ineligible -->
          This question is restricted for this voter
        </div>
      </v-alert>
    </div>
    <div v-else>
      <p class="subheading font-weight-bold">
        <span> {{ index + 1 }}. {{ question.header }}</span>
        <span class="red--text font-weight-bold body-1 ml-2">{{
          validationErrorMsg
        }}</span>
      </p>

      <v-radio-group
        v-for="(option, index) in voteOptions"
        :key="index"
        v-model="selectedAnswers"
        @change="emitSelectedAnswers"
        column
        hide-details
      >
        <v-radio
          :label="option.label"
          :value="option.value"
          class="capitalize"
        />
      </v-radio-group>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingProxiesQuestionRadio',
  props: {
    index: {
      type: Number,
      default() {
        return 0;
      }
    },
    question: {
      type: Object,
      default() {
        return {};
      }
    },
    validationErrorMsg: {
      type: String,
      default() {
        return '';
      }
    }
  },
  computed: {
    // question.restrictions can be null; in such cases return an empty array
    questionRestrictions() {
      return this.question.restrictions ? this.question.restrictions : [];
    },
    voteOptions() {
      const { meta } = this.question;
      const toggles = _.get(meta, 'options.toggles', null);

      if (toggles) {
        const optionsFormat = _.map(toggles, (option, index) => {
          return {
            // if visible true it shows the custom label otherwise show the default label
            label: option.visible ? option.label : index,
            value: index
          };
        });

        return optionsFormat;
      } else {
        // if the toggle is off, return default values
        return [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
          { label: 'Abstain', value: 'abstain' },
          { label: 'Defer', value: 'defer' }
        ];
      }
    }
  },
  data() {
    return {
      selectedAnswers: this.question.vote,
      writeIn: ''
    };
  },
  methods: {
    emitSelectedAnswers(answers) {
      let answerObj = {
        type: this.question.type,
        questionKey: this.question.questionKey,
        answers
      };

      this.$emit('selectedAnswers', answerObj);
    },
    getRestriction(restriction) {
      // Return object key and value for restriction
      const key = Object.keys(restriction)[0];
      return `${key}: ${restriction[key]}`;
    }
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
