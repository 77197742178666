<template>
  <v-dialog max-width="700px" persistent :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Edit Unit</span>
      </v-card-title>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs6>
            <v-text-field label="Unit" v-model="state.unit" />
          </v-flex>

          <v-flex xs6>
            <v-select
              label="Address"
              :items="addresses"
              v-model="state.address"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Control Number"
              v-model="state.controlNumber"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Mailing Address 1"
              v-model="state.mailAddress1"
            />

            <v-text-field
              label="Mailing Address 2"
              v-model="state.mailAddress2"
            />
          </v-flex>

          <v-flex xs6>
            <v-text-field label="Mailing City" v-model="state.mailCity" />
          </v-flex>
          <v-flex xs6>
            <v-text-field label="Mailing Postal" v-model="state.mailPostal" />
          </v-flex>

          <v-flex xs6>
            <v-text-field label="Mailing Province" v-model="state.mailProv" />
          </v-flex>
          <v-flex xs6>
            <v-text-field label="Mailing Country" v-model="state.mailCountry" />
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="primary" :disabled="!isChanged" @click.native="emitUpdate"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitEditUnitDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    addresses: {
      type: Array,
      default() {
        return [];
      }
    },
    unitSelected: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      state: {}
    };
  },
  watch: {
    value: function() {
      this.init(this.unitSelected);
    }
  },
  mounted() {
    this.init(this.unitSelected);
  },
  computed: {
    isChanged() {
      let state = {
        unit: this.state.unit,
        controlNumber: this.state.controlNumber,
        address: this.state.address,
        mailAddress1: this.state.mailAddress1,
        mailAddress2: this.state.mailAddress2,
        mailCity: this.state.mailCity,
        mailCountry: this.state.mailCountry,
        mailPostal: this.state.mailPostal,
        mailProv: this.state.mailProv
      };
      let currentMeeting = {
        unit: this.unitSelected.unit,
        controlNumber: this.unitSelected.controlNumber,
        address: this.unitSelected.address,
        mailAddress1: this.unitSelected.mailAddress1,
        mailAddress2: this.unitSelected.mailAddress2,
        mailCity: this.unitSelected.mailCity,
        mailCountry: this.unitSelected.mailCountry,
        mailPostal: this.unitSelected.mailPostal,
        mailProv: this.unitSelected.mailProv
      };
      return !_.isEqual(state, currentMeeting);
    }
  },
  methods: {
    init(value) {
      this.state = _.cloneDeep(value);
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitUpdate() {
      this.$emit('update', this.state);
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
