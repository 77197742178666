<template>
  <v-list-tile-content @click="emitFileDownload()">
    <v-list-tile-title>{{ attachment.name }}</v-list-tile-title>
    <v-list-tile-sub-title>{{
      attachment.size | formatSize
    }}</v-list-tile-sub-title>
    <span v-if="isLargeAttachment(attachment.size)" class="warning-text caption"
      >Warning: attachment exceeds 10 MB</span
    >
  </v-list-tile-content>
</template>

<script>
import filesize from 'filesize';

export default {
  name: 'FileUploadItem',
  filters: {
    formatSize(value) {
      if (value) {
        return filesize(value, { base: 10 });
      }
      return '';
    }
  },
  props: {
    attachment: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    emitFileDownload() {
      this.$emit('download', this.attachment);
    },
    isLargeAttachment(filesize) {
      return filesize > 10000000 ? true : false;
    }
  }
};
</script>

<style scoped>
.warning-text {
  color: #ff0000;
  opacity: 0.9;
}
</style>
