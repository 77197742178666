<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Distribution options</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>

      <v-container class="pt-0">
        Retrieve voters with the following consent:
        <v-layout row wrap>
          <v-flex xs12>
            <v-checkbox
              v-for="option in filterOptions"
              hide-details
              :key="option.value"
              :label="option.label"
              :value="option.value"
              v-model="selectedOptions"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn flat @click.native="$emit('close')">Cancel</v-btn>
          <v-spacer />
          <v-btn
            :disabled="selectedOptions.length === 0"
            @click.native="handleAction({ action: buttonAction })"
            color="primary"
            class="white--text"
          >
            {{ buttonText }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConsentDistributionDialogV2',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedOptions: [],
      // Consent filter options for multi-consent
      multiConsentOptionsV2: [
        {
          label: 'Notice & Voting Consent',
          value: 'noticeAndVoting'
        },
        {
          label: 'Notice Consent Only',
          value: 'noticeOnly'
        },
        {
          label: 'Voting Consent Only',
          value: 'votingOnly'
        },
        {
          label: 'No Action',
          value: 'noAction'
        },
        {
          label: 'Rejected Consent',
          value: 'rejectedConsent'
        }
      ],
      consentExportOptionsV2: [
        {
          label: 'Notice & Voting Consent',
          value: 'noticeAndVoting'
        },
        {
          label: 'Notice Consent Only',
          value: 'noticeOnly'
        },
        {
          label: 'Voting Consent Only',
          value: 'votingOnly'
        }
      ]
    };
  },
  computed: {
    // Filter options dependent on consent format
    filterOptions() {
      // List of export type reports
      const exportReports = ['consent-export-v2', 'consent-export-v2-client'];

      // For export reports, use consentExportOptionsV2
      if (exportReports.includes(this.reportType)) {
        return this.consentExportOptionsV2;
      }
      // Otherwise return default option list
      return this.multiConsentOptionsV2;
    },
    // Show different button text and emit different action based on report
    buttonText() {
      return ['consent-distribution-v2'].includes(this.reportType)
        ? 'Download'
        : 'Send';
    },
    buttonAction() {
      return ['consent-distribution-v2'].includes(this.reportType)
        ? 'download'
        : 'export';
    }
  },
  methods: {
    handleAction({ action }) {
      this.$emit('action', {
        options: this.selectedOptions,
        reportType: this.reportType,
        action
      });
    }
  }
};
</script>

<style scoped></style>
