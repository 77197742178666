<template>
  <v-dialog persistent :value="isOpen" max-width="500px">
    <v-card class>
      <v-card-title>
        <span class="title">Update Meeting Business Section</span>
      </v-card-title>
      <div class="px-3">
        <v-checkbox
          v-model="showProxyHolders"
          label='Display "Default Proxy Holders" Card'
        ></v-checkbox>
      </div>
      <v-card-actions>
        <v-btn flat color="primary" @click.native="handleClose">cancel</v-btn>
        <v-spacer />
        <v-btn color="primary" @click.native="handleAdd">save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateMeetingBusinessDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    displayHolders: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showProxyHolders: true
    };
  },
  watch: {
    isOpen() {
      // Set default value as some ARs may not have
      // settings saved in DB
      if (this.displayHolders === null) {
        this.showProxyHolders = true;
      } else {
        this.showProxyHolders = this.displayHolders;
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleAdd() {
      this.$emit('save', { displayProxyHolders: this.showProxyHolders });
    }
  }
};
</script>

<style scoped></style>
