<template>
  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
    <div>
      <div v-if="imageUploading">
        <v-progress-linear color="primary" height="2" indeterminate />
      </div>
      <v-toolbar class="toolbar" flat dense>
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <v-icon>format_bold</v-icon>
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <v-icon>format_italic</v-icon>
        </v-btn>
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <v-icon>format_underline</v-icon>
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button heading_button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button heading_button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button heading_button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button heading_button"
          :class="{ 'is-active': isActive.heading({ level: 4 }) }"
          @click="commands.heading({ level: 4 })"
        >
          H4
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button heading_button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <v-icon>format_list_bulleted</v-icon>
        </v-btn>

        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <v-icon>format_list_numbered</v-icon>
        </v-btn>
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.link() }"
          @click="setUrl(commands.link)"
        >
          <v-icon>link</v-icon>
        </v-btn>
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{ 'is-active': isActive.image() }"
          @click="openFileDialog(commands.image)"
        >
          <v-icon>image</v-icon>
        </v-btn>
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{
            'is-active': editor.activeMarkAttrs.aligntext.align === 'left'
          }"
          @click="commands.aligntext({ align: 'left' })"
          ><v-icon>format_align_left</v-icon></v-btn
        >
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{
            'is-active': editor.activeMarkAttrs.aligntext.align === 'center'
          }"
          @click="commands.aligntext({ align: 'center' })"
          ><v-icon>format_align_center</v-icon></v-btn
        >
        <v-btn
          icon
          flat
          class="menubar__button"
          :class="{
            'is-active': editor.activeMarkAttrs.aligntext.align === 'right'
          }"
          @click="commands.aligntext({ align: 'right' })"
          ><v-icon>format_align_right</v-icon></v-btn
        >
        <v-spacer />
        <v-menu
          v-if="!hideMergeMenu || showCustomFields"
          :close-on-content-click="false"
          max-width="400"
          bottom
        >
          <v-btn flat color="primary" slot="activator">Insert Merge Tag</v-btn>
          <v-card>
            <merge-help
              :hide="hideMergeMenu"
              :custom-label="customLabel"
              :custom-fields="customFields"
              :show-custom-fields="showCustomFields"
              :show-button-options="showButtonOptions"
              @insert="insertMergeTag"
            />
          </v-card>
        </v-menu>
        <input
          type="file"
          ref="file"
          @change.prevent="onFileChange($event, commands.image)"
          v-show="false"
          multiple
        />
      </v-toolbar>
    </div>
  </editor-menu-bar>
</template>

<script>
import { EditorMenuBar } from 'tiptap';
import { uploadPublicImages } from '@/api/files';
import MergeHelp from '@/components/RichTextEditor/MergeHelp.vue';

export default {
  name: 'Toolbar',
  components: {
    EditorMenuBar,
    MergeHelp
  },
  props: {
    editor: {
      type: Object,
      default: null
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    },
    hideMergeMenu: {
      type: Boolean,
      default: false
    },
    isUploading: {
      type: Boolean,
      default: false
    },
    customLabel: {
      type: String,
      default: ''
    },
    showCustomFields: {
      type: Boolean,
      default: false
    },
    showButtonOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUploading: false
    };
  },
  watch: {
    isUploading: function(newValue) {
      this.imageUploading = newValue;
    }
  },
  methods: {
    setUrl(command) {
      const state = this.editor.state;
      const { from, to } = state.selection;
      let marks = [];
      state.doc.nodesBetween(from, to, node => {
        marks = [...marks, ...node.marks];
      });

      const mark = marks.find(markItem => markItem.type.name === 'link');
      let urlSetting = '';
      if (mark && mark.attrs.href) {
        const presetURL = mark.attrs.href;
        prompt('Please update url', presetURL);
      } else {
        urlSetting = prompt('Please add url', '');
      }
      command({ href: urlSetting });
    },
    openFileDialog(command) {
      this.$refs.file.click();
    },
    async onFileChange(e, command) {
      try {
        this.imageUploading = true;
        const urls = await uploadPublicImages(e.target.files);
        urls.forEach(url => {
          command({
            src: url
          });
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        e.target.value = '';
        this.imageUploading = false;
      }
    },
    insertMergeTag(mergeTag) {
      const transaction = this.editor.state.tr.insertText(mergeTag);
      this.editor.view.dispatch(transaction);
    }
  }
};
</script>

<style scoped>
.menubar__button.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.toolbar {
  border: 1px solid #ccc;
  border-bottom: none;
  overflow-x: auto;
}
.heading_button {
  font-weight: bold;
  font-size: 18px;
}
</style>
