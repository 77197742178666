'use strict';

/**
 * @fileoverview Meeting tagging users
 */

import _ from 'lodash';

import { getTaggingUsers } from '@/api/meetings';

const state = {
  taggingUsers: []
};

const getters = {
  taggingUsers: state => state.taggingUsers
};

const actions = {
  /**
   * Get the latest list of users from API
   */
  async getTaggingUsers({ commit }, { shortCode }) {
    try {
      const res = await getTaggingUsers({ shortCode });

      commit('SET_TAGGING_USERS', res.data);
    } catch (err) {
      console.error('ERROR: getUsers action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_TAGGING_USERS(state, taggingUsers) {
    state.taggingUsers = taggingUsers;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.taggingUsers = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
