<template>
  <v-dialog :value="isOpen" persistent width="800px" height="200px">
    <v-card>
      <v-card-title>
        <v-layout row>
          <v-flex xs12>
            <p class="headline">Add {{ dataSet }}</p>
            <p class="secondary--text">
              View and select which {{ dataSet }} you would like to add
            </p>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-layout row class="mb-3" v-if="selected.length > 0">
          <v-flex justify-center>
            <div>Selected {{ dataSet }}</div>
            <v-chip v-for="(select, index) in selected" :key="index">
              {{ select.name }}
            </v-chip>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-toolbar dark color="grey lighten-4 elevation-1">
              <v-text-field
                light
                prepend-icon="search"
                :label="`Search for ${dataSet}`"
                single-line
                hide-details
                clearable
                v-model="searchText"
                @input="updateSearchResults"
              />
            </v-toolbar>
            <v-data-table
              v-model="selected"
              :headers="settings.dataHeaders[dataSet]"
              :items="uniqueUsers"
              :item-key="settings.uniqueKey"
              :rows-per-page-items="config.rowsPerPageItems"
              :pagination.sync="config.pagination"
              select-all
              :search="hasPagination"
              :loading="loading"
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <tr
                  :active="props.selected"
                  @click="props.selected = !props.selected"
                >
                  <td>
                    <v-checkbox
                      :input-value="props.selected"
                      primary
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td
                    v-for="(header, index) in settings.dataHeaders[dataSet]"
                    :key="index"
                  >
                    <span v-if="header.text.toLowerCase() === 'created'">
                      {{
                        props.item.createdAt | formatDateHumanReadable(false)
                      }}
                    </span>
                    <span v-else>
                      {{ props.item[header.value] || 'na' }}
                    </span>
                  </td>
                </tr>
              </template>
              <template slot="no-data" v-if="dataSet != 'agents'">
                <div v-if="loading" class="text-xs-center">
                  Loading...
                </div>
                <div v-else-if="searchText" class="text-xs-center">
                  No results match your search
                </div>
                <div v-else class="text-xs-center">
                  Start searching to find {{ dataSet }}
                </div>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="closeDialog" flat>Close</v-btn>
        <v-btn
          @click.native="addMembership"
          color="primary"
          :disabled="!canSave"
          flat
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { handleError } from '@/helpers';
import * as filters from '@/filters';
import _ from 'lodash';

export default {
  name: 'addMembershipSelectDialog',
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    currentDataList: {
      type: Array,
      default() {
        return [];
      }
    },
    dataSet: {
      type: String
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      selected: [],
      dataSetList: [],
      searchText: null,
      loading: false,
      config: {
        rowsPerPageItems: [10],
        pagination: {
          descending: true
        }
      },
      // Settings per dataset
      settings: {
        uniqueKey: '',
        dataHeaders: {
          users: [
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Type', value: 'type' }
          ],
          agents: [
            { text: 'Agent', value: 'name' },
            { text: 'Agent Type', value: 'agentType' },
            { text: 'Partner Type', value: 'partnerType' },
            { text: 'Created', value: 'createdAt' }
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters('users', ['userList', 'userListData']),
    ...mapGetters('agents', ['agentList']),
    canSave() {
      return this.selected.length > 0 ? true : false;
    },
    uniqueUsers() {
      const uniqueKey = this.settings.uniqueKey;
      if (uniqueKey) {
        // Calculate difference in the current users and those selected for data table
        return this.dataSetList.filter(user => {
          return !this.currentDataList.some(selectedUser => {
            return user[uniqueKey] === selectedUser[uniqueKey];
          });
        });
      }
    },
    hasPagination() {
      return this.searchText;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('users', ['getPartialUsers']),
    ...mapActions('agents', ['getAgentListData']),
    async init() {
      try {
        this.findDataSet();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    },
    async updateSearchResults(text) {
      // If we dont have pagination then make use of inbuilt search prop for data table
      if (this.dataSet == 'users') {
        this.loading = true;
        await this.setUserData(text);
      } else if (this.dataSet == 'agents') {
        this.loading = true;
        await this.setAgentData(text);
      }
    },
    setUserData: _.debounce(async function(text) {
      await this.getPartialUsers(text || '');
      this.dataSetList = this.userListData;
      this.loading = false;
    }, 350),

    setAgentData: _.debounce(async function(text) {
      await this.getAgentListData(text || '');
      this.dataSetList = this.agentList;
      this.loading = false;
    }, 350),

    // Determine which data set we want and perform necessary queries
    async findDataSet() {
      if (this.dataSet === 'users') {
        await this.getPartialUsers('');
        this.dataSetList = this.userListData;
        this.settings.uniqueKey = 'email';
      } else if (this.dataSet === 'agents') {
        await this.getAgentListData('');
        this.dataSetList = this.agentList;
        this.settings.uniqueKey = 'name';
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.resetState();
    },
    resetState() {
      this.searchText = '';
      this.selected = [];
      this.findDataSet();
    },
    addMembership() {
      const mappedPayload = this.selected.map(dataObject => {
        return dataObject.key;
      });
      this.$emit(`add-${this.dataSet}`, mappedPayload);
      this.closeDialog();
    }
  }
};
</script>
