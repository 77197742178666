import http from './requestType';

export function getPrintJobList({
  meetingKey,
  status,
  searchText,
  page,
  size
} = {}) {
  return http._get(`PrintJobs/`, {
    meetingKey,
    status,
    searchText,
    page,
    size
  });
}

export function getPrintJob(key) {
  return http._get(`PrintJobs/${key}`);
}

export function postPrintJob(printJob) {
  return http._post('PrintJobs/', printJob);
}

export function putPrintJob(key, printJob, force = false) {
  return http._put(`PrintJobs/${key}?force=${force}`, printJob);
}

export function deletePrintJob(key) {
  return http._delete(`PrintJobs/${key}`);
}

export function cancelPrintJob(key, recipients = []) {
  return http._patch(`PrintJobs/${key}/cancel`, { recipients });
}

export function holdPrintJob(key, recipients = []) {
  return http._patch(`PrintJobs/${key}/hold`, { recipients });
}

export function process(key, recipients = [], urgent = null) {
  return http._patch(`PrintJobs/${key}/next`, { recipients, urgent });
}

export function completePrintJob(data) {
  return http._patch(`PrintJobs/${data.jobKey}/complete`, data);
}

export function getPrintJobItems(key) {
  return http._get(`PrintJobs/${key}/items`);
}

export function getPrintJobItem(key, itemKey) {
  return http._get(`PrintJobs/${key}/items/${itemKey}`);
}

export function postPrintJobItem(key, printJobItem, force = false) {
  return http._post(`PrintJobs/${key}/items/?force=${force}`, printJobItem);
}

export function postAllPrintJobItems(key, items, force = false) {
  return http._post(`PrintJobs/${key}/items/all/?force=${force}`, items);
}

export function deletePrintJobItem(key, itemKey, force = false) {
  return http._delete(`PrintJobs/${key}/items/${itemKey}/?force=${force}`);
}

export function getVendorList() {
  return http._get(`Vendors/list/category/printing`);
}

export function sendTestEmail(key, email) {
  return http._post(`PrintJobs/${key}/notification/test`, { email });
}
