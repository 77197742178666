<template>
  <div>
    <div class="d-flex justify-between align-center mb-2">
      <h3 class="font-weight-bold">
        Reviewers
      </h3>
      <v-btn
        v-if="isAdmin && isOpen"
        flat
        color="blue"
        class="mr-3"
        dark
        small
        @click.native="dialog.isOpen = true"
      >
        <v-icon>add</v-icon>Add
      </v-btn>
    </div>
    <ul>
      <li v-for="reviewer in reviewers" :key="reviewer.key">
        <v-layout row align-center>
          <span class="capitalize mr-2">
            <b>{{ reviewer.firstName }} {{ reviewer.lastName }}</b>
          </span>
          <span class="mr-3">({{ reviewer.email }})</span>
          <div>
            <v-tooltip v-if="isAdmin && isOpen" class="mr-2" bottom>
              <v-icon
                slot="activator"
                class="mt-1"
                color="primary"
                @click="openRerequestDialog(reviewer)"
              >
                autorenew
              </v-icon>
              <span>Re-request review from: {{ formatName(reviewer) }}</span>
            </v-tooltip>
            <v-tooltip v-if="isAdmin && isOpen" bottom>
              <v-icon
                slot="activator"
                color="red"
                class="mt-1"
                @click="remove('user', reviewer)"
                >close</v-icon
              >
              <span>Remove {{ formatName(reviewer) }} as a reviewer</span>
            </v-tooltip>
          </div>

          <v-icon v-if="reviewer.email === approvedBy" color="green"
            >thumb_up</v-icon
          >
        </v-layout>
      </li>

      <li v-if="!reviewers || reviewers.length === 0">
        None
      </li>
    </ul>
    <rerequest-dialog
      :value="dialog.rerequest"
      :approval="approvalInfo"
      :reviewer="rerequest.reviewer"
      @close="dialog.rerequest = false"
      @send="rerequestReview"
    />
    <user-list-dialog
      :is-open="dialog.isOpen"
      title="Add Reviewers"
      type="reviewers"
      @close="dialog.isOpen = false"
      @add="handleAdd"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import UserListDialog from './UserListDialog.vue';
import RerequestDialog from './ApprovalRequestsRerequestDialog.vue';

export default {
  name: 'ReviewerList',
  components: {
    UserListDialog,
    RerequestDialog
  },
  props: {
    reviewers: {
      type: [Array, Object],
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    approvedBy: {
      type: String,
      default() {
        return '';
      }
    },
    approvalName: {
      type: String,
      default() {
        return '';
      }
    },
    approvalMeeting: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      arKey: this.$route.params.arKey,
      dialog: {
        isOpen: false,
        rerequest: false
      },
      rerequest: {
        reviewer: {}
      }
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    approvalInfo() {
      return { meetingName: this.approvalMeeting, name: this.approvalName };
    }
  },
  methods: {
    ...mapActions('meetings/approvals', ['addReviewer', 'removeResource']),
    async handleAdd({ reviewers }) {
      try {
        const payload = {
          shortCode: this.shortCode,
          arKey: this.arKey
        };

        const promises = _.map(reviewers, async reviewer => {
          payload.userKey = reviewer.key;

          await this.addReviewer(payload);
        });

        await Promise.all(promises);

        this.$store.dispatch('meetings/approvals/getMeetingApproval', {
          shortCode: this.shortCode,
          arKey: this.arKey
        });

        this.$events.$emit('toastEvent', 'Reviewer added');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.dialog.isOpen = false;
      }
    },
    async remove(resource, item) {
      try {
        this.loading = true;
        await this.removeResource({
          shortCode: this.shortCode,
          arKey: this.arKey,
          resource,
          key: item.key
        });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    async rerequestReview() {
      try {
        await this.addReviewer({
          shortCode: this.shortCode,
          arKey: this.arKey,
          userKey: this.rerequest.reviewer.key
        });

        this.rerequest.reviewer = {};
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.dialog.rerequest = false;
      }
    },
    formatName(user) {
      return user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.username;
    },
    openRerequestDialog(reviewer) {
      this.rerequest.reviewer = reviewer;
      this.dialog.rerequest = true;
    }
  }
};
</script>

<style scoped></style>
