<template>
  <v-container fluid grid-list-lg>
    <v-layout row class="mr-3 ml-3">
      <v-flex xs12>
        <p class="title">Users</p>
        <p class="secondary--text">
          Manage the users that have login access to the GetQuorum platform. You
          can add or remove users, reset passwords, send password reset/invite
          emails, and assign users to "Acccounts".
        </p>
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs12>
        <v-toolbar dark color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            :value="searchText"
            @input="setSearchText"
          />
          <v-spacer />
          <v-btn
            flat
            icon
            @click.native="getUsers"
            :loading="!dataLastFetchedAt"
            color="secondary"
          >
            <v-icon>cached</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="userData"
          :search="searchText"
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>
              <router-link
                :to="{
                  name: 'adminUserEdit',
                  params: { userKey: props.item.key }
                }"
              >
                <span class="capitalize">{{ props.item.name }}</span>
              </router-link>
            </td>
            <td>{{ props.item.email }}</td>
            <td><user-status-chip :data="props.item" /></td>
            <td>
              <v-tooltip
                bottom
                v-if="mfaStatus(props.item.mfaEnabledAt) !== 'off'"
              >
                <v-icon slot="activator">
                  {{ mfaStatus(props.item.mfaEnabledAt) }}
                </v-icon>
                <span>
                  2FA enabled on:
                  {{ props.item.mfaEnabledAt | dateFormatReadable(true) }}
                </span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <span slot="activator">{{
                  props.item.lastLoggedInAt | dateFromNow
                }}</span>
                <span>
                  Last Login:
                  {{ props.item.lastLoggedInAt | dateFormatReadable(true) }}
                  <br />
                  Total Logins: {{ props.item.logins }}
                </span>
              </v-tooltip>
            </td>

            <td>{{ props.item.type }}</td>
            <td>
              <v-tooltip bottom v-if="props.item.activeModerator">
                <v-icon slot="activator">
                  check
                </v-icon>
                <span>
                  This user is an active moderator
                </span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-fab-transition>
      <v-btn
        dark
        fab
        right
        bottom
        fixed
        color="pink"
        @click.native="showAddDialog = true"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>

    <admin-user-add-dialog
      @add-user="addUser"
      @close-dialog="showAddDialog = false"
      :is-open="showAddDialog"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import * as filters from '@/filters';

import AdminUserAddDialog from '@/components/dialogs/AddUserDialog';
import UserStatusChip from '@/components/UserStatusChip';

export default {
  name: 'AdminUsersListView',
  components: {
    AdminUserAddDialog,
    UserStatusChip
  },
  props: {
    userList: {
      type: Object,
      required: true
    },
    userData: {
      type: Array,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'lastLoggedInAt',
          descending: true
        }
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'status', sortable: false },
        { text: '2FA', value: 'mfaEnabledAt' },
        { text: 'Last Login', value: 'lastLoggedInAt' },
        { text: 'Type', value: 'type' },
        { text: 'Moderators', value: 'activeModerator' }
      ],
      showAddDialog: false
    };
  },
  computed: {
    ...mapGetters('admin/users', ['searchText'])
  },
  methods: {
    ...mapActions('admin/users', ['setSearchText']),
    ...mapActions('users', ['getUsers', 'createUser']),
    async addUser(newUserData) {
      try {
        const user = await this.createUser(newUserData);
        this.$events.$emit('toastEvent', 'User Created');
        this.showAddDialog = false;
        this.$router.push({
          name: 'adminUserEdit',
          params: { userKey: user.key }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    mfaStatus(mfaEnabledAt) {
      if (!mfaEnabledAt) {
        return 'off';
      } else if (moment(mfaEnabledAt).isAfter()) {
        return 'alarm';
      } else {
        return 'check';
      }
    }
  }
};
</script>

<style scoped></style>
