<template>
  <v-dialog persistent max-width="400px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <div class="title">Add New Business</div>
      </v-card-title>
      <v-card-text>
        <v-select
          label="Select Notice Template"
          :items="businessTypes"
          item-text="name"
          item-value="name"
          v-model="newBusiness.type"
        />
        <v-text-field label="Business Name" v-model="newBusiness.name" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click="emitClose">close</v-btn>
        <v-btn :disabled="!newBusiness.type" class="primary" @click="emitAdd" :loading="loading">ADD</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'MeetingBusinessAddDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      businessTypes: [
        'by-law',
        'owner-occupied',
        'general-elections',
        'director-removals',
        'substitute-elections',
        'substitute-elections-oo'
      ],
      newBusiness: {
        name: '',
        type: ''
      }
    };
  },
  watch: {
    'newBusiness.type': function(value) {
      this.newBusiness.name = this.fillBusinessName(value);
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitAdd() {
      this.$emit('add', this.newBusiness);
    },
    fillBusinessName(value) {
      switch (value) {
        case 'by-law':
          return 'By-Law';
        case 'owner-occupied':
          return 'Owner Occupied Election';
        case 'general-elections':
          return 'General Election';
        case 'director-removals':
          return 'Vote for Removal of Directors';
        case 'substitute-elections':
          return 'Election of Substitute Directors';
        case 'substitute-elections-oo':
          return 'Election of Substitute Owner Occupied Directors';
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
