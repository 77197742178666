<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <h5 class="title mb-3">
          {{ getCurrentTitle }}
          {{ getPositionsText }}
        </h5>
        <v-toolbar light color="grey lighten-4 elevation-1">
          <v-text-field
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            v-model="search"
          />
          <v-spacer />
          <v-btn class="primary" @click.native="exportClicked">
            <v-icon>launch</v-icon> Export as CSV
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
          class="elevation-1"
          :rows-per-page-items="config.rowsPerPageItems"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.value"
                class="column text-xs-left"
              >
                <span>{{ header.text }}</span>
                <v-tooltip right>
                  <v-icon
                    v-if="header.value === 'vote'"
                    slot="activator"
                    small
                    color="primary"
                    class="pl-2"
                    >info</v-icon
                  >
                  <span
                    >Voters have ranked their preferred candidates<br />Optional
                    votes in italics below <br />
                    <span v-if="voteExclusions.length > 0"
                      >Ineligible votes in strikethrough below</span
                    >
                  </span>
                </v-tooltip>
              </th>
            </tr>
          </template>

          <template slot="items" slot-scope="props">
            <td
              class="text-xs-left"
              v-for="(prop, index) in headers"
              :key="`key-${index}`"
            >
              <span
                v-if="showElectionRanking && prop.value === 'vote'"
                v-html="getVotesRankedProcessed(props.item['vote'])"
              ></span>
              <span v-else-if="!showElectionRanking && prop.value === 'vote'">
                {{ getVotesNoRankedProcessed(props.item['vote']) }}
              </span>
              <span v-else>{{ props.item[prop.value] }}</span>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMeetingBusinessData } from '@/api/meetings-business';
import { findVoteExclusions } from '@/api/meetings-rtv-live-votes';
import { convertArrayOfObjectsToCSV, downloadCSV } from '@/helpers';
import _ from 'lodash';
import momentTz from 'moment-timezone';

export default {
  name: 'MeetingBusinessDataView',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      state: {},
      tableData: [],
      questionType: null,
      positions: 0,
      search: '',
      voteExclusions: [],
      headers: [
        { value: 'unit', text: 'Unit', align: 'left' },
        { value: 'email', text: 'Email', align: 'left' },
        { value: 'voter_name', text: 'Voter Name', align: 'left' },
        { value: 'vote', text: 'Vote', align: 'left' },
        { value: 'unit_shares', text: 'Unit Shares', align: 'left' },
        { value: 'address', text: 'Address', align: 'left' },
        { value: 'business_name', text: 'Business Name', align: 'left' },
        { value: 'proxy_holder', text: 'Proxy Holder', align: 'left' },
        { value: 'created_at', text: 'Date', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      },
      shortCode: this.$route.params.shortcode,
      questionId: this.$route.params.questionId
    };
  },
  watch: {
    // Dynamically generate window title
    getCurrentTitle(title) {
      document.title = `${title} - ${this.currentMeeting.name}`;
    }
  },
  computed: {
    ...mapGetters('meetings/business', ['meetingBusinessList']),

    getCurrentTitle() {
      return this.tableData.length > 0 ? this.tableData[0].business_name : '';
    },
    showElectionRanking() {
      if (
        (this.questionType === 'owner-occupied' ||
          this.questionType === 'general-elections' ||
          this.questionType === 'substitute-elections-oo' ||
          this.questionType === 'substitute-elections') &&
        this.positions > 0
      ) {
        return true;
      }
      return false;
    },
    getPositionsText() {
      if (this.showElectionRanking) {
        return `[${this.positions} ${
          this.positions === 1 ? 'position' : 'positions'
        }]`;
      } else return '';
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        // get ineligible candidates
        const { data: voteExclusions } = await findVoteExclusions(
          this.shortCode,
          this.meetingBusinessList[this.questionId].questionKey
        );
        this.voteExclusions = voteExclusions.map(({ vote }) =>
          vote.toLowerCase()
        );

        const res = await getMeetingBusinessData(
          this.shortCode,
          this.questionId
        );
        if (res.status !== 200) {
          throw new Error(res.text);
        }

        this.tableData = res.data.map(row => {
          // Retrieve question level settings
          this.questionType = row.type;
          this.positions = row.positions;
          return {
            id: row.id,
            unit: row.unit,
            email: row.email,
            voter_name: row.voter_name,
            vote: row.vote,
            unit_shares: row.votes,
            address: row.address,
            business_name: row.business_name,
            proxy_holder: row.proxy_holder,
            receipt_code: row.receipt_code,
            ip_address: row.ip_address,
            type: row.type,
            positions: row.positions,
            created_at: momentTz(row.created_at)
              .tz('America/Toronto')
              .format('YYYY-MM-DD'),
            elections_ranking: row.elections_ranking,
            source: row.source,
            source_email: row.metaData.source ? row.metaData.source.email : ''
          };
        });
      } catch (err) {
        throw err;
      }
    },
    getVotesRankedProcessed(vote) {
      let voteArray = vote;
      // strikethrough ineligible votes
      voteArray = voteArray.map((vote, index) => {
        if (this.voteExclusions.includes(vote.toLowerCase())) {
          vote = `<s>${vote}</s>`;
        }

        // primary votes
        if (index < this.positions) {
          return `<span>${vote}</span>`;
        }
        // optional votes
        return `<i style="font-weight: 100; color: rgba(0, 0, 0, 0.5) ">${vote}</i>`;
      });
      return voteArray.join(', ');
    },
    getVotesNoRankedProcessed(vote) {
      return vote.join(', ');
    },
    generateFileName(questionTitle) {
      const shortCode = this.$route.params.shortcode;
      const dateStr = momentTz().format('YYYYMMDDhhmmss');
      const fileName =
        questionTitle.length > 100
          ? questionTitle.substring(0, 100).trim()
          : questionTitle;
      return `${shortCode.toUpperCase()}_${fileName}_${dateStr} data export.csv`;
    },
    exportClicked() {
      // Do not mutate original table data
      const tableDataClone = _.cloneDeep(this.tableData);

      let CSVData = convertArrayOfObjectsToCSV(tableDataClone);

      // Generate a sanitized filename for the advance voting breakdown
      const fileName = this.generateFileName(this.getCurrentTitle);
      downloadCSV(fileName, CSVData);
    }
  }
};
</script>

<style scoped>
.optional-votes {
  font-weight: 100;
  color: rgba(0, 0, 0, 0.5);
}
</style>
