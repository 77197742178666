<template>
  <section class="loading-screen">
    <!-- Error -->
    <div class="load-center">
      <div v-if="code === 503">
        <span class="title">Sorry, the GetQuorum Admin Panel is currently unavailable.</span><br />
        <span class="subheading">Please try again later.</span><br />

        <v-btn class="primary" @click.native="logout">
          Log Out
        </v-btn>
        <span class="caption error-code">{{ code }} - {{ message }}</span>
      </div>

      <div v-if="code === 404">
        <span class="title">Sorry, this page could not be found.</span><br />
        <span class="subheading">Please verify the URL is correct before trying again.</span><br />
        <span class="caption error-code">{{ code }} - {{ message }}</span>
      </div>

      <div v-if="code !== 503 && code !== 404">
        <span class="title">Error loading, please try again.</span>
        <br />
        <v-btn class="primary" @click.native="reload">
          Reload<v-icon>refresh</v-icon>
        </v-btn>
        <span class="caption error-code">{{ code }} - {{ message }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorStatus',
  props: {
    code: { type: Number, default: 0 },
    message: { type: String, default: '' }
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    logout() {
      this.$router.push({ name: 'logout' });
    }
  }
};
</script>

<style scoped>
.load-center div {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
</style>
