const additionalServices = {
  meeting: [
    {
      type: 'checkbox',
      title: 'Digital candidate nomination tool',
      info: 'Allow members to fill out their candidate disclosure form electronically and upload a resume. The form and applicable documents will be sent directly to your email and stored on the GetQuorum Dashboard.',
      pricing: 'No additional charge',
      value: 'additionalCandidateNominationTool'
    },
    {
      type: 'checkbox',
      title: 'Printing and mailing services for distributions',
      info: 'GetQuorum can print and mail your notice package alongside the approved electronic distribution',
      pricing: '$0.13 per page and $1.20 per envelope, standard shipping rates',
      value: 'additionalServicesPrintingMailing'
    },
    {
      type: 'dropdown',
      title: 'Who should a printed package be mailed to?',
      value: 'printedPackageMailedTo',
      dependsOn: {
        question: 'additionalServicesPrintingMailing',
        value: true
      },
      items: [
        {
          title: 'All members without an email on file',
          value: 'all-members-without-email'
        },
        {
          title: 'Only members who have not provided e-consent',
          value: 'not-provided-e-consent-members'
        }
      ]
    }
  ],
  meeting_townhall: [
    {
      type: 'checkbox',
      title: 'Digital candidate nomination tool',
      info: 'Allow members to fill out their candidate disclosure form electronically and upload a resume. The form and applicable documents will be sent directly to your email and stored on the GetQuorum Dashboard.',
      pricing: 'No additional charge',
      value: 'additionalCandidateNominationTool'
    },
    {
      type: 'checkbox',
      title: 'Meeting moderation',
      info: 'Have your meeting fully managed and moderated by a member of the GetQuorum team.',
      pricing:
        'Your first moderation service is free. Any additional meeting moderation services will be charged at a rate of $600 per meeting.',
      value: 'additionalMeetingModerationServices'
    },
    {
      type: 'checkbox',
      title: 'Printing and mailing services for distributions',
      info: 'GetQuorum can print and mail your notice package alongside the approved electronic distribution',
      pricing: '$0.13 per page and $1.20 per envelope, standard shipping rates',
      value: 'additionalServicesPrintingMailing'
    },
    {
      type: 'dropdown',
      title: 'Who should a printed package be mailed to?',
      value: 'printedPackageMailedTo',
      dependsOn: {
        question: 'additionalServicesPrintingMailing',
        value: true
      },
      items: [
        {
          title: 'All members without an email on file',
          value: 'all-members-without-email'
        },
        {
          title: 'Only members who have not provided e-consent',
          value: 'not-provided-e-consent-members'
        }
      ]
    }
  ],
  campaign_budget: [
    {
      type: 'checkbox',
      title: 'Printing and mailing services for distributions',
      info: 'GetQuorum can print and mail your notice package alongside the approved electronic distribution',
      pricing: '$0.13 per page and $1.20 per envelope, standard shipping rates',
      value: 'additionalServicesPrintingMailing'
    },
    {
      type: 'dropdown',
      title: 'Who should a printed package be mailed to?',
      value: 'printedPackageMailedTo',
      dependsOn: {
        question: 'additionalServicesPrintingMailing',
        value: true
      },
      items: [
        {
          title: 'All members without an email on file',
          value: 'all-members-without-email'
        },
        {
          title: 'Only members who have not provided e-consent',
          value: 'not-provided-e-consent-members'
        }
      ]
    }
  ],
  campaign_certs: [
    {
      type: 'checkbox',
      title: 'Printing and mailing services for distributions',
      info: 'GetQuorum can print and mail your notice package alongside the approved electronic distribution',
      pricing: '$0.13 per page and $1.20 per envelope, standard shipping rates',
      value: 'additionalServicesPrintingMailing'
    },
    {
      type: 'dropdown',
      title: 'Who should a printed package be mailed to?',
      value: 'printedPackageMailedTo',
      dependsOn: {
        question: 'additionalServicesPrintingMailing',
        value: true
      },
      items: [
        {
          title: 'All members without an email on file',
          value: 'all-members-without-email'
        },
        {
          title: 'Only members who have not provided e-consent',
          value: 'not-provided-e-consent-members'
        }
      ]
    }
  ],
  campaign_advance: [
    {
      type: 'checkbox',
      title: 'Digital candidate nomination tool',
      info: 'Allow members to fill out their candidate disclosure form electronically and upload a resume. The form and applicable documents will be sent directly to your email and stored on the GetQuorum Dashboard.',
      pricing: 'No additional charge',
      value: 'additionalCandidateNominationTool'
    },
    {
      type: 'checkbox',
      title: 'Printing and mailing services for distributions',
      info: 'GetQuorum can print and mail your notice package alongside the approved electronic distribution',
      pricing: '$0.13 per page and $1.20 per envelope, standard shipping rates',
      value: 'additionalServicesPrintingMailing'
    },
    {
      type: 'dropdown',
      title: 'Who should a printed package be mailed to?',
      value: 'printedPackageMailedTo',
      dependsOn: {
        question: 'additionalServicesPrintingMailing',
        value: true
      },
      items: [
        {
          title: 'All members without an email on file',
          value: 'all-members-without-email'
        },
        {
          title: 'Only members who have not provided e-consent',
          value: 'not-provided-e-consent-members'
        }
      ]
    }
  ]
};

export default additionalServices;
