<template>
  <div>
    <v-layout column>
      <v-toolbar dense color="grey lighten-4 elevation-1">
        <v-text-field
          light
          prepend-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
          v-model="search"
        />
        <v-spacer />
        <v-btn class="primary" dark @click.native="emitOpenAddDialog">
          <v-icon left>add</v-icon>new group
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :rows-per-page-items="config.rowsPerPageItems"
        :pagination.sync="config.pagination"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <tr :key="props.item.key" class="pointer">
            <td class="text-xs-left" @click="rowClicked(props.item.key)">
              {{ props.item.name }}
            </td>
            <td class="text-xs-left" @click="rowClicked(props.item.key)">
              {{
                props.item.checklistTemplates
                  ? props.item.checklistTemplates.length
                  : 0
              }}
            </td>
            <td class="text-xs-left" @click="rowClicked(props.item.key)">
              {{ props.item.updatedAt | dateFromNow }}
            </td>
            <td class="text-xs-left">
              <v-tooltip v-if="props.item.isDefaultGroup" bottom>
                <v-icon color="green" slot="activator">check</v-icon>
                <span>
                  This group will be pre-selected by default when adding and
                  assigning checklists.
                </span>
              </v-tooltip>
            </td>
            <td class="text-xs-left">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-0" v-on="on" depressed fab small>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-tile
                    :disabled="props.item.isDefaultGroup"
                    @click="openDialog(props.item)"
                  >
                    <v-list-tile-content
                      >Set as Default Group</v-list-tile-content
                    ></v-list-tile
                  >
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>

    <!-- Set Default Group dialog -->
    <v-dialog persistent max-width="600px" :value="dialog.setDefault">
      <v-card>
        <v-card-title>
          <div class="title">Set as Default Group?</div>
        </v-card-title>
        <v-card-text>
          <p>
            Would you like to set
            <b>{{ dialog.group ? dialog.group.name : '' }}</b> as the default
            checklist template group?
          </p>
          <p>
            This group will now be pre-selected when adding and assigning
            checklists.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="clearDialog">cancel</v-btn>
          <v-spacer />
          <v-btn class="primary" @click="updateDefault">confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as filters from '@/filters';

export default {
  name: 'AdminChecklistTemplateGroupsTable',
  filters: {
    ...filters
  },
  props: {
    state: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        {
          text: 'Number of Checklist Templates',
          value: 'templates',
          align: 'left',
          sortable: false
        },
        { text: 'Updated', value: 'updatedAt', align: 'left' },
        {
          text: 'Default Checklist Group',
          value: 'isDefaultGroup',
          align: 'left',
          sortable: false
        },
        { text: 'Options', align: 'left', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 20, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'name',
          descending: false
        }
      },
      dialog: {
        setDefault: false,
        group: null
      }
    };
  },
  computed: {
    items() {
      return this.state;
    }
  },
  methods: {
    ...mapActions('checklistTemplateGroups', ['updateChecklistTemplateGroup']),
    emitOpenAddDialog() {
      this.$emit('new-button-clicked');
    },
    rowClicked(key) {
      this.$emit('row-clicked', key);
    },
    openDialog(group) {
      this.dialog.group = group;
      this.dialog.setDefault = true;
    },
    clearDialog() {
      this.dialog.group = null;
      this.dialog.setDefault = false;
    },
    async updateDefault() {
      try {
        this.items.forEach(checklistGroup => {
          let payload;

          // Update if this is the new default group (isDefaultGroup = true)
          if (checklistGroup.key === this.dialog.group.key) {
            payload = {
              key: checklistGroup.key,
              name: checklistGroup.name,
              description: checklistGroup.description,
              isDefaultGroup: true
            };
          }

          // Or, update if this is the previous default group (isDefaultGroup = false)
          if (checklistGroup.isDefaultGroup) {
            payload = {
              key: checklistGroup.key,
              name: checklistGroup.name,
              description: checklistGroup.description,
              isDefaultGroup: false
            };
          }

          if (payload) {
            this.updateChecklistTemplateGroup({
              key: checklistGroup.key,
              payload: payload
            });
          }
        });

        this.clearDialog();

        this.$events.$emit(
          'toastEvent',
          'Default Checklist Template Group Updated'
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
