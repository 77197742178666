<template>
  <v-layout column>
    <v-toolbar dense color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />
      <v-btn class="primary" dark @click.native="emitOpenAddDialog">
        <v-icon left>add</v-icon>New
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="config.pagination"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr
          :key="props.item.key"
          @click="rowClicked(props.item.key)"
          class="pointer"
        >
          <td class="text-xs-left">
            {{ props.item.name }}
            <v-chip
              class="white--text"
              color="accent"
              label
              small
              outline
              disabled
              v-if="props.item.meta.urgent"
              >urgent</v-chip
            >
          </td>
          <td class="text-xs-left">
            {{
              props.item.conversationTemplates
                ? props.item.conversationTemplates.length
                : 0
            }}
          </td>
          <td class="text-xs-left">{{ props.item.updatedAt | dateFromNow }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import * as filters from '@/filters';

export default {
  name: 'AdminConversationTemplateGroupsTable',
  filters: {
    ...filters
  },
  props: {
    state: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        {
          text: '# of Templates',
          value: 'templates',
          align: 'left',
          sortable: false
        },
        { text: 'Updated', value: 'updatedAt', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [20, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'updatedAt',
          descending: true
        }
      }
    };
  },
  computed: {
    items() {
      return this.state;
    }
  },
  methods: {
    emitOpenAddDialog() {
      this.$emit('new-button-clicked');
    },
    rowClicked(key) {
      this.$emit('row-clicked', key);
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
