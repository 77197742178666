<template>
  <section>
    <v-layout align-baseline>
      <div class="gq-cell--section-title">Voter Attendance</div>
    </v-layout>
    <v-toolbar light class="grey lighten-4 elevation-1">
      <v-text-field
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        :value="searchQuery"
        @input="updateSearchQuery($event)"
      />
      <v-spacer />
      <template class="buttons-slot">
        <div>
          <v-menu :disabled="!isSelectedRows" offset-y>
            <v-btn
              :disabled="!isSelectedRows"
              slot="activator"
              light
              flat
              color="blue"
            >
              Bulk Actions ({{ numOfSelectedRows }})
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile @click="$emit('openDialog', 'sendBallot')">
                <v-list-tile-action>
                  <v-icon color="green">email</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Send Ballot Email(s)</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile @click="$emit('openDialog', 'deleteBallot')">
                <v-list-tile-action>
                  <v-icon color="red">refresh</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Reset Ballot(s)</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>

        <!-- SAVE FOR LATER
        <v-btn class="primary" @click.native="$emit('openDialog', 'addBallot')">
          <v-icon>add</v-icon>Add Proxy
        </v-btn>
        -->
      </template>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="tableData"
      :search="searchQuery"
      :sort-by="['unit']"
      v-model="selected"
      @input="emitSelected"
      select-all
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr :key="props.index">
          <td>
            <v-checkbox primary hide-details v-model="props.selected" />
          </td>
          <td class="text-xs-left" @click="viewBallotStatus(props.item, props)">
            {{ props.item.unit }}
          </td>
          <td class="text-xs-left" @click="viewBallotStatus(props.item, props)">
            {{ props.item.email }}
          </td>
          <td
            class="text-xs-left name-overflow"
            @click="viewBallotStatus(props.item, props)"
          >
            {{ props.item.name }}
          </td>
          <td
            class="text-xs-left text-highlight"
            @click="viewBallotStatus(props.item, props)"
          >
            <v-tooltip bottom>
              <v-icon
                small
                color="green"
                slot="activator"
                v-if="props.item.claimRequestKey"
                >check</v-icon
              >
              <span>{{
                props.item.requestedAt
                  ? formatDateReadable(props.item.requestedAt, true)
                  : 'Not Available'
              }}</span>
            </v-tooltip>
          </td>
          <td
            class="text-xs-left text-highlight"
            @click="viewBallotStatus(props.item, props)"
          >
            <v-tooltip bottom>
              <v-icon
                small
                color="green"
                slot="activator"
                v-if="props.item.ballotKey"
                >check</v-icon
              >
              <span>{{
                props.item.ballotIssued
                  ? formatDateReadable(props.item.ballotIssued, true)
                  : 'Not Available'
              }}</span>
            </v-tooltip>
          </td>
          <td
            class="text-xs-left text-highlight"
            @click="viewBallotStatus(props.item, props)"
          >
            {{ props.item.ballotKey ? props.item.unitsClaimed : '' }}
          </td>
          <td
            class="text-xs-left text-highlight"
            @click="viewBallotStatus(props.item, props)"
          >
            <v-tooltip bottom>
              <v-icon slot="activator" color="teal" v-if="props.item.holderName"
                >how_to_vote</v-icon
              >
              <span>Proxy: {{ props.item.holderName }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { formatDateHumanReadable } from '@/helpers';
import { config } from '@/config/index.js';

export default {
  name: 'MeetingRtvBallotsListViewTable',
  props: {
    ballotListData: {
      type: Array,
      default() {
        return [];
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: 'Unit', value: 'unit', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Owner Name', value: 'name', align: 'left' },
        { text: 'Registered', value: 'claimRequestKey', align: 'left' },
        { text: 'Claimed', value: 'ballotKey', align: 'left' },
        { text: 'Units Claimed', value: 'unitsClaimed', align: 'left' },
        { text: 'Advance Vote', value: 'unitsClaimed', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },
  watch: {
    selected: function(selected) {
      this.emitSelected(selected);
    }
  },
  computed: {
    ...mapGetters('meetings/ballots', ['searchQuery']),
    tableData() {
      let items = [...this.ballotListData];
      return items;
    },
    isSelectedRows() {
      return this.selected.length > 0;
    },
    numOfSelectedRows() {
      return this.selected.length;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  methods: {
    // Row Selection (radio boxes)
    emitSelected(selected) {
      this.$emit('selected', selected);
    },
    ...mapActions('meetings/ballots', ['setSearchQuery']),
    selectRows(type) {
      // You have to clear the search, as it filters
      this.setSearchQuery('');
      this.selected = this.getFilteredSelection(type);
    },
    // Returns selected rows based on filter type
    getFilteredSelection(type) {
      switch (type) {
        case 'all':
          return this.ballotListData;
        case 'clear':
          return [];
        default:
          break;
      }
    },
    viewBallotStatus(item, props) {
      this.$emit('targetEmail', item.email);
    },
    // Helper
    formatDateReadable(date, includeTime) {
      return formatDateHumanReadable(date, includeTime, this.meetingTimezone);
    },
    updateSearchQuery: _.debounce(function(value) {
      this.setSearchQuery(value);
    }, 350)
  }
};
</script>

<style scoped>
.text-overflow {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name-overflow {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expand-row-style {
  background-color: #eeeeee;
}
.pointer {
  cursor: pointer;
}
</style>
