<template>
  <v-container grid-list-md fluid>
    <div v-if="!this.loading && !this.error">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-card>
            <v-card-text>
              <v-layout row>
                <v-flex xs6>
                  <span class="display-1">{{ uniqueOpenRate }}%</span>
                  <br />
                  <span class="caption">Open Rate</span>
                </v-flex>
                <v-flex xs6>
                  <span class="display-1">{{ stats.uniqueOpens }}</span>
                  <br />
                  <span class="caption">Opens</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6>
          <v-card>
            <v-card-text>
              <v-layout row>
                <v-flex xs6>
                  <span class="display-1">{{ uniqueClickRate }}%</span>
                  <br />
                  <span class="caption">Click Rate</span>
                </v-flex>
                <v-flex xs6>
                  <span class="display-1">{{ stats.uniqueClicks }}</span>
                  <br />
                  <span class="caption">Clicks</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6 md3>
          <v-card>
            <v-card-text>
              <span class="title">{{ stats.sent }}</span>
              <br />
              <span class="caption">Total Sent</span>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md3>
          <v-card>
            <v-card-text>
              <span class="title">{{ stats.opens }}</span>
              <br />
              <span class="caption">Total Opens</span>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md3>
          <v-card>
            <v-card-text>
              <span class="title">{{ stats.clicks }}</span>
              <br />
              <span class="caption">Total Clicks</span>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md3>
          <v-card>
            <v-card-text>
              <v-layout row>
                <v-flex xs6>
                  <span class="title">{{ stats.bounces }}</span>
                  <br />
                  <span class="caption">Bounces</span>
                </v-flex>
                <v-flex xs6>
                  <span class="title">{{ stats.unsubscribes }}</span>
                  <br />
                  <span class="caption">Unsubscribes</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row nowrap justify-end>
        <v-flex xs12>
          <p class="caption text-xs-right mt-2">Last updated {{ updatedAt }}</p>
        </v-flex>
      </v-layout>
    </div>

    <div v-else-if="this.error">
      <v-alert :value="true" type="error">
        <span><b>Error Loading Stats</b></span>
      </v-alert>
    </div>

    <div v-else-if="this.loading">
      <v-flex xs12>
        <v-card>
          <v-card-title class="text-xs-center">
            <h2>Loading Stats</h2>
            <v-progress-linear color="primary" height="6" indeterminate />
          </v-card-title>
        </v-card>
      </v-flex>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'NoticeStatsSummary',
  props: {
    stats: {
      type: Object,
      default() {
        return {
          sent: 0,
          opens: 0,
          uniqueOpens: 0,
          clicks: 0,
          uniqueClicks: 0,
          bounces: 0,
          unsubscribes: 0,
          updatedAt: null
        };
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      default() {
        return 'America/Toronto';
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    uniqueOpenRate() {
      return this.hasSents
        ? this.formatPercentage(
            (this.stats.uniqueOpens / this.stats.sent) * 100
          )
        : 0;
    },
    uniqueClickRate() {
      return this.hasSents
        ? this.formatPercentage(
            (this.stats.uniqueClicks / this.stats.sent) * 100
          )
        : 0;
    },
    hasSents() {
      return this.stats.sent > 0;
    },
    hasOpens() {
      return this.stats.opens > 0;
    },
    hasClicks() {
      return this.stats.clicks > 0;
    },
    updatedAt() {
      if (this.stats && this.stats.updatedAt) {
        return moment(this.stats.updatedAt)
          .tz(this.timezone)
          .fromNow();
      }
    }
  },
  created() {
    // this.getStats();
  },
  methods: {
    // async getStats() {
    //   try {
    //     const res = await getNoticeStats(this.noticeKey);
    //     if (res.status >= 400) {
    //       return this.$events.$emit('showErrorDialog', res.statusText);
    //     }
    //     this.stats = res.data;
    //   } catch (err) {
    //     this.$events.$emit('showErrorDialog', err.response);
    //     throw err;
    //   }
    // },
    formatPercentage(val) {
      return val.toLocaleString('en', {
        maximumFractionDigits: 1
      });
    }
  }
};
</script>

<style scoped></style>
