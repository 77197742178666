<template>
  <div class="mt-2">
    <span v-if="questions.length > 0">
      <div v-for="question in questions" :key="question.question_key">
        <v-layout>
          <div class="gq-cell--section-title ml-2 mb-1">
            {{ question.label }}

            <live-vote-timer :question="question" @closed="emitVotingStopped" />

            <div v-if="hasPositions(question)" style="font-size: 12px;">
              [{{ question.positions }}
              {{ question.positions === 1 ? 'position' : 'positions' }}]
            </div>
          </div>
          <v-spacer />
          <div class="body-2 mr-2 mt-4">
            <span class="caption"
              >{{ showStartTime(question.start_at)
              }}<timezone-helper
                v-if="question.start_at"
                :date="question.start_at"
              />
            </span>
          </div>
        </v-layout>
        <v-layout row class="mb-4">
          <v-flex xs12>
            <vote-tally-combined-table-v3
              :question="question"
              :display-rounded-results="displayRoundedResults"
              :rounded-results-precision="roundedResultsPrecision"
              :display-vote-results-toggle="displayVoteResultsToggle"
              :metric="metric"
              @save-additional-vote="saveAdditionalVoteV3"
              @delete-additional-vote="deleteAdditionalVoteV3"
              @toggle-exclude-vote="toggleExcludeVoteV3"
              @add-option="addOptionV3"
              @cancel-option="cancelOptionV3"
              @save-option-changes-v3="saveOptionChangesV3"
              @cancel-option-changes="cancelOptionChangesV3"
              @enable-save-changes="enableSaveOptionChangesV3"
              @view-write-in-vote="emitViewWriteInVote"
            />
          </v-flex>
        </v-layout>
      </div>
    </span>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapActions } from 'vuex';
import {
  saveMeetingAdditionalVote,
  deleteAdditionalVote
} from '@/api/meetings-additional-votes';
import VoteTallyCombinedTableV3 from '@/components/VoteTallyCombinedTableV3';
import TimezoneHelper from '@/components/TimezoneHelper';
import LiveVoteTimer from '@/components/LiveVoteTimer';

export default {
  name: 'VoteTallyCombinedV3',
  components: {
    VoteTallyCombinedTableV3,
    TimezoneHelper,
    LiveVoteTimer
  },
  props: {
    tallyDataV3: {
      type: Array,
      default() {
        return [];
      }
    },
    meetingTimezone: {
      type: String,
      default: 'America/Toronto'
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default: '0'
    },
    displayVoteResultsToggle: {
      type: Boolean,
      default() {
        return false;
      }
    },
    metric: {
      type: String,
      default: 'shares'
    }
  },
  async created() {
    await this.getMeetingRTVQuestions({
      shortCode: this.$route.params.shortcode
    });
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    questions() {
      return this.prepareDataV3();
    }
  },
  methods: {
    ...mapActions('meetings/rtvQuestions', ['getMeetingRTVQuestions']),
    ...mapActions('meetings/proxies', [
      'deleteMeetingPaperProxy',
      'saveMeetingPaperProxies'
    ]),
    ...mapActions('meetings/liveVotes', ['toggleVoteExclusion']),
    prepareDataV3() {
      // 1) Calculate the total votes from what has been stored
      // 2) Handle save status
      // 3) Handle tally exclusions
      return _.map(this.tallyDataV3, question => {
        // Keep a flag for each question that could potentially be edited
        // Determines whether the save button is activated for the question
        if (question.canSave === undefined) {
          question.canSave = false;
        }

        _.map(question.tally, answer => {
          // Excluded votes are shifted to the bottom of the tables
          if (answer.isExcluded) {
            answer.numVotesTotal = '-';
            answer.numVotesTotalUnits = '-';
            return answer;
          }

          // Keep track of the total number of votes from stored values
          // 'shares' metric
          answer.numVotesTotal =
            answer.numVotes +
            answer.numVotesAdv +
            answer.numAdditionalVotesTotal;
          // 'units' metric
          answer.numVotesTotalUnits =
            (answer.numUnits || 0) +
            answer.numUnitsAdv +
            answer.numAdditionalVotesUnitsTotal;

          return answer;
        });
        return question;
      });
    },

    // Additional Votes
    async saveAdditionalVoteV3(payload) {
      // Save the changes - applied on the advance question reference
      try {
        await saveMeetingAdditionalVote({
          shortCode: this.shortCode,
          questionKey: payload.questionKey,
          vote: payload.vote,
          voteItem: payload.voteItem,
          createdAtName: payload.createdAtName,
          total: payload.total,
          metric: payload.metric
        });
        this.$events.$emit('toastEvent', 'Changes saved');
        this.emitRefreshTallyV3();
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be saved, invalid input found'
        );
      }
    },
    async deleteAdditionalVoteV3({
      additionalVoteKey,
      message,
      deletedAtName
    }) {
      try {
        await deleteAdditionalVote({
          shortCode: this.shortCode,
          additionalVoteKey,
          message,
          deletedAtName
        });
        this.$events.$emit('toastEvent', 'Additional note deleted');
        this.emitRefreshTallyV3();
      } catch {
        this.$events.$emit('toastEvent', 'delete error');
      }
    },

    // Vote Exclusion
    async toggleExcludeVoteV3({ questionKey, exclusion }) {
      try {
        await this.toggleVoteExclusion({
          shortCode: this.shortCode,
          questionKey,
          exclusion
        });

        // Refresh the combined vote tally after successfull proxy revokes
        this.emitRefreshTallyV3();
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Could not toggle exclusion, please try again'
        );
      }
    },

    // Options
    addOptionV3(questionId) {
      _.map(this.tallyDataV3, question => {
        if (question.id === questionId) {
          let tallyWithNewLine = question.tally;
          const blankOption = {
            newOptionKey: `new-${tallyWithNewLine.length}`,
            vote: '',
            voteItem: '',
            total: null
          };
          tallyWithNewLine.push(blankOption);
          question.tally = tallyWithNewLine;
        }
        return question;
      });
    },
    cancelOptionV3(params) {
      let { questionId, newOptionKey } = params;

      // Find and remove the target option passed
      _.map(this.tallyDataV3, question => {
        if (question.id === questionId) {
          // Clone the question tally so we can operate on it
          const filterTally = _.cloneDeep(question.tally);
          _.remove(filterTally, {
            newOptionKey: newOptionKey
          });
          question.tally = filterTally;
          this.$events.$emit('toastEvent', 'Option removed');
        }
        return question;
      });
    },
    async saveOptionChangesV3(option) {
      let { questionId, createdAtName } = option;

      const saveQuestion = _.find(this.tallyDataV3, {
        id: questionId
      });

      try {
        // Loop through each tally, determine if it's a newOption
        // If found, save to AdditionalVotes table
        // "VoteItem" is blank, as that's a subitem for a vote
        // "Total" will always be zero when saving a new option
        for (const item of saveQuestion.tally) {
          if (_.has(item, 'newOptionKey')) {
            let payload = {
              shortCode: this.shortCode,
              questionKey: saveQuestion.question_key,
              vote: item.vote,
              voteItem: item.voteItem,
              createdAtName,
              total: _.toInteger(item.total),
              metric: this.metric
            };
            await this.saveAdditionalVoteV3(payload);
          }
        }

        this.$events.$emit('toastEvent', 'Changes saved');
      } catch {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be saved, invalid input found'
        );
      } finally {
        this.emitRefreshTallyV3();
      }
    },
    async cancelOptionChangesV3(questionId) {
      // Find and reset question
      _.map(this.tallyDataV3, question => {
        // Find the target question
        if (question.id === questionId) {
          // Reset and remove unsaved new line items
          question.tally = _.filter(question.tally, 'answerId');
          question.canSave = false;
        }
        return question;
      });
      this.$events.$emit('toastEvent', 'Changes have been reset');
    },
    async enableSaveOptionChangesV3(questionId) {
      // Enable the save button for the target question
      _.map(this.tallyDataV3, question => {
        // Find the target question
        if (question.id === questionId) {
          question.canSave = true;
        } else {
          question.canSave = false;
        }
        return question;
      });
    },

    // Emit events
    emitViewWriteInVote(params) {
      this.$emit('view-write-in-vote', params);
    },
    emitVotingStopped(params) {
      this.$emit('voting-stopped', params);
    },
    emitRefreshTallyV3() {
      this.$emit('refresh-tally-v3');
    },

    // Native vote tally methods
    showStartTime(date) {
      return !date
        ? ''
        : `Voting started at ${moment(date)
            .tz(this.meetingTimezone ? this.meetingTimezone : 'America/Toronto')
            .format('h:mm:ss a zz')}`;
    },
    hasPositions(question) {
      if (
        (question.type === 'owner-occupied' ||
          question.type === 'general-elections') &&
        question.positions > 0
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style scoped></style>
