<template>
  <div>
    <p class="title mb-3">Confirm Campaign Details</p>
    <v-text-field
      label="Name"
      placeholder="e.g. TSCC 1234 - AGM 2017"
      maxlength="100"
      :value="name"
      @input="emitUpdate({ key: 'name', value: $event })"
    />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingCreateConfirm',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shortCodeValid: true
    };
  },
  methods: {
    emitUpdate(payload) {
      this.$emit('update', payload);
    }
  }
};
</script>

<style></style>
