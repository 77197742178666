'use strict';

/**
 * @fileoverview Vuex module for meeting business
 */

import _ from 'lodash';
import Vue from 'vue';

import { getInvoiceList } from '@/api/invoices';
import {
  postMeetingInvoice,
  postChargebeeMeetingInvoice
} from '@/api/meetings-billing';
import {
  getMeetingBilling,
  updateMeetingBilling,
  postMeetingBillingItem,
  deleteMeetingBillingItem,
  putMeetingBillingItem
} from '@/api/meetings-billing';

const state = {
  settings: {},
  meetingInvoiceList: {},
  dataLastFetchedAt: null
};

const getters = {
  billingSettings: state => state.settings,
  tax: state => state.settings.tax,
  moderatorFee: state => state.settings.moderatorFee,
  printJobs: state => {
    return state.settings.printJobs || [];
  },
  discount: state => state.settings.discount,
  items: state => state.settings.items,
  expenses: state => {
    return state.settings.items
      ? state.settings.items.filter(i => i.type === 'expense')
      : [];
  },
  discounts: state => {
    return state.settings.items
      ? state.settings.items.filter(i => i.type === 'discount')
      : [];
  },
  agent: state => state.settings.agent,
  meetingInvoiceList: state => state.meetingInvoiceList,

  // Get the meeting business as array sorted by order ascending
  meetingInvoiceData: state => {
    return Object.values(state.meetingInvoiceList);
  }
};

const actions = {
  /**
   * Get meeting's billing settings
   * @param {String} {shortCode} Meeting shortCode
   */

  async getBillingSettings({ commit }, { shortCode }) {
    try {
      const { data } = await getMeetingBilling({ shortCode });

      commit('SET_MEETING_BILLING_SETTINGS', data);
    } catch (err) {
      console.error('Error: getSettings action', err);
      throw err;
    }
  },

  /**
   * Update meeting's billing settings
   * @param {String} {shortCode} Meeting shortCode
   * @param {Object} {payload} Billing settings values
   */

  async updateBillingSettings({ dispatch }, { shortCode, payload }) {
    try {
      await updateMeetingBilling({ shortCode, payload });
      dispatch('getBillingSettings', { shortCode });
    } catch (err) {
      console.error('Error: updateBillingSettings action', err);
      throw err;
    }
  },

  /**
   * List the invoices for a given meeting
   * @param {String} {shortCode} Meeting shortCode
   */
  async getMeetingInvoiceList({ commit }, { shortCode }) {
    try {
      const {
        data: { invoices }
      } = await getInvoiceList({ shortCode });
      const data = invoices.map(i => {
        return { ...i, hasSentViews: i.sentViews.length > 0 };
      });
      commit('SET_MEETING_INVOICE_LIST', data);
    } catch (err) {
      console.error('Error: getMeetingInvoiceList action', err);
      throw err;
    }
  },

  /**
   * Stubs a new invoice for this meeting.
   *
   * @param {String} {shortCode}  Meeting shortCode
   */
  async stubNewMeetingInvoice({ commit }, { shortCode }) {
    try {
      const res = await postMeetingInvoice({ shortCode });
      commit('SET_MEETING_INVOICE_IN_LIST', res.data);
    } catch (err) {
      console.error('Error: addMeetingInvoice action', err);
      throw err;
    }
  },

  /**
   * Creates a new invoice for this meeting inside Chargebee.
   *
   * @param {String}   {shortCode}    Meeting shortCode
   * @param {String}   {invoiceDate}  Invoice date
   * @param {Object[]} {items}        Invoice items
   * @param {Object[]} {printJobs}    Invoice print jobs
   */
  async createChargebeeMeetingInvoice(
    { commit },
    { shortCode, invoiceDate, items, printJobs }
  ) {
    const payload = { invoiceDate, items, printJobs };

    try {
      const res = await postChargebeeMeetingInvoice({ shortCode, payload });
      commit('SET_MEETING_INVOICE_IN_LIST', res.data);
    } catch (err) {
      console.error('Error: createChargebeeMeetingInvoice action', err);
      throw err;
    }
  },

  /**
   * Update the meeting's billing option
   * @param  {String}  {shortCode}   Meeting shortcode
   * @param  {boolean} {enabled}     If billing is enabled
   */
  async setBillingEnabled({ dispatch }, { shortCode, enabled = true }) {
    try {
      const payload = { enabled };
      await updateMeetingBilling({ shortCode, payload });
      dispatch('getBillingSettings', { shortCode });
    } catch (err) {
      console.error('Error: setBillingEnabled action', shortCode, enabled, err);
      throw err;
    }
  },

  /**
   * Create meeting billing item
   * @param  {String}  {shortCode}  Meeting shortcode
   * @param  {Object} {payload}     Billing item values
   */
  async addBillingItem({ dispatch }, { shortCode, payload }) {
    try {
      await postMeetingBillingItem({ shortCode, payload });
      dispatch('getBillingSettings', { shortCode });
    } catch (err) {
      console.error('Error: addBillingItem action', shortCode, payload, err);
      throw err;
    }
  },
  /**
   * Delete meeting billing item
   * @param  {String} {shortCode}  Meeting shortcode
   * @param  {String} {itemKey}     Item key
   */
  async deleteBillingItem({ dispatch }, { shortCode, itemKey }) {
    try {
      await deleteMeetingBillingItem({ shortCode, itemKey });
      dispatch('getBillingSettings', { shortCode });
    } catch (err) {
      console.error('Error: deleteBillingItem action', shortCode, itemKey, err);
      throw err;
    }
  },

  /**
   * Update meeting billing item
   * @param  {String} {shortCode}  Meeting shortcode
   * @param  {String} {itemKey}     Item key
   */
  async updateBillingItem({ dispatch }, { shortCode, itemKey, payload }) {
    try {
      await putMeetingBillingItem({ shortCode, itemKey, payload });
      dispatch('getBillingSettings', { shortCode });
    } catch (err) {
      console.error(
        'Error: updateBillingItem action',
        shortCode,
        itemKey,
        payload,
        err
      );
      throw err;
    }
  }
};

const mutations = {
  // Set all invoices to store
  SET_MEETING_BILLING_SETTINGS(state, data) {
    state.settings = data;
  },

  // Set all invoices to store
  SET_MEETING_INVOICE_LIST(state, data) {
    state.meetingInvoiceList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  // Set one invoice to store
  SET_MEETING_INVOICE_IN_LIST(state, invoice) {
    Vue.set(state.meetingInvoiceList, invoice.key, invoice);
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.meetingInvoiceList = {};
    state.settings = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
