<template>
  <v-container fluid>
    <!-- If not print job -->
    <v-layout v-if="!hasPrintJobs" row>
      <v-flex xs12>
        <div class="mdl-cell mdl-cell--12-col center-align">
          <p>No Print Jobs were found for this campaign.</p>
          <p>One day this will all be paperless. For now, let's create one</p>
          <v-fab-transition>
            <div class="text-xs-center">
              <v-menu bottom transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" dark color="primary">
                    <v-icon left>add_box</v-icon>Create Print Job
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile>
                    <v-list-tile-title class="caption"
                      >Select a Vendor...</v-list-tile-title
                    >
                  </v-list-tile>
                  <v-list-tile
                    v-for="(vendor, index) in vendors"
                    :key="index"
                    @click.native="addPrintJob(vendor.key)"
                  >
                    <v-list-tile-title>{{ vendor.name }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </v-fab-transition>
        </div>
      </v-flex>
    </v-layout>

    <!-- There are print jobs -->
    <v-layout v-if="hasPrintJobs" row>
      <v-flex xs12>
        <v-toolbar dark color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            v-model="search"
          />
          <v-spacer />
          <v-menu bottom transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary">
                + Add Print Job
              </v-btn>
            </template>
            <v-list>
              <v-list-tile>
                <v-list-tile-title class="caption"
                  >Select a Vendor...</v-list-tile-title
                >
              </v-list-tile>
              <v-list-tile
                v-for="(vendor, index) in vendors"
                :key="index"
                @click.native="addPrintJob(vendor.key)"
              >
                <v-list-tile-title class="print-job-link">{{
                  vendor.name
                }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="printJobs"
          item-key="jobId"
          class="elevation-1"
          :expand="expand"
          show-expand
          :search="search"
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          @update:pagination="paginationUpdate"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td @click="itemExpanded(props)" class="text-xs-left caption">
                {{ props.item.jobId }}
              </td>
              <td @click="itemExpanded(props)" class="text-xs-left caption">
                <a
                  class="printJobKey print-job-link"
                  @click="goPrintJob(props.item.key)"
                  >{{ props.item.jobName }}</a
                >
              </td>
              <td @click="itemExpanded(props)" class="text-xs-left">
                <v-chip
                  small
                  :color="getStatusChipColor(props.item)"
                  text-color="white"
                  >{{ getStatusTitle(props.item) }}</v-chip
                >
                <v-chip v-if="props.item.disableBilling" small
                  ><v-icon left color="error">warning</v-icon>Billing
                  Disabled</v-chip
                >
              </td>
              <td
                nowrap
                @click="itemExpanded(props)"
                class="text-xs-left caption"
              >
                {{ formatDate(props.item.createdAt) }}
              </td>
              <td
                nowrap
                @click="itemExpanded(props)"
                class="text-xs-left caption"
              >
                {{ formatDate(props.item.updatedAt) }}
              </td>
              <td>
                <div class="text-xs-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="
                          !['SENT', 'IN_PROGRESS', 'COMPLETED'].includes(
                            props.item.status
                          )
                        "
                        flat
                        icon
                        color="completedStatusColor(props.item.status)"
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-for="(action, index) in actions"
                        :key="index"
                        @click.native="runCommand(props.item.key, action.name)"
                      >
                        <v-list-tile-title class="caption">{{
                          props.item.status !== 'COMPLETED'
                            ? action.label
                            : 'Update Completion'
                        }}</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        @click.native="openRecipientCountDialog(props.item)"
                      >
                        <v-list-tile-title class="caption"
                          >Update Recipient Count</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile
                        @click.native="openToggleBillingDialog(props.item)"
                      >
                        <v-list-tile-title class="caption">{{
                          props.item.disableBilling
                            ? 'Enable Billing'
                            : 'Disable Billing'
                        }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <v-container grid-list-xl fluid class="grey lighten-3">
              <v-layout>
                <v-flex xs6 class="ma-0">
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Last Updated By:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span>{{
                        props.item.meta
                          ? props.item.meta.email
                          : login.profile.email
                      }}</span>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Sent On:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span>{{
                        props.item.sentAt
                          ? formatDate(props.item.sentAt)
                          : 'n/a'
                      }}</span>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Acknowledged At:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span>{{
                        props.item.acknowledgedAt
                          ? formatDate(props.item.acknowledgedAt)
                          : 'n/a'
                      }}</span>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Mailing Date:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span
                        >{{ formatDate(props.item.mailingDate) }} ({{
                          formatDate(props.item.mailingDate, 'dddd')
                        }})</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Package Page Count:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span>{{
                        props.item.packagePagesPrinted
                          ? props.item.packagePagesPrinted
                          : 'n/a'
                      }}</span>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs5 class="text-xs-right ma-1 pa-0">
                      <span class="uppercase caption">Recipient Count:</span>
                    </v-flex>
                    <v-flex xs7 class="ma-1 pa-0">
                      <span>{{
                        props.item.packagesMailed
                          ? props.item.packagesMailed
                          : 'n/a'
                      }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 class="ma-1">
                  <div>
                    <v-layout row>
                      <v-flex xs5 class="text-xs-right">
                        <span class="uppercase caption">Printing file(s):</span>
                      </v-flex>
                      <v-flex xs-7 v-if="currentItemData.printing.length > 0">
                        <div
                          v-for="(file, key) in currentItemData.printing"
                          :key="key"
                        >
                          <i>
                            <a @click="toSignedUrl(file)">{{ file.name }}</a>
                          </i>
                        </div>
                      </v-flex>
                      <v-flex v-else>
                        <div>
                          <i>no printing files attached</i>
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs5 class="text-xs-right">
                        <span class="uppercase caption">Recipient file:</span>
                      </v-flex>
                      <v-flex xs-7 v-if="currentItemData.recipients.length > 0">
                        <i>
                          <a
                            @click="toSignedUrl(currentItemData.recipients[0])"
                            >{{ currentItemData.recipients[0].name }}</a
                          >
                        </i>
                      </v-flex>
                      <v-flex v-else>
                        <i>no recipient file attached</i>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs5 class="text-xs-right">
                        <span class="uppercase caption"
                          >Proof of Completion:</span
                        >
                      </v-flex>
                      <v-flex xs-7 v-if="currentItemData.poc.length > 0">
                        <i>
                          <a @click="toSignedUrl(currentItemData.poc[0])">{{
                            currentItemData.poc[0].name
                          }}</a>
                        </i>
                      </v-flex>
                      <v-flex v-else>
                        <i>no proof of completion file attached</i>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs5 class="text-xs-right">
                        <span class="uppercase caption">Postage Cost:</span>
                      </v-flex>
                      <v-flex xs7>
                        <span>{{
                          props.item.postageCost
                            ? '$' +
                              formattedCurrencyValue(props.item.postageCost)
                            : 'n/a'
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <complete-print-job-dialog
      :shortCode="currentMeeting.shortCode"
      :is-open="showCompletePrintJobDialog"
      :poc-files="currentItemData.poc"
      :postage-cost="currentItemData.postageCost"
      @upload="handlePocUpload($event)"
      @submit="completeJob"
      @close-dialog="showCompletePrintJobDialog = false"
    >
      <span slot="title">Complete Print Job</span>
      <span slot="description"
        >Upload Proof of Completion and Mark this Print Job as Completed</span
      >
    </complete-print-job-dialog>

    <update-recipients-dialog
      :is-open="showRecipientCountDialog"
      :print-job="selected"
      @update="updateRecipientCount"
      @close="showRecipientCountDialog = false"
    />

    <toggle-billing-dialog
      :is-open="showToggleBillingDialog"
      :print-job="selected"
      @update="toggleBilling"
      @close="showToggleBillingDialog = false"
    />
  </v-container>
</template>

<script>
import { config } from '@/config/index.js';
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { formatCurrencyValue } from '@/helpers';
import nextBusinessDay from '@/lib/next-business-day';
import CompletePrintJobDialog from '@/components/dialogs/CompletePrintJobDialog';
import UpdateRecipientsDialog from '@/components/dialogs/UpdatePrintJobRecipientsDialog';
import ToggleBillingDialog from '@/components/dialogs/TogglePrintJobBillingDialog';

export default {
  name: 'MeetingPrintJobsListView',
  components: {
    CompletePrintJobDialog,
    UpdateRecipientsDialog,
    ToggleBillingDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    meetingPrintJobData: {
      type: Array,
      required: true
    },
    vendorListData: {
      type: Array,
      required: true
    },
    dataLoaded: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }],
        pagination: {}
      },
      expand: false,
      shortCode: this.$route.params.shortcode,
      headers: [
        { text: 'Print Job ID', value: 'jobId' },
        { text: 'Name', value: 'jobName' },
        { text: 'Status', value: 'status' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Updated', value: 'updatedAt' },
        { sortable: false, align: 'right' }
      ],
      search: '',
      currentItemData: {
        jobKey: '',
        printing: [],
        recipients: [{}],
        poc: [{}]
      },
      actions: [
        {
          name: 'MARK_COMPLETED',
          label: 'Mark Completed'
        }
      ],
      selected: {},
      showCompletePrintJobDialog: false,
      showRecipientCountDialog: false,
      showToggleBillingDialog: false,
      billingDisabled: false
    };
  },
  computed: {
    ...mapGetters('meetings/printJobs', [
      'searchText',
      'pagination',
      'meetingPrintJobByKey'
    ]),
    ...mapGetters(['login', 'isAdmin']),
    printJobs() {
      return this.meetingPrintJobData;
    },
    vendors() {
      return this.vendorListData;
    },
    hasPrintJobs() {
      return !_.isEmpty(this.printJobs);
    }
  },
  methods: {
    ...mapActions('meetings/printJobs', [
      'getMeetingPrintJobList',
      'stubNewMeetingPrintJob',
      'getPrintJobItems',
      'savePrintJobItems',
      'getVendors',
      'setPagination',
      'completePrintJob',
      'saveMeetingPrintJob'
    ]),
    ...mapActions('files', ['getFile']),
    formattedCurrencyValue(value) {
      return formatCurrencyValue(value);
    },
    async addPrintJob(vendorKey) {
      try {
        const legalNameShort = this.currentMeeting.corporation.legalNameShort;
        const jobName = `GetQuorum Mailing for ${this.currentMeeting.name}`;
        const mailingDate = nextBusinessDay();
        const payload = {
          shortCode: this.shortCode,
          accountKey: this.currentMeeting.accountKey,
          jobName,
          meetingKey: this.currentMeeting.key,
          vendorKey,
          mailingDate
        };
        await this.stubNewMeetingPrintJob(payload);
        this.$events.$emit('toastEvent', 'New print job added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async getItems(jobKey) {
      try {
        this.clearItems();
        const items = await this.getPrintJobItems(jobKey);
        const printJobData = this.meetingPrintJobByKey(jobKey);
        this.currentItemData.jobKey = jobKey;
        this.currentItemData.postageCost = printJobData.postageCost;
        for (let item of items) {
          if (item.jobItemType === 'printing') {
            this.currentItemData.printing.push(item.filePackage);
          } else if (item.jobItemType === 'recipients') {
            // currently, only one recipient file allowed per job
            this.currentItemData.recipients = [item.filePackage];
          } else if (item.jobItemType === 'poc') {
            // currently only one PoC file allowed per job
            this.currentItemData.poc = [item.filePackage];
          }
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      const formattedDate = moment(date).format(format);
      if (formattedDate === 'Invalid date') {
        return 'n/a';
      } else {
        return formattedDate;
      }
    },
    goPrintJob(key) {
      return this.$router.push({
        name: 'meetingPrintJobEdit',
        params: {
          key
        }
      });
    },
    goMeeting(meetingCode) {
      return this.$router.push({
        name: 'meetingOverview',
        params: {
          shortcode: meetingCode
        }
      });
    },
    getStatusChipColor(item) {
      const status = item.status;
      const getColor = status =>
        ({
          IN_PROGRESS: 'primary',
          SENT: 'primary',
          ON_HOLD: 'warning',
          COMPLETED: 'success'
        }[status] || 'secondary');
      const chipColor = getColor(status);
      return chipColor;
    },
    getStatusTitle(item) {
      const status = item.status;
      const titles = {
        DRAFT: 'Draft',
        SENT: 'Sent',
        IN_PROGRESS: 'In Progress',
        ON_HOLD: 'On Hold',
        COMPLETED: 'Completed'
      };
      return titles[status];
    },
    completedStatusColor(status) {
      return status === 'COMPLETED' ? 'success' : 'grey';
    },
    itemExpanded(props) {
      if (!props.expanded) {
        this.getItems(props.item.key);
      }
      return (props.expanded = !props.expanded);
    },
    clearItems() {
      this.currentItemData = {
        printing: [],
        recipients: [],
        poc: []
      };
    },
    paginationUpdate(e) {
      this.setPagination(e);
    },
    async toSignedUrl(fileObj) {
      try {
        const file = await this.getFile({
          key: fileObj.Key,
          bucket: fileObj.Bucket
        });
        window.open(file.data.url, '_blank');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async runCommand(jobKey, action) {
      if (action === 'MARK_COMPLETED') {
        await this.getItems(jobKey);
        this.showCompletePrintJobDialog = true;
      }
    },
    handlePocUpload(files) {
      // note: currently, only one PoC file allowed per job
      let items = [];
      if (files.length > 0) {
        let itemFile = files[0];
        let printJobItem = {
          Bucket: itemFile.Bucket,
          Key: itemFile.Key,
          size: itemFile.size,
          name: itemFile.name,
          mimetype: itemFile.mimetype,
          url: itemFile.url
        };
        items.push(printJobItem);
      }
      this.currentItemData.poc = items;
    },
    async completeJob(pocData) {
      try {
        const itemPayload = {
          printing: this.currentItemData.printing,
          recipients: this.currentItemData.recipients,
          poc: this.currentItemData.poc
        };
        await this.savePrintJobItems({
          jobKey: this.currentItemData.jobKey,
          jobItems: itemPayload,
          force: true
        });
        const payload = {
          jobKey: this.currentItemData.jobKey,
          meetingKey: this.currentMeeting.key,
          postageCost: pocData.postageCost
        };
        await this.completePrintJob(payload);
        this.showCompletePrintJobDialog = false;
        this.$events.$emit('toastEvent', 'Print Job Completed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openRecipientCountDialog(printJob) {
      this.selected = printJob;
      this.showRecipientCountDialog = true;
    },
    async updateRecipientCount(recipientCount) {
      await this.saveMeetingPrintJob({
        jobKey: this.selected.key,
        meetingKey: this.currentMeeting.key,
        payload: { ...this.selected, packagesMailed: recipientCount },
        force: true // force overwrite non-mutable print job
      });

      this.showRecipientCountDialog = false;
    },
    openToggleBillingDialog(printJob) {
      this.selected = printJob;
      this.showToggleBillingDialog = true;
    },
    async toggleBilling() {
      await this.saveMeetingPrintJob({
        jobKey: this.selected.key,
        meetingKey: this.currentMeeting.key,
        payload: {
          ...this.selected,
          disableBilling: !this.selected.disableBilling
        },
        force: true // force overwrite non-mutable print job
      });

      this.showToggleBillingDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.center-align {
  text-align: center;
}
.value-cells {
  width: 100px;
}
.print-job-link {
  cursor: pointer;
}
.printJobKey {
  text-transform: uppercase;
}
</style>
