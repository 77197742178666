const serviceDetails = {
  typeService: {
    meeting_agm: 'Annual General Meeting',
    meeting_sgm: 'Special General Meeting',
    meeting_turnover: 'Turnover Meeting',
    meeting_townhall: 'Townhall',
    campaign_email_collection: 'Voter Email Collection',
    campaign_budget: 'Budget',
    certs_pic: 'PIC',
    certs_icu: 'ICU',
    certs_rule: 'Rule Change',
    certs_government: 'Government Notice',
    campaign_advance: 'Advance Voting',
    campaign_amendment: 'Declaration Amendment'
  },
  type: {
    Virtual: 'Virtual only',
    'In-Person': 'In person only',
    Hybrid: 'Hybrid'
  },
  details: {
    ownerOccupiedQuestion: 'Owner-occupied questions',
    directorRemoval: 'Director removal',
    additionalDetails: 'Other details'
  },
  additional: {
    additionalCandidateNominationTool: 'Digital candidate nomination tool',
    additionalServicesPrintingMailing: 'Printing and mailing services',
    printedPackageMailedTo: 'Mail printed package to',
    additionalMeetingModerationServices: 'Meeting moderation'
  }
};

export default serviceDetails;
