<template>
  <v-card
    :class="isAdmin ? 'elevation-1 pointer' : 'elevation-1'"
    @click="handleClick"
  >
    <v-card-title class="mb-0 pb-0">
      <v-layout row>
        <h2 class="title mb-0 pb-0 capitalize">
          <b>{{ notice.purpose }}</b>
        </h2>
        <v-spacer />

        <v-tooltip v-if="isAdmin" bottom>
          <v-icon
            slot="activator"
            class="mb-0 pb-0"
            @click.stop="toggleResend"
            color="primary"
            >{{ notice.isResendable ? 'star' : 'star_border' }}</v-icon
          >
          <span v-if="notice.isResendable"
            >This notice can be re-sent from Membership tab</span
          >
          <span v-else>This notice cannot be re-sent from Membership tab</span>
        </v-tooltip>

        <v-icon v-if="isAdmin" class="mb-0 pb-0" @click.stop="handleCopy"
          >content_copy</v-icon
        >

        <!-- @NOTE: @mr-gorro:  what's the purpose of this preview? -->
        <!-- <v-tooltip bottom v-if="!isAdmin">
          <v-icon
            slot="activator"
            class="mb-0 pb-0"
            @click.stop="getPreview('')"
            >find_in_page</v-icon
          >
          <span>Click to Preview Email</span>
        </v-tooltip> -->
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-1 pt-0">
      <v-layout row wrap>
        <!-- Main Card -->
        <v-flex xs12 sm11 md11>
          <!-- Status Chips -->
          <div>
            <v-chip
              small
              label
              :color="statusColor"
              class="white--text ml-0 pl-0"
            >
              {{ notice.status }}
            </v-chip>

            <v-chip
              v-if="notice.options.urgent"
              small
              label
              color="accent"
              class="white--text ml-2"
            >
              urgent
            </v-chip>

            <v-chip
              v-if="notice.options.resent"
              small
              label
              color="success"
              class="white--text ml-2"
              >resend</v-chip
            >
          </div>
          <!-- END Status Chips -->

          <div>
            <span><b>To:</b> {{ notice.recipients.segment | startCase }}</span>

            <!-- Sent / Scheduled Times -->
            <span v-if="notice.status === 'sent'">
              •
              <b
                >{{ formatSentAt }}
                <timezone-helper v-if="notice.sentAt" :date="notice.sentAt"
              /></b>
            </span>
            <span v-else>
              •
              <b
                >{{ formatSendOn
                }}<timezone-helper v-if="notice.sendOn" :date="notice.sendOn"
              /></b>
            </span>
            <!-- END Sent / Scheduled Times -->
          </div>

          <div class="subject-text"><b>Subject:</b> {{ notice.subject }}</div>

          <div
            v-for="(attach, index) in notice.attachments"
            :key="`key-${index}`"
          >
            <v-icon small class="mr-1">attachment</v-icon>
            <a :href="attach.url" class="file-font accent--text pr-1">{{
              attach.name
            }}</a>
          </div>
          <div>
            <span v-if="hasUnitFilesIncluded" class="file-font">
              <v-icon small class="mr-1">description</v-icon>includes unit files
            </span>
            <span
              v-if="!hasAttachments && !hasUnitFilesIncluded"
              class="file-font"
            >
              <v-icon small class="mr-1">attachment</v-icon>no attachments
            </span>
          </div>
        </v-flex>

        <!-- Stats Items -->
        <v-flex sm1 md1 class="text-xs-right">
          <!-- Show stats if sent and done loading -->
          <div v-if="isSent && !isStatsLoading && !isStatsLoadError">
            <notice-card-stats
              class="mt-3 mr-0 pr-0"
              :stats="notice.stats"
              :timezone="meetingTimezone"
            />
          </div>

          <!-- Show loading if loading -->
          <div v-else-if="isStatsLoading" class="mt-4">
            <p>Loading stats</p>
            <v-progress-linear color="primary" height="2" indeterminate />
          </div>

          <!-- Show if error loading stats -->
          <div v-else-if="isStatsLoadError" class="mt-4">
            <p>Error loading stats</p>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>

    <!-- Preview dialog, for non-admins -->
    <preview-dialog
      v-model="preview"
      :subject="meetingNoticePreview.subject"
      :html="meetingNoticePreview.content"
      :attachments="meetingNoticePreview.attachments"
      :recipientCount="recipientCount"
      :recipientTotal="recipientTotal"
      @previewPrev="getPreview('prev')"
      @previewNext="getPreview('next')"
      @downloadFile="downloadFile"
      @close="preview = false"
    />
  </v-card>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';
import { mapGetters, mapActions } from 'vuex';

import { formatDate } from '@/helpers.js';
import NoticeCardStats from '@/components/NoticeCardStats';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';
import TimezoneHelper from '@/components/TimezoneHelper';

export default {
  name: 'NoticeCard',
  filters: {
    ...filters,
    urgency(value) {
      return value ? 'urgent' : 'normal';
    }
  },
  components: {
    NoticeCardStats,
    PreviewDialog,
    TimezoneHelper
  },
  props: {
    notice: {
      type: Object,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      recipientCount: 0,
      recipientTotal: 0,
      recipients: [],
      preview: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/notices', [
      'recipientsListData',
      'meetingNoticePreview'
    ]),
    statusColor() {
      if (this.notice.status === 'sent') {
        return 'blue';
      } else if (this.notice.status === 'scheduled') {
        return 'deep-orange';
      } else {
        return 'grey';
      }
    },
    iconType() {
      if (this.notice.status === 'sent') {
        return 'email';
      } else if (this.notice.status === 'scheduled') {
        return 'access_time';
      } else {
        return 'drafts';
      }
    },
    formatSendOn() {
      return this.notice.sendOn === null
        ? 'n/a'
        : formatDate(
            this.notice.sendOn,
            this.timezone,
            'YYYY-MMM-DD h:mm a zz'
          );
    },
    formatSentAt() {
      return this.notice.sentAt === null
        ? 'n/a'
        : formatDate(
            this.notice.sentAt,
            this.timezone,
            'YYYY-MMM-DD h:mm a zz'
          );
    },
    hasUnitFilesIncluded() {
      return _.get(this.notice, 'options.includeUnitFiles', null);
    },
    hasAttachments() {
      if (!this.notice.attachments) {
        return false;
      }
      return this.notice.attachments.length !== 0;
    },
    isUrgent() {
      return _.has(this.notice, 'options.urgent')
        ? this.notice.options.urgent
        : null;
    },
    timezone() {
      return this.meetingTimezone ? this.meetingTimezone : 'America/Toronto';
    },
    isSent() {
      return this.notice.status === 'sent';
    },
    isStatsLoading() {
      return this.notice.statsLoading;
    },
    isStatsLoadError() {
      return this.notice.statsLoadError;
    }
  },
  created() {
    /**
     * @NOTE Commenting out because it results in a ton of additional API calls
     * to get recipient segments.  Preview functionaly for non-admins?
     * Is this really needed @mr-gorro?
     */
    // if (this.notice.recipients) {
    //   this.init();
    // }
  },
  methods: {
    ...mapActions('meetings/notices', [
      'getRecipientList',
      'getMeetingsNoticePreview'
    ]),
    ...mapActions('files', ['getFile']),
    async init() {
      try {
        await this.getRecipientList({
          shortCode: this.$route.params.shortcode,
          recipients: this.notice.recipients,
          urgent: this.isUrgent
        });

        this.recipients = this.recipientsListData;
        this.recipientTotal = this.recipientsListData.length;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleClick() {
      if (this.isAdmin) {
        this.$router.push({
          name: 'meetingEditNotices',
          params: {
            shortcode: this.$route.params.shortcode,
            noticeKey: this.notice.key
          }
        });
      }
    },
    handleCopy() {
      let noticeObj = {
        campaignType: this.notice.campaignType,
        campaignCode: this.$route.params.shortcode,
        purpose: this.notice.purpose,
        subject: this.notice.subject,
        body: this.notice.body,
        sendOn: null,
        options: this.notice.options,
        attachments: this.notice.attachments,
        recipients: {
          segment: this.notice.recipients.segment,
          customData: this.notice.recipients.customData,
          range: this.notice.recipients.range,
          emails: this.notice.recipients.emails
        },
        noticeTemplateKey: this.notice.noticeTemplateKey
      };
      this.$emit('emitCopy', noticeObj);
    },
    toggleResend() {
      this.$emit('toggleResend', this.notice);
    },
    getPreview(direction) {
      try {
        let total = this.recipients.length;

        // Determine the paging direction for voters
        if (direction === 'prev' && !this.recipientCount == 0) {
          this.recipientCount--;
        } else if (direction === 'next' && total - this.recipientCount > 1) {
          this.recipientCount++;
        } else {
          // This handles two cases, first open and cycling past the last record
          this.recipientCount = 0;
        }

        // Determine whether a voterKey is available
        let voterKey = '';
        if (!total == 0) {
          voterKey = this.recipients[this.recipientCount].voterKey;
        }

        this.getMeetingsNoticePreview({
          shortCode: this.$route.params.shortcode,
          noticeKey: this.notice.key,
          voterKey: voterKey
        });
        this.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Download unit attached file
    async downloadFile(file) {
      try {
        let res = await this.getFile({
          bucket: file.Bucket,
          key: file.Key
        });
        window.open(res.data.url, '_blank');
      } catch (err) {
        throw err;
      }
    }
  }
};
</script>

<style scoped>
.subject-text {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #757575;
}

.file-font {
  font-size: 12px;
  color: #757575;
}

.pointer {
  cursor: pointer;
}
</style>
