<template>
  <v-container fluid>
    <!-- Account Users -->
    <meeting-users-view-account-users-table
      class="mb-4"
      :meeting="currentMeeting"
      :account="account"
      :users="accountUsers"
      @welcome="resendAccountWelcome"
    />

    <!-- Collaborators -->
    <meeting-users-view-collaborators-table
      class="mt-4"
      :meeting="currentMeeting"
      :collaborators="collaboratorListData"
      @save="saveCollaborator"
      @delete="deleteCollaborator"
      @welcome="resendCollabWelcome"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import MeetingUsersViewAccountUsersTable from './MeetingUsersViewAccountUsersTable';
import MeetingUsersViewCollaboratorsTable from './MeetingUsersViewCollaboratorsTable';

export default {
  name: 'MeetingUsersView',
  components: {
    MeetingUsersViewAccountUsersTable,
    MeetingUsersViewCollaboratorsTable
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('meetings/collaborators', [
      'collaboratorListData',
      'dataLastFetchedAt'
    ]),
    accountUsers() {
      if (this.account && this.account.users) {
        return this.account.users;
      }
      return [];
    }
  },
  created() {
    this.fetchCollaborators(this.currentMeeting.shortCode);
  },
  methods: {
    ...mapActions('meetings/collaborators', [
      'fetchCollaborators',
      'addOrUpdateCollaborator',
      'removeCollaborator',
      'sendCollaboratorWelcome'
    ]),
    ...mapActions('users', ['sendWelcomeEmail']),
    async saveCollaborator({
      email,
      firstName,
      lastName,
      collaboratorType,
      scopes
    }) {
      try {
        await this.addOrUpdateCollaborator({
          shortCode: this.currentMeeting.shortCode,
          email,
          firstName,
          lastName,
          collaboratorType,
          scopes
        });
        this.$events.$emit('toastEvent', 'Collaborator Saved');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteCollaborator(email) {
      try {
        await this.removeCollaborator({
          shortCode: this.currentMeeting.shortCode,
          email
        });
        this.$events.$emit('toastEvent', 'Collaborator removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async resendCollabWelcome(email) {
      try {
        await this.sendCollaboratorWelcome({
          shortCode: this.currentMeeting.shortCode,
          email
        });
        this.$events.$emit('toastEvent', 'Collaborator welcome resent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async resendAccountWelcome(userKey) {
      try {
        await this.sendWelcomeEmail({ userKey });
        this.$events.$emit('toastEvent', 'Dashboard welcome email resent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.file-link {
  margin-right: 5px;
  white-space: nowrap;
}
.name {
  font-weight: bold;
  text-transform: capitalize;
}
</style>
