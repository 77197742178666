<template>
  <div>
    <!-- Notice Consent Chip -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          :color="
            consents.notice_consent === null
              ? 'grey lighten-1'
              : consents.notice_consent
              ? 'green lighten-1'
              : 'red lighten-1'
          "
          text-color="white"
          class="px-1 mr-2"
          v-bind="attrs"
          v-on="on"
          @click.stop.prevent="handleChipClick"
        >
          <v-icon left>{{ getChipIcon('notice_consent') }}</v-icon>
          Notices
        </v-chip>
      </template>
      <span>
        {{ getConsentStatusWithDate('notice_consent') }}
      </span>
    </v-tooltip>

    <!-- Voting Consent Chip -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          :color="
            consents.voting_consent === null
              ? 'grey lighten-1'
              : consents.voting_consent
              ? 'green lighten-1'
              : 'red lighten-1'
          "
          text-color="white"
          class="px-1"
          v-bind="attrs"
          v-on="on"
          @click.stop.prevent="handleChipClick"
        >
          <v-icon left>{{ getChipIcon('voting_consent') }}</v-icon>
          Voting
        </v-chip>
      </template>
      <span>
        {{ getConsentStatusWithDate('voting_consent') }}
      </span>
    </v-tooltip>

    <!-- Multi-consent Edit Dialog -->
    <multi-consent-edit-dialog
      v-model="dialog.multiConsentEdit"
      :consents="consents"
      :meeting-timezone="meetingTimezone"
      @update="handleConsentUpdate"
      @delete="handleConsentDelete"
      @close="dialog.multiConsentEdit = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDateHumanReadable } from '@/helpers';
import MultiConsentEditDialog from '@/components/dialogs/MultiConsentEditDialog.vue';

export default {
  name: 'OwnerMultiConsentChip',
  components: {
    MultiConsentEditDialog
  },
  props: {
    // Consents object
    consents: {
      type: Object,
      default() {
        return {};
      }
    },
    unitId: {
      type: Number,
      validator: value => {
        return Number.isInteger(value) && value >= 0;
      }
    },
    email: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  methods: {
    ...mapActions('meetings/units', [
      'updateMeetingUnitVoterMultiConsent',
      'deleteMeetingUnitVoterMultiConsent'
    ]),
    formatDateReadable(date) {
      // Check if date is valid
      const parsedDate = date ? new Date(date) : NaN;
      return isNaN(parsedDate)
        ? 'Invalid date'
        : formatDateHumanReadable(date, false, this.meetingTimezone);
    },
    handleChipClick() {
      if (this.isAdmin) {
        this.dialog.multiConsentEdit = true;
      }
    },
    async handleConsentUpdate(consent) {
      try {
        await this.updateMeetingUnitVoterMultiConsent({
          shortCode: this.$route.params.shortcode,
          unitId: this.unitId,
          email: this.email,
          consent: {
            notice_consent: consent.notice_consent,
            voting_consent: consent.voting_consent
          }
        });
        this.dialog.multiConsentEdit = false;
      } catch (err) {
        console.log(err);
        this.$events.$emit(
          'toastEvent',
          'Error: Unable to update voter consent'
        );
      } finally {
        this.$events.$emit('toastEvent', 'Consent Updated');
      }
    },
    async handleConsentDelete() {
      // Confirm before proceeding to delete a voter consent record
      if (
        !confirm('Are you sure you want to delete consents for this voter?')
      ) {
        return;
      }

      try {
        await this.deleteMeetingUnitVoterMultiConsent({
          shortCode: this.$route.params.shortcode,
          unitId: this.unitId,
          email: this.email
        });
        this.$events.$emit('toastEvent', 'Consent Deleted');
      } catch (err) {
        console.log(err);
        this.$events.$emit(
          'toastEvent',
          'Error: Unable to delete voter consent'
        );
      } finally {
        this.dialog.multiConsentEdit = false;
      }
    },
    getConsentStatusWithDate(consentType) {
      const consent = this.consents[consentType];
      if (consent === null) {
        return 'No action';
      }
      return `${
        consent ? 'Consented' : 'Rejected'
      } on: ${this.formatDateReadable(this.consents.timestamp)}`;
    },
    getChipIcon(consentType) {
      const consent = this.consents[consentType];
      if (consent === null) return 'do_not_disturb_on';

      if (consentType === 'notice_consent') {
        return consent ? 'mail' : 'not_interested';
      }
      return consent ? 'ballot' : 'not_interested';
    }
  },
  data() {
    return {
      dialog: {
        multiConsentEdit: false
      }
    };
  }
};
</script>

<style scoped></style>
