'use strict';

/**
 * Forces browser to download a file as though user clicked on a link.
 * @param {String} url      - the url for the file
 * @param {String} name     - the file name to save as
 */
export default async function forceLinkDownload({ url, name }) {
  try {
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (err) {
    console.error('Error forcing link download', err);
    throw err;
  }
}
