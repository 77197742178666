<!-- NOTES Global events are generally triggered via the event bus declared in main.js.
To expose these events globally, place this component in another component that is also
rendered on every page - in this case, the Header component.
-->

<template>
  <div>
    <!-- Toast snackbar -->
    <v-snackbar
      :top="toast.y === 'top'"
      :bottom="toast.y === 'bottom'"
      :right="toast.x === 'right'"
      :left="toast.x === 'left'"
      :timeout="toast.timeout"
      :multi-line="toast.mode === 'multi-line'"
      :vertical="toast.mode === 'vertical'"
      v-model="toast.snackbar"
    >
      {{ toast.text }}
      <v-btn flat color="red" @click.native="toast.snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>

    <!-- API error dialog -->
    <v-dialog v-model="dialog.error" max-width="300px">
      <v-card class="text-xs-center">
        <v-card-title class="horizontal-flex">
          <v-icon color="red" class="pr-1">error_outline</v-icon>
          <div class="headline red--text">Error</div>
        </v-card-title>
        <v-card-text>
          <div
            class="body mb-1"
            v-for="(message, index) in apiError.messages"
            :key="index"
          >
            {{ message }}
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="caption grey--text pl-2">
            {{ apiError.status }}: {{ apiError.statusText }}
          </div>
          <v-spacer />
          <v-btn color="primary" flat @click.native="dialog.error = false">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'HeaderEvents',
  data() {
    return {
      toast: {
        snackbar: false,
        y: 'bottom',
        x: null,
        timeout: 5000,
        mode: '',
        text: ''
      },
      apiError: {
        status: 0,
        statusText: '',
        messages: []
      },
      dialog: {
        error: false
      }
    };
  },
  created() {
    // Listen on global event variable for events
    this.$events.$on('toastEvent', this.showToastEvent);
    this.$events.$on('showErrorDialog', this.showErrorDialogEvent);
  },
  beforeDestroy() {
    // remove listeners when component destroyed
    this.$events.$off('toastEvent', this.showToastEvent);
    this.$events.$off('showErrorDialog', this.showErrorDialogEvent);
  },
  methods: {
    showErrorDialogEvent(error) {
      console.log(error);
      this.apiError.status = error.status;
      this.apiError.statusText = error.statusText;
      //Convert array of error objects to messages
      if (Array.isArray(error.data) && error.data.length > 0) {
        this.apiError.messages = error.data.map(err => err.message);
      } else {
        this.apiError.messages = [error.data];
      }

      this.dialog.error = true;
    },
    showToastEvent(msg) {
      this.toast.snackbar = true;
      this.toast.text = msg;
    }
  }
};
</script>

<style scoped>
.horizontal-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
