<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card class>
      <v-card-title>
        <span class="title">{{ title }}</span>
      </v-card-title>
      <div class="px-3">
        <v-alert
          v-if="type === 'reviewers'"
          :value="true"
          type="info"
          outline
          class="mb-3"
        >
          By clicking <b>ADD</b>, you will send an email invite to the
          reviewer(s).<br />
          Make sure the approval request is ready for review before you add
          reviewers.
        </v-alert>

        <v-autocomplete
          v-model="selectedValues"
          :items="userList"
          chips
          hide-no-data
          hide-selected
          label="Search"
          placeholder="Start typing to Search"
          item-text="email"
          item-value="key"
          multiple
          deletable-chips
          light
          return-object
        />
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-btn color="primary" :disabled="isDisabled" @click.native="handleAdd"
          >add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UserListDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default() {
        return '';
      }
    },
    type: {
      type: String,
      default() {
        return 'reviewers';
      }
    },
    maxWidth: {
      type: String,
      default() {
        return '600px';
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      selectedValues: null,
      currentMeeting: {}
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters('meetings/collaborators', ['collaboratorListByType']),
    isDisabled() {
      return _.isEmpty(this.selectedValues);
    },
    currentAccount() {
      return this.accountByKey(this.currentMeeting.accountKey);
    },
    accountUsers() {
      return _.get(this.currentAccount, 'users', []);
    },
    collaborators() {
      return this.collaboratorListByType('partner-serve');
    },
    userList() {
      const collaboratorParsed = _.map(this.collaborators, col => {
        return { key: col.user.key, email: col.user.email };
      });

      const accountUserParsed = _.map(this.accountUsers, account => {
        return { key: account.user.key, email: account.user.email };
      });

      return [...collaboratorParsed, ...accountUserParsed];
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/collaborators', ['fetchCollaborators']),
    async init() {
      try {
        this.currentMeeting = this.meetingByShortCode(
          this.$route.params.shortcode
        );

        if (this.isAdmin) {
          this.fetchCollaborators(this.currentMeeting.shortCode);
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    handleAdd() {
      this.$emit('add', { reviewers: this.selectedValues });
      this.resetState();
    },
    resetState() {
      this.selectedValues = null;
    }
  }
};
</script>

<style scoped></style>
