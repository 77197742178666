import http from './requestType';

/**
 * Returns a list of conversation groups available to the scheduler
 * @return {Promise} - Promise
 */
export function getGroupsForScheduler() {
  return http._get(`ConversationTemplateGroups/groupsForScheduler`);
}

/**
 * Returns a list of conversation template groups available
 * @return {Promise} - Promise
 */
export function getConversationTemplateGroups() {
  return http._get(`ConversationTemplateGroups`);
}

/**
 * Returns a conversation template group detail
 * @param  {object} key - Conversation template Group key
 * @return {Promise} - Promise
 */
export function getConversationTemplateGroup(key) {
  return http._get(`ConversationTemplateGroups/${key}`);
}

/**
 * Create a new conversation template group
 * @param  {object} templateGroup - new conversation template group to create
 * @return {Promise} - Promise
 */
export function postConversationNewTemplateGroup(conversationTemplateGroup) {
  return http._post(`ConversationTemplateGroups`, conversationTemplateGroup);
}

/**
 * Update a conversation template group
 * @param  {string} key - Conversation template Group key
 * @param  {object} formData - formData object to update template group
 * @return {Promise} - Promise
 */
export function putConversationTemplateGroup(key, formData) {
  return http._put(`ConversationTemplateGroups/${key}`, formData);
}

/**
 * Delete a conversation template group
 * @param  {string} key - Conversation template Group key
 * @return {Promise} - Promise
 */
export function deleteConversationTemplateGroup(key) {
  return http._delete(`ConversationTemplateGroups/${key}`);
}

/**
 * Create a new conversation template group membership
 * @param {object} templateGroup - new conversation template group to create
 * @return {Promise} - Promise
 */
export function postNewConversationTemplateGroupMembership(
  key,
  conversationTemplateGroupMembership
) {
  return http._post(
    `ConversationTemplateGroups/${key}/memberships`,
    conversationTemplateGroupMembership
  );
}

/**
 * Update a list of conversation template group memberships
 * @param  {string} key - Conversation template group key
 * @param  {object} payload - payload object to update the list of template group memberships
 * @return {Promise} - Promise
 */
export function putConversationTemplateGroupMembership(key, payload) {
  return http._put(`ConversationTemplateGroups/${key}/memberships/`, payload);
}

/**
 * Delete a conversation template group membership
 * @param  {string} key - Conversation template Group key
 * @param  {string} membershipKey - the conversation template group membership key
 * @return {Promise} - Promise
 */
export function deleteConversationTemplateGroupMembership(
  conversationTemplateGroupKey,
  membershipKey
) {
  return http._delete(
    `ConversationTemplateGroups/${conversationTemplateGroupKey}/memberships/${membershipKey}`
  );
}
