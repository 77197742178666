'use strict';

/**
 * Return the alert parameter object based on alert type for the Login page.
 *
 * @param {String} alertType - the alert type
 * @param {Object}      - the alert parameters.
 */
export default function setAlert(alertType = null) {
  let alert;
  switch (alertType) {
    case 'forgot-password':
      alert = {
        show: true,
        type: 'success',
        icon: 'check',
        message: 'A password reset email has been sent.'
      };
      break;
    case 'password-set':
      alert = {
        show: true,
        type: 'success',
        icon: 'check',
        message: 'Success! Please login with your new password.'
      };
      break;
    case 'login-failed':
      alert = {
        show: true,
        type: 'error',
        icon: 'warning',
        message: 'Email or password is incorrect.'
      };
      break;
    case 'login-disabled':
      alert = {
        show: true,
        type: 'error',
        icon: 'warning',
        message: 'Account has been suspended.'
      };
      break;
    case 'oauth-google-error':
      alert = {
        show: true,
        type: 'error',
        icon: 'warning',
        message:
          'Google Authentication is currently unavailable, please login with your email and password instead.'
      };
      break;
    case 'other-google-error':
      alert = {
        show: true,
        type: 'error',
        icon: 'warning',
        message: 'Error signing in with Google'
      };
      break;
    case 'session-expired':
      alert = {
        show: true,
        type: 'info',
        icon: 'timer_off',
        message: 'Your session has expired, please log in again.'
      };
      break;
    case 'unauthenticated':
      alert = {
        show: true,
        type: 'info',
        icon: 'lock',
        message: 'You are not authorized, please log in to continue.'
      };
      break;
    case 'mfa-failed':
      alert = {
        show: true,
        type: 'error',
        message: 'The security code is incorrect, please try again.'
      };
      break;
    case 'mfa-resend':
      alert = {
        show: true,
        type: 'info',
        message: 'The security code has been resent, please check your email.'
      };
      break;
    case 'attempts-exceeded':
      alert = {
        show: true,
        type: 'error',
        message: 'Too many attempts, please try again in 10 minutes.',
        solid: true
      };
      break;
    default:
      alert = {
        type: null,
        message: null,
        icon: null,
        show: false
      };
  }
  return alert;
}
