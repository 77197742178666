<template>
  <v-dialog max-width="400px" persistent :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <div class="title">Create New Owner</div>
      </v-card-title>
      <v-card-text>
        <div v-if="testVoterToggle">
          <v-text-field
            label="Name"
            v-model="testVoter.name"
            :rules="rules.name"
            placeholder="eg. Joe Quimby"
            required
          />
          <v-text-field
            label="Email"
            type="email"
            v-model="testVoter.email"
            :rules="rules.email"
            placeholder="eg. JoeQuimby@email.com"
            required
          />
        </div>
        <div v-else>
          <v-text-field
            label="Name"
            v-model="newVoter.name"
            :rules="rules.name"
            placeholder="eg. Joe Quimby"
            required
          />
          <v-text-field
            label="Email"
            type="email"
            v-model="newVoter.email"
            :rules="rules.email"
            placeholder="eg. JoeQuimby@email.com"
            required
          />
        </div>
        <!-- Set as panelist -->
        <v-checkbox
          v-if="isAdmin && hasWebcast"
          hide-details
          light
          label="Set as panelist"
          v-model="panelistToggle"
        />
        <!-- Set as test voter (hidden from non-admins) -->
        <v-checkbox
          v-if="isAdmin"
          hide-details
          light
          label="Set as test voter"
          v-model="testVoterToggle"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-btn :disabled="isDisabled" class="primary" @click="addVoter"
          >ADD</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import validator from 'email-validator';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MeetingUnitsAddOwnerDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    hasWebcast: {
      type: Boolean,
      default: false
    },
    unitSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    contact: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      newVoter: {
        name: '',
        email: '',
        attending: null
      },
      testVoter: {
        name: this.contact.name,
        email: this.contact.email,
        communicationPreferences: {
          isTestVoter: true
        }
      },
      testVoterToggle: false,
      panelistToggle: false,
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ],
        name: [v => !!v || 'Name is required']
      }
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isDisabled() {
      if (this.testVoterToggle) {
        return !(
          this.testVoter.name &&
          this.testVoter.email &&
          validator.validate(this.testVoter.email)
        );
      } else {
        return !(
          this.newVoter.name &&
          this.newVoter.email &&
          validator.validate(this.newVoter.email)
        );
      }
    }
  },
  methods: {
    ...mapActions('meetings/units', ['addMeetingUnitVoter']),
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    addVoter() {
      let payload = {
        shortCode: this.unitSelected.shortCode,
        unitId: this.unitSelected.id,
        newVoter: this.testVoterToggle ? this.testVoter : this.newVoter,
        isPanelist: this.panelistToggle
      };

      this.$emit('addVoter', payload);
    }
  }
};
</script>

<style scoped></style>
