<template>
  <div>
    <v-data-table :headers="headers" :items="subscriptions" class="elevation-1">
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <tr class="table-item">
          <td class="nowrap" width="100">
            {{ props.item.createdAt | dateFormatReadable }}
          </td>
          <td class="uppercase" width="200">
            <a :href="getChargebeeLink(props.item)" target="_blank"
              ><b>{{ props.item.invoiceNumber }}</b></a
            >
          </td>
          <td>
            <v-chip
              label
              small
              :color="getInvStatusColor(props.item.invoiceStatus)"
              text-color="white"
              >{{ props.item.invoiceStatus }}</v-chip
            >
          </td>
          <td class="uppercase">
            {{ props.item.productName }}
          </td>
          <td class="text-xs-right">
            <span :class="getOwingColor(props.item.invoiceOwing)">{{
              props.item.invoiceOwing | formatCurrencyValue
            }}</span>
          </td>
          <td class="text-xs-right">
            {{ props.item.invoiceSubtotal | formatCurrencyValue }}
          </td>
          <td>{{ props.item.currency }}</td>
          <td class="text-xs-left">
            <v-chip
              small
              label
              disabled
              :color="getStatusChipColor(props.item)"
              text-color="white"
              class="capitalize"
            >
              {{ getStatus(props.item) }}
            </v-chip>
          </td>
          <td class="nowrap" width="100">
            {{ props.item.nextBillingAt | dateFormatReadable }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';

import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'SubscriptionListTable',
  props: {
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  filters: {
    formatCurrencyValue,
    dateFormatReadable
  },
  data() {
    return {
      headers: [
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: 'Subscription', value: 'invoiceNumber', align: 'left' },
        { text: 'Invoice Status', value: 'invoiceStatus', sortable: false },
        { text: 'Name', value: 'productName' },
        { text: 'Due', value: 'invoiceOwing', align: 'right' },
        { text: 'Price', value: 'invoiceSubtotal', align: 'right' },
        { text: 'Currency', value: 'currency', sortable: false },
        {
          text: 'Subscription Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'Next Billing At',
          value: 'nextBillingAt',
          sortable: false,
          align: 'right'
        }
      ]
    };
  },
  methods: {
    getStatusChipColor(item) {
      const status = this.getStatus(item);
      if (status === 'paid') {
        return 'green';
      } else if (status === 'review') {
        return 'teal';
      } else if (status === 'sent' || status === 'posted') {
        return 'primary';
      } else if (status === 'payment due') {
        return 'red';
      } else {
        return 'secondary';
      }
    },
    getStatus(item) {
      const status = item.status;
      return status.replace('_', ' ');
    },
    getOwingColor(amount) {
      return amount > 0 ? 'owing' : 'not-owing';
    },
    getInvStatusColor(status) {
      switch (status) {
        case 'paid':
          return 'green';
        case 'payment_due':
          return 'red';
        case 'voided':
          return 'grey';
        case 'posted':
          return 'purple';
        default:
          break;
      }
    },
    getChargebeeLink(invoice) {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/invoices'
          : 'https://getquorum-test.chargebee.com/d/invoices';

      const url = `${baseurl}/${invoice.invoiceNumber}`;

      return url;
    }
  }
};
</script>

<style scoped>
.owing {
  color: red;
}

.not-owing {
  color: green;
}

.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.nowrap {
  white-space: nowrap;
}

.table-item {
  height: 60px;
}

.italics {
  font-style: italic;
  opacity: 0.75;
}
</style>
