<template>
  <v-chip
    small
    label
    disabled
    :color="checklistColor"
    :class="{ completed: isCompleted }"
    text-color="white"
  >
    <strong class="pr-1"> {{ checklistName }} </strong
    ><strong v-if="checklist.shortCode">
      ({{ checklist.numCompleted }} / {{ checklist.numItems }})
    </strong>
  </v-chip>
</template>

<script>
import { getChecklistColor } from '@/lib/checklist-helpers';

export default {
  name: 'ChecklistChip',
  props: {
    checklist: {
      type: Object
    }
  },
  computed: {
    checklistColor() {
      return getChecklistColor(this.checklist.stage);
    },
    checklistName() {
      return this.checklist.name;
    },
    isCompleted() {
      return (
        this.checklist.numItems > 0 &&
        this.checklist.numCompleted === this.checklist.numItems
      );
    }
  }
};
</script>

<style scoped>
.completed {
  text-decoration: black line-through;
  font-style: italic;
  opacity: 0.6;
}
</style>
