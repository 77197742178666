<template>
  <div>
    <div v-if="!showData && isSecret && !isVotingClose">
      <v-alert
        :value="true"
        class="vote-alert"
        type="info"
        icon="lock"
        color="#607d8b"
      >
        {{
          !canSeeResults
            ? 'The results will be shown once voting closes or when the meeting begins.'
            : 'The results will be shown once voting closes or when the meeting begins.'
        }}
        <div v-if="canSeeResults">
          <i>
            Moderators - Only you can preview the results, your customer cannot
            see this:</i
          >
          <v-btn small @click="display">Show Me</v-btn>
        </div>
      </v-alert>
    </div>
    <v-data-table
      v-else
      :headers="headers"
      :items="tally"
      :pagination.sync="pagination"
      hide-actions
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>
            <div class="flex">
              <v-icon>person</v-icon>
              <div class="label-header">
                {{
                  props.item.option === 'defer'
                    ? 'I give my proxy authority to vote on this matter'
                    : props.item.option
                }}
                <span
                  v-if="proxyHolderTotal(props.item.holders) > 0"
                  class="defer-note"
                  ><a href="javascript:void(0)"
                    >({{ isHideText(props.expanded) }})</a
                  ></span
                >
              </div>
            </div>
          </td>
          <td class="text-xs-right num-vote-text">
            {{ props.item.num_votes }}
          </td>
        </tr>
      </template>
      <template slot="expand" slot-scope="props">
        <v-card class="mb-3-defer" v-if="props.item.holders" flat>
          <strong
            >The following proxy holders have been given authority to vote by
            owners:</strong
          >
          <v-list
            class="v-list-defer"
            v-for="holder in props.item.holders"
            :key="holder.id"
          >
            - {{ holder.name }}: {{ holder.num_votes_defer }} proxies
          </v-list>
        </v-card>
        <v-divider />
      </template>
      <template slot="no-data">
        <div class="no-vote-text">No votes recorded.</div></template
      >
    </v-data-table>
    <div v-if="!isSecret || isVotingClose || showData" class="action-divider" />
    <v-toolbar
      v-if="!isSecret || isVotingClose || showData"
      dense
      color="white"
      class="elevation-1"
    >
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          color="blue"
          :disabled="!isVoteDataExists"
          @click.native="exportClicked"
          flat
          >Download Summary</v-btn
        >
        <v-tooltip bottom :disabled="checkVoteBreakdownDate">
          <template slot="activator">
            <v-btn
              v-if="isAdmin || enableVoteBreakdown"
              color="blue"
              :disabled="!isVoteDataExists || !checkVoteBreakdownDate"
              @click.native="viewDataClicked"
              flat
              >View Data</v-btn
            >
          </template>
          <span
            >Business data will be made avaliable <br />
            {{ parseVoteBreakdownDate }}</span
          >
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import ListLabel from '@/components/VoteTallyListLabel';
import checkScope from '@/lib/check-user-scopes';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'VoteTallyTable',
  components: {
    ListLabel
  },
  props: {
    tally: {
      type: Array,
      default() {
        return [];
      }
    },
    isSecret: {
      type: Boolean,
      default: false
    },
    businessType: {
      type: String,
      default: ''
    },
    businessId: {
      type: String,
      default: ''
    },
    businessName: {
      type: String,
      default: ''
    },
    isVotingClose: {
      type: Boolean,
      default: false
    },
    canSeeResults: {
      type: Boolean,
      default: false
    },
    enableVoteBreakdown: {
      type: Boolean,
      default: false
    },
    enableVoteBreakdownDate: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    canSeeSecretTallyData() {
      // Currently admins and collaborators (moderators/partners) can see the tally tables
      // Even when set as a secret question
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early')
      );
    },
    isVoteDataExists() {
      return this.tally.length > 0;
    },
    checkVoteBreakdownDate() {
      if (this.isAdmin) {
        return true;
      }
      return moment(this.enableVoteBreakdownDate).isBefore();
    },
    parseVoteBreakdownDate() {
      return moment(this.enableVoteBreakdownDate).format(
        'dddd, MMMM Do YYYY, h:mm:ss a'
      );
    }
  },
  methods: {
    proxyHolderTotal(holders) {
      if (!holders) {
        return 0;
      } else {
        return holders.length;
      }
    },
    isHideText(thisExpanded) {
      return thisExpanded ? 'Hide details' : 'Click for details';
    },
    display() {
      this.$emit('showToolbar');
      this.showData = true;
    },
    viewDataClicked() {
      this.$router.push({
        name: 'meetingData',
        params: {
          shortcode: this.shortCode,
          questionId: this.businessId
        }
      });
    },
    exportClicked() {
      this.$emit('exportClicked', this.businessName, this.tally);
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      headers: [
        {
          text: this.businessType === 'by-law' ? 'Vote' : 'Candidate',
          value: 'option'
        },
        {
          text: 'Votes',
          value: 'num_votes',
          align: 'right'
        }
      ],
      pagination: {
        sortBy: 'num_votes',
        descending: true,
        rowsPerPage: -1
      },
      showData: false
    };
  }
};
</script>

<style scoped>
.flex {
  display: flex;
}
.label-header {
  padding-left: 20px;
  font-size: 15px;
  text-transform: capitalize;
}
.no-vote-text {
  font-size: 15px;
}
.defer-note {
  display: block;
  text-transform: none;
  font-size: 13px !important;
}
.defer-note:hover {
  text-decoration: underline;
}
.mb-3,
.mb-3-defer {
  display: inline-block;
  text-indent: 75px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top-style: none;
  border-bottom-style: dotted;
}
.v-list-defer {
  padding: 0px 0 0px !important;
}
</style>
<style>
.vote-alert .v-alert__icon.v-icon {
  color: white !important;
}
</style>
