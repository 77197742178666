<template>
  <v-menu offset-y left :close-on-content-click="true" :nudge-width="200">
    <v-btn fab depressed slot="activator" class="primary">
      <v-icon>apps</v-icon>
    </v-btn>
    <v-card>
      <v-list class="py-0">
        <v-list-tile
          v-for="app in apps"
          :key="app.name"
          @click="openApp(app.name)"
          :class="app.view === $route.name ? 'active' : ''"
        >
          <v-list-tile-avatar>
            <v-icon color="primary">{{ app.icon }}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ app.label }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <div v-if="isAdmin">
          <v-divider></v-divider>
          <v-subheader>Admin Only</v-subheader>
          <v-list-tile
            v-for="option in adminOptions"
            :key="option.name"
            @click="openView(option.view)"
            :class="option.view === $route.name ? 'active' : ''"
          >
            <v-list-tile-avatar>
              <v-icon color="purple">{{ option.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ option.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'AppsMenu',
  props: {},
  data() {
    return {
      adminOptions: [
        { name: 'New Meeting', icon: 'event', view: 'addNewMeeting' },
        { name: 'Voter Search', icon: 'person_search', view: 'votersHome' },
        {
          name: 'Admin Dashboard',
          icon: 'admin_panel_settings',
          view: 'adminHome'
        }
      ],
      apps: [
        {
          name: 'dashboard',
          label: 'Dashboard',
          icon: 'dashboard',
          view: 'meetings'
        },
        {
          name: 'registration',
          label: 'Registration Desk',
          icon: 'table_restaurant',
          view: 'registration'
        },
        {
          name: 'surveys',
          label: 'Surveys App',
          icon: 'note_alt',
          view: 'surveys'
        }
      ],
      appProdLinks: {
        registration: 'https://regdesk.getquorum.com',
        surveys: 'https://surveys.getquorum.com/admin'
      },
      appLocalLinks: {
        registration: 'http://localhost:9000',
        surveys: 'http://localhost:9000/admin'
      }
    };
  },
  computed: {
    ...mapGetters(['login', 'isAdmin']),
    canSeeSchedulingWizardButton() {
      const { profile } = this.login;

      const isExperimental = this.$store.getters['features/experimentalMode'];

      // Check if isAdmin and isExperimental is toggled on
      if (this.isAdmin && isExperimental) {
        return true;
      }

      // Check if user has at least 1 account
      const totalAccounts = profile.nroAccounts || 0;

      if (totalAccounts >= 1) {
        return true;
      }

      return false;
    }
  },
  methods: {
    openView(view) {
      if (this.$route.name !== view) {
        this.$router.push({ name: view });
      }
    },
    openApp(appName) {
      let url = '';
      const isProd = process.env.NODE_ENV === 'production';

      if (appName === 'dashboard') {
        return this.$router.push({ name: 'meetings' });
      }

      if (isProd) {
        url = this.appProdLinks[appName];
      } else {
        url = this.appLocalLinks[appName];
      }

      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #e7f2fb;
  color: #0f64a9;
}
</style>
