<template>
  <v-list-tile
    class="elevation-0 mb-2 card"
    avatar
    @click="clicked"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <v-tooltip bottom>
      <v-list-tile-avatar slot="activator">
        <v-icon :class="avatarClass">{{ avatarIcon }}</v-icon>
      </v-list-tile-avatar>
      <span class="capitalize">{{ avatarTooltipText }}</span>
    </v-tooltip>

    <v-list-tile-content
      :style="{ 'max-width': `${contentMaxWidth}px` }"
      class="name-column"
    >
      <v-list-tile-title>
        {{ meeting.name }}
        <v-chip
          v-if="isCreatedToday"
          small
          label
          color="#0F64A9"
          text-color="white"
          class="ml-2"
          >New</v-chip
        >
      </v-list-tile-title>
      <v-list-tile-sub-title>
        {{
          meeting.meetingDate
            | formatDate(meeting.meetingTimezone, 'MMM Do YYYY [at] h:mmA zz')
        }}
        <b class="green--text">{{ daysRemainingText }}</b>
      </v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content style="max-width: 150px; position: relative">
      <v-chip
        class="hidden-sm-and-down status mr-3 bold"
        :text-color="getStatusDetails(meeting.meetingStatus).color"
        :color="getStatusDetails(meeting.meetingStatus).background"
        v-if="meeting.meetingStatus && isAdmin"
        small
      >
        {{ meeting.meetingStatus.toUpperCase() }}
      </v-chip>
    </v-list-tile-content>

    <v-list-tile-content>
      <v-list-tile-sub-title class="hidden-sm-and-down">
        <v-icon small>how_to_vote</v-icon>
        {{ totalSubs }} <span v-if="hover">submissions</span> ({{
          percentSubs
        }}%)
      </v-list-tile-sub-title>

      <v-list-tile-sub-title class="hidden-sm-and-down" v-if="hover">
        <v-icon small>mark_email_read</v-icon>
        {{ totalConsents }} consents ({{ percentConsents }}%)
      </v-list-tile-sub-title>

      <v-list-tile-sub-title class="hidden-sm-and-down" v-if="hover">
        <v-icon small>group</v-icon>
        {{ totalUnits }} members
      </v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-action v-if="meeting.partner || hasBylaw">
      <v-layout justify-end>
        <v-tooltip v-if="meeting.partner" bottom>
          <v-icon color="grey lighten-1" slot="activator"
            >account_circle</v-icon
          >
          <span>Is Partner model campaign</span>
        </v-tooltip>

        <v-tooltip v-if="hasBylaw" bottom class="ml-1">
          <v-icon color="grey lighten-1" slot="activator">info</v-icon>
          <span>Has a Bylaw vote</span>
        </v-tooltip>
      </v-layout>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { formatDate } from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'MeetingsViewListItem',
  props: {
    meeting: {
      type: Object,
      required: true
    },
    statusOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    contentMaxWidth: {
      type: Number,
      default() {
        return 600;
      }
    }
  },
  filters: {
    formatDate
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    avatarClass() {
      // If it's in the past, just do grey and grey.
      if (this.isPast) {
        return 'grey lighten-2 grey--text';
      }

      // It's upcoming, slight differences based on whether there's a webcast or n ot
      return this.hasWebcast
        ? `primary white--text`
        : `primary lighten-4 primary--text`;
    },
    avatarIcon() {
      const type = this.meeting.options ? this.meeting.options.type : '';

      if (this.hasWebcast) {
        return 'video_call';
      } else if (type === 'budget') {
        return 'attach_money';
      } else if (type === 'certs') {
        return 'attach_email';
      } else {
        return 'event';
      }
    },
    avatarTooltipText() {
      if (this.hasWebcast) {
        return 'Virtual Meeting';
      } else {
        return this.meeting.options ? this.meeting.options.type : 'event';
      }
    },
    hasBylaw() {
      return _.has(this.meeting, 'stats.bylawCount')
        ? this.meeting.stats.bylawCount
        : 0;
    },
    hasWebcast() {
      return (
        this.meeting.castId &&
        this.meeting.options &&
        (this.meeting.options.subtype === 'Virtual' ||
          !this.meeting.options.subtype)
      );
    },
    totalUnits() {
      return _.has(this.meeting, 'stats.totalUnits')
        ? this.meeting.stats.totalUnits
        : 0;
    },
    totalSubs() {
      return _.has(this.meeting, 'stats.totalProxies')
        ? this.meeting.stats.totalProxies
        : 0;
    },
    totalConsents() {
      return _.has(this.meeting, 'stats.totalConsents')
        ? this.meeting.stats.totalConsents
        : 0;
    },
    percentSubs() {
      return Math.floor((this.totalSubs / this.totalUnits || 0) * 100);
    },
    percentConsents() {
      return Math.floor((this.totalConsents / this.totalUnits || 0) * 100);
    },
    dateDiff() {
      const meetingDate = moment(this.meeting.meetingDate).startOf('day');
      const now = moment().startOf('day');
      return meetingDate.diff(now, 'days');
    },
    daysRemainingText() {
      return this.dateDiff === 0 ? '(Today)' : '';
    },
    isPast() {
      return this.dateDiff < 0;
    },
    isCreatedToday() {
      return moment().isSame(this.meeting.createdAt, 'days');
    },
    statusDetails() {
      return this.statusOptions;
    }
  },

  methods: {
    clicked() {
      if (this.meeting.options.type === 'vbm') {
        this.$router.push({
          name: 'meetingVbmOverview',
          params: {
            shortcode: this.meeting.shortCode
          }
        });
        this.$emit('update');
      } else {
        this.$router.push({
          name: 'meetingOverview',
          params: {
            shortcode: this.meeting.shortCode
          }
        });
        this.$emit('update');
      }
    },
    getStatusDetails(status) {
      return this.statusDetails[status] ? this.statusDetails[status] : '';
    }
  }
};
</script>

<style scoped>
.card {
  background-color: white;
}
.status {
  position: absolute;
  right: 0;
}
.v-list__tile__title {
  height: 28px;
}
.name-column {
  flex: 50%;
}
</style>
