import http from './requestType';

/**
 * Returns a list of notice templates
 * @return {Promise} - Promise
 */
export function getNoticeTemplateList() {
  return http._get(`NoticeTemplates`);
}

/**
 * Creates a notice template
 * @param  {Object}  noticeTemplate - the new notice template
 * @return {Promise}                - Promise
 */
export function postNoticeTemplate(noticeTemplate) {
  return http._post(`NoticeTemplates`, noticeTemplate);
}

/**
 * Delete the specified sample
 * @param  {String}  noticeTemplateKey - the notice template key
 * @return {Promise}                   - Promise
 */
export function deleteNoticeTemplate(noticeTemplateKey) {
  return http._delete(`NoticeTemplates/${noticeTemplateKey}`);
}

/**
 * Get the specified sample
 * @param  {String}  noticeTemplateKey  - the notice template key
 * @return {Promise}                    - Promise
 */
export function getNoticeTemplate(noticeTemplateKey) {
  return http._get(`NoticeTemplates/${noticeTemplateKey}`);
}

/**
 * Updates the specified sample
 * @param  {String} noticeTemplateKey - the notice template key
 * @param  {Object} noticeTemplate    - the notice template to use in the update
 * @return {Promise}                  - Promise
 */
export function putNoticeTemplate(noticeTemplateKey, noticeTemplate) {
  return http._put(`NoticeTemplates/${noticeTemplateKey}`, noticeTemplate);
}

/**
 * Returns a list of notice templates available to the scheduler
 * @param  {String} noticeTemplateKey - the notice template key
 * @return {Promise} - Promise
 */
export function getTemplatesForScheduler() {
  return http._get(`NoticeTemplates/actions/templatesForScheduler`);
}

/**
 * Find the notice template group(s) for the scheduler by noticeTemplateKey
 * @param  {String} noticeTemplateKey - the notice template key
 * @return {Promise} - Promise
 */
export function getNoticeGroupByTemplate(noticeTemplateKey) {
  return http._get(
    `NoticeTemplates/${noticeTemplateKey}/noticeGroupByTemplate`
  );
}
