'use strict';

/**
 * @fileoverview Vuex module for a additional services
 */

import _ from 'lodash';
import { patchMeeting, getMeetingMeta } from '@/api/meetings';
import { getRecipe } from '@/api/recipes';

const state = {
  serviceList: [],
  meetingMeta: [],
  orderedServices: [],
  otherServices: []
};

const getters = {
  services: state => state.orderedServices,
  other: state => state.otherServices
};

const actions = {
  /**
   * Get list of additional services
   */
  async getAdditionalServices({ commit }) {
    const { data } = await getRecipe('meetings.settings.additional_services');
    const values = _.get(data, 'data.values', []);

    commit('SET_ADDITIONAL_SERVICES', values);
  },

  /**
   * Get meeting meta data
   * @param  {String} {shortCode} Meeting shortcode
   */
  async getMetaData({ commit, dispatch, state }, { shortCode }) {
    if (!Object.keys(state.serviceList).length) {
      await dispatch('getAdditionalServices');
    }

    const { data: values } = await getMeetingMeta({
      shortCode,
      type: 'additional_services'
    });

    commit('SET_MEETING_META', values);

    const ordered = [];
    const other = [];

    _.forEach(state.serviceList, el => {
      if (values[el.value]) {
        ordered.push({ title: el.title, value: el.value });
      } else {
        other.push({ title: el.title, value: el.value });
      }
    });

    commit('SET_ORDERED_SERVICES', ordered);
    commit('SET_OTHER_SERVICES', other);
  },

  async setService({ dispatch }, { shortCode, updates }) {
    await patchMeeting(shortCode, updates);
    return await dispatch('getMetaData', { shortCode });
  }
};

const mutations = {
  SET_ORDERED_SERVICES(state, value) {
    state.orderedServices = value;
  },
  SET_OTHER_SERVICES(state, value) {
    state.otherServices = value;
  },
  SET_ADDITIONAL_SERVICES(state, value) {
    state.serviceList = value;
  },
  SET_MEETING_META(state, value) {
    state.meetingMeta = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
