var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-tile',{staticClass:"tile",class:{
    'tile-background': _vm.routeObject.isNestedNavLabel,
    'tile--active': _vm.$route.path.includes(_vm.path)
  },attrs:{"to":{ name: _vm.routeObject.name }}},[_c('v-list-tile-avatar',{staticClass:"tile-avatar",attrs:{"size":"30"}},[_c('v-icon',{class:{
        'tile-avatar--active': _vm.$route.path.includes(_vm.path),
        'tile-avatar--inactive': !_vm.$route.path.includes(_vm.path)
      },attrs:{"size":"18","color":"white"}},[_vm._v(_vm._s(_vm.routeObject.icon))])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"tile-title"},[_vm._v(_vm._s(_vm.routeObject.label))])],1),(_vm.count)?_c('v-list-tile-action',[_c('div',{staticClass:"tile-notification"},[_vm._v(_vm._s(_vm.count))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }