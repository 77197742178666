import { render, staticRenderFns } from "./NoteMessage.vue?vue&type=template&id=020eb762&scoped=true"
import script from "./NoteMessage.vue?vue&type=script&lang=js"
export * from "./NoteMessage.vue?vue&type=script&lang=js"
import style0 from "./NoteMessage.vue?vue&type=style&index=0&id=020eb762&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020eb762",
  null
  
)

export default component.exports