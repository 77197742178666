<template>
  <div>
    <v-autocomplete
      v-for="item in Object.keys(groupedData)"
      :key="item"
      :value="data"
      :items="groupedData[item]"
      chips
      hint="Start typing to search..."
      :label="item"
      item-text="value"
      item-value="id"
      multiple
      return-object
      @change="updateCustomFilter"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close @input="remove(data.item)">
          {{ data.item.value }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CustomDataSelect',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters('meetings/units', ['customData']),
    groupedData() {
      // Define the keys to filter out from customData
      const filterKeyArray = ['assigned_to_unit_id'];

      // 1. Filter out objects whose keys are in filterKeyArray
      // 2. Map the filtered data to a new structure, adding an id property
      // 3. The id is created by concatenating the key and value properties
      const withIds = this.customData
        .filter(item => !filterKeyArray.includes(item.key))
        .map(item => {
          return { ...item, id: `${item.key}: ${item.value}` };
        });

      // Group the objects by their key property using lodash groupBy method
      return _.groupBy(withIds, item => item.key);
    }
  },
  methods: {
    remove(item) {
      const filters = this.data.filter(
        element => element.value !== item.value || element.key !== item.key
      );

      this.updateCustomFilter(filters);
    },
    updateCustomFilter(filters) {
      this.$emit('update', filters);
    }
  }
};
</script>

<style></style>
