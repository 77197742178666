<template>
  <div>
    <div class="d-flex justify-between align-center mb-2">
      <h3 class="font-weight-bold">
        Owners
      </h3>
      <v-btn flat color="blue" class="mr-3" dark small :disabled="true">
        <v-icon>add</v-icon>Add
      </v-btn>
    </div>

    <ul>
      <li v-for="owner in owners" :key="owner.key">
        <span class="capitalize">
          <b>{{ owner.firstName }} {{ owner.lastName }}</b>
        </span>
        <!--  <span v-if="isAdmin && isOpen"
          >(<a @click="remove('user', owner)">Remove</a>)</span
        > -->
      </li>
      <li v-if="!owners || owners.length === 0">
        None
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserListDialog from './UserListDialog.vue';

export default {
  name: 'OwnerList',
  components: {
    UserListDialog
  },
  props: {
    owners: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
};
</script>

<style scoped></style>
