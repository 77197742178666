<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="title">Invoice Items</div>
        <v-spacer />
        <div>
          <v-btn
            :disabled="isLocked"
            outline
            @click.native="addInvoiceItemClicked('expense')"
            color="primary"
            >Add Expense</v-btn
          >
          <v-btn
            :disabled="isLocked"
            outline
            @click.native="addInvoiceItemClicked('discount')"
            color="primary"
            >Add Discount</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          class="elevation-0"
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left capitalize align-top">
              {{ props.item.type }}
            </td>
            <td class="text-xs-left capitalize">{{ props.item.category }}</td>
            <td
              class="text-xs-left pt-2 pb-2 item-description"
              v-html="handleMarkdown(props.item.description)"
            >
              />
            </td>
            <td class="text-xs-right table-cell--values-width">
              {{ formatValue(props.item.value) }}
            </td>
            <td class="text-xs-right nowrap">
              <v-btn
                :disabled="isLocked"
                flat
                icon
                small
                color="grey"
                @click.native="editInvoiceItemClicked(props.item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>

              <v-btn
                :disabled="isLocked"
                flat
                icon
                small
                color="grey"
                @click="deleteInvoiceItemClicked(props.item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </template>
          <template slot="footer">
            <td colspan="5" class="text-xs-center grey lighten-5">
              <strong
                >Total: &nbsp;&nbsp;&nbsp;{{ subtotal }} {{ currency }}</strong
              >
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-item-dialog
      :is-open="showDeleteDialog"
      resource-name="invoiceItem"
      :resource-key="itemToDelete.key"
      @delete="handleDeleteItem"
      @close-dialog="showDeleteDialog = false"
    >
      <span slot="title">Delete item from Invoice?</span>
      <span slot="description">
        <p>
          Please confirm you would like to remove the following item from the
          invoice:
        </p>
        <br />
        <p class="capitalize">
          <b>{{ itemToDelete.type }}:</b> {{ itemToDelete.category }}
        </p>
        <p><b>Description:</b> {{ itemToDelete.description }}</p>
        <p><b>Value:</b> {{ itemToDelete.value }}</p>
      </span>
    </delete-item-dialog>

    <v-dialog v-model="showAddDialog" persistent max-width="600px">
      <add-item-dialog
        :invoice-key="invoiceKey"
        :item-type="itemTypeToAdd"
        :item-options="itemOptions"
        @close="closeDialogs"
      />
    </v-dialog>

    <v-dialog v-model="showEditDialog" persistent max-width="600px">
      <edit-item-dialog
        :invoice-key="invoiceKey"
        :invoice-item="itemToEdit"
        @close="closeDialogs"
      />
    </v-dialog>
  </div>
</template>

<script>
import { marked } from 'marked';
import insane from 'insane';

import { mapActions } from 'vuex';
import { formatCurrencyValue } from '@/helpers';
import selectOptions from '@/lib/billing-select-options.json';

import AddItemDialog from './AdminInvoiceViewItemsAdd';
import DeleteItemDialog from '@/components/dialogs/DeleteResourceDialog';
import EditItemDialog from './AdminInvoiceViewItemsEdit';

export default {
  name: 'AdminInvoiceViewItemsTable',
  components: {
    AddItemDialog,
    DeleteItemDialog,
    EditItemDialog
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    currency: {
      type: String,
      default: ''
    },
    subtotal: {
      type: [String, Number],
      default: ''
    },
    invoiceKey: {
      type: String,
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDeleteDialog: false,
      showAddDialog: false,
      showEditDialog: false,
      itemToDelete: { key: '' },
      itemTypeToAdd: '',
      itemOptions: [],
      itemToEdit: {},
      headers: [
        { text: 'Item', value: 'type', sortable: false },
        { text: 'Category', value: 'category', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Amount', value: 'value', align: 'right', sortable: false }
      ]
    };
  },
  methods: {
    ...mapActions('invoices', ['deleteItemFromInvoice']),
    formatValue(value) {
      return formatCurrencyValue(value);
    },
    addInvoiceItemClicked(type) {
      this.showAddDialog = true;
      this.itemTypeToAdd = type;
      this.itemOptions = selectOptions[type];
    },
    editInvoiceItemClicked(item) {
      this.showEditDialog = true;
      this.itemToEdit = item;
    },
    deleteInvoiceItemClicked(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    closeDialogs() {
      this.showDeleteDialog = false;
      this.showAddDialog = false;
      this.showEditDialog = false;
      this.itemToDelete = { key: '' };
      this.itemToEdit = {};
    },
    async handleDeleteItem(itemKey) {
      try {
        await this.deleteItemFromInvoice({
          itemKey,
          key: this.invoiceKey
        });
        this.$events.$emit('toastEvent', 'Item Deleted');
        this.closeDialogs();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleMarkdown(markdown) {
      return insane(marked.parse(markdown));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';

.item-description {
  line-height: 1.15;
}
</style>
