<template>
  <div>
    <v-layout row justify-end>
      <div class="stat-box text-xs-right">
        <span class="subheading stat-number">{{ uniqueOpenRate }}%</span>
        <br />
        <span class="caption">Opens</span>
      </div>
      <div class="stat-box text-xs-right">
        <span class="subheading stat-number">{{ uniqueClickRate }}%</span>
        <br />
        <span class="caption">Clicks</span>
      </div>
      <div class="stat-box text-xs-right">
        <span class="subheading stat-number">{{ totalSent }}</span>
        <br />
        <span class="caption">Sent</span>
      </div>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'NoticeCardStats',
  props: {
    stats: {
      type: Object,
      default() {
        return {
          sent: 0,
          opens: 0,
          uniqueOpens: 0,
          clicks: 0,
          uniqueClicks: 0,
          bounces: 0,
          unsubscribes: 0,
          updatedAt: null
        };
      }
    },
    timezone: {
      type: String,
      default() {
        return 'America/Toronto';
      }
    }
  },
  data() {
    return {
      dataLoaded: true
    };
  },
  computed: {
    uniqueOpenRate() {
      return this.stats && this.stats.uniqueOpens > 0
        ? this.formatPercentage(
            (this.stats.uniqueOpens / this.stats.sent) * 100
          )
        : 0;
    },
    uniqueClickRate() {
      return this.stats && this.stats.uniqueClicks > 0
        ? this.formatPercentage(
            (this.stats.uniqueClicks / this.stats.sent) * 100
          )
        : 0;
    },
    totalSent() {
      return this.stats ? this.stats.sent : 0;
    },
    updatedAt() {
      if (this.stats && this.stats.updatedAt) {
        return moment(this.stats.updatedAt)
          .tz(this.timezone)
          .fromNow();
      }
    }
  },
  created() {},
  methods: {
    formatPercentage(val) {
      return val.toLocaleString('en', {
        maximumFractionDigits: 1
      });
    }
  }
};
</script>

<style scoped>
.stat-box {
  margin-left: 15px;
  margin-right: 15px;
}

.stat-number {
  font-weight: 500;
}
</style>
