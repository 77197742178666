<template>
  <v-dialog :value="value" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Send Meeting Invites?</div>
      </v-card-title>

      <v-alert
        :value="totalInvalidSendList > 0"
        icon="priority_high"
        outline
        color="orange"
        dense
        class="mx-3"
      >
        {{ totalInvalidSendList }} emails do not have a valid email and will be
        skipped.
      </v-alert>

      <v-card-text>
        <v-layout
          ><v-flex xs10
            ><p>
              Do you want to send a Meeting Invite email to the following
              <span v-if="type === 'fromMembership'"> voters </span>
              <span v-else> member(s)</span>?
            </p>
          </v-flex>
        </v-layout>
        <v-layout v-for="(u, key) in sendList" :key="key">
          <v-flex xs10>
            <div>
              <b v-if="type === 'fromMembership'">{{ u.unit }}</b>
              <b v-else> {{ u.name }}</b
              >: {{ u.email }}
              <v-tooltip right v-if="u.email === 'noemail@getquorum.com'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="orange">
                    warning
                  </v-icon>
                </template>
                <span>Email will not receive invite</span>
              </v-tooltip>
            </div>
          </v-flex>
        </v-layout>
        <div v-if="optionalMessageInput" class="mt-3">
          <p class="body-1">
            Include an optional message with the invitation email(s) below:
          </p>
          <v-textarea
            box
            name="input-7-4"
            label=""
            placeholder="(Optional) Enter your message here"
            v-model="message"
            hide-details
          ></v-textarea>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-spacer />
        <v-btn class="primary" :loading="loading" @click.native="emitSend"
          >send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'SendMeetingBulkInviteDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default() {
        return 'fromMembership';
      }
    },
    optionalMessageInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: ''
    };
  },
  computed: {
    sendList() {
      let list = [];
      let sortedList = [];

      // First, create a list of recipients
      // (Each unit selected may have multiple email recipients)
      if (this.type === 'fromMembership') {
        for (var i = 0; i < this.selected.length; i++) {
          const voters = this.selected[i].voters;

          for (var j = 0; j < voters.length; j++) {
            list.push({
              unitId: this.selected[i].id,
              unit: this.selected[i].unit,
              email: voters[j].email,
              isPanelist: voters[j].isPanelist,
              isEligible: !_.get(
                this.selected[i].customData,
                'ineligible_reason'
              )
            });
          }
        }

        // Second, sort the list of recipients by unit
        sortedList = _.sortBy(list, 'unit');
      } else {
        sortedList = _.sortBy(this.selected, 'unit_id');
      }
      return sortedList;
    },

    totalInvalidSendList() {
      let invalidList = this.sendList.filter(
        unit => unit.email === 'noemail@getquorum.com'
      );
      return invalidList.length;
    }
  },
  methods: {
    emitSend() {
      this.$emit('send', { sendList: this.sendList, message: this.message });
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>
