<template>
  <v-dialog max-width="500px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Link HubSpot company</span>
        <v-spacer />
      </v-card-title>
      <v-card-text
        >Please provide the company ID to which you want to link. The company ID
        can be found in the HubSpot company URL.
        <b
          >This action will synchronize the account data with HubSpot company
          data.</b
        ></v-card-text
      >
      <v-form ref="form">
        <v-card-text>
          <v-layout column>
            <v-text-field
              v-model="inputValue"
              clearable
              box
              label="Company ID"
              placeholder="123456789"
              :rules="rules.id"
            >
              <template v-slot:append-outer>
                <v-btn
                  small
                  class="primary mr-0 ml-2 mb-1 p-0"
                  :loading="checking"
                  @click="verify"
                  >verify</v-btn
                >
              </template>
            </v-text-field>
          </v-layout>
        </v-card-text>

        <v-card-text v-if="companyFound" class="pt-0">
          <span v-if="!checking" class="red--text">{{ errorMessage }}</span>
          <v-flex xs12 v-if="checking" class="h-flex" justify-center>
            <v-progress-circular indeterminate color="primary" />
          </v-flex>

          <div v-else class="company-container">
            <div class="body-2 mb-2">
              <b
                >Company Found (<a :href="hubspotUrl" target="_blank"
                  >open on hubspot</a
                >):</b
              >
            </div>
            <ul>
              <li :class="{ 'red--text': !companyFound.name }">
                <b>Name:</b> {{ companyFound.name }}
              </li>
              <li :class="{ 'red--text': !companyFound.legal_name }">
                <b>Legal Name:</b> {{ companyFound.legal_name }}
              </li>
              <li :class="{ 'red--text': !companyFound.type }">
                <b>Type:</b> {{ companyFound.type }}
              </li>
              <li :class="{ 'red--text': !companyFound.number_of_units }">
                <b>Number of Units:</b> {{ companyFound.number_of_units }}
              </li>
              <li :class="{ 'red--text': !companyFound.state }">
                <b>Region:</b> {{ companyFound.state }}
              </li>
              <li :class="{ 'red--text': !companyFound.fiscal_year_end }">
                <b>Fiscal Year End:</b>
                {{ companyFound.fiscal_year_end | formatDate }}
              </li>
              <li :class="{ 'red--text': !companyFound.currency }">
                <b>Currency:</b>
                {{ companyFound.currency }}
              </li>
              <li :class="{ 'red--text': !mainContact }">
                <b>Main Contact:</b> {{ mainContact }}
              </li>
              <li :class="{ 'red--text': !billingContact }">
                <b>Billing Contact:</b> {{ billingContact }}
              </li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            small
            flat
            color="primary"
            :disabled="loading"
            @click="emitClose"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            :disabled="!verified"
            @click="link"
            >Link</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import moment from 'moment';
import * as companyApi from '@/api/companies';

export default {
  name: 'AdminAccountHubspotLinkDialog',
  components: {},
  filters: {
    formatDate(date) {
      return moment(date).format('MMMM DD, YYYY');
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mainContact() {
      const mainContact = _.find(this.companyFound.contacts, c =>
        c.types.includes('main_contact')
      );
      if (mainContact) {
        return mainContact.email;
      }

      return null;
    },
    billingContact() {
      const billingContact = _.find(this.companyFound.contacts, c =>
        c.types.includes('main_billing_contact')
      );
      if (billingContact) {
        return billingContact.email;
      }

      return null;
    },
    hubspotUrl() {
      const hubspotSite = process.env.VUE_APP_HUBSPOT_SITE || '24343778';
      const companyId = this.companyFound.hs_object_id;
      return `https://app.hubspot.com/contacts/${hubspotSite}/company/${companyId}`;
    }
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey,
      checking: false,
      loading: false,
      verified: false,
      inputValue: '',
      companyFound: null,
      errorMessage: '',
      rules: {
        id: [v => !!v || 'ID is required']
      }
    };
  },
  methods: {
    ...mapActions('accounts', ['linkCompany']),
    emitClose() {
      this.$emit('close');
    },
    async verify() {
      this.checking = true;
      try {
        if (this.$refs.form.validate()) {
          const response = await companyApi.getCompany({
            companyId: this.inputValue
          });

          this.companyFound = response.data;
          // check if the company is valid
          const {
            fiscal_year_end,
            legal_name,
            name,
            state,
            type,
            number_of_units,
            currency
          } = this.companyFound;

          if (
            !fiscal_year_end ||
            !legal_name ||
            !name ||
            !state ||
            !type ||
            !number_of_units ||
            !this.mainContact ||
            !this.billingContact ||
            !currency
          ) {
            this.verified = false;
            this.errorMessage =
              'The company is not valid or incomplete. Please fill in the missing fields:';
          } else {
            this.verified = true;
            this.errorMessage = '';
          }
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.checking = false;
      }
    },
    async link() {
      this.loading = true;
      try {
        const accountKey = this.$route.params.accountKey;
        await this.linkCompany({
          accountKey,
          companyId: this.inputValue
        });
        this.$events.$emit('toastEvent', 'Company Linked');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
        this.inputValue = '';
        this.emitClose();
      }
    }
  }
};
</script>

<style scoped>
.company-container {
  padding: 15px 15px 15px 15px;
  background-color: #e1f5fe;
}
.highlight {
  color: red;
}
</style>
