<template>
  <v-dialog max-width="500px" :value="value" @input="emitInput">
    <v-card>
      <v-container grid-list-md>
        <v-layout row>
          <v-flex xs6>
            <v-select
              label="Select custom field"
              :items="availableCustomFields"
              no-data-text="No new fields available"
              v-model="newField.name"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field label="Enter field value" v-model="newField.value" />
          </v-flex>
        </v-layout>

        <v-text v-if="error" class="accent--text">{{ error }}</v-text>
      </v-container>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          color="primary"
          :disabled="isCompleted"
          @click.native="emitUpdate"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsAddCustomFieldDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unitSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      newField: {
        name: '',
        value: ''
      },
      availableCustomFields: [],
      error: ''
    };
  },
  watch: {
    // If dialog opens, init
    value: function() {
      this.init(this.unitSelected);
    }
  },
  computed: {
    isCompleted() {
      return this.newField.name === '';
    }
  },
  methods: {
    init(unitSelected) {
      this.resetForm();

      // Check if customData is empty
      // Diff the available fields to add
      let currentFields = _.isEmpty(unitSelected.customData)
        ? []
        : Object.keys(unitSelected.customData);
      this.availableCustomFields = _.xor(this.customFields, currentFields);
    },
    resetForm() {
      this.newField.name = '';
      this.newField.value = '';
      this.error = '';
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitUpdate() {
      let payload = {
        unit: this.unitSelected.unit,
        address: this.unitSelected.address,
        mailAddress1: this.unitSelected.mailAddress1,
        mailAddress2: this.unitSelected.mailAddress2,
        mailCity: this.unitSelected.mailCity,
        mailProv: this.unitSelected.mailProv,
        mailPostal: this.unitSelected.mailPostal,
        mailCountry: this.unitSelected.mailCountry,
        id: this.unitSelected.id,
        shortCode: this.unitSelected.shortCode,
        controlNumber: this.unitSelected.controlNumber,
        // If customData is null, add an empty object for setting up a new property
        customData: this.unitSelected.customData
          ? this.unitSelected.customData
          : {}
      };

      // Validation for field value
      // Check for escape character
      if (this.newField.value.includes('\\')) {
        this.error = 'Custom field cannot contain the following characters: \\';
        return;
      }
      // Setting a new property
      payload.customData[this.newField.name] = this.newField.value;
      this.$emit('update', {
        ...payload,
        actionLogTag: 'create',
        targetLogTag: 'unit-tag'
      });
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
