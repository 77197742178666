<template>
  <v-alert
    class="ma-0 py-2"
    small
    icon="warning"
    :type="alertType"
    :value="showAlert"
    dismissible
  >
    <span v-if="isExpired">
      <strong
        >Service agreement expired on
        {{
          currentMeetingAccount.agreementExpiresAt | formatDateHumanReadableAlt
        }}</strong
      >
    </span>
    <span v-else>
      <strong>Service agreement not signed</strong>
    </span>
  </v-alert>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { formatDateHumanReadableAlt } from '@/filters';

export default {
  name: 'ServiceAgreementAlert',
  props: {
    currentMeetingAccount: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  filters: {
    formatDateHumanReadableAlt
  },
  computed: {
    showAlert() {
      return !this.isSigned || this.isExpired;
    },
    alertType() {
      if (this.showAlert) {
        return !this.isSigned ? 'error' : 'warning';
      }
      return null;
    },
    isSigned() {
      const agreementSignedAt = _.get(
        this.currentMeetingAccount,
        'agreementSignedAt',
        false
      );

      return agreementSignedAt ? agreementSignedAt : false;
    },
    isExpired() {
      const agreementExpiresAt = _.get(
        this.currentMeetingAccount,
        'agreementSignedAt',
        null
      );

      return agreementExpiresAt
        ? moment(this.currentMeetingAccount.agreementExpiresAt).isBefore()
        : false;
    }
  }
};
</script>

<style scoped></style>
