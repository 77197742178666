<template>
  <v-dialog
    :value="isOpen"
    persistent
    scrollable
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-form ref="addPollForm" v-model="isFormValid" @submit.prevent="save">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-layout row class="mb-lg">
            <v-text-field
              label="Name of Poll"
              :counter="64"
              :rules="rules.titleLimit"
              v-model="state.title"
              required
            />
          </v-layout>

          <v-layout
            column
            v-for="(question, index) in this.state.questions"
            :key="question.name"
          >
            <v-toolbar>
              <h3>Question {{ index + 1 }}</h3>
            </v-toolbar>
            <v-card>
              <v-card-text>
                <v-layout row>
                  <v-text-field
                    label="Question Text"
                    :value="question.name"
                    placeholder="Do you approve the motion to.... ?"
                    :counter="255"
                    :rules="rules.questionLimit"
                    @change="setQuestion({ index, key: 'name', value: $event })"
                  />
                </v-layout>
                <v-layout row>
                  <v-radio-group
                    :value="question.type"
                    @change="setQuestion({ index, key: 'type', value: $event })"
                    row
                  >
                    <v-radio label="Single Choice" value="single" />
                    <v-radio label="Multiple Choice" value="multiple" />
                  </v-radio-group>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <p>Answers (one per row - minimum of two required)</p>
                    <code-mirror
                      :code-array="question.answers"
                      :options="editorOption"
                      @update="
                        setQuestion({ index, key: 'answers', value: $event })
                      "
                    />
                    <p v-if="!isAnswersValid" class="caption red--text pl-1">
                      Must have at least two rows
                    </p>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click.native="close" flat :disabled="loading">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!isFormValid"
            type="submit"
            :loading="loading"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import CodeMirror from '@/components/CodeMirror';

export default {
  name: 'EditPollDialog',
  components: {
    CodeMirror
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    poll: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {},
  data() {
    return {
      state: {
        id: '',
        title: '',
        type: 'new',
        questions: [
          {
            name: 'Do you approve the motion to...',
            type: 'single',
            answers: ['Yes', 'No']
          }
        ]
      },
      editorOption: {
        tabSize: 0,
        styleActiveLine: true,
        line: true,
        mode: 'text',
        lineWrapping: true
      },
      isFormValid: false,
      rules: {
        titleLimit: [
          v => !!v || 'Poll name required',
          v => v.length <= 64 || 'Max 64 characters'
        ],
        questionLimit: [
          v => !!v || 'Question text required',
          v => v.length <= 255 || 'Max 255 characters'
        ]
      },
      isAnswersValid: true
    };
  },
  computed: {
    isNewPoll() {
      return this.poll.type === 'new';
    },
    dialogTitle() {
      return this.isNewPoll ? 'Add a new poll' : 'Edit a poll';
    }
  },
  created() {
    this.setState();
  },
  methods: {
    setState() {
      this.state.id = this.poll.id;
      this.state.type = this.poll.type;
      this.state.title = this.poll.title;
      this.state.questions = this.poll.questions;
    },
    save() {
      this.$emit('save', this.state);
      this.close();
    },
    close() {
      this.$emit('close');
      this.resetState();
      this.$refs.addPollForm.resetValidation();
    },
    resetState() {
      this.state = {
        id: '',
        title: '',
        type: 'new',
        questions: [
          {
            name: 'Do you approve the motion to...',
            type: 'single',
            answers: ['Yes', 'No']
          }
        ]
      };
    },
    setQuestion({ index, key, value }) {
      this.isAnswersValid = value.length > 1;
      this.isFormValid = value.length > 1;
      this.state.questions[index][key] = value;
    }
  }
};
</script>

<style scoped></style>
