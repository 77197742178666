<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        <div class="title primary--text text--darken-1">
          {{ title }}
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="printJob.disableBilling">
          <p>Click <b>confirm</b> to enable billing for this print job.</p>
          <p>The customer will be charged for printing services.</p>
        </div>
        <div v-else>
          <p>Click <b>confirm</b> to disable billing for this print job.</p>
          <p>The customer will not be charged for printing services.</p>
        </div>
      </v-card-text>

      <v-spacer />
      <v-card-actions>
        <v-layout row class="ma-3">
          <v-flex class="ma-3 text-xs-right">
            <v-btn color="primary" @click.native="close" flat>Close</v-btn>
            <v-btn @click.native="update" color="primary">
              confirm
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TogglePrintJobBillingDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    },
    printJob: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      if (this.printJob.disableBilling) {
        return 'Enable Billing?';
      }
      return 'Disable Billing?';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    update() {
      this.$emit('update');
    }
  }
};
</script>

<style scoped></style>
