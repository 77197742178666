<template>
  <v-dialog
    :value="value"
    @input="emitInput"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false">
    <v-card>
      <v-toolbar
        dark
        color="primary"
        text-color="white">
        <v-text-field
          prepend-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
          v-model="search" />
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            flat
            @click.native="emitClose">Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="recipients"
        :search="search"
        class="elevation-1">
        <template
          slot="items"
          slot-scope="props">
          <td
            class="text-xs-left"
            v-for="(key, index) in headers"
            :key="`key-${index}`">
            {{ props.item[key.value] }}
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: 'RecipientListDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    recipients: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'unit', value: 'unit', align: 'left'},
        {text: 'email', value: 'email', align: 'left'},
        {text: 'name', value: 'name', align: 'left'},
        {text: 'customData', value: 'customData', align: 'left'}
      ]
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>

</style>
