import http from './requestType';

/**
 * Returns full list of a given meeting's real time questions
 * @param  {String} shortCode   - Meeting shortcode
 * @return {Promise}            - Promise
 */
export function getRTVQuestionList(shortCode) {
  return http._get(`Meetings/${shortCode}/ballot-questions`);
}

/**
 * Creates a new real time question for the given meeting
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {Object}   question      - Question object, to be created
 * @return {Promise}                - Promise
 */
export function postRTVQuestion(shortCode, question) {
  return http._post(`Meetings/${shortCode}/ballot-questions`, question);
}

/**
 * Updates an existing real time voting question
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {Object}   question      - Question object, to be updated
 * @param  {String}   questionKey   - Question-specific key
 * @return {Promise}                - Promise
 */
export function putRTVQuestion(shortCode, question, questionKey) {
  return http._put(
    `Meetings/${shortCode}/ballot-questions/${questionKey}`,
    question
  );
}

/**
 * Deletes a real time voting question from this meeting
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {String}   questionKey   - Question-specific key
 * @return {Promise}                - Promise
 */
export function deleteRTVQuestion(shortCode, questionKey) {
  return http._delete(`Meetings/${shortCode}/ballot-questions/${questionKey}`);
}

/**
 * Opens a real time voting question's voting period
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {Object}   question      - Question object
 * @param  {String}   questionKey   - Question key
 * @param  {String}   deadlineType  - Type of deadline provided (one of: ['date', 'minutes'])
 * @param  {String}   deadline      - Deadline (either # of minutes, or future date)
 * @param  {Boolean}  notifyVoters  - Whether we should send email to voters in attendance
 * @return {Promise}                - Promise
 */
export function startRTVQuestion(
  shortCode,
  question,
  questionKey,
  deadlineType,
  deadline,
  notifyVoters
) {
  const payload = {
    ...question,
    notifyVoters,
    votingPeriod: { deadlineType, deadline }
  };
  return http._put(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/start-voting`,
    payload
  );
}

/**
 * Extends a real time voting question's voting period
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {Object}   question      - Question object
 * @param  {String}   questionKey   - Question key
 * @param  {String}   time          - Length of voting extension
 * @return {Promise}                - Promise
 */
export function extendRTVQuestion(shortCode, question, questionKey, time) {
  return http._put(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/extend-voting/${time}`,
    question
  );
}

/**
 * Closes a real time voting question's voting period
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {Object}   question      - Question object
 * @param  {String}   questionKey   - Question key
 * @return {Promise}                - Promise
 */
export function stopRTVQuestion(shortCode, question, questionKey) {
  return http._put(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/stop-voting`,
    question
  );
}

/**
 * Returns full list of an RTV questions's restrictions
 * @param  {String} shortCode   - Meeting shortcode
 * @param  {String} questionKey - Question key
 * @return {Promise}            - Promise
 */
export function getRTVQuestionRestrictionsList(shortCode, questionKey) {
  return http._get(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/restrictions`
  );
}

/**
 * Creates a new restriction for an RTV question
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {String}   questionKey   - RTV Question key
 * @param  {Object}   restriction   - Restriction payload, to be created
 * @return {Promise}                - Promise
 */
export function postRTVQuestionRestriction(
  shortCode,
  questionKey,
  restriction
) {
  return http._post(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/restrictions`,
    restriction
  );
}

/**
 * Deletes a restriction from an RTV question
 * @param  {String}   shortCode     - Meeting shortcode
 * @param  {String}   questionKey   - Question-specific key
 * @param  {String}   customField   - Custom Field
 * @param  {String}   fieldValue    - Field Value
 * @return {Promise}                - Promise
 */
export function deleteRTVQuestionRestriction(
  shortCode,
  questionKey,
  customField,
  fieldValue
) {
  return http._delete(
    `Meetings/${shortCode}/ballot-questions/${questionKey}/restrictions/${customField}/${fieldValue}`
  );
}
