<template>
  <div>
    <h3>Renewal</h3>
    <v-divider class="primary mt-2 mb-4" />

    <v-switch :label="renewalLabel" v-model="subscription.autoRenew" disabled />
  </div>
</template>

<script>
export default {
  name: 'SubscriptionRenewalDetailsCard',
  props: {
    subscription: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    renewalLabel() {
      return this.subscription.autoRenew
        ? 'Auto renewal is ON'
        : 'Auto renewal is OFF';
    }
  }
};
</script>
