<template>
  <div>
    <v-dialog :value="value" persistent max-width="70%">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Proxies Appointed to: <b>{{ holder }}</b></span
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="proxies"
            :pagination.sync="config.pagination"
            :rows-per-page-items="config.rowsPerPageItems"
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>
                  {{ props.item.unit }}
                </td>
                <td>
                  {{ props.item.name }}
                </td>
                <td>
                  {{ props.item.shares }}
                </td>
                <td>
                  {{ formatDateReadable(props.item.createdAt) }}
                </td>
                <td>
                  {{ props.item.ineligibleReason }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" flat @click.native="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/helpers';
export default {
  name: 'ViewProxiesByHolderDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    holders: {
      type: Array,
      default() {
        return [];
      }
    },
    holder: {
      type: String,
      default: ''
    },
    timezone: {
      type: String,
      required: true,
      default() {
        return 'America/Toronto';
      }
    }
  },
  computed: {
    proxies() {
      if (!this.holder) {
        return [];
      }
      const currentHolder = this.holders.find(
        h => h.holderName === this.holder
      );
      return currentHolder.proxies;
    }
  },
  data() {
    return {
      headers: [
        { text: 'Unit', value: 'unit', sortable: true },
        { text: 'Voter Name', value: 'name', sortable: true },
        { text: 'Shares', value: 'shares', sortable: true },
        { text: 'Submission Date', value: 'createdAt', sortable: true },
        {
          text: 'Ineligible?',
          value: 'ineligibleReason',
          sortable: true
        }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, 100, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'unit',
          descending: false
        }
      }
    };
  },
  methods: {
    formatDateReadable(value) {
      switch (value) {
        case null:
          return '';
        default:
          return formatDate(value, this.timezone, 'YYYY-MM-DD HH:mm zz');
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
