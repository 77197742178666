'use strict';

/**
 * @fileoverview Vuex module for meeting print jobs
 */

import _ from 'lodash';
import Vue from 'vue';

import * as printJobApi from '@/api/print-jobs';
import { getMeetingDetails } from '@/api/meetings';
import moment from 'moment';

const state = {
  meetingPrintJobList: {},
  completedPrintJobList: {},
  vendorList: {},
  dataLastFetchedAt: null,
  searchText: '',
  pagination: {
    sortBy: 'updatedAt',
    descending: true,
    rowsPerPage: 25,
    page: 1
  }
};

const getters = {
  meetingPrintJobList: state => state.meetingPrintJobList,
  // Get the meeting printings as array sorted by order ascending
  meetingPrintJobData: state => {
    return Object.values(state.meetingPrintJobList);
  },
  vendorList: state => state.vendorList,
  vendorListData: state => {
    return Object.values(state.vendorList);
  },
  vendorByKey: state => key => state.vendorList[key],
  meetingPrintJobByKey: state => key => state.meetingPrintJobList[key],
  completedPrintJobData: state => {
    return Object.values(state.completedPrintJobList);
  },
  searchText: state => state.searchText,
  pagination: state => state.pagination
};

const actions = {
  /**
   * Load the print jobs for a given meeting.
   * Optionally retrieve print job items as well.
   * @param {String} key              - the meeting key
   * @param {Boolean} includeItems    - if true, retrieve print job items
   */
  async getMeetingPrintJobList(
    { commit, dispatch },
    { key, includeItems = false }
  ) {
    try {
      const res = await printJobApi.getPrintJobList({ meetingKey: key });
      let { printJobs = [], totalNum = 0 } = res.data;

      if (!includeItems) {
        commit('SET_MEETING_PRINT_JOB_LIST', { printJobs, totalNum });
      } else {
        let printJobsWithItems = [];
        for (let i = 0; i < printJobs.length; i++) {
          const items = await dispatch('getPrintJobItems', printJobs[i].key);
          printJobsWithItems.push({ ...printJobs[i], items });
        }
        commit('SET_MEETING_PRINT_JOB_LIST', {
          printJobs: printJobsWithItems,
          totalNum
        });
      }
    } catch (err) {
      console.error('Error: getMeetingPrintJobList action', err);
    }
  },

  /**
   * Load completed print jobs for the meeting with attached proof-of-completion file
   * @param {String} key - the meeting key
   */
  async getCompletedPrintJobs({ commit }, { key }) {
    try {
      const res = await printJobApi.getPrintJobList({ meetingKey: key });
      const completedPrintJobs = _.filter(res.data.printJobs, {
        status: 'COMPLETED'
      });
      let completedWithPoc = [];
      for (let job of completedPrintJobs) {
        let res = await printJobApi.getPrintJobItems(job.key);
        let poc = _.filter(res.data, { jobItemType: 'poc' });
        if (poc.length > 0) {
          completedWithPoc.push({ ...job, pocFile: poc[0].filePackage });
        }
      }
      commit('SET_PRINT_JOB_COMPLETED_LIST_DATA', completedWithPoc);
    } catch (err) {
      console.error('Error: getCompletedPrintJobs action', err);
    }
  },

  /**
   * Stubs a new print job for this meeting.  A stubbed print job is a draft minimal
   * print job to be edited before sending to the vendor.
   *
   * @param {String} shortCode - The Meeting shortCode
   * @param {String} accountKey - The account the meeting belongs to
   * @param {String} jobName - Initial placeholder name of the print job
   * @param {String} meetingKey - The Meeting key
   */
  async stubNewMeetingPrintJob(
    { commit },
    { shortCode, accountKey, jobName, meetingKey, vendorKey, mailingDate }
  ) {
    try {
      const meetingDetails = await getMeetingDetails(shortCode);
      const jobType =
        'type' in meetingDetails.data.options
          ? meetingDetails.data.options.type
          : 'other';
      const payload = {
        accountKey,
        vendorKey,
        meetingKey,
        jobName,
        jobType,
        jobId: shortCode,
        proofsRequired: false,
        mailingDate
      };
      const res = await printJobApi.postPrintJob(payload);
      commit('SET_MEETING_PRINT_JOB_IN_LIST', res.data);
    } catch (err) {
      console.error('Error: stubNewMeetingPrintJob action', err);
      throw err;
    }
  },

  /**
   * Save a print job
   * @param {String}  jobKey      - the print job key
   * @param {String}  meetingKey  - the meeting key
   * @param {Boolean} force       - force update a non-writable print job
   */
  async saveMeetingPrintJob(
    { commit },
    { jobKey, meetingKey, payload, force = false }
  ) {
    try {
      payload.meetingKey = meetingKey;

      // Fix timezone issues on save
      if (payload.mailingDate) {
        payload.mailingDate = moment(payload.mailingDate).toDate();
      }

      const res = await printJobApi.putPrintJob(jobKey, payload, force);
      commit('SET_MEETING_PRINT_JOB_IN_LIST', res.data);
    } catch (err) {
      console.error('ERROR: saveMeetingPrintJob action ', err);
      throw err;
    }
  },

  /**
   * Load list of all vendors
   */
  async getVendors({ commit }) {
    try {
      const res = await printJobApi.getVendorList();
      const data = res.data;
      commit('SET_PRINT_JOB_VENDOR_LIST_DATA', data);
    } catch (err) {
      console.error('ERROR: getVendors action', err);
      throw err;
    }
  },

  /**
   * Deletes a print job
   * @param {*} jobKey - the print job key
   * @param {*} meetingKey - the meeting key (used for refreshing listing after delete)
   */
  async removePrintJob({ dispatch }, { jobKey, meetingKey }) {
    try {
      await printJobApi.deletePrintJob(jobKey);
      dispatch('getMeetingPrintJobList', {
        key: meetingKey
      });
    } catch (err) {
      console.error('ERROR: removePrintJob() action failed', err);
      throw err;
    }
  },

  /**
   * Get all items for a given print job
   * @param {String} jobKey - the print job key
   */
  async getPrintJobItems({ commit }, jobKey) {
    try {
      const res = await printJobApi.getPrintJobItems(jobKey);
      return res.data;
    } catch (err) {
      console.error('ERROR: getPrintJobItems() action failed', err);
      throw err;
    }
  },

  /**
   * Save print job items
   * @param {String} jobKey - the print job key
   * @param {Array} jobItems - list of print job item objects to be saved
   * @param {Boolean} force - overrides access restriction imposed by status
   */
  async savePrintJobItems({ commit }, { jobKey, jobItems, force }) {
    try {
      let structuredItems = [];
      for (let jobType in jobItems) {
        if (!_.isEmpty(jobItems[jobType]) && jobItems[jobType].length > 0) {
          for (let itemData of jobItems[jobType]) {
            let jobItem = {
              jobKey,
              jobItemType: jobType,
              filePackage: {
                Bucket: itemData.Bucket,
                Key: itemData.Key,
                size: itemData.size,
                name: itemData.name,
                mimetype: itemData.mimetype,
                url: itemData.url
              },
              fileMeta: {}
            };
            structuredItems.push(jobItem);
          }
        }
      }
      await printJobApi.postAllPrintJobItems(jobKey, structuredItems, force);
    } catch (err) {
      console.error('ERROR: savePrintJobItems() action failed', err);
      throw err;
    }
  },

  /**
   * Progress print job to next stage in process
   * @param {string} jobKey - the print job key
   * @param {string} meetingKey - the meeting key
   * @param {array} recipients - list of email recipients to notify
   * @param {boolean} urgent - mark email as urgent
   */
  async processJob(
    { dispatch },
    { jobKey, meetingKey, recipients = [], urgent = false }
  ) {
    try {
      await printJobApi.process(jobKey, recipients, urgent);
      dispatch('getMeetingPrintJobList', {
        key: meetingKey
      });
    } catch (err) {
      console.error('ERROR: processJob() action failed ', err);
      throw err;
    }
  },

  /**
   * Put print job on hold
   * @param {string} jobKey - the print job key
   * @param {string} meetingKey - the meeting key
   * @param {array} recipients - list of email recipients to notify
   */
  async holdPrintJob({ dispatch }, { jobKey, meetingKey, recipients = [] }) {
    try {
      await printJobApi.holdPrintJob(jobKey, recipients);
      dispatch('getMeetingPrintJobList', {
        key: meetingKey
      });
    } catch (err) {
      console.error('ERROR: holdPrintJob() action failed ', err);
      throw err;
    }
  },

  /**
   * Cancel print job
   * @param {string} jobKey - the print job key
   * @param {string} meetingKey - the meeting key
   * @param {array} recipients - list of email recipients to notify
   */
  async cancelPrintJob({ dispatch }, { jobKey, meetingKey, recipients = [] }) {
    try {
      await printJobApi.cancelPrintJob(jobKey, recipients);
      dispatch('getMeetingPrintJobList', {
        key: meetingKey
      });
    } catch (err) {
      console.error('ERROR: holdPrintJob() action failed ', err);
      throw err;
    }
  },

  /**
   * Complete print job
   * @param {string} jobKey - the print job key
   * @param {string} meetingKey - the meeting key
   * @param {string} postageCost - total cost of postage
   */
  async completePrintJob({ dispatch }, { jobKey, meetingKey, postageCost }) {
    try {
      const payload = {
        jobKey,
        postageCost
      };
      await printJobApi.completePrintJob(payload);
      dispatch('getMeetingPrintJobList', {
        key: meetingKey
      });
    } catch (err) {
      console.error('ERROR: completePrintJob() action failed ', err);
      throw err;
    }
  },

  /**
   * Sends a test email of print job instructions (i.e. instead of sending to vendor)
   * @param {String} jobKey - the print job key
   * @param {String} email - an email address to send the test to
   */
  async sendTestEmail({ dispatch }, { jobKey, email }) {
    try {
      await printJobApi.sendTestEmail(jobKey, email);
      // @todo do something after test email submission?
    } catch (err) {
      console.error('ERROR: sendTestEmail() action failed');
      throw err;
    }
  },

  setSearchText({ commit }, searchText) {
    commit('SET_SEARCH_TEXT', searchText);
  },
  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination);
  }
};

const mutations = {
  // Set all print jobs to store
  SET_MEETING_PRINT_JOB_LIST(state, { printJobs, totalNum }) {
    state.meetingPrintJobList = _.keyBy(printJobs, 'key');
    state.pagination.totalItems = parseInt(totalNum);
    state.dataLastFetchedAt = new Date();
  },

  // Set one print job to store
  SET_MEETING_PRINT_JOB_IN_LIST(state, printJob) {
    Vue.set(state.meetingPrintJobList, printJob.key, printJob);
  },

  SET_PRINT_JOB_COMPLETED_LIST_DATA(state, data) {
    state.completedPrintJobList = _.keyBy(data, 'key');
  },

  SET_PRINT_JOB_VENDOR_LIST_DATA(state, data) {
    state.vendorList = _.keyBy(data, 'key');
  },

  SET_VENDOR_IN_LIST(state, { key, data }) {
    Vue.set(state.vendorList, key, data);
  },

  // Reset the states
  CLEAR_STATE(state) {
    state.meetingPrintJobList = {};
    state.vendorList = {};
    state.dataLastFetchedAt = null;
  },

  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
