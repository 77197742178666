<template>
  <v-chip
    v-if="statusOption"
    :color="statusOption.background"
    :outline="!status"
    :text-color="statusOption.color"
    small
  >
    <span class="hidden-xs-only">
      {{ statusOption.label.toUpperCase() }}
    </span>
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import { getRecipe } from '@/api/recipes';

export default {
  name: 'StatusButton',
  data() {
    return {
      statusOptions: {}
    };
  },
  computed: {
    ...mapGetters('meetings', ['meetingByShortCode']),
    meeting() {
      if (_.has(this.$route, 'params.shortcode')) {
        return this.meetingByShortCode(this.$route.params.shortcode);
      }
      return {};
    },
    status() {
      return _.get(this.meeting, 'meetingStatus', null);
    },
    statusOption() {
      return _.get(this.statusOptions, this.status, null);
    }
  },
  mounted() {
    this.getStatusOptions();
  },
  methods: {
    async getStatusOptions() {
      const { data } = await getRecipe('meetings.status.options');
      if (data && data.data && data.data.status) {
        const options = _.keyBy(data.data.status, 'value');

        this.statusOptions = {
          ...options
        };
      }
    }
  }
};
</script>

<style></style>
