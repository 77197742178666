<template>
  <v-dialog
    :value="value"
    @input="emitInput"
    :overlay="false"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <div class="headline">Edit Consent</div>
        <v-spacer />
        <v-icon @click="emitClose">close</v-icon>
      </v-card-title>

      <v-divider />

      <!-- Consent Info -->
      <v-card-text v-if="consents.timestamp">
        <v-card flat class="grey lighten-4">
          <v-card-text>
            <v-layout align-center>
              <v-flex grow>
                <div class="mb-2">
                  <v-icon small>access_time</v-icon>
                  <span class="body-2 grey--text text--darken-1">
                    Recorded: {{ submissionDate }}
                  </span>
                </div>
                <div>
                  <v-icon small>forward_to_inbox</v-icon>
                  <span class="body-2 grey--text text--darken-1">
                    Method: {{ consentMethod }}
                  </span>
                </div>
              </v-flex>
              <v-flex shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn flat icon color="red" v-on="on">
                      <v-icon @click.stop="emitDelete">delete_forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Consent</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text>
        <consent-edit-toggle
          label="Notice Consent"
          v-model="state.notice_consent"
        />

        <consent-edit-toggle
          label="Voting Consent"
          v-model="state.voting_consent"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn flat @click.native="$emit('close')">Cancel</v-btn>
        <v-spacer />
        <v-btn @click.native="emitUpdate" color="primary" class="white--text">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConsentEditToggle from './MultiConsentEditDialogToggle.vue';
import { formatDate, formatDateHumanReadable } from '@/helpers';

export default {
  name: 'MultiConsentEditDialog',
  components: {
    ConsentEditToggle
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    consents: {
      type: Object,
      default() {
        return {};
      }
    },
    meetingTimezone: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      state: {
        notice_consent: null,
        voting_consent: null
      }
    };
  },
  watch: {
    // Populate state for consent values
    consents: {
      immediate: true,
      handler(consents) {
        this.state.notice_consent = consents.notice_consent;
        this.state.voting_consent = consents.voting_consent;
      }
    }
  },
  computed: {
    submissionDate() {
      // Single timestamp check
      const timestamp = this.consents?.timestamp;
      if (!timestamp) return '-';

      // Format date
      return formatDateHumanReadable(timestamp, true, this.meetingTimezone);
    },
    consentMethod() {
      // Return the consent type, ie. 'manual-update', 'imported', 'owner-provided'
      return this.consents?.type ? this.consents.type : 'n/a';
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitUpdate() {
      // Emit the payload for notice_consent and voting_consent
      this.$emit('update', this.state);
    },
    emitDelete() {
      this.$emit('delete');
    }
  }
};
</script>

<style scoped>
.consent-info {
  background-color: #f5f5f5;
}
</style>
