'use strict';

/**
 * @fileoverview Vuex module for a meeting's webcasts
 */

import _ from 'lodash';

import {
  listCollaborators,
  putCollaborator,
  deleteCollaborator,
  postCollaboratorWelcome
} from '@/api/meetings';

const state = {
  collaboratorList: {},
  dataLastFetchedAt: null
};

const getters = {
  collaboratorList: state => state.collaboratorList,
  collaboratorListData: state => Object.values(state.collaboratorList),
  collaboratorListByType: state => type =>
    _.filter(
      state.collaboratorList,
      collaborator => collaborator.collaboratorType === type
    ),
  dataLastFetchedAt: state => state.dataLastFetchedAt
};

const actions = {
  /**
   * Get list of all collaborators for this meeting
   *
   * @param  {String} shortCode - The meeting key
   * @return {Void}
   */
  async fetchCollaborators({ commit }, shortCode) {
    try {
      const res = await listCollaborators({ shortCode });
      commit('SET_COLLABORATORS', res.data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove collaborator from a meeting
   * @param  {Object} params            - params
   * @param  {String} params.shortCode  - shortCode
   * @param  {String} params.email      - email
   * @return {Void}
   */
  async removeCollaborator({ dispatch }, { shortCode, email }) {
    try {
      const res = await deleteCollaborator({ shortCode, email });
      dispatch('fetchCollaborators', shortCode);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add or update a collaborator
   * @param  {Object} params            - params
   * @param  {String} params.shortCode  - shortCode
   * @param  {String} params.email      - email
   * @param  {String} params.firstName  - first name
   * @param  {String} params.lastNAme   - last name
   * @return {Void}
   */
  async addOrUpdateCollaborator(
    { dispatch },
    { shortCode, email, firstName, lastName, collaboratorType, scopes }
  ) {
    try {
      const payload = { firstName, lastName, collaboratorType, scopes };
      const res = await putCollaborator({ shortCode, email, payload });
      dispatch('fetchCollaborators', shortCode);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Resend collaborator welcome email
   * @param  {Object} params            - params
   * @param  {String} params.shortCode  - shortCode
   * @param  {String} params.email      - email
   * @return {Void}
   */
  async sendCollaboratorWelcome({ dispatch }, { shortCode, email }) {
    try {
      const res = await postCollaboratorWelcome({ shortCode, email });
      return res.data;
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_COLLABORATORS(state, collaborators) {
    state.collaboratorList = _.keyBy(collaborators, 'key');
    state.dataLastFetchedAt = new Date();
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.collaboratorList = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
