<template>
  <v-container>
    <v-layout class="mt-3 mb-3">
      <v-flex xs12>
        <h2 class="title mb-3">Step 1. Upload data</h2>

        <v-radio-group row v-model="importMethod" :disabled="success">
          <v-radio label="From my computer" value="local"></v-radio>
          <v-radio label="From another campaign" value="campaign"></v-radio>
        </v-radio-group>

        <div v-if="importMethod === 'local'">
          <p class="body-1">
            The import file must be a formatted <code>csv</code> or
            <code>xlsx</code>.
          </p>
          <p class="body-1" v-if="isMultiConsent">
            To import multi-consent status, please use this sample format:
            <a
              href="https://d1v53h6w11vaa4.cloudfront.net/downloads/multiconsent_import_sample.csv"
              download
              >Download Sample</a
            >
          </p>

          <v-card class="pa-3 mb-3">
            <file-upload-form
              :attachment="importFile"
              accept=".xlsx, .csv"
              @upload="handleUpload"
            />
          </v-card>
        </div>

        <div v-else>
          <p class="body-1">
            Import membership data from other {{ account.nameShort }} campaigns
          </p>
          <v-select
            :items="importCampaignItems"
            item-text="text"
            item-value="value"
            box
            :disabled="success"
            label="Import data from campaign"
            v-model="importCampaign"
            @change="resetSubmitted"
          ></v-select>
        </div>
      </v-flex>
    </v-layout>

    <v-layout class="mt-5 mb-5">
      <v-flex xs12>
        <h2 class="title mb-3">
          Step 2. Set additional options for the import
        </h2>
        <v-checkbox
          label="Check ignore columns prefixed with 'custom.' during import"
          v-if="importMethod === 'local'"
          v-model="importOptions.ignoreCustom"
          light
          hide-details
        />

        <v-checkbox
          label="Check to add a cover letter to each unit during import"
          v-if="importMethod === 'local'"
          v-model="importOptions.addCovers"
          light
          hide-details
        />

        <v-flex xs12>
          <v-layout>
            <div class="pr-3">
              <v-checkbox
                label="Check to generate a control number for units without one specified"
                v-model="generateControlNumber"
                light
                hide-details
              />
            </div>
          </v-layout>
        </v-flex>
        <v-layout>
          <div class="mt-2 pl-4">
            <v-text-field
              v-if="generateControlNumber"
              v-model="controlNumberDigits"
              label="Number of digits"
              placeholder="13"
              hint="Between 10 and 20"
              type="number"
              outline
              persistent-hint
              min="10"
              max="20"
            />
          </div>
        </v-layout>

        <div v-show="importOptions.addCovers">
          <p class="body-1 mt-2">
            The cover letters file should be the result of the mail merge done
            using Word, using the import file from step 1 (and in the same
            order), and saved as a PDF. Refer to the
            <a
              href="https://sites.google.com/powderkeglabs.com/wiki/home"
              target="_blank"
              >GQ Wiki</a
            >
            for instructions on how to properly generate the cover letter file
            for importing.
          </p>

          <v-card class="pa-3 mb-3">
            <file-upload-form
              :attachment="coversFile"
              accept=".pdf"
              @upload="handleCoversUpload"
            />
          </v-card>
        </div>
      </v-flex>
    </v-layout>

    <v-layout class="mt-5 mb-5">
      <v-flex xs12>
        <h2 class="title mb-3">Step 3. Import the data</h2>
        <div class="import">
          <v-btn
            class="primary"
            :disabled="!canSubmit"
            :loading="this.processing"
            @click.native="handleImport"
          >
            <v-icon left>file_upload</v-icon>IMPORT DATA
          </v-btn>
        </div>
      </v-flex>
    </v-layout>

    <v-layout class="mt-3 mb-3">
      <v-flex xs12>
        <div v-if="submitted">
          <user-task-events-tracker
            :user-task-key="userTask.key"
            :pusher-props="userTask._pusher"
            @update="updateTaskStatus"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';

import FileUploadForm from '@/components/FileUploadForm';
import { postImportUnits } from '@/api/meetings-units';
import { getExportImportReport } from '@/api/meetings-reports';
import { mapActions } from 'vuex';

import UserTaskEventsTracker from '@/components/UserTaskEventsTracker';

export default {
  name: 'ImportUnits',
  components: {
    FileUploadForm,
    UserTaskEventsTracker
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      success: false,
      submitted: false,
      processing: false,
      generateControlNumber: true,
      controlNumberDigits: 13,
      shortCode: this.$route.params.shortcode,
      importFile: {},
      coversFile: {},
      importOptions: {
        isReplaceExisting: false,
        ignoreCustom: false,
        addCovers: false
      },
      userTask: {},
      importMethod: 'local',
      importCampaign: null
    };
  },
  computed: {
    isFileUploaded() {
      if (_.isEmpty(this.importFile)) {
        return false;
      }
      return true;
    },
    canSubmit() {
      if (this.success) {
        return false;
      }

      // Can submit when using local mode and file is uploaded
      if (this.importMethod === 'local' && this.isFileUploaded) {
        return true;
      }

      // Can submit if using campaign import and a campaign is selected
      if (this.importMethod === 'campaign' && this.importCampaign) {
        return true;
      }

      // Otherwise cannot upload
      return false;
    },
    importCampaignItems() {
      if (!this.account.meetings || this.account.meetings <= 0) {
        return [
          {
            text: 'No campaigns available for this account',
            value: null
          }
        ];
      }

      const items = this.account.meetings
        // Exclude this current meeting
        .filter(m => m.shortCode !== this.shortCode)
        // Build up the item text and label
        .map(m => {
          const mtgDate = moment(m.meetingDate)
            .tz(m.meetingTimezone)
            .format('YYYY-MM-DD');
          return {
            text: `${mtgDate} --- ${m.name} `,
            value: m.shortCode
          };
        });

      return items;
    },
    isMultiConsent() {
      return this.currentMeeting?.options?.consentFormat === 'multi-consent';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/units', ['getMeetingUnits']),
    ...mapActions('accounts', ['getAccountMeetings']),
    async init() {
      try {
        await this.getAccountMeetings(this.account.key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleUpload(res) {
      // If the obejct is empty, it's a deletion
      if (_.isEmpty(res)) {
        this.submitted = false;
      }

      this.importFile = res;
    },
    resetSubmitted() {
      this.submitted = false;
    },
    handleCoversUpload(res) {
      this.coversFile = res;
    },
    async handleImport() {
      try {
        this.processing = true;
        this.importOptions.controlNumberDigits = this.generateControlNumber
          ? this.controlNumberDigits
          : 0;

        let file;
        let covers;

        // Use uploaded file Bucket and Key info if local
        if (this.importMethod === 'local') {
          file = this.importFile;
          covers = this.coversFile;
        } else {
          // Trigger an import/export file generation and get the Key and Bucket
          // to it.
          const exImpRes = await getExportImportReport(this.importCampaign);
          file = exImpRes.data;
        }

        // Perform the import
        const res = await postImportUnits(
          this.shortCode,
          file,
          covers,
          this.importOptions
        );

        // Get the user task that was created so we can subscribe to progress
        this.userTask = res.data;
        this.submitted = true;
      } catch (err) {
        this.processing = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    updateTaskStatus({ status }) {
      this.userTask.status = status;
      if (status === 'completed' || status === 'error') {
        this.processing = false;
        this.getMeetingUnits({ shortCode: this.shortCode });
      }

      if (status === 'completed') {
        this.success = true;
      }
    }
  }
};
</script>

<style scoped>
.errorText {
  color: red;
}
.successText {
  color: green;
}

.messages {
  margin-left: 15px;
  font-size: 13px;
}
</style>
