<template>
  <div>
    <v-data-table
      :items="summaryItems"
      :headers="getHeadersFormatted"
      :loading="loading"
      item-key="type"
      hide-actions
      class="elevation-1"
      hide-default-header
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th v-for="h in props.headers" :key="h.text">
            <div v-if="h.text">
              <span class="mr-1 label">{{ h.text }}</span>
              <v-chip
                :color="getStatusColor(h.status)"
                text-color="white"
                small
                >{{ h.status }}</v-chip
              >
            </div>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr class="meeting-row">
          <td>
            {{ props.item.type }}
          </td>
          <td
            v-for="header in headers"
            :key="header.position"
            class="text-center"
          >
            <a
              v-if="props.item[`period-${header.position}`]?.total"
              @click="handleOpen({ type: props.item.type, ...header })"
            >
              {{ props.item[`period-${header.position}`]?.total }}</a
            >
            <span v-else>0</span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <meetings-by-subscripion-period-dialog
      :isOpen="open"
      :details="details"
      @close-dialog="handleClose"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import MeetingsBySubscripionPeriodDialog from './MeetingsBySubscripionPeriodDialog.vue';

const STATUS_COLORS = {
  active: 'green',
  incoming: 'primary',
  cancelled: 'red',
  future: 'teal'
};

export default {
  name: 'SubscriptionUsageSummaryTable',
  components: {
    MeetingsBySubscripionPeriodDialog
  },
  props: {
    accountKey: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    displayRemainingPeriods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      details: {
        type: '',
        startDate: '',
        endDate: ''
      },
      open: false
    };
  },
  created() {
    this.getSummary();
  },
  computed: {
    ...mapGetters('accounts', ['subscriptionSummary']),
    summaryItems() {
      const { summary = {} } = this.subscriptionSummary;

      return Object.entries(summary).map(([__, value]) => ({
        ...value,
        type: _.upperFirst(value.type)
      }));
    },
    headers() {
      return this.getPeriods();
    },
    getHeadersFormatted() {
      const headers = this.getPeriods();

      const headersFormatted = headers.map(element => ({
        text: `${moment(element.startDate).format('MMM DD, YYYY')} - ${moment(
          element.endDate
        ).format('MMM DD, YYYY')}`,
        sortable: false,
        status: _.upperFirst(element.status),
        positon: element.position
      }));

      return [
        {
          text: '',
          value: 'type'
        },
        ...headersFormatted
      ];
    }
  },
  methods: {
    ...mapActions('accounts', ['getAccountSubscriptionUsageSummary']),
    async getSummary() {
      this.loading = true;
      try {
        await this.getAccountSubscriptionUsageSummary({ key: this.accountKey });
      } catch (err) {
        throw err;
      } finally {
        this.loading = false;
      }
    },

    async handleOpen({ type, startDate, endDate }) {
      this.details = { type, startDate, endDate };
      this.open = true;
    },
    async handleClose() {
      this.open = false;
    },
    getPeriods() {
      const { periods = [] } = this.subscriptionSummary;
      const sortedPeriods = _.sortBy(periods, 'position');

      if (!this.displayRemainingPeriods) {
        return sortedPeriods.filter(period => period.status !== 'incoming');
      } else {
        return sortedPeriods;
      }
    },
    getStatusColor(status) {
      return STATUS_COLORS[_.lowerCase(status)] || 'secondary';
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
  font-size: 14px;
}

.label {
  font-size: 14px;
}
</style>
