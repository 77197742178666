'use strict';

/**
 * @fileoverview Vuex module for meeting checklists
 */

import { checklists } from '@/api';

import _ from 'lodash';

const state = {
  meetingChecklists: {},
  dataLastFetchedAt: null
};

const getters = {
  meetingChecklists: state => state.meetingChecklists,
  meetingChecklistData: state => Object.values(state.meetingChecklists)
};

const actions = {
  /**
   * Retrieve checklists for  the specified meeting
   * @param  {string} meetingKey  the meeting key
   * @return {Object[]}           list of checklists found for the meeting
   */
  async getChecklists({ commit }, meetingKey) {
    try {
      const res = await checklists.getChecklists({ meetingKey });
      commit('SET_MEETING_CHECKLISTS', res.data);
    } catch (err) {
      console.error('ERROR: listChecklists action', err);
      throw err;
    }
  },
  async createChecklist({ dispatch }, checklist) {
    try {
      await checklists.postChecklists(checklist);
    } catch (err) {
      console.error('ERROR: createChecklist action', err);
      throw err;
    }
  },
  async updateChecklist({ dispatch }, checklist) {
    try {
      await checklists.patchChecklist(checklist);
    } catch (err) {
      console.error('ERROR: updateChecklist action', err);
      throw err;
    }
  },
  async removeChecklist({ dispatch }, checklistKey) {
    try {
      await checklists.deleteChecklist(checklistKey);
    } catch (err) {
      console.error('ERROR: deleteChecklist action', err);
      throw err;
    }
  },
  async setChecklistAssignees({ dispatch }, { checklistKey, assignees }) {
    try {
      await checklists.putChecklistAssignees({ key: checklistKey, assignees });
    } catch (err) {
      console.error('ERROR: setChecklistAssignees action', checklistKey, err);
      throw err;
    }
  },
  async updateBulkAssignments(
    { dispatch },
    { assignees = [], checklists = [] }
  ) {
    try {
      // Ensure we're not updating if no checklists are specified
      if (checklists.length <= 0) {
        return;
      }

      const usernames = assignees.map(a => a.username);

      const tasks = checklists.map(async checklist => {
        return await dispatch('setChecklistAssignees', {
          checklistKey: checklist.key,
          assignees: usernames
        });
      });

      await Promise.all(tasks);
    } catch (err) {
      console.error('ERROR: bulkUpdateAssignments action', err);
      throw err;
    }
  },

  async bulkAddAndAssignChecklists(
    { dispatch },
    { checklistArray, meetingKey }
  ) {
    try {
      const tasks = checklistArray.map(async checklist => {
        // First, create the checklist
        const newChecklist = await checklists.postChecklists(
          _.omit(checklist, ['assignee', 'menu'])
        );

        // Second, assign the checklist
        if (checklist.assignee) {
          // get assignee emails
          const emails = checklist.assignee.map(a => a.email);

          await checklists.putChecklistAssignees({
            key: newChecklist.data.key,
            assignees: emails
          });
        }
        return newChecklist;
      });

      await Promise.all(tasks);
      await dispatch('getChecklists', meetingKey);
    } catch (err) {
      console.error('ERROR: bulkAddAndAssignChecklists action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_CHECKLISTS(state, checklists) {
    // id required for v-data-table select all
    const cls = checklists.map(c => {
      return { ...c, id: c.key };
    });
    state.meetingChecklists = _.keyBy(cls, 'key');
    state.dataLastFetchedAt = new Date();
  },
  CLEAR_STATE(state) {
    state.meetingChecklists = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
