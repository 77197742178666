<template>
  <div>
    <v-layout column>
      <v-container>
        <v-toolbar class="elevation-1">
          <v-spacer />
          <v-btn
            color="primary"
            @click="
              dialog.add = true;
              addDialogClicked = true;
            "
          >
            + Conversation
          </v-btn>
          <v-btn color="primary" @click="openAutoScheduler">
            <v-icon left>library_add</v-icon> Multi-Conversations
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="currentConversations"
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <div class="pt-2 pb-2">
                  <b>
                    <a
                      @click.prevent="
                        onConversationClick(props.item.key, false)
                      "
                      >{{ props.item.name }}</a
                    >
                  </b>
                  <br />
                  <i>S: {{ props.item.subject }}</i>
                </div>
              </td>
              <td>
                <v-chip
                  label
                  small
                  :color="props.item.statusColor"
                  class="white--text"
                >
                  {{ props.item.status }}
                </v-chip>
              </td>
              <td>{{ props.item.recipients.length }} recipients</td>
              <td>
                {{ props.item.noStats ? 'n/a' : props.item.opened }}
              </td>
              <td>
                {{ props.item.datedAt | formatDate(meetingTimezone) }}
              </td>

              <td class="text-xs-right">
                <div class="d-inline-flex">
                  <v-tooltip top>
                    <div slot="activator">
                      <v-icon
                        class="pointer"
                        @click="onConversationClick(props.item.key, true)"
                        >content_copy</v-icon
                      >
                    </div>
                    <span>Copy</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <div slot="activator">
                      <v-icon
                        class="ml-1 pointer"
                        v-if="props.item.status !== 'sent'"
                        @click="onConversationClick(props.item.key, false)"
                        >edit</v-icon
                      >
                    </div>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <div slot="activator">
                      <v-icon
                        v-if="props.item.status === 'draft'"
                        class="ml-1 pointer  "
                        @click="deleteConversation(props.item.key)"
                        >delete</v-icon
                      >
                    </div>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-layout>

    <add-conversation-dialog
      v-if="addDialogClicked"
      v-model="dialog.add"
      :conversation-samples="currentConversationTemplateList"
      :current-meeting="currentMeeting"
      @add="onNewConversationClick"
      @close="
        dialog.add = false;
        addDialogClicked = false;
      "
    />
    <meeting-conversations-new-dialog
      v-if="conversationClicked"
      v-model="dialog.new"
      :current-meeting="currentMeeting"
      :current-conversation="currentConversation"
      :is-copy="isCopy"
      @close="
        dialog.new = false;
        conversationClicked = false;
        isCopy = true;
      "
    />
    <auto-scheduler-dialog
      v-model="dialog.autoScheduler"
      :current-meeting="currentMeeting"
      :conversation-group-list="currentConversationGroupList"
      :meeting-timezone="meetingTimezone"
      :conversation-template-list="this.state.conversationAddTemplateList"
      @close="resetAutoScheduler"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import MeetingConversationsNewDialog from './MeetingConversationsNewDialog';
import AddConversationDialog from '@/components/dialogs/AddConversationDialog';
import AutoSchedulerDialog from './MeetingConversationAutoSchedulerDialog';
import { mapActions } from 'vuex';

export default {
  name: 'MeetingConversationListView',
  components: {
    MeetingConversationsNewDialog,
    AddConversationDialog,
    AutoSchedulerDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    conversations: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversationList: {
      type: Object,
      required: true
    },
    conversationTemplateList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversationGroupTemplateList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversationGroupList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  filters: {
    formatDate(date, timezone) {
      if (!date || date === Infinity) {
        return '';
      }
      return moment(date)
        .tz(timezone)
        .format('MMM DD YYYY hh:mm a zz');
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'datedAt', sortable: false },
        { text: 'To', value: 'recipients', sortable: false },
        { text: 'Opened', value: 'actions', sortable: false },
        { text: 'Sent / Scheduled', value: 'datedAt' },
        { text: '', sortable: false, align: 'right' }
      ],
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'datedAt'
        }
      },
      dialog: {
        new: false,
        add: false,
        autoScheduler: false
      },
      state: {
        conversationAddTemplateList: []
      },
      currentConversation: {},
      conversationClicked: false,
      addDialogClicked: false,
      isCopy: false
    };
  },

  watch: {
    currentConversationTemplateList: function() {
      this.init();
    }
  },
  computed: {
    currentConversations() {
      return this.conversations.map(conversation => {
        // Conversations that are created from Notices test are not tracked
        // since they are related to voters.
        conversation.noStats = Boolean(
          conversation.status !== 'sent' ||
            (conversation.options && conversation.options.noticeKey) ||
            !conversation.options.replyTo
        );
        if (conversation.noStats) {
          return conversation;
        }
        const found = conversation.actions.find(
          action => action.action === 'conversation-open'
        );
        conversation.opened = found ? 'Yes' : 'No';
        return conversation;
      });
    },
    currentConversationTemplateList() {
      return this.conversationTemplateList;
    },
    currentConversationGroupTemplateList() {
      return this.conversationGroupTemplateList;
    },
    currentConversationGroupList() {
      return this.conversationGroupList;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/conversations', ['deleteMeetingConversation']),

    async init() {
      this.state.conversationAddTemplateList = this.currentConversationGroupTemplateList;
    },
    onNewConversationClick(newConversation) {
      if (newConversation) {
        this.currentConversation = {
          name: newConversation.name,
          subject: newConversation.subject,
          body: newConversation.body,
          meta: newConversation.meta
        };
        this.dialog.add = false;
      } else {
        this.currentConversation = {};
      }
      this.addDialogClicked = false;
      this.openConversationDialog();
    },
    onConversationClick(key, isCopy) {
      this.isCopy = isCopy;
      this.currentConversation = this.conversationList[key] || {};
      this.openConversationDialog();
    },
    openConversationDialog() {
      this.conversationClicked = true;
      this.dialog.new = true;
    },
    async deleteConversation(key) {
      const isConfirm = confirm(
        'Do you want to permanently delete this conversation?\n\nYou will not be able to undo it once deleted.'
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.deleteMeetingConversation({
          shortCode: this.currentMeeting.shortCode,
          conversationKey: key
        });
        this.$events.$emit('toastEvent', 'Conversation Deleted');
      } catch (err) {
        return this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async resetAutoScheduler() {
      this.dialog.autoScheduler = false;
    },

    openAutoScheduler() {
      this.dialog.autoScheduler = true;
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
