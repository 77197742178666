<template>
  <div class="mt-3">
    <div class="d-flex align-center justify-between">
      <h3>Proof of subscription's request</h3>
      <v-btn
        v-if="!subscriptionProof"
        color="primary"
        flat
        small
        @click.native="$emit('open')"
      >
        <v-icon>add</v-icon>Add Proof
      </v-btn>
    </div>
    <v-divider class="primary mt-2 mb-4" />

    <div v-if="!subscriptionProof" class="mt-3">
      <p class="subheading my-3">
        No subscription proof found for this account.
      </p>
    </div>

    <div v-else>
      <p class="subheading my-3">
        {{ proofFormat[subscriptionProof.type] }}:
        <a :href="subscriptionProof.value" target="blank">Open URL</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionProofCard',
  props: {
    subscriptionProof: {
      type: Object,
      default() {
        return null;
      }
    },
    proofTypes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      proofFormat: {
        signedAgreement: 'Signed agreement',
        electronicSignature: 'Electronic signature in CRM system'
      }
    };
  }
};
</script>
