<template>
  <v-dialog :value="open" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">Add checklists?</span>
      </v-card-title>

      <v-card-text>
        Proceed to the campaign for Checklist creation and CSM assignments
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click.native="close" flat>Cancel</v-btn>
        <v-spacer />

        <v-btn color="primary" @click.native="handleAction"
          >Add checklists</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChecklistDialog',

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comment: ''
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleAction() {
      this.$emit('redirect');
    }
  }
};
</script>

<style lang="scss" scoped></style>
