import { render, staticRenderFns } from "./MeetingSettingsCustomDataInput.vue?vue&type=template&id=96cbb8ee&scoped=true"
import script from "./MeetingSettingsCustomDataInput.vue?vue&type=script&lang=js"
export * from "./MeetingSettingsCustomDataInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96cbb8ee",
  null
  
)

export default component.exports