<template>
  <div>
    <div v-if="isUploading">
      <span class="caption">Uploading images ...</span>
      <v-progress-linear color="primary" height="2" indeterminate />
    </div>
    <div v-else>
      <span class="caption"
        >Insert image by
        <a href="#" class="accent--text" @click.prevent="addFileClicked"
          >clicking here</a
        >
      </span>
      <span v-if="isError" class="caption error"
        >Error uploading images, please try again.</span
      >
    </div>
    <!-- Hidden file upload to be triggered -->
    <input
      ref="file"
      type="file"
      accept="image/*"
      class="hidden-input"
      @change.prevent="onFileChange"
    />
  </div>
</template>

<script>
import { uploadPublicImages } from '@/api/files';

export default {
  name: 'InputLinkAddImages',
  description: 'Upload images and return URLs to access them',
  data() {
    return {
      isUploading: false,
      isError: false
    };
  },
  methods: {
    addFileClicked() {
      return this.$refs.file.click();
    },
    async onFileChange(e) {
      try {
        this.isUploading = true;
        this.isError = false;
        const files = e.target.files || e.dataTransfer.files;
        const urls = await uploadPublicImages(files);
        this.$emit('uploaded', urls);
      } catch (err) {
        console.error('Error uploading images', err);
        this.isError = true;
      } finally {
        // Clear to detect any change event
        e.target.value = '';
        this.isUploading = false;
      }
    }
  }
};
</script>

<style scoped>
.hidden-input {
  display: none;
}
.error {
  display: block;
}
</style>
