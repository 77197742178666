'use strict';

/**
 * @fileoverview Vuex module for Products
 */
import _ from 'lodash';
import * as productsApi from '@/api/products';
import Vue from 'vue';

const state = {
  products: {},
  dataLastFetchedAt: null,
  productTypes: [
    { value: 0, label: 'Transactional' },
    { value: 1, label: 'Subscription' },
    { value: 2, label: 'Discount' }
  ],
  categories: {
    0: ['professional services', 'software'],
    1: ['software license'],
    2: ['discount']
  },
  pagination: {
    descending: false,
    sortBy: 'productType',
    page: 1,
    rowsPerPage: 25,
    totalItems: 0,
    rowsPerPageItems: [25, 50, 100, 200],
    search: '',
    productType: null
  }
};

const getters = {
  products: state => state.products,
  productsData: state => Object.values(state.products),
  productsByCategory: state => category => {
    return Object.values(state.products).filter(p => p.category === category);
  },
  productByCode: state => code => state.products[code],
  productByKey: (state, getters) => key =>
    getters.productsData.find(p => p.key === key),
  productTypes: state => state.productTypes,
  categories: state => state.categories,
  categoriesByType: state => productType => state.categories[productType],
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  pagination: state => state.pagination
};

const actions = {
  /**
   * Return the list of campaign billing data
   * @param {Object}   [params]
   * @param {String}   [params.category]   optional category filter
   * @param {String}   [params.type]       optional product type filter
   * @param {String}   [params.search]     optional search filter
   */
  async getProducts({ commit }, { category, type, search } = {}) {
    const res = await productsApi.listProducts({ category, type, search });
    const products = _.keyBy(res.data, 'productCode');
    commit('SET_PRODUCTS', products);
    commit('SET_DATA_FETCHED_AT', new Date());
  },

  /**
   * Return the list of products stored in Chargebee
   * @param {Object}   [params]
   * @param {String}   [params.currency]   - currency filter ('CAD' or 'USD')
   */
  async getChargebeeProducts({ commit }, { currency } = {}) {
    const res = await productsApi.listChargebeeProducts({ currency });
    commit('SET_PRODUCTS', res.data);
    commit('SET_DATA_FETCHED_AT', new Date());
  },

  /**
   * Get a product
   * @param {Object}   [params]
   * @param {String}   params.productKey
   */
  async getProduct({ commit }, { productKey }) {
    const res = await productsApi.getProduct({ productKey });
    commit('SET_PRODUCT', res.data);
  },

  async updateProduct({ commit }, { productKey, product }) {
    const res = await productsApi.updateProduct({ productKey, product });
    commit('SET_PRODUCT', res.data);
  },

  async deleteProduct({ commit }, { productKey, productCode }) {
    await productsApi.deleteProduct({ productKey });
    commit('DELETE_PRODUCT', productCode);
  },

  async addProduct({ commit }, { product }) {
    const res = await productsApi.addProduct({ product });
    commit('SET_PRODUCT', res.data);
    return res.data.key;
  },

  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination);
  }
};

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_PRODUCT(state, product) {
    Vue.set(state.products, product.productCode, product);
  },
  DELETE_PRODUCT(state, productCode) {
    Vue.delete(state.products, productCode);
  },
  SET_DATA_FETCHED_AT(state, value) {
    state.dataLastFetchedAt = value;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
