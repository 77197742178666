<template>
  <div>
    <div class="gq-cell--section-title">Summary of Proxy Holders</div>
    <v-data-table
      :headers="headerOptions"
      :items="proxyHolders"
      item-key="holderName"
      :pagination.sync="config.pagination"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td>
            {{ props.item.holderName }}
          </td>
          <td v-if="generalProxyEnabled">
            {{ props.item.holderEmail }}
          </td>
          <td>
            {{ props.item.proxiesCount }}
          </td>
          <td>
            {{ props.item.sharesCount }}
          </td>
          <td>
            <v-btn
              flat
              outline
              color="primary"
              @click="viewProxiesByHolder(props.item.holderName)"
              >view proxies</v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>

    <view-proxies-by-holder-dialog
      :value="dialog"
      :holders="proxyHolders"
      :holder="holder"
      :timezone="timezone"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ViewProxiesByHolderDialog from '@/components/dialogs/ViewProxiesByHolderDialog';

export default {
  name: 'ProxyHolderSummaryTable',
  components: { ViewProxiesByHolderDialog },
  props: {
    proxyHolders: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    timezone: {
      type: String,
      required: true,
      default() {
        return 'America/Toronto';
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Proxy Holder Name', value: 'holderName', sortable: true },
        { text: 'Proxy Holder Email', value: 'holderEmail', sortable: true },
        {
          text: 'Proxies by Unit',
          value: 'proxiesCount',
          sortable: true
        },
        {
          text: 'Proxies by Shares',
          value: 'sharesCount',
          sortable: true
        },
        {
          text: '',
          sortable: false
        }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, 100, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'sharesCount',
          descending: true
        }
      },
      holder: '',
      dialog: false
    };
  },
  computed: {
    generalProxyEnabled() {
      return _.get(this.options, 'useGeneralProxyApp', false);
    },
    headerOptions() {
      return this.generalProxyEnabled
        ? this.headers
        : _.filter(this.headers, option => option.value !== 'holderEmail');
    }
  },
  methods: {
    viewProxiesByHolder(holderName) {
      this.holder = holderName;
      this.dialog = true;
    }
  }
};
</script>

<style scoped></style>
