import { render, staticRenderFns } from "./AdminNoticeScheduleViewWeek.vue?vue&type=template&id=dec858ec&scoped=true"
import script from "./AdminNoticeScheduleViewWeek.vue?vue&type=script&lang=js"
export * from "./AdminNoticeScheduleViewWeek.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dec858ec",
  null
  
)

export default component.exports