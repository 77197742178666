<template>
  <v-card elevation="2">
    <v-card-text>
      <v-form>
        <v-checkbox
          v-for="permission in permissions"
          v-model="selected"
          :key="permission.value"
          :label="permission.text"
          :value="permission.value"
          dense
          hide-details
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions class="text-xs-right">
      <v-spacer />
      <v-btn
        flat
        @click="submitUserChange"
        color="primary"
        :disabled="!isTypeChanged"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
export default {
  name: 'UserPermissionsCard',
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: [],
      permissions: [{ text: 'Invoices read', value: 'meeting.invoices.read' }]
    };
  },
  computed: {
    isTypeChanged() {
      return !_.isEqual(this.selected, this.currentUser.permissions);
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('users', ['updateUser']),
    async init() {
      this.selected = this.currentUser.permissions;
    },
    async submitUserChange() {
      try {
        const userKey = this.currentUser.key;
        const userUpdate = {
          permissions: this.selected
        };

        await this.updateUser({ userKey, user: userUpdate });
        this.$events.$emit('toastEvent', 'User Permissions Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
