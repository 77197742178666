<template>
  <v-card v-if="!hideAdditionalServices" class="card" :elevation="0">
    <div v-if="currentRequestData">
      <v-card-title v-if="title" class="card-header">
        <b>{{ title }}</b>
      </v-card-title>
      <!--  Service details #1 -->
      <v-container v-if="step === 1" class="white px-3 py-3" grid-list-md>
        <v-layout row wrap>
          <v-flex xs5>
            <div class="card-option">
              <v-icon color="primary" class="card-icon">article</v-icon>
              <div class="ml-3 d-grid">
                <span><b>Account</b></span>
                <span>{{ requestAccount.name }}</span>
              </div>
            </div>
          </v-flex>
          <v-flex xs7>
            <div class="card-option">
              <v-icon color="primary" class="card-icon">laptop</v-icon>
              <span class="mx-3"><b>Service Type</b></span>
              <v-chip color="#e7f2fb">
                <span>{{ serviceType }}</span>
              </v-chip>
            </div>
          </v-flex>
          <v-flex xs5>
            <div class="card-option">
              <v-icon color="primary" class="card-icon">event</v-icon>
              <div class="ml-3 d-grid">
                <span><b>Date</b></span>
                <span>{{
                  currentRequestData.meetingDate
                    | formatDate(currentRequestData.meetingTimezone)
                }}</span>
              </div>
            </div>
          </v-flex>
          <v-flex
            v-if="
              currentRequestData.options.typeService === 'campaign_amendment'
            "
            xs5
          >
            <div class="card-option">
              <v-icon color="primary" class="card-icon">event</v-icon>
              <div class="ml-3 d-grid">
                <span><b>Close submissions</b></span>
                <span>{{
                  currentRequestData.expiryDate
                    | formatDate(currentRequestData.expiryDate)
                }}</span>
              </div>
            </div>
          </v-flex>
          <v-flex v-if="!hideTime" xs7>
            <div class="card-option">
              <v-icon color="primary" class="card-icon">query_builder</v-icon>
              <div class="ml-3 d-grid">
                <span><b>Time</b></span>
                <span>{{
                  currentRequestData.meetingDate
                    | formatTime(currentRequestData.meetingTimezone)
                }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>

          <v-flex
            v-if="
              currentRequestData.options.typeService === 'campaign_amendment'
            "
            xs6
          >
            <div class="card-option">
              <div class="ml-3 d-grid">
                <span><b>Quorum minimum</b></span>
                <span>{{ currentRequestData.quorumMinimum }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex v-if="!hideFormat" xs6>
            <div class="card-option">
              <div class="ml-3 d-grid">
                <span><b>Format</b></span>
                <span>{{ format }}</span>
              </div>
            </div>
          </v-flex>

          <v-flex
            xs6
            v-for="question in serviceDetailQuestions"
            :key="question.title"
          >
            <div class="card-option">
              <div class="ml-3 d-grid">
                <span
                  ><b>{{ question.title }}</b></span
                >
                <span>{{ question.value }}</span>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container v-if="step === 2" class="white px-3 py-3" grid-list-md>
        <v-layout row wrap>
          <v-flex
            xs6
            v-for="additional in additionalServiceQuestions"
            :key="additional.title"
          >
            <div class="card-option">
              <div class="ml-3 d-grid">
                <span
                  ><b>{{ additional.title }}</b></span
                >
                <span>{{ additional.value }}</span>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container
        v-else-if="!step && isAdmin"
        class="white px-3 py-3"
        grid-list-md
      >
        <v-layout row wrap>
          <v-flex xs6>
            <div class="card-option">
              <div class="ml-3 d-grid">
                <span><b>Hubspot</b></span>
                <a @click="openDeal">See deal details</a>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import moment from 'moment';
import services from '@/lib/service-details';

export default {
  name: 'ServiceCard',
  filters: {
    formatDate(date, timeZone) {
      return moment(date)
        .tz(timeZone)
        .format('MMMM DD, YYYY');
    },
    formatTime(date, timeZone) {
      return moment(date)
        .tz(timeZone)
        .format('hh:mm a zz');
    }
  },
  props: {
    title: {
      type: String,
      default: 'NA'
    },
    currentRequestData: {
      type: Object
    },
    requestAccount: {
      type: Object
    },
    step: {
      type: Number,
      default: 0
    },
    dealUrl: {
      type: String,
      default: 'NA'
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters(['login']),
    isReviewer() {
      return _.some(this.reviewers, ['key', this.login.profile.key]);
    },
    hideAdditionalServices() {
      return (
        this.step === 2 &&
        (!this.additionalServiceQuestions ||
          this.additionalServiceQuestions.length === 0)
      );
    },
    serviceType() {
      const type =
        this.currentRequestData?.options?.typeService ||
        this.currentRequestData?.options?.type;

      return services.typeService[type];
    },
    hideTime() {
      return [
        'campaign_email_collection',
        'campaign_budget',
        'campaign_certs',
        'campaign_advance',
        'campaign_amendment'
      ].includes(this.currentRequestData?.options.typeService);
    },
    hideFormat() {
      return [
        'campaign_email_collection',
        'campaign_budget',
        'campaign_certs',
        'campaign_advance',
        'campaign_amendment'
      ].includes(this.currentRequestData?.options.typeService);
    },
    format() {
      const subtype = _.get(this.currentRequestData?.options, 'subtype');
      const virtualMode = _.get(
        this.currentRequestData?.options,
        'virtualMode'
      );

      if (virtualMode) {
        return services.type[virtualMode] || 'N/A';
      }

      return services.type[subtype] || 'N/A';
    },
    serviceDetailQuestions() {
      const data = this.currentRequestData.meetingMeta;

      let questions = [];
      for (const question in data) {
        if (services.details[question]) {
          let value = 'N/A';

          if (question === 'additionalDetails') {
            value = data[question] || 'None';
          } else {
            if (data[question]) {
              value = 'Yes';
            } else if (data[question] === false) {
              value = 'No';
            }
          }

          questions.push({
            title: services.details[question],
            value
          });
        }
      }

      return questions;
    },
    additionalServiceQuestions() {
      const data = this.currentRequestData.meetingMeta;
      let questions = [];

      let printedPackageQuestion = null;

      for (const question in data) {
        if (services.additional[question]) {
          let value = 'N/A';

          if (question === 'printedPackageMailedTo') {
            printedPackageQuestion = {
              title: services.additional[question],
              value: _.startCase(data[question]) || 'N/A'
            };
            continue;
          } else {
            if (data[question]) {
              value = 'Yes';
            } else if (data[question] === false) {
              value = 'No';
            }
          }

          questions.push({
            title: services.additional[question],
            value
          });
        }
      }

      if (printedPackageQuestion) {
        questions.push(printedPackageQuestion);
      }

      return questions;
    },
    digitalNomination() {
      return _.startCase(
        _.get(
          this.currentRequestData,
          'additionalCandidateNominationTool',
          'none'
        )
      );
    },
    takingServices() {
      return _.startCase(
        _.get(this.currentRequestData, 'additionalServicesMinuteTaking', 'none')
      );
    },
    pritingAndMailing() {
      return _.startCase(
        _.get(
          this.currentRequestData,
          'additionalServicesPrintingMailing',
          'none'
        )
      );
    }
  },
  methods: {
    openDeal() {
      window.open(this.dealUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.card-header {
  background-color: #e7f2fb !important;
}
.card {
  border: 2px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}

.card-icon {
  background-color: #e7f2fb;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
}

.card-option {
  display: flex;
  align-items: center;
  height: 100%;
}

.d-grid {
  display: grid;
}
</style>
