<template>
  <div>
    <v-layout row nowrap align-center class="ma-0 mb-2 pa-0">
      <h4 class="body-2">Hubspot Information</h4>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-layout column>
          <div class="body-2 grey--text">
            Status:
            <span v-if="companyId" class="green--text">Company Linked</span>
            <span v-else class="red--text">No company linked</span>
          </div>
        </v-layout>
      </v-card-text>
      <v-card-text v-if="companyId">
        <a :href="hubspotUrl" target="_blank"
          >Click here to open Hubspot site</a
        >
      </v-card-text>

      <v-card-actions v-if="!companyId">
        <v-btn small color="primary" flat @click="dialog.link = true"
          >Link a company</v-btn
        >
      </v-card-actions>
      <v-card-actions v-if="companyId">
        <v-btn small color="red" flat @click="dialog.unlink = true"
          >Unlink company</v-btn
        >
      </v-card-actions>
    </v-card>

    <admin-account-hubspot-link-dialog
      :value="dialog.link"
      @close="dialog.link = false"
    />

    <confirm-text-dialog
      :dialog="dialog.unlink"
      title="Unlink Company"
      width="400"
      description="Are you sure you want to unlink this company?. This action will remove the synchronization between your GQ account and the Hubspot company."
      confirm-text="confirm"
      :loading="loading"
      @close="dialog.unlink = false"
      @confirm="handleUnlink"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AdminAccountHubspotLinkDialog from './AdminAccountHubspotLinkDialog';
import ConfirmTextDialog from '@/components/dialogs/ConfirmTextDialog';

export default {
  name: 'AdminAccountHubspotViewInfo',
  components: {
    AdminAccountHubspotLinkDialog,
    ConfirmTextDialog
  },
  props: {
    companyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: {
        unlink: false,
        link: false
      },
      loading: false
    };
  },
  computed: {
    hubspotUrl() {
      const hubspotSite = process.env.VUE_APP_HUBSPOT_SITE || '24343778';
      return `https://app.hubspot.com/contacts/${hubspotSite}/company/${this.companyId}`;
    }
  },
  methods: {
    ...mapActions('accounts', ['unlinkCompany']),
    async handleUnlink(confirm) {
      if (!confirm) {
        this.dialog.unlink = false;
        return;
      }

      this.loading = true;
      try {
        const accountKey = this.$route.params.accountKey;
        await this.unlinkCompany({
          accountKey
        });

        this.$events.$emit('toastEvent', 'Company unlinked');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
        this.dialog.unlink = false;
      }
    }
  }
};
</script>

<style scoped>
.hs-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
