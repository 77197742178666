<template>
  <v-app id="app">
    <transition name="fade" mode="out-in"> <router-view /> </transition>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  methods: {
    ...mapActions(['getUserProfile']),
    ...mapActions('features', ['loadFeatureFlags'])
  },
  async created() {
    // In situations where the app is hard reloaded, get the latest
    // user profile information to ensure we have proper permissions.
    await this.getUserProfile();

    // Load feature flags for experimental testing from localStorage
    this.loadFeatureFlags({ shortCode: this.$route.params.shortcode });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '/assets/common.scss';
@import '/assets/grid.scss';

html {
  zoom: 100% !important;
}
html,
body {
  min-height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: $body-background !important;
}
.top-align {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.gq-cell--section-title {
  color: $secondary-font-color;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
}
a {
  color: #e91e63;
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.float-right {
  float: right;
}
.table-cell--actions-width {
  width: 50px;
}
a {
  text-decoration: none !important;
}
.nowrap {
  white-space: nowrap;
}
.helper-text {
  font-size: 10px;
  font-style: italic;
  opacity: 0.87;
}

/* Transitions */
/* always present */
.slide-fade-enter-active {
  transition: all 0.2s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.background {
  background-color: rgba(0, 0, 0, 0.1);
}

.gq-divider {
  margin: -17px;
  margin-top: 17px;
  margin-bottom: 17px;
  width: auto;
}

.gq-blue-background {
  background-color: #0861a1;
}
.login-image {
  height: 70px;
}
.flex-center {
  display: flex;
  align-items: center;
}
// Custom active tab style for vbm dashboard document tabs
.v-tabs__item.vbm-document__tab--active {
  background-color: #e7f2fb;
  height: 44px;
}
</style>
