<template>
  <v-dialog max-width="500px" :value="value" persistent>
    <v-card>
      <v-card-title primary-title>
        <div v-if="isNewRestriction" class="title">Add Custom Restriction</div>
        <div v-else class="title">Update Custom Restriction</div>
      </v-card-title>
      <v-container grid-list-md>
        <v-layout row></v-layout>
        <v-layout row>
          <v-flex xs6>
            <v-select
              label="Custom Field"
              :items="availableCustomFields"
              no-data-text="No custom fields found."
              v-model="newRestriction.key"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field label="Field Value" v-model="newRestriction.value" />
          </v-flex>
        </v-layout>
      </v-container>

      <v-card-actions>
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="isDisabled" @click.native="emitUpdate"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EditRestrictionDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    restriction: {
      type: Object,
      default() {
        return {};
      }
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      newRestriction: {}
    };
  },
  watch: {
    value() {
      this.newRestriction = _.cloneDeep(this.restriction);
    }
  },
  computed: {
    availableCustomFields() {
      return this.customFields.filter(
        f => f !== 'shares' && f !== 'ineligible_reason'
      );
    },
    isNewRestriction() {
      return _.isEmpty(this.restriction);
    },
    isDisabled() {
      return _.isEqual(this.restriction, this.newRestriction);
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('update', this.restriction, this.newRestriction);
      this.$emit('close');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
