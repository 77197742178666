<template>
  <v-dialog :value="isOpen" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <!-- Product list (GQ billing) -->
            <v-layout row v-if="!isChargebee">
              <v-flex xs12>
                <v-autocomplete
                  v-model="item.productCode"
                  :items="products"
                  label="Product Name"
                  item-text="label"
                  item-value="value"
                  @change="emitUpdate('productCode', $event)"
                  required
                  box
                  :disabled="isProductCodeDisabled"
                />

                <v-text-field
                  v-model="item.category"
                  label="Category"
                  box
                  disabled
                />

                <v-textarea
                  rows="1"
                  auto-grow
                  v-model="item.description"
                  label="Description"
                  placeholder="Virtual Meeting Moderator..."
                  :disabled="!descriptionEditable"
                  box
                />
                <v-text-field
                  v-model="item.value"
                  :label="`Price (${currency})`"
                  placeholder="0.00"
                  type="number"
                  @input="emitUpdate('value', $event)"
                  box
                />
              </v-flex>
            </v-layout>

            <!-- Product list (Chargebee billing) -->
            <v-layout row v-if="isChargebee">
              <v-flex xs12>
                <v-autocomplete
                  v-model="product"
                  :items="products"
                  label="Product Name"
                  item-text="externalName"
                  return-object
                  dense
                />

                <v-textarea
                  rows="1"
                  auto-grow
                  v-if="product.description"
                  v-model="product.description"
                  label="Description"
                  placeholder="Virtual Meeting Moderator..."
                  :disabled="!descriptionEditable"
                  box
                />

                <v-text-field
                  v-if="product.pricingModel === 'flat_fee'"
                  v-model="product.price"
                  :label="`Price (${currency})`"
                  placeholder="0.00"
                  type="number"
                />

                <div v-if="product.pricingModel === 'stairstep'">
                  <v-toolbar dense color="grey lighten-4 elevation-1">
                    <span>Pricing Model Breakdown</span>
                    <v-spacer />
                  </v-toolbar>

                  <v-data-table
                    :headers="headers"
                    :items="productTiers"
                    hide-actions
                    class="elevation-1"
                  >
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td class="text-xs-left">
                          {{ props.item.numUnits }}
                        </td>
                        <td class="text-xs-left">{{ props.item.price }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </v-flex>
            </v-layout>

            <!-- Stairstep pricing fields -->
            <v-layout v-if="isChargebee" row class="mt-2">
              <v-flex xs6 class="mr-1">
                <v-text-field
                  v-if="product.pricingModel === 'stairstep'"
                  v-model="product.quantity"
                  label="Number of Units"
                  type="number"
                  @input="calculatePrice"
                />
              </v-flex>
              <v-flex xs6 class="ml-1"
                ><v-text-field
                  v-if="product.pricingModel === 'stairstep'"
                  v-model="product.price"
                  :label="`Price (${currency})`"
                  placeholder="0.00"
                  type="number"
              /></v-flex>
            </v-layout>

            <!-- Per Unit pricing fields -->
            <v-layout v-if="isChargebee" row>
              <v-flex xs4 class="mr-1">
                <v-text-field
                  v-if="product.pricingModel === 'per_unit'"
                  v-model="product.quantity"
                  label="Number of Units"
                  type="number"
                  @input="calculatePrice"
                />
              </v-flex>
              <v-flex xs4 class="ml-1"
                ><v-text-field
                  v-if="product.pricingModel === 'per_unit'"
                  v-model="product.pricePerUnit"
                  :label="`Price Per Unit (${currency})`"
                  placeholder="0.00"
                  type="number"
                  @input="calculatePrice"
              /></v-flex>
              <v-flex xs4 class="ml-1"
                ><v-text-field
                  v-if="product.pricingModel === 'per_unit'"
                  v-model="product.price"
                  :label="`Total Price (${currency})`"
                  placeholder="0.00"
                  type="number"
                  disabled
              /></v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="close">Close</v-btn>
        <v-btn color="primary" :disabled="isSaveDisabled" @click.native="save"
          >save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'AddMeetingBillingItemDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    currency: {
      type: String,
      default() {
        return 'CAD';
      }
    },
    item: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    itemSelected: {
      type: String,
      default: null
    },
    descriptionEditable: {
      type: Boolean,
      required: true,
      default: false
    },
    isChargebee: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  watch: {
    item: function() {
      if (this.item.id) {
        const chargebeeProduct = this.products.find(p => p.id === this.item.id);
        this.product = {
          ...this.item,
          tiers: chargebeeProduct?.tiers,
          currencyCode: chargebeeProduct?.currencyCode
        };
      }
    },
    'item.productCode': function() {
      const productInfo = this.productByCode(this.item.productCode);

      if (productInfo) {
        this.$emit('update', {
          key: 'description',
          value: productInfo.description
        });

        this.$emit('update', {
          key: 'category',
          value: productInfo.category
        });

        const price =
          this.currency === 'CAD' ? productInfo.valueCad : productInfo.valueUsd;
        this.$emit('update', {
          key: 'value',
          value: Number(price)
        });
      }
    }
  },
  computed: {
    ...mapGetters('products', ['productByCode']),
    isProductCodeDisabled() {
      return (
        !_.isEmpty(String(this.item.productCode)) &&
        !_.isEmpty(this.itemSelected)
      );
    },
    productTiers() {
      if (this.product?.pricingModel !== 'stairstep') {
        return [];
      }

      return this.product.tiers.map(tier => {
        const numUnits = tier.endingUnit
          ? `${tier.startingUnit}-${tier.endingUnit}`
          : `${tier.startingUnit}+`;
        const price = '$' + tier.price;

        return { numUnits, price };
      });
    },
    isSaveDisabled() {
      // Don't allow saving if price does not exist
      if (!this.product?.price) {
        return true;
      }

      // Don't allow saving if product is stairstep/per unit,
      // and quantity does not exist
      if (
        this.product?.pricingModel !== 'flat_fee' &&
        !this.product?.quantity
      ) {
        return true;
      }

      return false;
    }
  },
  data() {
    return {
      product: {},
      calculatedPrice: 0,
      headers: [
        { text: 'Number of Units', value: 'numUnits', sortable: false },
        { text: 'Price', value: 'price', sortable: false }
      ]
    };
  },
  methods: {
    calculatePrice: _.debounce(function() {
      if (this.product.pricingModel === 'stairstep') {
        const tier = this.product.tiers.find(tier => {
          if (tier.endingUnit) {
            return (
              this.product.quantity >= tier.startingUnit &&
              this.product.quantity <= tier.endingUnit
            );
          }
          return this.product.quantity >= tier.startingUnit;
        });

        this.product.price = tier.price;
      }

      if (this.product.pricingModel === 'per_unit') {
        this.product.price = this.product.quantity * this.product.pricePerUnit;
      }
    }, 350),
    close() {
      this.$emit('close-dialog');
    },
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    },
    save() {
      this.$emit('save', this.product);
      this.product = {};
    }
  }
};
</script>

<style scoped>
.v-table tr:hover {
  background: transparent !important;
}
</style>
