<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Contacts</p>
        <p class="secondary--text">
          View and manage all GetQuorum Contacts on Hubspot.
        </p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ma-3">
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search by contact email or name"
            single-line
            hide-details
            clearable
            :value="search"
            @input="updateSearch"
          />
          <v-spacer />

          <v-btn color="primary" @click.stop="dialog.addContact = true">
            <v-icon left dark>add</v-icon>Contact
          </v-btn>
        </v-toolbar>
        <v-data-table
          :items="contactList"
          :headers="headers"
          class="elevation-1"
          hide-actions
        >
          <template v-slot:items="props">
            <td>
              <a @click="onEdit(props.item)"> {{ props.item.email }}</a>
            </td>
            <td>{{ props.item.firstName }}</td>
            <td>{{ props.item.lastName }}</td>
            <td>{{ props.item.phone }}</td>
            <td>
              <a :href="hubspotUrl({ id: props.item.id })" target="_blank"
                >View more</a
              >
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <add-contact-dialog
      :is-open="dialog.addContact"
      :contact="contactSelected"
      @closeDialog="dialog.addContact = false"
      @emitAdd="addContact"
      @emitEdit="editContact"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatReadable } from '@/filters';

import AddContactDialog from './AddContactDialog.vue';
export default {
  name: 'AdminContactsListView',
  filters: {
    dateFormatReadable
  },
  components: {
    AddContactDialog
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Email', sortable: false },
        { text: 'First Name', sortable: false },
        { text: 'Last Name', sortable: false },
        { text: 'Phone', sortable: false },
        { text: '', sortable: false }
      ],
      dialog: {
        addContact: this.$route.query.add || false
      },
      contactSelected: null
    };
  },
  computed: {
    ...mapGetters('contacts', ['contactList'])
  },
  created() {
    this.getContacts();
  },
  methods: {
    ...mapActions('contacts', [
      'getContacts',
      'createContact',
      'updateContact'
    ]),
    async addContact(payload) {
      try {
        await this.createContact(payload);
        this.$events.$emit('toastEvent', 'New Contact Added');
        this.dialog.addContact = false;
      } catch (err) {
        this.$events.$emit('toastEvent', err.response.data);
      }
    },
    updateSearch: _.debounce(async function(value) {
      await this.getContacts(value);
    }, 350),
    hubspotUrl({ id }) {
      const hubspotSite = process.env.VUE_APP_HUBSPOT_SITE || '24343778';

      return `https://app.hubspot.com/contacts/${hubspotSite}/${id}`;
    },
    onEdit(contact) {
      this.contactSelected = contact;
      this.dialog.addContact = true;
    },
    async editContact(payload) {
      try {
        const id = this.contactSelected.id;
        await this.updateContact({ id, payload });
        this.$events.$emit('toastEvent', 'Contact Updated');
        this.contactSelected = null;
        this.dialog.addContact = false;
      } catch (err) {
        this.$events.$emit('toastEvent', err.response.data);
      }
    }
  }
};
</script>

<style scoped>
.name-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.fade {
  opacity: 0.74;
}
</style>
