<template>
  <v-dialog :value="value" persistent max-width="700px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Preferred CSM assignment</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout row>
            <v-flex xs12>
              <p>
                To assign a CSM for this Request, select an option below and
                click <b>SAVE</b>.
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="mt-2">
            <v-flex xs12>
              <v-combobox
                v-model="managerSelected"
                :items="options"
                label="Preferred CSM"
                item-text="name"
                return-object
                dense
                outline
                required
              ></v-combobox>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="close" flat>Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="updateManager"
          :disabled="isSaveDisabled"
          color="primary"
          flat
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AddRequestAssigneeDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    currentManager: {
      type: Object,
      default() {
        return null;
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      managerSelected: this.currentManager
    };
  },
  computed: {
    isSaveDisabled() {
      if (!this.managerSelected) {
        return true;
      }

      if (
        this.currentManager &&
        _.isEqual(this.currentManager.userKey, this.managerSelected)
      ) {
        return true;
      }

      return false;
    }
  },
  methods: {
    updateManager() {
      this.$emit('update', this.managerSelected.key);
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
