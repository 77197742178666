<template>
  <v-dialog
    persistent
    :value="isOpen"
    max-width="700px">

    <v-card>
      <v-card-title>
        <span class="headline">Edit Agreement Created on {{ agreement.createdAt | dateFormatReadable(false) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>

          <v-layout row>
            <v-flex xs9>
              <date-picker
                label="Date agreement was signed" 
                :iso-date="state.signedAt" 
                @update="setSignedAt" />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs9>
              <date-picker
                label="Does this agreement expire, and if so when?"
                :iso-date="state.expiresAt" 
                :hint="'If there is no expiry date, leave this blank'" 
                @update="setExpiresAt" />
            </v-flex>
          </v-layout>

          <v-layout 
            row
            mt-3>
            <v-flex xs12>
              <v-textarea
                v-model="state.description"
                label="Any special notes that we should be aware of?"
                placeholder="eg. This agreement has special pricing as requested by the client's lawyers, etc...."
                outline />
                
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click.native="$emit('close-dialog')"
          flat>
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click.native="save" 
          flat>Save</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog> 

</template>
  
<script>
import * as filters from '@/filters';
import DatePicker from '@/components/DatePicker';
export default {
  name: 'AgreementEditDialog',
  components: {
    DatePicker
  },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    agreement: {
      type: Object,
      default() {
        return {
          key: ''
        };
      }
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      state: {
        key: null,
        signedAt: null,
        expiresAt: null,
        description: null,
        type: null
      }
    };
  },
  watch: {
    isOpen() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  computed: {},
  methods: {
    setState() {
      this.state.key = this.agreement.key;
      this.state.signedAt = this.agreement.signedAt;
      this.state.expiresAt = this.agreement.expiresAt;
      this.state.description = this.agreement.description;
      this.state.type = this.agreement.type;
    },
    setSignedAt(value) {
      this.state.signedAt = value;
    },
    setExpiresAt(value) {
      this.state.expiresAt = value;
    },
    save() {
      this.$emit('save', {...this.state});
      this.$emit('close-dialog');
    }
  }
};
</script>

<style scoped>
</style>
