'use strict';

/**
 * @fileoverview The vuex submodule for admin/users user preferences.
 *
 * NOTE: To see the User API actions, see the `@/vuex/modules/users`
 * file.
 */

const state = {
  searchText: ''
  // statusFilter: 'all'
};

const getters = {
  searchText: state => state.searchText
  // statusFilter: state => state.statusFilter
};

const actions = {
  setSearchText({commit}, searchText) {
    commit('SET_SEARCH_TEXT', searchText);
  }
  // setStatusFilter({commit}, statusFilter) {
  //   commit('SET_STATUS_FILTER', statusFilter);
  // }
};

const mutations = {
  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText;
  }
  // SET_STATUS_FILTER(state, statusFilter) {
  //   state.statusFilter = statusFilter;
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
