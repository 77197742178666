'use strict';

/**
 * @fileoverview Vuex module for users
 */

import _ from 'lodash';
import Vue from 'vue';

import { users } from '@/api';

const state = {
  userList: {},
  dataLastFetchedAt: null
};

const getters = {
  userList: state => state.userList,
  userListData: state => Object.values(state.userList),
  userByKey: state => key => state.userList[key],
  dataLastFetchedAt: state => state.dataLastFetchedAt
};

const actions = {
  /**
   * Get the latest list of users from API
   */
  async getUsers({ commit }) {
    try {
      commit('UNSET_DATA_LAST_FETCHED_AT');
      const res = await users.getUsersList();
      const usersWithNamesCombined = res.data.map(user => {
        return { ...user, name: `${user.firstName} ${user.lastName}` };
      });
      commit('SET_USER_LIST_DATA', usersWithNamesCombined);
    } catch (err) {
      console.error('ERROR: getUsers action', err);
      throw err;
    }
  },

  async getPartialUsers({ commit }, search = '') {
    try {
      const res = await users.getUsersPartialList({
        search
      });
      commit('SET_USER_LIST_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getPartialUsers action', err);
      throw err;
    }
  },

  /**
   * Get the user identified by key from API
   * @param {String} userKey - the user key
   */
  async getUser({ commit }, userKey) {
    try {
      const usrRes = await users.getUserByKey(userKey);
      // @TODO: FIX @jjhiew
      const memRes = await users.getUserAccounts(userKey);

      const meetingsRes = await users.getMeetingsOfUserAsCollaborator(userKey);

      const agentsRes = await users.getUserAgents({ userKey });

      const user = {
        ...usrRes.data,
        name: `${usrRes.data.firstName} ${usrRes.data.lastName}`,
        memberships: memRes.data,
        meetings: meetingsRes.data,
        agents: agentsRes.data
      };

      commit('SET_USER_IN_LIST', { key: user.key, data: user });
    } catch (err) {
      console.error('ERROR: getUser action', userKey, err);
      throw err;
    }
  },

  /**
   * Create a new user in API
   * @param  {Object} user     the new user payload
   * @return {Object}          the newly created user
   */
  async createUser({ dispatch }, user) {
    try {
      const payload = { ...user, username: user.email };
      const res = await users.postUser(payload);
      dispatch('getUser', res.data.key);
      return res.data;
    } catch (err) {
      console.error('ERROR: createUser action', err);
      throw err;
    }
  },

  /**
   * Update a user in the API
   * @param {String} userKey  - the user to update
   * @param {Object} user     - the user update payload
   */
  async updateUser({ dispatch }, { userKey, user }) {
    try {
      await users.patchUser(userKey, user);
      dispatch('getUser', userKey);
    } catch (err) {
      console.error('ERROR: updateUser action', userKey, err);
      throw err;
    }
  },

  /**
   * Delete a user
   *
   * @param  {String} userKey  - the user to send the email to
   */
  async removeUser({ dispatch }, userKey) {
    try {
      await users.deleteUser(userKey);
      dispatch('getUsers');
    } catch (err) {
      console.error('ERROR: removeUser action', userKey, err);
      throw err;
    }
  },

  /**
   * Send the reset password email to the user.
   *
   * @param  {String} userKey  - the user to send the email to
   */
  async sendPasswordResetEmail({ dispatch }, userKey) {
    try {
      await users.postPasswordEmail(userKey);
      // dispatch('getUser', userKey);
    } catch (err) {
      console.error('ERROR: sendPasswordResetEmail action', userKey, err);
      throw err;
    }
  },

  /**
   * Send the welcome invite email to the user.
   *
   * @param  {String} userKey  - the user to send the email to
   */
  async sendWelcomeEmail({ dispatch }, { userKey }) {
    try {
      await users.postWelcomeEmail(userKey);
      // dispatch('getUser', userKey);
    } catch (err) {
      console.error('ERROR: sendWelcomeEmail action', userKey, err);
      throw err;
    }
  },

  /**
   * Suspend a user account
   * @param  {Object} obj                - the params
   * @param  {String} obj.userKey        - the user key
   * @param  {String} [obj.reason]       - reason for suspension
   */
  async suspendUser({ dispatch }, { userKey, reason }) {
    try {
      await users.postSuspendUser(userKey, reason);
      dispatch('getUser', userKey);
    } catch (err) {
      console.error('ERROR: suspendUser action', userKey, err);
      throw err;
    }
  },

  /**
   * Reactivate a Suspended user account
   * @param  {Object} obj                - the params
   * @param  {String} obj.userKey        - the user key
   */
  async reactivateUser({ dispatch }, { userKey }) {
    try {
      await users.postReactivateUser(userKey);
      dispatch('getUser', userKey);
    } catch (err) {
      console.error('ERROR: reactivateUser action', userKey, err);
      throw err;
    }
  },

  /**
   * Enable MFA for a User
   * @param  {Object} obj                - the params
   * @param  {String} obj.userKey        - the user key
   */
  async enableMultifactor({ dispatch }, { userKey }) {
    await users.putUserMfa({ userKey, when: 0 });
    dispatch('getUser', userKey);
  },

  /**
   * Temporarily disable user's MFA for 24 hours
   * @param  {Object} obj                - the params
   * @param  {String} obj.userKey        - the user key
   */
  async pauseMultifactor({ dispatch }, { userKey }) {
    // Disable MFA for 24 hours (1440 minutes)
    await users.putUserMfa({ userKey, when: 1440 });
    dispatch('getUser', userKey);
  }
};

const mutations = {
  SET_USER_LIST_DATA(state, data) {
    state.userList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_USER_IN_LIST(state, { key, data }) {
    Vue.set(state.userList, key, data);
  },

  UNSET_DATA_LAST_FETCHED_AT(state) {
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
