import http from './requestType';

/**
 * Returns a list of voters, based on shortCode + search criteria
 *
 * @param  {string} shortCode     - the meeting shortcode
 * @param  {string} search        - voter name or email to search for
 * @return {Promise}              - Promise
 */
export function getMeetingsVotersList({ shortCode, search = '' }) {
  return http._get(`Meetings/${shortCode}/voters`, { search });
}
