<template>
  <date-time-pickers
    label="What is the planned send date?"
    :iso-date="sendOn"
    :show-clear-btn="true"
    time-menu-direction="top"
    :is-disabled="isDisabled"
    :timezone="meetingTimezone"
    @update="onChange('sendOn', $event)"
  />
</template>

<script>
import DateTimePickers from '@/components/DateTimePicker';

export default {
  name: 'InputSendDate',
  components: {
    DateTimePickers
  },
  props: {
    sendOn: { type: String, default: null },
    meetingTimezone: { type: String, default: null },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped></style>
