import http from './requestType';

/**
 *
 * Save additional vote to the tally table
 * @param  {String} {shortCode}       - meeting code
 * @param  {String} {questionKey}     - the advance question key
 * @param  {String} {vote}            - the root vote to tally under
 * @param  {String} {voteItem}        - the additional vote item for the vote
 * @param  {String} {createdAtName}   - the author name
 * @param  {String} {total}           - total votes
 * @param  {String} {metric}          - additional vote metric ('shares' or 'units')
 * @return {Promise} - Promise
 *
 */
export function saveMeetingAdditionalVote(params) {
  const {
    shortCode,
    questionKey,
    vote,
    voteItem,
    createdAtName,
    total,
    metric
  } = params;

  return http._post(`Meetings/${shortCode}/additional-votes`, {
    shortCode,
    questionKey,
    vote,
    voteItem,
    createdAtName,
    total,
    metric
  });
}

/**
 * Delete additional vote
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} additionalVoteKey - the additional vote key
 * @param  {string} deletedAtName - name of user deleting the vote
 * @param  {string} message - optional message explanation for the delete
 * @return {Promise} - Promise
 */
export function deleteAdditionalVote({
  shortCode,
  additionalVoteKey,
  deletedAtName,
  message
}) {
  return http._patch(`Meetings/${shortCode}/additional-votes`, {
    additionalVoteKey,
    deletedAtName,
    message
  });
}
