<template>
  <v-dialog :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title class="pb-0">
        <p class="title">Assign Shares</p>
      </v-card-title>
      <v-card-text>
        <p>Please select the Unit where Shares will be assigned.</p>
        <v-alert v-if="selected.length > 0" type="info" outline :value="true">
          <strong>Unit {{ selected[0].unit }}</strong> will be assigned shares.
          All other Units will be assigned a share value of 0.
        </v-alert>

        <v-alert
          v-if="primaryAssignedUnitsCount > 1"
          type="error"
          outline
          :value="true"
        >
          Multiple units with assigned shares have been selected. Reassigning an
          existing assignment of shares is not allowed. Please review the
          selected units before trying again.
        </v-alert>

        <v-alert
          v-if="assignedUnitsCount > 0"
          type="error"
          outline
          :value="true"
        >
          Unit(s) with shares reassigned have been selected. Please review the
          selected units before trying again.
        </v-alert>

        <v-toolbar color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search..."
            single-line
            hide-details
            clearable
            v-model="search"
          />
        </v-toolbar>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="selected.length === 0 ? defaultSelectedRows : previewRows"
          :search="search"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <tr
              :class="{ 'disabled-row': isRowDisabled(props.item) }"
              :key="props.item.id"
              :active="props.selected"
              @click="props.selected = !props.selected"
            >
              <td>
                <v-checkbox
                  :input-value="props.selected"
                  primary
                  hide-details
                />
              </td>
              <td>{{ props.item.unit }}</td>
              <td>{{ props.item.address }}</td>
              <td>{{ props.item.names }}</td>
              <td>{{ props.item.emails }}</td>
              <td>
                <span
                  v-for="(value, key) in formatCustomData(
                    props.item.customData
                  )"
                  :key="key"
                >
                  <v-chip label small>{{ key }}: {{ value }}</v-chip>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="selected.length === 0"
          @click.native="dialog.confirm = true"
          >Assign</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog.confirm" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <div class="title">
            Confirm ineligible reason
          </div>
        </v-card-title>
        <v-card-text>
          For ALL units with shares set to 0, you can provide an ineligible
          reason:
          <v-radio-group v-model="ineligibleReasonRadioGroup.selection">
            <v-radio label="Proxy Received" value="Proxy Received" />
            <v-radio label="Multiple Unit Owner" value="Multiple Unit Owner" />
            <v-radio
              label="None (No ineligible_reason will be added)"
              value="None"
            />
            <v-radio label="Other" value="Other" />
          </v-radio-group>
          <!-- Custom reason -->
          <v-text-field
            v-if="ineligibleReasonRadioGroup.selection === 'Other'"
            label="Ineligible Reason"
            v-model="ineligibleReasonRadioGroup.customReason"
            placeholder="example: 'Proxy Received.'"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.confirm = false">Back</v-btn>
          <v-btn
            class="primary"
            :disabled="!ineligibleReasonRadioGroup.selection"
            @click.native="handleAssignShares"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsAssignSharesDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    addresses: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedRows: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // default selected rows are initialized on watch
      defaultSelectedRows: [],
      // the assigned row selected by user
      selected: [],
      // preview displays share calculation in table
      previewRows: [],
      headers: [
        { text: '', value: '', sortable: false },
        { text: 'Unit', value: 'unit', align: 'left' },
        { text: 'Address', value: 'address', align: 'left', sortable: false },
        {
          text: 'Owner Name(s)',
          value: 'names',
          align: 'left',
          sortable: false
        },
        { text: 'Email(s)', value: 'emails', align: 'left', sortable: false },
        {
          text: 'Custom Data',
          value: 'customData',
          align: 'left',
          sortable: false
        }
      ],
      dialog: {
        confirm: false
      },
      ineligibleReasonRadioGroup: {
        selection: '',
        customReason: ''
      },
      search: ''
    };
  },
  watch: {
    // Watch when dialog is opened, reset defaultSelectedRows
    value(val) {
      if (val) {
        // defaults shares to 1 if they don't exist
        this.defaultSelectedRows = _.cloneDeep(this.selectedRows).map(row => {
          // Create customData object if it doesn't exist
          row.customData = row.customData || {};

          // Set default share value if undefined
          row.customData.shares =
            row.customData.shares === undefined ? 1 : row.customData.shares;
          return row;
        });
      }
    },

    // When row selected to be assigned, preview the row
    selected(assignedUnit) {
      if (assignedUnit.length > 0) {
        this.previewRows = this.processAssignedShares();
      }
    }
  },
  computed: {
    // Count of primary assigned units
    // - Row id matches the assigned_to_unit_id
    primaryAssignedUnitsCount() {
      let count = 0;

      this.selectedRows.forEach(row => {
        if (row.id === row.customData?.assigned_to_unit_id) {
          count++;
        }
      });
      return count;
    },

    // Count of re-assigned units
    // - Has assigned_to_unit_id
    // - Unit id does not match assigned_to_unit_id
    assignedUnitsCount() {
      let count = 0;

      this.selectedRows.forEach(row => {
        if (
          row.customData?.assigned_to_unit_id &&
          row.id !== row.customData?.assigned_to_unit_id
        ) {
          count++;
        }
      });
      return count;
    },

    // Detect when there is more than one address
    hasMultipleAddresses() {
      // Check if currentMeeting.addresses has multiple addresses
      if (this.addresses.length > 1) {
        return true;
      }

      // Check if there are no selected rows, return false
      if (!this.selectedRows?.length) {
        return false;
      }

      // Return true (has multiple addresses) if not all selectedRow addresses are the same
      if (
        !this.selectedRows.every(
          obj => obj.address === this.selectedRows[0].address
        )
      ) {
        return true;
      }

      // Otherwise return false
      return false;
    }
  },
  methods: {
    handleAssignShares() {
      let unitsToUpdate = this.processAssignedShares();

      // Pass the result to the parent
      this.emitUpdate(unitsToUpdate);
    },
    isRowDisabled(row) {
      // Check if a row is selected
      if (this.selected.length !== 0) {
        // If it's not the assigned row, disable the row
        return row.id !== this.selected[0].id;
      }

      // If all units are unassigned, allow assignment of any row
      if (
        this.primaryAssignedUnitsCount === 0 &&
        this.assignedUnitsCount === 0
      ) {
        return false;
      }

      // If there is more than one primary assigned row, disable all rows
      if (this.primaryAssignedUnitsCount > 1) {
        return true;
      }

      // If there are any re-assigned units selected, disable all rows
      if (this.assignedUnitsCount > 0) {
        return true;
      }

      // Exception: If there's 1 or less primary assigned units AND
      // All other units are not assigned
      // We can allow that assigned unit to be assigned more shares
      if (
        this.primaryAssignedUnitsCount <= 1 &&
        this.assignedUnitsCount === 0
      ) {
        // If it isn't the assigned row, disable the row
        return row.id !== row.customData?.assigned_to_unit_id;
      }
    },
    // Retrieve sum of all selected shares
    getSumOfShares(defaultSelectedRows) {
      // Copy to avoid mutation of original rows
      let selectedRowsCopy = _.cloneDeep(defaultSelectedRows);

      // Get sum of shares from all selected units
      // Will ignore rows with no shares
      const sumOfShares = selectedRowsCopy.reduce((accumulator, row) => {
        if (row?.customData?.shares) {
          return accumulator + Number(row.customData.shares);
        } else {
          return accumulator;
        }
      }, 0);

      return sumOfShares;
    },
    // Returns the customData object with the updated custom data values
    // Used for preview and payload
    processAssignedShares() {
      // Copy to avoid mutation of original rows
      // Default rows needed to calculate sum of shares
      let selectedRows = _.cloneDeep(this.defaultSelectedRows);

      // Assigned row value
      const {
        id: assignedRowId,
        unit: assignedRowUnit,
        address: assignedRowAddress
      } = this.selected[0];

      // Determine if there are multiple units in settings, or in selected Rows
      const assignedToUnitString = this.hasMultipleAddresses
        ? `${assignedRowUnit}@${assignedRowAddress}`
        : `${assignedRowUnit}`;

      // Update shares for units and set ineligible reason if selected
      return selectedRows.map(row => {
        // Set the assigned to unit (requires @address format to correctly identify)
        row.customData.assigned_to_unit = `${assignedToUnitString}`;

        // Set the assigned to unit id. Required for bulk undo reference. Not visible to user
        row.customData.assigned_to_unit_id = `${assignedRowId}`;

        // Set original value if it doesn't exist
        if (row.customData?.original === undefined) {
          row.customData.original = row.customData?.shares || 0;
        }

        // Set share values
        row.customData.shares =
          row.id === assignedRowId
            ? this.getSumOfShares(this.defaultSelectedRows)
            : 0;

        // If this row is not the assigned row, set the ineligible reason
        // Note that these will not be set when previewing shares
        if (row.id !== assignedRowId) {
          const { selection, customReason } = this.ineligibleReasonRadioGroup;
          if (selection && selection !== 'None') {
            row.customData.ineligible_reason =
              selection === 'Other' ? customReason : selection;
          }
        }

        return row;
      });
    },
    // Process customData field for display in table
    formatCustomData(customData) {
      // Keys to filter out from customData
      const filterKeyArray = ['assigned_to_unit_id'];

      // Convert to [key, value] pairs, filter out entries based on filterKeyArray
      // Reconvert to object using fromEntries
      const filteredObj = Object.fromEntries(
        Object.entries(customData).filter(
          ([key]) => !filterKeyArray.includes(key)
        )
      );

      return filteredObj;
    },

    // Dialog / Emit functions
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
      this.resetDialog();
    },
    emitUpdate(assignedRowsResult) {
      this.$emit('update', assignedRowsResult);
      this.emitClose();
    },
    resetDialog() {
      this.selected.splice(0);
      this.previewRows.splice(0);

      this.ineligibleReasonRadioGroup.selection = '';
      this.ineligibleReasonRadioGroup.customReason = '';
    }
  }
};
</script>

<style scoped>
.disabled-row {
  opacity: 0.3;
  pointer-events: none; /* Disable pointer events for disabled rows */
}
</style>
