<template>
  <v-stepper v-model="currentStep">
    <!-- Header -->
    <v-stepper-header>
      <v-stepper-step
        v-for="step in stepObj"
        :key="`key-${step.index}`"
        :step="step.index + 1"
        :complete="currentStep > step.index + 1"
        editable
      >
        {{ step.label }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <!-- Step -->
      <v-stepper-content step="1">
        <v-card class="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div><div class="headline">Corporation Name</div></div>
          </v-card-title>

          <dialog-corp-input
            :name="newAccount.name"
            :name-short="newAccount.nameShort"
            :customer-type="newAccount.customerType"
            :region="newAccount.region"
            :customer-types="customerTypes"
            :regions="regions"
            @update="handleUpdate"
          />

          <v-alert
            color="red accent-2"
            class="ma-0 py-2"
            dense
            type="warning"
            icon="warning"
            :value="haveDuplicateAccount"
            dismissible
            >An existing Account for {{ newAccount.nameShort }} -
            {{ newAccount.name }} was found</v-alert
          >
        </v-card>
      </v-stepper-content>

      <!-- Step -->
      <v-stepper-content step="2">
        <v-card class="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div><div class="headline">Agents</div></div>
          </v-card-title>

          <dialog-agent-input
            :agent-list="agentList"
            :accountAgents="newAccount.agents"
            :searching="searching"
            @update="handleUpdate"
            @updateAgentSearch="updateSearch"
            @toggleAgent="updateAgentStatus"
          />
        </v-card>
      </v-stepper-content>

      <!-- Step -->
      <v-stepper-content step="3">
        <v-card color="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div class="headline">Address</div>
          </v-card-title>

          <dialog-address-input
            :address="newAccount.address"
            @update="handleUpdate"
          />
        </v-card>
        <v-card color="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div class="headline">Billing Address</div>
          </v-card-title>

          <dialog-address-input
            :address="newAccount.billingAddress"
            :is-billing-address="true"
            :agents="newAccount.agents"
            @update="handleUpdate"
            @setBillingAsMailing="setBillingAsMailing"
          />
        </v-card>
      </v-stepper-content>

      <!-- Step -->
      <v-stepper-content step="4">
        <v-card color="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div class="headline">Additional Details</div>
          </v-card-title>

          <dialog-data-input :data="newAccount.data" @update="handleUpdate" />
        </v-card>
      </v-stepper-content>

      <!-- Step -->
      <v-stepper-content step="5">
        <v-card color="elevation-0">
          <v-card-title class="pl-1 pt-0">
            <div class="headline">Contacts Information</div>
          </v-card-title>

          <dialog-contact-input
            :contact="newAccount.contact"
            :billing-contact="newAccount.data.billingContact"
            :same-primary="samePrimary"
            :agents="newAccount.agents"
            @update="handleUpdate"
            @set-billing-values="setBillingValues"
          />
        </v-card>
      </v-stepper-content>
    </v-stepper-items>

    <v-card color="elevation-0" class="pl-4 pr-5">
      <v-card-actions>
        <v-btn :disabled="currentStep === 1" flat @click.native="prevStep">
          <v-icon left>chevron_left</v-icon>Back
        </v-btn>
        <v-btn
          v-if="currentStep < stepObj.length"
          :disabled="!validAgent"
          flat
          color="primary"
          @click.native="nextStep"
          ><v-icon left>chevron_right</v-icon>Next</v-btn
        >
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="isDisabled"
          :loading="loading"
          @click.native="emitAddAccount"
        >
          <v-icon>add</v-icon>Add Account
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import DialogCorpInput from '@/components/input/InputCorp';
import DialogAddressInput from '@/components/input/InputAddress';
import DialogContactInput from '@/components/input/InputContact';
import DialogDataInput from '@/components/input/InputCorpMeta';
import DialogAgentInput from '@/components/input/InputAgents';

export default {
  name: 'AddAccountDialog',
  components: {
    DialogCorpInput,
    DialogAddressInput,
    DialogContactInput,
    DialogDataInput,
    DialogAgentInput
  },
  props: {
    customerTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    regions: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      currentStep: 1,
      samePrimary: true,
      stepObj: [
        { index: 0, label: 'Corporation' },
        { index: 1, label: 'Agents' },
        { index: 2, label: 'Address' },
        { index: 3, label: 'Details' },
        { index: 4, label: 'Contact' }
      ],
      newAccount: {
        name: '',
        nameShort: '',
        address: {
          address: 'TBD',
          city: 'Toronto',
          prov: 'ON',
          postal: 'TBD',
          country: 'Canada'
        },
        billingAddress: {
          address: 'TBD',
          city: 'Toronto',
          prov: 'ON',
          postal: 'TBD',
          country: 'Canada'
        },
        contact: {
          email: '',
          name: '',
          preferredName: '',
          phone: ''
        },
        customerType: '',
        region: '',
        agents: [],
        data: {
          numberOfUnits: 0,
          nickname: 'TBD',
          neighbourhood: 'TBD',
          url: 'TBD',
          notes: [],
          fiscalYearEnd: 'TBD',
          signature: 'TBD',
          timezone: 'America/Toronto',
          billingContact: {
            email: '',
            name: '',
            preferredName: '',
            phone: '',
            preferredMethod: 'email',
            otherMethod: '',
            instructions: ''
          }
        }
      },
      hasNoAgent: false,
      searching: false,
      agentSearch: ''
    };
  },
  computed: {
    ...mapGetters('accounts', [
      'accountListDataNameShort',
      'accountListDataName'
    ]),
    ...mapGetters('agents', ['agentList']),
    isDisabled() {
      if (
        !_.isEmpty(this.newAccount.name) &&
        !_.isEmpty(this.newAccount.nameShort) &&
        !_.isEmpty(this.newAccount.customerType)
      ) {
        return false;
      } else {
        return true;
      }
    },
    haveDuplicateAccount() {
      return (
        this.accountListDataNameShort.includes(this.newAccount.nameShort) ||
        this.accountListDataName.includes(this.newAccount.name)
      );
    },
    validAgent() {
      // Forbid user from clicking Next if they haven't entered an Agent,
      // or if they haven't indicated that Account has no Agent(s)
      if (
        this.currentStep === 2 &&
        !this.hasNoAgent &&
        this.newAccount.agents.length === 0
      ) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('agents', ['getAgentListData', 'setPagination']),
    async init() {
      await this.setPagination({
        descending: true,
        sortBy: 'createdAt',
        search: '',
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        rowsPerPageItems: [25, 50, 100, 200]
      });
    },
    handleUpdate(obj) {
      _.set(this.newAccount, obj.key, obj.value);
    },
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
    emitAddAccount() {
      // if true it fills billing contact data with the contact data
      if (this.samePrimary) {
        const contact = this.newAccount.contact;
        this.newAccount.data.billingContact = {
          ...contact,
          preferredMethod: 'email',
          otherMethod: '',
          instructions: this.newAccount.data.billingContact.intructions
        };
      }

      // (Temp) Criteria for enabling vbm_trial
      // - All florida client
      // - All associations across all regions
      // - Ontario condominiums
      if (
        this.newAccount.region === 'FL' ||
        this.newAccount.customerType === 'assoc' ||
        (this.newAccount.region === 'ON' &&
          this.newAccount.customerType === 'condo-hoa')
      ) {
        this.newAccount.data.vbmTrial = {
          value: true,
          start_date: moment().add(-1, 'days')
        };
      }

      this.$emit('addAccount', this.newAccount);
    },
    setBillingValues(value) {
      this.samePrimary = value;
    },
    setBillingAsMailing() {
      this.newAccount.billingAddress = _.cloneDeep(this.newAccount.address);
    },
    updateSearch: _.debounce(async function(value) {
      this.searching = true;
      await this.getAgentListData(value || '');
      this.searching = false;
    }, 350),
    updateAgentStatus(value) {
      this.hasNoAgent = value;
      if (value === true) {
        this.newAccount.agents = [];
      }
    }
  }
};
</script>

<style scoped></style>
