<template>
  <v-dialog
    persistent
    :value="isOpen"
    :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        <div class="title red--text text--darken-1">
          <slot name="title" />
        </div>
      </v-card-title>
      <v-card-text>
        <v-alert
          outline
          color="error"
          :icon="icon"
          :value="true">
          <slot name="description" />
        </v-alert>                
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click.native="close"
          flat>Close</v-btn>
        <v-btn
          class="error"
          @click.native="deleteResource"
          flat>{{action}} {{ resourceName }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: 'DeleteResourceDialog',
  props: {
    resourceName: {
      type: String,
      required: true
    },
    resourceKey: {
      type: null,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    },
    showIcon: {
      type: Boolean,
      default() {
        return false;
      }
    },
    action: {
      type: String,
      default() {
        return 'Delete';
      }
    }
  },
  computed: {
    icon() {
      return this.showIcon ? 'warning' : null;
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    deleteResource() {
      this.$emit('delete', this.resourceKey);
      this.close();
    }
  }
};
</script>


<style scoped>
</style>
