<template>
  <v-dialog
    max-width="290px"
    :value="value"
    @input="emitInput">
    <v-card>
      <v-card-title class="mb-0 pb-0">
        <span class="title">You're making a copy of:</span>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-list two-line>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">short_text</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ notice.purpose }}</v-list-tile-title>
              <v-list-tile-sub-title>Purpose</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">subject</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ notice.subject }}</v-list-tile-title>
              <v-list-tile-sub-title>Subject</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          @click.stop="emitClose">Go back</v-btn>
        <v-btn
          class="primary"
          @click.native="emitCopy">Copy</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CopyNoticeDialog',
  props: {
    notice: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitCopy() {
      this.$emit('copy');
    }
  }
};
</script>

<style scoped>

</style>
