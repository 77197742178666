<template>
  <v-card>
    <v-card-title>
      <span class="title capitalize">Add Tax</span>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 class="ma-2">
          <v-select
            :items="itemOptions"
            v-model="e1"
            label="Quick Select"
            item-text="label"
            item-value="key"
            persistent-hint
            @input="onQuickSelect"
          />
        </v-flex>
      </v-layout>

      <v-layout row nowrap v-show="e1">
        <v-flex xs12>
          <v-layout row nowrap>
            <v-flex xs9 class="ma-2">
              <v-text-field
                name="input-description"
                label="Description"
                id="input-description"
                v-model="state.description"
              />
            </v-flex>

            <v-flex xs3 class="ma-2">
              <v-text-field
                type="number"
                name="input-value"
                label="Tax (%)"
                id="input-value"
                min="0"
                max="100"
                v-model="state.value"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row v-if="error">
        <v-flex xs12 class="ma-2">
          <v-icon>warning</v-icon> Error adding tax, please fix and try again.
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
      <v-btn flat color="primary" @click.native="handleAddItem">Add Tax</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AdminInvoiceViewTaxesAdd',
  props: {
    invoiceKey: {
      type: String,
      required: true
    },
    itemOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      e1: null,
      error: null,
      state: {
        category: '',
        description: '',
        value: 0
      }
    };
  },
  mounted() {
    this.state = {};
  },
  methods: {
    ...mapActions('invoices', ['addItemToInvoice']),
    async handleAddItem() {
      try {
        this.error = false;
        const payload = { ...this.state, value: this.state.value / 100 };
        await this.addItemToInvoice({
          key: this.invoiceKey,
          type: 'tax',
          payload
        });

        this.resetState();
        this.$emit('close');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.error = true;
        throw err;
      }
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    resetState() {
      this.state = { category: '', description: '', value: 0 };
      this.e1 = null;
    },
    onQuickSelect(e) {
      const item = this.itemOptions.filter(k => k.key === e)[0];
      this.state = {
        category: item.category,
        value: item.value,
        description: item.description,
        quickSelect: e
      };
    }
  }
};
</script>

<style scoped></style>
