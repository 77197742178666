<template>
  <router-view
    :current-meeting="currentMeeting"
    :conversation-list="conversationsList"
    :conversations="conversations"
    :conversation-template-list="this.conversationUniqueTemplateListData"
    :conversation-group-template-list="this.conversationTemplateListData"
    :conversation-group-list="this.groupsForScheduler"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'MeetingConversationsMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/conversations', [
      'conversations',
      'conversationsList'
    ]),
    ...mapGetters('meetings/conversationTemplates', [
      'conversationUniqueTemplateListData',
      'conversationTemplateListData',
      'groupsForScheduler'
    ]),
    ...mapGetters('meetings/conversations', ['conversationMergeFields'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/conversations', [
      'fetchConversations',
      'getConversationFields'
    ]),
    ...mapActions('meetings/conversationTemplates', [
      'getConversationTemplates',
      'getMeetingConversationGroups'
    ]),
    async init() {
      try {
        await this.fetchConversations(this.shortCode);
        await this.getConversationTemplates();
        await this.getMeetingConversationGroups();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }

      if (this.isAdmin) {
        // Check if conversationMergeFields is populated before requesting
        if (_.isEmpty(this.conversationMergeFields)) {
          await this.getConversationFields();
        }
      }
    }
  }
};
</script>

<style scoped></style>
