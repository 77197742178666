'use strict';

/**
 * @fileoverview Vuex module for a meeting's files
 */

import _ from 'lodash';
import Vue from 'vue';
import {
  getMeetingDetails,
  getMeetingStats,
  patchMeeting
} from '@/api/meetings';

const state = {
  meetingList: {},
  stats: {},
  dataLastFetchedAt: null
};

const getters = {
  stats: state => state.stats,
  meetings: state => Object.values(state.meetingList),
  meetingByShortCode: state => shortCode => state.meetingList[shortCode]
};

const actions = {
  /**
   * Get the latest data for this meeting
   * @param  {String} {shortCode} Meeting shortcode
   */
  async fetchMeeting({ commit }, { shortCode }) {
    try {
      const { data } = await getMeetingDetails(shortCode);

      commit('SET_MEETING_IN_LIST', { shortCode, data });
    } catch (err) {
      console.error('Error: fetchMeeting action', err);
      throw err;
    }
  },

  /**
   * Get meeting stats
   * @param  {String} {shortCode}  meeting code
   */
  async fetchMeetingStats({ commit }, { shortCode }) {
    try {
      const { data } = await getMeetingStats(shortCode);
      return commit('SET_STATS', { data });
    } catch (err) {
      console.error('Error: fetchMeetingStats action', err);
      throw err;
    }
  },

  /**
   * Update a meeting
   * @param  {String} {shortCode} Meeting shortcode
   * @param  {Object} {updates}   Meeting properties to be updated
   */
  async updateMeeting({ dispatch, rootState, commit }, { shortCode, updates }) {
    try {
      await patchMeeting(shortCode, updates);
      dispatch('fetchMeeting', { shortCode });
      return commit('SET_MEETING_LIST_NAME_ONLY', {
        rootState,
        shortCode,
        name: updates.name
      });
    } catch (err) {
      console.error('Error: updateMeeting action', err);
      throw err;
    }
  },

  /**
   * set meeting attendance snapshot
   * @param  {String} {shortCode} Meeting shortcode
   * @param  {Object} {updates}   Meeting properties to be updated
   */
  async setAttendanceSnapshot({ dispatch }, { shortCode, updates }) {
    try {
      await patchMeeting(shortCode, updates);
      dispatch('meetings/getMeeting', { shortCode }, { root: true });
    } catch (err) {
      console.error('Error: attendance snapshot action', err);
      throw err;
    }
  }
};

const mutations = {
  // Normalize storage of data as best practice. See...
  // https://gist.github.com/brianboyko/91fdfb492071e743e389d84eee002342#store
  SET_MEETING_LIST_DATA(state, data) {
    state.meetingList = _.keyBy(data, 'shortCode');
  },

  // Adds or replaces an existing meeting object into the current list
  // of meetings
  SET_MEETING_IN_LIST(state, { shortCode, data }) {
    Vue.set(state.meetingList, shortCode, data);
  },

  SET_STATS(state, { data }) {
    Vue.set(state, 'stats', data);
    state.dataLastFetchedAt = new Date();
  },

  // Reset the state
  CLEAR_STATE(state) {
    Vue.set(state, 'stats', {});
    state.dataLastFetchedAt = null;
  },

  SET_MEETING_LIST_NAME_ONLY(state, { rootState, shortCode, name }) {
    // Only update name to existing meeting list
    // Prevents overwrite of meeting business
    if (
      _.has(rootState.meetings.meetingList, shortCode) &&
      _.has(state.meetingList, shortCode)
    ) {
      Vue.set(state.meetingList[shortCode], 'name', name);
      Vue.set(rootState.meetings.meetingList[shortCode], 'name', name); // update global meeting list state
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
