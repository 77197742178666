'use strict';

/**
 * @fileoverview Vuex module for notices
 */

import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { getNoticeList } from '@/api/notices';
import parameterizeArray from '@/lib/parameterize-array.js';

const state = {
  noticeList: {},
  dataLastFetchedAt: null
};

const getters = {
  noticeListData: state => Object.values(state.noticeList),
  noticeByKey: state => key => state.noticeList[key],

  /**
   * Group notices by week sorted by day
   */
  noticeWeekGroups: state => {
    const noticeList = Object.values(state.noticeList);
    const sorted = noticeList.sort((a, b) => {
      return new Date(a['sortDate']) - new Date(b['sortDate']);
    });
    const sortedGroups = _.groupBy(sorted, function(obj) {
      return moment(obj.sortDate)
        .startOf('day')
        .format();
    });
    return sortedGroups;
  }
};

const actions = {
  async getNotices({ commit }, { from, to, statuses, includeUnscheduled }) {
    try {
      const sts = parameterizeArray('statuses', statuses);
      const res = await getNoticeList(from, to, sts, includeUnscheduled);
      if (res.status !== 200) {
        throw new Error(res.text);
      }
      return commit('SET_NOTICE_LIST_DATA', res.data);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_NOTICE_LIST_DATA(state, data) {
    state.noticeList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_NOTICE_IN_LIST(state, { key, data }) {
    Vue.set(state.noticeList, key, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
