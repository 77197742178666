<template>
  <v-layout class="ma-0 pa-0">
    <v-flex xs4>
      <v-tooltip top :disabled="checkWebcastTime">
        <v-text-field
          slot="activator"
          class="pr-1"
          :label="label ? label : ''"
          v-model="dateObj.date"
          :disabled="isDisabled"
          :box="box"
          :append-icon="!checkWebcastTime ? 'warning' : ''"
          @input="update('date')"
        />
        <span>
          <b
            >An invalid Zoom date/time has been detected (
            {{ webcastDateObj.date }} at {{ webcastDateObj.time }} +
            {{ timezone }} ). Please contact a Support team member for
            assistance.</b
          >
        </span>
      </v-tooltip>
    </v-flex>
    <v-flex xs4>
      <v-text-field
        class="pl-1 pr-1"
        label="Time"
        type="time"
        v-model="dateObj.time"
        :disabled="isDisabled"
        :box="box"
        :append-icon="!checkWebcastTime ? 'warning' : ''"
        @input="updateTime"
      />
    </v-flex>
    <!-- TODO: Get this working with custom timezones -->
    <v-flex xs4 class="pl-1">
      <v-text-field
        class="pl-1"
        :value="meetingTimezone"
        :disabled="isDisabled"
        label="Timezone"
        box
      />
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';

export default {
  name: 'DateTimePickerTimezone',
  props: {
    isoDate: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    box: {
      type: Boolean,
      default: false
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    timezone: {
      type: String,
      default() {
        return 'America/Toronto';
      }
    },
    webcastDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateObj: {
        date: '',
        time: '',
        timezone: ''
      },
      webcastDateObj: {
        date: '',
        time: ''
      },
      menu: false
    };
  },
  created() {
    this.setDate(this.isoDate, this.webcastDate);
  },
  computed: {
    combinedDate() {
      return moment
        .utc(`${this.dateObj.date} ${this.dateObj.time}`)
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    // The time at the local based on the specified timezone
    // or the default timezone of America/Toronto
    localeDateTime() {
      return moment.utc(this.isoDate).tz(this.timezone);
    },
    localeDateTimeFormatted() {
      return this.localeDateTime.format('YYYY-MM-DDTHH:mm:ss');
    },
    checkWebcastTime() {
      const webcastTime = moment.utc(this.webcastDate).tz(this.timezone);
      const meetingTime = moment.utc(this.isoDate).tz(this.meetingTimezone);

      // allow the delay between sending and saving data to zoom
      const isNotSame =
        webcastTime.diff(meetingTime, 'hours', true) > 1 ||
        webcastTime.diff(meetingTime, 'hours', true) < -1;

      // if zoom data time is not the same as the meeting time
      // return false if the zoom data time is before the meeting data time
      if (isNotSame) {
        return !webcastTime.isBefore(meetingTime);
      }

      return true;
    }
  },
  methods: {
    setDate(isoDate, webcastDate) {
      if (isoDate !== null) {
        this.dateObj.date = moment
          .utc(this.isoDate)
          .tz(this.meetingTimezone)
          .format('YYYY-MM-DD');
        this.dateObj.time = moment
          .utc(this.isoDate)
          .tz(this.meetingTimezone)
          .format('HH:mm');
        this.dateObj.timezone = this.timezone;
      }

      if (webcastDate !== null) {
        this.webcastDateObj.date = moment
          .utc(this.webcastDate)
          .tz(this.timezone)
          .format('YYYY-MM-DD');
        this.webcastDateObj.time = moment
          .utc(this.webcastDate)
          .tz(this.timezone)
          .format('hh:mm A');
      }
    },
    emitDatetime() {
      this.dateObj.timezone =
        this.meetingTimezone && this.meetingTimezone !== 'America/Toronto'
          ? this.meetingTimezone
          : 'America/New_York';
      const payload = {
        date: this.combinedDate,
        timezone: this.dateObj.timezone
      };
      this.$emit('update', payload);
    },
    updateDate() {
      this.emitDatetime();
    },
    updateTime() {
      this.emitDatetime();
    },
    updateTimezone: _.debounce(function() {
      this.emitDatetime();
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
