<template>
  <router-view
    :current-meeting="currentMeeting"
    :notice-list="meetingNoticeList"
    :notice-data="meetingNoticeListDataSorted"
    :notice-group-list="this.groupsForScheduler"
    :notice-template-list="this.templatesForScheduler"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'MeetingNoticesMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/notices', [
      'meetingNoticeList',
      'meetingNoticeListDataSorted',
      'groupsForScheduler',
      'templatesForScheduler',
      'noticeMergeFields'
    ]),
    currentNoticeGroupTemplateList() {
      // List to populate the add notice dropdown
      return this.templatesForScheduler;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/notices', [
      'getMeetingNotices',
      'getMeetingNoticeGroups',
      'getMeetingNoticeTemplates',
      'getNoticeFields'
    ]),
    async init() {
      try {
        await this.getMeetingNotices({
          shortCode: this.shortCode,
          includeStats: true
        });

        if (this.isAdmin) {
          await this.getMeetingNoticeGroups();
          await this.getMeetingNoticeTemplates();

          // Check if noticeMergeFields is populated before requesting
          if (_.isEmpty(this.noticeMergeFields)) {
            await this.getNoticeFields();
          }
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
