import http from './requestType';

/**
 * Get all the notes belonging to a meeting
 * @param {object} shortCode - shortCode to pull notes from
 * @return {Promise} - Promise
 */
export function getNotes(shortCode, noteType) {
  if (noteType) {
    return http._get(`Meetings/${shortCode}/notes?noteType=${noteType}`);
  }

  return http._get(`Meetings/${shortCode}/notes`);
}

/**
 * List all agents and their associated notes for the current meeting
 * @param {string} shortCode   - shortCode to pull agent notes from
 * @param {string} partnerType - the agent partner type
 * @returns {Promise}          - Promise
 */
export function getAgentNotes(shortCode, partnerType) {
  if (partnerType) {
    return http._get(`Meetings/${shortCode}/agents?partnerType=${partnerType}`);
  }
  return http._get(`Meetings/${shortCode}/agents`);
}

/**
 * Create a new note that is visible on the dashboard notes section.
 * @param {object} payload - note object
 * @return {Promise} - Promise
 */
export function postNote(shortCode, payload) {
  return http._post(`Meetings/${shortCode}/notes`, payload);
}

/**
 * Delete a note
 * @param {object} noteKey - note key of the note to delete
 * @return {Promise} - Promise
 */
export function deleteNote(shortCode, noteKey) {
  return http._delete(`Meetings/${shortCode}/notes/${noteKey}`);
}

/**
 * Mark a note as read
 * @param {object} noteKey - note key of the note to be read
 * @return {Promise} - Promise
 */
export function markNote(shortCode, noteKey) {
  return http._post(`Meetings/${shortCode}/notes/${noteKey}/read`);
}

/**
 * Mark all the moderator notes as read belonging to a meeting
 * @param {object} shortCode - shortCode to pull notes from
 * @return {Promise} - Promise
 */
export function markAllNotes(shortCode, payload) {
  return http._post(`Meetings/${shortCode}/notes/read-multiple`, payload);
}

/**
 * Update meeting note's type
 * @param {object} params
 * @param {string} params.shortCode     - meeting shortcode
 * @param {string} params.noteKey       - note key to be updated
 * @param {object} params.payload       - note payload
 * @param {string} params.payload.type  - new note type
 * @return {Promise} - Promise
 */
export function putNote({ shortCode, noteKey, payload }) {
  return http._put(`Meetings/${shortCode}/notes/${noteKey}`, payload);
}
