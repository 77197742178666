<template>
  <div>
    <v-toolbar dark color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        :value="searchText"
        @input="setText"
      />
      <v-spacer />
      <v-select
        box
        solo
        light
        single-line
        :items="statusFilterOptions"
        item-text="text"
        item-value="value"
        :value="statusFilter"
        @input="updateFilter"
        label="Status"
        hide-details
        dense
      />
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="invoiceListData"
      :pagination.sync="pagination"
      :total-items="pagination.totalItems"
      :rows-per-page-items="pagination.rowsPerPageItems"
      :loading="loading"
      class="elevation-1"
    >
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <tr class="table-item">
          <td class="nowrap">
            {{ props.item.invoiceDate | dateFormatReadable }}
          </td>
          <td class="uppercase">
            <a
              v-if="isChargebeeInvoice(props.item)"
              :href="getChargebeeLink(props.item)"
              target="_blank"
              ><b>{{ props.item.invoiceNumber }}</b></a
            >

            <router-link
              v-else
              :to="{
                name: 'adminInvoice',
                params: { invoiceKey: props.item.key }
              }"
              target="_blank"
              ><b>{{ props.item.invoiceNumber }}</b></router-link
            >

            <div class="caption italics nowrap">
              <span v-if="props.item.meetingCode">
                MTG: {{ props.item.meetingName }}

                <router-link
                  v-if="props.item.meetingCode"
                  :to="{
                    name: 'meetingOverview',
                    params: { shortcode: props.item.meetingCode }
                  }"
                  target="_blank"
                  class="nowrap"
                >
                  <v-icon small>open_in_new</v-icon>
                </router-link>
              </span>
              <span v-else> SUB: {{ props.item.accountNameShort }} </span>
            </div>
          </td>
          <td class="text-xs-right">
            <span :class="getOwingColor(props.item.amountOwing)">{{
              props.item.amountOwing | formatCurrencyValue
            }}</span>
          </td>
          <td class="text-xs-right">
            {{ props.item.totalPrice | formatCurrencyValue }}
          </td>
          <td>{{ props.item.currency }}</td>
          <td>
            <v-chip
              small
              label
              disabled
              :color="getStatusChipColor(props.item)"
              text-color="white"
              class="capitalize"
            >
              {{ getStatus(props.item) }}
            </v-chip>
          </td>
          <td class="nowrap">{{ props.item.sentAt | dateFormatReadable }}</td>
          <td v-if="!options.hideViewedColumn">
            {{ props.item.hasSentViews ? 'Yes' : '' }}
          </td>
          <td>
            <v-tooltip bottom>
              <span slot="activator" class="truncate">{{
                props.item.additionalData.privateNotes
              }}</span>
              <span>{{ props.item.additionalData.privateNotes }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'InvoiceListTable',
  props: {
    invoiceListData: {
      type: Array,
      required: true
    },
    accountKey: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default() {
        return {
          // If the shortcode column should be hidden
          hideMeetingColumn: false,
          // If the shortcode column should be hidden
          hideViewedColumn: false
        };
      }
    }
  },
  filters: {
    formatCurrencyValue,
    dateFormatReadable
  },
  data() {
    return {
      loading: true,
      statusFilterOptions: [
        { text: 'All', value: 'all' },
        { text: 'Draft (GQ)', value: 'draft' },
        { text: 'Review (GQ)', value: 'review' },
        { text: 'Sent/Unpaid (GQ)', value: 'unpaid' },
        { text: 'Paid (GQ / Chargebee)', value: 'paid' },
        { text: 'Posted (Chargebee)', value: 'posted' },
        { text: 'Payment Due (Chargebee)', value: 'due' },
        { text: 'Voided (Chargebee)', value: 'voided' }
      ],
      headers: [
        { text: 'Created', value: 'invoiceDate' },
        { text: 'Invoice', value: 'invoiceNumber' },
        { text: 'Due', value: 'amountOwing', align: 'right' },
        { text: 'Price', value: 'totalPrice', align: 'right' },
        { text: 'Currency', value: 'currency', sortable: false },
        { text: 'Status', value: 'status', sortable: false, align: 'center' },
        { text: 'Sent', value: 'sentAt' },
        { text: 'Viewed', value: 'hasSentViews', sortable: false },
        {
          text: 'Admin Notes',
          value: 'additionalData.privateNotes',
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters('invoices', ['searchText', 'statusFilter', 'pagination']),
    pagination: {
      get: function() {
        return this.$store.getters['invoices/pagination'];
      },
      set: function(value) {
        this.setPagination(value);
      }
    }
  },
  watch: {
    searchText: {
      handler() {
        this.fetchInvoices();
      }
    },
    statusFilter: {
      handler() {
        this.fetchInvoices();
      }
    },
    pagination: {
      handler() {
        this.fetchInvoices();
      }
    }
  },
  created() {
    this.updateHeaders();
    this.setDefaultPaginationValues();
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions('invoices', [
      'getInvoices',
      'setSearchText',
      'setStatusFilter',
      'setPagination',
      'resetState'
    ]),
    async fetchInvoices() {
      try {
        this.loading = true;
        await this.getInvoices({ accountKey: this.accountKey });
      } catch (err) {
        throw err;
      } finally {
        this.loading = false;
      }
    },
    setText: _.debounce(function(value) {
      this.setSearchText(value);
    }, 500),
    updateFilter(filter) {
      this.setStatusFilter(filter);
    },
    // If certain columns are to be hidden, we need to delete the
    // record from the headers array
    updateHeaders() {
      if (this.options.hideMeetingColumn) {
        this.headers = this.headers.filter(i => i.value !== 'meetingCode');
      }
      if (this.options.hideViewedColumn) {
        this.headers = this.headers.filter(i => i.value !== 'hasSentViews');
      }
    },
    getStatusChipColor(item) {
      const status = this.getStatus(item);
      if (status === 'paid') {
        return 'green';
      } else if (status === 'review') {
        return 'teal';
      } else if (status === 'sent' || status === 'posted') {
        return 'primary';
      } else if (status === 'payment due') {
        return 'red';
      } else {
        return 'secondary';
      }
    },
    getStatus(item) {
      if (this.isChargebeeInvoice(item)) {
        const status = item.status;
        return status.replace('_', ' ');
      }

      const isSent = item.status === 'sent';
      const isPaid = isSent && item.amountOwing <= 0;
      if (isPaid) {
        return 'paid';
      } else if (isSent) {
        return 'sent';
      } else if (item.status === 'review') {
        return 'review';
      } else {
        return 'draft';
      }
    },
    getOwingColor(amount) {
      return amount > 0 ? 'owing' : 'not-owing';
    },
    setDefaultPaginationValues() {
      this.setPagination({
        sortBy: 'sentAt',
        descending: true,
        rowsPerPage: 25,
        page: 1,
        totalItems: 0,
        rowsPerPageItems: [25, 50, 100, 200]
      });
    },
    isChargebeeInvoice(invoice) {
      return (
        invoice.additionalData?.chargebee === true ||
        invoice.additionalData?.chargebee === 'true'
      );
    },
    getChargebeeLink(invoice) {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/invoices'
          : 'https://getquorum-test.chargebee.com/d/invoices';

      const url = `${baseurl}/${invoice.invoiceNumber}`;

      return url;
    }
  }
};
</script>

<style scoped>
.owing {
  color: red;
}

.not-owing {
  color: green;
}

.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.nowrap {
  white-space: nowrap;
}

.table-item {
  height: 60px;
}

.italics {
  font-style: italic;
  opacity: 0.75;
}
</style>
