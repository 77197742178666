<template>
  <div class="text-xs-center">
    <v-dialog :value="dialog" :width="width" @input="handleClose">
      <v-card :class="{ 'round-card': roundDialog }">
        <v-card-title class="headline lighten-2 font-weight-bold" primary-title>
          {{ title }}
        </v-card-title>

        <v-card-text v-html="text" />

        <v-divider></v-divider>

        <v-card-actions>
          <!-- If close text is provided, it will hide other buttons -->
          <v-layout justify-center v-if="closeText">
            <v-btn class="primary text-xs-center" @click="handleClose">
              {{ closeText }}
            </v-btn>
          </v-layout>
          <v-layout justify-end v-else>
            <v-btn
              v-if="additionalText"
              outline
              color="primary"
              @click="handleExtraAction"
            >
              {{ additionalText }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="showDeclineBtn" flat text @click="handleCancel">
              {{ declineText }}
            </v-btn>
            <v-btn color="primary" :loading="loading" @click="handleConfirm">
              {{ confirmText }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    width: {
      type: String,
      default: '500'
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    declineText: {
      type: String,
      default: 'Cancel'
    },
    closeText: {
      type: String,
      default: ''
    },
    additionalText: {
      type: String,
      default: null
    },
    roundDialog: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    showDeclineBtn: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm', true);
    },
    handleCancel() {
      this.$emit('confirm', false);
    },
    handleClose() {
      this.$emit('close');
    },
    handleExtraAction() {
      this.$emit('extra');
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}
</style>
