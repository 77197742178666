'use strict';

/**
 * @fileoverview Vuex module for meeting business
 */

import _ from 'lodash';
import makeNewBusinessObject from '@/lib/make-business-object';

import {
  getMeetingBusinessList,
  postMeetingBusinessOrder,
  postMeetingBusinessStatus,
  postMeetingBusiness,
  deleteMeetingBusiness,
  putMeetingBusiness,
  getMeetingBusinessRestrictions,
  postMeetingBusinessRestriction,
  deleteMeetingBusinessRestriction
} from '@/api/meetings-business';

const state = {
  meetingBusinessList: {},
  meetingBusinessRestrictions: [],
  dataLastFetchedAt: null
};

const getters = {
  meetingBusinessList: state => state.meetingBusinessList,
  meetingBusinessById: state => id => state.meetingBusinessList[id],

  // Get the meeting business as array sorted by order ascending
  meetingBusinessData: state => {
    return Object.values(state.meetingBusinessList).sort((a, b) => {
      return a.order - b.order;
    });
  },
  meetingBusinessRestrictions: state => state.meetingBusinessRestrictions
};

const actions = {
  /**
   * List the business for a given meeting
   *
   * @param  {Object} {shortCode} Params containing shortcode of the meeting
   */
  async getMeetingBusiness({ commit }, { shortCode }) {
    try {
      const res = await getMeetingBusinessList(shortCode);
      const data = res.data.sort((a, b) => a.order - b.order);
      commit('SET_MEETING_BUSINESS_DATA', data);
      return res;
    } catch (err) {
      console.error('Error: getMeetingBusiness action', err);
      throw err;
    }
  },

  /**
   * Update the ordering for a meeting's business
   */
  async changeMeetingBusinessOrder({ dispatch }, { shortCode, orderArray }) {
    try {
      await postMeetingBusinessOrder(shortCode, orderArray);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: getMeetingBusiness action', err);
      throw err;
    }
  },

  /**
   * Update the status of a business
   */
  async changeMeetingBusinessStatus(
    { dispatch },
    { shortCode, id, statusObj }
  ) {
    try {
      await postMeetingBusinessStatus(shortCode, id, statusObj);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: changeBusinessStatus action', err);
      throw err;
    }
  },

  /**
   * Create a new business
   */
  async createMeetingBusiness(
    { dispatch },
    { shortCode, newBusiness, order, openBallot = false, isOntario = false }
  ) {
    try {
      const fullBusinessObj = await makeNewBusinessObject(
        newBusiness,
        order,
        openBallot,
        isOntario
      );

      await postMeetingBusiness(shortCode, fullBusinessObj);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: createBusiness action', err);
      throw err;
    }
  },

  /**
   * Update a meeting's business
   */
  async updateMeetingBusiness(
    { dispatch },
    { shortCode, questionId, business }
  ) {
    try {
      await putMeetingBusiness(shortCode, questionId, business);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: updateMeetingBusiness action', err);
      throw err;
    }
  },

  /**
   * Deletes a meeting's business
   */
  async deleteMeetingBusiness({ dispatch }, { shortCode, questionId }) {
    try {
      await deleteMeetingBusiness(shortCode, questionId);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: deleteBusiness action', err);
      throw err;
    }
  },

  /**
   * Create a new restriction for a business matter
   */
  async createMeetingBusinessRestriction(
    { dispatch },
    { shortCode, questionId, restriction = {} }
  ) {
    try {
      await postMeetingBusinessRestriction(shortCode, questionId, restriction);
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: createBusinessRestriction action', err);
      throw err;
    }
  },

  /**
   * Delete an existing business restriction
   */
  async deleteMeetingBusinessRestriction(
    { dispatch },
    { shortCode, questionId, customField, fieldValue }
  ) {
    try {
      await deleteMeetingBusinessRestriction(
        shortCode,
        questionId,
        customField,
        fieldValue
      );
      return dispatch('getMeetingBusiness', { shortCode });
    } catch (err) {
      console.error('Error: deleteBusinessRestriction action', err);
      throw err;
    }
  },

  /**
   * List the custom restrictions for a business matter
   */
  async getMeetingBusinessRestrictions({ commit }, { shortCode, questionId }) {
    try {
      const res = await getMeetingBusinessRestrictions(shortCode, questionId);
      const data = res.data;
      commit('SET_MEETING_RESTRICTIONS', data);
    } catch (err) {
      console.error('Error: getBusinessRestrictions action', err);
      throw err;
    }
  }
};

const mutations = {
  // Add business array to a meeting already in store
  SET_MEETING_BUSINESS_DATA(state, data) {
    state.meetingBusinessList = _.keyBy(data, 'id');
    state.dataLastFetchedAt = new Date();
  },

  SET_MEETING_RESTRICTIONS(state, data) {
    state.meetingBusinessRestrictions = data;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.meetingBusinessList = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
