/**
 * @fileoverview Vuex module for dashboard proxy submissions
 */

import { submitMeetingProxy } from '@/api/meetings-proxies';
import { isQuestionEligible } from '@/lib/is-question-eligible';

const state = {
  meetingProxyQuestions: null,
  proxyHolder: '',
  writeInProxyHolder: '',

  fallbackProxyHolder: '',
  writeInFallbackProxyHolder: '',

  selectedVoter: null,
  defaultHolders: [],
  attachment: {},
  formOptions: {
    onBill106: 'box-3'
  },
  validationErrorMessages: [],
  liveVoteExists: false
};

const getters = {};

const actions = {
  /**
   * Add a new proxy for a particular unit via dashboard
   *
   * @param  {String} {shortCode} the meeting code
   * @param  {Integer} {proxy}  the proxy payload
   */
  async addMeetingProxy({ dispatch, rootState }, { shortCode, proxy }) {
    let res = await submitMeetingProxy({ shortCode, proxy });

    const {
      BOXES,
      SOURCES,
      STATUSES,
      filters: {
        statuses: filterStatuses,
        sources: filterSources,
        boxes: filterBoxes
      }
    } = rootState.meetings.proxies;

    const includeStatuses =
      filterStatuses.length === 0 ? [...STATUSES] : [...filterStatuses];
    const includeSources =
      filterSources.length === 0 ? [...SOURCES] : [...filterSources];
    const includeBoxes =
      filterBoxes.length === 0 ? [...BOXES] : [...filterBoxes];

    dispatch('meetings/units/getMeetingUnits', { shortCode }, { root: true });
    dispatch(
      'meetings/proxies/getMeetingProxyList',
      { shortCode, includeStatuses, includeSources, includeBoxes },
      { root: true }
    );

    return res;
  },

  /**
   * Initialize meeting proxy questions for dashboard submission
   * This object is used for tracking changes and is part of the paylod
   *
   * @param  {Object} {meetingBusiness} all meeting business
   * @param  {Object} {currentUnit} currently selected unit
   */
  async initMeetingProxyQuestions(
    { commit },
    { meetingBusiness, currentUnit }
  ) {
    try {
      let questions = meetingBusiness
        .filter(business => !business.disabledAt)
        .map(business => ({
          ...business,
          vote: business.type === 'by-law' ? '' : [],
          eligible: isQuestionEligible({
            question: business,
            currentUnitCustomData: currentUnit.customData
          })
        }));

      commit('SET_MEETING_PROXY_QUESTIONS', questions);
    } catch (err) {
      console.error('Error: initMeetingProxyQuestions action', err);
      throw err;
    }
  },

  /**
   * Sets the answers for proxy questions
   *
   * @param  {Object} {answerObj} The answer object for the question
   */
  setSelectedAnswers({ commit, state }, answerObj) {
    // Find index of local state of 'questions' and append vote property
    let index = state.meetingProxyQuestions.findIndex(question => {
      return question.questionKey === answerObj.questionKey;
    });

    if (index < 0) {
      return;
    }

    commit('SET_MEETING_PROXY_ANSWERS', {
      questionIndex: index,
      answers: answerObj.answers
    });
  },

  /**
   * Sets the write in answer for a question
   *
   * @param  {String} {writeInObj} The write in object for the question
   */
  setWriteIn({ commit, state }, writeInObj) {
    // Find index of local state of 'questions' and append vote property
    let index = state.meetingProxyQuestions.findIndex(question => {
      return question.questionKey === writeInObj.questionKey;
    });

    if (index < 0) {
      return;
    }

    commit('SET_MEETING_PROXY_ANSWERS_WRITE_IN', {
      questionIndex: index,
      writeIn: writeInObj.writeIn
    });
  },

  /**
   * Sets the proxyholder for the proxy
   *
   * @param  {String} {proxyHolder} The proxy holder
   */
  setProxyHolder({ commit }, proxyHolder) {
    commit('SET_MEETING_PROXY_HOLDER', {
      proxyHolder
    });
  },

  /**
   * Sets the write in proxyholder for the proxy
   *
   * @param  {String} {writeInProxyHolder} The write in proxyholder
   */
  setWriteInProxyHolder({ commit }, writeInProxyHolder) {
    commit('SET_MEETING_PROXY_HOLDER_WRITE_IN', {
      writeInProxyHolder
    });
  },

  /**
   * Sets the fallback proxyholder for the proxy
   *
   * @param  {String} {fallbackProxyHolder} The proxy holder
   */
  setFallbackProxyHolder({ commit }, fallbackProxyHolder) {
    commit('SET_MEETING_FALLBACK_PROXY_HOLDER', {
      fallbackProxyHolder
    });
  },

  /**
   * Sets the write in proxyholder for the proxy
   *
   * @param  {String} {writeInFallbackProxyHolder} The write in proxyholder
   */
  setWriteInFallbackProxyHolder({ commit }, writeInFallbackProxyHolder) {
    commit('SET_MEETING_FALLBACK_PROXY_HOLDER_WRITE_IN', {
      writeInFallbackProxyHolder
    });
  },

  /**
   * Sets the selected voter for whom we're submitting a proxy
   *
   * @param  {Object} {voter} The voter the proxy is for
   */
  setVoterSelected({ commit }, voter) {
    commit('SET_MEETING_PROXY_VOTER_SELECTED', {
      voter
    });
  },

  /**
   * Sets the default proxy holders, used to populate form selection
   *
   * @param  {Array} {proxyHolders} The viable proxyholders for the proxy
   */

  setDefaultHolders({ commit }, proxyHolders) {
    commit('SET_MEETING_DEFAULT_PROXY_HOLDERS', {
      proxyHolders
    });
  },

  /**
   * Adds optional file upload, an electronic copy of the hardcopy form
   *
   * @param  {Object} {file} paper proxy file
   */
  setUpload({ commit }, file) {
    commit('SET_MEETING_PROXY_FILE', {
      file
    });
  },

  /** formOption actions */

  /**
   * Set the on-bill-106 form option
   * Ontario specific form option
   * @param  {String} {option} the bill 106 option chosen (box-1, box-2, box-3)
   */
  setOnBill106Selection({ commit }, option) {
    commit('SET_BILL_106_OPTION', { option });
  },

  /**
   * Checks the option selected for bill 106
   * Ontario specific form option, update answers based on selection
   * @param  {String} {option} the bill 106 option chosen
   */
  checkOnBill106Selection({ commit }, option) {
    if (option === 'box-1' || option === 'box-2') {
      state.meetingProxyQuestions.forEach((question, index) => {
        commit('SET_ON_BILL_106_ANSWERS', { question, index });
      });
    }
  },

  /**
   * Triggers mutation to reset the form state
   */
  resetProxyForm({ commit }) {
    commit('RESET_MEETING_PROXY_FORM');
  },

  /**
   * Triggers mutation to reset the form state
   */
  setLiveVoteExists({ commit }, { liveVoteExists }) {
    commit('SET_LIVE_VOTE_EXISTS', { liveVoteExists });
  },

  /**
   * Validate form entry, check for missing answers
   */
  validateProxyForm({ state, commit }, options = {}) {
    // Clear the validation error message array first
    commit('RESET_VALIDATION_ERROR_MESSAGES');

    if (state.liveVoteExists && !options.enableRtvProxyOverride) {
      commit('PUSH_VALIDATION_ERROR', {
        type: 'live-vote-exists',
        message:
          'Live vote already exists for this unit, you can no longer create a proxy'
      });
    }

    if (!state.selectedVoter) {
      commit('PUSH_VALIDATION_ERROR', {
        type: 'proxy-giver',
        message: 'Proxy giver is required'
      });
    }

    if (!state.proxyHolder) {
      commit('PUSH_VALIDATION_ERROR', {
        type: 'proxy-holder',
        message: 'Proxy is required'
      });
    }

    // If questions exist, then check question answers
    // Ignores disabled questions. Should already be safe since it's already filtered prior during init.
    if (state.meetingProxyQuestions) {
      state.meetingProxyQuestions
        .filter(question => !question.disabledAt)
        .forEach((question, index) => {
          // Question is not answered if vote array prop is empty OR vote is falsey
          if (
            (Array.isArray(question.vote) && question.vote.length === 0) ||
            !question.vote
          ) {
            // Check if question is eligible before validation
            // If ineligible, it means the voter did not fulfill question restriction
            // Therefore should be left blank
            if (question.eligible) {
              commit('PUSH_VALIDATION_ERROR', {
                type: `question-${index}`,
                message: `Answer for question ${index + 1} is required`
              });
            }
          }
        });
    }
  }
};

const mutations = {
  // Create proxies mutations ------------------------------

  SET_MEETING_PROXY_QUESTIONS(state, questions) {
    state.meetingProxyQuestions = questions;
  },

  SET_MEETING_PROXY_ANSWERS(state, { questionIndex, answers }) {
    state.meetingProxyQuestions[questionIndex].vote = answers;
  },

  SET_MEETING_PROXY_ANSWERS_WRITE_IN(state, { questionIndex, writeIn }) {
    state.meetingProxyQuestions[questionIndex].options.push(writeIn);
    state.meetingProxyQuestions[questionIndex].vote.push(writeIn);
  },

  SET_MEETING_PROXY_HOLDER(state, { proxyHolder }) {
    state.proxyHolder = proxyHolder;
  },

  SET_MEETING_PROXY_HOLDER_WRITE_IN(state, { writeInProxyHolder }) {
    state.writeInProxyHolder = writeInProxyHolder;
  },

  SET_MEETING_FALLBACK_PROXY_HOLDER(state, { fallbackProxyHolder }) {
    state.fallbackProxyHolder = fallbackProxyHolder;
  },

  SET_MEETING_FALLBACK_PROXY_HOLDER_WRITE_IN(
    state,
    { writeInFallbackProxyHolder }
  ) {
    state.writeInFallbackProxyHolder = writeInFallbackProxyHolder;
  },

  SET_MEETING_PROXY_VOTER_SELECTED(state, { voter }) {
    state.selectedVoter = voter;
  },

  SET_MEETING_DEFAULT_PROXY_HOLDERS(state, { proxyHolders }) {
    state.defaultHolders = proxyHolders;
  },

  SET_MEETING_PROXY_FILE(state, { file }) {
    state.attachment = file;
  },

  SET_BILL_106_OPTION(state, { option }) {
    state.formOptions.onBill106 = option;
  },

  SET_ON_BILL_106_ANSWERS(state, { question, index }) {
    // Both box-1 and box-2 result in the same 'abstain' text
    // If the question is an array type, remove all answers and push abstain text
    if (Array.isArray(question.vote)) {
      state.meetingProxyQuestions[index].vote.splice(
        0,
        state.meetingProxyQuestions[index].vote.length,
        'I abstain from voting and my proxy shall not vote on this matter'
      );
    } else {
      // If not an array, then it is a single choice type question, set string value
      state.meetingProxyQuestions[index].vote = 'abstain';
    }
  },

  RESET_MEETING_PROXY_FORM(state) {
    // Reset all local state properties
    state.meetingProxyQuestions = null;
    state.selectedVoter = null;
    state.proxyHolder = '';
    state.writeInProxyHolder = '';
    state.fallbackProxyHolder = '';
    state.writeInFallbackProxyHolder = '';
    state.attachment = {};
    state.formOptions.onBill106 = 'box-3';
    state.liveVoteExists = false;

    state.validationErrorMessages.splice(
      0,
      state.validationErrorMessages.length
    );
  },

  RESET_VALIDATION_ERROR_MESSAGES(state) {
    state.validationErrorMessages.splice(
      0,
      state.validationErrorMessages.length
    );
  },

  SET_LIVE_VOTE_EXISTS(state, { liveVoteExists }) {
    state.liveVoteExists = liveVoteExists;
  },

  PUSH_VALIDATION_ERROR(state, { type, message }) {
    state.validationErrorMessages.push({
      type,
      message
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
