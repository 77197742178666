export const form = [
  {
    formNum: 8,
    header: `<h4>Form 8</h4><h4>{{ formName }} Proxy Form</h4><h4>{{ formDescription }} (under subsection 52 (6) of the Condominium Act, 1998)</h4><h5><em>Condominium Act, 1998</em></h5>`,
    to: `<p>TO: <strong><u>{{ corporationName }}</u></strong> (known as the "Corporation")</p>`,
    proxyBody: `<li>I am (We are) the registered owner(s) or mortgagee(s) of <strong><u>Suite {{ unit }}</u></strong> at <strong><u>{{ address }}</u></strong>.</li><li>I (We) appoint <strong><u>{{ proxyHolder0 }}</u></strong>, if present, or whom failing him or her, <strong><u>{{{ proxyHolder1 }}}</u></strong>, if present, to be proxy (known as the "Proxy") and to attend and vote on my (our) behalf at the meeting of owners to be held on the <strong>{{{ eventDate }}}</strong>, and at any adjournment of the meeting (known as the "Meeting").</li><li>The Proxy may vote on my (our) behalf in respect of all matters that may come before the Meeting, except for any election or removal of a director, and subject to the instructions set out below, as I (we) could do if personally present at the Meeting.</li>{{{ answersHtml }}}<li>I (We) revoke all proxies previously given.</li>`,
    terms: `<p>[ &check; ] I (We) agree to the terms and conditions (located at www.getquorum.com/terms-of-use) and acknowledge that by using the Online Proxy Form Tool, I am (we are) submitting this Proxy as though it were signed and delivered by hand.</p>`,
    termsEmail: `<p>[ &check; ] I consent to receiving all Corporation communications electronically (eg. notices, meeting packages, and newsletters).</p>`,
    signature: `<p>Dated this <strong>{{{ submitDate }}}</strong></p><p>Name(s) of Registered Owner(s) or Mortgagee(s): <strong><u>{{ name }}</u></strong></p><p>By: {{{customSignature}}}</p>`,
    footer: `<p><em>(In the case of a corporation, affix corporate seal or add a statement that the persons signing have authoritiy to bind the corporation.)</em></p><p><em>(If you are authorized to act on behalf of the registered owner(s) or mortgagee(s), attach a copy of the document that gives you this authorization.)</em></p><p>A copy of this proxy has been emailed to the Corporation and to the Registered Owner as indicated at the email address above.</p><p>For questions about this proxy, please contact us at support@getquorum.com</p>`
  },
  {
    formNum: 9,
    header: `<h4>Form 9</h4>\n<h4>{{ formName }} Proxy Form</h4>\n<h4>{{ formDescription }} (under subsection 52 (6) of the Condominium Act, 1998)</h4>\n<h5><em>Condominium Act, 1998</em></h5>`,
    to: `<p>TO: <strong><u>{{ corporationName }}</u></strong> (known as the "Corporation")</p>`,
    proxyBody: `<li>I am (We are) the registered owner(s) or mortgagee(s) of <strong><u>Suite {{ unit }}</u></strong> at <strong><u>{{ address }}</u></strong>.</li>\n<li>I (We) appoint <strong><u>{{ proxyHolder0 }}</u></strong>, if present, or whom failing him or her, <strong><u>{{{ proxyHolder1 }}}</u></strong>, if present, to be proxy (known as the "Proxy") and to attend and vote on my (our) behalf at the meeting of owners to be held on the <strong>{{{ eventDate }}}</strong>, and at any adjournment of the meeting (known as the "Meeting").</li><li>The Proxy may vote on my (our) behalf in respect of all matters that may come before the Meeting, subject to the instructions set out below, as I (we) could do if personally present at the Meeting.</li>{{{ answersHtml }}}<li>I (We) revoke all proxies previously given.</li>`,
    terms: `<p>[ &check; ] I (We) agree to the terms and conditions (located at www.getquorum.com/terms-of-use) and acknowledge that by using the Online Proxy Form Tool, I am (we are) submitting this Proxy as though it were signed and delivered by hand.</p>`,
    termsEmail: `<p>[ &check; ] I consent to receiving all Corporation communications electronically (eg. notices, meeting packages, and newsletters).</p>`,
    signature: `<p>Dated this <strong>{{{ submitDate }}}</strong></p>\n<p>Name(s) of Registered Owner(s) or Mortgagee(s): <strong><u>&nbsp;&nbsp;&nbsp;&nbsp;{{ name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></strong></p>`,
    footer: `<p><em>(In the case of a corporation, affix corporate seal or add a statement that the persons signing have authoritiy to bind the corporation.)</em></p>\n<p><em>(If you are authorized to act on behalf of the registered owner(s) or mortgagee(s), attach a copy of the document that gives you this authorization.)</em></p>\n<p>A copy of this proxy has been emailed to the Corporation and to the Registered Owner as indicated at the email address above.</p>\n<p>For questions about this proxy, please contact us at support@getquorum.com</p>`
  }
];
