'use strict';

/**
 * @fileoverview Vuex module for v2
 */

// Submodules
import documents from './documents';
import meetings from './meetings';
import questions from './questions';
import webcasts from './webcasts';
import additionalServices from './additional-services';
import meetingsFiles from './meetings-files';

// API Calls

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  modules: {
    documents,
    meetings,
    questions,
    webcasts,
    additionalServices,
    meetingsFiles
  },
  getters,
  actions,
  mutations
};
