<template>
  <v-dialog :value="isOpen" persistent scrollable max-width="500px">
    <v-card>
      <v-card-title class="headline">
        Zoom Recordings Share Page
      </v-card-title>
      <v-card-text>
        <v-layout column align-center v-if="loading">
          Loading...
        </v-layout>

        <v-layout column v-else-if="!loading && hasRecordings">
          <p>
            The URL and password to access the Recordings from the Zoom Share
            Page can be found below. <br />
            <br />

            Please note that this page is only available for a limited amount of
            time. <br />
          </p>
          <div id="shareable">
            <p style="word-break:break-all;">
              <b>URL:</b>
              <a target="_blank" :href="recordingsUrl"> {{ recordingsUrl }} </a
              ><br />

              <b>Password:</b> {{ recordingsPassword }}
            </p>
          </div>
          <v-btn
            color="primary"
            @click="handleCopy('test')"
            style="width:180px;"
          >
            Copy to Clipboard
          </v-btn>
        </v-layout>

        <v-layout column v-else>
          <h3>No Recordings Found</h3>
          <ul>
            <li>Meeting or it may not have taken place yet.</li>
            <li>
              Recordings files may still be processing (usually takes an hour or
              so).
            </li>
            <li>Cloud Recordings may have been disabled for the meeting.</li>
          </ul>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ShareRecordingsDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    }
  },
  watch: {},
  data() {
    return {
      loading: false,
      hasRecordings: false,
      recordingsUrl: '',
      recordingsPassword: ''
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/webcast', ['getRecordings']),
    async init() {
      try {
        this.loading = true;
        const { data } = await this.getRecordings({
          shortCode: this.shortCode
        });
        this.recordingsUrl = data.recordings.share_url;
        this.recordingsPassword = data.recordings.password;
        this.hasRecordings = true;
      } catch (err) {
        this.hasRecordings = false;
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    },
    handleCopy() {
      this.copyElementToClipboard(document.body);
      this.copyElementToClipboard('shareable');
    },
    copyElementToClipboard(element) {
      window.getSelection().removeAllRanges();
      let range = document.createRange();
      range.selectNode(
        typeof element === 'string' ? document.getElementById(element) : element
      );
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    }
  }
};
</script>

<style scoped></style>
