<template>
  <v-card class="round-card full-height">
    <v-card-title primary-title class="pb-0">
      <v-layout align-center>
        <v-avatar size="30" color="#e7f2fb" class="mr-2 start">
          <v-icon color="#3874CB" size="20">videocam</v-icon>
        </v-avatar>

        <div class="subheading font-weight-bold">
          Your Meeting
        </div>
        <v-spacer />
        <div v-if="canEditMeeting">
          <v-btn
            data-v-step="vbm-your-meeting-edit-btn"
            flat
            color="primary"
            class="ma-0 pa-0"
            @click="edit = true"
          >
            Edit
          </v-btn>
        </div>
      </v-layout>
    </v-card-title>
    <v-card-text class="mt-4 pt-0">
      <div style="min-height: 125px;">
        <p class="subheading">
          {{ formatMeetingDate }}<br />
          {{ formatMeetingTime }}<timezone-helper :date="meetingDate" />
        </p>
      </div>
      <div
        data-v-step="vbm-your-meeting-recording-switch"
        class="switch-container"
      >
        <span class="caption text-wrapper">Allow live recording?</span>
        <span
          :class="[isRecording ? 'primary--text' : '']"
          class="mx-3 font-weight-bold"
          >{{ isRecording ? 'Yes' : 'No' }}</span
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <!-- Separate switch components for recording lock state 
            Needed because @change & @click events conflict
            -->
            <v-switch
              v-if="!isRecordingLocked"
              v-on="on"
              v-model="isRecording"
              color="primary"
              :disabled="!hasZoomData || saving"
              @change="updateRecording"
            />
            <v-switch
              v-else
              v-on="on"
              v-model="isRecording"
              color="primary"
              :disabled="!hasZoomData || saving"
              :readonly="isRecordingLocked"
              @click="openRecordingLockDialog"
            />
          </template>
          <span
            >Enabling record automatically, meeting record will be saved on the
            cloud</span
          >
        </v-tooltip>

        <v-tooltip bottom v-if="isRecordingLocked">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">lock</v-icon>
          </template>
          <span>Live recording is locked</span>
        </v-tooltip>
      </div>

      <!-- Tooltip is enabled before the meeting can be started -->
      <v-tooltip bottom :disabled="canStartMeeting">
        <span slot="activator">
          <div data-v-step="vbm-your-meeting-start-btn">
            <v-btn
              :outline="!canStartMeeting"
              :small="!canStartMeeting"
              :color="canStartMeeting ? 'primary' : 'grey'"
              :disabled="!canStartMeeting"
              @click="dialog.startMeetingConfirm = true"
              class="button"
            >
              Start Meeting
            </v-btn>
          </div>
        </span>
        <span v-if="isBefore">
          You will be able to start this board meeting
          {{ timeBeforeMeeting }} minutes before the scheduled start time
        </span>
        <span v-else-if="isAfter">
          Meeting has ended more than {{ timeAfterMeeting }} hours ago
        </span>
      </v-tooltip>
    </v-card-text>

    <!-- Dialogs -->
    <start-dialog
      v-if="hasZoomData"
      :is-open="dialog.start"
      :start-url="startUrl"
      :booting="booting"
      :license="license"
      :skip-boot="skipBoot"
      @close="dialog.start = false"
    />
    <boot-error-dialog
      :is-open="dialog.bootError"
      :error="bootError"
      :type="type"
      @close="dialog.bootError = false"
    />
    <edit-your-meeting
      v-model="edit"
      :meetingDate="meetingDate"
      :shortCode="shortCode"
      :meetingDuration="meetingDuration"
      :meetingName="meetingName"
      :meetingTimezone="meetingTimezone"
      @close="edit = false"
    />
    <!-- Confirm dialogs -->
    <confirm-dialog
      title="Enable automatic live recordings?"
      :text="confirmText.autoRecording"
      :dialog="dialog.record"
      confirm-text="Yes"
      decline-text="No"
      :round-dialog="true"
      @confirm="handleRecordToggle"
    />
    <confirm-dialog
      title="This meeting needs to be recorded for minute taking purposes."
      :text="confirmText.minutes"
      :dialog="dialog.recordingLock"
      close-text="close"
      :round-dialog="true"
      @close="dialog.recordingLock = false"
    />
    <confirm-dialog
      title="Before you start"
      :text="confirmText.startMeeting"
      :dialog="dialog.startMeetingConfirm"
      confirm-text="Proceed"
      :round-dialog="true"
      @confirm="handleConfirmStartMeeting"
    />
  </v-card>
</template>

<script>
import { formatDate } from '@/helpers.js';
import moment from 'moment-timezone';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import StartDialog from '../webcast/StartDialog.vue';
import BootErrorDialog from '../webcast/BootErrorDialog.vue';
import TimezoneHelper from '@/components/TimezoneHelper';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import EditYourMeeting from './components/MeetingVbmEditYourMeetingDialog.vue';

export default {
  name: 'MeetingYourMeeting',
  components: {
    StartDialog,
    TimezoneHelper,
    BootErrorDialog,
    ConfirmDialog,
    EditYourMeeting
  },
  props: {
    meetingName: {
      type: String,
      required: true
    },
    meetingDate: {
      type: String,
      required: true
    },
    meetingDuration: {
      type: Number,
      default: 0
    },
    meetingTimezone: {
      type: String,
      default: 'America/Toronto'
    },
    meetingMeta: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      booting: false,
      shortCode: this.$route.params.shortcode,
      bootError: '',
      isRecording: false,
      confirmText: {
        autoRecording:
          "This will automatically start the Zoom meeting once it's launched. Recordings will be available to download after the meeting ends.<br/><br/>Note: All attended will receive a notification upon joining the meeting informing them that it is being recorded.",
        minutes: `<p>A digital recoding of your meeting is necessary for accuracy of your minutes.</p><p>The recording will be sent to you privately and will not be shared without your consent. If you wish to cancel your order for minute taking services and turn off meeting recording, please email us at <a href="mailto:support@getquorum.com">support@getquorum.com</a> up to 48 hours before your meeting.`,
        hideSpectatorStartMeeting: `<p>To manage any restrictions such as unmuting mics and enabling videos please do the following once you launch your meeting:</p>
        <ol><li>Click <b>Participants</b> located in the meeting controls</li><li>Click <b>More</b> then select the options you'd like to allow your meeting attendees to do/not do (ie. Unmute themselves or Start Video)</li></ol>`,
        startMeeting: `<p>Unlike Members, spectators will not be able to cast votes in the meeting. They will be able to:</p>
        <ol><li>Join the meeting as participant</li><li>Unmute mics, enable video and send chat messages (if permitted by meeting Host)</li></ol>
        <br /><p>They will not have access to member information, meeting documents and private documents.</p>
        <p>To manage any restrictions such as unmuting mics and enabling videos please do the following once you launch your meeting:</p>
        <ol><li>Click <b>Participants</b> located in the meeting controls</li><li>Click <b>More</b> then select the options you'd like to allow your meeting attendees to do/not do (ie. Unmute themselves or Start Video)</li></ol>`
      },
      dialog: {
        start: false,
        bootError: false,
        record: false,
        recordingLock: false,
        startMeetingConfirm: false
      },
      saving: false,
      edit: false,
      type: 'fromWebcast'
    };
  },
  computed: {
    ...mapGetters('meetings/webcast', ['zoomData', 'license', 'webcast']),
    ...mapGetters(['login', 'isAdmin', 'getScopeByAction']),
    hasPermissionsToAssignLicense() {
      const { profile } = this.login;
      return (
        this.getScopeByAction(this.shortCode, 'meeting.webcast.boot.early') &&
        profile.type === 'user'
      );
    },
    isBefore() {
      return moment()
        .tz(this.meetingTimezone)
        .isBefore(
          moment(this.meetingDate).subtract(this.timeBeforeMeeting, 'minutes')
        );
    },
    isAfter() {
      return moment()
        .tz(this.meetingTimezone)
        .isAfter(moment(this.meetingDate).add(this.timeAfterMeeting, 'hours'));
    },
    canStartMeeting() {
      return !this.isAfter;
    },
    hasZoomData() {
      return this.zoomData;
    },
    skipBoot() {
      return this.webcast ? false : this.webcast.skipBoot;
    },
    startUrl() {
      return !this.canStartMeeting ? null : this.zoomData.startUrl;
    },
    formatMeetingDate() {
      return formatDate(
        this.meetingDate,
        this.meetingTimezone,
        'dddd, MMM DD YYYY'
      );
    },
    formatMeetingTime() {
      const startDateTime = this.meetingDate;
      const endDateTime = moment(startDateTime).add(this.meetingDuration, 'm');

      return (
        formatDate(
          startDateTime,
          this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
          'h:mma'
        ) +
        ' - ' +
        formatDate(
          endDateTime,
          this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
          'h:mma zz'
        )
      );
    },
    timeBeforeMeeting() {
      return process.env.WEBCAST_START_HOST_TIME || 90;
    },
    timeAfterMeeting() {
      return process.env.WEBCAST_END_HOST_TIME || 12;
    },
    // place holder, need to be changed later
    canEditMeeting() {
      if (this.isAdmin) {
        return true;
      }
      const { profile } = this.login;
      return (
        this.getScopeByAction(this.shortCode, 'meeting.webcast.update') &&
        profile.type === 'user'
      );
    },
    myDashboardLink() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';
      return `${baseUrl}/meetings`;
    },
    isRecordingLocked() {
      if (
        this.meetingMeta?.additionalServicesDigitalMinuteTaking ||
        this.meetingMeta?.additionalServicesLiveMinuteTaking
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    zoomData() {
      this.resetRecordState();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/webcast', [
      'fetchWebcastDetails',
      'updateWebcastWebinar',
      'bootWebcast'
    ]),
    resetRecordState() {
      const autoRecording = _.get(
        this.zoomData,
        'settings.autoRecording',
        'none'
      );
      this.isRecording = autoRecording === 'cloud';
    },

    async init() {
      await this.fetchWebcastDetails({ shortCode: this.shortCode });
    },

    async updateRecording() {
      if (this.isRecording) {
        this.dialog.record = true;
      } else {
        this.handleRecordToggle(true);
      }
    },

    async handleRecordToggle(isEnabled) {
      this.dialog.record = false;
      if (isEnabled) {
        try {
          this.saving = true;
          await this.updateWebcastWebinar({
            shortCode: this.shortCode,
            payload: {
              settings: {
                autoRecording: this.isRecording ? 'cloud' : 'none'
              }
            }
          });

          this.$events.$emit('toastEvent', 'Changes saved');
        } catch (err) {
          this.$events.$emit('showErrorDialog', err.response);
        } finally {
          this.saving = false;
        }
      } else {
        this.resetRecordState();
      }
    },
    // Confirmation dialog to start meeting
    handleConfirmStartMeeting($event) {
      // If confirmed, run bootup()
      if ($event) {
        this.bootup();
      }
      this.dialog.startMeetingConfirm = false;
    },

    // Execute the webcast bootup sequence
    async bootup() {
      if (!this.canStartMeeting) {
        return;
      }

      try {
        this.dialog.start = true;
        this.booting = true;

        await this.bootWebcast({ shortCode: this.shortCode });
      } catch (err) {
        this.dialog.start = false;
        if (err.response) {
          if (err.response.data === 'NO_LICENSES_AVAILABLE') {
            this.bootError =
              'There are no licenses available to start the meeting now. Please contact support.';
          } else if (err.response.data === 'CANNOT_START_MEETING') {
            this.type = 'fromVbm';
            this.bootError = `This meeting could not be started <br/>
                              There is another meeting in progress under your Account.
                              Please visit <a href="${this.myDashboardLink}" target="_blank">My Dashboard</a> for a list of all meetings. <br/>
                              For further assistance, please contact support@getquorum.com.`;
          } else {
            this.bootError = err.response.data;
          }
        } else if (err.request) {
          this.bootError = 'Service unavailable. Please contact support.';
        } else {
          console.log(err);
          this.bootError = 'There has been an error. Please contact support.';
        }
        this.dialog.bootError = true;
      } finally {
        this.booting = false;
      }
    },
    goToMyDashboard() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';
      const url = `${baseUrl}/meetings`;
      window.open(url, '_blank');
    },
    openRecordingLockDialog() {
      // Only open recording lock dialog when isRecordingLocked: true
      if (this.isRecordingLocked) {
        this.dialog.recordingLock = true;
      } else {
        this.isRecording = !this.isRecording;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-btn,
.v-switch {
  margin: 0;
}

.switch-container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 20px;
  margin-bottom: 15px;
  height: 45px;
}
.button {
  width: 100%;
}

.text-wrapper {
  margin: 0 auto;
  white-space: nowrap;
}
.full-height {
  height: 100%;
}
</style>
