import http from './requestType';

/**
 * Retrieve a Recipe
 * @param  {string} name          - the Recipe name
 * @return {Promise}              - Promise
 */
export function getRecipe(name) {
  return http._get(`Recipes/${name}`);
}
