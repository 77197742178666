<template>
  <v-card flat color="#f5f5f5">
    <v-toolbar dense class="elevation-0">
      <p class="mb-0 subheading font-weight-bold">Results</p>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          v-if="canSeeVotingDetails"
          color="blue"
          flat
          :disabled="!hasTallyData"
          @click="viewDataClicked"
        >
          Details
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="question.tally"
      hide-actions
      class="grey-table"
    >
      <template v-slot:items="props">
        <td>{{ props.item.vote }}</td>
        <td class="text-xs-right">{{ props.item.numVotes }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import checkScope from '@/lib/check-user-scopes';
import { mapGetters } from 'vuex';

export default {
  name: 'MeetingVbmBusinessDetails',
  props: {
    question: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      headers: [
        {
          text: 'Vote',
          align: 'left',
          sortable: false,
          value: 'vote'
        },
        { text: 'Votes', value: 'votes', align: 'right', sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    hasTallyData() {
      return this.question.tally.length > 0;
    },
    canSeeVotingDetails() {
      // Hide from vbm-assistant collaborators (no meeting.live-votes.access scope)
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.access')
      );
    }
  },
  methods: {
    viewDataClicked() {
      this.$router.push({
        name: 'meetingVbmLiveAnswers',
        params: {
          shortcode: this.shortCode
        },
        query: { filter: this.question.label }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.grey-table .v-table.theme--light {
  background-color: transparent !important;
}
</style>
