<template>
  <div>
    <v-layout row class="mt-3" v-if="hasPermissions">
      <v-flex xs12 class="text-xs-right">
        <v-btn
          slot="activator"
          color="primary"
          @click.native="showAddDialog = true"
        >
          Add Integration
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row class="mt-3" v-if="!hasIntegration">
      <v-flex xs12>
        <v-alert :value="true" type="info" outline>
          This account has no integrations.
        </v-alert>
      </v-flex>
    </v-layout>

    <v-layout row class="mt-3" v-if="hasIntegration">
      <v-flex
        xs4
        v-for="integration in currentAccount.integrations"
        :key="integration.name"
      >
        <integration-card
          icon="link"
          :title="integration.name | startCase"
          v-slot="{ edit }"
          :hide-edit="!hasPermissions"
          :saving="saving"
          :disabled="!newCode || newCode === integration.assocCode"
          @save="onSave(integration)"
        >
          <v-card-text>
            <p v-if="!edit">
              Click the button below to download the membership data in CSV
              format.
            </p>

            <v-text-field
              class="mt-0 pt-0"
              v-if="edit"
              label="Association code"
              placeholder="Association code"
              :value="integration.assocCode"
              @input="handleUpdate($event)"
            />
          </v-card-text>
          <v-card-actions class="justify-center" v-if="!edit">
            <v-tooltip top v-if="hasPermissions">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="red"
                  outline
                  :disabled="downloading"
                  :loading="removing"
                  v-on="on"
                  @click.native="
                    onRemove({ accountIntegrationKey: integration.key })
                  "
                  >Remove</v-btn
                >
              </template>
              <span>Remove this integration</span>
            </v-tooltip>

            <v-spacer />
            <v-btn
              color="primary"
              :disabled="removing"
              :loading="downloading"
              @click="
                downloadDataFromIntegration({
                  name: integration.name,
                  code: integration.assocCode
                })
              "
              >Download</v-btn
            >
          </v-card-actions>
        </integration-card>
      </v-flex>
    </v-layout>

    <admin-account-integrations-dialog
      :isOpen="showAddDialog"
      @close="showAddDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import IntegrationCard from './AminAccountIntegrationsCard.vue';
import AdminAccountIntegrationsDialog from './AdminAccountIntegrationsDialog.vue';
import { startCase } from '@/filters';

export default {
  name: 'AdminAccountIntegrationsView',
  components: { IntegrationCard, AdminAccountIntegrationsDialog },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  filters: {
    startCase
  },
  data() {
    return {
      saving: false,
      downloading: false,
      removing: false,
      newCode: '',
      showAddDialog: false
    };
  },

  computed: {
    ...mapGetters(['login', 'getScopeByAction']),
    hasIntegration() {
      return (
        this.currentAccount.integrations &&
        this.currentAccount.integrations.length > 0
      );
    },
    hasPermissions() {
      const { profile } = this.login;
      const permissions = profile.permissions;
      return _.includes(permissions, 'admin.integrations.update');
    }
  },
  methods: {
    ...mapActions('accounts', [
      'getAssociationMemberships',
      'upsertIntegration',
      'removeAccIntegration'
    ]),
    handleUpdate(value) {
      this.newCode = value;
    },
    async downloadDataFromIntegration({ name, code }) {
      this.downloading = true;
      try {
        await this.getAssociationMemberships({
          accountKey: this.currentAccount.key,
          integration: name,
          code
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.downloading = false;
      }
    },
    async onSave({ integrationKey }) {
      this.saving = true;

      try {
        await this.upsertIntegration({
          accountKey: this.currentAccount.key,
          integrationKey,
          code: this.newCode
        });
        this.$events.$emit('toastEvent', 'Integration Succesfully Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    },
    async onRemove({ accountIntegrationKey }) {
      this.removing = true;

      try {
        await this.removeAccIntegration({
          accountKey: this.currentAccount.key,
          accountIntegrationKey
        });
        this.$events.$emit('toastEvent', 'Integration Succesfully Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.removing = false;
      }
    }
  }
};
</script>

<style scoped>
.switch-right {
  display: flex;
  justify-content: right;
}
</style>
