var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","nowrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"dense":""}},[_c('v-text-field',{attrs:{"light":"","prepend-icon":"search","label":"Filter by campaign name...","single-line":"","hide-details":"","clearable":"","value":_vm.searchText},on:{"input":_vm.updateSearch}}),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"flat":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();_vm.dialog.filters = true}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("filter_list")]),_vm._v("Filters ")],1),_c('span',{staticClass:"mr-3"},[_vm._v("Show:")]),_c('v-btn-toggle',{staticClass:"elevation-1",attrs:{"value":_vm.toggles,"multiple":""},on:{"change":_vm.updateToggles}},[_c('v-btn',{attrs:{"flat":"","value":"showBilled"}},[_vm._v(" Billed ")]),_c('v-btn',{attrs:{"flat":"","value":"showIgnored"}},[_vm._v(" Ignored ")])],1),_c('v-btn',{attrs:{"flat":"","icon":"","loading":_vm.loading,"color":"secondary"},nativeOn:{"click":function($event){return _vm.fetchData.apply(null, arguments)}}},[_c('v-icon',[_vm._v("cached")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.campaignListData,"loading":_vm.loading,"rows-per-page-items":_vm.pagination.rowsPerPageItems,"total-items":_vm.pagination.totalItems,"pagination":_vm.pagination,"item-key":"shortCode"},on:{"update:pagination":[function($event){_vm.pagination=$event},_vm.handlePagination]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',[_c('td',{staticClass:"avatar-cell"},[_c('campaign-avatar',{attrs:{"campaign-type":props.item.type,"hide-billing":props.item.disableBilling}})],1),_c('td',{staticClass:"name-cell nowrap"},[_c('router-link',{attrs:{"to":{
                name: 'meetingOverview',
                params: { shortcode: props.item.shortCode }
              },"target":"_blank"}},[_c('b',[_vm._v(_vm._s(props.item.name))]),_vm._v(" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("open_in_new")])],1),_c('br'),_c('span',{staticClass:"secondary-label"},[_vm._v(_vm._s(props.item.type))])],1),_c('td',{staticClass:"nowrap"},[_c('router-link',{attrs:{"to":{
                name: 'editAccount',
                params: { accountKey: props.item.account.key }
              }}},[_vm._v(" "+_vm._s(props.item.account.nameShort)+" ")])],1),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(props.item.subscription?.status)+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatDateHumanReadable")(props.item.meetingDate))+" ")]),_c('td',[_c('invoice-status-chip',{attrs:{"stats":props.item.invoiceStats,"billing-enabled":props.item.billingEnabled,"route":_vm.getInvoicesRoute(props.item.shortCode)}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""}},[_c('template',{slot:"activator"},[_c('span',[_vm._v(_vm._s(props.item.billingSettings.totalAmount > 0 ? `$${props.item.billingSettings.totalAmount}` : ''))])]),_c('span',[_vm._v(" subtotal: $"+_vm._s(props.item.billingSettings.subtotal)),_c('br'),_vm._v(" tax: $"+_vm._s(props.item.billingSettings.taxAmount)),_c('br'),_vm._v(" total: $"+_vm._s(props.item.billingSettings.totalAmount)+" ")])],2)],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""}},[_c('template',{slot:"activator"},[_c('span',[_vm._v(_vm._s(props.item.invoices.total > 0 ? '$' + _vm.getInvoiceTotal(props.item.invoices.invoices) : ''))])]),_c('span',_vm._l((props.item.invoices.invoices),function(item){return _c('span',{key:item.key},[(_vm.isValidInvStatus(item.status))?_c('span',[_vm._v(_vm._s(item.invoiceNumber)+": $"+_vm._s(item.totalPrice)),_c('br')]):_vm._e()])}),0)],2)],1),_c('td',{staticClass:"nowrap"},[_c('print-status-chips',{attrs:{"stats":props.item.printStats}})],1)])]}}])})],1),_c('filter-campaigns-dialog',{attrs:{"value":_vm.dialog.filters,"current-sub-filter":_vm.subFilter},on:{"update":_vm.updateFilters,"close":function($event){_vm.dialog.filters = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }