'use strict';

import http from './requestType';

/**
 * Finds a user task
 * @return {Promise} - Promise
 */
export function findUserTask(userTaskKey) {
  return http._get(`UserTasks/${userTaskKey}`);
}

/**
 * Return a user task's event history
 * @return {Promise} - Promise
 */
export function listUserTaskEvents(userTaskKey) {
  return http._get(`UserTasks/${userTaskKey}/events`);
}

/**
 * API call to subscribe to Pusher
 * @param {string} authEndpoint   - the puser authorization endpoint
 * @param {Object} channel        - the pusher channel object
 */
export function postUserTaskSubscribe(authEndpoint, { socketId, channel }) {
  return http.instance
    .post(
      authEndpoint,
      {
        socket_id: socketId,
        channel_name: channel.name
      },
      { withCredentials: true }
    )
    .then(res => res.data);
}
