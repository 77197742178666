<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <span class="title">Edit Approval Request</span>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              prepend-icon="subject"
              label="Approval request name"
              :value="name"
              :rules="rules.name"
              @input="onChange('name', $event)"
            />
          </v-flex>
          <v-flex xs12>
            <date-picker
              label="Deadline"
              box
              :iso-date="deadline"
              :isRequired="true"
              @update="onChange('deadline', $event)"
              dateFormat="MMMM DD, YYYY"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!isChanged"
          :loading="saving"
          @click.native="onSave"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'EditRequestDialog',
  components: {
    DatePicker
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    currentApproval: {
      type: Object,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      arKey: this.$route.params.arKey,
      name: '',
      deadline: null,
      saving: false,
      rules: {
        name: [v => !!v || 'Approval request name is required']
      }
    };
  },
  watch: {
    currentApproval() {
      this.setState();
    }
  },
  computed: {
    isChanged() {
      const state = {
        name: this.name,
        deadline: this.deadline
      };
      const current = {
        name: this.currentApproval.name,
        deadline: moment(this.currentApproval.dueAt).format('YYYY-MM-DD')
      };

      return !_.isEqual(state, current);
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('meetings/approvals', ['updateApproval']),
    setState() {
      this.name = this.currentApproval.name;
      this.deadline = this.currentApproval.dueAt;
    },
    async onSave() {
      try {
        this.saving = true;
        const payload = {
          name: this.name,
          dueAt: moment(this.deadline).tz(this.meetingTimezone)
        };

        await this.updateApproval({
          shortCode: this.shortCode,
          arKey: this.arKey,
          payload
        });

        this.$events.$emit('toastEvent', 'Approval Request updated');
        this.handleClose();
      } catch (err) {
        this.setState();
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.saving = false;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    onChange(key, value) {
      _.set(this, key, value);
    }
  }
};
</script>

<style scoped></style>
