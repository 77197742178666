import { render, staticRenderFns } from "./StartDialog.vue?vue&type=template&id=5da91df5&scoped=true"
import script from "./StartDialog.vue?vue&type=script&lang=js"
export * from "./StartDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da91df5",
  null
  
)

export default component.exports