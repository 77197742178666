<template>
  <v-dialog :value="value" max-width="400" persistent>
    <v-card>
      <v-card>
        <v-card-title>
          <h2>Filter Campaigns:</h2>
        </v-card-title>
        <v-card-text>
          <h4>Subscription</h4>
          <v-layout row wrap>
            <!-- Subscription Filters -->

            <v-layout column>
              <v-radio-group v-model="subscriptionFilter">
                <v-radio label="All Campaigns" value="all"></v-radio>
                <v-radio label="All Subscribed" value="subscribed"></v-radio>
                <v-radio
                  label="Unpaid Invoice"
                  value="unpaid-invoice"
                ></v-radio>
                <v-radio label="Paid Invoice" value="paid-invoice"></v-radio>
                <v-radio label="No Invoice" value="no-invoice"></v-radio>
              </v-radio-group>
            </v-layout>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="close">close</v-btn>
          <v-spacer />
          <v-btn color="red" flat @click="clear">clear filters</v-btn>
          <v-btn color="blue" flat @click="update">apply filters</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
// This component is modified from FilterAccountsDialog
export default {
  name: 'FilterCampaignsDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      }
    },
    currentSubFilter: {
      type: String,
      default() {
        return 'all';
      }
    }
  },
  watch: {
    currentSubFilter() {
      this.subscriptionFilter = this.currentSubFilter;
    }
  },
  created() {
    this.subscriptionFilter = this.currentSubFilter;
  },
  data() {
    return {
      subscriptionFilter: 'all'
    };
  },
  methods: {
    update() {
      this.$emit('update', {
        subFilter: this.subscriptionFilter
      });
      this.$emit('close');
    },
    clear() {
      this.subscriptionFilter = 'all';
      this.update();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
