<template>
  <v-dialog :value="value" @input="emitClose" max-width="400px">
    <v-card>
      <span v-if="state.status === 'scheduled'">
        <span v-if="noticeStatus === 'justScheduled'">
          <v-card-title>
            <div class="title">Notice has been scheduled</div>
            <div class="gq-cell--section-title"></div>
          </v-card-title>
          <v-list>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">schedule</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{
                  state.sendOn
                    | formatDateHumanReadableAlt(true, meetingTimezone)
                }}</v-list-tile-title>
                <v-list-tile-sub-title
                  >Scheduled date & time</v-list-tile-sub-title
                >
              </v-list-tile-content>
              <v-list-tile-action>
                <v-tooltip bottom>
                  <span slot="activator">
                    <v-btn flat icon @click.native="emitReturn">
                      <v-icon>keyboard_return</v-icon>
                    </v-btn>
                  </span>
                  <span>Return to notices</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
          <v-divider />
          <span v-if="this.isPrimaryNotice">
            <v-card-text>
              <div>Would you like to schedule notice reminders?</div>
            </v-card-text>
            <v-card-actions>
              <v-btn flat @click.native="emitClose">Go Back</v-btn>
              <v-spacer />
              <v-btn
                color="deep-orange"
                class="white--text"
                @click.native="emitScheduler"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </span>
        </span>
        <span v-else>
          <v-card-title>
            <div class="title">You're about to unschedule this email!</div>
          </v-card-title>
          <v-card-text>
            <div>Click 'Unschedule' to continue.</div>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click.native="emitClose">Go Back</v-btn>
            <v-spacer />
            <v-btn
              color="deep-orange"
              class="white--text"
              @click.native="emitUnschedule"
            >
              <v-icon left>timer_off</v-icon>Unschedule
            </v-btn>
          </v-card-actions>
        </span>
      </span>

      <span v-else>
        <v-card-title primary-title>
          <div class="body-2 mb-2 grey--text">
            You're about to schedule a send out for:
          </div>
          <div class="title">{{ state.purpose }}</div>
        </v-card-title>
        <v-card-text class="mt-0 pt-0">
          <v-list>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">schedule</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{
                    state.sendOn
                      | formatDateHumanReadableAlt(true, meetingTimezone)
                  }}
                  <timezone-helper :date="state.sendOn"
                /></v-list-tile-title>
                <v-list-tile-sub-title
                  >Scheduled date & time</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">subject</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ state.subject }}</v-list-tile-title>
                <v-list-tile-sub-title>Subject</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">person</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ segment | startCase }} ({{
                    sendCount
                  }}
                  Recipients)</v-list-tile-title
                >
                <v-list-tile-sub-title>Segment</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="red">warning</v-icon>
              </v-list-tile-avatar>
              <notice-checklist
                :body="state.body"
                :attachments="state.attachments"
              />
            </v-list-tile>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="emitClose">Go Back</v-btn>
          <v-spacer />
          <v-btn
            color="deep-orange"
            class="white--text"
            @click.native="emitSchedule"
          >
            <v-icon left>schedule</v-icon>Confirm schedule
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';
import noticeChecklist from '@/components/NoticeSendChecklist';
import TimezoneHelper from '@/components/TimezoneHelper';

export default {
  name: 'MeetingNoticeViewSchedule',
  components: {
    noticeChecklist,
    TimezoneHelper
  },
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      default() {
        return {
          recipients: {}
        };
      }
    },
    noticeStatus: { type: String, default: '' },
    sendCount: { type: Number, default: 0 },
    meetingTimezone: { type: String, default: null },
    isPrimaryNotice: { type: Boolean, default: false }
  },
  computed: {
    segment() {
      return this.state.recipients ? this.state.recipients.segment : '';
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSchedule() {
      this.$emit('scheduleNotice');
    },
    emitUnschedule() {
      this.$emit('unscheduleNotice');
    },
    emitReturn() {
      this.$emit('return');
    },
    emitScheduler() {
      this.$emit('autoScheduler');
    }
  }
};
</script>

<style scoped></style>
