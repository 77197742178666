<template>
  <div>Redirecting to Billing Dashboard</div>
</template>

<script>
export default {
  name: 'BillingRedirectView',
  created() {
    window.location.href = process.env.VUE_APP_BILLING_UI_URL;
  }
};
</script>
