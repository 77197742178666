<template>
  <v-card>
    <v-card-title>
      <span class="headline">Send Certificate By Email</span>
    </v-card-title>
    <v-card-text>
      <v-text-field
        required
        label="Send to Email"
        v-model="payload.toEmail" />
      <v-text-field
        label="CC Email (optional)"
        v-model="payload.copyEmail" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        @click="emitClose">Close</v-btn>
      <v-btn
        :disabled="!payload.toEmail"
        class="primary"
        @click="emitSend"><v-icon left>send</v-icon> Send</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MeetingCertificatesViewSend',
  props: {
    certKey: {
      type: String,
      default: ''
    },
    contactEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      payload: {
        toEmail: '',
        copyEmail: ''
      }
    };
  },
  mounted() {
    this.payload.toEmail = this.contactEmail;
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitSend() {
      this.$emit('send', this.certKey, this.payload);
    }
  }
};
</script>

<style scoped>
</style>
