<template>
  <v-dialog persistent :value="isOpen" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="title">Disable Billing</span>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              box
              v-model="reason"
              label="Please enter a reason why billing is being disabled"
              auto-grow
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="!reason" @click.native="handleSubmit"
          >submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingBillingDisableBillingDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reason: ''
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.reason);
      this.resetState();
    },
    handleClose() {
      this.$emit('close');
      this.resetState();
    },
    resetState() {
      this.reason = '';
    }
  }
};
</script>

<style scoped></style>
