<template>
  <v-container grid-list-md>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Zoom Diagnostics</p>
        <div v-if="loading">
          <v-progress-circular indeterminate color="primary" />
        </div>

        <div v-else>
          <v-layout row>
            <v-flex xs12>
              <v-card>
                <v-card-title>
                  <h3 class="title mb-0">OAuth Token - Current status</h3>
                </v-card-title>
                <v-card-text>
                  <v-layout row>
                    <v-flex xs6>
                      <v-text-field
                        label="No token"
                        single-line
                        outline
                        disabled
                        :value="accessToken"
                        :loading="validating || refreshing"
                      >
                        <template v-if="!validating" v-slot:append>
                          <v-icon v-if="isTokenValid" color="green"
                            >check_circle</v-icon
                          >
                          <v-icon v-else color="red">error</v-icon>
                        </template>
                      </v-text-field>

                      <div class="flex">
                        <v-btn depressed color="primary" @click="verifyToken">
                          Test Token
                        </v-btn>
                        <v-btn depressed @click="dialog.confirm = true">
                          Get new Token
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs3 v-if="!validating">
                      <v-alert
                        v-if="isTokenValid"
                        v-show="true"
                        small
                        outline
                        color="success"
                        icon="check_circle"
                        class="my-0"
                      >
                        {{ tokenStatus.data.message }}
                      </v-alert>
                      <v-alert
                        v-else
                        v-show="true"
                        small
                        outline
                        color="error"
                        icon="warning"
                        class="my-0"
                      >
                        {{ tokenStatus.data.message }}
                      </v-alert>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs6>
              <v-card>
                <v-card-title>
                  <h5 class="title mb-0">Expiration time in zoom</h5>
                  <v-card-actions>
                    <v-icon
                      v-if="['N/A', '00:00'].includes(expCountdown)"
                      color="red"
                      >error</v-icon
                    >
                    <v-icon v-else color="green">check_circle</v-icon>
                  </v-card-actions>
                </v-card-title>
                <div class="d-flex items-center">
                  <v-card-text class="text-center">
                    <span c>{{ tokenExpiration }}</span>
                  </v-card-text>
                  <v-divider vertical></v-divider>
                  <v-card-text class="text-center">
                    <span>Time remaining: {{ expCountdown }}</span>
                  </v-card-text>
                </div>
              </v-card>
            </v-flex>
            <v-flex xs6>
              <v-card>
                <v-card-title>
                  <h5 class="title mb-0">Next Token Refresh</h5>
                  <v-card-actions>
                    <v-icon
                      v-if="['N/A', '00:00'].includes(refreshCountdown)"
                      color="red"
                      >error</v-icon
                    >
                    <v-icon v-else color="green">check_circle</v-icon>
                  </v-card-actions>
                </v-card-title>
                <div class="d-flex items-center">
                  <v-card-text class="text-center">
                    <span>{{ nextRefresh }}</span>
                  </v-card-text>
                  <v-divider vertical></v-divider>
                  <v-card-text class="text-center">
                    <span>Time remaining: {{ refreshCountdown }}</span>
                  </v-card-text>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row class="mt-3">
            <v-flex xs6>
              <p class="title">Zoom Licenses</p>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs6>
              <v-card style="height: 100%;">
                <v-card-title>
                  <h5 class="title mb-0">Licenses</h5>
                </v-card-title>
                <div class="d-flex items-center">
                  <v-card-text>
                    <ul style="line-height: 3;">
                      <li>
                        <span>Total (Host): {{ planBase.hosts }}</span>
                      </li>
                      <li>
                        <span>In Usage: {{ planBase.usage }}</span>
                      </li>
                      <li>
                        <span
                          >Available:
                          {{ planBase.hosts - planBase.usage }}</span
                        >
                      </li>
                    </ul>
                  </v-card-text>
                </div>
              </v-card>
            </v-flex>
            <v-flex xs6>
              <v-card>
                <v-card-title>
                  <h5 class="title mb-0">Webinar Licenses</h5>
                </v-card-title>
                <v-card-text>
                  <v-layout row>
                    <v-flex xs6 v-for="plan in plansWebinar" :key="plan.type">
                      <ul style="line-height: 3;">
                        <li>
                          <span>License type: {{ plan.type }}</span>
                        </li>
                        <li>
                          <span>Total (Host): {{ plan.hosts }}</span>
                        </li>
                        <li>
                          <span>In Usage: {{ plan.usage }}</span>
                        </li>
                        <li>
                          <span>Available: {{ plan.hosts - plan.usage }}</span>
                        </li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog v-model="dialog.confirm" max-width="500px">
          <v-card>
            <v-card-text>
              <p class="headline">Are you sure?</p>
              You are going to refresh the current token, this action can
              invalidate other zoom processes running at this time.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn flat @click.native="dialog.confirm = false">Close</v-btn>
              <v-btn class="primary" @click.native="refreshToken">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'AdminZoomView',
  data() {
    return {
      dialog: {
        confirm: false
      },
      validating: false,
      refreshing: false,
      loading: false,
      refreshInterval: null,
      expInterval: null,
      checkInterval: null,
      refreshCountdown: 'N/A',
      expCountdown: 'N/A',
      tokenStatus: null
    };
  },
  computed: {
    ...mapGetters('admin/zoom', ['zoomInfo', 'zoomPlan']),
    accessToken() {
      return this.zoomInfo.accessToken || 'N/A';
    },
    tokenExpiration() {
      const expDate = this.zoomInfo.expDate;
      if (expDate) {
        return moment(expDate).format('YYYY-MM-DD HH:mm A');
      }
      return 'N/A';
    },
    nextRefresh() {
      const nextRefresh = this.zoomInfo.nextRefresh;
      if (nextRefresh) {
        return moment(nextRefresh).format('YYYY-MM-DD HH:mm A');
      }
      return 'N/A';
    },
    isTokenValid() {
      return _.get(this.tokenStatus, 'data.isValid', false);
    },
    planBase() {
      return _.get(this.zoomPlan, 'plan_base', {});
    },
    plansWebinar() {
      return _.get(this.zoomPlan, 'plan_webinar', []);
    }
  },
  watch: {
    async zoomInfo(value) {
      // clear interval first
      this.destroyCountdownRefresh();
      this.destroyCountdownExp();

      // try to init the countdown
      const nextRefresh = _.get(value, 'nextRefresh', null);
      const expDate = _.get(value, 'expDate', null);

      this.createCountdownNextRefresh({ nextRefresh });
      this.createCountdownExp({ expDate });
    },
    refreshCountdown(value) {
      // if refresh countdown is not available, start fetch token interval
      if (value === '00:00' || value === 'N/A') {
        console.log('start check token');
        this.startCheckToken();
      }
    }
  },
  created() {
    // Get latest OAuth token in redis
    this.init();
  },
  methods: {
    ...mapActions('admin/zoom', [
      'getZoomInfo',
      'testZoomToken',
      'refreshZoomToken',
      'getZoomPlan'
    ]),
    async init() {
      try {
        this.loading = true;
        await this.getZoomInfo();
        await this.getZoomPlan();
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
        await this.verifyToken();
      }
    },
    createCountdownNextRefresh({ nextRefresh }) {
      if (!nextRefresh) {
        return;
      }
      const diff = moment(nextRefresh).diff(moment());

      if (diff <= 0) {
        this.stopCheckToken();
        return;
      }

      const self = this;
      const startTime = moment.utc(diff);
      self.refreshCountdown = startTime.format('mm:ss');

      // Create a new countdown timer
      this.refreshInterval = setInterval(() => {
        // If timer reaches '00:00', clear interval
        if (self.refreshCountdown === '00:00') {
          self.destroyCountdownRefresh();
        }
        // Otherwise, countdown by 1 second
        else {
          self.refreshCountdown = startTime
            .subtract(1, 'seconds')
            .format('mm:ss');
        }
      }, 1000);
    },
    createCountdownExp({ expDate }) {
      if (!expDate) {
        return;
      }
      const diff = moment(expDate).diff(moment());

      if (diff <= 0) {
        return;
      }

      const self = this;
      const startTime = moment.utc(diff);
      self.expCountdown = startTime.format('mm:ss');

      // Create a new countdown timer
      this.expInterval = setInterval(() => {
        // If timer reaches '00:00', clear interval and set question to inactive
        if (self.expCountdown === '00:00') {
          self.destroyCountdownExp();
        }
        // Otherwise, countdown by 1 second
        else {
          self.expCountdown = startTime.subtract(1, 'seconds').format('mm:ss');
        }
      }, 1000);
    },
    destroyCountdownRefresh() {
      // Clear out interval and reset variables
      this.refreshCountdown = 'N/A';
      clearInterval(this.refreshInterval);
    },
    destroyCountdownExp() {
      // Clear out interval and reset variables
      this.expCountdown = 'N/A';
      clearInterval(this.expInterval);
    },
    async verifyToken() {
      try {
        this.validating = true;
        this.tokenStatus = await this.testZoomToken();
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.validating = false;
      }
    },
    async refreshToken() {
      try {
        this.refreshing = true;
        await this.refreshZoomToken();
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.dialog.confirm = false;
        this.refreshing = false;
        await this.getZoomInfo();
        await this.verifyToken();
      }
    },
    startCheckToken() {
      const self = this;

      this.checkInterval = setInterval(async function() {
        await self.getZoomInfo();
      }, 45000);
    },
    stopCheckToken() {
      clearInterval(this.checkInterval);
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
