<template>
  <v-container>
    <v-layout v-if="isMeeting">
      <v-flex xs12>
        <v-alert type="info" :value="true" outline class="mb-3">
          Post-meeting recordings and reports will be available to download one
          business day after the meeting.
        </v-alert>

        <v-toolbar dense color="grey lighten-4 elevation-1">
          <v-spacer />
          <v-btn
            v-if="isAdmin"
            color="primary"
            @click.native="dialog.notification = true"
          >
            Send Notification
          </v-btn>
          <v-btn icon :loading="loading" @click.native="init">
            <v-icon>cached</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="meetingFiles"
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          :loading="loading"
          class="elevation-1"
        >
          <template slot="progress">
            <v-progress-linear
              height="2"
              color="blue"
              indeterminate
            ></v-progress-linear>
          </template>
          <template v-slot:no-data>
            No files available.
          </template>
          <template slot="items" slot-scope="props">
            <td>
              <v-tooltip right>
                <span slot="activator">
                  {{
                    props.item.lastModified
                      | dateFormatReadable(true, currentMeeting.meetingTimezone)
                  }}
                </span>
                <span>Uploaded by {{ props.item.uploadedBy }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <span slot="activator">
                  <v-icon
                    small
                    class="mr-1"
                    @click="handleDownload(props.item)"
                  >
                    get_app
                  </v-icon>
                  <a @click="handleDownload(props.item)">{{
                    props.item.name || props.item.Key
                  }}</a>
                </span>
                <span>Click to download</span>
              </v-tooltip>
            </td>
            <td class="text-xs-right">{{ props.item.size | fileSize }}</td>
            <td class="text-xs-right">
              <v-tooltip v-if="isAdmin" bottom>
                <span slot="activator">
                  {{ props.item.downloads ? props.item.downloads.length : 0 }}
                </span>
                <span>
                  <div
                    v-if="
                      props.item.downloads && props.item.downloads.length > 0
                    "
                  >
                    Recent:<br />
                    <ul>
                      <li
                        v-for="action in props.item.downloads"
                        :key="action.Key"
                      >
                        {{ action.data.downloadedBy }} on
                        {{ action.createdAt | dateFormatReadable }}
                      </li>
                    </ul>
                  </div>

                  <span v-else>No downloads</span>
                </span>
              </v-tooltip>

              <span v-else>
                {{ props.item.downloads ? props.item.downloads.length : 0 }}
              </span>
            </td>
            <td class="text-xs-right">
              <v-icon
                v-if="isAdmin"
                class="ml-2"
                :disabled="deleting"
                @click="deleteFile(props.item)"
              >
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-layout
      v-else
      align-center
      justify-center
      column
      fill-height
      class="empty-container"
    >
      <h2>This meeting does not support post-meeting recordings.</h2>

      <p class="mt-3">
        Recordings are only available for virtual meetings hosted by GetQuorum.
      </p>
    </v-layout>

    <v-layout class="mt-5" v-if="canUploadFiles && isMeeting">
      <v-flex xs12>
        <meeting-files-uploader
          :short-code="currentMeeting.shortCode"
          @update="init"
        />
      </v-flex>
    </v-layout>

    <!-- Dialogs -->
    <recordings-notification-dialog
      v-if="isAdmin"
      v-model="dialog.notification"
      :current-meeting="currentMeeting"
      @send="sendNotification"
      @close="dialog.notification = false"
    />
    <send-loading-dialog
      v-model="dialog.send"
      :notification-status="notificationStatus"
      @close="dialog.send = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import filesize from 'filesize';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatReadable, dateFromNow } from '@/filters';

import MeetingFilesUploader from './MeetingFilesUploader';
import SendLoadingDialog from './MeetingFilesViewSend';
import RecordingsNotificationDialog from '@/components/dialogs/RecordingsNotificationDialog';

import checkScope from '@/lib/check-user-scopes';

export default {
  name: 'MeetingFilesView',
  components: {
    MeetingFilesUploader,
    RecordingsNotificationDialog,
    SendLoadingDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  filters: {
    dateFormatReadable,
    dateFromNow,
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  data() {
    return {
      creating: false,
      loading: true,
      deleting: false,
      headers: [
        { text: 'Modified', value: 'lastModified' },
        { text: 'Name', value: 'name' },
        { text: 'Size', value: 'size', align: 'right' },
        {
          text: 'Downloads',
          value: 'downloads',
          align: 'right',
          sortable: false
        },
        { text: '', value: 'size', sortable: false, align: 'right' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'lastModified',
          descending: true
        }
      },
      dialog: {
        notification: false,
        send: false
      },
      notificationStatus: null
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('meetings/files', ['meetingFiles']),
    isMeeting() {
      return _.get(this.currentMeeting, 'options.type', '') === 'meeting';
    },
    canUploadFiles() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.files.create'
        )
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/files', [
      'fetchMeetingFiles',
      'trashMeetingFile',
      'sendFilesReadyNotification',
      'downloadMeetingFile'
    ]),
    async init() {
      try {
        this.loading = true;
        await this.fetchMeetingFiles({
          shortCode: this.currentMeeting.shortCode
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    async handleDownload({ Bucket, Key, name }) {
      try {
        await this.downloadMeetingFile({
          shortCode: this.currentMeeting.shortCode,
          Key,
          name
        });
        this.$events.$emit('toastEvent', 'Downloading File');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendNotification(emailBody) {
      try {
        this.dialog.notification = false;
        this.dialog.send = true;
        this.notificationStatus = 'sending';
        await this.sendFilesReadyNotification({
          shortCode: this.currentMeeting.shortCode,
          emailBody
        });
        this.notificationStatus = 'sent';
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.dialog.send = false;
        this.notificationStatus = null;
      }
    },
    async deleteFile({ Key, name }) {
      const isConfirm = confirm(`Delete ${name}? This cannot be undone.`);
      if (!isConfirm) {
        return;
      }
      try {
        this.deleting = true;
        await this.trashMeetingFile({
          shortCode: this.currentMeeting.shortCode,
          Key
        });
        this.$events.$emit('toastEvent', 'File deleted');
        await this.init();
        this.deleting = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.empty-container {
  height: 30vh;
}
</style>
