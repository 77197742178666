import http from './requestType';

/**
 * List products
 * @param  {Object}   [params]
 * @param  {String}   [params.category]       - category
 * @param  {String}   [params.search]         - search
 * @param  {Number}   [params.type]           - type
 * @return {Promise}                          - Promise
 */
export function listProducts({ category, type, search } = {}) {
  return http._get(`Products`, { category, type, search });
}

/**
 * List Chargebee products
 * @param  {Object}   [params]
 * @param  {String}   [params.currency]       - currency code filter
 * @return {Promise}                          - Promise
 */
export function listChargebeeProducts({ currency } = {}) {
  return http._get(`Products/chargebee`, { currency });
}

/**
 * Add a product
 * @param  {Object}   params
 * @param  {Object}   params.product          - product
 * @return {Promise}                          - Promise
 */
export function addProduct({ product }) {
  return http._post(`Products`, product);
}

/**
 * Update a product
 * @param  {Object}   params
 * @param  {Object}   params.product          - product
 * @param  {String}   params.productKey       - product key
 * @return {Promise}                          - Promise
 */
export function updateProduct({ product, productKey }) {
  return http._put(`Products/${productKey}`, product);
}

/**
 * Delete a product
 * @param  {Object}   params
 * @param  {String}   params.productKey       - product key
 * @return {Promise}                          - Promise
 */
export function deleteProduct({ productKey }) {
  return http._delete(`Products/${productKey}`);
}

/**
 * Get a product
 * @param  {Object}   params
 * @param  {String}   params.productKey       - product key
 * @return {Promise}                          - Promise
 */
export function getProduct({ productKey }) {
  return http._get(`Products/${productKey}`);
}
