<template>
  <v-dialog persistent max-width="500px" :value="value" @input="emitInput">
    <v-card>
      <v-card-text>
        <v-layout row>
          <v-flex>
            <div v-for="(value, key) in state" :key="key">
              <v-text-field :label="key" v-model="state[key]" />
            </div>
          </v-flex>
        </v-layout>

        <v-text v-if="error" class="accent--text">{{ error }}</v-text>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" flat @click.native="dialog.delete = true">
          <v-icon left>delete_forever</v-icon>Delete field
        </v-btn>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="primary" @click.native="emitUpdate">Save</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Delete Dialog -->
    <v-dialog v-model="dialog.delete" max-width="500px">
      <v-card>
        <v-card-title>
          <div class="title red--text text--darken-1">Delete custom field?</div>
        </v-card-title>
        <v-card-text>
          <v-alert outline color="error" icon="warning" :value="true">
            <strong>WARNING</strong>: You are about to delete this field. Once
            deleted you cannot undo this action.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.delete = false">close</v-btn>
          <v-btn class="error" @click="emitDelete">delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsEditCustomFieldDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unitSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    customField: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      state: {},
      dialog: {
        delete: false
      },
      error: ''
    };
  },
  watch: {
    value: function() {
      this.init(this.customField);
    }
  },
  mounted() {
    this.init(this.customField);
  },
  methods: {
    init(value) {
      this.state = _.cloneDeep(value);
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    makePayload() {
      return {
        unit: this.unitSelected.unit,
        address: this.unitSelected.address,
        mailAddress1: this.unitSelected.mailAddress1,
        mailAddress2: this.unitSelected.mailAddress2,
        mailCity: this.unitSelected.mailCity,
        mailProv: this.unitSelected.mailProv,
        mailPostal: this.unitSelected.mailPostal,
        mailCountry: this.unitSelected.mailCountry,
        id: this.unitSelected.id,
        shortCode: this.unitSelected.shortCode,
        controlNumber: this.unitSelected.controlNumber,
        customData: this.unitSelected.customData
      };
    },
    emitDelete() {
      // get key
      let key = Object.keys(this.state)[0];
      let payload = this.makePayload();

      // delete and emit update
      this.$delete(payload.customData, key);
      this.$emit('update', {
        ...payload,
        actionLogTag: 'delete',
        targetLogTag: 'unit-tag'
      });

      // close all the items
      this.$emit('close');
      this.dialog.delete = false;
    },
    emitUpdate() {
      // get key
      let key = Object.keys(this.state)[0];
      let payload = this.makePayload();

      // Validation for field value
      // Check for escape character
      if (this.state[key].includes('\\')) {
        this.error = 'Custom field cannot contain the following characters: \\';
        return;
      }

      // update the payload
      payload.customData[key] = this.state[key];
      this.$emit('update', {
        ...payload,
        actionLogTag: 'update',
        targetLogTag: 'unit-tag'
      });

      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
