<template>
  <v-container class="py-0">
    <p class="subheading font-weight-bold pt-4" v-if="!hideSubheader">
      Name of the proxy:
    </p>

    <v-radio-group
      v-model="selectedProxyHolder"
      @change="emitSelectedProxyHolder(selectedProxyHolder)"
    >
      <v-radio
        v-for="(holder, index) in defaultHolders"
        :key="index"
        :label="defaultHolders[index]"
        :value="defaultHolders[index] === 'None' ? null : defaultHolders[index]"
      ></v-radio>
      <v-radio label="Other, please specify" value="other" />
    </v-radio-group>

    <v-slide-y-transition>
      <div v-if="selectedProxyHolder === 'other'">
        <v-text-field
          outline
          label="Enter their full name here"
          v-model="writeInProxyHolder"
          @input="emitWriteIn"
        />
        <caption class="otherHolder-caption">
          This should
          <b>not</b>
          be the proxy giver
        </caption>
      </div>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
export default {
  name: 'MeetingProxiesCreateProxyholder',
  props: {
    defaultHolders: {
      type: Array,
      default() {
        return [];
      }
    },
    proxyHolder: {
      type: String,
      default: ''
    },
    writeIn: {
      type: String,
      default: ''
    },
    hideSubheader: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      selectedProxyHolder: this.proxyHolder,
      writeInProxyHolder: this.writeIn
    };
  },
  methods: {
    emitSelectedProxyHolder(proxyHolder) {
      this.$emit('selectedProxyHolder', proxyHolder);
    },
    emitWriteIn(writeInProxyHolder) {
      this.$emit('selectedWriteIn', writeInProxyHolder);
    }
  }
};
</script>

<style scoped>
.otherHolder-caption {
  width: 100%;
  text-align: left;
  position: absolute;
  margin-top: -20px;
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
</style>
