<template>
  <invoice-list-table
    :invoice-list-data="invoiceListData"
    :accountKey="accountKey"
    :options="options"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InvoiceListTable from '@/components/InvoiceListTable';

export default {
  name: 'AdminAccountViewInvoiceTable',
  components: {
    InvoiceListTable
  },
  props: {
    accountKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: {
        hideViewedColumn: true
      }
    };
  },
  created() {
    this.setStatusFilter('all');
  },
  computed: {
    ...mapGetters('invoices', ['invoiceListData'])
  },
  methods: {
    ...mapActions('invoices', ['setStatusFilter'])
  }
};
</script>

<style scoped></style>
