<template>
  <span>
    <!-- START button, if question is inactive -->
    <v-btn
      data-v-step="voting-matter-start-btn"
      v-if="!isActive"
      color="primary"
      :class="{ restart: isEnded }"
      :disabled="isDisabled"
      @click="emitStart"
    >
      {{ isEnded ? 'restart' : 'start' }}
    </v-btn>
    <!-- STOP button, if question is active -->
    <v-btn v-else color="error" :disabled="isDisabled" @click="emitStop">
      {{ countdown }}
    </v-btn>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LiveVoteButton',
  props: {
    question: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.question.active;
    },
    timeLeft() {
      return this.question.timeLeft;
    },
    isEnded() {
      const endAt = this.question.endAt || this.question.end_at;
      if (!endAt) {
        return false;
      }
      // Give endAt a -5 second buffer
      const endedAtBuffered = moment(endAt).subtract(5, 's');
      // If endAt is after now, the question has ended
      return moment().isAfter(endedAtBuffered);
    }
  },
  watch: {
    isActive() {
      this.destroyCountdownTimer();
      if (this.question.active) {
        this.createCountdownTimer();
      }
    },
    timeLeft() {
      this.destroyCountdownTimer();
      if (this.question.active) {
        this.createCountdownTimer();
      }
    }
  },
  mounted() {
    if (this.isActive) {
      this.createCountdownTimer();
    }
  },
  beforeDestroy() {
    this.destroyCountdownTimer();
  },
  data() {
    return {
      startTime: null,
      interval: null,
      countdown: null
    };
  },
  methods: {
    createCountdownTimer() {
      // Gain access to the current context for setInterval
      const self = this;

      self.startTime = moment.utc(self.question.timeLeft);

      if (moment().isBefore(moment(self.question.endAt).subtract(1, 'hours'))) {
        self.countdown = moment(self.question.endAt).fromNow(true);
      } else {
        self.countdown = self.startTime.format('mm:ss');
      }

      // Create a new countdown timer
      this.interval = setInterval(() => {
        // If timer reaches '00:00', clear interval and set question to inactive
        if (self.countdown === '00:00') {
          self.destroyCountdownTimer();
          self.question.active = false;
          self.emitClosed();
        }
        // Otherwise, countdown by 1 second
        else {
          if (
            moment().isBefore(moment(self.question.endAt).subtract(1, 'hours'))
          ) {
            // Is deadline greater than 1 hours?
            // If so, show number of hours or days away the deadline is
            self.countdown = moment(self.question.endAt).fromNow(true);
            self.startTime = moment.utc(
              moment(self.question.endAt).diff(moment())
            );
          } else {
            // Otherwise, show countdown in minutes:seconds
            self.countdown = self.startTime
              .subtract(1, 'seconds')
              .format('mm:ss');
          }
        }
      }, 1000);
    },
    destroyCountdownTimer() {
      // Clear out interval and reset variables
      // when we close voting or time runs out
      this.startTime = null;
      this.countdown = null;
      clearInterval(this.interval);
    },
    emitStart() {
      this.$emit('start', this.question);
    },
    emitStop() {
      this.$emit('stop', this.question);
    },
    emitClosed() {
      this.$emit('closed');
    }
  }
};
</script>

<style scoped>
.restart {
  opacity: 0.5;
}
</style>
