<!-- 
  This component is a on-bill-106 specific form question, and is not applicable to all meetings
-->

<template>
  <v-container>
    <p class="subheading font-weight-bold">
      What can the proxy do:
    </p>

    <v-radio-group
      v-model="selectedOption"
      @change="emitSelectedOption(selectedOption)"
    >
      <v-radio value="box-1">
        <template v-slot:label>
          <div>
            <b>Box 1</b> - The proxy is not authorized to vote on my (our)
            behalf with respect to any matter at the meeting, including matters
            of routine procedure.
          </div>
        </template>
      </v-radio>

      <v-radio value="box-2">
        <template v-slot:label>
          <div>
            <b>Box 2</b> - The proxy may vote on my (our) behalf only with
            respect to matters of routine procedure at the meeting, and no other
            matters, as I (we) could do if personally present at the meeting.
          </div>
        </template>
      </v-radio>

      <v-radio value="box-3">
        <template v-slot:label>
          <div>
            <b>Box 3</b> - The proxy may nominate candidates or may vote on my
            (our) behalf with respect to all matters that may come before the
            meeting, subject to any instructions set out below, as I (we) could
            do if personally present at the meeting.
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </v-container>
</template>

<script>
export default {
  name: 'MeetingProxiesCreateBill106',
  props: {},
  data() {
    return {
      // Box 1,2,3 are conventions of the ontario proxy form for describing the 3 options
      // Box-3 in this case is the default (proxy votes according to instructions)
      selectedOption: 'box-3'
    };
  },
  methods: {
    emitSelectedOption(option) {
      this.$emit('selected', option);
    }
  }
};
</script>

<style scoped></style>
