<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <notice-stats-summary
          v-if="currentNotice.status === 'sent'"
          :stats="currentNotice.stats"
          :loading="currentNotice.statsLoading"
          :error="currentNotice.statsLoadError"
          :notice-key="noticeKey"
          :timezone="currentMeeting.meetingTimezone"
        />
        <v-alert
          :value="currentNotice.status === 'sent'"
          outline
          color="blue"
          icon="check_circle"
          >This notice has already been sent and can no longer be
          edited.</v-alert
        >
        <v-alert
          :value="currentNotice.status === 'scheduled'"
          outline
          color="deep-orange"
          icon="warning"
        >
          This notice has been scheduled for sendout:
          {{
            currentNotice.sendOn
              | formatDateHumanReadableAlt(true, meetingTimezone)
          }}<timezone-helper :date="currentNotice.sendOn" />
        </v-alert>

        <v-card class="mb-3">
          <v-card-text>
            <input-info
              :purpose="state.purpose"
              :subject="state.subject"
              :is-disabled="!isDraft"
              @update="handleUpdate"
            />
            <v-select
              v-if="isPrimaryNotice"
              v-model="defaultGroup"
              label="Notice Group"
              :items="currentNoticeGroupMemberships"
              item-text="groupName"
              :value="noticeTemplateGroupKey"
              @update="handleGroupUpdate"
            />
            <rich-text-editor
              v-if="bodyLoaded"
              ref="noticeEditor"
              :body="state.body"
              :editable="isDraft"
              :show-custom-fields="true"
              custom-label="Custom
            fields defined in settings"
              :custom-fields="customFields"
              @update="handleUpdate({ key: 'body', value: $event })"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />

            <v-tooltip top :disabled="!isChanged">
              <template slot="activator">
                <v-btn
                  flat
                  @click.native="getPreview('')"
                  :disabled="isChanged"
                >
                  <v-icon left>remove_red_eye</v-icon>Preview
                </v-btn>
              </template>

              <span>Save changes to preview</span>
            </v-tooltip>

            <v-btn
              flat
              @click.native="dialog.test = true"
              :disabled="isChanged"
            >
              <v-icon left>mail_outline</v-icon>Test
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mb-3">
          <v-card-text>
            <input-reply-to
              :contact-email="contactEmail"
              :notice-reply-to="noticeReplyTo"
              :meeting-reply-to="meetingReplyTo"
              :is-disabled="!isDraft"
              @updateOption="handleUpdateOption"
            />
          </v-card-text>
        </v-card>

        <v-card class="mb-3">
          <v-card-text>
            <meeting-notice-file-attachments
              :attachments="state.attachments"
              :meeting-documents="currentMeeting.documents"
              :options="state.options"
              :is-disabled="!isDraft"
              :combined-size-warning="combinedSizeWarning"
              @update="handleUpdate"
              @updateOption="handleUpdateOption"
              @updateSizeWarning="handleSizeWarning"
            />
          </v-card-text>
        </v-card>

        <v-card class="mb-3">
          <v-card-text>
            <input-segment
              v-if="recipientsLoaded"
              :segment="state.recipients.segment"
              :emails="state.recipients.emails"
              :range="state.recipients.range"
              :custom-data="state.recipients.customData"
              :custom-data-keys="customDataKeys"
              :recipient-list-data="recipientsListData"
              :is-disabled="!isDraft"
              :is-changed="isChanged"
              :is-urgent="isUrgent"
              :is-multi-consent="isMultiConsent"
              @view-recipients="dialog.recipientList = true"
              @update="handleUpdate"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              v-if="missingEmailDataList.length > 0"
              @click="dialog.invalidEmails = true"
              flat
              outline
              color="deep-orange"
            >
              <v-icon color="deep-orange" left>warning</v-icon>
              View {{ missingEmailDataList.length }} emails not found
            </v-btn>
            <v-spacer />
            <v-btn
              flat
              :disabled="!isDraft || isChanged"
              @click.native="dialog.recipientList = true"
            >
              <v-icon left>group</v-icon>
              {{ viewRecipientsButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mb-3">
          <v-card-text>
            <input-send-date
              :send-on="state.sendOn"
              :meeting-timezone="meetingTimezone"
              :is-disabled="!isDraft"
              @update="handleUpdate"
            />
          </v-card-text>
        </v-card>

        <v-layout row>
          <v-btn
            color="pink"
            flat
            @click.native="deleteNotice"
            :disabled="!isDraft"
          >
            <v-icon>delete_forever</v-icon>Delete Notice
          </v-btn>
          <v-spacer />
          <div>
            <v-btn v-if="isDraft && isChanged" @click.native="resetChanges">
              <v-icon left>restart_alt</v-icon>Discard changes
            </v-btn>
            <v-btn
              class="error"
              @click.native="updateNotice"
              :disabled="!isChanged || !isDraft || combinedSizeWarning"
            >
              <v-icon left>save</v-icon>Save
            </v-btn>

            <!-- Swap components for schedules -->
            <v-btn
              color="deep-orange"
              class="white--text"
              @click.native="
                isScheduled
                  ? (dialog.schedule = true)
                  : handleAction('schedule')
              "
              :disabled="
                isChanged ||
                  !currentNotice.sendOn ||
                  (!isDraft && !isScheduled) ||
                  combinedSizeWarning
              "
            >
              <span v-if="!isScheduled">
                <v-icon left>timer</v-icon>Schedule Email
              </span>
              <span v-if="isScheduled">
                <v-icon left>timer_off</v-icon>Unschedule Email
              </span>
            </v-btn>

            <v-btn
              class="primary"
              @click.native="handleAction('send')"
              :disabled="isChanged || !isDraft || combinedSizeWarning"
            >
              <v-icon left>send</v-icon>Send Email
            </v-btn>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- DIALOG Components -->
    <preview-dialog
      v-model="dialog.preview"
      :subject="meetingNoticePreview.subject"
      :html="meetingNoticePreview.content"
      :attachments="meetingNoticePreview.attachments"
      :recipientCount="recipientCount"
      :recipientTotal="recipientsListData.length"
      @previewPrev="getPreview('prev')"
      @previewNext="getPreview('next')"
      @downloadFile="downloadFile"
      @close="dialog.preview = false"
    />

    <send-test-dialog
      v-if="optionsLoaded"
      v-model="dialog.test"
      :current-meeting="currentMeeting"
      :return-address-obj="returnAddressObj"
      :attachments="currentNotice.attachments"
      :include-unit-files="currentNotice.options.includeUnitFiles"
      :test-voters="testVotersListData"
      @send="sendTestNotice"
      @close="dialog.test = false"
    />
    <recipient-list-dialog
      v-model="dialog.recipientList"
      :recipients="recipientsListData"
      @close="dialog.recipientList = false"
    />

    <schedule-dialog
      v-model="dialog.schedule"
      :state="currentNotice"
      :notice-status="noticeStatus"
      :send-count="recipientsListData.length"
      :is-primary-notice="isPrimaryNotice"
      :meeting-timezone="meetingTimezone"
      @scheduleNotice="scheduleNotice"
      @unscheduleNotice="unscheduleNotice"
      @return="returnToNotices"
      @autoScheduler="launchAutoScheduler"
      @close="resetStatus"
    />

    <send-confirm-dialog
      v-model="dialog.send"
      :state="currentNotice"
      :notice-status="noticeStatus"
      :send-count="recipientsListData.length"
      :is-primary-notice="isPrimaryNotice"
      @send="sendNotice"
      @return="returnToNotices"
      @autoScheduler="launchAutoScheduler"
      @close="dialog.send = false"
    />

    <invalid-email-list-dialog
      v-model="dialog.invalidEmails"
      :invalidEmailList="missingEmailDataList"
      @close="dialog.invalidEmails = false"
      @clearInvalid="clearInvalidEmails"
    />

    <confirm-dialog
      :dialog="dialog.reminder"
      title="Reminder for attaching files"
      text="There are no files attached. Would you like to proceed without any attachments?"
      @confirm="handleConfirm"
    />

    <confirm-dialog
      :dialog="dialog.confirmChanges"
      title="Leave page?"
      text="There are unsaved changes. Click save to confirm changes"
      confirmText="Save"
      additionalText="Discard changes"
      @confirm="handleConfirmChanges"
      @extra="redirectControl.nextAction()"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import InputInfo from '@/components/input/InputInfo';
import InputSegment from '@/components/input/InputSegment';
import InputReplyTo from '@/components/input/InputReplyTo';
import InputSendDate from '@/components/input/InputSendDate';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import NoticeStatsSummary from '@/components/NoticeStatsSummary';
import MeetingNoticeFileAttachments from './MeetingNoticeFileAttachments';

// Dialogs
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';
import SendTestDialog from './MeetingNoticeSendTestDialog';
import RecipientListDialog from '@/components/dialogs/RecipientListDialog';
import InvalidEmailListDialog from './MeetingNoticeInvalidEmailDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import SendConfirmDialog from './MeetingNoticeViewSend';
import ScheduleDialog from './MeetingNoticeViewSchedule';
import TimezoneHelper from '@/components/TimezoneHelper';

import * as filters from '@/filters';

export default {
  name: 'MeetingNoticeView',
  components: {
    InputInfo,
    InputSegment,
    InputReplyTo,
    InputSendDate,
    RichTextEditor,
    PreviewDialog,
    SendTestDialog,
    RecipientListDialog,
    SendConfirmDialog,
    ScheduleDialog,
    NoticeStatsSummary,
    MeetingNoticeFileAttachments,
    TimezoneHelper,
    InvalidEmailListDialog,
    ConfirmDialog
  },
  filters: {
    ...filters
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    noticeList: {
      type: Object,
      required: true
    },
    noticeTemplateList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      noticeKey: this.$route.params.noticeKey,
      state: {},
      noticeStatus: null,
      dialog: {
        preview: false,
        test: false,
        recipientList: false,
        schedule: false,
        send: false,
        autoScheduler: false,
        invalidEmails: false,
        reminder: false,
        confirmChanges: false
      },
      action: null,
      recipientCount: 0,
      isPrimaryNotice: false,
      noticeTemplateGroupKey: '',
      defaultGroup: '',
      customDataKeys: [],
      redirectControl: {
        redirect: false,
        nextAction: ''
      },
      combinedSizeWarning: false
    };
  },
  computed: {
    ...mapGetters('meetings/notices', [
      'recipientsListData',
      'rawRecipientsListData',
      'noticeGroupByTemplate',
      'testVotersListData',
      'meetingNoticePreview'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters('meetings/units', ['meetingUnitsList']),
    ...mapGetters('meetings/checklists', ['meetingChecklists']),
    currentAddress() {
      let account = this.accountByKey(this.currentMeeting.accountKey);

      return account.address;
    },
    returnAddressObj() {
      let account = this.accountByKey(this.currentMeeting.accountKey);

      const retAddr = _.isEmpty(account.returnAddress.address)
        ? account.address
        : account.returnAddress;

      return retAddr;
    },
    currentNotice() {
      return this.noticeList[this.noticeKey] || {};
    },
    currentNoticeGroupMemberships() {
      return this.noticeGroupByTemplate;
    },
    recipients() {
      return this.currentNotice.recipients || {};
    },
    contactEmail() {
      return _.has(this.currentMeeting, 'contact.email')
        ? this.currentMeeting.contact.email
        : null;
    },
    meetingReplyTo() {
      return _.has(this.currentMeeting.options, 'replyToEmail')
        ? this.currentMeeting.options.replyToEmail
        : null;
    },
    noticeReplyTo() {
      return _.has(this.state, 'options.replyTo')
        ? this.state.options.replyTo
        : null;
    },
    isUrgent() {
      return _.has(this.state, 'options.urgent')
        ? this.state.options.urgent
        : null;
    },
    isDraft() {
      return this.currentNotice.status === 'draft' ? true : false;
    },
    isScheduled() {
      return this.currentNotice.status === 'scheduled' ? true : false;
    },
    isChanged() {
      return !_.isEqual(this.state, this.currentNotice);
    },
    isSent() {
      return this.state.status === 'sent';
    },
    recipientsLoaded() {
      return _.has(this.state, 'recipients');
    },
    optionsLoaded() {
      return _.has(this.state, 'options');
    },
    bodyLoaded() {
      return _.has(this.state, 'body');
    },
    viewRecipientsButtonText() {
      return this.isChanged
        ? 'Save to view Recipient List'
        : `View Recipients (${this.recipientsListData.length || 'N/A'})`;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    customFields() {
      const customUnitFields =
        this.currentMeeting.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    },
    missingEmailDataList() {
      if (this.rawRecipientsListData && this.recipientsListData) {
        // extract email for comparison
        const cleanEmails = this.recipientsListData.map(unit => unit.email);
        return this.rawRecipientsListData.filter(recipient => {
          return !cleanEmails.includes(recipient);
        });
      }
      return [];
    },
    hasStats() {
      return !_.isEmpty(this.currentNotice.stats);
    },
    isMultiConsent() {
      return this.currentMeeting?.options?.consentFormat === 'multi-consent';
    }
  },
  watch: {
    // Re-run init() and dynamically generate document title
    currentNotice: {
      immediate: true,
      handler(notice) {
        this.init();
        if (notice) {
          document.title = `${notice.purpose} - ${this.currentMeeting.name}`;
        }
      }
    }
  },
  created() {
    this.init();
  },
  beforeRouteLeave(_, __, next) {
    if (this.isChanged && this.currentNotice.status === 'draft') {
      this.dialog.confirmChanges = true;
      // save the next action in an state
      this.redirectControl = { redirect: true, nextAction: next };
      return;
    }
    // otherwise if not changes, just continue
    next();
  },
  methods: {
    ...mapActions('meetings/notices', [
      'getRecipientList',
      'getTestVotersList',
      'getMeetingsNoticePreview',
      'updateMeetingNotice',
      'deleteMeetingsNotice',
      'sendMeetingNoticeTest',
      'scheduleMeetingNotice',
      'unscheduleMeetingNotice',
      'sendMeetingNotice',
      'getNoticeGroupByTemplate',
      'getMeetingNoticeStats'
    ]),
    ...mapActions('files', ['getFile']),
    async init() {
      this.setState();

      // On initial mount of the component, the current notice won't be
      // populated in Vuex yet. Defer this to after.
      if (this.currentNotice.recipients) {
        this.getRecipientList({
          shortCode: this.shortCode,
          recipients: this.currentNotice.recipients,
          urgent: this.isUrgent
        });
      }

      // Determine if this is a primary notice and requires the option to schedule reminders
      if (!_.some(this.noticeTemplateList, _.isEmpty)) {
        const thisTemplate = _.find(this.noticeTemplateList, {
          noticeTemplateKey: this.currentNotice.noticeTemplateKey
        });

        this.isPrimaryNotice = _.has(thisTemplate, 'primaryNotice')
          ? thisTemplate.primaryNotice
          : false;
      }

      // Retrieve the possible group memberships that this notice could belong to
      // For later iterations, we could save any partial notices with their temporarily designated group prior to sends/scheduled
      if (this.currentNotice.noticeTemplateKey) {
        await this.getNoticeGroupByTemplate({
          noticeTemplateKey: this.currentNotice.noticeTemplateKey
        });
      }

      // Given that notice group list has more than one item, pre-select the option
      // For later iterations, allow for re-assigning to "Does not belong to a group"
      if (this.noticeGroupByTemplate && this.noticeGroupByTemplate.length > 0) {
        this.defaultGroup = this.noticeGroupByTemplate[0]; // Auto-select the first option
        this.noticeTemplateGroupKey = this.defaultGroup.noticeTemplateGroupKey;
      }

      // Make separate request For test-voters
      this.getTestVotersList({
        shortCode: this.shortCode,
        recipients: { segment: 'test-voters' }
      });

      if (
        this.currentMeeting.options.customUnitFields &&
        this.currentMeeting.options.customUnitFields.length > 0
      ) {
        this.customDataKeys = [...this.currentMeeting.options.customUnitFields];
      }

      // If the notice has been sent and no stats are available yet, go
      // and get the stats. Otherwise there are already stats, we can
      // skip getting stats.
      if (this.isSent && !this.hasStats) {
        this.getMeetingNoticeStats({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey
        });
      }
    },
    getPreview(direction) {
      try {
        let total = this.recipientsListData.length;

        // Determine the paging direction for voters
        if (direction === 'prev' && !this.recipientCount == 0) {
          this.recipientCount--;
        } else if (direction === 'next' && total - this.recipientCount > 1) {
          this.recipientCount++;
        } else {
          // This handles two cases, first open and cycling past the last record
          this.recipientCount = 0;
        }

        // Determine whether a voterKey is available
        let voterKey = '';
        if (!total == 0) {
          voterKey = this.recipientsListData[this.recipientCount].voterKey;
        }

        this.getMeetingsNoticePreview({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey,
          voterKey: voterKey
        });
        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateNotice() {
      try {
        await this.discardCustomSegmentChanges();
        await this.updateMeetingNotice({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey,
          notice: this.state
        });
        this.$events.$emit('toastEvent', 'Notice Updated');

        if (this.redirectControl.redirect) {
          this.redirectControl.nextAction();
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendTestNotice(payload) {
      try {
        await this.sendMeetingNoticeTest({
          payload,
          shortCode: this.shortCode,
          noticeKey: this.noticeKey
        });
        this.$events.$emit('toastEvent', 'Test Notices Sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.test = false;
      }
    },
    async sendNotice() {
      try {
        this.noticeStatus = 'sending';
        await this.sendMeetingNotice({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey
        });
        this.noticeStatus = 'sent';
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.noticeStatus = null;
      }
    },
    async deleteNotice() {
      const isConfirm = confirm(
        'Do you want to permanently delete this notice?\n\nYou will not be able to undo it once deleted.'
      );

      if (!isConfirm) {
        return;
      }

      try {
        this.deleteMeetingsNotice({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey
        });
        this.$events.$emit('toastEvent', 'Notice Deleted');
      } catch (err) {
        return this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.$router.push({
          name: 'meetingNotices',
          params: {
            shortcode: this.shortCode
          }
        });
      }
    },
    async scheduleNotice() {
      this.noticeStatus = 'scheduling';
      await this.scheduleMeetingNotice({
        shortCode: this.shortCode,
        noticeKey: this.noticeKey
      })
        .then(res => {
          this.$events.$emit('toastEvent', 'Notice Scheduled');

          // Once scheduled, return them to the notice list and prompt the auto-scheduler
          this.noticeStatus = 'justScheduled';
        })
        .catch(error => {
          let errObj = {
            status: error.response.status,
            msg: error.response.data
          };
          return this.$events.$emit(
            'toastEvent',
            errObj.status + ' Error: ' + errObj.msg
          );
        });
    },
    async unscheduleNotice() {
      try {
        this.noticeStatus = 'unscheduling';
        this.unscheduleMeetingNotice({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey
        });
        this.$events.$emit('toastEvent', 'Notice Unscheduled');
        this.noticeStatus = 'unscheduled';
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.schedule = false;
      }
    },
    returnToNotices() {
      this.$router.push({
        name: 'meetingNotices',
        params: {
          shortcode: this.$route.params.shortcode
        }
      });
    },
    async discardChange(segment, fieldKey) {
      // Discards a segment's changes IF user saves the notice with a different segment
      // i.e discard "custom email" segment changes if user saves on "everyone" segment
      if (
        this.state.recipients.segment !== segment &&
        !_.isEqual(
          this.state.recipients[fieldKey],
          this.currentNotice.recipients[fieldKey]
        )
      ) {
        this.state.recipients[fieldKey] = this.currentNotice.recipients[
          fieldKey
        ];
      }
    },
    async discardCustomSegmentChanges() {
      // Discards changes for all custom segments
      this.discardChange('custom-emails', 'emails');
      this.discardChange('custom-email-range', 'range');
      this.discardChange('custom-data', 'customData');
    },
    // When the admin chooses to schedule reminders along with the notice scheduled/sent
    // The Notice List page will require the following
    // 1) The notice group key this notice belongs to
    // 2) The estimated start date from which to generate reminder dates
    // 3) The estimated end date from which to generate reminder dates
    launchAutoScheduler() {
      // The start date will always be the day in which it is sent or scheduled
      let noticeStartDate = !this.state.sendOn
        ? this.state.sentAt
        : this.state.sendOn;

      // Determine the appropriate suggested end date (default: the meeting date)
      let noticeEndDate = this.currentMeeting.meetingDate;

      // When a notice group list is available, use the buffer to determine the end date
      if (
        this.currentNoticeGroupMemberships &&
        this.currentNoticeGroupMemberships.length
      ) {
        const noticeGroupSelected = _.filter(
          this.currentNoticeGroupMemberships,
          { noticeTemplateGroupKey: this.noticeTemplateGroupKey }
        );

        // Identify the group selected
        const groupName = noticeGroupSelected[0].groupName;

        // Given that checklists exist, determine the end date from the next stage's due date
        if (this.meetingChecklists) {
          let stage;
          if (groupName === 'Pre-Consent Group') {
            stage = 'pre-notice';
          } else if (groupName === 'Pre-Notice Group') {
            stage = 'service';
          }

          // Only search the checklist for Pre-Consents and Pre-Notices
          // For Notice of Service, the end date is already defaulted to the meeting date
          if (stage) {
            for (let cl in this.meetingChecklists) {
              let meetingChecklist = this.meetingChecklists[cl];
              if (meetingChecklist.stage === stage) {
                noticeEndDate = meetingChecklist.dueAt;
                break;
              }
            }
          }
        }
      }

      // Return to the Notice List, trigger the auto-scheduler dialog
      this.$router.push({
        name: 'meetingNotices',
        params: {
          shortcode: this.$route.params.shortcode,
          noticeTemplateGroupKey: this.noticeTemplateGroupKey,
          noticeStartDate: noticeStartDate,
          noticeEndDate: noticeEndDate
        }
      });
    },
    clearInvalidEmails() {
      // Updates the recipients from InputSegment without invalid emails
      this.state.recipients.emails = this.state.recipients.emails.filter(
        email => !this.missingEmailDataList.includes(email)
      );
      this.updateNotice();
    },
    handleUpdate(obj) {
      const newState = { ...this.state };
      // Lodash search state object for matching key
      _.set(newState, obj.key, obj.value);
      this.$set(this, 'state', newState);
    },
    handleGroupUpdate(obj) {
      this.noticeTemplateGroupKey = obj.noticeTemplateGroupKey;
    },
    handleUpdateOption(obj) {
      if (_.has(this.state, `options.${obj.key}`)) {
        // If property already exists, only update that property
        // Otherwise you'll overwrite the entire object
        _.set(this.state.options, obj.key, obj.value);
      } else {
        // With new properties, $set ensures they are reactive
        this.$set(this.state.options, obj.key, obj.value);
      }

      // Remove replyTo property entirely if empty
      // requirement: cannot be blank
      if (this.state.options.replyTo === '') {
        this.$delete(this.state.options, 'replyTo');
      }
    },
    async setState() {
      // Clone deep required for creating a copy of sub-properties.
      // If not cloned, then changes to subprops will also mutate the original
      // object which causes unintended actions.
      this.state = await _.cloneDeep(this.currentNotice);
    },
    resetStatus() {
      this.dialog.schedule = false;
      this.noticeStatus = null;
    },
    // Download unit attached file
    async downloadFile(file) {
      try {
        let res = await this.getFile({
          bucket: file.Bucket,
          key: file.Key
        });
        window.open(res.data.url, '_blank');
      } catch (err) {
        throw err;
      }
    },
    handleAction(action = 'send') {
      const { attachmentReminder } = this.currentNotice;
      const { attachments } = this.state;

      if (attachments.length <= 0 && attachmentReminder) {
        this.dialog.reminder = true;
        this.action = action;
        return;
      }

      this.dialog[action] = true;
    },
    async handleConfirm(confirm) {
      if (!confirm) {
        this.dialog.reminder = false;
        this.action = null;
        return;
      }

      this.dialog[this.action] = true;
      this.dialog.reminder = false;
    },
    async handleConfirmChanges(confirm) {
      if (!confirm) {
        this.dialog.confirmChanges = false;
        return;
      }

      // otherwise save changes
      this.updateNotice();
    },
    resetChanges() {
      const currentNotice = _.cloneDeep(this.currentNotice);
      this.state = currentNotice;

      // reset the editor content by passing the original state
      this.$refs.noticeEditor.editor.setContent(currentNotice.body);
    },
    handleSizeWarning(value) {
      this.combinedSizeWarning = value;
    }
  }
};
</script>

<style scoped>
.used-editor-font {
  font-size: 12px;
  color: #757575;
}
</style>
