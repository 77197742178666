'use strict';

/**
 * @fileoverview Meeting Snapshot Vuex submodule.
 * Used to store state for the meeting's snapshot data
 */

import _ from 'lodash';

import {
  getSnapshots,
  postSnapshot,
  updateSnapshot,
  deleteSnapshot
} from '@/api/attendance-snapshots';

const state = {
  snapshots: []
};

const getters = {
  snapshotList: state => state.snapshots
};

const actions = {
  async fetchSnapshots({ commit }, shortCode) {
    const { data } = await getSnapshots(shortCode);

    commit('SET_SNAPSHOTS', data);
  },

  async insertSnapshot({ dispatch }, shortCode) {
    await postSnapshot(shortCode);

    dispatch('fetchSnapshots', shortCode);
  },

  async patchSnapshot({ dispatch, state }, { shortCode, key, label }) {
    await updateSnapshot({ shortCode, key, label });

    dispatch('fetchSnapshots', shortCode);
  },

  async removeSnapshot({ dispatch }, { shortCode, key }) {
    await deleteSnapshot(shortCode, key);

    dispatch('fetchSnapshots', shortCode);
  }
};

const mutations = {
  SET_SNAPSHOTS(state, value) {
    state.snapshots = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
