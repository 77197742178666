<template>
  <div>
    <div class="event-container">
      <v-timeline dense clipped>
        <v-timeline-item
          v-for="element in eventList"
          :key="element.id"
          :class="
            `${
              ['approve', 'comment', 'close'].includes(element.event)
                ? 'flex-column'
                : 'mb-3'
            }`
          "
          color="grey"
          icon-color="white"
          :icon="element.icon"
          :hide-dot="element.icon ? false : true"
          right
          fill-dot
          small
        >
          <v-layout align-center>
            <!-- event open -->
            <v-flex xs12 v-if="element.event === 'open'"
              ><span
                ><b>{{ element.createdBy }}</b> created this approval request on
                {{ element.createdAt | dateFormatReadable(true) }}</span
              ></v-flex
            >
            <!-- event add reivewer -->
            <v-flex xs12 v-else-if="element.event === 'add-reviewer'"
              ><span
                ><b>{{ element.createdBy }}</b> added
                <b>{{ element.data.reviewer }}</b> as a reviewer on
                {{ element.createdAt | dateFormatReadable(true) }}</span
              ></v-flex
            >
            <!-- event edit request -->
            <v-flex xs12 v-else-if="element.event === 'edit'"
              ><span
                ><b>{{ element.createdBy }}</b> added
                {{ element.data.action }} on
                {{ element.createdAt | dateFormatReadable(true) }}</span
              ></v-flex
            >

            <!-- event approve with comment -->
            <v-flex
              xs12
              v-else-if="element.event === 'approve' && element.comment"
            >
              <div class="approve-event-container">
                <div class="approve px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="green">thumb_up_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> approved this request on
                    {{ element.createdAt | dateFormatReadable(true) }}</span
                  >
                </div>
                <div class="approve-comment mt-2 mb-3">
                  <span>{{ element.comment }}</span>
                </div>
              </div></v-flex
            >
            <!-- event approve without comment-->
            <v-flex xs12 v-else-if="element.event === 'approve'">
              <div class="approve-event-container d-flex">
                <div class="approve px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="green">thumb_up_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> approved this request on
                    {{ element.createdAt | dateFormatReadable(true) }}</span
                  >
                </div>
              </div></v-flex
            >

            <!-- event close with comment -->
            <v-flex
              xs12
              v-else-if="element.event === 'close' && element.comment"
            >
              <div class="approve-event-container">
                <div class="close px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="red">do_not_disturb_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> closed this request on
                    {{ element.createdAt | dateFormatReadable(true) }}</span
                  >
                </div>
                <div class="approve-comment mt-2 mb-3">
                  <span>{{ element.comment }}</span>
                </div>
              </div></v-flex
            >
            <!-- event close without comment-->
            <v-flex xs12 v-else-if="element.event === 'close'">
              <div class="approve-event-container d-flex">
                <div class="close px-2">
                  <div class="mr-4 pr-2">
                    <v-icon color="red">do_not_disturb_alt</v-icon>
                  </div>
                  <span
                    ><b>{{ element.createdBy }}</b> closed this request on
                    {{ element.createdAt | dateFormatReadable(true) }}</span
                  >
                </div>
              </div></v-flex
            >

            <!-- event comment only -->
            <v-flex xs12 v-else>
              <div class="comment-event-container">
                <div class="comment px-3">
                  <span
                    ><b>{{ element.createdBy }}</b> commented on
                    {{ element.createdAt | dateFormatReadable(true) }}</span
                  >
                </div>
                <div class="comment pl-3 pb-3">
                  <span>{{ element.comment || 'na' }}</span>
                </div>
              </div></v-flex
            >
          </v-layout>
        </v-timeline-item>
      </v-timeline>
    </div>
    <v-layout justify-space-between>
      <v-flex xs12>
        <v-textarea
          v-model="comment"
          :disabled="isApproved || isClosed"
          solo
          no-resize
          hide-details
          name="input-7-1"
          placeholder="Post a comment about the Notice Details or request detail changes. The Approval Request owners will be notified of all comments."
          hint
        ></v-textarea
      ></v-flex>
    </v-layout>
    <v-layout align-center class="mt-3">
      <v-btn
        v-if="isAdmin && !isApproved && !isClosed"
        outline
        color="red"
        @click.native="onClose"
        >Close</v-btn
      ><v-spacer />
      <v-btn
        outline
        color="primary"
        :disabled="isDisabled"
        @click.native="onComment"
        >comment</v-btn
      >
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { dateFormatReadable, dateFromNow } from '@/filters';

const eventIcon = {
  'add-reviewer': 'person',
  open: 'article',
  edit: 'edit'
};

export default {
  name: 'ActivityFeed',
  props: {
    isApproved: {
      type: Boolean,
      default: false
    },
    isClosed: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({ comment: '' }),
  filters: {
    dateFormatReadable,
    dateFromNow
  },
  computed: {
    ...mapGetters(['login']),
    ...mapGetters(['isAdmin']),
    eventList() {
      return _.map(this.events, event => {
        return { ...event, icon: eventIcon[event.event] };
      }).reverse();
    },
    isDisabled() {
      return _.isEmpty(this.comment);
    }
  },
  methods: {
    onComment() {
      this.$emit('comment', { action: 'comment', comment: this.comment });
      this.comment = '';
    },
    onClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.flex-column {
  flex-direction: column !important;
}
.approve-event-container {
  background-color: #fafafa;
  border: 1px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}

.approve {
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #e6f0e4;
}

.close {
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #f0e4e4;
}

.approve-comment {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  white-space: pre-wrap;
}

.comment-event-container {
  background-color: #fafafa;
  border: 3px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}

.comment {
  min-height: 40px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}

.event-container {
  display: flex;
  flex-direction: column-reverse;
}
</style>
