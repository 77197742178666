<template>
  <div>
    <!-- File Upload Box -->
    <v-layout row>
      <v-flex xs12>
        <file-upload-form
          :attachment="attachments"
          :multiple-files="true"
          icon="attach_file"
          @upload="emitUpload"
        />
      </v-flex>
    </v-layout>

    <!-- Attach Meeting Documents Button -->
    <v-layout row>
      <v-flex xs12>
        <v-btn
          flat
          color="pink"
          :disabled="!isMeetingDocuments || isDisabled"
          @click="dialog = true"
        >
          <v-icon>add</v-icon>Attach Meeting Documents PDF
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Attach Unit Files -->
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-checkbox
          label="Include all unit specific files as attachments (eg. mail merge files)"
          :input-value="includeUnitFiles"
          :disabled="isDisabled"
          @change="emitOptionUpdate"
        />
      </v-flex>
      <v-flex xs12 md6>
        <v-alert :value="includeUnitFiles" type="info" outline>
          <ul>
            <li>
              Attachments will go in the following order: unit files -> notice
              attachments
            </li>
            <li>
              Check the unit file sizes! The max 10 MB message size limitation
              still applies.
            </li>
            <li>If there are no unit attachments then this will do nothing</li>
          </ul>
        </v-alert>
      </v-flex>
    </v-layout>

    <!-- Combined Size Warning (total size of all attachments > 10 MB) -->
    <v-layout v-if="combinedSizeWarning" row>
      <v-flex xs12>
        <v-alert small outline color="red" icon="warning" :value="true">
          Warning: total attachment size exceeds 10 MB
        </v-alert>
      </v-flex>
    </v-layout>

    <!-- Select Document(s) to Attach Dialog -->
    <attach-files-dialog
      :value="dialog"
      :attachments="attachments"
      :meeting-documents="meetingDocuments"
      attachment-type="notice"
      @update="setAttachments"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import FileUploadForm from '@/components/FileUploadForm';
import AttachFilesDialog from '@/components/dialogs/AttachFilesDialog';

export default {
  name: 'MeetingNoticeFileAttachments',
  props: {
    attachments: {
      type: Array,
      default() {
        return [];
      }
    },
    meetingDocuments: {
      type: Array,
      default() {
        return [];
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    combinedSizeWarning: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileUploadForm,
    AttachFilesDialog
  },
  watch: {
    attachments() {
      let combinedSize = 0;
      this.attachments.forEach(att => {
        combinedSize += parseInt(att.size);
      });

      if (combinedSize > 10000000 && this.attachments.length > 1) {
        this.$emit('updateSizeWarning', true);
      } else {
        this.$emit('updateSizeWarning', false);
      }
    }
  },
  data() {
    return {
      dialog: false,
      selected: []
    };
  },
  computed: {
    isMeetingDocuments() {
      return !_.isEmpty(this.meetingDocuments);
    },
    includeUnitFiles() {
      return _.get(this.options, 'includeUnitFiles', false);
    }
  },
  methods: {
    emitUpload(file) {
      this.$emit('update', { key: 'attachments', value: file });
    },
    setAttachments(attachments) {
      this.$emit('update', {
        key: 'attachments',
        value: attachments
      });
      this.dialog = false;
    },
    emitOptionUpdate(event) {
      this.$emit('updateOption', {
        key: 'includeUnitFiles',
        value: event
      });
    }
  }
};
</script>

<style scoped></style>
