<template>
  <v-flex xs12>
    <div class="gq-cell--section-title">{{ fullDate }}</div>
    <admin-notice-card
      v-for="(notice, index) in week"
      :key="`key-${index}`"
      :state="notice" />
  </v-flex>
</template>

<script>
import moment from 'moment';
import AdminNoticeCard from '@/components/AdminNoticeCard';
export default {
  name: 'AdminNoticeScheduleViewWeek',
  components: {
    AdminNoticeCard
  },
  props: {
    week: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    fullDate() {
      if (this.week.length > 0) {
        if (this.week[0].sortDate) {
          return moment(this.week[0].sortDate).format('dddd MMMM DD, YYYY');
        } else {
          return 'Unscheduled Draft';
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
