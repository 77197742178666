<template>
  <v-dialog
    :value="value"
    @keydown.esc="emitClose"
    persistent
    @input="emitInput"
  >
    <v-stepper v-model="currentStep" vertical>
      <v-layout class="close-btn">
        <v-spacer />
        <v-btn flat @click="emitClose"><v-icon>close</v-icon> Close</v-btn>
      </v-layout>
      <v-stepper-step :complete="currentStep > 1" step="1">
        <div>
          Which test voter will be used for the preview?
          <v-tooltip right>
            <v-icon class="body-1" small slot="activator">info</v-icon>
            <span
              >Determines test record used for merge fields and button link in
              preview email below</span
            >
          </v-tooltip>
        </div>
        <div class="body-1 grey--text">
          {{
            testVoter === null
              ? 'No test voter selected'
              : `${testVoter.name} (${testVoter.email})`
          }}
        </div>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-layout wrap>
          <v-chip label class="py-1" @click.native="setTestVoterPreview(null)">
            Default
            <v-icon v-if="testVoter === null" class="ml-1">check</v-icon>
          </v-chip>
          <div v-for="voter in testVoters" :key="voter.id">
            <v-chip
              label
              class="py-1"
              @click.native="setTestVoterPreview(voter)"
            >
              <div>
                <div>{{ voter.name }}</div>
                <div>
                  (<strong>{{ voter.email }}</strong
                  >)
                </div>
              </div>
              <div v-if="testVoter !== null" class="ml-1">
                <v-icon v-if="voter.id === testVoter.id">check</v-icon>
              </div>
            </v-chip>
          </div>
        </v-layout>

        <!-- HTML preview -->
        <div class="mt-3">
          <notice-template-preview
            :contact-name="currentMeeting.contact.name"
            :subject="meetingNoticePreview.subject"
            :content="meetingNoticePreview.content"
            :include-unit-files="includeUnitFiles"
            :notice-attachments="attachments"
            :unit-attachments="meetingNoticePreview.attachments"
          />
        </div>

        <v-btn color="primary" @click="currentStep++">Next</v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">
        Who are you sending this test email to?
        <div class="body-1 grey--text" v-if="isApprovalToggle !== null">
          {{
            isApprovalToggle
              ? 'To the client for approval'
              : 'To myself or someone else'
          }}
        </div>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-radio-group
          class="ma-0 pa-0"
          v-model="isApprovalToggle"
          @change="previewToggleReset"
        >
          <v-radio label="To the client for approval" :value="true" />
          <v-radio label="To myself or someone else" :value="false" />
        </v-radio-group>

        <v-flex xs12 md6 lg6>
          <v-combobox
            v-if="isApprovalToggle === true"
            :items="items"
            :value="templateType"
            label="What type of preview is it?"
            outline
            @input="setTemplateType"
          />
        </v-flex>

        <v-btn
          color="primary"
          :disabled="isPreviewStepDisabled"
          @click="currentStep++"
          >Next</v-btn
        >
        <v-btn @click="currentStep--" flat>Back</v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 3" step="3"
        >Confirm Send Details</v-stepper-step
      >

      <v-stepper-content step="3">
        <!-- Only show once isApprovalToggle is chosen -->
        <div v-if="isApprovalToggle !== null">
          <v-layout grid-list-md>
            <v-flex xs6 md6 lg6>
              <v-combobox
                v-model="toEmails"
                :items="emailDefaults"
                label="to:"
                multiple
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeTo(data.item)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs6 md6 lg6>
              <v-combobox
                v-model="ccEmails"
                :items="emailDefaults"
                label="cc:"
                multiple
                small-chips
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeCC(data.item)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-text-field
            :disabled="!isApprovalToggle"
            label="From name"
            v-model="fromName"
          />
          <v-text-field
            :disabled="!isApprovalToggle"
            label="Subject line"
            v-model="subject"
          />
        </div>

        <div v-if="templateType">
          <rich-text-editor
            v-if="emailMessage !== ''"
            :body="emailMessage"
            :key="`new-${templateType}`"
            :hideMergeMenu="true"
            @update="handleUpdate('emailMessage', $event)"
          />
        </div>

        <notice-template-preview
          :contact-name="currentMeeting.contact.name"
          :subject="meetingNoticePreview.subject"
          :content="meetingNoticePreview.content"
          :include-unit-files="includeUnitFiles"
          :notice-attachments="attachments"
          :unit-attachments="meetingNoticePreview.attachments"
        />

        <v-btn color="primary" @click="handleSend">
          <v-icon left>send</v-icon>Send
        </v-btn>
        <v-btn @click="currentStep--" flat>Back</v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { getRecipe } from '@/api/recipes';
import { getFormattedAddress } from '@/lib/testemail-helpers.js';
import defaults from '@/lib/default-emails.js';
import { mapActions, mapGetters } from 'vuex';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import _ from 'lodash';

import NoticeTemplatePreview from './MeetingNoticeTemplatePreview';

export default {
  name: 'MeetingNoticeSendTestDialog',
  components: {
    RichTextEditor,
    NoticeTemplatePreview
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    returnAddressObj: {
      type: Object,
      default() {
        return {};
      }
    },
    testVoters: {
      type: Array,
      default() {
        return [];
      }
    },
    attachments: {
      type: Array,
      default() {
        return [];
      }
    },
    includeUnitFiles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStep: 1,
      items: [
        'Pre-Consent',
        'Pre-Consent (Missing Owner Type)',
        'Pre-Consent (Returning)',
        'Pre-Consent (Returning) (Missing Owner Type)',
        'Pre-Notice',
        'Pre-Notice with Candidate Nomination',
        'Notice of Service',
        'Notice of Service (Virtual)',
        'Budget',
        'PIC Distribution',
        'ICU Distribution',
        'Rule Distribution',
        'Pre-Notice (No Printing)',
        'Pre-Notice with Candidate Nomination (No Printing)',
        'Notice of Service (No Printing)',
        'Notice of Service (Virtual) (No Printing)',
        'Budget (No Printing)',
        'PIC Distribution (No Printing)',
        'ICU Distribution (No Printing)',
        'Rule Distribution (No Printing)'
      ],
      templateType: '',
      emailDefaults: defaults,
      toEmails: [],
      ccEmails: [],
      subject: '',
      fromName: '',
      isApprovalToggle: null,
      testVoter: null,
      emailMessage: '',
      approvalTemplates: null
    };
  },
  watch: {
    currentStep: function(value) {
      if (value === 3) {
        this.setApprovalToggle(this.isApprovalToggle);
      }
    },
    // Check when the dialog gets opened
    value: function(value) {
      if (value === true) {
        this.setTestVoterPreview(this.testVoter);
      }
    }
  },
  computed: {
    ...mapGetters('meetings/notices', ['meetingNoticePreview']),
    isPreviewStepDisabled() {
      if (this.isApprovalToggle === true && !this.templateType) {
        return true;
      } else if (this.isApprovalToggle === null) {
        return true;
      } else if (this.isApprovalToggle === false) {
        return false;
      }
    }
  },
  mounted() {
    // Get profile token
    const profile = JSON.parse(sessionStorage.getItem('profile'));

    // Get all approval templates
    this.getApprovalTemplates();

    // Push contact and profile email
    this.emailDefaults.unshift(this.currentMeeting.contact.email);
    this.emailDefaults.unshift(profile.email);
  },
  methods: {
    ...mapActions('meetings/units', ['getVoterLinks']),
    ...mapActions('meetings/notices', ['getMeetingsNoticePreview']),
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    removeTo(item) {
      this.toEmails.splice(this.toEmails.indexOf(item), 1);
      this.toEmails = [...this.toEmails];
    },
    removeCC(item) {
      this.ccEmails.splice(this.ccEmails.indexOf(item), 1);
      this.ccEmails = [...this.ccEmails];
    },
    handleUpdate(key, value) {
      this.$set(this, key, value);
    },
    previewToggleReset() {
      this.templateType = null;
    },
    setTemplateType(type) {
      // Set type and reset options
      this.templateType = type;
      this.setEmailMessage(type);
    },
    setApprovalToggle(isApproval) {
      const profile = JSON.parse(sessionStorage.getItem('profile'));

      // Set default to and cc based on previewToggle
      if (isApproval) {
        this.toEmails = [this.currentMeeting.contact.email];
        this.ccEmails = ['support@getquorum.com', 'cs.audit@getquorum.com'];
      } else {
        this.toEmails = [profile.email];
        this.ccEmails = [];
      }

      // Set subject line based on preview
      this.subject = isApproval
        ? `GetQuorum - Approval for ${this.templateType} (${this.currentMeeting.corporation.legalNameShort})`
        : `[TEST] ${this.meetingNoticePreview.subject}`;

      // If it's a preview email, check if profile name is valid
      if (isApproval) {
        let firstName = _.get(profile, 'firstName', '');
        let lastName = _.get(profile, 'lastName', '');
        this.fromName = `${firstName} ${lastName}, GetQuorum`;
      } else {
        // If not a preview email, then the from field is the contactName (property manager)
        this.fromName = this.currentMeeting.contact.name;
      }
    },
    async setTestVoterPreview(voter) {
      // Set the test voter value
      this.testVoter = voter;
      await this.getMeetingsNoticePreview({
        shortCode: this.$route.params.shortcode,
        noticeKey: this.$route.params.noticeKey,
        voterKey: voter ? voter.voterKey : ''
      });
    },
    handleSend() {
      // Check if we need to be sending it as an approval email
      const approvals = this.isApprovalToggle
        ? {
            type: this.templateType,
            message: this.emailMessage,
            fromName: this.fromName,
            subject: this.subject
          }
        : undefined;

      /**
       * Emit payload to send to MeetingNoticeView
       * @prop {Array} {emails} - List of recipient emails
       * @prop {Array} {ccEmails} - The notice key to update
       * @prop {Object} {preview} - Preview Object containing:
       * type: If it's a preview
       * message: If message provided, return markdown version in email body
       * profile: Profile information of the account logged in
       * voterKey: Provide voterKey in preview email
       */
      this.$emit('send', {
        emails: this.toEmails,
        ccEmails: this.ccEmails,
        voterKey: this.testVoter ? this.testVoter.voterKey : undefined,
        approvals
      });

      // reset step and close dialog
      this.currentStep = 1;
      this.emitClose();
    },
    setEmailMessage(type) {
      if (!this.items.includes(type)) {
        this.templateType = '';
        return;
      }

      const noticesTemplates = this.getNoticeTemplatesByRegion();
      // Get email message based on approval template type
      let emailMessage = noticesTemplates[type];

      // Get First name for greeting
      const firstName = this.currentMeeting.contact.name.trim().split(' ')[0];
      const greetingText = `<p>Hi ${firstName},</p>`;
      const signature = noticesTemplates['signature'];

      // If returnaddress is included in emailMessage template
      if (emailMessage.includes('[returnaddress]')) {
        // Get the formatted return address
        let returnAddress = getFormattedAddress(
          this.currentMeeting.corporation.legalNameShort,
          this.returnAddressObj
        );

        // Insert return address
        emailMessage = emailMessage.replace(
          '[returnaddress]',
          `${returnAddress}`
        );
      }

      // Combine emailMessage
      this.emailMessage = greetingText + emailMessage + signature;
    },
    async getApprovalTemplates() {
      try {
        let res = await getRecipe('meetings.notices.approval_template');
        this.approvalTemplates = res.data.data;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    getNoticeTemplatesByRegion() {
      const region = this.currentMeeting.region;
      const defaultTemplates = this.approvalTemplates.default;

      // special case for ontario
      if (region === 'ON') {
        const ontarioTemplates = this.approvalTemplates.ON;
        return { ...defaultTemplates, ...ontarioTemplates };
      }

      return defaultTemplates;
    }
  }
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
</style>
