'use strict';

/**
 * @fileoverview Vuex module for a meeting's files
 */

import _ from 'lodash';
import Vue from 'vue';
import {
  getMeetingDocuments,
  postMeetingDocument,
  deleteMeetingDocument
} from '@/api/meetings';

const state = {
  documentsLoading: false,
  documents: [],
  documentsList: {},
  dataLastFetchedAt: null
};

const getters = {
  documents: state => state.documents,
  documentsLoading: state => state.documentsLoading,
  documentsByShortCode: state => shortCode => {
    // Check if documents exist
    if (!state.documentsList[shortCode]) {
      return [];
    }
    // Append document key
    return state.documentsList[shortCode].map(meetingDocuments => {
      return {
        ...meetingDocuments.document,
        documentKey: meetingDocuments.key
      };
    });
  }
};

const actions = {
  /**
   * Get the list of meeting documents
   * @param  {String} {shortCode}  meeting code
   */
  async fetchMeetingDocuments({ state, commit }, { shortCode }) {
    try {
      // Set loading state
      commit('SET_DOCUMENT_LOADING_STATE', true);
      const { data } = await getMeetingDocuments(shortCode);

      // SET_DOCUMENTS is used for the overview V2. Should be revised in the future.
      commit('SET_DOCUMENTS', { data });
      commit('SET_DOCUMENTS_DATA', { data, shortCode });

      commit('SET_DOCUMENT_LOADING_STATE', false);
    } catch (err) {
      console.error('Error: fetchMeetingDocuments action', err);
      throw err;
    }
  },

  /**
   * Add a document (i.e., package) to the meeting.
   * @param  {String} shortCode    - Meeting shortcode
   * @param  {Object} document     - Document to add
   */
  async addMeetingDocument({ dispatch }, { shortCode, document }) {
    try {
      await postMeetingDocument(shortCode, document);
      dispatch('fetchMeetingDocuments', { shortCode });
    } catch (err) {
      console.error('Error: addMeetingDocument action', err);
      throw err;
    }
  },

  /**
   * Remove a document (i.e., package) from the meeting.
   * @param  {String} shortCode    - Meeting shortcode
   * @param  {String} documentKey  - Document key, to remove
   */
  async removeMeetingDocument({ dispatch }, { shortCode, documentKey }) {
    try {
      await deleteMeetingDocument(shortCode, documentKey);
      dispatch('fetchMeetingDocuments', { shortCode });
    } catch (err) {
      console.error('Error: removeMeetingDocument action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_DOCUMENTS(state, { data }) {
    Vue.set(state, 'documents', data);
    state.dataLastFetchedAt = new Date();
  },

  SET_DOCUMENTS_DATA(state, { data, shortCode }) {
    Vue.set(state.documentsList, shortCode, data);
  },

  // Reset the state
  CLEAR_STATE(state) {
    Vue.set(state, 'documents', []);
    state.dataLastFetchedAt = null;
  },
  SET_DOCUMENT_LOADING_STATE(state, toggle) {
    Vue.set(state, 'documentsLoading', toggle);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
