<template>
  <v-layout row wrap class="mt-4">
    <v-flex xs12>
      <h5 class="headline font-weight-bold">Meeting Business</h5>
      <v-divider class="gq-divider " />
    </v-flex>
    <v-flex v-if="canViewEarly" xs12 class="h-flex">
      <v-btn color="primary" disabled>
        <v-icon left>add</v-icon>
        New Poll
      </v-btn>

      <v-btn outline color="primary" @click="toggleQuestions">
        <v-icon left>
          {{ hasExpandedQuestionCombined ? 'unfold_less' : 'unfold_more' }}
        </v-icon>
        {{ hasExpandedQuestionCombined ? 'Hide' : 'Show' }} All Results
      </v-btn>

      <div class="h-flex">
        <p
          v-if="options.displayVoteResultsToggle"
          class="mb-1 subheading units-text mr-2"
          :class="{ 'primary--text': !isShares }"
        >
          Units
        </p>
        <v-switch
          v-if="options.displayVoteResultsToggle"
          color="primary"
          class="justify-end shares-toggle"
          :class="{ 'primary--text': isShares }"
          v-model="isShares"
          label="Shares"
        />
      </div>
      <v-spacer />

      <v-tooltip top>
        <v-switch
          slot="activator"
          color="success"
          class="justify-end voting-toggle my-2"
          persistent-hint
          hint="Use the toggle to open live voting"
          :background-color="isVotingOpen ? 'primary' : 'grey'"
          v-model="openVotingSwitch"
          :label="openVotingText"
          :loading="dialogs.openVoting"
          @change="onOpenVotingSwitch"
        />
        <p class="my-0">
          Open voting and do not allow voters to revoke their proxies
        </p>
      </v-tooltip>
    </v-flex>

    <v-flex
      xs12
      v-if="questionsCombined.length <= 0"
      class="v-flex grey--text pa-4"
    >
      <v-icon size="78" color="grey">question_answer</v-icon>
      <h2 class="pt-2 mb-0 font-weight-light">
        No Business
      </h2>
    </v-flex>

    <v-layout v-if="canViewEarly" row wrap id="questionsContainer">
      <v-flex xs12 v-for="question in questionsCombined" :key="question.id">
        <meeting-business-card
          :question="question"
          :disable-vote-btn="!isVotingOpen"
          :metric="isShares ? 'shares' : 'units'"
          :can-view-early="canViewEarly"
          @refresh="init"
          @view-write-in-vote="viewWriteInVote"
        />
      </v-flex>
    </v-layout>

    <v-layout v-else row wrap>
      <v-flex xs12>
        <vote-tally
          :business="meetingBusinessData"
          :is-voting-close="isVotingClose"
          :enableVoteBreakdown="options.enableVoteBreakdown"
          :enableVoteBreakdownDate="options.enableVoteBreakdownDate"
        />
      </v-flex>
    </v-layout>

    <open-voting-toggle
      :value="dialogs.openVoting"
      @openVoting="toggleVoting({ isVotingOpen: true })"
      @cancel="cancelOpenVoting"
    />

    <write-in-vote-dialog
      v-model="dialogs.writeInVote"
      :proxy-list="meetingWriteInProxyListData"
      @revoke-proxy="revokeProxy"
      @close="dialogs.writeInVote = false"
    />
  </v-layout>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Sortable from 'sortablejs';

import MeetingBusinessCard from './MeetingBusinessCard';
import OpenVotingToggle from '@/components/dialogs/OpenVotingToggle';
import WriteInVoteDialog from '@/components/dialogs/WriteInVoteDialog';
import VoteTally from '@/components/VoteTally';

export default {
  name: 'MeetingBusiness',
  components: {
    MeetingBusinessCard,
    OpenVotingToggle,
    WriteInVoteDialog,
    VoteTally
  },
  props: {
    canViewEarly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogs: {
        openVoting: false,
        writeInVote: false
      },
      expand: false,
      openVotingSwitch: false,
      isShares: true,
      loading: true,
      metric: 'units',
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('v2/questions', [
      'questionsCombined',
      'hasExpandedQuestionCombined'
    ]),
    ...mapGetters('v2/meetings', ['meetingByShortCode']),
    ...mapGetters('meetings/proxies', ['meetingWriteInProxyListData']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),

    currentMeeting() {
      return this.meetingByShortCode(this.shortCode);
    },
    options() {
      return this.currentMeeting.options ? this.currentMeeting.options : {};
    },
    openVotingText() {
      return this.isVotingOpen
        ? 'Live voting is OPEN'
        : 'Live Voting is CLOSED';
    },
    allowsPortalRevocations() {
      return this.currentMeeting.options.allowPortalRevocations;
    },
    isVotingOpen() {
      const metaIsVotingOpen = this.currentMeeting?.meetingMeta
        ?.liveVotingToggleStatus;
      return metaIsVotingOpen ? metaIsVotingOpen : false;
    },
    isVotingClose() {
      return (
        moment(this.currentMeeting.expiryDate).isBefore() ||
        moment(this.currentMeeting.meetingDate).isBefore()
      );
    }
  },
  watch: {
    isVotingOpen(newIsVotingOpen) {
      this.openVotingSwitch = newIsVotingOpen;
    }
  },
  created() {
    this.init();
  },
  destroyed() {
    this.destroyPusher();
  },
  mounted() {
    const table = document.getElementById('questionsContainer');

    if (table) {
      const _self = this;

      Sortable.create(table, {
        handle: '.handle',
        animation: 150,
        onEnd: ({ newIndex, oldIndex }) => {
          const rowSelected = _self.questionsCombined.splice(oldIndex, 1)[0]; // Get the selected row and remove it
          _self.questionsCombined.splice(newIndex, 0, rowSelected); // Move it to the new index

          const arrayOrder = _self.questionsCombined.map(d => d.id);
          _self.updateBusinessOrder(arrayOrder);
        }
      });
    }
  },
  methods: {
    ...mapActions('meetings', ['toggleMeetingPortalRevocations']),
    ...mapActions('meetings/proxies', [
      'listMeetingProxyWriteIns',
      'revokeMeetingWriteInProxy'
    ]),
    ...mapActions('v2/meetings', ['fetchMeeting']),
    // These vuex actions/API routes can be removed
    // if/when we sunset the V2 Overview page.
    //
    // ...mapActions('v2/questions', [
    // 'fetchQuestionsCombined',
    // 'changeBusinessOrder',
    // 'toggleAllQuestionsExpand'
    // ]),
    async init() {
      try {
        this.loading = true;
        // await this.fetchQuestionsCombined({ shortCode: this.shortCode });
        this.openVotingSwitch = this.isVotingOpen;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    async updateBusinessOrder(arrayOrder) {
      try {
        // await this.changeBusinessOrder({
        //   shortCode: this.shortCode,
        //   orderArray: arrayOrder
        // });
        this.$events.$emit('toastEvent', 'Business Order Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    toggleQuestions() {
      // this.toggleAllQuestionsExpand({
      //   isExpanded: !this.hasExpandedQuestionCombined
      // });
    },
    onOpenVotingSwitch(isSwitchOn) {
      // Case 1: revocations are being turned off
      // Display a confirmation dialog to user
      if (isSwitchOn) {
        this.dialogs.openVoting = true;
      }
      // Case 2: revocations are turned back on
      else {
        this.toggleVoting({ isVotingOpen: false });
      }
    },

    /* DEPRECATED:  Removed during v2/v1 API Merge - JJ*/
    /* TODO: Remove it */
    async toggleVoting({ isVotingOpen }) {
      // try {
      //   if (this.allowsPortalRevocations) {
      //     //Revocation status is the opposite of open voting
      //     const revocationStatus = isVotingOpen ? 'closed' : 'open';
      //     await this.toggleMeetingPortalRevocations({
      //       shortCode: this.shortCode,
      //       revocationStatus
      //     });
      //   }
      //   const liveVotingStatus = isVotingOpen ? 'open' : 'closed';
      //   await this.toggleLiveVoting({
      //     shortCode: this.shortCode,
      //     liveVotingStatus
      //   });
      //   this.dialogs.openVoting = false;
      //   this.$events.$emit('toastEvent', this.openVotingText);
      // } catch (err) {
      //   this.$events.$emit('showErrorDialog', err.response);
      // }
    },

    cancelOpenVoting() {
      this.openVotingSwitch = false;
      this.dialogs.openVoting = false;
    },
    async viewWriteInVote({ questionKey, vote }) {
      try {
        this.listMeetingProxyWriteIns({
          shortCode: this.shortCode,
          questionKey,
          vote
        });

        this.dialogs.writeInVote = true;
      } catch {
        this.$events.$emit('toastEvent', 'Could not retrieve write-in proxies');
      }
    },
    async revokeProxy({ proxyKey }) {
      try {
        await this.revokeMeetingWriteInProxy({
          shortCode: this.shortCode,
          proxyKey
        });

        this.$events.$emit('toastEvent', 'Proxy Revoked');

        // Refresh the combined vote tally after proxy successfully revoked
        this.init();
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Could not revoke proxy, please try again'
        );
      }
    }
  }
};
</script>

<style scoped>
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}

.h-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.justify-end {
  justify-content: flex-end;
  flex-grow: 0;
}

.units-text {
  color: rgba(0, 0, 0, 0.54);
}

.v-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.voting-toggle .v-input__slot {
  padding: 2px 4px;
}

.voting-toggle .v-label.theme--light {
  color: white !important;
}

.primary--text .v-label.theme--light {
  color: #1976d2 !important;
}
</style>
