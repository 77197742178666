'use strict';

import { getRecipe } from '@/api/recipes';

const defaults = {
  'by-law': {
    header: 'Approval of Proposed By-Law',
    template:
      "<p>I (we) instruct the Proxy to vote with respect to the Corporation's proposed By-law(s) as indicated below:</p>\n<p>{{{answer}}}</p>"
  },
  'owner-occupied': {
    header:
      'Whom do you elect to the Owner-Occupied Director position on the board?',
    template:
      '<p>I (We) instruct the Proxy to nominate, if necessary, and to vote for the candidate set out below for the position on the board of directors for which only owners of owner-occupied units may vote under subsection 51 (6) of the Condominium Act, 1998:</p>\n<p><strong>Candidates for the Position on the Board for which only owners of owner-occupied units may vote (if applicable under subsection 51 (6) of the Condominium Act, 1998)</strong></p>\n<p>{{{answer}}}</p>\n<p><em>Note: Your Proxy may only vote for one candidate.</em></p>',
    is_secret: true
  },
  'general-elections': {
    header: 'Whom do you elect to the General Director positions on the board?',
    template:
      '<p>I (We) instruct the Proxy to nominate, if necessary, and to vote for the candidates named below and in the order set out below. The candidates are or may be candidates for those positions on the board of directors for which all owners may vote at the Meeting:</p>\n<p><strong>Candidates for Positions on the Board for which all owners may vote</strong></p>\n<p>{{{answer}}}</p>\n<p><em>(additional names may be added)</em></p>\n<p><em>Note: Print the name of any individual whom you wish to elect to the board of directors. Your Proxy may only vote for individuals whose names are set out above and who, at the time of the vote, are candidates. If you list more names than positions available on the board of directors, your Proxy will vote in the order set out above up to the number of positions that are available.</em></p>',
    is_secret: true
  },
  'director-removals': {
    header: 'Are you in favor of removing any of the following directors?',
    template: '&nbsp;',
    is_secret: true
  },
  'substitute-elections': {
    header:
      'If the removal of directors is successful, whom do you elect as substitute directors?',
    template: '&nbsp;',
    is_secret: true
  },
  'substitute-elections-oo': {
    header:
      'If the removal of directors is successful, whom do you elect as substitute directors for the owner-occupied position?',
    template: '&nbsp;',
    is_secret: true
  }
};

/**
 * Takes a minimal Business object and turns it into a full Business object for
 * use in POST'ing a new Business to API. Uses sensible defaults for the full
 * object.
 *
 * Also accepts optional param "openBallot", set to true if the current meeting
 * is an Open Ballot campaign. If this is the case, we add advanced settings to
 * the new Business object.
 *
 * @param  {Object}  business       the minimal business object
 * @param  {Integer} order          The order number of the business
 * @param  {Boolean} openBallot     Whether current meeting is open ballot or not; defaults to false
 * @return {Object}                 the full business object
 */
export default async function makeNewBusinessObject(
  obj,
  order,
  openBallot = false,
  isOntario = false
) {
  try {
    let newBusinessObj = {
      order,
      header: '',
      name: obj.name,
      package: {},
      type: obj.type,
      template: ''
    };

    // Get default values to create business with
    const def = defaults[obj.type];
    newBusinessObj.header = def ? def.header : 'Add a header';
    newBusinessObj.template = def ? def.template : '&nbsp;';

    // add advanced settings, if the current meeting is an Open Ballot
    if (openBallot) {
      const businessRecipe = await getRecipe('meetings.business.open_ballot');
      const advancedSettings = businessRecipe.data.data.Questions;

      switch (newBusinessObj.type) {
        case 'by-law':
          newBusinessObj.isSecret = advancedSettings['by-law'].is_secret;
          newBusinessObj.meta = {};
          newBusinessObj.meta.options =
            advancedSettings['by-law'].custom_meta.options;
          newBusinessObj.template = advancedSettings['by-law'].template;
          break;
        default:
          newBusinessObj.isSecret = advancedSettings['election'].is_secret;
          newBusinessObj.meta = {};
          newBusinessObj.meta.options =
            advancedSettings['election'].custom_meta.options;
          newBusinessObj.template = advancedSettings['election'].template;
          break;
      }
    } else {
      newBusinessObj.isSecret = def.is_secret && isOntario;
    }

    return newBusinessObj;
  } catch (err) {
    console.error('Error: makeNewBusinessObject action', err);
    throw err;
  }
}
