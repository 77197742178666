import http from './requestType';

/**
 * Get meeting approvals list
 *
 * @param  {String} shortCode   shortcode
 * @return {Promise}            Promise
 */
export function getMeetingApprovals({ shortCode }) {
  return http._get(`Meetings/${shortCode}/approvals`);
}

/**
 * Get meeting approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @return {Promise}            Promise
 */
export function getMeetingApproval({ shortCode, arKey }) {
  return http._get(`Meetings/${shortCode}/approvals/${arKey}`);
}

/**
 * Post approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} name
 * @return {Promise}            Promise
 */
export function postApproval({ shortCode, name }) {
  return http._post(`Meetings/${shortCode}/approvals`, {
    name
  });
}

/**
 * Update meeting approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {Object} payload     approval data
 * @return {Promise}            Promise
 */
export function updateApproval({ shortCode, arKey, payload }) {
  return http._patch(`Meetings/${shortCode}/approvals/${arKey}`, payload);
}

/**
 * Update approval request settings
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {Object} payload     approval settings data
 * @return {Promise}            Promise
 */
export function patchSettings({ shortCode, arKey, payload }) {
  return http._patch(
    `Meetings/${shortCode}/approvals/${arKey}/settings`,
    payload
  );
}

/**
 * Post approval review
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} [comment]   optional comment
 * @param  {String} action      action
 * @return {Promise}            Promise
 */
export function postReview({ shortCode, arKey, comment, action }) {
  return http._post(`Meetings/${shortCode}/approvals/${arKey}/review`, {
    action,
    comment
  });
}

/**
 * Remove a user from approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} key         userKey
 * @return {Promise}            Promise
 */
export function deleteUser({ shortCode, arKey, key }) {
  return http._delete(`Meetings/${shortCode}/approvals/${arKey}/users/${key}`);
}

/**
 * Add a notice
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} key         noticeKey
 * @return {Promise}            Promise
 */
export function putNotice({ shortCode, arKey, key }) {
  return http._put(`Meetings/${shortCode}/approvals/${arKey}/notices/${key}`);
}

/**
 * Remove a notice from approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} key         noticeKey
 * @return {Promise}            Promise
 */
export function deleteNotice({ shortCode, arKey, key }) {
  return http._delete(
    `Meetings/${shortCode}/approvals/${arKey}/notices/${key}`
  );
}

/**
 * Add a printJob
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} key         job key
 * @return {Promise}            Promise
 */
export function putPrintJob({ shortCode, arKey, key }) {
  return http._put(`Meetings/${shortCode}/approvals/${arKey}/printJobs/${key}`);
}

/**
 * Remove a printJob from approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} key         job key
 * @return {Promise}            Promise
 */
export function deletePrintJob({ shortCode, arKey, key }) {
  return http._delete(
    `Meetings/${shortCode}/approvals/${arKey}/printJobs/${key}`
  );
}

/**
 * Add a reviewer
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} userKey     user key
 * @return {Promise}            Promise
 */
export function putReviewer({ shortCode, arKey, userKey }) {
  return http._put(
    `/Meetings/${shortCode}/approvals/${arKey}/reviewers/${userKey}`,
    { sendNotification: true }
  );
}

/**
 * Close approval
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} arKey       ar key
 * @param  {String} [comment]   optional comment
 * @return {Promise}            Promise
 */
export function closeApproval({ shortCode, arKey, comment, action }) {
  return http._post(`Meetings/${shortCode}/approvals/${arKey}/close`, {
    action,
    comment
  });
}
