<template>
  <v-card class="mt-1">
    <v-container fluid>
      <!-- Upload spinner overlay only shows when uploading a file -->
      <v-layout v-if="isUploading" align-center justify-center class="floating">
        <v-progress-circular indeterminate color="primary"/>
        <span class="upload-label">Uploading ...</span>
      </v-layout>
      <!-- END Upload spinner overlay only shows when uploading a file -->
      <!-- Main Card Content -->
      <v-layout 
        nowrap
        align-center
        :class="{'fade-overlay': isUploading}">
        <v-flex md11>
          <span class="item capitalize type-heading">
            <b>{{ agreement.type }} Agreement</b>
            <agreement-card-chip :is-signed="isSigned" :signed-at="signedAt"/>
          </span>
          
          <span v-if="!!description" class="item description">{{ description }}</span>
          
          <span class="item">
            <b>Created On:</b>
            {{ createdAt | dateFormatReadable(true) }}
          </span>
          
          <span
            v-for="file in files"
            :key="file.key" 
            class="item file-item">
            - <a 
              :href="file.url"
              target="_blank"
              rel="noopener">{{ file.name }}</a>
              &nbsp;
              <span class="caption">({{ file.createdAt | dateFromNow }})</span>
              &nbsp;
              <v-tooltip bottom>
                <v-icon
                  slot="activator" 
                  small
                  @click="deleteFile(file)">remove_circle</v-icon>
                <span>Remove this file from agreement</span>
              </v-tooltip>
          </span>
        </v-flex>
        <v-flex md1 class="text-xs-right">
          <v-menu offset-x>
            <v-btn slot="activator" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile @click="addFileClicked">
                <v-list-tile-title>Add Files</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="editClicked">
                <v-list-tile-title>Edit</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="deleteAgreement">
                <v-list-tile-title>Delete</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>

      <v-alert
        :value="!isSigned"
        type="info"
        outline
      >If you have already uploaded a signed PDF to this card, please make sure to mark it as "signed" by clicking on the card's edit button.</v-alert>

      <!-- END Main Card Content -->
    </v-container>

    <!-- Hidden file upload to be triggered -->
    <input ref="file" type="file" multiple class="hidden-input" @change.prevent="onFileChange">
  </v-card>
</template>

<script>
import _ from 'lodash';
import {mapActions} from 'vuex';
import * as filters from '@/filters';

import AgreementCardChip from './AgreementCardChip';

export default {
  name: 'AgreementCard',
  components: {
    AgreementCardChip
  },
  props: {
    agreement: {
      type: Object
      // required: true
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      isUploading: false
    };
  },
  computed: {
    isSigned() {
      return !!this.agreement.signedAt;
    },
    isCustom() {
      return this.agreement.type === 'custom';
    },
    description() {
      return this.agreement.description;
    },
    createdAt() {
      return this.agreement.createdAt;
    },
    signedAt() {
      return this.agreement.signedAt;
    },
    expiresAt() {
      return this.agreement.expiresAt;
    },
    files() {
      if (!this.agreement.files) {
        return [];
      }
      const files = [...this.agreement.files];
      return files.sort((a, b) => a.createdAt > b.createdAt);
    }
  },
  methods: {
    ...mapActions('files', ['uploadFiles']),
    ...mapActions('accounts', ['getAccountAgreements']),
    addFileClicked() {
      return this.$refs.file.click();
    },
    editClicked() {
      return this.$emit('edit-clicked', this.agreement);
    },
    async onFileChange(e) {
      try {
        this.isUploading = true;
        // Grab file object
        const files = e.target.files || e.dataTransfer.files;
        await this.uploadFiles({
          files,
          categoryType: 'agreement',
          categoryTypeKey: this.agreement.key
        });
        this.$events.$emit('toastEvent', 'File added to agreement');
        this.getAccountAgreements(this.agreement.accountKey);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        // Clear to detect any change event
        e.target.value = '';
        this.isUploading = false;
      }
    },
    async deleteFile(file) {
      this.$emit('delete-file', file);
    },
    async deleteAgreement() {
      this.$emit('delete-agreement', this.agreement);
    }
  }
};
</script>

<style scoped>
.item {
  display: block;
}
.type-heading {
  font-size: 18px;
}
.description {
  font-style: italic;
  color: grey;
}
.hidden-input {
  display: none;
}

.fade-overlay {
  opacity: 0.2;
}

.floating {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.upload-label {
  padding: 10px;
}
</style>