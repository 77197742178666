<template>
  <v-layout row justify-center>
    <v-dialog
      scrollable
      :value="isOpen"
      @input="$emit('close')"
      max-width="900px"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="ma-0 pa-0">
          <v-tabs v-model="activeTab" slider-color="primary">
            <v-tab ripple>
              Notes
              <span v-if="notesList"
                >({{ notesList.length + accountNotesList.length }})</span
              >
            </v-tab>
            <v-tab ripple v-if="isAdmin"
              >Checklist({{ meetingChecklistData.length }})</v-tab
            >
          </v-tabs>
          <v-spacer />
          <v-btn small flat fab class="ma-0 mr-2">
            <v-icon @click="$emit('close')">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="ma-0 pa-0">
          <meeting-notes
            v-if="activeTab === 0"
            :is-admin="isAdmin"
            :user-key="userKey"
            :notes-list="notesList"
            :account-notes-list="accountNotesList"
            :default-type="noteType"
            :checklist-stages="checklistStages"
            @removeNote="removeNote"
            :meeting-timezone="meetingTimezone"
          />
          <checklist-table
            v-if="activeTab === 1"
            :meeting-checklist-data="meetingChecklistData"
          />
        </v-card-text>
        <v-card-actions class="ma-0 pa-0" v-if="activeTab === 0">
          <meeting-notes-chatbox
            v-if="isAdmin || showNotesChatBox"
            :default-type="noteType"
            :checklist-stages="checklistStages"
            :is-moderator="isModerator"
            :tagging-users="taggingUsers"
            @addNewNote="addNote"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import ChecklistTable from '@/components/ChecklistTable';
import MeetingNotes from '@/components/MeetingNotes';
import MeetingNotesChatbox from '@/components/MeetingNotesChatbox';

export default {
  name: 'MeetingNotesDialog',
  components: {
    MeetingNotes,
    ChecklistTable,
    MeetingNotesChatbox
  },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    notesList: {
      type: Array,
      default() {
        return [];
      }
    },
    accountNotesList: {
      type: Array,
      default() {
        return [];
      }
    },
    shortCode: {
      type: String,
      required: true
    },
    meetingKey: {
      type: String,
      required: true
    },
    noteType: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      default: null
    }
  },
  watch: {
    isOpen: function(value) {
      // When the note drawer is open, refresh the notes list
      const { profile } = this.login;

      if (this.scopes) {
        if (
          this.getScopeByAction(
            this.$route.params.shortcode,
            'meeting.notes.create'
          ) &&
          profile.type === 'user'
        ) {
          this.showNotesChatBox = true;
          this.isModerator = true;
          this.userKey = profile.key;
        }
      }

      if (value) {
        this.setNotesList(this.shortCode);
        this.getChecklistStages();
      }
    }
  },
  data() {
    return {
      dataLoaded: false,
      activeTab: 0,
      showNotesChatBox: false,
      isModerator: false,
      userKey: null
    };
  },
  computed: {
    ...mapGetters(['login', 'isAdmin', 'scopes', 'getScopeByAction']),
    ...mapGetters('checklists', ['checklistStages']),
    ...mapGetters('meetings/taggingUsers', ['taggingUsers']),
    ...mapGetters('meetings/checklists', ['meetingChecklistData']),
    ...mapGetters('meetings/notes', ['notesByShortCode'])
  },
  created() {
    this.setNotesList(this.shortCode);
    this.getTaggingUsers({ shortCode: this.shortCode });
  },
  methods: {
    ...mapActions('checklists', ['getChecklistStages']),
    ...mapActions('meetings/notes', [
      'getMeetingNotesList',
      'createMeetingNote',
      'removeMeetingNote'
    ]),
    ...mapActions('meetings', ['getTaggingUsers']),
    setNotesList(shortCode) {
      try {
        this.getMeetingNotesList({ shortCode });

        this.dataLoaded = true;
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      }
    },
    addNote({ newNote, noteType, taggedUsers }) {
      try {
        let payload = {
          message: newNote,
          type: noteType,
          taggedUsers: taggedUsers
        };
        this.createMeetingNote({ shortCode: this.shortCode, payload });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      }
    },
    removeNote(noteKey) {
      let isConfirm = confirm('Are you sure you want to remove this note?');

      if (isConfirm) {
        try {
          this.removeMeetingNote({ shortCode: this.shortCode, noteKey });
        } catch (err) {
          if (err.response) {
            this.error = err.response;
          } else if (err.request) {
            this.error = { status: 503, statusText: 'Service unavailable' };
          } else {
            console.log(err);
            this.error = { status: 520, statusText: 'Unknown Error' };
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
