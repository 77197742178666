'use strict';

/**
 * @fileoverview Vuex module for samples
 */

import _ from 'lodash';
import Vue from 'vue';
import * as groupApi from '@/api/conversation-template-groups';

const state = {
  conversationTemplateGroupsList: {},
  conversationTemplateGroupMembership: {},
  dataLastFetchedAt: null
};

const getters = {
  conversationTemplateGroupsList: state => state.conversationTemplateGroupsList,
  conversationTemplateGroupDataLastFetched: state => state.dataLastFetchedAt,
  conversationTemplateGroupsListData: state =>
    Object.values(state.conversationTemplateGroupsList),
  conversationTemplateGroupByKey: state => key =>
    state.conversationTemplateGroupsList[key]
};

const actions = {
  /**
   * Get all the conversation template groups from API
   */
  async getConversationTemplateGroups({ commit }) {
    try {
      const res = await groupApi.getConversationTemplateGroups();
      return commit('SET_CONVERSATION_TEMPLATE_GROUP_LIST_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getConversationTemplateGroups action', err);
      throw err;
    }
  },

  /**
   * Add a new template group
   *
   * @param  {String} obj.name        the conversation template group name
   * @param  {String} obj.description conversation the template group description
   * @return {String}                 the new conversation template's key
   */
  async createConversationTemplateGroup(
    { dispatch },
    { name, description } = {}
  ) {
    const payload = {
      name,
      description: description ? description : name,
      valid_campain_types: ['meeting']
    };
    try {
      const res = await groupApi.postConversationNewTemplateGroup(payload);
      await dispatch('getConversationTemplateGroup', res.data.key);
      return res.data.key;
    } catch (err) {
      console.error(
        'ERROR: createConversationTemplateGroup action',
        payload,
        err
      );
      throw err;
    }
  },

  /**
   * Get the template group identified by key from API
   *
   * @param  {Integer} key    the conversation template group key
   */
  async getConversationTemplateGroup({ commit }, key) {
    try {
      const res = await groupApi.getConversationTemplateGroup(key);
      commit('SET_CONVERSATION_TEMPLATE_GROUP_IN_LIST', {
        key,
        data: res.data
      });
    } catch (err) {
      console.error('ERROR: getConversationTemplateGroup action', key, err);
      throw err;
    }
  },

  /**
   * Updates a template group
   *
   * @param {Object}  obj           the params
   * @param {String}  obj.key       the conversation template group key
   * @param {Object}  obj.payload   the conversation template group update payload
   */
  async updateConversationTemplateGroup({ dispatch }, { key, payload }) {
    try {
      await groupApi.putConversationTemplateGroup(key, payload);
      dispatch('getConversationTemplateGroup', key);
    } catch (err) {
      console.error('ERROR: updateTemplateGroup action', payload, err);
      throw err;
    }
  },

  /**
   * Delete a template group
   * @param {String} key        the conversation template group key
   */
  async deleteConversationTemplateGroup({ dispatch }, key) {
    try {
      await groupApi.deleteConversationTemplateGroup(key);
      dispatch('getConversationTemplateGroups');
    } catch (err) {
      console.error('ERROR: deleteConversationTemplateGroup action', err);
      throw err;
    }
  },

  /**
   * Add a new conversation template group membership
   *
   * @param  {Object} obj               the new conversation template group membership object
   * @param  {String} obj.templateKey   the conversation template key name
   * @param  {String} obj.key           the the conversation template group key
   * @param  {Number} obj.listOrder     the list order
   */
  async createConversationTemplateGroupMembership(
    { dispatch },
    { key, templateKey, listOrder } = {}
  ) {
    const payload = {
      conversationTemplateKey: templateKey,
      conversationTemplateGroupKey: key,
      listOrder
    };
    try {
      await groupApi.postNewConversationTemplateGroupMembership(key, payload);
      await dispatch('getConversationTemplateGroup', key);
    } catch (err) {
      console.error(
        'ERROR: createConversationTemplateGroupMembership action',
        payload,
        err
      );
      throw err;
    }
  },

  /**
   * Updates a list of template group memberships
   *
   * @param {Object}  obj           the params
   * @param {String}  obj.key       the conversation template group key
   * @param {Object}  obj.payload   the conversation template group memberships update payload
   */
  async updateConversationTemplateGroupMembership(
    { dispatch },
    { key, payload }
  ) {
    try {
      await groupApi.putConversationTemplateGroupMembership(key, payload);
    } catch (err) {
      console.error(
        'ERROR: updateConversationTemplateGroupMembership action',
        payload,
        err
      );
      throw err;
    }
  },

  /**
   * Delete a template group
   * @param {String} key                  the conversation template group key
   * @param {String} membershipKey        the conversation template group membership key
   */
  async deleteConversationTemplateGroupMembership(
    { dispatch },
    { key, membershipKey }
  ) {
    try {
      await groupApi.deleteConversationTemplateGroupMembership(
        key,
        membershipKey
      );
      dispatch('getConversationTemplateGroup', key);
    } catch (err) {
      console.error(
        'ERROR: deleteConversationTemplateGroupMembership action',
        err
      );
      throw err;
    }
  }
};

const mutations = {
  SET_CONVERSATION_TEMPLATE_GROUP_LIST_DATA(state, data) {
    state.conversationTemplateGroupsList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_CONVERSATION_TEMPLATE_GROUP_IN_LIST(state, { key, data }) {
    Vue.set(state.conversationTemplateGroupsList, key, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
