<template>
  <div v-if="loading">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else>
    <!-- Nested back button visible where $route.meta.nestedPage property exists -->
    <meeting-nav-nested-tab v-if="nestedPage" :nested-page="nestedPage" />
    <router-view :current-request="currentRequest" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import MeetingNavNestedTab from '../MeetingNavNestedTab';

export default {
  name: 'MeetingRequestMain',
  components: {
    MeetingNavNestedTab
  },
  data() {
    return {
      requestKey: this.$route.params.requestKey,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/requests', ['requestByKey']),
    currentRequest() {
      return this.requestByKey(this.requestKey);
    },
    nestedPage() {
      // If a route.meta nestedPage property exists, return the value
      if (this.$route.meta.hasOwnProperty('nestedPage')) {
        return this.$route.meta.nestedPage;
      }
    }
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('meetings/requests', ['getMeetingRequest']),
    async fetchData() {
      try {
        this.loading = true;
        await this.getMeetingRequest({
          requestKey: this.requestKey
        });
      } catch (err) {
        const { status } = err.response;
        //redirect to main list if status code 403
        if (status === 403) {
          return this.$router.push({
            name: `meetings`
          });
        }
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
