<template>
  <v-chip small label class="white--text" :color="color">
    {{ status }}
  </v-chip>
</template>

<script>
export default {
  name: 'UserStatusChip',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      return this.data.inactiveAt ? 'inactive' : this.data.status;
    },
    color() {
      if (this.status === 'inactive') {
        return 'orange lighten-2';
      } else if (this.status === 'disabled') {
        return 'red lighten-2';
      }
      return 'green';
    }
  }
};
</script>

<style scoped></style>
