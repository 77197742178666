<template>
  <v-card class="round-card full-height mb-2">
    <v-card-text class="card-content full-height">
      <div class="v-flex blue-text">
        <span class="display-2 font-weight-black ma-3 text-xs-center">
          {{ quorumMinimum }}
        </span>
        <p class="subheading mb-0 font-weight-bold">Needed for Quorum</p>
      </div>

      <v-icon :color="flagColor" size="85">flag</v-icon>

      <div class="v-flex max-width text-xs-center">
        <p class="mb-0 subheading ">{{ quorumReachedText }}</p>
        <v-progress-linear v-model="progress" />
        <p class="mb-0 subheading" v-if="!quorumReached">
          <b>{{ neededForQuorum }}</b> more to reach quorum
        </p>
      </div>

      <v-card class="round-card max-width my-2">
        <v-card-title class="space-between">
          <div class="h-flex">
            <v-avatar size="30" color="grey lighten-1" class="mr-2">
              <v-icon dark size="20">account_box</v-icon>
            </v-avatar>
            <h6 class="subheading">Total Units</h6>
          </div>
          <span class="headline font-weight-bold">
            {{ stats.totalUnits }}
          </span>
        </v-card-title>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'QuorumCard',
  components: {},
  props: {
    quorumMinimum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  created() {},
  computed: {
    ...mapGetters('v2/meetings', ['stats']),
    totalSubs() {
      return _.get(this.stats, 'totalProxies', 0);
    },
    quorumReached() {
      return this.quorumMinimum - this.totalSubs <= 0;
    },
    neededForQuorum() {
      return this.quorumReached ? 0 : this.quorumMinimum - this.totalSubs;
    },
    progress() {
      return this.quorumReached
        ? 100
        : (this.totalSubs / this.quorumMinimum) * 100;
    },
    quorumReachedText() {
      return this.quorumReached ? 'Quorum reached!' : 'Quorum not reached';
    },
    flagColor() {
      return this.quorumReached ? 'success' : 'rgba(15, 100, 169, 0.25)';
    }
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.v-flex {
  display: flex;
  flex-direction: column;
}
.card-content {
  display: flex;
  flex-direction: column;
  background-color: #e7f2fb;
  justify-content: space-between;
  align-items: center;
}
.blue-text {
  color: #0f64a9;
}
.full-height {
  height: 100%;
}
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.max-width {
  width: 100%;
}
</style>
