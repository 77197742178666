<template>
  <v-layout :class="formatting">
    <v-flex 
      xs2
      md4>
      <div>
        <v-icon 
          color="blue"
          class="mr-3">
          {{ icon }}
        </v-icon>
        <span class="label-heavy grey--text text--darken-2">{{ label }}</span>
      </div>
    </v-flex>
    <v-flex 
      xs10
      md8>
      <slot name="content" />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'MeetingDetailsListItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    formatting: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.label-heavy {
  font-weight: 500;
  opacity: 0.85;
}

@media only screen and (max-width: 920px) {
  .label-heavy {
    display: none;
  }
}
</style>