<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
        v-model="state.topic"
        :disabled="saving"
        label="Topic"
        placeholder="eg. TSCC 1234 Virtual Meeting 2020"
        box
        @input="update('topic', $event)"
      />

      <v-textarea
        v-model="state.agenda"
        :disabled="saving"
        label="Description"
        placeholder="eg. This is the webcast for the ......"
        box
        @input="update('agenda', $event)"
      />
      <v-layout row nowrap>
        <v-flex xs6 pr-1>
          <v-select
            :value="extraSettings.licenseTypeSelected"
            :disabled="saving || isMeeting"
            :items="licenseTypes"
            label="License type"
            box
            @input="updateAdditionalSettings('licenseTypeSelected', $event)"
          />
        </v-flex>
        <v-flex xs6 pr-1>
          <v-select
            v-model="extraSettings.capacity"
            :disabled="saving || isMeeting"
            :items="webcastCapacities"
            label="Capacity"
            box
            @input="updateAdditionalSettings('capacity', $event)"
          />
        </v-flex>
        <v-flex xs6 pr-1>
          <v-select
            v-if="showPrimaryHost"
            v-model="state.hostEmail"
            :disabled="saving || isMeeting"
            :items="hostsByCapacity"
            item-text="userId"
            item-value="userId"
            label="Primary Host"
            box
            @input="updateHost($event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row nowrap>
        <v-flex xs12>
          <date-time-picker-timezone
            label="Webcast Date"
            time-label="Webcast Time"
            hide-icons
            is-required
            :iso-date="meetingDate"
            :clearable="false"
            box
            :disabled="saving"
            :timezone="state.timezone"
            :meetingTimezone="meetingTimezone"
            :webcast-date="webcast.startTime"
            @update="updateDate($event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row nowrap>
        <v-flex xs6 pr-1>
          <v-text-field
            v-model="state.duration"
            :disabled="true"
            label="Duration (mins)"
            placeholder="eg. 90"
            type="number"
            box
            @input="update('duration', $event)"
          />
        </v-flex>
        <v-flex xs6 pr-1>
          <v-text-field
            v-model="state.password"
            disabled
            label="Password to Join"
            box
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="!isMeeting" row nowrap align-self>
        <v-flex xs12>
          <p class="ma-0 pa-0 grey--text text--darken-1">
            Registrations:
          </p>
          <v-radio-group
            class="mt-1 pt-1"
            v-model="state.settings.approvalType"
            :disabled="saving"
            @change="update('settings.approvalType', $event)"
            row
          >
            <v-radio
              label="Automatically Approve (Recommended)"
              :value="0"
            ></v-radio>
            <v-radio label="Manually Approve" :value="1"></v-radio>
            <v-radio label="No Registration Required" :value="2"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout row nowrap align-self>
        <v-flex xs12>
          <p class="ma-0 pa-0 grey--text text--darken-1">
            Record meeting automatically:
          </p>
          <v-radio-group
            class="mt-1 pt-1"
            v-model="state.settings.autoRecording"
            :disabled="saving"
            @change="update('settings.autoRecording', $event)"
            row
          >
            <v-radio label="Off" value="none"></v-radio>
            <v-radio label="On local computer" value="local"></v-radio>
            <v-radio label="In the cloud" value="cloud"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-layout v-if="!isMeeting" row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Enable Q&amp;A (Recommended)"
            v-model="state.settings.questionAnswer"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.questionAnswer', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="!isMeeting" row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Enable Practice Session (Recommended)"
            v-model="state.settings.practiceSession"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.practiceSession', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="isMeeting" row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Enable Waiting Room"
            v-model="state.settings.waitingRoom"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.waitingRoom', $event)"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="isMeeting" row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Mute Participants Upon entry"
            v-model="state.settings.muteUponEntry"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.muteUponEntry', $event)"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="isMeeting" row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Participants can join before Host (must disable waiting room)"
            v-model="state.settings.joinBeforeHost"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.joinBeforeHost', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Allow Multiple Devices to Join"
            v-model="state.settings.allowMultipleDevices"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.allowMultipleDevices', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Enable Registration Email Confirmation"
            v-model="state.settings.registrantsConfirmationEmail"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.registrantsConfirmationEmail', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Start video when host joins webinar"
            v-model="state.settings.hostVideo"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.hostVideo', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-checkbox
            class="mt-1 pt-1"
            label="Start video when panelists join webinar"
            v-model="state.settings.panelistsVideo"
            :disabled="saving"
            :false-value="false"
            :true-value="true"
            @change="update('settings.panelistsVideo', $event)"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn flat color="accent" disabled>Delete Webcast</v-btn>
      <v-spacer />
      <v-btn
        :disabled="!hasUnsavedChanges || !primaryHostInList || saving"
        @click="resetState"
        >Cancel</v-btn
      >
      <v-btn
        :disabled="!hasUnsavedChanges || !primaryHostInList"
        :loading="saving"
        @click="saveChanges"
        color="primary"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';

import { mapActions, mapGetters } from 'vuex';

import DatePicker from '@/components/DatePicker';
import DateTimePickerTimezone from './DateTimePickerTimezone';

export default {
  name: 'MeetingWebcastViewSettings',
  components: {
    DatePicker,
    DateTimePickerTimezone
  },
  props: {
    webcast: {
      type: Object,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    },
    meetingDate: {
      type: String,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    isMeeting: {
      type: Boolean,
      default: false
    },
    capacity: {
      type: Number,
      default: 0
    },
    skipBoot: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    webcast() {
      this.resetState();
    },
    'extraSettings.capacity'() {
      this.getUserSchedulers();
    }
  },
  data() {
    return {
      saving: false,
      dateChanged: false,
      licenseTypes: ['dynamic', 'static'],
      webinarCapacities: [500, 1000],
      meetingCapacities: [300],
      state: {
        settings: {}
      },
      extraSettings: {
        capacity: 500,
        licenseTypeSelected: null
      }
    };
  },
  computed: {
    ...mapGetters('meetings/webcast', ['schedulers']),
    hasUnsavedChanges() {
      const extraSettings = {
        capacity: this.capacity,
        licenseTypeSelected: this.skipBoot ? 'static' : 'dynamic'
      };

      return (
        JSON.stringify(this.state) !== JSON.stringify(this.webcast) ||
        JSON.stringify(this.extraSettings) !== JSON.stringify(extraSettings)
      );
    },
    showPrimaryHost() {
      return this.extraSettings.licenseTypeSelected === 'static';
    },
    hostsByCapacity() {
      return _.filter(
        this.schedulers,
        user => user.webinarCapacity === this.extraSettings.capacity
      );
    },
    primaryHostInList() {
      const isDynamic = this.extraSettings.licenseTypeSelected === 'dynamic';

      return (
        isDynamic ||
        this.hostsByCapacity.some(host => host.userId === this.state.hostEmail)
      );
    },
    webcastCapacities() {
      return this.isMeeting ? this.meetingCapacities : this.webinarCapacities;
    }
  },
  created() {
    this.resetState();
    this.getUserSchedulers();
  },
  methods: {
    ...mapActions('meetings/webcast', [
      'updateWebcastWebinar',
      'updateWebcast',
      'fetchWebcastUserSchedulers'
    ]),
    resetState() {
      this.state = _.cloneDeep(this.webcast);
      this.extraSettings.licenseTypeSelected = this.skipBoot
        ? 'static'
        : 'dynamic';
      this.extraSettings.capacity = this.capacity;
    },
    update(key, value) {
      _.set(this.state, key, value);
    },
    updateDate({ date, timezone }) {
      this.state.startTime = date;
      this.state.timezone = timezone;
      this.dateChanged = true;
    },
    updateAdditionalSettings(key, value) {
      _.set(this.extraSettings, key, value);
    },
    // Update: Oct 7, 2022
    // @NOTE: Re-adding license reassignment functions while we test out new
    // /boot functionality
    updateHost(hostEmail) {
      _.set(this.state, 'scheduleFor', hostEmail);
      _.set(this.state, 'hostEmail', hostEmail);
    },
    async getUserSchedulers() {
      try {
        await this.fetchWebcastUserSchedulers({
          shortCode: this.shortCode
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async saveChanges() {
      try {
        const isConfirm = confirm(
          'Do you want to update the webinar settings?'
        );
        if (!isConfirm) {
          return;
        }

        this.saving = true;

        let stateFix = {};

        // If the date hasn't changed, don't send a change in time as
        // timezones just screw everything up
        if (!this.dateChanged) {
          console.log('No date changed');
          stateFix = _.omit({ ...this.state }, ['startTime', 'timezone']);
        } else {
          console.log('Date changed');
          stateFix = { ...this.state };
        }

        await this.updateWebcastWebinar({
          shortCode: this.shortCode,
          payload: stateFix,
          skipRefresh: true
        });

        await this.updateWebcast({
          shortCode: this.shortCode,
          capacity: this.extraSettings.capacity,
          skipBoot: this.extraSettings.licenseTypeSelected === 'static'
        });

        this.$events.$emit('toastEvent', 'Changes saved');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.dateChanged = false;
      }
    }
  }
};
</script>
