<template>
  <v-dialog width="500" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title class="pb-0">
        <p class="title">Undo Assign Shares</p>
      </v-card-title>
      <v-card-text>
        Revert all selected rows to their 'original' share values. <br /><br />
        Note: The assigned unit will have these shares deducted from their
        total.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="emitClose">Cancel</v-btn>
        <v-btn class="primary" @click.native="emitUndoAssign">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsAssignSharesUndoDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selectedRows: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitUndoAssign() {
      // Get all units to undo shares, including the assigned to units
      const unitIds = Array.from(
        this.selectedRows.reduce((acc, row) => {
          acc.add(row.id);
          acc.add(row.customData?.assigned_to_unit_id);
          return acc;
        }, new Set())
      );

      this.$emit('undoAssign', { unitIds });
    }
  }
};
</script>

<style scoped>
.disabled-row {
  opacity: 0.5;
  pointer-events: none; /* Disable pointer events for disabled rows */
}
</style>
