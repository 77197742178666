<template>
  <v-container class="background" fill-height grid-list-md fluid>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg4>
        <v-card>
          <v-card-title class="gq-blue-background">
            <v-container>
              <v-layout column align-center>
                <v-flex xs12>
                  <img src="../assets/gq_navbar_logo.svg" class="login-image" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout column align-center class="text-xs-center">
                <v-alert
                  v-model="isSent"
                  outline
                  color="info"
                  style="width: 100%;"
                  >The email to reset your password has been sent successfully
                  <br />
                  Please check your email!
                </v-alert>
                <h3>Reset your password</h3>
                <p class="mt-3 secondary--text">
                  For security reasons, your account has been disabled due to
                  inactivity.
                  <br />
                  Please reset your password in order to login in to your
                  account.
                </p>

                <v-btn
                  large
                  color="primary"
                  class="mt-4"
                  :disabled="!email || isSent"
                  @click="submitReset"
                  >Send Reset Email</v-btn
                >

                <router-link :to="{ name: 'login' }" replace class="mt-2">
                  <a class="secondary--text">Return to login page</a>
                </router-link>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPasswordView',
  props: {
    title: {
      type: String,
      default: 'Forgot your password?'
    }
  },
  data() {
    return {
      email: this.$route.params.username,
      isSent: false
    };
  },
  mounted() {
    if (!this.email) {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    ...mapActions(['requestPasswordReset']),
    async submitReset() {
      try {
        await this.requestPasswordReset(this.email);
        this.isSent = true;
      } catch (err) {
        console.error('Error requesting password reset', err);
      }
    }
  }
};
</script>

<style scoped></style>
