<template>
  <v-container fluid grid-list-md v-if="dataLoaded">
    <v-layout row wrap class="ma-3">
      <v-flex xs12>
        <v-layout justify-space-between>
          <div class="headline uppercase">
            INV #: {{ currentInvoice.invoiceNumber }}
          </div>

          <div v-if="currentNotes">
            <v-chip
              v-if="invoiceNotes.length > 0"
              class="mr-2"
              color="red"
              text-color="white"
              @click="showMeetingNotesDialog = !showMeetingNotesDialog"
            >
              <strong>
                {{ invoiceNotes.length }} invoice related note(s)</strong
              >
            </v-chip>
            <span>({{ currentNotes.length + accountNotes.length }})</span>
            <v-btn
              class="ml-0"
              flat
              icon
              @click="showMeetingNotesDialog = true"
            >
              <v-icon>comment</v-icon>
            </v-btn>
          </div>

          <div
            v-else-if="
              !currentNotes &&
                currentAccount.data.notes &&
                currentAccount.data.notes.length > 0
            "
          >
            There are {{ currentAccount.data.notes.length }} account notes.
            <router-link :to="accountRouteObject" target="_blank"
              >Click to view</router-link
            >
          </div>
        </v-layout>

        <p class="body-2 mb-0 pb-0">
          Client:
          <router-link :to="accountRouteObject" target="_blank">{{
            currentAccount.name
          }}</router-link>

          (Region: {{ currentAccount.region }})
        </p>

        <p class="body-2 mt-0 pt-0">
          Client Subscription:
          <v-chip v-if="!currentAccount.subscription" label small
            >No Subscription</v-chip
          >

          <span v-else>
            <v-chip
              v-if="currentAccount.subscription.status === 'active'"
              color="green"
              class="white--text"
              label
              small
            >
              Active
            </v-chip>

            <v-chip v-else color="red" class="white--text" label small>{{
              currentAccount.subscription.status
            }}</v-chip>

            <v-chip v-if="currentAccount.subscription.productName" small>
              {{ currentAccount.subscription.productName }}
            </v-chip>
          </span>
        </p>

        <p class="body-2 mt-0 pt-0">
          <span v-if="currentInvoice.meetingCode">
            <b>Campaign:</b>
            <router-link :to="meetingRouteObject" target="_blank">{{
              currentInvoice.meetingCode
            }}</router-link>
          </span>

          <span v-else>
            <b class="uppercase"
              >Account level invoice / Subscription Invoice</b
            >
          </span>
        </p>
      </v-flex>
    </v-layout>

    <admin-invoice-summary-cards
      v-if="dataLoaded"
      :total-price="currentInvoice.totalPrice"
      :amount-owing="currentInvoice.amountOwing"
      :status="currentInvoice.status"
      :currency="currentInvoice.currency"
      :sent-to="currentInvoice.sentTo"
      :sent-at="currentInvoice.sentAt"
      :sent-views="currentInvoice.sentViews"
      :has-sent-views="currentInvoice.hasSentViews"
      :is-paid="isPaid"
    />

    <v-layout row class="ma-3">
      <v-flex xs12>
        <admin-invoice-info-card
          v-if="dataLoaded"
          :invoice-key="currentInvoice.key"
          :account-key="currentInvoice.accountKey"
          :meeting-code="currentInvoice.meetingCode"
          :invoice-date="currentInvoice.invoiceDate"
          :bill-to="currentInvoice.additionalData.billTo"
          :default-bill-to="currentAccount.name"
          :notes="currentInvoice.additionalData.notes"
          :private-notes="currentInvoice.additionalData.privateNotes"
          :purchase-order="currentInvoice.additionalData.purchaseOrder"
        />
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs12>
        <admin-invoice-items-table
          v-if="dataLoaded"
          :invoice-key="currentInvoice.key"
          :data="expensesAndDiscounts"
          :subtotal="expenseAndDiscountsSubtotal"
          :currency="currentInvoice.currency"
          :is-locked="!isEditable"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="ma-3">
      <v-flex xs12 md5>
        <admin-invoice-taxes-table
          v-if="dataLoaded"
          :invoice-key="currentInvoice.key"
          :data="currentInvoice.taxes"
        />
      </v-flex>
      <v-flex xs12 md7>
        <admin-invoice-payments-table
          v-if="dataLoaded"
          :invoice-key="currentInvoice.key"
          :data="currentInvoice.payments"
          :currency="currentInvoice.currency"
          :amount-owing="currentInvoice.amountOwing"
          @paymentDialog="showPaymentReceivedDialog = true"
        />
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs4>
        <v-btn flat color="accent" @click.native="deleteInvoiceClicked">
          <v-icon left>delete</v-icon> Delete Invoice
        </v-btn>
      </v-flex>
      <v-flex xs8 class="text-xs-right">
        <v-btn flat @click.native="openInvoicePreview">
          Preview
        </v-btn>
        <v-btn
          @click.native="markReadyClicked"
          color="teal"
          class="white--text"
          v-if="currentInvoice.status === 'draft'"
        >
          <v-icon left>check</v-icon>To Review
        </v-btn>
        <v-btn
          @click.native="markSentClicked"
          color="primary"
          v-if="currentInvoice.status !== 'sent'"
        >
          <v-icon left>check</v-icon> Mark as Sent
        </v-btn>
        <v-btn
          @click.native="markViewedClicked"
          color="secondary"
          v-if="
            currentInvoice.status === 'sent' && !currentInvoice.hasSentViews
          "
        >
          <v-icon left>pageview</v-icon> Mark as Viewed
        </v-btn>
        <v-btn @click.native="exportInvoiceClicked" color="primary">
          <v-icon left>file_download</v-icon> Download
        </v-btn>
        <v-btn color="accent" @click.native="sendEmailClicked">
          <v-icon left>send</v-icon> Send
        </v-btn>
      </v-flex>
    </v-layout>

    <delete-invoice-dialog
      :is-open="showDeleteDialog"
      resource-name="invoice"
      :resource-key="currentInvoice.key"
      @delete="handleDeleteInvoice"
      @close-dialog="showDeleteDialog = false"
    >
      <span slot="title">Delete this invoice?</span>
      <span slot="description"
        >Are you sure you want to delete
        <b>{{ currentInvoice.invoiceNumber }}</b
        >? There's no going back...</span
      >
    </delete-invoice-dialog>

    <send-email-dialog
      :is-open="showEmailDialog"
      :to-email="currentAccount.contact.email"
      title="Send this invoice?"
      label="Send invoice by email to:"
      hint="The PDF invoice will be attached to the email"
      @send-email="handleSendEmail"
      @close="closeDialogs"
    />

    <!-- Show meeting notes dialog if it's tied to a meeting -->
    <meeting-notes-dialog
      v-if="currentMeeting.key"
      :is-open="showMeetingNotesDialog"
      note-type="meeting"
      :notes-list="currentNotes"
      :account-notes-list="accountNotes"
      :short-code="currentInvoice.meetingCode"
      :meeting-key="currentMeeting.key"
      @close="showMeetingNotesDialog = false"
    />

    <payment-received-dialog
      :value="showPaymentReceivedDialog"
      :current-account="currentAccount"
      @send="sendPaymentConfirmation"
      @close="showPaymentReceivedDialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { handleError } from '@/helpers';

import AdminInvoiceInfoCard from './AdminInvoiceViewInfoCard';
import AdminInvoiceSummaryCards from './AdminInvoiceViewSummaryCards';
import AdminInvoiceItemsTable from './AdminInvoiceViewItemsTable';
import AdminInvoiceTaxesTable from './AdminInvoiceViewTaxesTable';
import AdminInvoicePaymentsTable from './AdminInvoiceViewPaymentsTable';
import DeleteInvoiceDialog from '@/components/dialogs/DeleteResourceDialog';
import SendEmailDialog from '@/components/dialogs/SendEmailDialog';
import MeetingNotesDialog from '@/components/dialogs/MeetingNotesDialog';
import PaymentReceivedDialog from './AdminInvoicePaymentReceivedDialog';

export default {
  name: 'AdminInvoiceView',
  components: {
    AdminInvoiceInfoCard,
    AdminInvoiceSummaryCards,
    AdminInvoiceItemsTable,
    AdminInvoiceTaxesTable,
    AdminInvoicePaymentsTable,
    DeleteInvoiceDialog,
    SendEmailDialog,
    MeetingNotesDialog,
    PaymentReceivedDialog
  },
  props: {},
  data() {
    return {
      dataLoaded: false,
      invoiceKey: this.$route.params.invoiceKey,
      showDeleteDialog: false,
      showEmailDialog: false,
      showMeetingNotesDialog: false,
      showPaymentReceivedDialog: false
    };
  },
  computed: {
    ...mapGetters(['login']),
    ...mapGetters('invoices', [
      'invoiceByKey',
      'invoiceExpenseData',
      'invoiceDiscountData',
      'invoiceItemsData',
      'invoiceItemSubtotal',
      'invoiceList'
    ]),
    ...mapGetters('accounts', ['accountByKey', 'accountNotesByKey']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('meetings/notes', [
      'notesByShortCode',
      'invoiceNotesByShortCode'
    ]),
    currentMeeting() {
      return this.currentInvoice.meetingCode
        ? this.meetingByShortCode(this.currentInvoice.meetingCode)
        : {};
    },
    currentInvoice() {
      return this.invoiceByKey(this.invoiceKey);
    },
    currentAccount() {
      return this.currentInvoice
        ? this.accountByKey(this.currentInvoice.accountKey)
        : {};
    },
    currentNotes() {
      return this.currentMeeting
        ? this.notesByShortCode(this.currentMeeting.shortCode)
        : [];
    },
    accountNotes() {
      return this.accountNotesByKey(this.currentInvoice.accountKey);
    },
    invoiceNotes() {
      return this.currentMeeting
        ? this.invoiceNotesByShortCode(this.currentMeeting.shortCode)
        : [];
    },
    expensesArray() {
      return this.invoiceExpenseData(this.invoiceKey);
    },
    discountsArray() {
      return this.invoiceDiscountData(this.invoiceKey);
    },
    expensesAndDiscounts() {
      return this.invoiceItemsData(this.invoiceKey);
    },
    expenseAndDiscountsSubtotal() {
      return this.invoiceItemSubtotal(this.invoiceKey);
    },
    isPaid() {
      return (
        this.currentInvoice.status === 'sent' &&
        this.currentInvoice.amountOwing <= 0
      );
    },
    accountRouteObject() {
      return {
        name: 'editAccount',
        params: {
          accountKey: this.currentAccount.key
        }
      };
    },
    meetingRouteObject() {
      return {
        name: 'meetingOverview',
        params: {
          shortcode: this.currentInvoice.meetingCode
        }
      };
    },
    isEditable() {
      return !this.currentAccount.subscription;
    }
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapActions('accounts', ['getAccount']),
    ...mapActions('invoices', [
      'setInvoiceAsSent',
      'setInvoiceAsViewed',
      'removeInvoice',
      'sendInvoiceByEmail',
      'exportInvoice',
      'getInvoiceByKey',
      'sendInvoicePaymentConfirmation',
      'setInvoiceAsReady',
      'previewInvoice'
    ]),
    ...mapActions('meetings', ['getMeeting']),
    async init() {
      try {
        this.dataLoaded = false;
        await this.getInvoiceByKey(this.invoiceKey);

        await this.getAccount({
          key: this.currentInvoice.accountKey,
          includeInvoices: false
        });

        // Only get meeting info if this invoice is tied to a meeting
        if (this.currentInvoice.meetingCode) {
          await this.getMeeting({ shortCode: this.currentInvoice.meetingCode });
        }

        window.document.title = `${this.currentInvoice.invoiceNumber} | Invoice`;

        this.dataLoaded = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    },
    async markReadyClicked() {
      try {
        await this.setInvoiceAsReady(this.currentInvoice.key);
        this.$events.$emit('toastEvent', 'Marked as ready');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async markSentClicked() {
      try {
        await this.setInvoiceAsSent(this.currentInvoice.key);
        this.$events.$emit('toastEvent', 'Marked as sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async markViewedClicked() {
      try {
        await this.setInvoiceAsViewed(this.currentInvoice.key);
        this.$events.$emit('toastEvent', 'Marked as viewed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async exportInvoiceClicked() {
      try {
        this.$events.$emit('toastEvent', 'Downloading Invoice');
        await this.exportInvoice(this.currentInvoice.key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleDeleteInvoice() {
      try {
        await this.removeInvoice(this.currentInvoice.key);
        this.$router.push({ name: 'adminInvoices' });
        this.showDeleteDialog = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleSendEmail(payload) {
      try {
        this.$events.$emit('toastEvent', 'Sending invoice...');
        await this.sendInvoiceByEmail({
          key: this.currentInvoice.key,
          payload
        });
        this.$events.$emit('toastEvent', 'Invoice sent');
        this.showEmailDialog = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendPaymentConfirmation() {
      try {
        await this.sendInvoicePaymentConfirmation({
          key: this.currentInvoice.key,
          payload: {
            toEmail: this.currentAccount.contact.email
          }
        });
        this.showPaymentEmailDialog = false;
        this.$events.$emit('toastEvent', 'Payment Confirmation Email Sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    closeDialogs() {
      this.showDeleteDialog = false;
      this.showEmailDialog = false;
    },
    deleteInvoiceClicked() {
      this.showDeleteDialog = true;
    },
    sendEmailClicked() {
      this.showEmailDialog = true;
    },
    async openInvoicePreview() {
      const html = await this.previewInvoice(this.currentInvoice.key);
      const winUrl = URL.createObjectURL(
        new Blob([html], { type: 'text/html' })
      );
      const win = window.open(
        winUrl,
        'win',
        'toolbar=0,location=0,menubar=0,width=800,height=1000,noopener'
      );
      win.document.title = 'Invoice Preview';
      win.document.write(html);
      win.focus();
      window.close();
    }
  }
};
</script>

<style scoped></style>
