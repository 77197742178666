<template>
  <router-view
    :checklist-templates-list="checklistTemplatesList"
    :checklist-templates-data="checklistTemplatesListData"
    :last-data-fetched="checklistTemplatesDataLastFetched"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminChecklistTemplatesMain',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('checklistTemplates', [
      'checklistTemplatesListData',
      'checklistTemplatesList',
      'checklistTemplatesDataLastFetched'
    ])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('checklistTemplates', ['getChecklistTemplates']),
    async init() {
      try {
        await this.getChecklistTemplates();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
