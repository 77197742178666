<template>
  <div>
    <app-header>
      <div slot="top-left-action">
        <router-link :to="{ name: 'meetings' }">
          <img class="mt-2" src="@/assets/gq_navbar_logo.svg" />
        </router-link>
      </div>
    </app-header>

    <v-container fill-height>
      <v-layout align-center justify-center fill-height>
        <v-flex xs12 sm8 md6 lg4>
          <v-card height="600">
            <div v-if="resetPrompt">
              <v-card-title class="pb-0">
                <v-container>
                  <v-layout column align-center>
                    <v-flex>
                      <div class="headline font-weight-bold mb-2">
                        Change your password
                      </div>
                      <span class="text-sm">
                        Choose a password that will be difficult for other
                        people to guess, but easy for you to remember.
                      </span>
                      <div class="text-sm mt-3">
                        Your password must have:
                      </div>
                      <span>
                        <ul>
                          <li
                            v-for="requirement in passwordRequirements"
                            :key="requirement.type"
                            class="text-sm"
                          >
                            {{ requirement.message }}
                          </li>
                        </ul>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-title>
              <v-card-text class="py-0">
                <v-container grid-list-lg>
                  <!-- Password form -->
                  <v-form
                    @submit.prevent="updatePassword"
                    ref="changePasswordForm"
                  >
                    <!-- Current Password -->
                    <v-layout>
                      <v-text-field
                        name="new"
                        :type="isPassVisible.current ? 'text' : 'password'"
                        label="Your current password"
                        :rules="[v => !!v || 'Password is required']"
                        v-model="user.currentPassword"
                        ref="currentPassword"
                        :error-messages="error.currentPassword"
                        @change="resetInputValidation('currentPassword')"
                        required
                      >
                        <template slot="append">
                          <v-icon
                            v-if="this.user.currentPassword"
                            :color="passwordErrorIcon.current.color"
                          >
                            {{ passwordErrorIcon.current.icon }}
                          </v-icon>
                          <v-icon
                            class="ml-1"
                            @click="reverseVisibility('current')"
                          >
                            {{ passwordVisibileIcon.current }}
                          </v-icon>
                        </template></v-text-field
                      >
                    </v-layout>
                    <!-- New Password -->
                    <v-layout>
                      <v-text-field
                        name="new"
                        :type="isPassVisible.new ? 'text' : 'password'"
                        label="Your new password"
                        :rules="rules.new"
                        v-model="user.newPassword"
                        ref="newPassword"
                        :error-messages="error.newPassword"
                        @change="resetInputValidation('newPassword')"
                        required
                      >
                        <template slot="append">
                          <v-icon
                            v-if="this.user.newPassword"
                            :color="passwordErrorIcon.new.color"
                          >
                            {{ passwordErrorIcon.new.icon }}
                          </v-icon>
                          <v-icon
                            class="ml-1"
                            @click="reverseVisibility('new')"
                          >
                            {{ passwordVisibileIcon.new }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-layout>
                    <!-- Password strength -->
                    <v-layout>
                      <v-progress-linear
                        class="my-1"
                        :value="passwordStrength.score * 25"
                        :color="passwordStrength.color"
                      />
                    </v-layout>
                    <v-layout>
                      <v-spacer />
                      <div class="text--grey">
                        Password is {{ passwordStrength.message }}
                      </div>
                    </v-layout>
                    <!-- Confirm Password -->
                    <v-layout>
                      <v-text-field
                        name="confirm"
                        :type="isPassVisible.confirm ? 'text' : 'password'"
                        label="Re-enter your new password"
                        :rules="rules.confirm"
                        ref="confirmPassword"
                        v-model="user.confirmPassword"
                        required
                      >
                        <template slot="append">
                          <v-icon
                            v-if="this.user.confirmPassword"
                            :color="passwordErrorIcon.confirm.color"
                          >
                            {{ passwordErrorIcon.confirm.icon }}
                          </v-icon>
                          <v-icon
                            class="ml-1"
                            @click="reverseVisibility('confirm')"
                          >
                            {{ passwordVisibileIcon.confirm }}
                          </v-icon>
                        </template></v-text-field
                      >
                    </v-layout>
                    <v-card-actions>
                      <v-layout class="mt-4" align-center>
                        <v-spacer />
                        <v-btn to="/" flat class="red--text">Cancel</v-btn>
                        <v-btn large type="submit" color="primary">Save</v-btn>
                      </v-layout>
                    </v-card-actions>
                  </v-form>
                </v-container>
              </v-card-text>
            </div>
            <!-- Success Screen (redirect to login) -->
            <div v-else>
              <v-card-title class="pt-5">
                <v-container>
                  <v-layout column align-center>
                    <v-icon size="40" color="green">check_circle</v-icon>
                  </v-layout>
                  <v-layout column align-center class="pt-5">
                    <p class="title font-weight-bold custom-text-center">
                      Your password has been changed successfully!
                    </p>
                  </v-layout>
                </v-container>
              </v-card-title>
              <v-card-text class="pt-5">
                <v-layout justify-center>
                  <v-btn color="primary" @click="navigateToLogin"
                    >Go To Login Page</v-btn
                  >
                </v-layout>
              </v-card-text>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppHeader from '@/components/Header';
export default {
  name: 'ProfileView',
  components: {
    AppHeader
  },
  data() {
    return {
      rules: {
        new: [
          v => !!v || 'Password is required',
          v => v.length >= 8 || 'Password is too short'
        ],
        confirm: [
          v => !!v || 'Password is required',
          v => v === this.user.newPassword || "Passwords don't match"
        ]
      },
      error: {
        currentPassword: '',
        newPassword: ''
      },
      user: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      isPassVisible: {
        current: false,
        new: false,
        confirm: false
      },
      // For future use if password requirements are expanded
      passwordRequirements: [
        {
          type: 'length',
          message: 'Min. 8 characters'
        }
      ],
      resetPrompt: true
    };
  },
  computed: {
    passwordStrength() {
      return this.calcPasswordStrength(this.user.newPassword.length);
    },
    passwordVisibileIcon() {
      return {
        current: this.isPassVisible.current ? 'visibility' : 'visibility_off',
        new: this.isPassVisible.new ? 'visibility' : 'visibility_off',
        confirm: this.isPassVisible.confirm ? 'visibility' : 'visibility_off'
      };
    },
    passwordErrorIcon() {
      return {
        current: this.$refs.currentPassword.hasError
          ? { icon: 'thumb_down', color: 'red' }
          : '',
        new: this.$refs.newPassword.hasError
          ? { icon: 'thumb_down', color: 'red' }
          : { icon: 'thumb_up', color: 'primary' },
        confirm: this.$refs.confirmPassword.hasError
          ? { icon: 'thumb_down', color: 'red' }
          : { icon: 'thumb_up', color: 'primary' }
      };
    }
  },
  methods: {
    ...mapActions(['doChangePassword']),
    // Helper for determining range
    rangeBetween(minLength, maxLength, x) {
      return x >= minLength && x <= maxLength;
    },
    isFormInvalid() {
      return !this.$refs.changePasswordForm.validate();
    },
    // Reset validation on input change to update user interface
    resetInputValidation(input) {
      this.error[input] = [];
      this.$refs[input].resetValidation();
    },
    reverseVisibility(type) {
      this.isPassVisible[type] = !this.isPassVisible[type];
    },
    navigateToLogin() {
      this.$router.push({ name: 'login' });
    },
    calcPasswordStrength(passLength) {
      // Handle password strength cases
      if (this.user.newPassword != '') {
        // Weak
        if (this.rangeBetween(1, 7, passLength)) {
          return {
            score: 1,
            message: 'Weak',
            color: 'red'
          };
        }
        // Medium
        else if (this.rangeBetween(8, 11, passLength)) {
          return {
            score: 2,
            message: 'Ok',
            color: 'orange'
          };
        }
        // Strong
        else if (this.rangeBetween(12, 16, passLength)) {
          return {
            score: 3,
            message: 'Good',
            color: 'light-green'
          };
        }
        // Absolute unit of a password
        else {
          return {
            score: 4,
            message: 'Strong',
            color: 'green'
          };
        }
      }
      // Base case for empty string
      return {
        score: 0,
        message: 'very weak',
        color: 'grey'
      };
    },
    // Assigns errors thrown from backend to respective input (allows)
    assignErrorType(customError) {
      this.error[customError.type] = [customError.message];
    },
    async updatePassword() {
      // Prevent flow if validation doesn't succeed
      if (this.isFormInvalid()) {
        return;
      }
      try {
        const res = await this.doChangePassword({
          password: this.user.currentPassword,
          newPassword: this.user.newPassword
        });
        // Success case for reset password
        if (res.status == 200) {
          // Show success Prompt
          this.resetPrompt = false;
        }
      } catch (err) {
        this.assignErrorType(err);
      }
    }
  }
};
</script>
<style scoped>
.custom-text-center {
  text-align: center !important;
}
</style>
