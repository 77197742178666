<template>
  <v-dialog max-width="800px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Add Custom Checklist</span>
      </v-card-title>
      <v-card-text>
        <!-- Checklist Stage -->
        <v-layout row>
          <v-flex xs12>
            <v-select
              :items="stages"
              label="Select Stage"
              @input="emitUpdate({ key: 'stage', value: $event })"
            />
          </v-flex>
        </v-layout>

        <!-- Checklist Name / Due At -->
        <v-layout>
          <v-flex xs12>
            <checklist-input-item
              :meeting-date="meetingDate"
              :meeting-timezone="meetingTimezone"
              :name="name"
              :due-at="dueAt"
              :stage="stage"
              :meta="meta"
              :show-print-options="false"
              @update="emitUpdate"
            />
          </v-flex>
        </v-layout>

        <!-- Checklist Items -->
        <v-layout row>
          <v-flex xs12>
            <label class="caption">Checklist Items</label>
            <code-mirror
              :code-array="checklistItems"
              @update="handleChecklistItemsUpdate"
            />
            <span class="caption grey--text text--darken-2">
              Note: append a <code>[p]</code> tag to the end of any line to
              designate it as a printing type item. Printing type items are
              specially noted in the checklist.
            </span>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.stop="emitClose">cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.native="emitAdd">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CodeMirror from '@/components/CodeMirror';
import ChecklistInputItem from '@/components/ChecklistInputItem';

export default {
  name: 'MeetingChecklistDialogAddCustom',
  components: {
    CodeMirror,
    ChecklistInputItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    stage: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    dueAt: {
      type: String,
      default: null
    },
    meetingDate: {
      type: String,
      default: null
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    meta: {
      type: Object,
      default() {
        return {};
      }
    },
    stages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      checklistItems: []
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitUpdate(obj) {
      obj.prop = 'newChecklist';
      this.$emit('update', obj);
    },
    emitAdd() {
      this.$emit('add', this.checklistItems);
      this.checklistItems = [];
      this.emitClose();
    },
    handleChecklistItemsUpdate(array) {
      this.checklistItems = array;
    }
  }
};
</script>

<style scoped></style>
