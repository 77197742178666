<template>
  <!-- Collaborators-->
  <v-layout>
    <v-flex xs12>
      <div class="gq-cell--section-title mb-4">
        <h4>Collaborators</h4>
        <p>
          These are non-account users that have been given explicit access to
          this campaign only (eg. partners).
        </p>
      </div>

      <v-toolbar dark dense color="grey lighten-4 elevation-1">
        <v-spacer />
        <v-btn color="primary" dark class="mb-2" @click="dialog = true">
          New Collaborator
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="collaborators"
        :rows-per-page-items="config.rowsPerPageItems"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <td>
            <span class="name">
              {{ props.item.user.firstName }}
              {{ props.item.user.lastName }}
            </span>
            <br />
            <span>
              <router-link
                :to="{
                  name: 'adminUserEdit',
                  params: { userKey: props.item.user.key }
                }"
                target="_blank"
              >
                {{ props.item.user.email }}
              </router-link>
            </span>
          </td>
          <td class="capitalize">{{ props.item.collaboratorType }}</td>
          <td><user-status-chip :status="isDisabled(props.item.user)" /></td>
          <td>
            {{ props.item.user.lastLoggedInAt | formatDate }}
          </td>
          <td>{{ props.item.createdAt | formatDate }}</td>
          <td class="text-xs-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon
                    class="mr-1"
                    :disabled="!!props.item.user.disabledAt"
                    @click="sendWelcome(props.item.user)"
                  >
                    mail
                  </v-icon>
                </span>
              </template>
              <span v-if="props.item.user.disabledAt">
                Cannot send welcome to disabled user
              </span>
              <span v-else>Resend welcome email</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon class="ml-1" @click="deleteItem(props.item.user)">
                    delete
                  </v-icon>
                </span>
              </template>
              <span>Remove collaborator</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-flex>

    <add-user-dialog
      :meeting-options-type="meeting.options.type"
      :is-open="dialog"
      title="Add collaborator"
      description="NOTE: The collaborator will receive an invite email."
      type="collaborator"
      @add-user="save"
      @close-dialog="dialog = false"
    />
  </v-layout>
</template>

<script>
import moment from 'moment';

import AddUserDialog from '@/components/dialogs/AddUserDialog';
import UserStatusChip from '@/components/UserStatusChip';

export default {
  name: 'MeetingUsersViewAccountUsersTable',
  components: {
    AddUserDialog,
    UserStatusChip
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    collaborators: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).fromNow() : 'Never';
    }
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'User', value: 'user.firstName' },
        { text: 'Collaborator Type', value: 'collaboratorType' },
        { text: 'Status', value: 'user.disabledAt' },
        { text: 'Last Login', value: 'user.lastLoggedInAt' },
        { text: 'Added', value: 'createdAt' },
        { text: '', sortable: false, align: 'right' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },
  computed: {},
  created() {},
  methods: {
    resetState() {
      this.state = {
        email: '',
        firstName: '',
        lastName: ''
      };
    },

    isDisabled({ disabledAt }) {
      return disabledAt ? 'disabled' : 'active';
    },
    sendWelcome(collaborator) {
      const { email } = collaborator;
      const isConfirm = confirm(
        `Re-send a collaborator welcome email to ${email}?`
      );
      if (!isConfirm) {
        return '';
      }
      this.$emit('welcome', email);
    },
    deleteItem(collaborator) {
      const { email } = collaborator;
      const isConfirm = confirm(
        `Are you sure you want to remove ${email} as a collaborator?`
      );
      if (!isConfirm) {
        return '';
      }
      this.$emit('delete', email);
    },
    close() {
      this.resetState();
      this.dialog = false;
    },
    save({ firstName, lastName, email, collaboratorType, scopes }) {
      this.$emit('save', {
        firstName,
        lastName,
        email,
        collaboratorType,
        scopes
      });
      this.close();
    }
  }
};
</script>

<style scoped>
.file-link {
  margin-right: 5px;
  white-space: nowrap;
}
.name {
  font-weight: bold;
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}
</style>
