<template>
  <!-- Notes Drawer-->
  <v-navigation-drawer
    app
    class="drawer-container"
    width="450px"
    clipped
    right
    v-resize="onResize"
    :temporary="showOverlay"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-container>
      <v-layout row wrap align-center>
        <v-flex xs12>
          <div class="space-between">
            <h5 class="headline font-weight-bold">Notes</h5>
            <v-btn flat icon class="pa-0 ma-0" @click="emitClose">
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>

      <v-divider class="gq-divider" />

      <v-layout align-baseline v-if="isCollaborator">
        <v-btn
          class="my-3"
          color="primary"
          flat
          text
          small
          :disabled="unreadNotes.length === 0 || markAllNotesLoading"
          @click="markAllNotes()"
        >
          Mark all as read
        </v-btn>
        <v-spacer />
        <span class="mr-3">Unread Notes: {{ unreadNotes.length }}</span>
      </v-layout>

      <v-layout class="my-2" align-baseline v-if="isAdmin">
        <v-select
          v-model="noteType"
          :items="filterItems"
          label="Filter"
          clearable
          hide-selected
          solo
          @change="handleUpdate"
        />
      </v-layout>

      <div class="notes-container">
        <meeting-notes
          :is-admin="isAdmin"
          :is-collaborator="isCollaborator"
          :user-key="userKey"
          :notes-list="notesList"
          :agent-notes-list="agentNotesList"
          :account-notes-list="accountNotesList"
          :default-type="noteType"
          @removeNote="removeNote"
          @markNote="markNote"
          @updateNoteType="updateNoteType"
          :meeting-timezone="meetingTimezone"
          :mark-notes-loading="markNotesLoading"
        />
      </div>
    </v-container>
    <div class="box-container">
      <meeting-notes-chatbox
        :default-type="isCollaborator ? 'collaborator' : 'meeting'"
        :is-collaborator="isCollaborator"
        :checklist-stages="checklistStages"
        :tagging-users="taggingUsers"
        :preferred-manager="preferredManager"
        :checklist-assignees="checklistAssignees"
        @addNewNote="addNote"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { startCase } from '@/filters';
import MeetingNotes from '@/components/MeetingNotes';
import MeetingNotesChatbox from '@/components/MeetingNotesChatbox';

export default {
  name: 'MeetingNotesDrawer',
  components: {
    MeetingNotes,
    MeetingNotesChatbox
  },
  filters: {
    startCase
  },
  props: {
    value: {
      type: Boolean,
      default: null
    },
    notesList: {
      type: Array,
      default() {
        return [];
      }
    },
    agentNotesList: {
      type: Array,
      default() {
        return [];
      }
    },
    accountNotesList: {
      type: Array,
      default() {
        return [];
      }
    },
    shortCode: {
      type: String,
      required: true
    },
    meetingKey: {
      type: String,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    unreadNotes: {
      type: Array,
      default() {
        return [];
      }
    },
    currentMeeting: {
      type: Object,
      default: null
    },
    checklists: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    value: function(value) {
      // When the note drawer is open, refresh the notes list
      if (value) this.setNotesList(this.shortCode);
      this.noteType = '';
    }
  },
  data() {
    return {
      dataLoaded: false,
      showNotesChatBox: false,
      showOverlay: false,
      userKey: null,
      noteType: '',
      markNotesLoading: false,
      markAllNotesLoading: false
    };
  },
  computed: {
    ...mapGetters(['login', 'isAdmin', 'scopes', 'getScopeByAction']),
    ...mapGetters('meetings/taggingUsers', ['taggingUsers']),
    ...mapGetters('meetings/notes', ['notesByShortCode']),
    ...mapGetters('checklists', ['checklistStages']),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters('agents', ['agentByKey']),
    isCollaborator() {
      const { profile } = this.login;

      if (this.scopes) {
        return (
          this.getScopeByAction(
            this.$route.params.shortcode,
            'meeting.notes.create'
          ) && profile.type === 'user'
        );
      }
      return false;
    },
    filterItems() {
      const items = [
        ...this.checklistStages,
        'invoice',
        'general',
        'collaborator',
        'agent',
        'complaint'
      ];
      return items;
    },
    preferredManager() {
      return this.currentMeeting.preferredManager || null;
    },
    checklistAssignees() {
      let assignees = [];

      for (const checklist of this.checklists) {
        if (checklist.meetingKey === this.meetingKey && checklist.assignees) {
          assignees = assignees.concat(
            checklist.assignees.map(assignee => assignee.email)
          );
        }
      }

      return assignees;
    }
  },
  created() {
    const { profile } = this.login;

    this.userKey = profile.key;
    this.setNotesList(this.shortCode, this.noteType);
    this.getChecklistStages();
    this.getTaggingUsers({ shortCode: this.shortCode });
  },
  mounted() {
    this.onResize();
  },
  methods: {
    ...mapActions('checklists', ['getChecklistStages']),
    ...mapActions('meetings/notes', [
      'getMeetingNotesList',
      'getAgentNotesList',
      'createMeetingNote',
      'removeMeetingNote',
      'markMeetingNote',
      'markAllMeetingNotes',
      'updateMeetingNote'
    ]),
    ...mapActions('meetings/taggingUsers', ['getTaggingUsers']),
    ...mapActions('agents', ['getAgentPreferredManagers', 'getAgent']),
    emitClose() {
      this.$emit('close');
    },
    setNotesList(shortCode, noteType = '', partnerType = '') {
      try {
        this.getMeetingNotesList({ shortCode, noteType });
        this.getAgentNotesList({ shortCode, noteType, partnerType });
        this.dataLoaded = true;
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      }
    },
    addNote({ newNote, noteType, taggedUsers }) {
      try {
        let payload = {
          message: newNote,
          type: noteType,
          taggedUsers: taggedUsers
        };
        this.createMeetingNote({ shortCode: this.shortCode, payload });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      }
    },
    removeNote(noteKey) {
      let isConfirm = confirm('Are you sure you want to remove this note?');

      if (isConfirm) {
        try {
          this.removeMeetingNote({ shortCode: this.shortCode, noteKey });
        } catch (err) {
          if (err.response) {
            this.error = err.response;
          } else if (err.request) {
            this.error = { status: 503, statusText: 'Service unavailable' };
          } else {
            console.log(err);
            this.error = { status: 520, statusText: 'Unknown Error' };
          }
        }
      }
    },
    handleUpdate(value) {
      if (_.isEmpty(value)) {
        this.setNotesList(this.shortCode);
        return;
      }
      this.setNotesList(this.shortCode, value);
    },
    // handlePartnerUpdate(value) {
    //   if (_.isEmpty(value)) {
    //     this.setNotesList(this.shortCode, this.noteType);
    //     return;
    //   }
    //   this.setNotesList(this.shortCode, this.noteType, value);
    // },
    async markNote(noteKey) {
      try {
        this.markNotesLoading = true;
        await this.markMeetingNote({ shortCode: this.shortCode, noteKey });
        this.markNotesLoading = false;
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      } finally {
        this.markNotesLoading = false;
      }
    },
    async markAllNotes() {
      let isConfirm = confirm('Are you sure you want to mark all notes?');

      const unreadNotes = _.filter(
        this.notesList,
        note => note.read === false
      ).map(note => note.key);

      const payload = { noteKeys: unreadNotes };
      if (isConfirm) {
        try {
          this.markAllNotesLoading = true;
          await this.markAllMeetingNotes({
            shortCode: this.shortCode,
            payload
          });
          this.markAllNotesLoading = false;
        } catch (err) {
          if (err.response) {
            this.error = err.response;
          } else if (err.request) {
            this.error = { status: 503, statusText: 'Service unavailable' };
          } else {
            console.log(err);
            this.error = { status: 520, statusText: 'Unknown Error' };
          }
        } finally {
          this.markAllNotesLoading = false;
        }
      }
    },
    onResize() {
      // On-resize, set drawer to overlay on smaller screen size
      this.showOverlay = window.innerWidth <= 1615 ? true : false;
    },
    updateNoteType({ noteKey, type }) {
      const payload = { type };

      try {
        this.updateMeetingNote({ shortCode: this.shortCode, noteKey, payload });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      }
    }
  }
};
</script>

<style scoped>
.v-list__tile__title {
  font-size: 14px !important;
}
.box-container {
  width: 99%;
  bottom: 0px;
  right: 0px;
  z-index: 5;
  position: absolute;
}
.notes-container {
  height: calc(100vh - 530px);
  overflow-y: auto;
}

@media only screen and (max-width: 1620px) {
  .notes-container {
    height: calc(100vh - 400px) !important;
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}

.v-navigation-drawer.drawer-container {
  background-color: #e7f2fb;
}
</style>
