<template>
  <v-tooltip left>
    <span slot="activator">
      {{ method }} <v-icon small>info</v-icon>
    </span>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ConsentMethodChip',
  props: {
    method: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    text() {
      switch (this.method) {
        case 'owner-direct':
          return 'User consented through consent tool';
        case 'on-proxy':
          return 'User consented through proxy/voting tool';
        case 'owner-requested':
          return 'Manually set by GQ as user made a request to consent (ie. through helpdesk)';
        case 'owner-provided':
          return 'Manually set by GQ as user made a request to consent (ie. through helpdesk)';
        case 'customer-provided':
          return 'Manually set by GQ as client made request on behalf of the user.';
        case 'customer-direct':
          return 'Manually set by GQ as client made request on behalf of the user.';
        default:
          return 'User consented through other means';
      }
    }
  }
};
</script>

<style scoped>
</style>
