import http from './requestType';

/**
 * Returns a list of all checklist templates
 * @return {Promise}          - Promise
 */
export function getChecklistTemplates() {
  return http._get(`ChecklistTemplates`);
}

/**
 * Creates a checklist template
 * @param  {Object} template  - the new checklist template
 * @return {Promise}          - Promise
 */
export function postChecklistTemplate(template) {
  return http._post(`ChecklistTemplates`, template);
}

/**
 * Delete the specified checklist template
 * @param  {String} templateId  - the checklist template id
 * @return {Promise}            - Promise
 */
export function deleteChecklistTemplate(templateId) {
  return http._delete(`ChecklistTemplates/${templateId}`);
}

/**
 * Get the specified checklist template
 * @param  {String} templateId   - the checklist template id
 * @return {Promise}             - Promise
 */
export function getChecklistTemplate(templateId) {
  return http._get(`ChecklistTemplates/${templateId}`);
}

/**
 * Updates the specified checklist template
 * @param  {String} templateId  - the checklist template id
 * @param  {Object} template    - the checklist template to use in the update
 * @return {Promise}            - Promise
 */
export function putChecklistTemplate(templateId, template) {
  return http._put(`ChecklistTemplates/${templateId}`, template);
}
