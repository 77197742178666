<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Agents (aka. "Partners")</p>
        <p class="secondary--text">
          View and manage all GetQuorum Agents.
        </p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ma-3">
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search by agent name"
            single-line
            hide-details
            clearable
            :value="search"
            @input="updateSearch"
          />
          <v-spacer />

          <v-btn color="primary" @click.stop="dialog.addAgent = true">
            <v-icon left dark>add</v-icon>Agent
          </v-btn>
        </v-toolbar>
        <v-data-table
          :items="agentList"
          :headers="headers"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :rows-per-page-items="pagination.rowsPerPageItems"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="nowrap name-cell">
                <span slot="activator">
                  <router-link
                    :to="{
                      name: 'editAgent',
                      params: { agentKey: props.item.key }
                    }"
                  >
                    {{ props.item.name }}
                  </router-link>
                </span>
              </td>
              <td class="nowrap">
                {{ props.item.agentType }}
              </td>
              <td>
                <v-chip
                  v-if="isPartner(props.item)"
                  color="blue lighten-1"
                  class="white--text"
                  small
                >
                  partner
                </v-chip>
              </td>
              <td class="nowrap">
                <span v-if="props.item.discount > 0">
                  {{ props.item.discount }}%
                </span>
                <span v-else>-</span>
              </td>
              <td class="nowrap">
                {{ props.item.totalUsers }}
              </td>
              <td class="nowrap">
                {{ props.item.totalAccounts }}
              </td>
              <td class="nowrap">
                {{ props.item.createdAt | dateFormatReadable }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog.addAgent" max-width="400px" persistent>
      <add-agent-dialog
        @closeDialog="dialog.addAgent = false"
        @emitAddAgent="addAgent"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatReadable } from '@/filters';
// Components
import AddAgentDialog from './AddAgentDialog.vue';
export default {
  name: 'AdminAgentsListView',
  filters: {
    dateFormatReadable
  },
  components: {
    AddAgentDialog
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Agent Name', sortable: false },
        { text: 'Type', sortable: false },
        { text: 'Partner', sortable: false },
        { text: 'Discount', sortable: false },
        { text: 'Users', sortable: false },
        { text: 'Accounts', sortable: false },
        { text: 'Created', sortable: false }
      ],
      dialog: {
        addAgent: this.$route.query.add || false
      }
    };
  },
  computed: {
    ...mapGetters('agents', ['agentList', 'pagination']),
    pagination: {
      get: function() {
        return this.$store.getters['agents/pagination'];
      },
      set: async function(value) {
        this.setPagination(value);
        await this.getAgentListData();
      }
    }
  },
  // Hot reload when local navigation -- prevents same search result
  created() {
    this.setPagination({
      descending: true,
      sortBy: 'createdAt',
      search: '',
      page: 1,
      rowsPerPage: 25,
      totalItems: 0,
      rowsPerPageItems: [25, 50, 100, 200]
    });
  },
  methods: {
    ...mapActions('agents', [
      'getAgentListData',
      'createAgent',
      'setPagination'
    ]),
    async addAgent(agentPayload) {
      try {
        await this.createAgent(agentPayload);
        this.$events.$emit('toastEvent', 'New Agent Added');
        this.dialog.addAgent = false;
      } catch (err) {
        this.$events.$emit('toastEvent', err.message);
      }
    },
    isPartner(item) {
      if (item.partnerType) {
        return item.partnerType.toLowerCase() === 'partner';
      }
      return false;
    },
    updateSearch: _.debounce(async function(value) {
      const newPagination = _.cloneDeep(this.pagination);
      this.setPagination(newPagination);
      await this.getAgentListData(value);
    }, 350)
  }
};
</script>

<style scoped>
.name-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.fade {
  opacity: 0.74;
}
</style>
