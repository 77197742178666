<template>
  <v-container v-if="lastDataFetched" grid-list-md fluid>
    <v-layout row class="ma-3">
      <v-flex xs12>
        <p class="title">Edit: {{ currentTemplateGroup.name }}</p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ml-3 mr-3">
        <v-card>
          <v-card-title class="headline">
            Checklist Template Group Details
          </v-card-title>
          <v-card-text>
            <input-info
              :name="state.name"
              :description="state.description"
              @update="handleUpdate"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn flat class="accent--text" @click="dialog.deleteGroup = true">
              <v-icon>delete_forever</v-icon>Delete Checklist Template Group
            </v-btn>
            <v-spacer />
            <v-btn
              class="accent"
              @click="updateTemplateGroup"
              :disabled="!isChanged"
            >
              <v-icon left>save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-4">
          <v-card-title class="headline pb-0">
            Checklist Template Group Management
          </v-card-title>
          <v-card-text>
            <v-toolbar dense color="grey lighten-4 elevation-1">
              <v-spacer />
              <v-btn
                class="primary"
                dark
                @click.native="dialog.addTemplate = true"
              >
                <v-icon left>add</v-icon>Add
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="items"
              item-key="checklistTemplateKey"
              v-sortable-data-table
              @sorted="saveOrder"
              :rows-per-page-items="config.rowsPerPageItems"
            >
              <template slot="items" slot-scope="props">
                <tr class="grab" :key="props.item.checklistTemplateKey">
                  <td>
                    <v-icon>reorder</v-icon>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="props.item.checklistLabel"
                      @save="handleMembershipSave(props.item)"
                      large
                      persistent
                    >
                      {{ props.item.checklistLabel }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.checklistLabel"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    {{ props.item.name }}
                  </td>
                  <td>{{ props.item.listOrder + 1 }}</td>
                  <td>
                    {{ props.item.stage }}
                  </td>
                  <td>
                    {{ props.item.items.length }}
                  </td>
                  <td>
                    <div class="d-inline-flex">
                      <v-tooltip bottom>
                        <div slot="activator">
                          <v-icon
                            class="ml-1 pointer"
                            color="error"
                            @click="handleRemoveTemplate(props.item)"
                            >remove_circle</v-icon
                          >
                        </div>
                        <span>Remove Checklist Template</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Add Template Dialog -->
    <add-checklist-template-dialog
      v-model="dialog.addTemplate"
      :templates-list="filteredChecklistTemplatesList"
      @add="addTemplate"
      @close="dialog.addTemplate = false"
    />

    <!-- Delete Template Dialog -->
    <v-dialog v-model="dialog.deleteTemplate" persistent max-width="550px">
      <v-card>
        <v-card-title class="title">Remove Checklist Template?</v-card-title>
        <v-card-text>
          Are you sure you want to remove the
          <b>{{ dialog.template ? dialog.template.name : '' }}</b> checklist
          template from this group?
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialog.deleteTemplate = false"
            >cancel</v-btn
          >
          <v-spacer />
          <v-btn class="primary" @click.native="removeTemplate">remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Template Group Dialog -->
    <v-dialog v-model="dialog.deleteGroup" persistent max-width="550px">
      <v-card>
        <v-card-title class="title"
          >Delete Checklist Template Group?</v-card-title
        >
        <v-card-text>
          Are you sure you want to delete this checklist template group?
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialog.deleteGroup = false">cancel</v-btn>
          <v-spacer />
          <v-btn class="primary" @click.native="deleteTemplateGroup"
            >delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import Sortable from 'sortablejs';
import { mapActions, mapGetters } from 'vuex';

import InputInfo from '@/components/input/InputTemplateGroupInfo';
import AddChecklistTemplateDialog from './AdminChecklistTemplateGroupsAddTemplateDialog';

export default {
  name: 'AdminChecklistTemplateGroupsView',
  components: {
    InputInfo,
    AddChecklistTemplateDialog
  },
  props: {
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  data() {
    return {
      templateGroupKey: this.$route.params.checklistTemplateGroupKey,
      state: { key: '', name: '', description: '', isDefaultGroup: null },
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false
        },
        {
          text: 'Checklist Label (Click to Edit)',
          align: 'left',
          sortable: false,
          value: 'checklistLabel'
        },
        {
          text: 'Checklist Template',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Checklist Order',
          align: 'left',
          value: 'listOrder',
          sortable: false
        },
        {
          text: 'Stage',
          align: 'left',
          value: 'stage',
          sortable: false
        },
        {
          text: 'Number of Checklist Items',
          align: 'left',
          value: 'items.length',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          sortable: false
        }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      },
      items: [],
      dialog: {
        template: {},
        addTemplate: false,
        deleteTemplate: false,
        deleteGroup: false
      }
    };
  },
  computed: {
    ...mapGetters('checklistTemplates', ['checklistTemplatesListData']),
    ...mapGetters('checklistTemplateGroups', ['checklistTemplateGroupByKey']),
    currentTemplateGroup() {
      return this.checklistTemplateGroupByKey(this.templateGroupKey) || {};
    },
    isChanged() {
      return !_.isEqual(this.state, this.currentTemplateGroup);
    },
    filteredChecklistTemplatesList() {
      if (this.items) {
        const existingTemplateKeys = this.items.map(
          i => i.checklistTemplateKey
        );

        const filteredChecklistTemplates = this.checklistTemplatesListData.filter(
          checklist => {
            return !existingTemplateKeys.includes(checklist.key);
          }
        );

        return filteredChecklistTemplates;
      }

      return this.checklistTemplatesListData;
    }
  },
  watch: {
    currentTemplateGroup: function() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('checklistTemplates', ['getChecklistTemplates']),
    ...mapActions('checklistTemplateGroups', [
      'getChecklistTemplateGroups',
      'deleteChecklistTemplateGroup',
      'updateChecklistTemplateGroup',
      'createChecklistTemplateGroupMembership',
      'updateChecklistTemplateGroupMembership',
      'deleteChecklistTemplateGroupMembership'
    ]),
    handleUpdate(obj) {
      const newState = { ...this.state };
      _.set(newState, obj.key, obj.value);
      this.$set(this, 'state', newState);
    },
    setState() {
      // Ensure we have the current template group object, and its checklist items
      this.state = _.cloneDeep(this.currentTemplateGroup);
      this.items = _.cloneDeep(this.currentTemplateGroup.checklistTemplates);

      // Also grab all existing checklist templates
      this.getChecklistTemplates();
    },
    saveOrder(event) {
      const movedItem = this.items.splice(event.oldIndex, 1)[0];
      this.items.splice(event.newIndex, 0, movedItem);

      const itemsWithOrder = this.items.map((item, index) => {
        item.listOrder = index;
        return item;
      });

      const payload = itemsWithOrder;
      this.updateTemplates(payload);
    },
    updateOrder() {
      const itemsWithOrder = this.items.map((item, index) => {
        item.listOrder = index;
        return item;
      });

      const payload = itemsWithOrder;

      this.updateTemplates(payload);
    },
    async updateTemplates(payload) {
      try {
        await this.updateChecklistTemplateGroupMembership({
          groupKey: this.state.key,
          payload
        });

        this.$events.$emit('toastEvent', 'Templates updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateTemplateGroup() {
      try {
        const { key, name, description, isDefaultGroup } = this.state;
        const payload = {
          key: key,
          name: name,
          description: description ? description : name,
          isDefaultGroup
        };

        await this.updateChecklistTemplateGroup({
          key: this.templateGroupKey,
          payload: payload
        });

        this.$events.$emit('toastEvent', 'Checklist Template Group Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteTemplateGroup() {
      try {
        await this.deleteChecklistTemplateGroup(this.templateGroupKey);

        this.$events.$emit('toastEvent', 'Checklist Template Group Deleted');
        this.$router.push({ name: 'adminChecklistTemplateGroups' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async addTemplate(selectedTemplates) {
      try {
        let count = this.items.length;

        selectedTemplates.forEach(async element => {
          await this.createChecklistTemplateGroupMembership({
            groupKey: this.state.key,
            templateKey: element.key,
            checklistLabel: element.name,
            listOrder: count++
          });
        });

        this.$events.$emit('toastEvent', 'Checklist Templates Added');
        this.dialog.addTemplate = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleRemoveTemplate(template) {
      this.dialog.template = template;
      this.dialog.deleteTemplate = true;
    },
    async removeTemplate() {
      try {
        await this.deleteChecklistTemplateGroupMembership({
          groupKey: this.dialog.template.checklistTemplateGroupKey,
          templateKey: this.dialog.template.checklistTemplateKey
        });

        this.dialog.template = {};
        this.dialog.deleteTemplate = false;
        this.updateOrder();

        this.$events.$emit('toastEvent', 'Conversation Template Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleMembershipSave(membership) {
      try {
        const payload = [membership];
        await this.updateChecklistTemplateGroupMembership({
          groupKey: this.state.key,
          payload
        });

        this.$events.$emit('toastEvent', 'Checklist Label Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            vnode.child.$emit('sorted', event);
          }
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      }
    }
  }
};
</script>

<style scoped>
.grab {
  cursor: grab;
}
</style>
