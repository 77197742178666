<template>
  <v-container>
    <v-layout>
      <v-flex xs12>
        <v-alert
          type="info"
          :value="true"
          outline
          class="mb-3"
          style="font-size: 18px;"
          id="sect-info"
        >
          <strong>Important:</strong>
          <ul>
            <li>
              Only candidates who submitted their nomination via
              <strong>GetQuorum’s Candidate Nomination Tool</strong>
              will be listed in this section.
            </li>
            <li>
              All candidates who were included in the meeting documents you
              provided to us will be included in the online voting tools.
            </li>
          </ul>
        </v-alert>

        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <!-- PDF Link online -->
          <a
            href="https://d1v53h6w11vaa4.cloudfront.net/downloads/Candidate_Disclosure_Form.pdf"
            target="_blank"
          >
            <v-btn
              v-if="!isFloridaNominationToolEnabled"
              color="primary"
              class="ml-0"
              dark
              flat
            >
              <v-icon left> file_download </v-icon>
              Blank Disclosure form
            </v-btn>
            <v-spacer />
          </a>
          <v-spacer />
          <v-btn
            color="primary"
            class="ml-0"
            dark
            :loading="exporting"
            @click="handleExport"
          >
            <v-icon left>file_download</v-icon> Download All Files
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="nominationsListData"
          :rows-per-page-items="config.rowsPerPageItems"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.createdAt | dateFormatReadable }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.unit }}</td>
            <td>{{ props.item.email }}</td>
            <td>
              <a
                v-if="hasFile(props.item.file)"
                class="file-link"
                @click="handleDownload(props.item.file)"
              >
                <v-icon small>attach_file</v-icon>
                {{
                  isFloridaNominationToolEnabled
                    ? 'Notice of Intent'
                    : 'Disclosure'
                }}
              </a>

              <a
                v-if="hasFile(props.item.resume)"
                class="file-link"
                @click="handleDownload(props.item.resume)"
              >
                <v-icon small>attach_file</v-icon> Resume
              </a>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatReadable } from '@/filters';
import forceDownloadUrl from '@/lib/force-download-url';

export default {
  name: 'MeetingNominationsView',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  filters: {
    dateFormatReadable
  },
  data() {
    return {
      exporting: false,
      headers: [
        { text: 'Date Submitted', value: 'createdAt' },
        { text: 'Name', value: 'name' },
        { text: 'Unit', value: 'unit' },
        { text: 'Email', value: 'email' },
        { text: 'Files', value: 'file', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },
  computed: {
    ...mapGetters('meetings/nominations', ['nominationsListData']),
    isFloridaNominationToolEnabled() {
      return this.currentMeeting.options?.enableFloridaNominationTool;
    }
  },
  created() {
    this.getMeetingNominations(this.currentMeeting.shortCode);
  },
  methods: {
    ...mapActions('meetings/nominations', [
      'getMeetingNominations',
      'exportMeetingNominations'
    ]),
    hasFile(file) {
      if (!file) {
        return false;
      }
      // Ensure that the file object has both the Key and Bucket to retrieve the file
      return file.Bucket && file.Key;
    },
    async handleDownload({ url, name }) {
      try {
        await forceDownloadUrl({ url, name });
        this.$events.$emit('toastEvent', 'Downloading File');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleExport() {
      try {
        this.exporting = true;
        await this.exportMeetingNominations(this.currentMeeting.shortCode);
        this.$events.$emit('toastEvent', 'Exporting Nominations');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.exporting = false;
      }
    }
  }
};
</script>

<style scoped>
.file-link {
  margin-right: 5px;
  white-space: nowrap;
}
</style>
