<template>
  <div>
    <div v-for="(result, index) in business" :key="`key-${index}`">
      <div class="gq-cell--section-title">
        {{ result.name }}
        <v-tooltip right>
          <span slot="activator">
            <v-chip
              v-if="result.isSecret"
              label
              small
              color="grey"
              text-color="white"
              ><strong
                >Secret
                <span v-if="isVotingClose" label text-color="white">
                  - Results now visible
                </span></strong
              >
            </v-chip>
          </span>
          <span
            >Tallies and results for this vote are visible only to admins and
            collaborators</span
          >
        </v-tooltip>
      </div>

      <v-layout row class="mb-3">
        <v-flex xs12>
          <vote-tally-table
            :business-type="result.type"
            :business-id="result.id"
            :business-name="result.name"
            :tally="result.tally"
            :is-secret="result.isSecret"
            :is-voting-close="isVotingClose"
            :can-see-results="canSeeSecretTallyData"
            @exportClicked="exportClicked"
            :enableVoteBreakdown="enableVoteBreakdown"
            :enableVoteBreakdownDate="enableVoteBreakdownDate"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VoteTallyTable from '@/components/VoteTallyTable';
import checkScope from '@/lib/check-user-scopes';
import exportTallyToCSV from '@/lib/export-vote-tally';

export default {
  name: 'VoteTally',
  components: {
    VoteTallyTable
  },
  props: {
    business: {
      type: Array,
      default() {
        return [];
      }
    },
    isVotingClose: {
      type: Boolean,
      default: false
    },
    enableVoteBreakdown: {
      type: Boolean,
      default: false
    },
    enableVoteBreakdownDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    canSeeSecretTallyData() {
      // Currently admins and collaborators (moderators/partners) can see the tally tables
      // Even when set as a secret question
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early')
      );
    }
  },
  methods: {
    viewDataClicked(id) {
      this.$router.push({
        name: 'meetingData',
        params: {
          shortcode: this.$route.params.shortcode,
          questionId: id
        }
      });
    },
    exportClicked(name, tally) {
      exportTallyToCSV({ name, tally });
    }
  }
};
</script>
<style scoped>
.action-divider {
  border: none;
  display: block;
  height: 1px;
  min-height: 1px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  background-color: #e0e0e0;
}
.num-vote-text {
  font-size: 15px;
}
</style>
