'use strict';

/**
 * @fileoverview Vuex module for notes
 */

import _ from 'lodash';
import Vue from 'vue';
import {
  postNote,
  getNotes,
  getAgentNotes,
  deleteNote,
  markNote,
  markAllNotes,
  putNote
} from '@/api/meetings-notes';

const state = {
  notes: {},
  agentNotes: {},
  notesDrawer: null,
  pusher: {}
};

const getters = {
  notesDrawerState: state => state.notesDrawer,
  notesList: state => state.notes,
  agentNotesList: state => state.agentNotes,
  agentNotesByShortCode: state => shortCode => state.agentNotes[shortCode],
  notesByShortCode: state => shortCode => state.notes[shortCode],
  invoiceNotesByShortCode: state => shortCode => {
    return _.filter(state.notes[shortCode], note => {
      return note.type === 'invoice';
    });
  }
};

const actions = {
  /**
   * Get list of all meeting notes for a specific meeting key
   *
   * @param  {Object} key - The meeting key
   * @param  {Object} noteType - The note type
   * @return {Object}
   */
  async getMeetingNotesList({ commit }, { shortCode, noteType }) {
    try {
      const res = await getNotes(shortCode, noteType);

      commit('SET_MEETING_NOTES', { shortCode, data: res.data });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get list of all agents from a specified meeting. (notes tied to an agent)
   * @param {string} shortCode   - the current meeting shortcode
   * @param {string} noteType    - explicit agent filter type
   * @param {string} partnerType - partner type filter
   */
  async getAgentNotesList({ commit }, { shortCode, noteType, partnerType }) {
    try {
      if (noteType && noteType != 'agent') {
        commit('SET_AGENT_MEETING_NOTES', { shortCode, data: [] });
        return;
      }
      const res = await getAgentNotes(shortCode, partnerType);
      commit('SET_AGENT_MEETING_NOTES', { shortCode, data: res.data });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Create a new note
   *
   * @param  {Object} payload  the new note to create
   * @return {Object}
   */
  async createMeetingNote({ dispatch }, { shortCode, payload }) {
    try {
      await postNote(shortCode, payload);
      dispatch('getMeetingNotesList', { shortCode, noteType: '' });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove a note
   *
   * @param  {Object} noteKey - the noteKey of the note to delete
   * @return {Object}
   */
  async removeMeetingNote({ dispatch }, { shortCode, noteKey }) {
    try {
      await deleteNote(shortCode, noteKey);
      dispatch('getMeetingNotesList', { shortCode, noteType: '' });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Mark a note as read
   *
   * @param  {Object} payload - the notekey to be stored as read
   * @return {Object}
   */
  async markMeetingNote({ dispatch }, { shortCode, noteKey }) {
    try {
      await markNote(shortCode, noteKey);
      dispatch('getMeetingNotesList', { shortCode, noteType: '' });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Mark all notes as read
   *
   * @param  {Object} shortCode - the noteKey of the note to be read
   * @return {Object}
   */
  async markAllMeetingNotes({ dispatch }, { shortCode, payload }) {
    try {
      await markAllNotes(shortCode, payload);
      dispatch('getMeetingNotesList', { shortCode, noteType: '' });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update meeting note type
   *
   * @param {object} params
   * @param {string} params.shortCode     - meeting shortcode
   * @param {string} params.noteKey       - note key to be updated
   * @param {object} params.payload       - note payload
   * @param {string} params.payload.type  - new note type
   * @return {Object}
   */
  async updateMeetingNote({ dispatch }, { shortCode, noteKey, payload }) {
    try {
      await putNote({ shortCode, noteKey, payload });
      dispatch('getMeetingNotesList', { shortCode, noteType: '' });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Toggle notes drawer
   *
   * @param  {Boolean} toggle - state of the drawer
   * @return {Object}
   */
  async toggleNoteDrawer({ commit }, toggle) {
    try {
      commit('TOGGLE_NOTE_DRAWER', toggle);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_NOTES(state, { shortCode, data }) {
    // Set the notes (array) for a particular meeting, key by meetingKey
    Vue.set(state.notes, shortCode, data);
  },
  SET_AGENT_MEETING_NOTES(state, { shortCode, data }) {
    Vue.set(state.agentNotes, shortCode, data);
  },
  TOGGLE_NOTE_DRAWER(state, toggle) {
    Vue.set(state, 'notesDrawer', toggle);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
