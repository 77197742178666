<template>
  <v-card>
    <v-card-title>
      <span class="title">Invoice Info</span>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs4>
          <v-menu
            v-model="menu"
            lazy
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              label="Invoice Date"
              v-model="state.invoiceDate"
              readonly
              box
            />
            <v-date-picker v-model="state.invoiceDate" no-title />
          </v-menu>
        </v-flex>
        <v-spacer />
        <v-flex xs4>
          <v-text-field
            slot="activator"
            label="Purchase Order (Optional)"
            placeholder="eg. PO #123456789"
            v-model="state.additionalData.purchaseOrder"
            box
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-textarea
            name="input-bill-to"
            label="Bill To"
            :placeholder="`${defaultBillTo} (default) `"
            id="input-bill-to"
            rows="1"
            :value="billTo"
            v-model="state.additionalData.billTo"
            box
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-textarea
            name="input-notes"
            label="Optional memo to client - bottom of invoice"
            placeholder="eg. Thanks for your business!"
            id="input-notes"
            auto-grow
            box
            :value="notes"
            v-model="state.additionalData.notes"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-textarea
            name="input-private-notes"
            label="Admin Only Notes (won't be seen by client)"
            placeholder="eg. Add a discount for xyz reason..."
            id="input-private-notes"
            rows="2"
            :value="privateNotes"
            v-model="state.additionalData.privateNotes"
            box
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click.native="resetInfo"
        :disabled="!infoChanged || saving"
        >Undo</v-btn
      >
      <v-btn
        flat
        color="primary"
        @click.native="updateInfo"
        :loading="saving"
        :disabled="!infoChanged || saving"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import * as filters from '@/filters';
import { getColor } from '@/helpers';

export default {
  name: 'AdminInvoiceViewInfoCard',
  props: {
    invoiceKey: {
      type: String,
      required: true
    },
    accountKey: {
      type: String,
      required: true
    },
    meetingCode: {
      type: String,
      default() {
        return '';
      }
    },
    invoiceDate: {
      type: [Date, String],
      default() {
        return '';
      }
    },
    billTo: {
      type: String,
      default() {
        return '';
      }
    },
    notes: {
      type: String,
      default() {
        return '';
      }
    },
    defaultBillTo: {
      type: String,
      default() {
        return '';
      }
    },
    privateNotes: {
      type: String,
      default() {
        return '';
      }
    },
    purchaseOrder: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      menu: false,
      saving: false,
      state: {
        invoiceDate: '',
        additionalData: {
          billTo: '',
          notes: '',
          privateNotes: '',
          purchaseOrder: ''
        }
      }
    };
  },
  filters: {
    ...filters
  },
  computed: {
    infoChanged() {
      const checker = {
        invoiceDate: moment(this.invoiceDate).format('YYYY-MM-DD'),
        additionalData: {
          billTo: this.billTo,
          notes: this.notes,
          privateNotes: this.privateNotes,
          purchaseOrder: this.purchaseOrder
        }
      };
      return !_.isEqual(this.state, checker);
    }
  },
  watch: {
    billTo: function() {
      this.resetInfo();
    },
    notes: function() {
      this.resetInfo();
    },
    invoiceDate: function() {
      this.resetInfo();
    },
    purchaseOrder: function() {
      this.resetInfo();
    }
  },
  created() {
    this.resetInfo();
  },
  methods: {
    ...mapActions('invoices', ['updateInvoice']),
    getColor,
    async updateInfo() {
      try {
        this.saving = true;
        await this.updateInvoice({
          key: this.invoiceKey,
          accountKey: this.accountKey,
          meetingCode: this.meetingCode,
          payload: this.state
        });
        this.$events.$emit('toastEvent', 'Successfully saved invoice info');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        throw err;
      } finally {
        this.saving = false;
      }
    },
    resetInfo() {
      this.state = {
        invoiceDate: moment(this.invoiceDate).format('YYYY-MM-DD'),
        additionalData: {
          billTo: this.billTo,
          notes: this.notes,
          privateNotes: this.privateNotes,
          purchaseOrder: this.purchaseOrder
        }
      };
    }
  }
};
</script>

<style scoped></style>
