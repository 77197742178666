<template>
  <v-layout row wrap>
    <v-flex xs12 v-if="loading" class="h-flex">
      <v-progress-circular indeterminate color="primary" />
    </v-flex>
    <v-flex xs12 v-if="edit">
      <file-upload-form
        multiple-files
        :attachment="docFiles"
        @upload="handleFileChange"
      />
    </v-flex>
    <v-flex v-else-if="docFiles.length <= 0" class="v-flex grey--text">
      <v-icon x-large color="grey">plagiarism</v-icon>
      <p class="pt-2 mb-0">
        No documents
      </p>
      <p class="caption">Uploaded documents will appear here</p>
    </v-flex>
    <v-flex class="pa-0" xs12 v-else>
      <ul class="doc-list">
        <li class="pb-2" v-for="doc in docFiles" :key="doc.Key">
          <v-tooltip bottom>
            <a slot="activator" @click="openFile(doc.url)">
              {{ doc.name }} ({{ doc.size | fileSize }})
            </a>
            <span>Click to open</span>
          </v-tooltip>
        </li>
      </ul>
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import filesize from 'filesize';
import { mapActions, mapGetters } from 'vuex';
import checkScope from '@/lib/check-user-scopes';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import FileUploadForm from '@/components/FileUploadForm';
export default {
  name: 'DocumentsContent',
  components: {
    MeetingOverviewCard,
    FileUploadForm
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  data() {
    return {
      loading: true,
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('v2/documents', ['documents']),
    docFiles() {
      if (!this.documents) {
        return [];
      } else {
        return this.documents.map(file => file.document);
      }
    },
    isMeeting() {
      return _.get(this.currentMeeting, 'options.type', '') === 'meeting';
    },
    canUploadFiles() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.files.create')
      );
    }
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    ...mapActions('v2/documents', [
      'fetchMeetingDocuments',
      'addMeetingDocument',
      'removeMeetingDocument'
    ]),
    async init() {
      try {
        this.loading = true;
        await this.fetchMeetingDocuments({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    handleFileChange(res) {
      try {
        if (!this.documents || res.length > this.documents.length) {
          // Case 1: Adding a new meeting doc
          const documentToAdd = res[res.length - 1];
          this.addMeetingDocument({
            shortCode: this.shortCode,
            document: documentToAdd
          });
          this.$events.$emit('toastEvent', 'Document Added');
        } else {
          // Case 2: Removing an existing meeting doc
          const newUrls = res.map(r => r.url);
          // Figure out which file is being deleted,
          // because we need to access the document key
          const documentToDelete = this.documents.filter(
            att => !newUrls.includes(att.document.url)
          );
          const keyToDelete = documentToDelete[0].key;
          this.removeMeetingDocument({
            shortCode: this.shortCode,
            documentKey: keyToDelete
          });
          this.$events.$emit('toastEvent', 'Document Removed');
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openFile(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped>
.doc-list {
  list-style-type: none;
}
.h-flex {
  display: flex;
  justify-content: center;
}
.v-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
