'use strict';

export const defaults = {
  businessObj: {
    name: '',
    header: '',
    snippet: '',
    type: '',
    positions: 0,
    options: [],
    template: '',
    meta: {}
  },
  hideIcons: false,
  toggles: {
    yes: {
      label: 'Yes',
      visible: true
    },
    no: {
      label: 'No',
      visible: true
    },
    abstain: {
      label: 'I choose not to vote',
      visible: true
    },
    defer: {
      label: 'I give my proxy authority to vote',
      visible: true
    }
  },
  customAnswerText: {
    yes: '(IN FAVOUR) Yes, I am in favour',
    no: '(AGAINST) No, I am against',
    abstain:
      '(ABSTAIN) I abstain from voting and my proxy shall not vote on this matter',
    defer: '(DEFER) I give my proxy authority to vote'
  },
  electionSettings: {
    hideSeatsText: false,
    hideWriteInCandidate: false,
    hideChoiceRanking: false,
    optionalVotes: 0
  }
};
