<template>
  <v-avatar size="40px" color="primary lighten-2">
    <v-icon color="white">{{ icon }}</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: 'CampaignAvatar',
  props: {
    campaignType: {
      type: String,
      default() {
        return '';
      }
    },
    hideBilling: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    icon() {
      switch (this.campaignType) {
        case 'meeting':
          return 'event';
        case 'budget':
          return 'attach_money';
        case 'certs':
          return 'assessment';
        default:
          return 'help';
      }
    }
  }
};
</script>

<style scoped></style>
