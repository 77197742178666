<template>
  <v-dialog persistent :value="isOpen" max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">Add account to user</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout row>
            <v-flex xs12>
              <v-autocomplete
                :items="accountListDataFiltered"
                item-text="nameShort"
                item-value="key"
                v-model="accountKeySelected"
                :search-input.sync="search"
                label="Search by shortcode"
                :loading="searching"
                cache-items
                solo
              />

              <p class="mt-3 caption">
                NOTE: The user will receive a notification that the account has
                been added to their profile.
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>Close</v-btn>
        <v-btn color="primary" @click.native="addMembership" flat
          >Add Membership</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddUserMembershipDialog',
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    memberships: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      accountKeySelected: null,
      search: '',
      searching: false
    };
  },
  watch: {
    search() {
      this.updateSearch(this.search);
    }
  },
  computed: {
    ...mapGetters('accounts', ['accountListData', 'dataLastFetchedAt']),
    accountKeysAlreadyAMember() {
      return this.memberships.map(i => i.accountKey);
    },
    accountListDataFiltered() {
      // Gets the accounts that are not already members
      return this.accountListData.filter(i => {
        return !this.accountKeysAlreadyAMember.includes(i.key);
      });
    }
  },
  created() {
    // Check if account data has already been loaded, otherwise load it.
    if (!this.dataLastFetchedAt) {
      this.getAccountListData();
    }
  },
  methods: {
    ...mapActions('accounts', ['getAccountListData', 'setSearch']),
    close() {
      this.$emit('close-dialog');
    },
    addMembership() {
      this.$emit('add-membership', this.accountKeySelected);
      this.close();
    },
    updateSearch: _.debounce(async function(search) {
      this.searching = true;
      await this.setSearch({ search, shortcodeOnly: true });
      await this.getAccountListData();
      this.searching = false;
    }, 350)
  }
};
</script>

<style scoped></style>
