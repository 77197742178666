'use strict';

/**
 * @fileoverview The vuex submodule for admin/print job user preferences.
 *
 * NOTE: To see the Print Job API actions, see the `@/vuex/modules/print-jobs`
 * file.
 */
import _ from 'lodash';
import Vue from 'vue';

import * as printJobApi from '@/api/print-jobs';

const state = {
  printJobList: {},
  vendorList: {},
  dataLastFetchedAt: null,
  statusFilter: 'activeOnly',
  pagination: {
    sortBy: 'updatedAt',
    searchText: '',
    descending: true,
    rowsPerPage: 25,
    page: 1,
    totalItems: 0
  }
};

const getters = {
  printJobList: state => state.printJobList,
  printJobData: state => Object.values(state.printJobList),
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  vendorList: state => state.vendorList,
  vendorListData: state => {
    return Object.values(state.vendorList);
  },
  vendorByKey: state => key => state.vendorList[key],
  printJobByKey: state => key => state.printJobList[key],
  statusFilter: state => state.statusFilter,
  pagination: state => state.pagination
};

const actions = {
  async getPrintJobList({ commit }, { status } = {}) {
    try {
      const res = await printJobApi.getPrintJobList({
        status,
        searchText: state.pagination.searchText,
        page: state.pagination.page,
        size: state.pagination.rowsPerPage
      });
      const { printJobs = [], totalNum = 0 } = res.data;
      commit('SET_PRINT_JOB_LIST_DATA', { printJobs, totalNum });
    } catch (err) {
      console.error('Error: getPrintJobList action', err);
      throw err;
    }
  },

  async getVendors({ commit }) {
    try {
      const res = await printJobApi.getVendorList();
      const data = res.data;
      commit('SET_PRINT_JOB_VENDOR_LIST_DATA', data);
    } catch (err) {
      console.error('ERROR: getVendors action', err);
      throw err;
    }
  },

  async removePrintJob({ dispatch }, { jobKey }) {
    try {
      await printJobApi.deletePrintJob(jobKey);
      dispatch('getPrintJobList', {});
    } catch (err) {
      console.error('ERROR: removePrintJob() action failed', err);
      throw err;
    }
  },

  async getPrintJobItems({ commit }, jobKey) {
    try {
      const res = await printJobApi.getPrintJobItems(jobKey);
      // @todo this should be replaced with pre-loaded items in getPrintJobList()
      return res.data;
    } catch (err) {
      console.error('ERROR: getPrintJobItems() action failed', err);
      throw err;
    }
  },

  /**
   * Save print job items
   * @param {String} jobKey - the print job key
   * @param {Array} jobItems - list of print job item objects to be saved
   * @param {Boolean} force - overrides access restriction imposed by status
   */
  async savePrintJobItems({ commit }, { jobKey, jobItems, force }) {
    try {
      let structuredItems = [];
      for (let jobType in jobItems) {
        if (!_.isEmpty(jobItems[jobType]) && jobItems[jobType].length > 0) {
          for (let itemData of jobItems[jobType]) {
            let jobItem = {
              jobKey,
              jobItemType: jobType,
              filePackage: {
                Bucket: itemData.Bucket,
                Key: itemData.Key,
                size: itemData.size,
                name: itemData.name,
                mimetype: itemData.mimetype,
                url: itemData.url
              },
              fileMeta: {}
            };
            structuredItems.push(jobItem);
          }
        }
      }
      await printJobApi.postAllPrintJobItems(jobKey, structuredItems, force);
    } catch (err) {
      console.error('ERROR: savePrintJobItems() action failed', err);
      throw err;
    }
  },

  async cancelPrintJob({ dispatch }, { jobKey }) {
    try {
      await printJobApi.cancelPrintJob(jobKey);
      dispatch('getPrintJobList', {});
    } catch (err) {
      console.error('ERROR: holdPrintJob() action failed ', err);
      throw err;
    }
  },

  /**
   * Complete print job
   * @param {string} jobKey - the print job key
   * @param {string} postageCost - total cost of postage
   */
  async completePrintJob({ dispatch }, { jobKey, postageCost }) {
    try {
      const payload = {
        jobKey,
        postageCost
      };
      await printJobApi.completePrintJob(payload);
      dispatch('getPrintJobList', { status: state.statusFilter });
    } catch (err) {
      console.error('ERROR: completePrintJob() action failed ', err);
      throw err;
    }
  },

  setSearchText({ commit }, searchText) {
    commit('SET_SEARCH_TEXT', searchText);
  },
  setStatusFilter({ commit }, statusFilter) {
    commit('SET_STATUS_FILTER', statusFilter);
  },
  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination);
  }
};

const mutations = {
  SET_PRINT_JOB_LIST_DATA(state, { printJobs, totalNum }) {
    state.printJobList = _.keyBy(printJobs, 'key');
    state.pagination.totalItems = parseInt(totalNum);
    state.dataLastFetchedAt = new Date();
  },

  SET_PRINT_JOB_VENDOR_LIST_DATA(state, data) {
    state.vendorList = _.keyBy(data, 'key');
  },

  SET_PRINT_JOB_IN_LIST(state, printJob) {
    Vue.set(state.printJobList, printJob.key, printJob);
  },

  SET_VENDOR_IN_LIST(state, { key, data }) {
    Vue.set(state.vendorList, key, data);
  },

  UNSET_DATA_LAST_FETCHED_AT(state) {
    state.dataLastFetchedAt = null;
  },
  SET_SEARCH_TEXT(state, searchText) {
    state.pagination.searchText = searchText;
  },
  SET_STATUS_FILTER(state, statusFilter) {
    state.statusFilter = statusFilter;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
