<template>
  <div class="main-dashboard">
    <!-- Header content -->
    <app-header>
      <div slot="top-left-action">
        <router-link :to="{ name: 'meetings' }">
          <img class="mt-2 hidden-sm-and-up" src="../../assets/gq_logo.svg" />
          <img
            class="mt-2 hidden-xs-only"
            src="../../assets/gq_navbar_logo.svg"
          />
        </router-link>
      </div>
    </app-header>

    <router-view />
  </div>
</template>

<script>
import _ from 'lodash';

import AppHeader from '@/components/Header';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MeetingsMain',
  components: { AppHeader },
  watch: {
    // Watch windowTitle for changes to the computed route name
    // Works even on window refresh
    windowTitle: {
      immediate: true,
      handler(title) {
        document.title = title;
      }
    }
    // @NOTE
    // This code is left here as a comment to show how we would hide code
    // under experimentalMode feature flags.
    // featureFlags: {
    //   handler() {
    //     // Do the thing
    //   }
    // }
  },
  computed: {
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters(['login']),
    // @NOTE
    // This code is left here as a comment to show
    // how we would hide code under experimentalMode
    // feature flags
    // ...mapGetters('features', ['featureFlags']),
    currentMeeting() {
      if (_.has(this.$route, 'params.shortcode')) {
        return this.meetingByShortCode(this.$route.params.shortcode);
      }
      return null;
    },
    // Compute the window title based on availability/changes to $route and currentMeeting.name
    windowTitle() {
      return this.currentMeeting
        ? `${this.$route.meta.navLabel} - ${this.currentMeeting.name}`
        : 'GetQuorum Dashboard';
    },
    userProfile() {
      return this.login.profile;
    }
  },
  methods: {},
  mounted() {
    // @NOTE
    // This code is left here as a comment to show
    // how we would hide code under experimentalMode
    // feature flags
    // if (this.featureFlags.experimentalMode) {
    // }
  }
};
</script>

<style scoped></style>
