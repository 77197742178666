<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Conversation Template Groups</p>
        <p class="secondary--text">
          Manage conversation templates groups used in the auto-scheduler. Click
          on a template group to view and edit it.
        </p>
      </v-flex>
    </v-layout>

    <v-layout class="ml-3 mr-3 mb-3" row>
      <v-flex xs12>
        <conversation-template-groups-table
          :state="templateGroupsData"
          @new-button-clicked="dialog.newTemplateGroup = true"
          @row-clicked="goToTemplateGroup"
        />
      </v-flex>
    </v-layout>

    <!-- Dialogs -->
    <v-dialog v-model="dialog.newTemplateGroup" persistent max-width="500px">
      <v-card>
        <v-card-title class="title"
          >Create New Conversation Template Group</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Conversation Template Group Name"
            v-model="newTemplateGroup.name"
            placeholder="eg. Pre-Consent Group"
            required
          />
          <v-text-field
            label="Description"
            v-model="newTemplateGroup.description"
            placeholder="eg. Group of reminders for Pre-consent"
            :rules="[
              () =>
                newTemplateGroup.description.length > 0 ||
                'This field is required'
            ]"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.newTemplateGroup = false"
            >Cancel</v-btn
          >
          <v-btn class="primary" @click.native="addNewTemplateGroupClicked"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

import ConversationTemplateGroupsTable from './AdminConversationTemplateGroupsTable';

export default {
  name: 'AdminConversationTemplateGroupsListView',
  components: {
    ConversationTemplateGroupsTable
  },
  props: {
    templateGroupsList: {
      type: Object,
      required: true
    },
    templateGroupsData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  data() {
    return {
      state: {},
      newTemplateGroup: {
        name: '',
        description: ''
      },
      dialog: {
        newTemplateGroup: false
      }
    };
  },
  methods: {
    ...mapActions('conversationTemplateGroups', [
      'createConversationTemplateGroup'
    ]),
    async addNewTemplateGroupClicked() {
      try {
        const key = await this.createConversationTemplateGroup({
          name: this.newTemplateGroup.name,
          description: this.newTemplateGroup.description
        });
        this.$events.$emit(
          'toastEvent',
          'New Conversation Template Group Created'
        );
        this.dialog.newTemplateGroup = false;
        this.goToTemplateGroup(key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    goToTemplateGroup(conversationTemplateGroupKey) {
      this.$router.push({
        name: 'adminConversationTemplateGroup',
        params: { conversationTemplateGroupKey }
      });
    }
  }
};
</script>

<style scoped></style>
