<template>
  <v-card class="round-card full-height mb-2">
    <v-card-text class="card-content full-height">
      <div class="v-flex blue-text">
        <span class="display-2 font-weight-black ma-3 text-xs-center">
          {{ this.totalSubs }}
        </span>
        <p class="subheading mb-0 font-weight-bold">
          Submissions Made
        </p>
      </div>

      <v-icon color="rgba(15, 100, 169, 0.25)" size="85">
        send
      </v-icon>

      <div class="v-flex">
        <p class="mb-0 subheading">
          <b> {{ stats.totalProxies }} </b> online submissions
        </p>
        <p class="mb-0 subheading">
          <b> {{ stats.totalPaperProxies }} </b> via paper proxy tool
        </p>
      </div>

      <v-btn
        color="primary"
        :disabled="disableReportButton"
        @click="$emit('openReportOptions')"
      >
        DOWNLOAD REPORTS
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import checkScope from '@/lib/check-user-scopes';
export default {
  name: 'SubmissionsCard',
  components: {},
  props: {
    hasSecretBusiness: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  created() {},
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('v2/meetings', ['stats']),
    totalSubs() {
      //TODO: Does totalProxies include paper proxies?
      return _.get(this.stats, 'totalProxies', 0);
    },
    disableReportButton() {
      if (this.stats && this.stats.totalProxies <= 0) {
        return true;
      }
      const canViewEarly =
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early');
      return !canViewEarly && this.hasSecretBusiness;
    },
    disabledMsg() {
      if (this.stats.totalProxies <= 0) {
        return 'There are no owner submissions';
      } else {
        return 'Not available until voting closes.';
      }
    }
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.v-flex {
  display: flex;
  flex-direction: column;
}
.card-content {
  display: flex;
  flex-direction: column;
  background-color: #e7f2fb;
  justify-content: space-between;
  align-items: center;
}
.blue-text {
  color: #0f64a9;
}
.full-height {
  height: 100%;
}
</style>
