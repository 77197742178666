<template>
  <v-dialog persistent :value="isOpen" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Update Subscription</span>
      </v-card-title>
      <v-card-text>
        You are about to navigate to Chargebee, where you can make updates to
        this subscription.
      </v-card-text>
      <v-card-actions>
        <v-btn @click.native="$emit('close-dialog')" flat color="primary">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn @click.native="openChargebee" flat color="primary">
          continue to chargebee
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateSubscriptionDialog',
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    subscription: {
      type: Object,
      default() {
        return {
          key: ''
        };
      }
    }
  },
  methods: {
    openChargebee() {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/subscriptions'
          : 'https://getquorum-test.chargebee.com/d/subscriptions';

      const url = `${baseurl}/${this.subscription.id}`;

      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped></style>
