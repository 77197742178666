/**
 * Make a proxy data object. Used for payload for user created proxies.
 * WIP - UPDATE these params when finished
 *
 * @param  {String} shortCode - the meeting shortCode
 * @param  {Array}  questions - the meeting questions
 * @param  {String} proxyHolder - the proxy holder
 * @param  {String} writeInProxyHolder - write in proxy holder
 * @param  {Object} selectedVoter - the selected voter
 * @param  {Object} attachment - the optional proxy file attachment
 * @param  {Object} formOptions - proxy template values
 * @param  {String} proxyTemplate - the name of the proxy template
 * @return {Object} - Returns payload data object
 */
export function makeProxyObject({
  shortCode,
  questions,
  proxyHolder,
  writeInProxyHolder,
  selectedVoter,
  attachment,
  formOptions,
  proxyTemplate,
  fallbackProxyHolder,
  writeInFallbackProxyHolder
}) {
  // Build the answers array
  const answers = questions
    .filter(question => !question.disabledAt)
    .filter(question => question.eligible)
    .map(question => {
      let answerObj = {
        id: question.id,
        order: question.order,
        type: question.type,
        isResident: true, // temp set isResident to true
        name: question.name,
        vote: question.vote
      };
      return answerObj;
    });

  // If holderName is other, then use the write in proxy holder name
  let holderName = proxyHolder === 'other' ? writeInProxyHolder : proxyHolder;

  let backupHolderName =
    fallbackProxyHolder === 'other'
      ? writeInFallbackProxyHolder
      : fallbackProxyHolder;

  if (!backupHolderName || !backupHolderName.trim()) {
    backupHolderName = null;
  }

  // Build the data object to send
  let dataObj = {
    shortCode,
    address: selectedVoter.address,
    unit: selectedVoter.unit,
    holderName,
    backupHolderName,
    name: selectedVoter.name,
    email: selectedVoter.email,
    isEmailOptIn: null,
    trackingCode: selectedVoter.voterKey,
    answers,
    proxyAbstainOption: false,
    file: attachment,
    // If this is a on-bill-106 type proxy template, then insert formOption property in payload
    // This will need expanding as we get more proxyTemplates other than 'on-bill-106'
    formOptions:
      proxyTemplate === 'on-bill-106'
        ? { onBill106: formOptions.onBill106 }
        : null
  };

  return dataObj;
}
