<template>
  <v-dialog :value="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Reassign {{ managerType }} Checklists?</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout row>
            <v-flex xs12>
              <p>
                The Preferred {{ managerType }} for
                <b>{{ account.nameShort }}</b> has been updated to
                <b>{{ managerName }}</b
                >.
              </p>
              <p>
                Would you like to reassign any incomplete checklists to the new
                Preferred
                {{ managerType }}?
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="close" flat>Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="reassignChecklists" color="primary" flat
          >Reassign Checklists</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ReassignPreferredManagerChecklistsDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default() {
        return null;
      }
    },
    currentManager: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    managerName() {
      return this.currentManager ? this.currentManager.user.name : '';
    },
    managerType() {
      return this.type.toUpperCase();
    }
  },
  methods: {
    reassignChecklists() {
      if (this.$route.name !== 'accountChecklists') {
        this.$router.replace({ name: 'accountChecklists' });
      }

      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
