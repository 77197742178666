<template>
  <v-toolbar dense class="elevation-0">
    <v-btn flat @click="backButtonClick" hide-slider>
      <v-icon left>arrow_back</v-icon>
      {{ nestedPage.label }}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'MeetingNavNestedTab',
  props: {
    nestedPage: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    backButtonClick() {
      this.$router.push({ name: this.nestedPage.routeName }).catch(err => {});
    }
  }
};
</script>

<style scoped>
.nested-label {
  text-transform: uppercase;
  font-weight: 500;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.nested-tab {
  margin-bottom: 32px;
}
</style>
