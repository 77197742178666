<template>
  <v-container fluid>
    <div v-if="isPending" class="text-xs-center">
      PENDING <br />
      A GQ Team member is confirming your request
    </div>

    <div v-else>
      <div>
        To find more details about additional services, consult our
        <a href="">Help Docs.</a>
      </div>
      <div class="mt-2">
        To get in touch with Customer Service to cancel a service or to get
        support, send an email to
        <a href="mailto:contact@getquorum.com"> contact@getquorum.com</a>
      </div>

      <template v-if="services.length > 0">
        <h3 class="mt-4">Ordered</h3>
        <v-card v-for="s in services" :key="s.value" class="mt-3">
          <v-card-text class="px-3">
            <div class="service-grid">
              <v-btn
                v-if="isAdmin"
                icon
                :disabled="!isAdmin"
                @click="handleUpdate(s.value, false)"
                ><v-icon>check_box</v-icon></v-btn
              >
              <div>{{ s.title }}</div>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-if="other.length > 0">
        <h3 class="mt-4">Other Services</h3>
        <v-card v-for="s in other" :key="s.value" class="mt-3">
          <v-card-text class="px-3">
            <div class="service-grid">
              <v-btn
                v-if="isAdmin"
                icon
                :disabled="!isAdmin"
                @click="handleUpdate(s.value, true)"
                ><v-icon>check_box_outline_blank</v-icon></v-btn
              >
              <div>{{ s.title }}</div>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'AdditionalServices',
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState('v2/additionalServices', ['serviceList']),
    ...mapGetters('v2/additionalServices', ['services', 'other']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    isPending() {
      return (
        _.get(this.meetingByShortCode(this.shortCode), 'meetingStatus', '') ===
        'pending'
      );
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('v2/additionalServices', ['setService', 'getMetaData']),
    async init() {
      if (this.shortCode) {
        return await this.getMetaData({ shortCode: this.shortCode });
      }
      return {};
    },
    async handleUpdate(key, value) {
      const updates = {
        meetingMeta: {
          [key]: value
        }
      };

      await this.setService({
        shortCode: this.shortCode,
        updates
      });
    }
  }
};
</script>

<style scoped lang="scss">
.service-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 16px 0;
  }
}
</style>
