import { render, staticRenderFns } from "./InputCorp.vue?vue&type=template&id=cd12b482&scoped=true"
import script from "./InputCorp.vue?vue&type=script&lang=js"
export * from "./InputCorp.vue?vue&type=script&lang=js"
import style0 from "./InputCorp.vue?vue&type=style&index=0&id=cd12b482&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd12b482",
  null
  
)

export default component.exports