import _ from 'lodash';

export { getFormattedAddress, getFormattedAddressWithNewline };

/**
 * Construct return address string using break tags
 *
 * @param  {Object} shortName - abbreviated condo legal name
 * @param  {Object} address - address object
 * @return {String} - return address string
 *
 */
function getFormattedAddress(shortName, address) {
  return `${shortName} Management Office<br />${address.address}<br />${
    address.city
  } ${address.prov} ${address.postal}<br />${address.country}`;
}

/**
 * Construct return address string using line breaks
 *
 * @param  {Object} shortName - abbreviated condo legal name
 * @param  {Object} address - address object
 * @return {String} - return address string
 *
 */
function getFormattedAddressWithNewline(shortName, address) {
  return `${shortName} Management Office\n${address.address}\n${address.city} ${
    address.prov
  } ${address.postal}\n${address.country}`;
}
