<template>
  <div>
    <v-card>
      <v-card-text>
        <!--  name -->
        <v-layout>
          <v-flex xs12>
            <h2>Agent information</h2>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <v-text-field
              box
              v-model="state.name"
              @change="onChange('name', $event)"
              label="Agent Name"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.primaryContactName"
              @change="onChange('primaryContactName', $event)"
              label="Primary contact name"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.phoneNumber"
              @change="onChange('phoneNumber', $event)"
              label="Phone number"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <h2>Mailing information</h2>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.mailingAddress"
              @change="onChange('mailingAddress', $event)"
              label="Mailing address"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.mailingCity"
              @change="onChange('mailingCity', $event)"
              label="Mailing City"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-autocomplete
              box
              v-model="state.mailingProv"
              :items="mailingProvinceItems"
              item-text="regionName"
              item-value="regionCode"
              @change="onChange('mailingProv', $event)"
              label="Mailing Province"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.mailingPostal"
              @change="onChange('mailingPostal', $event)"
              :rules="mailingPostalRule"
              label="Mailing Postal Code"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-autocomplete
              box
              v-model="state.mailingCountry"
              :items="countryItems"
              @change="onChange('mailingCountry', $event)"
              label="Mailing Country"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <h2>Billing information</h2>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.billingName"
              @change="onChange('billingName', $event)"
              label="Billing contact name"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.billingEmail"
              @change="onChange('billingEmail', $event)"
              label="Billing contact email"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-text-field
              box
              v-model="state.billingAddress"
              @change="onChange('billingAddress', $event)"
              label="Billing address"
            />
          </v-flex>

          <v-flex xs6>
            <v-text-field
              box
              v-model="state.billingCity"
              @change="onChange('billingCity', $event)"
              label="Billing City"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-autocomplete
              box
              v-model="state.billingProv"
              :items="billingProvinceItems"
              item-text="regionName"
              item-value="regionCode"
              @change="onChange('billingProv', $event)"
              label="Billing Province"
            />
          </v-flex>

          <v-flex xs6>
            <v-text-field
              box
              v-model="state.billingPostal"
              @change="onChange('billingPostal', $event)"
              :rules="billingPostalRule"
              label="Billing Postal Code"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs6>
            <v-autocomplete
              box
              v-model="state.billingCountry"
              :items="countryItems"
              @change="onChange('billingCountry', $event)"
              label="Billing Country"
            />
          </v-flex>

          <v-flex xs6>
            <v-autocomplete
              box
              label="Preferred method of payment"
              v-model="state.paymentMethod"
              :items="paymentMethodItems"
              @change="onChange('paymentMethod', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <h2>Others</h2>
          </v-flex>
        </v-layout>

        <!-- Agent type -->
        <v-layout>
          <v-flex xs6 class="pr-2">
            <v-autocomplete
              v-model="state.agentType"
              :items="agentTypeItems"
              box
              label="Type"
              @change="onChange('agentType', $event)"
            ></v-autocomplete>
          </v-flex>

          <!-- Partner type -->
          <v-flex xs6 class="pl-2">
            <v-checkbox
              label="This agent is a partner"
              color="primary"
              v-model="state.partnerType"
              value="partner"
              @change="onChange('partnerType', $event)"
            />
          </v-flex>
        </v-layout>

        <!-- Discounts -->
        <v-layout>
          <v-flex xs6 class="pr-2">
            <v-text-field
              box
              type="number"
              min="0"
              max="100"
              v-model="state.discount"
              label="Discount (%)"
              @change="onChange('discount', $event)"
              hint="Discount off software, services and license fees"
              persistent-hint
              required
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <v-textarea
              box
              label="Notes (will show up in meeting notes)"
              placeholder="eg. They usually like to print and mail for their Corporations."
              v-model="state.notes"
              @change="onChange('notes', $event)"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!isDataChanged || !isPostalCodesCorrect"
          @click="cancelChanges"
          flat
          color="primary"
          >Cancel</v-btn
        >
        <v-btn
          flat
          color="primary"
          :disabled="!isDataChanged || !isPostalCodesCorrect"
          @click="updateAgent"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  alpha2CountryCode,
  countryNames,
  provinceNames
} from '@/lib/address-validators';
import validator from 'validator';

export default {
  name: 'AdminAgentViewInfo',
  props: {
    agent: {
      type: Object,
      default() {
        return {
          name: '',
          agentType: '',
          partnerType: '',
          notes: '',
          discount: '',
          primaryContactName: '',
          phoneNumber: '',
          mailingAddress: '',
          mailingCity: '',
          mailingProv: '',
          mailingPostal: '',
          mailingCountry: '',
          billingName: '',
          billingEmail: '',
          billingAddress: '',
          billingCity: '',
          billingProv: '',
          billingPostal: '',
          billingCountry: '',
          paymentMethod: ''
        };
      }
    }
  },

  mounted() {
    this.setState();
  },
  data() {
    return {
      agentTypeItems: ['law-firm', 'pm-firm', 'other'],
      paymentMethodItems: ['check', 'electronic', 'credit_card'],
      properties: [
        'name',
        'agentType',
        'partnerType',
        'notes',
        'discount',
        'primaryContactName',
        'phoneNumber',
        'mailingAddress',
        'mailingCity',
        'mailingProv',
        'mailingPostal',
        'mailingCountry',
        'billingName',
        'billingEmail',
        'billingAddress',
        'billingCity',
        'billingProv',
        'billingPostal',
        'billingCountry',
        'paymentMethod'
      ],
      state: {
        name: '',
        agentType: '',
        partnerType: '',
        notes: '',
        discount: '',
        primaryContactName: '',
        phoneNumber: '',
        mailingAddress: '',
        mailingCity: '',
        mailingProv: '',
        mailingPostal: '',
        mailingCountry: '',
        billingName: '',
        billingEmail: '',
        billingAddress: '',
        billingCity: '',
        billingProv: '',
        billingPostal: '',
        billingCountry: '',
        paymentMethod: ''
      }
    };
  },
  computed: {
    isDataChanged() {
      const stateObject = _.pick(this.state, this.properties);
      const agentObject = _.pick(this.agent, this.properties);

      const isEqual = _.isEqual(stateObject, agentObject);

      return !isEqual;
    },

    isPostalCodesCorrect() {
      // Disabled if postal codes error
      let mailingPostalCorrect = false;
      if (
        !this.state.mailingPostal ||
        (this.state.mailingPostal &&
          validator.isPostalCode(
            this.state.mailingPostal,
            this.mailingCountryCode
          ))
      ) {
        mailingPostalCorrect = true;
      }

      let billingPostalCorrect = false;
      if (
        !this.state.billingPostal ||
        (this.state.billingPostal &&
          validator.isPostalCode(
            this.state.billingPostal,
            this.billingCountryCode
          ))
      ) {
        billingPostalCorrect = true;
      }

      if (mailingPostalCorrect && billingPostalCorrect) return true;

      return false;
    },

    countryItems() {
      const countryArray = countryNames();
      return countryArray;
    },
    mailingCountryCode() {
      return alpha2CountryCode(this.state.mailingCountry);
    },
    mailingProvinceItems() {
      const provArray = provinceNames(this.mailingCountryCode);
      return provArray;
    },
    mailingPostalRule() {
      // Must be a valid postal code based on the country selected
      return [
        v =>
          !v ||
          validator.isPostalCode(
            this.state.mailingPostal,
            this.mailingCountryCode
          ) ||
          'Must be a valid postal code'
      ];
    },
    billingCountryCode() {
      return alpha2CountryCode(this.state.billingCountry);
    },
    billingProvinceItems() {
      const provArray = provinceNames(this.billingCountryCode);
      return provArray;
    },
    billingPostalRule() {
      // Must be a valid postal code based on the country selected
      return [
        v =>
          !v ||
          validator.isPostalCode(
            this.state.billingPostal,
            this.billingCountryCode
          ) ||
          'Must be a valid postal code'
      ];
    }
  },
  methods: {
    setState() {
      const agentObject = _.pick(this.agent, this.properties);

      this.state = _.cloneDeep(agentObject);
    },
    onChange(key, value) {
      if (key == 'partnerType' && !value) {
        this.state.partnerType = '';
        this.$emit('update', { key, value: '' });
      } else {
        this.$emit('update', { key, value });
      }
    },
    updateAgent() {
      this.$emit('submit');
    },
    cancelChanges() {
      this.setState();
    }
  }
};
</script>

<style lang="scss" scoped></style>
