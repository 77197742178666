<template>
  <v-dialog :value="action !== ''" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span v-if="action === 'approve'" class="headline"
          >Confirm service request approval</span
        >
        <span v-else-if="action === 'deny'" class="headline"
          >Confirm service request denial</span
        >
      </v-card-title>

      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <v-list>
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon color="primary">comment</v-icon>
                </v-list-tile-avatar>
                <span><b>Add a comment</b> (optional)</span>
              </v-list-tile>
              <v-textarea
                v-model="comment"
                class="mt-2 mx-3"
                outline
                placeholder="Add any details needed to the activity feed."
              ></v-textarea>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click.native="close" flat>Cancel</v-btn>
        <v-spacer />

        <v-btn
          v-if="action === 'approve'"
          color="success"
          :loading="loading"
          @click.native="handleAction"
          >Approve</v-btn
        >
        <v-btn
          v-else-if="action === 'deny'"
          color="error"
          :loading="loading"
          @click.native="handleAction"
          >Deny</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApprovalDialog',

  props: {
    action: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comment: ''
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleAction() {
      if (this.action === 'approve') {
        this.$emit('handle-action', {
          action: 'approved',
          comment: this.comment
        });
      } else if (this.action === 'deny') {
        this.$emit('handle-action', {
          action: 'denied',
          comment: this.comment
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
