<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Advance Voting Report</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-card-text>
        This report is a summary of the voting results. It will include:<br />
        <ul>
          <li>a pdf of all owner submissions</li>
          <li>summarized tallies for each vote</li>
        </ul>
      </v-card-text>
      <v-container class="pt-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-select
              v-model="selectedRecipient"
              :label="`Select a recipient`"
              :items="reportRecipients"
              item-text="name"
              item-value="email"
              @input="setRecipient('selectedRecipient', $event)"
            >
              <template slot="selection" slot-scope="{ item }">
                {{ item.email }}
              </template>
              <template slot="item" slot-scope="{ item }">
                {{ item.name }} - {{ item.email }}
              </template>
            </v-select>
            <v-text-field
              v-if="isAdmin"
              v-model="emailCC"
              label="Add cc (optional)"
              type="email"
              :rules="emailRules"
            />
            <v-checkbox
              v-if="zipOption"
              :label="`Export Submissions Individually (admins only)`"
              :value="zipStatus"
              @change="setZipMode"
            ></v-checkbox>
            <v-checkbox
              v-if="isAdmin"
              class="mt-0"
              :label="`Export as ballot`"
              :value="exportAsBallot"
              @change="exportAsBallot = !exportAsBallot"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 v-if="isSecret">
            <v-alert outline color="warning" icon="warning" :value="true">
              <div class="black-text">
                This meeting includes secret ballots. Voter and voting
                information will be hidden in the report by default.
              </div>
              <v-checkbox
                :label="`Include Voter Information`"
                :value="includeVoters"
                @change="includeVoters = !includeVoters"
              />
              <v-checkbox
                :disabled="!isAdmin"
                class="mt-0"
                :label="`Include who Units Voted for (admin only)`"
                :value="includeVote"
                @change="includeVote = !includeVote"
              />
            </v-alert>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn
            :disabled="!this.selectedRecipient"
            @click.native="sendReport()"
            color="primary"
            class="white--text"
            >Send Report</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import validator from 'email-validator';

export default {
  name: 'VotingSummaryDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    contactName: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    contactEmail: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    isSecret: {
      type: Boolean,
      default: false
    },
    includeVoteInDownload: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    let recipientList = [];
    // Get profile token
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    if (profile.email) {
      recipientList.push({
        name: profile.firstName,
        email: profile.email,
        isAdmin: this.isAdmin
      });
    }
    // Admins are given the option to select either themselves or the property manager contact
    if (this.isAdmin && !this.isSecret) {
      recipientList.push({
        name: this.contactName,
        email: this.contactEmail,
        isAdmin: false
      });
    }

    // Assign the recipient list
    this.setRecipientList(recipientList);

    // Default selected recipient is the logged in user
    this.setRecipient('selectedRecipient', profile.email);
  },
  data() {
    return {
      selectedRecipient: {},
      reportRecipients: [],
      zipMode: 'merged',
      showRecipients: false,
      zipOption: false,
      zipStatus: false,
      emailCC: '',
      emailRules: [
        v => (v === '' ? true : validator.validate(v) || 'E-mail must be valid')
      ],
      includeVoters: false,
      includeVote: false,
      exportAsBallot: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  methods: {
    setRecipientList(list) {
      this.reportRecipients = list;
    },
    setRecipient(key, email) {
      this.selectedRecipient = _.find(this.reportRecipients, { email: email });
      this.zipOption = this.selectedRecipient.isAdmin ? true : false;
    },
    setZipMode() {
      this.zipMode = this.zipMode === 'merged' ? 'individual' : 'merged';
      this.zipStatus = !this.zipStatus;
    },
    sendReport() {
      if (!(this.emailCC.length > 0 && !validator.validate(this.emailCC))) {
        this.$emit('send', {
          type: 'vote-summary',
          mode: this.zipMode,
          recipient: {
            name: this.selectedRecipient.name,
            email: this.selectedRecipient.email,
            cc: this.emailCC
          },
          includeVoters: this.includeVoters,
          includeVote: this.includeVote,
          exportAsBallot: this.exportAsBallot,
          includeVoteInDownload: this.includeVoteInDownload
        });
      }
      // reset all the checkbox options to unchecked status
      this.zipStatus = false;
      this.exportAsBallot = false;
      this.includeVoters = false;
      this.includeVote = false;
    }
  }
};
</script>

<style scoped>
.black-text {
  color: rgba(0, 0, 0, 0.87);
}
</style>
