<template>
  <v-card>
    <v-list>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon x-large>account_box</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>{{ name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ email }}</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn disabled icon>
            <v-icon>star</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-divider></v-divider>

    <v-list>
      <v-list-tile @click="$emit('change-password')">
        <v-list-tile-action>
          <v-icon>settings</v-icon>
        </v-list-tile-action>
        <v-list-tile-sub-title class="secondary--text">
          Change password
        </v-list-tile-sub-title>
      </v-list-tile>

      <v-list-tile v-if="billingEnabled && hasInvoicesScope" @click="goBilling">
        <v-list-tile-action>
          <v-icon>credit_card</v-icon>
        </v-list-tile-action>
        <v-list-tile-sub-title class="secondary--text">
          Billing (Beta)
        </v-list-tile-sub-title>
      </v-list-tile>

      <v-list-tile @click="$emit('logout')">
        <v-list-tile-action>
          <v-icon>logout</v-icon>
        </v-list-tile-action>
        <v-list-tile-sub-title class="secondary--text">
          Logout
        </v-list-tile-sub-title>
      </v-list-tile>

      <v-list-tile v-if="isAdmin">
        <v-list-tile-action>
          <v-switch
            color="accent"
            :input-value="featureFlags.experimentalMode"
            @change="toggleExperimentalMode"
          ></v-switch>
        </v-list-tile-action>
        <v-list-tile-sub-title class="secondary--text">
          Experimental mode
        </v-list-tile-sub-title>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HeaderProfileCard',
  props: {
    name: {
      type: String,
      default() {
        return '';
      }
    },
    email: {
      type: String,
      default() {
        return '';
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['login']),
    ...mapGetters('features', ['featureFlags']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    billingEnabled() {
      return process.env.VUE_APP_BILLING_ENABLED === 'true';
    },
    currentMeeting() {
      if (_.has(this.$route, 'params.shortcode')) {
        return this.meetingByShortCode(this.$route.params.shortcode);
      }
      return null;
    },
    meetingOptions() {
      return _.get(this.currentMeeting, 'options', {});
    },
    hasInvoicesScope() {
      const userLevelPermissions = this.login.profile.permissions;
      return _.includes(userLevelPermissions, 'meeting.invoices.read');
    }
  },
  created() {},
  methods: {
    ...mapActions('features', ['setFeatureFlags']),
    ...mapActions('chat', [
      'chatInit',
      'chatIdentify',
      'chatDestroy',
      'chatPosition'
    ]),
    toggleExperimentalMode() {
      this.setFeatureFlags({
        experimentalMode: !this.featureFlags.experimentalMode
      });

      window.location.reload();
    },
    goBilling() {
      this.$router.push({ name: 'billing' });
    }
  }
};
</script>

<style lang="scss" scoped></style>
