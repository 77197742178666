<template>
  <div>
    <app-header>
      <div slot="top-left-action">
        <router-link to="/meetings">
          <img class="mt-2" src="../assets/gq_navbar_logo.svg" />
        </router-link>
      </div>
    </app-header>

    <v-layout justify-center align-center class="mt-4">
      <h2>Under Maintenance <br /></h2>
    </v-layout>
    <v-layout justify-center align-center>
      <v-img
        src="https://www.getquorum.com/assets/images/illustrations/about_us.png"
        max-height="400"
        max-width="400"
      />
    </v-layout>
    <v-layout justify-center align-center column class="mb-4">
      <h4>
        We'll be back up and running again soon, please check back later
      </h4>
    </v-layout>
  </div>
</template>

<script>
import AppHeader from '@/components/Header';
import { getUserInfo } from '@/api/auth';

export default {
  name: 'UnderMaintenanceView',
  components: {
    AppHeader
  },
  created() {
    this.pingService();
  },
  methods: {
    //Checks if the service has come online again.
    async pingService() {
      try {
        const res = await getUserInfo();
        if (res.status < 500) {
          this.$router.push({ name: 'meetings' });
        }
      } catch (error) {
        console.error(error.response);
        if (error.response && error.response.status < 500) {
          //Error is not a server-side error
          this.$router.push({ name: 'login' });
        }
      }
    }
  }
};
</script>

<style scoped>
h2 {
  font-size: 70px;
  font-family: 'century gothic';
  font-weight: lighter;
  text-align: center;
  color: #606060;
}
h4 {
  font-size: 18px;
  color: gray;
  font-weight: lighter;
  padding-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  h2 {
    font-size: 30px;
  }
  h4 {
    font-size: 18px;
    text-align: center;
  }
}
</style>
