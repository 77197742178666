<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title> <span class="title">Add a Proxy</span> </v-card-title>
      <v-card-text>
        <v-text-field label="Name" v-model="newBallot.name" />
        <v-text-field label="Email" v-model="newBallot.email" />
        <v-text-field label="Weight" v-model="newBallot.weight" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          :disabled="!(newBallot.email && newBallot.name && newBallot.weight)"
          color="primary"
          @click.native="emitAddBallot"
          >Add Ballot</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingRtvBallotAddDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newBallot: {
        name: '',
        email: '',
        weight: 1
      }
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');

      // Clear value
      this.newBallot.name = '';
      this.newBallot.email = '';
      this.newBallot.weight = '';
    },
    emitAddBallot() {
      this.$emit('addBallot', this.newBallot);
    }
  }
};
</script>

<style scoped></style>
