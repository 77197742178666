<template>
  <div v-if="!dataLoaded">
    <v-layout row class="fill-height">
      <v-flex xs12 class="text-xs-center">
        <v-progress-circular indeterminate :size="50" color="primary" />
      </v-flex>
    </v-layout>
  </div>

  <!-- Loaded -->
  <div v-else>
    <v-container>
      <v-layout>
        <v-flex>
          <v-toolbar color="grey lighten-4 elevation-1" dense>
            <v-spacer />
            <v-btn
              dark
              class="primary"
              @click.native="dialog.createCert = true"
            >
              <v-icon>add</v-icon>Add Certification
            </v-btn>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="meetingCertificatesListData"
            class="elevation-1"
            :rows-per-page-items="config.rowsPerPageItems"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>
                  {{ props.item.createdAt | formatDateHumanReadable(false) }}
                </td>
                <td>{{ props.item.name }}</td>
                <td>
                  {{ props.item.sentAt | formatDateHumanReadable(false) }}
                </td>
                <td>{{ props.item.sentTo }}</td>
                <td class="justify-center layout px-0">
                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      flat
                      icon
                      class="mx-0"
                      :disabled="!!props.item['sentAt']"
                      @click="rowActionDelete(props.item)"
                    >
                      <v-icon color="light-blue">delete</v-icon>
                    </v-btn>
                    <span>Delete</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      flat
                      icon
                      class="mx-0"
                      @click="rowActionFileExport(props.item)"
                    >
                      <v-icon color="light-blue">insert_drive_file</v-icon>
                    </v-btn>
                    <span>File Exports</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      flat
                      icon
                      class="mx-0"
                      @click="rowActionSend(props.item)"
                    >
                      <v-icon color="light-blue">send</v-icon>
                    </v-btn>
                    <span>Send</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- Dialogs -->
    <!-- Create -->
    <v-dialog v-model="dialog.createCert" persistent max-width="800px">
      <create-cert
        :account-key="currentMeeting.accountKey"
        :short-code="currentMeeting.shortCode"
        :region="currentMeeting.region"
        :preview-content="meetingCertificatePreviewContent"
        :contact-email="contactEmail"
        :cert-loading="certLoaded"
        :meeting-print-job-data="completedPrintJobData"
        @getPreview="previewCertification"
        @close="dialog.createCert = false"
        @create="createCertification"
        @createAndSend="createAndSendCertification"
      />
    </v-dialog>

    <!-- Preview -->
    <v-dialog v-model="dialog.fileExport" max-width="550px">
      <file-export :file-exports="fileExports" />
    </v-dialog>

    <delete-resource-dialog
      :is-open="dialog.delete"
      resource-name="certificate"
      :resource-key="certKeyToDelete"
      @delete="deleteCertificate"
      @close-dialog="dialog.delete = false"
    >
      <span slot="title">Delete Certificate?</span>
      <span slot="description">
        <strong>WARNING</strong>: You are about to delete this certificate. Once
        deleted you cannot undo this action.
      </span>
    </delete-resource-dialog>

    <!-- Send -->
    <v-dialog v-model="dialog.send" persistent max-width="350px">
      <send-cert
        :contact-email="contactEmail"
        :cert-key="certKeyToSend"
        @close="dialog.send = false"
        @send="sendCertificate"
      />
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDate } from '@/helpers';
import { mapGetters, mapActions } from 'vuex';
import * as filters from '@/filters';

import CreateCert from './MeetingCertificatesViewCreate';
import FileExport from './MeetingCertificatesViewExport';
import SendCert from './MeetingCertificatesViewSend';
import DeleteResourceDialog from '@/components/dialogs/DeleteResourceDialog';

export default {
  name: 'MeetingCertificatesView',
  components: {
    CreateCert,
    FileExport,
    SendCert,
    DeleteResourceDialog
  },
  filters: {
    ...filters
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataLoaded: false,
      certLoaded: false,
      dialog: {
        createCert: false,
        fileExport: false,
        delete: false,
        send: false
      },
      headers: [
        { value: 'createdAt', align: 'left', text: 'Created Date' },
        { value: 'name', align: 'left', text: 'Name' },
        { value: 'sentAt', align: 'left', text: 'Sent On' },
        { value: 'sentTo', align: 'left', text: 'Sent To' },
        { value: 'Actions', align: 'center', text: 'Actions', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      },
      fileExports: {},
      certKeyToDelete: null,
      certKeyToSend: null,
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('meetings/certificates', [
      'meetingCertificatesListData',
      'meetingCertificatePreviewContent'
    ]),
    ...mapGetters('meetings/printJobs', ['completedPrintJobData']),
    contactEmail() {
      if (_.has(this.currentMeeting.contact, 'email')) {
        return this.currentMeeting.contact.email;
      }
      return '';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/certificates', [
      'getMeetingCertificates',
      'createMeetingCertificate',
      'deleteMeetingCertificate',
      'previewMeetingCertificate',
      'sendMeetingCertificate'
    ]),
    ...mapActions('meetings/printJobs', ['getCompletedPrintJobs']),
    rowActionFileExport(certObj) {
      let fileExportObj = {
        data: certObj.data,
        files: certObj.files
      };
      this.fileExports = fileExportObj;
      this.dialog.fileExport = true;
    },
    rowActionDelete(certObj) {
      this.dialog.delete = true;
      this.certKeyToDelete = certObj.key;
    },
    rowActionSend(certObj) {
      this.dialog.send = true;
      this.certKeyToSend = certObj.key;
    },
    async init() {
      try {
        this.dataLoaded = false;
        await this.getMeetingCertificates(this.shortCode);
        await this.getCompletedPrintJobs({ key: this.currentMeeting.key });
        this.dataLoaded = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async createAndSendCertification(payload) {
      this.certLoaded = true;
      try {
        let res = await this.createMeetingCertificate(payload.create);
        await this.sendCertificate(res.data.key, payload.send);

        // Alert, close dialog, change route
        this.certLoaded = false;
        this.dialog.createCert = false;
        this.$events.$emit('toastEvent', 'Certificate created & sent');
      } catch (err) {
        console.error(err);
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendCertificate(certKey, payload) {
      try {
        await this.sendMeetingCertificate({
          shortCode: this.shortCode,
          certKey,
          payload
        });

        this.$events.$emit('toastEvent', 'Certificate Sent');
      } catch (err) {
        console.error(err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.send = false;
      }
    },
    async createCertification(payload) {
      this.certLoaded = true;
      try {
        await this.createMeetingCertificate(payload);
        // Alert, close dialog, change route
        this.$events.$emit('toastEvent', 'New Certificate Created');

        this.certLoaded = false; // stop loading
        this.dialog.createCert = false; // close dialog
      } catch (err) {
        console.error(err);
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async previewCertification(payload) {
      try {
        await this.previewMeetingCertificate(payload);
      } catch (err) {
        console.error(err);
        this.$events.$emit('toastEvent', 'Unable to preview certificate');
      }
    },
    async deleteCertificate(certKey) {
      try {
        await this.deleteMeetingCertificate({
          certKey,
          shortCode: this.shortCode
        });
        this.$events.$emit('toastEvent', 'Certificate Deleted');
      } catch (err) {
        console.error(err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.delete = false;
      }
    }
  }
};
</script>

<style scoped></style>
