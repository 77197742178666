<template>
  <v-card flat class="d-flex justify-center">
    <v-progress-circular
      :size="30"
      indeterminate
      color="primary"
      v-if="downloading"
    />
    <ul v-else>
      <li>
        <a @click="pollsReportDownloader()" href="javascript: void(0)"
          >Download Zoom Polls Report</a
        >
      </li>
      <li>
        <a href="javascript: void(0)" @click="participantsReportDownloader()"
          >Download Zoom Participants Report</a
        >
      </li>
    </ul>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'MeetingWebcastViewReports',
  components: {},
  props: {},
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      downloading: false
    };
  },
  computed: {},
  methods: {
    ...mapActions('meetings/webcast', [
      'triggerPollsReport',
      'triggerParticipantsReport'
    ]),
    async pollsReportDownloader() {
      try {
        this.downloading = true;
        await this.triggerPollsReport({
          shortCode: this.shortCode
        });

        this.downloading = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.downloading = false;
      }
    },
    async participantsReportDownloader() {
      try {
        this.downloading = true;
        await this.triggerParticipantsReport({
          shortCode: this.shortCode
        });

        this.downloading = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.downloading = false;
      }
    }
  }
};
</script>
