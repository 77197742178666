<template>
  <div>
    <v-toolbar dark dense color="white lighten-4 elevation-1">
      <v-text-field
        class="mb-2"
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="campaigns"
      :search="search"
      :pagination.sync="pagination"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-left">
            <span slot="activator">
              <router-link
                :to="{
                  name: 'meetingOverview',
                  params: { shortcode: props.item.shortCode }
                }"
                target="_blank"
              >
                {{ props.item.name }}
              </router-link>
            </span>
          </td>
          <td class="text-xs-left">{{ props.item.shortCode }}</td>
          <td class="text-xs-left">
            {{ props.item.meetingDate | formatDateHumanReadable }}
          </td>
          <td class="text-xs-left">
            <v-chip
              label
              small
              :color="getStatusColor(props.item)"
              :text-color="getStatusTextColor(props.item)"
              >{{ getStatusText(props.item) }}</v-chip
            >
          </td>
          <td class="text-xs-left">
            {{ props.item.expiryDate | formatDateHumanReadable }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatDateHumanReadable } from '@/filters';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'AdminAccountViewCampaignTable',
  props: {
    campaigns: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    formatDateHumanReadable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Shortcode', value: 'shortCode', align: 'left' },
        { text: 'Meeting Date', value: 'meetingDate', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Expiry Date', value: 'expiryDate', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      },
      pagination: {
        sortBy: 'meetingDate',
        descending: true
      }
    };
  },
  methods: {
    getStatusColor(meeting) {
      const status = meeting.meetingStatus;

      if (!status) {
        return moment().isAfter(meeting.meetingDate) ? 'deep-orange' : 'green';
      }

      switch (status) {
        case 'extra-care':
          return '#06060C';
        case 'demo':
          return '#ffe389';
        case 'test':
          return '#ffe389';
        case 'hybrid':
          return '#dec4ff';
        case 'reconvene':
          return '#dec4ff';
        case 'requisition':
          return '#dec4ff';
        case 'adjourned':
          return '#f9cfcd';
        case 'cancelled':
          return '#f9cfcd';
        case 'postponed':
          return '#f9cfcd';
        case 'deleted':
          return '#f9cfcd';
        default:
          return 'grey';
      }
    },
    getStatusTextColor(meeting) {
      const status = meeting.meetingStatus;

      switch (status) {
        case 'extra-care':
          return '#D0D4F6';
        case 'demo':
          return '#EC9235';
        case 'test':
          return '#EC9235';
        case 'hybrid':
          return '#5c21a7';
        case 'reconvene':
          return '#5c21a7';
        case 'requisition':
          return '#5c21a7';
        case 'adjourned':
          return '#eb5b55';
        case 'cancelled':
          return '#eb5b55';
        case 'postponed':
          return '#eb5b55';
        case 'deleted':
          return '#eb5b55';
        default:
          return 'white';
      }
    },
    getStatusText(meeting) {
      const status = meeting.meetingStatus;

      if (!status) {
        return moment().isAfter(meeting.meetingDate) ? 'CLOSED' : 'ACTIVE';
      }

      return status.toUpperCase();
    }
  }
};
</script>

<style scoped></style>
