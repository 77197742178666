import { render, staticRenderFns } from "./MeetingUnitsAddProxyDialog.vue?vue&type=template&id=df2b8412&scoped=true"
import script from "./MeetingUnitsAddProxyDialog.vue?vue&type=script&lang=js"
export * from "./MeetingUnitsAddProxyDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2b8412",
  null
  
)

export default component.exports