import http from './requestType';

/**
 * Returns a list of campaigns for billing view
 * @param  {Object}  [params]                - params
 * @param  {Boolean} [params.hideBilled]     - if true, omits billed campaigns
 * @param  {Boolean} [params.hideIgnored]    - if true, omits campaigns with billing disabled
 * @param  {Date}    [params.fromDate]       - starting date to search
 * @param  {Date}    [params.endDate]        - starting date to search
 * @param  {String}  [params.searchText]     - meeting name to search
 * @param  {String}  [params.subscriptions]  - filter by subscription status
 * @param  {Number}  params.page             - page number
 * @param  {Number}  params.size             - page size
 * @return {Promise} - Promise
 */
export function getCampaignList({
  hideBilled,
  hideIgnored,
  fromDate,
  toDate,
  searchText,
  subscriptions,
  page,
  size
} = {}) {
  return http._get(`Campaigns`, {
    hideBilled,
    hideIgnored,
    fromDate,
    toDate,
    searchText,
    subscriptions,
    page,
    size
  });
}
