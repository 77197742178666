<template>
  <v-container grid-list-md v-if="dataLastFetchedAt && currentUser">
    <v-layout row class="ma-3">
      <v-flex xs12>
        <admin-user-info
          :user-key="userKey"
          :current-user="currentUser"
          :data-last-fetched-at="dataLastFetchedAt"
        />
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs12>
        <v-progress-circular v-if="loading" indeterminate color="primary" />
        <admin-user-memberships
          v-else-if="currentUser"
          :current-user="currentUser"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdminUserInfo from './AdminUserViewUserInfo';
import AdminUserMemberships from './AdminUserViewUserMemberships';

export default {
  name: 'AdminUserView',
  components: {
    AdminUserInfo,
    AdminUserMemberships
  },
  props: {
    userList: {
      type: Object,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      userKey: this.$route.params.userKey
    };
  },
  computed: {
    ...mapGetters('users', ['userByKey']),
    currentUser() {
      return this.userByKey(this.userKey);
    }
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    ...mapActions('users', ['getUser']),
    async init() {
      try {
        this.loading = true;
        // Get latest user data including membership
        await this.getUser(this.userKey);
      } catch (err) {
        console.error('Error getting user details');
        throw err;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
