<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card class>
      <v-card-title>
        <span class="title">{{ title }}</span>
      </v-card-title>
      <v-alert :value="type === 'notices'" type="info" class="ma-3" outline>
        <div class="mb-2">
          To add a Notice to this Approval Request, the Notice must:
        </div>
        <li>Be in "draft" status</li>
        <li>Have a planned send date scheduled</li>
      </v-alert>
      <v-alert :value="type === 'printJobs'" type="info" class="ma-3" outline>
        <div class="mb-2">
          To add a Print Job to this Approval Request, the Print Job must:
        </div>
        <li>Be in "draft" status</li>
        <li>Have a print document and recipients document</li>
        <li>Have a page count and recipient count</li>
      </v-alert>
      <div class="px-3">
        <v-autocomplete
          v-model="selectedValues"
          :items="type === 'notices' ? notices : printJobs"
          chips
          hide-no-data
          hide-selected
          label="Search"
          placeholder="Start typing to Search"
          item-text="name"
          item-value="key"
          multiple
          deletable-chips
          light
          return-object
        />
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-btn color="primary" :disabled="isDisabled" @click.native="handleAdd"
          >add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ItemListDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default() {
        return '';
      }
    },
    type: {
      type: String,
      default() {
        return 'printJobs';
      }
    },
    meetingKey: {
      type: String,
      default() {
        return '';
      }
    },
    maxWidth: {
      type: String,
      default() {
        return '600px';
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      selectedValues: null
    };
  },
  computed: {
    ...mapGetters('meetings/notices', ['meetingNoticeList']),
    ...mapGetters('meetings/printJobs', ['meetingPrintJobData']),
    isDisabled() {
      return _.isEmpty(this.selectedValues);
    },
    notices() {
      // filter out notices with draft status and with send date
      return _.filter(
        this.meetingNoticeList,
        notice =>
          ['draft'].includes(notice.status.toLowerCase()) && notice.sendOn
      ).map(notice => {
        return {
          key: notice.key,
          name: notice.purpose
        };
      });
    },
    printJobs() {
      // filter out notices with draft status
      return _.filter(
        this.meetingPrintJobData,
        printjob =>
          ['draft'].includes(printjob.status.toLowerCase()) &&
          printjob.items &&
          printjob.items.some(item => item.jobItemType === 'printing') &&
          printjob.items.some(item => item.jobItemType === 'recipients') &&
          printjob.packagesMailed &&
          printjob.packagePagesPrinted
      ).map(printjob => {
        return {
          key: printjob.key,
          name: printjob.jobName
        };
      });
    }
  },
  watch: {
    isOpen() {
      this.init();
    }
  },
  methods: {
    ...mapActions('meetings/notices', ['getMeetingNotices']),
    ...mapActions('meetings/printJobs', ['getMeetingPrintJobList']),
    async init() {
      try {
        if (this.type === 'notices') {
          await this.getMeetingNotices({
            shortCode: this.shortCode,
            includeStats: false
          });
          return;
        } else {
          // load print jobs items
          await this.getMeetingPrintJobList({
            key: this.meetingKey,
            includeItems: true
          });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    handleAdd() {
      this.$emit('add', { items: this.selectedValues, type: this.type });
      this.resetState();
    },
    resetState() {
      this.selectedValues = null;
    }
  }
};
</script>

<style scoped></style>
