<template>
  <div class="chatbox">
    <v-textarea
      ref="note_textarea_ref"
      class="mt-1"
      solo
      hide-details
      rows="4"
      placeholder="Type your message here. Ctrl+enter shortcut to submit note."
      v-model="newNote"
      @keyup.ctrl.enter="emitAddNote"
    />

    <v-layout>
      <v-autocomplete
        chips
        clearable
        deletable-chips
        dense
        multiple
        small-chips
        solo
        label="Start typing to tag someone..."
        v-model="taggedUsers"
        :items="users"
        item-text="name"
        item-value="email"
        return-object
      >
        <template v-slot:item="{ item }">
          <div>
            {{ item.name }}
            <span v-if="item.isAssigned"> (assigned)</span>
          </div>
        </template>
      </v-autocomplete>
    </v-layout>
    <v-layout>
      <v-menu transition="slide-y-transition" top v-if="!isCollaborator">
        <template v-slot:activator="{ on }">
          <div>
            <v-chip
              v-on="on"
              label
              small
              class="white--text ml-0"
              :color="eventColor(selectedType)"
            >
              {{ selectedType }}
              <v-icon small class="ml-1">edit</v-icon>
            </v-chip>
            <span class="caption grey--text ml-2"
              >Click to change note label</span
            >
          </div>
        </template>
        <v-list>
          <v-list-tile
            v-for="(type, index) in checklistStages"
            :key="index"
            @click="setSelectedType(type)"
          >
            <v-list-tile-content>
              {{ type }}
            </v-list-tile-content>
          </v-list-tile>
          <!-- Options that are not in the checklist stages -->
          <v-list-tile @click="setSelectedType('invoice')">
            <v-list-tile-content>
              invoice
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click="setSelectedType('general')">
            <v-list-tile-content>
              general
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click="setSelectedType('collaborator')">
            <v-list-tile-content>
              collaborator
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click="setSelectedType('complaint')">
            <v-list-tile-content>
              complaint
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-spacer />
      <v-btn class="primary mt-1" @click="emitAddNote">submit</v-btn>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import getEventColor from '@/lib/get-event-color';

export default {
  name: 'MeetingNotesChatbox',
  props: {
    defaultType: {
      type: String,
      default: ''
    },
    isCollaborator: {
      type: Boolean,
      default: false
    },
    checklistStages: {
      type: Array,
      default() {
        return [];
      }
    },
    taggingUsers: {
      type: Array,
      default() {
        return [];
      }
    },
    preferredManager: {
      type: String,
      default: ''
    },
    checklistAssignees: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      newNote: '',
      selectedType: this.isCollaborator ? 'collaborator' : this.defaultType,
      taggedUsers: []
    };
  },
  computed: {
    users() {
      let assignedUsers = [];
      // Populate assigned users
      if (this.checklistAssignees) {
        assignedUsers = assignedUsers.concat(this.checklistAssignees);
      }
      if (this.preferredManager) {
        assignedUsers.push(this.preferredManager);
      }

      // Shifts assigned users to top of tagging users dropdown
      if (assignedUsers.length > 0) {
        const arr = _.cloneDeep(this.taggingUsers);
        for (const assignedUser of assignedUsers) {
          const index = arr.findIndex(obj => obj.email === assignedUser);

          if (index > -1) {
            let [matchedObj] = arr.splice(index, 1);
            matchedObj = {
              ...matchedObj,
              isAssigned: true
            };
            arr.unshift(matchedObj);
          }
        }
        return arr;
      }
      return this.taggingUsers;
    }
  },
  methods: {
    emitAddNote() {
      // Check if note is blank before emitting
      if (this.newNote) {
        this.$emit('addNewNote', {
          newNote: this.newNote,
          noteType: this.selectedType,
          taggedUsers: this.taggedUsers
        });
        this.newNote = '';
        this.taggedUsers = [];
      }
    },
    eventColor(type) {
      return getEventColor('checklist', type);
    },
    setSelectedType(type) {
      this.selectedType = type;
      this.$nextTick(() => this.$refs.note_textarea_ref.focus());
    }
  }
};
</script>

<style scoped>
.chatbox {
  width: 100%;
  border-top: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #e7f2fb;
  padding: 5px 24px;
}
</style>
