var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-2 rounded-borders",attrs:{"color":"grey lighten-4","flat":""}},[_c('v-card-text',[(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_vm._t("header"),(_vm.subscription)?_c('div',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v("Subscription:")]),_c('router-link',{staticClass:"mt-2",attrs:{"to":{
                name: 'accountSubscription',
                params: { accountKey: _vm.accountKey }
              },"target":"_blank"}},[(_vm.subscription.status === 'no_subscription')?_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.formatStatus(_vm.subscription.status)))]):_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.subscription.productName)+" ("+_vm._s(_vm.formatStatus(_vm.subscription.status))+")")])])],1)],1),(!_vm.hideRefresh)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"mt-3 grey--text text--darken-1"},[_vm._v(" Last Updated: ")]),_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm._f("dateFromNow")(_vm.cachedAt))+" ")])]),_c('v-flex',{staticClass:"mt-3 text-xs-right",attrs:{"xs6":""}},[_c('v-btn',{attrs:{"flat":"","icon":"","small":"","loading":_vm.loading,"color":"secondary"},nativeOn:{"click":function($event){return _vm.refreshSubscription.apply(null, arguments)}}},[_c('v-icon',[_vm._v("cached")])],1)],1)],1):_vm._e()],1):_c('div',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v("Subscription:")]),_c('router-link',{staticClass:"mt-2",attrs:{"to":{
                name: 'accountSubscription',
                params: { accountKey: _vm.accountKey }
              },"target":"_blank"}},[_c('span',[_vm._v("No Subscription")])])],1),(!_vm.hideRefresh)?_c('v-flex',{staticClass:"text-xs-right",attrs:{"xs6":""}},[_c('v-btn',{attrs:{"flat":"","icon":"","small":"","loading":_vm.loading,"color":"secondary"},nativeOn:{"click":function($event){return _vm.refreshSubscription.apply(null, arguments)}}},[_c('v-icon',[_vm._v("cached")])],1)],1):_vm._e()],1)],1),_c('div',[_c('div',{staticClass:"my-1"},[_c('a',{attrs:{"href":_vm.goToInvoicesPage}},[_c('span',{staticClass:"red--text text-underline"},[_vm._v(" Unpaid invoices amount: $"+_vm._s(_vm.invoiceOwing)+" ")])])]),_c('div',{staticClass:"my-1"},[_c('a',{attrs:{"href":_vm.goToSubscriptionPage}},[_c('span',{staticClass:"red--text text-underline"},[_vm._v(" Unpaid subscription amount: $"+_vm._s(_vm.subscriptionBalance)+" ")])])])])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }