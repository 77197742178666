<template>
  <div>
    <v-container>
      <div class="title grey--text text--darken-4 mb-1">Custom Options</div>
      <div class="body-1 grey--text text--darken-2 mb-2">
        Specifies custom display options for the Proxy App. Each property
        corresponds to a "page" in the Proxy App.
      </div>
    </v-container>

    <v-divider />

    <v-expansion-panel class="elevation-0">
      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Home</span>
        </div>
        <v-card>
          <v-card-text>
            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="
                    meeting.options.proxyAppDisplayOptions.home
                      .hideMeetingDocuments
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.home.hideMeetingDocuments',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Hide Meeting Documents</div>
                <div class="grey--text text--darken-2">
                  Whether to hide the meeting documents from being downloaded
                  (regardless of verified user)
                </div>
              </div>
            </v-layout>

            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  :true-value="'simple'"
                  :false-value="'default'"
                  v-model="meeting.options.proxyAppDisplayOptions.home.mode"
                  @change="
                    onChange('options.proxyAppDisplayOptions.home.mode', $event)
                  "
                />
              </div>
              <div>
                <div class="subheading">Enable Simple Home Page Mode</div>
                <div class="grey--text text--darken-2">
                  Simple mode hides all the meeting details, documents download
                  area, and the text above the start button
                </div>
              </div>
            </v-layout>

            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  v-model="
                    meeting.options.proxyAppDisplayOptions.home.simpleRsvp
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.home.simpleRsvp',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Enable Simple RSVP Popup</div>
                <div class="grey--text text--darken-2">
                  Use a simplier RSVP modal popup that does not mention anything
                  about voting and uses a close button instead of a "Vote Now"
                  button.
                </div>
              </div>
            </v-layout>

            <v-text-field
              label="Customize Start Proxy Text"
              placeholder="(Default) 'Cast Your Vote Online Now!'"
              v-model="
                meeting.options.proxyAppDisplayOptions.home
                  .customizeStartProxyText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.home.customizeStartProxyText',
                  $event
                )
              "
            />

            <v-text-field
              label="Customize submissions closed description text"
              placeholder="(Default) 'Please attend the event to cast your vote.'"
              v-model="
                meeting.options.proxyAppDisplayOptions.home
                  .customizeSubmissionsClosedDescText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.home.customizeSubmissionsClosedDescText',
                  $event
                )
              "
            />

            <v-layout>
              <v-text-field
                label="Timezone for displaying meeting date/time"
                placeholder="(Default) America/Toronto"
                v-model="
                  meeting.options.proxyAppDisplayOptions.home.dateFormat
                    .timezone
                "
                @input="
                  onChange(
                    'options.proxyAppDisplayOptions.home.dateFormat.timezone',
                    $event
                  )
                "
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Ownership</span>
        </div>
        <v-card>
          <v-card-text>
            <v-text-field
              label="Header Text"
              v-model="
                meeting.options.proxyAppDisplayOptions.ownership.headerText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.ownership.headerText',
                  $event
                )
              "
            />
            <v-text-field
              label="Snippet"
              v-model="meeting.options.proxyAppDisplayOptions.ownership.snippet"
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.ownership.snippet',
                  $event
                )
              "
            />
            <v-text-field
              label="Address Label"
              v-model="
                meeting.options.proxyAppDisplayOptions.ownership
                  .addressLabelText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.ownership.addressLabelText',
                  $event
                )
              "
            />
            <v-text-field
              label="Unit Label"
              v-model="
                meeting.options.proxyAppDisplayOptions.ownership.unitLabelText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.ownership.unitLabelText',
                  $event
                )
              "
            />
            <v-text-field
              label="Name Label"
              v-model="
                meeting.options.proxyAppDisplayOptions.contact.nameLabelText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.contact.nameLabelText',
                  $event
                )
              "
            />
            <v-text-field
              label="Email Label"
              v-model="
                meeting.options.proxyAppDisplayOptions.contact.emailLabelText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.contact.emailLabelText',
                  $event
                )
              "
            />
            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="
                    meeting.options.proxyAppDisplayOptions.ownership
                      .disableInputs
                  "
                  @change="toggleUnverifiedVoting($event)"
                />
              </div>
              <div>
                <div class="subheading">Disable Inputs</div>
                <div class="grey--text text--darken-2">
                  Prevents the changing of the ownership input fields thereby
                  disallowing unverified voters from proceeding.
                </div>
              </div>
            </v-layout>

            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  true-value="text"
                  false-value
                  v-model="
                    meeting.options.proxyAppDisplayOptions.ownership
                      .addressInputType
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.ownership.addressInputType',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Enable text field for addresses</div>
                <div class="grey--text text--darken-2">
                  Customize the input type for addresses. Recommended when there
                  are a lot of addresses.
                </div>
              </div>
            </v-layout>

            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  true-value="text"
                  false-value
                  v-model="
                    meeting.options.proxyAppDisplayOptions.ownership
                      .unitInputType
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.ownership.unitInputType',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Enable text field for unit field</div>
                <div class="grey--text text--darken-2">
                  Customize the input type for unit field.
                </div>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Is Resident</span>
        </div>
        <v-card>
          <v-card-text>
            <v-text-field
              label="Header Text"
              v-model="
                meeting.options.proxyAppDisplayOptions.isresident.headerText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.isresident.headerText',
                  $event
                )
              "
            />
            <v-text-field
              label="Snippet"
              v-model="
                meeting.options.proxyAppDisplayOptions.isresident.snippet
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.isresident.snippet',
                  $event
                )
              "
            />
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Proxy</span>
        </div>
        <v-card>
          <v-card-text>
            Moved to <b>Proxy Holders</b> settings page
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Communications</span>
        </div>
        <v-card>
          <v-card-text>
            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="meeting.options.disableComms"
                  @change="onChange('options.disableComms', $event)"
                />
              </div>
              <div>
                <div class="subheading">Disable Comms</div>
                <div class="grey--text text--darken-2">
                  If checked, owners will not be asked for their e-consent
                </div>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Review</span>
        </div>
        <v-card>
          <v-card-text>
            <v-text-field
              label="Ownership Label"
              v-model="
                meeting.options.proxyAppDisplayOptions.review.ownershipLabelText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.review.ownershipLabelText',
                  $event
                )
              "
            />
            <v-layout row>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="
                    meeting.options.proxyAppDisplayOptions.review
                      .hideChangeLinks
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.review.hideChangeLinks',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Hide Change Links</div>
                <div class="grey--text text--darken-2">
                  Determines whether the "change" link beside each review
                  question is shown. This link takes the voter back to the
                  question to change their answer. Default is FALSE.
                </div>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Legal / Confirm</span>
        </div>
        <v-card>
          <v-card-text>
            <v-text-field
              label="Header Text"
              v-model="
                meeting.options.proxyAppDisplayOptions.confirm.headerText
              "
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.confirm.headerText',
                  $event
                )
              "
            />
            <v-text-field
              label="Snippet"
              v-model="meeting.options.proxyAppDisplayOptions.confirm.snippet"
              @input="
                onChange(
                  'options.proxyAppDisplayOptions.confirm.snippet',
                  $event
                )
              "
            />
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Done</span>
        </div>
        <v-card>
          <v-card-text>
            <v-layout row>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="
                    meeting.options.proxyAppDisplayOptions.done.showStartAgain
                  "
                  @change="
                    onChange(
                      'options.proxyAppDisplayOptions.done.showStartAgain',
                      $event
                    )
                  "
                />
              </div>
              <div>
                <div class="subheading">Show Start Again Button</div>
                <div class="grey--text text--darken-2">
                  Toggle hiding of the "Start again" content and button.
                  Defaults to TRUE.
                </div>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>

      <v-expansion-panel-content>
        <div slot="header">
          <span class="body-2">Other</span>
        </div>
        <v-card>
          <v-card-text>
            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="meeting.options.allowNoInstructions"
                  @change="onChange('options.allowNoInstructions', $event)"
                />
              </div>
              <div>
                <div class="subheading">Allow No Instructions</div>
                <div class="grey--text text--darken-2">
                  <li>
                    Allow an owner to set the `No Instructions for proxy` option
                    for Bill 106 proxies
                  </li>
                  <li>
                    If TRUE, the "No Instructions" option will be set if the
                    voter abstains on all matters
                  </li>
                  <li>
                    If FALSE, the "Yes, Instructions" option will be set (ie.
                    business as usual).
                  </li>
                </div>
              </div>
            </v-layout>

            <v-layout class="mt-3">
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="meeting.options.disableIntervalPolling"
                  @change="onChange('options.disableIntervalPolling', $event)"
                />
              </div>
              <div>
                <div class="subheading">Disable interval refresh on RTV</div>
                <div class="grey--text text--darken-2">
                  <li>
                    This option will disable interval polling (getBallot
                    refresh) on the RTV app. This is a periodic refresh of the
                    RTV app state that occurs every 45 seconds.
                  </li>
                </div>
              </div>
            </v-layout>

            <v-layout class="mt-3">
              <div class="pr-3">
                <v-checkbox
                  :true-value="true"
                  :false-value="false"
                  v-model="meeting.options.enableRsvpEmails"
                  @change="onChange('options.enableRsvpEmails', $event)"
                />
              </div>
              <div>
                <div class="subheading">Enable RSVP registration emails</div>
                <div class="grey--text text--darken-2">
                  <li>
                    If enabled, a registration email from our servers will be
                    sent to voters when they RSVP. This can happen via the RTV
                    app or if a voter is set attending in the units tab. This
                    setting does NOT affect the zoom registration emails.
                  </li>
                </div>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import _ from 'lodash';
import CodeMirror from '@/components/CodeMirror';

export default {
  name: 'MeetingSettingsOptionsInput',
  components: {
    CodeMirror
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      meeting: {
        options: {
          // Specifies a list of additional (optional) selectable proxy holders that should not be defaults.
          // The ProxyApp will use this list to display a selectable list.
          // NOTE: Originally added for the CCI-N 2017 AGM.
          otherProxyHolders: [],

          // If set to true, the proxy holder question won't be required
          disableProxyHolder: false,

          // If set to true, owners should not be asked for their e-consent
          disableComms: false,

          // Allow an owner to set the `No Instructions for proxy` option for Bill 106 proxies.
          // If TRUE, the "No Instructions" option will be set if the voter abstains on all matters
          // If FALSE, the "Yes, Instructions" option will be set. (ie. business as usual)
          // Defaults to FALSE
          allowNoInstructions: false,

          // Disable interval polling on the RTV app. This is a periodic refresh of data in RTV.
          // The app will still work without the interval. This was added as a back up to get the current app state
          // if the pusher events were to fail
          disableIntervalPolling: false,

          // If enabled, a registration email from our servers will be sent to voters when they RSVP.
          // This can happen via the RTV app or if a voter is set attending in the units tab. This
          // setting does NOT affect the zoom registration emails.
          enableRsvpEmails: false,

          // This option will enable access to the RTV zoom integrated
          // beta version from the base RTV application. This is an
          // additional button accessible through the "Click here to open
          // Zoom Webinar" dialog.
          enableRtvZoom: false,

          // If enabled, the GQ API will send mobile notifications using pusher.
          enableMobileNotifications: false,

          // Specifies custom display options for the ProxyApp. Each sub property corresponds to a "page" in the ProxyApp
          proxyAppDisplayOptions: {
            // ProxyApp Home Page Customizations
            home: {
              // Used to specify which type of home page to show. Available types are ['simple', 'default'].
              // Simple mode - used to only display title, description, and start button. Use this for amendments
              // Default mode - the default meeting mode
              mode: 'default',

              // Whether to hide the meeting documents from being downloaded (regardless of verified user)
              hideMeetingDocuments: false,
              dateFormat: {
                timezone: ''
              },

              // If RSVP modal should not encourage voting and should use only a close button
              simpleRsvp: false,

              // Used to customize the text above the Start button, on the homepage of the proxy tool.
              // The default text is "Cast Your Vote Online Now!"
              customizeStartProxyText: '',

              // Used to customize the text in the submission closed description, on the homepage of the proxy tool.
              // The default text is "Please attend the event to cast your vote."
              customizeSubmissionsClosedDescText: ''
            },

            // Ownership Page
            ownership: {
              // Ownership Page Text Customizations
              headerText: '',
              snippet: '',
              addressLabelText: '',
              unitLabelText: '',

              // Customize the <input type=??>.  Used best when there are a lot of addresses
              addressInputType: '',
              unitInputType: '',

              // Prevents changing of the input fields
              disableInputs: false
            },

            // Contact Page
            contact: {
              // Text Customizations
              snippet: '',
              nameLabelText: '',
              emailLabelText: ''
            },

            // "Are you an owner occupied owner" (aka "isresident") question.
            isresident: {
              headerText: '',
              snippet: ''
            },

            // Proxy Page Text Customizations
            proxy: {
              snippet: '',

              // If true, should render out all names in the default proxy holders array as options. Default `false`.
              listAllDefaultHolders: false,

              // If true, the proxy holder question will be reordered so that it will be asked before the voting questions. Default `false`.
              shouldAskFirst: false
            },

            // Review Page Text Customizations
            review: {
              ownershipLabelText: '',

              // Determines whether the "change" link beside each review question is shown. This link takes the voter back to the question to change their answer.
              // Default is FALSE
              hideChangeLinks: false
            },

            // Confirm Page Template
            confirm: {
              headerText: '',
              snippet: ''
            },

            // Done page customizations
            done: {
              // Toggle hiding of the "Start again" content and button. Defaults to TRUE.
              showStartAgain: true
            }
          }
        }
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let that = this;

      // Init will set values in local state to match the options prop
      // This is necessary as form inputs (checkboxes, textfields) can only bind to an existing data property

      // Iterate through unnested object properties
      Object.keys(this.options).forEach(function(key) {
        if (key !== 'proxyAppDisplayOptions') {
          that.meeting.options[key] = _.clone(that.options[key]);
        }
      });

      // Iterate through proxyAppDisplayOptions
      if (_.has(this.options, 'proxyAppDisplayOptions')) {
        Object.keys(this.options.proxyAppDisplayOptions).forEach(function(key) {
          that.meeting.options.proxyAppDisplayOptions[key] = _.clone(
            that.options.proxyAppDisplayOptions[key]
          );
        });
      }

      // Fix for missing timezone property. Allows it to render if `dateFormat` doesn't
      // exist, which it won't usually.
      if (!_.has(this.options, 'proxyAppDisplayOptions.home.dateFormat')) {
        this.meeting.options.proxyAppDisplayOptions.home.dateFormat = {};
      }
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    onDelete(key, value) {
      this.$emit('delete', { key, value });
    },
    toggleUnverifiedVoting(value) {
      this.onChange(
        'options.proxyAppDisplayOptions.ownership.disableInputs',
        value
      );
    }
  }
};
</script>

<style scoped></style>
