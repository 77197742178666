'use strict';

/**
 * @fileoverview API calls for /auth routes
 */

import axios from 'axios';

const instance = axios.create();
const AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL;

/**
 * Login and get the access token. Token will be stored in an `httpOnly`
 * cookie, so we don't need to do anything other than to make the request
 * @param  {Object} obj                 - the login object
 * @param  {String} obj.username        - the username
 * @param  {String} obj.paswsword       - the password
 * @param  {String} [obj.token]         - mfa token
 * @param  {String} obj.trustThisDevice - check if the "trust" checkbox is checked
 * @return {Promise}              - promise
 */
export function postAuthToken({ username, password, token, trustThisDevice }) {
  const credentials = { username, password, mfaToken: token, trustThisDevice };
  return instance.post(`${AUTH_BASE_URL}token`, credentials, {
    withCredentials: true,

    // Forces promise to resolve even if error response
    validateStatus: () => true
  });
}

/**
 * Trigger the MFA Token Sending Process
 * @param  {Object} obj           - the login object
 * @param  {String} obj.username  - the username
 * @param  {String} obj.paswsword - the password
 * @return {Promise}              - promise
 */
export function postAuthTokenChallenge({ username, password }) {
  const credentials = { username, password };
  return instance.post(`${AUTH_BASE_URL}token/challenge`, credentials, {
    withCredentials: true,
    // Forces promise to resolve even if error response
    validateStatus: () => true
  });
}

/**
 * Log out the user
 * @return {Promise}       response
 */
export function postLogout() {
  return instance.post(`${AUTH_BASE_URL}logout`, {}, { withCredentials: true });
}

/**
 * Get the user's profile
 * @return {Promise}              - promise
 */
export function getUserInfo() {
  return instance.get(`${AUTH_BASE_URL}userinfo`, { withCredentials: true });
}

/**
 * Send a password reset email to specified email address
 *
 * @param  {String} email the email address
 * @return {Promise}              - promise
 */
export function postForgotPassword(email) {
  return instance.post(
    `${AUTH_BASE_URL}forgot-pw`,
    { email },
    { withCredentials: true }
  );
}

/**
 * Checks if a password reset token is valid
 *
 * @param  {String} token  - the token value
 * @param  {String} type   - the token type ['reset', ['invite']]
 * @return {Promise}       - response
 */
export function getPasswordReset(token = null, type = null) {
  if (!token) {
    throw new Error('Missing or invalid token');
  }
  if (!['reset', 'invite'].includes(type)) {
    throw new Error(`Invalid token type: ${type}`);
  }
  const headers =
    type === 'reset'
      ? { 'X-PASSWORD-RESET-TOKEN': token }
      : { 'X-USER-INVITE-TOKEN': token };

  return instance.get(`${AUTH_BASE_URL}reset-pw`, {
    headers,
    withCredentials: true
  });
}

/**
 * Sets the new password for a reset request
 *
 * @param  {String} token  - the token value
 * @param  {String} type        - the token type ['reset', ['invite']]
 * @param  {String} newPassword - the new password
 * @return {Promise}            - promise
 */
export function postPasswordReset(token, type, newPassword) {
  if (!token || !newPassword) {
    throw new Error('Missing token or password');
  }
  const password = newPassword;
  const headers =
    type === 'reset'
      ? { 'X-PASSWORD-RESET-TOKEN': token }
      : { 'X-USER-INVITE-TOKEN': token };

  return instance.post(
    `${AUTH_BASE_URL}reset-pw`,
    { password },
    { headers, withCredentials: true }
  );
}

/**
 * Sets a new password for an authenticated/logged in user
 *
 * @param  {String} username   - The user's email
 * @param  {String} currentPassword - The current user's password
 * @param  {String} newPassword     - the new password
 * @return {Promise}                - promise
 */
export function putPasswordChange({ password, newPassword }) {
  if (!password || !newPassword) {
    throw new Error('Missing password');
  }
  const credentials = { password, newPassword };
  return instance.put(`${AUTH_BASE_URL}password`, credentials, {
    withCredentials: true,
    // Forces promise to resolve even if error response (Enabling us to custom error handle)
    validateStatus: () => true
  });
}

/**
 * Login via OAth. Token will be stored in an `httpOnly`
 * cookie, so we don't need to do anything other than to make the request
 * @param  {string} network   - the IDP provider used (eg. Google)
 * @param  {string} token     - the token retrieved from IDP
 * @return {Promise}          - promise
 */
export function postOAuthToken(network, token) {
  return instance.post(
    `${AUTH_BASE_URL}oauth/token`,
    { network, token },
    { withCredentials: true }
  );
}

/**
 * Obtain a new `accessToken` using `refreshToken`. Both
 * tokens are stored in `httpOnly` cookies.
 * @return {Promise}
 */
export function postOAuthRefreshToken() {
  return instance.post(
    `${AUTH_BASE_URL}oauth/refresh`,
    {},
    { withCredentials: true }
  );
}

/**
 * Returns true if the URL matches the API url used to retrieve
 * an access token.
 * @param {string} url
 */
export function isRefreshUrl(url) {
  return url === `${AUTH_BASE_URL}oauth/refresh`;
}
