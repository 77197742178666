'use strict';

/**
 * @fileoverview Routes for all the /admin/ sub views
 */

// Voters Home
import VotersHomeView from '@/views/voters/home/VotersHomeView';

export default [
  {
    name: 'votersHome',
    path: '',
    label: 'Home',
    icon: 'home',
    meta: {
      headerLabel: 'Home'
    },
    component: VotersHomeView
  }
];
