<template>
  <v-dialog max-width="600px" persistent :value="value" @input="emitInput">
    <v-card>
      <v-card-title class="title"
        >Are you sure you want to reset the form?</v-card-title
      >
      <v-card-text>
        <p>
          The entire form will be reset. Click "OK" to confirm you'd like to
          proceed.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click="emitClose"
          ><v-icon>chevron_left</v-icon> Close</v-btn
        >
        <v-spacer />
        <v-btn class="primary text-xs-center" @click="emitReset"
          >Reset Form</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingProxiesCreateResetDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitReset() {
      this.$emit('reset');
    }
  }
};
</script>

<style scoped></style>
