import http from './requestType';

/**
 * Get Meeting Live Vote data for specified business
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingRTVLiveAnswersData(shortCode) {
  return http._get(`Meetings/${shortCode}/live-votes`);
}

/**
 * Get meeting's live vote tallies
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingRTVLiveAnswersTallies(shortCode) {
  return http._get(`Meetings/${shortCode}/live-votes/tallies`);
}

/**
 * Reset an array of voter answers
 * @param  {string} shortCode - the meeting shortcode
 * @param  {array} answers  - an array of answer keys
 * @return {Promise} - Promise
 */
export function resetMeetingVoterAnswers(shortCode, answers) {
  return http._patch(`Meetings/${shortCode}/live-votes`, { answers });
}

/**
 * Get the combined voting V2 results
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingRTVCombinedVoteV2Data(shortCode) {
  return http._get(`Meetings/${shortCode}/live-votes/tallies-combined-v2`);
}

/**
 * Get the combined voting V3 results
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingRTVCombinedVoteV3Data(shortCode) {
  return http._get(`Meetings/${shortCode}/live-votes/tallies-combined-v3`);
}

/**
 * find a number of vote exclusions
 * @param  {string} shortCode   - the meeting shortcode
 * @param  {string} questionKey - the advance question key
 * @return {Promise} - Promise
 */
export function findVoteExclusions(shortCode, questionKey) {
  return http._get(
    `Meetings/${shortCode}/live-votes/find-vote-exclusions/${questionKey}`
  );
}

/**
 * Reset an array of voter answers
 * @param  {string} shortCode       - the meeting shortcode
 * @param  {string} questionKey     - the advance question key
 * @param  {string} vote            - vote exclusion to toggle
 * @param  {string} liveQuestionKey - live question key
 * @return {Promise} - Promise
 */
export function toggleVoteExclusion(
  shortCode,
  questionKey,
  exclusion,
  liveQuestionKey
) {
  return http._put(`Meetings/${shortCode}/live-votes/toggle-vote-exclusion`, {
    questionKey,
    exclusion,
    liveQuestionKey
  });
}

/**
 * Reset an array of winner
 * @param  {string} shortCode       - the meeting shortcode
 * @param  {string} questionKey     - the advance question key
 * @param  {string} winner          - winner to toggle
 * @param  {string} liveQuestionKey - live question key
 * @return {Promise} - Promise
 */
export function toggleWinner(shortCode, questionKey, winner, liveQuestionKey) {
  return http._put(`Meetings/${shortCode}/live-votes/toggle-winner`, {
    questionKey,
    winner,
    liveQuestionKey
  });
}

/**
 * Retrieve RTV presence information
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getRtvPresenceData(shortCode) {
  return http._get(`Meetings/${shortCode}/live-votes/presence`);
}
