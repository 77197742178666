<template>
  <v-dialog persistent :value="value" max-width="550px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <div class="title">Stop Question?</div>
      </v-card-title>

      <v-card-text class="text-center">
        <strong>"{{ question.label }}"</strong>
        <p class="mt-3">
          Click <strong>STOP</strong> to close voting on this question, or
          <strong>CANCEL</strong> to continue voting.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click="emitClose">cancel</v-btn>
        <v-btn class="error" @click="emitStop">stop</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RTVStopQuestionDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitStop() {
      this.$emit('stop', this.question);
    }
  }
};
</script>
