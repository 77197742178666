<template>
  <v-container>
    <!-- Loading -->
    <v-layout column align-center fill-height v-if="hasWebcast && loading">
      <p class="title">Loading...</p>
    </v-layout>

    <!-- Webcast expired -->
    <v-layout v-else-if="hasWebcast && webcast.reset && !loading">
      <v-flex xs12>
        <div class="webcast-info gq-cell--section-title mb-4">
          <div class="title grey--text text--darken-4 mb-1">
            The Zoom webcast record has expired
          </div>
          <p>
            To create a new webcast record, the expired records must be removed.
            Please review the expired details below and confirm before
            proceeding to reset.
          </p>

          <div class="mb-3 subheading">
            Expired Webcast created on: {{ createdAt }}
          </div>

          <ul>
            <li><b>Expired Webcast name:</b> {{ webcast.meetingName }}</li>
            <li><b>Cast id:</b> {{ webcast.castId }}</li>
            <li>
              <b>Cast provider:</b>
              {{ isMeeting ? 'Zoom Meeting' : 'Zoom Webinar' }}
            </li>
            <li v-if="!isMeeting">
              <b>Rehearsal time:</b> {{ rehearsalDate }}
              {{ rehearsalTime }}
              <timezone-helper :date="webcast.rehearsalAt" />
            </li>
            <li v-if="webcast.startedAt">
              <b>Started at:</b>
              {{ startDate }} {{ startTime }}
              <timezone-helper :date="webcast.startedAt" />
            </li>
            <li v-if="webcast.endedAt">
              <b>Ended at:</b>
              {{ endDate }} {{ endTime }}
              <timezone-helper :date="webcast.endedAt" />
              <span v-if="logs"
                >( <a @click="dialog.meetingLog = true"> Log </a>)</span
              >
            </li>
            <li>
              <b>Registrations:</b>
              {{ webcast.registrations }}
            </li>
            <li>
              <b>Panelists:</b>
              {{ webcast.panelists.length }}
            </li>
            <li>
              <b>Webcast joined attendance:</b>
              {{ webcast.attendances }}
            </li>
          </ul>

          <v-divider class="my-3" />

          <v-btn
            color="primary"
            @click="dialog.confirmReset = true"
            v-if="isAdmin"
            >Reset Webinar</v-btn
          >
        </div>
      </v-flex>
    </v-layout>

    <!-- Done Loading -->
    <v-layout
      v-else-if="hasWebcast && !loading && !isSelfHosted"
      transition="slide-x-reverse-transition"
    >
      <v-flex xs12>
        <v-alert
          style="margin-bottom:0px;"
          :value="true"
          color="blue"
          icon="info"
        >
          <h2>{{ isMeeting ? 'Meeting' : 'Webinar' }} License</h2>
        </v-alert>
        <div
          v-if="hasZoomData"
          class="webcast-info gq-cell--section-title mb-4"
        >
          <ul class="mb-3">
            <li>
              <div style="display: flex" class="align-center">
                <b>{{ isMeeting ? 'Meeting' : 'Webinar' }} Id:</b>
                {{ zoomData.id }} (
                <v-tooltip v-if="!isInProgress" top>
                  <a
                    slot="activator"
                    @click="bootup"
                    :class="{ disabled: !canStartMeeting }"
                    :disabled="!canStartMeeting"
                  >
                    Start as Host
                  </a>

                  <span>{{ zoomUrlTooltipText }}</span>
                </v-tooltip>
                <v-tooltip v-else top>
                  <a
                    slot="activator"
                    @click="openStartUrl"
                    :class="{ disabled: !canStartMeeting }"
                    :disabled="!canStartMeeting"
                  >
                    Click to Join
                  </a>

                  <span>Join via Zoom</span>
                </v-tooltip>
                )
              </div>
            </li>
            <li>
              <b>Registration:</b> {{ registrationType }}
              (
              <v-tooltip bottom>
                <a slot="activator" @click="dialog.joinUrl = true">
                  Invite Link
                </a>
                <span>
                  Get link for attendees to join directly in Zoom (for
                  emergencies)
                </span>
              </v-tooltip>
              )
            </li>
            <li>
              <b>Dial-ins:</b> {{ dialIns }} for
              {{ dialInCountries }}
            </li>
            <li><b>Dial-in Password:</b> {{ zoomData.password }}</li>
            <li>
              <b>Phone Numbers: </b> {{ phoneNumbers }}
              <v-tooltip top>
                <span slot="activator">
                  <v-icon small @click="copyToClipboard">file_copy</v-icon>
                </span>
                <span>Click to copy</span>
              </v-tooltip>
            </li>
            <li>
              <b>Reports &amp; Recordings: </b>
              <span v-if="hasRecordings" class="green--text">
                Ready (<router-link
                  :to="{ name: 'meetingFiles', params: { shortCode } }"
                  >Go to Files</router-link
                >)
              </span>
              <span v-else-if="recordingsDownloading">
                Processing
              </span>
              <span v-else>
                Not Found
                <span v-if="isAdmin">
                  (<a @click="dialog.recordings = true">Check</a>)
                </span>
              </span>
              <span v-if="hasRecordings">
                (<a @click="dialog.shareRecordings = true">Get Zoom Share URL</a
                >)
              </span>
            </li>
            <li v-if="!isMeeting">
              <b>Pre-meeting Date:</b> {{ rehearsalDate }}
              {{ rehearsalTime }}
              <timezone-helper :date="webcast.rehearsalAt" />
            </li>
            <li v-if="webcast.startedAt">
              <b>Started at:</b>
              {{ startDate }} {{ startTime }}
              <timezone-helper :date="webcast.startedAt" />
            </li>
            <li v-if="webcast.endedAt">
              <b>Ended at:</b>
              {{ endDate }} {{ endTime }}
              <timezone-helper :date="webcast.endedAt" />
              <span v-if="logs"
                >( <a @click="dialog.meetingLog = true"> Log </a>)</span
              >
            </li>
          </ul>
        </div>

        <v-tabs
          v-model="activeTab"
          color="light-blue"
          dark
          slider-color="accent"
        >
          <v-tab key="settings" ripple v-if="isAdmin">
            Settings
          </v-tab>
          <v-tab-item key="settings" v-if="isAdmin">
            <meeting-webcast-view-settings
              v-if="dataLastFetchedAt"
              :webcast="webcast.zoomData"
              :meeting-date="currentMeeting.meetingDate"
              :short-code="currentMeeting.shortCode"
              :meeting-timezone="currentMeeting.meetingTimezone"
              :capacity="webcast.capacity"
              :skip-boot="webcast.skipBoot"
              :is-meeting="isMeeting"
            />
          </v-tab-item>

          <v-tab key="panelists" ripple>
            Panelists
          </v-tab>
          <v-tab-item key="panelists">
            <meeting-webcast-view-panelists
              v-if="dataLastFetchedAt"
              :webcast="webcast"
              :panelists="panelists"
              :short-code="currentMeeting.shortCode"
              :meetingTimezone="currentMeeting.meetingTimezone"
              :documents="currentMeeting.documents"
            />
          </v-tab-item>

          <v-tab key="polls" ripple>
            Polls
          </v-tab>
          <v-tab-item key="polls">
            <meeting-webcast-view-polls
              v-if="dataLastFetchedAt"
              :webcast="webcast"
              :polls="polls"
              :default-motions="defaultMotions"
              :short-code="currentMeeting.shortCode"
            />
          </v-tab-item>

          <v-tab key="slides" ripple>
            Slides
          </v-tab>
          <v-tab-item key="slides">
            <v-card flat>
              <v-card-text>
                <meeting-webcast-view-slides
                  :meeting-name="currentMeeting.name"
                  :meetingTimezone="currentMeeting.meetingTimezone"
                  :condo-code="currentMeeting.corporation.legalNameShort"
                  :date="currentMeeting.meetingDate"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab key="reports" ripple>
            Reports
          </v-tab>
          <v-tab-item key="reports">
            <v-card flat>
              <v-card-text>
                <meeting-webcast-view-reports />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>

    <!-- Self-Hosted Webcast -->
    <v-layout v-else-if="isSelfHosted" transition="slide-x-reverse-transition">
      <v-flex xs12>
        <div class="gq-cell--section-title mb-4">
          <h3>Self-Hosted Meeting</h3>
        </div>
        <v-tabs
          v-model="activeTab"
          color="light-blue"
          dark
          slider-color="accent"
        >
          <v-tab key="settings" ripple v-if="isAdmin">
            Settings
          </v-tab>
          <v-tab-item key="settings" v-if="isAdmin">
            <meeting-webcast-view-self-hosted
              :options="currentMeeting.options"
              :currentMeeting="currentMeeting"
            />
          </v-tab-item>
          <v-tab key="slides" ripple>
            Slides
          </v-tab>
          <v-tab-item key="slides">
            <v-card flat>
              <v-card-text>
                <meeting-webcast-view-slides
                  :meeting-name="currentMeeting.name"
                  :condo-code="currentMeeting.corporation.legalNameShort"
                  :date="currentMeeting.meetingDate"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>

    <!-- No Webcast, let's create one -->
    <v-layout
      v-else-if="isTypeOfMeeting"
      transition="slide-x-reverse-transition"
      align-center
      justify-center
      column
      fill-height
      class="empty-container"
    >
      <h2 class="mb-3">There's no webcast associated with this campaign.</h2>
      <p class="mb-3" v-if="isAdmin">
        Click the button below to create the Zoom Webcast for this campaign with
        all the defaults.
      </p>
      <p v-if="subtype">
        This meeting was created as a
        <strong>"{{ subtype }}"</strong> meeting
      </p>

      <p>
        <v-btn
          color="primary"
          :loading="creating"
          @click="create(false)"
          v-if="isAdmin"
          >Create Webinar</v-btn
        >
        <v-btn
          color="primary"
          :loading="creating"
          @click="create(true)"
          v-if="isAdmin"
          >Create Meeting</v-btn
        >
      </p>
    </v-layout>

    <!-- Campaign Type Does Not Support Webcasts -->
    <v-layout
      v-else
      transition="slide-x-reverse-transition"
      align-center
      justify-center
      column
      fill-height
      class="empty-container"
    >
      <h2 class="mb-3">
        This campaign type does not support webcasts.
      </h2>
      <p class="mb-3">
        Only <b>"GetQuorum Virtual"</b> meetings can have a webcast. The type
        for this meeting is <b>{{ type }}</b
        >.
        <span v-if="subtype"
          >The subtype for this meeting is <b>{{ virtualMeetingType }}</b>
          <v-tooltip bottom>
            <v-icon small slot="activator">info</v-icon>
            <span>
              Please enable GetQuorum License in the Virtual Meeting settings
              tab to create a webcast.
            </span> </v-tooltip
          >.</span
        >
      </p>
    </v-layout>

    <download-recordings-dialog
      :is-open="dialog.recordings"
      :short-code="currentMeeting.shortCode"
      @close="dialog.recordings = false"
    />
    <share-recordings-dialog
      v-if="dialog.shareRecordings"
      :is-open="dialog.shareRecordings"
      :short-code="currentMeeting.shortCode"
      @close="dialog.shareRecordings = false"
    />

    <join-url-dialog
      v-if="zoomData"
      :is-open="dialog.joinUrl"
      :join-url="zoomData.joinUrl"
      @close="dialog.joinUrl = false"
    />

    <event-log-dialog
      v-if="logs"
      :logs="eventLogsData"
      :is-open="dialog.meetingLog"
      @close="dialog.meetingLog = false"
    />

    <start-dialog
      v-if="zoomData"
      :is-open="dialog.start"
      :start-url="startUrl"
      :booting="booting"
      :license="license"
      @close="dialog.start = false"
    />
    <boot-error-dialog
      :is-open="dialog.bootError"
      :error="bootError"
      @close="dialog.bootError = false"
    />

    <confirm-dialog
      :dialog="dialog.confirmReset"
      title="Are you sure?"
      text="You will lose all previous Webcast records, registrations, panelists and reset attendance records in the Membership."
      confirm-text="confirm"
      @confirm="habndleReset"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import { formatDateHumanReadable } from '@/filters';

import MeetingWebcastViewSettings from './MeetingWebcastViewSettings';
import MeetingWebcastViewSlides from './MeetingWebcastViewSlides';
import MeetingWebcastViewPanelists from './MeetingWebcastViewPanelists';
import MeetingWebcastViewPolls from './MeetingWebcastViewPolls';
import MeetingWebcastViewReports from './MeetingWebcastViewReports';
import DownloadRecordingsDialog from './DownloadRecordingsDialog';
import JoinUrlDialog from './JoinUrlDialog';
import TimezoneHelper from '@/components/TimezoneHelper';
import MeetingWebcastViewSelfHosted from './MeetingWebcastViewSelfHosted.vue';
import EventLogDialog from './WebcastLogDialog';
import StartDialog from './StartDialog.vue';
import BootErrorDialog from './BootErrorDialog.vue';
import ShareRecordingsDialog from './ShareRecordingsDialog';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

export default {
  name: 'MeetingWebcastView',
  components: {
    MeetingWebcastViewSettings,
    MeetingWebcastViewSlides,
    MeetingWebcastViewPanelists,
    MeetingWebcastViewPolls,
    DownloadRecordingsDialog,
    MeetingWebcastViewReports,
    JoinUrlDialog,
    TimezoneHelper,
    RichTextEditor,
    MeetingWebcastViewSelfHosted,
    EventLogDialog,
    StartDialog,
    BootErrorDialog,
    ShareRecordingsDialog,
    ConfirmDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatDateHumanReadable
  },
  data() {
    return {
      activeTab: null,
      creating: false,
      loading: true,
      booting: false,
      shortCode: this.$route.params.shortcode,
      bootError: '',
      dialog: {
        recordings: false,
        joinUrl: false,
        meetingLog: false,
        start: false,
        bootError: false,
        shareRecordings: false,
        confirmReset: false
      }
    };
  },
  computed: {
    ...mapGetters('meetings/webcast', [
      'webcast',
      'logs',
      'zoomData',
      'schedulers',
      'panelists',
      'polls',
      'defaultMotions',
      'recordingsDownloading',
      'dataLastFetchedAt',
      'license'
    ]),
    ...mapGetters(['login', 'isAdmin', 'getScopeByAction']),
    hasPermissionsToAssignLicense() {
      const { profile } = this.login;
      return (
        this.getScopeByAction(
          this.$route.params.shortcode,
          'meeting.webcast.boot.early'
        ) && profile.type === 'user'
      );
    },
    canStartMeeting() {
      const {
        meetingTimezone: timezone,
        meetingDate: startTime,
        rehearsalAt: rehearsalTime
      } = this.currentMeeting;

      return (
        this.isAdmin ||
        this.hasPermissionsToAssignLicense ||
        !moment()
          .tz(timezone)
          .isBefore(
            moment(startTime).subtract(this.timeBeforeMeeting, 'minutes')
          ) ||
        !moment()
          .tz(timezone)
          .isBefore(
            moment(rehearsalTime).subtract(this.timeBeforePreMeeting, 'minutes')
          )
      );
    },
    hasWebcast() {
      const virtualType = this.virtualMeetingType;
      return (
        this.currentMeeting.castId &&
        (!this.currentMeeting.options.subtype ||
          (this.currentMeeting.options.subtype === 'Virtual' &&
            (virtualType === 'GQ-Hosted' ||
              !this.currentMeeting.options.virtualMeetingType)))
      );
    },
    isMeeting() {
      return this.currentMeeting.castProvider === 'zoom-meeting';
    },
    isSelfHosted() {
      return (
        this.currentMeeting.options.subtype &&
        this.currentMeeting.options.subtype === 'Virtual' &&
        this.currentMeeting.options.virtualMeetingType === 'Self-Hosted'
      );
    },
    type() {
      return this.currentMeeting.options.type;
    },
    subtype() {
      return this.currentMeeting.options.subtype;
    },
    virtualMeetingType() {
      return _.get(this.currentMeeting, 'options.virtualMeetingType', null);
    },
    isTypeOfMeeting() {
      const virtualType = this.virtualMeetingType;
      return (
        (this.currentMeeting.options.type === 'meeting' ||
          this.currentMeeting.options.type === 'vbm') &&
        (!this.currentMeeting.options.subtype ||
          (this.currentMeeting.options.subtype &&
            this.currentMeeting.options.subtype === 'Virtual' &&
            (virtualType === 'GQ-Hosted' ||
              !this.currentMeeting.options.virtualMeetingType)))
      );
    },
    hasZoomData() {
      return this.zoomData;
    },
    hasRecordings() {
      return !!this.currentMeeting.castRecordingsAt;
    },
    hasReports() {
      return !!this.currentMeeting.castReportsAt;
    },
    startUrl() {
      return !this.canStartMeeting ? null : this.zoomData.startUrl;
    },
    zoomUrlTooltipText() {
      const {
        meetingTimezone: timezone,
        meetingDate: startTime,
        rehearsalAt: rehearsalTime
      } = this.currentMeeting;

      let text = `${this.timeBeforeMeeting} minutes before the meeting`;

      if (
        moment(rehearsalTime)
          .tz(timezone)
          .isBefore(
            moment(startTime).subtract(this.timeBeforeMeeting, 'minutes')
          )
      ) {
        const availableBeforePreMeeting = moment(rehearsalTime).subtract(
          this.timeBeforePreMeeting,
          'minutes'
        );

        const date = availableBeforePreMeeting.format('dddd, MMMM Do YYYY');
        const time = availableBeforePreMeeting.format('hh:mm a zz');

        text = `on ${date} at ${time}`;
      }
      return !this.canStartMeeting
        ? `'Start as Host' link will be available ${text}`
        : 'Start the webinar and join as the primary host';
    },
    registrationType() {
      switch (this.zoomData.settings.approvalType) {
        case 0:
          return 'Automatically Approved';
        case 1:
          return 'Manual Approval Required';
        default:
          return 'WARNING - No Registration';
      }
    },
    dialInEnabled() {
      const numbers = _.get(this.zoomData, 'settings.globalDialInNumbers', []);
      return numbers.length > 0;
    },
    dialIns() {
      return this.dialInEnabled ? 'Enabled' : 'Disabled';
    },
    dialInCountries() {
      return this.dialInEnabled
        ? this.zoomData.settings.globalDialInCountries.join(', ')
        : '';
    },
    phoneNumbers() {
      return this.dialInEnabled
        ? this.zoomData.settings.globalDialInNumbers
            .map(v => v.number)
            .join(', ')
        : '';
    },
    rehearsalAt() {
      return moment(this.webcast.rehearsalAt).tz(
        this.currentMeeting.meetingTimezone
          ? this.currentMeeting.meetingTimezone
          : 'America/Toronto'
      );
    },
    createdAt() {
      return moment(this.webcast.createdAt)
        .tz(
          this.currentMeeting.meetingTimezone
            ? this.currentMeeting.meetingTimezone
            : 'America/Toronto'
        )
        .format('dddd, MMMM Do YYYY');
    },
    startAt() {
      return moment(this.webcast.startedAt).tz(
        this.currentMeeting.meetingTimezone
          ? this.currentMeeting.meetingTimezone
          : 'America/Toronto'
      );
    },
    startDate() {
      return this.startAt.format('dddd, MMMM Do YYYY');
    },
    startTime() {
      return this.startAt.format('hh:mm a zz');
    },
    endAt() {
      return moment(this.webcast.endedAt).tz(
        this.currentMeeting.meetingTimezone
          ? this.currentMeeting.meetingTimezone
          : 'America/Toronto'
      );
    },
    endDate() {
      return this.startAt.format('dddd, MMMM Do YYYY');
    },
    endTime() {
      return this.endAt.format('hh:mm a zz');
    },
    rehearsalDate() {
      return this.rehearsalAt.format('dddd, MMMM Do YYYY');
    },
    rehearsalTime() {
      return this.rehearsalAt.format('hh:mm a zz');
    },
    rehearsalDuration() {
      return this.webcast.rehearsalDuration;
    },
    timeBeforeMeeting() {
      return process.env.WEBCAST_START_HOST_TIME || 90;
    },
    timeBeforePreMeeting() {
      return process.env.WEBCAST_START_PRE_MEETING_TIME || 30;
    },
    eventLogsData() {
      return this.logs.length ? this.logs.map(item => item.data) : [];
    },
    isInProgress() {
      const { startedAt, endedAt } = this.webcast;

      if (startedAt && !endedAt) {
        return true;
      }

      return false;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings', ['fetchMeeting']),
    ...mapActions('meetings/webcast', [
      'fetchWebcastDetails',
      'fetchWebcastUserSchedulers',
      'fetchWebcastPanelists',
      'fetchWebcastPolls',
      'createWebcast',
      'fetchWebcastEvents',
      'bootWebcast',
      'updateWebcast',
      'resetWebcast'
    ]),
    async init() {
      try {
        this.loading = true;
        if (this.hasWebcast) {
          await this.fetchWebcastDetails({
            shortCode: this.shortCode
          });

          if (this.webcast.reset) {
            // if webcast has been reset
            return this.$events.$emit('toastEvent', 'webcast has expired');
          }

          await this.fetchWebcastEvents(this.shortCode);
          await this.fetchWebcastPolls(this.shortCode);
          await this.fetchWebcastPanelists({ shortCode: this.shortCode });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    // Create a webcast if there isn't one
    async create(isMeeting = false) {
      try {
        // Prevent creation of webcast if it's not a meeting
        if (!this.isTypeOfMeeting) {
          return this.$events.$emit(
            'toastEvent',
            'Cannot create webcast for non-meeting type'
          );
        }
        this.creating = true;

        const payload = { is_meeting: isMeeting };
        await this.createWebcast({ shortCode: this.shortCode, payload });

        this.$events.$emit('toastEvent', 'Webcast created');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.creating = false;
      }
    },
    // Execute the webcast bootup sequence
    async bootup() {
      if (!this.canStartMeeting) {
        return;
      }

      try {
        this.dialog.start = true;
        this.booting = true;

        await this.bootWebcast({ shortCode: this.shortCode });
      } catch (err) {
        this.dialog.start = false;
        if (err.response) {
          if (err.response.data === 'NO_LICENSES_AVAILABLE') {
            this.bootError =
              'There are no licenses available to start the meeting now. Please contact support.';
          } else {
            this.bootError = err.response.data;
          }
        } else if (err.request) {
          this.bootError = 'Service unavailable. Please contact support.';
        } else {
          console.log(err);
          this.bootError = 'There has been an error. Please contact support.';
        }
        this.dialog.bootError = true;
      } finally {
        this.booting = false;
      }
    },
    async copyToClipboard() {
      try {
        if (!this.zoomData) {
          this.$events.$emit('showErrorDialog', 'Zoom data does not exist');
          return;
        }

        const text = `Toll-Free Number: ${this.phoneNumbers}\nWebinarID: ${this.zoomData.id}\nPasscode: ${this.zoomData.password}`;
        await navigator.clipboard.writeText(text);

        this.$events.$emit('toastEvent', 'Information copied');
      } catch ($e) {
        this.$events.$emit('showErrorDialog', 'Cannot copy');
      }
    },
    openStartUrl() {
      return window.open(this.startUrl, '_blank');
    },
    async habndleReset(value) {
      try {
        if (!value) {
          this.dialog.confirmReset = false;
          return;
        }

        await this.resetWebcast({ shortCode: this.shortCode });

        this.$events.$emit('toastEvent', 'Webcast reset');
      } catch ($e) {
        this.$events.$emit('showErrorDialog', 'Cannot reset');
      } finally {
        this.dialog.confirmReset = false;
      }
    }
  }
};
</script>

<style scoped>
.empty-container {
  height: 30vh;
}
.disabled {
  opacity: 0.4;
  cursor: default;
}
.webcast-info {
  background: white;
  margin-top: 0px !important;
  padding: 10px;
}
</style>
