import { render, staticRenderFns } from "./MeetingApprovalsView.vue?vue&type=template&id=644b7dee&scoped=true"
import script from "./MeetingApprovalsView.vue?vue&type=script&lang=js"
export * from "./MeetingApprovalsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644b7dee",
  null
  
)

export default component.exports