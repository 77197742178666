'use strict';

import _ from 'lodash';
import additionalServices from './additional-services';
import serviceDetail2 from './service-details-2';

/**
 * Return an array of valid additional services
 *
 * @param  {Object} meeting  meeting type
 * @return {Array}        valid services
 */
export default function filterServices(meeting) {
  let selectedAdditionalService =
    additionalServices[meeting.typeService] || additionalServices[meeting.type];

  // Remove any nested questions unanswered or not selected
  // get nested questions
  const nestedQuestions = _.filter(selectedAdditionalService, s => {
    if (s.dependsOn) return s;
  });

  // filter nested questions if not selected
  _.forEach(nestedQuestions, q => {
    const question = q.dependsOn.question;
    const value = q.dependsOn.value;
    if (meeting.additionalServices[question] !== value) {
      // answer doesn't match -> no nested question
      selectedAdditionalService = selectedAdditionalService.filter(
        s => s.value !== q.value
      );
    }
  });

  const selectedServiceDetails2 =
    serviceDetail2[meeting.typeService] || serviceDetail2[meeting.type];

  const validAdditionalServices = _.map(
    selectedAdditionalService,
    s => s.value
  );

  const validServiceDetails2 = _.map(selectedServiceDetails2, s => s.value);

  const services = [];

  if (validAdditionalServices && validAdditionalServices.length > 0) {
    services.push(...validAdditionalServices);
  }

  if (validServiceDetails2 && validServiceDetails2.length > 0) {
    services.push(...validServiceDetails2);
  }

  return services;
}
