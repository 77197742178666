<template>
  <v-dialog max-width="400px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title">Mark Unit as Ineligible?</span>
      </v-card-title>
      <v-card-text>
        <p>
          To mark this unit as <strong>ineligible</strong>, select a reason from
          the options below and click <strong>CONFIRM</strong>:
        </p>

        <v-layout d-flex justify-center>
          <v-btn
            color="primary"
            :outline="reason !== 'arrears'"
            @click="selectReason('arrears')"
            >Arrears</v-btn
          >

          <v-btn
            color="primary"
            :outline="reason !== 'in-person'"
            @click="selectReason('in-person')"
            >In-person</v-btn
          >

          <v-btn
            color="primary"
            :outline="reason !== 'custom'"
            @click="selectReason('custom')"
            >Custom Reason</v-btn
          >
        </v-layout>

        <v-layout v-if="reason === 'custom'" justify-center>
          <v-flex xs6>
            <v-text-field
              class="mt-2"
              label="Custom Reason"
              v-model="customReason"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn :disabled="isDisabled" color="primary" @click.native="emitUpdate"
          >confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsMarkIneligibleDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unit: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    isDisabled() {
      return (
        this.reason === '' ||
        (this.reason === 'custom' && this.customReason === '')
      );
    },
    updatedReason() {
      return this.reason === 'custom' ? this.customReason : this.reason;
    }
  },
  data() {
    return {
      reason: '',
      customReason: ''
    };
  },
  methods: {
    selectReason(reason) {
      this.reason = reason;
    },
    emitClose() {
      this.reason = '';
      this.customReason = '';
      this.$emit('close');
    },
    emitUpdate() {
      // copy unit object
      const updatedUnit = _.cloneDeep(this.unit);

      // if unit's customData is null, set to empty object
      // so properties can be added
      if (!updatedUnit.customData) {
        updatedUnit.customData = {};
      }

      // add new `ineligible_reason` property
      updatedUnit.customData.ineligible_reason = this.updatedReason;

      this.$emit('update', {
        ...updatedUnit,
        actionLogTag: 'create',
        targetLogTag: 'unit-tag'
      });
      this.emitClose();
    }
  }
};
</script>

<style scoped></style>
