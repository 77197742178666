<template>
  <v-container class="background" fill-height grid-list-md fluid>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg4>
        <v-card>
          <v-card-title class="gq-blue-background">
            <v-container>
              <v-layout column align-center>
                <v-flex xs12>
                  <img src="../assets/gq_navbar_logo.svg" class="login-image" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout row>
                <v-flex xs12>
                  <h3>Forgot your password?</h3>
                  <p class="secondary--text">
                    Enter your email address below. We'll send you and email
                    with instructions on how to reset your password.
                  </p>
                </v-flex>
              </v-layout>
              <v-form
                action
                v-model="valid"
                @submit.prevent="submitForgotPassword"
              >
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="email"
                      id="email"
                      ref="email"
                      type="email"
                      label="Enter your email address here"
                      clearable
                      prepend-icon="mail"
                      autocomplete="email"
                      :rules="rules.email"
                      v-model="email"
                      required
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-4" align-center justify-center>
                  <v-btn :disabled="!valid" large type="submit" color="primary"
                    >Send Reset Email</v-btn
                  >
                </v-layout>
                <v-layout class="mt-2" align-center justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <router-link :to="{ name: 'login' }" replace>
                      <a class="secondary--text">Return to login page</a>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import validator from 'email-validator';
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPasswordView',
  data() {
    return {
      email: null,
      valid: false,
      rules: {
        email: [
          v => !!v || 'Email is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ]
      }
    };
  },
  methods: {
    ...mapActions(['requestPasswordReset']),
    async submitForgotPassword() {
      try {
        this.requestPasswordReset(this.email);
      } catch (err) {
        console.error('Error requesting password reset', err);
      } finally {
        this.$router.push({
          name: 'login',
          query: {
            alert: 'forgot-password'
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
