<template>
  <v-card>
    <v-card-title>
      <span class="title capitalize">Update Item</span>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex x12>
          <v-textarea
            name="input-description"
            label="Description"
            hint="Description supports Markdown formatting"
            persistent-hint
            id="input-description"
            auto-grow
            box
            v-model="state.description"
          />

          <v-text-field
            class="mt-3"
            type="number"
            name="input-value"
            label="Value"
            id="input-value"
            box
            v-model="state.value"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
      <v-btn
        flat
        color="primary"
        @click.native="handleSaveItem"
        :loading="saving"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'AdminInvoiceViewItemsEdit',
  props: {
    invoiceKey: {
      type: String,
      required: true
    },
    invoiceItem: {
      type: Object,
      default() {
        return {
          key: '',
          type: '',
          category: '',
          description: '',
          value: 0
        };
      }
    }
  },
  data() {
    return {
      saving: false,
      state: {
        key: '',
        type: '',
        category: '',
        description: '',
        value: 0
      }
    };
  },
  mounted() {
    this.resetState();
  },
  watch: {
    invoiceItem() {
      this.initState();
    }
  },
  methods: {
    ...mapActions('invoices', ['editInvoiceItem']),

    async handleSaveItem() {
      try {
        this.saving = true;
        await this.editInvoiceItem({
          itemKey: this.state.key,
          key: this.invoiceKey,
          payload: {
            type: this.state.type,
            category: this.state.category,
            description: this.state.description,
            value: this.state.value
          }
        });
        this.$events.$emit('toastEvent', 'Item Updated');
        this.handleClose();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    initState() {
      this.state = {
        key: this.invoiceItem.key,
        category: this.invoiceItem.category,
        description: this.invoiceItem.description,
        value: this.invoiceItem.value,
        type: this.invoiceItem.type
      };
    },
    resetState() {
      this.state = {
        key: '',
        type: '',
        category: '',
        description: '',
        value: 0
      };
    }
  }
};
</script>

<style scoped></style>
