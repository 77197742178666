<template>
  <v-layout column>
    <v-toolbar dense color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />
      <span class="mr-2">Filter by:</span>
      <v-btn-toggle v-model="toggle" class="elevation-1">
        <v-btn flat color="primary" value="notice">
          <span class="caption">Notices</span>
        </v-btn>
        <v-btn flat color="primary" value="internal">
          <span class="caption">Conversations</span>
        </v-btn>
      </v-btn-toggle>
      <v-btn class="primary" dark @click.native="emitOpenAddDialog">
        <v-icon left>add</v-icon>New
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="config.pagination"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr @click="rowClicked(props.item.key)">
          <td class="text-xs-left">
            {{ props.item.name }}
            <v-chip
              class="white--text"
              color="accent"
              label
              small
              outline
              disabled
              v-if="props.item.meta.urgent"
              >urgent</v-chip
            >
          </td>
          <td class="text-xs-left">{{ props.item.subject }}</td>
          <td class="text-xs-left">{{ props.item.meta.templateType }}</td>
          <td class="text-xs-left">{{ props.item.segment }}</td>
          <td class="text-xs-left">{{ props.item.category }}</td>
          <td class="text-xs-left">{{ props.item.updatedAt | dateFromNow }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import * as filters from '@/filters';

export default {
  name: 'AdminNoticeTemplatesTable',
  filters: {
    ...filters
  },
  props: {
    state: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      toggle: null,
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Subject', value: 'subject', align: 'left' },
        { text: 'Type', value: 'templateType', align: 'left' },
        { text: 'Segment', value: 'segment', align: 'left' },
        { text: 'Category', value: 'category', align: 'left' },
        { text: 'Updated', value: 'updatedAt', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [20, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'updatedAt',
          descending: true
        }
      }
    };
  },
  computed: {
    items() {
      if (!this.toggle) {
        return this.state;
      }
      return this.state.filter(template => template.category === this.toggle);
    }
  },
  methods: {
    emitOpenAddDialog() {
      this.$emit('new-button-clicked');
    },
    rowClicked(key) {
      this.$emit('row-clicked', key);
    }
  }
};
</script>

<style scoped></style>
