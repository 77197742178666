'use strict';
import axios from 'axios';
import { clearAuth } from '@/auth';
import * as authApi from './auth';

// The Vuex Store
import store from '@/vuex/store';

/**
 * Axios interceptor function to handle silent refresh. Used with axios interceptor
 * to do a silent refresh when an `accessToken` in cookie expires. Uses the `refreshtoken`
 * in cooke to obtain a new `accessToken`. If the `resfreshToken` is expired, then the
 * session has expired.
 *
 * @param  {Object} error      - axios error object
 * @return {Promise}
 */
export default function(error) {
  // The axios request that failed. We're storing this so we can retry
  // the request after we obtain a new access token.
  const origRequest = error.config;

  // Check if the request that failed was the one calling the refresh token
  // route. In essence, we're check if the refresh token is expired.
  // If so, we're redirecting to the login page.
  if (error.response.status === 401 && authApi.isRefreshUrl(origRequest.url)) {
    _redirectToLogin(error);
  }

  // Otherwise, check if the request failed due to expired accessToken.
  // We want to make sure that it's not an infinite loop of retries
  if (error.response.status === 401 && !origRequest._retried) {
    // This is set to prevent infinite loop retries if the request fails again
    origRequest._retried = true;

    // Refresh token, if successful retry the original request
    // Need to encapsulate in promise so that other API requests don't run
    // until this one is finished.
    return new Promise((resolve, reject) => {
      authApi
        .postOAuthRefreshToken()
        // Refresh the latest user profile
        .then(res => store.dispatch('getUserProfile'))
        // Try original request again
        .then(res => resolve(axios(origRequest)))
        // If not successful, means refresh token is invalid. Redirect to login
        .catch(err => _redirectToLogin(error));
    });
  }

  // Otherwise something went wrong
  return Promise.reject(error);
}

function _redirectToLogin(error) {
  clearAuth();
  const location = window.location.href;
  // the url is percent encoded 
  window.location.href = '/?alert=session-expired' + '&redirect=' + location;
  return Promise.reject(error);
}
