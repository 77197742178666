<template>
  <v-card flat>
    <v-card-text>
      <!-- Polls -->
      <h4>Polls</h4>
      <v-toolbar class="mt-2">
        <v-spacer />
        <v-btn @click="openMotionsDialog" color="primary">
          <v-icon left>gavel</v-icon>
          Add Motions
        </v-btn>

        <v-btn @click="openNewPollDialog" color="primary">
          <v-icon left>add</v-icon>
          Add Poll
        </v-btn>
      </v-toolbar>
      <meeting-webcast-view-polls-table
        :polls="polls"
        :loading="savingPolls"
        @remove="removePoll"
        @edit="openPollDialog"
        @close="closePollDialog"
        @bulk-remove="bulkRemovePolls"
      />

      <add-motions-dialog
        :is-open="dialog.motions"
        :motions="defaultMotions"
        :motion-type="motionType"
        :loading="savingPolls"
        @save="addMotions"
        @close="closeMotionsDialog"
      />

      <edit-poll-dialog
        :key="poll.id"
        :is-open="dialog.poll"
        :poll="poll"
        :loading="savingPolls"
        @save="savePoll"
        @close="closePollDialog"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import MeetingWebcastViewPollsTable from './MeetingWebcastViewPollsTable';
import AddMotionsDialog from './AddMotionsDialog';
import EditPollDialog from './EditPollDialog';

export default {
  name: 'MeetingWebcastViewPolls',
  components: {
    MeetingWebcastViewPollsTable,
    AddMotionsDialog,
    EditPollDialog
  },
  props: {
    webcast: {
      type: Object,
      required: true
    },
    polls: {
      type: Array,
      default() {
        return [];
      }
    },
    shortCode: {
      type: String,
      required: true
    },
    defaultMotions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters('meetings/webcast', ['motionType'])
  },
  watch: {
    motionType: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.fetchDefaultMotions({
            shortCode: this.shortCode
          });
        }
      }
    }
  },
  data() {
    return {
      savingPolls: false,
      poll: {
        id: '',
        title: '',
        type: 'new',
        questions: [
          {
            name: 'Do you approve of the motion to...',
            type: 'single',
            answers: ['Yes', 'No']
          }
        ]
      },
      dialog: {
        poll: false,
        motions: false
      }
    };
  },
  created() {
    this.fetchWebcastPolls(this.shortCode);
    this.resetPoll();
  },
  methods: {
    ...mapActions('meetings/webcast', [
      'fetchWebcastPolls',
      'addWebcastPoll',
      'updateWebcastPoll',
      'removeWebcastPoll',
      'bulkAddWebcastPolls',
      'bulkRemoveWebcastPolls',
      'fetchDefaultMotions'
    ]),
    openMotionsDialog() {
      this.dialog.motions = true;
    },
    async addMotions(motions) {
      try {
        this.savingPolls = true;
        await this.bulkAddWebcastPolls({
          shortCode: this.shortCode,
          polls: motions
        });
        await this.fetchWebcastPolls(this.shortCode);
        this.$events.$emit('toastEvent', 'Motions Added');
        this.dialog.motions = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPolls = false;
      }
    },
    closeMotionsDialog() {
      this.dialog.motions = false;
    },
    resetPoll() {
      this.poll = {
        type: 'new',
        id: '',
        title: '',
        questions: [
          {
            name: 'Do you approve the motion to...',
            type: 'single',
            answers: ['Yes', 'No']
          }
        ]
      };
    },
    openPollDialog(poll) {
      this.poll = poll;
      this.dialog.poll = true;
    },
    openNewPollDialog() {
      this.resetPoll();
      this.openPollDialog({ ...this.poll });
    },
    closePollDialog() {
      this.dialog.poll = false;
      this.resetPoll();
    },
    async getDefaulMotions() {},
    async savePoll({ id, type, title, questions }) {
      try {
        this.savingPolls = true;

        if (type === 'new') {
          await this.addWebcastPoll({
            shortCode: this.shortCode,
            poll: { id, title, questions }
          });
        } else {
          await this.updateWebcastPoll({
            shortCode: this.shortCode,
            pollId: id,
            poll: { id, title, questions }
          });
        }
        await this.fetchWebcastPolls(this.shortCode);
        this.closePollDialog();
        this.$events.$emit('toastEvent', 'Poll Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPolls = false;
      }
    },
    async removePoll({ id, title }) {
      const isConfirm = confirm(
        `Are you sure you want to remove the ${title} poll?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.savingPolls = true;
        await this.removeWebcastPoll({
          shortCode: this.shortCode,
          pollId: id
        });
        await this.fetchWebcastPolls(this.shortCode);
        this.$events.$emit('toastEvent', 'Poll Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPolls = false;
      }
    },
    async bulkRemovePolls(polls) {
      const isConfirm = confirm(
        `Are you sure you want to remove these ${polls.length} polls?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.savingPolls = true;
        await this.bulkRemoveWebcastPolls({
          shortCode: this.shortCode,
          polls
        });
        await this.fetchWebcastPolls(this.shortCode);
        this.$events.$emit('toastEvent', 'Polls Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPolls = false;
      }
    }
  }
};
</script>

<style scoped></style>
