<template>
  <v-dialog max-width="500px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title"
          >Update ({{ selected.length }}) unit share values to:</span
        >
      </v-card-title>
      <v-card-text>
        <v-layout justify-center>
          <v-text-field
            class="mt-2"
            type="number"
            label="Number of shares"
            v-model="shares"
          ></v-text-field>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn
          :disabled="!shares || shares < 0"
          color="primary"
          @click.native="emitUpdate"
          >confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingUnitsBulkIneligibleDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      shares: null
    };
  },
  methods: {
    emitClose() {
      this.shares = '';
      this.$emit('close');
    },
    emitUpdate() {
      this.$emit('update', this.shares);
      this.emitClose();
    }
  }
};
</script>

<style scoped></style>
