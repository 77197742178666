<template>
  <v-dialog
    :value="value"
    persistent
    @input="emitInput"
    content-class="multi-conversations-dialog"
    max-width="1250px"
  >
    <v-layout>
      <v-layout v-if="!state.success" column>
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 class="headline">
                Create Multi-Conversations
              </h3>
              <v-spacer />
              <div>
                Please review the recipients and scheduled dates for the
                conversations before scheduling
              </div>
            </div>
            <v-spacer />
            <div>
              <v-btn icon flat @click.stop="emitClose"
                ><v-icon>close</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text class=" pb-0">
            <v-flex xs6>
              <v-btn
                color="primary"
                class="checklist-button-conversations"
                @click="toggleChecklist()"
              >
                {{ !showChecklists ? 'Show' : 'Hide' }} Checklists
                <v-icon v-if="!showChecklists" small>
                  keyboard_arrow_down
                </v-icon>
                <v-icon v-else small>
                  keyboard_arrow_up
                </v-icon>
              </v-btn>
            </v-flex>
            <v-layout grid-list-md>
              <v-flex xs6 md6 lg6>
                <v-text-field label="From name" v-model="fromName" />
              </v-flex>
              <v-flex xs6 md6 lg6>
                <v-combobox
                  v-model="toEmails"
                  :items="emailDefaults"
                  label="to:"
                  multiple
                  clearable
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      small
                      close
                      :selected="data.selected"
                      @input="removeTo(data.item)"
                      >{{ data.item }}</v-chip
                    >
                  </template>
                </v-combobox>
              </v-flex>
            </v-layout>
            <v-layout grid-list-md>
              <v-flex xs6 md6 lg6>
                <v-combobox
                  v-model="ccEmails"
                  :items="emailDefaults"
                  label="cc:"
                  multiple
                  small-chips
                  clearable
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      small
                      close
                      :selected="data.selected"
                      @input="removeCC(data.item)"
                      >{{ data.item }}</v-chip
                    >
                  </template>
                </v-combobox>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text class="mt-0 pt-0">
            <div class="text-xs-left">
              <v-layout row>
                <v-flex xs12>
                  <span
                    >bcc: copy will be sent to the respective template sender
                    (support@getquorum.com)</span
                  >
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>
          <v-card-text class="mt-0 pt-0">
            <div class="text-xs-center">
              <v-layout row v-if="state.error">
                <v-flex xs12 class="ma-2">
                  <v-icon color="red">warning</v-icon>
                  {{ this.state.errorMsg }}
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>
          <v-card-text class="mt-0 pt-0">
            <v-list class="ml-2">
              <v-list-tile
                v-for="(reminder, index) in this.state.reminders"
                :key="index"
              >
                <v-flex xs5>
                  <v-icon small class="v-icon mr-3 material-icons theme--light"
                    >email</v-icon
                  >{{ reminder.name }}
                </v-flex>
                <v-flex xs6>
                  <date-time-pickers
                    label="What is the planned send date?"
                    :iso-date="reminder.sendOn"
                    :show-clear-btn="true"
                    :autoclose="true"
                    time-menu-direction="top"
                    :timezone="meetingTimezone"
                    @update="updateReminderDate(index, $event)"
                  />
                </v-flex>
                <v-flex xs1>
                  <v-list-tile-action>
                    <v-spacer />
                    <v-btn
                      v-if="!reminder.sent"
                      flat
                      icon
                      @click.native="removeConversation(index)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-flex>
              </v-list-tile>
            </v-list>
          </v-card-text>
          <v-toolbar light class="grey lighten-4 elevation-1">
            <template class="buttons-slot">
              <div>
                <v-menu offset-y>
                  <v-btn slot="activator" light flat color="blue">
                    <v-icon>add</v-icon>Conversation
                  </v-btn>
                  <v-card style="max-height: 250px; overflow-y: auto;">
                    <v-list>
                      <v-list-tile
                        v-for="item in currentUniqueConversationTemplateList"
                        @click="addTemplate(item.noticeTemplateKey)"
                        :key="item.conversationTemplateGroupMembershipKey"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-menu offset-y>
                  <v-btn slot="activator" light flat color="blue">
                    <v-icon>add</v-icon> Group
                  </v-btn>
                  <v-card style="max-height: 250px; overflow-y: auto;">
                    <v-list>
                      <v-list-tile
                        v-for="item in currentConversationGroupList"
                        @click="
                          updateConversationGroup(
                            item.conversationTemplateGroupKey
                          )
                        "
                        :key="item.conversationTemplateGroupKey"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </template>
            <v-spacer />
            <template class="buttons-slot">
              <v-btn
                class="primary"
                @click.native="scheduleConversations"
                :disabled="isSchedulerDisabled"
              >
                <v-icon left>send</v-icon>Schedule Reminders
              </v-btn>
            </template>
          </v-toolbar>
        </v-card>
      </v-layout>
      <v-layout
        v-if="!state.success"
        v-show="showChecklists"
        column
        class="checklist-table"
      >
        <v-card>
          <v-card-title primary-title>
            <h3 class="headline mb-0">Checklists</h3>
          </v-card-title>
          <checklist-table
            :meeting-checklist-data="meetingChecklistData"
            :show-assignee="false"
          />
        </v-card>
      </v-layout>
      <v-layout v-if="state.success" column>
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                Conversations have been scheduled! ({{
                  state.scheduledConversations.length
                }}
                of {{ state.reminders.length }})
              </h3>
            </div>
            <v-spacer />
            <template class="buttons-slot">
              <v-btn class="primary" @click="emitClose"
                ><v-icon left>check</v-icon>All Done
              </v-btn>
            </template>
          </v-card-title>
        </v-card>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import * as filters from '@/filters';
import { mapActions, mapGetters } from 'vuex';
import DateTimePickers from '@/components/DateTimePicker';
import defaults from '@/lib/default-emails.js';
import ChecklistTable from '@/components/ChecklistTable';

export default {
  name: 'MeetingConversationAutoSchedulerDialog',
  components: {
    DateTimePickers,
    ChecklistTable
  },
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      required: true
    },
    conversationGroupList: {
      type: Array,
      default() {
        return [];
      }
    },
    conversationTemplateList: {
      type: Array,
      default() {
        return [];
      }
    },
    conversationTemplateGroupKey: {
      type: String,
      default: null
    },
    meetingTimezone: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      emailDefaults: defaults,
      toEmails: [],
      ccEmails: [],
      recipients: [],
      shortCode: this.$route.params.shortcode,
      state: {
        reminders: [],
        conversationTemplateList: [],
        success: false,
        error: false,
        errorMsg: '',
        scheduledConversations: []
      },
      fromName: '',
      fromEmail: '',
      showChecklists: true
    };
  },
  computed: {
    ...mapGetters('meetings/checklists', ['meetingChecklistData']),
    currentConversationGroupList() {
      const sortedList = _.sortBy(this.conversationGroupList, 'name');
      return sortedList;
    },
    currentConversationTemplateList() {
      return this.conversationTemplateList;
    },
    currentUniqueConversationTemplateList() {
      const sortedList = _.sortBy(this.currentConversationTemplateList, 'name');
      return Object.values(_.keyBy(sortedList, 'noticeTemplateKey'));
    },
    isSchedulerDisabled() {
      if (this.state.reminders.length === 0) {
        return true;
      }
      let dateMissing = false;
      this.state.reminders.map(reminderTemplate => {
        if (!reminderTemplate.sendOn) {
          dateMissing = true;
          return;
        }
      });
      return dateMissing;
    }
  },
  watch: {
    currentConversationTemplateList: function() {
      this.init();
    }
  },
  created() {
    this.init();
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    this.emailDefaults.unshift(this.currentMeeting.contact.email);
    this.emailDefaults.unshift(profile.email);

    let firstName = _.get(profile, 'firstName', '');
    let lastName = _.get(profile, 'lastName', '');
    if (!this.fromEmail) {
      this.fromName = `${firstName} ${lastName}, GetQuorum`;
    }
    if (!this.fromEmail) {
      this.fromEmail = profile.email;
    }
    this.toEmails = [this.currentMeeting.contact.email];
  },
  methods: {
    ...mapActions('meetings/conversations', [
      'scheduleMeetingConversationList'
    ]),
    init() {
      this.state.success = false;

      this.state.conversationTemplateList = _.filter(
        this.currentConversationTemplateList
      );
      this.resetErrors();
    },
    emitClose() {
      this.state.success = false;
      this.state.reminders = [];
      this.recipients = [];
      this.showChecklists = true;
      this.$emit('close');
    },
    emitInput() {
      this.$emit('input');
    },
    removeConversation(index) {
      this.state.reminders.splice(index, 1);
    },
    removeTo(item) {
      this.toEmails.splice(this.toEmails.indexOf(item), 1);
      this.toEmails = [...this.toEmails];
    },
    removeCC(item) {
      this.ccEmails.splice(this.ccEmails.indexOf(item), 1);
      this.ccEmails = [...this.ccEmails];
    },
    processEmails(type, array) {
      array.forEach(email => {
        this.recipients.push({
          email: email.trim(),
          type
        });
      });
    },
    updateReminders(updateList) {
      this.state.reminders = updateList;
    },
    updateReminderDate(index, value) {
      this.state.reminders[index].sendOn = value;
    },
    updateConversationGroup(conversationTemplateGroupKey) {
      let targetTemplateList = _.filter(
        this.state.conversationTemplateList,
        reminderTemplate =>
          _.includes(reminderTemplate, conversationTemplateGroupKey)
      );

      targetTemplateList = _.map(targetTemplateList, function(reminder) {
        reminder.sendOn = null;
        return _.extend({}, reminder, { uuid: _.uniqueId() });
      });

      this.updateReminders(targetTemplateList);
      this.resetErrors();
    },
    addTemplate(noticeTemplateKey) {
      let newTemplate = _.find(this.state.conversationTemplateList, {
        noticeTemplateKey: noticeTemplateKey
      });
      newTemplate.sendOn = null;
      this.state.reminders.push(
        _.extend({}, newTemplate, { uuid: _.uniqueId() })
      );
      this.resetErrors();
    },
    resetErrors() {
      this.state.error = false;
      this.state.errorMsg = '';
    },
    toggleChecklist() {
      this.showChecklists = !this.showChecklists;
    },
    replaceSubjectMergeFields(subject) {
      let mergeFieldsArray = [
        {
          name: '[CORP_NAME]',
          value: _.get(this.currentMeeting, 'corporation.legalNameShort', '')
        },
        {
          name: '[CONTACT_PREFERRED_NAME]',
          value: _.get(this.currentMeeting, 'contact.preferredName', '')
        },
        {
          name: '[CONTACT_NAME]',
          value: _.get(this.currentMeeting, 'contact.name', '')
        },
        {
          name: '[MTG_DATE]',
          value: this.formatDate(_.get(this.currentMeeting, 'meetingDate', ''))
        }
      ];

      mergeFieldsArray.forEach(function(field) {
        subject = subject.replace(field.name, field.value);
      });
      return subject;
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('MMMM Do, YYYY');
    },
    async scheduleConversations() {
      this.resetErrors();
      const nowDate = moment();
      this.state.reminders.forEach(reminder => {
        const conversationDate = moment(reminder.sendOn);
        if (conversationDate.isBefore(nowDate)) {
          this.state.error = true;
          this.state.errorMsg = 'Date chosen is in the past';
          return;
        }
        reminder.options = {
          replyTo: 'support@getquorum.com'
        };
        reminder.subject = this.replaceSubjectMergeFields(reminder.subject);
        reminder.attachments = {};
      });
      if (this.state.error) {
        return;
      }

      this.processEmails('to', this.toEmails);
      this.processEmails('cc', this.ccEmails);

      await this.scheduleMeetingConversationList({
        conversations: this.state.reminders,
        status: 'scheduled',
        meeting: {
          id: this.currentMeeting.id,
          key: this.currentMeeting.key,
          shortCode: this.currentMeeting.shortCode,
          contact: this.currentMeeting.contact,
          options: this.currentMeeting.options,
          addresses: this.currentMeeting.addresses
        },
        recipients: this.recipients,
        fromName: this.fromName,
        fromEmail: this.fromEmail
      })
        .then(res => {
          this.state.scheduledConversations = _.filter(res.data, {
            success: true
          });
          this.state.success = true;
        })
        .catch(error => {
          this.state.error = true;
          this.state.errorMsg = error.response.data;
          this.state.success = false;
        });
      this.recipients = [];
    }
  }
};
</script>

<style>
.multi-conversations-dialog {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style scoped>
.checklist-button-conversations {
  font-size: 9px;
  position: absolute !important;
  padding: 0 5px;
  height: 20px;
  right: -55px;
  transform: rotate(-90deg);
  z-index: 1;
}
.checklist-table {
  width: 45%;
  background: white;
}
</style>
