<template>
  <v-dialog :value="isOpen" @input="handleClose" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="title capitalize">Add Payment</span>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 class="ma-2">
            <v-select
              :items="itemOptions"
              v-model="e1"
              label="Quick Select"
              item-text="label"
              item-value="key"
              persistent-hint
              @input="onQuickSelect"
            />
          </v-flex>
        </v-layout>

        <v-layout row nowrap v-show="e1">
          <v-flex xs12>
            <v-layout row nowrap>
              <v-flex xs9 class="ma-2">
                <v-text-field
                  name="input-description"
                  label="Description"
                  id="input-description"
                  v-model="state.description"
                />
              </v-flex>

              <v-flex xs3 class="ma-2">
                <v-text-field
                  type="number"
                  name="input-value"
                  label="Amount Paid"
                  id="input-value"
                  min="0"
                  v-model="state.value"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout row v-if="error">
          <v-flex xs12 class="ma-2">
            <v-icon>warning</v-icon> Error adding payment, please fix and try
            again.
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-btn flat color="primary" @click.native="handleAddItem"
          >Add Payment</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AdminInvoiceViewPaymentsAddDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    invoiceKey: {
      type: String,
      required: true
    },
    itemOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    amountOwing: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      e1: null,
      error: null,
      state: {
        category: '',
        description: '',
        value: 0
      }
    };
  },
  computed: {},
  mounted() {},
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.resetState();
      }
    }
  },
  methods: {
    ...mapActions('invoices', ['addItemToInvoice']),
    handleClose() {
      this.$emit('close');
      this.resetState();
    },
    async handleAddItem() {
      try {
        this.error = false;
        const payload = { ...this.state };
        await this.addItemToInvoice({
          key: this.invoiceKey,
          type: 'payment',
          payload
        });
        // If invoice is fully paid off, prompt user
        // to send the 'Payment Received' email confirmation
        if (this.amountOwing - this.state.value === 0) {
          this.handleClose();
          this.$emit('paymentEmail');
        } else {
          this.handleClose();
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.error = true;
        throw err;
      }
    },
    resetState() {
      const defaultItem = this.getDefaultItem();
      this.state = { ...defaultItem };
      this.e1 = defaultItem.key;
    },
    onQuickSelect(e) {
      const item = this.itemOptions.filter(k => k.key === e)[0];
      this.state = {
        category: item.category,
        value: item.value,
        description: item.description,
        quickSelect: e
      };
    },
    /**
     * Find the default payment option and always default to full amount owing
     * @return  {Object}  the default object will full amount owing
     */
    getDefaultItem() {
      const defaultItem = this.itemOptions.filter(i => i.default);
      return defaultItem.length > 0
        ? { ...defaultItem[0], value: this.amountOwing }
        : { category: '', description: '', value: 0 };
    }
  }
};
</script>

<style scoped></style>
