'use strict';

import _ from 'lodash';
import iso3166 from 'iso-3166-2';
import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

/**
 * Gets the ISO 3166 alpha-2 country code based on the
 * supplied country name.
 * @param  {String}       - country name
 * @return {Array}        - array of valid country names
 */
export function alpha2CountryCode(countryName) {
  try {
    return countries.getAlpha2Code(countryName, 'en');
  } catch (err) {
    console.error('Error getting country code', err);
    throw err;
  }
}

/**
 * Gets a list of valid country names, for use in
 * creating/updating accounts.
 *
 * @return {Array}        - array of valid country names
 */
export function countryNames() {
  try {
    // List of acceptable country names
    const countryNameAndCodes = countries.getNames('en', {
      select: 'official'
    });

    return Object.values(countryNameAndCodes);
  } catch (err) {
    console.error('Error getting country names', err);
    throw err;
  }
}

/**
 * Gets a list of valid province/state names, based
 * on the country code supplied.
 * @param  {String}       - country code
 * @return {Array}        - array of valid province/state names
 */
export function provinceNames(countryCode) {
  try {
    if (!countryCode) {
      return [];
    }

    // List of acceptable prov/state names, based on country supplied
    const provs = iso3166.country(countryCode);

    let provArray = [];

    // Create a sanitized array of region names/codes
    for (const key of Object.keys(provs.sub)) {
      const obj = iso3166.subdivision(countryCode, provs.sub[key].name);
      provArray.push({ regionName: obj.name, regionCode: obj.regionCode });
    }

    return _.sortBy(provArray, 'regionName');
  } catch (err) {
    console.error('Error getting province/state names', err);
    throw err;
  }
}
