<template>
  <v-card height="100%" class="flexcard">
    <v-card-title primary-title>
      <span class="title">
        <slot name="title">{{ report.title }}</slot>
      </span>
      <v-chip
        v-if="isAdmin"
        color="blue lighten-2"
        class="white--text"
        label
        small
        disabled
      >
        Admin
      </v-chip>
      <v-chip
        v-if="isClient"
        color="green lighten-2"
        class="white--text"
        label
        small
        disabled
      >
        Client
      </v-chip>
      <v-chip
        v-if="isBeta"
        color="orange lighten-2"
        class="white--text"
        label
        small
        disabled
      >
        Beta
      </v-chip>
    </v-card-title>
    <v-card-text class="grow">
      <slot name="description">{{ report.description }}</slot>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn flat :color="buttonColor" @click.native="clickDownloadReport"
        >{{ action }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ReportCard',
  props: {
    report: {
      type: Object,
      default() {
        return {
          reportType: '',
          title: '',
          description: '',
          adminOnly: false,
          background: false
        };
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    reportType() {
      return this.report.reportType;
    },
    action() {
      return this.report.background ? 'Email it to me' : 'Download';
    },
    buttonColor() {
      return 'primary';
    },
    isAdmin() {
      return this.report?.adminOnly;
    },
    isClient() {
      return this.report?.client;
    },
    isBeta() {
      return this.report?.beta;
    }
  },
  methods: {
    clickDownloadReport() {
      const action = this.report.background ? 'export' : 'download';
      return this.$emit('handleClick', {
        action,
        reportType: this.reportType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
