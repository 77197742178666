<template>
  <section>
    <v-text-field
      label="Template name"
      :value="name"
      :disabled="isDisabled"
      :rules="[(v) => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('name', $event)"
    />
    <v-text-field
      label="Subject line of email"
      :value="subject"
      :disabled="isDisabled"
      :rules="[(v) => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('subject', $event)"
    />
  </section>
</template>

<script>
export default {
  name: 'InputNoticeInfo',
  props: {
    name: { type: String, default: '' },
    subject: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped>
</style>
