<template>
  <div>
    <v-text-field
      label="Purpose"
      :value="purpose"
      :disabled="isDisabled"
      :rules="[(v) => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('purpose', $event)"
    />
    <v-text-field
      label="Subject"
      :value="subject"
      :disabled="isDisabled"
      :rules="[(v) => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('subject', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'InputInfo',
  props: {
    purpose: { type: String, default: '' },
    subject: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped>
</style>
