<template>
  <v-dialog :value="value" @input="emitInput" max-width="350px">
    <v-card v-if="notificationStatus === 'sending'">
      <v-card-title primary-title>
        <div class="title">Sending</div>
      </v-card-title>
      <v-card-text>
        <v-progress-circular indeterminate :size="50" color="primary" />
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-list>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon large color="green">check_circle</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Notification Sent!</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-tooltip bottom>
              <span slot="activator">
                <v-btn flat icon @click.native="emitClose">
                  <v-icon>keyboard_return</v-icon>
                </v-btn>
              </span>
              <span>Return to recordings</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingFilesViewSend',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    notificationStatus: {
      type: String,
      default: ''
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
