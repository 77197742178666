<template>
  <v-card hover>
    <v-card-text>
      <v-layout align-center row>
        <div class="icon-container status-icon-container">
          <v-icon :color="statusColor">{{ iconType }}</v-icon>
        </div>
        <v-flex xs8 sm8 md3 lg3 xl6>
          <a @click="shortcodeClick">{{ state.shortCode.toUpperCase() }}</a>
          <div @click="handleClick">
            {{ state.purpose }}
            <v-chip small :color="statusColor" class="white--text ml-2">{{
              state.status
            }}</v-chip>
          </div>
          <div class="subject-text" @click="handleClick">
            {{ state.subject }}
          </div>
        </v-flex>
        <v-spacer />
        <template v-if="!this.$vuetify.breakpoint.smAndDown">
          <v-flex class="stats-container" md3 lg3 xl2>
            <notice-card-stats
              v-if="state.status === 'sent'"
              :stats="state.stats"
            />
          </v-flex>
          <v-flex class="schedule-item" md3 lg3 xl2>
            <div>
              <span class="caption subject-text">Scheduled time:</span>
              <div>{{ sendOnDate }}</div>
              <div>{{ sendOnTime }}</div>
            </div>
          </v-flex>
          <v-flex class="schedule-item" md3 lg3 xl2>
            <span class="caption subject-text">Sent at:</span>
            <div>{{ sentAtDate }}</div>
            <div>{{ sentAtTime }}</div>
          </v-flex>
        </template>
        <template v-else>
          <v-icon @click="show = !show">info</v-icon>
        </template>
        <div class="icon-container">
          <v-btn flat icon @click.native="handleClick">
            <v-icon>keyboard_arrow_right</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-card-text>
    <!-- Info Dialog on smaller screens -->
    <v-row justify="center">
      <v-dialog v-model="show" width="350px">
        <v-card>
          <v-card-text>
            <v-layout row align-center>
              <v-flex>
                <div class="py-2">
                  <span class="caption subject-text">Scheduled time:</span>
                  <div>{{ sendOnDate }}</div>
                  <div>{{ sendOnTime }}</div>
                </div>
                <v-divider />
                <div class="py-2">
                  <span class="caption subject-text">Sent at:</span>
                  <div>{{ sentAtDate }}</div>
                  <div>{{ sentAtTime }}</div>
                </div>
              </v-flex>
              <v-divider vertical />
              <div v-if="state.status === 'sent'">
                <notice-card-stats :stats="state.stats" />
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import NoticeCardStats from '@/components/NoticeCardStats';
export default {
  name: 'AdminNoticeCard',
  components: {
    NoticeCardStats
  },
  props: {
    state: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    statusColor() {
      switch (this.state.status) {
        case 'sent':
          return 'blue';
        case 'scheduled':
          return 'deep-orange';
        default:
          return 'grey';
      }
    },
    iconType() {
      switch (this.state.status) {
        case 'sent':
          return 'email';
        case 'scheduled':
          return 'access_time';
        default:
          return 'drafts';
      }
    },
    isSent() {
      return this.state.status === 'sent';
    },
    isScheduled() {
      return this.state.status === 'scheduled';
    },
    sendOnDate() {
      if (this.state.sendOn) {
        return moment(this.state.sendOn).format('MMM DD, YYYY');
      }
      return 'Unscheduled';
    },
    sendOnTime() {
      if (this.state.sendOn) {
        return moment(this.state.sendOn)
          .tz('America/Toronto')
          .format('h:mm a zz');
      }
    },
    sentAtDate() {
      if (this.state.sentAt) {
        return moment(this.state.sentAt).format('MMM DD, YYYY');
      }
      return 'Not Sent';
    },
    sentAtTime() {
      if (this.state.sentAt) {
        return moment(this.state.sentAt)
          .tz('America/Toronto')
          .format('h:mm a zz');
      }
    }
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: 'meetingEditNotices',
        params: {
          shortcode: this.state.shortCode,
          noticeKey: this.state.key
        }
      });
    },
    shortcodeClick() {
      this.$router.push({
        name: 'meetingOverview',
        params: {
          shortcode: this.state.shortCode
        }
      });
    }
  }
};
</script>

<style scoped>
.chip--small {
  height: 17px;
  font-weight: 600;
  opacity: 0.8;
}
.subject-text {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #757575;
  min-width: 150px;
}
.icon-container {
  padding-left: 20px;
  padding-right: 40px;
}

.stats-container {
  min-width: 160px;
}
.schedule-item {
  border-left: 1px solid #e0e0e0;
}
@media screen and (max-width: 900px) {
  .status-icon-container {
    display: none;
  }
}
</style>
