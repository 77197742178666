<template>
  <div>
    <!-- File upload area, no package exists -->
    <div v-if="(!packageExists || multipleFiles) && !disabled">
      <div class="dz-area" v-if="processing">
        <div class="dz-message">
          <span>File Uploading</span>
          <v-progress-circular indeterminate :size="50" color="primary" />
        </div>
      </div>
      <div
        class="dz-area"
        v-if="(!packageExists && !processing) || (multipleFiles && !processing)"
      >
        <div class="dz-message">
          <v-icon color="grey" large>backup</v-icon>
          <span class="caption error--text" v-if="!packageExists"
            >No File Uploaded</span
          >
          <span>Click or Drag and Drop files here to upload</span>
        </div>
        <div class="input-area">
          <input type="file" :accept="accept" @change="onFileChange" />
        </div>
      </div>
    </div>

    <!-- Package exists -->
    <!-- Accepts array of files -->
    <div v-if="multipleFiles">
      <transition name="slide-fade">
        <v-list two-line v-if="packageExists">
          <v-list-tile
            class="pointer"
            v-for="(file, index) in attachment"
            :key="file.Key"
            avatar
          >
            <v-list-tile-avatar>
              <v-icon large color="blue">{{ icon }}</v-icon>
            </v-list-tile-avatar>
            <file-upload-item
              :icon="icon"
              :attachment="file"
              @download="downloadFile"
            />
            <v-list-tile-action>
              <v-btn
                :disabled="disabled"
                flat
                color="accent"
                @click.native="removeFileFromArray(attachment, index)"
                >delete</v-btn
              >
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { getPutSignedUrl, putSignedUrl } from '@/api/meetings-files';
import FileUploadItem from '@/components/FileUploadItem';

export default {
  name: 'PrintJobFileUploadForm',
  components: {
    FileUploadItem
  },
  props: {
    shortCode: {
      type: String,
      required: true
    },
    attachment: {
      type: [Object, Array],
      default() {
        return {};
      }
    },
    icon: {
      type: String,
      default: 'insert_drive_file'
    },
    multipleFiles: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default() {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    uploadFileType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      processing: false,
      fileFormatsAllowed: {
        printing: ['pdf', 'doc', 'docx'],
        recipients: ['csv', 'xls', 'xlsx'],
        poc: ['pdf']
      }
    };
  },
  computed: {
    packageExists() {
      return !_.isEmpty(this.attachment);
    }
  },
  methods: {
    ...mapActions('files', ['getFile']),
    async downloadFile(attachment) {
      try {
        let res = await this.getFile({
          bucket: attachment.Bucket,
          key: attachment.Key
        });
        window.open(res.data.url);
      } catch (err) {
        throw err;
      }
    },
    removeFile() {
      this.$emit('upload', {});
    },
    removeFileFromArray(attachment, index) {
      let fileArray = _.cloneDeep(attachment);
      fileArray.splice(index, 1);
      this.$emit('upload', fileArray);
    },
    validated(file) {
      const allowed = this.fileFormatsAllowed[this.uploadFileType];
      let valid = false;
      const fileExtension = file.name.split('.').pop();
      if (allowed.length > 0 && allowed.includes(fileExtension)) {
        valid = true;
      } else {
        this.$events.$emit(
          'toastEvent',
          'Invalid file type. Only ' +
            this.fileFormatsAllowed[this.uploadFileType].join(', ') +
            ' allowed'
        );
      }
      return valid;
    },
    onFileChange(e) {
      this.processing = true; // Trigger progress animation

      // Grab file object
      let file = e.target.files[0] || e.dataTransfer.files[0];

      // Clear to detect any change event
      e.target.value = '';
      if (this.validated(file)) {
        getPutSignedUrl(this.shortCode, file.name)
          .then(res => {
            let data = res.data;

            putSignedUrl(data.putObjectSignedUrl, file)
              .then(res => {
                if (res.status) {
                  let packageObj = {
                    Bucket: data.Bucket,
                    bucket: data.Bucket,
                    Key: data.Key,
                    file: file,
                    name: file.name,
                    size: file.size,
                    url: data.getObjectSignedUrl
                  };

                  if (this.multipleFiles) {
                    let attachmentArray = this.attachment;
                    attachmentArray.push(packageObj);
                    this.$emit('upload', attachmentArray);
                  } else {
                    this.$emit('upload', packageObj);
                  }

                  this.processing = false;
                }
              })
              .catch(err => {
                this.$events.$emit('showErrorDialog', err.response);
                this.processing = false;
              });
          })
          .catch(err => {
            this.$events.$emit('showErrorDialog', err.response);
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dz-area {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  cursor: pointer;
  border: 2px dashed #e8e8e8;
}
.dz-area > .dz-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #97a8be;
}
.dz-area > .dz-message > .highlight {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #20a0ff;
}
.dz-area .input-area {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dz-area .input-area input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
  cursor: hand;
}
</style>
