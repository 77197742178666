'use strict';

/**
 * @fileoverview The vuex submodule for admin/accounts user preferences.
 *
 * NOTE: To see the User API actions, see the `@/vuex/modules/accounts`
 * file.
 */

const state = {
  consentsSearchText: ''
};

const getters = {
  consentsSearchText: state => state.consentsSearchText
};

const actions = {
  setConsentsSearchText({commit}, consentsSearchText) {
    commit('SET_CONSENTS_SEARCH_TEXT', consentsSearchText);
  }
};

const mutations = {
  SET_CONSENTS_SEARCH_TEXT(state, consentsSearchText) {
    state.consentsSearchText = consentsSearchText;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
