<template>
  <v-card flat>
    <v-card-text>
      <div class="title grey--text text--darken-4 mb-1">
        Connection Instructions
      </div>
      <div class="body-1 grey--text text--darken-2">
        Joining instructions for attendees
      </div>

      <rich-text-editor
        :body="state.virtualMeetingInstructions"
        :hide-merge-menu="true"
        @update="onChange('virtualMeetingInstructions', $event)"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :loading="saving" @click="saveChanges" color="primary">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

import { mapActions } from 'vuex';

export default {
  name: 'MeetingWebcastViewSettings',
  components: {
    RichTextEditor
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      saving: false,
      shortCode: this.$route.params.shortcode,
      state: {
        virtualMeetingInstructions: ''
      }
    };
  },
  created() {
    this.initState();
  },
  methods: {
    ...mapActions('meetings', ['updateMeeting']),
    initState() {
      this.state = _.cloneDeep(this.options);
    },
    onChange(key, value) {
      _.set(this.state, key, value);
    },
    async saveChanges() {
      try {
        await this.updateMeeting({
          shortCode: this.shortCode,
          meeting: { ...this.currentMeeting, options: this.state }
        });

        this.$events.$emit('toastEvent', 'Changes saved');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.dateChanged = false;
      }
    }
  }
};
</script>
