<template>
  <div v-if="totalJobs > 0" class="nowrap">
    <v-chip
      v-if="completedJobs > 0"
      small
      label
      text-color="white"
      color="green lighten-2"
      >{{ completedJobs }}</v-chip
    >
    <v-chip
      v-if="pendingJobs > 0"
      small
      label
      text-color="white"
      color="blue lighten-2"
      >{{ pendingJobs }}</v-chip
    >
  </div>
</template>

<script>
export default {
  name: 'PrintStatusChips',
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    values() {
      return Object.values(this.stats);
    },
    hasValues() {
      return this.values.length > 0;
    },
    totalJobs() {
      return this.hasValues
        ? Object.values(this.stats).reduce((acc, val) => acc + val)
        : 0;
    },
    completedJobs() {
      return this.stats.completed || 0;
    },
    pendingJobs() {
      return this.totalJobs - this.completedJobs;
    }
  }
};
</script>

<style scoped>
.small {
  font-size: 12px;
}
</style>
