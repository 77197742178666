const serviceDetail2 = {
  meeting_agm: [
    {
      type: 'dropdown',
      title: 'Will this service be run virtually, in person, or both (hybrid)?',
      reviewTitle: 'Format',
      value: 'subtype',
      items: [
        { title: 'Virtual only', value: 'Virtual' },
        { title: 'In person only', value: 'In-Person' },
        { title: 'Hybrid', value: 'Hybrid' }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include any owner-occupied questions?',
      reviewTitle: 'Owner-occupied questions',
      value: 'ownerOccupiedQuestion',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include removal of any directors?',
      reviewTitle: 'Director removal',
      value: 'directorRemoval',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],
  meeting_sgm: [
    {
      type: 'dropdown',
      title: 'Will this service be run virtually, in person, or both (hybrid)?',
      reviewTitle: 'Format',
      value: 'subtype',
      items: [
        { title: 'Virtual only', value: 'Virtual' },
        { title: 'In person only', value: 'In-Person' },
        { title: 'Hybrid', value: 'Hybrid' }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include any owner-occupied questions?',
      reviewTitle: 'Owner-occupied questions',
      value: 'ownerOccupiedQuestion',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include removal of any directors?',
      reviewTitle: 'Director removal',
      value: 'directorRemoval',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],
  meeting_turnover: [
    {
      type: 'dropdown',
      title: 'Will this service be run virtually, in person, or both (hybrid)?',
      reviewTitle: 'Format',
      value: 'subtype',
      items: [
        { title: 'Virtual only', value: 'Virtual' },
        { title: 'In person only', value: 'In-Person' },
        { title: 'Hybrid', value: 'Hybrid' }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include any owner-occupied questions?',
      reviewTitle: 'Owner-occupied questions',
      value: 'ownerOccupiedQuestion',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],

  meeting_townhall: [
    {
      type: 'dropdown',
      title: 'Will this service be run virtually, in person, or both (hybrid)?',
      reviewTitle: 'Format',
      value: 'subtype',
      items: [
        { title: 'Virtual only', value: 'Virtual' },
        { title: 'In person only', value: 'In-Person' },
        { title: 'Hybrid', value: 'Hybrid' }
      ]
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],
  campaign_email_collection: [
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],

  campaign_budget: [
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],

  campaign_certs: [
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      value: 'additionalDetails',
      optional: true
    }
  ],
  campaign_advance: [
    {
      type: 'radio',
      title: 'Will meeting business include any owner-occupied questions?',
      reviewTitle: 'Owner-occupied questions',
      value: 'ownerOccupiedQuestion',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'radio',
      title: 'Will meeting business include removal of any directors?',
      reviewTitle: 'Director removal',
      value: 'directorRemoval',
      items: [
        { title: 'Yes', value: true },
        { title: 'No', value: false }
      ]
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ],
  campaign_amendment: [
    {
      type: 'number',
      title: 'Threshold for amendment to pass',
      subtitle: 'Input number of votes required to pass',
      reviewTitle: 'Threshold for amendment to pass',
      value: 'quorumMinimum'
    },
    {
      type: 'text-area',
      title: 'Are there any other details you want us to know?',
      reviewTitle: 'Other details',
      value: 'additionalDetails',
      optional: true
    }
  ]
};

export default serviceDetail2;
