<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Remove Custom Data From Membership</span>
      </v-card-title>
      <v-card-text>
        <div v-for="(key, index) in customDataKeys" :key="index">
          <v-checkbox
            v-model="customDataToBeRemoved"
            :value="key"
            :label="key"
          ></v-checkbox>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          :disabled="customDataToBeRemoved.length === 0"
          color="primary"
          @click.native="emitRemoveCustomData"
          >Remove</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsRemoveCustomDataDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    customDataKeys: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      customDataToBeRemoved: []
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');

      // reset value
      this.customDataToBeRemoved = [];
    },
    emitRemoveCustomData() {
      this.$emit('removeCustomData', this.customDataToBeRemoved);

      this.customDataToBeRemoved = [];
    }
  }
};
</script>

<style scoped></style>
