<template>
  <v-container fluid grid-list-lg>
    <v-layout v-if="currentRequest" class="mx-2 mb-5" align-center>
      <v-flex xs12>
        <v-layout row wrap align-center>
          <v-flex xs10>
            <v-layout class="align-center">
              <h5 class="headline font-weight-bold">
                {{ requestData.name }}
              </h5>
              <a
                v-if="isAdmin && currentRequest.status === 'approved'"
                :href="meetingUrl"
                class="headline font-weight-bold black--text ml-2"
              >
                <v-icon color="black">
                  exit_to_app
                </v-icon>
              </a>
            </v-layout>
            <div class="space-between align-center">
              <v-chip
                class="hidden-sm-and-down status mt-3 bold"
                :text-color="getStatusDetails(currentRequest.status).color"
                :color="getStatusDetails(currentRequest.status).background"
                small
              >
                {{ currentRequest.status.toUpperCase() }}
              </v-chip>
            </div>
          </v-flex>
        </v-layout>
        <v-layout v-if="isAssignee" row wrap align-center>
          <v-flex xs12 lg12>
            <approval-card
              :status="currentRequest.status"
              :meeting-url="meetingUrl"
              @approve="dialogs.action = 'approve'"
              @deny="dialogs.action = 'deny'"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mt-3">
          <v-flex xs6>
            <assignee-card
              :request-assignee="requestAssignee"
              :is-assignee="isAssignee"
              :is-approved="isApproved"
              :is-denied="isDenied"
              @handle-action="openAssigneeDialog"
            />
            <!--   Request details services -->
            <div class="mt-3">
              <v-layout v-if="isAssignee" row wrap align-center>
                <v-flex xs6>
                  <v-subheader class="pl-0" light>
                    <span class="capitalize">
                      Service Details
                    </span>
                  </v-subheader>
                </v-flex>
                <v-flex xs6 class="text-right">
                  <v-btn
                    outline
                    color="primary"
                    :disabled="isApproved || isDenied"
                    @click="editDetails"
                  >
                    Edit Details
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout v-else row wrap align-center>
                <v-flex xs12>
                  <v-subheader class="pl-0" light>
                    <span class="capitalize">
                      Meeting Details
                    </span>
                  </v-subheader>
                </v-flex>
              </v-layout>
            </div>
            <div class="white-bg px-2 py-2">
              <div class="mb-1" v-for="service in services" :key="service.step">
                <ServiceCard
                  :title="service.title"
                  :current-request-data="requestData"
                  :request-account="requestAccount"
                  :step="service.step"
                />
              </div>
              <ServiceCard
                v-if="isAdmin"
                title="Admin details"
                :current-request-data="requestData"
                :request-account="requestAccount"
                :deal-url="currentRequest.dealUrl"
              />
            </div>
          </v-flex>
          <v-flex xs6>
            <activity-feed
              :is-approved="isApproved"
              :is-denied="isDenied"
              :events="requestEvents"
              :loading="loading"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <approval-dialog
      :action="dialogs.action"
      :loading="loading"
      @close="dialogs.action = ''"
      @handle-action="handleAction"
    />

    <checklist-dialog
      :open="dialogs.checklist"
      @redirect="redirectToChecklist"
      @close="dialogs.checklist = false"
    />

    <add-request-assignee-dialog
      :value="dialogs.assignee"
      :account="account"
      :options="assigneesList"
      :current-manager="requestAssignee"
      :loading="loading"
      @update="assignManager"
      @close="dialogs.assignee = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import ApprovalCard from './components/ApprovalCard.vue';
import AssigneeCard from './components/AssigneeCard.vue';
import ServiceCard from './components/ServiceCard.vue';
import ActivityFeed from './components/ActivityFeed.vue';
import ChecklistDialog from './components/ChecklistDialog.vue';

// Dialogs
import ApprovalDialog from './components/ApprovalDialog';
import AddRequestAssigneeDialog from './components/AddRequestAssigneeDialog.vue';

export default {
  name: 'MeetingRequestDetailView',
  components: {
    ApprovalCard,
    AssigneeCard,
    ServiceCard,
    ActivityFeed,
    ApprovalDialog,
    AddRequestAssigneeDialog,
    ChecklistDialog
  },
  props: {
    currentRequest: {
      type: Object
    }
  },
  data() {
    return {
      services: [
        { step: 1, title: 'Service details' },
        { step: 2, title: 'Additional services' }
      ],
      statusOptions: {
        pending: {
          background: '#ffe389',
          color: '#EC9235',
          label: 'Pending',
          value: 'pending'
        }
      },
      dialogs: {
        action: '',
        assignee: false,
        checklist: false
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'login']),
    ...mapGetters('meetings/requests', ['assigneesList']),
    requestData() {
      return this.currentRequest.data;
    },
    requestAccount() {
      return this.currentRequest.account;
    },
    statusDetails() {
      return this.statusOptions;
    },
    requestAssignee() {
      const assignee = this.currentRequest?.assignee;

      if (assignee) {
        return {
          ...assignee,
          name: `${assignee?.first_name} ${assignee?.last_name}`
        };
      }

      return null;
    },
    isAssignee() {
      const userProfile = this.login.profile;
      const isAdmin = userProfile.admin;
      const assigneeKey = this.requestAssignee
        ? this.requestAssignee.key
        : null;

      return isAdmin || _.isEqual(userProfile.key, assigneeKey);
    },
    account() {
      return {
        key: this.requestAccount?.key,
        name: this.requestAccount?.name
      };
    },
    requestEvents() {
      return this.currentRequest?.events;
    },
    isApproved() {
      return this.currentRequest?.status === 'approved';
    },
    isDenied() {
      return this.currentRequest?.status === 'denied';
    },
    meetingUrl() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';

      return `${baseUrl}/meetings/${this.currentRequest?.shortCode}/overview`;
    }
  },
  async created() {
    await this.getAssignees();
  },
  methods: {
    ...mapActions('meetings/requests', [
      'getAssignees',
      'assignRequest',
      'updateMeetingRequestStatus',
      'updateMeetingRequestShortcode'
    ]),
    ...mapActions('meetings', ['createMeeting']),
    ...mapActions('meetings/webcast', ['createWebcast']),
    getStatusDetails(status) {
      return this.statusDetails[status] ? this.statusDetails[status] : '';
    },

    async handleAction(event) {
      this.loading = true;
      try {
        const virtualMeetingType = this.requestData.options.virtualMeetingType;
        const isMeeting = virtualMeetingType === 'GQ-Hosted-Meeting';
        const meetingSubType = this.requestData.options.subtype;

        // Update meeting request status
        await this.updateMeetingRequestStatus({
          requestKey: this.currentRequest.key,
          status: event.action,
          comment: event.comment
        });

        if (event.action === 'approved') {
          // create the meeting card
          const newMeetingObj = {
            ...this.requestData,
            ...this.requestData.options,
            selectedCorp: this.currentRequest.account,
            meetingMeta: { ...this.requestData.meetingMeta }
          };

          const newMeeting = await this.createMeeting(newMeetingObj);

          if (newMeeting) {
            // If it's a virtual / hybrid meeting, automatically create webcast
            if (
              newMeeting.options.type === 'meeting' &&
              ['Virtual', 'Hybrid'].includes(meetingSubType) &&
              ['GQ-Hosted', 'GQ-Hosted-Meeting'].includes(virtualMeetingType)
            ) {
              const payload = { is_meeting: isMeeting };
              await this.createWebcast({
                shortCode: newMeeting.shortCode,
                payload
              });
            }

            // update meeting request with the new meeting shortcode
            const shortcode = newMeeting.shortCode;
            await this.updateMeetingRequestShortcode({
              requestKey: this.currentRequest.key,
              shortcode
            });

            // Show checklist dialog
            this.dialogs.checklist = true;
          }
        }

        // Close dialog
        this.dialogs.action = '';

        this.$events.$emit('toastEvent', 'Meeting request has been updated');
      } catch (err) {
        console.log(err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    openAssigneeDialog() {
      this.dialogs.assignee = true;
    },
    async assignManager(managerKey) {
      this.loading = true;
      try {
        const updatedRequest = {
          key: this.currentRequest?.key,
          assignedToUserKey: managerKey
        };

        // Update meeting request assignee
        await this.assignRequest({ request: updatedRequest });

        this.dialogs.assignee = false;
        this.$events.$emit('toastEvent', 'CSM Assigned');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    editDetails() {
      let baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dash2.getquorum.com'
          : 'http://localhost:8087';

      window.open(
        `${baseUrl}/createMeeting?requestKey=${this.currentRequest.key}`
      );
    },
    redirectToChecklist() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';

      window.open(
        `${baseUrl}/meetings/${this.currentRequest?.shortCode}/checklists`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
}

.white-bg {
  background-color: white;
}

.option-icon {
  width: 45px;
  height: 45px;
  background-color: #e7f2fb;
  border-radius: 50%;
}
</style>
