<template>
  <v-container fluid class="grey lighten-3">

    <v-progress-linear v-if="loading"  :indeterminate="loading" height="2" />    

    <v-layout v-else row wrap>
      <v-flex xs12 md6 class="pa-2">
        <div class="ml-5">      
          <p><span class="label">Updated By:</span> {{ item.meta ? item.meta.email : 'Unknown' }}</p>
          <p><span class="label">Sent On:</span> {{ item.sentAt ? formatDate(item.sentAt) : 'n/a' }}</p>
          <p><span class="label">Acknowledged At:</span> {{ item.acknowledgedAt ? formatDate(item.acknowledgedAt) : 'n/a' }}</p>
          <p><span class="label">Mailing Date:</span> {{ formatDate(item.mailingDate) }}</p>
          <p><span class="label">Package Page Count:</span> {{item.packagePagesPrinted ? item.packagePagesPrinted : 'n/a'}}</p>
          <p><span class="label">Recipient Count:</span> {{item.packagesMailed ? item.packagesMailed : 'n/a'}}</p>
        </div>
        
      </v-flex>
      <v-flex xs12 md6 class="pa-2">
        <p><span class="label">Print file(s):</span>
          <ul v-if="currentItemData.printing.length>0">
            <li v-for="(file, key) in currentItemData.printing" :key="key">
              <i><a @click="toSignedUrl(file)">{{file.name}}</a></i>
            </li>
          </ul>
          <ul v-else><li><i>No files attached</i></li></ul>
        </p>
        
        <p><span class="label">Recipient file(s):</span>
          <ul v-if="currentItemData.recipients.length>0">
            <li v-for="(file, key) in currentItemData.recipients" :key="key">
              <i><a @click="toSignedUrl(file)">{{file.name}}</a></i>
            </li>
          </ul>
          <ul v-else><li><i>No files attached</i></li></ul>
        </p>

        <p><span class="label">Proof of Completion file(s):</span>
          <ul v-if="currentItemData.poc.length>0">
            <li v-for="(file, key) in currentItemData.poc" :key="key">
              <i><a @click="toSignedUrl(file)">{{file.name}}</a></i>
            </li>
          </ul>
          <ul v-else><li><i>No files attached</i></li></ul>
        </p>


        <p><span class="label">Postage Cost:</span>
          {{item.postageCost ? '$' + formattedCurrencyValue(item.postageCost) : 'n/a'}}
        </p>

      </v-flex>
    </v-layout>
  </v-container>  
</template>


<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { formatCurrencyValue } from '@/helpers';

export default {
  name: 'AdminPrintJobListItemExpand',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    currentItemData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    ...mapActions('files', ['getFile']),
    formattedCurrencyValue(value) {
      return formatCurrencyValue(value);
    },
    formatDate(date, allowEmpty = false) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      if (allowEmpty && formattedDate === 'Invalid date') {
        return 'n/a';
      } else {
        return formattedDate;
      }
    },
    async toSignedUrl(fileObj) {
      try {
        const file = await this.getFile({
          key: fileObj.Key,
          bucket: fileObj.Bucket
        });
        window.open(file.data.url, '_blank');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
}
</style>