<template>
  <v-container>
    <v-layout>
      <div class="gq-cell--section-title">{{ businessType }}</div>
      <v-spacer />
      <div>
        <v-switch
          :label="`Advanced Mode: ${advancedToggle ? 'ON' : 'OFF'}`"
          v-model="advancedToggle"
        />
      </div>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-text-field
          label="Name of Question"
          v-model="state.name"
          :counter="255"
        />
        <v-text-field
          label="Header text"
          v-model="state.header"
          :counter="255"
        />
        <rich-text-editor
          :body="state.snippet"
          :rows="5"
          :hide-merge-menu="true"
          @update="handleUpdate('snippet', $event)"
        />

        <div class="spacer" />

        <!-- Type of election, if not bylaw -->
        <v-layout column v-if="showOwnerOccupiedtoggle">
          <div>
            <v-radio-group
              column
              v-model="state.type"
              v-on:change="handleTypeChange"
              hide-details
            >
              <div slot="label">
                Is this vote only available to owner-occupied units?
              </div>
              <v-radio label="Yes" value="owner-occupied" />
              <v-radio label="No" value="general-elections" />
            </v-radio-group>
          </div>
        </v-layout>

        <v-layout>
          <div v-if="showSecondaryOwnerOccupiedToggle" class="mb-3">
            <v-switch
              v-model="defaultOwnerOcc"
              @change="handleToggle('defaultOwnerOcc')"
              hide-details
              color="primary"
              label="Allow all units to self-declare as Owner-Occupied"
            />
            <div class="grey--text font-weight-medium">
              <li :class="defaultOwnerOcc ? 'font-weight-bold' : ''">
                ON: all units will be asked "Do you occupy this unit?"
              </li>
              <li :class="defaultOwnerOcc ? '' : 'font-weight-bold'">
                OFF: only units with the custom field "owner_occupied: true"
                will be asked "Do you occupy this unit?"
              </li>
            </div>
          </div>
        </v-layout>

        <!-- Is not owner occupied or bylaw -->
        <v-layout
          v-if="
            state.type === 'general-elections' ||
              state.type === 'substitute-elections' ||
              state.type === 'substitute-elections-oo'
          "
        >
          <v-text-field
            label="Number Of Available Seats"
            type="number"
            v-model="state.positions"
            :disabled="state.type === 'substitute-elections-oo'"
          />
        </v-layout>

        <!-- If not by-law -->
        <div v-if="state.type !== 'by-law'">
          <label class="caption"
            >Nominated Candidates (One name per line)</label
          >
          <div class="codemirror-container">
            <code-mirror
              :code-array="state.options"
              @update="handleUpdate('options', $event)"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- File Uploads -->
    <div class="gq-cell--section-title">{{ uploadTitle }}</div>
    <v-card class="pa-3">
      <file-upload-form :attachment="state.package" @upload="handleUpload" />
    </v-card>

    <!-- Advanced Options -->
    <div v-if="advancedToggle">
      <div class="gq-cell--section-title">Advanced Options</div>
      <v-card class="pa-3">
        <div v-if="!isDefaultTemplate">
          <div class="subheading font-weight-medium">
            Custom Text on legal proxy document
          </div>
          <div class="grey--text font-weight-medium">
            Note: If you picked Bill-106 as your proxy template, this field is
            ignored.
            <span
              class="accent--text pointer"
              @click="fillFormDefault(state.type)"
              >Click here</span
            >
            to fill with default content.
          </div>
          <v-textarea auto-grow v-model="state.template" />
        </div>

        <!-- Customize the download file link text -->
        <v-layout column>
          <v-text-field
            label="Customize file download link text on voting tool"
            :placeholder="downloadLinkTextPlaceholder"
            v-model="state.meta.options.downloadLinkText"
          />
        </v-layout>

        <v-switch
          :label="`Secret Mode: ${state.isSecret ? 'ON' : 'OFF'}`"
          hide-details
          v-model="state.isSecret"
        />
        <div class="grey--text font-weight-medium">
          {{
            state.isSecret
              ? 'Results are only visible to admins on overview page'
              : 'Results are visible on overview page (default)'
          }}
        </div>

        <v-layout column v-if="state.type !== 'by-law'">
          <v-switch
            label="Election Settings"
            hide-details
            v-model="metaOptionToggle.electionSettings"
            @change="toggleElectionSettings($event)"
          />
          <div class="grey--text font-weight-medium">
            The following settings only affect election type questions
          </div>

          <div v-if="isElectionSettings">
            <v-checkbox
              :true-value="true"
              :false-value="false"
              class="pb-0 mb-0"
              label="Hides the `X Seats Available` text in the Election Question Header"
              v-model="state.meta.options.hideSeatsText"
            />
            <v-checkbox
              :true-value="true"
              :false-value="false"
              class="pt-0 mt-0"
              label="Hides the input box to allow for write-in candidates."
              v-model="state.meta.options.hideWriteInCandidate"
            />
            <v-checkbox
              :true-value="true"
              :false-value="false"
              class="pt-0 mt-0"
              label="Hides text for election rank."
              v-model="state.meta.options.hideChoiceRanking"
            />
            <div class="subheading font-weight-medium">
              Optional Election Votes
            </div>
            <div class="grey--text font-weight-medium">
              How many additional votes can be cast for an election (past the
              `positions`). This is often used when clients want to allow voters
              to choose alternate candidates in case their preferred ones drop
              out.
            </div>
            <v-text-field
              label="Votes"
              v-model="state.meta.options.optionalVotes"
              type="number"
              clearable
            />

            <div class="subheading font-weight-medium">
              Customize Instructions Text
            </div>
            <div class="grey--text font-weight-medium">
              Optionally customize the instructions text just under the snippet
              on the voting UI. (HTML Enabled)
            </div>
            <v-text-field
              label="Instructions Text"
              v-model="state.meta.options.instructionsText"
              type="string"
              placeholder="(default) To abstain from voting, leave your votes unused and click 'Next Step'"
              clearable
            />
          </div>
        </v-layout>

        <!-- SETTINGS WITH TOGGLES -->
        <v-layout column v-if="state.type === 'by-law'">
          <v-switch
            label="By-Law PDF Form Settings"
            hide-details
            v-model="metaOptionToggle.customAnswerText"
            @change="toggleAdvancedModeOption('customAnswerText', $event)"
          />
          <div class="grey--text font-weight-medium">
            Affects output text on final PDF proxy - does not change options
            presented on UI. Inputs are HTML enabled.
          </div>

          <div v-if="metaOptionToggle.customAnswerText">
            <div
              v-for="(field, key) in state.meta.options.customAnswerText"
              :key="key"
            >
              <v-text-field
                clearable
                :label="`${key.toUpperCase()}`"
                v-model="state.meta.options.customAnswerText[key]"
              />
            </div>
          </div>
        </v-layout>

        <v-layout column v-if="state.type === 'by-law'">
          <v-switch
            label="By-Law UI Settings"
            hide-details
            v-model="metaOptionToggle.toggles"
            @change="toggleAdvancedModeOption('toggles', $event)"
          />

          <div class="grey--text font-weight-medium mb-2">
            Customize the Voting UI's bylaw component's individual toggle text
            and visibility status - this does not change the output on the PDF
            form. Toggles indicate visibility (default: true). Inputs are TEXT
            ONLY.
          </div>

          <div v-if="metaOptionToggle.toggles">
            <div v-for="(toggle, key) in state.meta.options.toggles" :key="key">
              <v-layout>
                <v-text-field
                  :disabled="!toggle.visible"
                  clearable
                  v-model="toggle.label"
                  :label="`${key.toUpperCase()}`"
                />
                <v-switch
                  v-model="toggle.visible"
                  class="pr-0 mr-0"
                  color="blue"
                  :label="toggle.visible ? 'ON' : 'OFF'"
                />
              </v-layout>
            </div>
          </div>
        </v-layout>

        <v-layout column v-if="state.type === 'by-law'">
          <v-switch
            class="mt-0"
            v-model="metaOptionToggle.hideIcons"
            hide-details
            :label="
              metaOptionToggle.hideIcons ? 'Hide Icons: ON' : 'Hide Icons: OFF'
            "
            @change="toggleIcons($event)"
          />
          <div class="grey--text font-weight-medium">
            Toggles if thumbs up and down icons are visible for By-Law options.
            This should be toggled on if the options do not follow a "Yes/No"
            structure.
          </div>
        </v-layout>
      </v-card>
    </div>

    <!-- Meeting Buttons -->
    <v-layout class="mt-5">
      <v-btn color="error" flat @click.native="dialog.delete = true">
        <v-icon>delete_forever</v-icon>Delete Business
      </v-btn>
      <v-spacer />
      <v-btn @click="dialog.cancel = true">Cancel</v-btn>
      <v-btn class="primary" @click="dialog.update = true">Update</v-btn>
    </v-layout>

    <!-- Restrictions Card -->
    <transition name="slide-fade">
      <div v-if="advancedToggle">
        <v-divider class="mt-3" />
        <div class="gq-cell--section-title">Restrictions</div>
        <question-restrictions-card
          class="mb-3"
          :restrictions="restrictions"
          :custom-data-keys="customData"
          @update="handleUpdateRestrictions"
          @remove="handleRemoveRestrictions"
          @removeAll="handleRemoveAllRestrictions"
        />
      </div>
    </transition>

    <!-- Dialog popup -->
    <v-dialog max-width="500px" v-model="dialog.cancel">
      <v-card>
        <v-card-title>
          <div class="title">Cancel Changes?</div>
        </v-card-title>
        <v-card-text>
          Your changes will not be saved. Click
          <b>Cancel Changes</b> to discard unsaved changes, or <b>Close</b> to
          return to the settings page.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.cancel = false">Close</v-btn>
          <v-btn class="error" @click="cancelUpdate">Discard Changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="dialog.update">
      <v-card>
        <v-card-title>
          <div class="title">Confirm Update</div>
        </v-card-title>
        <v-card-text>
          Click
          <b>UPDATE</b> to confirm changes, or <b>CLOSE</b> to go back to the
          settings page.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.update = false">Close</v-btn>
          <v-btn class="primary" @click="submitUpdate">UPDATE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="dialog.delete">
      <v-card>
        <v-card-title>
          <div class="title red--text text--darken-1">
            Delete Meeting Business?
          </div>
        </v-card-title>
        <v-card-text>
          <v-alert outline color="error" icon="warning" :value="true">
            <strong>WARNING</strong>: You are about to delete this question.
            Once deleted you cannot undo this action. Click <b>DELETE</b> to
            confirm delete, or click <b>CLOSE</b> to return to editing
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.delete = false">Close</v-btn>
          <v-btn class="error" @click="deleteBusiness">DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      title="Are you sure?"
      text="This will determine which owners can self declare as owner occupied."
      :dialog="dialog.defaultOwnerOcc"
      confirm-text="Yes"
      decline-text="No"
      @confirm="handleOwnerOccupiedRestriction($event)"
      @decline="handleOwnerOccupiedRestriction($event)"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { removeEmptyProperty } from '@/lib/property-helpers';

import FileUploadForm from '@/components/FileUploadForm';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import CodeMirror from '@/components/CodeMirror';
import QuestionRestrictionsCard from '@/components/QuestionRestrictionsCard';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

import { defaults } from '@/lib/default-business-meta-options.js';

export default {
  name: 'MeetingBusinessEditView',
  components: {
    FileUploadForm,
    RichTextEditor,
    CodeMirror,
    QuestionRestrictionsCard,
    ConfirmDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    businessList: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      state: {},
      shortCode: this.$route.params.shortcode,
      questionId: this.$route.params.questionId,
      restrictions: [],
      customData: this.currentMeeting.options.customUnitFields,
      defaultOwnerOcc: true,
      advancedToggle: false,
      metaOptionToggle: {
        toggles: false,
        customAnswerText: false,
        electionSettings: false,
        hideIcons: false
      },
      dialog: {
        cancel: false,
        update: false,
        delete: false,
        defaultOwnerOcc: false
      }
    };
  },
  computed: {
    ...mapGetters('meetings/business', ['meetingBusinessRestrictions']),
    businessType() {
      switch (this.state.type) {
        case 'by-law':
          return 'By-Law';
        case 'general-elections':
          return 'General Election';
        case 'owner-occupied':
          return 'Owner Occupied Election';
        case 'director-removals':
          return 'Vote for Removal of Directors';
        case 'substitute-elections':
          return 'Election of Substitute Directors';
        case 'substitute-elections-oo':
          return 'Election of Substitute Owner Occupied Directors';
        default:
          return 'Unknown Election Type';
      }
    },
    uploadTitle() {
      if (this.state.type === 'by-law') {
        return 'Additional Bylaw Info (PDF)';
      } else if (this.state.type === 'director-removals') {
        return 'Additional Info (PDF)';
      } else {
        return 'Candidate Resumes (PDF)';
      }
    },
    downloadLinkTextPlaceholder() {
      if (this.state.type === 'by-law') {
        return '(Default) Click here for additional information';
      } else {
        return '(Default) Click here for candidate resumes';
      }
    },
    showOwnerOccupiedtoggle() {
      return ['owner-occupied', 'general-elections'].includes(this.state.type);
    },
    showSecondaryOwnerOccupiedToggle() {
      return ['owner-occupied', 'substitute-elections-oo'].includes(
        this.state.type
      );
    },
    isDefaultTemplate() {
      return _.get(this.currentMeeting, 'options.proxyTemplate', false) ===
        'on-bill-106'
        ? true
        : false;
    },
    // Check if PDF Form Settings are enabled
    isBylawCustomAnswer() {
      return _.has(this.state, 'meta.options.customAnswerText');
    },
    // Check if toggles are enabled
    isBylawUICustom() {
      return _.has(this.state, 'meta.options.toggles');
    },
    // Check if election related settings are enabled
    isElectionSettings() {
      if (
        _.has(this.state, 'meta.options.hideSeatsText') ||
        _.has(this.state, 'meta.options.hideWriteInCandidate') ||
        _.has(this.state, 'meta.options.hideChoiceRanking') ||
        _.has(this.state, 'meta.options.optionalVotes')
      ) {
        return true;
      }
      return false;
    },
    isHidingIcons() {
      return _.get(this.state, 'meta.options.hideIcons', false);
    },
    hasDownloadLinkText() {
      return _.get(this.state, 'meta.options.downloadLinkText', false);
    },
    // Check if any advanced options exist
    isAdvancedOptions() {
      if (
        this.isBylawCustomAnswer ||
        this.isBylawUICustom ||
        this.isElectionSettings ||
        this.hasDownloadLinkText ||
        this.state.isSecret ||
        this.hasRestrictions ||
        this.isHidingIcons
      ) {
        return true;
      }
      return false;
    },
    hasRestrictions() {
      return this.restrictions.length > 0;
    }
  },
  created() {
    this.init();
    this.initRestrictions();
  },
  mounted() {
    // Set the advanced toggle if advanced options exist
    this.advancedToggle = this.isAdvancedOptions;
    this.metaOptionToggle.toggles = this.isBylawUICustom;
    this.metaOptionToggle.customAnswerText = this.isBylawCustomAnswer;
    this.metaOptionToggle.electionSettings = this.isElectionSettings;
    this.metaOptionToggle.hideIcons = this.isHidingIcons;
  },
  methods: {
    ...mapActions('meetings/business', [
      'getMeetingBusiness',
      'deleteMeetingBusiness',
      'updateMeetingBusiness',
      'getMeetingBusinessRestrictions',
      'createMeetingBusinessRestriction',
      'deleteMeetingBusinessRestriction'
    ]),
    init() {
      const business = this.businessList[this.questionId];
      this.state = this.initBusinessState(business);

      // Ensure the download link meta field exists so it doesn't error out
      const linkText = _.get(business, 'meta.options.downloadLinkText', '');
      _.set(this.state, 'meta.options.downloadLinkText', linkText);

      // // Ensure the show icons meta field exists so it doesn't error out
      const hideIcons = _.get(business, 'meta.options.hideIcons', false);
      _.set(this.state, 'meta.options.hideIcons', hideIcons);
    },
    initBusinessState(business) {
      let businessObj = defaults['businessObj'];

      // Only copy the non meta.options items, as those are populated above
      Object.keys(business).forEach(function(key) {
        businessObj[key] = business[key];
      });

      return businessObj;
    },
    async initRestrictions() {
      this.restrictions = [];

      // Get available custom keys and existing restrictions
      await this.getMeetingBusinessRestrictions({
        shortCode: this.shortCode,
        questionId: this.questionId
      });

      // If restrictions exist, prepare them for displaying/editing
      if (this.meetingBusinessRestrictions.length > 0) {
        this.meetingBusinessRestrictions.forEach(restriction => {
          const obj = {
            key: restriction.customField,
            value: restriction.fieldValue
          };
          this.handleOwnerOccupiedToggle(obj);
          this.restrictions.push(obj);
        });
      }
    },
    handleOwnerOccupiedToggle(obj) {
      if (obj.key === 'owner_occupied') {
        this.defaultOwnerOcc = !obj.value;
      }
    },

    toggleElectionSettings(event) {
      let that = this;
      // Clone so you're not changing default values
      let defaultElectionObj = _.cloneDeep(defaults['electionSettings']);

      // If switching to ON
      if (event) {
        if (_.has(this.state.meta, 'options')) {
          Object.keys(defaultElectionObj).forEach(function(key) {
            that.$set(that.state.meta.options, key, defaultElectionObj[key]);
          });
        } else {
          // Add all object properties, the options object doesn't exist, no risk of overwriting properties
          let obj = {
            options: defaultElectionObj
          };
          this.$set(this.state, 'meta', obj);
        }
      } else if (!event) {
        Object.keys(defaultElectionObj).forEach(function(key) {
          that.$delete(that.state.meta.options, key);
        });
      }
    },
    toggleIcons(event) {
      if (event) {
        if (_.has(this.state.meta, 'options')) {
          this.$set(this.state.meta.options, 'hideIcons', true);
        } else {
          let obj = { options: {} };
          obj.options['hideIcons'] = true;
          this.$set(this.state, 'meta', obj);
        }
      } else if (!event) {
        this.$delete(this.state.meta.options, 'hideIcons');
      }
    },
    toggleAdvancedModeOption(type, event) {
      // Clone so you're not changing default values
      let defaultOption = _.cloneDeep(defaults[type]);

      if (event) {
        // Account for whether an options object already exists
        if (_.has(this.state.meta, 'options')) {
          this.$set(this.state.meta.options, type, defaultOption);
        } else {
          let obj = {
            options: {}
          };
          obj.options[type] = defaultOption;
          this.$set(this.state, 'meta', obj);
        }
      } else if (!event) {
        this.$delete(this.state.meta.options, type);
      }
    },
    async deleteBusiness() {
      try {
        await this.deleteMeetingBusiness({
          shortCode: this.shortCode,
          questionId: this.questionId
        });
        this.$events.$emit('toastEvent', 'Meeting Business Deleted');
        this.$router.push({
          name: 'meetingBusiness',
          params: {
            shortcode: this.shortCode
          }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.delete = false;
      }
    },
    cancelUpdate() {
      this.dialog.cancel = false;
      this.$router.push({
        name: 'meetingBusiness',
        params: {
          shortcode: this.shortCode
        }
      });
      this.$events.$emit('toastEvent', 'Changes Cancelled');
    },
    async submitUpdate() {
      try {
        // remove empty properties
        let business = removeEmptyProperty({ ...this.state });

        // Fix for saving error if emptying candidate options.
        if (business.options.length === 1 && business.options[0] === '') {
          // If there a supposed to be no candidates then options should be empty array
          business.options = [];
        }

        // temp fix for positions property requirement, and default
        if (business.type === 'by-law' || business.type === 'owner-occupied') {
          business.positions = 1;
        }

        await this.updateMeetingBusiness({
          business,
          shortCode: this.shortCode,
          questionId: this.questionId
        });

        this.$events.$emit('toastEvent', 'Meeting Business Updated');

        this.$router.push({
          name: 'meetingBusiness',
          params: {
            shortcode: this.shortCode
          }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.update = false;
      }
    },
    handleUpdate(key, value) {
      // Lodash search state object for matching key
      _.set(this.state, key, value);
    },
    handleUpload(res) {
      this.state.package = res;
    },
    fillFormDefault(type) {
      this.state.template = `<p>Insert Content Here</p><p>{{{ answer }}}</p>`;
    },
    handleTypeChange(newQuestionType) {
      if (newQuestionType === 'general-elections' && this.hasRestrictions) {
        // Question type is toggled from owner-occupied to general-elections:
        // remove any existing owner-occ custom restriction
        this.handleRemoveRestrictions({ key: 'owner_occupied', value: 'true' });
      }
    },
    handleToggle(key) {
      this.dialog[key] = true;
    },

    async handleOwnerOccupiedRestriction(value) {
      if (value) {
        this.handleOwnerOcc(this.defaultOwnerOcc);
      } else {
        this.defaultOwnerOcc = !this.defaultOwnerOcc;
      }
      this.dialog.defaultOwnerOcc = false;
    },

    handleOwnerOcc(ownerOccRestriction) {
      // If OO voting is open to all units to self-declare,
      // remove the OO restriction:
      if (ownerOccRestriction && this.hasRestrictions) {
        this.handleRemoveRestrictions({ key: 'owner_occupied', value: 'true' });
      }
      // Otherwise, only units with 'owner_occupied: true' can self-declare;
      // add the OO restriction:
      if (!ownerOccRestriction) {
        this.handleUpdateRestrictions(
          {},
          { key: 'owner_occupied', value: 'true' }
        );
      }
    },
    async handleUpdateRestrictions(existingRestriction, newRestriction) {
      try {
        // Check that newRestriction is a valid value
        if (newRestriction.key === 'owner_occupied') {
          this.validateOwnerOccupiedValue(newRestriction);
        }

        // Determine if we are adding new restriction,
        // or updating existing restriction
        const updating = !_.isEmpty(existingRestriction);

        if (updating) {
          await this.deleteMeetingBusinessRestriction({
            shortCode: this.shortCode,
            questionId: this.questionId,
            customField: existingRestriction.key,
            fieldValue: existingRestriction.value
          });
        }

        await this.createMeetingBusinessRestriction({
          shortCode: this.shortCode,
          questionId: this.questionId,
          restriction: {
            questionKey: this.state.questionKey,
            customField: newRestriction.key,
            fieldValue: newRestriction.value
          }
        });

        this.initRestrictions();

        if (updating) {
          this.$events.$emit(
            'toastEvent',
            `Restriction Updated (${newRestriction.key.toUpperCase()}: ${newRestriction.value.toUpperCase()})`
          );
        } else {
          this.$events.$emit(
            'toastEvent',
            `Restriction Added (${newRestriction.key.toUpperCase()}: ${newRestriction.value.toUpperCase()})`
          );
        }
      } catch (err) {
        // Added exception here for manually triggered error from checkOwnerOccupiedValue
        if (err?.response) {
          this.$events.$emit('showErrorDialog', err.response);
        } else {
          // Emitting error dialog for global event requires status, statusText, data
          this.$events.$emit('showErrorDialog', {
            status: 0,
            statusText: err,
            data: null
          });
        }
      }
    },
    async handleRemoveRestrictions(restriction) {
      try {
        await this.deleteMeetingBusinessRestriction({
          shortCode: this.shortCode,
          questionId: this.questionId,
          customField: restriction.key,
          fieldValue: restriction.value
        });

        this.initRestrictions();
        this.$events.$emit(
          'toastEvent',
          `Restriction Removed (${restriction.key.toUpperCase()}: ${restriction.value.toUpperCase()})`
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleRemoveAllRestrictions() {
      try {
        for (const restriction of this.restrictions) {
          await this.deleteMeetingBusinessRestriction({
            shortCode: this.shortCode,
            questionId: this.questionId,
            customField: restriction.key,
            fieldValue: restriction.value
          });
        }

        this.initRestrictions();
        this.$events.$emit('toastEvent', 'Restrictions Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    validateOwnerOccupiedValue(newRestriction) {
      // Check that owner_occupied value is lower case and true/false (string)
      // Restrictions are stored as strings
      if (newRestriction.value !== newRestriction.value.toLowerCase()) {
        throw new Error('OWNER_OCCUPIED value must be in lower case');
      } else if (
        newRestriction.value !== 'true' &&
        newRestriction.value !== 'false'
      ) {
        throw new Error('OWNER_OCCUPIED value must be "true" or "false"');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
