<template>
  <v-dialog :value="isOpen" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Import a Signed Service Agreement</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md>
          <v-form v-model="validForm">
            <v-layout row class="mb-3">
              <v-flex xs12>
                <p class="title">Step 1: Select the Agreement Type</p>
                <v-radio-group v-model="state.type" row>
                  <span slot="label" class="radio-label">Agreement Type: </span>
                  <v-radio label="Standard" value="standard" />
                  <v-radio label="Florida" value="florida" />
                  <v-radio label="Association" value="association" />
                  <v-radio label="Custom" value="custom" />
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row class="mt-3 mb-3">
              <v-flex xs12 sm6>
                <p class="title">Step 2: Upload Signed Agreement Files</p>
                <file-upload-form
                  :attachment="state.filesFormatted"
                  :multiple-files="true"
                  :static-files="true"
                  icon="attach_file"
                  @upload="onFileChange"
                />
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row wrap class="mt-3">
              <v-flex xs12>
                <p class="title">Step 3: Set the Agreement Dates</p>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm6 class="mb-3">
                <date-picker
                  label="Date Agreement Signed By Client *"
                  :hint="'This should match what is in the PDF upload below'"
                  :iso-date="state.signedAt"
                  hide-icon
                  is-required
                  @update="setSignedAt"
                />
              </v-flex>
              <v-flex xs12 sm6 class="mb-3" v-show="showExpiryDate">
                <date-picker
                  label="Date the agreement expires (optional)"
                  :iso-date="state.expiresAt"
                  hide-icon
                  :hint="
                    'If custom agreement does not exipre, leave this blank'
                  "
                  @update="setExpiresAt"
                />
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row class="mt-3">
              <v-flex xs12>
                <p class="mb-3 title">Step 4: (Optional) Add Notes</p>
                <v-textarea
                  v-model="state.description"
                  label="Special notes (optional)"
                  placeholder="eg. This agreement has special pricing as requested by the client's lawyers, etc...."
                  outline
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="isImporting"
          @click.native="$emit('close-dialog')"
          flat
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!canImport"
          :loading="isImporting"
          @click.native="save"
          flat
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import * as filters from '@/filters';
import DatePicker from '@/components/DatePicker';
import FileUploadForm from '@/components/FileUploadForm';

export default {
  name: 'ImportAgreementDialog',
  components: {
    DatePicker,
    FileUploadForm
  },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    accountKey: {
      type: String,
      default() {
        return null;
      }
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      state: {
        signedAt: null,
        expiresAt: null,
        description: null,
        type: 'standard',
        files: []
      },
      rules: {
        signedAt: [v => !!v || 'Signed date is required']
      },
      validForm: false,
      isImporting: false
    };
  },
  watch: {
    isOpen() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  computed: {
    showExpiryDate() {
      return this.state.type === 'custom';
    },
    fileList() {
      return Array.from(this.state.files);
    },
    hasFiles() {
      return this.fileList.length > 0;
    },
    canImport() {
      return this.validForm && this.hasFiles;
    }
  },
  methods: {
    ...mapActions('serviceAgreements', ['importServiceAgreement']),
    setState() {
      this.state = {
        signedAt: null,
        expiresAt: null,
        description: null,
        type: 'standard',
        files: [],
        filesFormatted: []
      };
      this.isImporting = false;
    },
    setSignedAt(value) {
      this.state.signedAt = value;
    },
    setExpiresAt(value) {
      this.state.expiresAt = value;
    },
    emitClose() {
      this.$emit('close-dialog');
    },
    addFilesClicked() {
      return this.$refs.files.click();
    },
    async onFileChange(files) {
      this.state.files = _.map(files, function(file) {
        return file.original;
      });
      this.state.filesFormatted = files;
    },
    async save() {
      try {
        this.isImporting = true;
        await this.importServiceAgreement({
          ...this.state,
          accountKey: this.accountKey
        });
        this.$events.$emit('toastEvent', 'Agreement Successfully Imported');
        this.$emit('imported');
        this.emitClose();
      } catch (err) {
        this.$events.$emit('toastEvent', `Error: ${err.response}`);
      } finally {
        this.isImporting = false;
      }
    }
  }
};
</script>

<style scoped>
.radio-label {
  margin-right: 10px;
}
.hidden-input {
  display: none;
}
.file-item {
  display: block;
}
</style>
