<template>
  <v-dialog
    persistent
    max-width="500px"
    :value="value"
    @keydown.esc="emitClose"
    @input="emitInput"
  >
    <v-card class="text-xs-center pt-4 pb-2">
      <p class="headline font-weight-black">
        Welcome!
      </p>

      <v-card-text class="pt-0 px-5"
        >Let's start with a quick tour of your Virtual Board Meeting
        dashboard.</v-card-text
      >

      <v-btn class="primary mt-3" @click="emitStart">Get Started</v-btn><br />
      <v-btn flat @click="emitClose" class="caption">skip the tour</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingVbmWelcomeDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Needed for v-model on component to work
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitStart() {
      this.$emit('start');
    }
  }
};
</script>
