<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title> <span class="title">Add New Unit</span> </v-card-title>
      <v-card-text>
        <v-text-field
          label="Unit"
          v-model="newUnit.unit"
          placeholder="eg. 1, 10, 20"
        />
        <v-select label="Select" :items="addresses" v-model="newUnit.address" />
        <v-text-field
          label="Control Number (optional)"
          v-model="newUnit.controlNumber"
          placeholder="Optional"
        />

        <!-- Set unit as ineligible -->
        <!-- <v-checkbox
          hide-details
          light
          label="Set as guest unit (not eligible to vote)"
          v-model="guestUnitToggle"
        /> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          :disabled="!(newUnit.unit && newUnit.address)"
          color="primary"
          @click.native="emitAddUnit"
          >Add Unit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsAddUnitDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    addresses: {
      type: Array,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      newUnit: {
        unit: '',
        address: ''
      }
      // guestUnitToggle: false
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');

      // Clear value
      this.newUnit.unit = '';
      this.newUnit.address = '';
    },
    emitAddUnit() {
      // if (this.guestUnitToggle) {
      //   this.newUnit.customData = { ineligible_reason: 'guest-unit' };
      // }
      this.$emit('addUnit', this.newUnit);
    }
  }
};
</script>

<style scoped></style>
