<template>
  <v-dialog persistent :value="value" max-width="550px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <div class="title">Close Advance Voting?</div>
      </v-card-title>

      <v-card-text>
        <p class="mt-3">
          Once advance voting is closed, owners will no longer be able to submit
          votes in advance of the meeting.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn flat @click="emitCancel">cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click="emitCloseAdvanceVoting"
          >close Advance Voting</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ToggleAdvanceVotingDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
    emitCloseAdvanceVoting() {
      this.$emit('closeAdvanceVoting');
    }
  }
};
</script>
