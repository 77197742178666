'use strict';

/**
 * @fileoverview The vuex submodules for admin user preferences
 */

// Vuex Modules
import accounts from './accounts';
import users from './users';
import printJobs from './print-jobs';
import zoom from './zoom';

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  modules: {
    accounts,
    users,
    printJobs,
    zoom
  },
  getters,
  actions,
  mutations
};
