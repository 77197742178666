<template>
  <div>
    <!-- Show header if navLabel is truthy -->
    <h5 class="headline font-weight-bold mt-3">
      Documents
    </h5>
    <v-divider class="gq-divider" />

    <!-- Tabs and buttons -->
    <v-layout class="mt-3">
      <v-tabs
        v-model="active"
        slider-color="primary"
        color="#fafafa"
        class="px-3"
        active-class="vbm-document__tab--active"
        id="vbm-documents-tab"
      >
        <v-tab data-v-step="vbm-documents-tab" ripple>
          <span style="color:#0F64A9; font-weight: 600;"
            >Meeting Documents ({{ meetingDocuments.length }})</span
          >
        </v-tab>
        <v-tab data-v-step="vbm-private-documents-tab" ripple>
          <span style="color:#0F64A9; font-weight: 600;"
            >Private Documents ({{ privateDocuments.length }})</span
          >
        </v-tab>
      </v-tabs>
      <v-spacer />

      <!-- UPLOAD button, dependent on active tab -->
      <v-btn
        color="primary"
        dark
        class="round-button"
        @click="dialog.upload = true"
        ><v-icon left dark>file_upload</v-icon>Upload</v-btn
      >

      <!-- Upload dialog -->
      <upload-dialog
        v-model="dialog.upload"
        :type="activeTab"
        @close="dialog.upload = false"
      />
    </v-layout>

    <!-- Tab description alerts -->
    <v-layout>
      <v-alert
        v-if="activeTab === 'meeting'"
        :value="true"
        color="#0F64A9"
        icon="info"
        outline
        class="py-2 mx-3 my-3"
        style="border-radius: 4px;"
      >
        <span style="font-size: 12px;"
          >Shared documents will be visible to all members through the meeting
          portal</span
        >
      </v-alert>
      <v-alert
        v-else-if="activeTab === 'private'"
        :value="true"
        color="#0F64A9"
        icon="info"
        outline
        class="py-2 mx-3 my-3"
        style="border-radius: 4px;"
      >
        <span style="font-size: 12px;"
          >Post-meeting documents (e.g. recordings if enabled) will be
          automatically uploaded here after the meeting ends</span
        >
      </v-alert>
      <v-spacer></v-spacer>
    </v-layout>

    <!-- Document data tables -->
    <v-card class="round-card" height="400">
      <!-- Shared documents in a VBM is the meeting-upload component -->
      <v-tabs-items v-model="active">
        <v-tab-item>
          <v-card flat class="round-card">
            <v-card-text>
              <documents-table
                v-if="meetingDocuments.length"
                type="meeting"
                :documents="meetingDocuments"
                :short-code="shortCode"
                :loading="documentsLoading"
              />
              <meeting-documents-uploader v-else :short-code="shortCode" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Post meeting documents in a VBM use the shared upload component -->
        <v-tab-item>
          <v-card flat class="round-card">
            <v-card-text>
              <documents-table
                v-if="privateDocuments.length"
                type="private"
                :documents="privateDocuments"
                :short-code="shortCode"
                :loading="filesLoading"
              />
              <!-- Use v2 version -->
              <meeting-files-uploader v-else :short-code="shortCode" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import MeetingDocumentsUploader from './components/MeetingVbmDocumentsUploader';
import MeetingFilesUploader from './components/MeetingVbmFilesUploader';
import UploadDialog from './components/MeetingVbmDocumentsUploadDialog.vue';
import DocumentsTable from './components/MeetingVbmDocumentsTable.vue';

export default {
  name: 'MeetingVbmDocuments',
  components: {
    MeetingDocumentsUploader,
    MeetingFilesUploader,
    UploadDialog,
    DocumentsTable
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      active: Number(this.$route.query.tab) || 0,
      tabs: ['meeting', 'private'],
      dialog: {
        upload: false
      }
    };
  },
  computed: {
    ...mapGetters('v2/documents', ['documentsLoading', 'documentsByShortCode']),
    ...mapGetters('v2/meetingsFiles', ['filesLoading', 'meetingFiles']),
    privateDocuments() {
      return this.meetingFiles;
    },
    meetingDocuments() {
      return this.documentsByShortCode(this.shortCode);
    },
    activeTab() {
      return this.tabs[this.active];
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('v2/meetingsFiles', ['fetchMeetingFiles']),
    ...mapActions('v2/meetings', ['fetchMeeting']),
    ...mapActions('v2/documents', ['fetchMeetingDocuments']),
    async init() {
      try {
        // Get files for private meeting documents
        await this.fetchMeetingFiles({ shortCode: this.shortCode });

        // Get files for public meeting files
        await this.fetchMeetingDocuments({ shortCode: this.shortCode });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
.round-button {
  border-radius: 5px;
}
.round-card {
  border-radius: 7px;
}
</style>

<style>
/* Fix for vue-tour highlighting. Allow box-shadow to fill page */
#vbm-documents-tab > .v-tabs__bar > .v-tabs__wrapper {
  contain: none !important;
  overflow: visible !important;
}
</style>
