<template>
  <div>
    <div v-if="!showData && question.is_secret">
      <v-alert
        :value="true"
        class="vote-alert"
        type="info"
        icon="lock"
        color="#607d8b"
      >
        {{
          !canViewEarly
            ? 'The results will be shown once voting closes or when the meeting begins.'
            : 'The results will be shown once voting closes or when the meeting begins.'
        }}
        <div v-if="canViewEarly">
          <i>
            Moderators - Only you can preview the results, your customer cannot
            see this:</i
          >
          <v-btn small @click="display">Show Me</v-btn>
        </div>
      </v-alert>
    </div>
    <v-data-table
      v-else
      :headers="headers"
      :items="question.tally"
      :sort-by="['numVotesTotal']"
      :pagination.sync="pagination"
      item-key="vote"
      hide-actions
      :class="{ 'grey-table': flatTable, 'elevation-1': !flatTable }"
    >
      <template slot="headerCell" slot-scope="props">
        {{ props.header.text }}
        <v-icon
          v-if="props.header.text === 'Eligibility'"
          small
          @click="showCandidateEligibilityDialog = true"
        >
          info
        </v-icon>
      </template>
      <template slot="items" slot-scope="props">
        <tr
          v-if="props.item.answerId > 0"
          :key="props.item.answerId"
          :class="{
            'grey lighten-2': props.item.isExcluded,
            'tie-row': isTie(props.item)
          }"
        >
          <td class="text-xs-left">
            <span :class="props.item.isExcluded ? 'strike-through' : ''">
              {{ props.item.vote }}
            </span>
            <!-- Added option REMOVAL:
              Removal button will only be visible when ALL other additional votes 
              itemized under this option have been deleted
            -->
            <v-tooltip right v-if="props.item.hasCustomEntry">
              <v-icon
                slot="activator"
                small
                color="red"
                @click="emitDeleteOption(props.item)"
                >close</v-icon
              >
              Click to remove row
            </v-tooltip>
          </td>
          <!-- TOGGLE: Candidate eligibility -->
          <td class="text-xs-left">
            <div class="fixed-width pa-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-switch
                    v-if="
                      (props.item.isCandidate && props.item.numVotesAdv) ||
                        props.item.isExcluded
                    "
                    v-model="props.item.isExcluded"
                    :false-value="true"
                    :true-value="false"
                    color="success"
                    :label="props.item.isExcluded ? 'Ineligible' : 'Eligible'"
                    :hide-details="true"
                    v-bind="attrs"
                    v-on="on"
                    @change="emitVoteExclusion(props.item.answerId)"
                  />
                </template>
                <span
                  >{{
                    props.item.isExcluded
                      ? 'Eligibility: OFF. Click to activate this choice.'
                      : 'Eligibility: ON. Click to deactivate this choice.'
                  }}
                  <v-spacer />
                  Click on <v-icon color="black" small>info</v-icon> above for
                  more informantion
                </span>
              </v-tooltip>
            </div>
          </td>

          <td class="text-xs-right">
            <div class="float-right">
              {{ formatAdvanceVote(props.item) }}
            </div>
            <!-- INDICATOR: For write-in votes -->
            <v-tooltip left v-if="props.item.isWriteIn">
              <div slot="activator" class="float-right pr-4">
                <v-icon
                  small
                  color="#ffb347"
                  @click="
                    emitViewWriteInVote(question.question_key, props.item.vote)
                  "
                  >warning</v-icon
                >
              </div>
              <span>Validate WRITE-IN votes<br />** Click icon to view **</span>
            </v-tooltip>
          </td>
          <td class="text-xs-right">
            {{ formatLiveVote(props.item) }}
          </td>
          <!-- Additional votes popover 
            Hidden if no advance question exists
          -->
          <td class="text-xs-right">
            <v-layout justify-end v-if="question.question_key">
              <additional-vote-popover
                :question-key="question.question_key"
                :vote="props.item.vote"
                :num-votes="
                  isSharesMetric
                    ? props.item.numAdditionalVotesTotal
                    : props.item.numAdditionalVotesUnitsTotal
                "
                :is-excluded="props.item.isExcluded"
                :vote-items="
                  isSharesMetric
                    ? props.item.voteItems
                    : props.item.voteItemsUnits
                "
                @add="emitSaveAdditionalVote"
                @delete="emitDeleteAdditionalVote"
              />
            </v-layout>
          </td>

          <!-- Tie Indicator -->
          <td class="text-xs-right">
            <div class="fixed-width-tie pa-0">
              <div
                v-if="isTie(props.item)"
                class="pl-3 pr-3 pb-1 pt-1 float-right tie-indicator"
              >
                <span>TIE</span>
              </div>
            </div>
          </td>

          <td class="text-xs-right">
            {{ rowTotal(props.item) }}
          </td>
        </tr>

        <!-- START: Custom vote option entry -->
        <tr
          v-if="isAdmin && props.item.newOptionKey"
          class="new-option"
          :key="props.item.newOptionKey"
        >
          <td class="text-xs-left" :colspan="headers.length - 4">
            <v-text-field
              class="line-vote"
              v-model="props.item.vote"
              placeholder="Type vote option here"
              single-line
              clearable
              @click:clear="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
              @keyup.exact="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
            />
          </td>
          <td class="text-xs-left" :colspan="headers.length - 5">
            <v-text-field
              class="line-vote"
              v-model="props.item.voteItem"
              placeholder="Description (optional)"
              single-line
              clearable
              @keyup.exact="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
            />
          </td>
          <td class="text-xs-left" :colspan="headers.length - 5">
            <v-text-field
              class="line-vote"
              v-model="props.item.total"
              placeholder="Total"
              single-line
              clearable
              type="number"
              @click:clear="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
              @keyup.exact="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
            />
          </td>
          <td class="text-xs-right">
            <v-icon
              @click="
                emitCancelOption({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
              >cancel</v-icon
            >
          </td>
        </tr>
        <!-- END: Custom vote entry -->
      </template>

      <!-- Add Option Footer - available only for live questions with advance references -->
      <template v-if="isAdmin && question.question_key" v-slot:footer>
        <tr>
          <td class="pl-0">
            <v-btn flat color="blue" @click="emitAddOption(question.id)" dark>
              <v-icon>add</v-icon>Add Option
            </v-btn>
          </td>
          <td class="text-xs-right" :colspan="headers.length - 1">
            <v-btn
              flat
              color="blue"
              dark
              @click="emitSaveOptionChangesV3(question.id)"
              :disabled="question.canSave ? false : true"
              ><v-icon small left>
                warning
              </v-icon>
              Save Changes
            </v-btn>

            <v-btn
              flat
              color="black"
              dark
              @click="emitCancelOptionChanges(question.id)"
              :disabled="question.canSave ? false : true"
            >
              Cancel Changes
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <candidate-eligibility-toggle-dialog
      :is-open="showCandidateEligibilityDialog"
      @close="showCandidateEligibilityDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import AdditionalVotePopover from '@/components/dialogs/AdditionalVotePopover';
import CandidateEligibilityToggleDialog from '@/components/dialogs/CandidateEligibilityToggleDialog';

export default {
  name: 'VoteTallyCombinedTableV3',
  components: {
    AdditionalVotePopover,
    CandidateEligibilityToggleDialog
  },
  props: {
    question: {
      type: Object,
      default() {
        return null;
      }
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default() {
        return '0';
      }
    },
    displayVoteResultsToggle: {
      type: Boolean,
      default() {
        return false;
      }
    },
    metric: {
      type: String,
      default: 'shares'
    },
    flatTable: {
      type: Boolean,
      default() {
        return false;
      }
    },
    canViewEarly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: {},
      pagination: {
        sortBy: 'numVotesTotal',
        descending: true,
        rowsPerPage: -1
      },
      showData: false,
      showCandidateEligibilityDialog: false,
      shortCode: this.$route.params.shortcode,
      rules: {
        number: value => {
          // Accept empty/undefined/null field (equivalent to zero)
          if (!value) {
            return true;
          } else {
            return !isNaN(value) || 'Invalid';
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'login']),

    arrayTotalVotes() {
      return this.question.tally.map(m => {
        if (!this.isNonCandidateOption(m.vote)) {
          return this.rowTotal(m);
        }
      });
    },
    isSharesMetric() {
      return this.metric === 'shares';
    },
    metricFormatted() {
      return `(${this.metric.toUpperCase()})`;
    },
    headers() {
      return [
        {
          text: 'Vote',
          value: 'vote',
          width: '50%'
        },
        {
          text: 'Eligibility',
          sortable: false,
          align: 'center',
          width: '1%'
        },
        {
          text: this.displayVoteResultsToggle
            ? `Advance ${this.metricFormatted}`
            : 'Advance',
          value: this.isSharesMetric ? 'numVotesAdv' : 'numUnitsAdv',
          align: 'right',
          width: '12%'
        },
        {
          text: this.displayVoteResultsToggle
            ? `Live ${this.metricFormatted}`
            : 'Live',
          value: this.isSharesMetric ? 'numVotes' : 'numUnits',
          align: 'right',
          width: '12%'
        },
        {
          text: this.displayVoteResultsToggle
            ? `Additional ${this.metricFormatted}`
            : 'Additional',
          align: 'right',
          width: '12%',
          sortable: false
        },
        {
          text: '',
          sortable: false,
          width: '1%'
        },
        {
          text: this.displayVoteResultsToggle
            ? `Total ${this.metricFormatted}`
            : 'Total',
          value: this.isSharesMetric ? 'numVotesTotal' : 'numVotesTotalUnits',
          align: 'right',
          width: '12%'
        }
      ];
    }
  },
  watch: {
    metric() {
      // if the metric changes, we need to
      // update the table's 'sortBy' property
      this.setPagination();
    }
  },
  methods: {
    // Additional Votes
    emitSaveAdditionalVote(item) {
      const payload = {
        questionKey: item.questionKey,
        vote: item.vote,
        voteItem: item.voteItem,
        createdAtName: this.login.profile?.name,
        total: item.voteItemNumOfVotes,
        metric: this.metric
      };

      this.$emit('save-additional-vote', payload);
    },
    emitDeleteAdditionalVote({ additionalVoteKey, message }) {
      // Append deletedAtName to payload
      this.$emit('delete-additional-vote', {
        additionalVoteKey,
        message,
        deletedAtName: this.login.profile?.name
      });
    },

    // Exclusions
    emitVoteExclusion(answerId) {
      const voteLineItem = _.find(this.question.tally, { answerId });
      const {
        vote: exclusion,
        numVotesAdv,
        isExcluded,
        isCandidate
      } = voteLineItem;

      // Toggle reserved for candidates with advance votes or when a vote has already been excluded
      if (isCandidate && (numVotesAdv || isExcluded)) {
        this.$emit('toggle-exclude-vote', {
          questionKey: this.question.question_key,
          exclusion
        });
        this.$forceUpdate();
      }
    },

    // Custom Options
    emitSaveOptionChangesV3(questionId) {
      let option = {
        questionId,
        createdAtName: this.login.profile?.name
      };

      this.$emit('save-option-changes-v3', option);
    },
    emitAddOption(questionId) {
      this.$emit('add-option', questionId);
    },
    emitCancelOption(params) {
      this.$emit('cancel-option', params);
    },
    emitCancelOptionChanges(questionId) {
      this.$emit('cancel-option-changes', questionId);
    },
    changeDetection(param) {
      const { questionId, answerId, newOptionKey } = param;
      let voteLineItem;
      if (answerId) {
        voteLineItem = _.find(this.question.tally, { answerId });
      } else if (newOptionKey) {
        voteLineItem = _.find(this.question.tally, { newOptionKey });
      }

      if (voteLineItem.vote && voteLineItem.total) {
        this.$emit('enable-save-changes', questionId);
      } else {
        this.$emit('enable-save-changes', null);
      }

      this.$forceUpdate();
    },
    emitDeleteOption(item) {
      let foundOptionVoteItem = item.voteItems.find(item => !item.deleted_at);

      // An added option is like any other additional vote
      // But deleting is only allowed when all other added votes are removed
      // In such a case, only 1 element will remain in the voteItems array
      this.emitDeleteAdditionalVote({
        additionalVoteKey: foundOptionVoteItem.key,
        message: ''
      });
    },

    // Write-in votes
    emitViewWriteInVote(questionKey, vote) {
      this.$emit('view-write-in-vote', { questionKey, vote });
    },

    // Misc
    getVoteOptionStatusColor(answerId) {
      const voteLineItem = _.find(this.question.tally, { answerId });
      if (voteLineItem.isExcluded) {
        return '#FD5C63'; // Red - Ineligible
      } else if (!voteLineItem.isExcluded) {
        return '#74C365'; // Green - Eligible
      }
      return '#BFC1C2';
    },

    // Display Rounded Results calculation
    formatVotes(value) {
      return this.displayRoundedResults && value
        ? parseFloat(Number(value)).toFixed(
            Number(this.roundedResultsPrecision)
          )
        : value;
    },
    formatAdvanceVote(voteOption) {
      if (voteOption.isExcluded) {
        return '-';
      }
      if (this.isSharesMetric) {
        return this.formatVotes(voteOption.numVotesAdv);
      }
      return this.formatVotes(voteOption.numUnitsAdv);
    },
    formatLiveVote(voteOption) {
      if (voteOption.isExcluded) {
        return '-';
      }
      if (this.isSharesMetric) {
        return this.formatVotes(voteOption.numVotes);
      }
      return this.formatVotes(voteOption.numUnits);
    },
    countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split('.')[1].length || 0;
    },
    maxNumberOfDigits(num1, num2, num3) {
      const val1 = num1 ? this.countDecimals(num1) : 0;
      const val2 = num2 ? this.countDecimals(num2) : 0;
      const val3 = num3 ? this.countDecimals(num3) : 0;

      const maxDigits = Math.max(val1, val2, val3);
      return Number(`1e${maxDigits}`);
    },
    rowTotal(row) {
      // In v2, this calculation was originally done in template
      // We're replicating logic here in a function instead

      // Return '-' immediately if excluded
      if (row.isExcluded) {
        return '-';
      }

      let advance, live, additional;

      if (this.isSharesMetric) {
        advance = row.numVotesAdv || 0;
        live = row.numVotes || 0;
        additional = row.numAdditionalVotesTotal || 0;
      } else {
        advance = row.numUnitsAdv || 0;
        live = row.numUnits || 0;
        additional = row.numAdditionalVotesUnitsTotal || 0;
      }

      // If no displayRoundedResults, return total
      if (!this.displayRoundedResults) {
        // parse the result to avoid rounding errors
        return parseFloat((advance + live + additional).toFixed(10));
      } else {
        // Get decimal number
        let roundedValue =
          Math.round(
            (advance + live + additional) *
              this.maxNumberOfDigits(advance + live + additional)
          ) / this.maxNumberOfDigits(advance + live + additional);

        return this.formatVotes(roundedValue);
      }
    },
    isNonCandidateOption(optionName) {
      const restrictions = [
        'Abstain',
        'I Abstain From Voting And My Proxy Shall Not Vote On This Matter',
        'Not Owner Occupied',
        'I Give My Proxy Authority To Vote',
        'I Choose Not To Vote'
      ];
      return restrictions.includes(optionName);
    },

    isTie(question) {
      if (question.isExcluded) {
        return false;
      }
      const vote = this.rowTotal(question);

      const amountOfNum = this.arrayTotalVotes.filter(votes => votes === vote);

      if (this.isNonCandidateOption(question.vote)) {
        return false;
      }

      if (amountOfNum.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    setPagination() {
      const sortBy = this.isSharesMetric
        ? 'numVotesTotal'
        : 'numVotesTotalUnits';
      this.pagination.sortBy = sortBy;
    },
    display() {
      this.$emit('showToolbar');
      this.showData = true;
    }
  }
};
</script>

<style scoped>
.tie-indicator {
  color: rgba(236, 95, 89, 1);
  background-color: rgba(246, 204, 202, 1);
  letter-spacing: 2px;
  font-weight: bold;
  border-radius: 5px;
}
.strike-through {
  text-decoration: line-through;
}
.fixed-width {
  min-width: 110px;
}
.fixed-width-tie {
  min-width: 60px;
}

.tie-row {
  background-color: #fee9ea;
  color: rgba(236, 95, 89, 1);
}

.tie-row td {
  font-weight: bold;
  font-size: 14px;
}

.grey-table .v-table.theme--light {
  background-color: transparent !important;
}
</style>
