'use strict';

/**
 * @fileoverview Vuex module for the support/chat widget
 */

import beacon from '@/lib/helpscout-beacon';

const actions = {
  /**
   * Initializes the Helpscout Beacon chat element
   */
  chatInit() {
    return beacon.init();
  },

  /** Identifies the user using the chat beacon
   * @param {Object}  user
   * @param {String}  user.name       user name
   * @param {String}  user.email      user email
   * @param {String}  user.type       user type
   * @param {String}  user.shortCode  shortCode
   */
  chatIdentify({ dispatch }, { name, email, type, shortCode } = {}) {
    if (!name || !email) {
      return;
    }

    let userName = `${name}`;

    if (type) {
      userName = `${userName} (${type})`;
    }

    if (shortCode) {
      userName = `${userName} - ${shortCode}`;
    }

    // Truncate the length of the name in case it's too long
    if (userName.length >= 40) {
      userName = userName.substring(0, 40);
    }

    return beacon.identify({ name: userName, email, jobTitle: type });
  },

  /**
   * Destroy the chat beacon
   */
  chatDestroy({ dispatch }) {
    return beacon.destroy();
  },

  // Popup a chat beacon with the messageId saved in Help Scout
  showChatMessage({ dispatch }, { messageId }) {
    return beacon.showMessage(messageId);
  },

  chatPosition({ dispatch }, { shortCode, drawerState = null }) {
    const beaconContainer = document.getElementsByClassName(
      'BeaconContainer'
    )[0];

    if (!beaconContainer) {
      return;
    }
    const isDrawerOpen = drawerState
      ? drawerState
      : localStorage.getItem(`${shortCode}-notesDrawer`) === 'true';
    if (isDrawerOpen) {
      Object.assign(beaconContainer.style, { right: '33rem' });
    } else {
      Object.assign(beaconContainer.style, { right: '40px' });
    }
  },

  toggleHelpscout({ dispatch }) {
    return beacon.toggle();
  }
};

export default {
  namespaced: true,
  actions
};
