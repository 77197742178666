var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.showData && _vm.question.is_secret)?_c('div',[_c('v-alert',{staticClass:"vote-alert",attrs:{"value":true,"type":"info","icon":"lock","color":"#607d8b"}},[_vm._v(" "+_vm._s(!_vm.canViewEarly ? 'The results will be shown once voting closes or when the meeting begins.' : 'The results will be shown once voting closes or when the meeting begins.')+" "),(_vm.canViewEarly)?_c('div',[_c('i',[_vm._v(" Moderators - Only you can preview the results, your customer cannot see this:")]),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.display}},[_vm._v("Show Me")])],1):_vm._e()])],1):_c('v-data-table',{class:{ 'grey-table': _vm.flatTable, 'elevation-1': !_vm.flatTable },attrs:{"headers":_vm.headers,"items":_vm.question.tally,"sort-by":['numVotesTotal'],"pagination":_vm.pagination,"item-key":"vote","hide-actions":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_vm._v(" "+_vm._s(props.header.text)+" "),(props.header.text === 'Eligibility')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.showCandidateEligibilityDialog = true}}},[_vm._v(" info ")]):_vm._e()]}},{key:"items",fn:function(props){return [(props.item.answerId > 0)?_c('tr',{key:props.item.answerId,class:{
          'grey lighten-2': props.item.isExcluded,
          'tie-row': _vm.isTie(props.item)
        }},[_c('td',{staticClass:"text-xs-left"},[_c('span',{class:props.item.isExcluded ? 'strike-through' : ''},[_vm._v(" "+_vm._s(props.item.vote)+" ")]),(props.item.hasCustomEntry)?_c('v-tooltip',{attrs:{"right":""}},[_c('v-icon',{attrs:{"slot":"activator","small":"","color":"red"},on:{"click":function($event){return _vm.emitDeleteOption(props.item)}},slot:"activator"},[_vm._v("close")]),_vm._v(" Click to remove row ")],1):_vm._e()],1),_c('td',{staticClass:"text-xs-left"},[_c('div',{staticClass:"fixed-width pa-0"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                    (props.item.isCandidate && props.item.numVotesAdv) ||
                      props.item.isExcluded
                  )?_c('v-switch',_vm._g(_vm._b({attrs:{"false-value":true,"true-value":false,"color":"success","label":props.item.isExcluded ? 'Ineligible' : 'Eligible',"hide-details":true},on:{"change":function($event){return _vm.emitVoteExclusion(props.item.answerId)}},model:{value:(props.item.isExcluded),callback:function ($$v) {_vm.$set(props.item, "isExcluded", $$v)},expression:"props.item.isExcluded"}},'v-switch',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.isExcluded ? 'Eligibility: OFF. Click to activate this choice.' : 'Eligibility: ON. Click to deactivate this choice.')+" "),_c('v-spacer'),_vm._v(" Click on "),_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v("info")]),_vm._v(" above for more informantion ")],1)])],1)]),_c('td',{staticClass:"text-xs-right"},[_c('div',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.formatAdvanceVote(props.item))+" ")]),(props.item.isWriteIn)?_c('v-tooltip',{attrs:{"left":""}},[_c('div',{staticClass:"float-right pr-4",attrs:{"slot":"activator"},slot:"activator"},[_c('v-icon',{attrs:{"small":"","color":"#ffb347"},on:{"click":function($event){return _vm.emitViewWriteInVote(_vm.question.question_key, props.item.vote)}}},[_vm._v("warning")])],1),_c('span',[_vm._v("Validate WRITE-IN votes"),_c('br'),_vm._v("** Click icon to view **")])]):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.formatLiveVote(props.item))+" ")]),_c('td',{staticClass:"text-xs-right"},[(_vm.question.question_key)?_c('v-layout',{attrs:{"justify-end":""}},[_c('additional-vote-popover',{attrs:{"question-key":_vm.question.question_key,"vote":props.item.vote,"num-votes":_vm.isSharesMetric
                  ? props.item.numAdditionalVotesTotal
                  : props.item.numAdditionalVotesUnitsTotal,"is-excluded":props.item.isExcluded,"vote-items":_vm.isSharesMetric
                  ? props.item.voteItems
                  : props.item.voteItemsUnits},on:{"add":_vm.emitSaveAdditionalVote,"delete":_vm.emitDeleteAdditionalVote}})],1):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_c('div',{staticClass:"fixed-width-tie pa-0"},[(_vm.isTie(props.item))?_c('div',{staticClass:"pl-3 pr-3 pb-1 pt-1 float-right tie-indicator"},[_c('span',[_vm._v("TIE")])]):_vm._e()])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.rowTotal(props.item))+" ")])]):_vm._e(),(_vm.isAdmin && props.item.newOptionKey)?_c('tr',{key:props.item.newOptionKey,staticClass:"new-option"},[_c('td',{staticClass:"text-xs-left",attrs:{"colspan":_vm.headers.length - 4}},[_c('v-text-field',{staticClass:"line-vote",attrs:{"placeholder":"Type vote option here","single-line":"","clearable":""},on:{"click:clear":function($event){return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })},"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })}},model:{value:(props.item.vote),callback:function ($$v) {_vm.$set(props.item, "vote", $$v)},expression:"props.item.vote"}})],1),_c('td',{staticClass:"text-xs-left",attrs:{"colspan":_vm.headers.length - 5}},[_c('v-text-field',{staticClass:"line-vote",attrs:{"placeholder":"Description (optional)","single-line":"","clearable":""},on:{"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })}},model:{value:(props.item.voteItem),callback:function ($$v) {_vm.$set(props.item, "voteItem", $$v)},expression:"props.item.voteItem"}})],1),_c('td',{staticClass:"text-xs-left",attrs:{"colspan":_vm.headers.length - 5}},[_c('v-text-field',{staticClass:"line-vote",attrs:{"placeholder":"Total","single-line":"","clearable":"","type":"number"},on:{"click:clear":function($event){return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })},"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })}},model:{value:(props.item.total),callback:function ($$v) {_vm.$set(props.item, "total", $$v)},expression:"props.item.total"}})],1),_c('td',{staticClass:"text-xs-right"},[_c('v-icon',{on:{"click":function($event){return _vm.emitCancelOption({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })}}},[_vm._v("cancel")])],1)]):_vm._e()]}},(_vm.isAdmin && _vm.question.question_key)?{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"pl-0"},[_c('v-btn',{attrs:{"flat":"","color":"blue","dark":""},on:{"click":function($event){return _vm.emitAddOption(_vm.question.id)}}},[_c('v-icon',[_vm._v("add")]),_vm._v("Add Option ")],1)],1),_c('td',{staticClass:"text-xs-right",attrs:{"colspan":_vm.headers.length - 1}},[_c('v-btn',{attrs:{"flat":"","color":"blue","dark":"","disabled":_vm.question.canSave ? false : true},on:{"click":function($event){return _vm.emitSaveOptionChangesV3(_vm.question.id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" warning ")]),_vm._v(" Save Changes ")],1),_c('v-btn',{attrs:{"flat":"","color":"black","dark":"","disabled":_vm.question.canSave ? false : true},on:{"click":function($event){return _vm.emitCancelOptionChanges(_vm.question.id)}}},[_vm._v(" Cancel Changes ")])],1)])]},proxy:true}:null],null,true)}),_c('candidate-eligibility-toggle-dialog',{attrs:{"is-open":_vm.showCandidateEligibilityDialog},on:{"close":function($event){_vm.showCandidateEligibilityDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }