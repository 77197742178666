var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sortedItems,"pagination":_vm.pagination,"item-key":`table-key-${_vm.question.id}`,"hide-actions":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [(props.item.answerId > 0)?_c('tr',{key:props.item.answerId,staticClass:"hover-row",class:_vm.getRowClass(props.item)},[(_vm.showWinnerStatus)?_c('td',{staticClass:"text-xs-left"},[(!props.item.isExcluded)?_c('v-tooltip',{attrs:{"right":""}},[_c('v-icon',{class:props.item.isCurrentQuestionWinner ||
              props.item.isPreviousQuestionWinner
                ? ''
                : 'winner-icon',attrs:{"slot":"activator","disabled":props.item.isPreviousQuestionWinner},on:{"click":function($event){return _vm.emitToggleWinner(props.item.answerId)}},slot:"activator"},[_vm._v("emoji_events")]),_c('span',[_vm._v("WINNER STATUS / CLICK TO TOGGLE")])],1):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(_vm.formatVoteLabel(props.item.vote))+" "),(
            props.item.hasCustomEntry &&
              !props.item.numVotesAdv &&
              !props.item.numVotes
          )?_c('v-tooltip',{attrs:{"right":""}},[_c('v-icon',{staticClass:"pl-6",attrs:{"slot":"activator","small":"","color":"red"},on:{"click":function($event){return _vm.emitDeleteOption(_vm.question.question_key, props.item.vote)}},slot:"activator"},[_vm._v("close")]),_c('span',[_vm._v("Paper vote summary"),_c('br'),_vm._v("** Click to remove row **")])],1):_vm._e()],1),_c('td',{staticClass:"text-xs-center"},[(
            (props.item.isCandidate || props.item.isExcluded) &&
              !props.item.isCurrentQuestionWinner &&
              !props.item.isPreviousQuestionWinner
          )?_c('v-tooltip',{attrs:{"bottom":""}},[_c('v-btn',{staticClass:"pl-2 pr-2",attrs:{"slot":"activator","small":"","outline":"","depressed":"","color":_vm.getVoteOptionStatusColor(props.item.answerId)},on:{"click":function($event){return _vm.emitVoteExclusion(props.item.answerId)}},slot:"activator"},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(_vm._s(props.item.isExcluded ? 'thumb_down_off_alt' : 'thumb_up_off_alt'))]),_vm._v(" "+_vm._s(props.item.isExcluded ? 'INELIGIBLE' : 'ELIGIBLE')+" ")],1),_c('span',[_vm._v(" "+_vm._s(props.item.isExcluded ? 'INELIGIBLE' : 'ELIGIBLE')+"  CANDIDATE"),_c('br'),_vm._v("** Click to toggle status **")])],1):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_c('div',{staticClass:"float-right"},[_vm._v(" "+_vm._s(props.item.isExcluded || props.item.isPreviousQuestionWinner ? '-' : _vm.formatVotes(props.item.numVotesAdv))+" ")]),(props.item.isWriteIn)?_c('v-tooltip',{attrs:{"left":""}},[_c('div',{staticClass:"float-right pr-4",attrs:{"slot":"activator"},slot:"activator"},[_c('v-icon',{attrs:{"small":"","color":"#ffb347"},on:{"click":function($event){return _vm.emitViewWriteInVote(_vm.question.question_key, props.item.vote)}}},[_vm._v("warning")])],1),_c('span',[_vm._v("Validate WRITE-IN votes"),_c('br'),_vm._v("** Click icon to view **")])]):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item.isExcluded || props.item.isPreviousQuestionWinner ? '-' : _vm.formatVotes(props.item.numVotes))+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('div',{staticClass:"float-right"},[(_vm.question.question_key)?_c('v-text-field',{attrs:{"single-line":"","rules":[_vm.rules.number],"disabled":props.item.isPreviousQuestionWinner},on:{"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
                questionId: _vm.question.id,
                answerId: props.item.answerId
              })}},model:{value:(props.item.numVotesPaperEdit),callback:function ($$v) {_vm.$set(props.item, "numVotesPaperEdit", $$v)},expression:"props.item.numVotesPaperEdit"}}):_vm._e()],1),_c('v-tooltip',{attrs:{"left":""}},[_c('div',{staticClass:"float-right pt-4 pr-2",attrs:{"slot":"activator"},slot:"activator"},[(_vm.hasChanged(_vm.question, props.item.answerId))?_c('v-icon',{staticClass:"pl-1",attrs:{"size":"14px","color":"primary"}},[_vm._v("warning")]):_vm._e()],1),_c('span',[_vm._v("Unsaved change")])])],1),_c('td',{staticClass:"text-xs-right pr-0"},[(
            _vm.isTie(props.item) &&
              !props.item.isCurrentQuestionWinner &&
              !props.item.isPreviousQuestionWinner
          )?_c('div',{staticClass:"pl-3 pr-3 pb-1 pt-1 float-right tie-indicator"},[_c('span',[_vm._v("TIE")])]):_vm._e()]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item.isExcluded || props.item.isPreviousQuestionWinner ? '-' : _vm.voteTotal(props.item))+" ")])]):_vm._e(),(props.item.newOptionKey)?_c('tr',{key:props.item.newOptionKey,staticClass:"new-option"},[(_vm.showWinnerStatus)?_c('td'):_vm._e(),_c('td',{staticClass:"text-xs-left",attrs:{"colspan":_vm.showWinnerStatus ? _vm.headers.length - 4 : _vm.headers.length - 3}},[_c('v-text-field',{staticClass:"line-vote",attrs:{"placeholder":"Type vote option here","single-line":"","clearable":""},on:{"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
              questionId: _vm.question.id,
              newOptionKey: props.item.newOptionKey
            })}},model:{value:(props.item.vote),callback:function ($$v) {_vm.$set(props.item, "vote", $$v)},expression:"props.item.vote"}})],1),_c('td',{staticClass:"text-xs-right"},[_c('div',{attrs:{"align":"right"}},[_c('v-text-field',{attrs:{"single-line":"","rules":[_vm.rules.number]},on:{"keyup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeDetection({
                questionId: _vm.question.id,
                newOptionKey: props.item.newOptionKey
              })}},model:{value:(props.item.numVotesPaperEdit),callback:function ($$v) {_vm.$set(props.item, "numVotesPaperEdit", $$v)},expression:"props.item.numVotesPaperEdit"}})],1)]),_c('td',{staticClass:"text-xs-right",attrs:{"colspan":2}},[_c('v-icon',{on:{"click":function($event){return _vm.emitCancelOption(_vm.question.id, props.item.newOptionKey)}}},[_vm._v("cancel")])],1)]):_vm._e()]}},(_vm.question.question_key)?{key:"footer",fn:function(){return [_c('tr',[(_vm.showWinnerStatus)?_c('td'):_vm._e(),_c('td',{staticClass:"pl-0"},[_c('v-btn',{attrs:{"flat":"","color":"blue","dark":""},on:{"click":function($event){return _vm.emitAddOption(_vm.question.id)}}},[_c('v-icon',[_vm._v("add")]),_vm._v("Add Option ")],1)],1),_c('td',{staticClass:"text-xs-right",attrs:{"colspan":_vm.headers.length - 1}},[_c('v-btn',{attrs:{"flat":"","color":"blue","dark":"","disabled":_vm.question.canSave ? false : true},on:{"click":function($event){return _vm.emitSaveAllChanges(_vm.question.id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" warning ")]),_vm._v(" Save Changes ")],1),_c('v-btn',{attrs:{"flat":"","color":"black","dark":"","disabled":_vm.question.canSave ? false : true},on:{"click":function($event){return _vm.emitCancelAllChanges(_vm.question.id)}}},[_vm._v(" Cancel Changes ")])],1)])]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }