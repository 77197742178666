'use strict';

/**
 * @fileoverview Vuex module for meeting proxies
 */

import _ from 'lodash';

import {
  getMeetingProxyList,
  getProxyAnswers,
  revokeMeetingProxy,
  verifyMeetingProxy,
  deleteMeetingPaperProxy,
  saveMeetingPaperProxies,
  listMeetingProxyWriteIns
} from '@/api/meetings-proxies';

import { listMeetingProxyHolders } from '@/api/meetings';

const state = {
  meetingProxyList: {},
  meetingWriteInProxyList: {},
  meetingProxyHoldersList: [],
  dataLastFetchedAt: null,
  proxyQuestions: [],
  filters: {
    statuses: [],
    sources: [],
    boxes: []
  },
  filterLabels: {
    verified: 'Verified',
    unverified: 'Unverified',
    revoked: 'Revoked',
    user: 'Submitted By: User',
    voter: 'Submitted By: Voter',
    admin: 'Submitted By: Admin',
    'box-1': 'Box 1',
    'box-2': 'Box 2',
    'box-3': 'Box 3'
  },
  STATUSES: ['verified', 'unverified', 'revoked'],
  SOURCES: ['user', 'voter', 'admin'],
  BOXES: ['box-1', 'box-2', 'box-3', 'non']
};

const getters = {
  meetingProxyList: state => state.meetingProxyList,
  meetingProxyListData: state => Object.values(state.meetingProxyList),
  meetingWriteInProxyList: state => state.meetingWriteInProxyList,
  meetingWriteInProxyListData: state =>
    Object.values(state.meetingWriteInProxyList),
  meetingProxyHoldersList: state => state.meetingProxyHoldersList,
  proxyQuestions: state => state.proxyQuestions,
  filterStatuses: state => state.filters.statuses,
  filterSources: state => state.filters.sources,
  filterBoxes: state => state.filters.boxes,
  allFilters: state =>
    state.filters.statuses
      .concat(state.filters.sources)
      .concat(state.filters.boxes),
  labelByFilter: state => filterName => state.filterLabels[filterName],
  STATUSES: state => state.STATUSES,
  SOURCES: state => state.SOURCES,
  BOXES: state => state.BOXES
};

const actions = {
  /**
   * Get the list of proxies for this meeting and set it into an array store.
   * @param  {String}   {shortCode}         meeting code
   * @param  {String}   {sortBy}            field to sort by
   * @param  {String}   {sortDir}           'asc' or 'desc' for sort direction
   * @param  {String[]} {includeStatuses}   array of statuses to include
   * @param  {String[]} {includeSources}    array of sources to include
   */
  async getMeetingProxyList(
    { commit },
    {
      shortCode,
      sortBy,
      sortDir,
      includeStatuses,
      includeSources,
      includeBoxes
    }
  ) {
    try {
      const sort = sortBy || 'created_at';
      const direction = sortDir || 'desc';

      const res = await getMeetingProxyList({
        shortCode,
        sortBy: sort,
        sortDir: direction,
        includeStatuses,
        includeSources,
        includeBoxes
      });
      return commit('SET_MEETING_PROXY_LIST', res.data);
    } catch (err) {
      console.error('Error: getMeetingProxyList action', err);
      throw err;
    }
  },

  /**
   * Revoke a meeting proxy
   * @param  {String} {shortCode}  meeting code
   * @param  {String} {proxyKey}   the proxy key
   */
  async revokeMeetingProxy({ commit }, { shortCode, proxyKey }) {
    try {
      await revokeMeetingProxy(shortCode, proxyKey);
      return commit('SET_MEETING_PROXY_IN_LIST_REVOKED', proxyKey);
    } catch (err) {
      console.error('Error: revokeMeetingProxy action', err);
      throw err;
    }
  },

  /**
   * Verify a meeting proxy
   * @param  {String} {shortCode}  meeting code
   * @param  {String} {proxyKey}   the proxy key
   */
  async verifyMeetingProxy({ commit }, { shortCode, proxyKey }) {
    try {
      await verifyMeetingProxy(shortCode, proxyKey);
      return commit('SET_MEETING_PROXY_IN_LIST_VERIFIED', proxyKey);
    } catch (err) {
      console.error('Error: verifyMeetingProxy action', err);
      throw err;
    }
  },

  /**
   * Delete a saved paper proxy vote entry
   * @param  {String} {shortCode}           meeting code
   * @param  {String} {questionKey}         the advance question key ref
   * @param  {String} {vote}                the vote option
   * @param  {String} {liveQuestionKey}     the live question key
   */
  async deleteMeetingPaperProxy(
    { dispatch },
    { shortCode, questionKey, vote, liveQuestionKey }
  ) {
    try {
      return await deleteMeetingPaperProxy({
        shortCode,
        questionKey,
        vote,
        liveQuestionKey
      });
    } catch (err) {
      console.error('Error: deleteMeetingPaperProxy action', err);
      throw err;
    }
  },

  /**
   * Save paper proxy vote entries
   * @param  {String} {shortCode}     meeting code
   * @param  {String} {questionKey}   the advance question key ref
   * @param  {Object} {paperProxies}  the question object containing the required updates
   * @param  {String} {liveQuestionKey}  live question key
   */
  async saveMeetingPaperProxies(
    { dispatch },
    { shortCode, questionKey, paperProxies, liveQuestionKey }
  ) {
    try {
      return await saveMeetingPaperProxies({
        shortCode,
        questionKey,
        paperProxies,
        liveQuestionKey
      });
    } catch (err) {
      console.error('Error: saveMeetingPaperProxies action', err);
      throw err;
    }
  },

  /**
   * Find and list submitted proxies containing the target vote value
   * @param  {String} {shortCode}     meeting code
   * @param  {String} {questionKey}   the advance question key ref
   * @param  {String} {vote}          the target write in vote value
   */
  async listMeetingProxyWriteIns({ commit }, { shortCode, questionKey, vote }) {
    try {
      const res = await listMeetingProxyWriteIns({
        shortCode,
        questionKey,
        vote
      });
      return commit('SET_MEETING_WRITE_IN_PROXY_LIST', res.data);
    } catch (err) {
      console.error('Error: listMeetingProxyWriteIns action', err);
      throw err;
    }
  },

  /**
   * Revoke a meeting proxy with write-in vote
   * @param  {String} {shortCode}  meeting code
   * @param  {String} {proxyKey}   the proxy key
   */
  async revokeMeetingWriteInProxy({ commit }, { shortCode, proxyKey }) {
    try {
      await revokeMeetingProxy(shortCode, proxyKey);
      return commit('SET_MEETING_WRITE_IN_PROXY_LIST_REVOKED', proxyKey);
    } catch (err) {
      console.error('Error: revokeMeetingProxy action', err);
      throw err;
    }
  },

  /**
   * Get the list of proxy holders for this meeting.
   * @param  {String}   shortCode         - meeting shortcode
   */
  async listMeetingProxyHolders({ commit }, { shortCode }) {
    try {
      const res = await listMeetingProxyHolders({ shortCode });
      return commit('SET_MEETING_PROXY_HOLDER_LIST', res.data);
    } catch (err) {
      console.error('Error: listMeetingProxyHolders action', err);
      throw err;
    }
  },

  /**
   * Get a proxys questions and answers.
   * @param  {String} {shortCode}  meeting code
   * @param  {String} {proxyKey}   the proxy key
   */
  async getProxyAnswers({ commit }, { shortCode, proxyKey }) {
    try {
      const res = await getProxyAnswers({
        shortCode,
        proxyKey
      });

      return commit('SET_MEETING_PROXY_QUESTIONS', res.data);
    } catch (err) {
      console.error('Error: getProxyAnswers action', err);
      throw err;
    }
  },

  /**
   * Clear a proxy filter
   */
  clearFilter({ commit, state }, { filterName }) {
    const isStatusFilter = state.STATUSES.includes(filterName);
    const isSourceFilter = state.SOURCES.includes(filterName);
    const isBoxesFilter = state.BOXES.includes(filterName);

    if (isStatusFilter) {
      commit('CLEAR_PROXY_FILTER', {
        filterType: 'statuses',
        filterName
      });
    } else if (isSourceFilter) {
      commit('CLEAR_PROXY_FILTER', {
        filterType: 'sources',
        filterName
      });
    } else if (isBoxesFilter) {
      commit('CLEAR_PROXY_FILTER', {
        filterType: 'boxes',
        filterName
      });
    } else {
      console.log('Error: clearFilter action - unknown filterName');
    }
  },

  /**
   * Clear all proxy filters
   */
  clearFilters({ commit }) {
    commit('CLEAR_FILTERS');
  }
};

const mutations = {
  SET_MEETING_PROXY_LIST(state, dataArray) {
    state.meetingProxyList = _.keyBy(dataArray, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_MEETING_PROXY_IN_LIST_REVOKED(state, proxyKey) {
    state.meetingProxyList[proxyKey].status = 'revoked';
    state.meetingProxyList[proxyKey].revokedOn = new Date();
  },

  SET_MEETING_PROXY_IN_LIST_VERIFIED(state, proxyKey) {
    state.meetingProxyList[proxyKey].status = 'verified';
    state.meetingProxyList[proxyKey].verifiedOn = new Date();
    state.meetingProxyList[proxyKey].verifiedBy = 'You';
  },

  SET_MEETING_WRITE_IN_PROXY_LIST(state, dataArray) {
    state.meetingWriteInProxyList = _.keyBy(dataArray, 'key');
  },

  SET_MEETING_WRITE_IN_PROXY_LIST_REVOKED(state, proxyKey) {
    state.meetingWriteInProxyList[proxyKey].status = 'revoked';
    state.meetingWriteInProxyList[proxyKey].revokedOn = new Date();
  },

  SET_MEETING_PROXY_HOLDER_LIST(state, proxyHolders) {
    state.meetingProxyHoldersList = proxyHolders;
  },

  SET_MEETING_PROXY_QUESTIONS(state, dataArray) {
    state.proxyQuestions = dataArray;
  },

  CLEAR_PROXY_FILTER(state, { filterType, filterName }) {
    const index = state.filters[filterType].indexOf(filterName);
    state.filters[filterType].splice(index, 1);
  },

  CLEAR_FILTERS(state) {
    state.filters.statuses = [];
    state.filters.sources = [];
    state.filters.boxes = [];
  },

  CLEAR_STATE(state) {
    state.meetingProxyList = {};
    state.meetingWriteInProxyList = {};
    state.dataLastFetchedAt = null;
    state.proxyQuestions = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
