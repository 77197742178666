<template>
  <v-container class="mt-0 pt-0">
    <meeting-rtv-live-answers />
  </v-container>
</template>

<script>
import MeetingRtvLiveAnswers from '@/views/meetings/meeting/rtv-live-answers/MeetingRTVLiveAnswersView';

export default {
  name: 'MeetingTallyDataView',
  components: {
    MeetingRtvLiveAnswers
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
