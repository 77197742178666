<template>
  <v-card>
    <div class="white--text gq-blue-background" height="250px">
      <v-container fill-height fluid>
        <v-layout fill-height>
          <v-flex xs11 align-end flexbox>
            <h2 class="capitalize">
              {{ currentUser.name }}
              <user-status-chip :status="currentUser.status" />
            </h2>
            <h4>{{ currentUser.email }} - {{ currentUser.type }}</h4>
            <h4>Logged in {{ lastLoggedInAtFormatted }}</h4>
            <h4>{{ currentUser.logins }} total logins</h4>
            <br />
            <p>
              <b>Two Factor Authentication:</b>
              <v-chip small :color="mfaStatusColor" class="white--text">
                {{ mfaStatusText }}
              </v-chip>
            </p>
            <p>
              Updated:
              {{ currentUser.updatedAt | formatDateHumanReadable(true) }}
              <br />
              Created:
              {{ currentUser.createdAt | formatDateHumanReadable(true) }}

              <span v-if="isDisabled"
                ><br />Disabled:
                {{
                  currentUser.disabledAt | formatDateHumanReadable(true)
                }}</span
              >
            </p>
          </v-flex>
          <v-flex class="text-xs-right" xs1>
            <v-menu offset-y>
              <v-icon color="white" slot="activator">menu</v-icon>
              <v-list>
                <!-- deprecated -->
                <!-- <v-list-tile @click.native="$emit('set-password')">
                  <v-list-tile-avatar>
                    <v-icon>vpn_key</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>Set new password</v-list-tile-title>
                </v-list-tile> -->
                <v-list-tile
                  v-if="!isDisabled"
                  @click.native="$emit('send-reset-email')"
                >
                  <v-list-tile-avatar>
                    <v-icon>mail</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>Send password reset</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-if="!isDisabled"
                  @click.native="$emit('send-welcome-email')"
                >
                  <v-list-tile-avatar>
                    <v-icon>how_to_reg</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>Resend welcome email</v-list-tile-title>
                </v-list-tile>

                <!-- Pause Two Factor -->
                <v-list-tile
                  v-if="isMfaEnabled"
                  @click.native="$emit('pause-mfa')"
                >
                  <v-list-tile-avatar>
                    <v-icon>pause</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Pause Two-Factor Auth (24 hrs)
                  </v-list-tile-title>
                </v-list-tile>

                <!-- Enable Two Factor -->
                <v-list-tile
                  v-if="!isMfaEnabled"
                  @click.native="$emit('enable-mfa')"
                >
                  <v-list-tile-avatar>
                    <v-icon>lock</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Enable Two-Factor Auth
                  </v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  v-if="!isDisabled"
                  @click.native="$emit('suspend-user')"
                >
                  <v-list-tile-avatar>
                    <v-icon>block</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>Suspend this user</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-if="isDisabled"
                  @click.native="$emit('reactivate-user')"
                >
                  <v-list-tile-avatar>
                    <v-icon>play_arrow</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>Reactivate user</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row>
          <v-flex xs12>
            <v-form v-model="valid">
              <v-text-field
                label="Username"
                v-model="state.username"
                :rules="rules.username"
                required
              />

              <v-text-field
                label="Email"
                type="email"
                v-model="state.email"
                :rules="rules.email"
                required
              />

              <v-text-field
                label="First Name"
                v-model="state.firstName"
                :rules="rules.firstName"
                required
              />

              <v-text-field
                label="Last Name"
                v-model="state.lastName"
                :rules="rules.lastName"
                required
              />

              <v-checkbox
                label="Mark this user as an active moderator"
                v-model="state.activeModerator"
              >
              </v-checkbox>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions class="text-xs-right">
      <v-spacer />
      <v-btn flat @click="setState" :disabled="!isStateChanged" color="primary"
        >Undo</v-btn
      >
      <v-btn
        flat
        @click="submitUserChange"
        :disabled="!isStateChanged"
        color="primary"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import validator from 'email-validator';
import { mapActions } from 'vuex';
import { dateFromNow, formatDateHumanReadable } from '@/filters';

import UserStatusChip from '@/components/UserStatusChip';

export default {
  name: 'UserCard',
  components: {
    UserStatusChip
  },
  props: {
    userKey: {
      type: String,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  filters: {
    dateFromNow,
    formatDateHumanReadable
  },
  data() {
    return {
      valid: false,
      state: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        type: '',
        lastLoggedInAt: '',
        logins: 0,
        createdAt: '',
        updatedAt: '',
        activeModerator: false
      },
      rules: {
        username: [v => !!v || 'Username is required'],
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ],
        firstName: [v => !!v || 'First name is required'],
        lastName: [v => !!v || 'Last name is required']
      }
    };
  },
  computed: {
    lastLoggedInAtFormatted() {
      return this.currentUser.lastLoggedInAt
        ? dateFromNow(this.currentUser.lastLoggedInAt)
        : 'never';
    },
    isStateChanged() {
      return !_.isEqual(this.state, this.currentUser);
    },
    isDisabled() {
      return this.currentUser.status === 'disabled';
    },
    isLoggedIn() {
      return !!this.currentUser.lastLoggedInAt;
    },
    isMfaEnabled() {
      return (
        this.currentUser.mfaEnabledAt &&
        moment(this.currentUser.mfaEnabledAt).isSameOrBefore()
      );
    },
    mfaStatus() {
      if (!this.currentUser.mfaEnabledAt) {
        return 'off';
      } else if (moment(this.currentUser.mfaEnabledAt).isAfter()) {
        return 'temp_off';
      } else {
        return 'on';
      }
    },
    mfaStatusText() {
      switch (this.mfaStatus) {
        case 'off':
          return 'Off';
        case 'temp_off':
          return `Paused for ${moment(this.currentUser.mfaEnabledAt).fromNow(
            true
          )}`;
        default:
          return 'Enabled';
      }
    },
    mfaStatusColor() {
      switch (this.mfaStatus) {
        case 'off':
          return 'red';
        case 'temp_off':
          return 'amber darken-2';
        default:
          return 'green';
      }
    }
  },
  watch: {
    currentUser: function() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('users', ['updateUser']),
    async submitUserChange() {
      try {
        await this.updateUser({ userKey: this.userKey, user: this.state });
        this.$events.$emit('toastEvent', 'User Info Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    updateField(key, value) {
      this.state[key] = value;
    },
    setState() {
      this.state = _.cloneDeep(this.currentUser);
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>
