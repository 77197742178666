<template>
  <v-layout
    row
    wrap
    class="ma-3">
    <v-flex
      xs12
      sm6
      md3>
      <v-card>
        <v-card-text>
          <v-layout row>
            <v-flex xs12><span class="display-1">{{ formatValue(amountOwing) }}</span></v-flex>
          </v-layout>
          <span class="caption">Due <br> ({{ currency }})</span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      sm6
      md3>
      <v-card>
        <v-card-text>
          <v-layout row>
            <v-flex xs12><span class="display-1 capitalize">{{ status }}</span></v-flex>
          </v-layout>
          <span
            class="caption"
            v-if="sentAt">
            {{ formatDate(sentAt) }} <br> To: {{ sentTo }} 
            <v-tooltip 
              bottom
              v-if="hasSentViews">
              <span slot="activator"><v-icon 
                small
                color="green">visibility_on</v-icon></span>
              <span v-html="viewedListStr"/>
            </v-tooltip>
          </span>
          <span
            class="caption"
            v-if="!sentAt">Status <br><br></span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      sm6
      md3>
      <v-card>
        <v-card-text>
          <v-layout row>
            <v-flex xs12><span class="display-1">{{ formatValue(amountPaid) }}</span></v-flex>
          </v-layout>
          <span class="caption">Total Paid <br> ({{ currency }})</span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      sm6
      md3>
      <v-card>
        <v-card-text>
          <v-layout row>
            <v-flex xs12><span class="display-1">{{ formatValue(totalPrice) }}</span></v-flex>
          </v-layout>
          <span class="caption">Total Price <br> ({{ currency }})</span>
        </v-card-text>
      </v-card>
    </v-flex>

  </v-layout>
</template>

<script>
import moment from 'moment';

import {formatCurrencyValue} from '@/helpers';

export default {
  name: 'AdminInvoiceViewSummaryCards',
  props: {
    totalPrice: {type: Number, default: 0},
    amountOwing: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    sentTo: {type: String, default: ''},
    sentAt: {type: [String, Date], default: ''},
    sentViews: {
      type: Array,
      default() {
        return [];
      }
    },
    hasSentViews: {type: Boolean, default: false},
    isPaid: {type: Boolean, default: false}
  },
  computed: {
    amountPaid() {
      return this.totalPrice - this.amountOwing;
    },
    viewedList() {
      return this.sentViews
        .map(v => {
          const isManual = v.action === 'Manual mark';
          return isManual
            ? `${this.formatDateTime(v.createdAt)} (Manual)`
            : this.formatDateTime(v.createdAt);
        })
        .join('<br>');
    },
    viewedListStr() {
      return `Opened on: <br>${this.viewedList}`;
    }
  },
  methods: {
    formatValue(value) {
      return formatCurrencyValue(value);
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    formatDateTime(date) {
      return moment(date).format('MMM DD, YYYY HH:mm');
    }
  }
};
</script>

<style scoped>
.sent-icon {
  width: 100%;
  padding-top: 4px;
}
</style>
