<template>
  <v-dialog persistent :value="isOpen" max-width="500px">
    <v-card>
      <!-- Join Portal email -->
      <v-card-text v-if="reminderType === 'join'">
        <div>The <b>Join Meeting Portal</b> email will be sent:</div>

        <div>
          <v-text-field
            v-model="startingReminder"
            type="number"
            suffix="mins before meeting"
            min="0"
            max="1440"
          />
        </div>

        <v-alert class="mt-3" :value="true" outline color="info">
          <v-layout>
            <v-flex>
              <div class="mb-1">
                Based on this setting, the Join Portal notice will be sent on:
              </div>
              <li>
                {{ joinPortalDate | dateFormatReadable(true, meetingTimezone) }}
              </li>
            </v-flex>
          </v-layout>
        </v-alert>
      </v-card-text>

      <!-- Reminder email(s) -->
      <v-card-text v-else>
        <div>
          Reminders will be automatically sent every:
        </div>

        <div>
          <v-text-field
            v-model="frequency"
            type="number"
            suffix="days"
            min="0"
            max="30"
          />
        </div>

        <v-alert class="mt-3" :value="true" outline color="info">
          <v-layout>
            <v-flex>
              <div class="mb-1">
                Reminders will be sent on:
              </div>
              <li v-for="(reminder, index) in reminderList" :key="index">
                <span>{{ reminder | dateFormatReadable }}</span>
                <span v-if="isWeekend(reminder)" class="ml-2"
                  ><v-tooltip right
                    ><v-icon slot="activator" small color="warning"
                      >info_outline</v-icon
                    ><span>This date is a weekend.</span></v-tooltip
                  ></span
                >
              </li>
              <div class="mt-1">
                All reminders will be sent between 7am - 3pm
                {{ timezoneAbbreviation }}.
              </div>
            </v-flex>
          </v-layout>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">close</v-btn>
        <v-btn
          color="primary"
          :disabled="isDisabled"
          @click.native="handleUpdate"
          >update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { dateFormatReadable } from '@/filters';
import getReminderDates from '@/lib/get-auto-reminder-dates';

export default {
  name: 'ReminderDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    currentMeeting: {
      type: Object,
      required: true
    },
    reminderSettings: {
      type: Object,
      default() {
        return {};
      }
    },
    reminderType: {
      type: String,
      default: ''
    }
  },
  filters: {
    dateFormatReadable
  },
  data() {
    return {
      frequency: null,
      startingReminder: null
    };
  },
  computed: {
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    timezoneAbbreviation() {
      return moment()
        .tz(this.meetingTimezone)
        .format('z');
    },
    reminderList() {
      if (!this.frequency) {
        return [];
      }

      const reminderStartDate = this.reminderSettings.reminderStartDate;
      const reminderFrequency = this.frequency;
      const meetingDate = this.currentMeeting.meetingDate;

      return getReminderDates(
        reminderStartDate,
        reminderFrequency,
        meetingDate
      );
    },
    joinPortalDate() {
      if (!this.startingReminder) {
        return null;
      }
      return moment(this.currentMeeting.meetingDate).subtract(
        this.startingReminder,
        'minutes'
      );
    },
    isDisabled() {
      if (this.reminderType === 'join') {
        return this.startingReminder === this.reminderSettings.joinReminder;
      } else {
        return this.frequency === this.reminderSettings.reminderFrequency;
      }
    }
  },
  watch: {
    isOpen() {
      this.frequency = this.reminderSettings.reminderFrequency;
      this.startingReminder = this.reminderSettings.joinReminder;
    }
  },
  methods: {
    isWeekend(date) {
      let day = moment(date).format('ddd');
      return day === 'Sat' || day === 'Sun';
    },
    handleClose() {
      this.$emit('close');
    },
    handleUpdate() {
      const payload = {
        meetingReminderFrequency:
          this.reminderType === 'join'
            ? this.reminderSettings.reminderFrequency
            : this.frequency,
        meetingStartingReminder:
          this.reminderType === 'join'
            ? this.startingReminder
            : this.reminderSettings.joinReminder
      };

      this.$emit('update', payload);
    }
  }
};
</script>

<style scoped></style>
