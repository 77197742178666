'use strict';

import _ from 'lodash';

/**
 * @fileoverview Helper functions for Invoices
 */

/**
 * Sum of total price of invoices by key
 *
 * @param  {Array}  invoices  - the list of invoices
 * @param  {String} key       - the property to total on
 * @return {Number}           - the total amount
 */
export function getInvoicesTotal(invoices, key) {
  if (!invoices || !Array.isArray(invoices) || invoices.length === 0) {
    return 0;
  }
  return invoices.map(inv => inv[key]).reduce((a, b) => a + b);
}

/**
 * Gets the status of the invoices.  If one invoice is a draft, then status
 * of draft will be returned
 *
 * @param  {Array}   invoices     - the list of invoices
 * @param  {NUmber}  amountOwing  - amount owing
 * @return {String}               - the status
 */
export function getInvoicesStatus(invoices, amountOwing) {
  if (!invoices || !Array.isArray(invoices) || invoices.length === 0) {
    return null;
  }
  const statuses = invoices.map(inv => inv.status);
  const status = statuses.includes('draft') ? 'draft' : 'sent';
  if (status === 'sent' && amountOwing <= 0) {
    return 'paid';
  }
  return status;
}

/**
 * Returns an object with the total price of invoices, total amount owning, and
 * status of invoices.
 *
 * @param  {Array} invoices the invoices
 * @return {Object}         {totalPrice: num, totalOwing: num, status: str}
 */
export function summarizeInvoices(invoices) {
  const totalInvoicesPrice = getInvoicesTotal(invoices, 'totalPrice');
  const totalAmountOwing = getInvoicesTotal(invoices, 'amountOwing');
  const invoiceStatus = getInvoicesStatus(invoices, totalAmountOwing);
  return {
    totalInvoicesPrice,
    totalAmountOwing,
    invoiceStatus
  };
}
/**
 * Calculates the printing costs based on num pages/pkgs, postage, and costs per printing.
 * Does not include taxes or margins.
 *
 * @param  {Integer} numPages     Number of pages per package
 * @param  {Integer} numPkgs      Number of packages
 * @param  {Number}  costPerPage  Cost per printing page ($)
 * @param  {Number}  costPerPkg   Cost per mailing package ($)
 * @param  {Number}  postage      Total postage cost
 * @return {Number}               Calculated printing costs
 */
export function calculatePrinting(
  numPages,
  numPkgs,
  costPerPage,
  costPerPkg,
  postage
) {
  const perPackage =
    parseInt(numPages) * parseFloat(costPerPage) + parseFloat(costPerPkg);
  const subtotalPrinting = parseFloat(perPackage) * parseFloat(numPkgs);
  const totalPrinting = parseFloat(subtotalPrinting) + parseFloat(postage);
  return _.round(totalPrinting, 2);
}
