<template>
  <v-container class="content-container">
    <v-layout>
      <v-flex xs12>
        <v-toolbar v-if="isAdmin" light class="grey lighten-4 mb-1">
          <v-btn
            v-if="hasResentNotice"
            flat
            @click="includeResent = !includeResent"
            >{{ includeResent ? 'hide' : 'show' }} resent notices</v-btn
          >
          <v-spacer />
          <v-btn class="primary" @click.native="dialog.add = true">
            <v-icon left>add</v-icon>Notice
          </v-btn>
          <v-btn class="primary" @click="openAutoScheduler">
            <v-icon left>library_add</v-icon>Multi-Notices
          </v-btn>
        </v-toolbar>

        <v-card v-if="currentNotices.length === 0">
          <v-card-text>
            <div class="grey--text text--darken-1 text-xs-center">
              No notices have been added
            </div>
          </v-card-text>
        </v-card>

        <div v-else>
          <notice-card
            class="mb-2"
            v-for="(notice, index) in currentNotices"
            :key="`key-${index}`"
            :notice="notice"
            :meeting-timezone="currentMeeting.meetingTimezone"
            @emitCopy="handleCopy"
            @toggleResend="handleUpdate"
          />
        </div>
      </v-flex>
    </v-layout>

    <!-- Dialogs -->
    <add-notice-dialog
      v-if="isAdmin"
      v-model="dialog.add"
      :notice-samples="currentNoticeGroupTemplateList"
      :current-meeting="currentMeeting"
      @add="addNewNotice"
      @close="dialog.add = false"
    />

    <copy-notice-dialog
      v-if="isAdmin"
      v-model="dialog.copy"
      :notice="notice.copyObj"
      @copy="copyNotice"
      @close="dialog.copy = false"
    />

    <auto-scheduler-dialog
      v-if="isAdmin"
      v-model="dialog.autoScheduler"
      :current-meeting="currentMeeting"
      :notice-group-list="currentNoticeGroupList"
      :notice-template-list="this.state.noticeAddTemplateList"
      :notice-start-date="this.noticeStartDate"
      :notice-end-date="this.noticeEndDate"
      :notice-template-group-key="this.noticeTemplateGroupKey"
      @close="resetAutoScheduler"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import NoticeCard from '@/components/NoticeCard';
import AddNoticeDialog from '@/components/dialogs/AddNoticeDialog';
import CopyNoticeDialog from '@/components/dialogs/CopyNoticeDialog';
import AutoSchedulerDialog from './MeetingNoticeAutoSchedulerDialog';

export default {
  name: 'MeetingNoticeListView',
  components: {
    NoticeCard,
    AddNoticeDialog,
    CopyNoticeDialog,
    AutoSchedulerDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    noticeData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    noticeGroupList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    noticeTemplateList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      noticeTemplateGroupKey: this.$route.params.noticeTemplateGroupKey,
      noticeStartDate: this.$route.params.noticeStartDate,
      noticeEndDate: this.$route.params.noticeEndDate,
      state: {
        noticeAddTemplateList: []
      },
      notice: {
        samples: [],
        copyObj: {}
      },
      dialog: {
        add: false,
        copy: false,
        autoScheduler: false
      },
      includeResent: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    currentNotices() {
      if (this.includeResent) {
        return this.noticeData;
      }
      return this.noticeData.filter(notice => !notice.options.resent);
    },
    currentNoticeGroupList() {
      // List to populate the add notice dropdown
      return this.noticeGroupList;
    },
    currentNoticeGroupTemplateList() {
      // List to populate the add notice group dropdown
      return this.noticeTemplateList;
    },
    hasResentNotice() {
      const resentNotices = this.noticeData.filter(
        notice => notice.options.resent
      );
      return resentNotices.length > 0;
    }
  },
  watch: {
    // Update state and recipients on data save
    currentNotices: function() {
      this.init();
    },
    currentNoticeGroupTemplateList: function() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/notices', [
      'createMeetingNotice',
      'getMeetingNotices',
      'updateMeetingNotice'
    ]),
    async init() {
      const filteredTemplates = this.currentNoticeGroupTemplateList;

      // Populate the list of templates available to the scheduler
      this.state.noticeAddTemplateList = _.filter(filteredTemplates, {
        primaryNotice: false
      });

      // When a notice template along with the start date and end date, continue the notice scheduling and spawn the dialog
      if (this.noticeTemplateGroupKey) {
        this.openAutoScheduler();
      }
    },
    async refresh() {
      // When refreshing, it's after the auto sceduler. We don't want to get stats for all
      // the notices in this instance.
      await this.getMeetingNotices({
        shortCode: this.shortCode,
        includeStats: false
      });
    },
    async copyNotice() {
      try {
        await this.createMeetingNotice({
          shortCode: this.shortCode,
          newNotice: this.notice.copyObj,
          contact:
            this.currentMeeting.options.replyToEmail ||
            this.currentMeeting.contact.email
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.copy = false;
      }
    },
    async addNewNotice(newNotice) {
      try {
        const data = await this.createMeetingNotice({
          shortCode: this.shortCode,
          newNotice: newNotice,
          contact:
            this.currentMeeting.options.replyToEmail ||
            this.currentMeeting.contact.email
        });

        // Transition to edit page
        // 2019-07-30 added noticeTemplateKey
        this.$router.push({
          name: 'meetingEditNotices',
          params: {
            noticeKey: data.key,
            shortcode: this.shortCode
          }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleCopy(noticeObj) {
      this.notice.copyObj = noticeObj;
      this.dialog.copy = true;
    },
    async handleUpdate(notice) {
      try {
        // By default, we can't update a non-draft Notice
        // Including force=true param to override this default setting
        await this.updateMeetingNotice({
          shortCode: this.shortCode,
          noticeKey: notice.key,
          notice: { ...notice, isResendable: !notice.isResendable },
          force: true
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openAutoScheduler() {
      // Retrieve the checklist dates to pre-populate the start and end dates
      this.dialog.autoScheduler = true;
    },
    async resetAutoScheduler() {
      // Reset the passed parameters (if any)
      this.$data.noticeTemplateGroupKey = '';
      this.$data.noticeStartDate = null;
      this.$data.noticeEndDate = null;
      this.dialog.autoScheduler = false;

      this.refresh();
    }
  }
};
</script>

<style scoped></style>
