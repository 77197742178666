<template>
  <v-container grid-list-sm>
    <v-layout row>
      <v-radio-group v-model="hasNoAgent" row>
        <v-radio label="Account has Agents" :value="false"></v-radio>
        <v-radio label="Account does not have Agents" :value="true"></v-radio>
      </v-radio-group>
    </v-layout>

    <v-layout row>
      <v-divider class="my-2"></v-divider>
    </v-layout>

    <v-layout row align-center>
      <v-flex xs8>
        <v-autocomplete
          :disabled="hasNoAgent"
          :items="agentList"
          item-text="name"
          item-value="key"
          v-model="agents"
          :search-input.sync="agentSearch"
          label="Search for agent"
          :loading="searching"
          return-object
          multiple
          dense
          chips
          deletable-chips
        ></v-autocomplete>
      </v-flex>
      <v-flex xs4>
        <v-btn flat outline :disabled="hasNoAgent" @click="createNewAgent"
          >create new agent</v-btn
        >
      </v-flex>
    </v-layout>

    <v-layout row v-for="(agent, index) in agents" :key="index">
      <v-textarea
        v-if="agent.notes"
        box
        disabled
        :label="`${agent.name} Notes`"
        :value="agent.notes"
      >
      </v-textarea>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'InputAgents',
  props: {
    agentList: {
      type: Array,
      default() {
        return [];
      }
    },
    accountAgents: {
      type: Array,
      default() {
        return [];
      }
    },
    searching: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    accountAgents() {
      this.agents = this.accountAgents;
    },
    agentSearch() {
      this.$emit('updateAgentSearch', this.agentSearch);
    },
    hasNoAgent() {
      this.$emit('toggleAgent', this.hasNoAgent);
    },
    agents() {
      this.$emit('update', { key: 'agents', value: this.agents });
    }
  },
  data() {
    return {
      hasNoAgent: false,
      agentSearch: '',
      agents: []
    };
  },
  methods: {
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    },
    createNewAgent() {
      // Navigate to Admin Agents page, with "Add Agent" dialog open
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';
      const url = `${baseUrl}/admin/agents/?add=true`;
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped></style>
