import http from './requestType';

/**
 * Get a list of the available reports for this meeting
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getReports(shortCode) {
  return http._get(`Meetings/${shortCode}/reports`);
}

/**
 * Get the data for a meeting report. Specify the view as 'csv' to download a report, otherwise return as JSON.
 * @param  {string} reportType - the report to get
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} region    - the meeting region
 * @param  {object} filters   - report filters
 * @return {Promise} - Promise
 */
export function getReport(reportType, shortCode, region, folder, filters) {
  return http._get(`Meetings/${shortCode}/reports/${reportType}`, {
    view: 'csv',
    region,
    folder,
    ...filters
  });
}

/**
 * Get the export/import report for a meeting.
 *
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getExportImportReport(shortCode) {
  return http._get(`Meetings/${shortCode}/reports/export-import/?view=csv`);
}
