<template>
  <router-view
    :user-list="userList"
    :user-data="userListData"
    :data-last-fetched-at="dataLastFetchedAt"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleError } from '@/helpers';

export default {
  name: 'AdminUsersMain',
  computed: {
    ...mapGetters('users', ['userList', 'userListData', 'dataLastFetchedAt'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('users', ['getUsers']),
    async init() {
      try {
        await this.getUsers();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    }
  }
};
</script>

<style scoped></style>
