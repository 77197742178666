'use strict';

/**
 * @fileoverview Vuex module for a meeting's webcast
 */

import _ from 'lodash';
import Vue from 'vue';
import { meetingWebcasts } from '@/api';

// Used as parameter in dispatch to use v2 api
// Some v2 actions are identical to v1, but use v2 routes to check access based on subscription status
const apiVersion = 'v2';

const state = {
  webcast: {},
  license: {},
  dataLastFetchedAt: null
};

const getters = {
  webcast: state => state.webcast,
  license: state => state.license,
  zoomData: state => (state.webcast ? state.webcast.zoomData : null)
};

const actions = {
  /**
   * Boot up a meeting before joining as host
   * @param  {String} shortCode - The meeting key
   * @return {Object}           - license details
   */
  async bootWebcast({ commit, dispatch }, { shortCode }) {
    try {
      const { data } = await meetingWebcasts.putBootWebcast({ shortCode });
      commit('SET_LICENSE', { license: data });

      await dispatch(
        'meetings/webcast/fetchWebcastDetails',
        { shortCode },
        { root: true }
      );
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get total units for this meeting (included deleted ones)
   * Use for VBM meeting unit naming convention
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getTotalUnits({ dispatch }, { shortCode }) {
    try {
      return dispatch(
        'meetings/units/getTotalUnits',
        { shortCode, apiVersion },
        { root: true }
      );
    } catch (err) {
      console.error('ERROR: getTotalUnits', err);
      throw err;
    }
  }
};

const mutations = {
  SET_WEBCAST(state, { webcast }) {
    Vue.set(state, 'webcast', webcast);
    state.dataLastFetchedAt = new Date();
  },

  SET_LICENSE(state, { license }) {
    Vue.set(state, 'license', license);
  },

  // Reset the state
  CLEAR_STATE(state) {
    Vue.set(state, 'webcast', {});
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
