<template>
  <v-dialog persistent :value="value" max-width="550px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <div class="title">Extend Voting?</div>
      </v-card-title>

      <v-card-text>
        <p class="mt-3">
          To add time to <strong>ALL</strong> active questions, select a length
          of time below and click <strong>ADD</strong>
        </p>
      </v-card-text>

      <!-- Select extension to all active questions -->
      <v-layout justify-center>
        <v-flex xs4>
          <v-select
            :items="timeOptions"
            label="Time (mins)"
            v-model="lengthOfTime"
            prepend-icon="alarm"
            outline
            dense
          >
          </v-select>
        </v-flex>
      </v-layout>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click="emitClose">cancel</v-btn>
        <v-btn :disabled="!lengthOfTime" class="primary" @click="emitAdd"
          >add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RTVExtendAllDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeOptions: [1, 2, 5, 10, 15, 20],
      lengthOfTime: null
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitAdd() {
      this.$emit('add', this.lengthOfTime);
      this.lengthOfTime = null;
      this.$emit('close');
    }
  }
};
</script>
