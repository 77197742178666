var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 mr-2",attrs:{"small":"","color":_vm.consents.notice_consent === null
            ? 'grey lighten-1'
            : _vm.consents.notice_consent
            ? 'green lighten-1'
            : 'red lighten-1',"text-color":"white"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleChipClick.apply(null, arguments)}}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getChipIcon('notice_consent')))]),_vm._v(" Notices ")],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.getConsentStatusWithDate('notice_consent'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1",attrs:{"small":"","color":_vm.consents.voting_consent === null
            ? 'grey lighten-1'
            : _vm.consents.voting_consent
            ? 'green lighten-1'
            : 'red lighten-1',"text-color":"white"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleChipClick.apply(null, arguments)}}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getChipIcon('voting_consent')))]),_vm._v(" Voting ")],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.getConsentStatusWithDate('voting_consent'))+" ")])]),_c('multi-consent-edit-dialog',{attrs:{"consents":_vm.consents,"meeting-timezone":_vm.meetingTimezone},on:{"update":_vm.handleConsentUpdate,"delete":_vm.handleConsentDelete,"close":function($event){_vm.dialog.multiConsentEdit = false}},model:{value:(_vm.dialog.multiConsentEdit),callback:function ($$v) {_vm.$set(_vm.dialog, "multiConsentEdit", $$v)},expression:"dialog.multiConsentEdit"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }