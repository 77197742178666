<template>
  <v-dialog :value="isOpen" max-width="550px" persistent>
    <v-card>
      <v-card-title class="headline">
        An error ocurred while setting the license
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <v-alert
              v-if="type === 'fromWebcast'"
              outline
              type="error"
              :value="true"
            >
              {{ error }}
            </v-alert>
            <v-alert v-else outline type="error" :value="true">
              <span v-html="error"></span>
            </v-alert>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BootErrorDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'fromWebcast'
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
