<template>
  <v-dialog max-width="800px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Assign Checklists</span>
      </v-card-title>
      <v-card-text>
        <v-layout row class="mt-3">
          <v-combobox
            v-model="select"
            :items="assignees"
            item-value="username"
            item-text="name"
            :search-input.sync="search"
            label="Assign to:"
            hide-selected
            multiple
            small-chips
            deletable-chips
            dense
          />
        </v-layout>
        <p>Note: Leave blank to remove assignees from the checklist(s).</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Cancel</v-btn>
        <v-btn class="primary" @click.native="emitSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingChecklistDialogAssign',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    assignees: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      select: [],
      search: null
    };
  },
  watch: {},
  mounted() {},
  methods: {
    emitSave() {
      // Prevent clearing assignees.
      if (this.select.length <= 0) {
        const isConfirm = confirm(
          'WARNING: You are removing all assignees.\n\nAre you sure?'
        );
        if (!isConfirm) {
          return;
        }
      }

      this.$emit('save', this.select);
      this.emitClose();
    },
    emitClose() {
      this.$emit('close');
      this.select = [];
    }
  }
};
</script>

<style scoped></style>
