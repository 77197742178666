// Utility to check auth status
import _ from 'lodash';

/**
 * Check if the user is logged in.
 */
export function loggedIn() {
  const profile = sessionStorage.getItem('profile');
  const isLoggedIn = profile && !_.isEmpty(profile);
  return isLoggedIn;
}

// Clear out the items (eg. Logout)
export function clearAuth() {
  sessionStorage.removeItem('profile');
}
