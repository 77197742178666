<template>
  <section>
    <div v-if="!isDisabled" class="mb-3">
      <v-alert
        small
        outline
        color="blue"
        icon="info"
        :value="true"
        v-show="!noticeReplyTo"
      >
        Replies will be sent an unmonitored GQ email address. However, an
        auto-responder will direct the voter to voters@getquorum.com should they
        require further assistance.
      </v-alert>
      <v-alert
        small
        outline
        color="pink"
        icon="warning"
        :value="true"
        v-show="noticeReplyTo"
      >
        Replies may not go to GQ's helpdesk, only use for budgets or other
        non-voting campaigns
      </v-alert>
    </div>

    <v-layout row align-center>
      <v-flex xs5>
        <v-text-field
          label="Replies to this email will go to ..."
          :value="noticeReplyTo"
          :placeholder="`notices+${$route.params.shortcode}@getquorum.com`"
          :disabled="isDisabled"
          hint="Uses default if left blank"
          persistent-hint
          @input="onChangeOption('replyTo', $event)"
        />
      </v-flex>
      <v-flex xs7>
        <v-btn
          flat
          color="blue"
          :disabled="isDisabled"
          @click="onChangeOption('replyTo', '')"
        >
          Use Default
        </v-btn>
        <v-btn
          flat
          color="blue"
          :disabled="isDisabled"
          @click="onChangeOption('replyTo', contactEmail)"
        >
          use {{ contactEmail }}
        </v-btn>
        <v-btn
          v-if="meetingReplyTo"
          flat
          color="blue"
          :disabled="isDisabled"
          @click="onChangeOption('replyTo', meetingReplyTo)"
        >
          use {{ meetingReplyTo }}
        </v-btn>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  name: 'InputReplyTo',
  props: {
    contactEmail: {
      type: String,
      default: ''
    },
    noticeReplyTo: {
      type: String,
      default: ''
    },
    meetingReplyTo: {
      type: String,
      default: ''
    },
    isDisabled: { type: Boolean, default: false }
  },
  methods: {
    onChangeOption(key, value) {
      this.$emit('updateOption', { key, value });
    }
  }
};
</script>

<style scoped></style>
