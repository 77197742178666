<template>
  <v-card class="round-card full-height">
    <v-card-title class="space-between">
      <div class="h-flex">
        <v-avatar size="30" color="grey lighten-1" class="mr-2">
          <v-icon dark size="20">{{ icon }}</v-icon>
        </v-avatar>
        <h6 class="subheading font-weight-bold">
          {{ title }}
          <v-tooltip bottom v-if="tooltip">
            <span slot="activator">
              <v-icon small color="grey">info</v-icon>
            </span>
            <div>
              {{ tooltip }}
            </div>
          </v-tooltip>
        </h6>
      </div>
      <div>
        <div v-if="edit && doneButton">
          <v-btn
            v-if="edit"
            color="primary"
            class="ma-0 mr-2 pa-0"
            :loading="saving"
            @click="edit = false"
          >
            Done
          </v-btn>
        </div>
        <div v-else-if="edit">
          <v-btn
            v-if="edit && !hideSave"
            color="primary"
            class="ma-0 mr-2 pa-0"
            :loading="saving"
            :disabled="disabled"
            @click="onSave"
          >
            Save
          </v-btn>
          <v-btn
            v-if="edit"
            flat
            color="error"
            class="ma-0 pa-0"
            @click="onCancel"
          >
            Cancel
          </v-btn>
        </div>
        <v-btn
          v-else-if="!edit && !hideEdit"
          flat
          color="primary"
          class="ma-0 pa-0"
          @click="edit = true"
        >
          Edit
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <slot :edit="edit"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MeetingOverviewCard',
  components: {},
  props: {
    icon: {
      type: String,
      default: 'help'
    },
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    doneButton: {
      type: Boolean,
      default: false
    },
    hideSave: {
      type: Boolean,
      default: false
    },
    hideEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false
    };
  },
  created() {},
  computed: {},
  watch: {
    saving: function() {
      //When saving is false, set edit to false
      this.edit = !this.saving ? false : this.edit;
    }
  },
  methods: {
    onSave() {
      this.$emit('save');
    },
    onCancel() {
      this.edit = false;
      this.$emit('cancel');
    }
  }
};
</script>

<style>
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.round-card {
  border-radius: 7px;
}

.full-height {
  height: 100%;
}
</style>
