import http from './requestType';

export {
  getAllCertificates,
  getCertificate,
  postCertificate,
  deleteCertificate,
  postCertificatePreview,
  sendCertificate
};

/** List the certificates based on the search parameters. By default it will return all certificates.
 * Can search by and account's accountKey and/or a campaign's shortCode.
 * @param  {string} searchFieldName - The field to search or filter on
 * @param  {string} searchFieldValues - The values to search or filter on
 * @return {Promise} - Promise
 */
function getAllCertificates(searchFieldValues) {
  return http._get(
    `Certificates?searchFieldName=shortCode&searchFieldValues=${searchFieldValues}`
  );
}

/**
 * Create a certificate PDF. Depending on the type of certificate created it may also extract
 * additional reports. Must supply the shortCode and accountKey.
 * @param  {object} certificate  - The new certificate payload
 * @return {Promise} - Promise
 */
function postCertificate(certificate) {
  return http._post(`Certificates`, certificate);
}

/**
 * Deletes an unsent certificate
 * @param  {string} certKey - The unique id for the certificate to retrieve
 * @return {Promise} - Promise
 */
function deleteCertificate(certKey) {
  return http._delete(`Certificates/${certKey}`);
}

/** Returns a certificate
 * @param  {string} certKey - The unique id for the certificate to retrieve
 * @return {Promise} - Promise
 */
function getCertificate(certKey) {
  return http._get(`Certificates/${certKey}`);
}

/**
 * Returns HTML representation of the PDF Certificate for preview purposes.
 * @param  {object} certificate  - The new certificate payload
 * @return {Promise} - Promise
 */
function postCertificatePreview(certificate) {
  return http._post(`Certificates/preview`, certificate);
}

/**
 * Sends the certificate via email with any associated files attached.
 * @param  {object} certKey  - The unique id for the certificate to send
 * @param  {object} payload  - the email properties
 * @return {Promise} - Promise
 */
function sendCertificate(certKey, payload) {
  return http._post(`Certificates/${certKey}/send`, payload);
}
