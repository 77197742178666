<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-subheader light>
          <span class="capitalize">
            Pending Requests ({{ requestsList.length }})
          </span>
        </v-subheader>
        <div v-if="requestsList.length > 0">
          <v-list two-line class="list-back">
            <meetings-requested-view-list-item
              v-for="request in requestsList"
              :request="request"
              :key="request.key"
            />
          </v-list>
        </div>
        <div v-else>
          <p class="pl-3">
            No pending requests.
          </p>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import MeetingsRequestedViewListItem from './MeetingsRequestedViewListItem.vue';

export default {
  name: 'MeetingsRequestedView',
  components: {
    MeetingsRequestedViewListItem
  },
  props: {
    requestsList: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.list-back {
  background-color: #fafafa !important;
}
</style>
