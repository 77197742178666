<template>
  <v-dialog :value="isOpen" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Create New Approval Request</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <v-layout row>
              <v-flex xs12>
                <v-text-field
                  v-model="state.name"
                  label="Name of Request"
                  placeholder="eg. Notice of Service"
                  box
                  @input="emitUpdate('name', $event)"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="close" :loading="loading">Close</v-btn>
        <v-btn color="primary" @click.native="save" :loading="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CreateRequestDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    state: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    },
    save() {
      this.$emit('save');
    }
  }
};
</script>

<style scoped></style>
