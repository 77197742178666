<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">Voter Registration</div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Configuration settings
    </div>
    <v-layout>
      <v-flex xs12>
        <v-layout>
          <div>
            <v-checkbox
              v-model="hasVoterRegistration"
              @change="
                toggleVoterRegistration(
                  'options.enable_voter_registration',
                  $event
                )
              "
            />
          </div>
          <div>
            <h5 class="subheading">Enable Voter Registration</h5>
            <p class="grey--text text--darken-2">
              Voters may register through a control number when no email
            </p>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />
    <div v-if="hasVoterRegistration">
      <v-layout>
        <v-flex xs12>
          <div class="title grey--text text--darken-4 mb-1">
            Custom content specific
          </div>
          <v-radio-group
            name="voter-registration-type"
            v-model="voterRegistrationType"
            @change="onChange('options.voter_registration_type', $event)"
          >
            <v-radio value="virtual">
              <template v-slot:label>
                <div>
                  <h5 class="subheading">
                    For Virtual Meetings
                  </h5>
                  <p class="grey--text text--darken-2">
                    Meeting details and actions included
                  </p>
                </div>
              </template>
            </v-radio>
            <v-radio value="registration-only">
              <template v-slot:label>
                <div>
                  <h5 class="subheading">
                    For Registration only
                  </h5>
                  <p class="grey--text text--darken-2">
                    Generic registration page (i.e. Email collection only)
                  </p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingSettingsVoterRegistrationInput',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      hasVoterRegistration: false,
      voterRegistrationType: null
    };
  },
  methods: {
    initState() {
      this.hasVoterRegistration = _.get(
        this.options,
        `enableVoterRegistration`,
        ''
      );
      this.voterRegistrationType = _.get(
        this.options,
        `voterRegistrationType`,
        'virtual'
      );
    },
    toggleVoterRegistration(key, value) {
      this.onChange(key, value);
      this.onChange(
        'options.voter_registration_type',
        this.voterRegistrationType
      );
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style lang="scss" scoped></style>
