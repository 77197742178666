<template>
  <v-layout row>
    <v-flex xs12>
      <h1 class="intro-title">My Dashboard</h1>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'MeetingsViewIntro'
};
</script>

<style lang="scss" scoped>
.intro-title {
  font-weight: 500;
  font-size: 24px;
}
</style>
