<template>
  <div>
    <v-toolbar dark dense color="white lighten-4 elevation-1">
      <v-text-field
        class="mb-2"
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />
      <v-btn @click="dialog.addUser = true" color="primary"> Add User </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :pagination.sync="pagination"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-left">
            <router-link
              :to="{
                name: 'adminUserEdit',
                params: { userKey: props.item.userKey }
              }"
            >
              {{ props.item.user.name }}
            </router-link>
          </td>
          <td class="text-xs-left">{{ props.item.user.email }}</td>
          <td class="text-xs-left">
            {{ props.item.createdAt | formatDateHumanReadable(false) }}
          </td>
          <td class="text-xs-left">
            {{ props.item.lastLoginAt | dateFromNow(false) }}
          </td>
          <td class="text-xs-left">
            <v-chip
              label
              small
              :color="props.item.disabledAt ? 'red' : 'green'"
              text-color="white"
              >{{ props.item.disabledAt ? 'disabled' : 'active' }}</v-chip
            >
          </td>

          <td>
            <v-btn
              flat
              icon
              color="red"
              @click.native="deleteMembership(props.item)"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Add user dialog -->
    <admin-user-add-dialog
      @add-user="addUser"
      @close-dialog="dialog.addUser = false"
      :is-open="dialog.addUser"
      description="NOTE: An invite email will be sent asking the user to log into their Dashboard."
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as filters from '@/filters';
import AdminUserAddDialog from '@/components/dialogs/AddUserDialog';

export default {
  name: 'AdminAccountViewUsersTable',
  props: {
    users: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    AdminUserAddDialog
  },
  filters: {
    ...filters
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey,
      dialog: {
        addUser: false
      },
      search: '',
      headers: [
        { text: 'Name', value: 'user.name', align: 'left' },
        { text: 'Email', value: 'user.email', align: 'left' },
        { text: 'Created', value: 'user.createdAt', align: 'left' },
        { text: 'Last Login', value: 'user.lastLoginAt', align: 'left' },
        {
          text: 'Status',
          value: 'user.disabledAt',
          align: 'left',
          sortable: false
        },
        { text: '', value: '', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      },
      pagination: {
        sortBy: 'meetingDate',
        descending: true
      }
    };
  },
  methods: {
    ...mapActions('accounts', [
      'getAccountUsers',
      'removeAccountUser',
      'addAccountUser'
    ]),
    async addUser({ email, firstName, lastName }) {
      try {
        await this.addAccountUser({
          email,
          firstName,
          lastName,
          accountKey: this.accountKey
        });
        this.$events.$emit('toastEvent', 'User Created');
        await this.getAccountUsers(this.accountKey);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteMembership(item) {
      let isConfirm = confirm(
        'Are you sure you want to remove this user from this account?'
      );

      if (isConfirm) {
        try {
          await this.removeAccountUser({
            accountKey: this.accountKey,
            email: item.user.email
          });
          this.$events.$emit('toastEvent', 'Membership Removed');
          // Update account users
          this.getAccountUsers(this.accountKey);
        } catch (err) {
          this.$events.$emit('showErrorDialog', err.response);
        }
      }
    }
  }
};
</script>

<style scoped></style>
