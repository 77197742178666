<template>
  <div>
    <v-layout class="mt-3">
      <v-flex xs12 class="text-xs-center">
        <p class="subheading">Active Checklists</p>
      </v-flex>
    </v-layout>
    <v-layout class="mt-3">
      <v-flex xs12>
        <!-- Toolbar with filters and assignment buttons -->
        <v-toolbar dense color="elevation-1">
          <span class="mr-2">Filter by checklist type:</span>
          <v-btn-toggle v-model="toggle" class="elevation-1">
            <v-btn flat color="primary" value="csm">
              CSM
            </v-btn>
            <v-btn flat color="primary" value="vmm">
              VMM
            </v-btn>
          </v-btn-toggle>
          <v-spacer />
          <v-btn
            :disabled="!preferredCsmEmail || selected.length === 0"
            @click.native="openCsmDialog"
            color="primary"
            >assign to preferred csm</v-btn
          >
          <v-btn
            :disabled="!preferredVmmEmail || selected.length === 0"
            @click.native="openVmmDialog"
            color="primary"
            >assign to preferred vmm</v-btn
          >
        </v-toolbar>
        <!-- Active Checklists data table -->
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="incompleteChecklists"
          :rows-per-page-items="config.rowsPerPageItems"
          item-key="key"
          select-all
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <v-checkbox v-model="props.selected" primary hide-details />
              </td>
              <td>
                <checklist-chip :checklist="props.item" />
              </td>
              <td>
                {{ props.item.meetingName }}
              </td>
              <td>
                {{ props.item.assignees | getAssigneeNames }}
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p v-if="toggle === 'csm'">
              There are no active CSM checklists for
              {{ currentAccount.nameShort }}.
            </p>
            <p v-else-if="toggle === 'vmm'">
              There are no active VMM checklists for
              {{ currentAccount.nameShort }}.
            </p>
            <p v-else>
              There are no active checklists for
              {{ currentAccount.nameShort }}.
            </p>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <!-- Confirmation dialog -->
    <v-dialog :value="dialog.assign" persistent max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Assign Checklists?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row>
              <v-flex xs12>
                <p>
                  You are about to assign
                  {{ selected.length }} checklist(s) to
                  <b>{{
                    dialog.manager === 'csm'
                      ? preferredCsmName
                      : preferredVmmName
                  }}</b
                  >.
                </p>
                <p>Click CONFIRM to continue, or CANCEL to go back.</p>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog.assign = false" flat
            >cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="handleAssign" flat
            >confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { getAssigneeNames } from '@/lib/checklist-helpers';
import ChecklistChip from '@/components/ChecklistChip';

export default {
  name: 'AdminAccountChecklistsView',
  components: {
    ChecklistChip
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  filters: { getAssigneeNames },
  computed: {
    preferredCsmEmail() {
      if (!this.currentAccount.preferredManagers) {
        return null;
      }

      const preferredCsm = this.currentAccount.preferredManagers.find(
        m => m.userType === 'csm'
      );
      return preferredCsm ? preferredCsm.user.email : null;
    },
    preferredVmmEmail() {
      if (!this.currentAccount.preferredManagers) {
        return null;
      }

      const preferredVmm = this.currentAccount.preferredManagers.find(
        m => m.userType === 'vmm'
      );
      return preferredVmm ? preferredVmm.user.email : null;
    },
    preferredCsmName() {
      if (!this.currentAccount.preferredManagers) {
        return null;
      }

      const preferredCsm = this.currentAccount.preferredManagers.find(
        m => m.userType === 'csm'
      );
      return preferredCsm ? preferredCsm.user.name : null;
    },
    preferredVmmName() {
      if (!this.currentAccount.preferredManagers) {
        return null;
      }

      const preferredVmm = this.currentAccount.preferredManagers.find(
        m => m.userType === 'vmm'
      );
      return preferredVmm ? preferredVmm.user.name : null;
    },
    incompleteChecklists() {
      if (!this.currentAccount.incompleteChecklists) {
        return [];
      } else {
        const filteredChecklists = this.currentAccount.incompleteChecklists.filter(
          checklist => {
            if (this.toggle === 'csm') {
              return !checklist.stage.startsWith('vm');
            }
            if (this.toggle === 'vmm') {
              return checklist.stage.startsWith('vm');
            }
            return checklist;
          }
        );
        return _.sortBy(filteredChecklists, ['meetingName', 'dueAt']);
      }
    }
  },
  data() {
    return {
      toggle: null,
      selected: [],
      headers: [
        { text: 'Checklist', value: 'stage', sortable: false },
        { text: 'Meeting', value: 'meetingName', sortable: false },
        { text: 'Current Assignee', value: 'assignees', sortable: false }
      ],
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }]
      },
      dialog: {
        assign: false,
        manager: 'csm'
      }
    };
  },
  methods: {
    ...mapActions('meetings/checklists', ['setChecklistAssignees']),
    ...mapActions('accounts', ['getAccountIncompleteChecklists']),
    openCsmDialog() {
      this.dialog.manager = 'csm';
      this.dialog.assign = true;
    },
    openVmmDialog() {
      this.dialog.manager = 'vmm';
      this.dialog.assign = true;
    },
    resetDialog() {
      this.selected = [];
      this.dialog.manager = '';
      this.dialog.assign = false;
      this.toggle = null;
    },
    handleAssign() {
      if (this.dialog.manager === 'csm') {
        this.assignToCsm();
      } else {
        this.assignToVmm();
      }
    },
    async assignToCsm() {
      try {
        for (const checklist of this.selected) {
          await this.setChecklistAssignees({
            checklistKey: checklist.key,
            assignees: [this.preferredCsmEmail]
          });
        }

        await this.getAccountIncompleteChecklists({
          accountKey: this.currentAccount.key
        });

        this.$events.$emit(
          'toastEvent',
          `${this.selected.length} Checklists Assigned to Preferred CSM`
        );

        this.resetDialog();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async assignToVmm() {
      try {
        for (const checklist of this.selected) {
          await this.setChecklistAssignees({
            checklistKey: checklist.key,
            assignees: [this.preferredVmmEmail]
          });
        }

        await this.getAccountIncompleteChecklists({
          accountKey: this.currentAccount.key
        });

        this.$events.$emit(
          'toastEvent',
          `${this.selected.length} Checklists Assigned to Preferred VMM`
        );

        this.resetDialog();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>
