<template>
  <div>
    <div class="h-flex px-3 pt-3">
      <h6 class="subheading">Filters</h6>
      <v-btn flat small color="primary" @click="clear">
        Clear all
      </v-btn>
    </div>

    <v-subheader>Status</v-subheader>
    <v-btn-toggle
      multiple
      v-model="filters.statuses"
      @change="emitUpdate"
      class="btn-group px-2"
    >
      <v-btn value="verified" flat small class="py-1" color="success">
        <v-icon class="mr-1">done</v-icon>
        Verified
      </v-btn>
      <v-btn value="revoked" flat small class="py-1" color="error">
        <v-icon class="mr-1">close</v-icon>
        Revoked
      </v-btn>
    </v-btn-toggle>

    <v-subheader>Submitted By</v-subheader>
    <v-btn-toggle
      v-model="filters.sources"
      @change="emitUpdate"
      multiple
      class="btn-group px-2"
    >
      <v-btn value="voter" flat small class="py-1" color="teal">
        <v-icon class="mr-1">how_to_vote</v-icon>
        Voters
      </v-btn>
      <v-btn value="user" flat small class="py-1" color="purple">
        <v-icon class="mr-1">person</v-icon>
        User
      </v-btn>
      <v-btn value="admin" flat small class="py-1" color="blue">
        <v-icon class="mr-1">shield</v-icon>
        Admin
      </v-btn>
    </v-btn-toggle>

    <v-subheader>Box</v-subheader>
    <v-btn-toggle
      v-if="isBill"
      v-model="filters.boxes"
      @change="emitUpdate"
      multiple
      class="btn-group px-2"
    >
      <v-btn value="box-1" flat small class="py-1" color="purple">
        <v-icon class="mr-1">looks_one</v-icon>
        Box 1 ({{ boxCount(1) }})
      </v-btn>
      <v-btn value="box-2" flat small class="py-1" color="teal">
        <v-icon class="mr-1">looks_two</v-icon>
        Box 2 ({{ boxCount(2) }})
      </v-btn>
      <v-btn value="box-3" flat small class="py-1" color="orange">
        <v-icon class="mr-1">looks_3</v-icon>
        Box 3 ({{ boxCount(3) }})
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'ProxiesFilterDrawer',
  components: {},
  props: {
    isBill: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('meetings/proxies', ['filters']),
    ...mapGetters('meetings/proxies', ['meetingProxyListData'])
  },

  methods: {
    ...mapActions('meetings/proxies', ['clearFilters']),
    boxCount(num) {
      return this.meetingProxyListData.filter(
        el => el.boxOptions === `box-${num}`
      ).length;
    },
    emitUpdate() {
      this.$emit('update');
    },
    clear() {
      this.clearFilters();
      this.$emit('update');
    }
  }
};
</script>

<style scoped>
.h-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btn-group > button {
  width: 100%;
}

.btn-group.v-btn-toggle--selected {
  box-shadow: none;
}
.btn-group.v-btn-toggle:not(.v-btn-toggle--only-child)
  .v-btn.v-btn--active:not(:last-child) {
  border-right-color: transparent;
}
</style>
