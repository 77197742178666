<template>
  <v-container>
    <!-- Invalid email list dialog -->
    <v-dialog :value="value" max-width="450">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div class="title">
              {{ invalidEmailList.length }} Emails not found in Membership
            </div>
            <span class="body-2 grey--text">
              The following email(s) are not defined in Membership
            </span>
          </div>
        </v-card-title>
        <v-layout style="max-height: 450px; overflow-y: auto;">
          <v-list>
            <template v-for="(email, i) in invalidEmailList">
              <div :key="i">
                <v-list-tile>
                  <v-list-tile-content class="border-bottom">
                    {{ email }}
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider />
              </div>
            </template>
          </v-list>
        </v-layout>
        <v-card-actions>
          <v-btn @click.native="emitClose">
            Go Back
          </v-btn>
          <v-spacer />
          <v-btn
            @click.native="dialog.confirm = true"
            flat
            outline
            color="deep-orange"
            >Remove from List</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirm Dialog -->
    <v-dialog :value="dialog.confirm" max-width="350">
      <v-card>
        <v-card-title primary-title>
          <div class="title">
            Are you sure?
          </div>
          <span class="grey--text"
            >This will remove all emails not defined in Membership</span
          >
        </v-card-title>
        <v-card-actions>
          <v-btn @click.native="dialog.confirm = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn @click.native="emitClear" flat outline color="deep-orange">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'MeetingNoticeInvalidEmailDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    invalidEmailList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dialog: {
        confirm: false
      }
    };
  },
  methods: {
    // Clears all invalid emails
    emitClear() {
      this.dialog.confirm = false;
      this.$emit('close');
      this.$emit('clearInvalid');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>
