<template>
  <v-card flat>
    <v-card-text>
      <!-- Panelists -->
      <h4>Panelists</h4>
      <meeting-webcast-view-panelists-table
        class="mt-2"
        :panelists="panelists"
        :loading="savingPanelists"
        :meeting-timezone="meetingTimezone"
        :documents="documents"
        @send-rehearsal="sendRehearsal"
        @remove="removePanelist"
        @bulk-rehearsal="bulkRehearsalInvites"
        @bulk-zoom-invite="bulkZoomInvites"
      />

      <!-- Adding a new panelist -->
      <h4 class="mt-5">Add a new panelist</h4>
      <v-form v-model="validForm" ref="form">
        <v-container grid-list-xl text-xs-center>
          <v-layout row wrap>
            <v-flex xs6 class="pb-0">
              <v-text-field
                label="Name and title to show in Zoom"
                placeholder="Seymour Skinner (Principal)"
                maxlength="64"
                counter="64"
                v-model="panelist.name"
                :rules="rules.name"
                :disabled="savingPanelists"
                box
              />
            </v-flex>
            <v-flex xs6 class="pb-0">
              <v-text-field
                label="Email"
                placeholder="skinner@springfieldelementary.com"
                v-model="panelist.email"
                :disabled="savingPanelists"
                :rules="rules.email"
                @keyup.enter="addPanelist"
                box
              />
            </v-flex>
            <v-flex xs6 class="pt-0">
              <v-checkbox
                v-model="sendZoomInvite"
                class="mt-0"
                label="Send Panelist Invite"
              ></v-checkbox>
            </v-flex>
            <v-flex xs6 class="pt-0 pr-0 text-xs-right">
              <v-btn
                class="mt-0"
                color="primary"
                :disabled="!validForm"
                :loading="savingPanelists"
                @click.native="onAddPanelist"
              >
                Add
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <v-divider class="mt-4 mb-4" />

      <h4 v-if="canEditDressRehearsalSettings">Pre-meeting Settings</h4>
      <v-layout row class="mt-2" v-if="canEditDressRehearsalSettings">
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3>
              <v-menu
                v-model="date.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date.picker"
                    label="Rehearsal Date"
                    readonly
                    v-on="on"
                    box
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date.picker"
                  :min="today"
                  no-title
                  @input="date.menu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 md2 class="pl-1 pr-1">
              <v-text-field
                label="Time"
                v-model="date.time"
                hide-details
                type="time"
                box
              />
            </v-flex>
            <v-flex xs12 sm6 md2>
              <v-text-field
                label="Duration (Mins)"
                type="number"
                min="0"
                v-model="date.duration"
                box
              />
            </v-flex>
            <v-flex xs12 sm6 md3 class="pl-1">
              <v-select
                v-if="!Boolean(meetingTimezone)"
                :items="timezones"
                item-text="text"
                item-value="value"
                v-model="date.timezone"
                box
                label="Time Zone to show in rehearsal invite"
              />
              <v-text-field
                v-else
                class="pl-1"
                :value="meetingTimezone"
                :disabled="true"
                label="Timezone"
                box
              />
            </v-flex>
            <v-flex xs12 sm6 md2>
              <v-btn
                @click.native="saveWebcast"
                :loading="savingWebcast"
                color="primary"
              >
                Save
              </v-btn>
            </v-flex>
          </v-layout>

          <v-layout>
            <ul>
              <li>
                <b>Pacific:</b> {{ calc({ tz: 'America/Vancouver' }) | format }}
              </li>
              <li>
                <b>Mountain:</b> {{ calc({ tz: 'America/Edmonton' }) | format }}
              </li>
              <li>
                <b>Central:</b> {{ calc({ tz: 'America/Winnipeg' }) | format }}
              </li>
              <li>
                <b>Eastern:</b> {{ calc({ tz: 'America/Toronto' }) | format }}
              </li>
              <li>
                <b>Atlantic:</b> {{ calc({ tz: 'America/Halifax' }) | format }}
              </li>
              <li>
                <b>Newfoundland :</b>
                {{ calc({ tz: 'America/St_Johns' }) | format }}
              </li>
              <li>
                <b>London, UK :</b>
                {{ calcDate({ tz: 'Europe/London' }) | format }}
              </li>
              <li><b>UTC:</b> {{ calc({ tz: 'utc' }) | formatUtc }}</li>
            </ul>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>

    <!-- Select Document(s) to Attach Dialog  -->
    <attach-files-dialog
      :value="dialog.attachments"
      :meeting-documents="documents"
      :attachments="zoomInviteAttachments"
      attachment-type="email"
      @update="sendInviteWithAttachments"
      @close="resetAttachmentsDialog"
    />
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import validator from 'email-validator';

import { mapActions, mapGetters } from 'vuex';

import timezones from '@/lib/timezones';
import checkScope from '@/lib/check-user-scopes';

import MeetingWebcastViewPanelistsTable from './MeetingWebcastViewPanelistsTable';
import AttachFilesDialog from '@/components/dialogs/AttachFilesDialog';

export default {
  name: 'MeetingWebcastPanelistsView',
  components: {
    MeetingWebcastViewPanelistsTable,
    AttachFilesDialog
  },
  props: {
    webcast: {
      type: Object,
      required: true
    },
    panelists: {
      type: Array,
      default() {
        return [];
      }
    },
    shortCode: {
      type: String,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    documents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    webcast() {
      this.setDate();
    }
  },
  data() {
    return {
      savingPanelists: false,
      savingWebcast: false,
      panelist: { name: '', email: '' },
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ],
        name: [v => !!v || 'Name is required']
      },
      validForm: false,
      timezones,
      date: {
        menu: null,
        picker: new Date(),
        time: new Date(),
        timezone: null,
        duration: 30
      },
      dialog: {
        attachments: false
      },
      sendZoomInvite: false,
      zoomInviteAttachments: []
    };
  },
  filters: {
    format(mom) {
      return mom.format('YYYY-MM-DD hh:mm a zz');
    },
    formatUtc(mom) {
      return mom.format('YYYY-MM-DD HH:mm zz');
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    today() {
      return moment().format('YYYY-MM-DD');
    },
    rehearsalAt() {
      return moment(this.webcast.rehearsalAt).tz(
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
      );
    },
    rehearsalTimeNoTz() {
      return moment(this.webcast.rehearsalAt).format('HH:mm');
    },
    newRehearsalAt() {
      const tz = this.date.timezone;
      return this.calc({ tz, isUpdate: true }).format();
    },
    canEditDressRehearsalSettings() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.webcast.update')
      );
    }
  },
  created() {
    this.setDate();
  },
  methods: {
    ...mapActions('meetings/webcast', [
      'updateWebcastSetting',
      'fetchWebcastPanelists',
      'addWebcastPanelists',
      'removeWebcastPanelist',
      'updateWebcast',
      'sendRehearsalInvite',
      'sendBulkRehearsalInvites',
      'sendBulkZoomInvites'
    ]),
    resetState() {
      this.panelist = { name: '', email: '' };
      this.sendZoomInvite = false;
      this.$refs.form.reset();
    },
    setDate() {
      this.date = {
        picker: this.rehearsalAt.format('YYYY-MM-DD'),
        time: this.rehearsalAt.format('HH:mm'),
        timezone: this.webcast.rehearsalTimezone,
        duration: this.webcast.rehearsalDuration
      };
    },
    calcDate({ date, time, tz, isUpdate = false }) {
      const fullDate = `${date} ${time}`;

      if (isUpdate) {
        // When updating the dr time, it should use Toronto time zone.
        return moment.tz(
          fullDate,
          this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
        );
      }
      return moment(fullDate, 'YYYY-MM-DD HH:mm', true).tz(tz);
    },
    calc({ tz, isUpdate = false }) {
      const date = this.date.picker;
      // To show dr time in different timezones, we need the original time with no timezone format.
      const time = isUpdate ? this.date.time : this.rehearsalTimeNoTz;
      return this.calcDate({ date, time, tz, isUpdate });
    },
    async onAddPanelist() {
      if (!this.validForm) {
        return;
      }

      if (this.sendZoomInvite) {
        this.dialog.attachments = true;
      } else {
        this.addPanelist();
      }
    },
    resetAttachmentsDialog() {
      this.dialog.attachments = false;
      this.zoomInviteAttachments = [];
    },
    async sendInviteWithAttachments(attachments) {
      const newPanelist = { ...this.panelist };
      this.resetAttachmentsDialog();
      try {
        await this.addPanelist();
        this.bulkZoomInvites([newPanelist], attachments);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async addPanelist() {
      try {
        if (!this.validForm) {
          return;
        }

        this.savingPanelists = true;
        await this.addWebcastPanelists({
          shortCode: this.shortCode,
          ...this.panelist
        });
        await this.fetchWebcastPanelists({ shortCode: this.shortCode });
        this.$events.$emit('toastEvent', 'Panelists Updated');
        this.resetState();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPanelists = false;
      }
    },
    async removePanelist({ email }) {
      const isConfirm = confirm(
        `Are you sure you want to remove ${email} as a panelist? They will NOT receive a notification.`
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.savingPanelists = true;
        await this.removeWebcastPanelist({
          shortCode: this.shortCode,
          email: email
        });
        this.$events.$emit('toastEvent', 'Panelist Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPanelists = false;
      }
    },
    async saveWebcast() {
      try {
        this.savingWebcast = true;

        await this.updateWebcast({
          shortCode: this.shortCode,
          rehearsalAt: this.newRehearsalAt,
          rehearsalDuration: this.date.duration,
          rehearsalTimezone: this.date.timezone
        });

        this.$events.$emit('toastEvent', 'Webcast Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingWebcast = false;
      }
    },

    async sendRehearsal({ email }) {
      try {
        await this.sendRehearsalInvite({
          shortCode: this.shortCode,
          email
        });
        this.$events.$emit('toastEvent', 'Rehearsal Invite Sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },

    async bulkRehearsalInvites(panelists, attachments) {
      try {
        this.savingPanelists = true;
        await this.sendBulkRehearsalInvites({
          shortCode: this.shortCode,
          panelists,
          attachments
        });
        this.$events.$emit('toastEvent', 'Rehearsal Invites Sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPanelists = false;
      }
    },

    async bulkZoomInvites(panelists, attachments) {
      try {
        this.savingPanelists = true;
        await this.sendBulkZoomInvites({
          shortCode: this.shortCode,
          panelists,
          attachments
        });
        this.$events.$emit('toastEvent', 'Panelist Invites Sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.savingPanelists = false;
      }
    }
  }
};
</script>
