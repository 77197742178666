<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        <div class="title primary--text text--darken-1">
          Update Recipient Count
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="recipientCount"
          label="Recipient Count"
          type="number"
        ></v-text-field>
      </v-card-text>

      <v-spacer />
      <v-card-actions>
        <v-layout row class="ma-3">
          <v-flex class="ma-3 text-xs-right">
            <v-btn color="primary" @click.native="close" flat>Close</v-btn>
            <v-btn @click.native="update" color="primary">
              update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdatePrintJobRecipientsDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    },
    printJob: {
      type: Object,
      required: true
    }
  },
  watch: {
    printJob() {
      this.recipientCount = this.printJob.packagesMailed;
    }
  },
  data() {
    return {
      recipientCount: 0
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    update() {
      this.$emit('update', this.recipientCount);
    }
  }
};
</script>

<style scoped></style>
