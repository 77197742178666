'use strict';

/**
 * @fileoverview Vuex module for partners
 */

import _ from 'lodash';
import {
  fetchPartner,
  insertPartnerMeeting,
  updatePartnerMeeting,
  removePartnerMeeting
} from '@/api/meetings-partners';
import { getAgents } from '@/api/agents';

const state = {
  partnerList: [],
  initialPartner: {},
  partner: {},
  options: {
    isPartner: false
  }
};

const getters = {
  partnerData: state => state.partner,
  partnerListData: state => state.partnerList,
  initialPartnerData: state => state.initialPartner,
  isPartnerModel: state => state.options.isPartner
};

const actions = {
  /**
   * Get all partners
   */
  async getPartnerList({ commit }) {
    try {
      const { data } = await getAgents({ type: 'partner' });

      commit('SET_PARTNER_LIST', data.agents);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get partner
   *
   * @param  {String} shortCode - Meeting shortcode
   */
  async getPartner({ commit }, { shortCode }) {
    try {
      const { data } = await fetchPartner(shortCode);

      if (data && data.key) {
        commit('SET_PARTNER_MODEL', true);
      } else {
        commit('SET_PARTNER_MODEL', false);
      }

      commit('SET_PARTNER', data);
      commit('SET_INITIAL', data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Change current partner
   *
   * @param  {String} shortCode - Meeting shortcode
   */
  async changePartner({ dispatch, state }, { shortCode }) {
    try {
      if (!state.partner && !!state.initialPartner) {
        dispatch('removePartner', { shortCode });
      } else if (!!state.partner && !state.initialPartner) {
        dispatch('insertPartner', { shortCode });
      } else if (!!state.partner && !!state.initialPartner) {
        dispatch('updatePartner', { shortCode });
      }

      await dispatch('getPartnerList');
    } catch (err) {
      throw err;
    }
  },

  /**
   * Create new partner model
   *
   * @param  {String} shortCode  - Meeting shortcode
   */
  async insertPartner({ state, dispatch }, { shortCode }) {
    try {
      await insertPartnerMeeting(shortCode, state.partner.key);
      dispatch('getPartner', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update existing partner model
   *
   * @param  {String} shortCode  - Meeting shortcode
   */
  async updatePartner({ state, dispatch }, { shortCode }) {
    try {
      await updatePartnerMeeting(shortCode, state.partner.key);
      dispatch('getPartner', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove partner model from a campaign
   *
   * @param  {String} shortCode - Meeting shortcode
   */
  async removePartner({ dispatch }, { shortCode }) {
    try {
      await removePartnerMeeting(shortCode);
      dispatch('getPartner', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  setPartner({ commit }, partner) {
    commit('SET_PARTNER', partner);
  },

  setPartnerModel({ commit }, value) {
    commit('SET_PARTNER_MODEL', !!value);
  },

  resetPartner({ commit }) {
    commit('CLEAR_STATE');
  }
};

const mutations = {
  SET_PARTNER_LIST(state, data) {
    const notPartner = state.partnerList.find(p => p.key === state.partner.key);

    if (!notPartner && state.partner.key) {
      data.push(state.partner);
    }

    state.partnerList = data;
  },
  SET_PARTNER(state, data) {
    state.partner = data;
  },
  CLEAR_STATE(state) {
    state.partner = {};
    state.partnerList = [];
    state.initialPartner = {};
    state.options.isPartner = false;
  },
  SET_INITIAL(state, data) {
    state.initialPartner = data;
  },
  SET_PARTNER_MODEL(state, data) {
    state.options.isPartner = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
