<template>
  <v-dialog :value="value" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >Send "Payment Received" Confirmation Email?</span
        >
      </v-card-title>
      <v-card-text>
        <p>
          Looks like this invoice is now paid in full!
        </p>
        <p>
          Would you like to send a confirmation email to
          <b>{{ currentAccount.contact.email }}</b
          >?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click.native="emitClose" flat>close</v-btn
        ><v-spacer></v-spacer>
        <v-btn @click.native="emitSend" color="primary" flat>send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AdminInvoicePaymentReceivedDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    currentAccount: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitSend() {
      this.$emit('send');
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
