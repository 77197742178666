import http from './requestType';

/**
 * Get all proxies archived into a single zip file
 * @param  {string}   shortCode - the meeting shortcode
 * @param  {boolean}  mergeMode - if set to true, merge PDFs before zipping
 * @return {Promise}            - Promise
 */
export function postExportProxies(shortCode, mergeMode = null) {
  const params = {
    mode: mergeMode ? mergeMode : 'merged'
  };
  return http._post(`Meetings/${shortCode}/exports/zip-proxies`, params);
}

/**
 * Export consent forms and report
 * @param  {string}   shortCode - the meeting shortcode
 * @param  {string}   emailOnly - weather or not to filter on email consents
 * @param  {string}   region    - meeting region
 * @param  {string}   version   - report version
 * @param  {boolean}  isClient  - use client report format
 * @param  {Object}   filters   - consent filters
 * @return {Promise}            - Promise
 */
export function postExportConsents({
  shortCode,
  emailOnly,
  region,
  version = null,
  isClient = null,
  filters
}) {
  const params = {
    mode: emailOnly ? 'email-only' : null,
    version,
    isClient,
    filters
  };
  return http._post(
    `Meetings/${shortCode}/exports/consents?region=${region}`,
    params
  );
}

/**
 * Export print files and report
 * @param  {string}   shortCode - the meeting shortcode
 * @param  {object}   filters   - report filters
 * @param  {number}   version   - report version
 * @param  {boolean}  noVoting  - indicate no voting
 * @return {Promise}            - Promise
 */
export function postExportPrintFiles({
  shortCode,
  filters,
  version = null,
  noVoting
}) {
  return http._post(`Meetings/${shortCode}/exports/print-files`, {
    ...filters,
    version,
    noVoting
  });
}

/**
 * Export nominations files
 * @param  {string}   shortCode - the meeting shortcode
 * @return {Promise}            - Promise
 */
export function postExportNominations(shortCode) {
  return http._post(`Meetings/${shortCode}/exports/nominations`);
}

/**
 * Generate and send the advance voting report
 * @param  {string}   shortCode         - the meeting shortcode
 * @param  {boolean}  mergeMode         - if set to true, merge PDFs before zipping
 * @param  {Object}   recipient         - target recipient of the exported file
 * @param {boolean}   includeVoters     - Include Voter information in data
 * @param {boolean}   includeVote       - Include what vote was for
 * @param {boolean}   exportAsBallot    - Whether to export report as ballot
 * @return {Promise}                    - Promise
 */
export function postSendVoteSummaryReport(
  shortCode,
  mergeMode = null,
  recipient,
  includeVoters,
  includeVote,
  exportAsBallot,
  includeVoteInDownload
) {
  const params = {
    mode: mergeMode ? mergeMode : 'merged',
    recipient: recipient,
    includeVoters,
    includeVote,
    exportAsBallot,
    includeVoteInDownload
  };
  return http._post(`Meetings/${shortCode}/exports/send-vote-summary`, params);
}

/**
 * Generate and send link to download all live vote submission PDFs
 * @param  {string}   shortCode - the meeting shortcode
 * @return {Promise}            - Promise
 */
export function postExportLiveBallots(shortCode) {
  return http._post(`Meetings/${shortCode}/exports/ballots`);
}

/**
 * Generate and send the voting results report
 * @param  {string}   shortCode - the meeting shortcode
 * @param  {Object}   recipient - target recipient of the exported file
 * @return {Promise}            - Promise
 */
export function postSendVotingResultsReport(shortCode, recipient) {
  const params = {
    recipient: recipient
  };
  return http._post(
    `Meetings/${shortCode}/exports/send-voting-results`,
    params
  );
}

/**
 * Generate and send link to download voter registration PDFs
 * @param  {string}   shortCode - the meeting shortcode
 * @param  {string}   mode      - the report type
 * @return {Promise}            - Promise
 */
export function postExportVoterRegistrationPage(shortCode, mode) {
  return http._post(`Meetings/${shortCode}/exports/send-registration-pages`, {
    mode
  });
}
