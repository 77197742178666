<template>
  <v-card class="round-card full-height">
    <v-card-title primary-title>
      <v-layout align-center>
        <v-avatar size="30" color="#e7f2fb" class="mr-2">
          <v-icon color="#3874CB" size="20">tv</v-icon>
        </v-avatar>
        <div class="subheading font-weight-bold">
          Meeting Spectators
        </div>
        <v-spacer />
        <v-icon
          color="primary"
          size="25"
          style="cursor: pointer"
          @click.native="openDialog('aboutSpectators')"
          >info</v-icon
        >
      </v-layout>
    </v-card-title>

    <v-card-text class="pt-0">
      <v-flex>
        <v-alert
          :value="true"
          color="#0F64A9"
          outline
          class="mb-3"
          style="border-radius: 4px;"
        >
          <span style="font-size: 12px;"
            >Invite guests such as owners using the information below. Press the
            Information icon for more details.</span
          >
        </v-alert>

        <div class="url-container">
          <span class="ml-2 pt-1 mr-2">Meeting Id:</span>
          <input ref="cast-id" class="url-input" :value="castId" readonly />
          <v-spacer />
          <span
            style="color: rgb(15, 100, 169); cursor: pointer"
            slot="activator"
            @click="handleCopy('cast-id')"
          >
            copy
          </span>
        </div>

        <div class="url-container">
          <span class="ml-2 pt-1 mr-3">Password:</span>
          <input ref="password" class="url-input" :value="password" readonly />
          <v-spacer />
          <span
            style="color: rgb(15, 100, 169); cursor: pointer"
            slot="activator"
            @click="handleCopy('password')"
          >
            copy
          </span>
        </div>

        <div class="url-container">
          <span class="ml-2 pt-1 mr-2">Share Link:</span>
          <input
            ref="livestream-url"
            class="url-input"
            :value="livestreamUrl"
            readonly
          />
          <v-spacer />
          <span
            style="color: rgb(15, 100, 169); cursor: pointer"
            slot="activator"
            @click="handleCopy('livestream-url')"
          >
            copy
          </span>
        </div>

        <p class="ml-2 pt-1 mr-2">
          Dial-in Numbers:
          <v-tooltip v-model="tooltip.show" bottom>
            <template v-slot:activator="{ on }">
              <span class="tooltip" @click="tooltip.show = !tooltip.show"
                >Click to {{ tooltip.show ? 'close' : 'view' }}</span
              ><v-icon small class="ml-1" @click="tooltip.show = !tooltip.show"
                >help</v-icon
              >
            </template>
            <span
              ><strong>Canada or USA (Toll Free):</strong> <br />877-853-5257
              <br />855-703-8985 <br />888-475-4499 <br />833-548-0276
              <br />833-548-0282 <br />877-853-5257</span
            >
          </v-tooltip>
        </p>
      </v-flex>
    </v-card-text>

    <!-- Dialogs -->
    <about-spectators
      v-model="dialog.aboutSpectators"
      @close="dialog.aboutSpectators = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AboutSpectators from './components/MeetingVbmAboutSpectatorsDialog.vue';

export default {
  name: 'MeetingVbmSpectators',
  components: {
    AboutSpectators
  },
  props: {
    shortCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: {
        aboutSpectators: false,
        tollFreePhone: false
      },
      tooltip: {
        show: false
      }
    };
  },
  computed: {
    ...mapGetters('meetings/webcast', ['zoomData']),
    castId() {
      return this.zoomData && this.zoomData.id ? this.zoomData.id : '';
    },
    livestreamUrl() {
      return this.zoomData && this.zoomData.joinUrl
        ? this.zoomData.joinUrl
        : '';
    },
    password() {
      return this.zoomData && this.zoomData.password
        ? this.zoomData.password
        : '';
    }
  },
  methods: {
    openDialog(key) {
      this.dialog[key] = true;
    },
    handleCopy(ref) {
      this.$refs[ref].select();
      document.execCommand('copy');
    }
  }
};
</script>

<style scoped>
.full-height {
  height: 100%;
}
.url-input {
  background-color: #e7f2fb;
  max-width: 200px;
}
.url-container {
  display: flex;
  height: 25px;
  margin: 10px 0px 10px 0px;
  flex-basis: 100%;
}
.tooltip {
  position: relative;
  border-bottom: 1px dashed #000;
  cursor: pointer;
}
.v-tooltip__content {
  font-size: 12px !important;
  color: #000;
  background: white;
  opacity: 1 !important;
}
</style>
