<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <span class="title">Confirm that you want to approve this request</span>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <div>Please ensure that the details below are correct.</div>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <v-list>
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon color="primary">mail</v-icon>
                </v-list-tile-avatar>
                <span><b>Scheduled Email Notices</b></span>
              </v-list-tile>
              <div v-if="hasNotices" class="ml-3 item-text">
                <!-- Notices -->
                <div
                  v-for="notice in currentApproval.notices"
                  :key="notice.key"
                >
                  <span>{{ notice.subject }}</span
                  ><v-chip
                    v-if="notice.sendOn"
                    class="ml-3"
                    color="#f9e2d0"
                    small
                    >Send on
                    {{
                      notice.sendOn | dateFormatReadable(true, meetingTimezone)
                    }}</v-chip
                  >
                </div>
                <!-- Reminders -->
                <div v-if="hasAutoReminders">
                  <span>Reminder Email(s)</span>
                  <v-chip class="ml-3" color="#f9e2d0" small
                    >{{ reminderDates.length }} reminders</v-chip
                  >
                </div>
                <!-- Join Portal -->
                <div v-if="hasAutoJoinPortal">
                  <span>Join Meeting Portal</span>
                  <v-chip class="ml-3" color="#f9e2d0" small
                    >Send on
                    {{
                      joinPortalTime | dateFormatReadable(true, meetingTimezone)
                    }}</v-chip
                  >
                </div>
              </div>
              <div v-else class="ml-3">
                <span
                  >There are no email notices scheduled for this send-out.</span
                >
              </div>
            </v-list>
          </v-flex>
        </v-layout>

        <v-layout row class="mt-1">
          <v-flex xs12><v-divider /></v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <v-list>
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon color="primary">print</v-icon>
                </v-list-tile-avatar>
                <span><b>Packages to be Printed and Mailed</b></span>
              </v-list-tile>
              <div v-if="hasPrintJobs" class="ml-3 item-text">
                <div
                  v-for="printJob in currentApproval.printJobs"
                  :key="printJob.key"
                >
                  <span>{{ printJob.jobName }}</span>
                  <span class="ml-3 print-detail"
                    >{{ printJob.packagesMailed || 0 }} recipients /
                    {{ printJob.packagePagesPrinted || 0 }} pages each</span
                  >
                </div>
              </div>
              <div v-else class="ml-3">
                <span>
                  There are no print jobs scheduled for this send-out.
                </span>
              </div>
            </v-list>
          </v-flex>
        </v-layout>

        <v-layout row class="mt-1">
          <v-flex xs12><v-divider /></v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <v-list>
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon color="primary">comment</v-icon>
                </v-list-tile-avatar>
                <span><b>Additional Comments</b> (optional)</span>
              </v-list-tile>
              <v-textarea
                v-model="comment"
                class="mt-3"
                outline
                placeholder="Add a comment that will be attached to your approval."
              ></v-textarea>
            </v-list>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <span
              >If you need changes to this approval request, please cancel this
              dialog and note your feedback in the activity feed at the bottom
              of the request page.</span
            >
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-spacer />
        <v-btn color="success" @click.native="handleApprove"
          >submit approval</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormatReadable } from '@/filters';
import moment from 'moment';
import getReminderDates from '@/lib/get-auto-reminder-dates';

export default {
  name: 'ApprovalSubmitDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    currentApproval: {
      type: Object,
      required: true
    },
    currentMeeting: {
      type: Object,
      required: true
    },
    meetingReminderSettings: {
      type: Object,
      default() {
        return {};
      }
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    }
  },
  data() {
    return {
      comment: ''
    };
  },
  filters: {
    dateFormatReadable
  },
  computed: {
    hasNotices() {
      return this.currentApproval?.notices.length > 0;
    },
    hasPrintJobs() {
      return this.currentApproval?.printJobs.length > 0;
    },
    hasAutoReminders() {
      return (
        this.meetingReminderSettings.enableReminders &&
        this.meetingReminderSettings.reminderFrequency
      );
    },
    hasAutoJoinPortal() {
      return (
        this.meetingReminderSettings.enableReminders &&
        this.meetingReminderSettings.joinReminder
      );
    },
    reminderDates() {
      const reminderStartDate = this.meetingReminderSettings.reminderStartDate;
      const reminderFrequency = this.meetingReminderSettings.reminderFrequency;
      const meetingDate = this.currentMeeting.meetingDate;

      return getReminderDates(
        reminderStartDate,
        reminderFrequency,
        meetingDate
      );
    },
    joinPortalTime() {
      if (!this.meetingReminderSettings.joinReminder) {
        return null;
      }

      return moment(this.currentMeeting.meetingDate).subtract(
        this.meetingReminderSettings.joinReminder,
        'minutes'
      );
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  mounted() {
    this.resetState();
  },
  methods: {
    handleApprove() {
      this.$emit('approve', { action: 'approve', comment: this.comment });
      this.handleClose();
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    resetState() {
      this.comment = '';
    }
  }
};
</script>

<style scoped>
.item-text {
  font-size: 16px;
}

.print-detail {
  font-size: 14px;
  font-style: italic;
}
</style>
