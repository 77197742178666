import http from './requestType';

/**
 * List all agents
 * @returns {Promise}
 */
export function getAgents({
  page = '',
  size = '',
  descending = false,
  search = '',
  type = ''
}) {
  return http._get(
    `Agents?page=${page}&size=${size}&descending=${descending}&search=${search}&type=${type}`
  );
}

/**
 * Creates a new Agent with the given payload
 * @param {Object} obj - the new agent object
 * @returns
 */
export function createAgent(obj) {
  return http._post(`Agents`, obj);
}

/**
 * Creates a new Agent user with the given payload
 * @param {Object} obj - the new agent user object
 * @returns
 */
export function createAgentUser(obj) {
  const { agentKey } = obj;
  return http._post(`Agents/${agentKey}/users`, obj);
}

/**
 * Removes a user associated with agentKey
 * @param {string} agentUserKey - the agent and agentuser key
 * @returns
 */
export function deleteAgentUser(agentUserKey) {
  return http._delete(`Agents/${agentUserKey}/users`);
}

/**
 * Updates the Agent associated with agentKey
 * @param {string} key - the agent key
 * @param {Object} obj - the agent object
 * @returns {Promise}  - the response
 */
export function putAgent(key, obj) {
  return http._put(`Agents/${key}`, obj);
}

/**
 * Returns the Agent associated with the agentKey
 * @param  {String} key - the Agent key
 * @return {Promise}    - the response
 */
export function getAgent(key) {
  return http._get(`Agents/${key}`);
}

/**
 * Returns the Users associated with the agentKey
 * @param  {String} key - the Agent key
 * @returns {Promise}    - the response
 */
export function getAgentUsers(key) {
  return http._get(`Agents/${key}/users`);
}

/**
 * Returns the Accounts associated with the agentKey
 * @param  {String} key - the Agent key
 * @returns {Promise}    - the response
 */
export function getAgentAccounts(key) {
  return http._get(`Agents/${key}/accounts`);
}

/**
 * Gets the list of preferred managers belonging to this agent
 *
 * @param  {String} key  - the Agent Key
 * @return {Promise}     - the response
 */
export function getAgentPreferredManagers(key) {
  return http._get(`Agents/${key}/preferred-managers`);
}

/**
 * Add a preferred manager from this agent
 *
 * @param  {String} agentKey  - the Agent Key
 * @param  {String} userKey     - the User Key
 * @return {Promise}            - the response
 */
export function addAgentPreferredManager({ agentKey, userKey }) {
  return http._post(`Agents/${agentKey}/preferred-managers/`, {
    agentKey,
    userKey
  });
}

/**
 * Remove a preferred manager from this agent
 *
 * @param  {String} agentKey  - the Agent Key
 * @return {Promise}            - the response
 */
export function deleteAgentPreferredManager({ agentKey }) {
  return http._delete(`Agents/${agentKey}/preferred-managers/`);
}
