<template>
  <v-container class="py-0">
    <v-radio-group v-model="selectedVoter">
      <span>
        <v-radio
          v-for="voter in voterList"
          :key="voter.voterKey"
          :label="`${voter.name} (${voter.email})`"
          :value="voter"
        />
      </span>
    </v-radio-group>
    <v-fade-transition>
      <div>
        <v-alert
          v-if="existingProxy"
          :value="true"
          color="error"
          icon="warning"
          outline
        >
          A proxy already exists for this unit, submitted by
          <strong
            >{{ existingProxySource.name }} ({{
              existingProxySource.email
            }})</strong
          >
          on
          <strong>{{
            existingProxy.createdAt | dateFormatReadable(true)
          }}</strong
          >. This proxy will be revoked if you continue with this submission.
        </v-alert>
        <v-alert
          v-if="!isRtvProxyOverrideEnabled && liveVoteExists"
          :value="true"
          color="error"
          icon="error"
          outline
        >
          A live vote has already been submitted for this unit. You will not be
          able to continue with this submission.
        </v-alert>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'MeetingProxiesCreateOwnershipRadioButtons',
  props: {
    voters: {
      type: Array,
      default() {
        return [];
      }
    },
    existingProxy: {
      default() {
        return null;
      }
    },
    liveVoteExists: {
      type: Boolean,
      default() {
        return false;
      }
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    dateFormatReadable
  },
  computed: {
    // Use the metaData source name and email when proxy is created via dashboard
    existingProxySource() {
      if (_.has(this.existingProxy, 'metaData.source')) {
        return {
          name: this.existingProxy.metaData.source.name,
          email: this.existingProxy.metaData.source.email
        };
      } else {
        return {
          name: this.existingProxy.name,
          email: this.existingProxy.email
        };
      }
    },
    isRtvProxyOverrideEnabled() {
      return this.options.enableRtvProxyOverride;
    }
  },
  watch: {
    selectedVoter: function(voter) {
      this.$emit('selected', voter);
    }
  },
  data() {
    return {
      selectedVoter: null,
      voterList: _.cloneDeep(this.voters)
    };
  },
  methods: {}
};
</script>

<style scoped></style>
