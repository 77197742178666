<template>
  <v-card class="px-2 py-2 card" :elevation="0">
    <div v-if="requestAssignee" class="d-flex items-center justify-between">
      <v-icon color="primary" large>account_circle</v-icon>
      <v-card-text>
        <b>{{ assignee }}</b> is the CSM handling this request.</v-card-text
      >
      <v-card-actions>
        <v-btn
          v-if="isAssignee"
          outline
          color="primary"
          :disabled="isApproved || isDenied"
          @click.native="assign"
        >
          Assign another CSM
        </v-btn>
      </v-card-actions>
    </div>

    <div v-else class="d-flex items-center justify-between">
      <v-icon color="primary" large>account_circle</v-icon>

      <v-card-text
        >A member of our team will reach out to you shortly. Please contact us
        at
        <a target="_blank" href="mailto:contact@getquorum.com"
          >contact@getquorum.com</a
        >, direct: 647-495-9144 or toll free: 1-877-353-9450 if you need
        support.</v-card-text
      >
      <!-- <v-card-actions>
        <v-btn
          v-if="isAssignee"
          outline
          color="primary"
          :disabled="isApproved || isDenied"
          @click.native="assign"
        >
          Assign CSM
        </v-btn>
      </v-card-actions> -->
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'AssigneeCard',
  props: {
    requestAssignee: {
      type: Object,
      default: null
    },
    isAssignee: {
      type: Boolean,
      default: false
    },
    isApproved: {
      type: Boolean,
      default: false
    },
    isDenied: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    assignee() {
      return `${this.requestAssignee.first_name} ${this.requestAssignee.last_name}`;
    }
  },
  methods: {
    assign() {
      this.$emit('handle-action');
    }
  }
};
</script>

<style scoped>
.card {
  border: 2px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}
</style>
