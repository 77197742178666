<template>
  <v-container grid-list-sm>
    <v-layout row>
      <v-flex xs6>
        <v-autocomplete
          autocomplete
          label="Region Code (optional autofill)"
          placeholder="eg. TSCC, PSCC"
          :items="regionItem"
          v-model="autofill.region"
          @input="computeAutofill"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          name="number"
          label="Corp No."
          placeholder="eg. 1234"
          v-model="autofill.number"
          @input="computeAutofill"
        />
      </v-flex>
    </v-layout>

    <!-- Input fields for newAccount -->
    <div class="autofill-container">
      <div class="blue--text caption note">
        <v-icon class="pr-1" color="blue" small>star</v-icon>
        <div>
          This section is autofilled based on the above values. You can edit
          these fields directly.
        </div>
      </div>
      <v-text-field
        label="Name"
        placeholder="eg. Toronto Standard Condominium Corporation No. 1234"
        @input="emitUpdate('name', $event)"
        :value="name"
      />
      <v-text-field
        label="Short Name"
        placeholder="eg. TSCC 1234"
        @input="emitUpdate('nameShort', $event)"
        :value="nameShort"
      />
      <v-select
        class="pb-0 mb-0"
        label="Customer Type"
        :items="customerTypes"
        item-text="label"
        item-value="value"
        @input="emitUpdate('customerType', $event)"
        :value="customerType"
      />
      <v-select
        class="pb-0 mb-0"
        label="Region"
        :items="regions"
        item-text="label"
        item-value="value"
        @input="emitUpdate('region', $event)"
        :value="region"
      />
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { regionObjList } from '@/lib/default-account-lists';

export default {
  name: 'InputCorp',
  props: {
    name: { type: String, default: '' },
    nameShort: { type: String, default: '' },
    customerType: { type: String, default: '' },
    region: { type: String, default: '' },
    customerTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    regions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      regionItem: [],
      regionList: [],
      autofill: {
        region: '',
        number: ''
      }
    };
  },
  created() {
    this.regionList = regionObjList;
    // Populate regionItem options
    this.regionItem = _.map(regionObjList, 'nameShort');
  },
  methods: {
    computeAutofill() {
      let regionObj = _.find(this.regionList, {
        nameShort: this.autofill.region
      });
      let name = `${regionObj.name} ${this.autofill.number}`;
      let nameShort = `${regionObj.nameShort} ${this.autofill.number}`;
      // call method to emit update
      this.emitUpdate('name', name);
      this.emitUpdate('nameShort', nameShort);
      this.emitUpdate('customerType', 'condo');
    },
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped>
.autofill-container {
  padding: 15px 15px 0px 15px;
  background-color: #e1f5fe;
}
.newAccount-container {
  padding-top: 10px;
}
.note {
  padding-bottom: 10px;
  display: flex;
  align-content: center;
}
</style>
