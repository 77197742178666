<template>
  <router-view />
</template>

<script>
export default {
  name: 'AdminInvoicesMain'
};
</script>

<style scoped>
</style>
