import axios from 'axios';
import silentRefresh from './silent-refresh';
import getFileExt from '@/lib/get-file-ext';

const instance = axios.create();

instance.interceptors.response.use(response => response, silentRefresh);

export default {
  _get,
  _put,
  _patch,
  _post,
  _delete,
  _putAWS,
  instance
};

/** BASIC HTTP REQUESTS **/
/**
 * Private function to make a get call
 * @param {string} path - the API path to call
 * @param {string} params - optional query parameters
 * @return {Promise} - Promise
 */
function _get(path, params = {}) {
  const url =
    path.indexOf('http') === 0 ? path : process.env.VUE_APP_API_BASE_URL + path;
  return instance.get(`${url}`, { params, withCredentials: true });
}

/**
 * Private function to make a put request
 * @param {string} path - the API path to call
 * @param {object} input - object to be submitted
 * @return {Promise} - Promise
 */
function _put(path, input) {
  const url =
    path.indexOf('http') === 0 ? path : process.env.VUE_APP_API_BASE_URL + path;

  return instance.put(`${url}`, input, { withCredentials: true });
}

/**
 * Private function to make a patch request
 * @param {string} path - the API path to call
 * @param {object} input - object to be submitted
 * @return {Promise} - Promise
 */
function _patch(path, input) {
  const url =
    path.indexOf('http') === 0 ? path : process.env.VUE_APP_API_BASE_URL + path;
  return instance.patch(`${url}`, input, { withCredentials: true });
}

/**
 * Private function to make a put request
 * @param {string} url - the url to call
 * @param {object} file - file to be submitted
 * @param {object} config - configuration options
 * @return {Promise} - Promise
 */
function _putAWS(url, file) {
  const ext = getFileExt(file.name);

  // Explicitly force the Content-Type to be 'text/csv' since Windows will
  // treat csv files as `application/vnd.ms-excel when Excel is installed on
  // the computer.  Otherwise, if it's not a CSV then use whatever the
  // browser detects.
  const contentType = ext === 'csv' ? 'text/csv' : file.type;

  const configVal = {
    headers: { 'Content-Type': contentType }
  };
  return instance.put(`${url}`, file, configVal);
}

/**
 * Private generic function to make a post submission request
 * @param  {string} path the API path to call
 * @param  {object}  input variables to be submitted
 * @param  {object} [params] additional query params
 * @return {Promise} - Promise
 */
function _post(path, input, params = {}) {
  const url =
    path.indexOf('http') === 0 ? path : process.env.VUE_APP_API_BASE_URL + path;
  return instance.post(`${url}`, input, { params, withCredentials: true });
}

/**
 * Private generic function to make a post submission request
 * @param  {string} path the API path to call
 * @return {Promise} - Promise
 */
function _delete(path) {
  const url =
    path.indexOf('http') === 0 ? path : process.env.VUE_APP_API_BASE_URL + path;
  return instance.delete(`${url}`, { withCredentials: true });
}
