<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Voter Registration Covers Export</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-container class="pt-0">
        <v-layout row wrap>
          <h4 class="mt-3">What units include in the export?</h4>
          <v-flex xs12>
            <v-radio-group v-model="reportType">
              <v-radio value="all">
                <template v-slot:label>
                  <div>
                    <h5 class="subheading">
                      All Units
                    </h5>
                  </div>
                </template>
              </v-radio>
              <v-radio value="no-email">
                <template v-slot:label>
                  <div>
                    <h5 class="subheading">
                      Units with no email on file
                    </h5>
                  </div>
                </template>
              </v-radio>
              <v-radio value="no-consent">
                <template v-slot:label>
                  <div>
                    <h5 class="subheading">Units with no consent on file</h5>
                    <div class="markdown-label">
                      If multiple owners, there will be a registration page for
                      each one.
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn
            @click.native="sendReport()"
            color="primary"
            class="white--text"
            >Send Report</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VoterRegistrationReportDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reportType: 'all'
    };
  },
  computed: {},
  methods: {
    sendReport() {
      this.$emit('send', this.reportType);
    }
  }
};
</script>

<style scoped>
.markdown-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  visibility: visible;
  margin-bottom: 5px;
}
</style>
