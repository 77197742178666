<template>
  <router-view
    :current-meeting="currentMeeting"
    :current-user="localProfile"
    :meeting-print-job-data="meetingPrintJobData"
    :vendor-list-data="vendorListData"
    :data-loaded="dataLoaded"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingPrintJobsMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('meetings/printJobs', [
      'meetingPrintJobData',
      'vendorListData'
    ]),
    localProfile() {
      return JSON.parse(sessionStorage.getItem('profile'));
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/printJobs', [
      'getMeetingPrintJobList',
      'stubNewMeetingPrintJob',
      'getVendors'
    ]),
    async init() {
      try {
        this.dataLoaded = false;
        await this.getMeetingPrintJobList({ key: this.currentMeeting.key });
        await this.getVendors();
        this.dataLoaded = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
</style>
