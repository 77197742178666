<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Print Jobs</p>
        <p class="secondary--text">
          View all print jobs sent through the platform. Click on a row to view
          more details about the job.
        </p>
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs12>
        <v-toolbar dark color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            :value="searchText"
            @input="updateSearch"
          />&nbsp;
          <v-spacer />
          <v-select
            box
            solo
            light
            single-line
            :items="statusFilterOptions"
            item-text="text"
            item-value="value"
            :value="statusFilter"
            @input="updateFilter"
            label="Filter Status"
            hide-details
          />
          <v-btn
            flat
            icon
            @click.native="loadPrintJobs"
            :loading="loading"
            color="secondary"
          >
            <v-icon>cached</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="printJobData"
          :rows-per-page-items="config.rowsPerPageItems"
          :total-items="pagination.totalItems"
          :pagination.sync="config.pagination"
          @update:pagination="paginationUpdate"
          class="elevation-1"
          :loading="loading"
        >
          <template slot="items" slot-scope="props">
            <tr @click="itemExpanded(props)">
              <td class="avatar-cell">
                <v-avatar size="40px" color="primary lighten-2">
                  <v-icon color="white">print</v-icon>
                </v-avatar>
              </td>
              <td>
                <a
                  @click.stop="goPrintJob(props.item.key, props.item.shortCode)"
                >
                  <b>{{ props.item.jobName | trimName }}</b>
                </a>
              </td>
              <td>{{ props.item.jobId }}</td>
              <td>
                <v-chip
                  small
                  disabled
                  :color="getStatusChipColor(props.item)"
                  text-color="white"
                  >{{ getStatusTitle(props.item) }}</v-chip
                >
              </td>
              <td nowrap>{{ props.item.updatedAt | dateFromNow }}</td>
              <td nowrap>
                {{ props.item.createdAt | formatDateHumanReadable }}
              </td>
              <td>
                <div class="text-xs-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="
                          !['SENT', 'IN_PROGRESS', 'COMPLETED'].includes(
                            props.item.status
                          )
                        "
                        flat
                        icon
                        color="completedStatusColor(props.item.status)"
                        @click.stop
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-for="(action, index) in actions"
                        :key="index"
                        @click.native.stop="
                          runCommand(
                            props.item.key,
                            action.name,
                            props.item.shortCode
                          )
                        "
                      >
                        <v-list-tile-title class="caption">{{
                          props.item.status !== 'COMPLETED'
                            ? action.label
                            : 'Update Completion'
                        }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </template>

          <!-- Table Row Expander -->
          <template v-slot:expand="props">
            <job-list-item-expand
              :item="props.item"
              :current-item-data="currentItemData"
              :loading="expandLoading"
            />
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <complete-print-job-dialog
      :short-code="currentItemData.shortCode"
      :is-open="showCompletePrintJobDialog"
      :poc-files="currentItemData.poc"
      :postage-cost="currentItemData.postageCost"
      @upload="handlePocUpload($event)"
      @submit="completeJob"
      @close-dialog="showCompletePrintJobDialog = false"
    >
      <span slot="title">Complete Print Job</span>
      <span slot="description"
        >Upload Proof of Completion and Mark this Print Job as Completed</span
      >
    </complete-print-job-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import JobListItemExpand from './AdminPrintJobsListItemExpand';
import CompletePrintJobDialog from '@/components/dialogs/CompletePrintJobDialog';
import { handleError } from '@/helpers';
import { formatDateHumanReadable, dateFromNow } from '@/filters';

export default {
  name: 'AdminPrintJobsListView',
  components: {
    CompletePrintJobDialog,
    JobListItemExpand
  },
  filters: {
    formatDateHumanReadable,
    dateFromNow,
    trimName(name) {
      const search = 'getquorum mailing for ';
      const index = name.toLowerCase().indexOf(search);
      return name.slice(index + search.length);
    }
  },
  watch: {
    async pagination() {
      await this.loadPrintJobs();
    }
  },
  data() {
    return {
      loading: false,
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }],
        pagination: {}
      },
      statusFilterOptions: [
        { text: 'All Statuses', value: 'all' },
        { text: 'Sent / In Progress / Draft', value: 'activeOnly' },
        { text: 'Completed', value: 'completedOnly' }
      ],
      headers: [
        { sortable: false, width: '40px' },
        { text: 'MAILING NAME', value: 'jobName' },
        { text: 'PRINT JOB ID', value: 'jobId' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'LAST UPDATED', value: 'updatedAt' },
        { text: 'CREATED AT', value: 'createdAt' },
        { sortable: false }
      ],
      searchText: '',
      expand: false,
      expandLoading: false,
      currentItemData: {
        jobKey: '',
        shortCode: '',
        printing: [],
        recipients: [{}],
        poc: [{}]
      },
      actions: [
        {
          name: 'MARK_COMPLETED',
          label: 'Mark Completed'
        }
      ],
      showCompletePrintJobDialog: false
    };
  },
  computed: {
    ...mapGetters('admin/printJobs', [
      'printJobData',
      'statusFilter',
      'pagination',
      'printJobByKey'
    ])
  },
  created() {
    this.init();
    // Get the current pagination values from store
    this.config.pagination = this.pagination;
  },
  methods: {
    ...mapActions('admin/printJobs', [
      'getPrintJobList',
      'getPrintJobItems',
      'savePrintJobItems',
      'completePrintJob',
      'setSearchText',
      'setStatusFilter',
      'setPagination'
    ]),
    async loadPrintJobs() {
      try {
        this.loading = true;

        const status = this.statusFilter;
        await this.getPrintJobList({ status });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
        handleError(err);
      } finally {
        this.loading = false;
      }
    },
    updateFilter(filter) {
      this.setStatusFilter(filter);
      this.loadPrintJobs();
    },
    async init() {
      await this.setStatusFilter('activeOnly');
      await this.setPagination({
        descending: false,
        searchText: '',
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        rowsPerPageItems: [25, 50, 100, 200]
      });
      await this.loadPrintJobs();
    },
    paginationUpdate(e) {
      this.setPagination(e);
    },
    async getItems(jobKey, shortCode) {
      try {
        this.clearItems();
        this.expandLoading = true;
        const items = await this.getPrintJobItems(jobKey);
        const printJobData = this.printJobByKey(jobKey);
        this.currentItemData.jobKey = jobKey;
        this.currentItemData.shortCode = shortCode;
        this.currentItemData.postageCost = printJobData.postageCost;
        for (let item of items) {
          if (item.jobItemType === 'printing') {
            this.currentItemData.printing.push(item.filePackage);
          } else if (item.jobItemType === 'recipients') {
            // currently, only one recipient file allowed per job
            this.currentItemData.recipients = [item.filePackage];
          } else if (item.jobItemType === 'poc') {
            // currently only one PoC file allowed per job
            this.currentItemData.poc = [item.filePackage];
          }
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.expandLoading = false;
      }
    },
    clearItems() {
      this.currentItemData = {
        jobKey: '',
        shortCode: '',
        postageCost: '',
        printing: [],
        recipients: [],
        poc: []
      };
    },
    goPrintJob(key, shortcode) {
      const routeData = this.$router.resolve({
        name: 'meetingPrintJobEdit',
        params: { shortcode, key }
      });
      window.open(routeData.href, '_blank');
    },
    getStatusChipColor(item) {
      const status = item.status;
      const getColor = status =>
        ({
          IN_PROGRESS: 'yellow darken-2',
          SENT: 'primary',
          ON_HOLD: 'warning',
          COMPLETED: 'green'
        }[status] || 'secondary');
      const chipColor = getColor(status);
      return chipColor;
    },
    getStatusTitle(item) {
      const status = item.status;
      const titles = {
        DRAFT: 'Draft',
        SENT: 'Sent',
        IN_PROGRESS: 'In Progress',
        ON_HOLD: 'On Hold',
        COMPLETED: 'Completed'
      };
      return titles[status];
    },
    completedStatusColor(status) {
      return status === 'COMPLETED' ? 'success' : 'grey';
    },
    itemExpanded(props) {
      if (!props.expanded) {
        this.getItems(props.item.key);
      }
      return (props.expanded = !props.expanded);
    },
    async runCommand(jobKey, action, shortCode) {
      if (action === 'MARK_COMPLETED') {
        await this.getItems(jobKey, shortCode);
        this.showCompletePrintJobDialog = true;
      }
    },
    handlePocUpload(files) {
      // note: currently, only one PoC file allowed per job
      let items = [];
      if (files.length > 0) {
        let itemFile = files[0];
        let printJobItem = {
          Bucket: itemFile.Bucket,
          Key: itemFile.Key,
          size: itemFile.size,
          name: itemFile.name,
          mimetype: itemFile.mimetype,
          url: itemFile.url
        };
        items.push(printJobItem);
      }
      this.currentItemData.poc = items;
    },
    async completeJob(pocData) {
      try {
        const itemPayload = {
          printing: this.currentItemData.printing,
          recipients: this.currentItemData.recipients,
          poc: this.currentItemData.poc
        };
        await this.savePrintJobItems({
          jobKey: this.currentItemData.jobKey,
          jobItems: itemPayload,
          force: true
        });
        const payload = {
          jobKey: this.currentItemData.jobKey,
          postageCost: pocData.postageCost
        };
        await this.completePrintJob(payload);
        this.showCompletePrintJobDialog = false;
        this.$events.$emit('toastEvent', 'Print Job Completed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    updateSearch: _.debounce(function(value) {
      const newPagination = _.cloneDeep(this.pagination);
      newPagination.searchText = value;
      this.paginationUpdate(newPagination);
    }, 350)
  }
};
</script>

<style scoped>
.avatar-cell {
  padding-right: 0px !important;
}

.name-cell {
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.secondary-label {
  opacity: 0.74;
  font-size: 12px;
}
</style>
