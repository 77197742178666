import { render, staticRenderFns } from "./DetailsDocumentsCard.vue?vue&type=template&id=7c309b17&scoped=true"
import script from "./DetailsDocumentsCard.vue?vue&type=script&lang=js"
export * from "./DetailsDocumentsCard.vue?vue&type=script&lang=js"
import style0 from "./DetailsDocumentsCard.vue?vue&type=style&index=0&id=7c309b17&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c309b17",
  null
  
)

export default component.exports