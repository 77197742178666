<template>
  <div>
    <v-layout row wrap>
      <!-- Col 2 -->
      <v-flex xs12 md6>
        <!-- Customer Info -->
        <v-layout>
          <v-flex xs12>
            <customer-card :current-meeting="currentMeeting" />
          </v-flex>
        </v-layout>

        <!-- Print Jobs -->
        <v-layout>
          <v-flex xs12>
            <printing-card />
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- End Col 2  -->

      <!-- Column 1 -->
      <v-flex xs12 md6>
        <!-- Meeting fees / subscription info -->
        <v-layout>
          <v-flex xs12>
            <meeting-fees-card />
          </v-flex>
        </v-layout>
        <!--Additional Discounts -->
        <v-layout v-if="showDiscounts">
          <v-flex xs12>
            <discounts-card />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <proof-card :current-meeting="currentMeeting" />
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- End Col 1 -->
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import CustomerCard from './CustomerCard.vue';
import DiscountsCard from './DiscountsCard.vue';
import MeetingFeesCard from './MeetingFeesCard.vue';
import PrintingCard from './PrintingCard.vue';
import ProofCard from './ProofCard.vue';

export default {
  name: 'MeetingBillingServices',
  components: {
    MeetingOverviewCard,
    CustomerCard,
    DiscountsCard,
    MeetingFeesCard,
    PrintingCard,
    ProofCard
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('meetings/billing', [
      'billingSettings',
      'moderatorFee',
      'printJobs',
      'discount',
      'items',
      'expenses',
      'discounts',
      'tax'
    ]),
    showDiscounts() {
      // If we're billing in Chargebee,
      // don't show "Additional Discounts / Credits" card
      return !this.billingSettings.chargebeeBilling;
    }
  }
};
</script>
