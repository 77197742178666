<template>
  <div>
    <p>
      <b>UserTask:</b>
      {{userTaskKey}}
    </p>

    <ul>
      <li v-for="(event, index) in events" :key="index">
        <pre :class="getClass(event.status)">{{event.createdAt | formatDateHumanReadable(true)}} - {{event.message}}</pre>
      </li>
    </ul>

    <div v-if="error" class="red--text">
      <p>Error Details:</p>
      <pre>{{error}}</pre>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pusher from 'pusher-js';
import * as userTasksApi from '@/api/user-tasks';
import { formatDateHumanReadable } from '@/filters';
import _ from 'lodash';

// Only enable pusher logging on development
Pusher.logToConsole = process.env.NODE_ENV === 'development';

export default {
  name: 'UserTaskEventsTracker',
  props: {
    userTaskKey: {
      type: String,
      required: true
    },
    // The pusher properties
    pusherProps: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatDateHumanReadable
  },
  watch: {
    events: function(events) {
      let self = this;
      clearTimeout(this.timeOut);

      if (this.pollingActive) {
        this.timeOut = setTimeout(function() {
          self.getUserTaskEvents();
        }, 10000);
      }
    }
  },
  mounted() {
    this.initPusher();
    this.subscribe();
    this.bindEvents();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  data() {
    return {
      appKey: this.pusherProps.appKey,
      cluster: this.pusherProps.cluster,
      authEndpoint: this.pusherProps.authEndpoint,
      channel: this.pusherProps.channel,
      pusher: null,
      events: [],
      error: null,
      timeout: null,
      pollingActive: true
    };
  },
  methods: {
    // Set events to most current history from DB
    async getUserTaskEvents() {
      try {
        const res = await userTasksApi.listUserTaskEvents(this.userTaskKey);

        // Find an event status that is completed or error
        let event = _.find(res.data, function(event) {
          return event.status === 'completed' || event.status === 'error';
        });

        // If completed or error status is found, log the event and unsubscribe
        if (event) {
          this.logEvent(event);
          this.unsubscribe();

          // Show the full error message
          if (event.status === 'error') {
            this.error = event.data;
          }

          // disable Polling once complete
          this.pollingActive = false;
        } else {
          // Otherwise log all the events that have occured
          this.events = [...res.data];
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    initPusher() {
      console.log(this.appKey, this.cluster, this.authEndpoint, this.channel);

      this.pusher = new Pusher(this.appKey, {
        cluster: this.cluster,
        encrypted: true,
        authEndpoint: this.authEndpoint,
        authorizer: function(channel, options) {
          return {
            authorize: function(socketId, cb) {
              return userTasksApi
                .postUserTaskSubscribe(options.authEndpoint, {
                  socketId,
                  channel
                })
                .then(data => cb(null, data))
                .catch(err => {
                  console.error('Error authorizing with pusher', err);
                  throw err;
                });
            }
          };
        }
      });
    },
    subscribe() {
      this.pusher.subscribe(this.channel);
    },
    unsubscribe() {
      this.pusher.unsubscribe(this.channel);
    },
    bindEvents() {
      // After acknowledging a subscription event, retrieve all previous event history in database
      this.pusher.bind(
        'pusher:subscription_succeeded',
        this.getUserTaskEvents()
      );

      this.pusher.bind('in-progress', event => {
        this.logEvent(event);
      });

      this.pusher.bind('completed', event => {
        this.logEvent(event);
        this.unsubscribe();
        this.pollingActive = false;
      });

      this.pusher.bind('error', event => {
        this.logEvent(event);
        this.unsubscribe();
        this.pollingActive = false;
        this.error = event.data;
      });
    },
    logEvent(event) {
      this.$emit('update', event);
      this.events.push(event);
    },
    // Return class name based on status
    getClass(status) {
      switch (status) {
        case 'error':
          return 'red--text';
        case 'completed':
          return 'green--text';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.error-text {
  color: red;
}
</style>
