<template>
  <v-container v-if="currentApproval">
    <v-layout class="mx-2 mb-2" align-center>
      <v-flex xs12>
        <v-layout row wrap align-center>
          <v-flex xs12>
            <div class="space-between align-center">
              <h5 class="headline font-weight-bold">
                {{ currentApproval.name }}
                <v-chip
                  small
                  v-if="currentApproval.approvedAt"
                  color="purple"
                  class="white--text"
                  >Approved</v-chip
                >
                <v-chip
                  v-else-if="currentApproval.status === 'open'"
                  color="green"
                  class="white--text"
                  >Open</v-chip
                >
                <v-chip v-else color="grey" class="white--text">Closed</v-chip>
                <v-chip
                  v-if="requestDeadline"
                  color="#e8def8"
                  class="black--text"
                >
                  Deadline: {{ requestDeadline }}</v-chip
                >
                <v-btn
                  v-if="isAdmin && isOpen"
                  icon
                  flat
                  color="primary"
                  @click="dialog.editing = true"
                >
                  <v-icon>edit</v-icon></v-btn
                >
              </h5>
            </div>
          </v-flex>
        </v-layout>
        <v-divider class="gq-divider" />

        <v-layout row wrap class="mt-3">
          <v-flex xs4 lg3>
            <owner-list
              :owners="currentApproval.owners || {}"
              :is-open="isOpen"
            />
          </v-flex>
          <v-flex xs4 lg5>
            <reviewer-list
              :reviewers="currentApproval.reviewers || {}"
              :is-open="isOpen"
              :approval-name="currentApproval.name"
              :approval-meeting="currentMeeting.name"
              :approved-by="approvedBy"
            />
          </v-flex>
          <v-flex xs4 lg4>
            <approval-card
              v-if="!isClosed"
              :reviewers="currentApproval.reviewers || {}"
              :is-approved="isApproved"
              :request-deadline="requestDeadline"
              @submit="dialog.confirmSubmit = true"
            />
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mt-3">
          <v-flex xs12 lg12 class="mb-4">
            <h2 class="font-weight-bold">
              Review Notice Details
              <v-btn flat color="primary" @click="hideAll">Hide All</v-btn>
            </h2>
          </v-flex>
          <v-flex xs12 lg12>
            <notice-details
              :panel="panel"
              :current-meeting="currentMeeting"
              :current-approval="currentApproval"
              :meeting-reminder-settings="meetingReminderSettings"
            />
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mt-3">
          <v-flex xs12 lg12 class="mb-4">
            <h2 class="font-weight-bold">
              Activity Feed
            </h2>
          </v-flex>
          <v-flex xs10 lg10>
            <activity-feed
              :is-approved="isApproved"
              :is-closed="isClosed"
              :events="currentApproval.events"
              @comment="postReview"
              @close="dialog.confirmClose = true"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <approval-submit-dialog
      :is-open="dialog.confirmSubmit"
      :current-approval="currentApproval"
      :current-meeting="currentMeeting"
      :meeting-reminder-settings="meetingReminderSettings"
      @approve="postReview"
      @close="dialog.confirmSubmit = false"
      max-width="700px"
    />
    <edit-request-dialog
      :is-open="dialog.editing"
      :current-approval="currentApproval"
      :meeting-timezone="currentMeeting.meetingTimezone"
      @close="dialog.editing = false"
    />
    <approval-close-dialog
      :is-open="dialog.confirmClose"
      @submit="postClose"
      @close="dialog.confirmClose = false"
      max-width="600px"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import OwnerList from './components/OwnerList.vue';
import ReviewerList from './components/ReviewerList.vue';
import ApprovalCard from './components/ApprovalCard.vue';
import NoticeDetails from './components/NoticeDetails.vue';
import ActivityFeed from './components/ActivityFeed.vue';
import ApprovalSubmitDialog from './components/ApprovalSubmitDialog.vue';
import ApprovalCloseDialog from './components/ApprovalCloseDialog.vue';
import EditRequestDialog from './components/EditRequestDialog.vue';

export default {
  name: 'MeetingApprovalEditView',
  components: {
    OwnerList,
    ReviewerList,
    ApprovalCard,
    NoticeDetails,
    ActivityFeed,
    ApprovalSubmitDialog,
    EditRequestDialog,
    ApprovalCloseDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      arKey: this.$route.params.arKey,
      loading: false,
      panel: [true, true, true, true], // all sections expanded by default
      dialog: {
        confirmSubmit: false,
        editing: false,
        confirmClose: false
      },
      approvalState: {}
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings', ['meetingReminderSettings']),
    ...mapGetters('meetings/approvals', ['approval']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),
    currentApproval() {
      return this.approval(this.arKey);
    },
    isApproved() {
      return !_.isEmpty(this.currentApproval.approvedAt);
    },
    isClosed() {
      return this.currentApproval.status === 'closed' && !this.isApproved;
    },
    isOpen() {
      return this.currentApproval.status === 'open';
    },
    isButtonDisabled() {
      return _.isEmpty(this.approvalState.name);
    },
    approvedBy() {
      const approvalEvent = _.find(
        this.currentApproval.events,
        event => event.event === 'approve'
      );

      if (approvalEvent) {
        return approvalEvent.createdBy;
      }

      return '';
    },
    requestDeadline() {
      return this.currentApproval.dueAt
        ? moment(this.currentApproval.dueAt).format('MMM DD, YYYY')
        : moment().format('MMM DD, YYYY');
    }
  },
  async created() {
    this.loading = true;
    await this.fetchData();
  },
  methods: {
    ...mapActions('meetings', ['getMeetingReminderSettings']),
    ...mapActions('meetings/approvals', [
      'getMeetingApproval',
      'submitReview',
      'setCloseApproval'
    ]),
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    async fetchData() {
      try {
        this.loading = true;
        await this.getMeetingApproval({
          shortCode: this.shortCode,
          arKey: this.arKey
        });
        await this.getMeetingBusiness({ shortCode: this.shortCode });
        await this.getMeetingReminderSettings({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    async postReview({ action, comment }) {
      try {
        this.loading = true;
        await this.submitReview({
          shortCode: this.shortCode,
          arKey: this.arKey,
          comment,
          action
        });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    async postClose({ comment }) {
      try {
        this.loading = true;
        await this.setCloseApproval({
          shortCode: this.shortCode,
          arKey: this.arKey,
          comment
        });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    hideAll() {
      this.panel = new Array(3).fill(false);
    },
    setApproval(value) {
      // fill local state with approval data
      this.approvalState.name = value.name;
    },
    handleUpdate(key, value) {
      // Lodash search state object for matching key
      _.set(this.approvalState, key, value);
    }
  }
};
</script>

<style scoped>
.space-between {
  display: flex;
  justify-content: space-between;
}

.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 0px;
}
</style>
