<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Notice Schedule</p>
        <p class="secondary--text">
          Select a date range to view the sent and scheduled email notices for
          that period.
        </p>
      </v-flex>
    </v-layout>

    <v-layout row class="ma-3">
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <span class="title">Search options</span>
          </v-card-title>
          <v-card-text>
            <v-layout row>
              <v-flex xs4>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  v-model="menu.fromDate"
                  transition="scale-transition"
                  offset-y
                  full-width
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    label="From Date"
                    v-model="query.fromDate"
                    readonly
                  />
                  <v-date-picker v-model="query.fromDate" scrollable actions />
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  v-model="menu.toDate"
                  transition="scale-transition"
                  offset-y
                  full-width
                  :nudge-right="40"
                  max-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    label="To Date"
                    v-model="query.toDate"
                    readonly
                  />
                  <v-date-picker v-model="query.toDate" scrollable actions />
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs4>
                <v-select
                  label="Status Types"
                  v-model="query.statusChoice"
                  :items="statusOption"
                  multiple
                  chips
                />
              </v-flex>
              <v-flex xs4>
                <v-switch
                  label="Include Unscheduled"
                  v-model="query.includeUnscheduled"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout
      row
      class="ma-3"
      v-for="(week, index) in noticeWeekGroups"
      :key="`key-${index}`"
    >
      <notice-week :key="`key-${index}`" :week="week" />
    </v-layout>

    <v-layout v-show="noResults" class="ma-3" row>
      <v-flex xs12>
        <v-alert outline color="info" icon="info" :value="noResults">
          No results found.
        </v-alert>
      </v-flex>
    </v-layout>

    <div v-show="loading === true">
      <v-progress-circular indeterminate :size="50" color="primary" />
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { handleError } from '@/helpers';

import NoticeWeek from './AdminNoticeScheduleViewWeek';

export default {
  name: 'AdminNoticeScheduleView',
  components: {
    NoticeWeek
  },
  data() {
    return {
      menu: {
        fromDate: false,
        toDate: false
      },
      query: {
        fromDate: moment()
          .startOf('day')
          .format('YYYY-MM-DD'),
        toDate: moment()
          .endOf('day')
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        includeUnscheduled: false,
        statusChoice: ['sent', 'scheduled', 'draft']
      },
      loading: false,
      statusOption: ['sent', 'draft', 'scheduled']
    };
  },
  computed: {
    ...mapGetters('notices', ['noticeListData', 'noticeWeekGroups']),
    noResults() {
      return _.isEmpty(this.noticeWeekGroups);
    }
  },
  watch: {
    query: {
      deep: true,
      handler: function() {
        this.retrieveNoticeList();
      }
    }
  },
  created() {
    this.retrieveNoticeList();
  },
  methods: {
    ...mapActions('notices', ['getNotices']),
    async retrieveNoticeList() {
      this.loading = true;
      let from = moment(this.query.fromDate)
        .startOf('day')
        .toDate();
      let to = moment(this.query.toDate)
        .endOf('day')
        .toDate();
      let statuses = this.query.statusChoice;
      let includeUnscheduled = this.query.includeUnscheduled;

      try {
        await this.getNotices({
          from,
          to,
          statuses,
          includeUnscheduled
        });
        this.loading = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    }
  }
};
</script>

<style scoped></style>
