<template>
  <v-chip :color="color[status.value]" text-color="white">
    {{ status.text }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    endTime: {
      type: String,
      default: null
    },
    startTime: {
      type: String,
      default: null
    },
    timeLeft: {
      type: String | Number,
      default: null
    }
  },
  data() {
    return {
      color: {
        ended: '#bdbdbd',
        live: '#81c784',
        notStarted: '#90caf9'
      }
    };
  },
  computed: {
    status() {
      if (!!this.startTime && !!this.timeLeft) {
        return { value: 'live', text: 'Live' };
      }
      if (!!this.startTime && !this.timeLeft) {
        return { value: 'ended', text: 'Ended' };
      }
      return { value: 'notStarted', text: 'Not Started' };
    }
  }
};
</script>

<style scoped></style>
