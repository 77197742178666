import http from './requestType';

/**
 * Returns a list of the units that belong to this proxy/meeting
 * @param  {String} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingFiles(shortCode) {
  return http._get(`Meetings/${shortCode}/files`);
}

/**
 * Moves a meeting file to trash
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} Key       - S3 key of the file to trash
 * @return {Promise} - Promise
 */
export function putTrashMeetingFile({ shortCode, Key }) {
  return http._put(`Meetings/${shortCode}/files/trash`, { Key });
}

/**
 * @deprecated
 * Post a new meeting file (unused)
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} file - the file to upload
 * @return {Promise} - Promise
 */
export function postMeetingFiles(shortCode, file) {
  return http._post(`Meetings/${shortCode}/files`, file);
}

/**
 * Upload the file to signed url
 * @param  {String} url - url s3 bucket
 * @param  {Object} file - file to upload
 * @param  {Object} config - config options
 * @return {Promise} - Promise
 */
export function putSignedUrl(url, file) {
  return http._putAWS(`${url}`, file);
}

/**
 * Get a Signed URL so that a file can be directly uploaded to S3
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} fileName - the file to upload
 * @param  {String} [folder] - optional folder to upload to
 * @return {Promise} - Promise
 */
export function getPutSignedUrl(shortCode, fileName, folder) {
  return http._get(`Meetings/${shortCode}/files/upload-signed-url`, {
    fileName,
    folder
  });
}

/**
 * Get a Signed URL so that a temporary file can be directly uploaded to S3
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} fileName - the file to upload
 * @param  {String} folder   - target folder to upload to
 * @return {Promise} - Promise
 */
export function getPutSignedUrlTmp(shortCode, fileName, folder) {
  return http._get(`Meetings/${shortCode}/files/upload-signed-url-tmp`, {
    fileName,
    folder
  });
}

/**
 * Get a Signed URL to download file from S3
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} key       - the file key
 * @return {Promise} - Promise
 */
export function getGetSignedUrl({ shortCode, key, name }) {
  return http._get(`Meetings/${shortCode}/files/download-signed-url`, {
    key,
    name
  });
}

/**
 * Send notification that meeting files are ready to download
 *
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} emailBody - the email notification body
 * @return {Promise} - Promise
 */
export function postFilesReadyNotification(shortCode, emailBody) {
  return http._post(`Meetings/${shortCode}/files/notifications`, {
    emailBody
  });
}

/**
 * Upload the file to pre signed url
 * @param  {String} url - url s3 bucket
 * @param  {Object} file - file to upload
 * @param  {Object} config - config options
 * @return {Promise} - Promise
 */
export function getPostPreSignedUrl(shortCode, fileName, folder) {
  return http._get(`Meetings/${shortCode}/files/upload-pre-signed-url`, {
    fileName,
    folder
  });
}
