<template>
  <div class="mb-4">
    <div class="body-2 mb-2">{{ label }}</div>
    <div class="consent-container">
      <v-btn-toggle
        :value="getButtonValue"
        @change="handleChange"
        mandatory
        class="consent-toggle elevation-0"
      >
        <v-btn flat :value="0" :class="{ selected: value === null }">
          No Action
        </v-btn>
        <v-btn
          flat
          :value="1"
          :class="{
            'selected-rejected': value === false,
            'rejected-text': value !== false
          }"
        >
          Rejected
        </v-btn>
        <v-btn
          flat
          :value="2"
          :class="{
            'selected-consented': value === true,
            'consented-text': value !== true
          }"
        >
          Consented
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConsentToggle',

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: null,
      required: false // Allow null value
    }
  },

  computed: {
    getButtonValue() {
      // Convert boolean/null to button index
      if (this.value === null) return 0;
      if (this.value === false) return 1;
      if (this.value === true) return 2;
      return 1; // Default to No Action
    }
  },

  methods: {
    handleChange(buttonIndex) {
      // Convert button index back to boolean/null
      const valueMap = {
        0: null, // Rejected
        1: false, // No Action
        2: true // Consented
      };
      this.$emit('input', valueMap[buttonIndex]);
    }
  }
};
</script>

<style scoped>
.consent-container {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  padding: 2px;
}

.consent-toggle {
  width: 100%;
  background: transparent !important;
}

.v-btn-toggle .v-btn {
  height: 40px;
  min-width: 33.333%;
  text-transform: none;
  font-weight: normal;
  border-radius: 4px !important;
  background: transparent !important;
  color: #495057 !important;
}

.v-btn-toggle .v-btn.selected {
  background: white !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.v-btn-toggle .v-btn.selected-rejected {
  background: #e4605d !important;
  color: white !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.v-btn-toggle .v-btn.selected-consented {
  background: #47b75c !important;
  color: white !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.rejected-text {
  color: #e4605d !important;
}

.consented-text {
  color: #47b75c !important;
}
</style>
