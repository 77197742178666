<template>
  <v-stepper v-model="currentStep">
    <!-- Header -->
    <v-stepper-header>
      <v-stepper-step
        v-for="step in stepObj"
        :key="`key-${step.index}`"
        :step="step.index + 1"
        :complete="currentStep > step.index + 1"
      >
        {{ step.label }}
        <small>{{ step.desc }}</small>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="elevation-0 card-container">
          <v-card-title class="pt-0 pb-0">
            <span class="title">Create Certificate</span>
          </v-card-title>
          <v-card-text>
            <v-combobox
              label="Name of Service"
              :items="nameTypes"
              v-model="newCertificate.name"
              clearable
            />
            <v-menu
              lazy
              :close-on-content-click="false"
              v-model="serviceDateMenu"
              transition="scale-transition"
              full-width
              max-width="460px"
              :nudge-right="40"
            >
              <v-text-field
                slot="activator"
                label="Select Service Date"
                v-model="serviceDate"
                prepend-icon="event"
                readonly
              />
              <v-date-picker v-model="serviceDate" landscape scrollable actions>
                <template>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn flat color="primary" @click="serviceDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" @click="saveServiceDate">SAVE</v-btn>
                  </v-card-actions>
                </template>
              </v-date-picker>
            </v-menu>
            <v-textarea
              v-if="!!newCertificate.body"
              class="textarea-container"
              label="Certification Message"
              v-model="newCertificate.body"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat @click.native="emitClose">Close</v-btn>
            <v-btn
              color="primary"
              :disabled="
                !newCertificate.name || !newCertificate.body || !serviceDate
              "
              @click.native="nextStep"
              >Next Step</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="elevation-0 card-container">
          <v-card-title class="pt-0 pb-0">
            <span class="title">ESOM Upload</span>
          </v-card-title>
          <v-card-text v-if="esomSource === 'file' || esomSource === 'choice'">
            <p class="body-1 grey--text text--darken-2">
              If you've received ESOM files for this service, you can upload it
              here. If you haven't, you may click next step.
            </p>
            <file-upload-form
              :attachment="newCertificate.data.esomFiles"
              :multiple-files="true"
              icon="attach_file"
              @upload="handleUpload"
            />
          </v-card-text>
          <v-card-text v-if="esomSource === 'link' || esomSource === 'choice'">
            <v-divider v-if="esomSource === 'choice'" class="mb-3" />
            <span v-if="esomSource === 'choice'" class="subheading"
              >Or, alternatively:</span
            >
            <v-select
              v-model="newCertificate.printJob"
              :items="printJobOptions"
              item-text="label"
              item-value="value"
              label="Choose an ESOM from one of the existing completed print jobs"
              clearable
              @click:clear="resetEsom"
              @input="selectLinkedPrintJob"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat @click.native="prevStep">Back</v-btn>
            <v-btn color="primary" @click.native="nextStep">Next Step</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card color="elevation-0">
          <v-card-title class="pt-0 pb-0">
            <span class="title">Preview & Confirm</span>
          </v-card-title>
          <v-card-text>
            <div
              class="previewContainer"
              v-if="!!previewContent"
              v-html="previewContent"
            />
            <v-progress-circular
              v-else
              indeterminate
              :size="50"
              color="primary"
            />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="prevStep">Back</v-btn>
          <v-btn color="primary" @click.native="nextStep">Next Step</v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card color="elevation-0">
          <v-card-title class="pt-0 pb-0">
            <span class="title">Send Certificate</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              required
              label="Send to Email"
              :value="send.toEmail"
              v-model="send.toEmail"
            />
            <v-text-field
              label="CC Email (optional)"
              v-model="send.copyEmail"
            />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="prevStep">Back</v-btn>
          <v-btn
            color="deep-orange"
            class="white--text"
            :loading="certLoading"
            @click.native="emitCreate"
          >
            <v-icon left>add</v-icon>create without sending
          </v-btn>
          <v-btn
            :disabled="!send.toEmail"
            color="primary"
            :loading="certLoading"
            @click.native="emitCreateAndSend"
          >
            <v-icon left>send</v-icon>Send
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import FileUploadForm from '@/components/FileUploadForm';

export default {
  name: 'MeetingCertificatesViewCreate',
  components: {
    FileUploadForm
  },
  props: {
    previewContent: {
      type: String,
      default: null
    },
    certLoading: {
      type: Boolean,
      default: false
    },
    accountKey: {
      type: String,
      default: null
    },
    contactEmail: {
      type: String,
      default: ''
    },
    shortCode: {
      type: String,
      default: null
    },
    meetingPrintJobData: {
      type: Array,
      required: true
    },
    region: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nameTypes: [
        'Annual Budget for Fiscal Year',
        'ICU Distribution',
        'Meeting of Owners',
        'PIC Distribution',
        'Pre-Notice for Meeting of Owners',
        'Rule Distribution'
      ],
      currentStep: 1,
      stepObj: [
        { index: 0, label: 'Step 1', desc: 'Certification Details' },
        { index: 1, label: 'Step 2', desc: 'ESOM Files' },
        { index: 2, label: 'Step 3', desc: 'Preview' },
        { index: 3, label: 'Step 4', desc: 'Send' }
      ],
      newCertificate: {
        name: null,
        body: null,
        data: {
          esomFiles: [],
          printJob: null
        }
      },
      send: {
        toEmail: '',
        copyEmail: ''
      },
      serviceDate: null,
      serviceDateMenu: false,
      loading: {
        preview: false
      }
    };
  },
  computed: {
    printJobOptions() {
      return _.map(this.meetingPrintJobData, job => {
        return { label: `${job.jobName} (${job.jobId})`, value: job.key };
      });
    },
    esomSource() {
      let source = 'choice';
      if (
        !_.isEmpty(this.newCertificate.data.esomFiles) &&
        !this.newCertificate.data.printJob
      ) {
        source = 'file';
      } else if (
        this.printJobOptions.length > 0 &&
        this.newCertificate.data.printJob
      ) {
        source = 'link';
      }
      return source;
    }
  },
  watch: {
    // whenever question changes, this function will run
    currentStep(value) {
      if (value === 3) {
        this.emitPreview();
      }
    }
  },
  created() {
    // Set the default contact email
    this.send.toEmail = this.contactEmail;
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    createPayload() {
      return {
        accountKey: this.accountKey,
        shortCode: this.shortCode,
        type: 'service-confirmation',
        name: this.newCertificate.name,
        body: this.newCertificate.body,
        data: this.newCertificate.data
      };
    },
    emitCreate() {
      let payload = this.createPayload();
      this.$emit('create', payload);
    },
    emitCreateAndSend() {
      let payload = {
        create: this.createPayload(),
        send: {
          toEmail: this.send.toEmail,
          copyEmail: this.send.copyEmail
        }
      };
      this.$emit('createAndSend', payload);
    },
    emitPreview() {
      this.$emit('getPreview', this.createPayload());
    },
    saveServiceDate() {
      let date = moment(this.serviceDate).format('MMMM Do YYYY');

      // TODO: handle the certificates by region using the recipe table?
      if (this.region === 'FL') {
        this.newCertificate.body = `<p><b>TO:</b> Representative of Customer</p><p>GetQuorum hereby certifies that on ${date}, notice was served to all consented owners of the Customer identified by the Customer to GetQuorum by means of electronic transmission and/or regular mail (via USPS). </p>`;
      } else {
        this.newCertificate.body = `<p><b>TO:</b> Representative of Customer</p><p>GetQuorum hereby certifies that on ${date}, notice was served to all condominium unit owners of the Customer identified by the Customer to GetQuorum by means of electronic transmission and/or regular mail (via Canada Post). </p>`;
      }

      // Close the menu
      this.serviceDateMenu = false;
    },
    handleUpload(res) {
      this.newCertificate.data.esomFiles = res;
    },
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
    selectLinkedPrintJob(jobKey) {
      this.newCertificate.data.printJob = jobKey;
      const printJob = _.filter(this.meetingPrintJobData, { key: jobKey });
      this.newCertificate.data.esomFiles =
        printJob.length > 0 ? [printJob[0].pocFile] : [];
    },
    resetEsom() {
      this.newCertificate.data.esomFiles = [];
    }
  }
};
</script>

<style scoped>
.previewContainer {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #eeeeee;
}
</style>
