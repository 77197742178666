<template>
  <div>
    <rich-text-editor
      :key="`editorKey${editorKey}`"
      :show-custom-fields="true"
      custom-label="Custom
          fields defined in settings"
      :custom-fields="customFields"
      :body="templateEdit"
      @update="onChange($event)"
    />

    <div style="text-align: right;">
      <v-tooltip top :disabled="!isPreviewDisabled">
        <template slot="activator">
          <v-btn
            flat
            @click.native="openPreviewDialog"
            :disabled="isPreviewDisabled"
            ><v-icon left>remove_red_eye</v-icon>Preview
          </v-btn>
        </template>
        <span>Save changes to preview</span>
      </v-tooltip>
    </div>

    <!-- Preview Dialog -->
    <preview-dialog
      :key="`previewDialogKey${previewDialogKey}`"
      v-model="dialog.preview"
      :html="htmlPreview"
      :recipientCount="0"
      :recipientTotal="0"
      @close="dialog.preview = false"
      :recipientInfoHidden="true"
      containerStyles="px-4"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { getRecipe } from '@/api/recipes';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';

export default {
  name: 'MeetingSettingsConsentOptionsInputCustom',
  components: {
    RichTextEditor,
    PreviewDialog
  },
  props: {
    template: {
      type: String,
      default: ''
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    },
    consentTemplateSelect: {
      type: String,
      default: ''
    },
    consentTemplateName: {
      type: String,
      default: ''
    },
    consentFormatSelect: {
      type: String,
      default: ''
    }
  },
  watch: {
    // Immediately run watcher on component creation
    consentTemplateSelect: {
      handler: 'updateCustomPreview',
      immediate: true
    },
    consentFormatSelect: {
      handler: 'updateCustomPreview',
      immediate: true
    }
  },
  computed: {
    ...mapGetters('meetings/consentTemplates', ['htmlPreview']),
    isPreviewDisabled() {
      // Conditions for disabling custom preview button

      // 1. Check if the custom template text has been edited and not saved
      const isTemplateEdited = !_.isEqual(
        _.trim(this.template),
        _.trim(this.templateEdit)
      );

      // 2. Check if the 'custom' consent template is selected but not yet saved
      const isCustomTemplateUnsaved =
        this.consentTemplateSelect === 'custom' &&
        this.consentTemplateSelect !== this.consentTemplateName;

      return isTemplateEdited || isCustomTemplateUnsaved;
    }
  },
  data() {
    return {
      // Used for manual refresh of components
      editorKey: 0,
      previewDialogKey: 0,

      // Dialogs
      dialog: {
        preview: false
      },
      // Local template edit state
      templateEdit: ''
    };
  },
  created() {
    // On create, create a local data property for editing
    this.templateEdit = _.clone(this.template);
  },
  methods: {
    ...mapActions('meetings/consentTemplates', ['getConsentTemplatePreview']),
    onChange(value) {
      this.$emit('update', value);
    },

    async updateCustomPreview() {
      if (this.template) {
        // If there's already a body, just increment the editorKey and return
        this.editorKey += 1;
        return;
      }

      const recipeMap = {
        'multi-consent': 'meetings.settings.custom_multi_consent_template',
        default: 'meetings.settings.custom_consent_template'
      };

      try {
        const recipeName =
          recipeMap[this.consentFormatSelect] || recipeMap.default;
        const recipe = await getRecipe(recipeName);

        // Check for template content template content
        const templateContent = recipe?.data?.data?.template;

        if (templateContent) {
          this.templateEdit = templateContent;

          // Propagate the new template to the parent component if no initial template existed
          // Ensures that saving an unedited custom template doesn't result in a null value
          if (!this.template) {
            this.onChange(templateContent);
          }
        } else {
          throw new Error('Invalid recipe data structure');
        }
      } catch (error) {
        console.error('Error fetching custom preview:', error);
        this.$events.$emit('showErrorDialog', error.response || error.message);
      } finally {
        this.editorKey += 1;
      }
    },
    // Retrieve custom preview and open dialog
    async openPreviewDialog() {
      try {
        const previewParams = {
          shortCode: this.$route.params.shortcode,
          template: this.consentTemplateSelect,
          consentFormat: this.consentFormatSelect
        };

        if (this.consentFormatSelect === 'multi-consent') {
          previewParams.noticeConsent = true;
          previewParams.votingConsent = true;
        }

        await this.getConsentTemplatePreview(previewParams);

        // Open dialog
        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.previewDialogKey += 1;
      }
    }
  }
};
</script>

<style scoped>
.preview-html {
  width: 100%;
  height: 70vh;
  max-height: 700px;
}
@media only screen and (max-width: 599px) {
  .preview-html {
    height: 40vh;
  }
}
</style>
