<template>
  <v-dialog
    min-width="500px"
    max-width="1000px"
    :value="value"
    persistent
    @input="emitInput"
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title
        >Add Proxy ({{ currentUnit ? currentUnit.unit : '' }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="emitClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-container class="mb-0 pb-0">
        <p class="body-2">
          This page is intended for you to record a proxy you received outside
          of the GetQuorum system (eg. by paper). GetQuorum does not take any
          responsibility for the validity of proxies entered through this
          manner.
        </p>
      </v-container>

      <!-- Proxy giver section -->

      <v-container class="mb-0 pb-0">
        <p class="subheading font-weight-bold">
          Who is the proxy giver?
          <span class="red--text font-weight-bold body-1">{{
            showErrorMessage('proxy-giver')
          }}</span>
        </p>
      </v-container>

      <meeting-proxies-create-ownership-radio-buttons
        v-if="currentUnit"
        :voters="currentUnit.voters"
        :existing-proxy="existingProxy"
        :live-vote-exists="liveVoteExists"
        :options="currentMeeting.options"
        @selected="setVoterSelected"
        :key="`${componentKey.meetingProxiesCreateOwnershipRadioButtons}`"
      />

      <!-- Proxy holder section -->
      <v-container class="mb-0 pb-0">
        <p class="subheading font-weight-bold">
          Name of the primary proxy:
          <span class="red--text font-weight-bold body-1">{{
            showErrorMessage('proxy-holder')
          }}</span>
        </p>
      </v-container>

      <!-- Proxyholder -->
      <meeting-proxies-create-proxyholder
        :default-holders="defaultHolders"
        :proxy-holder="proxyHolder"
        :write-in="writeInProxyHolder"
        :hide-subheader="true"
        @selectedProxyHolder="setProxyHolder"
        @selectedWriteIn="setWriteInProxyHolder"
        :key="
          `create-proxyholder-${componentKey.meetingProxiesCreateProxyholder}`
        "
      />

      <!-- Fallback Proxy holder section -->
      <v-container v-if="!disabledBackupProxyHolder" class="mb-0 pb-0">
        <p class="subheading font-weight-bold">
          (If specified) Name of the fallback proxy:
          <span class="red--text font-weight-bold body-1">{{
            showErrorMessage('proxy-holder')
          }}</span>
        </p>
      </v-container>

      <!-- Proxyholder -->
      <meeting-proxies-create-proxyholder
        v-if="!disabledBackupProxyHolder"
        :default-holders="defaultFallBackHolders"
        :proxy-holder="fallbackProxyHolder"
        :write-in="writeInFallbackProxyHolder"
        :hide-subheader="true"
        @selectedProxyHolder="setFallbackProxyHolder"
        @selectedWriteIn="setWriteInFallbackProxyHolder"
        :key="
          `create-fallback-proxyholder-${componentKey.meetingProxiesCreateProxyholder}`
        "
      />

      <!-- Ontario (bill 106) proxy specific section -->
      <meeting-proxies-create-bill-106
        v-if="currentMeeting.options.proxyTemplate === 'on-bill-106'"
        @selected="setOnBill106Selection"
        :key="`create-bill-106-${componentKey.meetingProxiesCreateBill106}`"
      />

      <!-- Question section, hide if no questions exist -->
      <v-slide-y-transition>
        <div
          v-if="
            meetingProxyQuestions &&
              meetingProxyQuestions.length > 0 &&
              formOptions.onBill106 === 'box-3'
          "
        >
          <!-- Iterate through questions, render as by-law (single choice) or election (multiple choice) questions -->
          <div
            v-for="(question, index) in meetingProxyQuestions"
            :key="question.id"
          >
            <meeting-proxies-question-radio
              v-if="question.type === 'by-law'"
              :index="index"
              :question="question"
              :validation-error-msg="showErrorMessage(`question-${index}`)"
              @selectedAnswers="setSelectedAnswers"
            />

            <meeting-proxies-question
              v-else
              :index="index"
              :question="question"
              :validation-error-msg="showErrorMessage(`question-${index}`)"
              :show-ranking="showRanking"
              @selectedAnswers="setSelectedAnswers"
              @selectedWriteIn="setWriteIn"
            />
          </div>
        </div>
      </v-slide-y-transition>

      <!-- Optional file upload -->
      <v-container class="mb-0 pb-0">
        <div>
          <p class="subheading font-weight-bold">
            OPTIONAL - Upload paper proxy (PDF Only)
          </p>
          <p class="body-1">
            If you'd like to have a record of this proxy on file, feel free to
            add the file here
          </p>

          <meeting-proxies-create-upload
            :attachment="attachment"
            @upload="setUpload"
          />
        </div>
      </v-container>

      <v-container>
        <v-alert
          v-if="isValidationErrors"
          :value="true"
          color="error"
          icon="warning"
          outline
        >
          <strong>There are issues with the proxy submission:</strong>
          <ul>
            <li v-for="(error, index) in validationErrorMessages" :key="index">
              {{ error.message }}
            </li>
          </ul>
        </v-alert>
      </v-container>

      <!-- Dialog -->
      <meeting-proxies-create-submit-dialog
        v-model="dialog.submit"
        :selected-voter="selectedVoter"
        :proxy-holder="proxyHolder"
        :existing-proxy="existingProxy"
        :loading-submit="loading.submit"
        :form-submitted="formSubmitted"
        :hide-submit-another="true"
        @close="dialog.submit = false"
        @reset="resetForm"
        @back="goBack"
        @submit="submitProxy"
      />

      <meeting-proxies-create-reset-dialog
        v-model="dialog.reset"
        @reset="resetForm"
        @close="dialog.reset = false"
      />

      <v-divider class="mt-2" />
      <v-card-actions>
        <v-btn flat @click="emitClose"
          ><v-icon>chevron_left</v-icon> cancel</v-btn
        >
        <v-btn flat @click="dialog.reset = true"
          ><v-icon left>refresh</v-icon> reset form</v-btn
        >

        <v-spacer />
        <v-tooltip bottom :disabled="!hasRestrictions">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                class="primary"
                :loading="loading.submit"
                @click="validateForm"
                :disabled="hasRestrictions"
                >SUBMIT PROXY</v-btn
              >
            </span>
          </template>
          <span
            >Submissions through the Dashboard will be available when voting
            matters have been configured.</span
          >
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* This dialog should contain all the modal state specific UI/data properties, import to re-use create proxy actions/functions
 */
import _ from 'lodash';

import { mapGetters, mapActions, mapState } from 'vuex';
import MeetingProxiesCreateOwnership from '@/views/meetings/meeting/proxies/MeetingProxiesCreateOwnership';
import MeetingProxiesCreateOwnershipRadioButtons from '@/views/meetings/meeting/proxies/MeetingProxiesCreateOwnershipRadioButtons';
import MeetingProxiesCreateProxyholder from '@/views/meetings/meeting/proxies/MeetingProxiesCreateProxyholder';
import MeetingProxiesCreateSubmitDialog from '@/views/meetings/meeting/proxies/MeetingProxiesCreateSubmitDialog';
import MeetingProxiesCreateUpload from '@/views/meetings/meeting/proxies/MeetingProxiesCreateUpload';
import MeetingProxiesQuestion from '@/views/meetings/meeting/proxies/MeetingProxiesQuestion';
import MeetingProxiesQuestionRadio from '@/views/meetings/meeting/proxies/MeetingProxiesQuestionRadio';
import MeetingProxiesCreateResetDialog from '@/views/meetings/meeting/proxies/MeetingProxiesCreateResetDialog';
import MeetingProxiesCreateBill106 from '@/views/meetings/meeting/proxies/MeetingProxiesCreateBill106';
import * as CreateProxy from '@/lib/make-proxy-object';

export default {
  name: 'MeetingUnitsAddProxyDialog',
  components: {
    MeetingProxiesCreateOwnership,
    MeetingProxiesCreateOwnershipRadioButtons,
    MeetingProxiesQuestion,
    MeetingProxiesQuestionRadio,
    MeetingProxiesCreateProxyholder,
    MeetingProxiesCreateSubmitDialog,
    MeetingProxiesCreateUpload,
    MeetingProxiesCreateResetDialog,
    MeetingProxiesCreateBill106
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    unitId: {
      type: Number,
      default() {
        return null;
      }
    },
    meetingUnitsListData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    value(val) {
      // Only initialize values when add proxy is clicked
      // Otherwise very expensive operation when row is expanded
      // Reset form and init every time the dialog is opened
      if (val) {
        this.resetForm();
        this.init();
      }
    },
    async selectedVoter(voter) {
      if (voter) {
        // Determine if existing live vote exists, show alert if true
        try {
          let res = await this.getMeetingUnit({
            shortCode: this.shortCode,
            unitId: voter.unit_id
          });
          // Set local liveVoteExists property
          this.setLiveVoteExists({ liveVoteExists: res.data.hasLiveVotes });
        } catch (err) {
          console.log(err);
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'login']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),
    ...mapGetters('meetings/units', ['meetingUnitsList', 'meetingUnitById']),
    ...mapGetters('meetings/proxies', ['meetingProxyListData']),
    ...mapState('meetings/dashboardProxySubmissions', [
      'meetingProxyQuestions',
      'proxyHolder',
      'writeInProxyHolder',
      'fallbackProxyHolder',
      'writeInFallbackProxyHolder',
      'selectedVoter',
      'defaultHolders',
      'attachment',
      'formOptions',
      'liveVoteExists',
      'validationErrorMessages'
    ]),
    existingProxy() {
      if (!_.isEmpty(this.selectedVoter)) {
        return this.meetingProxyListData.find(proxy => {
          this.selectedVoter.unit === proxy.unit;
        });
      }
      return false;
    },
    isValidationErrors() {
      return this.validationErrorMessages.length > 0 ? true : false;
    },
    currentUnit() {
      return this.meetingUnitById(this.unitId);
    },
    hasBusinessData() {
      return this.meetingBusinessData.length > 0;
    },
    restrictProxyInput() {
      return _.get(this.currentMeeting, 'options.restrictProxyInput', false);
    },
    hasRestrictions() {
      return !this.hasBusinessData && this.restrictProxyInput;
    },
    disabledBackupProxyHolder() {
      if (
        this.currentMeeting?.options?.proxyAppDisplayOptions?.proxy
          ?.disableFallbackHolder ||
        (!this.proxyHolder && !this.writeInProxyHolder)
      )
        return true;

      return false;
    },
    defaultFallBackHolders() {
      return [...this.defaultHolders, 'None'];
    },
    showRanking() {
      return _.get(this.currentMeeting, 'options.electionsRanking', false);
    }
  },
  beforeDestroy() {
    // Always reset the form on exit. State is shared in add proxy dialog.
    this.resetProxyForm();
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      loading: {
        submit: false
      },
      dialog: {
        submit: false,
        reset: false
      },
      formSubmitted: false,
      componentKey: {
        meetingProxiesCreateOwnership: 0,
        meetingProxiesCreateProxyholder: 0,
        meetingProxiesCreateBill106: 0,
        meetingProxiesCreateOwnershipRadioButtons: 0
      }
    };
  },
  methods: {
    ...mapActions('meetings/units', ['getMeetingUnits', 'getMeetingUnit']),
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    ...mapActions('meetings/dashboardProxySubmissions', [
      'addMeetingProxy',
      'initMeetingProxyQuestions',
      'setSelectedAnswers',
      'setWriteIn',
      'setProxyHolder',
      'setWriteInProxyHolder',
      'setFallbackProxyHolder',
      'setWriteInFallbackProxyHolder',
      'setVoterSelected',
      'setDefaultHolders',
      'setUpload',
      'setOnBill106Selection',
      'checkOnBill106Selection',
      'resetProxyForm',
      'validateProxyForm',
      'setLiveVoteExists'
    ]),
    async init() {
      try {
        let res = await this.getMeetingBusiness({ shortCode: this.shortCode });

        // Initialize questions with empty vote property
        // Vote property must be initialized first to be reactive
        await this.initMeetingProxyQuestions({
          meetingBusiness: res.data,
          currentUnit: this.currentUnit
        });

        // Set the default proxy holder list
        this.setDefaultHolders(this.currentMeeting.defaultHolders);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async submitProxy() {
      this.loading.submit = true;

      const payload = CreateProxy.makeProxyObject({
        shortCode: this.shortCode,
        questions: this.meetingProxyQuestions,
        proxyHolder: this.proxyHolder,
        writeInProxyHolder: this.writeInProxyHolder,
        fallbackProxyHolder: this.fallbackProxyHolder,
        writeInFallbackProxyHolder: this.writeInFallbackProxyHolder,
        selectedVoter: {
          ...this.selectedVoter,
          unit: this.currentUnit.unit,
          address: this.currentUnit.address
        },
        attachment: this.attachment,
        formOptions: this.formOptions,
        proxyTemplate: this.currentMeeting.options.proxyTemplate
          ? this.currentMeeting.options.proxyTemplate
          : null
      });

      try {
        let res = await this.addMeetingProxy({
          shortCode: this.shortCode,
          proxy: payload
        });
        // On submit success, close submit dialog, open submitSuccess dialog
        if (res) {
          this.formSubmitted = true;
          this.$events.$emit('toastEvent', 'New proxy added');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.submit = false;
      }
    },
    // NAVIGATION functions ***********************
    resetForm() {
      // Reset all local state properties
      this.loading.submit = false;
      this.dialog.submit = false;
      this.dialog.reset = false;
      this.formSubmitted = false;

      // Changing the key of a component will re create it
      this.componentKey.meetingProxiesCreateOwnership += 1;
      this.componentKey.meetingProxiesCreateProxyholder += 1;
      this.componentKey.meetingProxiesCreateBill106 += 1;
      this.componentKey.meetingProxiesCreateOwnershipRadioButtons += 1;

      // Reset vuex state
      this.resetProxyForm();
      // Re-init to get new data
      this.init();
    },
    goBack() {
      this.resetProxyForm();
      this.emitClose();
    },
    // FORM validation functions ******************
    showErrorMessage(type) {
      let error = this.validationErrorMessages.find(
        error => error.type === type
      );
      return error ? `*${error.message}` : null;
    },
    validateForm() {
      // If it's the ontario bill 106 proxy template, check form options
      // If option 1 or 2, answers may need to be all set to abstain or defer
      if (this.currentMeeting.options.proxyTemplate === 'on-bill-106') {
        this.checkOnBill106Selection(this.formOptions.onBill106);
      }

      const enableRtvProxyOverride = this.currentMeeting.options
        .enableRtvProxyOverride;
      this.validateProxyForm({ enableRtvProxyOverride });

      // If there are no validation form messages, then open the submit dialog confirmation
      if (this.validationErrorMessages.length === 0 && !this.hasRestrictions) {
        this.dialog.submit = true;
      }
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
