<template>
  <div class="main-dashboard">
    <!-- Header content -->
    <app-header>
      <div slot="top-left-action">
        <router-link :to="{ name: 'meetings' }">
          <img class="mt-2 hidden-sm-and-up" src="../../assets/gq_logo.svg" />
          <img
            class="mt-2 hidden-xs-only"
            src="../../assets/gq_navbar_logo.svg"
          />
        </router-link>
      </div>
    </app-header>

    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/Header';
export default {
  name: 'VotersMain',
  components: { AppHeader },
  beforeRouteEnter(to, from, next) {
    window.document.title = 'Membership Search';
    next();
  }
};
</script>

<style scoped></style>
