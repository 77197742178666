<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">General Settings</div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Displayed on proxy form home page
    </div>

    <v-select
      attach
      label="Status"
      item-text="label"
      item-value="value"
      clearable
      :items="statusOptions"
      :value="meetingStatus"
      @change="onChange('meetingStatus', $event)"
    >
      <template #selection="{ item }">
        <v-chip :color="item.background" :text-color="item.color">{{
          item.label
        }}</v-chip>
      </template>
    </v-select>

    <v-text-field
      label="Name"
      :value="name"
      :rules="[v => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('name', $event)"
    />

    <v-textarea
      label="Description"
      rows="1"
      :value="description"
      :rules="[v => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('description', $event)"
    />

    <v-text-field
      label="Meeting Location"
      :value="location"
      :rules="[v => v.length <= 255 || 'Max 255 characters']"
      :counter="255"
      @input="onChange('location', $event)"
    />

    <span>What is this meeting about?</span>
    <v-chip
      class="elevation-0"
      small
      flat
      @click="toggleMeetingAbout = !toggleMeetingAbout"
      >{{ toggleMeetingAbout ? 'hide' : 'show' }}</v-chip
    >
    <div class="markdown-label">(optional) Field is hidden by default</div>
    <div v-if="toggleMeetingAbout">
      <rich-text-editor
        :body="meetingAbout"
        :hide-merge-menu="true"
        @update="onChange('meetingAbout', $event)"
      />
    </div>

    <v-divider class="gq-divider" />

    <div class="title grey--text text--darken-4 mb-1">
      Optional/Other Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Settings not visible on the proxy form
    </div>

    <v-select
      :items="campaignTypes"
      item-value="value"
      item-text="label"
      chips
      :value="options.type || 'meeting'"
      label="Campaign Type (Visibility on User's dashboard)"
      @change="onChange('options.type', $event)"
    />

    <v-text-field
      label="Quorum Minimum"
      :value="quorumMinimum"
      type="number"
      @input="onChange('quorumMinimum', $event)"
    />

    <v-divider class="gq-divider" />

    <div class="title grey--text text--darken-4 mb-1">
      Overview Display Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Settings to configure this meeting's Overview page
    </div>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          :value="displaySecondaryMetric"
          @change="onDisplaySecondaryMetricChange($event)"
        />
      </div>
      <div>
        <div class="subheading">Display Secondary Attendance Metric</div>
        <div class="grey--text text--darken-2">
          Click this to show a second metric (either unit values or share
          values) on the Overview page's attendance stats: <br />Submissions,
          Total Units, Total RSVPs, Units in Attendance, and Eligible Units in
          Attendance.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displayCustomGroups"
          @change="onDisplayGroupsChange($event)"
        />
      </div>
      <div>
        <div class="subheading">Display Groups Metric</div>
        <div class="grey--text text--darken-2">
          Click this to show the groups metric (Unit Factor Vote splitting) on
          the Overview page's attendance stats: <br />Total Units, Total RSVPs,
          Eligible RSVPs, Units in Attendance, and Eligible Units in Attendance.
        </div>
      </div>
    </v-layout>

    <v-layout
      v-if="this.displaySecondaryMetric || this.displayCustomGroups"
      justify-center
      class="mt-3"
    >
      <v-flex xs4>
        <v-text-field
          v-model="primaryAttendanceMetric"
          label="Primary Attendance Metric"
          outline
          readonly
        />
      </v-flex>
      <v-flex xs2>
        <div class="text-xs-center">
          <v-btn flat small fab @click="onMetricChange">
            <v-icon medium dark>swap_horiz</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          v-model="secondaryAttendanceMetric"
          label="Secondary Attendance Metric"
          outline
          readonly
        />
      </v-flex>
      <v-flex xs2 v-if="hasThirdMetric">
        <div class="text-xs-center">
          <v-btn flat small fab @click="onMetricChange2">
            <v-icon medium dark>swap_horiz</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs4 v-if="hasThirdMetric">
        <v-text-field
          v-model="tertiaryAttendanceMetric"
          label="Tertiary Attendance Metric"
          outline
          readonly
        />
      </v-flex>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displaySharesAsPercentages"
          @change="onChange('options.sharesAsPercentages', $event)"
        />
      </div>
      <div>
        <div class="subheading">Display Shares as Percentages</div>
        <div class="grey--text text--darken-2">
          Click this to show shares as percentages on the Overview page's stats:
          <br />Submissions, Total Units, Total RSVPs, Eligible RSVPs, Units in
          Attendance, and Eligible Units in Attendance.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displayRoundedResults"
          @change="onChange('options.roundedResults', $event)"
        />
      </div>
      <div>
        <div class="subheading">Display Rounded Decimal Places</div>
        <div class="grey--text text--darken-2">
          Click this to round values based on the selected number of decimal
          <br />
          places on the Overview page and Voting Reports.
        </div>
      </div>
      <v-text-field
        :disabled="!displayRoundedResults"
        class="shrink ml-4"
        outline
        label="Number of decimal places"
        :value="options.roundedResultsPrecision"
        type="number"
        min="0"
        :rules="[v => v >= 0 || 'Number of decimal places must be above 0']"
        @input="onChange('options.roundedResultsPrecision', $event)"
      />
    </v-layout>

    <v-layout class="mt-3">
      <p>
        <i>
          <b>Update - 2022-08-02:</b> Experimental settings have been moved to
          the "Advanced / Experimental" page.
        </i>
      </p>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import { getRecipe } from '@/api/recipes';

export default {
  name: 'MeetingSettingsGeneralInput',
  components: {
    RichTextEditor
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    meetingAbout: {
      type: String,
      default: ''
    },
    quorumMinimum: {
      type: [String, Number],
      default: 0
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    },
    meetingStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      toggleMeetingAbout: false,
      displaySecondaryMetric: false,
      displayCustomGroups: false,
      displaySharesAsPercentages: false,
      displayAdvanceToggle: false,
      displayRoundedResults: false,
      roundedResultScale: 0,
      primaryAttendanceMetric: '',
      secondaryAttendanceMetric: '',
      statusOptions: [],
      statusSeleceted: null,
      campaignTypes: [
        {
          label: 'AGMs, SGMs, or Voting (Visible)',
          value: 'meeting'
        },
        {
          label: 'Budget Distribution (Hidden)',
          value: 'budget'
        },
        {
          label: 'PIC / ICU Distribution (Hidden)',
          value: 'certs'
        },
        {
          label: 'Custom Campaign (Hidden)',
          value: 'custom'
        }
      ]
    };
  },
  watch: {
    options(newOptions) {
      this.setState(newOptions);
    }
  },
  mounted() {
    this.setState(this.options);
  },
  computed: {
    hasThirdMetric() {
      return this.displaySecondaryMetric && this.displayCustomGroups;
    }
  },
  methods: {
    setState(currentOptions) {
      const {
        displaySecondaryMetric = false,
        displayCustomGroups = false,
        sharesAsPercentages = false,
        displayAdvanceToggle = false,
        roundedResults = false,
        primaryAttendanceMetric = 'Units',
        secondaryAttendanceMetric = 'Shares',
        tertiaryAttendanceMetric = 'Groups'
      } = currentOptions;

      this.displaySecondaryMetric = displaySecondaryMetric;
      this.displayCustomGroups = displayCustomGroups;
      this.displaySharesAsPercentages = sharesAsPercentages;
      this.displayAdvanceToggle = displayAdvanceToggle;
      this.displayRoundedResults = roundedResults;

      this.primaryAttendanceMetric = primaryAttendanceMetric;
      this.secondaryAttendanceMetric = secondaryAttendanceMetric;
      this.tertiaryAttendanceMetric = tertiaryAttendanceMetric;

      this.getStatusOptions();
    },
    onDisplayGroupsChange(value) {
      if (!value) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Shares';
      }
      if (!this.displaySecondaryMetric) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Groups';
      } else if (value) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Shares';
        this.tertiaryAttendanceMetric = 'Groups';
      }
      this.onChange('options.displayCustomGroups', value);
      this.onChange(
        'options.primaryAttendanceMetric',
        this.primaryAttendanceMetric
      );
      this.onChange(
        'options.secondaryAttendanceMetric',
        this.secondaryAttendanceMetric
      );
      this.onChange(
        'options.tertiaryAttendanceMetric',
        this.tertiaryAttendanceMetric
      );
    },
    onDisplaySecondaryMetricChange(value) {
      if (!value && !this.displayCustomGroups) {
        // reset the metrics when only 1
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = '';
        this.tertiaryAttendanceMetric = '';
      } else if (!value) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Groups';
      }

      if (!this.displayCustomGroups && value) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Shares';
      } else if (value) {
        this.primaryAttendanceMetric = 'Units';
        this.secondaryAttendanceMetric = 'Shares';
        this.tertiaryAttendanceMetric = 'Groups';
      }

      this.onChange('options.displaySecondaryMetric', value);
      this.onChange(
        'options.primaryAttendanceMetric',
        this.primaryAttendanceMetric
      );
      this.onChange(
        'options.secondaryAttendanceMetric',
        this.secondaryAttendanceMetric
      );
      this.onChange(
        'options.tertiaryAttendanceMetric',
        this.tertiaryAttendanceMetric
      );
    },
    onChange(key, value = null) {
      this.$emit('update', { key, value });
    },
    onMetricChange() {
      // Swap the primary and secondary metrics; units becomes shares, shares becomes units
      const tmp = this.primaryAttendanceMetric;
      this.primaryAttendanceMetric = this.secondaryAttendanceMetric;
      this.secondaryAttendanceMetric = tmp;

      this.onChange(
        'options.primaryAttendanceMetric',
        this.primaryAttendanceMetric
      );
      this.onChange(
        'options.secondaryAttendanceMetric',
        this.secondaryAttendanceMetric
      );
    },
    onMetricChange2() {
      const tmp = this.secondaryAttendanceMetric;
      this.secondaryAttendanceMetric = this.tertiaryAttendanceMetric;
      this.tertiaryAttendanceMetric = tmp;

      this.onChange(
        'options.secondaryAttendanceMetric',
        this.secondaryAttendanceMetric
      );
      this.onChange(
        'options.tertiaryAttendanceMetric',
        this.tertiaryAttendanceMetric
      );
    },
    async getStatusOptions() {
      const { data } = await getRecipe('meetings.status.options');
      if (data && data.data && data.data.status) {
        const options = _.keyBy(data.data.status, 'value');
        this.statusOptions = _.map(options, option => option);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';

.markdown-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  visibility: visible;
  margin-bottom: 5px;
}
</style>
