'use strict';

/**
 * @fileoverview Vuex module for a tallying a meeting's combined advance + live votes
 */

import _ from 'lodash';

import * as liveVoteApi from '@/api/meetings-rtv-live-votes';

const state = {
  meetingRTVCombinedVoteV2List: {},
  meetingRTVCombinedVoteV3List: {},
  meetingRTVLiveVotes: [],
  dataLastFetchedAt: null,
  pusher: {}
};

const getters = {
  meetingRTVCombinedVoteV2List: state => state.meetingRTVCombinedVoteV2List,
  meetingRTVCombinedVoteV2ListData: state =>
    Object.values(state.meetingRTVCombinedVoteV2List),
  meetingRTVCombinedVoteV3List: state => state.meetingRTVCombinedVoteV3List,
  meetingRTVCombinedVoteV3ListData: state =>
    Object.values(state.meetingRTVCombinedVoteV3List),
  meetingRTVLiveVotes: state =>
    _.groupBy(state.meetingRTVLiveVotes, 'questionId')
};

const actions = {
  /**
   * Get the combined voting results from advance, live and paper vote totals V2
   * @param  {String} {shortCode}  meeting code
   */
  async getMeetingRTVCombinedVoteV2Data({ commit }, { shortCode }) {
    try {
      const res = await liveVoteApi.getMeetingRTVCombinedVoteV2Data(shortCode);
      commit('SET_MEETING_RTV_COMBINED_VOTE_V2_DATA', res.data);
    } catch (err) {
      console.error('Error: getMeetingRTVCombinedVoteV2Data action', err);
      throw err;
    }
  },

  /**
   * Get the combined voting results from advance, live and paper vote totals V3
   * @param  {String} {shortCode}  meeting code
   */
  async getMeetingRTVCombinedVoteV3Data({ commit }, { shortCode }) {
    try {
      const res = await liveVoteApi.getMeetingRTVCombinedVoteV3Data(shortCode);
      return commit('SET_MEETING_RTV_COMBINED_VOTE_V3_DATA', res.data);
    } catch (err) {
      console.error('Error: getMeetingRTVCombinedVoteV3Data action', err);
      throw err;
    }
  },

  /**
   * Get the list of this meeting's live votes
   * @param  {String} {shortCode}  meeting code
   */
  async getMeetingRTVLiveVotes({ commit }, { shortCode }) {
    try {
      const res = await liveVoteApi.getMeetingRTVLiveAnswersData(shortCode);
      commit('SET_MEETING_RTV_LIVE_VOTES', res.data);
      return res.data;
    } catch (err) {
      console.error('Error: getMeetingRTVLiveVotes action', err);
      throw err;
    }
  },

  /**
   * Reset a live vote answer
   * @param  {String} {shortCode}  meeting code
   * @param  {Array} {answers} answers to reset
   */
  async resetRTVLiveVotes({ commit }, { shortCode, answers }) {
    try {
      await liveVoteApi.resetMeetingVoterAnswers(shortCode, answers);
    } catch (err) {
      console.error('Error: resetRTVLiveVotes action', err);
      throw err;
    }
  },

  /**
   * Update the list of candidate exclusions
   */
  async toggleVoteExclusion(
    { dispatch },
    { shortCode, questionKey, liveQuestionKey, exclusion }
  ) {
    try {
      await liveVoteApi.toggleVoteExclusion(
        shortCode,
        questionKey,
        exclusion,
        liveQuestionKey
      );
    } catch (err) {
      console.error('Error: toggleVoteExclusion action', err);
      throw err;
    }
  },

  /**
   * Update the list of winners
   */
  async toggleWinner(
    { dispatch },
    { shortCode, questionKey, liveQuestionKey, winner }
  ) {
    try {
      await liveVoteApi.toggleWinner(
        shortCode,
        questionKey,
        winner,
        liveQuestionKey
      );
    } catch (err) {
      console.error('Error: toggleWinner action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_RTV_COMBINED_VOTE_V2_DATA(state, dataArray) {
    state.meetingRTVCombinedVoteV2List = _.keyBy(dataArray, 'id');
    state.dataLastFetchedAt = new Date();
  },

  SET_MEETING_RTV_COMBINED_VOTE_V3_DATA(state, dataArray) {
    state.meetingRTVCombinedVoteV3List = _.keyBy(dataArray, 'id');
    state.dataLastFetchedAt = new Date();
  },

  SET_MEETING_RTV_LIVE_VOTES(state, dataArray) {
    state.meetingRTVLiveVotes = dataArray;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.meetingRTVCombinedVoteV2List = {};
    state.meetingRTVLiveVotes = [];
    state.dataLastFetchedAt = null;
    state.pusher = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
