<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title> <span class="title">Edit Member</span> </v-card-title>
      <v-card-text>
        <label for="name">Name</label>
        <v-text-field
          name="name"
          maxlength="64"
          counter="64"
          v-model="state.name"
          :rules="rules.name"
          outline
          single-line
          required
        />
        <label for="email">Email</label>
        <v-text-field
          name="email"
          v-model="state.email"
          :rules="rules.email"
          outline
          single-line
          required
        />
        <v-checkbox
          hide-details
          light
          label="This member is not allowed to vote"
          v-model="state.ineligible"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="canDeleteMembers && type !== 'spectators'"
          color="error"
          flat
          :loading="deleting"
          @click.native="dialog.delete = true"
        >
          <v-icon left>delete_forever</v-icon> Delete Member
        </v-btn>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Cancel</v-btn>
        <v-btn
          :disabled="!isChanged && !isEligibleChanged"
          color="primary"
          :loading="saving"
          @click.native="updateMember"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Delete Dialog -->
    <delete-voter-confirmation-dialog
      v-model="dialog.delete"
      type="fromVBM"
      @close="dialog.delete = false"
      @delete="deleteMember"
    />
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import validator from 'email-validator';

import DeleteVoterConfirmationDialog from '../../units/MeetingUnitsDeleteVoterConfirmationDialog.vue';

export default {
  name: 'MeetingVbmEditMemberDialog',
  components: {
    DeleteVoterConfirmationDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    memberSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default() {
        return 'vbm';
      }
    }
  },
  data() {
    return {
      state: {
        name: '',
        email: '',
        ineligible: false
      },
      rules: {
        name: [v => !!v || 'Name is required'],
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ]
      },
      dialog: {
        delete: false
      },
      deleting: false,
      saving: false,
      shortCode: this.$route.params.shortcode
    };
  },
  watch: {
    memberSelected(value) {
      let { name, email, ineligible } = _.cloneDeep(value);
      this.state.name = name;
      this.state.email = email;
      this.state.ineligible = ineligible;
    }
  },
  computed: {
    isChanged() {
      let state = {
        name: this.state.name,
        email: this.state.email
      };
      let current = {
        name: this.memberSelected.name,
        email: this.memberSelected.email
      };
      return !_.isEqual(state, current);
    },
    isEligibleChanged() {
      return !_.isEqual(this.state.ineligible, this.memberSelected.ineligible);
    },
    // place holder, need to be changed later
    canDeleteMembers() {
      return true;
    }
  },
  methods: {
    ...mapActions('meetings/units', [
      'updateMeetingUnitVoter',
      'deleteMeetingUnits'
    ]),
    ...mapActions('meetings/webcast', [
      'addWebcastPanelists',
      'removeWebcastPanelist'
    ]),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    async updateMember() {
      try {
        if (this.isChanged) {
          this.saving = true;
          await this.updateMeetingUnitVoter({
            shortCode: this.shortCode,
            unitId: this.memberSelected.unit_id,
            email: this.memberSelected.email,
            voter: {
              name: this.state.name,
              email: this.state.email
            }
          });

          await this.updatePanelist();
        }

        // Get updated list of voters, for invite dialog
        await this.getMeetingVoters({ shortCode: this.shortCode });

        // Separate update for isEligible to avoid unneeded zoom api registrant request
        if (this.isEligibleChanged) {
          this.$emit('update', {
            unitId: this.memberSelected.unit_id,
            ineligibleReason:
              this.state.ineligible === true ? 'vbm_blocked' : ''
          });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.$emit('close');
      }
    },
    async deleteMember() {
      try {
        this.deleting = true;

        const shortCode = this.shortCode;
        const unitId = this.memberSelected.unit_id;
        const email = this.memberSelected.email;

        // Deletes both unit and voter
        await this.deleteMeetingUnits({
          shortCode: this.shortCode,
          unitIds: [unitId]
        });

        // remove them as panelist
        await this.removeWebcastPanelist({
          shortCode,
          email
        });

        // Get updated list of voters, for invite dialog
        await this.getMeetingVoters({ shortCode: this.shortCode });

        this.$events.$emit('toastEvent', 'Member Deleted');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.deleting = false;
        this.dialog.delete = false;
        this.$emit('delete');
        this.emitClose();
      }
    },
    async updatePanelist() {
      if (this.type === 'boardMember') {
        const shortCode = this.shortCode;
        const email = this.state.email;
        const name = this.state.email;
        // Panelists needs to be deleted
        await this.deletePanelist({
          shortCode,
          email: this.memberSelected.email
        });
        //and then created again
        await this.createPanelist({ shortCode, email, name });
        // to be updated
      }
    },
    async deletePanelist({ shortCode, email }) {
      await this.removeWebcastPanelist({
        shortCode,
        email
      });
    },
    async createPanelist({ shortCode, email, name }) {
      await this.addWebcastPanelists({
        shortCode,
        name,
        email
      });
    }
  }
};
</script>

<style scoped></style>
