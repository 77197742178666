<template>
  <v-dialog :value="isOpen" persistent scrollable max-width="700px">
    <v-card>
      <v-card-title class="headline">
        Recordings and Reports
      </v-card-title>
      <v-card-text>
        <v-layout column align-center v-if="!checked">
          <v-btn color="primary" @click="init" :loading="loading">
            Check Now
          </v-btn>
        </v-layout>

        <v-layout column v-else-if="!loading && hasRecordings">
          <h3>Recordings Found!</h3>
          <p>
            The recordings and reports are being retrieved and will be available to download
            via the <b>Files</b> tab. We'll send an email to
            <b>{{ login.profile.email }}</b> when they're ready.
          </p>
        </v-layout>

        <v-layout column v-else>
          <h3>No Recordings Found</h3>
          <p>
            <ul>
              <li>Meeting or it may not have taken place yet.</li>
              <li>Recordings files may still be processing (usually takes an hour or so).</li>
              <li>Cloud Recordings may have been disabled for the meeting.</li>
            </ul>
          </p>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DownloadRecordingsDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    }
  },
  watch: {},
  data() {
    return {
      loading: false,
      hasRecordings: false,
      checked: false
    };
  },
  created() {
    // this.init();
  },
  computed: {
    ...mapGetters(['login'])
  },
  methods: {
    ...mapActions('meetings/webcast', ['triggerRecordingDownloads']),
    async init() {
      try {
        this.loading = true;
        await this.triggerRecordingDownloads({ shortCode: this.shortCode });
        this.hasRecordings = true;
      } catch (err) {
        this.hasRecordings = false;
      } finally {
        this.checked = true;        
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
