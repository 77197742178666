<template>
  <v-dialog max-width="650px" :value="value" persistent>
    <v-card class="round-card">
      <v-card-title primary-title>
        <v-layout align-center>
          <div class="subheading font-weight-bold blue-text">
            About Members
          </div>
          <v-spacer />
          <v-icon @click.stop="emitClose">close</v-icon>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <p>Members are able to participate in the meeting and cast votes.</p>

        <p>
          They will have access to meeting documents within the portal, but not
          private documents. They will be able to turn their mics and cameras
          on.
        </p>

        <p>In this dashboard as the meeting organizer you can:</p>
        <div>
          <ul>
            <li>Add individual members</li>
            <li>Edit member information (name/email)</li>
            <li>Delete members</li>
            <li>Import members from a csv or xls/xlsx file</li>
            <li>Import members from a previous virtual board meeting</li>
            <li>Send invitation emails individually or all at once</li>
            <li>
              Block specific members from voting within the meeting portal
            </li>
          </ul>
        </div>
        <br style="line-height: 50px;" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingVbmAboutMembers',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
