<template>
  <v-dialog max-width="600px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title"
          >All voters from the selected units will be updated with the email
          specified below:</span
        >
      </v-card-title>
      <v-alert
        :value="unitsMultipleVotes.length > 0"
        color="#0F64A9"
        icon="warning"
        outline
        class="ml-3 mr-3"
      >
        <span style="font-size: 12px;">
          The following units with multiple voters, will not be updated. Please
          review and update these units manually.
        </span>
        <ul class="mt-2">
          <li v-for="unit in unitsMultipleVotes" :key="unit.key">
            {{ unit.unit }} - {{ unit.address }}
          </li>
        </ul>
      </v-alert>
      <v-card-text>
        <v-layout justify-center>
          <v-text-field
            class="mt-2"
            label="New voter email"
            placeholder="noemail@getquorum.com"
            :rules="[rules.required, rules.emailValid]"
            v-model="email"
          ></v-text-field>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn
          :disabled="!email || isValidEmail"
          color="primary"
          @click.native="emitUpdate"
          >update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import validator from 'email-validator';

export default {
  name: 'MeetingUnitsBulkEmailsDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      email: 'noemail@getquorum.com',
      rules: {
        required: value => !!value || 'Required.',
        emailValid: value =>
          validator.validate(value) || `Please enter a valid email.`
      }
    };
  },
  computed: {
    isValidEmail() {
      return !validator.validate(this.email);
    },
    unitsMultipleVotes() {
      const unitMultipleVoters = this.selected.filter(
        unit => _.size(unit.voters) > 1
      );
      return unitMultipleVoters;
    }
  },
  methods: {
    emitClose() {
      this.email = 'noemail@getquorum.com';
      this.$emit('close');
    },
    emitUpdate() {
      this.$emit('update', this.email);
      this.emitClose();
    }
  }
};
</script>

<style scoped></style>
