<template>
  <v-container fluid grid-list-lg>
    <!-- Original Report List -->
    <v-layout row wrap>
      <v-flex
        xs12
        sm6
        md4
        v-for="(report, index) in reportList"
        :key="`key-${index}`"
      >
        <report-card :report="report" @handleClick="handleReportClick">
        </report-card>
      </v-flex>
    </v-layout>

    <!-- V2 Beta Report List -->
    <span v-if="isAdmin">
      <h5 class="headline font-weight-bold pt-4">V2 Beta</h5>
      <hr class="gq-divider v-divider ml-1 mb-4 theme--light" />

      <v-layout row wrap>
        <v-flex
          xs12
          sm6
          md4
          v-for="(report, index) in betaReportList"
          :key="`key-${index}`"
        >
          <report-card :report="report" @handleClick="handleReportClickV2">
          </report-card>
        </v-flex>
      </v-layout>
    </span>

    <!-- V1 Dialogs -->
    <export-files-dialog
      :is-open="showExportDialog"
      :processing="isExportProcessing"
      :error="exportDialogError"
      :email-to="login.profile.email"
      @close-dialog="closeExportDialog"
    />
    <voter-registration-report-dialog
      :is-open="showVoterRegistrationDialog"
      @send="sendVoterRegistrationReport"
      @close="showVoterRegistrationDialog = false"
    />

    <user-guide-report-type-dialog
      :is-open="showUserGuideDialog"
      @download="downloadGuide"
      @close="showUserGuideDialog = false"
    />

    <format-option-dialog
      :is-open="showFormatOptionDialog"
      :format-option="formatOption"
      @close="showFormatOptionDialog = false"
      @download="downloadUnitOverviewReport"
      @send="sendConsentReport"
    />

    <print-distribution-option-dialog
      :is-open="showPrintOptionDialog"
      :report-type="reportTypeSelected"
      @action="handlePrintAction"
      @close="showPrintOptionDialog = false"
    />

    <!-- V2 Dialogs -->
    <!-- This dialog is multi-consent specific -->
    <consent-distribution-dialog-v2
      :is-open="dialogsV2['consent-distribution-v2']"
      :report-type="reportTypeSelected"
      @action="handleReportActionV2"
      @close="dialogsV2['consent-distribution-v2'] = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ReportCard from '@/components/ReportCard';
import ExportFilesDialog from '@/components/dialogs/ExportFilesDialog';
import VoterRegistrationReportDialog from '@/components/dialogs/VoterRegistrationReportDialog';
import UserGuideReportTypeDialog from '@/components/dialogs/UserGuideReportTypeDialog';
import FormatOptionDialog from '@/components/dialogs/formatOptionDialog';
import PrintDistributionOptionDialog from '@/components/dialogs/PrintDistributionOptionDialog.vue';
import ConsentDistributionDialogV2 from '@/components/dialogs/ConsentDistributionDialogV2.vue';

export default {
  name: 'MeetingReportsView',
  components: {
    ReportCard,
    ExportFilesDialog,
    VoterRegistrationReportDialog,
    UserGuideReportTypeDialog,
    FormatOptionDialog,
    PrintDistributionOptionDialog,
    ConsentDistributionDialogV2
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      showVoterRegistrationDialog: false,
      showUserGuideDialog: false,
      showFormatOptionDialog: false,
      showPrintOptionDialog: false,
      showPrintOptionDialogV2: false,
      formatOption: '',
      reportTypeSelected: null,
      // V2 Dialogs
      dialogsV2: {
        'consent-distribution-v2': false,
        'consent-export-v2': false,
        'consent-export-v2-client': false
      }
    };
  },
  computed: {
    ...mapGetters('meetings/reports', [
      'meetingReportListData',
      'showExportDialog',
      'isExportProcessing',
      'exportDialogError'
    ]),
    ...mapGetters(['isAdmin', 'login']),
    reportList() {
      return this.meetingReportListData.filter(report => !report?.beta);
    },
    betaReportList() {
      // retrieve all beta reports
      const betaReports = this.meetingReportListData.filter(
        report => report?.beta
      );

      // If not multi-consent format, exclude multiConsentOnly reports
      if (!this.isMultiConsent) {
        return betaReports.filter(report => !report?.multiConsentOnly);
      }

      // Return all beta reports for multi-consent format
      return betaReports;
    },
    isMultiConsent() {
      return this.currentMeeting.options?.consentFormat === 'multi-consent';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/reports', [
      'getMeetingReportList',
      'downloadReport',
      'exportReport',
      'openExportDialog',
      'closeExportDialog'
    ]),
    init() {
      this.getMeetingReportList({ shortCode: this.shortCode }).catch(err =>
        this.$events.$emit('showErrorDialog', err.response)
      );
    },
    handleReportClick({ action, reportType }) {
      let region = 'default';
      if (reportType === 'voter-registration') {
        this.showVoterRegistrationDialog = true;
        return;
      }

      if (
        this.currentMeeting?.region === 'FL' &&
        (reportType === 'unit-overview' || reportType === 'consents')
      ) {
        if (this.isAdmin) {
          if (reportType === 'unit-overview') {
            this.formatOption = 'download';
          } else {
            this.formatOption = 'send';
          }
          this.showFormatOptionDialog = true;
          return;
        } else {
          region = 'florida';
        }
      }

      if (['print-distribution', 'print-files-export'].includes(reportType)) {
        this.reportTypeSelected = reportType;
        this.showPrintOptionDialog = true;
        return;
      }

      if (action === 'export') {
        return this.exportReport({
          shortCode: this.shortCode,
          type: reportType,
          region
        });
      }

      if (reportType === 'user-guide') {
        this.showUserGuideDialog = true;
        return;
      }

      this.clickDownloadReport(reportType, region);
    },
    clickDownloadReport(reportType, region) {
      this.downloadReport({
        shortCode: this.shortCode,
        reportType,
        $events: this.$events,
        region
      });
    },
    sendVoterRegistrationReport(mode = 'all') {
      this.showVoterRegistrationDialog = false;
      return this.exportReport({
        shortCode: this.shortCode,
        type: 'voter-registration',
        mode
      });
    },
    downloadGuide({ guide }) {
      this.clickDownloadReport(guide);
    },
    downloadUnitOverviewReport({ format }) {
      this.downloadReport({
        shortCode: this.shortCode,
        reportType: 'unit-overview',
        $events: this.$events,
        region: format
      });
      this.showFormatOptionDialog = false;
    },
    sendConsentReport({ format }) {
      this.exportReport({
        shortCode: this.shortCode,
        type: 'consents',
        region: format
      });
      this.showFormatOptionDialog = false;
    },
    handlePrintAction({ option, reportType, action }) {
      try {
        const region = 'default';

        if (action === 'download') {
          return this.downloadReport({
            shortCode: this.shortCode,
            reportType,
            $events: this.$events,
            region,
            filters: { [option]: true }
          });
        }

        this.exportReport({
          shortCode: this.shortCode,
          type: reportType,
          region,
          filters: { [option]: true }
        });
      } catch (error) {
        this.$events.$emit('showErrorDialog', error.response);
      } finally {
        this.showPrintOptionDialog = false;
      }
    },

    // Handle a V2 report click
    handleReportClickV2({ action, reportType }) {
      // Check if the report type requires checkbox dialogs
      const checkboxDialogReports = [
        'consent-distribution-v2',
        'consent-export-v2',
        'consent-export-v2-client'
      ];

      // For multi-consent and print type reports, open V2 print dialog
      if (this.isMultiConsent && checkboxDialogReports.includes(reportType)) {
        this.reportTypeSelected = reportType;
        this.dialogsV2['consent-distribution-v2'] = true;
        return;
      }

      // Handle report action without filters
      this.handleReportActionV2({ action, reportType });
    },
    // Handle report action and pass report params
    handleReportActionV2({
      action,
      reportType,
      options = [],
      region = 'default'
    }) {
      // If applicable to report, return filters that evaluate to "true"
      const filters = options.reduce((acc, option) => {
        acc[option] = true;
        return acc;
      }, {});

      // Check if the action type is valid
      if (!['download', 'export'].includes(action)) {
        throw new Error('Invalid action');
      }

      // Define report parameters
      const reportParams = {
        shortCode: this.shortCode,
        reportType,
        $events: this.$events,
        region,
        filters,
        version: 2
      };

      try {
        if (action === 'download') {
          return this.downloadReport({
            ...reportParams
          });
        } else if (action === 'export') {
          this.exportReport({
            ...reportParams,
            type: reportType
          });
        }
      } catch (error) {
        this.$events.$emit('showErrorDialog', error.response);
      } finally {
        this.dialogsV2['consent-distribution-v2'] = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
</style>
