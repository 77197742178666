<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">User Guide</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-card-text>Choose the type of user guide to download.</v-card-text>
      <v-container class="pt-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-radio-group
              name="guide-type"
              :mandatory="true"
              @change="handleUpdate({ value: $event })"
            >
              <v-radio
                v-for="guide in guideTypes"
                :label="guide.label"
                :value="guide.value"
                :key="guide.value"
              />
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn flat @click.native="$emit('close')">Cancel</v-btn>
          <v-spacer />
          <v-btn
            :disabled="!this.selectedGuide"
            @click.native="handleDownload()"
            color="primary"
            class="white--text"
            >Download</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'UserGuideReportTypeDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedGuide: null,
      guideTypes: [
        { label: 'Virtual Meeting', value: 'user-guide' },
        {
          label: 'Virtual Meeting (Associations)',
          value: 'user-guide-associations'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  methods: {
    handleUpdate({ value }) {
      this.selectedGuide = value;
    },
    handleDownload() {
      this.$emit('download', { guide: this.selectedGuide });
    }
  }
};
</script>

<style scoped>
.black-text {
  color: rgba(0, 0, 0, 0.87);
}
</style>
