import axios from 'axios';
import http from './requestType';

/**
 * This route returns the signed URL to retrieve a file stored on S3. A number of our API
 * responses contain a file property comprised of a {Bucket, Key} object pairing. Pass the
 * Bucket and Key into this route to view the file.
 * @param  {String} bucket  - The S3 Bucket where the file is stored.
 * @param  {String} key     - The S3 file Key
 * @return {Promise}        - Promise
 */
export function getDownloadSignedUrl(bucket, key) {
  return http._get(`Files/download-signed-url?Key=${key}&Bucket=${bucket}`);
}

/**
 * Add a new file
 * @param  {Object} file  - the new file to add
 * @return {Promise}      - Promise
 */
export function postFile(file) {
  return http._post(`Files`, file);
}

/**
 * Gets the signed URL to upload the file to temporary storage in S3
 * @param  {String} fileName  - the filename
 * @param  {String} type      - what type of file (eg. asset or private)
 * @param  {String} folder    - file folder
 * @return {Promise}          - Promise
 */
export function getUploadSignedUrl(fileName, type, folder = 'uploads') {
  return http._get(`Files/upload-signed-url`, { fileName, type, folder });
}

/**
 * Upload the file
 * @param {String} url  the URL to place the file
 * @param {File}   file the file to upload
 */
export function putAWS(url, file) {
  return axios.put(url, file, { headers: { 'Content-Type': file.type } });
}

/**
 * Save the record of the file to API
 * @param {Object} file the file record to save
 */
export function saveFile(file) {
  return http._post(`Files`, file);
}

/**
 * Remove record of file from API
 * @param {String} fileKey the unique file identifier
 */
export function deleteFile(fileKey) {
  return http._delete(`Files/${fileKey}`);
}

/**
 * Upload Public Image Asset Files to S3
 * @param  {FileList} files - List of files to upload in browser's FileList object
 * @return {Array}          - the URL to the uploaded images
 */
export async function uploadPublicImages(files) {
  let urls = [];

  // Iterate through the File
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    // Get the URL on S3 to upload the public images asset
    const res = await getUploadSignedUrl(file.name, 'asset');
    const { putObjectSignedUrl, getObjectSignedUrl } = res.data;
    await putAWS(putObjectSignedUrl, file);
    // Add URL to access public image to return array
    urls.push(getObjectSignedUrl);
  }

  return urls;
}

/**
 * @typedef   {Object} S3File
 * @property  {String} Bucket   - the S3 Bucket
 * @property  {String} Key      - the S3 Key
 * @property  {String} [url]    - URL to retrieve the file
 */
/**
 * Upload a file to a temporary location on S3
 * @param  {File} file   - the file to upload
 * @return {S3File}
 */
export async function uploadFileToS3(file) {
  try {
    // Get Signed URL to upload the file
    const res = await getUploadSignedUrl(file.name);
    const { Bucket, Key, putObjectSignedUrl, getObjectSignedUrl } = res.data;

    // Upload the file
    await putAWS(putObjectSignedUrl, file);

    return { Key, Bucket, url: getObjectSignedUrl };
  } catch (err) {
    console.error('Error uploading file', err);
    throw err;
  }
}
