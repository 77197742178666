<template>
  <div>
    <div class="title mb-2">Users</div>
    <v-toolbar dense color="elevation-1">
      <v-text-field
        class="mb-2"
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />
      <v-btn @click="dialog.addUser = true" color="primary">
        Add User
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :pagination.sync="config.pagination"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-left">
            <router-link
              :to="{
                name: 'adminUserEdit',
                params: { userKey: props.item.userKey }
              }"
            >
              {{ props.item.name }}
            </router-link>
          </td>
          <td class="text-xs-left">{{ props.item.email }}</td>
          <td class="text-xs-left">
            <v-chip
              label
              small
              :color="props.item.disabledAt ? 'red' : 'green'"
              text-color="white"
              >{{ props.item.disabledAt ? 'disabled' : 'active' }}</v-chip
            >
          </td>
          <td>
            <v-btn
              flat
              icon
              color="red"
              @click.native="deleteMembership(props.item)"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <add-membership-dialog
      :is-open="dialog.addUser"
      :current-data-list="users"
      data-set="users"
      @close-dialog="dialog.addUser = false"
      @add-users="addMembership"
    />
  </div>
</template>

<script>
import * as filters from '@/filters';
import AddMembershipDialog from '@/components/dialogs/AddMembershipDialog';
import { mapActions } from 'vuex';
export default {
  name: 'AdminAgentViewUsersTable',
  props: {
    users: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    ...filters
  },
  components: {
    AddMembershipDialog
  },
  data() {
    return {
      agentKey: this.$route.params.agentKey,
      search: '',
      dialog: {
        addUser: false
      },
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        {
          text: 'Status',
          value: 'disabledAt',
          align: 'left',
          sortable: false
        },
        { text: '', value: '', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      }
    };
  },
  methods: {
    ...mapActions('agents', ['createAgentUser', 'removeAgentUser']),
    async addMembership(selectedUsers) {
      try {
        await this.createAgentUser({
          agentUserList: selectedUsers,
          agentKey: this.agentKey
        });
        this.$events.$emit('toastEvent', 'User(s) added to agent');
      } catch (err) {
        this.$events.$emit('toastEvent', 'Error adding user(s) to agent');
        this.$events.$emit('showErrorDialog', err.response);
        console.log(err);
      }
    },
    async deleteMembership(agentUser) {
      try {
        const agentUserKey = agentUser.agentUserKey;
        const agentKey = this.agentKey;
        await this.removeAgentUser({ agentKey, agentUserKey });

        this.$events.$emit('toastEvent', 'User removed from agent');
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped></style>
