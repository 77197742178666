import http from './requestType';

/**
 * List all contacts on hubspot
 * @returns {Promise}
 */
export function getContacts({ limit = 25, after = undefined, search = '' }) {
  return http._get('Contacts', {
    limit,
    after,
    search
  });
}

/**
 * Creates a new Contact on hubspot
 * @param {Object} obj - the new contact object
 * @returns
 */
export function createContact(obj) {
  return http._post('Contacts', obj);
}

/**
 * Updates a new Contact on hubspot
 * @param {string} id - the contact id
 * @param {Object} obj - the contact object
 * @returns
 */
export function updateContact(id, obj) {
  return http._put(`Contacts/${id}`, obj);
}
