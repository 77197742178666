export const printStatus = {
  DRAFT: {
    label: 'Save Draft',
    icon: 'create',
    color: 'secondary',
    value: 'DRAFT'
  },
  SENT: {
    label: 'Send Job',
    icon: 'send',
    color: 'primary',
    value: 'SENT'
  },
  IN_PROGRESS: {
    label: 'Mark Job As "In Progress"',
    icon: 'access_time',
    color: 'primary',
    value: 'IN PROGRESS'
  },
  ON_HOLD: {
    label: 'Stop/Hold',
    icon: 'pause',
    color: 'warning',
    value: '"ON HOLD"'
  },
  COMPLETED: {
    label: 'Mark Job Completed',
    icon: 'check',
    color: 'green',
    value: 'COMPLETED'
  },
  CANCELLED: {
    label: 'Cancel Job',
    icon: 'cancel',
    color: 'error',
    value: 'CANCELLED'
  }
};
