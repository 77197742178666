import { render, staticRenderFns } from "./UploadFileSizeErrorDialog.vue?vue&type=template&id=4fa1cffc&scoped=true"
import script from "./UploadFileSizeErrorDialog.vue?vue&type=script&lang=js"
export * from "./UploadFileSizeErrorDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa1cffc",
  null
  
)

export default component.exports