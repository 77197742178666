<template>
  <v-card class="ma-2 rounded-borders" color="grey lighten-4" flat>
    <v-card-text>
      <!-- Data loading -->
      <div v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>

      <div v-else>
        <!-- Custom slot for header content -->
        <slot name="header" />

        <!-- Cached subscription exists -->
        <div v-if="subscription">
          <v-layout row>
            <v-flex xs12>
              <div class="grey--text text--darken-1">Subscription:</div>
              <router-link
                class="mt-2"
                :to="{
                  name: 'accountSubscription',
                  params: { accountKey }
                }"
                target="_blank"
              >
                <span
                  v-if="subscription.status === 'no_subscription'"
                  class="capitalize"
                  >{{ formatStatus(subscription.status) }}</span
                >
                <span v-else class="capitalize"
                  >{{ subscription.productName }} ({{
                    formatStatus(subscription.status)
                  }})</span
                >
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout row v-if="!hideRefresh">
            <v-flex xs6>
              <div class="mt-3 grey--text text--darken-1">
                Last Updated:
              </div>
              <div class="grey--text text--darken-1">
                {{ cachedAt | dateFromNow }}
              </div>
            </v-flex>
            <v-flex xs6 class="mt-3 text-xs-right">
              <v-btn
                flat
                icon
                small
                @click.native="refreshSubscription"
                :loading="loading"
                color="secondary"
              >
                <v-icon>cached</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>

        <!-- Cached subscription does not exist -->
        <div v-else>
          <v-layout row>
            <v-flex xs6
              ><div class="grey--text text--darken-1">Subscription:</div>
              <router-link
                class="mt-2"
                :to="{
                  name: 'accountSubscription',
                  params: { accountKey }
                }"
                target="_blank"
              >
                <span>No Subscription</span>
              </router-link></v-flex
            >
            <v-flex xs6 class="text-xs-right" v-if="!hideRefresh">
              <v-btn
                flat
                icon
                small
                @click.native="refreshSubscription"
                :loading="loading"
                color="secondary"
              >
                <v-icon>cached</v-icon>
              </v-btn></v-flex
            >
          </v-layout>
        </div>

        <div>
          <div class="my-1">
            <a :href="goToInvoicesPage">
              <span class="red--text text-underline">
                Unpaid invoices amount: ${{ invoiceOwing }}
              </span>
            </a>
          </div>

          <div class="my-1">
            <a :href="goToSubscriptionPage">
              <span class="red--text text-underline">
                Unpaid subscription amount: ${{ subscriptionBalance }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';

import { dateFromNow } from '@/filters';

export default {
  name: 'MeetingNavDrawerInfo',
  props: {
    accountKey: {
      type: String,
      default() {
        return '';
      }
    },
    subscription: {
      type: Object,
      default() {
        return {
          status: '',
          name: ''
        };
      }
    },
    invoiceOwing: {
      type: Number,
      default: 0
    },
    subscriptionBalance: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    // Hides the last updated and refresh button functionality
    hideRefresh: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    dateFromNow
  },
  computed: {
    cachedAt() {
      return this.subscription ? this.subscription.cachedAt : null;
    },
    goToSubscriptionPage() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';
      const url = `${baseUrl}/admin/accounts/${this.accountKey}/subscription`;
      return url;
    },
    goToInvoicesPage() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';
      const url = `${baseUrl}/admin/accounts/${this.accountKey}`;
      return url;
    }
  },
  methods: {
    formatStatus(value) {
      if (value === 'non_renewing') {
        return 'Non-Renewing';
      }
      if (value === 'no_subscription') {
        return 'No Subscription';
      }
      return value;
    },
    refreshSubscription() {
      this.$emit('refresh');
    }
  }
};
</script>

<style scoped>
.text-underline {
  text-decoration: underline;
}
</style>
