<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Generate Control Numbers For No Email Units</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="controlNumberDigits"
          label="Number of digits"
          placeholder="13"
          hint="Between 10 and 20"
          type="number"
          outline
          persistent-hint
          min="10"
          max="20"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          :disabled="controlNumberDigits < 10 || controlNumberDigits > 20"
          color="primary"
          @click.native="emitGenerateControlNumbers"
          >Generate</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsGenerateControlNumbersDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      controlNumberDigits: 13
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');

      // reset value
      this.controlNumberDigits = 13;
    },
    emitGenerateControlNumbers() {
      this.$emit('generateControlNumbers', this.controlNumberDigits);
    }
  }
};
</script>

<style scoped></style>
