<template>
  <div class="text-xs-center">
    <v-menu
      v-model="menu"
      :max-width="450"
      :min-height="900"
      :close-on-content-click="false"
      :close-on-click="true"
      left
      nudge-left="55"
      nudge-top="11"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator="{ on }">
        <div class="text-xs-right">
          <!-- If excluded, show "-" -->
          <span v-if="isExcluded">-</span>
          <v-chip v-on="on" small class="white" v-else
            >{{ numVotes }}
            <v-icon color="primary" class="pl-1" small>edit</v-icon>
          </v-chip>
        </div>
      </template>

      <v-card>
        <v-card-title class="pt-1 pb-2">
          <v-container class="py-2 px-1" grid-list-md>
            <v-layout align-end>
              <v-flex xs2>
                <v-text-field
                  v-model="voteItemNumOfVotes"
                  label="Votes"
                  ref="addVoteTextfield"
                  :rules="[rules.number]"
                  placeholder=""
                  hide-details
                />
              </v-flex>
              <v-flex xs7>
                <v-text-field
                  v-model="voteItem"
                  label="Description"
                  hide-details
                />
              </v-flex>

              <v-flex xs3>
                <v-btn
                  color="primary"
                  class="btn-position"
                  @click="emitAdd"
                  :disabled="!isValidNumOfVotes"
                  >add</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <v-layout
            justify-center
            class="pa-0 ma-0"
            v-if="validVoteItems.length + deletedVoteItems.length > 0"
          >
            <div @click="showVoteItems = !showVoteItems">
              <span class="caption pointer grey--text"
                >{{ showVoteItems ? 'Hide' : 'View' }} history ({{
                  validVoteItems.length + deletedVoteItems.length
                }}
                item)</span
              ><v-icon small
                >{{ showVoteItems ? 'expand_more' : 'expand_less' }}
              </v-icon>
            </div>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>

        <transition name="slide-y-transition">
          <div class="scrollable-area" v-if="showVoteItems">
            <v-list
              v-show="validVoteItems.length > 0"
              single-line
              class="pt-2 pb-0"
            >
              <!-- List all valid vote items -->
              <v-list-tile
                v-for="item in validVoteItems"
                :key="item.key"
                class="py-1"
              >
                <v-list-tile-avatar>
                  {{ item.total }}
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title>
                    <!-- Display full vote item message in tooltip if >39 characters -->
                    <v-tooltip
                      bottom
                      v-if="getStringLength(item.vote_item) > 39"
                    >
                      <span slot="activator" style="font-size: 15px;">{{
                        item.vote_item
                      }}</span>
                      {{ item.vote_item }}
                    </v-tooltip>
                    <!-- No tooltip -->
                    <span style="font-size: 15px;" v-else>{{
                      item.vote_item
                    }}</span>
                  </v-list-tile-title>

                  <v-list-tile-sub-title
                    v-if="!item.deleted_at"
                    class="caption"
                  >
                    Added {{ item.created_at | dateFromNow }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <delete-menu :item="item" @delete="emitDelete" />
                </v-list-tile-action>
              </v-list-tile>
            </v-list>

            <!-- List all deleted vote items -->
            <v-list
              v-show="deletedVoteItems.length > 0"
              two-line
              class="pt-0 mt-0"
            >
              <v-list-tile v-for="item in deletedVoteItems" :key="item.key">
                <v-list-tile-avatar>
                  <span :class="{ 'strike-through': item.deleted_at }">{{
                    item.total
                  }}</span>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title>
                    <!-- Display full vote item message if >39 characters -->
                    <v-tooltip
                      bottom
                      v-if="getStringLength(item.vote_item) > 39"
                    >
                      <span
                        slot="activator"
                        style="font-size: 15px;"
                        :class="{ 'strike-through': item.deleted_at }"
                        >{{ item.vote_item }}
                      </span>
                      {{ item.vote_item }}
                    </v-tooltip>
                    <!-- No tooltip -->
                    <span
                      v-else
                      style="font-size: 15px;"
                      :class="{ 'strike-through': item.deleted_at }"
                      >{{ item.vote_item }}
                    </span>
                  </v-list-tile-title>

                  <v-list-tile-sub-title class="caption">
                    Removed {{ item.deleted_at | dateFromNow }} by
                    {{ item.deleted_at_name }}<br />
                    Added
                    {{ item.created_at | formatDateHumanReadableAltShort }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon class="grey--text" v-if="item.message" v-on="on"
                        >info</v-icon
                      >
                    </template>
                    <span>{{ item.message }}</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </div>
        </transition>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { dateFromNow, formatDateHumanReadableAltShort } from '@/filters';
import deleteMenu from '@/components/dialogs/AdditionalVotePopoverDeleteMenu';

export default {
  name: 'AdditionalVotePopover',
  components: {
    deleteMenu
  },
  filters: {
    dateFromNow,
    formatDateHumanReadableAltShort
  },
  props: {
    questionKey: {
      type: String,
      default: ''
    },
    vote: {
      type: String,
      default: ''
    },
    numVotes: {
      type: Number,
      default: 0
    },
    isExcluded: {
      type: Boolean,
      default: false
    },
    voteItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    menu(val) {
      if (val === true) {
        // Workaround to autofocus textfield on open

        setTimeout(() => {
          this.$refs.addVoteTextfield.focus();
        }, 100);
      } else {
        this.dialog.delete = false;
      }
    }
  },
  computed: {
    validVoteItems() {
      return this.voteItems.filter(item => {
        // If both total and vote_item are blank, ignore the record
        // This is allowed for "add option"
        if (item.total === 0 && !item.vote_item) {
          return false;
        }
        // Check if deleted
        if (item.deleted_at) {
          return false;
        }
        return true;
      });
    },
    deletedVoteItems() {
      return this.voteItems.filter(item => item.deleted_at);
    },
    isValidNumOfVotes() {
      let value = this.voteItemNumOfVotes;
      if (!value) {
        return false;
      } else if (isNaN(value)) {
        return false;
      }
      return true;
    }
  },
  data() {
    return {
      menu: false,
      voteItem: '',
      voteItemNumOfVotes: '',
      showVoteItems: true,
      dialog: {
        delete: false
      },
      rules: {
        number: value => {
          // Accept empty/undefined/null field (equivalent to zero)
          if (!value) {
            return true;
          } else {
            return !isNaN(value) || 'Invalid';
          }
        }
      }
    };
  },

  methods: {
    hasVoteItems(voteItems) {
      let filteredItem = voteItems.filter(item => !item.deleted_at);
      return filteredItem.length > 0;
    },
    emitAdd() {
      let additionalVoteObj = {
        questionKey: this.questionKey,
        vote: this.vote,
        voteItem: this.voteItem,
        voteItemNumOfVotes: this.voteItemNumOfVotes
      };

      this.$emit('add', additionalVoteObj);
      this.voteItem = '';
      this.voteItemNumOfVotes = '';
    },
    emitDelete({ additionalVoteKey, message }) {
      this.$emit('delete', { additionalVoteKey, message });
    },
    getStringLength(val) {
      return [...val].length;
    }
  }
};
</script>

<style scoped>
.btn-position {
  position: relative;
  top: 7px;
}
.btn-position-bottom {
  position: relative;
  bottom: 20px;
  left: 2px;
}
.scrollable-area {
  max-height: 300px;
  overflow: auto;
}
.pointer {
  cursor: pointer;
}
.strike-through {
  text-decoration-line: line-through;
  color: grey;
  opacity: 0.4;
}
</style>
