var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"body-2 mb-2"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"consent-container"},[_c('v-btn-toggle',{staticClass:"consent-toggle elevation-0",attrs:{"value":_vm.getButtonValue,"mandatory":""},on:{"change":_vm.handleChange}},[_c('v-btn',{class:{ selected: _vm.value === null },attrs:{"flat":"","value":0}},[_vm._v(" No Action ")]),_c('v-btn',{class:{
          'selected-rejected': _vm.value === false,
          'rejected-text': _vm.value !== false
        },attrs:{"flat":"","value":1}},[_vm._v(" Rejected ")]),_c('v-btn',{class:{
          'selected-consented': _vm.value === true,
          'consented-text': _vm.value !== true
        },attrs:{"flat":"","value":2}},[_vm._v(" Consented ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }