<template>
  <v-list-tile
    :to="{ name: routeObject.name }"
    class="tile"
    :class="{
      'tile-background': routeObject.isNestedNavLabel,
      'tile--active': $route.path.includes(path)
    }"
  >
    <v-list-tile-avatar size="30" class="tile-avatar">
      <v-icon
        size="18"
        color="white"
        :class="{
          'tile-avatar--active': $route.path.includes(path),
          'tile-avatar--inactive': !$route.path.includes(path)
        }"
        >{{ routeObject.icon }}</v-icon
      >
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title class="tile-title">{{
        routeObject.label
      }}</v-list-tile-title>
    </v-list-tile-content>

    <v-list-tile-action v-if="count">
      <div class="tile-notification">{{ count }}</div>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
export default {
  name: 'MeetingNavDrawerListTile',
  props: {
    routeObject: {
      type: Object,
      default() {
        return {};
      }
    },
    count: {
      type: Number,
      default: null
    }
  },
  computed: {
    path() {
      return `/meetings/${this.$route.params.shortcode}/${this.routeObject.path}`;
    }
  }
};
</script>

<style scoped lang="scss">
.tile {
  &--active {
    background-color: #e7f2fb;
    color: #0f64a9;
  }
}
.tile-avatar {
  color: #fff;
  &--active {
    background-color: #0f64a9;
  }

  &--inactive {
    background-color: #c4c4c4;
  }
}
.tile-title {
  font-size: 16px;
}
.tile-notification {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #da4f7a;
  text-align: center;
  color: #fff;
}
.tile-background {
  background-color: #f5f5f5;
}
</style>
