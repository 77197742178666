<template>
  <v-dialog max-width="650px" :value="value" persistent>
    <v-card>
      <v-card-title class="headline">Re-request Review</v-card-title>
      <v-card-text>
        <div>
          <div>
            <b>Meeting:</b>
            {{ approval.meetingName }}
          </div>
          <div>
            <b>Approval Request:</b>
            {{ approval.name }}
          </div>
          <div>
            <b>Reviewer:</b>
            {{ reviewerString }}
          </div>
        </div>
        <div class="mt-3">
          Click
          <b>confirm</b>
          to re-request review and send an email to the reviewer listed above.
        </div>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn flat @click="emitClose">cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click="emitSend">
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApprovalRequestsReminderDialog',
  filters: {},
  props: {
    approval: {
      type: Object,
      default() {
        return {};
      }
    },
    reviewer: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    reviewerString() {
      if (this.reviewer.firstName && this.reviewer.lastName) {
        return `${this.reviewer.firstName} ${this.reviewer.lastName} (${this.reviewer.email})`;
      }
      return this.reviewer.email;
    }
  },
  methods: {
    emitSend() {
      this.$emit('send');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
