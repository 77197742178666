<template>
  <div>
    <div v-for="(question, index) in questions" :key="`key-${index}`">
      <div class="gq-cell--section-title">
        {{ question.label }}
      </div>

      <v-layout row class="mb-3">
        <v-flex xs12>
          <!-- Account Users can see live questions, but not data - if campaign is collecting advance ballots in RTV -->
          <div v-if="!canSeeData">
            <v-alert
              :value="true"
              class="vote-alert"
              type="info"
              icon="lock"
              color="#607d8b"
            >
              The results are secret and will be provided to you after the
              meeting.
            </v-alert>
          </div>
          <v-data-table
            v-else
            :headers="headers"
            :pagination="pagination"
            :items="question.tally"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td class="text-xs-left">{{ props.item.vote }}</td>
                <td class="text-xs-right">
                  {{ formatVotes(props.item.numVotes) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import checkScope from '@/lib/check-user-scopes';

export default {
  name: 'LiveVoteTally',
  components: {},
  props: {
    questions: {
      type: Array,
      default() {
        return [];
      }
    },

    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default: '0'
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    canSeeData() {
      // If user is Account User, and we're collecting ballots in RTV,
      // we show that this question exists but they cannot read the data
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.read')
      );
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      headers: [
        {
          text: 'Vote',
          value: 'vote'
        },
        {
          text: 'Votes',
          value: 'num_votes',
          align: 'right'
        }
      ],
      pagination: {
        sortBy: 'num_votes',
        descending: true,
        rowsPerPage: -1
      }
    };
  },
  methods: {
    formatVotes(value) {
      return this.displayRoundedResults && value
        ? parseFloat(Number(value)).toFixed(
            Number(this.roundedResultsPrecision)
          )
        : value;
    }
  }
};
</script>
<style scoped></style>
