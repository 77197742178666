<template>
  <v-card class="px-2 py-2 approval-card" :elevation="0">
    <div class="d-flex items-center justify-between">
      <v-card-text
        >Approve this request to create a new service card. Edit service details
        and communicate with the requester in the activity feed
        below.</v-card-text
      >

      <v-card-actions style="width: 25%">
        <v-btn
          v-if="status === 'pending'"
          outline
          color="error"
          block
          @click.native="handleDeny"
        >
          DENY
        </v-btn>

        <v-btn
          v-if="status === 'pending'"
          color="success"
          block
          @click.native="handleApprove"
        >
          APPROVE
        </v-btn>
        <v-btn v-else-if="status === 'denied'" block disabled>DENIED</v-btn>
        <v-btn
          v-else-if="status === 'approved'"
          block
          color="primary"
          @click="goToMeetingCard"
          >GO TO MEETING CARD</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ApprovalCard',
  props: {
    status: {
      type: String,
      default: 'pending'
    },
    meetingUrl: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters(['login'])
  },
  methods: {
    handleApprove() {
      this.$emit('approve');
    },
    handleDeny() {
      this.$emit('deny');
    },
    goToMeetingCard() {
      window.location.href = `${this.meetingUrl}`;
    }
  }
};
</script>

<style scoped>
.approval-card {
  border: 2px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}
</style>
