<template>
  <v-container grid-list-lg>
    <v-layout row class="mt-3 mb-3">
      <v-flex xs12>
        <p class="title">Product: {{ product.name }}</p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12>
        <v-card class="elevation-1">
          <v-card-text>
            <v-layout row>
              <v-flex xs12>
                <v-text-field
                  box
                  v-model="state.name"
                  label="Product Name"
                  counter
                />
              </v-flex>
            </v-layout>

            <v-layout row nowrap>
              <v-flex xs12 sm4 class="pr-1">
                <v-text-field
                  box
                  type="number"
                  v-model="state.productCode"
                  label="Product Code"
                />
              </v-flex>
            </v-layout>

            <v-layout row nowrap>
              <v-flex xs6 sm4 class="pl-1 pr-1">
                <v-select
                  box
                  :items="productTypes"
                  item-value="value"
                  item-text="label"
                  v-model="state.productType"
                  label="Product Type"
                  @change="setCategory"
                />
              </v-flex>

              <v-flex xs6 sm4 class="pl-1">
                <v-autocomplete
                  v-model="state.category"
                  :items="categories"
                  box
                  label="Product Category"
                />
              </v-flex>
            </v-layout>

            <v-layout row nowrap>
              <v-flex xs6 sm4 class="pr-1">
                <v-text-field
                  box
                  v-model="state.valueCad"
                  label="CAD Price ($)"
                  type="number"
                />
              </v-flex>

              <v-flex xs6 sm4 class="pl-1">
                <v-text-field
                  box
                  v-model="state.valueUsd"
                  label="USD Price ($)"
                  type="number"
                />
              </v-flex>
            </v-layout>

            <v-layout row nowrap>
              <v-flex xs6 sm4 class="pr-1">
                <v-text-field
                  box
                  v-model="state.minUnits"
                  label="Minimum number of units in tier"
                  type="number"
                />
              </v-flex>

              <v-flex xs6 sm4 class="pl-1">
                <v-text-field
                  box
                  v-model="state.maxUnits"
                  label="Maximum number of units in tier"
                  type="number"
                />
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs12>
                <v-textarea
                  box
                  v-model="state.description"
                  label="Product Description (Markdown Enabled)"
                  auto-grow
                  counter
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="red" :loading="saving" @click.native="remove"
              >Delete</v-btn
            >
            <v-spacer />
            <v-btn
              flat
              color="primary"
              :disabled="!isChanged"
              :loading="saving"
              @click.native="setState"
              >Cancel</v-btn
            >
            <v-btn
              flat
              color="primary"
              :disabled="!isChanged"
              :loading="saving"
              @click.native="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AdminProductsEditView',
  data() {
    return {
      saving: false,
      productKey: this.$route.params.productKey,
      state: { productType: 0 },
      originalState: {}
    };
  },
  async mounted() {
    await this.getProduct({ productKey: this.productKey });
    this.setState();
  },
  computed: {
    ...mapGetters('products', [
      'productByKey',
      'productTypes',
      'categoriesByType'
    ]),
    product() {
      return this.productByKey(this.productKey) || {};
    },
    categories() {
      return this.categoriesByType(this.state.productType);
    },
    isChanged() {
      return !_.isEqual(this.state, this.originalState);
    }
  },
  methods: {
    ...mapActions('products', ['getProduct', 'updateProduct', 'deleteProduct']),
    setState() {
      this.state = { ...this.product };
      this.originalState = { ...this.product };
    },
    async save() {
      try {
        this.saving = true;
        await this.updateProduct({
          productKey: this.productKey,
          product: this.state
        });
        this.$events.$emit('toastEvent', 'Product Updated');
        this.setState();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    },
    async remove() {
      try {
        const isConfirm = window.confirm(
          'Are you sure you want to delete this product?'
        );

        if (!isConfirm) {
          return null;
        }

        this.saving = true;
        await this.deleteProduct({
          productKey: this.productKey,
          productCode: this.product.productCode
        });
        this.$events.$emit('toastEvent', 'Product Deleted');
        this.$router.replace({ name: 'adminProducts' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    },
    // See if we need to set the category to discount if type was set to discount
    setCategory() {
      if (this.state.productType === 2) {
        this.state.category = 'discount';
      } else if (this.state.productType === 1) {
        this.state.category = 'software license';
      } else {
        this.state.category = 'professional services';
      }
    }
  }
};
</script>
