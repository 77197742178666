'use strict';

import { removeEmptyProperty } from '@/lib/property-helpers';
import { getRecipe } from '@/api/recipes';
import _ from 'lodash';

/**
 * Takes an existing Meeting object and toggles Open Ballot settings
 * on or off. If we're turning O.B. settings on, we retrieve a Recipe
 * whose payload holds custom settings. If we're turning O.B. settings
 * off, we simply use the default options from the make-meeting-object
 * library helper to update Meeting settings.
 *
 * @param  {Object} meeting     - the existing Meeting object
 * @return {Object}             - the updated Meeting object
 */
export async function toggleOpenBallot(meeting) {
  try {
    const recipe = await getRecipe('meetings.settings.open_ballot');
    const settings = recipe.data.data;
    let virtualMeeting = false;

    // Retain all meeting options
    const meetingOptions = _.cloneDeep(meeting.options);

    const meetingUpdateObj = removeEmptyProperty(meeting);

    // if meeting is virtual, proxy deferrals will be disabled.
    // we want to keep this option in place whether campaign is
    // for an open ballot or not
    if (meetingUpdateObj.options.disableProxyDeferrals) {
      virtualMeeting = true;
    }

    if (meeting.options.openBallot) {
      // assign custom settings/proxy template if Open Ballot is being enabled
      meetingUpdateObj.options = settings.Meeting.options;
      meetingUpdateObj.options.openBallot = true;
    } else if (!meeting.options.openBallot) {
      // remove custom settings/proxy template if Open Ballot is being disabled
      // we use the default options from the make-meeting-object lib helper
      const defaultOptions = {
        blockUnverifiedVoters: true,
        customUnitFields: ['ineligible_reason', 'owner_occupied', 'shares'],
        type: 'meeting',
        proxyAppDisplayOptions: {
          ownership: {
            addressInputType: 'text',
            disableInputs: true,
            headerText: 'Is your ownership information correct?',
            snippet:
              'Please verify your records with the Corporation. If your information requires updating, please contact management.'
          },
          proxy: {
            experimentalMode: true
          }
        }
      };

      meetingUpdateObj.options = defaultOptions;
    }

    // make sure proxy deferrals are still disabled for virtual meetings
    if (virtualMeeting) {
      meetingUpdateObj.options.disableProxyDeferrals = true;
    }

    meetingUpdateObj.options = {
      ...meetingOptions,
      ...meetingUpdateObj.options
    };

    return meetingUpdateObj;
  } catch (err) {
    console.error('Error: toggleOpenBallot action', err);
    throw err;
  }
}
