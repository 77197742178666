var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"dark":"","color":"grey lighten-4 elevation-1"}},[_c('v-text-field',{attrs:{"light":"","prepend-icon":"search","label":"Search","single-line":"","hide-details":"","clearable":"","value":_vm.searchText},on:{"input":_vm.setText}}),_c('v-spacer'),_c('v-select',{attrs:{"box":"","solo":"","light":"","single-line":"","items":_vm.statusFilterOptions,"item-text":"text","item-value":"value","value":_vm.statusFilter,"label":"Status","hide-details":"","dense":""},on:{"input":_vm.updateFilter}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoiceListData,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"rows-per-page-items":_vm.pagination.rowsPerPageItems,"loading":_vm.loading},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"table-item"},[_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("dateFormatReadable")(props.item.invoiceDate))+" ")]),_c('td',{staticClass:"uppercase"},[(_vm.isChargebeeInvoice(props.item))?_c('a',{attrs:{"href":_vm.getChargebeeLink(props.item),"target":"_blank"}},[_c('b',[_vm._v(_vm._s(props.item.invoiceNumber))])]):_c('router-link',{attrs:{"to":{
              name: 'adminInvoice',
              params: { invoiceKey: props.item.key }
            },"target":"_blank"}},[_c('b',[_vm._v(_vm._s(props.item.invoiceNumber))])]),_c('div',{staticClass:"caption italics nowrap"},[(props.item.meetingCode)?_c('span',[_vm._v(" MTG: "+_vm._s(props.item.meetingName)+" "),(props.item.meetingCode)?_c('router-link',{staticClass:"nowrap",attrs:{"to":{
                  name: 'meetingOverview',
                  params: { shortcode: props.item.meetingCode }
                },"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("open_in_new")])],1):_vm._e()],1):_c('span',[_vm._v(" SUB: "+_vm._s(props.item.accountNameShort)+" ")])])],1),_c('td',{staticClass:"text-xs-right"},[_c('span',{class:_vm.getOwingColor(props.item.amountOwing)},[_vm._v(_vm._s(_vm._f("formatCurrencyValue")(props.item.amountOwing)))])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyValue")(props.item.totalPrice))+" ")]),_c('td',[_vm._v(_vm._s(props.item.currency))]),_c('td',[_c('v-chip',{staticClass:"capitalize",attrs:{"small":"","label":"","disabled":"","color":_vm.getStatusChipColor(props.item),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getStatus(props.item))+" ")])],1),_c('td',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm._f("dateFormatReadable")(props.item.sentAt)))]),(!_vm.options.hideViewedColumn)?_c('td',[_vm._v(" "+_vm._s(props.item.hasSentViews ? 'Yes' : '')+" ")]):_vm._e(),_c('td',[_c('v-tooltip',{attrs:{"bottom":""}},[_c('span',{staticClass:"truncate",attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(props.item.additionalData.privateNotes))]),_c('span',[_vm._v(_vm._s(props.item.additionalData.privateNotes))])])],1)])]}}])},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }