'use strict';

/**
 * @fileoverview Vuex module for a meeting's files
 */

import _ from 'lodash';
import Vue from 'vue';

import * as meetingFilesApi from '@/api/meetings-files';
import forceLinkDownload from '@/lib/force-link-download';

const state = {
  filesLoading: false,
  meetingFiles: [],
  dataLastFetchedAt: null
};

const getters = {
  filesLoading: state => state.filesLoading,
  meetingFiles: state => state.meetingFiles
};

const actions = {
  /**
   * Get the list of meeting reports available for this user
   * @param  {String} {shortCode}  meeting code
   */
  async fetchMeetingFiles({ commit, dispatch }, { shortCode }) {
    try {
      dispatch('setFilesLoading', { toggle: true });
      const res = await meetingFilesApi.getMeetingFiles(shortCode);
      dispatch('setFilesLoading', { toggle: false });
      return commit('SET_MEETING_FILES_DATA', res.data);
    } catch (err) {
      console.error('Error: getMeetingFiles action', err);
      throw err;
    }
  },

  /**
   * Sends a meeting file to trash
   * @param  {String} shortCode meeting code
   * @param  {String} Key       S3 file key
   * @param  {String} [folder]  optional folder
   */
  async trashMeetingFile({ dispatch }, { shortCode, Key }) {
    try {
      const res = await meetingFilesApi.putTrashMeetingFile({ shortCode, Key });
      dispatch('fetchMeetingFiles', { shortCode });
      return res.data;
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get the signed URL to upload files to the `files` folder for this meeting
   * @param  {String} shortCode meeting code
   * @param  {String} fileName  filename
   * @param  {String} [folder]  optional folder
   */
  async getPutSignedUrl({ dispatch }, { shortCode, fileName, folder }) {
    try {
      const res = await meetingFilesApi.getPutSignedUrl(
        shortCode,
        fileName,
        folder
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },

  /**
   * Upload the file to signed url
   * @param  {String} url - url s3 bucket
   * @param  {Object} file - file to upload
   * @return {Promise} - Promise
   */
  async putSignedUrl({ dispatch }, { url, file }) {
    try {
      return await meetingFilesApi.putSignedUrl(url, file);
    } catch (err) {
      console.error('Error retrieving signed URL', err);
      throw err;
    }
  },

  /**
   * This action retrieves the meeting file from an S3 object and forces it to download
   * @param  {string} shortCode   - The meeting shortCode
   * @param  {string} Key         - The S3 file Key
   * @param  {string} name        - the file name to save to drive
   */
  async downloadMeetingFile({ dispatch }, { shortCode, Key, name }) {
    try {
      const { data } = await meetingFilesApi.getGetSignedUrl({
        shortCode,
        key: Key,
        name
      });

      // If no filename, open the url in the browser so that user can
      // decide what name to use.
      if (!name) {
        return window.open(data.url, '_blank');
      }

      // Otherwise for the file to download
      await forceLinkDownload({ url: data.url, name });
    } catch (err) {
      console.error('Error downloading file from action', err);
      throw err;
    }
  },

  /**
   * Get the pre-signed URL to upload files to the `files` folder for
   * this meeting
   * @param  {String} shortCode meeting code
   * @param  {String} fileName  filename
   * @param  {String} [folder]  optional folder
   */
  async getPostPreSignedUrl({ commit }, { shortCode, fileName, folder }) {
    try {
      const res = await meetingFilesApi.getPostPreSignedUrl(
        shortCode,
        fileName,
        folder
      );

      return res.data;
    } catch (err) {
      throw err;
    }
  },

  /**
   * Set the loading state for files module
   * @param  {Boolean} toggle  - The loading state to toggle (true/false)
   */
  setFilesLoading({ commit }, { toggle }) {
    commit('SET_FILES_LOADING_STATE', toggle);
  }
};

const mutations = {
  SET_MEETING_FILES_DATA(state, { data }) {
    Vue.set(state, 'meetingFiles', data);
    state.dataLastFetchedAt = new Date();
  },

  // Reset the state
  CLEAR_STATE(state) {
    Vue.set(state, 'meetingFiles', []);
    state.dataLastFetchedAt = null;
  },
  SET_FILES_LOADING_STATE(state, toggle) {
    Vue.set(state, 'filesLoading', toggle);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
