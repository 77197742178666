<template>
  <v-container class="background" fill-height grid-list-md fluid>
    <v-layout v-if="!loading" row align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg4>
        <!-- Login Card -->
        <v-card>
          <v-card-title class="gq-blue-background">
            <v-container>
              <v-layout column align-center>
                <v-flex xs12>
                  <img src="../assets/gq_navbar_logo.svg" class="login-image" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout column align-center>
                <v-flex xs12>
                  <h1 class="headline mb-2">Log in to your Dashboard</h1>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-alert
                    transition="slide-y-transition"
                    class="mb-4"
                    :outline="!alert.solid"
                    :color="alert.type"
                    :icon="alert.icon"
                    v-model="alert.show"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-flex>
              </v-layout>
              <form action="" @submit.prevent="submitLogin">
                <v-layout row class="pt-2">
                  <v-flex xs12>
                    <v-text-field
                      name="username"
                      id="username"
                      ref="username"
                      type="text"
                      label="Email Address"
                      clearable
                      prepend-icon="person"
                      autocomplete="email"
                      :rules="rules.username"
                      v-model="login.username"
                      @keyup.enter="$refs.password.focus()"
                      required
                    />
                    <v-text-field
                      name="password"
                      id="password"
                      ref="password"
                      type="password"
                      label="Password"
                      clearable
                      v-model="login.password"
                      :rules="rules.password"
                      prepend-icon="lock"
                      required
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-2" align-center justify-center>
                  <v-btn large type="submit" color="primary">Log In</v-btn>
                </v-layout>
                <v-layout class="mt-4" align-center justify-center>
                  <v-flex xs6 class="text-xs-left">
                    <router-link :to="{ name: 'forgotPassword' }" replace>
                      <a class="secondary--text">Forgot your password?</a>
                    </router-link>
                  </v-flex>
                  <v-flex xs6 class="text-xs-right">
                    <a @click="openGoogleLogin" class="secondary--text">
                      Log in with Google
                    </a>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <global-events />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _debug from '@/lib/debug';
import setAlert from '@/lib/set-login-alert';

import GlobalEvents from '@/components/GlobalEvents';

export default {
  name: 'LoginView',
  components: {
    GlobalEvents
  },
  data() {
    return {
      login: {
        username: '',
        password: '',
        path: ''
      },
      loading: false,
      rules: {
        username: [v => !!v || 'Email address required'],
        password: [v => !!v || 'Password required']
      },
      infoAlert: {
        show: false
      },
      alert: {
        type: null,
        message: null,
        icon: null,
        show: false
      }
    };
  },
  created() {
    this.checkSession();

    // Initialize google client if needed
    if (!this.googleClient) {
      // Check if script has loaded
      if (this.isGoogleSignInAvailable) {
        this.initGoogleLogin();
      } else {
        // If not, manually load the script
        const script = document.createElement('script');
        script.src = '//accounts.google.com/gsi/client';
        document.body.appendChild(script);

        // Once the script is loaded, initialize the google client
        script.onload = () => {
          this.initGoogleLogin();
        };
      }
    }
  },
  mounted() {
    // Check if any query params come in that need re-routing or custom display
    this.alert = setAlert(this.$route.query.alert);
    this.login.path = this.$route.query.redirect;
  },
  computed: {
    ...mapGetters(['googleClient']),
    isGoogleSignInAvailable() {
      // typeof will work even if `google` is not declared
      return typeof window.google !== 'undefined';
    }
  },
  methods: {
    ...mapActions([
      'doLogin',
      'getUserProfile',
      'clearCredentials',
      'initGoogleLogin',
      'doRefreshToken'
    ]),
    async submitLogin() {
      try {
        this.alert = setAlert('default');
        await this.doLogin({
          username: this.login.username,
          password: this.login.password,
          path: this.login.path
        });
      } catch (err) {
        this.alert = setAlert(err.alert);
      }
    },
    async checkSession() {
      try {
        this.loading = true;

        // Check if access_token is still valid
        const user = await this.getUserProfile();

        // If it's not valid, try refreshing access_token
        if (!user) {
          await this.doRefreshToken();
        }

        _debug('Login session valid');
        this.$router.push({ name: 'meetings' });
      } catch (err) {
        _debug('Login session no longer valid');
        // Ensure session storage is cleared so we can do a clean login
        this.clearCredentials();
      } finally {
        this.loading = false;
      }
    },
    async openGoogleLogin() {
      try {
        // RequestAccessToken will open google login dialog
        this.googleClient.requestAccessToken();
      } catch (err) {
        this.alert = setAlert('other-google-error');
        if (err.response) {
          this.$events.$emit('showErrorDialog', err.response);
        }
      }
    }
  }
};
</script>

<style scoped></style>
