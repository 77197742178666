<template>
  <div>
    <v-layout row wrap class="mt-4">
      <v-flex xs12 md3>
        <accounts-view-info
          :name="currentAccount.name"
          :name-short="currentAccount.nameShort"
          :contact="currentAccount.contact"
          :address="currentAccount.address"
          :return-address="currentAccount.returnAddress"
          :billing-address="currentAccount.billingAddress"
          :data="currentAccount.data"
          :customer-type="currentAccount.customerType"
          :region="currentAccount.region"
          :logoUrl="currentAccount.logoUrl"
          :currency="currentAccount.billingCurrency"
          :company-id="currentAccount.hubspotCompanyId"
          @edit="dialog.editInfo = true"
        />
        <accounts-notes :notes="currentAccount.data.notes" @update="addNote" />
      </v-flex>
      <v-flex xs12 md9>
        <div v-if="hasCompanyId">
          <h4 class="body-2 campaign-title mt-2">Contacts</h4>
          <account-contacts-table />
        </div>
        <h4 class="body-2 campaign-title">Campaigns</h4>
        <accounts-campaign-table :campaigns="currentAccount.meetings" />
        <h4 class="body-2 campaign-title mt-2">Agents</h4>
        <accounts-agents-table :agents="currentAccount.agents" />
        <h4 class="body-2 campaign-title mt-2">Users</h4>
        <accounts-users-table :users="currentAccount.users" />
        <h4 class="body-2 campaign-title mt-2">Invoices</h4>
        <account-invoice-table :accountKey="currentAccount.key" />
        <h4 class="body-2 campaign-title mt-2">Subscriptions</h4>
        <account-subscription-table />
        <!-- Temporary solution for displaying all chargebee invoices -->
        <h4 class="body-2 campaign-title mt-2">
          Chargebee Invoices (<a
            :href="getChargebeeCustomerLink()"
            target="_blank"
            >Go to Customer Page</a
          >)
        </h4>
        <account-chargebee-invoices-table :invoices="accountInvoices" />
      </v-flex>
    </v-layout>

    <accounts-view-info-edit-dialog
      v-model="dialog.editInfo"
      :name="state.name"
      :name-short="state.nameShort"
      :contact="state.contact"
      :address="state.address"
      :return-address="state.returnAddress"
      :billing-address="state.billingAddress"
      :data="state.data"
      :customer-type="state.customerType"
      :region="state.region"
      :logoUrl="state.logoUrl"
      :currency="state.billingCurrency"
      :customer-types="customerTypes"
      :regions="regions"
      :company-id="currentAccount.hubspotCompanyId"
      @close="dialog.editInfo = false"
      @update="handleUpdate"
      @submit="submit"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import AccountsNotes from './AdminAccountViewNotes';
import AccountsViewInfo from './AdminAccountViewInfo';
import AccountsViewInfoEditDialog from './AdminAccountViewInfoEditDialog';
import AccountsCampaignTable from './AdminAccountViewCampaignTable';
import AccountsUsersTable from './AdminAccountViewUsersTable';
import AccountsAgentsTable from './AdminAccountViewAgentsTable.vue';
import AccountInvoiceTable from './AdminAccountViewInvoiceTable';
import AccountSubscriptionTable from './AdminAccountViewSubscriptionTable';
import AccountChargebeeInvoicesTable from './AdminAccountViewChargebeeInvoicesTable';
import AccountContactsTable from './AdminAccountViewContactsTable.vue';

export default {
  name: 'AdminAccountView',
  components: {
    AccountsNotes,
    AccountsViewInfo,
    AccountsViewInfoEditDialog,
    AccountsCampaignTable,
    AccountsUsersTable,
    AccountsAgentsTable,
    AccountInvoiceTable,
    AccountSubscriptionTable,
    AccountChargebeeInvoicesTable,
    AccountContactsTable
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      state: {
        data: {
          notes: []
        }
      },
      dialog: {
        editInfo: false
      }
    };
  },
  created() {
    this.setState(this.currentAccount);
    this.getCustomerTypes();
    this.getRegions();
    window.document.title = `${this.currentAccount.nameShort} | Account`;
  },
  computed: {
    ...mapGetters('accounts', ['customerTypes', 'regions', 'accountInvoices']),
    hasCompanyId() {
      return !!this.currentAccount.hubspotCompanyId;
    }
  },
  watch: {
    currentAccount() {
      this.setState(this.currentAccount);
    }
  },
  methods: {
    ...mapActions('accounts', [
      'updateAccount',
      'updateAccountNotes',
      'getCustomerTypes',
      'getRegions'
    ]),
    setState(currentAccount) {
      this.state = _.cloneDeep(currentAccount);
      if (!this.state.data.billingContact) {
        this.state.data.billingContact = {
          name: null,
          preferredName: null,
          email: null,
          phone: null,
          preferredMethod: 'email',
          otherMethod: ''
        };
      }
    },
    async submit() {
      try {
        // check for billing preferred method
        if (
          this.state.data.billingContact &&
          (this.state.data.billingContact.preferredMethod === 'email' ||
            this.state.data.billingContact.preferredMethod === 'letter_mail')
        ) {
          this.state.data.billingContact.otherMethod = '';
        }

        await this.updateAccount({
          key: this.$route.params.accountKey,
          payload: this.state
        });
        this.dialog.editInfo = false;
        this.$events.$emit('toastEvent', 'Account Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async addNote(obj) {
      try {
        _.set(this.state, obj.key, obj.value);

        await this.updateAccountNotes({
          key: this.currentAccount.key,
          notes: obj.value
        });

        this.$events.$emit('toastEvent', 'Notes updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleUpdate(obj) {
      // Lodash search state object for matching key
      _.set(this.state, obj.key, obj.value);
    },
    getChargebeeCustomerLink() {
      let url = '';

      if (this.currentAccount.key) {
        const baseurl =
          process.env.NODE_ENV === 'production'
            ? 'https://getquorum.chargebee.com/d/customers'
            : 'https://getquorum-test.chargebee.com/d/customers';

        url = `${baseurl}/${this.currentAccount.key}`;
      }

      return url;
    }
  }
};
</script>

<style scoped>
.campaign-title {
  padding-top: 10px;
  padding-bottom: 9px;
}
</style>
