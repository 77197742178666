<template>
  <v-dialog :value="isOpen" max-width="600px" persistent>
    <v-card>
      <v-card-title class="headline">
        Direct Join Url for Attendees
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <p>
              <b>For emergency troubleshooting purposes only.</b>
            </p>
            <p>
              This URL can be used to bypass the GQ voter RSVP process and join
              the Zoom Webinar directly. Only use if an attendee cannot join via
              the Meeting portal.
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <v-text-field
              :value="joinUrl"
              append-icon="content_copy"
              box
              single-line
              hide-details
              readonly
              ref="textbox"
              @click:append="copyToClipboard"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'JoinUrlDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    joinUrl: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close');
    },
    copyToClipboard() {
      this.$refs.textbox.focus();
      document.execCommand('selectAll');
      document.execCommand('copy');
      this.$events.$emit('toastEvent', 'Copied to Clipboard');
    }
  }
};
</script>

<style scoped></style>
