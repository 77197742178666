<template>
  <v-layout>
    <v-spacer></v-spacer>
    <!-- Preferred CSM -->
    <v-list class="mr-2 elevation-1">
      <v-list-tile avatar>
        <v-list-tile-avatar v-if="preferredCsm && preferredCsm.user.disabledAt">
          <v-tooltip bottom>
            <v-icon large slot="activator" color="accent">warning</v-icon>
            <span
              >This user has been disabled. Please update the Preferred CSM for
              this account.</span
            >
          </v-tooltip>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            Preferred CSM
          </v-list-tile-title>
          <v-list-tile-sub-title>
            {{ preferredCsm ? preferredCsm.user.name : 'N/A' }}
          </v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action v-if="hasPermissionsToAssignCsm">
          <v-icon color="primary" @click="openDialog('csm')">
            edit
          </v-icon></v-list-tile-action
        >
      </v-list-tile>
    </v-list>

    <update-preferred-manager-dialog
      v-model="dialog.update"
      :account="currentAccount"
      :type="dialog.type"
      :options="checklistAssignees"
      :current-manager="preferredCsm"
      :unassigned-checklists="unassignedCsChecklists"
      @close="dialog.update = false"
      @remove="deleteManager"
      @update="handleUpdate"
      @assign="handleAssign"
    />

    <reassign-checklists-dialog
      v-model="dialog.reassign"
      :account="currentAccount"
      :type="dialog.type"
      :current-manager="preferredCsm"
      @close="dialog.reassign = false"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import UpdatePreferredManagerDialog from '@/components/dialogs/UpdatePreferredManagerDialog';
import ReassignChecklistsDialog from '@/components/dialogs/ReassignPreferredManagerChecklistsDialog';

export default {
  name: 'AdminAccountPreferredManagers',
  components: { UpdatePreferredManagerDialog, ReassignChecklistsDialog },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['login', 'getScopeByAction']),
    ...mapGetters('checklists', ['checklistAssignees']),
    ...mapGetters('accounts', ['accountUnassignedChecklistsByKey']),
    preferredCsm() {
      return this.currentAccount.preferredManagers
        ? this.currentAccount.preferredManagers.find(m => m.userType === 'csm')
        : null;
    },
    unassignedCsChecklists() {
      const csChecklists = this.accountUnassignedChecklistsByKey(
        this.currentAccount.key
      ).filter(checklist => !checklist.stage.startsWith('vm'));

      return _.sortBy(csChecklists, ['meetingName', 'dueAt']);
    },
    hasPermissionsToAssignCsm() {
      const { profile } = this.login;
      const permissions = profile.permissions;
      return _.includes(permissions, 'admin.lead.access.update');
    }
  },
  data() {
    return {
      dialog: {
        update: false,
        reassign: false,
        type: ''
      }
    };
  },
  created() {
    this.initManagers();
  },
  methods: {
    ...mapActions('accounts', [
      'getAccountPreferredManagers',
      'addAccountPreferredManager',
      'updateAccountPreferredManager',
      'removeAccountPreferredManager',
      'getAccountUnassignedChecklists',
      'getAccountIncompleteChecklists'
    ]),
    ...mapActions('checklists', ['getChecklistAssignees']),
    ...mapActions('meetings/checklists', ['setChecklistAssignees']),
    async initManagers() {
      await this.getChecklistAssignees();
      await this.getAccountUnassignedChecklists({
        accountKey: this.currentAccount.key
      });
      await this.getAccountIncompleteChecklists({
        accountKey: this.currentAccount.key
      });
    },
    openDialog(type) {
      this.dialog.type = type;
      this.dialog.update = true;
    },
    handleUpdate(managerKey, managerType) {
      // If no preferred manager exists, we're adding one
      if (managerType === 'csm' && !this.preferredCsm) {
        this.addManager(managerKey, managerType);
      }
      // Otherwise, we're updating
      else {
        this.updateManager(managerKey, managerType);
      }
    },
    async addManager(managerKey, managerType) {
      try {
        // Add preferred manager
        await this.addAccountPreferredManager({
          accountKey: this.$route.params.accountKey,
          userKey: managerKey,
          userType: managerType
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Added');
        // Prompt user to reassign any active checklists
        this.dialog.reassign = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateManager(managerKey, managerType) {
      try {
        // Update preferred manager
        await this.updateAccountPreferredManager({
          accountKey: this.$route.params.accountKey,
          userKey: managerKey,
          userType: managerType
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Updated');
        // Prompt user to reassign any active checklists
        this.dialog.reassign = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteManager(managerType) {
      try {
        await this.removeAccountPreferredManager({
          accountKey: this.$route.params.accountKey,
          userType: managerType
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Deleted');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleAssign(checklists, assignee) {
      try {
        for (const checklist of checklists) {
          await this.setChecklistAssignees({
            checklistKey: checklist.key,
            assignees: [assignee]
          });
        }

        await this.getAccountPreferredManagers(this.$route.params.accountKey);

        this.dialog.update = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>
