<template>
  <v-expansion-panel :value="panel" expand>
    <v-expansion-panel-content class="panel-content-bg">
      <template v-slot:header>
        <panel-title
          title="Meeting Business"
          description="The voting matters that will be presented at the meeting."
          :edit-action="true"
          :is-admin="isAdmin"
          :is-open="isOpen"
          @update="dialog.update = true"
        />
      </template>
      <v-container class="white px-3 py-3" grid-list-md>
        <v-layout row wrap>
          <v-flex xs6 v-if="currentApproval.displayProxyHolders !== false">
            <notice-card
              type="holders"
              :holders="currentMeeting.defaultHolders"
              :min-height="250"
            />
          </v-flex>
          <v-flex
            xs6
            v-for="(business, index) in activeBusiness"
            :key="business.key"
          >
            <notice-card
              :key="index"
              :index="index"
              type="matter"
              :business="business"
              :min-height="250"
              @viewDocument="openFile"
            />
          </v-flex>
          <v-flex
            v-if="
              isAdmin &&
                currentApproval.displayProxyHolders === false &&
                activeBusiness.length === 0
            "
            >Add <b>meeting business</b> to the meeting notice on the Questions
            tab.</v-flex
          >
          <v-flex
            v-if="
              !isAdmin &&
                currentApproval.displayProxyHolders === false &&
                activeBusiness.length === 0
            "
          >
            No meeting business added.
          </v-flex>
        </v-layout>
      </v-container>
    </v-expansion-panel-content>

    <v-expansion-panel-content class="panel-content-bg">
      <template v-slot:header>
        <panel-title
          title="Meeting Documents"
          description="Documents that will be shared with all members, accessible through their meeting portals."
        />
      </template>
      <v-container class="white px-0 py-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-list
              v-if="
                currentMeeting.documents && currentMeeting.documents.length > 0
              "
            >
              <v-list-tile
                v-for="doc in currentMeeting.documents"
                :key="doc.key"
              >
                <v-list-tile-avatar class="pl-0">
                  <v-icon color="grey lighten-1">attachment</v-icon>
                </v-list-tile-avatar>
                <v-layout row align-center>
                  <span
                    class="primary--text cursor-pointer"
                    @click="openFile(doc.document.url)"
                    >{{ doc.document.name }}</span
                  >
                  <span class="ml-3 ">{{ doc.document.size | fileSize }}</span>
                </v-layout>
              </v-list-tile>
            </v-list>
            <div v-else-if="isAdmin" class="px-3 py-3">
              Add <b>meeting document</b> to the meeting notice on the meeting
              settings section.
            </div>
            <div v-else class="px-3 py-3">No files added.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-expansion-panel-content>

    <v-expansion-panel-content class="panel-content-bg">
      <template v-slot:header>
        <panel-title
          title="Email Notices"
          description="Emails scheduled to be sent to all members."
          :add-action="true"
          :is-admin="isAdmin"
          :is-open="isOpen"
          @add="openDialog({ type: 'notices' })"
        />
      </template>
      <v-container class="white px-0 py-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-list
              v-if="
                currentApproval.notices && currentApproval.notices.length > 0
              "
            >
              <v-list-tile
                v-for="notice in currentApproval.notices"
                :key="notice.key"
              >
                <v-list-tile-avatar class="pl-0">
                  <v-icon color="grey lighten-1">mail</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-avatar class="pl-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="cursor-pointer"
                        color="grey lighten-1"
                        @click.native="
                          getNoticePreview({ noticeKey: notice.key })
                        "
                        >search</v-icon
                      >
                    </template>
                    <span
                      >Clicking on the link will open a document preview.</span
                    >
                  </v-tooltip>
                </v-list-tile-avatar>
                <v-layout row align-center>
                  <span
                    class="primary--text cursor-pointer"
                    @click="getNoticePreview({ noticeKey: notice.key })"
                    >{{ notice.subject }}</span
                  >
                  <v-chip
                    v-if="notice.sendOn"
                    class="ml-3"
                    color="#f9e2d0"
                    small
                    >Send on
                    {{
                      notice.sendOn | dateFormatReadable(true, meetingTimezone)
                    }}</v-chip
                  >
                  <v-chip v-else class="ml-3" color="#f9e2d0" small>
                    Send Immediately
                  </v-chip>

                  <v-icon
                    v-if="isAdmin && isOpen"
                    color="red"
                    class="ml-3 cursor-pointer"
                    @click="removeNotice({ key: notice.key })"
                    >close</v-icon
                  >
                </v-layout>
              </v-list-tile>

              <v-divider v-if="hasAutoReminders || hasAutoJoinPortal" />

              <!-- Automated 'Reminder' Notice(s) -->
              <v-list-tile v-if="hasAutoReminders">
                <v-list-tile-avatar class="pl-0">
                  <v-icon color="grey lighten-1">mail</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-avatar class="pl-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="cursor-pointer"
                        color="grey lighten-1"
                        @click.native="getAutoPreview('reminder')"
                        >search</v-icon
                      >
                    </template>
                    <span
                      >Clicking on the link will open a document preview.</span
                    >
                  </v-tooltip>
                </v-list-tile-avatar>
                <v-layout row align-center>
                  <span
                    class="primary--text cursor-pointer"
                    @click="getAutoPreview('reminder')"
                    >Reminder Email(s)</span
                  >
                  <v-chip class="ml-3" color="#f9e2d0" small
                    >{{ reminderDates.length }} reminders</v-chip
                  >
                  <v-icon
                    v-if="isOpen"
                    color="primary"
                    class="ml-3 cursor-pointer"
                    @click="openReminderDialog('reminder')"
                    >edit</v-icon
                  >
                </v-layout>
              </v-list-tile>

              <!-- Automated 'Join Portal' Notice -->
              <v-list-tile v-if="hasAutoJoinPortal">
                <v-list-tile-avatar class="pl-0">
                  <v-icon color="grey lighten-1">mail</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-avatar class="pl-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="cursor-pointer"
                        color="grey lighten-1"
                        @click.native="getAutoPreview('join')"
                        >search</v-icon
                      >
                    </template>
                    <span
                      >Clicking on the link will open a document preview.</span
                    >
                  </v-tooltip>
                </v-list-tile-avatar>
                <v-layout row align-center>
                  <span
                    class="primary--text cursor-pointer"
                    @click="getAutoPreview('join')"
                    >Join Meeting Portal</span
                  >
                  <v-chip class="ml-3" color="#f9e2d0" small
                    >Send on
                    {{
                      joinPortalTime | dateFormatReadable(true, meetingTimezone)
                    }}</v-chip
                  >
                  <v-icon
                    v-if="isOpen"
                    color="primary"
                    class="ml-3 cursor-pointer"
                    @click="openReminderDialog('join')"
                    >edit</v-icon
                  >
                </v-layout>
              </v-list-tile>
            </v-list>
            <div v-else-if="isAdmin" class="px-3 py-3">
              Add <b>meeting notices</b> using the ADD button on the right.
            </div>
            <div v-else class="px-3 py-3">No email notices added.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-expansion-panel-content>

    <v-expansion-panel-content class="panel-content-bg">
      <template v-slot:header>
        <panel-title
          title="Print Jobs"
          description="The material and printing setup for members who have opted to receive paper packages."
          :add-action="true"
          :is-admin="isAdmin"
          :is-open="isOpen"
          @add="openDialog({ type: 'printJobs' })"
        />
      </template>
      <v-container class="white px-3 py-3" grid-list-md>
        <v-layout
          row
          wrap
          v-if="
            currentApproval.printJobs && currentApproval.printJobs.length > 0
          "
        >
          <v-flex xs6>
            <notice-card
              type="printJobs"
              :print-jobs="currentApproval.printJobs"
              :min-height="200"
              :is-open="isOpen"
              @remove="removePrintJob"
            />
          </v-flex>
          <v-flex xs6>
            <notice-card
              type="printAddress"
              :address="printJobAddress"
              :min-height="200"
            />
          </v-flex>
          <v-flex xs6>
            <notice-card
              type="instructions"
              title="Printing Instructions"
              :descriptions="printingIntructions"
              :min-height="200"
            />
          </v-flex>
          <!--     <v-flex xs6>
            <notice-card
              type="instructions"
              title="Mailing Instructions"
              :descriptions="mailingIntructions"
              :min-height="200"
            />
          </v-flex> -->
        </v-layout>
        <v-layout row wrap v-else-if="isAdmin">
          <v-flex xs12>
            <div>
              Add <b>print documents</b> using the ADD button on the right.
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-else>
          <v-flex xs12>
            <div>
              There are no print jobs scheduled for this send-out. Please leave
              a comment below if you require printing and mailing services.
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-expansion-panel-content>
    <!-- dialog to update AR settings -->
    <update-business-dialog
      :is-open="dialog.update"
      :display-holders="currentApproval.displayProxyHolders"
      @close="dialog.update = false"
      @save="handleUpdate"
    />
    <!-- dialog to add print jobs or email notices -->
    <item-list-dialog
      :is-open="dialog.isOpen"
      :title="dialog.title"
      :type="dialog.type"
      :meeting-key="currentMeeting.key"
      @close="dialog.isOpen = false"
      @add="handleAdd"
    />
    <!-- preview dialog for email notices -->
    <preview-dialog
      v-model="dialog.preview"
      max-width="1000px"
      :subject="meetingNoticePreview.subject"
      :html="meetingNoticePreview.content"
      :attachments="meetingNoticePreview.attachments"
      :recipientCount="0"
      :recipientTotal="0"
      :show-test-voters="true"
      :test-voters="testVotersListData"
      @close="dialog.preview = false"
      @setTestVoter="setTestVoter"
      :recipientInfoHidden="true"
      containerStyles="px-4"
    />
    <!-- preview dialog for automated reminders -->
    <preview-dialog
      v-model="dialog.autoPreview"
      max-width="1000px"
      :recipientCount="0"
      :recipientTotal="0"
      :show-test-voters="true"
      :test-voters="testVotersListData"
      :html="automatedTemplatePreview.content"
      :subject="automatedTemplatePreview.subject"
      :recipientInfoHidden="true"
      containerStyles="px-4"
      @close="dialog.autoPreview = false"
      @setTestVoter="setTestVoter"
    />
    <!-- dialog to view / edit automatic reminders -->
    <reminder-dialog
      :is-open="dialog.reminder"
      :current-meeting="currentMeeting"
      :reminder-settings="meetingReminderSettings"
      :reminder-type="dialog.reminderType"
      @close="dialog.reminder = false"
      @update="updateAutoReminders"
    />
  </v-expansion-panel>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import filesize from 'filesize';
import { mapGetters, mapActions } from 'vuex';
import { dateFormatReadable } from '@/filters';
import PanelTitle from './PanelTitle.vue';
import NoticeCard from './NoticeCard.vue';
import ItemListDialog from './ItemListDialog.vue';
import ReminderDialog from './ReminderDialog.vue';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';
import UpdateBusinessDialog from './UpdateMeetingBusinessDialog';
import defaults from '@/lib/default-print-job-content.json';
import getReminderDates from '@/lib/get-auto-reminder-dates';

export default {
  name: 'NoticeDetails',
  components: {
    PanelTitle,
    NoticeCard,
    ItemListDialog,
    ReminderDialog,
    PreviewDialog,
    UpdateBusinessDialog
  },
  props: {
    panel: {
      type: Array,
      required: true,
      default() {
        return [true, true, true, true];
      }
    },
    currentMeeting: {
      type: Object,
      required: true
    },
    currentApproval: {
      type: Object,
      required: true
    },
    meetingReminderSettings: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      arKey: this.$route.params.arKey,
      dialog: {
        isOpen: false,
        type: '',
        title: '',
        preview: false,
        reminder: false,
        reminderType: null,
        autoPreview: false,
        update: false,
        noticeKey: '',
        testVoterKey: ''
      }
    };
  },
  filters: {
    dateFormatReadable,
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  computed: {
    ...mapGetters('meetings', ['automatedTemplatePreview']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),
    ...mapGetters('meetings/notices', [
      'testVotersListData',
      'meetingNoticePreview'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters(['isAdmin']),
    activeBusiness() {
      return this.meetingBusinessData.filter(b => !b.disabledAt);
    },
    isOpen() {
      return this.currentApproval.status === 'open';
    },
    printJobAddress() {
      const printJob = _.head(this.currentApproval.printJobs);

      if (printJob && printJob.returnAddress) {
        return _.split(printJob.returnAddress, '\n');
      } else {
        return ['Specify the return mailing address in the print jobs view.'];
      }
    },
    printingIntructions() {
      const printJob = _.head(this.currentApproval.printJobs);

      if (printJob && printJob.printingInstructions) {
        return _.split(printJob.printingInstructions, '\n');
      } else {
        // default instructions
        return _.split(defaults.budget, '\n');
      }
    },
    mailingIntructions() {
      const printJob = _.head(this.currentApproval.printJobs);

      if (printJob && printJob.mailingInstructions) {
        return _.split(printJob.mailingInstructions, '\n');
      } else {
        // default instructions
        return _.split(defaults.mailing, '\n');
      }
    },
    hasAutoReminders() {
      return (
        this.meetingReminderSettings.enableReminders &&
        this.meetingReminderSettings.reminderFrequency
      );
    },
    hasAutoJoinPortal() {
      return (
        this.meetingReminderSettings.enableReminders &&
        this.meetingReminderSettings.joinReminder
      );
    },
    reminderDates() {
      const reminderStartDate = this.meetingReminderSettings.reminderStartDate;
      const reminderFrequency = this.meetingReminderSettings.reminderFrequency;
      const meetingDate = this.currentMeeting.meetingDate;

      return getReminderDates(
        reminderStartDate,
        reminderFrequency,
        meetingDate
      );
    },
    joinPortalTime() {
      if (!this.meetingReminderSettings.joinReminder) {
        return null;
      }

      return moment(this.currentMeeting.meetingDate).subtract(
        this.meetingReminderSettings.joinReminder,
        'minutes'
      );
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings', [
      'updateMeetingReminderSettings',
      'getReminderTemplatePreview'
    ]),
    ...mapActions('meetings/notices', [
      'getTestVotersList',
      'getMeetingsNoticePreview'
    ]),
    ...mapActions('meetings/approvals', [
      'addNotice',
      'remoteNotice',
      'addPrintJob',
      'remotePrintJob',
      'upsertSettings'
    ]),
    async init() {
      try {
        await this.getTestVotersList({
          shortCode: this.shortCode,
          recipients: { segment: 'test-voters' }
        });

        if (this.testVotersListData.length > 0) {
          this.setTestVoter(this.testVotersListData[0]);
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openFile(url) {
      window.open(url);
    },
    openDialog({ type }) {
      this.dialog = {
        ...this.dialog,
        isOpen: true,
        type,
        title: type === 'notices' ? 'Add Email Notices' : 'Add Print Jobs'
      };
    },
    async handleAdd({ type, items }) {
      try {
        const payload = {
          shortCode: this.shortCode,
          arKey: this.arKey
        };

        // filtering out elements already exists in the approval request email notices / printjobs
        const filtered = _.filter(items, item => {
          if (type === 'notices') {
            return !_.some(this.currentApproval.notices, ['key', item.key]);
          } else {
            return !_.some(this.currentApproval.printJobs, ['key', item.key]);
          }
        });

        const promises = _.map(filtered, async items => {
          payload.key = items.key;

          type === 'notices'
            ? await this.addNotice(payload)
            : await this.addPrintJob(payload);
        });

        await Promise.all(promises);

        this.$store.dispatch('meetings/approvals/getMeetingApproval', {
          shortCode: this.shortCode,
          arKey: this.arKey
        });

        this.$events.$emit('toastEvent', 'Items added');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.dialog.isOpen = false;
      }
    },
    async handleUpdate(payload) {
      try {
        await this.upsertSettings({
          shortCode: this.shortCode,
          arKey: this.arKey,
          payload
        });

        this.$events.$emit('toastEvent', 'Settings updated');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.dialog.update = false;
      }
    },
    async removeNotice({ key }) {
      try {
        this.loading = true;
        await this.remoteNotice({
          shortCode: this.shortCode,
          arKey: this.arKey,
          key
        });

        this.$events.$emit('toastEvent', 'Email Notice removed');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    async setTestVoter(testVoter) {
      this.dialog.testVoterKey = testVoter.voterKey;

      if (this.dialog.noticeKey) {
        await this.getMeetingsNoticePreview({
          shortCode: this.shortCode,
          noticeKey: this.dialog.noticeKey,
          voterKey: this.dialog.testVoterKey
        });
      }
    },
    async getNoticePreview({ noticeKey }) {
      try {
        this.dialog.noticeKey = noticeKey;

        await this.getMeetingsNoticePreview({
          shortCode: this.shortCode,
          noticeKey: this.dialog.noticeKey,
          voterKey: this.dialog.testVoterKey
        });

        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async removePrintJob({ key }) {
      try {
        this.loading = true;
        await this.remotePrintJob({
          shortCode: this.shortCode,
          arKey: this.arKey,
          key
        });

        this.$events.$emit('toastEvent', 'Print Job removed');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    async getAutoPreview(previewType) {
      try {
        await this.getReminderTemplatePreview({
          shortCode: this.shortCode,
          type: previewType,
          voterKey: this.dialog.testVoterKey
        });

        this.dialog.autoPreview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openReminderDialog(type) {
      this.dialog.reminderType = type;
      this.dialog.reminder = true;
    },
    async updateAutoReminders(payload) {
      try {
        this.loading = true;

        const reminderSettings = {
          enableReminders: this.meetingReminderSettings.enableReminders,
          reminderStartDate: this.meetingReminderSettings.reminderStartDate,
          reminderFrequency: payload.meetingReminderFrequency,
          joinReminder: payload.meetingStartingReminder,
          reminderTemplate: this.meetingReminderSettings.reminderTemplate,
          reminderSubject: this.meetingReminderSettings.reminderSubject,
          joinTemplate: this.meetingReminderSettings.joinTemplate,
          joinSubject: this.meetingReminderSettings.joinSubject,
          reminderReplyTo: this.meetingReminderSettings.reminderReplyTo
        };

        await this.updateMeetingReminderSettings({
          shortCode: this.shortCode,
          reminderSettings
        });

        this.$events.$emit('toastEvent', 'Reminder Updated');
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
        this.dialog.reminder = false;
      }
    }
  }
};
</script>

<style scoped>
.panel-content-bg {
  background-color: #e7f2fb !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
