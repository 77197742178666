<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        <div class="title primary--text text--darken-1">
          <slot name="title"/>
        </div>
      </v-card-title>
      <v-card-text>
        <slot name="description"/>
      </v-card-text>
      <v-layout row class="mx-3">
        <v-flex xs12>
          <v-card-text>
            <print-job-file-upload-form
              :short-code="state.shortCode"
              :attachment="state.items.poc"
              :multiple-files="true"
              icon="attach_file"
              accept=".pdf"
              :upload-file-type="'poc'"
              @upload="handleUpload($event)"
            />
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-layout row class="mx-3">
        <v-flex xs12 sm6 md3>
          <v-card-text>
            <v-text-field v-model="state.postageCost" required label="Postage Cost *" prefix="$"/>
          </v-card-text>
        </v-flex>
      </v-layout>

      <v-spacer/>
      <v-card-actions>
        <v-layout row class="ma-3">
          <v-flex class="ma-3 text-xs-right">
            <v-btn color="primary" @click.native="close" flat>Close</v-btn>
            <v-btn @click.native="markComplete" color="primary" :disabled="!valid">
              <v-icon left>check</v-icon>Mark Completed
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapMutations } from 'vuex';
import PrintJobFileUploadForm from '@/components/PrintJobFileUploadForm';

export default {
  name: 'CompletePrintJobDialog',
  components: {
    PrintJobFileUploadForm
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    shortCode: {
      type: String,
      default() {
        return '';
      }
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    },
    pocFiles: {
      type: Array,
      default() {
        return [];
      }
    },
    postageCost: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      state: {
        items: {
          poc: []
        },
        poc: {
          types: 'pdf'
        },
        postageCost: '',
        shortCode: ''
      }
    };
  },
  computed: {
    valid() {
      return (
        !_.isEmpty(this.state.items.poc) && !_.isEmpty(this.state.postageCost)
      );
    }
  },
  watch: {
    isOpen() {
      this.state.items.poc = this.pocFiles;
      this.state.postageCost = this.postageCost;
      this.state.shortCode = this.shortCode;
    }
  },
  methods: {
    close() {
      this.handleUpload({}); // delete uploaded file
      this.state.postageCost = '';
      this.state.shortCode = '';
      this.$emit('close-dialog');
    },
    handleUpload(file) {
      let pocFile = file.length > 1 ? file.pop() : file[0];
      this.state.items.poc = !_.isEmpty(pocFile) ? [pocFile] : [];
      this.$emit('upload', this.state.items.poc);
    },
    markComplete() {
      this.$emit('submit', {
        pocFile: this.state.items.poc,
        postageCost: this.state.postageCost
      });
    }
  }
};
</script>

<style scoped>
</style>