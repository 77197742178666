<template>
  <meeting-overview-card
    icon="subject"
    title="Meeting Fees and Services"
    :hide-save="true"
    :hide-edit="true"
    :saving="saving"
    @save="onSave"
  >
    <div v-if="hasExpenses">
      <!-- Display custom expenses -->
      <v-layout row v-for="expense in expenses" :key="expense.key">
        <v-flex xs10>
          {{ expense.name || expense.description }}

          <v-tooltip bottom max-width="400px">
            <v-icon small slot="activator">info</v-icon>
            <div>
              Product #: {{ expense.productCode }}<br />
              Category: {{ expense.category }} <br />
              {{ expense.description }}
            </div>
          </v-tooltip>
        </v-flex>
        <v-flex class="v-flex center text-xs-right pr-4">
          <span class="nowrap"> ${{ expense.value | formatCurrency }} </span>
        </v-flex>
        <v-flex xs2 class="v-flex center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">more_vert</v-icon>
            </template>

            <v-list>
              <v-list-tile @click="handleEdit({ expense })">
                <v-list-tile-content>Edit</v-list-tile-content></v-list-tile
              >
              <v-list-tile @click="onDelete({ itemKey: expense.key })">
                <v-list-tile-content>Remove</v-list-tile-content></v-list-tile
              >
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </div>

    <div v-else>
      <v-layout row>
        <v-flex xs9 class="">No meeting fees or services.</v-flex>
      </v-layout>
    </div>

    <v-btn @click="handleOpen" flat color="blue" class="mt-4 mx-0" dark small>
      <v-icon>add</v-icon>Add item
    </v-btn>

    <v-divider class="primary mt-2" />

    <v-layout row>
      <v-flex xs9 class="text-xs-right pb-0">Subtotal: </v-flex>
      <v-flex xs3 class="text-xs-right pr-4 pb-0 ">
        <span class="nowrap bold">${{ subtotalItemExpenses || 0 }} </span>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs9 class="text-xs-right pb-0">
        <span class="nowrap">
          <v-icon small class="pr-2" @click="dialog.discountOpen = true"
            >edit</v-icon
          >
          Discount ({{ discount || 0 }}%):
        </span>
      </v-flex>
      <v-flex xs3 class="text-xs-right pr-4 pb-0">
        <span class="nowrap bold"> - ${{ discountAmount || 0 }} </span>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs9 class="text-xs-right">Total: </v-flex>
      <v-flex xs3 class="text-xs-right pr-4">
        <span class="nowrap bold"> ${{ total || 0 }} </span>
      </v-flex>
    </v-layout>

    <add-meeting-billing-item-dialog
      title="Add Meeting Fee or Service"
      :is-open="dialog.isOpen"
      :item="state"
      :item-selected="itemSelected"
      :products="products"
      :description-editable="false"
      :currency="billingSettings.billingCurrency"
      :is-chargebee="billingSettings.chargebeeBilling"
      @update="handleUpdate"
      @save="onSave"
      @close-dialog="handleClose"
    />
    <add-billing-discount-dialog
      :is-open="dialog.discountOpen"
      :state="state"
      @update="handleUpdate"
      @save="onSaveDiscount"
      @close-dialog="dialog.discountOpen = false"
    />
  </meeting-overview-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { formatCurrency } from '@/filters';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import AddMeetingBillingItemDialog from './AddMeetingBillingItemDialog';
import AddBillingDiscountDialog from './AddBillingDiscountDialog';

export default {
  name: 'MeetingFeesCard',
  components: {
    MeetingOverviewCard,
    AddMeetingBillingItemDialog,
    AddBillingDiscountDialog
  },
  filters: {
    formatCurrency
  },
  data() {
    return {
      saving: false,
      dialog: { isOpen: false, discountOpen: false },
      state: {
        id: '',
        name: '',
        description: '',
        category: '',
        productCode: '',
        value: '',
        discount: 0
      },
      itemSelected: null
    };
  },
  watch: {
    // fills discount value
    discount() {
      this.state.discount = this.discount;
    }
  },
  computed: {
    ...mapGetters('meetings/billing', [
      'billingSettings',
      'discount',
      'expenses'
    ]),
    ...mapGetters('products', ['productsData']),
    hasExpenses() {
      return this.expenses.length > 0;
    },
    products() {
      // Format products if we're billing via Chargebee
      if (this.billingSettings.chargebeeBilling) {
        return _.map(this.productsData, product => {
          if (product.pricingModel === 'per_unit') {
            return {
              ...product,
              pricePerUnit: product.price
            };
          }
          return product;
        });
      }
      // Format products if we're billing in GQ
      else {
        return _.map(this.productsData, product => {
          return {
            value: product.productCode,
            label: `${product.name} | #${product.productCode}`
          };
        });
      }
    },
    subtotalItemExpenses() {
      return this.billingSettings
        ? this.billingSettings.subtotalItemExpenses
        : 0;
    },
    discountAmount() {
      return this.discount > 0
        ? (this.subtotalItemExpenses * this.discount) / 100
        : 0;
    },
    total() {
      return this.subtotalItemExpenses - this.discountAmount;
    }
  },
  methods: {
    ...mapActions('meetings/billing', [
      'addBillingItem',
      'deleteBillingItem',
      'updateBillingItem',
      'updateBillingSettings'
    ]),
    handleUpdate(obj) {
      // Lodash search state object for matching key
      _.set(this.state, obj.key, obj.value);
    },
    handleOpen() {
      this.dialog.isOpen = true;
    },
    handleClose() {
      this.dialog.isOpen = false;
      // reset state
      this.state = {
        description: '',
        productCode: '',
        value: '',
        category: ''
      };
      this.itemSelected = null;
    },
    async onSave(product) {
      try {
        let payload = {};

        // Prepare payload if we're billing via Chargebee
        if (this.billingSettings.chargebeeBilling) {
          payload = {
            type: 'expense',
            name: product.externalName,
            description: product.description,
            value: Number(product.price),
            quantity: Number(product.quantity),
            chargebeeItemPriceId: product.id,
            chargebeePricingModel: product.pricingModel,
            chargebeePricePerUnit: Number(product.pricePerUnit)
          };
        }
        // Prepare payload if we're billing in GQ
        else {
          payload = {
            type: 'expense',
            description: this.state.description,
            category: this.state.category,
            productCode: Number(this.state.productCode),
            value: Number(this.state.value)
          };
        }

        if (this.itemSelected) {
          // if item selected call update item function
          await this.updateBillingItem({
            shortCode: this.$route.params.shortcode,
            itemKey: this.itemSelected,
            payload
          });
          this.$events.$emit('toastEvent', 'Item updated');
        } else {
          await this.addBillingItem({
            shortCode: this.$route.params.shortcode,
            payload
          });
          this.$events.$emit('toastEvent', 'Item added');
        }

        //reset state
        this.state = { description: '', productCode: '', value: '' };
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.dialog.isOpen = false;
        this.itemSelected = null;
      } finally {
        this.dialog.isOpen = false;
        this.itemSelected = null;
      }
    },
    async onSaveDiscount() {
      try {
        const payload = {
          enabled: this.billingSettings.enabled,
          discount: this.state.discount
        };

        await this.updateBillingSettings({
          shortCode: this.$route.params.shortcode,
          payload
        });
        this.$events.$emit('toastEvent', 'Discount updated');
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.dialog.discountOpen = false;
      } finally {
        this.dialog.discountOpen = false;
      }
    },
    handleEdit({ expense }) {
      this.itemSelected = expense.key;

      if (this.billingSettings.chargebeeBilling) {
        this.state = {
          id: expense.chargebeeItemPriceId,
          externalName: expense.name,
          description: expense.description,
          pricingModel: expense.chargebeePricingModel,
          price: expense.value,
          quantity: expense.quantity,
          pricePerUnit: expense.chargebeePricePerUnit
        };
      } else {
        this.state = {
          description: expense.description,
          productCode: expense.productCode,
          value: expense.value
        };
      }

      this.dialog.isOpen = true;
    },
    async onDelete({ itemKey }) {
      try {
        this.saving = true;
        await this.deleteBillingItem({
          shortCode: this.$route.params.shortcode,
          itemKey
        });
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: 400;
}
</style>
