<template>
  <div>
    <div v-if="isOnAccount && isCondo">
      <v-layout row class="mt-3">
        <v-flex xs12>
          <p class="secondary--text">
            This Account is located in <b>Ontario</b> with account type
            <b>"condo-hoa"</b>.<br />
            The consent management tool below is intended for Accounts using the
            <b>"Default"</b> consent format setting.
          </p>
        </v-flex>
      </v-layout>

      <v-expansion-panel>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>Click here to use the consent management tool</div>
          </template>
          <div class="mx-3">
            <account-consents-content
              :current-account="currentAccount"
              :data-last-fetched-at="dataLastFetchedAt"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>

    <account-consents-content
      v-else
      :current-account="currentAccount"
      :data-last-fetched-at="dataLastFetchedAt"
    />
  </div>
</template>

<script>
import AccountConsentsContent from './AdminAccountConsentsContent';

export default {
  name: 'AdminAccountConsentsView',
  components: {
    AccountConsentsContent
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    isOnAccount() {
      return this.currentAccount.region === 'ON';
    },
    isCondo() {
      return this.currentAccount.customerType === 'condo-hoa';
    }
  },
  methods: {}
};
</script>
