<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="invoices"
      class="elevation-1"
      :pagination.sync="pagination"
    >
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <tr class="table-item">
          <td class="nowrap" width="100">
            {{ props.item.date | dateFormatReadable }}
          </td>

          <td class="uppercase nowrap" width="200">
            <a
              :href="getChargebeeLink(props.item.invoiceNumber)"
              target="_blank"
              ><b>{{ props.item.invoiceNumber }}</b></a
            >
            <v-btn
              icon
              flat
              small
              @click="downloadChargebeeInvoice(props.item.invoiceNumber)"
            >
              <v-icon dark>download</v-icon>
            </v-btn>
          </td>

          <td>
            <v-chip
              label
              small
              :color="getInvStatusColor(props.item.invoiceStatus)"
              text-color="white"
              >{{ props.item.invoiceStatus }}</v-chip
            >
          </td>
          <td width="275"></td>

          <td class="text-xs-right">
            <span :class="getOwingColor(props.item.invoiceOwing)">
              {{ props.item.invoiceOwing | formatCurrencyValue }}
            </span>
          </td>
          <td class="text-xs-right">
            {{ props.item.invoiceTotal | formatCurrencyValue }}
          </td>
          <td>{{ props.item.currency }}</td>
          <td width="100"></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';
import { formatCurrencyValue } from '@/helpers';
import { mapActions } from 'vuex';

export default {
  name: 'AdminAccountViewChargebeeInvoicesTable',
  props: {
    invoices: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  filters: {
    dateFormatReadable,
    formatCurrencyValue
  },
  data() {
    return {
      headers: [
        { text: 'Date', value: 'date', align: 'left' },
        { text: 'Invoice Number', value: 'invoiceNumber', align: 'left' },
        { text: 'Invoice Status', value: 'invoiceStatus', sortable: false },
        { text: '', value: '', sortable: false },
        { text: 'Due', value: 'amountOwing', align: 'right' },
        { text: 'Total', value: 'invoiceTotal', align: 'right' },
        { text: 'Currency', value: 'currency', sortable: false },
        { text: '', value: '', sortable: false }
      ],
      pagination: {
        sortBy: 'date',
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsPerPageItems: [5, 10, 25]
      }
    };
  },
  methods: {
    ...mapActions('invoices', ['downloadChargebeeInvoice']),
    getChargebeeLink(invoiceNumber) {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/invoices'
          : 'https://getquorum-test.chargebee.com/d/invoices';

      const url = `${baseurl}/${invoiceNumber}`;

      return url;
    },
    getInvStatusColor(status) {
      switch (status) {
        case 'paid':
          return 'green';
        case 'payment_due':
          return 'red';
        case 'voided':
          return 'grey';
        case 'posted':
          return 'purple';
        default:
          break;
      }
    },
    getOwingColor(amount) {
      return amount > 0 ? 'red--text' : 'green--text';
    }
  }
};
</script>

<style scoped></style>
