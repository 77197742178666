<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-spacer />

      <v-btn color="primary" @click="$emit('add-invoice')">
        <v-icon left>add_box</v-icon>Add Invoice
      </v-btn>

      <v-btn flat icon @click.native="$emit('refresh')" :loading="loading">
        <v-icon>cached</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="meetingInvoiceData"
      :loading="loading"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">
          {{ props.item.invoiceDate | dateFormatReadable }}
        </td>
        <td class="text-xs-left">
          <a
            v-if="isChargebeeInvoice(props.item)"
            :href="getChargebeeLink(props.item)"
            target="_blank"
            >{{ props.item.invoiceNumber }}</a
          >
          <router-link
            v-else
            :to="getInvoiceRouteObject(props.item.key)"
            target="_blank"
            class="invoicenumber invoice-link"
            >{{ props.item.invoiceNumber }}</router-link
          >
        </td>
        <td class="text-xs-right">
          {{ props.item.amountOwing | formatCurrencyValue }}
        </td>
        <td class="text-xs-right">
          {{ props.item.totalPrice | formatCurrencyValue }}
        </td>
        <td class="text-xs-left">
          {{ props.item.currency }}
        </td>
        <td>
          <v-chip
            small
            class="capitalize"
            :color="getStatusChipColor(props.item)"
            outline
          >
            {{ getStatus(props.item) }}
          </v-chip>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'MeetingInvoicesTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    dateFormatReadable,
    formatCurrencyValue
  },
  data() {
    return {
      headers: [
        { text: 'Inv Date', value: 'invoiceDate', align: 'left' },
        { text: 'Number', value: 'invoiceNumber', align: 'left' },
        { text: 'Due', value: 'amountOwing', align: 'right' },
        { text: 'Price', value: 'totalPrice', align: 'right' },
        { text: 'Currency', value: 'currency', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' }
      ]
    };
  },
  computed: {
    ...mapGetters('meetings/billing', ['meetingInvoiceData'])
  },
  methods: {
    getInvoiceRouteObject(invoiceKey) {
      return {
        name: 'adminInvoice',
        params: {
          invoiceKey
        }
      };
    },
    isChargebeeInvoice(invoice) {
      return (
        invoice.additionalData?.chargebee === true ||
        invoice.additionalData?.chargebee === 'true'
      );
    },
    getChargebeeLink(invoice) {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/invoices'
          : 'https://getquorum-test.chargebee.com/d/invoices';

      const url = `${baseurl}/${invoice.invoiceNumber}`;

      return url;
    },
    getStatusChipColor(row) {
      const status = this.getStatus(row);
      if (status === 'paid') {
        return 'green';
      } else if (status === 'sent' || status === 'posted') {
        return 'primary';
      } else if (status === 'payment due') {
        return 'red';
      } else {
        return 'secondary';
      }
    },
    getStatus(row) {
      if (this.isChargebeeInvoice) {
        const status = row.status;
        return status.replace('_', ' ');
      }

      const isSent = row.status === 'sent';
      const isPaid = isSent && row.amountOwing <= 0;
      if (isPaid) {
        return 'paid';
      } else if (isSent) {
        return 'sent';
      } else {
        return 'draft';
      }
    }
  }
};
</script>

<style scoped>
.value-cells {
  width: 100px;
}
.invoice-link {
  cursor: pointer;
}
.invoicenumber {
  text-transform: uppercase;
}
</style>
