<template>
  <v-dialog max-width="800px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Edit Checklist ({{ stage }})</span>
      </v-card-title>
      <v-card-text>
        <checklist-input-item
          :meeting-date="meetingDate"
          :meeting-timezone="meetingTimezone"
          :name="name"
          :due-at="dueAt"
          :type="eventType"
          :stage="stage"
          :meta="meta"
          @update="emitUpdate"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn class="primary" @click.native="emitSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import ChecklistInputItem from '@/components/ChecklistInputItem';

export default {
  name: 'MeetingChecklistDialogEdit',
  components: {
    ChecklistInputItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    dueAt: {
      type: String,
      default: ''
    },
    eventType: {
      type: String,
      default: ''
    },
    stage: {
      type: String,
      default: ''
    },
    meetingDate: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    meta: {
      type: Object,
      default() {
        return {};
      }
    },
    assignees: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      menu: false,
      search: null
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitUpdate(obj) {
      obj.prop = 'editChecklist';
      this.$emit('update', obj);
    },
    emitSave() {
      this.$emit('save');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
