<template>
  <v-card class="vbm-member-container" color="#e7f2fb">
    <v-card-title primary-title>
      <v-layout align-center>
        <v-avatar size="30" color="white" class="mr-2 mt-2 ml-1 mb-2 start">
          <v-icon color="#3874CB" size="20">group</v-icon>
        </v-avatar>
        <div class="subheading font-weight-bold blue-text">
          Members
        </div>
        <v-spacer />
        <v-btn
          data-v-step="vbm-members-invite-all-btn"
          outline
          color="primary"
          class="round-card mr-0"
          @click.native="openDialog('bulkInvite')"
          ><v-icon left dark>mail</v-icon>Invite All</v-btn
        >
        <v-btn
          data-v-step="vbm-add-members-btn"
          color="primary"
          @click.native="openDialog('addMember')"
          ><v-icon left dark>add</v-icon>Add</v-btn
        >
        <v-icon
          color="primary"
          size="25"
          style="cursor: pointer"
          @click.native="openDialog('aboutMembers')"
          >info</v-icon
        >
      </v-layout>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-alert
        :value="true"
        color="#0F64A9"
        outline
        class="mb-3"
        style="border-radius: 4px;"
      >
        <span style="font-size: 12px;"
          >Invite your key meeting participants such as board members and
          special guests here. Press the Information icon for more details.
        </span>
      </v-alert>

      <v-layout>
        <span style="margin: 0 20px 0 10px;"
          ><b
            >{{ stats.attendingCountBoardMembers }}/{{
              pagination.totalItems
            }}</b
          >
          RSVPs</span
        >
        <span>
          <b
            >{{ stats.attendanceCountBoardMembers }}/{{
              pagination.totalItems
            }}</b
          >
          in attendance</span
        >
      </v-layout>
    </v-card-text>
    <v-card-text>
      <div
        v-if="tableData.length === 0"
        class="bluer-background text-xs-center"
        data-v-step="vbm-members-add-import-container"
      >
        <span class="pt-4 pb-4 subtitle"
          >Start building your members list.</span
        >
        <v-btn
          color="primary"
          dark
          class="mb-4"
          @click.native="openDialog('addMember')"
          ><v-icon left dark>add</v-icon>Add Member</v-btn
        >

        <!-- Import functionality available to account users -->
        <span v-if="canImport">
          <span class="pt-4 pb-4 subtitle"
            >Import board members from computer or from another campaign</span
          >
          <v-btn
            color="primary"
            dark
            class="mb-4"
            @click.native="openDialog('importMembers')"
            ><v-icon left dark>add</v-icon>Import Members</v-btn
          >
        </span>
      </div>

      <v-layout column v-else>
        <v-data-table
          hide-headers
          :items="tableData"
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :rows-per-page-items="pagination.rowsPerPageItems"
        >
          <template slot="items" slot-scope="props">
            <div class="py-1 px-2 blue-background">
              <v-card
                hover
                style="border-radius: 8px;"
                :key="props.item.key"
                @click="select(props.item)"
              >
                <v-list two-line>
                  <v-list-tile @click.native="openDialog('editMember')">
                    <v-avatar color="orange" size="30" class="mr-3">
                      <div
                        v-if="props.item.inAttendance"
                        class="active-indicator"
                      ></div>
                      <span class="white--text caption">{{
                        props.item.initial
                      }}</span>
                    </v-avatar>

                    <v-list-tile-content>
                      <v-list-tile-title class="font-weight-bold">{{
                        props.item.name
                      }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{
                        props.item.email
                      }}</v-list-tile-sub-title>
                    </v-list-tile-content>

                    <!-- Ineligible Chips -->
                    <v-tooltip v-if="props.item.ineligible" bottom>
                      <v-chip slot="activator" small disabled class="px-1"
                        ><v-icon left>not_interested</v-icon>
                        Ineligible
                      </v-chip>
                      <span>This member is blocked from voting</span>
                    </v-tooltip>

                    <!-- RSVP Chips -->
                    <v-tooltip v-if="props.item.attending" bottom>
                      <v-chip
                        slot="activator"
                        small
                        color="blue lighten-1"
                        text-color="white"
                        class="px-1"
                        ><v-icon left>how_to_reg</v-icon>
                        RSVP
                      </v-chip>
                      <span>Received</span>
                    </v-tooltip>

                    <v-tooltip
                      v-else-if="props.item.attending === false"
                      bottom
                    >
                      <v-chip
                        color="red lighten-1"
                        text-color="white"
                        slot="activator"
                        small
                        disabled
                        class="px-1"
                        ><v-icon left>event_busy</v-icon>
                        RSVP
                      </v-chip>
                      <span>Declined</span>
                    </v-tooltip>

                    <v-tooltip v-else bottom>
                      <v-chip slot="activator" small disabled class="px-1"
                        ><v-icon left>not_interested</v-icon>
                        RSVP
                      </v-chip>
                      <span>Not received</span>
                    </v-tooltip>

                    <v-list-tile-action>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            outline
                            color="primary"
                            slot="activator"
                            v-bind="attrs"
                            v-on="on"
                            fab
                            small
                            dark
                            @click.stop="confirmSendInvite(props.item)"
                          >
                            <v-icon>mail_outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Send Meeting Invite Email</span>
                      </v-tooltip>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-card>
            </div>
          </template>
        </v-data-table>
      </v-layout>
    </v-card-text>

    <!-- Add member dialog -->
    <add-member-dialog
      v-model="dialog.addMember"
      :short-code="shortCode"
      type="boardMember"
      @close="dialog.addMember = false"
    />

    <!-- Edit member dialog -->
    <edit-member-dialog
      v-model="dialog.editMember"
      :member-selected="selectedMember"
      type="boardMember"
      @close="dialog.editMember = false"
      @delete="deleteClose"
      @update="updateUnit"
    />

    <!-- Add member dialog -->
    <import-members-dialog
      v-if="canImport"
      v-model="dialog.importMembers"
      :current-account="currentAccount"
      :short-code="shortCode"
      @close="dialog.importMembers = false"
    />

    <send-invite-dialog
      v-model="invite.confirm"
      :voters="invite.members"
      :loading="loading"
      :optional-message-input="true"
      :default="invite.default"
      :current-meeting="currentMeeting"
      :is-admin="isAdmin"
      @invite="sendInviteEmail"
      @close="invite.confirm = false"
    />

    <bulk-invite-dialog
      v-model="dialog.bulkInvite"
      :loading="loading"
      :selected="tableData"
      type="fromVBM"
      :optional-message-input="true"
      @send="sendBulkInvites"
      @close="dialog.bulkInvite = false"
    />

    <about-members-dialog
      v-model="dialog.aboutMembers"
      @close="dialog.aboutMembers = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import checkScope from '@/lib/check-user-scopes';
import AddMemberDialog from './components/MeetingVbmAddMemberDialog.vue';
import EditMemberDialog from './components/MeetingVbmEditMemberDialog.vue';
import ImportMembersDialog from './components/MeetingVbmImportMembersDialog.vue';
import AboutMembersDialog from './components/MeetingVbmAboutMembersDialog.vue';
import BulkInviteDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkInviteDialog';
import SendInviteDialog from '@/views/meetings/meeting/units/MeetingUnitsSendInviteDialog';

export default {
  name: 'MeetingVbmMembers',
  components: {
    AddMemberDialog,
    EditMemberDialog,
    ImportMembersDialog,
    BulkInviteDialog,
    SendInviteDialog,
    AboutMembersDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    unitsListData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    shortCode: {
      type: String,
      required: true
    }
  },
  watch: {
    pagination: {
      async handler() {
        this.loading = true;
        await this.getMeetingUnits({
          shortCode: this.currentMeeting.shortCode
        });
        this.loading = false;
      }
    }
  },
  data() {
    return {
      dialog: {
        addMember: false,
        editMember: false,
        importMembers: false,
        bulkInvite: false,
        aboutMembers: false
      },
      loading: false,
      selectedMember: {},
      invite: {
        confirm: false,
        default: false,
        members: []
      }
    };
  },
  computed: {
    ...mapGetters('v2/meetings', ['stats']),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('meetings/units', ['pagination']),
    tableData() {
      return (
        this.unitsListData
          // Filter out only items with board members
          .filter(
            item =>
              item.customData?.join_as === 'board_member' &&
              item.voters?.length > 0
          )
          // Flatten voters array and map each voter to the new structure
          .flatMap(item =>
            item.voters.map(voter => ({
              key: voter.voterKey,
              initial: voter.name[0],
              name: voter.name,
              email: voter.email,
              unit_id: voter.unit_id,
              attending: voter.attending,
              inAttendance: voter.inAttendance,

              // Set ineligible boolean based on ineligible_reason
              ineligible: item.customData?.ineligible_reason === 'vbm_blocked'
            }))
          )
      );
    },
    pagination: {
      get: function() {
        return this.$store.getters['meetings/units/pagination'];
      },
      set: function(value) {
        this.setPagination(value);
      }
    },
    currentAccount() {
      return this.accountByKey(this.currentMeeting.accountKey);
    },
    canImport() {
      // Scope meeting.units.import is specific to account users
      // vbm-assistant collaborators do not have this scope
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.units.import')
      );
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.setDefaultPaginationValues();
  },
  methods: {
    ...mapActions('v2/meetings', ['fetchMeetingStats']),
    ...mapActions('meetings/webcast', ['addWebcastPanelists']),
    ...mapActions('meetings/units', [
      'getMeetingUnits',
      'sendMeetingPortalInvite',
      'updateMeetingUnit',
      'setPagination'
    ]),
    ...mapActions('accounts', ['getAccount']),
    async init() {
      try {
        this.loading = true;

        await this.getMeetingUnits({
          shortCode: this.shortCode
        });
        await this.fetchMeetingStats({ shortCode: this.shortCode });

        // VBM collaborators cannot run getAccount because they have no account level access
        // getAccount is used for listing meetings from other accounts
        if (this.canImport) {
          await this.getAccount({
            key: this.currentMeeting.accountKey,
            includeMeetings: true
          });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    openDialog(key) {
      if (key === 'bulkInvite') {
        this.invite.members = this.tableData;
        this.invite.default = true;
        this.invite.confirm = true;
      } else {
        this.dialog[key] = true;
      }
    },
    async deleteClose() {
      try {
        await this.fetchMeetingStats({ shortCode: this.shortCode });
        this.dialog.editMember = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    select(selectedMember) {
      this.selectedMember = selectedMember;
    },
    confirmSendInvite(member) {
      this.invite.confirm = true;
      this.invite.members = [member];
    },
    async sendInviteEmail(payload) {
      const { recipients, message } = payload;

      try {
        this.loading = true;

        recipients.forEach(async voter => {
          const unitId = voter.unit_id || voter.unitId;

          await this.sendMeetingPortalInvite({
            shortCode: this.shortCode,
            unitId,
            email: voter.email,
            message
          });
        });

        this.invite.confirm = false;
        this.invite.members = [];
        this.loading = false;
        this.$events.$emit('toastEvent', 'Meeting Invite Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendBulkInvites(payload) {
      const { sendList, message } = payload;

      try {
        this.loading = true;
        for (const member in sendList) {
          await this.sendMeetingPortalInvite({
            shortCode: this.shortCode,
            unitId: sendList[member].unit_id,
            email: sendList[member].email,
            message
          });
        }
        this.dialog.bulkInvite = false;
        this.loading = false;
        return this.$events.$emit('toastEvent', 'Meeting Invites Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateUnit(payload) {
      try {
        const unit = this.unitsListData.find(
          u => u.id === payload.unitId.toString()
        );

        if (unit) {
          const updatedUnit = _.cloneDeep(unit);

          // Make eligible
          if (!payload.ineligibleReason) {
            delete updatedUnit.customData.ineligible_reason;
          } else {
            // if unit's customData is null, set to empty object
            // so properties can be added
            if (!updatedUnit.customData) {
              updatedUnit.customData = {};
            }

            updatedUnit.customData.ineligible_reason = payload.ineligibleReason;
          }

          await this.updateMeetingUnit({
            shortCode: updatedUnit.shortCode,
            unitId: updatedUnit.id,
            unitData: updatedUnit
          });
        } else {
          this.$events.$emit('toastEvent', 'Could not find the unit');
        }
      } catch (err) {
        console.error(err);
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    setDefaultPaginationValues() {
      this.setPagination({
        descending: false,
        sortBy: 'unit',
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 25, 50, 100]
      });
    }
  }
};
</script>

<style scoped>
.blue-background {
  background-color: #e7f2fb;
}
.bluer-background {
  background-color: #d3e4f1;
}
.blue-text {
  color: #0f64a9;
}
.subtitle {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
}
.vbm-member-container {
  height: 100%;
}
.active-indicator {
  background: #3be481 none repeat scroll 0 0;
  border: 2px solid white;
  border-radius: 50%;
  height: 35%;
  right: -5%;
  position: absolute;
  top: -5%;
  width: 35%;
}
.v-tabs__wrapper {
  /* add css that will remove overflow and contain */
  overflow: visible !important;
  contain: content !important;
}
</style>
