<template>
  <v-dialog :value="value" @input="emitInput" max-width="350px">
    <v-card v-if="noticeStatus === 'sending'">
      <v-card-title primary-title>
        <div class="title">Sending</div>
      </v-card-title>
      <v-card-text>
        <v-progress-circular indeterminate :size="50" color="primary" />
      </v-card-text>
    </v-card>

    <v-card v-else-if="noticeStatus === 'sent'">
      <v-list>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon large color="green">check_circle</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Notice Sent!</v-list-tile-title>
            <v-list-tile-sub-title>
              Email sent to
              <strong>{{ sendCount }}</strong> Recipients
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-tooltip bottom>
              <span slot="activator">
                <v-btn flat icon @click.native="emitReturn">
                  <v-icon>keyboard_return</v-icon>
                </v-btn>
              </span>
              <span>Return to notices</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-divider />
      <span v-if="this.isPrimaryNotice">
        <v-card-text>
          <div>Would you like to schedule notice reminders?</div>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="emitClose">Go Back</v-btn>
          <v-spacer/>
          <v-btn color="deep-orange" class="white--text" @click.native="emitScheduler">
            Continue
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>

    <v-card v-else>
      <v-card-title primary-title>
        <div class="body-2 mb-2 grey--text">You're about to send an email to owners:</div>
        <div class="title">{{ state.purpose }}</div>
      </v-card-title>
      <v-card-text class="mt-0 pt-0">
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">subject</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ state.subject }}</v-list-tile-title>
              <v-list-tile-sub-title>Subject</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">person</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ segment | startCase }} ({{ sendCount }} Recipients)</v-list-tile-title>
              <v-list-tile-sub-title>Segment</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="red">warning</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <notice-checklist :body="state.body" :attachments="state.attachments" />
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">Close</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.native="emitSend">
          <v-icon left>send</v-icon>Confirm Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';
import noticeChecklist from '@/components/NoticeSendChecklist';

export default {
  name: 'MeetingNoticeViewSend',
  components: {
    noticeChecklist
  },
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      default() {
        return {};
      }
    },
    noticeStatus: {type: String, default: ''},
    sendCount: {type: Number, default: 0},
    isPrimaryNotice: {type: Boolean, default: false}
  },
  computed: {
    segment() {
      return this.state.recipients ? this.state.recipients.segment : '';
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSend() {
      this.$emit('send');
    },
    emitReturn() {
      this.$emit('return');
    },
    emitScheduler() {
      this.$emit('autoScheduler');
    }
  }
};
</script>

<style scoped>
</style>
