<template>
  <v-chip label :small="size === 'small'" :color="color" text-color="white">
    <span :class="size" class="capitalize">{{ status }}</span>
  </v-chip>
</template>

<script>
export default {
  name: 'ConsentStatusChip',
  props: {
    status: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    color() {
      switch (this.status) {
        case 'consented':
          return 'success';
        case 'rejected':
          return 'red';
        default:
          return 'grey';
      }
    }
  }
};
</script>

<style scoped>
.small {
  font-size: 11px;
}
</style>
