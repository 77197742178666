'use strict';

/**
 * @fileoverview Vuex module for a meeting's webcasts
 */

import _ from 'lodash';
import Vue from 'vue';

import { meetingWebcasts } from '@/api';

const state = {
  webcast: {},
  license: {},
  logs: {},
  panelists: {},
  polls: {},
  schedulers: [],
  defaultMotions: [],
  dataLastFetchedAt: null,
  panelistsFetchedAt: null,
  pollsFetchedAt: null,
  recordingsDownloading: false,
  slideLinks: [],
  motionType: 'defaults'
};

const getters = {
  webcast: state => state.webcast,
  license: state => state.license,
  logs: state => state.logs,
  zoomData: state => state.webcast.zoomData,
  panelists: state => Object.values(state.panelists),
  polls: state => Object.values(state.polls),
  schedulers: state => state.schedulers,
  defaultMotions: state => state.defaultMotions,
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  recordingsDownloading: state => state.recordingsDownloading,
  slideLinks: state => state.slideLinks,
  motionType: state => state.motionType
};

const actions = {
  /**
   * Get a meeting's webcast details
   *
   * @param  {String} shortCode - The meeting key
   * @param  {String} apiVersion - optional parameter to specify the api version (default v1)
   * @return {Void}
   */
  async fetchWebcastDetails({ commit }, { shortCode }) {
    try {
      const res = await meetingWebcasts.getWebcast(shortCode);
      commit('SET_WEBCAST', { webcast: res.data });
    } catch (err) {
      console.error('Error: Fetch Webcast Details', err);
      throw err;
    }
  },

  /**
   * Create a webcast with webinar and stuff
   *
   * @param  {String} shortCode     - shortCode
   * @return {Object}
   */

  async createWebcast({ dispatch }, { shortCode, payload }) {
    try {
      const res = await meetingWebcasts.postWebcast({ shortCode, payload });
      await dispatch('meetings/getMeeting', { shortCode }, { root: true });
      await dispatch('fetchWebcastDetails', { shortCode });
      return res.data;
    } catch (err) {
      console.error('Error action createWebcast', err);
      throw err;
    }
  },

  /**
   * Update webcast settings (eg. dress rehearsals)
   *
   * @param  {Object}  params
   * @param  {String}  params.shortCode     - the meeting shortcode
   * @param  {String} data.rehearsalAt        - rehearsal start time
   * @param  {String} data.rehearsalDuration  - rehearsal duration in minutes
   * @param  {String} data.rehearsalTimezone  - rehearsal time zone
   * @param  {Boolean} data.skipBoot          - skip boot indicator
   * @return {Void}
   */

  async updateWebcast(
    { dispatch },
    {
      shortCode,
      rehearsalAt,
      rehearsalDuration,
      rehearsalTimezone,
      skipBoot,
      capacity
    }
  ) {
    try {
      await meetingWebcasts.patchWebcast({
        shortCode,
        rehearsalAt,
        rehearsalDuration,
        rehearsalTimezone,
        skipBoot,
        capacity
      });
      await dispatch('fetchWebcastDetails', { shortCode });
    } catch (err) {
      console.error('Error action update WebcastSetting', err);
      throw err;
    }
  },

  /**
   * Update webcast's webinar settings
   *
   * @param  {String} shortCode     - meeting shortcode
   * @param  {String} payload       - webinar payload
   * @param  {String} skipRefresh   - skip refresh query (optional)
   * @param  {String} apiVersion    - optional parameter to specify the api version (default v1)
   * @return {Void}
   */

  async updateWebcastWebinar(
    { dispatch },
    { shortCode, payload, skipRefresh = false }
  ) {
    try {
      await meetingWebcasts.patchWebcastWebinar({ shortCode, payload });

      if (!skipRefresh) {
        await dispatch('fetchWebcastDetails', { shortCode });
      }
    } catch (err) {
      console.error('Error action update WebcastSetting', err);
      throw err;
    }
  },

  /**
   * List of all user schedulers for this meeting
   *
   * @param  {String} shortCode - The meeting key
   * @param  {Number} capacity - Webcast capacity
   * @return {Void}
   */
  async fetchWebcastUserSchedulers({ commit, getters }, { shortCode }) {
    try {
      // Don't make another API call to Zoom for new schedulers. Require a refresh to get
      // new ones. This is to help reduce API call.
      if (getters.schedulers && getters.schedulers.length > 0) {
        return null;
      }

      const res = await meetingWebcasts.listWebcastSchedulers(shortCode);
      commit('SET_WEBCAST_USER_SCHEDULERS', { schedulers: res.data });
    } catch (err) {
      err.response.data = [
        {
          message:
            'The Zoom webcast is no longer available, please contact the Dev team for additional information'
        }
      ];
      throw err;
    }
  },

  /**
   * Get list of all panelists for a meeting
   *
   * @param  {String} shortCode - The meeting key
   * @param {String} apiVersion - optional parameter to specify the api version (default v1)
   * @return {Void}
   */
  async fetchWebcastPanelists({ commit }, { shortCode }) {
    try {
      const res = await meetingWebcasts.getWebcastPanelists(shortCode);
      const { panelists, cachedAt } = res.data;
      commit('SET_PANELISTS', { panelists, cachedAt });
    } catch (err) {
      console.error('Error: Fetch Webcast Panelists', err);
      throw err;
    }
  },

  /**
   * Add or update a panelist for webinar
   *
   * @param  {String} shortCode   - meeting
   * @param  {String} name        - panelist name
   * @param  {String} email       - panelist email
   * @param {String} apiVersion   - optional parameter to specify the api version (default v1)
   * @return {Void}
   */
  async addWebcastPanelists({ dispatch }, { shortCode, name, email }) {
    try {
      await meetingWebcasts.putWebcastPanelist({ shortCode, name, email });
    } catch (err) {
      console.error('Error: Add Webcast Panelists', err);
      throw err;
    }
  },

  /**
   * Remove a panelist from the webinar
   *
   * @param  {String} shortCode   - meeting
   * @param  {String} email       - panelist email
   * @param {String} apiVersion   - optional parameter to specify the api version (default v1)
   * @return {Void}
   */
  async removeWebcastPanelist({ commit }, { shortCode, email }) {
    try {
      await meetingWebcasts.deleteWebcastPanelist({ shortCode, email });
      commit('REMOVE_PANELIST', { email });
    } catch (err) {
      console.error('Error: Remove Webcast Panelist', err);
      throw err;
    }
  },

  /**
   * Send or resent a rehearsal email to panelist
   *
   * @param  {String} shortCode   - meeting
   * @param  {String} email       - panelist email
   * @param  {File[]} attachments - meeting document attachments
   * @return {Void}
   */
  async sendRehearsalInvite({ commit }, { shortCode, email, attachments }) {
    try {
      await meetingWebcasts.postWebcastPanelistRehearsal({
        shortCode,
        email,
        attachments
      });
    } catch (err) {
      console.error('Error: Send Rehersal Invites', err);
      throw err;
    }
  },

  /**
   * Send or resend a webcast invite email to panelist
   *
   * @param  {String} shortCode   - meeting
   * @param  {String} email       - panelist email
   * @param  {File[]} attachments - meeting document attachments
   * @return {Void}
   */
  async sendPanelistWebcastInvite(
    { commit },
    { shortCode, email, attachments }
  ) {
    try {
      await meetingWebcasts.postWebcastPanelistInvite({
        shortCode,
        email,
        attachments
      });
    } catch (err) {
      console.error('Error: Send Panelist Webcast Invite', err);
      throw err;
    }
  },

  /**
   * Send rehearsal invites to multiple panelists
   *
   * @param  {String}   shortCode   - meeting
   * @param  {Object[]} panelists   - panelists in [{name, email}]
   * @param  {File[]}   attachments - meeting document attachments
   * @return {Void}
   */
  async sendBulkRehearsalInvites(
    { dispatch },
    { shortCode, panelists, attachments }
  ) {
    try {
      if (!panelists || panelists.length <= 0) {
        return;
      }

      const tasks = panelists.map(async ({ email }) => {
        return await dispatch('sendRehearsalInvite', {
          shortCode,
          email,
          attachments
        });
      });

      await Promise.all(tasks);

      return await dispatch('fetchWebcastPanelists', { shortCode });
    } catch (err) {
      console.error('Error: Send Bulk Rehersal Invites', err);
      throw err;
    }
  },

  /**
   * Re-send zoom panelist invites to multiple panelists.
   *
   * @param  {String}   shortCode   - meeting
   * @param  {Object[]} panelists   - panelists in [{name, email}]
   * @param  {File[]}   attachments - meeting document attachments
   * @return {Void}
   */
  async sendBulkZoomInvites(
    { dispatch },
    { shortCode, panelists, attachments }
  ) {
    try {
      if (!panelists || panelists.length <= 0) {
        return;
      }

      const tasks = panelists.map(async ({ name, email }) => {
        return await dispatch('sendPanelistWebcastInvite', {
          shortCode,
          name,
          email,
          attachments
        });
      });

      await Promise.all(tasks);

      return await dispatch('fetchWebcastPanelists', { shortCode });
    } catch (err) {
      console.error('Error: Send Bulk Zoom Invites', err);
      throw err;
    }
  },

  /**
   * Get list of all polls for a meeting
   *
   * @param  {String} shortCode - The meeting key
   * @return {Void}
   */
  async fetchWebcastPolls({ commit }, shortCode) {
    try {
      const res = await meetingWebcasts.listWebcastPolls({ shortCode });
      const { polls, cachedAt } = res.data;
      commit('SET_POLLS', { polls, cachedAt });
    } catch (err) {
      console.error('Error: Fetch Webcast Polls', err);
      throw err;
    }
  },

  /**
   * Add a new poll
   *
   * @param  {String}  shortCode    - meeting
   * @param  {Object}  poll         - poll payload
   * @return {Void}
   */
  async addWebcastPoll({ dispatch }, { shortCode, poll }) {
    try {
      await meetingWebcasts.postWebcastPolls({ shortCode, poll });
    } catch (err) {
      console.error('Error: Add Webcast Poll', err);
      throw err;
    }
  },

  /**
   * Update a poll
   *
   * @param  {String}  shortCode    - meeting
   * @param  {String}  pollId       - poll Id
   * @param  {Object}  poll         - poll payload
   * @return {Void}
   */
  async updateWebcastPoll({ dispatch }, { shortCode, pollId, poll }) {
    try {
      await meetingWebcasts.putWebcastPolls({ shortCode, pollId, poll });
      await dispatch('fetchWebcastPolls', shortCode);
    } catch (err) {
      console.error('Error: Fetch Webcast Polls', err);
      throw err;
    }
  },

  /**
   * Remove a poll
   *
   * @param  {String}  shortCode    - meeting
   * @param  {String}  pollId       - poll Id
   * @return {Void}
   */
  async removeWebcastPoll({ dispatch }, { shortCode, pollId }) {
    try {
      await meetingWebcasts.deleteWebcastPolls({ shortCode, pollId });
    } catch (err) {
      console.error('Error: Remove Webcast Poll', err);
      throw err;
    }
  },

  /**
   * Add polls in bulk
   *
   * @param  {String}  shortCode    - meeting
   * @param  {Object}  polls        - polls to remove
   * @return {Void}
   */
  async bulkAddWebcastPolls({ dispatch }, { shortCode, polls }) {
    try {
      // Change to using batch polls
      await meetingWebcasts.postWebcastBatchPolls({ shortCode, polls });
    } catch (err) {
      console.error('Error: Bulk Add Webcast Polls', err);
      throw err;
    }
  },

  /**
   * Remove polls in bulk
   *
   * @param  {String}  shortCode    - meeting
   * @param  {Object}  polls        - polls to remove
   * @return {Void}
   */
  async bulkRemoveWebcastPolls({ dispatch }, { shortCode, polls }) {
    try {
      const tasks = await polls.map(async poll => {
        return await dispatch('removeWebcastPoll', {
          shortCode: shortCode,
          pollId: poll.id
        });
      });
      await Promise.all(tasks);

      return dispatch('fetchWebcastPolls', shortCode);
    } catch (err) {
      console.error('Error: Bulk Remove Webcast Polls', err);
      throw err;
    }
  },

  /**
   * Grab the default motions from recipies
   *
   * @param  {String}  shortCode    - meeting
   * @param  {Object}  polls        - polls to remove
   * @return {Void}
   */
  async fetchDefaultMotions({ commit, state }, { shortCode }) {
    try {
      const results = await meetingWebcasts.getMotions({
        shortCode,
        type: state.motionType
      });
      const motions = _.get(results, 'data.data.motions', []);
      commit('SET_DEFAULT_MOTIONS', motions);
    } catch (err) {
      console.error('Error: Fetch Default Motions', err);
      throw err;
    }
  },

  /**
   * Check for recordings and trigger the download job if found
   *
   * @param  {String}  shortCode    - meeting
   * @param  {Object}  polls        - polls to remove
   * @return {Boolean}              - true if download trigger, false if no recordings.
   */
  async triggerRecordingDownloads({ commit }, { shortCode }) {
    try {
      const results = await meetingWebcasts.postRecordings({ shortCode });
      commit('SET_RECORDINGS_DOWNLOADING');
    } catch (err) {
      console.error('Error: Trigger Recording Downloads', err);
      throw err;
    }
  },

  /**
   * Get recordings from a webinar if exists.
   *
   * @param  {String}  shortCode    - meeting
   * @return {Object}               - recordings object
   */
  async getRecordings({ commit }, { shortCode }) {
    try {
      const result = await meetingWebcasts.getRecordings({ shortCode });
      return result;
    } catch (err) {
      console.error('Error: Get Recordings', err);
      throw err;
    }
  },

  /**
   * Fetch the slide links from the DB
   */
  async fetchSlideLinks({ commit }, { shortCode, webSlides }) {
    try {
      const res = await meetingWebcasts.getSlideLinks({ shortCode });
      const links = _.get(res, 'data.data.recipes', []);
      const retLinks = [];

      if (webSlides === 'ontario_hybrid') {
        retLinks.push(links[16]);
        retLinks.push(links[4]);
        retLinks.push(links[5]);
      } else if (webSlides === 'ontario_ip') {
        retLinks.push(links[3]);
        retLinks.push(links[4]);
        retLinks.push(links[5]);
      } else if (webSlides === 'ontario_no_ip') {
        retLinks.push(links[6]);
        retLinks.push(links[7]);
        retLinks.push(links[8]);
      } else if (webSlides === 'assoc_ip') {
        retLinks.push(links[9]);
        retLinks.push(links[10]);
        retLinks.push(links[11]);
      } else if (webSlides === 'assoc_hybrid') {
        retLinks.push(links[15]);
        retLinks.push(links[10]);
        retLinks.push(links[11]);
      } else if (webSlides === 'alberta_ip') {
        retLinks.push(links[12]);
        retLinks.push(links[13]);
        retLinks.push(links[14]);
      } else if (webSlides === 'alberta_hybrid') {
        retLinks.push(links[17]);
        retLinks.push(links[13]);
        retLinks.push(links[14]);
      } else {
        retLinks.push(links[0]);
        retLinks.push(links[1]);
        retLinks.push(links[2]);
      }

      commit('SET_SLIDE_LINKS', retLinks);
    } catch (err) {
      console.error('Error: Fetch Slide Links', err);
      throw err;
    }
  },

  /**
   * Get list of all meeting event logs
   *
   * @param  {String} shortCode - The meeting key
   * @return {Void}
   */
  async fetchWebcastEvents({ commit }, shortCode) {
    try {
      const res = await meetingWebcasts.getEventLogs({ shortCode });
      const logs = res.data.filter(
        item => item.event === 'webinar.ended' || item.event === 'meeting.ended'
      );

      commit('SET_WEBCAST_EVENTS', logs);
    } catch (err) {
      console.error('Error: Fetch Webcast Events', err);
      throw err;
    }
  },

  /**
   * Boot up a meeting before joining a s host
   * @param  {String} shortCode - The meeting key
   * @return {Object}           - license details
   */
  async bootWebcast({ commit, dispatch }, { shortCode }) {
    try {
      const res = await meetingWebcasts.putBootWebcast({ shortCode });
      commit('SET_LICENSE', { license: res.data });

      await dispatch('fetchWebcastDetails', { shortCode });
    } catch (err) {
      console.error('Error: Boot Webcast', err);
      throw err;
    }
  },

  /**
   * Get list of all meeting event logs
   *
   * @param  {String} type - Motion type
   * @return {Void}
   */
  setMotionsType({ commit }, type) {
    commit('SET_MOTIONS_TYPE', type);
  },

  /**
   * Check for polls and trigger the download job if found
   *
   * @param  {String}  shortCode    - meeting
   * @return {Object}
   */
  async triggerPollsReport({ commit }, { shortCode }) {
    try {
      const { data } = await meetingWebcasts.postPollsReport({ shortCode });

      return window.open(data.url, '_blank');
    } catch (err) {
      console.error('Error: Trigger Polls Report', err);
      throw err;
    }
  },

  /**
   * Check for zoom participants and trigger the download job if found
   *
   * @param  {String}  shortCode    - meeting
   * @return {Object}
   */
  async triggerParticipantsReport({ commit }, { shortCode }) {
    try {
      const { data } = await meetingWebcasts.postParticipantsReport({
        shortCode
      });

      return window.open(data.url, '_blank');
    } catch (err) {
      console.error('Error: Trigger Participants Report', err);
      throw err;
    }
  },

  /**
   * reset expired webcast data
   *
   * @param  {String}  shortCode    - meeting
   * @return {Object}
   */
  async resetWebcast({ dispatch }, { shortCode }) {
    try {
      await meetingWebcasts.resetWebcast({
        shortCode
      });

      await dispatch('meetings/getMeeting', { shortCode }, { root: true });
    } catch (err) {
      console.error('Error: Reset webcast data', err);
      throw err;
    }
  }
};

const mutations = {
  SET_WEBCAST(state, { webcast }) {
    Vue.set(state, 'webcast', webcast);
    state.dataLastFetchedAt = new Date();
  },

  SET_LICENSE(state, { license }) {
    Vue.set(state, 'license', license);
  },

  SET_PANELISTS(state, { panelists, cachedAt }) {
    state.panelists = _.keyBy(panelists, 'email');
    state.panelistsFetchedAt = cachedAt ? cachedAt : new Date();
  },

  REMOVE_PANELIST(state, { email }) {
    Vue.delete(state.panelists, email);
  },

  SET_POLLS(state, { polls, cachedAt }) {
    state.polls = _.keyBy(polls, 'id');
    state.pollsFetchedAt = cachedAt ? cachedAt : new Date();
  },

  SET_WEBCAST_USER_SCHEDULERS(state, { schedulers }) {
    state.schedulers = schedulers;
  },

  SET_DEFAULT_MOTIONS(state, motions) {
    state.defaultMotions = motions;
  },

  SET_RECORDINGS_DOWNLOADING(state) {
    state.recordingsDownloading = true;
  },

  SET_SLIDE_LINKS(state, links) {
    state.slideLinks = links;
  },

  SET_WEBCAST_EVENTS(state, logs) {
    state.logs = logs;
  },

  UPDATE_WEBCAST_SETTING(state, { key, value }) {
    _.set(state.webcast, key, value);
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.webcast = { settings: {} };
    state.license = {};
    state.panelists = {};
    state.polls = {};
    state.recordingsDownloading = false;
    state.dataLastFetchedAt = null;
  },

  SET_MOTIONS_TYPE(state, type) {
    state.motionType = type;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
