<template
  ><v-dialog max-width="1000px" :value="value" persistent>
    <v-card>
      <v-card-title class="title">Add New Checklist Template</v-card-title>
      <v-card-text>
        <v-toolbar dark color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            v-model="search"
          />
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="templatesList"
          :search="search"
          v-model="selected"
          select-all
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          item-key="key"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>
              <v-checkbox primary hide-details v-model="props.selected" />
            </td>
            <td>{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.stage }}</td>
            <td class="text-xs-left">{{ props.item.items.length }}</td>
            <td class="text-xs-left">
              {{ props.item.updatedAt | dateFromNow }}
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">Cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.native="emitAdd">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as filters from '@/filters';

export default {
  name: 'AdminChecklistTemplateGroupsAddTemplateDialog',
  filters: {
    ...filters
  },
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    templatesList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'stage',
          descending: false
        }
      },
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Stage', value: 'stage', align: 'left' },
        {
          text: 'Number of Checklist Items',
          value: 'items.length',
          align: 'left'
        },
        { text: 'Last Modified', value: 'updatedAt', align: 'left' }
      ],
      selected: []
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitAdd() {
      this.$emit('add', this.selected);
    }
  }
};
</script>

<style scoped></style>
