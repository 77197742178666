<template>
  <v-card class="round-card  full-height">
    <v-card-title class="space-between">
      <div class="h-flex">
        <v-avatar size="30" color="#e7f2fb" class="mr-2">
          <v-icon color="#3874CB" size="20">{{ icon }}</v-icon>
        </v-avatar>
        <v-tabs v-model="tab" slider-color="primary">
          <v-tab :href="`#details`">
            Details
          </v-tab>
          <v-tab :href="`#documents`">
            Documents
          </v-tab>
        </v-tabs>
        <v-tooltip bottom v-if="isDocTab">
          <span slot="activator">
            <v-icon small color="grey">info</v-icon>
          </span>
          <div>
            These documents will be available for owners to download
          </div>
        </v-tooltip>
      </div>
      <div>
        <v-btn
          v-if="edit && isDocTab"
          color="primary"
          class="ma-0 pa-0"
          @click="edit = false"
        >
          Done
        </v-btn>
        <div v-else-if="edit">
          <v-btn
            color="primary"
            class="ma-0 pa-0 mr-2"
            :loading="saving"
            @click="onSave"
          >
            Save
          </v-btn>
          <v-btn flat color="error" class="ma-0 pa-0" @click="edit = false">
            Cancel
          </v-btn>
        </div>
        <v-btn
          v-else-if="canEditDocuments || !isDocTab"
          flat
          color="primary"
          class="ma-0 pa-0"
          @click="edit = true"
        >
          Edit
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item value="details">
          <v-layout row wrap class="pa-2">
            <v-flex xs2 class="v-flex center">Location</v-flex>
            <v-flex xs10>
              <p v-if="!edit" class="mb-0">{{ meetingLocation }}</p>
              <v-text-field
                v-else
                label="Meeting Location"
                :value="location"
                :rules="[v => v.length <= 255 || 'Max 255 characters']"
                :counter="255"
                @input="onChange('location', $event)"
              />
            </v-flex>
            <v-flex xs2 class="v-flex center">Contact</v-flex>
            <v-flex xs10>
              <p v-if="!edit" class="mb-0">
                {{ contactEmail }}
              </p>
              <v-text-field
                v-else
                label="Primary Contact Email"
                :value="email"
                @input="onChange('email', $event)"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item value="documents">
          <documents-content :edit="edit" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import DocumentsContent from './DocumentsContent';
export default {
  name: 'DetailsDocumentsCard',
  components: {
    DocumentsContent
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    meetingLocation: {
      type: String,
      default: ''
    },
    contactEmail: {
      type: String,
      default: ''
    },
    canEditDocuments: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false,
      email: '',
      location: '',
      saving: false,
      shortCode: this.$route.params.shortcode,
      tab: 'details'
    };
  },
  created() {
    this.setState();
  },
  computed: {
    icon() {
      return this.tab === 'details' ? 'subject' : 'description';
    },
    isDocTab() {
      return this.tab === 'documents';
    }
  },
  watch: {
    tab: function() {
      this.edit = false;
    },
    meetingLocation: function() {
      this.setState();
    },
    contactEmail: function() {
      this.setState();
    }
  },
  methods: {
    ...mapActions('v2/meetings', ['updateMeeting']),
    setState() {
      // Copy component prop values into mutable local properties
      this.location = this.meetingLocation;
      this.email = this.contactEmail;
    },
    onChange(key, value) {
      _.set(this, key, value);
    },
    async onSave() {
      try {
        this.saving = true;
        const updates = {
          location: this.location,
          contactEmail: this.email
        };
        await this.updateMeeting({
          shortCode: this.shortCode,
          updates
        });
        this.$events.$emit('toastEvent', 'Meeting Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.edit = false;
      }
    }
  }
};
</script>

<style scoped>
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.round-card {
  border-radius: 7px;
}
.full-height {
  height: 100%;
}
.v-flex {
  display: flex;
  flex-direction: column;
}
.center {
  justify-content: center;
}
</style>
