<template>
  <v-container fluid>
    <h1 class="headline">Global Membership Search</h1>

    <p class="subheading">Search for a voter or member by name or email</p>

    <p>Note: at least 3 characters required to search</p>

    <v-toolbar light class="grey lighten-4 elevation-1">
      <v-text-field
        prepend-icon="search"
        label="Start typing to search..."
        single-line
        hide-details
        class="shrink"
        clearable
        :value="searchQuery"
        @input="updateSearchQuery($event)"
      />
      <span class="mx-4">Search By:</span>
      <v-btn-toggle v-model="searchToggle" mandatory class="elevation-1">
        <v-btn :disabled="isLoading" flat color="primary" value="email">
          <span class="caption">Email</span>
        </v-btn>
        <v-btn :disabled="isLoading" flat color="primary" value="name">
          <span class="caption">Name</span>
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-data-table
      :loading="isLoading"
      v-if="searchQuery && searchQuery.length > 2"
      :headers="headers"
      :items="items"
      :search="searchQuery"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="defaultPagination"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr
          :key="props.index"
          :class="props.item.deletedAt ? 'deleted-voter' : ''"
        >
          <td
            class="text-xs-left text-highlight name-overflow"
            :class="props.item.deletedAt ? '' : 'pointer link-style'"
            @click="onClickVoter(props.item, props.item.name)"
          >
            {{ props.item.name }}
            <v-tooltip bottom>
              <v-icon small slot="activator" v-if="props.item.deletedAt"
                >info</v-icon
              >
              <span>
                Deleted at:
                {{ props.item.deletedDate }}
              </span>
            </v-tooltip>
          </td>
          <td
            class="text-xs-left"
            :class="props.item.deletedAt ? '' : 'pointer link-style'"
            @click="onClickVoter(props.item, props.item.email)"
          >
            {{ props.item.email }}
          </td>
          <td class="text-xs-left">
            {{ props.item.unit }}
          </td>
          <td class="text-xs-left">
            {{ props.item.meetingName }}
          </td>
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            <v-tooltip
              bottom
              v-if="
                props.item.status.consent &&
                  props.item.status.consent !== 'rejected'
              "
            >
              <v-icon
                slot="activator"
                color="green lighten-1"
                v-show="
                  props.item.status.consent &&
                    props.item.status.consent !== 'rejected' &&
                    props.item.email !== 'noemail@getquorum.com'
                "
                >email</v-icon
              >
              <span>
                Owner consented on:
                {{
                  props.item.status.consent
                    ? formatDateReadable(
                        props.item.status.consentTimestamp,
                        props.item.meetingTimezone
                      )
                    : 'Not Available'
                }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="blue lighten-1"
                v-show="props.item.status.rsvp > 0"
                >how_to_reg</v-icon
              >
              <span>Owner has RSVP'd</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="purple lighten-1"
                v-show="props.item.status.attendance > 0"
                >person_pin_circle</v-icon
              >
              <span>Owner is attending event</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { formatDate, formatDateHumanReadable } from '@/helpers';
import { handleError } from '@/helpers';

export default {
  name: 'VotersHomeView',
  components: {},
  data() {
    return {
      defaultPagination: {
        descending: true,
        sortBy: 'meetingDate'
      },
      searchToggle: 'email',
      isLoading: false,
      headers: [
        { text: 'Voter Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Unit', value: 'email', align: 'left' },
        { text: 'Meeting Name', value: 'meetingName', align: 'left' },
        { text: 'Meeting Date', value: 'meetingDate', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },

  watch: {
    searchToggle: function() {
      const value = this.searchQuery;
      if (!value || (value && value.length < 3)) {
        this.cleanItems();
        return;
      }
      this.fetchVotersData();
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('voters', ['searchQuery', 'votersListData']),
    items() {
      return this.votersListData.map(voter => {
        voter.date = this.formatMeetingDate(
          voter.meetingDate,
          voter.meetingTimezone
        );
        voter.status = {
          consent: voter.consent.status,
          consentTimestamp: voter.consent.timestamp,
          rsvp: voter.attending,
          attendance: voter.inAttendance
        };
        if (voter.deletedAt) {
          voter.deletedDate = this.formatMeetingDate(
            voter.deletedAt,
            voter.meetingTimezone
          );
        }
        return voter;
      });
    }
  },
  methods: {
    ...mapActions('voters', ['setSearchQuery', 'getVoters', 'cleanVoters']),
    async fetchVotersData() {
      try {
        this.isLoading = true;
        await this.getVoters({
          searchText: this.searchQuery,
          type: this.searchToggle
        });
      } catch (err) {
        console.error('Error getting voters list data', err);
        handleError(err);
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    onClickVoter(voter, searchValue) {
      if (voter.deletedAt || !voter.name) {
        return;
      }
      this.$router.push({
        name: 'meetingUnits',
        params: {
          shortcode: voter.shortCode
        },
        query: { search: searchValue }
      });
    },
    formatMeetingDate(meetingDate, meetingTimezone) {
      return formatDate(
        meetingDate,
        meetingTimezone ? meetingTimezone : 'America/Toronto',
        'MMMM Do YYYY, h:mmA zz'
      );
    },
    cleanItems() {
      this.cleanVoters();
    },

    formatDateReadable(date, meetingTimezone) {
      return formatDateHumanReadable(date, false, meetingTimezone);
    },
    updateSearchQuery: _.debounce(function(value) {
      this.setSearchQuery(value);
      if (!value || (value && value.length < 3)) {
        this.cleanItems();
        return;
      }
      this.fetchVotersData();
    }, 800)
  }
};
</script>

<style scoped>
.name-overflow {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
}
.deleted-voter {
  text-decoration: line-through;
}
.link-style {
  text-decoration: underline;
  color: #0077cc;
}
</style>
