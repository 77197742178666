<template>
  <v-tooltip right>
    <v-menu offset-y light slot="activator">
      <v-chip
        v-show="isAdmin"
        small
        :color="stageColor"
        text-color="white"
        class="light-blue darken-2 ml-2"
        v-model="selected"
        slot="activator"
      >
        <v-avatar> <v-icon>edit</v-icon> </v-avatar>
        {{ stage.toUpperCase() }}
      </v-chip>
      <v-list>
        <v-list-tile
          v-for="item in items"
          @click.native="handleStageChange(item.value);"
          :key="item.value"
        >
          <v-list-tile-title>{{ item.label }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <span>Click to edit stage</span>
  </v-tooltip>
</template>



<script>
import _ from 'lodash';

export default {
  name: 'MeetingStageChip',
  props: {
    stage: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        { label: 'New', value: 'new' },
        { label: 'Preconsent', value: 'preconsent' },
        { label: 'Prenotice', value: 'prenotice' },
        { label: 'Served', value: 'served' }
      ],
      selected: ''
    };
  },
  computed: {
    stageColor() {
      switch (this.stage) {
        case 'new':
          return 'amber darken-3';
        case 'prenotice':
          return 'teal lighten-1';
        case 'preconsent':
          return 'indigo';
        case 'served':
          return 'green darken-1';
        default:
          return 'grey';
      }
    }
  },
  methods: {
    handleStageChange(newStage) {
      this.$emit('change', newStage);
    }
  }
};
</script>

<style scoped>
</style>
