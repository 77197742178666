<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <span class="title">Are you sure you want to close this request?</span>
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="comment"
          outline
          hide-details
          hint
          name="input-7-4"
          placeholder="Leave a comment (optional)."
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-btn color="primary" @click.native="handleSubmit"
          >close approval</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApprovalCloseDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    }
  },
  data() {
    return {
      comment: ''
    };
  },
  computed: {},
  mounted() {
    this.resetState();
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { comment: this.comment });
      this.handleClose();
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    resetState() {
      this.comment = '';
    }
  }
};
</script>

<style scoped></style>
