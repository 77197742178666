<template>
  <v-card class="round-card  full-height">
    <v-card-title class="space-between">
      <div class="h-flex">
        <v-avatar size="30" color="#e7f2fb" class="mr-2">
          <v-icon color="#3874CB" size="20">{{ icon }}</v-icon>
        </v-avatar>
        <v-tabs v-model="tab" slider-color="primary">
          <v-tab :href="`#dates`">
            Dates
          </v-tab>
          <v-tab v-if="canSeeWebcast" :href="`#webcast`">
            Webcast
          </v-tab>
        </v-tabs>
      </div>
      <div v-if="canEditWebcast">
        <div v-if="edit">
          <v-btn
            v-if="edit"
            color="primary"
            class="ma-0 mr-2 pa-0"
            :loading="saving"
            @click="onSave"
          >
            Save
          </v-btn>
          <v-btn
            v-if="edit"
            flat
            color="error"
            class="ma-0 pa-0"
            @click="edit = false"
          >
            Cancel
          </v-btn>
        </div>
        <v-btn
          v-else-if="!edit && !hideEdit"
          flat
          color="primary"
          class="ma-0 pa-0"
          @click="edit = true"
        >
          Edit
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item value="dates">
          <v-layout row wrap>
            <v-flex xs12 v-if="edit">
              <h5 class="subheading">
                Meeting Time Zone
              </h5>
              <p class="grey--text text--darken-2">
                Set the time zone for all dates in this meeting.
              </p>
              <v-select
                :items="timezones"
                item-text="text"
                item-value="value"
                v-model="timezone"
                solo
                label="Timezones"
                @change="onChange('timezone', $event)"
              />
            </v-flex>
            <v-flex xs12>
              <p class="mb-0">Meeting</p>
              <div v-if="!edit">
                <p>
                  <b>{{ formatMeetingDate }}</b>
                </p>
              </div>
              <div v-else>
                <date-time-pickers
                  label="Date of meeting"
                  :timezone="timezone"
                  :iso-date="date"
                  @update="onChange('date', $event)"
                />
                <v-text-field
                  prepend-icon="watch"
                  label="Duration"
                  :value="duration"
                  type="number"
                  suffix="minutes"
                  min="0"
                  max="360"
                  @input="onChange('duration', $event)"
                />
              </div>
              <div>
                <p class="grey--text mb-0">
                  Submissions Deadline
                  <v-tooltip bottom>
                    <span slot="activator">
                      <v-icon small color="grey">info</v-icon>
                    </span>
                    <div>
                      Deadline for voters to make their submissions
                    </div>
                  </v-tooltip>
                </p>
              </div>
              <div v-if="!edit">
                <p class="grey--text">
                  {{ formatExpiryDate }}
                </p>
              </div>
              <div v-else>
                <date-time-pickers
                  label="Submissions Deadline"
                  :iso-date="expiryDate"
                  :timezone="timezone"
                  @update="onChange('deadline', $event)"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item v-if="canSeeWebcast" value="webcast">
          <webcast-content :loading="loading" :webcast="webcast" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import { formatDate } from '@/helpers.js';
import timezones from '@/lib/timezones';

import WebcastContent from './WebcastContent';
import DateTimePickers from '@/components/DateTimePicker';
export default {
  name: 'DatesWebcastCard',
  components: {
    DateTimePickers,
    WebcastContent
  },
  props: {
    webcast: {
      type: Object,
      default() {
        return null;
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    expiryDate: {
      type: String,
      required: true,
      default: ''
    },
    hasWebcast: {
      type: Boolean,
      default: false
    },
    isIntegratedPortalEnabled: {
      type: Boolean,
      default: false
    },
    meetingDate: {
      type: String,
      required: true,
      default: ''
    },
    meetingDuration: {
      type: Number,
      default: 0
    },
    meetingTimezone: {
      type: String,
      default: 'America/Toronto'
    },
    canSeeWebcast: {
      type: Boolean,
      default: false
    },
    canEditWebcast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 'dates',
      edit: false,
      date: null,
      deadline: null,
      duration: 0,
      saving: false,
      shortCode: this.$route.params.shortcode,
      timezone: null,
      timezones
    };
  },
  created() {
    this.setState();
  },
  computed: {
    hideEdit() {
      return this.tab === 'webcast';
    },
    icon() {
      return this.tab === 'webcast' ? 'videocam' : 'event';
    },
    formatMeetingDate() {
      return formatDate(
        this.meetingDate,
        this.meetingTimezone,
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    formatExpiryDate() {
      return formatDate(
        this.expiryDate,
        this.meetingTimezone,
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    }
  },
  watch: {
    tab: function() {
      this.edit = false;
    },
    meetingDate: function() {
      this.setState();
    },
    expiryDate: function() {
      this.setState();
    },
    meetingDuration: function() {
      this.setState();
    },
    meetingTimezone: function() {
      this.setState();
    },
    hasWebcast: function() {
      this.setState();
    }
  },
  methods: {
    ...mapActions('v2/meetings', ['updateMeeting']),
    ...mapActions('meetings/webcast', ['updateWebcastWebinar']),
    setState() {
      // Copy component prop values into mutable local properties
      this.date = this.meetingDate;
      this.deadline = this.expiryDate;
      this.duration = this.meetingDuration;
      this.timezone = this.meetingTimezone;
    },
    onChange(key, value) {
      _.set(this, key, value);
    },
    async onSave() {
      try {
        this.saving = true;
        const isSame = moment(this.meetingDate).isSame(moment(this.date));
        const isSameDuration = this.meetingDuration === this.duration;
        //If meeting date or duration is changed, update the webcast as well
        const mustUpdateWebcast =
          (!isSame || !isSameDuration) && this.hasWebcast;
        if (mustUpdateWebcast) {
          await this.updateWebCast();
        }
        const updates = {
          expiryDate: this.deadline,
          duration: this.duration,
          meetingDate: this.date,
          meetingTimezone: this.timezone
        };
        await this.updateMeeting({
          shortCode: this.shortCode,
          updates
        });
        const msg = mustUpdateWebcast
          ? 'Meeting & Webcast Updated'
          : 'Meeting Updated';
        this.$events.$emit('toastEvent', msg);
      } catch (err) {
        //Update did not go through, restore original state.
        this.setState();
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.edit = false;
      }
    },
    async updateWebCast() {
      try {
        const momentDate = moment(this.date).tz(this.timezone);
        await this.updateWebcastWebinar({
          shortCode: this.shortCode,
          payload: {
            startTime: momentDate.format('YYYY-MM-DD[T]HH:mm:ss'),
            timezone: this.timezone,
            duration: this.duration,
            settings: {
              allowMultipleDevices: this.isIntegratedPortalEnabled
            }
          }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        throw err;
      }
    }
  }
};
</script>

<style scoped>
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.round-card {
  border-radius: 7px;
}
.full-height {
  height: 100%;
}
</style>
