import { render, staticRenderFns } from "./InvoiceStatusChip.vue?vue&type=template&id=97c65638&scoped=true"
import script from "./InvoiceStatusChip.vue?vue&type=script&lang=js"
export * from "./InvoiceStatusChip.vue?vue&type=script&lang=js"
import style0 from "./InvoiceStatusChip.vue?vue&type=style&index=0&id=97c65638&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c65638",
  null
  
)

export default component.exports