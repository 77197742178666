export default [
  'support@getquorum.com',
  'eric@getquorum.com',
  'max@getquorum',
  'nandaram@getquorum.com',
  'jason@getquorum.com',
  'frank@getquorum.com',
  'kelvin@getquorum.com',
  'giancarlo@getquorum.com'
];
