import http from './requestType';

/**
 * Get a meeting's conversations
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function listConversations(shortCode) {
  return http._get(`Meetings/${shortCode}/conversations`);
}

/**
 * Get the conversation associated with the id
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} conversationKey - the conversation id
 * @return {Promise} - Promise
 */
export function getConversation(shortCode, conversationKey) {
  return http._get(`Meetings/${shortCode}/conversations/${conversationKey}`);
}

/**
 * Create a conversation for the meeting
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} payload - payload of the new conversation
 * @return {Promise} - Promise
 */
export function postMeetingConversation(shortCode, payload) {
  return http._post(`Meetings/${shortCode}/conversations`, payload);
}

/**
 * Update a meeting conversation, but only if it's status is equal to 'draft'. Sending or sent conversations cannot be changed.
 * @param  {String} shortCode - the meeting key
 * @param  {String} conversationKey - the conversation key
 * @param  {String} conversation    - new conversation
 * @return {Promise}          - Promise
 */
export function putConversation(shortCode, conversationKey, conversation) {
  return http._put(
    `Meetings/${shortCode}/conversations/${conversationKey}`,
    conversation
  );
}

/**
 * Delete a Meeting's conversation, but only if it's status is equal to 'draft'. Sent or sending conversations cannot be deleted.
 * @param  {String} shortCode - the meeting key
 * @param  {String} conversationKey - conversation key
 * @return {Promise}          - Promise
 */
export function deleteConversation(shortCode, conversationKey) {
  return http._delete(`Meetings/${shortCode}/conversations/${conversationKey}`);
}

/**
 * Create the list of conversations provided and schedule
 * @param  {String} conversations  - the list of conversations to create and schedule
 * @return {Promise}        - Promise
 */
export function scheduleConversationList(scheduleParams) {
  return http._post(`Meetings/conversation-schedule`, scheduleParams);
}

/**
 * Delete a Meeting's conversation, but only if it's status is equal to 'draft'. Sent or sending conversations cannot be deleted.
 * @return {Promise}          - Promise
 */
export function getConversationFields() {
  return http._get(`Meetings/conversation-fields`);
}

/**
 * Gets a preview of a conversation message.
 * This purpose of the route is for previewing the content as a user builds the email in an editor.
 * @param  {String} shortCode - the meeting key
 * @param  {String} conversationKey - the conversation key
 * @return {Promise}          - Promise
 */
export function getConversationPreview(shortCode, conversationKey) {
  return http._get(
    `Meetings/${shortCode}/conversations/${conversationKey}/preview`
  );
}
