<template>
  <div class="text-xs-center">
    <v-menu
      v-model="menu"
      :max-width="450"
      :min-height="900"
      :close-on-content-click="false"
      :close-on-click="true"
      right
      :nudge-right="40"
      :nudge-top="20"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn class="red--text lighten-2" icon v-on="on">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-text class="pt-1">
          <v-layout align-end>
            <v-text-field
              v-model="message"
              label="Reason for deletion"
              placeholder="optional message"
              hide-details
            />

            <v-btn class="error delete-btn" small flat @click="emitDelete(item)"
              >Delete</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AdditionalVotePopoverDeleteMenu',
  filters: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {},
  computed: {},
  data() {
    return {
      menu: false,
      message: ''
    };
  },
  methods: {
    emitDelete(item) {
      this.$emit('delete', {
        additionalVoteKey: item.key,
        message: this.message
      });

      // Reset
      this.message = '';
    }
  }
};
</script>

<style scoped>
.delete-btn {
  position: relative;
  top: 5px;
}
</style>
