<template>
  <v-select
    label="Fiscal Year End"
    placeholder="Fiscal Year End"
    :value="month"
    :items="items"
    item-text="label"
    item-value="value"
    @change="handleUpdate"
  />
</template>

<script>
export default {
  name: 'FiscalYearEndSelector',
  props: {
    month: {
      type: Number,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      items: [
        { label: 'TBD', value: null },
        { label: 'January 31', value: 1 },
        { label: 'February 28', value: 2 },
        { label: 'March 31', value: 3 },
        { label: 'April 30', value: 4 },
        { label: 'May 31', value: 5 },
        { label: 'June 30', value: 6 },
        { label: 'July 31', value: 7 },
        { label: 'August 31', value: 8 },
        { label: 'September 30', value: 9 },
        { label: 'October 31', value: 10 },
        { label: 'November 30', value: 11 },
        { label: 'December 31', value: 12 }
      ]
    };
  },
  methods: {
    handleUpdate(value) {
      this.$emit('update', value);
    }
  }
};
</script>

<style scoped></style>
