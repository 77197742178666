<template>
  <div>
    <v-card fill-height>
      <v-container align-center>
        <div v-if="displayAdvanceToggle" class="center">
          <div>
            <span>
              Advance Voting is
              <b class="switch__label">{{ advanceToggleText }}</b></span
            >
          </div>
          <div class="ml-1 mt-1">
            <v-switch
              slot="activator"
              color="primary"
              v-model="advanceToggle"
              :loading="dialog.advanceVoting"
              @change="handleAdvanceVotingToggle"
            >
            </v-switch>
          </div>
        </div>
        <div class="center">
          <v-icon color="light-blue" dark class="icon-size pr-2"
            >description</v-icon
          >
          <v-badge right overlap color="red">
            <template
              v-slot:badge
              v-if="
                stats &&
                  stats.testVoterStats &&
                  stats.testVoterStats.totalTestVoterProxies > 0
              "
            >
              <v-tooltip bottom>
                <span slot="activator">
                  <span>{{ stats.testVoterStats.totalTestVoterProxies }}</span>
                </span>
                <span
                  >{{ stats.testVoterStats.totalTestVoterProxies }} test voter
                  submissions included</span
                >
              </v-tooltip>
            </template>

            <div
              :class="
                primaryStatNumber && primaryStatNumber > 1000000
                  ? 'big-number'
                  : 'number'
              "
            >
              <v-progress-circular
                v-if="loadingStats"
                indeterminate
                color="primary"
                class="mt-3"
              />
              <div v-else-if="primaryStatNumber >= 0">
                {{ primaryStatNumber.toLocaleString() }}
              </div>
              <div v-else>?</div>
            </div>
          </v-badge>
          <div class="stat-label">{{ primaryStatLabel }}</div>
        </div>

        <div
          class="grid-container my-2"
          v-if="!loadingStats && stats && primaryStatNumber > 0"
        >
          <div v-if="collectRtvBallots" class="text-right">
            {{ this.stats.totalRtvBallots }}
          </div>
          <div v-if="collectRtvBallots">
            <span class="ml-1">electronic ballots</span>
          </div>
          <div class="text-right">{{ this.stats.totalElectronicProxies }}</div>
          <div>
            <span class="ml-1">{{
              collectRtvBallots ? 'electronic proxies' : 'online submissions'
            }}</span>
          </div>
          <div class="text-right">{{ this.stats.totalPaperProxies }}</div>
          <div>
            <span class="ml-1">via paper proxy tool</span>
          </div>
        </div>

        <div
          v-if="!loadingStats && stats && displaySecondaryStats"
          class="center secondary-stat my-2"
        >
          ({{ secondaryStatNumber.toLocaleString() }} {{ secondaryStatLabel }})
        </div>

        <div
          v-if="!loadingStats && stats && stats.unverifiedProxyCount > 0"
          class="center my-2"
        >
          <div class="unverified-text" @click="dialog.unverified = true">
            <v-icon class="pr-2" color="error" small>error</v-icon>
            <div>
              <span class="body-2">{{ stats.unverifiedProxyCount }}</span>
              submissions require additional verification (
              <span class="unverified-help">what does this mean</span>? )
            </div>
          </div>
        </div>

        <div
          v-if="
            !loadingStats &&
              stats &&
              stats.testVoterStats &&
              stats.testVoterStats.totalTestVoterProxies > 0
          "
          class="center"
        >
          <div class="unverified-text">
            <v-icon class="pr-2" color="error" small>warning</v-icon>
            <div>
              <span class="body-2">{{
                stats.testVoterStats.totalTestVoterProxies
              }}</span>
              submissions are from units with test voters
            </div>
          </div>
        </div>
      </v-container>

      <v-divider />

      <v-container align-center>
        <div class="center">
          <v-btn
            :disabled="disableReport"
            @click.native="$emit('options')"
            color="primary"
            class="white--text"
            >Download Forms &amp; Reports</v-btn
          >
        </div>
        <div class="center" v-if="disableReport">{{ disabledMsg }}</div>
      </v-container>
    </v-card>

    <!-- VOTING REPORT -->
    <v-card
      v-if="displayVotingReport && (isAdmin || isCollaborator)"
      fill-height
      class="mt-3"
    >
      <v-container align-center>
        <div class="center">
          <v-btn outline @click.native="$emit('votingReport')" color="primary"
            ><v-icon left>download</v-icon>Voting Results Report</v-btn
          >
        </div>
        <div class="center">
          <v-btn
            outline
            color="primary"
            :loading="saving"
            @click.native="openSnapshotHandler"
          >
            <v-icon class="mr-4">filter_center_focus</v-icon>Attendance
            snapshot</v-btn
          >
        </div>
        <div v-if="hasAttendanceSnapshot" class="center mt-3">
          <span class="secondary-stat text-center">
            <snapshot-dialog
              :snapshot-list="snapshotList"
              :meeting-timezone="meetingTimezone"
            />
          </span>
        </div>
      </v-container>
    </v-card>

    <v-dialog v-model="dialog.unverified" max-width="500px">
      <v-card>
        <v-card-text>
          <v-alert outline color="error" icon="error" :value="true">
            <v-progress-circular
              v-if="loadingStats"
              indeterminate
              color="primary"
              class="mt-3"
            />
            <h3 v-else-if="!loadingStats">
              {{ stats.unverifiedProxyCount || '?' }} of the submissions are
              unverified.
            </h3>
            <p>
              These submissions will be downloaded in a separate folder and
              should be reviewed to confirm they are valid.
            </p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" flat @click.native="dialog.unverified = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <toggle-advance-voting-dialog
      v-model="dialog.advanceVoting"
      @cancel="cancelAdvanceVoting"
      @closeAdvanceVoting="closeAdvanceVoting"
    />

    <confirm-dialog
      title="Are you sure?"
      text="This will create a new snapshot."
      :dialog="dialog.snapshot"
      confirm-text="Yes"
      decline-text="No"
      @confirm="handleTakeSnapshot"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { formatDate } from '@/helpers.js';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import checkScope from '@/lib/check-user-scopes';
import ToggleAdvanceVotingDialog from './dialogs/ToggleAdvanceVotingDialog.vue';
import ConfirmDialog from './dialogs/ConfirmDialog.vue';
import SnapshotDialog from './dialogs/SnapshotDialog.vue';

export default {
  name: 'MeetingVoteSummary',
  components: {
    ToggleAdvanceVotingDialog,
    ConfirmDialog,
    SnapshotDialog
  },
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      }
    },
    disableReport: {
      type: Boolean,
      default() {
        return false;
      }
    },
    primaryStat: {
      type: String,
      default() {
        return '';
      }
    },
    displaySecondaryStats: {
      type: Boolean,
      default() {
        return false;
      }
    },
    displaySharesAsPercentages: {
      type: Boolean,
      default() {
        return false;
      }
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default() {
        return '0';
      }
    },
    displayAdvanceToggle: {
      type: Boolean,
      default() {
        return false;
      }
    },
    displayVotingReport: {
      type: Boolean,
      default() {
        return false;
      }
    },
    expiryDate: {
      type: String,
      default() {
        return null;
      }
    },
    isVotingClose: {
      type: Boolean,
      default() {
        return false;
      }
    },
    attendanceSnapshot: {
      type: Object,
      default() {
        return {};
      }
    },
    meetingTimezone: { type: String, default: '' },
    collectRtvBallots: {
      type: Boolean,
      default() {
        return false;
      }
    },
    loadingStats: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      primaryStatNumber: 0,
      primaryStatLabel: '',
      secondaryStatNumber: 0,
      secondaryStatLabel: '',
      saving: false,
      dialog: {
        unverified: false,
        advanceVoting: false,
        snapshot: false
      },
      advanceToggle: true
    };
  },
  watch: {
    stats() {
      this.buildData();
    }
  },

  async mounted() {
    this.buildData();
    await this.fetchSnapshots(this.shortCode);
  },
  created() {
    // if we set the expiry date in the future, the toggle should be open
    if (this.expiryDate && this.isDateExpiried) this.advanceToggle = false;
    // if the expiry date is in the past, the toggle should be closed
    else if (this.expiryDate && !this.isDateExpiried) this.advanceToggle = true;
    // this means when we toggle from closed to open
    else if (!this.expiryDate) this.advanceToggle = true;
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes', 'getScopeByAction']),
    ...mapGetters('meetings/attendanceSnapshots', ['snapshotList']),
    disabledMsg() {
      if (this.stats.totalProxies <= 0) {
        return 'There are no owner submissions';
      } else {
        return 'Not available until voting closes.';
      }
    },
    advanceToggleText() {
      if (this.advanceToggle) {
        return 'Open';
      }
      return 'Closed';
    },
    // check the expiry date to precisely set the toggle
    isDateExpiried() {
      return moment().isSameOrAfter(this.expiryDate);
    },
    hasAttendanceSnapshot() {
      return !_.isEmpty(this.snapshotList);
    },
    snapshotDate() {
      return formatDate(
        _.get(this.attendanceSnapshot, 'updatedAt', ''),
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    getTotalAttendanceProxies() {
      const electronicProxies = this.attendanceSnapshot.electronicProxies || 0;
      const paperProxies = this.attendanceSnapshot.paperProxies || 0;
      const total = electronicProxies + paperProxies;

      return total;
    },
    isCollaborator() {
      return checkScope(
        this.scopes,
        this.shortCode,
        'meeting.elections.view.early'
      );
    }
  },
  methods: {
    ...mapActions('meetings', ['toggleMeetingAdvanceVoting']),
    ...mapActions('meetings/attendanceSnapshots', [
      'fetchSnapshots',
      'insertSnapshot'
    ]),
    ...mapActions('v2/meetings', ['setAttendanceSnapshot']),
    formatShares() {
      const totalSubmissionsShares = this.collectRtvBallots
        ? this.stats.totalProxiesBallotsShares
        : this.stats.totalProxiesShares;

      if (this.displaySharesAsPercentages) {
        return (
          parseFloat(totalSubmissionsShares * 100).toFixed(
            this.displayRoundedResults ? this.roundedResultsPrecision : 0
          ) + '%'
        );
      }

      return totalSubmissionsShares;
    },

    async handleAdvanceVotingToggle(value) {
      // case 1: the toggle is turned back on
      if (value) {
        await this.toggleMeetingAdvanceVoting({
          shortCode: this.shortCode,
          advanceVotingStatus: 'open'
        });

        this.$events.$emit('toastEvent', 'Advance Voting is OPEN');
      } else {
        // case 2: the toggle is turned off
        this.dialog.advanceVoting = true;
      }
    },
    async closeAdvanceVoting() {
      try {
        await this.toggleMeetingAdvanceVoting({
          shortCode: this.shortCode,
          advanceVotingStatus: 'closed'
        });

        this.dialog.advanceVoting = false;

        this.$events.$emit('toastEvent', 'Advance Voting is CLOSED');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    cancelAdvanceVoting() {
      this.advanceToggle = true;
      this.dialog.advanceVoting = false;
    },
    async handleTakeSnapshot(value) {
      this.dialog.snapshot = false;
      if (value) {
        this.saving = true;
        try {
          await this.insertSnapshot(this.shortCode);
        } catch (err) {
          this.$events.$emit('toastEvent', err.response);
        } finally {
          this.saving = false;
        }
      }
    },
    async openSnapshotHandler() {
      if (!this.hasAttendanceSnapshot) {
        this.saving = true;
        try {
          await this.insertSnapshot(this.shortCode);
        } catch (err) {
          this.$events.$emit('toastEvent', err.response);
        } finally {
          this.saving = false;
        }
      } else {
        this.dialog.snapshot = true;
      }
    },

    buildData() {
      if (this.collectRtvBallots) {
        if (!this.displaySecondaryStats || this.primaryStat === 'Units') {
          this.primaryStatNumber = this.stats.totalProxiesBallots;
          this.primaryStatLabel = 'Unique Voters';
          this.secondaryStatNumber = this.formatShares();
          this.secondaryStatLabel = 'Unique Voters by Shares';
        } else {
          this.primaryStatNumber = this.formatShares();
          this.primaryStatLabel = 'Unique Voters by Shares';
          this.secondaryStatNumber = this.stats.totalProxiesBallots;
          this.secondaryStatLabel = 'Unique Voters';
        }
      } else {
        if (!this.displaySecondaryStats || this.primaryStat === 'Units') {
          this.primaryStatNumber = this.stats.totalProxies;
          this.primaryStatLabel = 'Submissions';
          this.secondaryStatNumber = this.formatShares();
          this.secondaryStatLabel = 'Submissions by Shares';
        } else {
          this.primaryStatNumber = this.formatShares();
          this.primaryStatLabel = 'Submissions by Shares';
          this.secondaryStatNumber = this.stats.totalProxies;
          this.secondaryStatLabel = 'Submissions';
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stat-label {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.number {
  padding-right: 15px;
  font-size: 55px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}
.big-number {
  padding-right: 15px;
  font-size: 40px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}
.center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.secondary-stat {
  color: rgba(0, 0, 0, 0.6);
}
.unverified-text {
  display: flex;
  font-size: 12px;
  width: 255px;
  color: rgba(0, 0, 0, 0.6);
}
.icon-size {
  top: 2px;
  font-size: 45px;
}
.unverified-help {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: pointer;
  color: #757575;
}
.menu {
  position: absolute;
  right: 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
}
.text-right {
  text-align: right;
}
.switch__label {
  color: #1976d2;
}
.text-center {
  text-align: center;
}
</style>
