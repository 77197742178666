<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Checklist Template Groups</p>
        <p class="secondary--text">
          Manage checklist templates groups. Click on a template group to edit
          it.
        </p>
      </v-flex>
    </v-layout>

    <!-- Checklist Template Groups Table -->
    <v-layout class="ml-3 mr-3 mb-3" row>
      <v-flex xs12>
        <checklist-template-groups-table
          :state="templateGroupsData"
          @new-button-clicked="dialog.newTemplateGroup = true"
          @row-clicked="goToTemplateGroup"
        />
      </v-flex>
    </v-layout>

    <!-- Create Template Group Dialog -->
    <v-dialog v-model="dialog.newTemplateGroup" persistent max-width="600px">
      <v-card>
        <v-card-title class="title"
          >Create New Checklist Template Group</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Checklist Template Group Name"
            v-model="newTemplateGroup.name"
            placeholder="eg. Ontario Condo Group"
            required
          />
          <v-text-field
            label="Description"
            v-model="newTemplateGroup.description"
            placeholder="eg. Group of checklists for Ontario Condo AGM campaigns"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="dialog.newTemplateGroup = false"
            >Cancel</v-btn
          ><v-spacer />
          <v-btn
            class="primary"
            :disabled="isAddButtonDisabled"
            @click.native="addNewTemplateGroupClicked"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

import ChecklistTemplateGroupsTable from './AdminChecklistTemplateGroupsTable';

export default {
  name: 'AdminChecklistTemplateGroupsListView',
  components: {
    ChecklistTemplateGroupsTable
  },
  props: {
    templateGroupsData: {
      type: Array,
      required: true
    }
  },
  computed: {
    isAddButtonDisabled() {
      return (
        this.newTemplateGroup.name === '' ||
        this.newTemplateGroup.description === ''
      );
    }
  },
  data() {
    return {
      newTemplateGroup: {
        name: '',
        description: ''
      },
      dialog: {
        newTemplateGroup: false
      }
    };
  },
  methods: {
    ...mapActions('checklistTemplateGroups', ['createChecklistTemplateGroup']),
    async addNewTemplateGroupClicked() {
      try {
        const key = await this.createChecklistTemplateGroup({
          name: this.newTemplateGroup.name,
          description: this.newTemplateGroup.description
        });

        this.$events.$emit(
          'toastEvent',
          'New Checklist Template Group Created'
        );

        this.dialog.newTemplateGroup = false;
        this.goToTemplateGroup(key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    goToTemplateGroup(checklistTemplateGroupKey) {
      this.$router.push({
        name: 'adminChecklistTemplateGroup',
        params: { checklistTemplateGroupKey }
      });
    }
  }
};
</script>

<style scoped></style>
