'use strict';

/**
 * @fileoverview Vuex module for meeting business
 */

import _ from 'lodash';
import Vue from 'vue';

import {
  getMeetingApprovals,
  getMeetingApproval,
  postApproval,
  updateApproval,
  patchSettings,
  postReview,
  deleteUser,
  deletePrintJob,
  putPrintJob,
  deleteNotice,
  putNotice,
  putReviewer,
  closeApproval
} from '@/api/meetings-approvals';

const state = {
  approvals: {},
  // meetingInvoiceList: {},
  dataLastFetchedAt: null
};

const getters = {
  approvals: state => state.approvals,
  approvalsListData: state => Object.values(state.approvals),
  approval: state => arKey => state.approvals[arKey]
};

const actions = {
  /**
   * Get meeting's approvals
   * @param {String} {shortCode} Meeting shortCode
   */
  async getMeetingApprovalsList({ commit }, { shortCode }) {
    try {
      const { data } = await getMeetingApprovals({ shortCode });
      commit('SET_MEETING_APPROVALS_LIST', data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get meeting approval
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   */
  async getMeetingApproval({ commit }, { shortCode, arKey }) {
    try {
      const { data } = await getMeetingApproval({ shortCode, arKey });
      commit('SET_MEETING_APPROVAL_IN_LIST', data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Create a new approval request
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {name}      Approval name
   */
  async createApproval({ dispatch }, { shortCode, name }) {
    try {
      await postApproval({ shortCode, name });
      await dispatch('getMeetingApprovalsList', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update approval request name
   * @param {String} {shortCode}  Meeting shortCode
   * @param {String} {arKey}      ar key
   * @param {Object} {payload}    approval data
   * @param {String} {name}       Approval name
   */
  async updateApproval({ dispatch }, { shortCode, arKey, payload }) {
    try {
      await updateApproval({ shortCode, arKey, payload });
      await dispatch('getMeetingApprovalsList', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Post a review (approve or comment)
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {comment}   Comment
   * @param {String} {action}    the action (approve or comment)
   */
  async submitReview({ dispatch }, { shortCode, arKey, comment, action }) {
    try {
      await postReview({ shortCode, arKey, comment, action });
      dispatch('getMeetingApproval', { shortCode, arKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Upsert approval request settings
   * @param {String} {shortCode}  Meeting shortCode
   * @param {String} {arKey}      ar key
   * @param {Object} {payload}    approval settings
   */
  async upsertSettings({ dispatch }, { shortCode, arKey, payload }) {
    try {
      await patchSettings({ shortCode, arKey, payload });
      await dispatch('getMeetingApprovalsList', { shortCode });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove an owner or review
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {key}       user key to remove
   */
  async removeUser({ dispatch }, { shortCode, arKey, key }) {
    try {
      await deleteUser({ shortCode, arKey, key });
      dispatch('getMeetingApproval', { shortCode, arKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a notice
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {key}       Notice key to add
   */
  async addNotice({ dispatch }, { shortCode, arKey, key }) {
    try {
      await putNotice({ shortCode, arKey, key });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove a notice
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {key}       Notice key to remove
   */
  async remoteNotice({ dispatch }, { shortCode, arKey, key }) {
    try {
      await deleteNotice({ shortCode, arKey, key });
      dispatch('getMeetingApproval', { shortCode, arKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a print job
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {key}       Job key to add
   */
  async addPrintJob({ dispatch }, { shortCode, arKey, key }) {
    try {
      await putPrintJob({ shortCode, arKey, key });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Remove a print job
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {key}       Job key to remove
   */
  async remotePrintJob({ dispatch }, { shortCode, arKey, key }) {
    try {
      await deletePrintJob({ shortCode, arKey, key });
      dispatch('getMeetingApproval', { shortCode, arKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a reviewer
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {userKey}   User key to add (collaborator, account user)
   */
  async addReviewer({ dispatch }, { shortCode, arKey, userKey }) {
    try {
      await putReviewer({ shortCode, arKey, userKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Close an approval
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {comment}   Comment
   */
  async setCloseApproval({ dispatch }, { shortCode, arKey, comment }) {
    try {
      await closeApproval({ shortCode, arKey, comment });
    } catch (err) {
      throw err;
    }
  },

  /**
   * General function to remove a resource
   * @param {String} {shortCode} Meeting shortCode
   * @param {String} {arKey}     Approval key
   * @param {String} {resource}  name of the resource
   * @param {String} {key}      the resource key
   */
  async removeResource({ dispatch }, { shortCode, arKey, resource, key }) {
    try {
      let action;
      let payload = { shortCode, arKey };
      switch (resource) {
        case 'user':
          action = 'removeUser';
          payload = { ...payload, key };
          break;
        case 'notice':
          action = 'remoteNotice';
          payload = { ...payload, key };
          break;
        case 'print':
          action = 'remotePrintJob';
          payload = { ...payload, key };
          break;
        default:
          throw new Error('Invalid resource');
      }

      await dispatch(action, payload);
    } catch (err) {
      console.error('Error: dispatching remove approval sub resource', err);
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_APPROVALS_LIST(state, data) {
    state.approvals = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },
  // // Set all invoices to store
  // SET_MEETING_INVOICE_LIST(state, data) {
  //   state.meetingInvoiceList = _.keyBy(data, 'key');
  //   state.dataLastFetchedAt = new Date();
  // },
  // Set one approval to store
  SET_MEETING_APPROVAL_IN_LIST(state, data) {
    Vue.set(state.approvals, data.key, data);
  },
  // Reset the state
  CLEAR_STATE(state) {
    state.approvals = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
