import http from './requestType';

/**
 * Get meeting billing settings
 *
 * @param  {String} shortCode   shortcode
 * @return {Promise}            Promise
 */
export function getMeetingBilling({ shortCode }) {
  return http._get(`Meetings/${shortCode}/billing`);
}

/**
 * Update meeting billing option
 *
 * @param  {String} shortCode   shortcode
 * @param  {Object} payload     meeting billing setting (default 'enabled')
 * @return {Promise}            Promise
 */
export function updateMeetingBilling({
  shortCode,
  payload = { enabled: true }
}) {
  return http._patch(`Meetings/${shortCode}/billing`, payload);
}

/**
 * Create meeting billing item
 *
 * @param  {String} shortCode   shortcode
 * @param  {Object} payload     meeting billing item
 * @return {Promise}            Promise
 */
export function postMeetingBillingItem({ shortCode, payload }) {
  return http._post(`Meetings/${shortCode}/billing/items`, payload);
}

/**
 * Delete meeting billing item
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} itemKey     item key
 * @return {Promise}            Promise
 */
export function deleteMeetingBillingItem({ shortCode, itemKey }) {
  return http._delete(`Meetings/${shortCode}/billing/items/${itemKey}`);
}

/**
 * Update meeting billing item
 *
 * @param  {String} shortCode   shortcode
 * @param  {String} itemKey     item key
 * @param  {Object} payload     item values
 * @return {Promise}            Promise
 */
export function putMeetingBillingItem({ shortCode, itemKey, payload }) {
  return http._put(`Meetings/${shortCode}/billing/items/${itemKey}`, payload);
}

/**
 * Create invoice for a meeting
 * @param  {String} shortCode           - meeting shortcode
 * @return {Promise}
 */
export function postMeetingInvoice({ shortCode }) {
  return http._post(`Meetings/${shortCode}/billing/invoices`);
}

/**
 * Create invoice for a meeting inside Chargebee
 * @param  {String} shortCode           - meeting shortcode
 * @param  {Object} payload             - payload containing invoice date, invoice items, and print jobs
 * @return {Promise}
 */
export function postChargebeeMeetingInvoice({ shortCode, payload }) {
  return http._post(
    `Meetings/${shortCode}/billing/chargebee-invoices`,
    payload
  );
}
