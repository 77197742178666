'use strict';

import moment from 'moment';

const typeServiceFormat = {
  meeting_agm: 'AGM',
  meeting_sgm: 'SGM',
  meeting_turnover: 'Turnover Meeting',
  meeting_requisition: 'Requisition Meeting',
  meeting_reconvene: 'Reconvene Meeting',
  certs_icu: 'ICU',
  certs_pic: 'PIC',
  custom_consent: 'Consent Campaign',
  custom_survey: 'Survey',
  custom: 'CUSTOM'
};

/**
 * Generates a meeting's name based on the event being created
 *
 * @param  {Object} meeting the meeting
 * @return {String}         the meeting name
 */
export default function generateMeetingName(meeting) {
  let {
    selectedCorp,
    type,
    typeService,
    subtype,
    meetingDate,
    customType
  } = meeting;
  let meetingName = '';

  type = type === 'other' ? customType : type;
  typeService =
    type !== 'budget' ? typeServiceFormat[typeService] : type.toUpperCase();

  const meetingYear = moment(meetingDate).year();

  // prepend 'Virtual' to meeting name, if Virtual AGM
  if (type === 'meeting' && subtype === 'Virtual') {
    meetingName = `${selectedCorp.nameShort} - ${subtype} ${typeService} ${meetingYear}`;
  } else if (type === 'meeting' && subtype === 'Hybrid') {
    meetingName = `${selectedCorp.nameShort} - Virtual ${typeService} ${meetingYear} (${subtype})`;
  } else {
    meetingName = `${selectedCorp.nameShort} - ${typeService} ${meetingYear}`;
  }

  return meetingName;
}
