export const regionObjList = [
  { name: 'Algoma Condominium Corporation No.', nameShort: 'ACC' },
  { name: 'Brant Condominium Corporation No.', nameShort: 'BCC' },
  {
    name: 'Brant Common Element Condominium Corporation No.',
    nameShort: 'BCECC'
  },
  { name: 'Brant Standard Condominium Corporation No.', nameShort: 'BSCC' },
  { name: 'Carleton Condominium Corporation No.', nameShort: 'CCC' },
  { name: 'Dufferin Condominium Corporation No.', nameShort: 'DCC' },
  {
    name: 'Durham Common Element Condominium Corporation No.',
    nameShort: 'DCECC'
  },
  { name: 'Durham Standard Condominium Corporation No.', nameShort: 'DSCC' },
  { name: 'Elgin Condominium Corporation No.', nameShort: 'ECC' },
  { name: 'Essex Standard Condominium Corporation No.', nameShort: 'ESCC' },
  { name: 'Glengarry Condominium Corporation No.', nameShort: 'GCC' },
  {
    name: 'Grey Common Element Condominium Corporation No.',
    nameShort: 'GCECC'
  },
  { name: 'Grey Standard Condominium Corporation No.', nameShort: 'GSCC' },
  { name: 'Halton Condominium Corporation No.', nameShort: 'HCC' },
  { name: 'Halton Standard Condominium Corporation No.', nameShort: 'HSCC' },
  {
    name: 'Halton Common Element Condominium Corporation No.',
    nameShort: 'HCECC'
  },
  {
    name: 'Halton Vacant Land Condominium Corporation No.',
    nameShort: 'HVLCC'
  },
  { name: 'Kenora Condominium Corporation No.', nameShort: 'KCC' },
  { name: 'Lambton Condominium Corporation No.', nameShort: 'LCC' },
  { name: 'Lambton Standard Condominium Corporation No.', nameShort: 'LSCC' },
  { name: 'Lanark North Condominium Corporation No.', nameShort: 'LNCC' },
  {
    name: 'Metropolitan Toronto Condominium Corporation No.',
    nameShort: 'MTCC'
  },
  { name: 'Middlesex Condominium Corporation No.', nameShort: 'MCC' },
  { name: 'Middlesex Standard Condominium Corporation No.', nameShort: 'MSCC' },
  {
    name: 'Middlesex Vacant Land Condominium Corporation No.',
    nameShort: 'MVLCC'
  },
  { name: 'Newcastle Condominium Corporation No.', nameShort: 'NCC' },
  { name: 'Niagara North Condominium Corporation No.', nameShort: 'NNCC' },
  {
    name: 'Niagara North Common Element Condominium Corporation No.',
    nameShort: 'NNECC'
  },
  {
    name: 'Niagara North Standard Condominium Corporation No.',
    nameShort: 'NNSCC'
  },
  { name: 'Niagara Standard Condominium Corporation No.', nameShort: 'NSCC' },
  {
    name: 'Norfolk Common Element Condominium Corporation No.',
    nameShort: 'NCECC'
  },
  {
    name: 'Northumberland East Condominium Corporation No.',
    nameShort: 'NECC'
  },
  { name: 'Ontario Condominium Corporation No.', nameShort: 'OCC' },
  {
    name: 'Ottawa-Carleton Standard Condominium Corporation No.',
    nameShort: 'OSCC'
  },
  {
    name: 'Oxford Common Element Condominium Corporation No.',
    nameShort: 'OCECC'
  },
  { name: 'Peel Standard Condominium Corporation No.', nameShort: 'PSCC' },
  { name: 'Peel Condominium Corporation No.', nameShort: 'PCC' },
  {
    name: 'Peel Common Element Condominium Corporation No.',
    nameShort: 'PCECC'
  },
  { name: 'Peel Vacant Land Condominium Corporation No.', nameShort: 'PVLCC' },
  { name: 'Prince Edward Condominium Corporation No.', nameShort: 'PECC' },
  { name: 'Renfrew Condominium Corporation No.', nameShort: 'RCC' },
  { name: 'Russell Standard Condominium Corporation No.', nameShort: 'RSCC' },
  { name: 'Simcoe Condominium Corporation No.', nameShort: 'SCC' },
  { name: 'Simcoe County Condominium Corporation No.', nameShort: 'SCCC' },
  {
    name: 'Simcoe Common Element Condominium Corporation No.',
    nameShort: 'SCECC'
  },
  { name: 'Simcoe Standard Condominium Corporation No.', nameShort: 'SSCC' },
  { name: 'Thunder Bay Condominium Corporation No.', nameShort: 'TBCC' },
  {
    name: 'Thunder Bay Standard Condominium Corporation No.',
    nameShort: 'TBSCC'
  },
  { name: 'Toronto Condominium Corporation No.', nameShort: 'TCC' },
  {
    name: 'Toronto Common Element Condominium Corporation No.',
    nameShort: 'TCECC'
  },
  { name: 'Toronto Standard Condominium Corporation No.', nameShort: 'TSCC' },
  { name: 'Victoria Condominium Corporation No.', nameShort: 'VCC' },
  { name: 'Victoria Standard Condominium Corporation No.', nameShort: 'VSCC' },
  { name: 'Waterloo Condominium Corporation No.', nameShort: 'WCC' },
  {
    name: 'Waterloo Common Element Condominium Corporation No.',
    nameShort: 'WCECC'
  },
  { name: 'Waterloo North Condominium Corporation No.', nameShort: 'WNCC' },
  { name: 'Waterloo Standard Condominium Corporation No.', nameShort: 'WSCC' },
  {
    name: 'Wellington Vacant Land Condominium Corporation No.',
    nameShort: 'WVLCC'
  },
  { name: 'York Condominium Corporation No.', nameShort: 'YCC' },
  { name: 'York North Condominium Corporation No.', nameShort: 'YNCC' },
  { name: 'York Region Condominium Corporation No.', nameShort: 'YRCC' },
  {
    name: 'York Region Common Element Condominium Corporation No.',
    nameShort: 'YRCECC'
  },
  {
    name: 'York Region Standard Condominium Corporation No.',
    nameShort: 'YRSCC'
  },
  {
    name: 'York Region Vacant Land Condominium Corporation No.',
    nameShort: 'YRVLCC'
  }
];

export const pm = [
  '360 Community Management',
  'AA Property Management',
  'Affordable Property Management',
  'Akelius Real Estate Management',
  'Andrejs Management',
  'Aptco Capital Corporation',
  'Arden Management Services',
  'Artscape Property Management',
  'Association Concepts ',
  'Atrens Management Group',
  'Aykler Management',
  'Balance Property Management',
  'Bay Brook',
  'Benvenuto Residences Inc',
  'Berard Property Management',
  'Berkley Property Management',
  'BF Property Management Ltd.',
  'Bonita Management',
  'Briarlane Condominium Property Management',
  'Brilliant Property Management',
  'Burnleigh Property Management',
  'BV Property Management',
  'CABER MANAGEMENT SERVICES INC.',
  'Canderel Property Management',
  'Canlight Hall Management',
  'Cape Property Management',
  'Capitalink Property Management Ltd.',
  'Centurion Property Associates Inc',
  'Channel Property Management',
  'City Sites Property Management',
  'CityTowers Property Management',
  'Clp Property Management',
  'Comfield Property Management',
  'Comfort Property Management',
  'Condo Solutions',
  'Condominium Living Management',
  'Condominium Solutions Management',
  'Conkrite Capital',
  'Connium Management',
  'Cpo/Canadian Properties Operator Management Inc.',
  'Crespro Management',
  'Crossbridge Condominium Services',
  'Daniels Home Management',
  'Datsun Property Management',
  'Dba Property Management Ltd',
  'Del Property Management',
  'Designers Walk Inc',
  'DJS Management',
  'Dove Square Management',
  'Dowing Property Management',
  'Duka Property Management',
  'Dynamic Property Management Inc.',
  'E.A.Taylor Management',
  'Eastway Property Management',
  'Eco Condo Management',
  'Elite Property Management',
  'Equity Hill Properties Group',
  'Esbin Property Management',
  'Fidelity Property Management',
  'FirstService Residential',
  'Fit Property Management',
  'Four Seasons Private Residences',
  'Gatehouse Properties',
  'Gatsby Management',
  'General Property Management',
  'Glendale Properties Inc.',
  'Golden Property Management',
  'Goldview Property Management',
  'Gpm Property Management',
  'Grenadier Property Management',
  'Gsa Property Management',
  'Harris Property Management',
  'Hearthstone Property Management',
  'Horizon Property Management',
  'Icc Property Management',
  'Icon Property Management',
  'Jtb Management Group Inc',
  'Kindle Property Management',
  'L & H Property Management',
  'Larlyn Property Management',
  'Lexington Property Management',
  'Living Properties',
  'Malvern Condominium Property Management',
  'Manor Crest Management Ltd',
  'Maple Ridge Community Management',
  'Mareka Property Management',
  'Markham Glen',
  'Mea Property Management',
  'Menres Property Management',
  'Meritus Group Management',
  'Metropolis Group Management',
  'MF Properties Ltd',
  'Minto Property Management',
  'Mizrahi Developments Inc.',
  'Nadlan-Harris Property Management',
  'Near North Services',
  'Networks Property Management',
  'New City Property Management',
  'Newton-Trewlany Property Management',
  'Northcan Property Management',
  'Olaya Management',
  'Online Property Management',
  'Orion Management',
  'Pal/Max Property Management',
  'Pearlhouse Management',
  'Peer Property Management',
  'Percel Property Management',
  'Philmore Group Ltd',
  'Pro-House Management Ltd',
  'RAB Property Management',
  'Ram-Land Property Management',
  'Remington Facilities Management',
  'Ritz Carlton Property Management',
  'Royale Grande Property Management',
  'Samuel Property Management',
  'Sayland Property Management',
  'Self Managed',
  'Shelter Property Management',
  'Sherwood Park Management',
  'Shiu Pong Management',
  'Simsons Management',
  'Skywater Property Management',
  'Solotex Property Management',
  'St George Property Management',
  'Sterling Property Management',
  'Strategic Property Management',
  'Summa Property Management',
  'Summerhill Property Management',
  'Taft Forward Property Management',
  'Taylor Property Management',
  'Terrawood Property Management',
  'Times Property Management',
  'Tony Kung & Associates',
  'Trigate Property Management',
  'Trinity Property Services',
  'Trump Toronto Hotel Management Corp',
  'TrustLink Property Management',
  'Tse Management',
  'Vero Property Management',
  'Vista Property Management',
  'Wallace Rivard & Associates',
  'Whitestone Property Management',
  'Wilson Blanchard Property Management',
  'Wise Management Inc.',
  'Woroz Management Inc',
  'YL Hendler Ltd.',
  'Zoran Properties'
];

export const neighbourhood = [
  'York University Heights',
  'The Core',
  'St. Lawrence',
  'CityPlace',
  'The Waterfront',
  'Newtonbrook West',
  'Liberty Village',
  'Bayview Village',
  'Grange Park',
  'Bay St. Corridor',
  'Cabbagetown',
  'Church St. Corridor',
  'Henry Farm',
  'King West',
  'High Park North',
  'Kingsview Village | The Westway',
  'Fort York',
  'Yonge and Bloor',
  'Rockcliffe-Smythe',
  'Mimico',
  'Yorkdale | Glen Park',
  'Don Valley Village',
  'Islington | City Centre West',
  'Little Portugal',
  'Agincourt South-Malvern West',
  'Wexford-Maryvale',
  'Mount Pleasant West',
  'South Parkdale',
  'Willowdale East',
  'Flemingdon Park',
  'Yorkville',
  'Bendale',
  'Lansing-Westgate',
  'Woburn',
  'High Park-Swansea',
  'Moss Park',
  'Clanton Park',
  'Markland Wood',
  'board',
  'West Humber | Clairville',
  'L`Amoreaux',
  'Willowdale West',
  'Stonegate-Queensway',
  'St. James Town',
  'Malvern',
  'Queen West',
  'Banbury | Don Mills',
  'Corktown',
  'Eringate | Centennial | West Deane',
  'Rouge E11',
  'Englemount | Lawrence',
  'Long Branch',
  'Weston',
  'Regent Park',
  'Yonge-St. Clair',
  'Newtonbrook East',
  'Distillery District',
  'Parkwoods-Donalda',
  'Thorncliffe Park',
  'Yonge-Eglinton',
  'Briar Hill-Belgravia',
  'Kennedy Park',
  'Mount Dennis',
  'Scarborough Village',
  'Agincourt North',
  'Lawrence Park South',
  'Eglinton East',
  'Morningside',
  'Steeles',
  'Dorset Park',
  'Junction Area',
  'Brookhaven-Amesbury',
  'Tam O`Shanter | Sullivan',
  'South Riverdale | Leslieville',
  'Etobicoke West Mall',
  'Dovercourt-Wallace Emerson-Junction',
  'Black Creek',
  'Edenbridge | Humber Valley',
  'Casa Loma',
  'Milliken',
  'Rosedale-Moore Park',
  'West Hill',
  'East End-Danforth',
  'Mount Olive | Silverstone | Jamestown',
  'Westminster | Branson',
  'Annex | U of T',
  'Humber Heights | Westmount',
  'Elms | Old Rexdale',
  'Humbermede',
  'Birchcliffe-Cliffside',
  'Downsview | Roding | CFB',
  'Dufferin Grove',
  'Hillcrest Village',
  'Bridle Path | Sunnybrook | York Mills',
  'Kensington Market',
  'Oakwood-Vaughan',
  'Pleasant View',
  'Humberlea-Pelmo Park W5',
  'Victoria Village',
  'Kingsway South',
  'Oakridge',
  'Guildwood',
  'Broadview North',
  'Clairlea | Birchmount',
  'Princess-Rosethorn',
  'New Toronto',
  'St. Andrew | Windfields',
  'Keelesdale-Eglinton West',
  'Leaside',
  'Alexandra Park',
  'Forest Hill South',
  'Bayview Woods-Steeles',
  'O`Connor-Parkview',
  'Glenfield-Jane Heights',
  'Bedford Park-Nortown',
  'Roncesvalles',
  'Humber Summit',
  'Woodbine Corridor',
  'Maple Leaf',
  'Willowridge | Martingrove | Richview',
  'Bathurst Manor',
  'Beechborough-Greenbrook',
  'Wychwood',
  'Little Italy',
  'Danforth Village-East York',
  'The Beaches',
  'Humewood-Cedarvale',
  'Ionview',
  'Forest Hill North',
  'Greenwood-Coxwell',
  'Mount Pleasant East',
  'Trinity Bellwoods',
  'Lawrence Park North',
  'Weston-Pellam Park',
  'Alderwood',
  'North Riverdale',
  'Rouge E10',
  'Woodbine-Lumsden',
  'Cliffcrest',
  'Rustic',
  'Centennial Scarborough',
  'Blake-Jones',
  'Chinatown',
  'Runnymede-Bloor West Village'
];
