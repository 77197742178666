'use strict';

/**
 * @fileoverview Vuex module for feature flags
 */

const state = {
  // Experimental mode feature flags
  featureFlags: {
    // Use in-app chat widget
    experimentalMode: false
  }
};

const getters = {
  featureFlags: state => state.featureFlags,
  experimentalMode: state => state.featureFlags.experimentalMode
};

const actions = {
  /**
   * Loads feature flags from localStorage
   * @param {String}  {shortCode}   optional eeting shortCode
   */
  loadFeatureFlags({ commit, dispatch }, { shortCode }) {
    let featureFlags = localStorage.getItem('featureFlags');

    if (featureFlags) {
      featureFlags = JSON.parse(featureFlags);
      console.log('Loading feature flags', featureFlags);
      dispatch('setFeatureFlags', featureFlags);
    }
  },

  setFeatureFlags({ commit }, flags) {
    commit('SET_FEATURE_FLAGS', flags);
  }
};

const mutations = {
  SET_FEATURE_FLAGS(state, featureFlags) {
    state.featureFlags = featureFlags;
    localStorage.setItem('featureFlags', JSON.stringify(featureFlags));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
