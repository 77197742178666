<template>
  <v-layout column>
    <v-toolbar dark dense color="grey lighten-4 elevation-1">
      <v-text-field
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
      <v-spacer />
      <v-btn class="primary" dark @click.native="emitOpenAddDialog">
        <v-icon left>add_box</v-icon>Checklist
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="state"
      :search="search"
      :rows-per-page-items="config.rowsPerPageItems"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr @click="rowClicked(props.item.id)">
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.stage }}</td>
          <td class="text-xs-left">
            {{ props.item.items ? props.item.items.length : 0 }}
          </td>
          <td class="text-xs-left">
            {{ formatDeadline(props.item.deadline, 'deadline') }}
          </td>
          <td class="text-xs-left">
            {{ formatDeadline(props.item.legalDeadline, 'legalDeadline') }}
          </td>
          <td class="text-xs-left">{{ props.item.updatedAt | dateFromNow }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import * as filters from '@/filters';

export default {
  name: 'AdminChecklistTemplatesTable',
  filters: {
    ...filters
  },
  props: {
    state: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Stage', value: 'stage', align: 'left' },
        { text: 'Items', value: 'numItems', align: 'left' },
        { text: 'Deadline', value: 'deadline', align: 'left' },
        { text: 'Legal Deadline', value: 'legalDeadline', align: 'left' },
        { text: 'Updated', value: 'updatedAt', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },
  methods: {
    emitOpenAddDialog() {
      this.$emit('open-add-new-dialog');
    },
    rowClicked(id) {
      this.$emit('row-clicked', id);
    },
    formatDeadline(deadline, type) {
      if (!deadline && type === 'deadline') {
        return 'Current date (today)';
      }

      if (!deadline && type === 'legalDeadline') {
        return '-';
      }

      if (deadline > 0) {
        return deadline + ' day(s) before meeting';
      }

      if (parseInt(deadline) === 0) {
        return 'Day of meeting';
      }

      return Math.abs(deadline) + ' day(s) after meeting';
    }
  }
};
</script>

<style scoped></style>
