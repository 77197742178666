<template>
  <div>
    <v-layout row wrap class="ml-3 mr-3 mb-0">
      <v-flex v-bind="{ [calculateBreakpoint]: true }">
        <v-card>
          <v-card-text>
            <v-layout row>
              <v-flex xs4>
                <span class="display-1">{{ stats.sentInvoices }}</span>
                <br />
                <span class="caption">Issued</span>
              </v-flex>
              <v-flex xs4>
                <span class="display-1">{{ stats.unpaidInvoices }}</span>
                <br />
                <span class="caption">Unpaid</span>
              </v-flex>
              <v-flex xs4>
                <span class="display-1">{{ stats.draftInvoices }}</span>
                <br />
                <span class="caption">Draft</span>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex v-bind="{ [calculateBreakpoint]: true }">
        <v-card>
          <v-card-title>
            <div class="">
              <span class="display-1">${{ totalUnpaid | formatValue }}</span>
              <br />
              <span class="caption">Total Amount Outstanding</span>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>

      <v-flex v-bind="{ [calculateBreakpoint]: true }">
        <v-card class="cardtest">
          <v-card-title class="cardtitle">
            <div class="">
              <span class="display-1">${{ totalPaid | formatValue }}</span>
              <br />
              <span class="caption">Total Amount Paid</span>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>

      <v-flex v-bind="{ [calculateBreakpoint]: true }">
        <v-card class="cardtest">
          <v-card-title>
            <div class="">
              <span class="display-1">${{ totalInvoiced | formatValue }}</span>
              <br />
              <span class="caption">Total Amount Invoiced</span>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row nowrap class="ml-3 mr-3 mt-0">
      <v-flex xs12>
        <p class="text-xs-right">
          Stats Updated: {{ stats.timestamp | dateFromNow }}
          <v-btn
            flat
            icon
            @click.native="refreshStats(true)"
            :loading="refreshing"
            color="secondary"
          >
            <v-icon>cached</v-icon>
          </v-btn>
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatCurrencyValue } from '@/helpers';
import { dateFromNow } from '@/filters';

export default {
  props: {},
  filters: {
    dateFromNow,
    formatValue: formatCurrencyValue
  },
  data() {
    return {
      refreshing: false
    };
  },
  computed: {
    ...mapGetters('invoices', ['stats']),
    totalPaid() {
      return this.stats.amountPaid;
    },
    totalUnpaid() {
      return this.stats.amountOutstanding;
    },
    totalInvoiced() {
      return this.stats.amountInvoiced;
    },
    // Custom breakpoint to prevent large number overflow
    calculateBreakpoint() {
      const breakpoint = this.$vuetify.breakpoint;
      switch (true) {
        case breakpoint.width > 1660:
          return 'lg3';
        case breakpoint.width <= 1660 && breakpoint.name == 'lg':
          return 'lg6';
        case breakpoint.name == 'md':
          return 'md6';
        case breakpoint.name == 'xs':
          return 'xs12';
      }
    }
  },
  mounted() {
    this.refreshStats(false);
  },
  methods: {
    ...mapActions('invoices', ['getInvoiceStats']),
    async refreshStats(clearCache = false) {
      try {
        this.refreshing = true;
        // Force stats call with cache clear
        await this.getInvoiceStats(clearCache);
      } catch (err) {
        throw err;
      } finally {
        this.refreshing = false;
      }
    }
  }
};
</script>

<style scoped></style>
