'use strict';

/**
 * @fileoverview Meeting Ballots Vuex submodule.
 * Used to store state for the meeting's ballot data
 */

import _ from 'lodash';
import Vue from 'vue';
import * as ballotApi from '@/api/meetings-ballots';

const state = {
  meetingBallotsList: {},
  meetingBallotUnitList: {},
  meetingBallotStatus: {},
  meetingBallotLink: null,
  dataLastFetchedAt: null,
  searchQuery: ''
};

const getters = {
  meetingBallotsList: state => state.meetingBallotsList,
  meetingBallotsListData: state => Object.values(state.meetingBallotsList),
  meetingBallotStatusData: state => state.meetingBallotStatus,
  meetingBallotLink: state => state.meetingBallotLink,
  meetingBallotUnitList: state => state.meetingBallotUnitList,
  meetingBallotUnitListData: state =>
    Object.values(state.meetingBallotUnitList),
  searchQuery: state => state.searchQuery
};

const actions = {
  /**
   * Get the meeting's current list of ballots
   * @param {String} {shortCode} the meeting
   */
  async getMeetingBallots({ commit }, { shortCode }) {
    try {
      const res = await ballotApi.getMeetingsBallotList(shortCode);
      return commit('SET_CURRENT_MEETING_BALLOTS_LIST', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingBallots action', err);
      throw err;
    }
  },

  /**
   * Get the ballot status for this particular email (could be an owner or proxy)
   * @param {String} {shortCode}  the meeting
   * @param {String} {email}      voter email
   */
  async getMeetingBallotStatus({ commit }, { shortCode, email }) {
    try {
      commit('CLEAR_STATE_UNITS', this.state);
      const res = await ballotApi.getMeetingBallotStatus(shortCode, email);
      return commit('SET_CURRENT_MEETING_BALLOT_STATUS', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingBallotStatus action', err);
      throw err;
    }
  },

  /**
   * Get the list of eligible units for this ballot voter
   * @param {String} {shortCode} the meeting
   * @param {String} {ballotKey} ballot key
   */
  async getMeetingBallotUnits({ commit }, { shortCode, ballotKey }) {
    try {
      const res = await ballotApi.getMeetingBallotUnitList(
        shortCode,
        ballotKey
      );
      return commit('SET_CURRENT_MEETING_BALLOT_UNIT_LIST', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingBallotUnits action', err);
      throw err;
    }
  },

  /**
   * Get the ballot link for this voter
   * @param {String} {shortCode}  the meeting
   * @param {String} {email}      the voter email
   */
  async getMeetingBallotLink({ commit }, { shortCode, email }) {
    try {
      const res = await ballotApi.getMeetingVoterBallotLink(shortCode, email);
      return commit('SET_CURRENT_MEETING_BALLOT_LINK', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingBallotLink action', err);
      throw err;
    }
  },

  /**
   * Delete single ballot by key
   * @param {String} {shortCode} the meeting
   * @param {String} {ballotKey} the ballot key
   */

  async deleteMeetingBallot({ dispatch }, { shortCode, ballotKey }) {
    try {
      const res = await ballotApi.deleteMeetingBallot(shortCode, ballotKey);
      dispatch('getMeetingBallots', { shortCode });
      return res.data;
    } catch (err) {
      console.error('ERROR: deleteMeetingBallot action', err);
      throw err;
    }
  },

  /**
   * Add unit claim to ballot
   * @param {String} {shortCode}  the meeting
   * @param {String} {email}      the voter email
   * @param {String} {ballotKey}  the ballot key
   * @param {String} {unitKey}    the unit key
   */

  async addMeetingBallotUnit(
    { dispatch },
    { shortCode, email, ballotKey, unitKey }
  ) {
    try {
      const res = await ballotApi.addMeetingBallotUnit(
        shortCode,
        ballotKey,
        unitKey
      );
      dispatch('getMeetingBallots', { shortCode });
      dispatch('getMeetingBallotStatus', { shortCode, email });
      dispatch('getMeetingBallotUnits', { shortCode, ballotKey });
      return res.data;
    } catch (err) {
      console.error('ERROR: addMeetingBallotUnit action', err);
      throw err;
    }
  },

  /**
   * Remove unit claim from ballot
   * @param {String} {shortCode}  the meeting
   * @param {String} {email}      the voter email
   * @param {String} {ballotKey}  the ballot key
   * @param {String} {unitKey}    the unit key
   */

  async deleteMeetingBallotUnit(
    { dispatch },
    { shortCode, email, ballotKey, unitKey }
  ) {
    try {
      const res = await ballotApi.deleteMeetingBallotUnit(
        shortCode,
        ballotKey,
        unitKey
      );
      dispatch('getMeetingBallots', { shortCode });
      dispatch('getMeetingBallotStatus', { shortCode, email });
      dispatch('getMeetingBallotUnits', { shortCode, ballotKey });
      return res.data;
    } catch (err) {
      console.error('ERROR: deleteMeetingBallotUnit action', err);
      throw err;
    }
  },

  /**
   * Delete selected ballot(s)
   * @param {String} {shortCode} the meeting
   * @param {Array}  {ballotKeys} the array of ballotKeys to delete
   */

  deleteMeetingBallotKeys({ dispatch }, { shortCode, ballotKeys }) {
    return ballotApi
      .deleteMeetingBallotKeys(shortCode, {
        ballotKeys
      })
      .then(() => dispatch('getMeetingBallots', { shortCode }));
  },

  /**
   * Add a new ballot
   * @param {String} {shortCode} the meeting
   * @param {Object} {newBallot} new ballot data
   */
  async createMeetingBallot({ dispatch }, { shortCode, newBallot }) {
    try {
      const res = await ballotApi.postMeetingsBallot(shortCode, newBallot);
      dispatch('getMeetingBallots', { shortCode });
      return res.data;
    } catch (err) {
      console.error('ERROR: createMeetingBallot action', err);
      throw err;
    }
  },

  /**
   * Send selected ballot(s)
   * @param {String} {shortCode} the meeting
   * @param {Array}  {voters} the array of voters to send the ballot email
   */
  sendBallotEmails({ dispatch }, { shortCode, voters }) {
    try {
      return ballotApi
        .sendBallotEmails(shortCode, {
          voters
        })
        .then(() => dispatch('getMeetingBallots', { shortCode }));
    } catch (err) {
      console.error('ERROR: sendBallotEmails action', err);
      throw err;
    }
  },

  setSearchQuery({ commit }, searchQuery) {
    return commit('SET_BALLOT_SEARCH_QUERY', searchQuery);
  }
};

const mutations = {
  SET_CURRENT_MEETING_BALLOTS_LIST(state, dataArray) {
    state.meetingBallotsList = _.keyBy(dataArray, 'id');
    state.dataLastFetchedAt = new Date();
  },
  SET_CURRENT_MEETING_BALLOT_UNIT_LIST(state, dataArray) {
    state.meetingBallotUnitList = _.keyBy(dataArray, 'unitKey');
  },
  SET_CURRENT_MEETING_BALLOT_STATUS(state, ballotStatus) {
    state.meetingBallotStatus = ballotStatus;
  },
  SET_CURRENT_MEETING_BALLOT_LINK(state, results) {
    state.meetingBallotLink = results.voterLink;
  },
  // Reset the state
  CLEAR_STATE(state) {
    state.meetingBallotsList = {};
    state.meetingBallotUnitList = {};
    state.meetingBallotStatus = {};
    state.meetingBallotLink = null;
    state.dataLastFetchedAt = null;
  },
  CLEAR_STATE_UNITS(state) {
    state.meetingBallotUnitList = {};
  },

  SET_BALLOT_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
