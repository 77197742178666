<template>
  <v-dialog max-width="550px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title"> Edit Your Meeting </span>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-layout row wrap>
          <v-flex xs12>
            <h5 class="subheading pt-3">
              Meeting Time Zone
            </h5>
            <p class="grey--text text--darken-2">
              Set the time zone for all dates in this meeting.
            </p>
            <v-select
              :items="timezones"
              item-text="text"
              item-value="value"
              v-model="timezone"
              solo
              label="Timezones"
              @change="onChange('timezone', $event)"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              prepend-icon="subject"
              label="Meeting name"
              :value="meetingName"
              :rules="rules.name"
              @input="onChange('name', $event)"
            />
          </v-flex>
          <v-flex xs12>
            <div>
              <date-time-pickers
                label="Date of meeting"
                :timezone="timezone"
                :iso-date="date"
                @update="onChange('date', $event)"
              />
              <v-text-field
                prepend-icon="watch"
                label="Duration"
                :value="duration"
                type="number"
                suffix="minutes"
                min="0"
                max="360"
                :rules="rules.duration"
                @input="onChange('duration', $event)"
              />
            </div>
          </v-flex>
        </v-layout>
        <v-slide-y-transition>
          <v-alert
            :value="isChanged"
            color="warning"
            icon="priority_high"
            outline
          >
            If your meeting date has changed, please re-send your invitation
            email
          </v-alert>
        </v-slide-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-btn
          flat
          color="error"
          class="ma-0"
          @click="cancelMeetingDialog = true"
          @close="cancelMeetingDialog = false"
        >
          Cancel meeting
        </v-btn>

        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!isChanged"
          :loading="saving"
          class="ma-0 mr-2 pa-0"
          @click="onSave"
        >
          Save
        </v-btn>
        <v-btn flat color="error" class="ma-0 pa-0" @click.stop="emitClose">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="cancelMeetingDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <div class="title red--text text--darken-1">Are you sure?</div>
        </v-card-title>
        <v-card-text>
          <v-alert outline color="error" icon="warning" :value="true">
            <strong>WARNING</strong>: Once cancelled, you will not be able to
            access this meeting and its data again later.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="cancelMeetingDialog = false">close</v-btn>
          <v-btn class="error" @click="onCancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import timezones from '@/lib/timezones';
import moment from 'moment-timezone';
import _ from 'lodash';

import DateTimePickers from '@/components/DateTimePicker';

export default {
  name: 'EditYourMeetingDialog',
  components: {
    DateTimePickers
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    meetingName: {
      type: String,
      required: true
    },
    meetingDate: {
      type: String,
      required: true
    },
    meetingDuration: {
      type: Number,
      default: 0
    },
    meetingTimezone: {
      type: String,
      default: 'America/Toronto'
    },
    shortCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date: null,
      duration: 0,
      timezone: null,
      name: null,
      timezones,
      saving: false,
      cancelMeetingDialog: false,
      rules: {
        duration: [
          v => !!v || 'Duration is required',
          v =>
            (!isNaN(parseFloat(v)) && v >= 0 && v <= 360) ||
            'The maximum allowed duration is 360 minutes (6 hours)'
        ],
        name: [v => !!v || 'Meeting name is required']
      }
    };
  },
  watch: {
    meetingDate() {
      this.setState();
    },
    meetingDuration() {
      this.setState();
    },
    meetingTimezone() {
      this.setState();
    },
    meetingName() {
      this.setState();
    }
  },
  computed: {
    isChanged() {
      let state = {
        duration: this.duration,
        date: this.date,
        timezone: this.timezone,
        name: this.name
      };
      let current = {
        duration: this.meetingDuration,
        date: this.meetingDate,
        timezone: this.meetingTimezone,
        name: this.meetingName
      };
      return !_.isEqual(state, current);
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('v2/meetings', ['updateMeeting']),
    ...mapActions('meetings/webcast', ['updateWebcastWebinar']),
    ...mapActions('meetings', ['setVBMStatus', 'getMeetingListData']),
    setState() {
      this.date = this.meetingDate;
      this.duration = this.meetingDuration;
      this.timezone = this.meetingTimezone;
      this.name = this.meetingName;
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    onChange(key, value) {
      _.set(this, key, value);
    },
    async onSave() {
      try {
        this.saving = true;
        const isSame = moment(this.meetingDate).isSame(moment(this.date));
        const isSameDuration = this.meetingDuration === this.duration;
        //If meeting date or duration is changed, update the webcast as well
        const mustUpdateWebcast = !isSame || !isSameDuration;
        if (mustUpdateWebcast) {
          await this.updateWebCast();
        }
        const updates = {
          duration: this.duration,
          meetingDate: this.date,
          meetingTimezone: this.timezone,
          name: this.name
        };
        await this.updateMeeting({
          shortCode: this.shortCode,
          updates
        });

        this.$events.$emit('toastEvent', 'Meeting updated');
        this.emitClose();
      } catch (err) {
        //Update did not go through, restore original state.
        this.setState();
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    },

    async updateWebCast() {
      try {
        const momentDate = moment(this.date).tz(this.timezone);
        await this.updateWebcastWebinar({
          shortCode: this.shortCode,
          payload: {
            startTime: momentDate.format('YYYY-MM-DD[T]HH:mm:ss'),
            timezone: this.timezone,
            duration: this.duration
          }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        throw err;
      }
    },

    async onCancel() {
      try {
        await this.setVBMStatus({
          shortCode: this.shortCode,
          status: 'cancelled',
          type: 'vbm'
        });

        await this.getMeetingListData({
          dateRangeStart: moment()
            .add(-2, 'days')
            .format('YYYY-MM-DD'),
          limit: 15,
          isInitial: false
        });
        this.$router.push({ name: 'meetings' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        throw err;
      }
    }
  }
};
</script>

<style scoped></style>
