<template>
  <div>
    <p class="subheading my-3">
      Is there a CRM Deal for this meeting?
    </p>

    <!-- meeting crm deal -->
    <v-radio-group
      name="meeting-crm-deal"
      :value="meetingProof.crmDeal"
      @change="handleUpdate({ key: 'meetingProof.crmDeal', value: $event })"
    >
      <v-radio label="Yes" :value="true" />
      <v-radio label="No" :value="false" />
    </v-radio-group>

    <v-text-field
      v-if="meetingProof.crmDeal"
      label="URL"
      placeholder="URL to CRM Deal"
      :value="meetingProof.crmDealUrl"
      @input="handleUpdate({ key: 'meetingProof.crmDealUrl', value: $event })"
    />

    <p class="subheading my-3">
      What proof do you have that the customer confirmed their request for this
      service?
    </p>

    <!-- meeting proof of request -->
    <v-radio-group
      name="meeting-proof"
      :value="meetingProof.type"
      @change="handleUpdate({ key: 'meetingProof.type', value: $event })"
    >
      <v-radio
        v-for="proof in proofTypes"
        :label="proof.label"
        :value="proof.value"
        :key="proof.label"
      />
    </v-radio-group>

    <MeetingProofUploader
      v-if="meetingProof.type === 'signedAgreement'"
      @update="handleUpdate"
    />

    <v-list two-line v-if="showFile && !isMultipleAgreements">
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon large color="blue">insert_drive_file</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ meetingProof.value.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{
            meetingProof.value.size | formatSize
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn flat color="red" @click.native="removeFile">delete</v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-list two-line v-if="showFile && isMultipleAgreements">
      <v-list-tile
        v-for="agreement in meetingProof.value"
        :key="agreement.name"
      >
        <v-list-tile-avatar>
          <v-icon large color="blue">insert_drive_file</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ agreement.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{
            agreement.size | formatSize
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn flat color="red" @click.native="removeFile(agreement)"
            >delete</v-btn
          >
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-text-field
      v-if="['emailExchange', 'noProofRequired'].includes(meetingProof.type)"
      :label="getInputLabel()"
      :placeholder="getInputPlaceholder()"
      :value="meetingProof.value"
      @input="handleUpdate({ key: 'meetingProof.value', value: $event })"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import filesize from 'filesize';
import MeetingProofUploader from './MeetingProofUploader.vue';

export default {
  name: 'MeetingRequestProof',
  components: {
    MeetingProofUploader
  },
  filters: {
    formatSize(value) {
      if (value) {
        return filesize(value, { base: 10 });
      }
      return '';
    }
  },
  props: {
    meetingProof: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      proofTypes: [
        { label: 'Signed agreement', value: 'signedAgreement' },
        { label: 'Email exchange', value: 'emailExchange' },
        { label: 'In CRM Deal', value: 'inDeal' },
        { label: 'No proof required', value: 'noProofRequired' }
      ]
    };
  },
  watch: {
    'meetingProof.type'() {
      // everytime type changes reset proof value, crm, crmurl
      this.$emit('update', { key: 'meetingProof.value', value: null });
    },
    'meetingProof.crmDeal'() {
      // everytime crmDeal changes reset crmDealUrl
      this.$emit('update', { key: 'meetingProof.crmDealUrl', value: null });
    }
  },
  computed: {
    showFile() {
      return (
        _.get(this.meetingProof.value, 'size', null) !== null ||
        (_.isArray(this.meetingProof.value) &&
          this.meetingProof.value.length > 0)
      );
    },
    isMultipleAgreements() {
      return _.isArray(this.meetingProof.value);
    }
  },
  methods: {
    handleUpdate({ key, value, addFile = false }) {
      this.$emit('update', { key, value, addFile });
    },
    removeFile(agreement) {
      if (!agreement) {
        this.$emit('update', { key: 'meetingProof.value', value: null });
      } else {
        const meetingProofVal = this.meetingProof.value;

        const newValue = meetingProofVal.filter(p => p.name !== agreement.name);

        this.$emit('update', { key: 'meetingProof.value', value: newValue });
      }
    },
    getInputLabel() {
      if (this.meetingProof.type === 'emailExchange') {
        return 'Link to email';
      }

      if (this.meetingProof.type === 'noProofRequired') {
        return 'Explanation';
      }

      return '';
    },
    getInputPlaceholder() {
      if (this.meetingProof.type === 'emailExchange') {
        return 'e.g. URL to Hubspot or Helpscout';
      }

      if (this.meetingProof.type === 'noProofRequired') {
        return 'e.g. Customer on subscription';
      }

      return '';
    }
  }
};
</script>

<style></style>
