<template>
  <transition name="fade" mode="out-in">
    <router-view :current-meeting="currentMeeting" />
  </transition>
</template>

<script>
/*
This component serves as the parent component for the main proxies view
No data request is made here. See MeetingProxiesView for init() call
*/

export default {
  name: 'MeetingProxiesMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
