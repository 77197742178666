import http from './requestType';

/**
 * Get a list of voters by search term
 * @param  {string} searchText    - search text
 * @param  {string} type          - type
 * @return {Promise}              - Promise
 */
export function getVoters({ searchText, type = 'email' }) {
  const params = { searchText, type };
  return http._get(`Voters`, params);
}
