<template>
  <meeting-overview-card icon="subject" title="Print Jobs" hideEdit>
    <!-- Display custom printJobs -->

    <v-layout row>
      <v-flex xs12>
        <p class="mb-0">
          <b>
            {{ billingSettings.pricePerPage | formatCurrency }}/page,
            {{ billingSettings.pricePerPackage | formatCurrency }}/package
          </b>

          <v-icon small right @click="edit = true">edit</v-icon>
        </p>
      </v-flex>
    </v-layout>

    <div v-if="jobs.length > 0">
      <v-layout row v-for="job in jobs" :key="job.key" align-center>
        <!-- If billing is disabled, show a warning icon -->
        <v-flex v-if="hasDisabledJobs && job.disableBilling" xs1>
          <v-tooltip v-if="job.disableBilling" bottom>
            <v-icon color="error" slot="activator">warning</v-icon>
            <span>Billing disabled for this print job</span>
          </v-tooltip>
        </v-flex>

        <!-- Otherwise leave a gap in this column -->
        <v-flex v-if="hasDisabledJobs && !job.disableBilling" xs1></v-flex>

        <!-- Print job description -->
        <v-flex :xs8="hasDisabledJobs" :xs9="!hasDisabledJobs">
          <p class="mb-0">
            <span class="mb-0" v-html="formatMarkdownToHtml(job.description)" />
          </p>
        </v-flex>

        <!-- Print job cost ($0 if billing is disabled) -->
        <v-flex xs3 class="v-flex center text-xs-right pr-4">
          <span v-if="!job.disableBilling">{{
            job.value | formatCurrency
          }}</span>
          <span v-else>$0</span>
        </v-flex>
      </v-layout>

      <v-divider class="primary" />

      <v-layout row>
        <v-flex xs9 class="v-flex text-xs-right pr-4">Subtotal: </v-flex>
        <v-flex xs3 class="v-flex center text-xs-right pr-4">
          <span class="nowrap">${{ this.price }}</span>
        </v-flex>
      </v-layout>
    </div>

    <div v-else>
      <!-- If no print jobs, display simple message -->
      <v-layout row>
        <v-flex xs10>
          No completed print jobs. Check print jobs tab.
        </v-flex>
      </v-layout>
    </div>
    <add-print-jobs-prices-dialog
      :is-open="edit"
      :prices="state"
      @update="handleUpdate"
      @save="onSave"
      @close-dialog="edit = false"
    />
  </meeting-overview-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { formatCurrency, formatMarkdownToHtml } from '@/filters';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import AddPrintJobsPricesDialog from '@/components/dialogs/AddPrintJobsPricesDialog';

export default {
  name: 'PrintingCard',
  components: {
    MeetingOverviewCard,
    AddPrintJobsPricesDialog
  },
  filters: {
    formatCurrency
  },
  data() {
    return { edit: false, state: { pricePerPage: 0, pricePerPackage: 0 } };
  },
  watch: {
    // fills state with computed values
    billingSettings() {
      this.state.pricePerPage = this.billingSettings.pricePerPage;
      this.state.pricePerPackage = this.billingSettings.pricePerPackage;
    }
  },
  computed: {
    ...mapGetters('meetings/billing', ['billingSettings', 'printJobs']),
    jobs() {
      return this.printJobs && this.printJobs.jobs ? this.printJobs.jobs : [];
    },
    price() {
      return this.printJobs ? this.printJobs.totalPrice : 0;
    },
    hasDisabledJobs() {
      // Check if 1+ print job has billing disabled
      // This prop is used for formatting the list of print jobs
      if (this.printJobs.jobs) {
        const disabledJob = this.printJobs.jobs.find(job => job.disableBilling);
        return disabledJob ? true : false;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('meetings/billing', ['updateBillingSettings']),
    handleUpdate(obj) {
      // Lodash search state object for matching key
      _.set(this.state, obj.key, obj.value);
    },
    async onSave() {
      try {
        const payload = {
          ...this.state,
          enabled: this.billingSettings.enabled
        };
        await this.updateBillingSettings({
          shortCode: this.$route.params.shortcode,
          payload
        });
        this.$events.$emit('toastEvent', 'Print Jobs prices updated');
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.edit = false;
      } finally {
        this.edit = false;
      }
    },
    formatMarkdownToHtml
  }
};
</script>
