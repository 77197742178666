<template>
  <div class="codemirror-container">
    <codemirror
      :disabled="isDisabled"
      :code="computeToString"
      :options="editorOption"
      @change="onChange($event)"
    />
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror';

export default {
  name: 'CodeMirror',
  components: {
    codemirror
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    codeArray: {
      type: Array,
      default() {
        return [];
      }
    },
    isDelimited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editorOption: {
        tabSize: 4,
        styleActiveLine: true,
        line: true,
        mode: 'text/html',
        lineWrapping: true,
        readOnly: this.setDisabledValue()
      }
    };
  },
  computed: {
    computeToString() {
      return this.codeArray.join('\n');
    }
  },
  watch: {
    isDisabled() {
      this.editorOption.readOnly = this.setDisabledValue();
    }
  },
  methods: {
    setDisabledValue() {
      return this.isDisabled ? 'nocursor' : false;
    },
    onChange(value) {
      let array = [];
      // make array by comma, semicolon or newline.
      if (this.isDelimited) {
        array = value.split(/[,;\n]+/);
      } else {
        array = value.split(/[\n]/);
      }
      this.$emit('update', array);
    }
  }
};
</script>

<style>
.CodeMirror {
  border: 1px solid #c9c9c9;
}
/* Limit height of textarea */
.CodeMirror.cm-s-default.CodeMirror-wrap {
  height: 100%;
}
</style>
