'use strict';

/**
 * @fileoverview Library to get a file's extension
 */

/**
 * Return the lowercase file extension
 * @param   {string} filename   - the name of the file
 * @return  {string}            - the lower case file extension
 */
export default function getFileExtension(filename) {
  return filename
    .split('.')
    .pop()
    .toLowerCase();
}
