<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="documentsWithType"
      :loading="loading"
      class="round-card"
    >
      <template v-slot:items="props">
        <td>
          <a class="flex-center" @click="handleDownload(props.item)">
            <v-icon>file_download</v-icon>
            {{ props.item.name }}
          </a>
        </td>
        <td>
          <v-chip
            v-if="props.item.extension"
            small
            class="px-2"
            :color="getColor(props.item.extension).background"
            :text-color="getColor(props.item.extension).color"
            disabled
            >{{ props.item.extension.toUpperCase() }}</v-chip
          >
        </td>
        <td>{{ props.item.size | fileSize }}</td>
        <td class="text-xs-right">
          <v-btn flat icon color="error" @click="handleDialog(props.item)">
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <delete-dialog
      :is-open="dialog"
      :show-icon="true"
      resource-name=""
      :resource-key="resource.key"
      @delete="handleDelete(resource)"
      @close-dialog="dialog = false"
    >
      <template slot="title"
        >Do you want to delete {{ resource.name }}?</template
      >
      <template slot="description"
        >This will delete {{ resource.name }} from storage</template
      >
    </delete-dialog>
  </v-card>
</template>

<script>
import _ from 'lodash';
import filesize from 'filesize';
import { mapActions } from 'vuex';

import DeleteDialog from '@/components/dialogs/DeleteResourceDialog';

export default {
  name: 'MeetingVbmDocuments',
  components: {
    DeleteDialog
  },
  filters: {
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  props: {
    type: {
      type: String,
      default: 'meeting'
    },
    documents: {
      type: Array,
      default() {
        return [];
      }
    },
    shortCode: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      resource: {
        name: '',
        key: ''
      },
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Type', value: 'extension' },
        { text: 'Size', value: 'size' },
        // Provide empty 4th header, fixes loading bar width
        { text: '', value: '' }
      ],
      // Check calendar app (meeting status) for more color combinations if needed
      chipColors: {
        doc: {
          color: '#0F64A9',
          background: '#ddebf6'
        },
        docx: {
          color: '#0F64A9',
          background: '#ddebf6'
        },
        mp4: {
          color: '#305C5C',
          background: '#C1E4E4'
        },
        pdf: {
          color: '#B85BA1',
          background: '#eacee3'
        },
        file: {
          color: '#33691e',
          background: '#c5e1a5'
        },
        csv: {
          color: '#034663',
          background: '#b3c8d0'
        }
      }
    };
  },
  computed: {
    documentsWithType() {
      const docs = _.map(this.documents, el => {
        if (el.Key) {
          const name = el.Key.split('.');
          if (name.length > 1) {
            const extension = name.pop();
            return {
              ...el,
              extension
            };
          }
        }
        return el;
      });
      return docs;
    }
  },
  methods: {
    ...mapActions('v2/meetingsFiles', [
      'fetchMeetingFiles',
      'trashMeetingFile',
      'downloadMeetingFile'
    ]),
    ...mapActions('v2/documents', ['removeMeetingDocument']),
    handleDialog(data) {
      this.resource = data;
      this.dialog = true;
    },
    // Handles downloads for private meeting files
    async handleDownload({ Key, name }) {
      try {
        await this.downloadMeetingFile({
          shortCode: this.shortCode,
          Key,
          name
        });
        this.$events.$emit('toastEvent', 'Downloading File');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleDelete({ Key, documentKey }) {
      try {
        if (this.type === 'meeting') {
          await this.removeMeetingDocument({
            shortCode: this.shortCode,
            documentKey
          });
        } else if (this.type === 'private') {
          await this.trashMeetingFile({
            shortCode: this.shortCode,
            Key
          });
        }

        this.$emit('update');

        this.$events.$emit('toastEvent', 'File deleted');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    getColor(value) {
      if (!value) {
        return undefined;
      }

      if (this.chipColors[value]) {
        return {
          background: this.chipColors[value].background,
          color: this.chipColors[value].color
        };
      }
      return {
        color: '#a7c0cd',
        background: '#4b636e'
      };
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}
</style>
