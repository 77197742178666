'use strict';

/**
 * @fileoverview Helper functions for object properties
 */

/** Return object without empty properties
 * @param {Object} object - Object to remove empty properties
 * @return {Object} - Return object
 */
export function removeEmptyProperty(object) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      let value = object[key];

      // If value is string type, trim spaces
      if (typeof value === 'string') {
        value = value.trim();
      }

      // If value is null or empty string, delete object
      if (value === null || value === '') {
        delete object[key];
      }
    }
  }
  return object;
}

/** Return object without empty properties
 * @param {Object} obj - object to check
 * @return {Boolean} - If object has blank properties return true
 */
export function hasBlankProperties(obj) {
  for (let key in obj) {
    if (obj[key] === '' || obj[key] === null) {
      return true;
    }
  }
  return false;
}
