<template>
  <v-autocomplete
    ref="autocomplete"
    :items="contacts"
    placeholder="Select a contact"
    :label="label"
    :item-text="getLabel"
    clearable
    return-object
    cache-items
    :chips="chips"
    deletable-chips
    :search-input.sync="search"
    :loading="loading"
    :multiple="multiple"
    @change="emitUpdate($event)"
  >
    <template v-slot:no-data>
      <div
        v-if="!loading"
        @click="createNewContact"
        class="px-4 cursor-pointer primary--text"
      >
        Click to add a new contact
      </div>
      <div v-else class="px-4">loading...</div>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'ContactSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    search: _.debounce(async function(value) {
      await this.getContactList(value);
    }, 350),
    samePrimary(value) {
      this.usePrimary = value;
    }
  },
  created() {
    this.getContactList();
  },
  data() {
    return {
      search: '',
      loading: false,
      contacts: []
    };
  },
  methods: {
    ...mapActions('contacts', ['getContacts']),
    async getContactList(search) {
      this.loading = true;
      try {
        this.contacts = await this.getContacts(search);
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    },
    emitUpdate(value) {
      this.$emit('update', value);
    },
    createNewContact() {
      // Navigate to Admin contacts page, with "Add Contact" dialog open
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dashboard.getquorum.com'
          : 'http://localhost:8080';

      const url = `${baseUrl}/admin/contacts/?add=true`;
      window.open(url, '_blank');
    },
    getLabel(contact) {
      const firstName = contact.firstName || '';
      const lastName = contact.lastName || '';
      return `${firstName} ${lastName} - ${contact.email}`;
    },
    clearInput() {
      this.$refs.autocomplete.reset();
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
