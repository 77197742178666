<template>
  <v-card class="elevation-0">
    <v-progress-circular
      v-if="!dataLoaded"
      indeterminate
      :size="50"
      color="primary" />
    <v-card-text v-else>
      <p class="title">ESOM Files</p>
      <div v-if="!!esomFile">
        <div
          v-for="file in esomFile"
          :key="file.Key">
          <v-icon
            color="blue lighten-2"
            small>insert_drive_file</v-icon>
          <a
            class="body-1"
            :href="file.url"
            target="_new">{{ file.name }}
          </a>
        </div>
      </div>
      <div v-else>( No ESOM Files uploaded )</div>

      <br>
      <p class="title">Certificate PDF</p>
      <div v-if="!!certFile">
        <v-icon
          color="blue lighten-2"
          small>insert_drive_file</v-icon>
        <a
          class="body-1"
          :href="certFile.url"
          target="_new">{{ certFile.name }}</a>
      </div>
      <div v-else>No Certificate Available</div>

      <br>
      <p class="title">Distribution Report</p>
      <div v-if="!!reportFile">
        <v-icon
          color="blue lighten-2"
          small>insert_drive_file</v-icon>
        <a
          class="body-1"
          :href="reportFile.url"
          target="_new">{{ reportFile.name }}
        </a>
      </div>
      <div v-else>No Report Available</div>

    </v-card-text>
  </v-card>
</template>

<script scoped>
import _ from 'lodash';

export default {
  name: 'MeetingCertificatesViewExport',
  props: {
    fileExports: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    dataLoaded() {
      if (_.isEmpty(this.fileExports)) {
        return false;
      }
      return true;
    },
    esomFile() {
      return !_.isEmpty(this.fileExports.data.esomFiles)
        ? this.fileExports.data.esomFiles
        : false;
    },
    certFile() {
      return !_.isEmpty(this.fileExports.files)
        ? this.fileExports.files[0]
        : false;
    },
    reportFile() {
      return !_.isEmpty(this.fileExports.files)
        ? this.fileExports.files[1]
        : false;
    }
  }
};
</script>

<style scoped>
</style>
