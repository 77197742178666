<template>
  <v-layout>
    <v-spacer></v-spacer>
    <!-- Preferred CSM -->
    <v-list class="mr-2 elevation-1">
      <v-list-tile avatar>
        <v-list-tile-avatar v-if="preferredCsm && preferredCsm.disabledAt">
          <v-tooltip bottom>
            <v-icon large slot="activator" color="accent">warning</v-icon>
            <span
              >This user has been disabled. Please update the Preferred CSM for
              this agent.</span
            >
          </v-tooltip>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            Preferred CSM
          </v-list-tile-title>
          <v-list-tile-sub-title>
            {{ preferredCsm ? preferredCsm.name : 'N/A' }}
          </v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action v-if="hasPermissionsToAssignCsm">
          <v-icon color="primary" @click="openDialog('csm')">
            edit
          </v-icon></v-list-tile-action
        >
      </v-list-tile>
    </v-list>

    <update-agent-preferred-manager-dialog
      v-model="dialog.update"
      :agent="currentAgent"
      :type="dialog.type"
      :options="checklistAssignees"
      :current-manager="preferredCsm"
      @close="dialog.update = false"
      @remove="deleteManager"
      @update="handleUpdate"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import UpdateAgentPreferredManagerDialog from '@/components/dialogs/UpdateAgentPreferredManagerDialog';

export default {
  name: 'AdminAgentPreferredManagers',
  components: { UpdateAgentPreferredManagerDialog },
  props: {
    currentAgent: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['login', 'getScopeByAction']),
    ...mapGetters('checklists', ['checklistAssignees']),
    preferredCsm() {
      if (!_.isEmpty(this.currentAgent.preferredManagers)) {
        return this.currentAgent.preferredManagers[0].user;
      } else {
        return null;
      }
    },
    hasPermissionsToAssignCsm() {
      const { profile } = this.login;
      const permissions = profile.permissions;
      return _.includes(permissions, 'admin.lead.access.update');
    }
  },
  data() {
    return {
      dialog: {
        update: false,
        reassign: false,
        type: ''
      }
    };
  },
  created() {
    this.initManagers();
  },
  methods: {
    ...mapActions('agents', [
      'getAgentPreferredManagers',
      'addAgentPreferredManager',
      'updateAgentPreferredManager',
      'removeAgentPreferredManager'
    ]),
    ...mapActions('checklists', ['getChecklistAssignees']),
    async initManagers() {
      await this.getChecklistAssignees();
      await this.getAgentPreferredManagers(this.$route.params.agentKey);
    },
    openDialog(type) {
      this.dialog.type = type;
      this.dialog.update = true;
    },
    handleUpdate(managerKey) {
      // If no preferred manager exists, we're adding one
      if (!this.preferredCsm) {
        this.addManager(managerKey);
      }
      // Otherwise, we're updating
      else {
        this.updateManager(managerKey);
      }
    },
    async addManager(managerKey) {
      try {
        // Add preferred manager
        await this.addAgentPreferredManager({
          agentKey: this.$route.params.agentKey,
          userKey: managerKey
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Added');
        // Prompt user to reassign any active checklists
        this.dialog.reassign = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateManager(managerKey) {
      try {
        // Update preferred manager
        await this.updateAgentPreferredManager({
          agentKey: this.$route.params.agentKey,
          userKey: managerKey
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Updated');
        // Prompt user to reassign any active checklists
        this.dialog.reassign = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteManager() {
      try {
        await this.removeAgentPreferredManager({
          agentKey: this.$route.params.agentKey
        });

        this.dialog.update = false;
        this.$events.$emit('toastEvent', 'Preferred Manager Deleted');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>
