<!-- This component is the same as FileUploadForm, but will only upload to a .tmp folder -->
<template>
  <div>
    <!-- File upload area, no package exists -->
    <div v-if="!packageExists || multipleFiles">
      <div class="dz-area" v-if="processing">
        <div class="dz-message">
          <span>File Uploading</span>
          <v-progress-circular indeterminate :size="50" color="primary" />
        </div>
      </div>
      <div
        class="dz-area"
        v-if="(!packageExists && !processing) || (multipleFiles && !processing)"
      >
        <div class="dz-message">
          <v-icon color="grey" large>backup</v-icon>
          <span v-if="!packageExists" class="highlight">No File Uploaded</span>
          <span v-else class="highlight">File Uploaded</span>
          <span>Click or Drag and Drop files here to upload</span>
        </div>
        <div class="input-area">
          <input
            type="file"
            :multiple="multipleFiles"
            :accept="accept"
            @change="onFileChange"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>

    <!-- Package exists -->
    <!-- Accepts array of files -->
    <div v-if="multipleFiles">
      <transition name="slide-fade">
        <v-list two-line v-if="packageExists">
          <v-list-tile
            class="pointer"
            v-for="(file, index) in attachment"
            :key="file.Key"
            avatar
          >
            <v-list-tile-avatar>
              <v-icon large color="blue">{{ icon }}</v-icon>
            </v-list-tile-avatar>
            <file-upload-item
              :icon="icon"
              :attachment="file"
              @download="downloadFile"
            />
            <v-list-tile-action>
              <v-btn
                :disabled="disabled"
                flat
                color="accent"
                @click.native="removeFileFromArray(attachment, index)"
                >delete</v-btn
              >
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </transition>
    </div>

    <!-- Single attachment -->
    <div v-else>
      <v-list two-line v-if="packageExists && !processing">
        <v-list-tile class="pointer" avatar>
          <v-list-tile-avatar>
            <v-icon large color="blue">{{ icon }}</v-icon>
          </v-list-tile-avatar>
          <file-upload-item
            :icon="icon"
            :attachment="attachment"
            @download="downloadFile"
          />
          <v-list-tile-action>
            <v-btn flat color="accent" @click.native="removeFile">delete</v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>

    <!-- Overwrite dialog -->
    <v-dialog :value="dialog.open" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <div class="title">Overwrite document?</div>
        </v-card-title>
        <v-card-text>
          <v-layout
            >A document with the same name exists. Do you want to overwrite
            it?</v-layout
          >
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="resetDialog">cancel</v-btn>
          <v-spacer />
          <v-btn class="primary" @click.native="overwriteFile">overwrite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { removeAccents } from '@/helpers.js';
import { getPutSignedUrlTmp, putSignedUrl } from '@/api/meetings-files';
import FileUploadItem from '@/components/FileUploadItem';

export default {
  name: 'FileUploadFormTmp',
  components: {
    FileUploadItem
  },
  props: {
    attachment: {
      type: [Object, Array],
      default() {
        return {};
      }
    },
    icon: {
      type: String,
      default: 'insert_drive_file'
    },
    multipleFiles: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default() {
        return '';
      }
    },
    folder: {
      type: String,
      default() {
        return null;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    staticFiles: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      processing: false,
      dialog: {
        open: false,
        newFile: {},
        existingIndex: null
      }
    };
  },
  computed: {
    packageExists() {
      return !_.isEmpty(this.attachment);
    }
  },
  methods: {
    ...mapActions('files', ['getFile']),
    async downloadFile(attachment) {
      try {
        let res = await this.getFile({
          bucket: attachment.Bucket,
          key: attachment.Key
        });
        window.open(res.data.url);
      } catch (err) {
        throw err;
      }
    },
    removeFile() {
      this.$emit('upload', {});
    },
    removeFileFromArray(attachment, index) {
      let fileArray = _.cloneDeep(attachment);
      fileArray.splice(index, 1);
      this.$emit('upload', fileArray);
    },
    onFileChange(e) {
      this.processing = true; // Trigger progress animation

      let files = e.target.files || e.dataTransfer.files;
      // Grab file object

      let file = files[0];

      // check for accents and replace them
      const nameWithoutAccents = removeAccents(file.name);

      // Update the name of the file creating a new file object
      file = new File([file], nameWithoutAccents, { type: file.type });

      // Check for any duplicate file names
      let dupeIndex = _.findIndex(this.attachment, function(element) {
        return element.name === file.name;
      });

      // If a duplicate file name exists, open Overwrite dialog
      if (dupeIndex >= 0 && !this.staticFiles) {
        this.dialog.open = true;
        this.dialog.newFile = file;
        this.dialog.existingIndex = dupeIndex;
      } else if (this.staticFiles) {
        // Otherwise, move on with static files
        let filesFormatted = _.map(files, function(file, index) {
          return {
            url: '',
            key: index,
            name: file.name,
            size: file.size,
            original: file
          };
        });
        this.$emit('upload', filesFormatted);
        this.processing = false;
      } else {
        // Otherwise, move on with uploading file
        this.processFile(file);
      }

      // Clear to detect any change event
      e.target.value = '';
    },
    overwriteFile() {
      this.removeFileFromArray(this.attachment, this.dialog.existingIndex);
      this.processFile(this.dialog.newFile);
      this.resetDialog();
      this.processing = true;
    },
    resetDialog() {
      this.dialog.open = false;
      this.dialog.newFile = {};
      this.dialog.existingIndex = null;
      this.processing = false;
    },
    async processFile(file) {
      try {
        // Optional prop values for custom folder name and designate as temporary file
        let folder = this.folder;
        let { data } = await getPutSignedUrlTmp(
          this.shortCode,
          file.name,
          folder
        );

        let { status } = await putSignedUrl(data.putObjectSignedUrl, file);

        if (status) {
          let packageObj = {
            Bucket: data.Bucket,
            bucket: data.Bucket,
            Key: data.Key,
            file: file,
            name: file.name,
            size: file.size,
            url: data.getObjectSignedUrl
          };

          if (this.multipleFiles) {
            let attachmentArray = this.attachment;
            attachmentArray.push(packageObj);
            this.$emit('upload', attachmentArray);
          } else {
            this.$emit('upload', packageObj);
          }

          this.processing = false;
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        this.processing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dz-area {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  cursor: pointer;
  border: 2px dashed #e8e8e8;
}
.dz-area > .dz-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #97a8be;
}
.dz-area > .dz-message > .highlight {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #20a0ff;
}
.dz-area .input-area {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dz-area .input-area input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
  cursor: hand;
}
</style>
