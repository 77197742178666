import moment from 'moment';

/**
 * Return the list of reminder dates, based on meeting date,
 * reminder start date, and reminder frequency.
 *
 * @param  {String} reminderStartDate      - Date used to calculate when reminders should 'start'
 * @param  {Number} reminderFrequency      - How often reminders should be sent (in days)
 * @param  {String} meetingDate            - Meeting date
 * @return {String[]}                      - List of reminder dates
 */
export default function getReminderDates(
  reminderStartDate,
  reminderFrequency,
  meetingDate
) {
  let reminderDates = [];
  let date = reminderStartDate;

  while (moment(date).isBefore(meetingDate)) {
    const nextReminder = moment(date).add(reminderFrequency, 'days');
    if (moment(nextReminder).isBefore(meetingDate)) {
      reminderDates.push(nextReminder);
    }
    date = nextReminder;
  }

  return reminderDates;
}
