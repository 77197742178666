<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-subheader light>
          <span class="capitalize">
            {{ campaignType }} Meetings ({{ campaigns.length }})
          </span>
        </v-subheader>

        <div v-if="campaigns.length > 0">
          <v-list two-line class="list-back">
            <meetings-view-list-item
              v-for="campaign in campaigns"
              :meeting="campaign"
              :key="campaign.key"
              :content-max-width="newLayout ? 300 : 600"
              :status-options="statusOptions"
            />
          </v-list>
        </div>

        <div v-else>
          <p class="pl-3" v-if="newLayout && campaignType === 'upcoming'">
            No {{ campaignType }} meetings.
            <a class="label blue--text">Book a service</a> to get started.
          </p>
          <p class="pl-3" v-else>
            <i>No {{ campaignType }} meetings found.</i>
          </p>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import MeetingsViewListItem from './MeetingsViewListItem';

export default {
  name: 'MeetingsViewList',
  components: {
    MeetingsViewListItem
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    },
    statusOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    newLayout: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style scoped>
.list-back {
  background-color: #fafafa !important;
}
</style>
