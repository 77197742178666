<template>
  <v-card class="px-2 py-2 approval-card" :elevation="0">
    <div v-if="!isApproved">
      <v-card-text
        >This request must be approved by at least <b>1</b> reviewer by
        <b>{{ requestDeadline }}</b
        >.</v-card-text
      >
      <v-card-text
        >Request any detail changes needed in the activity feed
        below.</v-card-text
      >
      <v-card-actions v-if="isAdmin || isReviewer">
        <v-btn color="success" block @click.native="handleApprove">
          APPROVE
        </v-btn>
      </v-card-actions>
    </div>
    <div v-else>
      <v-card-text>This request has been aproved.</v-card-text>
      <v-card-text
        >If any further changes are needed, please contact us for
        support.</v-card-text
      >
      <v-card-actions>
        <v-btn color="primary" block href="mailto:billing@getquorum.com">
          CONTACT SUPPORT
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ApprovalCard',
  props: {
    isApproved: {
      type: Boolean,
      default: false
    },
    reviewers: {
      type: [Array, Object],
      required: true
    },
    requestDeadline: {
      type: String,
      default() {
        return '';
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters(['login']),
    isReviewer() {
      return _.some(this.reviewers, ['key', this.login.profile.key]);
    }
  },
  methods: {
    handleApprove() {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped>
.approval-card {
  border: 3px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}
</style>
