<template>
  <v-data-table
    :headers="headers"
    :items="meetingChecklistData"
    :pagination.sync="config.pagination"
    class="elevation-0"
    hide-actions
  >
    <template v-slot:items="props">
      <tr @click="goToChecklist(props.item)">
        <td>
          <v-layout>
            <checklist-chip :checklist="props.item" />
            <span v-if="hasPrintingItems(props.item)">
              <span v-if="isPrintItemsComplete(props.item)">
                <v-tooltip left>
                  <span slot="activator">
                    <v-layout class="mt-1">
                      <v-icon>print</v-icon>
                      <v-icon small color="green">check_circle</v-icon>
                    </v-layout>
                  </span>
                  <span>All print items completed</span>
                </v-tooltip>
              </span>
            </span>
            <span v-else>
              <span v-if="props.item.stage !== 'pre-consent'">
                <v-tooltip left>
                  <span slot="activator">
                    <v-icon color="red" class="mt-1">print_disabled</v-icon>
                  </span>
                  <span>No printing required</span>
                </v-tooltip>
              </span>
            </span>
          </v-layout>
        </td>
        <td>
          {{ props.item.dueAt | formatDate }}
        </td>
        <td v-show="showAssignee">
          {{ props.item.assignees | getAssigneeInitials }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import { getAssigneeInitials } from '@/lib/checklist-helpers';
import ChecklistChip from '@/components/ChecklistChip';

export default {
  name: 'ChecklistTable',
  components: {
    ChecklistChip
  },
  props: {
    meetingChecklistData: {
      type: Array,
      default() {
        return [];
      }
    },
    showAssignee: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    getAssigneeInitials
  },
  data() {
    return {
      config: {
        pagination: {
          sortBy: 'dueAt',
          descending: false
        }
      }
    };
  },
  computed: {
    headers() {
      const headers = [
        { text: 'Name', value: 'name', align: 'left', sortable: false },
        { text: 'Due At', value: 'dueAt', align: 'left', sortable: false }
      ];
      if (this.showAssignee) {
        headers.push({
          text: 'Assignee',
          value: '',
          align: 'left',
          sortable: false
        });
      }
      return headers;
    }
  },
  methods: {
    hasPrintingItems(checklist) {
      let index = _.findIndex(checklist.items, function(item) {
        return item.printType;
      });

      if (index > -1) {
        return true;
      }
      return false;
    },
    isPrintItemsComplete(checklist) {
      let index = _.findIndex(checklist.items, function(item) {
        if (item.printType) {
          return !item.checked;
        }
      });

      // If unchecked print items are found, print items are not complete
      if (index > -1) {
        return false;
      }
      return true;
    },
    goToChecklist(item) {
      let key = item.key;

      let baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://calendar.getquorum.com'
          : 'http://localhost:8082';

      let url = `${baseurl}/checklists/${key}`;

      window.open(url, '_blank');
    },
    isAssignees(item) {
      return _.has(item, 'assignees');
    }
  }
};
</script>

<style scoped></style>
