<template>
  <v-dialog persistent :value="isOpen" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Add Subscription Proof</span>
      </v-card-title>
      <v-card-text>
        What proof do we have that the subscription has been accepted?
      </v-card-text>
      <v-card-text>
        <!-- meeting proof of request -->
        <v-radio-group
          name="subscription-proof"
          :value="subscriptionProof.type"
          @change="handleUpdate({ key: 'type', value: $event })"
        >
          <v-radio
            v-for="proof in proofTypes"
            :label="proof.label"
            :value="proof.value"
            :key="proof.label"
          />
        </v-radio-group>

        <subscription-proof-uploader
          v-if="
            subscriptionProof.type === 'signedAgreement' &&
              !subscriptionProof.value
          "
          @update="handleUpdate"
        />

        <v-list two-line v-if="showFile">
          <v-list-tile>
            <v-list-tile-avatar>
              <v-icon large color="blue">insert_drive_file</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{
                subscriptionProof.value.name
              }}</v-list-tile-title>
              <v-list-tile-sub-title>{{
                subscriptionProof.value.size | formatSize
              }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn flat color="red" @click.native="removeFile">delete</v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>

        <v-text-field
          v-if="subscriptionProof.type === 'electronicSignature'"
          label="Deal URL"
          placeholder="e.g. URL to Hubspot or Helpscout"
          :value="subscriptionProof.value"
          @input="handleUpdate({ key: 'value', value: $event })"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click.native="$emit('close-dialog')" flat>
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click.native="saveSubscriptionProof"
          color="primary"
          :loading="loading"
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import filesize from 'filesize';
import SubscriptionProofUploader from './SubscriptionProofUploader.vue';

export default {
  name: 'AddSubscriptionProofDialog',
  components: {
    SubscriptionProofUploader
  },
  filters: {
    formatSize(value) {
      if (value) {
        return filesize(value, { base: 10 });
      }
      return '';
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    accountKey: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      proofTypes: [
        { label: 'Signed agreement', value: 'signedAgreement' },
        {
          label: 'Electronic signature in CRM system',
          value: 'electronicSignature'
        }
      ],
      subscriptionProof: {
        type: null,
        value: null
      },
      loading: false
    };
  },
  computed: {
    showFile() {
      return _.get(this.subscriptionProof.value, 'size', null) !== null;
    }
  },
  methods: {
    ...mapActions('accounts', ['addAccountSubscriptionProof']),
    ...mapActions('files', ['uploadSingleFile']),
    async saveSubscriptionProof() {
      this.loading = true;
      try {
        let fileObj = {};

        // if signed agreement file exists
        if (_.get(this.subscriptionProof.value, 'size', null) !== null) {
          // upload file
          fileObj = await this.uploadSingleFile({
            file: this.subscriptionProof.value,
            folder: `agreement/${this.accountKey}`
          });

          return await this.addAccountSubscriptionProof({
            key: this.accountKey,
            subscriptionProof: { ...this.subscriptionProof, value: fileObj.url }
          });
        }

        await this.addAccountSubscriptionProof({
          key: this.accountKey,
          subscriptionProof: this.subscriptionProof
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
        this.$emit('close-dialog');
      }
    },
    handleUpdate(obj) {
      _.set(this.subscriptionProof, obj.key, obj.value);
    },
    removeFile() {
      this.subscriptionProof.value = null;
    }
  }
};
</script>

<style scoped></style>
