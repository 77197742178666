<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AdminProductsMain',
  mounted() {
    this.getProducts();
  },
  methods: {
    ...mapActions('products', ['getProducts'])
  }
};
</script>
<style scoped>
.fill-height {
  height: 50vh;
}
</style>
