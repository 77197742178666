'use strict';

/**
 * @fileoverview Vuex module for a meeting's RTV Questions
 */

import _ from 'lodash';
import makeRTVQuestionObject from '@/lib/make-rtv-question-object';
// import Pusher from 'pusher-js';
// Pusher.logToConsole = process.env.NODE_ENV === 'development';
// const pusherKey = process.env.VUE_APP_PUSHER_KEY;

import {
  getRTVQuestionList,
  postRTVQuestion,
  putRTVQuestion,
  deleteRTVQuestion,
  startRTVQuestion,
  extendRTVQuestion,
  stopRTVQuestion,
  getRTVQuestionRestrictionsList,
  postRTVQuestionRestriction,
  deleteRTVQuestionRestriction
} from '@/api/meetings-rtv-questions';

const state = {
  meetingRTVQuestionsList: {},
  meetingRTVQuestionRestrictions: [],
  dataLastFetchedAt: null
};

const getters = {
  meetingRTVQuestionsObject: state => state.meetingRTVQuestionsList,
  meetingRTVQuestionsListId: state => id => state.meetingRTVQuestionsList[id],
  meetingRTVQuestionsArray: state => {
    return Object.values(state.meetingRTVQuestionsList);
  },
  meetingRTVQuestionByCode: state => code => {
    return _.find(
      Object.values(state.meetingRTVQuestionsList),
      rtv => rtv.question_key === code
    );
  },
  rtvQuestionRestrictions: state =>
    state.meetingRTVQuestionRestrictions.restrictions
};

const actions = {
  /**
   * List the RTV Questions for a given meeting
   * @param  {String} shortCode   - Meeting shortcode
   */
  async getMeetingRTVQuestions({ commit }, { shortCode }) {
    try {
      const res = await getRTVQuestionList(shortCode);
      const questionData = res.data;
      commit('SET_MEETING_RTV_QUESTIONS_DATA', questionData);
    } catch (err) {
      console.error('Error: getMeetingRTVQuestions action', err);
      throw err;
    }
  },

  /**
   * Create a new RTV Question
   * @param  {String}  shortCode          - Meeting shortcode
   * @param  {Object}  question           - New question object
   * @param  {Object}  restrictions       - Optional restrictions array, to add to RTV question
   * @param  {Boolean} overrideProxy      - Proxy override
   * @param  {Boolean} isBallot           - Is ballot
   */
  async createMeetingRTVQuestion(
    { dispatch },
    {
      shortCode,
      question = {},
      restrictions = null,
      isTest = false,
      overrideProxy = false,
      isBallot = false
    }
  ) {
    try {
      let newQuestion = question;
      if (_.isEmpty(question)) {
        newQuestion = await makeRTVQuestionObject(
          isTest,
          overrideProxy,
          isBallot
        );
      }

      const res = await postRTVQuestion(shortCode, newQuestion);

      // Check if we need to add any custom restrictions to the new question
      if (restrictions) {
        const questionKey = res.data.key;

        for (const restriction of restrictions) {
          const customField = Object.keys(restriction)[0];
          const fieldValue = restriction[customField];
          const restrictionObj = { customField, fieldValue, questionKey };

          await dispatch('createRTVQuestionRestriction', {
            shortCode,
            questionKey,
            restriction: restrictionObj
          });
        }
      }
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: createMeetingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Update an existing RTV Question
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {Number} questionKey - Question key, to be updated
   * @param  {Object} question    - Updated question object
   */
  async updateMeetingRTVQuestion(
    { dispatch },
    { shortCode, questionKey, question }
  ) {
    try {
      await putRTVQuestion(shortCode, question, questionKey);
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: updateMeetingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Delete an existing RTV Question
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {Number} questionKey - Question key, to be deleted
   */
  async deleteMeetingRTVQuestion({ dispatch }, { shortCode, questionKey }) {
    try {
      await deleteRTVQuestion(shortCode, questionKey);
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: deleteMeetingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Open an RTV Question's voting period
   * @param  {String}  shortCode      - Meeting shortcode
   * @param  {String}  questionKey    - Question key, to be updated
   * @param  {Object}  question       - Question object
   * @param  {String}  deadlineType   - Type of deadline provided (one of: ['date', 'minutes'])
   * @param  {String}  deadline       - Deadline (either # of minutes, or future date)
   * @param  {Boolean} notifyVoters   - Whether we should send email to voters in attendance
   */
  async openVotingRTVQuestion(
    { dispatch },
    {
      shortCode,
      questionKey,
      question,
      deadlineType = 'minutes',
      deadline,
      notifyVoters = false
    }
  ) {
    try {
      await startRTVQuestion(
        shortCode,
        question,
        questionKey,
        deadlineType,
        deadline,
        notifyVoters
      );
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: openVotingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Extend an RTV Question's voting period
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {String} questionKey - Question key, to be updated
   * @param  {Object} question    - Question object
   * @param  {Number} time        - Length of voting extension
   */
  async extendVotingRTVQuestion(
    { dispatch },
    { shortCode, questionKey, question, time }
  ) {
    try {
      await extendRTVQuestion(shortCode, question, questionKey, time);
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: openVotingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Close an RTV Question's voting period
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {String} questionKey - Question key, to be updated
   * @param  {Object} question    - Question object
   */
  async closeVotingRTVQuestion(
    { dispatch },
    { shortCode, questionKey, question }
  ) {
    try {
      await stopRTVQuestion(shortCode, question, questionKey);
      return dispatch('getMeetingRTVQuestions', { shortCode });
    } catch (err) {
      console.error('Error: openVotingRTVQuestion action', err);
      throw err;
    }
  },

  /**
   * Create a new RTV Question restriction
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {String} questionKey - Question key
   * @param  {Object} restriction - Restriction payload
   */
  async createRTVQuestionRestriction(
    { dispatch },
    { shortCode, questionKey, restriction = {} }
  ) {
    try {
      await postRTVQuestionRestriction(shortCode, questionKey, restriction);
    } catch (err) {
      console.error('Error: createRTVQuestionRestriction action', err);
      throw err;
    }
  },

  /**
   * Delete an existing RTV Question restriction
   * @param  {String} shortCode   - Meeting shortcode
   * @param  {String} questionKey - Question key
   * @param  {String} customField - Custom Field
   * @param  {String} fieldValue  - Field Value
   */
  async removeRTVQuestionRestriction(
    { dispatch },
    { shortCode, questionKey, customField, fieldValue }
  ) {
    try {
      await deleteRTVQuestionRestriction(
        shortCode,
        questionKey,
        customField,
        fieldValue
      );
    } catch (err) {
      console.error('Error: removeRTVQuestionRestriction action', err);
      throw err;
    }
  },

  /**
   * Get list of custom restrictions for a question
   *
   * @param  {String} questionKey    - question key
   */
  async getRTVQuestionRestrictions({ commit }, { shortCode, questionKey }) {
    try {
      const res = await getRTVQuestionRestrictionsList(shortCode, questionKey);
      const restrictionsData = res.data;
      commit('SET_QUESTION_RESTRICTIONS', {
        key: questionKey,
        restrictions: restrictionsData
      });
    } catch (err) {
      console.error('ERROR: getRTVQuestionRestrictions', err);
      throw err;
    }
  }

  /**
   * @DEPRECATE if pusher subscription on main meeting module works
   * Subscribe to this meeting's RTV Pusher channel
   * @param  {String} {shortCode}  - meeting shortcode
   */
  // initPusher({ state, commit, dispatch }, { shortCode }) {
  //   try {
  //     const pusher = new Pusher(pusherKey, {
  //       cluster: 'us2'
  //     });

  //     // Save to store, so we can force disconnect
  //     commit('SET_PUSHER', pusher);

  //     const channel = state.pusher.subscribe(`rtv-${shortCode}`);

  //     channel.bind('update-question', () => {
  //       dispatch('getMeetingRTVQuestions', { shortCode });
  //     });
  //   } catch (err) {
  //     console.error('Error: initPusher', err);
  //     throw err;
  //   }
  // },

  /**
   * @DEPRECATE if pusher subscription on main meeting module works
   * Disconnect from this meeting's RTV Pusher channel
   */
  // destroyPusher({ state, commit }) {
  //   if (!_.isEmpty(state.pusher)) {
  //     commit('CLEAR_PUSHER');
  //   }
  // }
};

const mutations = {
  // Add RTV Questions to a meeting's store
  SET_MEETING_RTV_QUESTIONS_DATA(state, data) {
    state.meetingRTVQuestionsList = _.keyBy(data, 'id');
    state.dataLastFetchedAt = new Date();
  },

  SET_QUESTION_RESTRICTIONS(state, data) {
    state.meetingRTVQuestionRestrictions = data;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.meetingRTVQuestionsList = {};
    state.dataLastFetchedAt = null;
  }

  // SET_PUSHER(state, pusher) {
  //   state.pusher = pusher;
  // },

  // CLEAR_PUSHER(state) {
  //   state.pusher.disconnect();
  //   state.pusher = null;
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
