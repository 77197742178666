<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Partner model Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Settings about meeting partners
    </div>

    <create-partner :is-outline="false" />
  </v-container>
</template>

<script>
import CreatePartner from '@/components/CreatePartner';

export default {
  name: 'MeetingSettingsTemplateInput',
  components: {
    CreatePartner
  }
};
</script>

<style></style>
