import http from './requestType';

/**
 * Returns a list of checklist template groups
 * @return {Promise}              - Promise
 */
export function getChecklistTemplateGroups() {
  return http._get(`ChecklistTemplateGroups`);
}

/**
 * Returns a single checklist template group
 * @param  {String} groupKey     - checklist template group key
 * @return {Promise}             - Promise
 */
export function getChecklistTemplateGroup(groupKey) {
  return http._get(`ChecklistTemplateGroups/${groupKey}`);
}

/**
 * Create a new checklist template group
 * @param  {Object} payload         - new checklist template group to create
 * @return {Promise}                - Promise
 */
export function postChecklistTemplateGroup(payload) {
  return http._post(`ChecklistTemplateGroups`, payload);
}

/**
 * Update a checklist template group
 * @param  {String} groupKey        - checklist template group key
 * @param  {Object} payload         - updated checklist template group
 * @return {Promise}                - Promise
 */
export function putChecklistTemplateGroup(groupKey, payload) {
  return http._put(`ChecklistTemplateGroups/${groupKey}`, payload);
}

/**
 * Delete a checklist template group
 * @param  {String} groupKey    - checklist template group key, to delete
 * @return {Promise}            - Promise
 */
export function deleteChecklistTemplateGroup(groupKey) {
  return http._delete(`ChecklistTemplateGroups/${groupKey}`);
}

/**
 * Add a checklist template to a template group
 * @param {String} groupKey      - checklist template group key
 * @param {Object} payload       - new checklist template to add to group
 * @return {Promise}             - Promise
 */
export function postChecklistTemplateGroupMembership(groupKey, payload) {
  return http._post(`ChecklistTemplateGroups/${groupKey}/memberships`, payload);
}

/**
 * Update a list of checklist template group memberships
 * @param  {String}   groupKey      - checklist template group key
 * @param  {Object[]} payload       - updated list of memberships
 * @return {Promise}                - Promise
 */
export function putChecklistTemplateGroupMembership(groupKey, payload) {
  return http._put(`ChecklistTemplateGroups/${groupKey}/memberships/`, payload);
}

/**
 * Remove a checklist template from a template group
 * @param  {String} groupKey       - checklist template group key
 * @param  {String} templateKey    - checklist template key
 * @return {Promise}               - Promise
 */
export function deleteChecklistTemplateGroupMembership(groupKey, templateKey) {
  return http._delete(
    `ChecklistTemplateGroups/${groupKey}/memberships/${templateKey}`
  );
}
