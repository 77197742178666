import http from './requestType';

/**
 * Get a meeting's webcast details
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getWebcast(shortCode) {
  return http._get(`Meetings/${shortCode}/webcast`);
}

/**
 * List webcast user schedulers for a meeting
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function listWebcastSchedulers(shortCode) {
  return http._get(`Meetings/${shortCode}/webcast/schedulers`);
}

/**
 * Update this meeting's webcast
 * @param  {Object}  params
 * @param  {String}  params.shortCode       - the meeting shortcode
 * @param  {String}  data.rehearsalAt       - rehearsal start time
 * @param  {String}  data.rehearsalDuration - rehearsal duration in minutes
 * @param  {String}  data.rehearsalTimezone - rehearsal time zone
 * @param  {Boolean} data.skipBoot          - skip boot indicator
 * @param  {Boolean} data.capacity          - webcast capacity
 * @return {Promise}                        - Promise
 */
export function patchWebcast({
  shortCode,
  rehearsalAt,
  rehearsalDuration,
  rehearsalTimezone,
  skipBoot,
  capacity
}) {
  return http._patch(`Meetings/${shortCode}/webcast`, {
    rehearsalAt,
    rehearsalDuration,
    rehearsalTimezone,
    skipBoot,
    capacity
  });
}

/**
 * Update this meeting webcast's Zoom webinar settings
 * @param  {Object}  params
 * @param  {String}  params.shortCode    - the meeting shortcode
 * @param  {Object}  params.payload      - webinar payload
 * @return {Promise}                     - Promise
 */
export function patchWebcastWebinar({ shortCode, payload }) {
  return http._patch(`Meetings/${shortCode}/webcast/webinar`, payload);
}

/**
 * Add a webcast to this meeting
 * @param  {String}  shortCode           - the meeting shortcode
 * @param  {Object}  params.payload      - webcast payload
 * @return {Promise}              - Promise
 */
export function postWebcast({ shortCode, payload }) {
  return http._post(`Meetings/${shortCode}/webcast`, payload);
}

/**
 * Get a meeting's webcast panelists
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getWebcastPanelists(shortCode) {
  return http._get(`Meetings/${shortCode}/webcast/panelists`);
}

/**
 * Add or update a webcast webinar panelist
 * @param  {Object}  param
 * @param  {String}  param.shortCode    - the meeting shortcode
 * @param  {String}  param.name         - name of panelist
 * @param  {String}  param.email        - email of panelist
 * @return {Promise}              - Promise
 */
export function putWebcastPanelist({ shortCode, name, email }) {
  return http._put(`Meetings/${shortCode}/webcast/panelists/${email}`, {
    name
  });
}

/**
 * Send or resend a panelist rehearsal invite email
 * @param  {Object}  param
 * @param  {String}  param.shortCode    - the meeting shortcode
 * @param  {String}  param.email        - email of panelist
 * @param  {File[]}  param.attachments  - meeting document attachments
 * @return {Promise}                    - Promise
 */
export function postWebcastPanelistRehearsal({
  shortCode,
  email,
  attachments
}) {
  return http._post(
    `Meetings/${shortCode}/webcast/panelists/${email}/rehearsal`,
    {
      attachments
    }
  );
}

/**
 * Send or resend a panelist webinar invite email
 * @param  {Object}  param
 * @param  {String}  param.shortCode    - the meeting shortcode
 * @param  {String}  param.email        - email of panelist
 * @param  {File[]}  param.attachments  - email meeting document attachments
 * @return {Promise}                    - Promise
 */
export function postWebcastPanelistInvite({ shortCode, email, attachments }) {
  return http._post(`Meetings/${shortCode}/webcast/panelists/${email}/invite`, {
    attachments
  });
}

/**
 * Remove a webcast webinar panelist
 * @param  {Object}  param
 * @param  {String}  param.shortCode    - the meeting shortcode
 * @param  {String}  param.email        - email of panelist
 * @return {Promise}                    - Promise
 */
export function deleteWebcastPanelist({ shortCode, email }) {
  return http._delete(`Meetings/${shortCode}/webcast/panelists/${email}`);
}

/**
 * Lists polls for this meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function listWebcastPolls({ shortCode }) {
  return http._get(`Meetings/${shortCode}/webcast/polls`);
}

/**
 * Add a poll to this meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {Object}  poll         - poll payload
 * @return {Promise}              - Promise
 */
export function postWebcastPolls({ shortCode, poll }) {
  return http._post(`Meetings/${shortCode}/webcast/polls`, poll);
}

/**
 * Add polls to this meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {Object}  polls        - polls payload
 * @return {Promise}              - Promise
 */
export function postWebcastBatchPolls({ shortCode, polls }) {
  return http._post(`Meetings/${shortCode}/webcast/batch-polls`, {
    polls: polls
  });
}

/**
 * Update a poll for this meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  pollId       - poll to update
 * @param  {Object}  poll         - poll payload
 * @return {Promise}              - Promise
 */
export function putWebcastPolls({ shortCode, pollId, poll }) {
  return http._put(`Meetings/${shortCode}/webcast/polls/${pollId}`, poll);
}

/**
 * Delete a poll from the meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  pollId       - poll to update
 * @return {Promise}              - Promise
 */
export function deleteWebcastPolls({ shortCode, pollId }) {
  return http._delete(`Meetings/${shortCode}/webcast/polls/${pollId}`);
}

/**
 * Get a set of pre-defined motions
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  type         - the motions type
 * @return {Promise}              - Promise
 */
export function getMotions({ shortCode, type }) {
  const params = { type };

  return http._get(`Meetings/${shortCode}/webcast/motions`, params);
}

/**
 * Check if there are any recordings and queue them up for
 * download.
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function postRecordings({ shortCode }) {
  return http._post(`Meetings/${shortCode}/webcast/recordings`);
}

/**
 * Get the Zoom recordings.
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getRecordings({ shortCode }) {
  return http._get(`Meetings/${shortCode}/webcast/recordings`);
}

/**
 * Get a meeting's webcast slide links
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getSlideLinks({ shortCode }) {
  return http._get(`Meetings/${shortCode}/webcast/slide-links`);
}

/**
 * Get a meeting's webcast event logs
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getEventLogs({ shortCode }) {
  return http._get(`Meetings/${shortCode}/webcast/events`);
}

/**
 * Boot a meeting before joining as host
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function putBootWebcast({ shortCode }) {
  return http._put(`Meetings/${shortCode}/webcast/boot`);
}

/**
 * Check if there are any zoom polls for download
 *
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function postPollsReport({ shortCode }) {
  return http._post(`Meetings/${shortCode}/webcast/polls/report`);
}

/**
 * Check if there are any zoom participants for download
 *
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function postParticipantsReport({ shortCode }) {
  return http._post(`Meetings/${shortCode}/webcast/participants/report`);
}

/**
 * reset expired webcast
 *
 * @param  {String}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function resetWebcast({ shortCode }) {
  return http._delete(`Meetings/${shortCode}/webcast/reset`);
}
