<template>
  <v-dialog :value="value" persistent max-width="700px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Update Preferred {{ managerType }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout row>
            <v-flex xs12>
              <p>
                To update the Preferred {{ managerType }} for this Account and
                assign them to any unassigned
                {{ account.nameShort }} checklists, select an option below and
                click <b>SAVE</b>.
              </p>
              <p v-if="currentManager && !currentManager.user.disabledAt">
                To remove the Preferred {{ managerType }} from this Account,
                click <b>REMOVE</b>.
              </p>
            </v-flex>
          </v-layout>

          <v-layout row class="mt-2">
            <v-flex xs12>
              <v-combobox
                v-model="manager"
                :items="options"
                :label="'Preferred ' + managerType"
                item-text="name"
                return-object
                dense
                outline
                required
              ></v-combobox>
            </v-flex>
          </v-layout>

          <v-layout row class="mt-2">
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="unassignedChecklists"
                item-key="stage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>
                      <checklist-chip :checklist="props.item" />
                    </td>
                    <td>
                      {{ props.item.meetingName }}
                    </td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  <p>
                    All existing {{ managerType }} checklists for
                    {{ account.nameShort }} have been assigned.
                  </p>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="close" flat>Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentManager && !currentManager.user.disabledAt"
          color="error"
          @click="removeManager"
          :disabled="isRemoveDisabled"
          flat
          >Remove</v-btn
        >
        <v-btn
          @click="updateManager"
          :disabled="isSaveDisabled"
          color="primary"
          flat
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import ChecklistChip from '@/components/ChecklistChip';

export default {
  name: 'UpdatePreferredManagerDialog',
  components: {
    ChecklistChip
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default() {
        return null;
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    currentManager: {
      type: Object,
      default() {
        return null;
      }
    },
    unassignedChecklists: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      manager: '',
      headers: [
        { text: 'Unassigned Checklist', value: 'stage', sortable: false },
        { text: 'Meeting', value: 'meetingName', sortable: false }
      ]
    };
  },
  watch: {
    currentManager() {
      if (this.currentManager) {
        this.manager = this.currentManager.user;
      } else {
        this.manager = null;
      }
    }
  },
  computed: {
    ...mapGetters('users', ['userListData']),
    isRemoveDisabled() {
      return !this.manager;
    },
    isSaveDisabled() {
      if (!this.manager) {
        return true;
      }
      if (
        this.currentManager &&
        _.isEqual(this.currentManager.userKey, this.manager)
      ) {
        return true;
      }
      if (
        this.currentManager &&
        !this.options.some(user => user.key === this.manager.key)
      ) {
        return true;
      }
      return false;
    },
    managerType() {
      return this.type.toUpperCase();
    },
    hasUnassignedChecklists() {
      return this.unassignedChecklists.length > 0;
    }
  },
  methods: {
    removeManager() {
      this.$emit('remove', this.type);
      this.close();
    },
    updateManager() {
      this.$emit('update', this.manager.key, this.type);

      if (this.unassignedChecklists.length > 0) {
        this.$emit('assign', this.unassignedChecklists, this.manager.username);
      }

      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
