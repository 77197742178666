<template>
  <v-dialog :value="isOpen" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Subscription period {{ periodDate }} </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :items="data"
            :headers="headers"
            :loading="loading || loadingStatus"
            item-key="name"
          >
            <template slot="items" slot-scope="props">
              <tr class="meeting-row">
                <td @click="openMeeting({ shortCode: props.item.shortCode })">
                  <a class="cursor-pointer">{{ props.item.name }}</a>
                </td>
                <td>
                  {{ props.item.meetingDate | formatDate }}
                </td>
                <td v-if="props.item.meetingStatus">
                  <v-chip
                    class="hidden-sm-and-down status mr-3 bold"
                    :text-color="
                      getStatusDetails(props.item.meetingStatus).color
                    "
                    :color="
                      getStatusDetails(props.item.meetingStatus).background
                    "
                    small
                  >
                    {{ props.item.meetingStatus.toUpperCase() }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { getRecipe } from '@/api/recipes';

export default {
  name: 'MeetingsBySubscriptionPeriodDialog',
  filters: {
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    details: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingStatus: false,
      statusOptions: [],
      headers: [
        {
          text: 'Meeting',
          value: 'name',
          sortable: true
        },
        {
          text: 'Meeting date',
          value: 'meetingDate',
          sortable: true
        },
        {
          text: 'Status',
          value: 'meetingStatus',
          sortable: true
        }
      ],
      data: []
    };
  },
  watch: {
    isOpen: function(value) {
      if (value) {
        this.fetchMeetingsByPeriod();
      }
    }
  },
  created() {
    this.getStatusOptions();
  },
  computed: {
    periodDate() {
      const { startDate, endDate } = this.details;

      return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(
        endDate
      ).format('MMM DD, YYYY')}`;
    },
    statusDetails() {
      return this.statusOptions;
    }
  },
  methods: {
    ...mapActions('accounts', ['getAccountMeetingBySubscritionPeriod']),
    close() {
      this.$emit('close-dialog');
    },
    async fetchMeetingsByPeriod() {
      this.loading = true;
      const { type, startDate, endDate } = this.details;
      try {
        const response = await this.getAccountMeetingBySubscritionPeriod({
          key: this.$route.params.accountKey,
          type: type.toLowerCase(),
          startDate,
          endDate
        });

        this.data = response.data;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    async getStatusOptions() {
      this.loadingStatus = true;

      try {
        const { data } = await getRecipe('meetings.status.options');
        if (data && data.data && data.data.status) {
          this.statusOptions = _.keyBy(data.data.status, 'value');
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loadingStatus = false;
      }
    },
    getStatusDetails(status) {
      return this.statusDetails[status] ? this.statusDetails[status] : '';
    },
    openMeeting({ shortCode }) {
      const routeData = this.$router.resolve({
        name: 'meetingOverview',
        params: { shortcode: shortCode }
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
