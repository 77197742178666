'use strict';

/**
 * Creates a minimal RTV Question object to be POST'ed to API.
 *
 * @param  {Boolean} isTest            - isTest question
 * @param  {Boolean} overrideProxy     - proxy override
 * @param  {Boolean} isBallot          - Is ballot
 * @return {Object}                    - default RTV Question object
 */
export default function makeRTVQuestionObject(isTest, overrideProxy, isBallot) {
  if (isTest === true) {
    const defaultQuestion = {
      label: 'Please select your favorite three (3) pets:',
      choices: ['Dog', 'Cat', 'Hamster', 'Fish', 'Bird', 'Rabbit', 'Reptile'],
      maxChoices: 3,
      startAt: null,
      endAt: null,
      meta: {},
      ownerOccupied: false,
      isTest: true,
      questionKey: null,
      overrideProxy: false,
      isBallot: false
    };
    return defaultQuestion;
  } else {
    const defaultQuestion = {
      label: 'New Question!',
      choices: ['Enter choices here...'],
      maxChoices: 1,
      startAt: null,
      endAt: null,
      meta: {},
      ownerOccupied: false,
      isTest: false,
      questionKey: null,
      overrideProxy: overrideProxy,
      isBallot
    };
    return defaultQuestion;
  }
}
