'use strict';
import _ from 'lodash';
import moment from 'moment';

/**
 * format meeting date
 *
 * @param  {Date} date
 * @return {String}
 */
function formatDate(date) {
  if (!date) {
    return '';
  }
  return moment(date).format('MMMM Do, YYYY');
}
/**
 * replace merge tags into the subject value for conversation and notices
 *
 * @param  {Object} params
 * @param  {String} params.subject
 * @param  {Object} params.meeting
 * @return {String}
 */
export default function replaceSubjectMergeFields({ subject, meeting }) {
  if (!subject) {
    return null;
  }
  // Define the mergeField array structure
  let mergeFieldsArray = [
    {
      name: '[CORP_NAME]',
      value: _.get(meeting, 'corporation.legalNameShort', '')
    },
    {
      name: '[CONTACT_PREFERRED_NAME]',
      value:
        _.get(meeting, 'contact.preferredName', '') ||
        _.get(meeting, 'contact.name', '')
    },
    {
      name: '[CONTACT_NAME]',
      value: _.get(meeting, 'contact.name', '')
    },
    {
      name: '[MTG_DATE]',
      value: formatDate(_.get(meeting, 'meetingDate', ''))
    }
  ];

  mergeFieldsArray.forEach(function(field) {
    subject = subject.replace(field.name, field.value);
  });

  return subject;
}
