<template>
  <v-dialog :value="isOpen" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="validForm">
            <v-layout row>
              <v-flex xs12>
                <v-text-field
                  label="Email *"
                  type="email"
                  v-model="state.email"
                  :rules="rules.email"
                  required
                />

                <v-text-field
                  label="First Name *"
                  v-model="state.firstName"
                  :rules="rules.firstName"
                  required
                />

                <v-text-field
                  label="Last Name *"
                  v-model="state.lastName"
                  :rules="rules.lastName"
                  required
                />

                <!-- Show dropdown menu if the user being added is a collaborator -->
                <v-select
                  v-if="type === 'collaborator'"
                  v-model="state.collaboratorType"
                  :rules="rules.collaborator"
                  :items="options"
                  item-text="type"
                  item-value="collaboratorType"
                  label="Collaborator Type *"
                  dense
                  outlined
                  required
                ></v-select>

                <br />
                <p v-if="description" class="secondary--text">
                  {{ description }}
                </p>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>Close</v-btn>
        <v-btn
          :disabled="!validForm"
          @click.native="addUser"
          color="primary"
          flat
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validator from 'email-validator';
import strength from '@/lib/password-strength';
import { mapGetters } from 'vuex';
import { getRecipe } from '@/api/recipes';

export default {
  name: 'AddUserDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      default() {
        return null;
      }
    },
    title: {
      type: String,
      default() {
        return 'Add new user';
      }
    },
    type: {
      type: String,
      default() {
        return null;
      }
    },
    meetingOptionsType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: {
        email: '',
        firstName: '',
        lastName: '',
        collaboratorType: ''
      },
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ],
        firstName: [v => !!v || 'First name is required'],
        lastName: [v => !!v || 'Last name is required'],
        collaborator: [v => !!v || 'Collaborator Type is required']
      },
      setRandomPassword: true,
      validForm: false,
      options: []
    };
  },
  computed: {
    ...mapGetters('users', ['userListData']),
    passwordStrength() {
      return strength(this.state.newPassword);
    },
    passwordsMatch() {
      return this.state.newPassword === this.state.confirmPassword;
    }
  },
  mounted() {
    if (this.type === 'collaborator') {
      this.getCollaborators();
    }
  },
  methods: {
    addUser() {
      if (this.type === 'collaborator') {
        const collaborator = this.options.find(
          o => o.type === this.state.collaboratorType
        );
        const scopes = collaborator.scopes;
        this.$emit('add-user', { ...this.state, scopes });
      } else {
        this.$emit('add-user', this.state);
      }
      this.close();
    },
    close() {
      this.$emit('close-dialog');
      this.resetState();
    },
    resetState() {
      this.state = {
        email: '',
        firstName: '',
        lastName: '',
        collaboratorType: ''
      };
    },
    async getCollaborators() {
      try {
        const recipe = await getRecipe('meetings.users.collaborators');

        let collaboratorTypes = recipe.data.data.collaboratorTypes;

        // Depending on meeting type, filter out non-relevant collaborator types
        collaboratorTypes = recipe.data.data.collaboratorTypes.filter(
          collaborator =>
            this.meetingOptionsType === 'vbm'
              ? collaborator.type === 'vbm-assistant'
              : collaborator.type !== 'vbm-assistant'
        );

        this.options = collaboratorTypes;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
