<template>
  <v-container class="background" fill-height grid-list-md fluid>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg4>
        <!-- Info Alert -->
        <v-alert
          transition="slide-y-transition"
          class="mb-4 grey lighten-2"
          :outline="true"
          color="info"
          dismissible
          v-model="infoAlert.show"
          max-width="300px"
        >
          <b>New:</b> Starting on March 5, 2021, Two-Factor Authentication will
          be required to access your Dashboard. For more info,
          <b>
            <a
              href="https://www.getquorum.com/blog/two-factor-authentication-is-now-on-by-default/"
              target="_blank"
              rel="nofollow"
            >
              click here
            </a>
          </b>
          to read the blog post.
        </v-alert>

        <!-- Login Card -->
        <v-card>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout column align-center>
                <v-flex xs12>
                  <h1 class="headline mb-4">Security Code Required</h1>
                </v-flex>
              </v-layout>

              <v-layout row>
                <v-flex xs12>
                  <p>
                    Your account is secured using two-factor authentication.
                  </p>
                  <p>
                    A 6-digit security code has been sent to your email, please
                    enter it below.
                  </p>
                </v-flex>
              </v-layout>

              <v-layout row>
                <v-flex xs12>
                  <v-alert
                    transition="slide-y-transition"
                    class="mb-4"
                    :color="alert.type"
                    :icon="alert.icon"
                    v-model="alert.show"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-flex>
              </v-layout>
              <form action="" @submit.prevent="submit">
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="token"
                      id="token"
                      ref="token"
                      type="text"
                      label="Enter the 6-digit security code"
                      clearable
                      prepend-icon="lock"
                      autocomplete="email"
                      v-model="login.token"
                      @keyup.enter="$refs.password.focus()"
                      :disabled="loading"
                      required
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-checkbox v-model="trustThisDevice">
                    <template v-slot:label>
                      <div>
                        Trust this device. Don't ask for codes for 30 days.
                      </div>
                    </template>
                  </v-checkbox>
                </v-layout>

                <v-layout column class="mt-2" align-center>
                  <v-btn large type="submit" color="primary" :loading="loading"
                    >Submit</v-btn
                  >
                </v-layout>

                <v-layout column class="mt-4" align-center>
                  <p><a @click="resendChallenge">Resend Code Via Email</a></p>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import setAlert from '@/lib/set-login-alert';

export default {
  name: 'LoginMFAView',
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      login: {
        token: ''
      },
      loading: false,
      infoAlert: {
        show: true
      },
      alert: {
        type: null,
        message: null,
        icon: null,
        show: false
      },
      trustThisDevice: false
    };
  },
  created() {},
  mounted() {
    // This view expects a username and password to have been tried
    // before getting here. Boots to main login page if not supplied.
    if (!this.username || !this.password) {
      console.error('Missing username or password - redirect to home');
      window.location.href = '/';
    }

    this.sendTokenChallenge({
      username: this.username,
      password: this.password
    });

    // Check if any query params come in that need re-routing or custom display
    this.alert = setAlert(this.$route.query.alert);
  },
  methods: {
    ...mapActions(['sendTokenChallenge', 'doMFALogin']),

    resendChallenge() {
      this.sendTokenChallenge({
        username: this.username,
        password: this.password
      });

      this.alert = setAlert('mfa-resend');
    },

    async submit() {
      try {
        this.alert = setAlert('default');
        this.loading = true;

        // Re-attempt login
        await this.doMFALogin({
          username: this.username,
          password: this.password,
          token: this.login.token,
          path: this.path,
          trustThisDevice: this.trustThisDevice
        });
      } catch (err) {
        this.alert = setAlert('mfa-failed');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
