<template>
  <v-dialog
    max-width="800px"
    :value="value"
    persistent
    @keydown.esc="emitClose"
    @input="emitInput"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Upload document
      </v-card-title>

      <v-card-text>
        <meeting-documents-uploader
          v-if="type === 'meeting'"
          :short-code="shortCode"
          @update="emitClose"
        />
        <meeting-files-uploader
          v-else-if="type === 'private'"
          :short-code="shortCode"
          @update="emitClose"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="emitClose">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* Description:
   Dialog that re-uses the same upload components from meetingVbmDocuments, swaps between components based on type
*/

import MeetingDocumentsUploader from './MeetingVbmDocumentsUploader.vue';
import MeetingFilesUploader from './MeetingVbmFilesUploader';

export default {
  name: 'MeetingVbmDocumentsUploadDialog',
  components: {
    MeetingDocumentsUploader,
    MeetingFilesUploader
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'meeting'
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  methods: {
    // Needed for v-model on component to work
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.round-button {
  border-radius: 5px;
}
</style>
