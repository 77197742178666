<template>
  <v-card>
    <v-card-text class="card-padding">
      <meeting-details-list-item
        formatting="mb-2 mt-1 mx-2"
        icon="subject"
        label="Description"
        :value="description"
      >
        <template slot="content">
          <span>{{ description }}</span>
        </template>
      </meeting-details-list-item>

      <v-divider />

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="location_on"
        label="Location"
      >
        <template slot="content">
          <span>{{ location }}</span>
        </template>
      </meeting-details-list-item>

      <v-divider />

      <template v-if="partner">
        <meeting-details-list-item
          formatting="mb-2 mt-1 mx-2"
          icon="account_circle"
          label="Partner"
          :value="partner.name"
        >
          <template slot="content">
            <span>{{ partner.name }}</span>
          </template>
        </meeting-details-list-item>

        <v-divider />
      </template>

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="badge"
        label="Meeting Code"
      >
        <template slot="content">
          <span>{{ shortCode }}</span>
        </template>
      </meeting-details-list-item>

      <v-divider />

      <template v-if="meetingType === 'meeting' && enableVoterRegistration">
        <meeting-details-list-item
          formatting="my-2 mx-2"
          icon="link"
          label="Public Registration"
        >
          <template slot="content">
            <a :href="registrationLink" target="_blank">{{
              registrationLink
            }}</a>
          </template>
        </meeting-details-list-item>

        <v-divider />
      </template>

      <meeting-details-list-item
        v-if="castId"
        formatting="my-2 mx-2"
        icon="video_call"
        label="Webcast Details"
      >
        <template slot="content">
          <span class="capitalize">{{ castProvider }} ID: {{ castId }} </span>
        </template>
      </meeting-details-list-item>

      <v-divider v-if="castId" />

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="meeting_room"
        label="Pre-meeting Date"
        v-if="formatRehearsalDate"
      >
        <template slot="content">
          Pre-meeting will be held on
          <strong>{{ formatRehearsalDate }}</strong>
          ({{ rehearsalDuration }} minutes)
          <timezone-helper :date="rehearsalDate" />
        </template>
      </meeting-details-list-item>

      <v-divider v-if="formatRehearsalDate" />

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="event"
        label="Meeting Date"
      >
        <template slot="content">
          Meeting will be held on
          <strong>{{ formatMeetingDate }}</strong>
          <timezone-helper :date="meetingDate" />
        </template>
      </meeting-details-list-item>

      <v-divider />

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="event_busy"
        label="Submissions Deadline"
      >
        <template slot="content">
          <span v-if="expiryDate">
            Online submissions will close
            <strong>{{ formatExpiryDate }}</strong>
            <timezone-helper :date="expiryDate" />
          </span>
          <span v-else>
            TBD
          </span>
        </template>
      </meeting-details-list-item>

      <v-divider />

      <meeting-details-list-item
        formatting="my-2 mx-2"
        icon="picture_as_pdf"
        label="Meeting Documents"
      >
        <template slot="content">
          <div v-if="documents" class="text-ellipsis">
            <a
              v-for="(pkg, index) in documents"
              :key="index"
              :href="pkg.document.url"
              target="_new"
            >
              {{ formatDocumentText(pkg.document) }}<br />
            </a>
          </div>
          <div v-else class="label">
            No Uploads
          </div>
        </template>
      </meeting-details-list-item>

      <v-divider />

      <!-- Expanded content section -->
      <v-slide-y-transition>
        <div v-show="show">
          <meeting-details-list-item
            formatting="my-2 mx-2"
            icon="domain"
            label="Corporation"
          >
            <template slot="content"
              >{{ corporation.legalName }} ({{
                corporation.legalNameShort
              }})</template
            >
          </meeting-details-list-item>
          <v-divider />

          <meeting-details-list-item
            formatting="my-2 mx-2"
            icon="contact_mail"
            label="Contact"
          >
            <template slot="content">
              <strong>{{ contact.name }}</strong>
              <br />
              {{ contact.email }}
            </template>
          </meeting-details-list-item>
        </div>
      </v-slide-y-transition>
    </v-card-text>

    <v-card-actions class="ma-0 pa-0">
      <v-spacer />
      <v-tooltip left>
        <div slot="activator">
          <v-btn class="ma-1" icon @click.native="show = !show">
            <v-icon color="blue">{{
              show ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
            }}</v-icon>
          </v-btn>
        </div>
        <span>Click to toggle expand</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatDate } from '@/helpers.js';
import filesize from 'filesize';
import MeetingDetailsListItem from './MeetingDetailsListItem';
import TimezoneHelper from '@/components/TimezoneHelper';

export default {
  name: 'MeetingDetailsCard',
  components: {
    MeetingDetailsListItem,
    TimezoneHelper
  },
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    about: { type: String, default: '' },
    location: { type: String, default: '' },
    meetingDate: { type: String, default: '' },
    meetingTimezone: { type: String, default: '' },
    rehearsalDate: { type: String, default: '' },
    rehearsalDuration: { type: Number, default: 0 },
    expiryDate: { type: String, default: '' },
    nominationExpiryDate: { type: String, default: '' },
    meetingType: { type: String, default: '' },
    partner: {
      type: Object,
      default() {
        return {};
      }
    },
    corporation: {
      type: Object,
      default() {
        return {};
      }
    },
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    addresses: {
      type: Array,
      default() {
        return [];
      }
    },
    documents: {
      type: Array,
      default() {
        return [];
      }
    },
    castId: {
      type: String,
      default() {
        return '';
      }
    },
    castProvider: {
      type: String,
      default() {
        return '';
      }
    },
    enableVoterRegistration: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      show: false
    };
  },
  computed: {
    formatMeetingDate() {
      return formatDate(
        this.meetingDate,
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    formatRehearsalDate() {
      if (!this.rehearsalDate) {
        return null;
      }
      return formatDate(
        this.rehearsalDate,
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    formatExpiryDate() {
      return formatDate(
        this.expiryDate,
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    registrationLink() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? `https://vote.getquorum.com`
          : 'http://localhost:8085';

      return `${baseUrl}/${this.shortCode}/registration`;
    }
  },
  methods: {
    cardClicked() {
      this.$router.push({
        name: 'meeting',
        params: { shortcode: this.shortCode }
      });
    },
    isNumber(obj) {
      return !isNaN(obj);
    },
    formatDocumentText(document) {
      if (document.size && this.isNumber(document.size)) {
        return `${document.name} (${filesize(document.size)})`;
      }
      return `${document.name} (Invalid filesize)`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';

.card-title {
  font-size: 16px;
  font-weight: 500;
}
.card-padding {
  padding: 30px 20px 0px 20px;
}
.label-heavy {
  font-weight: 500;
  opacity: 0.85;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media only screen and (max-width: 920px) {
  .card-padding {
    padding: 10px 0px;
  }
  .label-heavy {
    display: none;
  }
}
</style>
