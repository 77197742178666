import http from './requestType';

/**
 * Returns a list of users
 * @return {Promise} - Promise
 */
export function getUsersList() {
  return http._get(`Users`);
}

export function getUsersPartialList({ search }) {
  return http._get(`Users?search=${search}`);
}

/**
 * Get User by key
 * @param  {String} userKey - the user's key
 * @return {Promise}        - the found user
 */
export function getUserByKey(userKey) {
  return http._get(`Users/${userKey}`);
}

/**
 * Create a new user
 * @param  {Object} user - the new user payload
 * @return {Promise} - Promise
 */
export function postUser(user) {
  return http._post(`Users`, user);
}

/**
 * Update a user info partially (Patch)
 * @param  {String} userKey - the user to update
 * @param  {Object} user    - the user update payload
 * @return {Promise}        - Promise
 */
export function patchUser(userKey, user) {
  return http._patch(`Users/${userKey}`, user);
}

/**
 * Delete a user
 * @param  {String} userKey - the user to delete
 * @return {Promise}        - the response
 */
export function deleteUser(userKey) {
  return http._delete(`Users/${userKey}`);
}

/**
 * Send a password reset email for this user
 * @param  {String} userKey - the user to send the email to
 * @return {Promise}        - the response
 */
export function postPasswordEmail(userKey) {
  return http._post(`Users/${userKey}/password-email`);
}

/**
 * Send a welcome reminder / invite email for this user
 * @param  {String} userKey - the user to send the email to
 * @return {Promise}        - the response
 */
export function postWelcomeEmail(userKey) {
  return http._post(`Users/${userKey}/welcome`);
}

/**
 * Suspend a user
 * @param  {String} userKey - the user to suspend
 * @param  {string} reason  - reason for suspension
 * @return {Promise}        - the response
 */
export function postSuspendUser(userKey, reason) {
  return http._post(`Users/${userKey}/suspend`, { reason });
}

/**
 * Reactivate a suspended user
 * @param  {String} userKey - the user to reactivate
 * @param  {string} reason  - reason for reactivation
 * @return {Promise}        - the response
 */
export function postReactivateUser(userKey, reason) {
  return http._post(`Users/${userKey}/reactivate`, { reason });
}

/**
 * Get all accounts the user is a member of
 * @param  {String} userKey - the user to suspend
 * @return {Promise}        - the response
 */
export function getUserAccounts(userKey) {
  return http._get(`Users/${userKey}/accounts`);
}

/**
 * Get all meetings the user is a collaborator on
 * @param  {String} userKey - the user to suspend
 * @return {Promise}        - the response
 */
export function getMeetingsOfUserAsCollaborator(userKey) {
  return http._get(`Users/${userKey}/collaborations`);
}

/**
 * Set User's MFA Status
 * @param  {Object} param
 * @param  {String} param.userKey - the user to suspend
 * @param  {Number} param.when    - when to turn on MFA
 * @return {Promise}              - the response
 */
export function putUserMfa({ userKey, when }) {
  return http._put(`Users/${userKey}/mfa/${when}`);
}

/**
 * Get all agents associated to a user
 * @param  {Object} param
 * @param  {String} param.userKey - the user key
 * @return {Promise}              - the response
 */
export function getUserAgents({ userKey }) {
  return http._get(`Users/${userKey}/agents`);
}
