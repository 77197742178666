'use strict';

/**
 * @fileoverview Vuex module for File actions
 */

import bluebird from 'bluebird';
import * as api from '@/api/files';
import forceDownloadUrl from '@/lib/force-download-url';

export default {
  namespaced: true,
  state: {},
  actions: {
    /**
     * Upload files to S3 and save to API
     * @param {Array}  files            the files to upload
     * @param {String} categoryType     category the files belong to (eg. checklist)
     * @param {String} categoryTypeKey  category key (eg. checklist key)
     */
    async uploadFiles({ dispatch }, { files, categoryType, categoryTypeKey }) {
      let jobs = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const res = await api.getUploadSignedUrl(file.name);
        const { Bucket, Key, putObjectSignedUrl } = res.data;

        await api.putAWS(putObjectSignedUrl, file);

        const payload = {
          name: file.name,
          package: {
            Bucket,
            Key,
            size: file.size,
            name: file.name,
            mimetype: file.type
          },
          categoryType,
          categoryTypeKey
        };

        jobs.push(api.saveFile(payload));
      }
      return await bluebird.all(jobs);
    },

    /**
     * Upload single file to S3
     * @param {Array}  files      the files to upload
     * @param {String} folder     file folder
     */
    async uploadSingleFile({ dispatch }, { file, folder }) {
      const res = await api.getUploadSignedUrl(file.name, 'pdf', folder);
      const { Bucket, Key, putObjectSignedUrl, getObjectSignedUrl } = res.data;

      await api.putAWS(putObjectSignedUrl, file);

      const payload = {
        name: file.name,
        Bucket,
        Key,
        size: file.size,
        mimetype: file.type,
        url: getObjectSignedUrl
      };

      return payload;
    },

    /**
     * Delete a file
     * @param {String} fileKey - the file key to remove
     */
    async removeFile({ dispatch }, fileKey) {
      try {
        return await api.deleteFile(fileKey);
      } catch (err) {
        throw err;
      }
    },

    /**
     * This route returns the signed URL to retrieve a file stored on S3. A number of our API
     * responses contain a file property comprised of a {Bucket, Key} object pairing. Pass the
     * Bucket and Key into this route to view the file.
     * @param  {String} bucket  - The S3 Bucket where the file is stored.
     * @param  {String} key     - The S3 file Key
     * @return {Promise}        - Promise
     */
    async getFile({ dispatch }, { bucket, key }) {
      try {
        return await api.getDownloadSignedUrl(bucket, key);
      } catch (err) {
        throw err;
      }
    },

    /**
     * This action retrieves the file from an S3 object and forces it to download
     * and save to local drive.
     * @param  {string} Bucket  - The S3 Bucket where the file is stored.
     * @param  {string} Key     - The S3 file Key
     * @param  {string} name    - the file name to save to drive
     */
    async downloadFile({ dispatch }, { Bucket, Key, name }) {
      try {
        const { data } = await api.getDownloadSignedUrl(Bucket, Key);

        // If no filename, open the url in the browser so that user can
        // decide what name to use.
        if (!name) {
          return window.open(data.url, '_blank');
        }

        // Otherwise for the file to download
        await forceDownloadUrl({ url: data.url, name });
      } catch (err) {
        console.error('Error downloading file from action', err);
        throw err;
      }
    }
  }
};
