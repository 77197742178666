<template>
  <div>
    <!-- File upload area, no package exists -->
    <div v-if="processing" class="dz-area">
      <div class="dz-message">
        <p>Uploading...</p>
        <v-progress-circular :size="50" indeterminate color="primary" />
      </div>
    </div>
    <div v-if="!processing" class="dz-area">
      <div class="dz-message">
        <v-icon color="grey" large>backup</v-icon>
        <span class="highlight">
          Click or Drag and Drop files here to upload
        </span>
      </div>
      <div class="input-area">
        <input
          multiple
          type="file"
          @change.prevent="onFileChange"
          accept=".pdf"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingProofUploader',
  components: {},
  props: {},
  data() {
    return {
      processing: false
    };
  },
  methods: {
    async onFileChange(e) {
      try {
        this.processing = true; // Trigger progress animation

        const files = e.target.files || e.dataTransfer.files;

        const detailedFiles = [];

        for (let i = 0; i < files.length; i++) {
          detailedFiles.push(files[i]);
        }

        this.$emit('update', {
          key: 'meetingProof.value',
          value: detailedFiles,
          addFile: true
        });
      } catch (err) {
        console.error('Error uploading', err);
      } finally {
        // Clear to detect any change event
        e.target.value = '';
        this.processing = false;
      }
    }
  }
};
</script>

<style scoped>
.dz-area {
  position: relative;
  display: flex;
  justify-content: center;
  height: 150px;
  cursor: pointer;
  border: 2px dashed #e8e8e8;
}
.dz-area > .dz-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #97a8be;
}
.dz-area > .dz-message > .highlight {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #20a0ff;
}
.dz-area .input-area {
  position: absolute;
  width: 100%;
  height: 100%;
}
.dz-area .input-area input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
  cursor: hand;
}
</style>
