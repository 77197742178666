<template>
  <div :class="newLayout && 'mt-4'">
    <!-- Active Meetings List -->
    <meetings-view-list
      campaign-type="upcoming"
      :campaigns="filteredActive"
      :new-layout="newLayout"
      :status-options="statusOptions"
    />

    <v-divider class="mt-4 mb-2" />

    <!-- Past Meetings List -->
    <meetings-view-list
      campaign-type="past"
      :campaigns="filteredPast"
      :new-layout="newLayout"
      :status-options="statusOptions"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { sortByProperty } from '@/helpers';
import { mapGetters } from 'vuex';
import { getRecipe } from '@/api/recipes';

import MeetingsViewList from './MeetingsViewList';

export default {
  name: 'MeetingsViewCards',
  components: {
    MeetingsViewList
  },
  props: {
    campaigns: {
      type: Array,
      default: () => []
    },
    newLayout: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      statusOptions: {}
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    // Meetings that are happening today or in the future
    filteredActive() {
      const campaigns = this.campaigns.filter(
        card => !this.isPast(card.meetingDate)
      );
      return sortByProperty(campaigns, 'meetingDate', 'oldest');
    },

    // Meetings from yesterday and further in the past
    filteredPast() {
      const campaigns = this.campaigns.filter(card =>
        this.isPast(card.meetingDate)
      );
      return sortByProperty(campaigns, 'meetingDate', 'newest');
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.getStatusOptions();
    }
  },
  methods: {
    isPast(meetingDate) {
      const now = moment().startOf('day');
      const date = moment(meetingDate).startOf('day');
      return date.diff(now, 'days') < 0;
    },
    async getStatusOptions() {
      const { data } = await getRecipe('meetings.status.options');
      if (data && data.data && data.data.status) {
        this.statusOptions = _.keyBy(data.data.status, 'value');
      }
    }
  }
};
</script>

<style scoped></style>
