<template>
  <!-- The Route View -->
  <v-content>
    <!-- Alert status for service agreement -->
    <!--  <service-agreement-alert
      v-if="isAdmin && currentMeetingAccount"
      :current-meeting-account="currentMeetingAccount"
    /> -->

    <!-- Nested back button visible where $route.meta.nestedPage property exists -->
    <meeting-nav-nested-tab v-if="nestedPage" :nested-page="nestedPage" />

    <!-- Show header if navLabel is truthy -->
    <v-layout class="justify-space-between align-center">
      <h5
        v-if="this.$route.meta.navLabel"
        class="headline font-weight-bold pl-4 pt-4"
      >
        {{ this.$route.meta.navLabel }}
      </h5>

      <v-btn
        v-if="enableChatWidget"
        fab
        small
        slot="activator"
        color="primary"
        @click.native="displayHelpscout"
        class="mt-4 mr-4"
      >
        <v-icon>chat_bubble_outline</v-icon>
      </v-btn>
    </v-layout>

    <v-divider v-if="this.$route.meta.navLabel" class="gq-divider mx-4" />

    <v-alert
      :value="this.$route.meta.workInProgress"
      color="warning"
      icon="construction"
      outline
      class="ma-3"
    >
      <span>This page is currently a work in progress</span>
      <span v-if="this.$route.meta.adminOnly"
        >, and is only visible to admins</span
      >
    </v-alert>

    <transition name="slide-fade" mode="out-in">
      <div class="load-center" v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <error-status
        v-else-if="error"
        :code="error.status"
        :message="error.statusText"
        @reload="init"
      />
      <router-view
        v-else
        :current-meeting="currentMeeting"
        :account="currentMeetingAccount"
      />
    </transition>
  </v-content>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ErrorStatus from '@/components/ErrorStatus';
import MeetingNavNestedTab from './MeetingNavNestedTab';
import ServiceAgreementAlert from '@/components/ServiceAgreementAlert';

export default {
  name: 'MeetingMain',
  components: {
    ErrorStatus,
    MeetingNavNestedTab,
    ServiceAgreementAlert
  },
  watch: {
    enableChatWidget: {
      handler(value) {
        if (value) {
          // Ensures we try loading chat after a hard reload
          this.loadChat();
        } else {
          // Destroy the chat is the option is false
          this.chatDestroy();
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', ['login']]),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('accounts', ['accountList']),
    currentMeeting() {
      return this.meetingByShortCode(this.$route.params.shortcode);
    },
    currentMeetingAccount() {
      return _.get(this.currentMeeting, 'accountKey')
        ? this.accountList[this.currentMeeting.accountKey]
        : null;
    },
    nestedPage() {
      // If a route.meta nestedPage property exists, return the value
      if (this.$route.meta.hasOwnProperty('nestedPage')) {
        return this.$route.meta.nestedPage;
      }
    },
    enableChatWidget() {
      return (
        this.currentMeeting &&
        this.currentMeeting.options &&
        this.currentMeeting.options.enableChatWidget
      );
    }
  },
  data: () => ({
    loading: true,
    drawer: null,
    error: null
  }),
  created() {
    // Subscribe to the pusher channel for this meeting's Dashboard events
    this.initPusher({ shortCode: this.$route.params.shortcode });
    this.init();
  },
  mounted() {
    if (this.enableChatWidget) {
      this.loadChat();
    }

    // Just in case the user browses to a different meeting, it'll update
    // the Helpscout beacon identity even if the other meeting doesn't have
    // chat enabled.
    this.chatIdentify({
      ...this.login.profile,
      shortCode: this.$route.params.shortcode
    });
  },
  methods: {
    ...mapActions(['initPusher', 'destroyPusher']),
    ...mapActions('meetings', ['getMeeting']),
    ...mapActions('chat', [
      'chatInit',
      'chatIdentify',
      'chatDestroy',
      'chatPosition',
      'toggleHelpscout'
    ]),
    async init() {
      this.loading = true;
      try {
        await this.getMeeting({
          shortCode: this.$route.params.shortcode
        });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      } finally {
        this.loading = false;
      }
    },
    loadChat() {
      if (this.enableChatWidget) {
        console.log('Enabling experimental chat');
        this.chatInit().then(() => {
          console.log('Chat Loaded');
          // update position based on the drawer
          const that = this;
          setTimeout(function() {
            that.chatPosition({ shortCode: that.$route.params.shortcode });
          }, 2000);
        });

        this.chatIdentify({
          ...this.login.profile,
          shortCode: this.$route.params.shortcode
        });
      }
    },
    displayHelpscout() {
      this.toggleHelpscout();
    }
  },
  destroyed() {
    this.destroyPusher();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';
.load-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
</style>
