<template>
  <router-view
    :notice-templates-list="noticeTemplateList"
    :notice-templates-data="noticeTemplateListData"
    :last-data-fetched="noticeTemplateDataLastFetched"
    :template-type-items="templateTypeItems"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import { handleError } from '@/helpers';

export default {
  name: 'AdminNoticeTemplatesMain',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('noticeTemplates', [
      'noticeTemplateListData',
      'noticeTemplateList',
      'noticeTemplateDataLastFetched',
      'templateTypeItems'
    ]),
    ...mapGetters('meetings/notices', ['noticeMergeFields'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('noticeTemplates', ['getNoticeTemplates']),
    ...mapActions('meetings/notices', ['getNoticeFields']),
    async init() {
      try {
        await this.getNoticeTemplates();

        // Check if noticeMergeFields is populated before requesting
        if (_.isEmpty(this.noticeMergeFields)) {
          await this.getNoticeFields();
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    }
  }
};
</script>

<style scoped></style>
