<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Meeting Invite Template
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Define the meeting invite template. This invite email is sent from the
      Membership tab.
    </div>

    <div v-if="options.type === 'vbm'">
      <v-alert outline color="info" icon="info" :value="true">
        The meeting invite template is not editable for VBMs.
      </v-alert>
    </div>

    <div v-else>
      <rich-text-editor
        :show-custom-fields="true"
        custom-label="Custom
            fields defined in settings"
        :custom-fields="customFields"
        :body="inviteTemplate"
        :key="renderKey"
        @update="onChange('inviteTemplate', $event)"
      />

      <div class="preview-container">
        <v-btn flat @click.native="getDefaultTemplate"
          >use default invite template</v-btn
        >
        <v-btn flat @click.native="getPreview()"
          ><v-icon left>remove_red_eye</v-icon>Preview
        </v-btn>
      </div>

      <preview-dialog
        v-model="dialog.preview"
        :html="inviteTemplatePreview.content"
        :recipientCount="0"
        :recipientTotal="0"
        @close="dialog.preview = false"
        :recipientInfoHidden="true"
        containerStyles="px-4"
      />
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { getRecipe } from '@/api/recipes';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';

export default {
  name: 'MeetingSettingsInviteTemplateInput',
  components: {
    RichTextEditor,
    PreviewDialog
  },
  props: {
    template: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    shortCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inviteTemplate: '',
      renderKey: 0,
      dialog: {
        preview: false
      }
    };
  },
  computed: {
    ...mapGetters('meetings', ['inviteTemplatePreview']),
    customFields() {
      const customUnitFields = this.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    }
  },
  methods: {
    ...mapActions('meetings/conversations', ['getConversationFields']),
    ...mapActions('meetings', ['getInviteTemplatePreview']),
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    async init() {
      if (!this.template) {
        const recipe = await getRecipe('meetings.templates.portal_invite_body');
        const template = recipe.data.data.template;
        this.inviteTemplate = template;
        this.renderKey++;
      } else {
        this.inviteTemplate = this.template;
      }

      await this.getConversationFields();
    },
    async getDefaultTemplate() {
      const recipe = await getRecipe('meetings.templates.portal_invite_body');
      const template = recipe.data.data.template;
      this.inviteTemplate = template;
      this.renderKey++;
      this.onChange('inviteTemplate', this.inviteTemplate);
    },
    async getPreview() {
      try {
        await this.getInviteTemplatePreview({
          shortCode: this.shortCode
        });
        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.preview-container {
  text-align: right;
}
</style>
