<template>
  <div>
    <file-upload-form-tmp
      :attachment="attachment"
      accept=".pdf"
      icon="attach_file"
      folder="proxy-uploads"
      @upload="emitUpload"
    />
  </div>
</template>

<script>
import FileUploadFormTmp from '@/components/FileUploadFormTmp';

export default {
  name: 'MeetingProxiesCreateUpload',
  props: {
    attachment: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    FileUploadFormTmp
  },
  methods: {
    emitUpload(res) {
      this.$emit('upload', res);
    }
  }
};
</script>

<style scoped></style>
