<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on }">
        <a v-on="on" class="label accent--text" @click="dialog">
          View Snapshots
        </a>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Attendance snapshots
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="snapshotList"
            :loading="loading"
            sort-by.sync="timestamp"
          >
            <template slot="items" slot-scope="props">
              <td>
                <v-edit-dialog
                  :return-value.sync="props.item.label"
                  large
                  lazy
                  persistent
                  @save="handleEdit(props.item)"
                  @cancel="edit = false"
                >
                  <a class="text-xs-left label blue--text">{{
                    props.item.label
                  }}</a>
                  <template v-slot:input>
                    <div class="mt-3 title">Update Label</div>
                  </template>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.label"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-xs-left">
                {{ getTotalAttendanceProxies(props.item.data) }}
              </td>
              <td class="text-xs-left">
                {{ props.item.data.unitsInAttendance }}
              </td>
              <td class="text-xs-left">
                {{ props.item.data.eligibleUnitsInAttendance }}
              </td>
              <td class="text-xs-left">{{ snapshotDate(props.item) }}</td>
              <td class="text-xs-left">
                <v-btn
                  class="red--text lighten-2"
                  icon
                  @click="handleDeleteDialog(props.item)"
                >
                  <v-icon color="red">delete</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      title="Delete snapshot"
      :text="`Do you want to delete ${current.label} snapshot`"
      :dialog="current.dialog"
      confirm-text="Yes"
      decline-text="No"
      @confirm="handleDelete"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { formatDate } from '@/helpers.js';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
  name: 'SnapshotDialog',
  props: {
    snapshotList: {
      type: Array,
      required: true
    },
    meetingTimezone: { type: String, default: '' }
  },
  components: {
    ConfirmDialog
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      dialog: false,
      loading: false,
      edit: false,
      current: {
        key: '',
        dialog: false,
        label: '',
        edit: false
      },
      headers: [
        { align: 'left', text: 'Label', value: 'label', width: '200px' },
        {
          align: 'left',
          text: 'Electronic and paper proxies',
          value: 'proxies',
          width: '100px'
        },
        {
          align: 'left',
          text: 'Units in attendance',
          value: 'unitsInAttendance',
          width: '100px'
        },
        {
          align: 'left',
          text: 'Eligible units in attendance',
          value: 'eligibleUnitsInAttendance',
          width: '100px'
        },
        { align: 'left', text: 'Timestamp', value: 'timestamp' },
        { text: '', value: '' }
      ]
    };
  },
  methods: {
    ...mapActions('meetings/attendanceSnapshots', [
      'removeSnapshot',
      'patchSnapshot'
    ]),
    getTotalAttendanceProxies(snapshot) {
      const electronicProxies = snapshot.electronicProxies || 0;
      const paperProxies = snapshot.paperProxies || 0;
      const total = electronicProxies + paperProxies;

      return total;
    },
    snapshotDate(snapshot) {
      return formatDate(
        _.get(snapshot, 'createdAt', ''),
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'dddd, MMM Do YYYY, h:mmA zz'
      );
    },
    handleDeleteDialog(snapshot) {
      this.current = {
        key: snapshot.key,
        dialog: true,
        label: snapshot.label
      };
    },
    async handleDelete(value) {
      if (value) {
        try {
          this.loading = true;
          await this.removeSnapshot({
            shortCode: this.shortCode,
            key: this.current.key
          });
        } catch (err) {
          this.$events.$emit('toastEvent', err.response);
        } finally {
          this.loading = false;
        }
      }
      this.current.dialog = false;
    },
    async handleEdit(value) {
      try {
        this.loading = true;
        this.edit = false;
        await this.patchSnapshot({
          shortCode: this.shortCode,
          key: value.key,
          label: value.label
        });
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
