<template>
  <v-dialog persistent :value="isOpen" max-width="600px">
    <v-card>
      <v-card-text>
        <v-alert
          type="warning"
          outline
          color="#74C365"
          icon="thumb_up_off_alt"
          dense
          :value="true"
        >
          <strong>ELIGIBLE Candidate</strong><br />
          <ul>
            <li>
              The candidate is in the running for the available position
            </li>
          </ul>
        </v-alert>
        <v-alert
          type="warning"
          outline
          color="#FD5C63"
          icon="thumb_down_off_alt"
          :value="true"
        >
          <strong>INELIGIBLE Candidate</strong><br />
          <ul>
            <li>The candidate is no longer eligible for the position<br /></li>
            <li>
              Advance votes cast for this candidate will not be counted<br />
            </li>
            <li>Vote totals will be recalculated<br /></li>
            <li>
              Any optional votes that have been cast and are now valid from the
              ineligible candidate will be counted<br />
            </li>
          </ul>
        </v-alert>
        <v-alert outline color="#7E7E7E" :value="true">
          <h3>Example: General Election with 2 available positions</h3>
          <br />
          <h4>Owner Jamie votes:</h4>
          Candidate A<br />
          Candidate B<br />
          Candidate C (optional vote)<br /><br />

          <h4>Candidate B is marked as INELIGIBLE:</h4>
          Candidate A<br />
          <strong><strike>Candidate B</strike></strong
          ><br />
          Candidate C<br /><br />

          <h4>
            Candidate C will now receive an additional vote from Jamie's ballot
          </h4>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click="emitClose">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CandidateEligibilityToggleDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 16px;
  padding-right: 20px;
}
.subtitle {
  font-size: 15px;
  padding-right: 20px;
}
</style>
