<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <meeting-units-list-view-table
          :units-list-data="unitsListData"
          :current-meeting="currentMeeting"
          :display-consent-details="displayConsentDetails"
          @selected="setSelected"
          @rowExpand="setRowExpand"
          @voterSelected="setVoterSelected"
          @customFieldSelected="setCustomFieldSelected"
          @tagSelected="setTagSelected"
          @downloadFile="downloadFile"
          @deleteFile="deleteFile"
          @openDialog="openDialog"
          @closeDialog="closeDialog"
          @sendInvite="confirmSendInvite"
          @viewProxy="viewProxy"
        />

        <!-- Generate control number dialog -->
        <generate-control-numbers-dialog
          v-model="dialog.generateControlNumbers"
          @generateControlNumbers="generateControlNumbers"
          @close="dialog.generateControlNumbers = false"
        />

        <!-- Add unit dialog -->
        <add-unit-dialog
          v-model="dialog.addUnit"
          :addresses="currentMeeting.addresses"
          @addUnit="addUnit"
          @close="dialog.addUnit = false"
        />

        <!-- Edit Unit -->
        <edit-unit-dialog
          v-model="dialog.editUnit"
          :addresses="currentMeeting.addresses"
          :unit-selected="unitsList[select.unitId]"
          @update="updateUnit"
          @close="dialog.editUnit = false"
        />

        <!-- Voter -->
        <add-voter-dialog
          v-model="dialog.addVoter"
          :unit-selected="unitsList[select.unitId]"
          :contact="currentMeeting.contact"
          :has-webcast="hasWebcast"
          @addVoter="addVoter"
          @close="dialog.addVoter = false"
        />

        <edit-voter-dialog
          v-model="dialog.editVoter"
          :voter-selected="select.voter"
          :meeting-timezone="currentMeeting.meetingTimezone"
          :options="currentMeeting.options"
          :virtual-meeting-type="currentMeeting.options.virtualMeetingType"
          :has-webcast="hasWebcast"
          :display-consent-details="displayConsentDetails"
          @close="dialog.editVoter = false"
        />

        <send-invite-dialog
          v-model="invite.confirm"
          :voters="invite.voters"
          :loading="loading"
          :notices="resendableNotices"
          :default="invite.default"
          :current-meeting="currentMeeting"
          :is-admin="isAdmin"
          @send="handleSend"
          @invite="handleInvite"
          @sendDial="handleSendDial"
          @close="invite.confirm = false"
        />

        <!-- Edit custom field dialog -->
        <add-custom-field-dialog
          v-model="dialog.addCustomField"
          :unit-selected="unitsList[select.unitId]"
          :custom-fields="currentMeeting.options.customUnitFields"
          @update="updateUnit"
          @close="dialog.addCustomField = false"
        />
        <edit-custom-field-dialog
          v-model="dialog.editCustomField"
          :unit-selected="unitsList[select.unitId]"
          :custom-field="select.customField"
          @update="updateUnit"
          @close="dialog.editCustomField = false"
        />

        <!-- Multiselected dialogs -->
        <addresses-update-dialog
          v-model="dialog.addresses"
          :addresses="currentMeeting.addresses"
          :selected="selected"
          @close="dialog.addresses = false"
          @update="updateAddresses"
        />
        <delete-units-dialog
          v-model="dialog.deleteUnits"
          :selected="selected"
          @close="dialog.deleteUnits = false"
          @delete="deleteSelected"
        />
        <delete-all-units-dialog
          v-model="dialog.deleteAllUnits"
          :total-items="pagination.totalItems"
          @close="dialog.deleteAllUnits = false"
          @delete="deleteAllUnits"
        />
        <reset-attendance-dialog
          v-model="dialog.resetAttendance"
          :selected="selected"
          @close="dialog.resetAttendance = false"
          @reset="resetAttendance"
        />
        <delete-files-dialog
          v-model="dialog.deleteFiles"
          :selected="selected"
          @close="dialog.deleteFiles = false"
          @deleteFiles="deleteFiles"
        />
        <bulk-ineligible-dialog
          v-model="dialog.bulkIneligible"
          :is-admin="isAdmin"
          :selected="selected"
          @close="dialog.bulkIneligible = false"
          @update="updateBulkIneligible"
        />
        <bulk-invite-dialog
          v-model="dialog.bulkInvite"
          :selected="selected"
          :loading="loading"
          @close="dialog.bulkInvite = false"
          @send="sendBulkInvites"
        />
        <bulk-registration-page-dialog
          v-model="dialog.bulkRegistrationPage"
          :selected="selected"
          :loading="loading"
          @close="dialog.bulkRegistrationPage = false"
          @send="sendBulkVoterRegistrationPages"
        />
        <remove-custom-data-dialog
          v-model="dialog.removeCustomData"
          :selected="selected"
          :loading="loading"
          :custom-data-keys="customDataKeys"
          @close="dialog.removeCustomData = false"
          @removeCustomData="removeCustomData"
        />
        <assign-shares-dialog
          v-model="dialog.assignShares"
          :selected-rows="selected"
          :addresses="currentMeeting.addresses"
          :loading="loading"
          @close="dialog.assignShares = false"
          @update="updateAssignShares"
        />
        <assign-shares-undo-dialog
          v-model="dialog.assignSharesUndo"
          :selected-rows="selected"
          @close="dialog.assignSharesUndo = false"
          @undoAssign="handleUndoAssign"
        />

        <!-- Revoke Proxy -->
        <revoke-proxy-dialog
          v-model="dialog.revokeProxy"
          :unit-selected="select"
          :data="unitsListData"
          @close="dialog.revokeProxy = false"
          @delete="revokeProxy"
        />

        <!-- Unit Tags -->
        <mark-ineligible-dialog
          v-model="dialog.markIneligible"
          :unit="unitsList[select.unitId]"
          @close="dialog.markIneligible = false"
          @update="updateUnit"
        />

        <mark-eligible-dialog
          v-model="dialog.markEligible"
          :unit="unitsList[select.unitId]"
          @close="dialog.markEligible = false"
          @update="updateUnit"
        />

        <bulk-shares-dialog
          v-model="dialog.bulkShares"
          :is-admin="isAdmin"
          :selected="selected"
          @close="dialog.bulkShares = false"
          @update="updateBulkShares"
        />

        <bulk-emails-dialog
          v-model="dialog.bulkEmails"
          :is-admin="isAdmin"
          :selected="selected"
          @close="dialog.bulkEmails = false"
          @update="updateBulkEmails"
        />

        <!-- Add Proxy Dialog -->
        <!-- Update this dialog to re-use proxy create vuex actions -->
        <add-proxy-dialog
          v-model="dialog.addProxy"
          :current-meeting="currentMeeting"
          :meeting-units-list-data="unitsListData"
          :unit-id="select.unitId"
          @close="dialog.addProxy = false"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import MeetingUnitsListViewTable from './MeetingUnitsListViewTable';
import ImportUnitsButton from '@/components/ImportUnitsButton';
import DeleteUnitsDialog from '@/views/meetings/meeting/units/MeetingUnitsDeleteUnitsDialog';
import DeleteAllUnitsDialog from '@/views/meetings/meeting/units/MeetingUnitsDeleteAllUnitsDialog';
import ResetAttendanceDialog from '@/views/meetings/meeting/units/MeetingUnitsResetAttendanceDialog';
import DeleteFilesDialog from '@/views/meetings/meeting/units/MeetingUnitsDeleteFilesDialog';
import EditUnitDialog from '@/views/meetings/meeting/units/MeetingUnitsEditUnitDialog';
import AddressesUpdateDialog from '@/views/meetings/meeting/units/MeetingUnitsAddressesUpdateDialog';
import EditVoterDialog from '@/views/meetings/meeting/units/MeetingUnitsEditVoterDialog';
import AddVoterDialog from '@/views/meetings/meeting/units/MeetingUnitsAddVoterDialog';
import EditCustomFieldDialog from '@/views/meetings/meeting/units/MeetingUnitsEditCustomFieldDialog';
import AddCustomFieldDialog from '@/views/meetings/meeting/units/MeetingUnitsAddCustomFieldDialog';
import AddUnitDialog from '@/views/meetings/meeting/units/MeetingUnitsAddUnitDialog';
import RevokeProxyDialog from '@/views/meetings/meeting/units/MeetingUnitsRevokeProxyDialog';
import MarkIneligibleDialog from '@/views/meetings/meeting/units/MeetingUnitsMarkIneligibleDialog';
import MarkEligibleDialog from '@/views/meetings/meeting/units/MeetingUnitsMarkEligibleDialog';
import BulkIneligibleDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkIneligibleDialog';
import BulkSharesDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkSharesDialog';
import SendInviteDialog from '@/views/meetings/meeting/units/MeetingUnitsSendInviteDialog';
import BulkInviteDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkInviteDialog';
import BulkRegistrationPageDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkRegistrationPageDialog';
import AddProxyDialog from '@/views/meetings/meeting/units/MeetingUnitsAddProxyDialog';
import GenerateControlNumbersDialog from '@/views/meetings/meeting/units/MeetingUnitsGenerateControlNumbersDialog';
import RemoveCustomDataDialog from '@/views/meetings/meeting/units/MeetingUnitsRemoveCustomDataDialog';
import AssignSharesUndoDialog from '@/views/meetings/meeting/units/MeetingUnitsAssignSharesUndoDialog';
import BulkEmailsDialog from '@/views/meetings/meeting/units/MeetingUnitsBulkEmailsDialog';
import AssignSharesDialog from '@/views/meetings/meeting/units/MeetingUnitsAssignSharesDialog';

export default {
  name: 'MeetingUnitsListView',
  components: {
    MeetingUnitsListViewTable,
    ImportUnitsButton,
    DeleteUnitsDialog,
    ResetAttendanceDialog,
    DeleteFilesDialog,
    EditUnitDialog,
    AddressesUpdateDialog,
    EditVoterDialog,
    AddVoterDialog,
    EditCustomFieldDialog,
    AddCustomFieldDialog,
    AddUnitDialog,
    RevokeProxyDialog,
    MarkIneligibleDialog,
    MarkEligibleDialog,
    BulkIneligibleDialog,
    BulkRegistrationPageDialog,
    BulkSharesDialog,
    SendInviteDialog,
    BulkInviteDialog,
    AddProxyDialog,
    GenerateControlNumbersDialog,
    RemoveCustomDataDialog,
    AssignSharesDialog,
    AssignSharesUndoDialog,
    BulkEmailsDialog,
    DeleteAllUnitsDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    unitsListData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    unitsList: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/units', ['customDataKeys', 'pagination']),
    ...mapGetters('meetings/notices', ['meetingNoticeListDataSorted']),
    hasWebcast() {
      return (
        this.currentMeeting.castId &&
        (!this.currentMeeting.options.subtype ||
          (this.currentMeeting.options.subtype === 'Virtual' &&
            (this.currentMeeting.options.virtualMeetingType === 'GQ-Hosted' ||
              !this.currentMeeting.options.virtualMeetingType)))
      );
    },
    resendableNotices() {
      return this.meetingNoticeListDataSorted.filter(
        notice => notice.isResendable && notice.status !== 'draft'
      );
    },
    displayConsentDetails() {
      const { consentFormat = false } = this.currentMeeting.options;

      return consentFormat !== 'email-on-file';
    }
  },
  data() {
    return {
      state: {},
      dialog: {
        addUnit: false,
        deleteUnits: false,
        deleteAllUnits: false,
        resetAttendance: false,
        editUnit: false,
        addresses: false,
        editVoter: false,
        addVoter: false,
        editCustomField: false,
        addCustomField: false,
        addFile: false,
        deleteFiles: false,
        previewFile: false,
        revokeProxy: false,
        markIneligible: false,
        markEligible: false,
        bulkIneligible: false,
        bulkShares: false,
        bulkInvite: false,
        bulkRegistrationPage: false,
        addProxy: false,
        generateControlNumbers: false,
        removeCustomData: false,
        assignShares: false,
        assignSharesUndo: false,
        bulkEmails: false
      },
      shortCode: this.$route.params.shortcode,
      selected: [],
      select: {
        unitId: null,
        voter: {},
        customField: {},
        tag: ''
      },
      invite: {
        confirm: false,
        voter: {},
        default: false
      },
      loading: false
    };
  },
  created: function() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/units', [
      'getMeetingUnit',
      'getMeetingUnits',
      'getCustomDataKeys',
      'deleteMeetingUnit',
      'deleteMeetingUnits',
      'deleteAllMeetingUnits',
      'createMeetingUnit',
      'updateMeetingUnit',
      'updateMeetingUnits',
      'attachMeetingUnitFiles',
      'removeMeetingUnitFile',
      'removeMeetingUnitsFiles',
      'undoAssignShares',
      'addMeetingUnitVoter',
      'sendMeetingPortalInvite',
      'generateControlNumbersForUnits',
      'sendVoterRegistrationPages',
      'updateMeetingUnitVoter',
      'resetAttendanceUnits',
      'setFilters',
      'setPagination',
      'sendDialInInformation'
    ]),
    ...mapActions('meetings/webcast', ['addWebcastPanelists']),
    ...mapActions('files', ['getFile']),
    ...mapActions('meetings/proxies', ['revokeMeetingProxy']),
    ...mapActions('meetings/notices', [
      'getMeetingNotices',
      'resendMeetingNotice'
    ]),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    async init() {
      try {
        this.loading = true;

        await this.getCustomDataKeys({ shortCode: this.shortCode });

        // Get list of Notices and all possible recipients,
        // so account users can re-send notices from Membership page
        await this.getMeetingNotices({
          shortCode: this.shortCode,
          includeStats: false
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    setSelected(selected) {
      this.selected = selected;
    },
    setVoterSelected(voter) {
      this.select.voter = voter;
    },
    setRowExpand(item) {
      // item.id must be an integer, prevents warning in console on prop check
      this.select.unitId = parseInt(item.id);
    },
    setCustomFieldSelected(payload) {
      // Clear before selecting
      this.select.customField = {};
      this.select.customField[payload.key] = payload.value;
    },
    setTagSelected(tag) {
      this.select.tag = tag;
    },
    openDialog(key) {
      if (key === 'bulkInvite') {
        let voters = [];

        this.selected.forEach(unit =>
          unit.voters.forEach(voter => {
            voters.push(voter);
          })
        );

        this.invite.voters = voters;
        this.invite.default = true;
        this.invite.confirm = true;
      } else {
        this.dialog[key] = true;
      }
    },
    closeDialog(key) {
      this.dialog[key] = false;
    },
    async addVoter(payload) {
      try {
        const { isPanelist, ...data } = payload;

        if (isPanelist) {
          await this.addWebcastPanelists({
            shortCode: data.shortCode,
            ...data.newVoter
          });
        }

        await this.addMeetingUnitVoter(data);

        await this.getMeetingVoters({
          shortCode: this.shortCode
        });

        this.$events.$emit('toastEvent', 'Owner Added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('addVoter');
      }
    },
    async updateUnit(payload) {
      try {
        await this.updateMeetingUnit({
          shortCode: payload.shortCode,
          unitId: payload.id,
          unitData: payload
        });
        this.$events.$emit('toastEvent', 'Unit Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateUnits(unitsData) {
      try {
        let unitsObject = {};
        const unitIdList = [];
        unitsData.forEach(unitData => {
          unitIdList.push(unitData.id);
          unitsObject[unitData.id] = unitData;
        });
        await this.updateMeetingUnits({
          shortCode: this.shortCode,
          unitIdList,
          unitsData: unitsObject
        });
        this.$events.$emit('toastEvent', 'Units Updated');

        // clear selected after update
        // if left uncleared, consecutive bulk update will use stale selected data in payload
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Add a unit manually
    async addUnit(newUnit) {
      try {
        await this.createMeetingUnit({
          shortCode: this.$route.params.shortcode,
          newUnit: newUnit
        });
        this.$events.$emit('toastEvent', 'Unit Created');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.addUnit = false;
      }
    },

    async generateControlNumbers(controlNumberDigits) {
      try {
        const { message } = await this.generateControlNumbersForUnits({
          shortCode: this.$route.params.shortcode,
          controlNumberDigits: controlNumberDigits
        });

        return this.$events.$emit('toastEvent', message);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.generateControlNumbers = false;
      }
    },

    removeCustomData(customDataToBeRemoved) {
      let unitsToUpdate = _.cloneDeep(this.selected);
      unitsToUpdate = unitsToUpdate.map(unit => {
        // Check if customData exists in this unit
        if (unit.customData && typeof unit.customData === 'object') {
          customDataToBeRemoved.forEach(key => {
            delete unit.customData[key];
          });
        }
        return unit;
      });

      this.updateUnits(unitsToUpdate);
      this.dialog.removeCustomData = false;
    },

    // Deletes all rows selected
    async deleteSelected() {
      let unitIds = this.selected.map(item => item.id);
      try {
        await this.deleteMeetingUnits({
          shortCode: this.shortCode,
          unitIds
        });
        this.$events.$emit('toastEvent', `${unitIds.length} Units Deleted`);
        // Clear selected after deletion
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('deleteUnits');
      }
    },

    // Deletes all units
    async deleteAllUnits() {
      try {
        await this.deleteAllMeetingUnits({
          shortCode: this.shortCode
        });
        this.$events.$emit('toastEvent', `All Units Deleted`);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('deleteAllUnits');
      }
    },

    // Reset attendance for selected units
    async resetAttendance() {
      let unitIds = this.selected.map(item => item.id);
      try {
        await this.resetAttendanceUnits({
          shortCode: this.shortCode,
          unitIds
        });
        this.$events.$emit(
          'toastEvent',
          `${unitIds.length} Unit's attendance reset`
        );
        // Clear selected after resetting
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('resetAttendance');
      }
    },

    // Adds multiple files in bulk
    async addFiles(payload) {
      let requestBody = {
        unitKeys: payload.unitKeys,
        file: payload.file
      };
      try {
        await this.attachMeetingUnitFiles({
          shortCode: this.$route.params.shortcode,
          requestBody: requestBody,
          mode: payload.mode
        });
        this.$events.$emit(
          'toastEvent',
          'Attachment process started, you will be notified by email once complete.'
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('addFile');
      }
    },
    // Delete individual files
    deleteFile(file) {
      let isConfirm = confirm('Are you sure you want to delete this file?');
      if (isConfirm) {
        try {
          this.removeMeetingUnitFile({
            shortCode: this.$route.params.shortcode,
            fileKey: file.key
          });

          this.$events.$emit('toastEvent', 'File Deleted');
        } catch (err) {
          this.$events.$emit('showErrorDialog', err.response);
        }
      }
    },
    // Delete files in bulk (checkbox selection)
    async deleteFiles(payload) {
      try {
        await this.removeMeetingUnitsFiles({
          shortCode: this.$route.params.shortcode,
          requestBody: payload
        });
        this.$events.$emit('toastEvent', 'Files Deleted');
        // Clear selected after deletion
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('deleteFiles');
      }
    },
    // Download unit attached file
    async downloadFile(file) {
      try {
        let res = await this.getFile({
          bucket: file.Bucket,
          key: file.Key
        });
        window.open(res.data.url, '_blank');
      } catch (err) {
        throw err;
      }
    },
    updateAddresses(address) {
      // Bulk address updates
      let payload = {
        selected: _.cloneDeep(this.selected),
        address: address
      };
      const unitsToUpdate = payload.selected.map(function(unit) {
        unit.address = payload.address;
        return { ...unit, skipLogging: true };
      });
      this.updateUnits(unitsToUpdate);
      this.dialog.addresses = false;
    },
    updateBulkIneligible(reason) {
      let unitsInArrears = _.cloneDeep(this.selected);
      const unitsToUpdate = unitsInArrears.map(unit => {
        if (!unit.customData) {
          unit.customData = {};
        }
        unit.customData.ineligible_reason = reason;
        return {
          ...unit,
          actionLogTag: 'update',
          targetLogTag: 'unit-tag'
        };
      });
      this.updateUnits(unitsToUpdate);
      this.dialog.bulkIneligible = false;
    },
    updateBulkShares(shares) {
      let selectedUnits = _.cloneDeep(this.selected);
      const unitsToUpdate = selectedUnits.map(unit => {
        if (!unit.customData) {
          unit.customData = {};
        }
        unit.customData.shares = Number(shares);
        return {
          ...unit,
          skipLogging: true
        };
      });
      this.updateUnits(unitsToUpdate);
      this.dialog.bulkShares = false;
    },
    async updateBulkEmails(email) {
      try {
        let selectedUnits = _.cloneDeep(this.selected);
        const gatherVotersToUpdate = [];

        // format voter to be updated
        _.forEach(selectedUnits, unit => {
          // check if unit has more than 1 voter
          const hasMultipleVoters = _.size(unit.voters) > 1;

          if (!hasMultipleVoters) {
            _.forEach(unit?.voters, async voter => {
              gatherVotersToUpdate.push({
                unitId: unit.id,
                email: voter.email,
                voter: {
                  ...voter,
                  email
                }
              });
            });
          }
        });

        this.setFilters([]);
        this.resetSearch();
        // update each voter with the new email
        const tasks = gatherVotersToUpdate.map(async v => {
          return await this.updateMeetingUnitVoter({
            shortCode: this.shortCode,
            unitId: v.unitId,
            email: v.email,
            voter: v.voter
          });
        });

        await Promise.all(tasks);
        this.$events.$emit('toastEvent', 'All Unit Voters Updated');
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        console.log(err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.bulkEmails = false;
      }
    },
    viewProxy(proxy) {
      return window.open(proxy.fileUrl, '_blank');
    },
    async revokeProxy(proxy) {
      try {
        await this.revokeMeetingProxy({
          shortCode: this.shortCode,
          proxyKey: proxy.key
        });
        await this.getMeetingUnits({ shortCode: this.shortCode });
        this.dialog.revokeProxy = false;
        return this.$events.$emit('toastEvent', 'Proxy Revoked');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    confirmSendInvite(voter) {
      this.invite.voters = [voter];
      this.invite.confirm = true;
    },
    async handleSend({ recipients, noticeKey }) {
      try {
        this.loading = true;
        const recipientList = recipients.map(r => r.voterKey);

        await this.resendMeetingNotice({
          shortCode: this.currentMeeting.shortCode,
          noticeKey,
          recipients: recipientList
        });

        this.invite.confirm = false;
        this.invite.voters = [];
        this.invite.default = false;
        this.loading = false;
        this.$events.$emit('toastEvent', 'Email Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleInvite(payload) {
      try {
        const { recipients, troubleshooting } = payload;
        this.loading = true;

        recipients.forEach(async voter => {
          const unitId = voter.unit_id || voter.unitId;

          await this.sendMeetingPortalInvite({
            shortCode: this.currentMeeting.shortCode,
            unitId,
            email: voter.email,
            troubleshooting
          });
        });

        this.invite.confirm = false;
        this.invite.voters = [];
        this.invite.default = false;
        this.loading = false;
        this.$events.$emit('toastEvent', 'Email Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendBulkInvites(payload) {
      const { sendList } = payload;
      try {
        this.loading = true;
        for (const voter in sendList) {
          await this.sendMeetingPortalInvite({
            shortCode: this.currentMeeting.shortCode,
            unitId: sendList[voter].unitId,
            email: sendList[voter].email
          });
        }
        this.dialog.bulkInvite = false;
        this.loading = false;
        return this.$events.$emit('toastEvent', 'Meeting Invites Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendBulkVoterRegistrationPages(sendList) {
      try {
        await this.sendVoterRegistrationPages({
          shortCode: this.currentMeeting.shortCode,
          unitsList: sendList
        });
        this.$events.$emit(
          'toastEvent',
          'An email will be sent with the generated PDFs'
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
      this.dialog.bulkRegistrationPage = false;
    },
    resetSearch() {
      this.setPagination({
        searchQuery: '',
        descending: false,
        sortBy: 'unit',
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        rowsPerPageItems: [25, 50, 100, 200]
      });
    },
    async updateAssignShares(unitsToUpdate) {
      try {
        this.updateUnits(unitsToUpdate);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
      this.dialog.assignShares = false;
    },
    async handleUndoAssign(payload) {
      try {
        await this.undoAssignShares({
          shortCode: this.shortCode,
          requestBody: payload
        });
        this.$events.$emit('toastEvent', 'Undo assigned');
        // Clear selected after deletion
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('assignSharesUndo');
      }
    },
    async handleSendDial(payload) {
      try {
        const { recipients } = payload;
        this.loading = true;

        recipients.forEach(async voter => {
          const unitId = voter.unit_id || voter.unitId;

          await this.sendDialInInformation({
            shortCode: this.currentMeeting.shortCode,
            unitId,
            email: voter.email
          });
        });

        this.invite.confirm = false;
        this.invite.voters = [];
        this.invite.default = false;
        this.loading = false;
        this.$events.$emit('toastEvent', 'Email Sent');
      } catch (err) {
        this.loading = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
