'use strict';

/** @fileoverview Common filters */

import momentTz from 'moment-timezone';
import _ from 'lodash';
import { marked } from 'marked';
import insane from 'insane';

const timezone = process.env.VUE_APP_DEFAULT_TIMEZONE || 'America/Toronto';

export function dateFromNow(value) {
  if (!value) {
    return '';
  }

  return momentTz(value)
    .tz(timezone)
    .fromNow();
}

export function dateFormatReadable(
  value,
  includeTime = false,
  meetingTimezone
) {
  if (!value) {
    return '';
  }
  const formatString = includeTime ? 'YYYY-MM-DD HH:mm:ss zz' : 'YYYY-MM-DD';
  return momentTz(value)
    .tz(meetingTimezone ? meetingTimezone : timezone)
    .format(formatString);
}

/**
 * Truncates text
 * @param {String}  value   the string to truncate
 * @param {Integer} length  the max length (default: 40)
 */
export function truncateText(value, length = 40) {
  if (value.length >= length) {
    return `${value.substring(0, length)}...`;
  }
  return value;
}

/**
 * Format date using common human readable parameters
 *
 * @param {Date} date - the date to format
 * @param {Boolean} includeTime - to include time
 * @return {String} the formatted date
 */
export function formatDateHumanReadable(
  date,
  includeTime = null,
  meetingTimezone
) {
  if (!date) {
    return '-';
  }
  const format = includeTime ? 'YYYY-MM-DD h:mm a zz' : 'YYYY-MM-DD';
  return momentTz(date)
    .tz(meetingTimezone ? meetingTimezone : timezone)
    .format(format);
}

/**
 * Format date using common human readable parameters, alternate
 *
 * @param {Date} date - the date to format
 * @param {Boolean} includeTime - to include time
 * @return {String} the formatted date
 */
export function formatDateHumanReadableAlt(
  date,
  includeTime = null,
  meetingTimezone
) {
  if (!date) {
    return '-';
  }
  const format = includeTime ? 'MMM Do YYYY @ h:mm a zz' : 'MMMM Do YYYY';
  return momentTz(date)
    .tz(meetingTimezone ? meetingTimezone : timezone)
    .format(format);
}

/**
 * Format date using common human readable parameters, alternate
 *
 * @param {Date} date - the date to format
 * @param {Boolean} includeTime - to include time
 * @return {String} the formatted date
 */
export function formatDateHumanReadableAltShort(
  date,
  includeTime = null,
  meetingTimezone
) {
  if (!date) {
    return '-';
  }
  const format = includeTime ? 'MMM Do YYYY @ h:mm a zz' : 'MMM Do YYYY';
  return momentTz(date)
    .tz(meetingTimezone ? meetingTimezone : timezone)
    .format(format);
}

/**
 * Truncates text
 * @param {String}  value   the string to convert to startcase
 * @return {String} the formatted string
 */
export function startCase(value) {
  return _.startCase(value);
}

/**
 * Format a question's custom restrictions; turns the
 * array of restrictions (key/value pairs) into a string.
 *
 * @param {Object[]} question - the question
 * @return {String}           - the formatted string of restrictions
 */
export function formatRestrictions(question) {
  let restrictionString = '';
  let restrictions = question.restrictions;

  for (var i = 0; i < restrictions.length; i++) {
    if (i !== 0) {
      restrictionString += ' OR ';
    }
    const restriction = restrictions[i];

    const key = Object.keys(restriction)[0];
    restrictionString += `${key}: ${restriction[key]}`;
  }

  return restrictionString;
}

/**
 * Formats to a currency value
 * @param  {String|Number} value
 * @return {String}
 */
export function formatCurrency(value) {
  return value
    ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    : value;
}

/**
 * Formats markdown to HTML
 * @param  {String} value
 * @return {String}
 */
export function formatMarkdownToHtml(value) {
  return value ? insane(marked.parse(value)) : value;
}
