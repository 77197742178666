<template>
  <div>
    <v-text-field
      class="pb-0 pt-0"
      prepend-inner-icon="search"
      hide-details
      solo
      :placeholder="placeholder"
      clearable
      :value="searchQuery"
      @input="onChange($event)"
    />

    <div class="caption grey--text search-container">
      <div v-if="searchQuery" class="text-xs-right search-query-text">
        Showing results for "{{ searchQuery }}"
      </div>
      <div v-else class="text-xs-right search-query-text">
        Showing {{ results }} most recent campaigns
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingsViewToolbar',
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    results: {
      type: Number,
      default() {
        return 0;
      }
    },
    placeholder: {
      type: String,
      default: 'Starting typing to search for a meeting...'
    }
  },
  methods: {
    onChange: _.debounce(function(value) {
      this.$emit('search', value);
    }, 500)
  }
};
</script>

<style scoped>
.search-container {
  height: 20px;
}
.search-query-text {
  position: relative;
  top: 5px;
}
</style>
