'use strict';

/**
 * @fileoverview Vuex module for invoices
 */

import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';

import { formatCurrencyValue } from '@/helpers';
import forceDownloadUrl from '@/lib/force-download-url';
import downloadAndOpenPdf from '@/lib/download-and-open-pdf';

// API Calls
import * as invApi from '@/api/invoices';
import { handleError } from '@/helpers';

const state = {
  invoiceList: {},
  dataLastFetchedAt: null,
  meta: {
    nextInvoiceNumber: ''
  },
  // Not used ATM,
  invoiceExportUrl: '',
  stats: {
    totalInvoices: 0,
    sentInvoices: 0,
    unpaidInvoices: 0,
    draftInvoices: 0,
    amountOutstanding: 0.0,
    amountInvoiced: 0.0,
    amountPaid: 0.0,
    timestamp: null,
    cached: false
  },
  searchText: '',
  statusFilter: 'unpaid',
  pagination: {
    sortBy: 'sentAt',
    descending: true,
    rowsPerPage: 25,
    page: 1,
    totalItems: 0,
    rowsPerPageItems: [25, 50, 100, 200]
  },
  outstandingAccountInvoices: []
};

const getters = {
  invoiceList: state => state.invoiceList,
  invoiceListData: state => Object.values(state.invoiceList),
  invoiceExportUrl: state => state.invoiceExportUrl,
  invoiceByKey: state => key => state.invoiceList[key],
  meta: state => state.meta,
  stats: state => state.stats,
  searchText: state => state.searchText,
  statusFilter: state => state.statusFilter,
  pagination: state => state.pagination,
  outstandingAccountInvoices: state => state.outstandingAccountInvoices,

  // Gets the subtotal of discounts & expenses for the invoice.
  invoiceItemSubtotal: (state, getters) => key => {
    const invoice = getters.invoiceByKey(key);
    if (!invoice) {
      return 0;
    }
    return formatCurrencyValue(
      invoice.expenses.total - invoice.discounts.total
    );
  },

  // Gets the expense data for an invoice
  invoiceExpenseData: (state, getters) => key => {
    return getters.invoiceByKey(key)
      ? getters.invoiceByKey(key).expenses.details
      : [];
  },

  // Gets the discounts data for an invoice
  invoiceDiscountData: (state, getters) => key => {
    return getters.invoiceByKey(key)
      ? getters.invoiceByKey(key).discounts.details
      : [];
  },

  // Ensures discounts data for an invoice is represented as negative values
  invoiceDiscountDataNeg: (state, getters) => key => {
    return getters.invoiceDiscountData(key).map(disc => {
      return { ...disc, value: -Math.abs(disc.value) };
    });
  },

  // Gets the combined items (discounts & expenses) for the invoice.
  invoiceItemsData: (state, getters) => key => {
    return [
      ...getters.invoiceExpenseData(key),
      ...getters.invoiceDiscountDataNeg(key)
    ];
  }
};

const actions = {
  /**
   * Load the latest list of invoices from API
   * @param {Object} params               - parameters
   * @param {String} [params.accountKey]  - account key filter
   */
  async getInvoices({ commit }, { accountKey }) {
    try {
      const {
        data: { invoices, total }
      } = await invApi.getInvoiceList({
        accountKey,
        status: state.statusFilter,
        searchText: state.searchText,
        sortBy: state.pagination.sortBy,
        ascending: !state.pagination.descending,
        page: state.pagination.page,
        size: state.pagination.rowsPerPage
      });

      const invoicesFormatted = invoices.map(i => {
        return { ...i, hasSentViews: i.sentViews.length > 0 };
      });
      commit('SET_INVOICE_LIST_DATA', { invoices: invoicesFormatted, total });
    } catch (err) {
      console.error('ERROR: getInvoiceList action', err);
      handleError(err);
      throw err;
    }
  },

  /**
   * Load the latest list of invoices from API
   * @param {Object} params               - parameters
   * @param {String} [params.accountKey]  - account key filter
   */
  async getOutstandingInvoicesForAccount({ commit }, { accountKey }) {
    try {
      const {
        data: { invoices }
      } = await invApi.getInvoiceList({
        accountKey
      });

      let filteredInvoices = [];
      if (invoices && invoices.length > 0) {
        filteredInvoices = invoices.filter(i => {
          if (i.status === 'payment_due' && i.amountOwing > 0) return i;
        });
      }

      commit('SET_OUTSTANDING_ACCOUNT_INVOICES', {
        invoices: filteredInvoices
      });
    } catch (err) {
      console.error('ERROR: getInvoiceList action', err);
      handleError(err);
      throw err;
    }
  },

  /**
   * Load the invoice identified by key from API
   * @param {String} key - the invoice key
   */
  async getInvoiceByKey({ commit }, key) {
    try {
      const res = await invApi.getInvoice(key);
      const data = res.data;
      const invoice = { ...data, hasSentViews: data.sentViews.length > 0 };

      commit('SET_INVOICE_IN_LIST', { key, invoice });
    } catch (err) {
      console.error('ERROR: getInvoiceByKey action', key, err);
      throw err;
    }
  },

  /**
   * Updates the high-level invoice information (eg. invoice #, bill, to, etc.)
   *
   * @param  {Object} obj             params
   * @param  {String} obj.key         invoice key
   * @param  {String} obj.accountKey  account key
   * @param  {String} obj.meetingCode meeting code for this invoice
   * @param  {Object} obj.payload     the invoice data
   */
  async updateInvoice({ dispatch }, { key, accountKey, meetingCode, payload }) {
    try {
      const data = { ...payload };

      // Ensure it doesn't set the date back 1 day due to timezone
      data.invoiceDate = moment(data.invoiceDate).toISOString();
      await invApi.patchInvoice(key, accountKey, meetingCode, data);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: updateInvoice action', key, err);
      throw err;
    }
  },

  /**
   * Deletes the invoice identified by key from API
   * @param {String} key - the invoice key
   */
  async removeInvoice({ dispatch }, key) {
    try {
      await invApi.deleteInvoice(key);
      dispatch('getInvoices');
    } catch (err) {
      console.error('ERROR: removeInvoice action', key, err);
      throw err;
    }
  },

  /**
   * Preview Invoice Html
   * @param {String} invoiceKey - the invoice Key to download
   */
  async previewInvoice({ commit }, invoiceKey) {
    try {
      const res = await invApi.previewInvoice(invoiceKey);
      return res.data;
    } catch (err) {
      console.error('ERROR: previewInvoice action', err);
      throw err;
    }
  },

  /**
   * Export an invoice
   * @param {String} invoiceKey - the invoice Key to download
   */
  async exportInvoice({ commit }, invoiceKey) {
    try {
      const res = await invApi.createInvoiceExport(invoiceKey);
      commit('SET_INVOICE_EXPORT_URL', res.data.url);
      await forceDownloadUrl({ url: res.data.url, name: res.data.fileName });
    } catch (err) {
      console.error('ERROR: exportInvoice action', err);
      throw err;
    }
  },

  /**
   * Mark an invoice as ready for review
   * @param  {String} key     - the invoice key
   */
  async setInvoiceAsReady({ dispatch }, key) {
    try {
      await invApi.markInvoiceReview(key);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: markInvoiceReview action', err);
      throw err;
    }
  },

  /**
   * Mark an invoice as having been sent
   * @param  {String} key     - the invoice key
   */
  async setInvoiceAsSent({ dispatch }, key) {
    try {
      await invApi.markInvoiceSent(key);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: markInvoiceSent action', err);
      throw err;
    }
  },

  /**
   * Mark an invoice as having been viewed by client
   * @param  {String} key     - the invoice key
   */
  async setInvoiceAsViewed({ dispatch }, key) {
    try {
      await invApi.markInvoiceViewed(key);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: markInvoiceViewed action', err);
      throw err;
    }
  },

  /**
   * Send the invoice by email
   *
   * @param  {Object} obj          the params
   * @param  {String} obj.key      the invoice key
   * @param  {Object} obj.payload  the email payload to send
   */
  async sendInvoiceByEmail({ dispatch }, { key, payload }) {
    try {
      await invApi.sendInvoiceEmail(key, payload);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: sendInvoiceByEmail action', err);
      throw err;
    }
  },

  /**
   * Add an item to the invoice
   *
   * @param  {Object} obj           the params
   * @param  {String} obj.key       the invoice key
   * @param  {String} obj.type      the item type (eg. item, payment, etc)
   * @param  {Object} obj.payload   the invoice item payload
   */
  async addItemToInvoice({ dispatch }, { key, type, payload }) {
    try {
      await invApi.addInvoiceItem(key, type, payload);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: addItemToInvoice action', err);
      throw err;
    }
  },

  /**
   * Delete an item from the invoice
   *
   * @param  {Object} obj           the params
   * @param  {String} obj.key       the invoice key
   * @param  {String} obj.itemKey   the item key
   */
  async deleteItemFromInvoice({ dispatch }, { key, itemKey }) {
    try {
      await invApi.deleteInvoiceItem(key, itemKey);
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: deleteItemFromInvoice action', err);
      throw err;
    }
  },

  /**
   * Edit an invoice item
   *
   * @param  {Object} obj           the params
   * @param  {String} obj.key       the invoice key
   * @param  {String} obj.itemKey   the item key
   * @param  {Object} obj.payload   the invoice item payload
   */
  async editInvoiceItem({ dispatch }, { key, itemKey, payload }) {
    try {
      await invApi.editInvoiceItem({ invoiceKey: key, itemKey, payload });
      dispatch('getInvoiceByKey', key);
    } catch (err) {
      console.error('ERROR: editInvoiceItem action', err);
      throw err;
    }
  },

  async getInvoicesMetaData({ commit }) {
    try {
      const res = await invApi.getInvoicesMeta();
      commit('SET_INVOICES_META', res.data);
    } catch (err) {
      console.error('ERROR: getMeta Invoice action', err);
      throw err;
    }
  },

  /**
   * Return the invoice stats object
   * @param  {Boolean} clearCache  - if caching should be clear, default false
   * @return {Promise}             - stats
   */
  async getInvoiceStats({ commit }, clearCache = false) {
    try {
      const res = await invApi.getInvoiceStats(clearCache);
      commit('SET_INVOICES_STATS', res.data);
    } catch (err) {
      console.error('ERROR: getInvoiceStats Invoice action', err);
      throw err;
    }
  },

  /**
   * Send the 'Payment Received' confirmation email
   *
   * @param  {Object} obj          the params
   * @param  {String} obj.key      the invoice key
   * @param  {Object} obj.payload  the email payload to send
   */
  async sendInvoicePaymentConfirmation({ dispatch }, { key, payload }) {
    try {
      await invApi.sendInvoicePaymentEmail(key, payload);
    } catch (err) {
      console.error('ERROR: sendInvoicePaymentConfirmation action', err);
      throw err;
    }
  },

  /**
   * Downloads and opens the chargebee invoice (pdf)
   * @param {String} invoiceId - the invoiceId
   */
  async downloadChargebeeInvoice({ dispatch }, invoiceId) {
    try {
      const res = await invApi.getChargebeeInvoiceDownloadUrl(invoiceId);

      await downloadAndOpenPdf({
        url: res.data.download_url,
        name: `${invoiceId}.pdf`
      });
    } catch (err) {
      console.error('ERROR: getChargebeeInvoiceDownloadUrl action', err);
      throw err;
    }
  },

  setSearchText({ commit }, searchText) {
    commit('SET_SEARCH_TEXT', searchText);
  },

  setStatusFilter({ commit }, statusFilter) {
    commit('SET_STATUS_FILTER', statusFilter);
  },

  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination);
  },

  resetState({ commit }) {
    commit('CLEAR_STATE');
  }
};

const mutations = {
  SET_INVOICE_LIST_DATA(state, { invoices, total }) {
    state.invoiceList = _.keyBy(invoices, 'key');
    state.dataLastFetchedAt = new Date();
    state.pagination.totalItems = Number(total);
  },

  SET_OUTSTANDING_ACCOUNT_INVOICES(state, { invoices }) {
    state.outstandingAccountInvoices = invoices;
  },

  SET_INVOICE_IN_LIST(state, { key, invoice }) {
    Vue.set(state.invoiceList, key, invoice);
  },

  SET_INVOICE_EXPORT_URL(state, url) {
    state.invoiceExportUrl = url;
  },

  SET_INVOICES_META(state, meta) {
    state.meta = meta;
  },

  SET_INVOICES_STATS(state, stats) {
    state.stats = stats;
  },

  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText;
  },

  SET_STATUS_FILTER(state, statusFilter) {
    state.statusFilter = statusFilter;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.invoiceList = {};
    state.dataLastFetchedAt = null;
    state.searchText = '';
    state.statusFilter = 'unpaid';
    state.pagination = {
      sortBy: 'sentAt',
      descending: true,
      rowsPerPage: 25,
      page: 1,
      totalItems: 0,
      rowsPerPageItems: [25, 50, 100, 200]
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
