<template>
  <v-layout row wrap class="background">
    <v-flex xs4>
      <v-card class="round-card">
        <v-card-title class="space-between">
          <div class="h-flex">
            <v-avatar size="30" color="grey lighten-1" class="mr-2">
              <v-icon dark size="20">local_offer</v-icon>
            </v-avatar>
            <h6 class="subheading">Subtotal ({{billingSettings.billingCurrency}})</h6>
          </div>
          <span class="headline font-weight-bold"
            >${{ billingSettings.subtotal || 0 }}</span
          >
        </v-card-title>
      </v-card>
    </v-flex>

    <v-flex xs4>
      <v-card class="round-card">
        <v-card-title class="space-between">
          <div class="h-flex">
            <v-avatar size="30" color="grey lighten-1" class="mr-2">
              <v-icon dark size="20">account_balance</v-icon>
            </v-avatar>
            <h6 class="subheading">
              {{ tax ? tax.description : 'Zero-Rated (0%)' }}
            </h6>
          </div>
          <span class="headline font-weight-bold">
            ${{ billingSettings.taxAmount || 0 }}
          </span>
        </v-card-title>
      </v-card>
    </v-flex>

    <v-flex xs4>
      <v-card class="round-card">
        <v-card-title class="space-between">
          <div class="h-flex">
            <v-avatar size="30" color="grey lighten-1" class="mr-2">
              <v-icon dark size="20">attach_money</v-icon>
            </v-avatar>
            <h6 class="subheading">Total ({{ billingSettings.billingCurrency }})</h6>
          </div>
          <span class="headline font-weight-bold">
            ${{ billingSettings.totalAmount || 0 }}
          </span>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MeetingBillingSummary',
  computed: {
    ...mapGetters('meetings/billing', [
      'billingSettings',
      'discount',
      'expenses',
      'tax'
    ])
  }
};
</script>

<style scoped>
/* .background {
  background-color: rgba(187, 213, 236, 0.77);
  border-radius: 7px;
  padding-top: 40px;
  padding-bottom: 40px;
} */
</style>
