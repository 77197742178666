<template>
  <div>
    <div
      v-for="note in agentNotesList"
      :key="note.key"
      class="account-note-container"
    >
      <meeting-notes-message
        v-if="isAdmin"
        :note-key="note.key"
        :name="note.name"
        :message="note.notes"
        :updated-at="note.updatedAt"
        :is-admin="isAdmin"
        :meeting-timezone="meetingTimezone"
        type="agent"
      />
    </div>
    <!-- Account notes -->
    <div
      v-for="(note, index) in accountNotesList"
      :key="index"
      class="account-note-container"
    >
      <!-- Show account notes if isAdmin -->
      <meeting-notes-message
        v-if="isAdmin"
        :name="note.nickname"
        :message="note.message"
        :updated-at="note.date"
        type="account"
        :is-admin="isAdmin"
        :meeting-timezone="meetingTimezone"
      />
    </div>

    <!-- Meeting notes -->
    <div v-for="note in notesList" :key="note.key" class="note-container">
      <meeting-notes-message
        :note-key="note.key"
        :name="`${note.firstName} ${note.lastName}`"
        :message="note.message"
        :updated-at="note.updatedAt"
        :deleted-at="note.deletedAt"
        :tagged-users="note.taggedUsers"
        :type="note.type"
        :read="note.read"
        :is-admin="isAdmin"
        :is-collaborator="isCollaborator"
        :is-owner="userKey === note.userKey"
        :meeting-timezone="meetingTimezone"
        :mark-notes-loading="markNotesLoading"
        @remove="emitRemoveNote"
        @mark="emitMarkNote"
        @updateNoteType="emitUpdateNoteType"
      />
    </div>

    <!-- If no notes exist -->
    <p
      class="body-1 grey--text text-xs-center ma-3"
      v-if="
        notesList.length === 0 &&
          accountNotesList.length === 0 &&
          agentNotesList.length === 0
      "
    >
      No notes found.
    </p>
  </div>
</template>

<script>
import _ from 'lodash';
import meetingNotesMessage from '@/components/MeetingNotesMessage';

export default {
  name: 'MeetingNotes',
  components: {
    meetingNotesMessage
  },
  props: {
    notesList: {
      type: Array,
      default() {
        return [];
      }
    },
    agentNotesList: {
      type: Array,
      default() {
        return [];
      }
    },
    accountNotesList: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultType: {
      type: String,
      default: ''
    },
    checklistStages: {
      type: Array,
      default() {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isCollaborator: {
      type: Boolean,
      default: false
    },
    userKey: {
      type: String,
      default: null
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    markNotesLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitRemoveNote(noteKey) {
      this.$emit('removeNote', noteKey);
    },
    emitMarkNote(noteKey) {
      this.$emit('markNote', noteKey);
    },
    emitUpdateNoteType({ noteKey, type }) {
      this.$emit('updateNoteType', { noteKey, type });
    }
  }
};
</script>

<style scoped>
.note-container {
  background-color: #fff;
  padding: 5px 5px 12px 15px;
  margin: 7px 0;
  border-radius: 7px;
}
.account-note-container {
  background-color: #f5f5f5;
  padding: 5px 5px 12px 15px;
  margin: 7px 0;
  border-radius: 7px;
}
</style>
