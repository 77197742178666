<template>
  <v-dialog :value="value" @input="emitInput" persistent max-width="350px">
    <v-card v-if="status === 'sending'">
      <v-card-title primary-title>
        <div class="title">Sending</div>
      </v-card-title>
      <v-card-text>
        <v-progress-circular indeterminate :size="50" color="primary" />
      </v-card-text>
    </v-card>
    <v-card v-else-if="conversationStatus === 'sent'">
      <v-list>
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon large color="green">check_circle</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Conversation Sent!</v-list-tile-title>
            <v-list-tile-sub-title>
              Email sent to
              <strong>{{ sendCount }}</strong> Recipients
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-tooltip bottom>
              <span slot="activator">
                <v-btn flat icon @click.native="emitReturn">
                  <v-icon>keyboard_return</v-icon>
                </v-btn>
              </span>
              <span>Return to conversations</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-divider />
    </v-card>

    <v-card v-else>
      <v-card-title primary-title>
        <div class="body-2 mb-2 grey--text">
          You're about to send an email to owners:
        </div>
        <div class="title">{{ state.purpose }}</div>
      </v-card-title>
      <v-card-text class="mt-0 pt-0">
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">subject</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ state.subject }}</v-list-tile-title>
              <v-list-tile-sub-title>Subject</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="primary">person</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ sendCount }} Recipients</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">Close</v-btn>
        <v-spacer />
        <v-btn class="primary" @click.native="emitSend">
          <v-icon left>send</v-icon>Confirm Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';

export default {
  name: 'MeetingConversationViewSend',
  components: {},
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      default() {
        return {};
      }
    },
    conversationStatus: { type: String, default: '' },
    status: { type: String, default: null },
    sendCount: { type: Number, default: 0 }
  },
  computed: {},
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSend() {
      this.$emit('send');
    },
    emitReturn() {
      this.$emit('return');
    }
  }
};
</script>

<style scoped></style>
