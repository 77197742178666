<template>
  <div>
    <v-layout row class="mt-3">
      <v-flex xs12 class="text-xs-right">
        <v-btn
          slot="activator"
          v-if="!subscription"
          color="primary"
          @click.native="showAddDialog = true"
        >
          Add Subscription
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Display Usage summary -->
    <div v-if="subscription" class="mt-3">
      <v-layout row align-center>
        <v-flex xs6 item>
          <h3>Usage summary</h3>
        </v-flex>

        <v-flex xs6>
          <v-switch
            class="switch-right"
            :label="periodLabel"
            v-model="showRemainingPeriods"
            @change="handleRemainingPeriodsToggle"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <subscription-usage-summary-table
            :account-key="currentAccount.key"
            :display-remaining-periods="showRemainingPeriods"
          />
        </v-flex>
      </v-layout>
    </div>

    <v-layout v-if="subscription" class="mt-3">
      <v-flex xs12 class="text-xs-left">
        <v-card>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 md6 class="pa-2">
                <subscription-details-card :subscription="subscription" />
              </v-flex>

              <v-flex xs12 md6 class="pa-2">
                <subscription-invoice-details-card
                  :subscription="subscription"
                  @delete="showDeleteInvoiceDialog = true"
                />

                <subscription-renewal-details-card
                  class="mt-5"
                  :subscription="subscription"
                />

                <subscription-proof-card
                  :subscription-proof="subscriptionProof"
                  @open="showProofDialog = true"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="showUpdateDialog = true">
              <v-icon left>edit</v-icon>view and update subscription
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <p v-else>
      <b>No subscription found for this account.</b>
    </p>

    <v-divider class="primary mb-5 mt-5" />

    <!-- Display Account Notes -->
    <v-layout row class="mt-3">
      <v-flex xs12 sm5>
        <accounts-notes :notes="dataNotes" @update="updateNotes" />
      </v-flex>
    </v-layout>

    <!-- Create Subscription Dialog -->
    <add-subscription-dialog
      v-if="showAddDialog"
      :account-key="currentAccount.key"
      :is-open="showAddDialog"
      @close-dialog="showAddDialog = false"
    />

    <!-- Update Subscription Dialog -->
    <update-subscription-dialog
      v-if="showUpdateDialog"
      :subscription="subscription"
      :is-open="showUpdateDialog"
      @close-dialog="showUpdateDialog = false"
    />

    <!-- Add Subscription Proof Dialog -->
    <add-subscription-proof-dialog
      :account-key="currentAccount.key"
      :is-open="showProofDialog"
      @close-dialog="showProofDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AddSubscriptionDialog from './AddSubscriptionDialog';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';
import AccountsNotes from '../overview/AdminAccountViewNotes';

import SubscriptionDetailsCard from './SubscriptionDetailsCard.vue';
import SubscriptionInvoiceDetailsCard from './SubscriptionInvoiceDetailsCard.vue';
import SubscriptionRenewalDetailsCard from './SubscriptionRenewalDetailsCard.vue';
import SubscriptionProofCard from './SubscriptionProofCard.vue';
import AddSubscriptionProofDialog from './AddSubscriptionProofDialog.vue';
import SubscriptionUsageSummaryTable from './SubscriptionUsageSummaryTable.vue';

export default {
  name: 'AdminAccountSubscriptionView',
  components: {
    AddSubscriptionDialog,
    UpdateSubscriptionDialog,
    AccountsNotes,
    SubscriptionDetailsCard,
    SubscriptionInvoiceDetailsCard,
    SubscriptionRenewalDetailsCard,
    SubscriptionProofCard,
    AddSubscriptionProofDialog,
    SubscriptionUsageSummaryTable
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAddDialog: false,
      showUpdateDialog: false,
      showProofDialog: false,
      showRemainingPeriods: false
    };
  },

  created() {
    this.showRemainingPeriods =
      localStorage.getItem(`periods-${this.currentAccount.key}`) === 'true';
  },
  computed: {
    ...mapGetters('accounts', ['subscription']),
    dataNotes() {
      return this.currentAccount.data.notes || [];
    },
    subscriptionProof() {
      return this.currentAccount.data.subscriptionProof || null;
    },
    periodLabel() {
      return this.showRemainingPeriods
        ? 'Hide remaining periods'
        : 'Show remaining periods';
    }
  },
  methods: {
    ...mapActions('accounts', ['updateAccountNotes']),
    async updateNotes({ value }) {
      try {
        await this.updateAccountNotes({
          key: this.currentAccount.key,
          notes: value
        });
        this.$events.$emit('toastEvent', 'Notes updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleRemainingPeriodsToggle(value) {
      localStorage.setItem(`periods-${this.currentAccount.key}`, value);
    }
  }
};
</script>

<style scoped>
.switch-right {
  display: flex;
  justify-content: right;
}
</style>
