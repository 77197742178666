<template>
  <v-dialog :value="isOpen" persistent width="850">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12>
                <label>Question</label>
                <v-text-field
                  v-model="question.label"
                  type="text"
                  outline
                  single-line
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="my-2">
              <v-flex xs8 md2>
                How many choices can voters make?
              </v-flex>
              <v-flex xs4 md2>
                <v-text-field
                  v-model="question.maxChoices"
                  :value="question.maxChoices"
                  type="number"
                  outline
                  single-line
                  hide-details
                  min="1"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout
              v-for="(option, index) in question.choices"
              :key="index"
              row
              wrap
              align-center
            >
              <v-flex xs10 md5>
                <v-text-field
                  :value="option"
                  type="text"
                  outline
                  single-line
                  hide-details
                  :placeholder="`Option ${index + 1}`"
                  @input="handleInput($event, index)"
                ></v-text-field>
              </v-flex>
              <v-flex v-if="index !== 0" xs2 md1>
                <v-btn icon flat @click="handleClear(index)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-btn color="primary" round outline @click="addOption">
                <v-icon left>add</v-icon> Add Option
              </v-btn>
            </v-layout>

            <div class="selection-box">
              <v-layout align-center row fill-height>
                <v-flex xs12 md4>
                  <v-layout align-center row fill-height>
                    <v-icon color="primary" class="mr-3">person</v-icon>
                    <div>Who can vote on this question?</div>
                  </v-layout>
                </v-flex>
                <v-flex xs12 md8>
                  <v-select
                    v-model="selectedRestrictions"
                    :items="listOfParticipants"
                    item-text="label"
                    item-value="join_as"
                    chips
                    multiple
                    clearable
                    deletable-chips
                    small-chips
                    hide-details
                    outline
                    single-line
                    dense
                    disabled
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>

            <v-layout row wrap v-if="value.id">
              <!-- only show the tooltip when button is disabled -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="votesReceived ? on : ''">
                    <v-btn
                      slot="activator"
                      flat
                      color="error"
                      class="pa-0"
                      v-bind="attrs"
                      @click="handleDelete(question.key)"
                      :disabled="votesReceived"
                    >
                      <v-icon>delete</v-icon>
                      Delete question
                    </v-btn>
                  </div>
                </template>
                <span
                  >This voting matter has received votes and cannot be
                  deleted.</span
                >
              </v-tooltip>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-3">
        <v-layout align-center justify-center row fill-height>
          <v-btn color="primary" outline @click="handleCancel">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="handleSave">
            Save
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingVbmEditQuestionDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default() {
        return {
          label: '',
          maxChoices: null,
          choices: [''],
          restrictions: []
        };
      }
    }
  },
  data() {
    return {
      question: {
        label: '',
        max_choices: null,
        choices: [''],
        restrictions: []
      },
      listOfParticipants: [
        { label: 'Board members', join_as: 'board_member' },
        { label: 'Spectators', join_as: 'spectator' }
      ]
    };
  },
  computed: {
    title() {
      return this.action === 'add' ? 'Add Voting Matter' : 'Edit Voting Matter';
    },
    votesReceived() {
      return this.totalBallotsCast(this.question) > 0;
    },
    selectedRestrictions() {
      return this.action === 'add'
        ? [{ join_as: 'board_member' }]
        : this.question.restrictions;
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.question = _.cloneDeep(newValue);
      },
      deep: true
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSave() {
      if (this.action === 'add') {
        this.$emit('save', this.question);
      } else if (this.action === 'edit') {
        this.$emit('update', this.question);
      }
    },
    handleInput(val, index) {
      this.question.choices[index] = val;
    },
    handleClear(index) {
      this.$nextTick(() => {
        if (index > 0) {
          this.question.choices.splice(index, 1);
        }
      });
    },
    addOption() {
      const option = '';
      this.question.choices.push(option);
    },
    handleDelete(questionKey) {
      let isConfirm = confirm('Are you sure you want to delete this question?');

      if (isConfirm) {
        this.$emit('delete', questionKey);
      }
    },
    totalBallotsCast(question) {
      if (!question.tally) {
        return 0;
      }
      const tallyTotal = question.tally.reduce((sum, tally) => {
        const totalVotes = isNaN(tally.numVotesTotal) ? 0 : tally.numVotesTotal;
        return sum + totalVotes;
      }, 0);

      return _.round(tallyTotal, 2);
    }
  }
};
</script>

<style scoped lang="scss">
.selection-box {
  border: 1px solid #2196f3;
  border-radius: 2px;
  padding: 6px 6px 6px 12px;
  margin: 36px 0 16px;
}
</style>
