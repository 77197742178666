<template>
  <v-dialog max-width="500px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Remove files</span>
      </v-card-title>
      <v-card-text>
        <v-alert outline color="error" icon="warning" :value="true">
          <strong>WARNING</strong>: File attachments for the selected units will
          be permanently deleted. You cannot undo this action.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-spacer />
        <v-btn
          v-if="!noSelection"
          color="error"
          :disabled="noSelection"
          @click.native="emitDeleteFiles(selected)"
          >Remove files for ({{ this.selected.length }}) units</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUploadForm from '@/components/FileUploadForm';
import _ from 'lodash';

export default {
  name: 'MeetingUnitsDeleteFilesDialog',
  components: {
    FileUploadForm
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    noAttachment() {
      return _.isEmpty(this.attachment);
    },
    noSelection() {
      return _.isEmpty(this.selected);
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitDeleteFiles(array) {
      let unitKeys = array.map(function(el) {
        return el.unitKey;
      });
      let payload = {
        unitKeys: unitKeys
      };
      this.$emit('deleteFiles', payload);
    }
  }
};
</script>

<style></style>
