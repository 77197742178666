import { render, staticRenderFns } from "./AdminAccountMain.vue?vue&type=template&id=44d0c306&scoped=true"
import script from "./AdminAccountMain.vue?vue&type=script&lang=js"
export * from "./AdminAccountMain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d0c306",
  null
  
)

export default component.exports