<template>
  <meeting-overview-card
    icon="subject"
    title="Proof of request"
    v-slot="{ edit }"
    :saving="saving"
    :disabled="isDisabled"
    @save="onSave"
    @cancel="onCancel"
  >
    <!-- Display proof -->
    <v-layout row>
      <v-flex v-if="!edit" xs10>
        <div v-if="requestProof">
          <p v-if="requestProof.type === 'emailExchange'">
            Email exchange:
            <a @click="openFile(requestProof.value)">
              {{ requestProof.value }}</a
            >
          </p>

          <p v-else-if="requestProof.type === 'signedAgreement'">
            Signed agreement:
            <template v-if="!isMultipleAgreements">
              <a @click="downloadFile(requestProof.value)">View File</a>
            </template>
            <template v-else>
              <div v-for="proof in requestProof.value" :key="proof.name">
                <a @click="downloadFile(proof)">{{ proof.name }}</a>
              </div>
            </template>
          </p>

          <p v-else-if="requestProof.type === 'noProofRequired'">
            No proof required: {{ requestProof.value }}
          </p>

          <p v-if="requestProof.crmDeal">
            CRM Deal URL:
            <a @click="openFile(requestProof.crmDealUrl)">Deal URL</a>
          </p>
          <p v-else>
            No CRM Deal for this meeting
          </p>
        </div>
        <div v-else>No request proof for this meeting.</div>
      </v-flex>
      <v-flex v-else>
        <meeting-request-proof
          :meeting-proof="state.meetingProof"
          @update="handleUpdate"
      /></v-flex>
    </v-layout>
  </meeting-overview-card>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import MeetingRequestProof from '../../../MeetingRequestProof.vue';

export default {
  name: 'ProofCard',
  components: { MeetingOverviewCard, MeetingRequestProof },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      saving: false,
      shortCode: this.$route.params.shortcode,
      state: {
        meetingProof: null
      },
      proofs: {
        emailExchange: 'Email exchange',
        signedAgreement: 'Signed agreement',
        noProofRequired: 'No proof required'
      }
    };
  },
  mounted() {
    // fills state with prop values
    this.setState();
  },
  computed: {
    requestProof() {
      const requestProof = _.get(
        this.currentMeeting,
        'meetingMeta.meetingRequestProof',
        null
      );
      return requestProof;
    },
    isDisabled() {
      if (
        (this.state.meetingProof?.type === null &&
          this.state.meetingProof?.value === null) ||
        (this.state.meetingProof?.type !== 'inDeal' &&
          this.state.meetingProof?.value === null)
      ) {
        return true;
      }

      if (
        this.state.meetingProof?.crmDeal === null ||
        (this.state.meetingProof?.crmDeal &&
          !this.state.meetingProof?.crmDealUrl)
      ) {
        return true;
      }

      return false;
    },
    isMultipleAgreements() {
      return _.isArray(this.state.meetingProof?.value);
    }
  },
  methods: {
    ...mapActions('meetings', ['updateMeeting']),
    ...mapActions('meetings/files', ['getPutSignedUrl', 'putSignedUrl']),
    ...mapActions('files', ['getFile']),
    handleUpdate({ key, value, addFile }) {
      // Lodash search state object for matching key

      if (addFile) {
        // check if the value is an array, and the first one is a file
        const currentProof = this.state.meetingProof.value;

        // For the old object where value is an object
        if (
          (currentProof?.Bucket || currentProof?.bucket) &&
          (currentProof?.Key || currentProof?.key)
        ) {
          value = [currentProof, ...value];
        } else if (
          // For the new object where value is an array
          _.isArray(currentProof) &&
          (currentProof.find(c => (c.Bucket || c.bucket) && (c.Key || c.key)) ||
            currentProof.find(c => c.type && c.size))
        ) {
          value = [...currentProof, ...value];
        }
      }

      _.set(this.state, key, value);
    },
    setState() {
      const emptyProof = {
        type: null,
        value: null,
        crmDeal: null,
        crmDealUrl: null
      };

      this.state.meetingProof = _.cloneDeep(this.requestProof) || emptyProof;
    },
    openFile(url) {
      window.open(url);
    },
    onCancel() {
      this.setState();
    },
    async onSave() {
      this.saving = true;
      try {
        let meetingProof = this.state.meetingProof;
        // if signed agreement file exists

        if (_.isArray(meetingProof.value) && meetingProof.value.length > 0) {
          // upload file
          const newFilesToProcessed = [];
          const s3Files = [];

          meetingProof.value.forEach(v => {
            if (v instanceof File) newFilesToProcessed.push(v);
            else {
              s3Files.push(v);
            }
          });

          const fileObj = await Promise.all(
            newFilesToProcessed.map(async file => {
              return await this.processFile({
                file,
                shortCode: this.shortCode
              });
            })
          );

          s3Files.push(...fileObj);

          meetingProof.value = s3Files;
        }

        // update meeting meta with the new upload
        await this.updateMeeting({
          shortCode: this.shortCode,
          meeting: {
            ...this.currentMeeting,
            meetingMeta: {
              meetingRequestProof: { ...meetingProof }
            }
          }
        });

        this.$events.$emit('toastEvent', 'Billing customer updated');
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.saving = false;
      } finally {
        this.saving = false;
      }
    },
    async processFile({ file, shortCode }) {
      try {
        // Get the signed url
        let data = await this.getPutSignedUrl({
          shortCode,
          fileName: file.name,
          folder: 'files'
        });

        // Upload to the signed url
        let putRes = await this.putSignedUrl({
          url: data.putObjectSignedUrl,
          file
        });

        if (putRes.status) {
          return {
            name: file.name,
            size: file.size,
            url: data.getObjectSignedUrl,
            Bucket: data.Bucket,
            Key: data.Key
          };
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Download unit attached file
    async downloadFile(file) {
      try {
        let res = await this.getFile({
          bucket: file.bucket,
          key: file.key
        });
        window.open(res.data.url, '_blank');
      } catch (err) {
        throw err;
      }
    }
  }
};
</script>
