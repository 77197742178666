<template>
  <v-container fluid>
    <v-layout row>
      Work in progress
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'AdminHomeView'
};
</script>

<style scoped>
</style>