<template>
  <div>
    <v-layout row class="mt-3">
      <v-flex xs12 class="text-xs-right">
        <v-btn color="primary" outline @click.native="showImportDialog = true">
          Import Signed Agreement
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">
              New Blank Agreement
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              v-for="(type, index) in serviceTypes"
              :key="index"
              @click="toggleAgreementDialog(type.value)"
            >
              <v-list-tile-title>{{ type.label }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>

    <v-layout row class="">
      <!-- Has Agreements, show the list -->
      <v-flex v-show="hasAgreements" xs12>
        <account-service-agreements-list
          :agreements="agreementsData"
          @delete-file="openDeleteFileDialog"
          @edit-agreement="editAgreement"
          @delete-agreement="openDeleteAgreementDialog"
        />
      </v-flex>

      <!-- No Agreements, show the empty message -->
      <v-flex v-show="!hasAgreements" xs12 class="text-xs-center">
        <p class="subheading">No Service Agreements</p>
      </v-flex>
    </v-layout>

    <edit-agreement-dialog
      :is-open="showEditDialog"
      :agreement="agreementToEdit"
      @save="updateAgreement"
      @close-dialog="showEditDialog = false"
    />

    <add-agreement-dialog
      :is-open="showAddDialog"
      :account-key="currentAccount.key"
      :service-type="selectedServiceType"
      @close-dialog="showAddDialog = false"
    />

    <import-agreement-dialog
      :is-open="showImportDialog"
      :account-key="currentAccount.key"
      @imported="agreementImported"
      @close-dialog="showImportDialog = false"
    />

    <!-- Dialog to Delete a File in Service Agreement -->
    <delete-resource-dialog
      :is-open="showDeleteFileDialog"
      resource-name="file"
      :resource-key="fileToDelete.key"
      @delete="deleteFile"
      @close-dialog="showDeleteFileDialog = false"
    >
      <span slot="title"
        >Delete file {{ fileToDelete.name }} from agreement?</span
      >
      <span slot="description">
        <p class="capitalize"><b>Name:</b> {{ fileToDelete.name }}</p>
        <p><b>Created:</b> {{ fileToDelete.createdAt }}</p>
      </span>
    </delete-resource-dialog>

    <!-- Dialog to Delete a Service Agreement -->
    <delete-resource-dialog
      :is-open="showDeleteAgreementDialog"
      resource-name="agreement"
      :resource-key="agreementToEdit.key"
      @delete="deleteAgreement"
      @close-dialog="showDeleteAgreementDialog = false"
    >
      <span slot="title">Delete agreement?</span>
      <span slot="description">
        <p class="capitalize"><b>Type:</b> {{ agreementToEdit.type }}</p>
        <p><b>Created:</b> {{ agreementToEdit.createdAt }}</p>
        <p>{{ agreementToEdit.description }}</p>
      </span>
    </delete-resource-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

import DeleteResourceDialog from '@/components/dialogs/DeleteResourceDialog';

import AccountServiceAgreementsList from './AdminAccountAgreementsViewList';
import EditAgreementDialog from './EditAgreementDialog';
import AddAgreementDialog from './AddAgreementDialog';
import ImportAgreementDialog from './ImportAgreementDialog';

export default {
  name: 'AdminAccountAgreementsView',
  components: {
    AccountServiceAgreementsList,
    EditAgreementDialog,
    AddAgreementDialog,
    DeleteResourceDialog,
    ImportAgreementDialog
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEditDialog: false,
      showAddDialog: false,
      showDeleteFileDialog: false,
      showDeleteAgreementDialog: false,
      showImportDialog: false,
      agreementToEdit: {},
      fileToDelete: {
        name: '',
        createdAt: ''
      },
      serviceTypes: [
        { label: 'Standard', value: 'standard' },
        { label: 'Florida', value: 'florida' },
        { label: 'Association', value: 'association' }
      ],
      selectedServiceType: null
    };
  },
  computed: {
    agreementsData() {
      // Ensure agreements list has been resolved from API
      if (_.has(this.currentAccount, 'agreementsList')) {
        // Return agreements sorted by oldest
        return Object.values(this.currentAccount.agreementsList).sort(
          (a, b) => a.createdAt > b.createdAt
        );
      }
      // Agreements have not be resolved yet, return empty array to prevent errors
      return [];
    },
    hasAgreements() {
      return this.agreementsData ? !_.isEmpty(this.agreementsData) : false;
    }
  },
  methods: {
    ...mapActions('files', ['removeFile']),
    ...mapActions('accounts', [
      'createAccountAgreement',
      'getAccountAgreements'
    ]),
    ...mapActions('serviceAgreements', [
      'deleteServiceAgreement',
      'updateServiceAgreement'
    ]),
    editAgreement(agreement) {
      this.agreementToEdit = agreement;
      this.openEditDialog();
    },
    openEditDialog() {
      return (this.showEditDialog = true);
    },
    openDeleteFileDialog(file) {
      this.fileToDelete = file;
      this.showDeleteFileDialog = true;
    },
    openDeleteAgreementDialog(agreement) {
      this.agreementToEdit = agreement;
      this.showDeleteAgreementDialog = true;
    },
    agreementImported() {
      this.getAccountAgreements(this.currentAccount.key);
    },
    toggleAgreementDialog(type) {
      this.selectedServiceType = type;
      this.showAddDialog = true;
    },
    async deleteFile(fileKey) {
      try {
        await this.removeFile(fileKey);
        this.$events.$emit('toastEvent', 'File Succesfully Removed');
        this.getAccountAgreements(this.currentAccount.key);
      } catch (err) {
        this.$events.$emit('toastEvent', 'Error removing file');
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteAgreement(agreementKey) {
      try {
        await this.deleteServiceAgreement(agreementKey);
        this.$events.$emit('toastEvent', 'Agreement Succesfully Deleted');
        this.getAccountAgreements(this.currentAccount.key);
      } catch (err) {
        this.$events.$emit('toastEvent', 'Error deleting agreement');
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateAgreement(agreement) {
      try {
        await this.updateServiceAgreement(agreement);
        this.$events.$emit('toastEvent', 'Agreement Succesfully Updated');
        this.getAccountAgreements(this.currentAccount.key);
      } catch (err) {
        this.$events.$emit('toastEvent', 'Error updating agreement');
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
