<template>
  <v-dialog :value="isOpen" max-width="600px" persistent>
    <v-card>
      <v-card-text>
        <!-- Booting Up -->
        <v-layout row v-if="booting">
          <v-flex xs12>
            <h1 class="headline">Preparing...</h1>
            <p class="subheading mt-3">
              Please wait while we boot up the webcast, it should only take a
              few seconds.
            </p>

            <div class="text-xs-center mt-4 mb-3">
              <v-progress-circular indeterminate :size="50" color="primary" />
            </div>
          </v-flex>
        </v-layout>

        <!-- Booting Complete -->
        <v-layout row v-if="!booting && hasLicense">
          <v-flex xs12>
            <h1 class="headline">Ready To Join</h1>
            <v-alert
              v-if="hasCapacityWarning"
              :value="true"
              color="warning"
              icon="priority_high"
              class="justify"
              outline
            >
              The assigned license does not meet the expected meeting capacity
              based on total RSVP. Please double check the capacity and contact
              support if needed. <br />
              License capacity: {{ licenseData.webinarCapacity }}<br />
              Total RSVP: {{ rsvp }}<br />
            </v-alert>

            <p class="subheading mt-3">
              Click on the button below to join the
              <b>Zoom</b> webcast with full <b>Host</b> permissions.
            </p>

            <div class="text-xs-center">
              <v-btn color="primary" @click.native="openUrl">
                Join via Zoom
                <v-icon right>launch</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.native="close" flat>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'StartDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    booting: {
      type: Boolean,
      required: true
    },
    startUrl: {
      type: String,
      default() {
        return '';
      }
    },
    license: {
      type: Object || String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    hasLicense() {
      return !_.isEmpty(this.license);
    },
    licenseData() {
      return this.license ? this.license.license : {};
    },
    isInUse() {
      return this.license ? this.license.isInUse : false;
    },
    rsvp() {
      return this.license ? this.license.rsvp : '';
    },
    hasCapacityWarning() {
      return this.license ? this.license.capacityWarning : false;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    openUrl() {
      window.open(this.startUrl, '_blank');
      this.close();
    }
  }
};
</script>

<style scoped></style>
