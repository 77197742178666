<template>
  <v-container fluid grid-list-md v-if="dataLoaded">
    <v-layout>
      <v-flex xs6>
        <v-card-text>
          <div class="text-xs-left">
            <span class="uppercase">Print Job Code:</span>
            <span class="subheading uppercase ml-2 font-weight-bold">{{
              state.jobId
            }}</span>
          </div>
        </v-card-text>
      </v-flex>
      <v-flex xs6>
        <div class="text-xs-right">
          <span class="pr-1 uppercase"> <b>Current Status:</b> </span>
          <v-chip
            class="pl-2 pr-1"
            disabled
            :color="statusChipColor"
            text-color="white"
          >
            <v-icon left>{{ currentStatus.icon }}</v-icon>
            <span class="status-chip-text">{{ currentStatus.value }}</span>
          </v-chip>
          <v-spacer></v-spacer>
          <span class="caption"
            >updated on:
            {{
              currentPrintJob.updatedAt
                | formatDateHumanReadable(true, meetingTimezone)
            }}</span
          >
        </div>
      </v-flex>
    </v-layout>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mt-1">
        <v-layout row class="ma-3">
          <v-flex xs6>
            <v-text-field
              v-model="state.jobName"
              :disabled="!isWritable"
              required
              :rules="rules.jobName"
              label="Job Name *"
              :value="state.jobName"
            />
          </v-flex>
          <v-flex xs3>
            <v-combobox
              v-model="state.jobType"
              :disabled="!isWritable"
              :items="jobTypes"
              label="Job Type *"
              :rules="rules.jobType"
              :value="state.jobType"
            ></v-combobox>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="state.jobId"
              :disabled="!isWritable"
              required
              :rules="rules.jobId"
              label="Job Code *"
              :value="state.jobId"
            />
          </v-flex>
        </v-layout>

        <v-layout row class="ma-3">
          <v-flex xs6>
            <v-select
              v-model="state.vendorKey"
              :disabled="!isWritable"
              :items="vendorOptions"
              item-text="label"
              item-value="value"
              :value="currentVendor ? currentVendor.key : ''"
              required
              label="Vendor *"
              @input="setSelectedVendor"
            />
          </v-flex>
          <v-flex xs6>
            <date-picker
              date-format="ddd, MMM DD YYYY"
              :isDisabled="!isWritable"
              label="Mailing Date *"
              :iso-date="state.mailingDate"
              hide-icon
              :isRequired="true"
              @update="handleDateUpdate"
            />
          </v-flex>
        </v-layout>

        <v-layout row class="ma-3">
          <v-flex xs6>
            <v-card class="pa-1" v-bind:class="!isWritable ? 'disabled' : ''">
              <v-card-title>
                <span class="mb-0 subheading">Print Documents *</span>
              </v-card-title>
              <v-card-text>
                <print-job-file-upload-form
                  :short-code="shortCode"
                  :disabled="!isWritable"
                  :attachment="items.printing"
                  :multiple-files="true"
                  icon="attach_file"
                  accept=".pdf, .doc, .docx"
                  :upload-file-type="jobItemType.PRINTING"
                  @upload="handleUpload($event, jobItemType.PRINTING)"
                />

                <v-btn
                  flat
                  color="pink"
                  :disabled="
                    !isMeetingDocuments || !currentPrintJob.status === 'DRAFT'
                  "
                  @click="dialog.attachments = true"
                >
                  <v-icon>add</v-icon>Attach Meeting Documents PDF
                </v-btn>
                <v-alert
                  v-if="isCombinedSizeWarning"
                  small
                  outline
                  color="red"
                  icon="warning"
                  :value="true"
                >
                  Warning: total print documents size exceeds 10 MB
                </v-alert>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs6>
            <v-card class="pa-1" v-bind:class="!isWritable ? 'disabled' : ''">
              <v-card-title>
                <span class="mb-0 subheading">Recipients *</span>
              </v-card-title>
              <v-card-text>
                <print-job-file-upload-form
                  :short-code="shortCode"
                  :disabled="!isWritable"
                  :attachment="items.recipients"
                  :multiple-files="true"
                  icon="attach_file"
                  accept=".xls, .xlsx, .csv"
                  :upload-file-type="jobItemType.RECIPIENTS"
                  @upload="handleUpload($event, jobItemType.RECIPIENTS)"
                />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row class="ma-3">
          <v-flex xs6>
            <v-text-field
              v-model="state.packagePagesPrinted"
              type="number"
              :disabled="!isWritable"
              label="Print Documents Page Count"
              :placeholder="estimatedPackagePageCount"
              :value="state.packagePagesPrinted"
            />
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="state.packagesMailed"
              type="number"
              :disabled="!isWritable"
              label="Recipient Count"
              :placeholder="estimatedRecipientCount"
              :value="state.packagesMailed"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs10>
            <v-card-text>
              <v-flex class="text-xs-left">
                <span class="uppercase">Print cost estimate:</span>
                <span
                  v-if="paperWeight > 500"
                  class="subheading uppercase ml-2 font-weight-bold"
                  >Subject to package shipping rates when exceeding 500g</span
                >
                <span v-else class="subheading uppercase ml-2 font-weight-bold"
                  >${{ totalCost || 0 }}</span
                >
                <v-tooltip v-if="paperWeight <= 500" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      small
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      info
                    </v-icon>
                  </template>
                  <span>
                    <p>
                      Processing cost per package: ${{
                        processingCostPerPackage
                      }}
                    </p>

                    <p>(price per page x package pages printed) + postage</p>
                    <p>
                      ({{ priceDefault.pricePerPage }} x
                      {{ state.packagePagesPrinted || 0 }} pages) +
                      {{ priceDefault.pricePerPackage }}
                    </p>

                    <p>---</p>

                    <p>
                      Shipping cost per package (including tax): ${{
                        shippingCostPerPackage
                      }}
                    </p>

                    <p>---</p>

                    <p>Total estimate: ${{ totalCost }}</p>
                    <p>
                      (Processing cost per package + Shipping cost per package)
                      x Total Recipients
                    </p>
                    <p>
                      ({{ processingCostPerPackage }} +
                      {{ shippingCostPerPackage }}) x
                      {{ state.packagesMailed || 0 }}
                    </p>
                  </span>
                </v-tooltip>
              </v-flex>
            </v-card-text>
          </v-flex>
        </v-layout>

        <v-layout row class="ma-3">
          <v-flex>
            <v-layout row>
              <v-flex class="ma-0 pa-0">
                <v-card-text class="ma-0 pa-0 text-xs-right">
                  <v-menu bottom transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="!isWritable"
                        >Use Default</v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-tile
                        @click="loadDefaultPrintingInstructions('budget')"
                      >
                        <v-list-tile-title>Budget</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        @click="loadDefaultPrintingInstructions('ballotF')"
                      >
                        <v-list-tile-title
                          >Florida Ballot Printing</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile
                        @click="loadDefaultPrintingInstructions('meeting')"
                      >
                        <v-list-tile-title
                          >Meeting Documents, Pre Notice, PIC, and
                          ICU</v-list-tile-title
                        >
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-card-text>
                <v-textarea
                  :disabled="!isWritable"
                  class="mb-0"
                  rows="3"
                  label="Printing Instructions *"
                  auto-grow
                  v-model="state.printingInstructions"
                  :rules="rules.printingInstructions"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex class="ma-0 pa-0">
                <v-checkbox
                  v-model="state.proofsRequired"
                  hide-details
                  label="Proofs required?"
                  :disabled="!isWritable"
                  @change="handleUpdate"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row class="ma-3">
          <v-flex>
            <v-card-text class="ma-0 pa-0 text-xs-right">
              <v-btn
                @click="loadDefaultMailingInstructions"
                :disabled="!isWritable"
                >Use Default</v-btn
              >
            </v-card-text>
            <v-textarea
              :disabled="!isWritable"
              class="mb-0"
              rows="3"
              label="Mailing Instructions *"
              auto-grow
              :rules="rules.mailingInstructions"
              v-model="state.mailingInstructions"
            />
          </v-flex>
        </v-layout>
        <v-layout row class="ma-3">
          <v-flex>
            <v-card-text class="ma-0 pa-0 text-xs-right">
              <v-btn @click="loadFormattedReturnAddress" :disabled="!isWritable"
                >Use Default</v-btn
              >
            </v-card-text>
            <v-textarea
              class="mb-0"
              rows="4"
              label="Return Address *"
              v-model="state.returnAddress"
              :rules="rules.returnAddress"
              :disabled="!isWritable"
            />
          </v-flex>
        </v-layout>

        <v-layout row class="ma-3">
          <v-combobox
            :disabled="!isWritable"
            multiple
            v-model="state.recipients"
            label="Job Email Notifications Sent To *"
            append-icon
            chips
            deletable-chips
            :rules="rules.jobRecipients"
          />
        </v-layout>

        <v-layout>
          <v-card-text class="text-xs-right">
            <!-- contains all the button actions for the print job -->
            <meeting-print-job-actions
              v-if="currentPrintJob"
              :current-print-job="currentPrintJob"
              :form-is-valid="formIsValid"
              :next-status="nextStatus"
              @openDialog="dialog[$event] = true"
              @saveDraft="saveDraft"
              @process="processClicked"
              @back="$router.back()"
            />
          </v-card-text>
        </v-layout>
      </v-card>
    </v-form>

    <!-- DIALOGS -->

    <!-- Cancel is an unused dialog, button to open is never enabled -->
    <!-- <cancel-print-job-dialog
      :is-open="dialog.cancel"
      resource-name="print job"
      :action="'Cancel'"
      :resource-key="currentPrintJob ? currentPrintJob.key : ''"
      @delete="handleCancelPrintJob"
      @close-dialog="dialog.cancel = false"
    >
      <span slot="title">Cancel this print job?</span>
      <span slot="description"
        >This action will permanently cancel this print job and it will no
        longer appear in the listing. Are you sure?</span
      >
    </cancel-print-job-dialog> -->

    <delete-print-job-dialog
      :is-open="dialog.delete"
      resource-name="print job"
      :resource-key="currentPrintJob ? currentPrintJob.key : ''"
      @delete="handleDeletePrintJob"
      @close-dialog="dialog.delete = false"
    >
      <span slot="title">Delete this draft print job?</span>
      <span slot="description">
        Are you sure you want to delete
        <i>{{ currentPrintJob ? currentPrintJob.key : '' }}</i
        >? There's no going back...
      </span>
    </delete-print-job-dialog>

    <send-email-dialog
      :is-open="dialog.email"
      :to-email="currentUser.email"
      title="Send test?"
      label="Send test email to:"
      hint
      @send-email="sendTest"
      @close="dialog.email = false"
    ></send-email-dialog>

    <process-print-job-dialog
      :is-open="dialog.process"
      :process-action="nextStatus"
      :max-width="'700px'"
      :status-info="printStatus"
      :vendor-addresses="state.recipients"
      @process="handleProcess"
      @close-dialog="dialog.process = false"
    >
      <span slot="title">{{ nextStatus.label }}?</span>
      <span slot="description">Next status will be...</span>
    </process-print-job-dialog>

    <!-- Select Document(s) to Attach Dialog -->
    <attach-files-dialog
      :value="dialog.attachments"
      :attachments="items.printing"
      :meeting-documents="currentMeeting.documents"
      attachment-type="print"
      @update="setAttachments"
      @close="dialog.attachments = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getFormattedAddressWithNewline } from '@/lib/testemail-helpers.js';
import { formatDateHumanReadable } from '@/helpers';
import { printStatus } from '@/lib/print-job-status';
import defaults from '@/lib/default-print-job-content.json';

import _ from 'lodash';
import validator from 'email-validator';

import DeletePrintJobDialog from '@/components/dialogs/DeleteResourceDialog';
import ProcessPrintJobDialog from '@/components/dialogs/ProcessPrintJobDialog';
import CancelPrintJobDialog from '@/components/dialogs/DeleteResourceDialog';
import PrintJobFileUploadForm from '@/components/PrintJobFileUploadForm';
import SendEmailDialog from '@/components/dialogs/SendEmailDialog';
import DatePicker from '@/components/DatePicker';
import MeetingPrintJobActions from './MeetingPrintJobActions';
import AttachFilesDialog from '@/components/dialogs/AttachFilesDialog';
import { getRecipe } from '@/api/recipes';

export default {
  name: 'MeetingPrintJobView',
  components: {
    DeletePrintJobDialog,
    ProcessPrintJobDialog,
    CancelPrintJobDialog,
    DatePicker,
    PrintJobFileUploadForm,
    SendEmailDialog,
    MeetingPrintJobActions,
    AttachFilesDialog
  },
  filters: {
    formatDateHumanReadable
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      valid: true,
      state: {
        jobId: '',
        jobName: '',
        jobType: '',
        mailingDate: '',
        vendorKey: '',
        packagePagesPrinted: '',
        packagesMailed: '',
        printingInstructions: '',
        mailingInstructions: '',
        returnAddress: ''
      },
      dataLoaded: false,
      account: null,
      key: this.$route.params.key,
      dialog: {
        delete: false,
        process: false,
        cancel: false,
        email: false,
        attachments: false
      },
      returnAddress: '',
      items: {
        printing: [],
        recipients: []
      },
      jobItemType: {
        PRINTING: 'printing',
        RECIPIENTS: 'recipients'
      },
      // print status is passed as props to processPrintJobDialog
      printStatus: printStatus,
      printingFileFormatsAllowed: ['pdf', 'doc', 'docx'],
      recipientFileFormatsAllowed: ['csv', 'xls', 'xlsx'],
      jobTypes: ['meeting', 'budget', 'custom', 'certs'],
      vendors: [],
      validFormat: {
        printing: true,
        recipients: true
      },
      estimatedPackagePageCount: '',
      estimatedRecipientCount: '',
      // textfield rules
      rules: {
        jobId: [v => !!v || 'Job code is required'],
        jobName: [
          v => !!v || 'Job name is required',
          v => (v && v.length >= 5) || 'Job name must be at least 5 characters'
        ],
        jobType: [v => !!v || 'Job type is required'],
        vendor: [v => !!v || 'Vendor is required'],
        packagePagesPrinted: [
          v => /^\d*$/.test(v) || 'Print documents page count must be a number'
        ],
        packagesMailed: [
          v => /^\d*$/.test(v) || 'Recipient count must be a number'
        ],
        printingInstructions: [
          v => !!v || 'Printing Instructions are required'
        ],
        mailingInstructions: [v => !!v || 'Mailing Instructions are required'],
        returnAddress: [v => !!v || 'Return Adddress is required'],
        jobRecipients: [
          v => this.validateJobRecipients(v) || 'Invalid emails found'
        ]
      },
      priceDefault: null
    };
  },
  watch: {
    // Dynamically generate document title
    currentPrintJob: {
      immediate: true,
      handler(printJob) {
        if (printJob) {
          document.title = `${printJob.jobName} - ${this.currentMeeting.name}`;
        }
      }
    }
  },
  computed: {
    ...mapGetters('meetings/printJobs', [
      'meetingPrintJobData',
      'vendorListData',
      'vendorByKey',
      'meetingPrintJobByKey'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    currentPrintJob() {
      return this.meetingPrintJobByKey(this.key);
    },
    currentVendor() {
      return this.currentPrintJob
        ? this.vendorByKey(this.currentPrintJob.vendorKey)
        : {};
    },
    currentAccount() {
      return this.currentPrintJob
        ? this.accountByKey(this.currentPrintJob.accountKey)
        : {};
    },
    currentVendorAddresses() {
      const primary = this.currentVendor.primaryContact.email;
      const other = _.map(this.currentVendor.otherContacts, o => {
        return o.email;
      });
      const addresses = [primary].concat(other);
      return addresses;
    },
    nextStatus() {
      let next = {};

      // Looks up properties of nextStatus
      const nextState = status =>
        ({
          DRAFT: printStatus['SENT'],
          SENT: printStatus['IN_PROGRESS'],
          ON_HOLD: printStatus['IN_PROGRESS'],
          IN_PROGRESS: printStatus['COMPLETED'],
          COMPLETED: printStatus['COMPLETED']
        }[status] || printStatus['DRAFT']);

      if (this.currentPrintJob) {
        const status = this.currentPrintJob.status;
        next = nextState(status);
      }

      return next;
    },
    vendorOptions() {
      return _.map(this.vendorListData, vendor => {
        return { label: vendor.name, value: vendor.key };
      });
    },
    isWritable() {
      return this.currentPrintJob && this.currentPrintJob.status === 'DRAFT'
        ? true
        : false;
    },
    currentStatus() {
      return this.currentPrintJob
        ? printStatus[this.currentPrintJob.status]
        : printStatus['DRAFT'];
    },
    statusChipColor() {
      return this.currentPrintJob
        ? printStatus[this.currentPrintJob.status].color
        : '';
    },
    validPrinting() {
      let valid = false;
      if (this.items.printing.length > 0) {
        const foundInvalid = _.filter(this.items.printing, item => {
          return !this.isValidFileFormat(
            item.name,
            this.printingFileFormatsAllowed
          );
        });
        valid = foundInvalid.length > 0 ? false : true;
      }
      return valid;
    },
    validRecipients() {
      let valid = false;
      if (this.items.recipients.length > 0) {
        const lastItem = this.items.recipients[
          this.items.recipients.length - 1
        ];
        valid = this.isValidFileFormat(
          lastItem.name,
          this.recipientFileFormatsAllowed
        );
      }
      return valid;
    },
    validJobRecipients() {
      const invalid = _.filter(this.state.recipients, email => {
        return !validator.validate(email);
      });
      return invalid.length === 0 ? true : false;
    },
    formIsValid() {
      return (
        this.valid &&
        this.validPrinting &&
        this.validRecipients &&
        this.validJobRecipients
      );
    },
    isMeetingDocuments() {
      if (!this.currentMeeting.documents) {
        return false;
      } else {
        return this.currentMeeting.documents.length > 0;
      }
    },
    isCombinedSizeWarning() {
      let combinedSize = 0;
      this.items.printing.forEach(i => {
        combinedSize += parseInt(i.size);
      });

      if (combinedSize > 10000000 && this.items.printing.length > 1) {
        return true;
      } else {
        return false;
      }
    },

    processingCostPerPackage() {
      if (
        this.priceDefault &&
        this.state.packagePagesPrinted &&
        this.state.packagePagesPrinted != 0
      ) {
        return (
          this.priceDefault.pricePerPage * this.state.packagePagesPrinted +
          this.priceDefault.pricePerPackage
        ).toFixed(2);
      }
      return 0;
    },

    totalProcessingCost() {
      if (this.state.packagesMailed && this.state.packagesMailed !== 0) {
        return this.processingCostPerPackage * this.state.packagesMailed;
      }
      return 0;
    },

    paperWeight() {
      return 6 * this.state.packagePagesPrinted;
    },

    pricePerPiece() {
      // each paper - 6 grams
      // total paper weight (in grams)
      const paperWeight = this.paperWeight;

      let pricePerPiece = 0;
      if (paperWeight > 0 && paperWeight <= 100) {
        pricePerPiece = 1.94;
      } else if (paperWeight > 100 && paperWeight <= 200) {
        pricePerPiece = 3.19;
      } else if (paperWeight > 200 && paperWeight <= 300) {
        pricePerPiece = 4.44;
      } else if (paperWeight > 300 && paperWeight <= 400) {
        pricePerPiece = 5.09;
      } else if (paperWeight > 400 && paperWeight <= 500) {
        pricePerPiece = 5.47;
      }

      return pricePerPiece;
    },

    shippingCostPerPackage() {
      return (this.pricePerPiece * 1.13).toFixed(2);
    },

    totalShippingCost() {
      return (+this.shippingCostPerPackage * this.state.packagesMailed).toFixed(
        2
      );
    },

    totalCost() {
      return (+this.totalProcessingCost + +this.totalShippingCost).toFixed(2);
    }
  },
  created() {
    // Need this to load account when entering from print job list. But on
    // page reload, currentPrintJob might not exist yet.
    this.init();
  },
  methods: {
    ...mapActions('accounts', ['getAccount']),
    ...mapActions('meetings/printJobs', [
      'getMeetingPrintJobList',
      'getPrintJobItems',
      'saveMeetingPrintJob',
      'savePrintJobItems',
      'holdPrintJob',
      'cancelPrintJob',
      'removePrintJob',
      'processJob',
      'sendTestEmail'
    ]),
    async init() {
      try {
        if (!this.currentPrintJob && !this.dataLoaded) {
          await this.getMeetingPrintJobList({ key: this.currentMeeting.key });
        }

        this.state = _.cloneDeep(this.currentPrintJob);

        if (!('items' in this.currentPrintJob)) {
          const items = await this.getItems(this.currentPrintJob.key);
          _.set(this.state, 'items', items);
        }

        // Load Defaults if values do not exist
        if (!this.currentPrintJob.printingInstructions) {
          this.loadDefaultPrintingInstructions(this.state.jobType);
        }
        if (!this.currentPrintJob.mailingInstructions) {
          this.loadDefaultMailingInstructions();
        }
        if (!this.currentPrintJob.returnAddress) {
          this.loadFormattedReturnAddress();
        }

        // Set the vendor list to send to if empty
        if (_.isEmpty(this.state.recipients)) {
          this.state.recipients = this.currentVendorAddresses;
        }
        await this.getAccount({ key: this.currentPrintJob.accountKey });

        // normalize items into printing or recipient documents
        this.items = {
          printing: _.map(
            _.filter(this.state.items, p => {
              return p.jobItemType === this.jobItemType.PRINTING;
            }),
            i => {
              return i.filePackage;
            }
          ),
          recipients: _.map(
            _.filter(this.state.items, r => {
              return r.jobItemType === this.jobItemType.RECIPIENTS;
            }),
            i => {
              return i.filePackage;
            }
          )
        };

        let data;
        if (this.currentVendor) {
          let res;
          if (this.currentVendor.country === 'USA') {
            res = await getRecipe('printing.prices.us');
            data = res.data;
          } else if (this.currentVendor.country === 'Canada') {
            res = await getRecipe('printing.prices.defaults');
            data = res.data;
          }
        }

        // Price recipes
        this.priceDefault = data.data;

        // Set the next status
        this.next = this.nextStatus;
        this.dataLoaded = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async getItems(jobKey) {
      try {
        return await this.getPrintJobItems(jobKey);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleUpload(files, jobItemType) {
      let items = [];
      if (jobItemType === this.jobItemType.RECIPIENTS) {
        let recipientFile = files.length > 1 ? files.pop() : files[0];
        files = !_.isEmpty(recipientFile) ? [recipientFile] : [];
      }
      if (files.length > 0) {
        for (let itemFile of files) {
          let printJobItem = {
            Bucket: itemFile.Bucket,
            Key: itemFile.Key,
            size: itemFile.size,
            name: itemFile.name,
            mimetype: itemFile.mimetype,
            url: itemFile.url
          };
          items.push(printJobItem);
        }
      }
      this.items[jobItemType] = items;
    },
    handleDateUpdate(value) {
      if (value === null) {
        this.$refs.form.validate();
      } else {
        this.handleUpdate({ key: 'mailingDate', value });
      }
    },
    handleUpdate(obj) {
      _.set(this.state, obj.key, obj.value);
    },
    setAttachments(attachments) {
      this.items.printing = attachments;
      this.dialog.attachments = false;
    },
    async saveDraft(silent = false) {
      try {
        const printJob = await this.saveMeetingPrintJob({
          jobKey: this.currentPrintJob.key,
          meetingKey: this.currentMeeting.key,
          payload: this.state
        });
        await this.savePrintJobItems({
          jobKey: this.currentPrintJob.key,
          jobItems: this.items
        });
        if (!silent) {
          this.$events.$emit('toastEvent', 'Print Job Saved');
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async setSelectedVendor(vendorKey) {
      try {
        // when setting a vendor selection, trigger any other resulting changes (i.e. notificaiton recipients, billing recalculation, etc.)
        const emails = this.getVendorContactEmails(vendorKey);
        this.state.recipients = emails;

        const currentVendor = this.vendorByKey(vendorKey);

        let data;
        if (currentVendor) {
          let res;
          if (currentVendor.country === 'USA') {
            res = await getRecipe('printing.prices.us');
            data = res.data;
          } else if (currentVendor.country === 'Canada') {
            res = await getRecipe('printing.prices.defaults');
            data = res.data;
          }
        }

        // Price recipes
        this.priceDefault = data.data;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    getVendorContactEmails(key) {
      const vendor = this.vendorByKey(key);
      const primary = vendor.primaryContact.email;
      const other = _.map(vendor.otherContacts, o => {
        return o.email;
      });
      const addresses = [primary].concat(other);
      return addresses;
    },
    // The buttons that use these methods are currently always disabled
    // async handleCancelPrintJob() {
    //   try {
    //     await this.cancelPrintJob({
    //       jobKey: this.currentPrintJob.key,
    //       meetingKey: this.currentMeeting.key,
    //       recipients: this.state.recipients
    //     });
    //     this.$router.push({ name: 'meetingPrintJobs' });
    //     this.dialog.cancel = false;
    //   } catch (err) {
    //     this.$events.$emit('showErrorDialog', err.response);
    //   }
    // },
    // async hold() {
    //   try {
    //     await this.holdPrintJob({
    //       jobKey: this.currentPrintJob.key,
    //       meetingKey: this.currentMeeting.key,
    //       recipients: this.state.recipients
    //     });
    //     this.$events.$emit('toastEvent', 'Print Job put on hold');
    //   } catch (err) {
    //     this.$events.$emit('showErrorDialog', err.response);
    //   }
    // },
    async handleDeletePrintJob() {
      try {
        await this.removePrintJob({
          jobKey: this.currentPrintJob.key,
          meetingKey: this.currentMeeting.key
        });
        this.$router.push({ name: 'meetingPrintJobs' });
        this.dialog.delete = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleProcess({ urgent }) {
      try {
        this.$events.$emit(
          'toastEvent',
          `${this.nextStatus.label} print job...`
        );
        // if job writable, save any changes before processing
        if (this.currentPrintJob.status === 'DRAFT') {
          await this.saveDraft(true);
        }

        // Adds support inbox to list of recipients if on production
        if (process.env.NODE_ENV === 'production') {
          this.state.recipients.push('support@getquorum.com');
        }

        // Create processJob params
        let processJob = {
          jobKey: this.currentPrintJob.key,
          meetingKey: this.currentMeeting.key,
          recipients: this.state.recipients
        };

        // Add urgent flag if provided
        if (urgent) {
          processJob.urgent = urgent;
        }

        await this.processJob(processJob);
        this.$events.$emit('toastEvent', 'Print Job changes submitted');
        this.dialog.process = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    validate() {
      const baseValidated = this.$refs.form.validate();
      const attachmentsFound = this.validPrinting && this.validRecipients;
      const validRecipientEmails = this.validJobRecipients;
      this.valid = baseValidated && attachmentsFound && validRecipientEmails;
      return this.valid;
    },
    processClicked() {
      // Checks all the validation in the form and opens the process dialog
      // Technically there is no way for the send email button to be enabled if it fails any validation, possibly remove
      this.dialog.process = this.validate();
    },
    isValidFileFormat(fileName, allowed) {
      let valid = false;
      const fileExtension = fileName.split('.').pop();
      if (allowed.length > 0 && allowed.includes(fileExtension)) {
        valid = true;
      }
      return valid;
    },
    async sendTest(email) {
      try {
        const jobKey = this.currentPrintJob.key;
        // if job writable, save any changes before test
        if (this.currentPrintJob.status === 'DRAFT') {
          await this.saveDraft(true);
        }
        await this.sendTestEmail({ jobKey, email: email.toEmail });
        this.$events.$emit('toastEvent', 'Test email sent');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    validateJobRecipients(recipients) {
      let invalid = _.filter(recipients, email => {
        return !validator.validate(email);
      });
      return recipients.length > 0 && invalid.length === 0 ? true : false;
    },

    // Load instruction defaults
    loadDefaultPrintingInstructions(jobType) {
      if (jobType === 'budget') {
        this.state.printingInstructions = defaults[jobType];
      } else if (jobType === 'ballotF') {
        this.state.printingInstructions = defaults[jobType];
      } else {
        this.state.printingInstructions = defaults['meeting'];
      }
    },
    loadDefaultMailingInstructions() {
      this.state.mailingInstructions = defaults['mailing'];
    },
    loadFormattedReturnAddress() {
      // use main address if no return address information stored
      const retAddr = !_.isEmpty(this.currentAccount.returnAddress.address)
        ? this.currentAccount.returnAddress
        : this.currentAccount.address;

      this.state.returnAddress = getFormattedAddressWithNewline(
        this.currentAccount.nameShort,
        retAddr
      );
    }
  }
};
</script>

<style scoped>
.status-chip-text {
  font-size: larger;
  font-weight: bold;
}
.disabled {
  opacity: 0.5;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.required {
  color: red;
}
</style>
