<template>
  <div>
    <v-layout row wrap v-if="loading">
      <v-flex xs12 class="h-flex center">
        <v-progress-circular indeterminate color="primary" />
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else-if="!loading && !webcast">
      <v-flex xs12 class="v-flex">
        <v-icon x-large class="pt-4" color="grey">tv_off</v-icon>
        <p class="pt-2 grey--text">No webcast associated with this campaign</p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="px-2" v-else-if="!loading && webcast">
      <v-flex xs3>Zoom ID </v-flex>
      <v-flex xs9>{{ zoomData && zoomData.id }}</v-flex>
      <v-flex xs12 class="px-0">
        <v-btn
          outline
          color="primary"
          :disabled="!dialInEnabled"
          @click="onCopyDialIn"
        >
          COPY DIAL-IN NUMBERS
        </v-btn>
        <v-btn color="primary" @click="bootup">START WEBCAST</v-btn>
      </v-flex>
    </v-layout>

    <start-dialog
      v-if="zoomData"
      :is-open="dialog.start"
      :start-url="startUrl"
      :booting="booting"
      :license="license"
      @close="dialog.start = false"
    />

    <boot-error-dialog
      :is-open="dialog.bootError"
      :error="bootError"
      @close="dialog.bootError = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import StartDialog from '../webcast/StartDialog.vue';
import BootErrorDialog from '../webcast/BootErrorDialog.vue';
export default {
  name: 'WebcastContent',
  components: {
    MeetingOverviewCard,
    StartDialog,
    BootErrorDialog
  },
  props: {
    webcast: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bootError: '',
      booting: false,
      dialog: {
        start: false,
        bootError: false
      },
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['login', 'isAdmin', 'getScopeByAction']),
    ...mapGetters('v2/webcasts', ['zoomData', 'license']),
    dialInEnabled() {
      const numbers = _.get(this.zoomData, 'settings.globalDialInNumbers', []);
      return numbers.length > 0;
    },
    hasPermissionsToAssignLicense() {
      const { profile } = this.login;
      return (
        this.getScopeByAction(this.shortcode, 'meeting.webcast.boot.early') &&
        profile.type === 'user'
      );
    },
    timeBeforeMeeting() {
      return process.env.WEBCAST_START_HOST_TIME || 90;
    },
    canStartMeeting() {
      if (!this.zoomData || !this.zoomData.timezone) {
        return false;
      }
      const { timezone, startTime } = this.zoomData;
      return (
        this.isAdmin ||
        this.hasPermissionsToAssignLicense ||
        !moment()
          .tz(timezone)
          .isBefore(
            moment(startTime).subtract(this.timeBeforeMeeting, 'minutes')
          )
      );
    },
    startUrl() {
      return this.canStartMeeting && this.zoomData
        ? this.zoomData.startUrl
        : null;
    }
  },
  methods: {
    ...mapActions('v2/webcasts', ['bootWebcast']),
    onCopyDialIn() {
      if (this.dialInEnabled) {
        const dialIns = _.get(
          this.zoomData,
          'settings.globalDialInNumbers',
          []
        );
        const phoneNumbers = dialIns
          .map(v => `${v.city}: ${v.number}`)
          .join(', ');
        navigator.clipboard.writeText(phoneNumbers);
        this.$events.$emit('toastEvent', 'Copied to clipboard!');
      }
    },
    // Execute the webcast bootup sequence
    async bootup() {
      if (!this.canStartMeeting) {
        return;
      }
      try {
        this.dialog.start = true;
        this.booting = true;
        await this.bootWebcast({ shortCode: this.shortCode });
      } catch (err) {
        this.dialog.start = false;
        if (err.response) {
          if (err.response.data === 'NO_LICENSES_AVAILABLE') {
            this.bootError =
              'There are no licenses available to start the meeting now. Please contact support.';
          } else {
            this.bootError = err.response.data;
          }
        } else if (err.request) {
          this.bootError = 'Service unavailable. Please contact support.';
        } else {
          console.log(err);
          this.bootError = 'There has been an error. Please contact support.';
        }
        this.dialog.bootError = true;
      } finally {
        this.booting = false;
      }
    }
  }
};
</script>

<style>
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.v-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center {
  justify-content: center;
}
.wrapping {
  flex-wrap: wrap;
}
</style>
