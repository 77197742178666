<template>
  <div>
    <p class="body-2">Add Consents / Rejections</p>
    <v-card>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="codemirror-container">
              <span class="caption"
                >Enter the email addresses here, one on each line or separated
                by commas</span
              >
              <code-mirror
                :code-array="state.emails"
                :is-delimited="true"
                @update="handleUpdate('emails', $event)"
              />
            </div>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              v-model="state.status"
              :items="statuses"
              label="Are these consented or rejected emails?"
              required
            />
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              v-model="state.type"
              :items="methods"
              item-text="label"
              item-value="value"
              label="How did this come about?"
              required
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <span v-show="dataSaved"><v-icon>check</v-icon> Saved</span>
        <span v-show="error && !dataSaved"
          ><v-icon>warning</v-icon> Error: {{ error }}</span
        >
        <v-spacer />
        <v-btn
          flat
          color="primary"
          @click.native="clearInputs"
          :disabled="!isDirty"
          >Clear</v-btn
        >
        <v-btn
          flat
          color="primary"
          @click.native="dialog.add = true"
          :disabled="!isSubmitable"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog.add" max-width="500px">
      <v-card>
        <v-card-text>
          <p class="headline">Are you sure?</p>
          You're about to add <strong>({{ state.emails.length }})</strong> email
          address<span v-show="state.emails.length > 1">es</span> as
          <strong
            ><span :class="state.status">{{ state.status }}</span></strong
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.add = false">Close</v-btn>
          <v-btn class="primary" @click.native="addConsent">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import CodeMirror from '@/components/CodeMirror';

export default {
  name: 'AdminAccountConsentsViewAdd',
  components: {
    CodeMirror
  },
  props: {
    currentAccountKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      statuses: ['consented', 'rejected'],
      methods: [
        {
          label: 'Requested by the unit owner/voter',
          value: 'owner-requested'
        },
        {
          label: 'Requested by the client/customer',
          value: 'customer-provided'
        }
      ],
      state: {
        status: null,
        type: null,
        emails: []
      },
      dialog: {
        add: false
      },
      dataSaved: false,
      error: null
    };
  },
  computed: {
    isDirty() {
      return this.state.status || this.state.type || this.state.emails;
    },
    isSubmitable() {
      return (
        this.state.status && this.state.type && !_.isEmpty(this.state.emails)
      );
    }
  },
  methods: {
    ...mapActions('accounts', ['addAccountConsents']),
    clearInputs() {
      this.state.status = null;
      this.state.type = null;
      while (this.state.emails.length > 0) {
        this.state.emails.pop();
      }
    },
    handleUpdate(key, value) {
      // Lodash search state object for matching key
      _.set(this.state, key, value);
    },
    async addConsent() {
      try {
        this.dataSaved = false;
        await this.addAccountConsents({
          key: this.currentAccountKey,
          emails: _.uniq(this.state.emails),
          type: this.state.type,
          status: this.state.status
        });
        this.dataSaved = true;
        this.dialog.add = false;
        this.clearInputs();
      } catch (err) {
        this.error = err.response.data.details[0].message;
      }
    }
  }
};
</script>

<style scoped>
.codemirror-container {
  height: 90px;
  margin-bottom: 10px;
}
.consented {
  color: #43a047;
}
.rejected {
  color: #ef5350;
}
</style>
