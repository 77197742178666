import http from './requestType';

/**
 * Returns a list of the ballots for this meeting
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise}          - Promise
 */
export function getMeetingsBallotList(shortCode) {
  return http._get(`Meetings/${shortCode}/ballots`);
}

/**
 * Get the ballot status for this particular email (could be an owner or proxy)
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} email     - voter email
 * @return {Promise}          - Promise
 */
export function getMeetingBallotStatus(shortCode, email) {
  return http._get(`Meetings/${shortCode}/ballots/actions/status`, { email });
}

/**
 * Returns a list of eligible units for this ballot voter
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballotKey - ballot key
 * @return {Promise}          - Promise
 */
export function getMeetingBallotUnitList(shortCode, ballotKey) {
  return http._get(`Meetings/${shortCode}/ballots/${ballotKey}/units`);
}

/**
 * Create a Ballot
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballot    - newly created ballot data
 * @return {Promise}          - Promise
 */
export function postMeetingsBallot(shortCode, ballot) {
  return http._post(`Meetings/${shortCode}/ballots`, ballot);
}

/**
 * Delete a ballot
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballotKey - the unique ballot key to delete
 * @return {Promise}          - Promise
 */

export function deleteMeetingBallot(shortCode, ballotKey) {
  return http._delete(`Meetings/${shortCode}/ballots/${ballotKey}`);
}

/**
 * Add unit claim to ballot
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballotKey - the ballot that lays claim to the unit key
 * @param  {string} unitKey   - the unit key to which the claim will be deleted
 * @return {Promise}          - Promise
 */

export function addMeetingBallotUnit(shortCode, ballotKey, unitKey) {
  return http._post(
    `Meetings/${shortCode}/ballots/${ballotKey}/unit/${unitKey}`
  );
}

/**
 * Delete unit claim from ballot
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballotKey - the ballot that lays claim to the unit key
 * @param  {string} unitKey   - the unit key to which the claim will be deleted
 * @return {Promise}          - Promise
 */

export function deleteMeetingBallotUnit(shortCode, ballotKey, unitKey) {
  return http._delete(
    `Meetings/${shortCode}/ballots/${ballotKey}/unit/${unitKey}`
  );
}

/**
 * Get the ballot link for this voter
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} ballotKey - the ballot
 * @return {Promise}          - Promise
 */

export function getMeetingVoterBallotLink(shortCode, email) {
  return http._get(`Meetings/${shortCode}/ballots/actions/link`, { email });
}

/**
 * Delete ballot keys passed as an array
 * @param  {string} shortCode - the meeting shortcode
 * @param  {Array} ballotKeys - the array of ballot keys to delete
 * @return {Promise}          - Promise
 */

export function deleteMeetingBallotKeys(shortCode, ballotKeys) {
  return http._post(
    `Meetings/${shortCode}/ballots/actions/delete-ballots`,
    ballotKeys
  );
}

/**
 * Send the ballot email to the selected list of voters
 * @param  {string} shortCode   - the meeting shortcode
 * @param  {Array}  voters      - the array of claim request keys to send
 * @return {Promise}            - Promise
 */
export function sendBallotEmails(shortCode, voters) {
  return http._post(
    `Meetings/${shortCode}/ballots/actions/send-ballots`,
    voters
  );
}
