'use strict';

/**
 * @fileoverview Vuex module for contacts
 */

import _ from 'lodash';

// API Calls
import * as contactApi from '@/api/contacts';

const state = {
  contactList: [],
  pagination: {
    after: undefined,
    limit: 25
  }
};

const getters = {
  contactList: state => state.contactList,
  pagination: state => state.pagination
};

const actions = {
  /**
   * Get the latest Contacts from API
   *
   */
  async getContacts({ commit }, search = '') {
    try {
      const res = await contactApi.getContacts({
        limit: state.pagination.limit,
        after: state.pagination.after,
        search
      });

      commit('SET_CONTACTS', {
        contacts: res.data.contacts
      });

      return res.data.contacts;
    } catch (err) {
      console.error('ERROR: getContacts action', err);
      throw err;
    }
  },

  /**
   * Creates a new contact
   *
   * @param {Object} payload - New contact data
   * @returns
   */
  async createContact({ dispatch }, payload) {
    try {
      await contactApi.createContact(payload);
      dispatch('getContacts');
    } catch (err) {
      console.error('ERROR: createContact action', err);
      throw err;
    }
  },

  /**
   * Updates a contact
   *
   * @param {String} id      - The contact id
   * @param {Object} payload - contact data
   * @returns
   */
  async updateContact({ dispatch }, { id, payload }) {
    try {
      await contactApi.updateContact(id, payload);
      dispatch('getContacts');
    } catch (err) {
      console.error('ERROR: updateContact action', err);
      throw err;
    }
  },

  setPagination({ commit, dispatch }, pagination) {
    commit('SET_PAGINATION', pagination);
  }
};

const mutations = {
  SET_CONTACTS(state, { contacts }) {
    state.contactList = contacts;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
