<template>
  <v-dialog :value="isOpen" max-width="400px" persistent>
    <v-card>
      <v-card-title>
        <div class="headline">
          {{ isEditing ? 'Edit Contact' : 'Add New Contact' }}
        </div>
      </v-card-title>
      <div class="pa-3">
        <v-flex xs12 md12>
          <v-form ref="contactForm">
            <v-text-field
              prepend-icon="email"
              label="Email"
              v-model="contactForm.email"
              hint="sample@email.com"
              :disabled="isEditing"
              :rules="rules.email"
              required
            />
            <v-text-field
              prepend-icon="person"
              label="First Name"
              v-model="contactForm.firstname"
              :rules="rules.firstname"
              required
            />
            <v-text-field
              prepend-icon="person"
              label="Last Name"
              v-model="contactForm.lastname"
              :rules="rules.lastname"
              required
            />
            <v-text-field
              prepend-icon="phone"
              label="Phone"
              v-model="contactForm.phone"
              hint="(555) 555 - 5555"
              :rules="rules.phone"
              required
            />
          </v-form>
        </v-flex>
      </div>
      <v-card-actions>
        <v-btn color="primary" flat @click.native="emitCloseDialog"
          >Cancel</v-btn
        >
        <v-spacer />
        <v-btn color="primary" @click.native="handleSubmit">
          {{ isEditing ? 'Save' : 'Add' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validator from 'email-validator';

export default {
  name: 'AddContactDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: {
        firstname: [v => !!v || 'first name is required'],
        lastname: [v => !!v || 'last name is required'],
        phone: [v => !!v || 'phone is required'],
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ]
      },
      contactForm: {
        email: '',
        firstname: '',
        lastname: '',
        phone: ''
      }
    };
  },
  watch: {
    contact() {
      if (this.contact) {
        const contact = {
          email: this.contact.email,
          firstname: this.contact.firstName,
          lastname: this.contact.lastName,
          phone: this.contact.phone
        };

        this.contactForm = contact;
      }
    },
    isOpen() {
      if (!this.isOpen) {
        this.resetForm();
      }
    }
  },
  computed: {
    isEditing() {
      return this.contact ? true : false;
    }
  },
  methods: {
    emitCloseDialog() {
      this.$emit('closeDialog');
      this.resetForm();
    },
    handleSubmit() {
      if (this.isEditing) {
        return this.emitEdit();
      }

      this.emitAdd();
    },
    emitAdd() {
      if (this.$refs.contactForm.validate()) {
        this.$emit('emitAdd', this.contactForm);
      }
    },
    emitEdit() {
      if (this.$refs.contactForm.validate()) {
        this.$emit('emitEdit', this.contactForm);
      }
    },
    resetForm() {
      this.$refs.contactForm.resetValidation();
      this.contactForm = {
        email: '',
        firstname: '',
        lastname: '',
        phone: ''
      };
    }
  }
};
</script>
