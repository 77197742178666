<template>
  <v-chip
    v-if="countdown"
    :color="timer < lastSecondsLeft ? 'red' : undefined"
    :text-color="timer < lastSecondsLeft ? 'white' : undefined"
  >
    Vote time left: {{ countdown }}
  </v-chip>
  <v-chip v-else-if="voteEndedAt"> Vote ended {{ voteEndedAt }} </v-chip>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'LiveVoteTimer',
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      timer: null,
      countdown: null,
      interval: null,
      lastSecondsLeft: 10000
    };
  },
  computed: {
    // Time of vote ended
    voteEndedAt() {
      if (this.question?.end_at) {
        const now = moment(new Date().getTime());
        const endTime = moment(new Date(this.question.end_at).getTime());

        if (now > endTime) {
          const timeAgo = moment.duration(now.diff(endTime));

          return Number(timeAgo.days()) > 0
            ? `${timeAgo.days()} day(s) ago`
            : Number(timeAgo.hours()) > 0
            ? `${timeAgo.hours()} hour(s) ago`
            : Number(timeAgo.minutes()) > 0
            ? `${timeAgo.minutes()} minute(s) ago`
            : Number(timeAgo.seconds()) > 0
            ? `${timeAgo.seconds()} second(s) ago`
            : 'just now';
        }
        return 'just now';
      }
      return undefined;
    },
    // Question is live from initial state or incoming live state
    isLive() {
      const currentTime = moment();
      const startTime = moment(this.question.start_at);
      const endTime = moment(this.question.end_at);

      return currentTime.isBetween(startTime, endTime);
    },
    // Time left from initial or live push event, if it exists
    timeLeft() {
      if (this.isLive) {
        return moment(this.question.end_at).diff(moment());
      }
      return null;
    }
  },
  watch: {
    timeLeft: {
      immediate: true,
      handler(val) {
        this.destroyCountdownTimer();

        if (val) {
          this.timer = moment.utc(val);
          this.createCountdownTimer();
        }
      }
    },
    isLive(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.clearData();
      }
    }
  },
  destroyed() {
    this.destroyCountdownTimer();
  },
  methods: {
    createCountdownTimer() {
      if (
        moment().isBefore(moment(this.question.end_at).subtract(1, 'hours'))
      ) {
        this.countdown = moment(this.question.end_at).fromNow(true);
      } else {
        this.countdown = this.timer.format('mm:ss');
      }

      // Create a new countdown timer
      this.interval = setInterval(() => {
        // If timer age is more than 1000 milis, then substract 1 second of from the timer
        if (this.timer >= 1000) {
          if (
            moment().isBefore(moment(this.question.end_at).subtract(1, 'hours'))
          ) {
            this.timer.subtract(1, 'seconds');
            this.countdown = moment(this.question.end_at).fromNow(true);
          } else {
            this.timer.subtract(1, 'seconds');
            this.countdown = this.timer.format('mm:ss');
          }
        }
        // Otherwise clear the data and emit timer stop
        else {
          this.clearData();
        }
      }, 1000);
    },
    clearData() {
      this.countdown = null;
      this.timer = null;
      this.emitClosed();
      this.destroyCountdownTimer();
    },
    destroyCountdownTimer() {
      clearInterval(this.interval);
    },
    emitClosed() {
      this.$emit('closed', this.question.type);
    }
  }
};
</script>
