<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-spacer />

      <v-btn v-if="isAdmin" color="primary" @click="$emit('create')">
        <v-icon left>add_box</v-icon>New Request
      </v-btn>

      <v-btn flat icon @click.native="$emit('refresh')" :loading="loading">
        <v-icon>cached</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="approvalsListData"
      :loading="loading"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">
          <router-link :to="getReviewRouteObject(props.item.key)">
            {{ props.item.name }}
          </router-link>
        </td>
        <td class="text-xs-left uppercase">
          <v-chip
            small
            v-if="props.item.approvedAt"
            color="purple"
            class="white--text"
            >Approved</v-chip
          >
          <v-chip
            small
            v-else-if="props.item.status === 'open'"
            color="green"
            class="white--text"
            >Open</v-chip
          >
          <v-chip small v-else color="grey" class="white--text">Closed</v-chip>
        </td>
        <td class="text-xs-left capitalize">
          {{
            props.item.owners && props.item.owners.length > 0
              ? `${props.item.owners[0].firstName} ${props.item.owners[0].lastName}`
              : 'None'
          }}
        </td>
        <td class="text-xs-left">
          {{ props.item.reviewers ? props.item.reviewers.length : 0 }}
        </td>
        <td class="text-xs-left">
          {{ props.item.updatedAt | dateFormatReadable(true) }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'MeetingApprovalsTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    dateFormatReadable
    // formatCurrencyValue
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Owner', sortable: false },
        { text: 'Reviewers', sortable: false },
        { text: 'Last Updated', value: 'updatedAt' }
      ]
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/approvals', ['approvalsListData'])
  },
  methods: {
    getReviewRouteObject(arKey) {
      return {
        name: 'meetingApprovalEdit',
        params: {
          arKey
        }
      };
    }
  }
};
</script>

<style scoped>
/* .value-cells {
  width: 100px;
}
.invoice-link {
  cursor: pointer;
}
.invoicenumber {
  text-transform: uppercase;
} */
</style>
