'use strict';

/**
 * @fileoverview Vuex module for checklist templates
 */

import _ from 'lodash';
import Vue from 'vue';
import * as api from '@/api/checklist-templates';
import { handleError } from '@/helpers';

const state = {
  checklistTemplatesList: {},
  dataLastFetchedAt: null
};

const getters = {
  checklistTemplatesList: state => state.checklistTemplatesList,
  checklistTemplatesListData: state =>
    Object.values(state.checklistTemplatesList),
  checklistTemplateById: state => id => state.checklistTemplatesList[id],
  checklistTemplatesDataLastFetched: state => state.dataLastFetchedAt
};

const actions = {
  /**
   * Get the list of checklist templates from API
   */
  async getChecklistTemplates({ commit }) {
    try {
      const res = await api.getChecklistTemplates();
      return commit('SET_CHECKLIST_TEMPLATE_LIST_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getChecklistTemplates action', err);
      handleError(err);
      throw err;
    }
  },

  /**
   * Get the checklist template identified by ID from API
   *
   * @param  {Integer} id    the checklist template ID
   */
  async getChecklistTemplate({ commit }, id) {
    try {
      const res = await api.getChecklistTemplate(id);
      commit('SET_CHECKLIST_TEMPLATE_IN_LIST', { id, data: res.data });
    } catch (err) {
      console.error('ERROR: getChecklistTemplate action', id, err);
      throw err;
    }
  },

  /**
   * Updates a checklist template
   *
   * @param {Integer} id       the checklist template id
   * @param {Object}  payload  the checklist template object update payload
   */
  async updateChecklistTemplate({ dispatch }, { id, payload }) {
    try {
      await api.putChecklistTemplate(id, payload);
      dispatch('getChecklistTemplate', id);
    } catch (err) {
      console.error('ERROR: updateChecklistTemplate action', payload, err);
      throw err;
    }
  },

  /**
   * Add a new checklist template
   *
   * @param  {String} name        the checklist name
   * @return {Integer}            the new checklist template's id
   */
  async createChecklistTemplate({ dispatch }, { name } = {}) {
    try {
      const payload = {
        name
      };
      const res = await api.postChecklistTemplate(payload);
      await dispatch('getChecklistTemplate', res.data.id);
      return res.data.id;
    } catch (err) {
      throw err;
    }
  },

  /**
   * Delete a checklist template
   * @param {Integer} id    the ID of the checklist template to delete
   */
  async removeChecklistTemplate({ dispatch }, id) {
    try {
      await api.deleteChecklistTemplate(id);
      dispatch('getChecklistTemplates');
    } catch (err) {
      console.error('ERROR: removeChecklistTemplate action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_CHECKLIST_TEMPLATE_LIST_DATA(state, data) {
    state.checklistTemplatesList = _.keyBy(data, 'id');
    state.dataLastFetchedAt = new Date();
  },

  SET_CHECKLIST_TEMPLATE_IN_LIST(state, { id, data }) {
    Vue.set(state.checklistTemplatesList, id, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
