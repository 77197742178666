// Vue Store/Router
import Vue from 'vue';
import store from './vuex/store';
import router from '@/routes';

Vue.config.productionTip = false;

// Material Design Icons
import 'material-design-icons-iconfont/dist/material-design-icons.css';

// Vuetify
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.css';
Vue.use(Vuetify, {
  theme: {
    accent: '#E91E63'
  }
});

// Vue Tour
import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
Vue.use(VueTour);

// Root Component
import App from './App';

// Declare global instance property for events
// available in all components with 'this.$events'
const events = new Vue();
Vue.prototype.$events = events;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
