<template>
  <v-container class="background" fill-height grid-list-md fluid>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg4>
        <v-card>
          <v-card-title class="gq-blue-background">
            <v-container>
              <v-layout column align-center>
                <v-flex xs12>
                  <img src="../assets/gq_navbar_logo.svg" class="login-image" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
          <v-card-text>
            <!-- ERROR MESSAGE -->
            <v-container grid-list-md v-if="error">
              <v-layout row>
                <v-flex xs12>
                  <h3>Error!</h3>
                  <p>
                    There is a problem with this request, see the message below.
                  </p>
                  <v-alert outline color="error" icon="warning" v-model="error">
                    <span v-if="tokenType === 'reset'"
                      >This password reset request has expired. Please click the
                      button below to request another password reset
                      email.</span
                    >
                    <span v-if="tokenType === 'invite'"
                      >This invitation has expired. Forgot your password? Click
                      on the button below to request a reset email.</span
                    >
                  </v-alert>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" align-center justify-center>
                <v-btn large @click.native="goForgotPassword" color="primary"
                  >Request Password Reset Email</v-btn
                >
              </v-layout>
            </v-container>

            <!-- Reset Form -->
            <v-container grid-list-md v-if="!error">
              <v-layout row>
                <v-flex xs12>
                  <h3 v-if="tokenType === 'reset'">Reset your password</h3>
                  <h3 v-if="tokenType === 'invite'">
                    Welcome! Your invitation has been accepted.
                  </h3>
                  <p class="secondary--text">Enter your new password below.</p>
                </v-flex>
              </v-layout>
              <v-form
                action
                v-model="valid"
                @submit.prevent="submitPasswordReset"
              >
                <!-- New Password -->
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="password"
                      id="password"
                      ref="password"
                      type="password"
                      label="Enter your new password here"
                      :append-icon="passwordStrengthIcon"
                      prepend-icon="lock"
                      @keyup.enter="$refs.confirm.focus()"
                      autocomplete="password"
                      :rules="rules.password"
                      v-model="password"
                      :hint="passwordStrengthMessage"
                      required
                    />
                  </v-flex>
                </v-layout>

                <!-- Password Confirmation -->
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="confirm"
                      id="confirm"
                      ref="confirm"
                      type="password"
                      label="Confirm your new password here"
                      prepend-icon="lock"
                      :rules="rules.confirm"
                      v-model="confirm"
                      required
                    />
                  </v-flex>
                </v-layout>

                <!-- Button -->
                <v-layout class="mt-4" align-center justify-center>
                  <v-btn
                    :disabled="!passwordMatch() || !valid"
                    :loading="loading"
                    large
                    type="submit"
                    color="primary"
                    >Set New Password</v-btn
                  >
                </v-layout>

                <!-- Link -->
                <v-layout class="mt-2" align-center justify-center>
                  <v-flex xs12 class="text-xs-center">
                    <router-link :to="{ name: 'login' }" replace>
                      <a class="secondary--text">Return to login page</a>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import strength from '@/lib/password-strength';

export default {
  name: 'ResetPasswordView',
  data() {
    return {
      password: '',
      confirm: '',
      token: this.$route.query.resetToken || this.$route.query.inviteToken,
      valid: false,
      loading: false,
      rules: {
        password: [
          v => !!v || 'Password is required',
          v => v.length >= 8 || 'Password is too short'
        ],
        confirm: [v => v === this.password || 'Passwords must match']
      },
      error: false
    };
  },
  computed: {
    tokenType() {
      if (this.$route.query.resetToken) {
        return 'reset';
      } else if (this.$route.query.inviteToken) {
        return 'invite';
      } else {
        return null;
      }
    },
    passwordStrength() {
      return strength(this.password);
    },
    passwordStrengthIcon() {
      return !this.password ? '' : this.passwordStrength.icon;
    },
    passwordStrengthMessage() {
      return `${this.passwordStrength.message.toUpperCase()} password`;
    }
  },
  created() {
    if (!this.token) {
      this.$router.push({ name: 'login' });
    }
    this.checkToken();
  },
  methods: {
    ...mapActions(['checkPasswordResetToken', 'doResetPassword']),
    async submitPasswordReset() {
      this.loading = true;
      try {
        await this.doResetPassword({
          token: this.token,
          type: this.tokenType,
          newPassword: this.password
        });

        this.$router.push({
          name: 'login',
          query: {
            alert: 'password-set'
          }
        });
      } catch (err) {
        console.error('Invalid token', err);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async checkToken() {
      try {
        await this.checkPasswordResetToken({
          token: this.token,
          type: this.tokenType
        });
      } catch (err) {
        console.error('Invalid token', err);
        this.error = true;
      }
    },
    goForgotPassword() {
      this.$router.push({ name: 'forgotPassword' });
    },
    passwordMatch() {
      return this.password === this.confirm;
    }
  }
};
</script>

<style scoped></style>
