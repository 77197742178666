<template>
  <router-view />
</template>

<script>
export default {
  name: 'AdminAgentsMain'
};
</script>
<style scoped>
.fill-height {
  height: 50vh;
}
</style>
