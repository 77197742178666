<template>
  <v-container>
    <!-- Use v2 api for file upload form -->
    <meeting-files-uploader
      :short-code="shortCode"
      api-version="v2"
      @update="refresh"
    />
  </v-container>
</template>

<script>
/* Meeting files upload component receives events from the files upload form */
import { mapActions } from 'vuex';
import MeetingFilesUploader from '@/views/meetings/meeting/files/MeetingFilesUploader';

export default {
  name: 'MeetingVbmFilesUploader',
  components: {
    MeetingFilesUploader
  },
  props: {
    shortCode: {
      type: String,
      required: true
    },
    attachment: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions('v2/meetingsFiles', ['fetchMeetingFiles']),
    async refresh() {
      try {
        // Refresh meeting files list
        // This is required for the
        await this.fetchMeetingFiles({ shortCode: this.shortCode });

        // Emit update to close the dialog
        this.$emit('update');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
