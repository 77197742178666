'use strict';

const moment = require('moment-timezone');

/**
 * Gets the next business day (M-F) from date specified. If no
 * date is specified then it'll use today's date.
 *
 * @param  {Date} date   date
 * @return {Date}        next business day
 */
export default function nextBusinessDay(date) {
  const startDate = date ? moment(date) : moment();
  const day = startDate.day();
  let endDate;

  if (day === 5) {
    // Friday ---> Monday
    endDate = startDate.add(3, 'days');
  } else if (day === 6) {
    // Saturday ---> Moday
    endDate = startDate.add(2, 'days');
  } else {
    // Sun to Thursday ---> next day
    endDate = startDate.add(1, 'day');
  }
  return endDate.toDate();
}
