import http from './requestType';

/** Searches for a list of agreements that the user has access to.
 * @return {Promise} - Promise
 */
export function getServiceAgreementList() {
  return http._get(`ServiceAgreements`);
}

/** Create a new agreement
 * @param  {Object} obj - the agreements object
 * @param  {String} obj.type          type of agreement
 * @param  {String} obj.accountKey    account to create agreement for
 * @param  {String} [obj.signedAt]    date the agreement was signed
 * @param  {String} [obj.expiresAt]   date the agreement expires
 * @param  {String} [obj.description] additional notes for the agreement
 * @param  {String} [obj.version]     version of the agreement
 * @return {Promise}    - Promise
 */
export function postServiceAgreement({
  type,
  accountKey,
  signedAt,
  expiresAt,
  description,
  version
}) {
  return http._post(`ServiceAgreements`, {
    type,
    accountKey,
    signedAt,
    expiresAt,
    description,
    version
  });
}

/** Returns the agreement associated with the key
 * @param  {String} key - the ServiceAgreement key
 * @return {Promise}    - Promise
 */
export function getServiceAgreement(key) {
  return http._get(`ServiceAgreements/${key}`);
}

/** Updates the agreements associated with the key
 * @param  {String} key             ServiceAgreement key
 * @param  {String} obj             ServiceAgreement object
 * @param  {Date}   obj.signedAt    date the agreement was signed
 * @param  {Date}   obj.expiresAt   date agreement expires
 * @param  {String} obj.description additional description of agreement
 * @return {Promise}    - Promise
 */
export function patchServiceAgreement(key, {signedAt, expiresAt, description}) {
  // If any param is empty, set it to null so API accepts it
  const params = {
    signedAt: signedAt ? signedAt : null,
    expiresAt: expiresAt ? expiresAt : null,
    description: description ? description : null
  };
  return http._patch(`ServiceAgreements/${key}`, params);
}

/** Deletes an agreement
 * @param  {String} key - the ServiceAgreement key
 * @return {Promise}    - Promise
 */
export function deleteServiceAgreement(key) {
  return http._delete(`ServiceAgreements/${key}`);
}

/** Returns the HTML preview of the service agreement
 * @param  {Object} obj             - payload
 * @param  {String} obj.type        - type of service agreement
 * @param  {String} obj.accountKey  - account to create agreement for
 * @return {Promise}                - Promise
 */
export function previewServiceAgreement({type, accountKey}) {
  return http._post(`ServiceAgreements/actions/preview-html`, {
    type,
    accountKey
  });
}

/** Returns the HTML preview of the service agreement
 * @param  {Object} obj             - payload
 * @param  {String} obj.type        - type of service agreement
 * @param  {String} obj.accountKey  - account to create agreement for
 * @return {Promise}                - Promise
 */
export function createAgreementPdf({type, accountKey}) {
  return http._post(`ServiceAgreements/actions/create-pdf`, {type, accountKey});
}
