<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Webcast Event Log</span>
      </v-card-title>
      <v-card-text>
        <ul>
          <li v-for="item in logs" :key="item.key">
            Started at: {{ item.startedAt | formatDateHumanReadable(true) }} -
            Ended at: {{ item.endedAt | formatDateHumanReadable(true) }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="close" flat>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateHumanReadable } from '@/filters';

export default {
  name: 'WebcastLogDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    logs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    formatDateHumanReadable
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
