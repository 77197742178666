<template>
  <v-dialog :value="isOpen" persistent scrollable max-width="700px">
    <v-card>
      <v-card-title class="headline">
        Add Default Motions
      </v-card-title>
      <v-card-text>
        <v-select
          label="Motions"
          placeholder="Select Motions"
          :value="motionType"
          :items="motionTypes"
          item-text="label"
          item-value="value"
          @change="setMotionsType"
        />
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="motionsOrdered"
          item-key="title"
          select-all
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <tr
              :class="{ 'expand-row-style': props.expanded === true }"
              @click="props.expanded = !props.expanded"
            >
              <td>
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details
                  @click.native.stop=""
                />
              </td>
              <td>{{ props.index + 1 }}. {{ props.item.title }}</td>
            </tr>
          </template>
        </v-data-table>

        <!-- <v-layout column v-for="(motion, index) in motions" :key="index">
          <v-checkbox
            v-model="selected"
            :label="motion.title"
            :value="motion"
          />
        </v-layout> -->
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click.native="close" flat :disabled="loading">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn @click.native="save" :loading="loading" color="primary">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'AddMotionsDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    motions: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    motionType: {
      type: String,
      default: 'defaults'
    }
  },
  watch: {},
  data() {
    return {
      headers: [{ text: 'Motion Title', value: 'title', sortable: false }],
      selected: [],
      motionTypes: [
        { label: 'Default Motions', value: 'defaults' },
        { label: 'Alberta Motions', value: 'alberta' },
        { label: 'Alternate Motions', value: 'alternate'}
      ]
    };
  },
  computed: {
    motionsOrdered() {
      const tmp = [...this.motions];
      return tmp.sort((a, b) => a.order - b.order);
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('meetings/webcast', ['setMotionsType']),
    setState() {
      this.selected = [];
    },
    save() {
      this.$emit('save', this.selected);
      this.selected = [];
    },
    close() {
      this.$emit('close');
      this.setState();
    }
  }
};
</script>

<style scoped></style>
