<template>
  <v-dialog :value="isOpen" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Create Standard Agreement (Unsigned PDF)</span>
      </v-card-title>
      <v-divider />
      <v-card-text style="height: 500px;">
        <v-container fluid>
          <v-layout align-center justify-center>
            <v-flex fill-height xs12>
              <!-- Preview HTML Here -->
              <span v-if="!isLoading" v-html="agreementPreviewHtml" />
              <!-- END Preview HTML Here -->

              <div v-if="isLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn flat @click.native="close">Cancel</v-btn>
        <v-spacer />
        <v-btn flat :loading="isCreating" @click.native="create">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AddAgreementDialog',
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    accountKey: {
      type: String,
      default() {
        return null;
      }
    },
    serviceType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      isCreating: false
    };
  },
  watch: {
    isOpen() {
      // Get the preview content each time it's open
      if (this.isOpen) {
        this.getPreview(this.serviceType);
      } else {
        this.resetState();
      }
    }
  },
  computed: {
    ...mapGetters('serviceAgreements', ['agreementPreviewHtml'])
  },
  methods: {
    ...mapActions('accounts', ['createAccountAgreement']),
    ...mapActions('serviceAgreements', ['getHtmlPreview']),
    close() {
      this.isLoading = false;
      this.isCreating = false;
      this.$emit('close-dialog');
    },
    async getPreview(serviceType) {
      try {
        this.isLoading = true;
        await this.getHtmlPreview({
          accountKey: this.accountKey,
          type: serviceType
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.isLoading = false;
      }
    },
    async create() {
      try {
        this.isCreating = true;
        await this.createAccountAgreement({
          type: this.serviceType,
          accountKey: this.accountKey
        });
        this.$events.$emit('toastEvent', 'Blank Agreement Created');
        this.close();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.isCreating = false;
      }
    },
    resetState() {
      this.html = '';
      this.isLoading = false;
      this.isCreating = false;
    }
  }
};
</script>

<style scoped></style>
