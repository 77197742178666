'use strict';

/**
 * @fileoverview Vuex module for samples
 */

import _ from 'lodash';
import * as api from '@/api/conversation-templates';
import * as ctgApi from '@/api/conversation-template-groups';

const state = {
  conversationTemplateList: {},
  conversationUniqueTemplateList: {},
  groupsForScheduler: []
};

const getters = {
  conversationTemplateList: state => state.conversationTemplateList,
  conversationTemplateListData: state =>
    Object.values(state.conversationTemplateList),
  conversationUniqueTemplateListData: state =>
    Object.values(state.conversationUniqueTemplateList),
  groupsForScheduler: state => state.groupsForScheduler
};

const actions = {
  /**
   * Get the latest conversation templates from API
   */
  async getConversationTemplates({ commit }) {
    try {
      const res = await api.getConversationTemplateList();
      return commit('SET_CONVERSATION_TEMPLATE_LIST_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getNoticeTemplates action', err);
      throw err;
    }
  },

  /**
   * Gets the list of conversation template groups for the scheduler
   */
  async getMeetingConversationGroups({ commit }) {
    try {
      const res = await ctgApi.getGroupsForScheduler();
      commit('SET_MEETING_CONVERSATION_TEMPLATE_GROUPS_IN_LIST', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingConversationGroups action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_CONVERSATION_TEMPLATE_LIST_DATA(state, data) {
    state.conversationTemplateList = _.keyBy(
      data,
      'conversationTemplateGroupMembershipKey'
    );
    state.conversationUniqueTemplateList = _.keyBy(data, 'noticeTemplateKey');
    state.dataLastFetchedAt = new Date();
  },
  SET_MEETING_CONVERSATION_TEMPLATE_GROUPS_IN_LIST(state, data) {
    state.groupsForScheduler = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
