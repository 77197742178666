<template>
  <v-dialog :value="value" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <div class="title red--text text--darken-1">
          Delete <span v-if="type === 'fromMembership'">Voter</span>
          <span v-else-if="type === 'spectators'">Spectator</span>
          <span v-else>Board Member</span>?
        </div>
      </v-card-title>
      <v-card-text>
        <v-alert outline color="error" icon="warning" :value="true">
          <strong>WARNING</strong>: You are about to delete this
          <span v-if="type === 'fromMembership'">voter</span>
          <span v-else-if="type === 'spectators'">spectator</span>
          <span v-else>board member</span>. Once deleted you cannot undo this
          action.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-btn class="error" @click.native="emitDelete">delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteVoterConfirmationDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default() {
        return 'fromMembership';
      }
    }
  },
  methods: {
    emitDelete() {
      this.$emit('delete');
    },
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>
