'use strict';

/**
 * @fileoverview Vuex module for samples
 */

import _ from 'lodash';
import Vue from 'vue';
import * as api from '@/api/notice-templates';

const state = {
  noticeTemplateList: {},
  noticeTemplateGroupMembership: {},
  dataLastFetchedAt: null,
  templateTypeItems: [
    'cs-onboard',
    'pre-consent',
    'pre-notice',
    'service',
    'vm',
    'vm-post',
    'other'
  ]
};

const getters = {
  noticeTemplateList: state => state.noticeTemplateList,
  noticeGroupByTemplate: state => state.noticeGroupByTemplate,
  noticeTemplateListData: state => Object.values(state.noticeTemplateList),
  noticeTemplateDataLastFetched: state => state.dataLastFetchedAt,
  noticeTemplateByKey: state => key => state.noticeTemplateList[key],
  templateTypeItems: state => state.templateTypeItems
};

const actions = {
  /**
   * Get the latest notice templates from API
   */
  async getNoticeTemplates({ commit }) {
    try {
      const res = await api.getNoticeTemplateList();

      return commit('SET_NOTICE_TEMPLATE_LIST_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getNoticeTemplates action', err);
      throw err;
    }
  },

  /**
   * Get the notice template identified by key from API
   *
   * @param  {Integer} key    the notice template key
   */
  async getNoticeTemplate({ commit }, key) {
    try {
      const res = await api.getNoticeTemplate(key);
      commit('SET_NOTICE_TEMPLATE_IN_LIST', { key, data: res.data });
    } catch (err) {
      console.error('ERROR: getNoticeTemplate action', key, err);
      throw err;
    }
  },

  /**
   * Updates a sample
   *
   * @param {Object}  obj           the params
   * @param {String}  obj.key       the notice template key
   * @param {Object}  obj.payload   the notice template update payload
   */
  async updateNoticeTemplate({ dispatch }, { key, payload }) {
    try {
      await api.putNoticeTemplate(key, payload);
      dispatch('getNoticeTemplate', key);
    } catch (err) {
      console.error('ERROR: updateNoticeTemplate action', payload, err);
      throw err;
    }
  },

  /**
   * Add a new notice template
   *
   * @param  {Object} obj                 - the new notice template object
   * @param  {String} obj.name            - the notice name
   * @param  {String} obj.subject         - the notice subject
   * @param  {String} obj.templateType    - the notice template
   * @return {String}                     - the new template's key
   */
  async createNoticeTemplate(
    { dispatch },
    { name, subject, templateType } = {}
  ) {
    const payload = {
      name,
      subject,
      body: 'TBD',
      segment: 'all',
      description: name, // This is a required field
      meta: {
        replyTo: false,
        templateType
      }
    };
    try {
      const res = await api.postNoticeTemplate(payload);
      await dispatch('getNoticeTemplate', res.data.key);
      return res.data.key;
    } catch (err) {
      console.error('ERROR: createNoticeTemplate action', payload, err);
      throw err;
    }
  },

  /**
   * Delete a sample
   * @param {String} key        the notice template key
   */
  async deleteNoticeTemplate({ dispatch }, key) {
    try {
      await api.deleteNoticeTemplate(key);
      dispatch('getNoticeTemplates');
    } catch (err) {
      console.error('ERROR: deleteNoticeTemplate action', err);
      throw err;
    }
  },

  /**
   * Get the notice templates available to the bulk notice scheduler from API
   */
  async getTemplatesForScheduler({ commit }) {
    try {
      const res = await api.getTemplatesForScheduler();
      return commit('SET_NOTICE_TEMPLATES_FOR_SCHEDULER', res.data);
    } catch (err) {
      console.error('ERROR: getTemplatesForScheduler action', err);
      throw err;
    }
  },

  /**
   * Get the notice membership groups through the notice template key
   */
  async getNoticeGroupByTemplate({ commit }, noticeTemplateKey) {
    try {
      const res = await api.getNoticeGroupByTemplate(noticeTemplateKey);
      return commit('SET_NOTICE_GROUP_BY_TEMPLATE', res.data);
    } catch (err) {
      console.error('ERROR: getNoticeGroupByTemplate action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_NOTICE_TEMPLATE_LIST_DATA(state, data) {
    state.noticeTemplateList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_NOTICE_TEMPLATES_FOR_SCHEDULER(state, data) {
    state.noticeTemplatesForScheduler = data;
  },

  SET_NOTICE_TEMPLATE_IN_LIST(state, { key, data }) {
    Vue.set(state.noticeTemplateList, key, data);
  },

  SET_NOTICE_GROUP_BY_TEMPLATE(state, data) {
    state.noticeGroupByTemplate = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
