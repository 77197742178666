<template>
  <v-card>
    <v-card-title>
      <div class="headline">Add New Product</div>
    </v-card-title>
    <div class="pa-3">
      <v-flex xs12 md12>
        <!-- <v-form v-model="valid"> -->
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              box
              v-model="state.name"
              label="Product Name"
              counter
            />
          </v-flex>
        </v-layout>

        <v-layout row nowrap>
          <v-flex xs12 class="pr-1">
            <v-text-field
              box
              type="number"
              v-model="state.productCode"
              label="Product Code"
            />
          </v-flex>
        </v-layout>

        <v-layout row nowrap>
          <v-flex xs6 class="pl-1 pr-1">
            <v-select
              box
              :items="productTypes"
              item-value="value"
              item-text="label"
              v-model="state.productType"
              label="Product Type"
              @change="setCategory"
            />
          </v-flex>

          <v-flex xs6 class="pl-1">
            <v-autocomplete
              v-model="state.category"
              :items="categories"
              box
              label="Product Category"
            />
          </v-flex>
        </v-layout>

        <v-layout row nowrap>
          <v-flex xs6 class="pr-1">
            <v-text-field
              box
              v-model="state.valueCad"
              label="CAD Price ($)"
              type="number"
              min="0"
            />
          </v-flex>

          <v-flex xs6 class="pl-1">
            <v-text-field
              box
              v-model="state.valueUsd"
              label="USD Price ($)"
              type="number"
              min="0"
            />
          </v-flex>
        </v-layout>

        <v-layout row nowrap>
          <v-flex xs6 class="pr-1">
            <v-text-field
              box
              v-model="state.minUnits"
              label="Minimum number of units in tier"
              type="number"
              min="0"
            />
          </v-flex>

          <v-flex xs6 class="pl-1">
            <v-text-field
              box
              v-model="state.maxUnits"
              label="Maximum number of units in tier"
              type="number"
              min="0"
            />
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <v-textarea
              box
              v-model="state.description"
              label="Product Description (Markdown Enabled)"
              counter
              auto-grow
            />
          </v-flex>
        </v-layout>
        <!-- </v-form> -->
      </v-flex>
    </div>
    <v-card-actions>
      <v-btn color="primary" flat @click.native="emitCloseDialog">Cancel</v-btn>
      <v-spacer />
      <v-btn color="primary" flat @click.native="add" :loading="saving">
        Add Product
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddProductDialog',
  data() {
    return {
      valid: false,
      saving: false,
      state: {
        name: '',
        productCode: 0,
        productType: 0,
        category: 'software',
        description: '',
        valueCad: 1,
        valueUsd: 1,
        minUnits: 1,
        maxUnits: 100000
      }
    };
  },
  computed: {
    ...mapGetters('products', [
      'productByKey',
      'productTypes',
      'categoriesByType'
    ]),
    categories() {
      return this.categoriesByType(this.state.productType);
    }
  },
  methods: {
    ...mapActions('products', ['addProduct']),
    emitCloseDialog() {
      this.$emit('closeDialog');
      this.resetState();
    },
    resetState() {
      this.state = {
        name: '',
        productCode: 0,
        productType: 0,
        category: 'software',
        description: '',
        valueCad: 1,
        valueUsd: 1,
        minUnits: 1,
        maxUnits: 100000
      };
    },
    // See if we need to set the category to discount if type was set to discount
    // See if we need to set the category to discount if type was set to discount
    setCategory() {
      if (this.state.productType === 2) {
        this.state.category = 'discount';
      } else if (this.state.productType === 1) {
        this.state.category = 'software license';
      } else {
        this.state.category = 'professional services';
      }
    },
    async add() {
      try {
        this.saving = true;
        const productKey = await this.addProduct({ product: this.state });
        this.$events.$emit('toastEvent', 'Product Added');
        this.emitCloseDialog();
        this.$router.push({
          name: 'adminProductsEdit',
          params: { productKey }
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
