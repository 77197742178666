<template>
  <div>
    <v-layout align-baseline>
      <div class="mr-2">
        <p class="note-name font-weight-bold mb-0">
          {{ name }}
        </p>
      </div>

      <v-spacer />

      <div
        v-if="!isDeleted"
        class="caption grey--text"
        :class="{
          'mr-4': !isOwner && !isAdmin
        }"
      >
        {{ updatedAt | formatDateHumanReadableAlt(true, meetingTimezone) }}
        <timezone-helper :date="updatedAt" />
      </div>

      <div v-else class="caption grey--text mr-4">
        {{ deletedAt | formatDateHumanReadableAlt(true, meetingTimezone) }}
        <timezone-helper :date="deletedAt" />
      </div>
      <v-menu left v-if="isAdmin || isOwner">
        <template v-slot:activator="{ on }">
          <!-- if note is 'account' type, it cannot be deleted from drawer -->
          <v-btn
            icon
            v-on="on"
            :disabled="type === 'account'"
            class="remove-note-btn"
            small
            v-if="!isDeleted"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0 ma-0">
          <v-list-tile
            class="red--text"
            @click="emitRemove(noteKey)"
            v-if="type != 'agent'"
          >
            <span class="body-2">Delete Message</span>
          </v-list-tile>
          <v-list-tile v-else-if="isAdmin">
            <router-link
              :to="{
                name: 'editAgent',
                params: { agentKey: noteKey }
              }"
            >
              Go to agent
            </router-link>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-layout>

    <v-layout v-if="taggedUsers && taggedUsers.length > 0">
      <p class="caption text--secondary mb-0">
        Tagged:
        {{ taggedUsers.map(u => u.name).join(', ') }}
      </p>
    </v-layout>

    <div
      class="message-style mt-2"
      :class="isDeleted && 'note-message-deleted'"
    >
      {{ message }}
    </div>
    <div :class="!customType ? 'read-container' : 'custom-read-container'">
      <v-btn
        v-if="isCollaborator && !isOwner && !read"
        color="primary"
        flat
        text
        small
        @click="emitMark(noteKey)"
        :disabled="markNotesLoading"
      >
        Mark as read
      </v-btn>
      <v-btn
        v-if="isCollaborator && isOwner"
        color="primary"
        flat
        text
        small
        @click="emitUpdateNoteType()"
      >
        <span v-if="type !== 'invoice'">Mark as Invoice note</span>
        <span v-else>Mark as Collaborator note</span>
      </v-btn>
      <v-chip
        v-if="customType"
        small
        label
        class="white--text"
        disabled
        :color="eventColor(customType)"
        ><strong>{{ customType }}</strong></v-chip
      >
      <v-chip
        small
        label
        class="white--text"
        disabled
        :color="eventColor(type)"
      >
        <strong>{{ type }}</strong>
      </v-chip>
    </div>

    <p
      v-if="type === 'account'"
      class="caption grey--text mr-2 mb-0 text-xs-right"
    >
      <v-icon small>visibility</v-icon> This note is visible to admins only
    </p>
  </div>
</template>

<script>
import momentTz from 'moment-timezone';
import getEventColor from '@/lib/get-event-color';
import TimezoneHelper from '@/components/TimezoneHelper';

export default {
  name: 'MeetingNotesMessage',
  components: {
    TimezoneHelper
  },
  props: {
    noteKey: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    updatedAt: {
      type: String,
      default: ''
    },
    deletedAt: {
      type: String,
      default: ''
    },
    taggedUsers: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: ''
    },
    customType: {
      type: String,
      default: ''
    },
    read: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isCollaborator: {
      type: Boolean,
      default: false
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    markNotesLoading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    formatDateHumanReadableAlt(date, includeTime = null, timezone) {
      const format = includeTime ? 'MMM Do YYYY @ h:mm a zz' : 'MMMM Do YYYY';
      return momentTz(date)
        .tz(timezone ? timezone : 'America/Toronto')
        .format(format);
    }
  },
  computed: {
    isDeleted() {
      return this.deletedAt ? true : false;
    }
  },
  methods: {
    eventColor(type) {
      return getEventColor('checklist', type);
    },
    emitRemove(noteKey) {
      this.$emit('remove', noteKey);
    },
    emitMark(noteKey) {
      this.$emit('mark', noteKey);
    },
    emitUpdateNoteType() {
      const newType = this.type === 'collaborator' ? 'invoice' : 'collaborator';
      this.$emit('updateNoteType', { noteKey: this.noteKey, type: newType });
    }
  }
};
</script>

<style scoped>
.message-style {
  white-space: pre-wrap;
}
.remove-note-btn {
  position: relative;
  top: 7px;
  padding: 0px;
  margin: 0px;
}
.note-name {
  font-size: 15px;
}
.label-spacing {
  position: relative;
  right: 28px;
}
.message-container {
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.read-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.custom-read-container {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.note-message-deleted {
  text-decoration: line-through;
  color: red;
}
</style>
