<template>
  <v-card>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="panelists"
      item-key="email"
      select-all
      :loading="loading"
      hide-actions
    >
      <template slot="progress">
        <v-progress-linear
          height="2"
          color="blue"
          indeterminate
        ></v-progress-linear>
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <td>
            <v-checkbox v-model="props.selected" primary hide-details />
          </td>
          <td>{{ props.item.name }}</td>
          <td>
            {{ props.item.email }}
          </td>
          <td>
            <v-tooltip bottom v-if="props.item.isMember">
              <span slot="activator">
                <v-chip small color="blue lighten-2">
                  <v-icon small color="white">account_box</v-icon>
                </v-chip>
              </span>
              <span>
                Panelist is also a part of the membership
              </span>
            </v-tooltip>
          </td>
          <td>
            <div v-if="findInviteSentAction(props.item.actions)">
              <v-tooltip top>
                <span slot="activator">
                  <v-icon small color="blue lighten-2">mail</v-icon>
                  {{ findInviteSentAction(props.item.actions) | formatDate }}
                </span>
                <span
                  >Panelist invite sent on
                  {{
                    findInviteSentAction(props.item.actions)
                      | formatDateTime(meetingTimezone)
                  }}</span
                >
              </v-tooltip>
            </div>
          </td>
          <td>
            <div v-if="findRehearsalSentAction(props.item.actions)">
              <v-tooltip top>
                <span slot="activator">
                  <v-icon small color="blue lighten-2">mail</v-icon>
                  {{ findRehearsalSentAction(props.item.actions) | formatDate }}
                </span>
                <span
                  >Rehearsal invite sent on
                  {{
                    findRehearsalSentAction(props.item.actions)
                      | formatDateTime(meetingTimezone)
                  }}</span
                >
              </v-tooltip>
            </div>

            <div v-if="findRehearsalViewedAction(props.item.actions)">
              <v-tooltip bottom>
                <span slot="activator">
                  <v-icon small color="green lighten-2">visibility</v-icon>
                  {{
                    findRehearsalViewedAction(props.item.actions) | formatDate
                  }}
                </span>
                <span
                  >Panelist opened rehearsal invite on
                  {{
                    findRehearsalViewedAction(props.item.actions)
                      | formatDateTime(meetingTimezone)
                  }}</span
                >
              </v-tooltip>
            </div>
          </td>
          <td class="text-xs-right">
            <!-- <v-icon @click="sendRehearsal(props.item)" color="grey">
              mail
            </v-icon> -->
            <v-btn @click="removePanelist(props.item)" icon>
              <v-icon color="grey darken-2">
                delete
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        No panelists yet. If you think this is a mistake, please check the
        Webinar directly in Zoom.
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="canSendInvite"
        color="primary"
        @click.native="dialog.zoomAttachments = true"
        :loading="loading"
        :disabled="selected.length <= 0"
        >Send Panelist Invite</v-btn
      >
      <v-btn
        v-if="canSendInvite"
        color="primary"
        @click.native="dialog.rehearsalAttachments = true"
        :loading="loading"
        :disabled="selected.length <= 0"
        >Send Rehearsal Invite</v-btn
      >
    </v-card-actions>

    <!-- Select Document(s) to Attach Dialog  -->
    <attach-files-dialog
      :value="dialog.zoomAttachments || dialog.rehearsalAttachments"
      :meeting-documents="documents"
      :attachments="attachments"
      attachment-type="email"
      @update="setAttachments"
      @close="resetAttachmentsDialog"
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import checkScope from '@/lib/check-user-scopes';
import AttachFilesDialog from '@/components/dialogs/AttachFilesDialog';

export default {
  name: 'MeetingWebcastViewPanelistsTable',
  components: {
    AttachFilesDialog
  },
  props: {
    panelists: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    documents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    formatDate(action) {
      if (!action || !action.createdAt) {
        return '';
      }
      return moment(action.createdAt).format('MMM DD, YYYY');
    },
    formatDateTime(action, meetingTimezone) {
      if (!action || !action.createdAt) {
        return '';
      }
      return moment(action.createdAt)
        .tz(meetingTimezone ? meetingTimezone : 'America/Toronto')
        .format('MMM DD, YYYY hh:mm a zz');
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    canSendInvite() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.shortCode,
          'meeting.webcast.panelist.invite'
        )
      );
    }
  },
  watch: {},
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: '', value: 'membership', sortable: false, width: '10px' },
        {
          text: 'Panelist Invite',
          value: 'virtualMeetingSentAt',
          sortable: false
        },
        { text: 'Rehearsal Invite', value: 'rehearsalSentAt', sortable: false },
        { text: '', value: '', align: 'right', sortable: false }
      ],
      config: {
        pagination: { sortBy: 'name' }
      },
      dialog: {
        zoomAttachments: false,
        rehearsalAttachments: false
      },
      attachments: []
    };
  },
  methods: {
    // Checks if there's a rehearsal sent action for the panelist
    findRehearsalSentAction(actions) {
      return actions ? actions.find(a => a.action === 'rehearsal-sent') : null;
    },

    // Checks if there's a rehearsal viewed action for the panelist
    findRehearsalViewedAction(actions) {
      return actions
        ? actions.find(a => a.action === 'rehearsal-viewed')
        : null;
    },

    findInviteSentAction(actions) {
      return actions
        ? actions.find(a => a.action === 'panelist-webcast-invite-sent')
        : null;
    },

    removePanelist(panelist) {
      this.$emit('remove', panelist);
    },
    resetAttachmentsDialog() {
      this.dialog.zoomAttachments = false;
      this.dialog.rehearsalAttachments = false;
      this.attachments = [];
    },
    setAttachments(attachments) {
      this.attachments = attachments;
      //Send rehearsal or zoom invite
      if (this.dialog.zoomAttachments) {
        this.$emit('bulk-zoom-invite', this.selected, this.attachments);
      }
      if (this.dialog.rehearsalAttachments) {
        this.$emit('bulk-rehearsal', this.selected, this.attachments);
      }
      this.resetAttachmentsDialog();
    }
  }
};
</script>

<style scoped></style>
