<template>
  <v-menu
    lazy
    :close-on-content-click="true"
    v-model="menu"
    transition="scale-transition"
    offset-y
    full-width
    :nudge-right="40"
    :disabled="isDisabled"
    max-width="290px"
  >
    <v-text-field
      :hide-details="hideDetails"
      slot="activator"
      :label="label ? label : ''"
      :value="dateFormatted"
      :prepend-icon="hideIcon ? '' : 'event'"
      :disabled="isDisabled"
      readonly
      :required="isRequired"
      :rules="isRequired ? rules.required : []"
      placeholder="No Date Selected"
      :hint="hint"
      :persistent-hint="!!hint"
      clearable
      @click:clear="$emit('clear')"
    />
    <v-date-picker v-model="date" scrollable no-title actions>
      <template slot-scope="{ save, cancel }">
        <v-card-actions>
          <v-spacer />
          <v-btn flat color="primary" @click="menu = false">Close</v-btn>
        </v-card-actions>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    isoDate: {
      type: [String, Date],
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default() {
        return 'YYYY-MM-DD';
      }
    }
  },
  data() {
    return {
      date: '',
      menu: false,
      rules: {
        required: [v => !!v || 'Date is required']
      }
    };
  },
  watch: {
    isoDate() {
      this.setDate();
    },
    date() {
      this.emitDate();
    }
  },
  created() {
    this.setDate();
  },
  computed: {
    dateFormatted() {
      return this.date ? moment(this.date).format(this.dateFormat) : '';
    }
  },
  methods: {
    setDate() {
      this.date = this.isoDate ? moment(this.isoDate).format('YYYY-MM-DD') : '';
    },
    emitDate() {
      this.$emit('update', this.date);
    }
  }
};
</script>

<style scoped></style>
