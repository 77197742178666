<template>
  <div>
    <div class="mb-3 mt-4">
      <v-autocomplete
        label="Account"
        placeholder="Start typing to search for an Account"
        :items="options"
        item-text="searchField"
        :search-input.sync="search"
        hide-details
        cache-items
        solo
        dense
        return-object
        :loading="loading"
        @change="handleUpdate({ key: 'selectedCorp', value: $event })"
      ></v-autocomplete>
    </div>

    <!-- Alert for account invoice/subscription information -->
    <meeting-nav-drawer-info
      v-if="selectedCorp && showInvoiceAlert"
      :account-key="selectedCorp.key"
      :subscription="subscription"
      :subscription-balance="subscriptionBalance"
      :invoice-owing="totalInvoiceAmountOwing"
      :hide-refresh="true"
    >
      <template v-slot:header>
        <div class="pb-2">
          <v-icon small color="red" class="pr-1">error</v-icon>This account has
          an outstanding balance
        </div>
      </template>
    </meeting-nav-drawer-info>

    <v-layout row class="mt-4" v-if="newMeeting.selectedCorp">
      <v-flex xs8 md8>
        <date-time-pickers
          label="When is the meeting date?"
          :hide-details="true"
          :iso-date="newMeeting.meetingDate"
          :timezone="newMeeting.meetingTimezone"
          @update="handleUpdate({ key: 'meetingDate', value: $event })"
        />
      </v-flex>
      <v-flex xs4 md4 class="pl-3 mt-1">
        <v-select
          :items="timezones"
          item-text="text"
          item-value="value"
          :value="newMeeting.meetingTimezone"
          label="Timezones"
          @change="handleUpdate({ key: 'meetingTimezone', value: $event })"
        />
      </v-flex>
    </v-layout>

    <create-partner />

    <!-- Campaign Type/Category -->
    <div v-if="newMeeting.selectedCorp">
      <p class="title mb-3 mt-5">Select Campaign Category</p>
      <p class="secondary--text">
        <v-icon small>info</v-icon>
        <i>
          Only Meeting/Voting campaigns will be visible in a user's dashboard.
        </i>
      </p>
      <v-radio-group
        name="meeting-type"
        :mandatory="true"
        @change="handleUpdate({ key: 'type', value: $event })"
      >
        <v-radio
          v-for="meeting in meetingTypes"
          :value="meeting.value"
          :key="meeting.value"
        >
          <v-tooltip slot="label" right>
            <span slot="activator">
              {{ meeting.label }}
              <v-icon small>{{ meeting.icon }}</v-icon>
            </span>
            <span>{{ meeting.iconText }}</span>
          </v-tooltip>
        </v-radio>
      </v-radio-group>
    </div>

    <!-- campaign type service -->
    <v-radio-group
      v-if="showCampaignServices"
      name="meeting-typeService"
      :value="newMeeting.typeService"
      @change="handleUpdate({ key: 'typeService', value: $event })"
    >
      <p class="secondary--text subheading">
        Please select a campaign type:
      </p>

      <v-radio
        v-for="service in campaignTypeServices"
        :label="service.label"
        :value="service.value"
        :key="service.label"
      />
    </v-radio-group>

    <!-- If we're creating an AGM, specify sub-category (i.e., Virtual or In-Person) -->
    <v-radio-group
      v-if="newMeeting.type === 'meeting' && newMeeting.typeService"
      name="meeting-subtype"
      :value="newMeeting.subtype"
      @change="handleUpdate({ key: 'subtype', value: $event })"
    >
      <p class="secondary--text subheading">
        Please select a sub-category:
      </p>

      <v-radio
        v-for="subtype in meetingSubtypes"
        :label="`${subtype} Meeting`"
        :value="subtype"
        :key="subtype"
      />
    </v-radio-group>
    <v-radio-group
      v-if="['Virtual', 'Hybrid'].includes(newMeeting.subtype)"
      name="meeting-webcast-type"
      :value="newMeeting.virtualMeetingType"
      @change="handleUpdate({ key: 'virtualMeetingType', value: $event })"
    >
      <p class="secondary--text subheading">
        Please select the webcast type:
      </p>
      <v-radio
        v-for="virtualMeetingType in meetingvirtualMeetingTypes"
        :label="virtualMeetingType.label"
        :value="virtualMeetingType.value"
        :key="virtualMeetingType.value"
      />
    </v-radio-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import _ from 'lodash';
import meetingTypeDefault from '@/lib/default-checklist.js';
import Multiselect from 'vue-multiselect';
import DateTimePickers from '@/components/DateTimePicker';
import CreatePartner from '@/components/CreatePartner';
import MeetingNavDrawerInfo from '@/components/MeetingNavDrawerInfo';
import timezones from '@/lib/timezones';

export default {
  name: 'MeetingCreateCampaign',
  props: {
    newMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    accountListData: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    Multiselect,
    DateTimePickers,
    CreatePartner,
    MeetingNavDrawerInfo
  },
  watch: {
    search() {
      if (!this.search || this.search.trim() === '') {
        this.newMeeting.selectedCorp = null;
      }
      this.$emit('search', this.search);
    },
    selectedCorp(corp) {
      // When a corporation is selected, fetch outstanding invoices + account subscription data
      this.getOutstandingInvoicesForAccount({ accountKey: corp.key });

      this.getAccountSubscription({
        key: corp.key,
        clearCache: true
      });

      // update meeting time zone based on the corporation's timezone
      const timezone = _.get(
        this.newMeeting,
        'selectedCorp.contact.timezone',
        'America/Toronto'
      );
      this.handleUpdate({ key: 'meetingTimezone', value: timezone });
    }
  },
  computed: {
    ...mapGetters('invoices', ['outstandingAccountInvoices']),
    ...mapGetters('accounts', ['subscription']),
    options() {
      return this.accountListData.map(acct => {
        return {
          ...acct,
          searchField: `${acct.nameShort} - ${acct.address.address}`
        };
      });
    },
    campaignTypeServices() {
      return this.campaignServices[this.newMeeting.type];
    },
    showCampaignServices() {
      return ['meeting', 'other', 'certs'].includes(this.newMeeting.type);
    },
    selectedCorp() {
      return this.newMeeting?.selectedCorp;
    },
    showInvoiceAlert() {
      return this.totalInvoiceAmountOwing + this.subscriptionBalance > 0;
    },
    totalInvoiceAmountOwing() {
      let owing = 0;
      if (
        this.outstandingAccountInvoices &&
        this.outstandingAccountInvoices.length > 0
      ) {
        this.outstandingAccountInvoices.forEach(i => {
          owing += +i.amountOwing;
        });
      }
      return owing;
    },
    subscriptionBalance() {
      if (
        this.subscription &&
        this.subscription.invoiceStatus === 'payment_due' &&
        this.subscription.invoiceOwing
      ) {
        return this.subscription.invoiceOwing;
      }
      return 0;
    }
  },
  data() {
    return {
      search: '',
      meetingTypes: meetingTypeDefault,
      meetingSubtypes: ['In-Person', 'Virtual', 'Hybrid'],
      timezones,
      meetingvirtualMeetingTypes: [
        { value: 'GQ-Hosted', label: 'Zoom Webinar' },
        {
          value: 'GQ-Hosted-Meeting',
          label: 'Zoom Meeting'
        },
        {
          value: 'Self-Hosted',
          label: "Use Client's own Virtual Meeting Instructions"
        }
      ],
      campaignServices: {
        meeting: [
          { label: 'AGM', value: 'meeting_agm' },
          { label: 'SGM', value: 'meeting_sgm' },
          { label: 'Turnover Meeting', value: 'meeting_turnover' },
          { label: 'Requisition Meeting', value: 'meeting_requisition' },
          { label: 'Reconvene Meeting', value: 'meeting_reconvene' }
        ],
        certs: [
          { label: 'ICU', value: 'certs_icu' },
          { label: 'PIC', value: 'certs_pic' }
        ],
        other: [
          { label: 'Consent Campaign', value: 'custom_consent' },
          { label: 'Survey', value: 'custom_survey' },
          { label: 'Custom', value: 'custom' }
        ]
      }
    };
  },
  methods: {
    ...mapActions('accounts', ['getAccountSubscription']),
    ...mapActions('invoices', ['getOutstandingInvoicesForAccount']),
    handleUpdate({ key, value }) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style scoped></style>
