<template>
  <v-dialog max-width="450px" :value="value">
    <v-card>
      <v-card-title>
        <span class="title">Reset attendance?</span>
      </v-card-title>
      <v-card-text>
        You are about to reset each Voter's IN ATTENDANCE for
        <b>{{ selected.length }} unit(s)</b>. Click RESET to continue.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="error" @click.native="emitReset">RESET</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsResetAttendanceDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitReset() {
      this.$emit('reset');
    }
  }
};
</script>

<style scoped></style>
