// import Vue from 'vue';
import * as saApi from '@/api/service-agreements';
import * as filesApi from '@/api/files';

const state = {
  agreementPreviewHtml: '',
  dataLastFetchedAt: null
};

const getters = {
  agreementPreviewHtml: state => state.agreementPreviewHtml
};

const actions = {
  /**
   * Returns the HTML preview of a service agreement
   * @param  {String} type        type of agreement
   * @param  {String} accountKey  account to create agreement for
   * @return {String}             html string representing the agreement
   */
  async getHtmlPreview({ commit }, { type, accountKey }) {
    try {
      const res = await saApi.previewServiceAgreement({ type, accountKey });
      const html = res.data;
      return commit('SET_AGREEMENT_PREVIEW_HTML', html);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Create a new service agreement with blank PDF template.
   * 1. Create the PDF agreement
   * 2. Create the agreement record
   * 3. Add the PDF file to the agreement
   * 4. Get the latest agreement
   * @param  {String} type        type of agreement
   * @param  {String} accountKey  account to create agreement for
   */
  async createBlankAgreement({ dispatch }, { type, accountKey }) {
    try {
      // Step 1: create the PDF agreement
      const { data: pdf } = await saApi.createAgreementPdf({
        type,
        accountKey
      });
      const { name, Bucket, Key } = pdf;

      // Step 2: create the file record
      const { data: agreement } = await saApi.postServiceAgreement({
        type,
        accountKey
      });

      // Build file object for next step...
      const file = {
        name,
        description: 'Blank (unsigned) PDF service agreement',
        package: { Bucket, Key, name },
        categoryType: 'agreement',
        categoryTypeKey: agreement.key
      };

      // Step 3: Add the PDF to the agreement record
      await filesApi.postFile(file);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Import a signed agreement by first creating the agreement record
   * then uploading the required files
   * @param  {String}   type          type of agreement
   * @param  {String}   accountKey    account to create agreement for
   * @param  {String}   [signedAt]    date the agreement was signed
   * @param  {String}   [expiresAt]   date the agreement expires
   * @param  {String}   [description] additional notes for the agreement
   * @param  {FileList} files         file list to upload
   */
  async importServiceAgreement(
    { dispatch },
    { type, accountKey, signedAt, expiresAt, description, files = [] } = {}
  ) {
    try {
      const fileList = Array.from(files);

      if (fileList.length === 0) {
        throw new Error('Files cannot be empty');
      }

      // Step 1 - Create the agreement record
      const res = await saApi.postServiceAgreement({
        type,
        accountKey,
        signedAt,
        expiresAt,
        description
      });

      const filesPayload = {
        files,
        categoryType: 'agreement',
        categoryTypeKey: res.data.key
      };

      // Step 2 - Upload the files into the new service agreement
      await dispatch('files/uploadFiles', filesPayload, {
        root: true
      });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Delete a service agreement
   * @param  {String} agreementKey  the agreement id
   */
  async deleteServiceAgreement({ dispatch }, agreementKey) {
    try {
      await saApi.deleteServiceAgreement(agreementKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update a service agreement
   * @param  {String} key         the agreement id
   * @param  {Date}   signedAt    the date the agreement was signed
   * @param  {Date}   expiresAt   the date agreement expires
   * @param  {String} description additional description of agreement
   */
  async updateServiceAgreement(
    { dispatch },
    { key, signedAt, expiresAt, description }
  ) {
    try {
      const payload = { signedAt, expiresAt, description };
      const res = await saApi.patchServiceAgreement(key, payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_AGREEMENT_PREVIEW_HTML(state, html) {
    state.agreementPreviewHtml = html;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
