<template>
  <!-- Navigation Drawer -->
  <v-navigation-drawer
    width="260px"
    fixed
    :value="value"
    @input="emitInput($event)"
    app
  >
    <v-list two-line>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <span v-if="localProfile.picture">
            <img :src="localProfile.picture" />
          </span>
          <span v-else>
            <v-icon class="grey lighten-1 white--text">person</v-icon>
          </span>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ localProfile.email }}</v-list-tile-title>
          <v-list-tile-sub-title>{{
            localProfile.nickname
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-divider />

    <v-list>
      <v-list-tile
        v-for="(item, index) in sidenavList"
        :key="`key-${index}`"
        :to="`/admin/${item.path}`"
        exact
      >
        <v-list-tile-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.label | startCase }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';

export default {
  name: 'AdminNavDrawer',
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: null
    },
    routes: {
      type: Array,
      required: true
    },
    localProfile: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      sidenavList: this.routes
    };
  },
  methods: {
    emitInput(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style scoped>
.list__tile__title {
  color: #000;
}
</style>
