<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">Meeting Documents</div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Displayed on front page of proxy for download
    </div>
    <file-upload-form
      :attachment="documents"
      :multiple-files="true"
      @upload="emitUpload"
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import FileUploadForm from '@/components/FileUploadForm';

export default {
  name: 'MeetingSettingsDocumentsInput',
  components: {
    FileUploadForm
  },
  props: {
    shortCode: {
      type: String,
      required: true
    },
    attachment: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    documents() {
      if (!this.attachment) {
        return [];
      } else {
        return this.attachment.map(file => file.document);
      }
    }
  },
  methods: {
    ...mapActions('meetings', ['addMeetingDocument', 'removeMeetingDocument']),
    emitUpload(res) {
      try {
        if (!this.attachment || res.length > this.attachment.length) {
          // Case 1: Adding a new meeting doc
          const documentToAdd = res[res.length - 1];
          this.addMeetingDocument({
            shortCode: this.shortCode,
            document: documentToAdd
          });
          this.$events.$emit('toastEvent', 'Document Added');
        } else {
          // Case 2: Removing an existing meeting doc
          const newUrls = res.map(r => r.url);
          const existingUrls = this.documents.map(f => f.url);

          // Figure out which file is being deleted,
          // because we need to access the document key
          const documentToDelete = this.attachment.filter(
            att => !newUrls.includes(att.document.url)
          );
          const keyToDelete = documentToDelete[0].key;

          this.removeMeetingDocument({
            shortCode: this.shortCode,
            documentKey: keyToDelete
          });

          this.$events.$emit('toastEvent', 'Document Removed');
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
