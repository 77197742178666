<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Products</p>
      </v-flex>
    </v-layout>

    <v-layout row class="ml-3 mr-3">
      <v-flex xs4>
        <v-autocomplete
          label="Product Type Filter"
          light
          hide-details
          :items="productTypesFilter"
          item-text="label"
          item-value="value"
          :value="pagination.type"
          @change="updateType"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ma-3">
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search by product name, description or code"
            single-line
            hide-details
            clearable
            :value="search"
            @input="updateSearch"
          />
          <v-spacer />

          <v-btn color="primary" @click.native="dialog.addProduct = true">
            <v-icon left dark>add</v-icon>Product
          </v-btn>
        </v-toolbar>
        <v-data-table
          :items="productsData"
          :headers="headers"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :rows-per-page-items="pagination.rowsPerPageItems"
          :loading="loading"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="nowrap name-cell">
                <span slot="activator">
                  <router-link
                    :to="{
                      name: 'adminProductsEdit',
                      params: { productKey: props.item.key }
                    }"
                  >
                    {{ props.item.name }}
                  </router-link>
                </span>
              </td>
              <td class="nowrap">
                {{ props.item.productCode }}
              </td>
              <td class="capitalize nowrap">{{ props.item.category }}</td>
              <td>{{ props.item.valueCad | formatCurrency }}</td>
              <td>{{ props.item.valueUsd | formatCurrency }}</td>
              <td>
                <v-chip
                  v-if="props.item.productType === 0"
                  small
                  color="amber"
                  class="white--text"
                >
                  transactional
                </v-chip>
                <v-chip
                  v-else-if="props.item.productType === 1"
                  small
                  color="teal"
                  class="white--text"
                >
                  subscription
                </v-chip>
                <v-chip
                  v-else-if="props.item.productType === 2"
                  small
                  color="black"
                  class="white--text"
                >
                  discount
                </v-chip>
                <v-chip v-else small color="grey" class="white--text">
                  n/a
                </v-chip>
              </td>
              <td>{{ props.item.minUnits }}</td>
              <td>{{ props.item.maxUnits }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog.addProduct" max-width="600px" persistent>
      <add-product-dialog @closeDialog="dialog.addProduct = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatReadable, formatCurrency } from '@/filters';

// Components
import AddProductDialog from './AddProductDialog.vue';
export default {
  name: 'AdminProductsListView',
  filters: {
    dateFormatReadable,
    formatCurrency
  },
  components: {
    AddProductDialog
  },
  data() {
    return {
      search: '',
      loading: false,
      type: null,
      headers: [
        { text: 'Product Name', value: 'name' },
        { text: 'Product Code', value: 'productCode' },
        { text: 'Category', value: 'category' },
        { text: 'Price (CAD)', value: 'valueCad' },
        { text: 'Price (USD)', value: 'valueUsd' },
        { text: 'Product Type', value: 'productType' },
        { text: 'Min Units', value: 'minUnits' },
        { text: 'Max Units', value: 'maxUnits' }
      ],
      dialog: {
        addProduct: false
      }
    };
  },
  computed: {
    ...mapGetters('products', ['productsData', 'pagination', 'productTypes']),
    productTypesFilter() {
      return [{ value: null, label: 'All' }, ...this.productTypes];
    },
    pagination: {
      get: function() {
        return this.$store.getters['products/pagination'];
      },
      set: async function(value) {
        this.loading = true;
        this.setPagination(value);
        this.search = value.search;
        await this.getProducts({ search: value.search, type: value.type });
        this.loading = false;
      }
    }
  },

  methods: {
    ...mapActions('products', ['setPagination', 'getProducts']),
    updateSearch: _.debounce(async function(value) {
      this.loading = true;
      const newPagination = _.cloneDeep(this.pagination);
      this.setPagination({ ...newPagination, search: value });
      await this.getProducts({ search: value, type: newPagination.type });
      this.loading = false;
    }, 350),
    async updateType(type) {
      this.loading = true;
      const newPagination = _.cloneDeep(this.pagination);
      this.setPagination({ ...newPagination, type });
      await this.getProducts({ search: newPagination.search, type });
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
