'use strict';

/** @fileoverview Exports Vote Summary Data to CSV */

import _ from 'lodash';
import { downloadCSV, convertArrayOfObjectsToCSV } from '@/helpers';

const addDeferredVoteInfo = (CSVData, proxies, deferred) => {
  let deferredCSVData = convertArrayOfObjectsToCSV(proxies);
  CSVData +=
    '\n\n' +
    'Note: For the ' +
    deferred +
    ' votes given to the proxy`s authority to vote; please see the following breakdown by proxy holder:' +
    '\n\n';
  CSVData += deferredCSVData;
  return CSVData;
};

const formatTallyForCSV = (tally, version) => {
  let deferredProxies = [];
  let deferCount = 0;

  // Slight difference for column headers for By-Laws versus Candidate Elections
  Object.keys(tally).forEach(function(el) {
    let obj = tally[el];

    obj.votes = obj.num_votes || 0;

    if (version === 2) {
      obj.numUnits = obj.numUnits || 0;
      obj.numUnitsAdv = obj.numUnitsAdv || 0;
      obj.numVotes = obj.numVotes || 0;
      obj.numVotesAdv = obj.numVotesAdv || 0;
      obj.numVotesPaper = obj.numVotesPaper || 0;
      obj.option = obj.option || '';
    }

    // All deferred votes text should be replaced for clarity
    obj.option =
      obj.option === 'defer'
        ? 'I give my proxy authority to vote on this matter'
        : obj.option;

    // For votes that defer to the proxy, list each proxy holder with total votes
    if (obj.option === 'I give my proxy authority to vote on this matter') {
      deferCount = obj.votes;
      _.forEach(obj.holders, function(holder) {
        deferredProxies.push({
          name: holder.name,
          total: holder.num_votes_defer + ' proxies'
        });
      });
    }

    delete obj.voteItems;
    delete obj.id;
    delete obj.num_votes;
    delete obj.holders;
  });

  return { formattedTally: tally, deferredProxies, deferCount };
};

const exportTallyToCSV = ({ name, tally, version = 1 }) => {
  // Clone so we don't mutate data
  let tallyDataClone = _.cloneDeep(tally);
  let { formattedTally, deferredProxies, deferCount } = formatTallyForCSV(
    tallyDataClone,
    version
  );

  let CSVData = convertArrayOfObjectsToCSV(formattedTally);

  // Add deferred votes if any
  if (deferCount > 0) {
    CSVData = addDeferredVoteInfo(CSVData, deferredProxies, deferCount);
  }
  const currentTitle = name.length > 100 ? name.substring(0, 100).trim() : name;
  downloadCSV(`${currentTitle} - summary export.csv`, CSVData);
};

export default exportTallyToCSV;
