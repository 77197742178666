<template>
  <v-container grid-list-xl>
    <!-- Switch and Notes -->
    <v-layout class="mx-2 mb-5" align-center>
      <v-switch
        :label="billingEnabledText"
        :disabled="hasInvoices"
        v-model="isBillingEnabled"
        hint="Cannot disable if there are invoices"
        :persistent-hint="hasInvoices"
        @change="handleToggle"
      />
      <v-spacer />
      <v-chip
        v-if="billingSettings.chargebeeBilling"
        outline
        label
        color="primary"
        >Billing via: Chargebee
      </v-chip>
      <v-chip v-else outline label color="black"
        >Billing via: GetQuorum
      </v-chip>
      <v-chip
        color="red"
        class="white--text"
        v-if="invoiceNotes && invoiceNotes.length > 0"
        @click="setNotesDrawer(!notesDrawerState)"
        ><v-icon class="mr-1">assignment</v-icon
        ><strong
          >{{ invoiceNotes.length }} invoice related note(s)</strong
        ></v-chip
      >
    </v-layout>

    <meeting-billing-summary class="mb-5" />

    <meeting-billing-services class="mb-5" :current-meeting="currentMeeting" />

    <!-- If Billing is disabled -->
    <v-layout v-if="!isBillingEnabled" row>
      <v-flex xs12>
        <div class="mdl-cell mdl-cell--12-col center-align">
          <h2><b>Billing has been disabled for this campaign</b></h2>
          <p>
            This meeting will be listed under "Ignored" campaigns in the Admin
            Campaigns page
          </p>
        </div>
      </v-flex>
    </v-layout>

    <div v-else>
      <!-- If no invoices -->
      <v-container
        v-if="!loading && !hasInvoices"
        row
        class="mdl-cell mdl-cell--12-col center-align"
      >
        <p>What the...there are no invoices for this meeting?!</p>
        <p>Better create one so we can get paid.</p>

        <v-btn color="primary" @click="handleAdd">
          <v-icon left>add_box</v-icon> Create Invoice
        </v-btn>
      </v-container>

      <!-- There are invoices, show table -->
      <v-layout v-else row>
        <v-flex xs12>
          <meeting-invoices-table
            :loading="loading"
            @add-invoice="handleAdd"
            @refresh="fetchData"
          />
        </v-flex>
      </v-layout>
    </div>

    <create-invoice-dialog
      :is-open="dialog.create"
      :current-meeting="currentMeeting"
      :billing-items="billingSettings.items"
      :print-jobs="printJobs"
      @submit="addInvoice"
      @close="dialog.create = false"
    />

    <disable-billing-dialog
      :is-open="dialog.disable"
      @submit="toggleBillingEnabled"
      @close="resetToggle"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

import MeetingInvoicesTable from './MeetingInvoicesTable.vue';
import MeetingBillingSummary from './MeetingBillingSummary.vue';
import MeetingBillingServices from './MeetingBillingServices/MeetingBillingServices.vue';
import CreateInvoiceDialog from './MeetingBillingCreateInvoiceDialog.vue';
import DisableBillingDialog from './MeetingBillingDisableBillingDialog.vue';

export default {
  name: 'MeetingBillingView',
  components: {
    MeetingInvoicesTable,
    MeetingBillingSummary,
    MeetingBillingServices,
    CreateInvoiceDialog,
    DisableBillingDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  filters: {
    dateFormatReadable,
    formatCurrencyValue
  },
  watch: {
    billingSettings() {
      this.isBillingEnabled = this.billingSettings.enabled;
    }
  },
  data() {
    return {
      dataLoaded: false,
      loading: false,
      isBillingEnabled: false,
      shortCode: this.$route.params.shortcode,
      dialog: { create: false, disable: false }
    };
  },
  computed: {
    ...mapGetters('meetings/billing', [
      'meetingInvoiceData',
      'billingSettings'
    ]),
    ...mapGetters('meetings/notes', [
      'invoiceNotesByShortCode',
      'notesDrawerState'
    ]),
    hasInvoices() {
      // check if all invoices have been voided
      const allVoided = _.every(this.meetingInvoiceData, invoice => {
        return invoice.status === 'voided';
      });

      return (
        this.dataLoaded && this.meetingInvoiceData.length > 0 && !allVoided
      );
    },
    billingEnabledText() {
      return this.isBillingEnabled ? 'Billing Enabled' : 'Billing Disabled';
    },
    invoiceNotes() {
      return this.invoiceNotesByShortCode(this.shortCode);
    },
    printJobs() {
      return this.billingSettings.printJobs?.jobs;
    }
  },
  async created() {
    this.loading = true;
    this.dataLoaded = false;
    await this.fetchData();
  },
  methods: {
    ...mapActions('meetings/billing', [
      'getMeetingInvoiceList',
      'stubNewMeetingInvoice',
      'createChargebeeMeetingInvoice',
      'setBillingEnabled',
      'getBillingSettings'
    ]),
    ...mapActions('meetings/notes', ['toggleNoteDrawer', 'createMeetingNote']),
    ...mapActions('products', ['getProducts', 'getChargebeeProducts']),
    async fetchData() {
      try {
        this.loading = true;
        await this.getBillingSettings({ shortCode: this.shortCode });
        await this.getMeetingInvoiceList({ shortCode: this.shortCode });

        // Get list products, either from our Chargebee or our DB
        if (this.billingSettings.chargebeeBilling) {
          await this.getChargebeeProducts({
            currency: this.billingSettings.billingCurrency
          });
        } else {
          await this.getProducts({ type: 0 }); // type = 0 only transactional products
        }
      } catch (err) {
        this.$events.$emit('toastEvent', err.response);
      } finally {
        this.loading = false;
        this.dataLoaded = true;
      }
    },
    handleAdd() {
      if (this.billingSettings.chargebeeBilling) {
        // If we are using Chargebee for billing,
        // open dialog so user can select invoice date
        this.dialog.create = true;
      } else {
        // If we are using GQ for billing, we can skip
        // right to creating the invoice
        this.addInvoice();
      }
    },
    async addInvoice(invoiceDate = null, items = [], printJobs = []) {
      try {
        this.loading = true;

        if (this.billingSettings.chargebeeBilling) {
          await this.createChargebeeMeetingInvoice({
            shortCode: this.shortCode,
            invoiceDate,
            items,
            printJobs
          });
        } else {
          await this.stubNewMeetingInvoice({ shortCode: this.shortCode });
        }

        this.$events.$emit('toastEvent', 'New invoice added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    handleToggle() {
      if (this.isBillingEnabled) {
        // If billing is being enabled, simply enable it
        this.toggleBillingEnabled(null);
      } else {
        // If billing is being disabled,
        // display pop-up for user to enter a reason
        this.dialog.disable = true;
      }
    },
    resetToggle() {
      this.isBillingEnabled = this.billingSettings.enabled;
      this.dialog.disable = false;
    },
    async toggleBillingEnabled(reason) {
      try {
        await this.setBillingEnabled({
          shortCode: this.shortCode,
          enabled: this.isBillingEnabled
        });

        if (reason) {
          const payload = {
            message: 'Billing Disabled:\n' + reason,
            type: 'invoice'
          };
          await this.createMeetingNote({ shortCode: this.shortCode, payload });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.disable = false;
      }
    },
    setNotesDrawer(state) {
      this.toggleNoteDrawer(state);
    }
  }
};
</script>

<style lang="scss" scoped>
.center-align {
  text-align: center;
}
</style>
