<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="subheading font-weight-medium">
          Only allow units with the following custom field/value(s) to vote on
          this question:
        </div>
        <div class="grey--text font-weight-medium mb-3">
          If multiple restrictions exist, voters only need one matching
          field/value to be able to vote.
        </div>

        <v-layout v-if="!hasRestrictions" row>
          <v-flex>
            <div class="font-weight-medium">
              No data available - click "Add Restriction" to get started.
            </div>
          </v-flex>
        </v-layout>
        <v-layout
          v-for="(r, index) in restrictions"
          :key="index"
          align-center
          justify-space-around
          row
        >
          <v-flex xs5>
            <span class="mr-2">Custom Field:</span>
            <v-chip disabled outline label class="restriction-chip">
              {{ r.key }}
            </v-chip>
          </v-flex>
          <v-flex xs5>
            <span class="mr-2">Field Value:</span>
            <v-chip disabled outline label class="restriction-chip">
              {{ r.value }}
            </v-chip>
          </v-flex>
          <v-flex xs1>
            <v-tooltip bottom>
              <span slot="activator">
                <v-icon
                  class="mr-1"
                  color="primary"
                  @click="openRestrictionDialog(r)"
                  >edit</v-icon
                >
              </span>
              <span>Edit Restriction</span>
            </v-tooltip>
            <v-tooltip bottom>
              <span slot="activator">
                <v-icon class="ml-1" color="red" @click="removeRestriction(r)"
                  >remove_circle</v-icon
                >
              </span>
              <span>Clear Restriction</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <!-- Clear all restrictions button -->
        <v-btn
          color="error"
          flat
          :disabled="!hasRestrictions"
          @click="removeRestrictions"
          ><v-icon class="mr-1">remove_circle</v-icon>clear all
          restrictions</v-btn
        >
        <v-spacer />
        <v-btn color="primary" flat @click="addRestriction"
          >Add Restriction</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Edit Restriction Dialog -->
    <edit-restriction-dialog
      v-model="editRestriction"
      :restriction="selectedRestriction"
      :custom-fields="customDataKeys"
      @update="updateRestriction"
      @close="editRestriction = false"
    />
  </div>
</template>

<script>
import EditRestrictionDialog from '@/components/dialogs/EditRestrictionDialog';

export default {
  name: 'QuestionRestrictionsCard',
  components: {
    EditRestrictionDialog
  },
  props: {
    restrictions: {
      type: Array,
      default() {
        return [];
      }
    },
    customDataKeys: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      editRestriction: false,
      selectedRestriction: {}
    };
  },
  computed: {
    hasRestrictions() {
      return this.restrictions.length > 0;
    }
  },
  methods: {
    openRestrictionDialog(restriction) {
      this.selectedRestriction = restriction;
      this.editRestriction = true;
    },
    addRestriction() {
      if (this.customDataKeys.length > 0) {
        this.openRestrictionDialog(this.restrictions[this.restrictions.length]);
      }
    },
    removeRestriction(restriction) {
      this.$emit('remove', restriction);
    },
    removeRestrictions() {
      this.$emit('removeAll');
    },
    updateRestriction(existingRestriction, newRestriction) {
      this.$emit('update', existingRestriction, newRestriction);
    }
  }
};
</script>

<style lang="scss" scoped>
.restriction-chip {
  width: 75%;
}
</style>
