'use strict';

/**
 * @fileoverview Vuex module for meeting nominations
 */

import _ from 'lodash';
import forceDownloadUrl from '@/lib/force-download-url';
import { getMeetingNominationsList } from '@/api/meetings-nominations';
import { postExportNominations } from '@/api/meetings-exports';

const state = {
  nominationsList: {},
  dataLastFetchedAt: null
};

const getters = {
  nominationsList: state => state.nominationsList,
  nominationsListData: state => Object.values(state.nominationsList)
};

const actions = {
  /**
   * Get all nominations for this meeting
   * @param  {String} shortCode - the meeting's shortCode
   */
  async getMeetingNominations({ commit }, shortCode) {
    try {
      const res = await getMeetingNominationsList(shortCode);
      commit('SET_MEETING_NOMINATION_LIST_DATA', res.data);
    } catch (err) {
      console.error('Error: getMeetingNominations action', err);
      throw err;
    }
  },

  /**
   * Export all the nomination files (reusmes, disclosures) to disk
   * @param  {String} shortCode - the meeting's shortCode
   */
  async exportMeetingNominations({ dispatch }, shortCode) {
    try {
      const { data } = await postExportNominations(shortCode);
      const { url } = data;
      const name = `${shortCode}_prelim_nominations.zip`;
      await forceDownloadUrl({ url, name });
    } catch (err) {
      console.error('Error: exportMeetingNominations', err);
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_NOMINATION_LIST_DATA(state, data) {
    state.nominationsList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  CLEAR_STATE(state) {
    state.nominationsList = {};
    state.dataLastFetchedAt = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
