/**
 * Downloads a PDF file to disk and opens it in a new browser tab.
 *
 * @async
 * @param {string} url - The URL to fetch the PDF from.
 * @param {string} name - The file name
 *
 * @returns {void}
 */
export default async function downloadAndOpenPDF({ url, name }) {
  try {
    // Fetch the PDF file
    const response = await fetch(url);

    // Convert the response to a blob
    const blob = await response.blob();

    // Save the PDF file locally
    const fileURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = name;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Open the PDF in a new browser tab
    window.open(fileURL, '_blank');
  } catch (err) {
    console.error('Error: downloadAndOpenPDF', err);
    throw err;
  }
}
