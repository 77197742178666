<template>
  <v-container class="pb-0">
    <!-- Check restrictions before displaying actual question -->
    <div v-if="!question.eligible">
      <p class="subheading font-weight-bold">
        <span> {{ index + 1 }}. {{ question.header }}</span>
      </p>
      <v-alert
        :value="true"
        class="ma-0 py-2"
        outline
        type="error"
        color="red accent-2"
        icon="error"
      >
        <!-- Conditional check for question restrictions
        Not expected to happen, because an ineligible question must have restrictions
        But we can prevent errors from occuring in 'getRestriction' should it not exist
        -->
        <div v-if="questionRestrictions.length > 0">
          Question is restricted to voters with one of the following criteria:
          <ul>
            <li
              v-for="(restriction, index) in questionRestrictions"
              :key="index"
            >
              {{ getRestriction(restriction) }}
            </li>
          </ul>
        </div>
        <div v-else>
          <!-- Default text if ineligible -->
          This question is restricted for this voter
        </div>
      </v-alert>
    </div>
    <div v-else>
      <p class="subheading font-weight-bold">
        <span> {{ index + 1 }}. {{ question.header }}</span>
        <span class="red--text font-weight-bold body-1 ml-2"
          >{{ validationErrorMsg }}
        </span>
      </p>

      <v-radio-group v-model="userOption" row hide-details>
        <v-radio label="Select Candidates" value="select" />

        <!-- Owner occupied -->
        <v-radio
          label="Not owner occupied"
          value="not_owner_occupied"
          v-if="question.type === 'owner-occupied'"
        />

        <!-- Director removals -->
        <v-radio
          v-if="question.type === 'director-removals'"
          label="Abstain/Against removal of directors"
          value="abstain"
        />
        <v-radio v-else label="Abstain" value="abstain" />

        <v-radio label="Defer" value="defer" />
      </v-radio-group>

      <v-slide-y-transition>
        <div v-if="userOption === 'select'">
          <p class="grey--text font-italic pt-3 mb-0">
            Please rank choices in order of preference
          </p>
          <div v-for="(option, index) in question.options" :key="index">
            <v-checkbox
              v-model="selectedAnswers"
              @change="emitSelectedAnswers(selectedAnswers)"
              hide-details
              class="mt-2"
              :label="option"
              :value="option"
            >
              <template v-slot:append v-if="isElection">
                <v-chip
                  small
                  class="checkbox-v-chip blue white--text lighten-2"
                  v-if="selectedAnswers.indexOf(option) > -1 && showRanking"
                  disabled
                  >{{ indexChoiceLabel(option) }}
                </v-chip>
              </template>
            </v-checkbox>
          </div>
          <v-layout row class="mt-4" v-if="isElection">
            <v-flex xs12 md7>
              <v-text-field
                outline
                label="Don't see your nominee? Add them here."
                v-model="writeIn"
              >
                <template v-slot:append-outer>
                  <v-btn
                    large
                    bottom
                    class="primary ml-0"
                    :disabled="isInvalidName"
                    @click="emitWriteIn(writeIn)"
                    >add</v-btn
                  >
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>
        </div>
      </v-slide-y-transition>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingProxiesQuestion',
  props: {
    index: {
      type: Number,
      default() {
        return 0;
      }
    },
    question: {
      type: Object,
      default() {
        return {};
      }
    },
    validationErrorMsg: {
      type: String,
      default() {
        return '';
      }
    },
    showRanking: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAnswers: this.question.vote,
      writeIn: '',
      userOption: 'select'
    };
  },
  watch: {
    userOption: function(option) {
      // No matter what the user option, clear when changed
      this.selectedAnswers.splice(0, this.selectedAnswers.length);
      if (option === 'abstain') {
        this.selectedAnswers.push(
          'I abstain from voting and my proxy shall not vote on this matter'
        );
      } else if (option === 'defer') {
        this.selectedAnswers.push(
          'I give my proxy authority to vote on this matter'
        );
      } else if (
        option === 'not_owner_occupied' &&
        this.question.type === 'owner-occupied'
      ) {
        this.selectedAnswers.push('Not Owner Occupied');
      }
      this.emitSelectedAnswers(this.selectedAnswers);
    }
  },
  computed: {
    isElection() {
      return (
        this.question.type === 'general-elections' ||
        this.question.type === 'owner-occupied' ||
        this.question.type === 'director-removals' ||
        this.question.type === 'substitute-elections' ||
        this.question.type === 'substitute-elections-oo'
      );
    },
    isInvalidName() {
      if (!this.question.options) {
        return false;
      }
      return !this.isValidCandidate(this.writeIn);
    },
    questionRestrictions() {
      return this.question.restrictions ? this.question.restrictions : [];
    }
  },
  methods: {
    emitSelectedAnswers(answers) {
      let answerObj = {
        type: this.question.type,
        questionKey: this.question.questionKey,
        answers
      };
      this.$emit('selectedAnswers', answerObj);
    },
    emitWriteIn(writeIn) {
      // Create writeInObj to emit to parent component
      let writeInObj = {
        type: this.question.type,
        questionKey: this.question.questionKey,
        writeIn: _.startCase(this.sanitizeCandidate(writeIn))
      };
      // Clear the write-in field
      this.writeIn = '';
      this.$emit('selectedWriteIn', writeInObj);
    },
    indexChoiceLabel(option) {
      let indexChoice = this.selectedAnswers.indexOf(option);
      if (indexChoice > -1) {
        return `${this.ordinalSuffix(indexChoice + 1)} Choice`;
      }
      return '';
    },
    ordinalSuffix(i) {
      let j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
    isValidCandidate(candidate) {
      // Ensure blank spaces cannot be entered as options
      if (candidate.trim() === '') {
        return false;
      }
      // Sanitize writeIn input and check against existing options
      return (
        this.question.options
          .map(option => {
            return this.sanitizeCandidate(option);
          })
          .indexOf(this.sanitizeCandidate(this.writeIn)) < 0
      );
    },
    sanitizeCandidate(writeIn) {
      // Consolidate spaces, convert to lower case
      return writeIn
        .replace(/\s\s+/g, ' ')
        .trim()
        .toLowerCase();
    },
    getRestriction(restriction) {
      const key = Object.keys(restriction)[0];
      return `${key}: ${restriction[key]}`;
    }
  }
};
</script>

<style scoped>
.btn-position {
  position: relative;
  top: 5px;
}
.checkbox-v-chip {
  height: 16px !important;
  font-size: 12px;
}
</style>
