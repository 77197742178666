<template>
  <v-dialog max-width="650px" :value="value" persistent>
    <v-card class="round-card">
      <v-card-title primary-title>
        <v-layout align-center>
          <div class="subheading font-weight-bold blue-text">
            About Spectators
          </div>
          <v-spacer />
          <v-icon @click.stop="emitClose">close</v-icon>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <span>
          Unlike Members, spectators will not be able to cast votes in the
          meeting. They will be able to:
          <div>
            <br style="line-height: 50px;" />
            <ul>
              <li>Join the meeting as participant</li>
              <li>
                Unmute mics, enable video and send chat messages (if permitted
                by meeting Host)
              </li>
            </ul>
          </div>
          <br style="line-height: 50px;" />
          They will not have access to member information, meeting documents and
          private documents.
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingVbmAboutSpectators',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
