<template>
  <div>
    <span
      >From: <strong>{{ contactName }}</strong></span
    ><br />
    <span
      >Subject: <strong>{{ subject }}</strong></span
    ><br />
    <div v-html="content" />
    <v-layout>
      <div v-if="includeUnitFiles === true && unitAttachments !== {}">
        <v-chip
          v-for="file in unitAttachments"
          :key="file.key"
          small
          color="grey lighten-4"
          @click="downloadFile(file.package)"
        >
          <v-icon left>attachment</v-icon>
          {{ file.package.name }}
        </v-chip>
      </div>
      <div v-if="noticeAttachments && noticeAttachments.length > 0">
        <v-chip
          v-for="file in noticeAttachments"
          :key="file.key"
          small
          color="grey lighten-4"
          @click="downloadFile(file)"
        >
          <v-icon left>attachment</v-icon>
          {{ file.name }}
        </v-chip>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingNoticeTemplatePreview',
  props: {
    contactName: {
      type: String,
      default: ''
    },
    subject: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    includeUnitFiles: {
      type: Boolean,
      default: false
    },
    noticeAttachments: {
      type: Array,
      default() {
        return [];
      }
    },
    unitAttachments: {
      type: [Array, Object],
      default() {
        return [];
      }
    }
  },
  methods: {
    ...mapActions('files', ['getFile']),
    // Download unit attached file
    async downloadFile(file) {
      try {
        let res = await this.getFile({
          bucket: file.Bucket,
          key: file.Key
        });
        window.open(res.data.url, '_blank');
      } catch (err) {
        throw err;
      }
    }
  }
};
</script>

<style scoped></style>
