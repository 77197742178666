<template>
  <div>
    <v-alert :value="!hasMain && !loading" color="red">
      No main contact found. Please resolve this issue by selecting a main
      contact.
    </v-alert>
    <v-alert :value="!hasBilling && !loading" color="red">
      No billing contact found. Please resolve this issue by selecting a billing
      contact.
    </v-alert>
    <v-layout row>
      <v-flex xs12>
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-btn
            color="primary"
            flat
            @click="updateAssociation({ type: 'main' })"
          >
            Set / change Main
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="updateAssociation({ type: 'billing' })"
          >
            Set / change Billing
          </v-btn>
          <v-spacer />
          <v-btn flat icon @click.native="init">
            <v-icon color="primary">refresh</v-icon>
          </v-btn>
          <v-btn color="primary" @click="dialog.addContact = true">
            Add Contact
          </v-btn>
        </v-toolbar>
        <v-data-table
          :items="accountContacts"
          :headers="headers"
          :search="search"
          :loading="loading"
          :pagination.sync="config.pagination"
          :rows-per-page-items="config.rowsPerPageItems"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr class="cursor-pointer">
              <td>
                <a :href="hubspotUrl({ id: props.item.id })" target="_blank">
                  {{ props.item.email }}</a
                >
              </td>
              <td class="nowrap">
                {{ props.item.firstName }}
              </td>
              <td>{{ props.item.lastName }}</td>
              <td>{{ props.item.phone }}</td>
              <td>
                <v-chip
                  v-for="t in props.item.types"
                  :key="t"
                  outline
                  :color="typeColors[t] || 'grey'"
                >
                  {{ typeLabels[t] || t }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="props.item.types <= 0"
                  flat
                  icon
                  color="red"
                  @click.native="onConfirmDelete(props.item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>

                <v-tooltip left v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn flat icon color="grey" v-on="on">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ getMessage(props.item.types) }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <add-account-contact-association-dialog
      :value="dialog.addContact"
      @close="dialog.addContact = false"
    />

    <confirm-dialog
      :dialog="dialog.delete"
      title="Are you sure?"
      text="This will remove the contact from the account."
      :loading="removing"
      @close="dialog.delete = false"
      @confirm="deleteContact"
    />

    <update-account-contact-association-dialog
      :value="dialog.updateAssociation"
      :contacts="accountContacts"
      :type="typeSelected"
      @close="dialog.updateAssociation = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';
import AddAccountContactAssociationDialog from '@/components/dialogs/AddAccountContactAssociationDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import UpdateAccountContactAssociationDialog from '@/components/dialogs/UpdateAccountContactAssociationDialog.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AdminContactsTableView',
  props: {},
  components: {
    AddAccountContactAssociationDialog,
    UpdateAccountContactAssociationDialog,
    ConfirmDialog
  },
  filters: {
    dateFormatReadable
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey,
      search: '',
      contacts: [],
      contactSelected: null,
      typeSelected: 'main',
      loading: false,
      removing: false,
      typeLabels: {
        main_contact: 'Main contact',
        main_billing_contact: 'Billing contact'
      },
      typeColors: {
        main_contact: 'primary',
        main_billing_contact: 'teal'
      },
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'First name', value: 'firstName' },
        { text: 'Last name', value: 'lastName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Type', value: 'type' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      },
      dialog: {
        addContact: false,
        delete: false,
        updateAssociation: false
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('accounts', ['accountContacts']),
    hasMain() {
      return _.some(this.accountContacts, c =>
        c.types.includes('main_contact')
      );
    },

    hasBilling() {
      return _.some(this.accountContacts, c =>
        c.types.includes('main_billing_contact')
      );
    }
  },
  methods: {
    ...mapActions('accounts', [
      'getAccountContacts',
      'removeContactAssociation'
    ]),
    async init() {
      this.loading = true;
      try {
        await this.getAccountContacts({ key: this.accountKey });
      } catch (err) {
        this.$events.$emit('toastEvent', 'Error loading contacts');
      } finally {
        this.loading = false;
      }
    },
    hubspotUrl({ id }) {
      const hubspotSite = process.env.VUE_APP_HUBSPOT_SITE || '24343778';
      return `https://app.hubspot.com/contacts/${hubspotSite}/${id}`;
    },
    onConfirmDelete(contact) {
      this.contactSelected = contact;
      this.dialog.delete = true;
    },
    updateAssociation({ type }) {
      this.dialog.updateAssociation = true;
      this.typeSelected = type;
    },
    async deleteContact(action) {
      if (!action) {
        this.dialog.delete = false;
        return;
      }
      this.removing = true;
      try {
        await this.removeContactAssociation({
          contactId: this.contactSelected.id,
          key: this.accountKey
        });
        this.$events.$emit('toastEvent', 'Contact removed from account');
      } catch (err) {
        console.log(err);
      } finally {
        this.dialog.delete = false;
        this.contactSelected = null;
        this.removing = false;
      }
    },
    getMessage(types) {
      const isPrimary = types.includes('main_contact');
      const isBilling = types.includes('main_billing_contact');
      if (isPrimary && isBilling) {
        return 'Cannot delete main or billing contact. Please choose another contact before deleting.';
      }

      if (isPrimary) {
        return 'Cannot delete main contact. Please choose another main contact before deleting.';
      }

      if (isBilling) {
        return 'Cannot delete billing contact. Please choose another billing contact before deleting.';
      }
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
