<template>
  <v-card
    elevation="0"
    class="request-card"
    :min-height="minHeight"
    height="100%"
  >
    <!-- Proxy Holders view if type = holders -->
    <div v-if="type === 'holders'">
      <v-card-title primary-title>
        <h3>Default Proxy Holders</h3>
      </v-card-title>

      <ul class="list-styly-disc">
        <li v-for="holder in holders" :key="holder">
          <span class="capitalize">
            {{ holder }}
          </span>
        </li>
        <li v-if="!holders || holders.length === 0">
          None
        </li>
      </ul>
    </div>

    <!-- Business matter view if type = matter -->
    <div v-else-if="type === 'matter'">
      <v-card-text class="pb-0"
        ><h4 class="capitalize caption">MATTER {{ index + 1 }}</h4></v-card-text
      >
      <v-card-title class="pt-0 pb-2" primary-title>
        <h3>{{ business.name }}</h3>
      </v-card-title>

      <v-card-text
        class="pt-0 pb-0"
        v-if="['general-elections', 'owner-occupied'].includes(business.type)"
        ><span class="mr-2"><i>Choices available:</i></span
        >{{ business.positions }}</v-card-text
      >

      <v-card-text class="pt-0 pb-0" v-else
        ><span class="mr-2"><i>Choices available:</i></span
        >1</v-card-text
      >

      <v-card-text class="pb-0"><span class="mr-2">Options:</span></v-card-text>

      <ul
        class="list-styly-disc ml-3"
        v-if="['general-elections', 'owner-occupied'].includes(business.type)"
      >
        <li v-for="option in business.options" :key="option">
          <span class="capitalize">
            {{ option }}
          </span>
        </li>
        <li v-if="!business.options || business.options.length === 0">
          None
        </li>
      </ul>

      <ul class="list-styly-disc ml-3" v-else>
        <li v-for="option in byLawOptions" :key="option">
          <span class="capitalize">
            {{ option }}
          </span>
        </li>
      </ul>
      <v-card-text
        ><v-layout row align-center class="mt-0" v-if="business.package.url">
          <div class="mr-3">
            <v-icon color="grey lighten-1 ">attachment</v-icon>
          </div>
          <span
            class="primary--text cursor-pointer"
            @click="viewDocument(business.package.url)"
            >{{ business.package.name }}</span
          >
          <span class="ml-3">{{ business.package.size | fileSize }}</span>
        </v-layout></v-card-text
      >
    </div>

    <!-- Print jobs list if view = printJobs -->
    <div v-else-if="type === 'printJobs'">
      <v-list>
        <v-list-tile v-for="print in printJobs" :key="print.key">
          <v-list-tile-avatar class="pl-0">
            <v-icon color="grey lighten-1">print</v-icon>
          </v-list-tile-avatar>
          <v-layout row align-center class="mt-0">
            <span class="primary--text">{{ print.jobName }}</span>
            <span class="ml-3 print-detail"
              >{{ print.packagesMailed || 0 }} recipients /
              {{ print.packagePagesPrinted || 0 }} pages each</span
            >
            <v-icon
              v-if="isAdmin && isOpen"
              color="red"
              class="ml-3 cursor-pointer"
              @click="remove({ key: print.key })"
              >close</v-icon
            >
          </v-layout>
        </v-list-tile>
      </v-list>
    </div>

    <!-- Print jobs mailing address if view = printAddress -->
    <div v-else-if="type === 'printAddress'">
      <v-card-title primary-title>
        <h3>Return Mailing Address</h3>
      </v-card-title>

      <ul class="list-style-none">
        <li v-for="item in address" :key="item">
          <span>
            {{ item }}
          </span>
        </li>
      </ul>
    </div>

    <!-- Instructions if view = instructions -->
    <div v-else-if="type === 'instructions'">
      <v-card-title primary-title>
        <h3>{{ title }}</h3>
      </v-card-title>

      <ul class="list-styly-disc">
        <li v-for="desc in descriptions" :key="desc">
          {{ desc }}
        </li>
        <li v-if="!descriptions || descriptions.length === 0">
          None
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash';
import filesize from 'filesize';
import { mapGetters } from 'vuex';

export default {
  name: 'NoticeCard',
  props: {
    type: {
      type: String,
      default() {
        return '';
      }
    },
    holders: {
      type: Array,
      default() {
        return [];
      }
    },
    printJobs: {
      type: Array,
      default() {
        return [];
      }
    },
    index: {
      type: Number,
      default: 0
    },
    business: {
      type: Object,
      default() {
        return {};
      }
    },
    minHeight: {
      type: Number,
      default: 300
    },
    title: {
      type: String,
      default() {
        return '';
      }
    },
    descriptions: {
      type: Array,
      default() {
        return [];
      }
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    address: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    byLawOptions() {
      if (this.business.type === 'by-law') {
        // check if the question has custom label for choices
        if (
          !_.isEmpty(this.business.meta) &&
          _.get(this.business.meta, 'options.toggles', null)
        ) {
          const {
            options: { toggles = {} }
          } = this.business.meta;

          _.unset(toggles, 'defer'); // exclude defer from choices

          // Exclude any options that have been toggled off
          // and return the custom label
          return _.filter(toggles, toggle => {
            return toggle.visible;
          }).map(toggle => toggle.label);
        } else {
          return [`Yes`, `No`, 'Abstain']; // default options
        }
      }
      return [];
    }
  },
  methods: {
    viewDocument(url) {
      this.$emit('viewDocument', url);
    },
    remove({ key }) {
      this.$emit('remove', { key });
    }
  }
};
</script>

<style scoped>
.request-card {
  border: 3px solid #e7f2fb;
  border-color: #e7f2fb !important;
  border-radius: 5px !important;
}
.capitalize {
  text-transform: capitalize;
}
.list-style-none {
  list-style-type: none;
}
.list-styly-disc {
  list-style-type: disc;
}
.cursor-pointer {
  cursor: pointer;
}
.print-detail {
  font-size: 14px;
  font-style: italic;
}
</style>
