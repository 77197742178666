<template>
  <div>
    <h3>Invoice Details</h3>
    <v-divider class="primary mt-2 mb-4" />
    <div class="body-2 grey--text">Invoice Number</div>

    <span v-if="!subscription.invoiceNumber">
      No invoice, please create one in
      <a :href="chargebeeSubscriptionLink" target="_blank">Chargebee</a>.
    </span>

    <span v-if="subscription.invoiceNumber">
      <a :href="chargebeeInvoiceLink" target="_blank">{{
        subscription.invoiceNumber
      }}</a>

      <span v-if="subscription.invoiceStatus === 'paid'">
        <v-chip small color="green" class="white--text">
          Paid
        </v-chip>
      </span>

      <span v-else-if="subscription.invoiceStatus === 'posted'">
        <v-chip small color="teal" class="white--text">
          Posted
        </v-chip>
      </span>

      <span v-else-if="subscription.invoiceStatus === 'payment_due'">
        <v-chip small color="red" class="white--text">
          Payment Due
        </v-chip>
      </span>

      <span v-else>
        <v-chip small color="black" class="white--text">
          {{ subscription.invoiceStatus }}
        </v-chip>
      </span>
    </span>

    <div v-if="subscription.invoiceNumber">
      <div class="body-2 grey--text mt-2">
        Total w/ taxes ({{ subscription.currency }})
      </div>
      <span>{{ subscription.invoiceTotal | formatCurrency }}</span>

      <div class="body-2 grey--text mt-2">
        Owing ({{ subscription.currency }})
      </div>
      <span>{{ subscription.invoiceOwing | formatCurrency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionInvoiceDetailsCard',
  props: {
    subscription: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  filters: {
    formatCurrency(value) {
      return value
        ? value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })
        : 0;
    }
  },
  computed: {
    chargebeeInvoiceLink() {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/invoices'
          : 'https://getquorum-test.chargebee.com/d/invoices';

      return `${baseurl}/${this.subscription.invoiceNumber}`;
    },
    chargebeeSubscriptionLink() {
      const baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://getquorum.chargebee.com/d/subscriptions'
          : 'https://getquorum-test.chargebee.com/d/subscriptions';

      return `${baseurl}/${this.subscription.id}`;
    }
  }
};
</script>
