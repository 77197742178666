<template>
  <v-dialog :value="value" @input="emitClose" persistent max-width="400px">
    <v-card>
      <span v-if="status === 'justScheduled'">
        <v-card-title>
          <div class="title">Conversation has been scheduled</div>
          <div class="gq-cell--section-title"></div>
        </v-card-title>
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon color="orange">schedule</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{
                state.sendOn | formatDateHumanReadableAlt(true, meetingTimezone)
              }}</v-list-tile-title>
              <v-list-tile-sub-title
                >Scheduled date & time</v-list-tile-sub-title
              >
            </v-list-tile-content>
            <v-list-tile-action>
              <v-tooltip bottom>
                <span slot="activator">
                  <v-btn flat icon @click.native="emitReturn">
                    <v-icon>keyboard_return</v-icon>
                  </v-btn>
                </span>
                <span>Return to conversation</span>
              </v-tooltip>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </span>
      <span v-else-if="conversationStatus === 'scheduled'">
        <span>
          <v-card-title>
            <div class="title">You're about to unschedule this email!</div>
          </v-card-title>
          <v-card-text>
            <div>Click 'Unschedule' to continue.</div>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click.native="emitClose">Go Back</v-btn>
            <v-spacer />
            <v-btn
              color="deep-orange"
              class="white--text"
              @click.native="emitUnschedule"
            >
              <v-icon left>timer_off</v-icon>Unschedule
            </v-btn>
          </v-card-actions>
        </span>
      </span>

      <span v-else>
        <v-card-title primary-title>
          <div class="body-2 mb-2 grey--text">
            You're about to schedule a send out for:
          </div>
          <div class="title">{{ state.purpose }}</div>
        </v-card-title>
        <v-card-text class="mt-0 pt-0">
          <v-list>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">schedule</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  >{{
                    state.sendOn
                      | formatDateHumanReadableAlt(true, meetingTimezone)
                  }}<timezone-helper :date="state.sendOn"
                /></v-list-tile-title>
                <v-list-tile-sub-title
                  >Scheduled date & time</v-list-tile-sub-title
                >
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon color="orange">subject</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ state.subject }}</v-list-tile-title>
                <v-list-tile-sub-title>Subject</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click.native="emitClose">Go Back</v-btn>
          <v-spacer />
          <v-btn
            color="deep-orange"
            class="white--text"
            @click.native="emitSchedule"
            :disabled="isDisabled"
          >
            <v-icon left>schedule</v-icon>Confirm schedule
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import * as filters from '@/filters';
import TimezoneHelper from '@/components/TimezoneHelper';

export default {
  name: 'MeetingConversationViewSchedule',
  components: { TimezoneHelper },
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      default() {
        return {
          recipients: {}
        };
      }
    },
    conversationStatus: { type: String, default: '' },
    meetingTimezone: { type: String, default: null },
    status: { type: String, default: null },
    sendCount: { type: Number, default: 0 }
  },
  computed: {
    isDisabled() {
      return !this.state || !this.state.sendOn;
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSchedule() {
      this.$emit('scheduleConversation');
    },
    emitUnschedule() {
      this.$emit('unscheduleConversation');
    },
    emitReturn() {
      this.$emit('return');
    }
  }
};
</script>

<style scoped></style>
