'use strict';

import _ from 'lodash';

/**
 * Returns a full notice object from a minimal one
 *
 * @param  {String} shortCode the meeting shortCode
 * @param  {Object} notice    the minimal notice
 * @param  {Object} contact   meeting contact information
 * @return {Object}           the full notice object
 */
export function makeNewNoticeObj(shortCode, notice, replyToEmail) {
  let noticeObj = {
    campaignType: notice.campaignType,
    campaignCode: shortCode,
    campaignId: notice.campaignId,
    purpose: notice.purpose || notice.name,
    subject: notice.subject,
    body: notice.body,
    sendOn: notice.sendOn || null,
    options: notice.options,
    meta: !notice.meta ? {} : notice.meta,
    attachments: notice.attachments,
    recipients: {
      segment: notice.recipients.segment,
      emails: [],
      range: notice.recipients.range,
      customData: notice.recipients.customData
    },
    noticeTemplateKey: notice.noticeTemplateKey
  };

  // If replyTo exists on tempalte level, add to options replyTo
  if (_.get(notice, 'meta.replyTo', false)) {
    noticeObj.options = { ...noticeObj.options, replyTo: replyToEmail };
  }

  // If template has urgency set, make sure it's reflected in options as well
  if (_.get(notice, 'meta.urgent', false)) {
    noticeObj.options = { ...noticeObj.options, urgent: true };
  }

  return noticeObj;
}

/**
 * Returns the notice object used in an update operation
 *
 * @param  {String} shortCode  the shortcode the notice belongs to
 * @param  {Object} notice     the notice payload to use in update
 * @return {Object}            the full notice payload
 */
export function makeNoticeUpdateObj(shortCode, notice) {
  const noticeObj = {
    campaignCode: shortCode,
    campaignType: notice.campaignType,
    purpose: notice.purpose,
    subject: notice.subject,
    body: notice.body,
    sendOn: notice.sendOn,
    attachments: notice.attachments || [],
    options: notice.options,
    meta: notice.meta,
    recipients: {
      segment: notice.recipients.segment,
      emails: notice.recipients.emails,
      range: notice.recipients.range,
      customData: notice.recipients.customData
    },
    noticeTemplateKey: notice.noticeTemplateKey,
    isResendable: notice.isResendable
  };
  return noticeObj;
}

/**
 * Returns a full notice object from a minimal one
 *
 * @param  {String} shortCode the meeting shortCode
 * @return {Object}           the full notice object
 */
export function getNewSampleNoticeObj(shortCode) {
  const noticeObj = {
    campaignType: 'meeting',
    campaignCode: shortCode,
    purpose: 'Purpose of this notice',
    subject: 'What is your notice subject line',
    body: 'Body Content',
    sendOn: null,
    attachments: [],
    recipients: {
      segment: 'all',
      emails: []
    }
  };
  return noticeObj;
}

/**
 * Finds a notice template from a list of samples
 * @param {Array}  templates     - the list of notice templates
 * @param {String} templateName  - the tempate name to find
 * @param {String} shortCode     - the meeting shortCode
 */
export function findSampleNoticeTemplate(templates, templateName, shortCode) {
  if (name === 'New Notice') {
    return getNewSampleNoticeObj(shortCode);
  }

  const noticeTemplate = _.find(templates, { name: templateName });
  // Format for posting to API

  return {
    ...noticeTemplate,
    campaignType: 'meeting',
    recipients: {
      segment: noticeTemplate.segment,
      emails: []
    }
  };
}

/**
 * Returns the Notice Sample obj to be used in an update request
 *
 * @param  {Object} noticeSample  the notice sample data
 * @return {Object}               sample data in proper payload format
 */
export function remapNoticeSampleToSample(noticeSample) {
  const { name, campaignType, subject, body, recipients } = noticeSample;
  return {
    name,
    type: 'notice-template',
    tags: ['notices', 'templates'],
    data: {
      campaignType,
      subject,
      body,
      recipients
    }
  };
}

/**
 * Remap a Sample type to Notice Sample
 *
 * @param  {Object} sample the sample type
 * @return {Object}        the notice sample
 */
export function remapSampleToNoticeSample(sample) {
  const { id, name, data, createdAt, updatedAt } = sample;
  const { subject, campaignType, recipients, body } = data;
  return {
    id,
    name,
    subject,
    campaignType,
    recipients,
    body,
    createdAt,
    updatedAt
  };
}
