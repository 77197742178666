<template>
  <v-container column>
    <p class="body-2">
      This page is intended for you to record a proxy you received outside of
      the GetQuorum system (eg. by paper). GetQuorum does not take any
      responsibility for the validity of proxies entered through this manner.
    </p>

    <!-- Proxy giver section -->
    <p class="title mt-5">
      Who is the proxy giver?
      <span class="red--text font-weight-bold body-1">{{
        showErrorMessage('proxy-giver')
      }}</span>
    </p>

    <meeting-proxies-create-ownership
      :meeting-units-list-data="meetingUnitsListData"
      :existing-proxy="existingProxy"
      :live-vote-exists="liveVoteExists"
      :options="currentMeeting.options"
      @voterSelected="setVoterSelected"
      @clear="setVoterSelected(null)"
      :key="componentKey.meetingProxiesCreateOwnership"
    />

    <!-- Proxy holder section -->
    <p class="title mt-5">
      Who is the proxy?
      <span class="red--text font-weight-bold body-1">{{
        showErrorMessage('proxy-holder')
      }}</span>
    </p>
    <v-card>
      <v-card-text>
        <!-- Proxyholder -->
        <meeting-proxies-create-proxyholder
          :default-holders="defaultHolders"
          :proxy-holder="proxyHolder"
          :write-in="writeInProxyHolder"
          @selectedProxyHolder="setProxyHolder"
          @selectedWriteIn="setWriteInProxyHolder"
          :key="
            `create-proxyholder-${componentKey.meetingProxiesCreateProxyholder}`
          "
        />

        <v-container v-if="!disabledBackupProxyHolder" class="mb-0 pb-0">
          <p class="subheading font-weight-bold">
            (If specified) Name of the fallback proxy:
            <span class="red--text font-weight-bold body-1">{{
              showErrorMessage('proxy-holder')
            }}</span>
          </p>
        </v-container>

        <!-- Proxyholder -->
        <meeting-proxies-create-proxyholder
          v-if="!disabledBackupProxyHolder"
          :default-holders="defaultFallBackHolders"
          :proxy-holder="fallbackProxyHolder"
          :write-in="writeInFallbackProxyHolder"
          :hide-subheader="true"
          @selectedProxyHolder="setFallbackProxyHolder"
          @selectedWriteIn="setWriteInFallbackProxyHolder"
          :key="
            `create-fallback-proxyholder-${componentKey.meetingProxiesCreateProxyholder}`
          "
        />

        <!-- Ontario (bill 106) proxy specific section -->
        <meeting-proxies-create-bill-106
          v-if="currentMeeting.options.proxyTemplate === 'on-bill-106'"
          @selected="setOnBill106Selection"
          :key="`create-bill-106-${componentKey.meetingProxiesCreateBill106}`"
        />
      </v-card-text>
    </v-card>

    <!-- Question section, hide if no questions exist -->
    <v-slide-y-transition>
      <div
        v-if="
          meetingProxyQuestions &&
            meetingProxyQuestions.length > 0 &&
            formOptions.onBill106 === 'box-3' &&
            selectedUnit
        "
      >
        <p class="title mt-5">Voting</p>
        <v-card>
          <v-card-text>
            <!-- Iterate through questions, render as by-law (single choice) or election (multiple choice) questions -->
            <div
              v-for="(question, index) in meetingProxyQuestions"
              :key="question.id"
            >
              <meeting-proxies-question-radio
                v-if="question.type === 'by-law'"
                :index="index"
                :question="question"
                :validation-error-msg="showErrorMessage(`question-${index}`)"
                @selectedAnswers="setSelectedAnswers"
              />

              <meeting-proxies-question
                v-else
                :index="index"
                :question="question"
                :validation-error-msg="showErrorMessage(`question-${index}`)"
                :show-ranking="showRanking"
                @selectedAnswers="setSelectedAnswers"
                @selectedWriteIn="setWriteIn"
              />
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-y-transition>

    <!-- Optional file upload -->
    <p class="title mt-5">OPTIONAL - Upload paper proxy (PDF Only)</p>
    <p class="body-1">
      If you'd like to have a record of this proxy on file, feel free to add the
      file here
    </p>

    <v-card>
      <v-card-text>
        <meeting-proxies-create-upload
          :attachment="attachment"
          @upload="setUpload"
        />
      </v-card-text>
    </v-card>

    <v-alert
      v-if="isValidationErrors"
      class="mt-4"
      :value="true"
      color="error"
      icon="warning"
      outline
    >
      <strong>There are issues with the proxy submission:</strong>
      <ul>
        <li v-for="(error, index) in validationErrorMessages" :key="index">
          {{ error.message }}
        </li>
      </ul>
    </v-alert>

    <v-layout>
      <v-btn flat class="ml-0 mb-4 mt-5" @click="goBack"
        ><v-icon>chevron_left</v-icon> cancel</v-btn
      >
      <v-btn flat class="ml-0 mb-4 mt-5" @click="dialog.reset = true"
        ><v-icon left>refresh</v-icon> reset form</v-btn
      >
      <v-spacer />
      <v-btn
        class="primary ml-0 mb-4 mt-5"
        :loading="loading.submit"
        @click="validateForm"
        >SUBMIT PROXY</v-btn
      >
    </v-layout>

    <!-- Dialog -->
    <meeting-proxies-create-submit-dialog
      v-model="dialog.submit"
      :selected-voter="selectedVoter"
      :proxy-holder="proxyHolder"
      :existing-proxy="existingProxy"
      :loading-submit="loading.submit"
      :form-submitted="formSubmitted"
      @close="dialog.submit = false"
      @reset="resetForm"
      @back="goBack"
      @submit="submitProxy"
    />

    <meeting-proxies-create-reset-dialog
      v-model="dialog.reset"
      @reset="resetForm"
      @close="dialog.reset = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import MeetingProxiesCreateOwnership from './MeetingProxiesCreateOwnership';
import MeetingProxiesCreateProxyholder from './MeetingProxiesCreateProxyholder';
import MeetingProxiesCreateSubmitDialog from './MeetingProxiesCreateSubmitDialog';
import MeetingProxiesCreateUpload from './MeetingProxiesCreateUpload';
import MeetingProxiesQuestion from './MeetingProxiesQuestion';
import MeetingProxiesQuestionRadio from './MeetingProxiesQuestionRadio';
import MeetingProxiesCreateResetDialog from './MeetingProxiesCreateResetDialog';
import MeetingProxiesCreateBill106 from './MeetingProxiesCreateBill106';
import * as CreateProxy from '@/lib/make-proxy-object';

export default {
  name: 'MeetingProxyCreate',
  components: {
    MeetingProxiesCreateOwnership,
    MeetingProxiesQuestion,
    MeetingProxiesQuestionRadio,
    MeetingProxiesCreateProxyholder,
    MeetingProxiesCreateSubmitDialog,
    MeetingProxiesCreateUpload,
    MeetingProxiesCreateResetDialog,
    MeetingProxiesCreateBill106
  },
  watch: {
    async selectedVoter(voter) {
      if (voter) {
        // Determine if existing live vote exists, show alert if true
        try {
          let res = await this.getMeetingUnit({
            shortCode: this.shortCode,
            unitId: voter.unit_id
          });
          // Set local liveVoteExists property
          this.setLiveVoteExists({ liveVoteExists: res.data.hasLiveVotes });

          // Get meeting business when voter selected
          const { data: meetingBusiness } = await this.getMeetingBusiness({
            shortCode: this.shortCode
          });

          // Set the local questions object with vote property
          // This can only be run after selecting a voter, because question
          // validity is based on the selected unit customData (ie. owner_occupied)
          await this.initMeetingProxyQuestions({
            meetingBusiness: meetingBusiness,
            currentUnit: this.meetingUnitById(this.selectedVoter.unit_id)
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'login']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('meetings/units', [
      'meetingUnitsListData',
      'meetingUnitById'
    ]),
    ...mapGetters('meetings/proxies', [
      'meetingProxyListData',
      'filterStatuses',
      'filterSources',
      'filterBoxes',
      'STATUSES',
      'SOURCES',
      'BOXES'
    ]),
    ...mapState('meetings/dashboardProxySubmissions', [
      'meetingProxyQuestions',
      'proxyHolder',
      'writeInProxyHolder',
      'fallbackProxyHolder',
      'writeInFallbackProxyHolder',
      'selectedVoter',
      'defaultHolders',
      'attachment',
      'formOptions',
      'liveVoteExists',
      'validationErrorMessages'
    ]),
    currentMeeting() {
      return this.meetingByShortCode(this.$route.params.shortcode);
    },
    selectedUnit() {
      if (_.has(this.selectedVoter, 'unit_id')) {
        return this.meetingUnitById(this.selectedVoter.unit_id);
      }
      return null;
    },
    existingProxy() {
      if (_.has(this.selectedUnit, 'unit')) {
        return this.meetingProxyListData.find(
          proxy =>
            this.selectedUnit.unit === proxy.unit && proxy.revokedOn === null
        );
      }
      return null;
    },
    isValidationErrors() {
      return this.validationErrorMessages.length > 0 ? true : false;
    },
    disabledBackupProxyHolder() {
      if (
        this.currentMeeting?.options?.proxyAppDisplayOptions?.proxy
          ?.disableFallbackHolder ||
        (!this.proxyHolder && !this.writeInProxyHolder)
      )
        return true;

      return false;
    },
    defaultFallBackHolders() {
      return [...this.defaultHolders, 'None'];
    },
    showRanking() {
      return _.get(this.currentMeeting, 'options.electionsRanking', false);
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    // Always reset the form on exit. State is shared in add proxy dialog.
    this.resetForm();
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      loading: {
        submit: false
      },
      dialog: {
        submit: false,
        reset: false
      },
      formSubmitted: false,
      componentKey: {
        meetingProxiesCreateOwnership: 0,
        meetingProxiesCreateProxyholder: 0,
        meetingProxiesCreateBill106: 0
      }
    };
  },
  methods: {
    ...mapActions('meetings/units', ['getMeetingUnits', 'getMeetingUnit']),
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    // GetMeetingProxyList is needed here but not in the add proxy dialog
    ...mapActions('meetings/proxies', ['getMeetingProxyList']),
    ...mapActions('meetings/dashboardProxySubmissions', [
      'addMeetingProxy',
      'initMeetingProxyQuestions',
      'setSelectedAnswers',
      'setWriteIn',
      'setProxyHolder',
      'setWriteInProxyHolder',
      'setFallbackProxyHolder',
      'setWriteInFallbackProxyHolder',
      'setVoterSelected',
      'setDefaultHolders',
      'setUpload',
      'setOnBill106Selection',
      'checkOnBill106Selection',
      'resetProxyForm',
      'validateProxyForm',
      'setLiveVoteExists'
    ]),
    async init() {
      try {
        // Get all meeting unit data and meeting business
        await this.getMeetingUnits({ shortCode: this.shortCode });

        const includeStatuses =
          this.filterStatuses.length === 0
            ? [...this.STATUSES]
            : [...this.filterStatuses];
        const includeSources =
          this.filterSources.length === 0
            ? [...this.SOURCES]
            : [...this.filterSources];
        const includeBoxes =
          this.filterBoxes.length === 0
            ? [...this.BOXES]
            : [...this.filterBoxes];

        // Needed to determine if proxy already exists for voter
        await this.getMeetingProxyList({
          shortCode: this.shortCode,
          includeStatuses,
          includeSources,
          includeBoxes
        });

        // Set the local default proxy holder list
        let currentMeeting = this.meetingByShortCode(
          this.$route.params.shortcode
        );
        // Set the default proxy holder list
        this.setDefaultHolders(currentMeeting.defaultHolders);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async submitProxy() {
      this.loading.submit = true;
      const payload = CreateProxy.makeProxyObject({
        shortCode: this.shortCode,
        questions: this.meetingProxyQuestions,
        proxyHolder: this.proxyHolder,
        writeInProxyHolder: this.writeInProxyHolder,
        fallbackProxyHolder: this.fallbackProxyHolder,
        writeInFallbackProxyHolder: this.writeInFallbackProxyHolder,
        selectedVoter: {
          ...this.selectedVoter,
          unit: this.selectedUnit.unit,
          address: this.selectedUnit.address
        },
        attachment: this.attachment,
        formOptions: this.formOptions,
        proxyTemplate: this.currentMeeting.options.proxyTemplate
          ? this.currentMeeting.options.proxyTemplate
          : null
      });
      try {
        let res = await this.addMeetingProxy({
          shortCode: this.shortCode,
          proxy: payload
        });
        // On submit success, close submit dialog, open submitSuccess dialog
        if (res) {
          this.formSubmitted = true;
          this.$events.$emit('toastEvent', 'New proxy added');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.submit = false;
      }
    },
    // NAVIGATION functions ***********************
    resetForm() {
      // Reset all local state properties
      this.loading.submit = false;
      this.dialog.submit = false;
      this.dialog.reset = false;
      this.formSubmitted = false;

      // Changing the key of a component will re create it
      this.componentKey.meetingProxiesCreateOwnership += 1;
      this.componentKey.meetingProxiesCreateProxyholder += 1;
      this.componentKey.meetingProxiesCreateBill106 += 1;

      // Reset vuex state
      this.resetProxyForm();
      // Re-init to get new data
      this.init();

      //Scroll to top of page
      this.$vuetify.goTo(0, {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic'
      });
    },
    goBack() {
      // Reset the form before routing to proxies page
      this.resetForm();
      this.$router.push({ name: 'meetingProxies' });
    },
    // FORM validation functions ******************
    showErrorMessage(type) {
      let error = this.validationErrorMessages.find(
        error => error.type === type
      );
      return error ? `*${error.message}` : null;
    },
    validateForm() {
      // If it's the ontario bill 106 proxy template, check form options
      // If option 1 or 2, answers may need to be all set to abstain or defer
      if (this.currentMeeting.options.proxyTemplate === 'on-bill-106') {
        this.checkOnBill106Selection(this.formOptions.onBill106);
      }

      const enableRtvProxyOverride = this.currentMeeting.options
        .enableRtvProxyOverride;
      this.validateProxyForm({ enableRtvProxyOverride });

      // If there are no validation form messages, then open the submit dialog confirmation
      if (this.validationErrorMessages.length === 0) {
        this.dialog.submit = true;
      }
    }
  }
};
</script>

<style scoped></style>
