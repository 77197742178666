'use strict';

/**
 * @fileoverview Vuex module for checklist template groups
 */

import _ from 'lodash';
import Vue from 'vue';
import * as api from '@/api/checklist-template-groups';
import { handleError } from '@/helpers';

const state = {
  checklistTemplateGroupsList: {},
  checklistTemplateGroupMembership: {},
  dataLastFetchedAt: null
};

const getters = {
  checklistTemplateGroupsList: state => state.checklistTemplateGroupsList,
  checklistTemplateGroupDataLastFetched: state => state.dataLastFetchedAt,
  checklistTemplateGroupsListData: state =>
    Object.values(state.checklistTemplateGroupsList),
  checklistTemplateGroupByKey: state => key =>
    state.checklistTemplateGroupsList[key]
};

const actions = {
  /**
   * List all checklist template groups
   */
  async getChecklistTemplateGroups({ commit }) {
    try {
      const res = await api.getChecklistTemplateGroups();
      const sortedData = _.sortBy(res.data, 'name');

      return commit('SET_CHECKLIST_TEMPLATE_GROUP_LIST_DATA', sortedData);
    } catch (err) {
      console.error('ERROR: getChecklistTemplateGroups action', err);
      handleError(err);
      throw err;
    }
  },

  /**
   * Add a new checklist template group
   *
   * @param  {String} name            - checklist template group name
   * @param  {String} description     - checklist template group description
   */
  async createChecklistTemplateGroup({ dispatch }, { name, description }) {
    try {
      const payload = { name, description };
      const res = await api.postChecklistTemplateGroup(payload);
      await dispatch('getChecklistTemplateGroup', res.data.key);
      return res.data.key;
    } catch (err) {
      console.error('ERROR: createChecklistTemplateGroup action', err);
      throw err;
    }
  },

  /**
   * Get a checklist template group by key
   *
   * @param  {String} key            - checklist template group key
   */
  async getChecklistTemplateGroup({ commit }, key) {
    try {
      const res = await api.getChecklistTemplateGroup(key);
      return commit('SET_CHECKLIST_TEMPLATE_GROUP_IN_LIST', {
        key,
        data: res.data
      });
    } catch (err) {
      console.error('ERROR: getChecklistTemplateGroup action', err);
      throw err;
    }
  },

  /**
   * Updates a checklist template group
   *
   * @param {String}  key             - checklist template group key
   * @param {Object}  payload         - checklist template group update payload
   */
  async updateChecklistTemplateGroup({ dispatch }, { key, payload }) {
    try {
      await api.putChecklistTemplateGroup(key, payload);
      return dispatch('getChecklistTemplateGroup', key);
    } catch (err) {
      console.error('ERROR: updateChecklistTemplateGroup action', err);
      throw err;
    }
  },

  /**
   * Delete a checklist template group
   * @param {String} key              - checklist template group key
   */
  async deleteChecklistTemplateGroup({ dispatch }, key) {
    try {
      await api.deleteChecklistTemplateGroup(key);
      return dispatch('getChecklistTemplateGroups');
    } catch (err) {
      console.error('ERROR: deleteChecklistTemplateGroup action', err);
      throw err;
    }
  },

  /**
   * Add a checklist template to a checklist template group
   *
   * @param  {String} groupKey        - checklist template group key
   * @param  {String} templateKey     - checklist template key
   * @param  {String} checklistLabel  - checklist label (i.e., the name displayed on the checklist chip)
   * @param  {Number} listOrder       - list order for this template
   */
  async createChecklistTemplateGroupMembership(
    { dispatch },
    { groupKey, templateKey, checklistLabel, listOrder }
  ) {
    try {
      const payload = {
        checklistTemplateKey: templateKey,
        checklistTemplateGroupKey: groupKey,
        checklistLabel,
        listOrder
      };
      await api.postChecklistTemplateGroupMembership(groupKey, payload);
      return dispatch('getChecklistTemplateGroup', groupKey);
    } catch (err) {
      console.error(
        'ERROR: createChecklistTemplateGroupMembership action',
        err
      );
      throw err;
    }
  },

  /**
   * Updates a list of checklist template group memberships
   *
   * @param {String}  groupKey        - checklist template group key
   * @param {Object}  payload         - checklist template group memberships update payload
   */
  async updateChecklistTemplateGroupMembership(
    { dispatch },
    { groupKey, payload }
  ) {
    try {
      await api.putChecklistTemplateGroupMembership(groupKey, payload);
      return dispatch('getChecklistTemplateGroup', groupKey);
    } catch (err) {
      console.error(
        'ERROR: updateChecklistTemplateGroupMembership action',
        err
      );
      throw err;
    }
  },

  /**
   * Delete a checklist template group membership
   * @param {String} groupKey             - checklist template group key
   * @param {String} templateKey          - checklist template key
   */
  async deleteChecklistTemplateGroupMembership(
    { dispatch },
    { groupKey, templateKey }
  ) {
    try {
      await api.deleteChecklistTemplateGroupMembership(groupKey, templateKey);
      return dispatch('getChecklistTemplateGroup', groupKey);
    } catch (err) {
      console.error(
        'ERROR: deleteChecklistTemplateGroupMembership action',
        err
      );
      throw err;
    }
  }
};

const mutations = {
  SET_CHECKLIST_TEMPLATE_GROUP_LIST_DATA(state, data) {
    state.checklistTemplateGroupsList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_CHECKLIST_TEMPLATE_GROUP_IN_LIST(state, { key, data }) {
    Vue.set(state.checklistTemplateGroupsList, key, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
