<template>
  <div>
    <v-card class="mx-0 px-0 elevation-0">
      <!-- 1. This text field is only used for search. Once a voter is selected, we swap to the 2nd textfield input -->
      <!-- This is not a typical use of textfields, alternative is to use v-autocomplete component, but vuetify 1.5 v-autocomplete component is buggy -->
      <v-text-field
        v-if="!selectedVoter"
        prepend-inner-icon="search"
        label="Search by unit, name or email"
        solo
        hide-details
        v-model="searchQuery"
        @input="updateSearchQuery($event)"
      >
        <template v-slot:append>
          <v-btn flat icon v-if="searchQuery" @click="searchQuery = ''">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <!-- 2. Textfield element appears when voter is selected, is not editable, displays in specified format -->
      <v-text-field
        v-if="selectedVoter"
        solo
        hide-details
        readonly
        :value="
          `${selectedUnit} - ${selectedVoter.name} (${selectedVoter.email})`
        "
      >
        <template v-slot:append>
          <v-btn flat icon @click="clearVoterSelected">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <v-data-table
        :items="items"
        hide-headers
        hide-actions
        class="elevation-1 padding-table"
      >
        <template v-slot:items="props">
          <tr
            v-for="voter in props.item.voters"
            :key="voter.id"
            class="table-cursor"
            @click="
              selectVoter({
                voter,
                unit: props.item.unit,
                address: props.item.address
              })
            "
          >
            <td>
              {{ props.item.unit }} - {{ voter.name }} ({{ voter.email }})
            </td>
          </tr>
        </template>

        <!-- If a voter is selected or there's no search query, do not show the "no data" text -->
        <template v-slot:no-data>
          <tr
            style="visibility: hidden;"
            v-if="selectedVoter || !searchQuery"
          />
          <tr v-else>
            <td>
              No results found
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-fade-transition>
      <div>
        <!-- Check existingProxy prop & existingProxySource is truthy to trigger alert -->
        <v-alert
          v-if="existingProxy && existingProxySource"
          :value="true"
          color="error"
          icon="warning"
          outline
        >
          A proxy already exists for this unit, submitted by
          <strong
            >{{ existingProxySource.name }} ({{
              existingProxySource.email
            }})</strong
          >
          on
          <strong>{{
            existingProxy.createdAt | dateFormatReadable(true)
          }}</strong
          >. This proxy will be revoked if you continue with this submission.
        </v-alert>
        <v-alert
          v-if="!isRtvProxyOverrideEnabled && liveVoteExists"
          :value="true"
          color="error"
          icon="error"
          outline
        >
          A live vote has already been submitted for this unit. You will not be
          able to continue with this submission.
        </v-alert>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { getMeetingsUnitsList } from '@/api/meetings-units';
import { dateFormatReadable } from '@/filters';
import _ from 'lodash';

export default {
  name: 'MeetingProxiesCreateOwnership',
  props: {
    meetingUnitsListData: {
      type: Array,
      default() {
        return [];
      }
    },
    existingProxy: {
      default() {
        return null;
      }
    },
    liveVoteExists: {
      type: Boolean,
      default() {
        return false;
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  filters: {
    dateFormatReadable
  },
  computed: {
    // Use the metaData source name and email when proxy is created via dashboard
    existingProxySource() {
      // Check that existingProxy is a truthy value
      if (this.existingProxy) {
        // Use metaData.source name & email if it exists in existingProxy
        // Otherwise, use the original name and email
        let name = _.has(this.existingProxy, 'metaData.source')
          ? this.existingProxy.metaData.source.name
          : this.existingProxy.name;

        let email = _.has(this.existingProxy, 'metaData.source')
          ? this.existingProxy.metaData.source.email
          : this.existingProxy.email;

        return {
          name,
          email
        };
      }
      return null;
    },
    isRtvProxyOverrideEnabled() {
      return this.options.enableRtvProxyOverride;
    }
  },
  data() {
    return {
      items: [],
      searchQuery: null,
      selectedVoter: null,
      selectedUnit: null,
      loading: false
    };
  },
  methods: {
    selectVoter({ voter, unit }) {
      // Clear search query
      this.searchQuery = null;

      // Clear table items
      this.items.splice(0, this.items.length);

      // select voter and unit (used for display in search bar)
      this.selectedVoter = voter;
      this.selectedUnit = unit;

      // emit event
      this.$emit('voterSelected', voter);
    },
    clearVoterSelected() {
      this.selectedVoter = null;
      this.$emit('clear');
    },
    updateSearchQuery: _.debounce(function(value) {
      if (!value || (value && value.length < 1)) {
        this.items.splice(0, this.items.length);
        return;
      }
      this.searchUnits({
        shortCode: this.$route.params.shortcode,
        searchText: value
      });
    }, 500),
    async searchUnits({ shortCode, searchText }) {
      try {
        const res = await getMeetingsUnitsList({ shortCode, searchText });
        if (res.status !== 200) {
          throw new Error(res.text);
        }

        // Set items to voter response
        this.items = res.data.units;
      } catch (err) {
        throw err;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.padding-table {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 49px;
}
.table-cursor {
  cursor: pointer;
}
.hide-no-data-row {
  visibility: hidden;
}
</style>
