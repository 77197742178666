import moment from 'moment';
import momentTz from 'moment-timezone';
import _ from 'lodash';
import router from '@/routes';

export {
  // General
  getUserMeetingScope,
  formatCurrencyValue,
  filterSearchData,
  sortByProperty,
  handleError,
  // Dates
  formatDate,
  formatDateHumanReadable,
  // CSV conversion
  convertArrayOfObjectsToCSV,
  convertVotingResultsObjectToCSV,
  downloadCSV,
  getSortDirectionString,
  brify,
  // Invoice
  addMarginValue,
  // API
  isDataExpired,
  // UI
  getColor
};

/**
 * Get user scopes (ie. permissions) for specified meeting
 *
 * @param  {String}      shortCode - the meeting shortCode
 * @return {Object|Null} The user's scope object for this meeting or null if
 *                       not found.
 */
function getUserMeetingScope(shortCode) {
  const profile = JSON.parse(sessionStorage.getItem('profile'));

  if (!_.has(profile, 'scopes')) {
    return null;
  }

  const userScopes = profile.scopes;
  const mtgScope = userScopes.filter(prop => prop.meeting === shortCode);
  return mtgScope.length > 0 ? mtgScope[0] : null;
}

/** Convert to moment timezone object
 * @param  {Date} date - the meeting shortcode
 * @param  {String} timezone - the timezone to convert to
 * @param  {String} format - the string date format to convert to
 * @return {Object} - returns a moment object
 */
function formatDate(date, timezone, format) {
  return momentTz(new Date(date))
    .tz(timezone)
    .format(format);
}

/**
 * Format date using common human readable parameters
 *
 * @param {Date} date - the date to format
 * @param {Boolean} includeTime - to include time
 * @return {String} the formatted date
 */
function formatDateHumanReadable(date, includeTime = null, timezone) {
  const format = includeTime ? 'YYYY-MM-DD H:mm a zz' : 'YYYY-MM-DD';
  return formatDate(date, timezone ? timezone : 'America/Toronto', format);
}

/**
 * Format currency type numbers
 * @param {Number} value - value to format
 * @return {String} formatted string
 */
function formatCurrencyValue(value) {
  return parseFloat(value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: 'symbol'
  });
}

/** Converts Array to CSV for download
 * @param  {Object} args - object to convert to CSV format
 * @return {Object} - returns formatted CSV
 */
function convertArrayOfObjectsToCSV(args) {
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;

  // set data to object
  data = args || null;

  // if null, return null
  if (data === null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  // get keys from first element in data
  keys = Object.keys(data[0]);

  // Construct first header row
  // Wrap each element of the array with quotes
  // Add comma delimiter
  result = '';
  result += keys
    .map(function(el) {
      return '"' + el + '"';
    })
    .join(columnDelimiter);
  result += lineDelimiter; // Add new line

  data.forEach(function(item) {
    ctr = 0;
    keys.forEach(function(key) {
      // Don't add column delimiter in front of first item
      if (ctr > 0) {
        result += columnDelimiter;
      }
      result += '"' + item[key] + '"';
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

/** Converts Voting Result Array to CSV for download
 * @param  {Object} args - object to convert to CSV format
 * @return {Object} - returns formatted CSV
 */
function convertVotingResultsObjectToCSV(args) {
  let result,
    ctr,
    mainKeys,
    keys,
    subKeys,
    columnDelimiter,
    lineDelimiter,
    data;

  // set data to object
  data = args || null;

  // if null, return null
  if (data === null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  // get keys from first element in data
  keys = Object.keys(data[0]);
  mainKeys = _.cloneDeep(keys);
  mainKeys.splice(-1);
  // get keys from tally array
  subKeys = Object.keys(data[0].tally[0]);
  subKeys.splice(0, 3); // ignore 3 first keys
  // gather all keys into one array
  let gatherKeys = [...mainKeys, ...subKeys];

  // Construct first header row
  // Wrap each element of the array with quotes
  // Add comma delimiter
  result = '';
  result += gatherKeys
    .map(function(el) {
      return '"' + el + '"';
    })
    .join(columnDelimiter);
  result += lineDelimiter; // Add new line

  data.forEach(function(item) {
    ctr = 0;
    keys.forEach(function(key) {
      // Don't add column delimiter in front of first item
      if (ctr > 0) {
        result += columnDelimiter;
      }

      if (key === 'tally') {
        // start mapping tally array
        const format = item.tally.map(row => Object.values(row));
        result += lineDelimiter;
        result += format.join('\n');
      } else {
        result += '"' + item[key] + '"';
        ctr++;
      }
    });
    result += lineDelimiter;
  });

  return result;
}

/** Download CSV file
 * @param {Object} filename - filename
 * @param {Object} csvdata - the csv object to convert
 */
function downloadCSV(filename, csvdata) {
  if (csvdata === null) {
    return;
  }

  let blob = new Blob(['\ufeff', csvdata]);
  let url = URL.createObjectURL(blob);

  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * Returns the Sort Direction String that the API accepts
 * @param {String} direction - the sort direction used by Element-UI
 * @return {String|null} the direction string that API accepts
 */
function getSortDirectionString(direction) {
  if (!direction) {
    return null;
  }
  switch (direction.toLowerCase()) {
    case 'ascending':
      return 'asc';
    case 'descending':
      return 'desc';
    default:
      return null;
  }
}

/**
 * Searches a data array (of objects) and returns a new array
 * that matches the search string.
 *
 * @param {Array} data - the array of object data
 * @param {String} searchKey - the string to search on
 * @return {Array} a new array of data that matches the searchKey
 */
function filterSearchData(dataArray, searchKey) {
  // Don't filter if nothing to search on
  if (!searchKey) {
    return [...dataArray];
  }
  return dataArray.filter(function(e) {
    return Object.keys(e).some(function() {
      return (
        String(e['name'])
          .toLowerCase()
          .indexOf(searchKey) > -1 ||
        String(e['shortCode'])
          .toLowerCase()
          .indexOf(searchKey) > -1
      );
    });
  });
}

/**
 * Converts the \n from a string into <br>
 *
 * @param  {String} value the string with line breaks
 * @return {String}       the string with line breaks replaced with <br>
 */
function brify(value) {
  return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

/**
 * Calculate the amount after adding gross margin. Uses the formula
 * PRICE = COST / (1 - MARGIN) where margin is in decimal
 *
 * @param  {Number} marg gross margin percentage as a decimal
 * @param  {Number} cost base cost of item
 * @return {Number}      revenue after margin added
 */
function addMarginValue(marg, cost) {
  if (!cost || cost <= 0) {
    return 0;
  }
  if (!marg || marg <= 0) {
    return cost;
  }
  const price = cost / (1 - marg);
  return _.round(price, 2);
}

/**
 * Sort an array of objects a property
 *
 * @param  {Array}  array      the array to sort
 * @param  {String} prop       they name of the property to sort by
 * @param  {String} direction  direction to sort by ('oldest', 'newest')
 * @return {Array}             sorted array
 */
function sortByProperty(array, prop, direction) {
  if (direction === 'oldest') {
    return array.sort((a, b) => {
      return new Date(a[prop]) - new Date(b[prop]);
    });
  }
  return array.sort((a, b) => {
    return new Date(b[prop]) - new Date(a[prop]);
  });
}

/**
 * Checks to see if the data from the API has expired
 *
 * @param  {Date}    lastFetchedAt last data fetch timestamp
 * @param  {Integer} minutes       Num minutes data is fresh. Default 5 mins.
 * @return {Boolean}               true if data is expired
 */
function isDataExpired(lastFetchedAt, minutes = 5) {
  const expiryDate = moment(lastFetchedAt).add(minutes, 'minutes');
  return moment().isSameOrAfter(expiryDate);
}

/**
 * Get the color of a note or checklist type, generally used for chips
 *
 * @param  {String} type
 * @return {String}
 */
function getColor(type) {
  switch (type) {
    case 'service':
      return 'red';
    case 'pre-notice':
      return 'light-blue';
    case 'pre-consent':
      return 'orange';
    default:
      return 'grey';
  }
}

/**
 * Handle response errors and navigate to the maintenance page when appropriate
 *
 * @param {Object} error
 */
function handleError(error) {
  console.error(error.response);
  if (error.response) {
    if (error.response.status === 503) {
      navToMaintenance();
    }
  } else if (error.request) {
    //If response is null and request is defined, service is under maintenance
    navToMaintenance();
  }
}

/**
 * Navigate to maintenance page if not already on it
 */
export function navToMaintenance() {
  if (router.currentRoute.name !== 'maintenance') {
    router.push({ name: 'maintenance' });
  }
}

/**
 * Removes accents from a string.
 *
 * @param {string} str - the input string with accents
 * @return {string} the input string with accents removed
 */
export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
