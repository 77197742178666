<template
  ><v-dialog
    max-width="900px"
    :value="value"
    @keydown.esc="emitClose"
    persistent
    @input="emitInput"
  >
    <v-card>
      <v-card-title class="title">Add New Conversation Template</v-card-title>
      <v-card-text>
        <v-toolbar dark color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            v-model="search"
          />
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="templates"
          :search="search"
          v-model="selected"
          select-all
          :rows-per-page-items="config.rowsPerPageItems"
          :pagination.sync="config.pagination"
          item-key="noticeTemplateKey"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>
              <v-checkbox primary hide-details v-model="props.selected" />
            </td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.subject }}</td>
            <td class="text-xs-left">{{ props.item.meta.templateType }}</td>

            <td class="text-xs-left">
              {{ props.item.updatedAt | dateFromNow }}
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="emitClose">Cancel</v-btn>
        <v-btn class="primary" @click.native="emitAdd">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import * as filters from '@/filters';

import _ from 'lodash';
export default {
  name: 'AdminConversationTemplateGroupsManagementDialog',
  filters: {
    ...filters
  },
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    templatesList: {
      type: Array,
      default() {
        return [];
      }
    },
    addedTemplateKeys: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      state: {},
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'updatedAt',
          descending: true
        }
      },
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Subject', value: 'subject', align: 'left' },
        { text: 'Type', value: 'type', align: 'left' },
        { text: 'Last Modified', value: 'updatedAt', align: 'left' }
      ],
      selected: []
    };
  },
  computed: {
    templates() {
      const filteredTemplates = this.templatesList.filter(item => {
        return !this.addedTemplateKeys.includes(item.noticeTemplateKey);
      });
      return filteredTemplates || [];
    }
  },
  mounted() {},
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitAdd() {
      this.$emit('add', this.selected);
    }
  }
};
</script>

<style scoped></style>
