<template>
  <v-dialog persistent :value="isOpen" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="title">Create Invoice</span>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex xs12
            ><v-alert :value="true" type="info" outline
              >This invoice will be created in Chargebee.</v-alert
            ></v-flex
          >
        </v-layout>

        <!-- Invoice Date card -->
        <v-layout class="mt-3">
          <v-flex xs12>
            <v-card tile>
              <v-card-title class="mb-0 pb-0">
                <h3>Select invoice date:</h3>
              </v-card-title>
              <v-card-text class="mt-0 pt-0">
                <date-picker
                  label="Invoice Date"
                  hide-icon
                  box
                  :iso-date="invoiceDate"
                  :is-required="true"
                  hint="Date that will appear on the invoice PDF"
                  @update="updateDate"
                  @clear="updateDate"
                  dateFormat="MMMM DD, YYYY"
                  class="mt-2"
                />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- Billing items/print jobs card -->
        <v-layout class="mt-3">
          <v-card tile>
            <v-card-title class="mb-0 pb-0">
              <h3>Select items/print jobs to bill for:</h3>
            </v-card-title>
            <v-card-text class="mt-0 pt-0">
              <!-- Billing item checkboxes -->
              <v-checkbox
                v-for="item in items"
                v-model="item.include"
                :key="item.key"
                :label="`${item.name}: $${item.value}`"
                hide-details
              ></v-checkbox>
              <!-- Print job checkboxes -->
              <v-checkbox
                v-for="job in jobs"
                v-model="job.include"
                :key="job.key"
                :label="
                  `${job.jobName} - (${job.pages} pages x ${job.packages} packages) plus postage: $${job.value}`
                "
                hide-details
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="primary" @click.native="handleClose">Cancel</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!invoiceDate"
          @click.native="handleSubmit"
          >create</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'MeetingBillingCreateInvoiceDialog',
  components: {
    DatePicker
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    billingItems: {
      type: Array,
      default() {
        return [];
      }
    },
    printJobs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  watch: {
    billingItems() {
      this.items = this.billingItems.map(item => {
        return { ...item, include: true };
      });
    },
    printJobs() {
      this.jobs = this.printJobs.map(job => {
        return { ...job, include: true };
      });
    }
  },
  data() {
    return {
      invoiceDate: '',
      items: [],
      jobs: []
    };
  },
  mounted() {
    this.resetState();
  },
  methods: {
    updateDate(invoiceDate) {
      if (!invoiceDate) {
        this.invoiceDate = '';
      } else {
        this.invoiceDate = moment(invoiceDate)
          .hour(0)
          .minute(0)
          .tz(this.meetingTimezone)
          .format('YYYY-MM-DD[T]HH:mmZZ');
      }
    },
    handleSubmit() {
      const itemsToBill = this.items.filter(item => item.include);
      const jobsToBill = this.jobs.filter(job => job.include);

      this.$emit('submit', this.invoiceDate, itemsToBill, jobsToBill);

      this.handleClose();
    },
    handleClose() {
      this.resetState();
      this.$emit('close');
    },
    resetState() {
      const now = moment();
      this.updateDate(now);

      this.items = this.billingItems.map(item => {
        return { ...item, include: true };
      });

      this.jobs = this.printJobs.map(job => {
        return { ...job, include: true };
      });
    }
  }
};
</script>

<style scoped></style>
