<template>
  <v-list-tile
    class="elevation-0 mb-2 card"
    @click="clicked"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    avatar
  >
    <v-tooltip bottom>
      <v-list-tile-avatar slot="activator">
        <v-icon :class="avatarClass">{{ avatarIcon }}</v-icon>
      </v-list-tile-avatar>
      <span class="capitalize">{{ avatarTooltipText }}</span>
    </v-tooltip>

    <v-list-tile-content class="name-column">
      <v-list-tile-title>
        {{ requestData.name }}
        <v-chip
          v-if="isCreatedToday"
          small
          label
          color="#0F64A9"
          text-color="white"
          class="ml-2"
          >New</v-chip
        >
      </v-list-tile-title>
      <v-list-tile-sub-title>
        {{
          requestData.meetingDate
            | formatDate(
              requestData.meetingTimezone,
              'MMM Do YYYY [at] h:mmA zz'
            )
        }}
        <b class="green--text">{{ daysRemainingText }}</b>
      </v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content>
      <v-chip
        class="hidden-sm-and-down status mr-3 bold"
        :text-color="getStatusDetails(request.status).color"
        :color="getStatusDetails(request.status).background"
        v-if="request.status && isAdmin"
        small
      >
        {{ request.status.toUpperCase() }}
      </v-chip>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { formatDate } from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'MeetingsRequestedViewListItem',
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatDate
  },
  data() {
    return {
      statusOptions: {
        pending: {
          background: '#ffe389',
          color: '#EC9235',
          label: 'Pending',
          value: 'pending'
        }
      }
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    requestData() {
      return this.request.data;
    },
    avatarClass() {
      // If it's in the past, just do grey and grey.
      if (this.isPast) {
        return 'grey lighten-2 grey--text';
      }

      // It's upcoming, slight differences based on whether there's a webcast or n ot
      return this.hasWebcast
        ? `primary white--text`
        : `primary lighten-4 primary--text`;
    },
    avatarIcon() {
      const type = this.requestData.options
        ? this.requestData.options.type
        : '';

      if (this.hasWebcast) {
        return 'video_call';
      } else if (type === 'budget') {
        return 'attach_money';
      } else if (type === 'certs') {
        return 'attach_email';
      } else {
        return 'event';
      }
    },
    avatarTooltipText() {
      if (this.hasWebcast) {
        return 'Virtual Meeting';
      } else {
        return this.requestData.options
          ? this.requestData.options.type
          : 'event';
      }
    },
    hasWebcast() {
      return (
        this.requestData.options &&
        (this.requestData.options.subtype === 'Virtual' ||
          !this.requestData.options.subtype)
      );
    },
    dateDiff() {
      const meetingDate = moment(this.requestData.meetingDate).startOf('day');
      const now = moment().startOf('day');
      return meetingDate.diff(now, 'days');
    },
    daysRemainingText() {
      return this.dateDiff === 0 ? '(Today)' : '';
    },
    isPast() {
      return this.dateDiff < 0;
    },
    isCreatedToday() {
      return moment().isSame(this.request.createdAt, 'days');
    },
    statusDetails() {
      return this.statusOptions;
    }
  },

  methods: {
    clicked() {
      this.$router.push({
        name: 'meetingRequestDetails',
        params: {
          requestKey: this.request.key
        }
      });
    },
    getStatusDetails(status) {
      return this.statusDetails[status] ? this.statusDetails[status] : '';
    }
  }
};
</script>

<style scoped>
.card {
  background-color: white;
}
.status {
  position: absolute;
  right: 0;
}
.v-list__tile__title {
  height: 28px;
}
.name-column {
  flex: 50%;
}
</style>
