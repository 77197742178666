'use strict';

/**
 * @fileoverview Vuex module for a meeting's files
 */

import _ from 'lodash';
import Vue from 'vue';

// import {
//    getQuestionsCombined,
//   postBusinessOrder,
//    toggleVoteExclusion
// } from '@/api/v2/questions';

const state = {
  questionsCombinedList: {},
  questionsList: {},
  dataLastFetchedAt: null,
  pusher: {}
};

const getters = {
  questionsCombined: state =>
    Object.values(state.questionsCombinedList).sort(
      (a, b) => a.order - b.order
    ),
  hasExpandedQuestionCombined: state =>
    Object.values(state.questionsCombinedList).find(q => q.isExpanded),
  questions: state =>
    Object.values(state.questionsList).sort((a, b) => a.order - b.order),
  hasExpandedQuestion: state =>
    Object.values(state.questionsList).find(q => q.isExpanded)
};

const actions = {
  // TO-DO: Remove if/when we sunset the V2 Overview page.
  //
  /**
   * Get the list of V2 questions
   * @param  {String} {shortCode}  meeting code
   */
  // async fetchQuestionsCombined({ commit, state }, { shortCode }) {
  //   try {
  //     const { data } = await getQuestionsCombined(shortCode);
  //     const questionsCombined = data.map(question => {
  //       const existingQuestion = state.questionsCombinedList[question.id];
  //       // Add total votes to tallies
  //       // TODO: This could be calculated with SQL
  //       _.map(question.tally, tally => {
  //         // Excluded votes are shifted to the bottom of the tables
  //         if (tally.isExcluded) {
  //           tally.numVotesTotal = '-';
  //           tally.numVotesTotalUnits = '-';
  //           return tally;
  //         }
  //         // Keep track of the total number of votes from stored values
  //         // 'shares' metric
  //         tally.numVotesTotal =
  //           tally.numVotes + tally.numVotesAdv + tally.numAdditionalVotesTotal;
  //         // 'units' metric
  //         tally.numVotesTotalUnits =
  //           (tally.numUnits || 0) +
  //           tally.numUnitsAdv +
  //           tally.numAdditionalVotesUnitsTotal;
  //         return tally;
  //       });
  //       const options = _.map(question.tally, tally => {
  //         return tally.vote;
  //       });
  //       const label = question.label ? question.label : question.name;
  //       return {
  //         ...question,
  //         options,
  //         label,
  //         isExpanded: existingQuestion ? existingQuestion.isExpanded : false
  //       };
  //     });
  //     return commit('SET_QUESTIONS_COMBINED', { questionsCombined });
  //   } catch (err) {
  //     console.error('Error: fetchQuestionsCombined action', err);
  //     throw err;
  //   }
  // },
  // TO-DO: Remove if/when we sunset the V2 Overview page.
  //
  /**
   * Update the ordering for a meeting's business
   */
  // async changeBusinessOrder({ dispatch }, { shortCode, orderArray }) {
  //   try {
  //     await postBusinessOrder({ shortCode, orderArray });
  //     return dispatch('fetchQuestionsCombined', { shortCode });
  //   } catch (err) {
  //     console.error('Error: changeBusinessOrder action', err);
  //     throw err;
  //   }
  // },
  // TO-DO: Remove if/when we sunset the V2 Overview page.
  //
  /**
   * Toggle an individual question's expand or collapse
   * @param  {String} {id}  questions Id
   */
  // async toggleQuestionExpand({ commit, state }, { id }) {
  //   try {
  //     const questionCombined = state.questionsCombinedList[id];
  //     if (!questionCombined) {
  //       throw Error(`Question id: ${id} not found`);
  //     }
  //     const updatedQuestion = {
  //       ...questionCombined,
  //       isExpanded: !questionCombined.isExpanded
  //     };
  //     return commit('SET_QUESTIONS_COMBINED', { question: updatedQuestion });
  //   } catch (err) {
  //     console.error('Error: expandQuestion action', err);
  //     throw err;
  //   }
  // },
  // TO-DO: Remove if/when we sunset the V2 Overview page.
  //
  /**
   * Toggle an all questions to expand or collapse
   * @param  {String} {isExpanded}  boolean to toggle expand
   */
  // async toggleAllQuestionsExpand({ commit, state }, { isExpanded }) {
  //   try {
  //     const questionsCombined = Object.values(state.questionsCombinedList).map(
  //       question => {
  //         return {
  //           ...question,
  //           isExpanded
  //         };
  //       }
  //     );
  //     return commit('SET_QUESTIONS_COMBINED', { questionsCombined });
  //   } catch (err) {
  //     console.error('Error: expandQuestion action', err);
  //     throw err;
  //   }
  // }
  // TO-DO: Remove if/when we sunset the V2 Overview page.
  //
  /**
   * Update the list of candidate exclusions
   */
  // async toggleVoteExclusion(
  //   { dispatch },
  //   { shortCode, questionKey, exclusion }
  // ) {
  //   try {
  //     await toggleVoteExclusion({ shortCode, questionKey, exclusion });
  //     return dispatch('fetchQuestionsCombined', { shortCode });
  //   } catch (err) {
  //     console.error('Error: toggleVoteExclusion action', err);
  //     throw err;
  //   }
  // }
};

const mutations = {
  // SET_QUESTIONS_COMBINED(state, { questionsCombined }) {
  //   Vue.set(state, 'questionsCombinedList', _.keyBy(questionsCombined, 'id'));
  //   state.dataLastFetchedAt = new Date();
  // },
  // SET_QUESTIONS(state, { questions }) {
  //   Vue.set(state, 'questionsList', _.keyBy(questions, 'id'));
  //   state.dataLastFetchedAt = new Date();
  // },
  // SET_QUESTION(state, { question }) {
  //   Vue.set(state.questionsList, question.id, question);
  // },
  // // Reset the state
  // CLEAR_STATE(state) {
  //   Vue.set(state, 'questionsCombinedList', {});
  //   Vue.set(state, 'questionsList', {});
  //   Vue.set(state, 'pusher', {});
  //   Vue.set(state, 'dataLastFetchedAt', null);
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
