<template>
  <router-view
    :template-groups-list="conversationTemplateGroupsList"
    :template-groups-data="conversationTemplateGroupsListData"
    :last-data-fetched="conversationTemplateGroupDataLastFetched"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import { handleError } from '@/helpers';

export default {
  name: 'AdminConversationTemplateGroupsMain',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('conversationTemplateGroups', [
      'conversationTemplateGroupsList',
      'conversationTemplateGroupsListData',
      'conversationTemplateGroupDataLastFetched'
    ])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('conversationTemplateGroups', [
      'getConversationTemplateGroups'
    ]),
    async init() {
      try {
        await this.getConversationTemplateGroups();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    }
  }
};
</script>

<style scoped></style>
