<template>
  <v-container v-if="lastDataFetched" grid-list-md fluid>
    <v-layout row class="ma-3">
      <v-flex xs12>
        <p class="title">Edit: {{ currentTemplateGroup.name }}</p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ml-3 mr-3">
        <v-card>
          <v-card-title class="headline">
            Template Group Details
          </v-card-title>
          <v-card-text>
            <input-info
              :name="state.name"
              :description="state.description"
              @update="handleUpdate"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn flat class="accent--text" @click="deleteSampleTemplateGroup">
              <v-icon>delete_forever</v-icon>Delete Template Group
            </v-btn>
            <v-spacer />
            <v-btn
              class="accent"
              @click="updateSampleTemplateGroup"
              :disabled="!isChanged"
            >
              <v-icon left>save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-4">
          <v-card-title class="headline pb-0">
            Template Group Management
          </v-card-title>
          <v-card-text>
            <v-toolbar dense color="grey lighten-4 elevation-1">
              <v-spacer />
              <v-btn class="primary" dark @click.native="addTemplate()">
                <v-icon left>add</v-icon>Add
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="items"
              v-sortable-data-table
              @sorted="saveOrder"
              item-key="key"
              hide-actions
            >
              <template slot="items" slot-scope="props">
                <tr class="grab" :key="props.item.key">
                  <td>
                    <v-icon style="vertical-align: middle">reorder</v-icon>
                    {{ props.item.name }}
                    <v-chip
                      class="white--text"
                      color="accent"
                      label
                      small
                      outline
                      disabled
                      v-if="props.item.meta.urgent"
                      >urgent</v-chip
                    >
                  </td>

                  <td>{{ props.item.subject }}</td>
                  <td>{{ props.item.meta.templateType }}</td>
                  <td>{{ props.item.listOrder }}</td>
                  <td>
                    <div class="float-right">
                      <v-text-field
                        v-model="props.item.membershipMeta.estimatedDays"
                        label="Only relevant for meeting triggers."
                        filled
                        hint="Number of days taken into
                        account to schedule this conversation before the due
                        date of the stage."
                        persistent-hint
                        @keyup.exact="changeDetection(props.item)"
                      />
                    </div>
                  </td>
                  <td>
                    {{ props.item.membershipDateUpdated | formatDate }}
                  </td>
                  <td class="text-xs-right">
                    <div class="d-inline-flex">
                      <v-tooltip top>
                        <div slot="activator">
                          <v-icon
                            class="ml-1 pointer"
                            @click="goToTemplate(props.item.key)"
                            >edit</v-icon
                          >
                        </div>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <div slot="activator">
                          <v-icon
                            class="ml-1 pointer  "
                            @click="
                              removeTemplate(
                                props.item.conversationTemplateGroupKey,
                                props.item.membershipKey
                              )
                            "
                            >remove_circle</v-icon
                          >
                        </div>
                        <span>Remove</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <add-conversation-template-dialog
      v-if="addTemplateClicked"
      v-model="dialog.addTemplate"
      :templates-list="conversationUniqueTemplateListData"
      :added-template-keys="addedKeys"
      @add="onAddTemplateClick"
      @close="dialog.addTemplate = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import Sortable from 'sortablejs';
import { mapActions, mapGetters } from 'vuex';
import InputInfo from '@/components/input/InputTemplateGroupInfo';

import AddConversationTemplateDialog from './AdminConversationTemplateGroupsManagementDialog';

export default {
  name: 'AdminConversationTemplateGroupsView',
  components: {
    InputInfo,
    AddConversationTemplateDialog
  },
  props: {
    templateGroupsList: {
      type: Object,
      required: true
    },
    templateGroupsData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  filters: {
    formatDate(date) {
      if (!date || date === Infinity) {
        return '';
      }
      return moment(date)
        .tz('America/Toronto')
        .format('MMM DD YYYY hh:mm a zz');
    }
  },
  data() {
    return {
      templateGroupKey: this.$route.params.conversationTemplateGroupKey,
      state: {
        name: '',
        description: ''
      },
      headers: [
        {
          text: 'Template Name',
          align: 'left',
          sortable: false,
          value: 'templateName'
        },
        {
          text: 'Subject',
          align: 'left',
          value: 'subject',
          sortable: false
        },
        {
          text: 'Type (stage)',
          align: 'left',
          value: 'type',
          sortable: false
        },
        {
          text: 'Order',
          align: 'left',
          value: 'order',
          sortable: false
        },
        {
          text: 'Estimated days',
          align: 'left',
          value: 'order',
          sortable: false
        },
        {
          text: 'Last Modified',
          align: 'left',
          value: 'date',
          sortable: false
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ],
      items: [],
      dialog: {
        addTemplate: false
      },
      addTemplateClicked: false
    };
  },
  computed: {
    ...mapGetters('conversationTemplateGroups', [
      'conversationTemplateGroupByKey'
    ]),
    ...mapGetters('meetings/conversationTemplates', [
      'conversationUniqueTemplateListData'
    ]),
    currentTemplateGroup() {
      return this.conversationTemplateGroupByKey(this.templateGroupKey) || {};
    },
    isChanged() {
      return !_.isEqual(this.state, this.currentTemplateGroup);
    },
    lastOrder() {
      if (this.items.length === 0) {
        return 0;
      }
      return this.items[this.items.length - 1].listOrder;
    },
    addedKeys() {
      const keys = this.items.map(item => {
        return item.key;
      });
      return keys;
    }
  },
  watch: {
    currentTemplateGroup: function() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('meetings/conversationTemplates', [
      'getConversationTemplates'
    ]),
    ...mapActions('conversationTemplateGroups', [
      'deleteConversationTemplateGroup',
      'updateConversationTemplateGroup',
      'createConversationTemplateGroupMembership',
      'updateConversationTemplateGroupMembership',
      'deleteConversationTemplateGroupMembership'
    ]),
    handleUpdate(obj) {
      const newState = { ...this.state };
      _.set(newState, obj.key, obj.value);
      this.$set(this, 'state', newState);
    },
    setState() {
      this.state = _.cloneDeep(this.currentTemplateGroup);
      this.items = _.cloneDeep(this.currentTemplateGroup.conversationTemplates);
      this.items.forEach(item => {
        if (!item.membershipMeta) {
          item.membershipMeta = {};
        }
      });
    },
    async updateSampleTemplateGroup() {
      try {
        const { key, name, description } = this.state;
        const payload = {
          key: key,
          name: name,
          description: description ? description : name
        };
        await this.updateConversationTemplateGroup({
          key: this.templateGroupKey,
          payload: payload
        });
        this.$events.$emit('toastEvent', 'Conversation Template Group Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteSampleTemplateGroup() {
      const isConfirm = confirm('Delete this Conversation Template Group?');
      if (!isConfirm) {
        return;
      }

      try {
        await this.deleteConversationTemplateGroup(this.templateGroupKey);
        this.$events.$emit('toastEvent', 'Conversation Template Group Deleted');
        this.$router.push({ name: 'adminConversationTemplateGroups' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    saveOrder(event) {
      const movedItem = this.items.splice(event.oldIndex, 1)[0];
      this.items.splice(event.newIndex, 0, movedItem);

      const itemsWithorder = this.items.map((item, index) => {
        item.listOrder = index + 1;
        return item;
      });
      const payload = itemsWithorder;
      this.updateTemplates(payload);
    },
    onAddTemplateClick(selectedTemplates) {
      try {
        let count = this.lastOrder + 1;
        selectedTemplates.forEach(async element => {
          await this.createConversationTemplateGroupMembership({
            key: this.state.key,
            templateKey: element.noticeTemplateKey,
            listOrder: count++
          });
        });
        this.$events.$emit('toastEvent', 'Conversation Templates Added');
        this.dialog.addTemplate = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    changeDetection(templateItem) {
      if (
        !templateItem.membershipMeta.estimatedDays ||
        templateItem.membershipMeta.estimatedDays.length === 0
      ) {
        return;
      }
      this.updateTemplates([templateItem]);
    },
    async updateTemplates(payload) {
      try {
        await this.updateConversationTemplateGroupMembership({
          key: this.state.key,
          payload
        });
        this.$events.$emit('toastEvent', 'Templates updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    removeTemplate(conversationTemplateGroupKey, membershipKey) {
      const isConfirm = confirm(
        'Remove this Conversation Template from this Group?'
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.deleteConversationTemplateGroupMembership({
          key: conversationTemplateGroupKey,
          membershipKey
        });
        this.$events.$emit('toastEvent', 'Conversation Template Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    goToTemplate(key) {
      this.$router.push({
        name: 'adminNoticeTemplate',
        params: {
          noticeTemplateKey: key
        }
      });
    },
    async addTemplate() {
      this.dialog.addTemplate = true;
      this.addTemplateClicked = true;
      await this.getConversationTemplates();
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            vnode.child.$emit('sorted', event);
          }
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      }
    }
  }
};
</script>

<style scoped>
.grab {
  cursor: grab;
}
.row {
  display: flex;
  align-items: center;
}
.right {
  margin-left: auto;
}
</style>
