import http from './requestType';

/**
 * Get attendance snapshots for a meeting
 * @param {String} shortCode  - The meeting shortCode
 * @return {Promise}          - Promise
 */
export function getSnapshots(shortCode) {
  return http._get(`Meetings/${shortCode}/attendance-snapshots`);
}

/**
 * Post an attendance snapshot for a meeting
 * @param {String} shortCode  - The meeting shortCode
 * @return {Promise}          - Promise
 */
export function postSnapshot(shortCode) {
  return http._post(`Meetings/${shortCode}/attendance-snapshots`);
}

/**
 * Update label for an attendance snapshot of a meeting
 * @param  {String} shortCode   - the meeting shortcode
 * @param  {String} key         - snapshot key
 * @param  {String} label       - snapshot label
 * @return {Promise}          Promise
 */
export function updateSnapshot({ shortCode, key, label }) {
  return http._put(`Meetings/${shortCode}/attendance-snapshots/${key}`, {
    label
  });
}

/**
 * Delete a snapshot
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  key          - snapshot key
 * @return {Promise}              - Promise
 */
export function deleteSnapshot(shortCode, key) {
  return http._delete(`Meetings/${shortCode}/attendance-snapshots/${key}`);
}
