<template>
  <v-container>
    <v-layout>
      <div class="gq-cell--section-title">
        <span>Live Voting</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-tooltip :disabled="!disableRevocationToggle" top>
          <v-switch
            slot="activator"
            :label="revocationToggleText"
            :loading="dialog.revocations"
            v-model="revocationToggle"
            :disabled="disableRevocationToggle"
            @change="handleRevocationsToggle"
          />
          <div v-if="!allowsPortalRevocations">
            <p class="my-0">
              This meeting does not allow voters to revoke their proxies inside
              the Meeting Portal.
            </p>
          </div>
          <div v-else>
            <p class="my-0">Live voting has begun or has passed.</p>
            <p class="my-0">
              Voters can no longer revoke their proxies inside the Meeting
              Portal.
            </p>
          </div>
        </v-tooltip>
      </div>
    </v-layout>

    <v-toolbar dense color="grey lighten-4 elevation-1">
      <span class="meeting-stat"
        ><v-icon class="mr-1" color="blue">person_pin_circle</v-icon
        >{{ currentMeeting.stats.attendanceCount }} Units in Attendance</span
      >
      <span class="meeting-stat ml-3"
        ><v-icon class="mr-1" color="blue">tap_and_play</v-icon
        >{{ currentMeeting.stats.rtvPresence.numUnits }} Units Online</span
      >

      <v-spacer />

      <v-btn flat color="blue" @click="viewLiveVoteAnswers" dark>
        View Votes
      </v-btn>

      <v-menu offset-y>
        <v-btn slot="activator" light flat color="blue">
          Actions
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile
            :disabled="noActiveQuestions || collectRtvBallots"
            @click="dialog.extendAll = true"
          >
            <v-list-tile-action>
              <v-icon>alarm_add</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Extend Voting (All)</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile @click="addQuestion(true)">
            <v-list-tile-action>
              <v-icon>add_task</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Add Test Question</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>

      <v-btn class="primary" @click="addQuestion(false)" dark>
        <v-icon>add</v-icon>Custom Question
      </v-btn>
    </v-toolbar>

    <!-- Questions table -->
    <!-- Table sorted by start time, if one exists -->
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="questions"
      hide-actions
    >
      <template v-slot:items="props">
        <tr>
          <td
            class="pointer label-cell py-2"
            @click="editQuestion(props.item.id)"
          >
            <span>
              <v-chip v-if="props.item.isTest" class="ml-0">Test</v-chip>
              {{ props.item.label }}
            </span>
            <br />
            <span v-if="hasRestrictions(props.item)" class="caption fade">
              Limit voting to <b>{{ props.item | formatRestrictions }}</b>
            </span>
          </td>
          <td class="pointer nowrap" @click="editQuestion(props.item.id)">
            <v-tooltip bottom v-if="props.item.maxChoices === 1">
              <v-icon slot="activator" color="primary">person</v-icon>
              <span>Single Choice</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <v-icon slot="activator" color="primary">group_add</v-icon>
              <span>Multiple Choice</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isOwnerOccQuestion(props.item)">
              <v-icon slot="activator" color="deep-orange">home</v-icon>
              <span>Owner-Occupied</span>
            </v-tooltip>

            <v-tooltip bottom v-if="props.item.overrideProxy">
              <v-icon slot="activator" color="green">pending_actions</v-icon>
              <span>Allows proxy vote to be overridden</span>
            </v-tooltip>
          </td>
          <td class="pointer" @click="editQuestion(props.item.id)">
            {{ formatDateTime(props.item.startAt)
            }}<timezone-helper
              :date="props.item.startAt"
              :showIcon="Boolean(props.item.startAt)"
            />
          </td>
          <td class="pointer" @click="editQuestion(props.item.id)">
            {{ formatDateTime(props.item.endAt)
            }}<timezone-helper
              :date="props.item.endAt"
              :showIcon="Boolean(props.item.startAt)"
            />
          </td>
          <td class="pointer" @click="editQuestion(props.item.id)">
            <transition name="fade" mode="out-in" appear>
              <span :key="props.item.totalVotes">{{
                props.item.totalVotes ? props.item.totalVotes : 0
              }}</span>
            </transition>
          </td>
          <td class="pointer">
            <live-vote-button
              :question="props.item"
              :is-disabled="
                disableLiveVoteButton1 && disableLiveVoteButton2(props.item)
              "
              @start="openDialog(props.item, 'start')"
              @stop="openDialog(props.item, 'stop')"
              @closed="getMeetingRTVQuestions({ shortCode })"
            />
          </td>
          <td class="text-xs-left pointer">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-0" v-on="on" depressed fab small>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-tile
                  :disabled="!props.item.active"
                  @click="openDialog(props.item, 'extend')"
                >
                  <v-list-tile-avatar
                    ><v-icon>alarm_add</v-icon></v-list-tile-avatar
                  >
                  <v-list-tile-content>{{
                    collectRtvBallots ? 'Update Deadline' : 'Extend Voting'
                  }}</v-list-tile-content></v-list-tile
                >
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Dialog boxes -->
    <!-- Start / Extend Dialog -->
    <add-time-dialog
      v-model="dialog.add"
      :action="dialog.action"
      :question="dialog.question"
      :current-meeting="currentMeeting"
      @start="openVoting"
      @extend="extendVoting"
      @setDeadline="setQuestionDeadline"
      @updateDeadline="updateQuestionDeadline"
      @close="dialog.add = false"
    />

    <!-- Close Dialog -->
    <close-vote-dialog
      v-model="dialog.stop"
      :question="dialog.question"
      @stop="closeVoting"
      @close="dialog.stop = false"
    />

    <!-- Extend All Questions -->
    <extend-all-dialog
      v-model="dialog.extendAll"
      @add="extendAllQuestions"
      @close="dialog.extendAll = false"
    />

    <!-- Toggle Revocations -->
    <toggle-revocations-dialog
      v-model="dialog.revocations"
      @closeRevocations="closeRevocations"
      @cancel="cancelRevocationToggle"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import AddTimeDialog from '@/components/LiveVoteAddTimeDialog';
import CloseVoteDialog from '@/components/LiveVoteCloseDialog';
import LiveVoteButton from '@/components/LiveVoteButton';
import ExtendAllDialog from './MeetingRTVExtendAllDialog';
import ToggleRevocationsDialog from '@/components/dialogs/ToggleRevocationsDialog';

import TimezoneHelper from '@/components/TimezoneHelper';

import { formatRestrictions } from '@/filters';

export default {
  name: 'MeetingRTVQuestionsListView',
  components: {
    ExtendAllDialog,
    AddTimeDialog,
    CloseVoteDialog,
    LiveVoteButton,
    TimezoneHelper,
    ToggleRevocationsDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    rtvQuestionsArray: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  filters: { formatRestrictions },
  beforeRouteEnter(to, from, next) {
    // Always refresh data on entering component,
    // to ensure questions + voting periods are up-to-date
    next(vm => {
      const shortCode = vm.$route.params.shortcode;
      vm.getMeetingRTVQuestions({ shortCode });
    });
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      questions: [],
      hiddenAt: null,
      headers: [
        {
          text: 'Question',
          align: 'left',
          value: 'label',
          sortable: false
        },
        {
          text: 'Type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Start Time',
          align: 'left',
          value: 'startAt',
          sortable: false
        },
        {
          text: 'End Time',
          align: 'left',
          value: 'endAt',
          sortable: false
        },
        {
          text: 'Ballots Cast',
          align: 'left',
          value: '',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          sortable: false
        },
        {
          text: 'Options',
          align: 'left',
          sortable: false
        }
      ],
      dialog: {
        add: false,
        action: '',
        start: false,
        stop: false,
        extend: false,
        question: {},
        extendAll: false,
        revocations: false
      },
      revocationToggle: false
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('meetings/liveVotes', ['meetingRTVLiveVotes']),
    collectRtvBallots() {
      return this.currentMeeting.options?.collectRtvBallots;
    },
    noActiveQuestions() {
      const active = this.questions.filter(question => question.active);
      return active.length === 0 ? true : false;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    revocationToggleText() {
      if (!this.allowsPortalRevocations) {
        return 'Proxy Revocations: DISABLED FOR THIS MEETING';
      }
      if (this.revocationToggle) {
        return 'Proxy Revocations: OPEN';
      }
      return 'Proxy Revocations: CLOSED';
    },
    disableRevocationToggle() {
      // If meeting does not allow portal revocations, disable the toggle for all user types
      if (!this.allowsPortalRevocations) {
        return true;
      }

      // If user is admin, allow them to switch portal revocations open/closed indefinitely
      if (this.isAdmin) {
        return false;
      }

      // Otherwise, user is collaborator:
      // User can only CLOSE revocations (unless 0 live votes have been received OR there are no active questions)
      return (
        !this.revocationToggle &&
        (!_.isEmpty(this.meetingRTVLiveVotes) || !this.noActiveQuestions)
      );
    },
    allowsPortalRevocations() {
      return this.currentMeeting.options.allowPortalRevocations;
    },
    disableLiveVoteButton1() {
      return (
        this.allowsPortalRevocations &&
        !this.currentMeeting.portalRevocationsClosedAt
      );
    }
  },
  beforeMount() {
    // add listener here for page visibility
    document.addEventListener('visibilitychange', this.visibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilityChange);
  },
  created() {
    this.init();
    this.getRevocationToggleStatus();
  },
  watch: {
    rtvQuestionsArray() {
      this.init();
    },
    meetingRTVLiveVotes() {
      this.prepareQuestions();
      this.sortQuestions();
    },
    currentMeeting() {
      this.getRevocationToggleStatus();
    }
  },
  methods: {
    ...mapActions('meetings', ['toggleMeetingPortalRevocations']),
    ...mapActions('meetings/rtvQuestions', [
      'getMeetingRTVQuestions',
      'createMeetingRTVQuestion',
      'openVotingRTVQuestion',
      'closeVotingRTVQuestion',
      'extendVotingRTVQuestion'
    ]),
    ...mapActions('meetings/liveVotes', ['getMeetingRTVLiveVotes']),
    formatDateTime(date) {
      return date
        ? moment(date)
            .tz(this.meetingTimezone)
            .format('MMM D, h:mm A zz')
        : '-';
    },
    async init() {
      try {
        // Pull in up-to-date live votes
        await this.getMeetingRTVLiveVotes({ shortCode: this.shortCode });

        // then, prepare + sort data
        this.prepareQuestions();
        this.sortQuestions();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },

    getRevocationToggleStatus() {
      if (!this.allowsPortalRevocations) {
        this.revocationToggle = false;
      } else {
        const areRevocationsOpen = !this.currentMeeting
          .portalRevocationsClosedAt;
        this.revocationToggle = areRevocationsOpen;
      }
    },

    prepareQuestions() {
      // Prepare questions array, to populate table
      this.questions = this.rtvQuestionsArray.map(question => {
        // If question has received any live votes,
        // assign the total # of votes to `totalVotes` prop
        for (const key of Object.keys(this.meetingRTVLiveVotes)) {
          if (question.id === key) {
            question.totalVotes = this.meetingRTVLiveVotes[key].length;
          }
        }

        return question;
      });
    },
    sortQuestions() {
      // break Questions array into two separate arrays, active/inactive questions
      let active = this.questions.filter(question => question.active);
      let inactive = this.questions.filter(question => !question.active);

      // sort both questions arrays by which ones will expire first
      active.sort((a, b) => {
        const endDateA = new Date(a.endAt);
        const endDateB = new Date(b.endAt);
        return endDateA - endDateB;
      });
      inactive.sort((a, b) => {
        if (a.endAt && b.endAt) {
          const endDateA = new Date(a.endAt);
          const endDateB = new Date(b.endAt);
          return endDateA - endDateB;
        }
        return a.endAt ? -1 : b.endAt ? 1 : 0;
      });

      // put them back together
      this.questions = active.concat(inactive);
    },
    openDialog(question, dialogType) {
      switch (dialogType) {
        case 'start':
          this.dialog.action = 'start';
          this.dialog.question = question;
          this.dialog.add = true;
          break;
        case 'stop':
          this.dialog.stop = true;
          this.dialog.question = question;
          break;
        case 'extend':
          this.dialog.action = 'extend';
          this.dialog.question = question;
          this.dialog.add = true;
          break;
      }
    },
    hasRestrictions(question) {
      return !_.isEmpty(question.restrictions);
    },
    formatRestriction(question) {
      let restrictionString = '';
      let restrictions = question.restrictions;

      for (var i = 0; i < restrictions.length; i++) {
        if (i !== 0) {
          restrictionString += ' OR ';
        }
        const restriction = restrictions[i];

        const key = Object.keys(restriction)[0];
        restrictionString += `${key}: ${restriction[key]}`;
      }

      return restrictionString;
    },
    isOwnerOccQuestion(question) {
      // Question can be owner-occupied if the ownerOccupied field is true,
      // or if the {owner_occupied: true} custom restriction exists
      return (
        question.ownerOccupied ||
        (question.restrictions && question.restrictions.owner_occupied === true)
      );
    },
    async addQuestion(isTest) {
      try {
        if (isTest === true) {
          await this.createMeetingRTVQuestion({
            shortCode: this.shortCode,
            isTest,
            overrideProxy: this.currentMeeting.options?.collectRtvBallots
          });
        } else {
          await this.createMeetingRTVQuestion({
            shortCode: this.shortCode,
            overrideProxy: this.currentMeeting.options?.collectRtvBallots
          });
        }
        this.$events.$emit('toastEvent', 'Custom question added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    editQuestion(id) {
      this.$router.push({
        name: 'meetingRTVEditQuestion',
        params: {
          questionId: id,
          shortcode: this.shortCode
        }
      });
    },
    async openVoting(question, votingPeriod, notifyVoters) {
      try {
        question.actionLogTag = 'start';

        await this.openVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question: question,
          deadlineType: 'minutes',
          deadline: votingPeriod,
          notifyVoters
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
        this.sortQuestions();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async extendVoting(question, votingPeriod) {
      try {
        question.actionLogTag = 'extend';

        await this.extendVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question: question,
          time: votingPeriod
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
        this.sortQuestions();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async closeVoting(question) {
      try {
        question.actionLogTag = 'stop';
        question.active = false;

        await this.closeVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question: question
        });

        this.dialog.stop = false;
        this.dialog.question = {};
        this.sortQuestions();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    extendAllQuestions(time) {
      this.questions.forEach(question => {
        if (question.active) {
          question.actionLogTag = 'extend';
          this.extendVoting(question, time);
        }
      });
    },
    async setQuestionDeadline(question, ballotSubmissionDeadline) {
      try {
        question.actionLogTag = 'start';

        await this.openVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question,
          deadlineType: 'date',
          deadline: ballotSubmissionDeadline,
          notifyVoters: false
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateQuestionDeadline(question, ballotSubmissionDeadline) {
      try {
        // First, close voting on this question momentarily
        await this.closeVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question
        });

        // Second, open voting with the new deadline
        await this.openVotingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: question.key,
          question,
          deadlineType: 'date',
          deadline: ballotSubmissionDeadline,
          notifyVoters: false
        });

        this.dialog.add = false;
        this.dialog.action = '';
        this.dialog.question = {};
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    viewLiveVoteAnswers() {
      this.$router.push({
        name: 'meetingRTVLiveAnswersQuestions',
        params: {
          shortcode: this.$route.params.shortcode
        }
      });
    },
    async handleRevocationsToggle(value) {
      // Case 1: revocations are turned back on
      if (value) {
        await this.toggleMeetingPortalRevocations({
          shortCode: this.shortCode,
          revocationStatus: 'open'
        });

        this.$events.$emit('toastEvent', 'Revocations are OPEN');
      }
      // Case 2: revocations are being turned off
      // Display a confirmation dialog to user
      else {
        this.dialog.revocations = true;
      }
    },
    async closeRevocations() {
      try {
        await this.toggleMeetingPortalRevocations({
          shortCode: this.shortCode,
          revocationStatus: 'closed'
        });

        this.dialog.revocations = false;

        this.$events.$emit('toastEvent', 'Revocations are CLOSED');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    cancelRevocationToggle() {
      const areRevocationsOpen = !this.currentMeeting.portalRevocationsClosedAt;
      this.revocationToggle = areRevocationsOpen;
      this.dialog.revocations = false;
    },
    disableLiveVoteButton2(question) {
      if (!question.isTest) {
        return true;
      } else {
        return false;
      }
    },
    async visibilityChange() {
      if (document.hidden) {
        this.hiddenAt = new Date();
        return;
      }

      // calculate how much time the page has been hidden in seconds
      const differenceInSeconds = moment().diff(
        moment(this.hiddenAt),
        'seconds'
      );

      // if tab has been hidden for more than 60 seconds and there are active questions
      if (differenceInSeconds >= 60 && !this.noActiveQuestions) {
        // reload query to accurately reflect live votes and timers
        await this.getMeetingRTVQuestions({ shortCode: this.shortCode });
      }
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.label-cell {
  width: 350px;
}

.meeting-stat {
  display: inline-flex;
  align-items: center;
}

.fade {
  opacity: 0.74;
}
</style>
