<template>
  <v-dialog
    max-width="900px"
    :value="value"
    persistent
    @keydown.esc="emitClose"
    @input="emitInput"
  >
    <v-card>
      <v-card-title class="headline">
        {{ isDraft ? 'New Conversation' : '' }}
        {{ isCopy ? '(Copied)' : '' }}
        <v-alert
          :value="conversationStatus === 'sent'"
          outline
          color="blue"
          icon="check_circle"
          >This conversation has already been sent and can no longer be edited.
          <span v-if="noticeKey">
            Click
            <a class="font-weight-bold" target="_blank" :href="noticeUrl()">
              here</a
            >
            to see the related notice.</span
          >
        </v-alert>
        <v-alert
          :value="conversationStatus === 'scheduled'"
          outline
          color="deep-orange"
          icon="warning"
        >
          This conversation has been scheduled for sendout:
          {{
            state.scheduledAt
              | formatDateHumanReadableAlt(
                true,
                currentMeeting.meetingTimezone
              )
          }}<timezone-helper :date="state.scheduledAt" />
        </v-alert>
        <v-spacer />
        <div>
          <v-btn icon flat @click.stop="emitClose"
            ><v-icon>close</v-icon></v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-layout grid-list-md>
            <v-flex xs6 md6 lg6>
              <v-text-field
                label="From name"
                v-model="fromName"
                :disabled="!isDraft"
              />
            </v-flex>
            <v-flex xs6 md6 lg6>
              <v-combobox
                @keydown.native.space.prevent
                v-model="fromEmail"
                :items="fromEmails"
                :disabled="!isDraft"
                label="from:"
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeFrom()"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout grid-list-md>
            <v-flex xs6 md6 lg6>
              <v-combobox
                @keydown.native.space.prevent
                v-model="toEmails"
                :items="emailDefaults"
                label="to:"
                :disabled="!isDraft"
                multiple
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeTo(data.item)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs6 md6 lg6>
              <v-combobox
                @keydown.native.space.prevent
                v-model="ccEmails"
                :items="emailDefaults"
                :disabled="!isDraft"
                label="cc:"
                multiple
                small-chips
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeCC(data.item)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout grid-list-md>
            <v-flex xs6 md6 lg6>
              <v-combobox
                @keydown.native.space.prevent
                v-model="bccEmails"
                :items="emailDefaults"
                :disabled="!isDraft"
                label="bcc:"
                multiple
                small-chips
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    small
                    close
                    :selected="data.selected"
                    @input="removeBCC(data.item)"
                    >{{ data.item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-text-field
            label="Purpose"
            v-model="purpose"
            :disabled="!isDraft"
          />
          <v-text-field
            label="Subject line"
            v-model="subject"
            :disabled="!isDraft"
          />
        </div>

        <rich-text-editor
          :key="richTextEditorKey"
          :body="emailMessage"
          :editable="isDraft"
          :show-custom-fields="true"
          custom-label="Custom
            fields defined in settings"
          :custom-fields="customFields"
          @update="handleUpdateEmailText('emailMessage', $event)"
        />

        <div class="preview-container">
          <v-tooltip top :disabled="!isPreviewDisabled">
            <template slot="activator">
              <v-btn
                flat
                @click.native="getPreview()"
                :disabled="isPreviewDisabled"
                :loading="dialog.loadingPreview"
                ><v-icon left>remove_red_eye</v-icon>Preview
              </v-btn>
            </template>

            <span>Save changes to preview</span>
          </v-tooltip>
        </div>

        <v-spacer />
        <meeting-conversation-file-attachments
          class="mb-4"
          :attachments="state.attachments"
          :is-disabled="!isDraft"
          :options="state.options"
          @update="handleUpdate"
        />

        <input-send-date
          :send-on="state.scheduledAt"
          :meeting-timezone="currentMeeting.meetingTimezone"
          @update="handleUpdate"
          :is-disabled="!isDraft"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="state.key && !isCopy"
          color="pink"
          flat
          @click.native="deleteConversation"
          :disabled="!isDraft"
        >
          <v-icon>delete_forever</v-icon>Delete Conversation
        </v-btn>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Cancel</v-btn>
        <v-btn
          class="error"
          :disabled="!isDraft || isNotSavable"
          @click.native="saveDraftAction"
        >
          <v-icon left>save</v-icon>Save as draft
        </v-btn>
        <v-btn
          color="deep-orange"
          class="white--text"
          @click.native="handleConversationSchedule()"
          :disabled="
            isNotSavable || (!isDraft && !isScheduled) || !state.sendOn
          "
        >
          <span v-if="!isScheduled">
            <v-icon left>timer</v-icon>Schedule Email
          </span>
          <span v-if="isScheduled">
            <v-icon left>timer_off</v-icon>Unschedule Email
          </span>
        </v-btn>
        <v-btn
          class="primary"
          @click.native="handleConversationSend()"
          :disabled="!isDraft || isNotSavable"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>

    <schedule-dialog
      v-model="dialog.schedule"
      :state="state"
      :status="status"
      :conversation-status="conversationStatus"
      :meeting-timezone="currentMeeting.meetingTimezone"
      @scheduleConversation="scheduleConversation"
      @unscheduleConversation="unscheduleConversation"
      @return="returnToConversations"
      @close="resetStatus"
    />

    <send-confirm-dialog
      v-model="dialog.send"
      :state="state"
      :conversation-status="status"
      :status="status"
      :send-count="recipients.length"
      @send="sendAction"
      @return="returnToConversations"
      @close="resetStatus"
    />

    <preview-dialog
      v-model="dialog.preview"
      :subject="conversationPreview.subjectLineHtml"
      :html="conversationPreview.bodyHtml"
      :recipientCount="0"
      :recipientTotal="0"
      @close="dialog.preview = false"
      :recipientInfoHidden="true"
      containerStyles="px-4"
    />
  </v-dialog>
</template>

<script>
import * as filters from '@/filters';
import { mapActions, mapGetters } from 'vuex';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import MeetingConversationFileAttachments from './MeetingConversationFileAttachments';
import InputSendDate from '@/components/input/InputSendDate';
import ScheduleDialog from './MeetingConversationViewSchedule';
import SendConfirmDialog from './MeetingConversationViewSend';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';
import defaults from '@/lib/default-emails.js';
import TimezoneHelper from '@/components/TimezoneHelper';

import _ from 'lodash';
export default {
  name: 'MeetingConversationsNewDialog',

  filters: {
    ...filters
  },
  components: {
    RichTextEditor,
    MeetingConversationFileAttachments,
    InputSendDate,
    ScheduleDialog,
    SendConfirmDialog,
    TimezoneHelper,
    PreviewDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    currentConversation: {
      type: Object,
      default() {
        return {};
      }
    },
    isCopy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emailDefaults: defaults,
      fromEmails: ['support@getquorum.com'],
      toEmails: [],
      ccEmails: [],
      bccEmails: [],
      recipients: [],
      subject: this.currentConversation.subject,
      fromName: this.currentConversation.fromName,
      fromEmail: this.currentConversation.fromEmail || 'support@getquorum.com',
      emailMessage: this.currentConversation.body,
      purpose: this.currentConversation.name,
      dialog: {
        schedule: false,
        send: false,
        preview: false,
        loadingPreview: false
      },
      status: '',
      state: {},
      richTextEditorKey: 0,
      toBeSend: false
    };
  },
  computed: {
    ...mapGetters('meetings/conversations', ['conversationPreview']),
    isNotSavable() {
      return (
        !this.purpose ||
        !this.subject ||
        !this.fromEmail ||
        !this.fromName ||
        !this.emailMessage ||
        this.toEmails.length === 0
      );
    },
    conversationStatus() {
      if (this.state.status === 'sent' && !this.isCopy) {
        return 'sent';
      }
      if (this.state.status === 'scheduled' && !this.isCopy) {
        return 'scheduled';
      }
      return 'draft';
    },
    isDraft() {
      return this.conversationStatus === 'draft' ? true : false;
    },
    isScheduled() {
      return this.conversationStatus === 'scheduled' ? true : false;
    },
    isPreviewDisabled() {
      if (this.state.key) {
        return (
          !_.isEqual(_.trim(this.state.body), _.trim(this.emailMessage)) ||
          !_.isEqual(_.trim(this.state.subject), _.trim(this.subject))
        );
      }

      return true;
    },
    noticeKey() {
      return this.state.options && this.state.options.noticeKey
        ? this.state.options.noticeKey
        : null;
    },
    customFields() {
      const customUnitFields =
        this.currentMeeting.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    }
  },
  created() {
    this.setState();
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    this.emailDefaults.unshift(
      this.currentMeeting.contact.email,
      profile.email
    );

    this.fromEmails.unshift(this.currentMeeting.contact.email, profile.email);

    let firstName = _.get(profile, 'firstName', '');
    let lastName = _.get(profile, 'lastName', '');

    if (
      this.currentConversation.meta &&
      this.currentConversation.name.indexOf('Client Satisfaction Survey') >= 0
    ) {
      // Specific only for surveys
      this.fromName = `The GetQuorum Team`;
    } else {
      this.fromName = `${firstName} ${lastName}, GetQuorum`;
    }

    if (this.currentConversation.meta) {
      if (this.currentConversation.meta.templateType.indexOf('vm') >= 0)
        this.fromEmail = 'support@getquorum.com';

      // for all template type "other" the default FROM email will be the admi user email
      if (this.currentConversation.meta.templateType.indexOf('other') >= 0)
        this.fromEmail = profile.email;
    }

    if (this.state.recipients) {
      this.state.recipients.forEach(r => {
        if (r.type === 'to') {
          this.toEmails.push(r.email);
        } else if (r.type === 'cc') {
          this.ccEmails.push(r.email);
        } else {
          this.bccEmails.push(r.email);
        }
      });
    } else {
      if (this.toEmails.length === 0) {
        this.toEmails = [this.currentMeeting.contact.email];

        if (this.currentConversation.meta) {
          this.bccEmails = ['support@getquorum.com'];
        }
      }
    }

    const meta = this.currentConversation.meta;
    if (meta) {
      if (meta.hasOwnProperty('defaultCcEmail')) {
        this.ccEmails = meta.defaultCcEmail === '' ? [] : [meta.defaultCcEmail];
      }

      if (meta.hasOwnProperty('defaultBccEmail')) {
        this.bccEmails =
          meta.defaultBccEmail === '' ? [] : [meta.defaultBccEmail];
      }
    }
  },
  methods: {
    ...mapActions('meetings/conversations', [
      'addConversation',
      'updateMeetingConversation',
      'deleteMeetingConversation',
      'getMeetingConversationPreview'
    ]),
    setState() {
      this.state = _.cloneDeep(this.currentConversation);
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    removeFrom() {
      this.fromEmail = '';
    },
    removeTo(item) {
      this.toEmails.splice(this.toEmails.indexOf(item), 1);
      this.toEmails = [...this.toEmails];
    },
    removeCC(item) {
      this.ccEmails.splice(this.ccEmails.indexOf(item), 1);
      this.ccEmails = [...this.ccEmails];
    },
    removeBCC(item) {
      this.bccEmails.splice(this.bccEmails.indexOf(item), 1);
      this.bccEmails = [...this.bccEmails];
    },
    handleUpdateEmailText(key, value) {
      this.$set(this, key, value);
    },
    handleUpdate(obj) {
      const newState = { ...this.state };
      _.set(newState, obj.key, obj.value);
      this.$set(this, 'state', newState);
    },
    saveDraftAction() {
      this.state.status = 'draft';
      this.setConversationValues();
      this.sendConversation(false);
    },
    sendAction() {
      this.state.status = 'sent';
      this.sendConversation(true);
    },
    processEmails(type, array) {
      array.forEach(email => {
        this.recipients.push({
          email,
          type
        });
      });
    },
    handleConversationSchedule() {
      this.setConversationValues();
      this.dialog.schedule = true;
    },
    handleConversationSend() {
      this.setConversationValues();
      this.dialog.send = true;
    },
    setConversationValues() {
      this.state.subject = this.subject;
      this.state.purpose = this.purpose;
      this.processEmails('to', this.toEmails);
      this.processEmails('cc', this.ccEmails);
      this.processEmails('bcc', this.bccEmails);
    },
    async sendConversation(isSend, removeSendOn = false) {
      const payload = {
        name: this.purpose,
        subject: this.subject,
        body: this.emailMessage,
        fromName: this.fromName,
        fromEmail: this.fromEmail,
        attachments: this.state.attachments ? this.state.attachments : [],
        recipients: this.recipients,
        scheduledAt: !isSend && !removeSendOn ? this.state.sendOn : null,
        sentAt: isSend ? Date.now() : null,
        status: this.state.status
      };
      let result = {};
      try {
        this.status = 'sending';
        if ((this.state.key && !this.isCopy) || this.toBeSend) {
          payload.options = this.state.options;
          result = await this.updateMeetingConversation({
            conversationKey: this.state.key,
            shortCode: this.currentMeeting.shortCode,
            payload
          });
        } else {
          // Set the replyTo option on creation.
          payload.options = {
            replyTo: 'support@getquorum.com'
          };
          result = await this.addConversation({
            shortCode: this.currentMeeting.shortCode,
            payload
          });
          // indicator to control when the conversion is already created and ready to be sent
          this.toBeSend = true;
        }
        this.state = result.data;
        this.state.sendOn = result.data.scheduledAt;
        this.recipients = [];
        if (payload.scheduledAt && payload.status === 'scheduled') {
          this.status = 'justScheduled';
          return;
        }
        if (payload.sentAt) {
          this.status = 'sent';
          return;
        }
        this.status = 'draft';
        this.$emit('close');
        this.$events.$emit('toastEvent', 'Conversation Saved');
      } catch (err) {
        this.status = '';
        this.$events.$emit('showErrorDialog', err.response);
        console.error(err);
        throw err;
      }
    },
    async deleteConversation() {
      const isConfirm = confirm(
        'Do you want to permanently delete this conversation?\n\nYou will not be able to undo it once deleted.'
      );
      if (!isConfirm) {
        return;
      }
      try {
        this.deleteMeetingConversation({
          shortCode: this.currentMeeting.shortCode,
          conversationKey: this.state.key
        });
        this.$events.$emit('toastEvent', 'Conversation Deleted');
      } catch (err) {
        return this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.resetStatus();
        this.$emit('close');
      }
    },

    scheduleConversation() {
      this.state.status = 'scheduled';
      this.sendConversation();
    },

    async unscheduleConversation() {
      try {
        this.state.status = 'draft';
        await this.sendConversation(false);
        this.$events.$emit('toastEvent', 'Conversation Unscheduled');
        this.forceRichTextEditorRerender();
        this.resetStatus();
      } catch (err) {
        console.error(err);
        this.$events.$emit('toastEvent', 'Error updating conversation');
      }
    },
    resetStatus() {
      this.dialog.schedule = false;
      this.dialog.send = false;
      this.recipients = [];
      this.status = '';
    },
    returnToConversations() {
      this.resetStatus();
      this.$emit('close');
    },
    noticeUrl() {
      let baseurl =
        process.env.NODE_ENV === 'production'
          ? `https://dashboard.getquorum.com`
          : 'http://localhost:8080';

      return `${baseurl}/meetings/${this.currentMeeting.shortCode}/notices/${this.noticeKey}/edit`;
    },
    forceRichTextEditorRerender() {
      this.richTextEditorKey += 1;
    },
    async getPreview() {
      this.dialog.loadingPreview = true;
      try {
        await this.getMeetingConversationPreview({
          shortCode: this.currentMeeting.shortCode,
          conversationKey: this.state.key
        });

        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.loadingPreview = false;
      }
    }
  }
};
</script>

<style scoped>
.preview-container {
  text-align: right;
}
</style>
