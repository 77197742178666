<template>
  <v-card>
    <v-card-title>
      <div class="headline">Add New Agent</div>
    </v-card-title>
    <div class="pa-3">
      <v-flex xs12 md12>
        <v-form ref="agentForm">
          <v-text-field
            v-model="agentForm.name"
            :rules="[v => !!v || 'Agent name is required']"
            label="Agent name"
            hint="e.g Brookfield, Del"
          />
          <v-autocomplete
            v-model="agentForm.agentType"
            :items="agentTypeItems"
            label="Agent Type"
          ></v-autocomplete>
          <v-checkbox
            label="Partner Type"
            color="primary"
            v-model="agentForm.partnerType"
          />
        </v-form>
      </v-flex>
    </div>
    <v-card-actions>
      <v-btn color="primary" flat outline @click.native="emitCloseDialog"
        >Cancel</v-btn
      >
      <v-spacer />
      <v-btn color="primary" @click.native="emitAddAgent">
        <v-icon>add</v-icon>Add Agent
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AddAgentDialog',
  data() {
    return {
      agentTypeItems: ['law-firm', 'management-firm', 'pm-firm', 'association'],
      agentForm: {
        name: '',
        notes: '',
        agentType: '',
        partnerType: ''
      }
    };
  },
  methods: {
    emitCloseDialog() {
      this.$emit('closeDialog');
      this.$refs.agentForm.reset();
    },
    emitAddAgent() {
      if (this.$refs.agentForm.validate()) {
        const payload = {
          name: this.agentForm.name,
          notes: '',
          agentType: this.agentForm.agentType || '',
          partnerType: this.agentForm.partnerType ? 'partner' : ''
        };
        this.$emit('emitAddAgent', payload);
        this.$refs.agentForm.reset();
      }
    }
  }
};
</script>
