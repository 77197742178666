'use strict';

/**
 * @fileoverview Vuex module for top level checklists
 */

import { checklists } from '@/api';

import _ from 'lodash';

const state = {
  stages: [],
  assignees: []
};

const getters = {
  checklistStages: state => state.stages.sort(),
  checklistAssignees: state => state.assignees
};

const actions = {
  async getChecklistAssignees({ commit }) {
    try {
      const res = await checklists.getChecklistAssignees();

      // Filter all assignees down to just CS/VM team members
      const assignees = res.data.filter(
        a => a.data.team === 'cs' || a.data.team === 'vm'
      );

      commit('SET_ASSIGNEES', assignees);
    } catch (err) {
      throw err;
    }
  },
  async getChecklistStages({ commit }) {
    try {
      const res = await checklists.getChecklistStages();
      commit('SET_STAGES', res.data.stages);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_STAGES(state, stages) {
    state.stages = ['meeting', ...stages];
  },
  SET_ASSIGNEES(state, assignees) {
    state.assignees = assignees;
  },
  CLEAR_STATE(state) {
    state.stages = [];
    state.assignees = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
