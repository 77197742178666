<template>
  <v-dialog max-width="500px" :value="value" @input="emitInput">
    <v-card>
      <v-card-text>
        <span class="title">Update ({{ selected.length }}) unit addresses to:</span>
        <v-select single-line label="Address" :items="addresses" v-model="address"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="primary" @click.native="emitUpdate">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'MeetingUnitsAddressesUpdateDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    },
    addresses: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      address: ''
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitUpdate() {
      this.$emit('update', this.address);
    }
  }
};
</script>

<style>
</style>
