<template>
  <div>

    <agreement-card 
      v-for="(agreement) in agreements" 
      :key="agreement.key"      
      :agreement="agreement"
      @delete-file="deleteFile"
      @delete-agreement="deleteAgreement"
      @edit-clicked="editClicked" />

  </div>
</template>

<script>
import * as filters from '@/filters';
import AgreementCard from './AgreementCard';

export default {
  name: 'AdminAccountAgreementsViewList',
  components: {
    AgreementCard
  },
  props: {
    agreements: {
      type: Array,
      required: true
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      config: {
        rowsPerPageItems: [100],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      },
      headers: [
        {text: 'Created', value: 'createdAt', align: 'left'},
        {text: 'Type', value: 'type', align: 'left', sortable: false},
        {
          text: 'Description',
          value: 'description',
          align: 'left',
          sortable: false
        },
        {text: 'Signed', value: 'signedAt', align: 'left', sortable: false},
        {text: 'Expires', value: 'expiresAt', align: 'left', sortable: false}
      ]
    };
  },
  methods: {
    editClicked(agreement) {
      return this.$emit('edit-agreement', agreement);
    },
    deleteFile(file) {
      return this.$emit('delete-file', file);
    },
    deleteAgreement(agreement) {
      return this.$emit('delete-agreement', agreement);
    }
  }
};
</script>


<style scoped>
.item {
  display: block;
}
</style>
