'use strict';
import checkRouteScopes from '@/lib/check-route-scopes';

/** @fileoverview Maps the meeting routes into format useable by the meeting nav bar */

/**
 * @typedef {Object} MeetingNavRoute
 * @property {string}  name        - the route name
 * @property {string}  label       - the label to display on nav tab
 * @property {boolean} [adminOnly] - if true, the route is only visble by admins
 */

/**
 * Function to filter the meeting routes that should appear on the Meeting page navbar
 * @param {Object[]} routes    - the meeting routes
 * @return {MeetingNavRoute[]}
 */
export function mapMeetingRoutes({ routes }) {
  if (!routes) {
    throw new Error('Invalid Meeting Routes');
  }

  const meetingMain = routes.filter(route => route.name === 'meetingMain');

  if (!meetingMain || meetingMain.length <= 0) {
    throw new Error('Could not find meetingMain route');
  }

  const meetingChildren = meetingMain[0].children;

  if (!meetingChildren) {
    throw new Error('Could not find meetingMain children');
  }

  // Map the output
  return meetingChildren.map(child => {
    // If this child has more children, then the first element contains the
    // route name we want.
    if (child.children && child.children.length > 0) {
      return {
        name: child.children[0].name,
        path: child.path,
        label: child.meta.navLabel,
        isNestedNavLabel: child.meta.isNestedNavLabel,
        icon: child.meta.icon,
        adminOnly: child.meta.adminOnly,
        isAdminNavCategory: child.meta.isAdminNavCategory,
        workInProgress: child.meta.workInProgress,
        hidden: child.meta.hidden,
        scope: child.meta.scope,
        isExperimental: child.meta.isExperimental || false
      };
    }

    // Otherwise there are no additional children on this route
    return {
      name: child.name,
      path: child.path,
      label: child.meta.navLabel,
      isNestedNavLabel: child.meta.isNestedNavLabel,
      icon: child.meta.icon,
      adminOnly: child.meta.adminOnly,
      isAdminNavCategory: child.meta.isAdminNavCategory,
      workInProgress: child.meta.workInProgress,
      hidden: child.meta.hidden,
      scope: child.meta.scope,
      isExperimental: child.meta.isExperimental || false
    };
  });
}

/**
 * Filter out routes based on admin status and route scopes
 *
 * @param {MeetingNavRoute[]} routes - array of meeting nav routes
 * @param {String}   shortCode       - meeting shortCode
 * @param {Array}    scopes          - array of scope names
 * @param {Boolean}  isAdmin         - account admin status
 * @returns {MeetingNavRoute[]}      - array of filtered routes
 */
export function filterRoutes({ routes, shortCode, scopes, isAdmin }) {
  // Filter hidden routes
  let filteredRoutes = routes.filter(route => !route.hidden);

  // If the user is admin, return all routes
  if (isAdmin) {
    return filteredRoutes;
  } else {
    // If user is not an admin, filter out admin only routes
    filteredRoutes = filteredRoutes.filter(route => !route.adminOnly);

    // Filter out routes which user does not have access to
    filteredRoutes = checkRouteScopes(shortCode, filteredRoutes, scopes);

    return filteredRoutes;
  }
}
