<template>
  <div>
    <div class="title mb-2">Accounts</div>
    <v-toolbar dense color="elevation-1">
      <v-text-field
        class="mb-2"
        light
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        v-model="search"
      />
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="accounts"
      :search="search"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="config.pagination"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{ props.item.account.nameShort }}</td>
        <td class="text-xs-left">{{ props.item.account.name }}</td>
        <td class="text-xs-left">
          {{ props.item.createdAt | formatDateHumanReadable(false) }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as filters from '@/filters';
export default {
  name: 'AdminAgentViewAccountsTable',
  props: {
    accounts: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    ...filters
  },
  data() {
    return {
      agentKey: this.$route.params.agentKey,
      search: '',
      headers: [
        { text: 'Account', value: 'account.nameShort', align: 'left' },
        { text: 'Name', value: 'user.name', align: 'left' },
        { text: 'Added', value: 'createdAt', align: 'left' },
        { text: '', value: '', align: 'left', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      }
    };
  }
};
</script>

<style scoped></style>
