<template>
  <v-container v-if="lastDataFetched" grid-list-md fluid>
    <v-layout row class="ma-3">
      <v-flex xs12>
        <p class="title">Edit: {{ currentChecklistTemplate.name }}</p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ml-3 mr-3">
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field
                name="name"
                label="Checklist Name"
                placeholder="AGM/SGM Fulfilment Checklist"
                :value="state.name"
                @input="handleUpdate('name', $event)"
              />

              <v-text-field
                name="description"
                label="Description of the Checklist"
                placeholder="Use this checklist for all general meetings"
                :value="state.description"
                @input="handleUpdate('description', $event)"
              />

              <v-select
                :items="checklistStages"
                item-text="label"
                item-value="stage"
                label="Checklist Type"
                required
                :value="state.stage"
                @input="handleUpdate('stage', $event)"
              />

              <span class="caption grey--text text--darken-2"
                >Checklist Items
              </span>
              <code-mirror
                :code-array="codemirrorList"
                @update="handleChecklistUpdate"
              />
              <span class="caption grey--text text--darken-2">
                Note: append a <code>[p]</code> tag to the end of any line to
                designate it as a printing type item. Printing type items are
                specially noted in the checklist.
              </span>

              <v-divider class="my-2" />

              <!-- Checklist Deadline (GetQuorum Due Date) -->
              <v-layout column>
                <v-flex>
                  <span class="caption grey--text text--darken-2">
                    Checklist Deadline:
                    <v-tooltip right>
                      <span slot="activator">
                        <v-icon color="primary" small>info</v-icon>
                      </span>
                      <span>
                        Use this section to select a default "Due At" date for
                        this checklist template.
                      </span>
                    </v-tooltip>
                  </span>
                  <v-radio-group v-model="deadline.date" class="mt-0" column>
                    <v-radio label="Before Meeting" value="before" />
                    <v-radio label="After Meeting" value="after" />
                    <v-radio label="Day of Meeting" value="dayOf" />
                    <v-radio label="Today (Current Date)" value="today" />
                  </v-radio-group>
                  <v-select
                    v-if="
                      deadline.date === 'before' || deadline.date === 'after'
                    "
                    :items="daysArray"
                    :label="`Number of days ${deadline.date} meeting`"
                    required
                    v-model="deadline.days"
                    dense
                  />
                </v-flex>
              </v-layout>

              <!-- Legal Checklist Deadline (Legal Due Date) -->
              <v-layout column>
                <v-flex>
                  <span class="caption grey--text text--darken-2">
                    Legal Checklist Deadline:
                    <v-tooltip right>
                      <span slot="activator">
                        <v-icon color="primary" small>info</v-icon>
                      </span>
                      <span>
                        If this checklist template has a legal deadline (i.e.,
                        Notice of Service), input the legal deadline here.
                      </span>
                    </v-tooltip>
                  </span>
                  <v-radio-group
                    v-model="legalDeadline.date"
                    class="mt-0"
                    column
                  >
                    <v-radio label="Before Meeting" value="before" />
                    <v-radio label="After Meeting" value="after" />
                    <v-radio label="Day of Meeting" value="dayOf" />
                    <v-radio label="No Legal Deadline" value="none" />
                  </v-radio-group>
                  <v-select
                    v-if="
                      legalDeadline.date === 'before' ||
                        legalDeadline.date === 'after'
                    "
                    :items="daysArray"
                    :label="`Number of days ${legalDeadline.date} meeting`"
                    required
                    v-model="legalDeadline.days"
                    dense
                  />
                </v-flex>
              </v-layout>
            </v-form>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <v-btn flat class="accent--text" @click="dialog.delete = true"
              ><v-icon>delete_forever</v-icon> Delete Checklist</v-btn
            >
            <v-spacer />
            <v-btn
              class="accent"
              @click="updateChecklist"
              :disabled="isDisabled"
              ><v-icon left>save</v-icon>Save</v-btn
            >
          </v-card-actions>
        </v-card>

        <!-- Dialogs -->
        <v-dialog v-model="dialog.delete" max-width="250px">
          <v-card>
            <v-card-title class="title">Confirm Delete?</v-card-title>
            <v-card-text
              >Are you sure you want to delete this checklist?</v-card-text
            >
            <v-card-actions>
              <v-spacer />
              <v-btn flat @click.native="dialog.delete = false">Cancel</v-btn>
              <v-btn class="accent" @click.native="deleteChecklist"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';

import { mapGetters, mapActions } from 'vuex';

import CodeMirror from '@/components/CodeMirror';
import checklistDefault from '@/lib/default-checklist';

export default {
  name: 'AdminChecklistTemplateView',
  components: {
    CodeMirror
  },
  props: {
    checklistTemplatesList: {
      type: Object,
      required: true
    },
    checklistTemplatesData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  data() {
    return {
      checklistTemplateId: this.$route.params.checklistTemplateId,
      codemirrorList: [],
      state: {
        name: '',
        description: '',
        stage: '',
        items: []
      },
      daysArray: [],
      deadline: {
        days: null,
        date: ''
      },
      legalDeadline: {
        days: null,
        date: ''
      },
      dialog: {
        delete: false
      }
    };
  },
  computed: {
    ...mapGetters('checklistTemplates', ['checklistTemplateById']),
    ...mapGetters('checklists', ['checklistStages']),
    currentChecklistTemplate() {
      return this.checklistTemplateById(this.checklistTemplateId) || {};
    },
    isChanged() {
      return !_.isEqual(this.state, this.currentChecklistTemplate);
    },
    isDisabled() {
      // If the deadline is 'before' or 'after' the meeting date, and
      // a number of days has not been selected, disable the SAVE button
      if (
        (this.deadline.date === 'before' || this.deadline.date === 'after') &&
        this.deadline.days === null
      ) {
        return true;
      }

      // Same goes for the legal deadline
      if (
        (this.legalDeadline.date === 'before' ||
          this.legalDeadline.date === 'after') &&
        this.legalDeadline.days === null
      ) {
        return true;
      }

      return false;
    }
  },
  watch: {
    currentChecklistTemplate: function(val) {
      this.setState(val);
    }
  },
  created() {
    this.getChecklistStages();

    // Fill the "daysArray" with 365 days to choose from
    for (let i = 1; i <= 365; i++) {
      this.daysArray.push(i);
    }
  },
  mounted() {
    this.setState(this.currentChecklistTemplate);
  },
  methods: {
    ...mapActions('checklistTemplates', [
      'removeChecklistTemplate',
      'updateChecklistTemplate'
    ]),
    ...mapActions('checklists', ['getChecklistStages']),
    async deleteChecklist() {
      try {
        await this.removeChecklistTemplate(this.checklistTemplateId);
        this.$events.$emit('toastEvent', 'Checklist Deleted');
        this.dialog.delete = false;
        this.$router.push({
          name: 'adminChecklistTemplatesOverview'
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateChecklist() {
      try {
        // Ensure the payload has the cleaned items array
        let items = this.codemirrorList.map(function(item, index) {
          return {
            label: item.replace('[p]', ''),
            printType: item.includes('[p]')
          };
        });

        // Ensure the deadline (number of days this checklist
        // is due before/after meeting) is correct
        let deadline = null;
        if (this.deadline.date) {
          if (this.deadline.date === 'before') {
            deadline = this.deadline.days;
          } else if (this.deadline.date === 'after') {
            deadline = -Math.abs(this.deadline.days);
          } else if (this.deadline.date === 'dayOf') {
            deadline = 0;
          }
        }

        // Same goes for the legal deadline
        let legalDeadline = null;
        if (this.legalDeadline.date) {
          if (this.legalDeadline.date === 'before') {
            legalDeadline = this.legalDeadline.days;
          } else if (this.legalDeadline.date === 'after') {
            legalDeadline = -Math.abs(this.legalDeadline.days);
          } else if (this.legalDeadline.date === 'dayOf') {
            legalDeadline = 0;
          }
        }

        // Add the deadline and legal deadline to the payload,
        // if they exist
        let payload = { ...this.state, items };
        if (deadline !== null) {
          payload = { ...payload, deadline };
        }
        if (legalDeadline !== null) {
          payload = { ...payload, legalDeadline };
        }

        await this.updateChecklistTemplate({
          payload,
          id: this.checklistTemplateId
        });
        this.$events.$emit('toastEvent', 'Checklist Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleChecklistUpdate(array) {
      this.codemirrorList = array;
    },
    handleUpdate(key, value) {
      const newState = { ...this.state };
      // Lodash search state object for matching key
      _.set(newState, key, value);
      this.$set(this, 'state', newState);
    },
    setState(currentChecklistTemplate) {
      const stateObj = _.cloneDeep(currentChecklistTemplate);
      this.state = _.omit(stateObj, ['deadline', 'legalDeadline']);

      // Set state of checklist deadline
      if (currentChecklistTemplate.deadline) {
        this.deadline.days = Math.abs(currentChecklistTemplate.deadline);
        if (currentChecklistTemplate.deadline > 0) {
          this.deadline.date = 'before';
        } else if (parseInt(currentChecklistTemplate.deadline) === 0) {
          this.deadline.date = 'dayOf';
        } else if (currentChecklistTemplate.deadline < 0) {
          this.deadline.date = 'after';
        } else {
          this.deadline.date = 'today';
        }
      } else {
        this.deadline.date = 'today';
      }

      // Set state of checklist legal deadline, if one exists
      if (currentChecklistTemplate.legalDeadline) {
        this.legalDeadline.days = Math.abs(
          currentChecklistTemplate.legalDeadline
        );
        if (currentChecklistTemplate.legalDeadline > 0) {
          this.legalDeadline.date = 'before';
        } else if (parseInt(currentChecklistTemplate.legalDeadline) === 0) {
          this.legalDeadline.date = 'dayOf';
        } else if (currentChecklistTemplate.legalDeadline < 0) {
          this.legalDeadline.date = 'after';
        } else {
          this.legalDeadline.date = 'none';
        }
      } else {
        this.legalDeadline.date = 'none';
      }

      // Turn into array of strings
      let codemirrorList = currentChecklistTemplate.items.map(function(
        item,
        index
      ) {
        return !item.printType ? item.label : `${item.label}[p]`;
      });

      this.codemirrorList = codemirrorList;
    }
  }
};
</script>

<style scoped></style>
