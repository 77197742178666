<template>
  <meeting-overview-card
    icon="subject"
    title="Customer Info"
    v-slot="{ edit }"
    :saving="saving"
    @save="onSave"
  >
    <v-layout row>
      <v-flex xs2>Bill To:</v-flex>
      <v-flex xs10>
        <p v-if="!edit" class="mb-0">
          {{ billingSettings.billTo }}
        </p>
        <v-text-field
          class="mt-0 pt-0"
          v-if="edit"
          clearable
          placeholder="Billing To"
          :value="billingSettings.billTo"
          @input="handleUpdate('billTo', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs2>Contact:</v-flex>
      <v-flex xs10>
        <p v-if="!edit" class="mb-0">
          {{ billingSettings.billingContact }}
        </p>
        <v-text-field
          class="mt-0 pt-0"
          v-if="edit"
          clearable
          placeholder="Billing name"
          :value="billingSettings.billingContact"
          @input="handleUpdate('billingContact', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs2>Send To:</v-flex>
      <v-flex xs10>
        <p v-if="!edit" class="mb-0">
          {{ billingSettings.billingEmail }}
        </p>
        <v-text-field
          class="mt-0 pt-0"
          v-if="edit"
          clearable
          placeholder="billing@example.com"
          :value="billingSettings.billingEmail"
          @input="handleUpdate('billingEmail', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs2>PO #: </v-flex>
      <v-flex xs10>
        <p v-if="!edit" class="mb-0">
          {{ billingSettings.purchaseOrder || 'No purchase order' }}
        </p>
        <v-text-field
          class="mt-0 pt-0"
          v-if="edit"
          clearable
          placeholder="PO #0000"
          :value="billingSettings.purchaseOrder"
          @input="handleUpdate('purchaseOrder', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs2>Agent: </v-flex>
      <v-flex xs10>
        <p class="mb-0" v-if="agent">
          {{ agent.name }}
          <span v-if="agent.discount"> ({{ agent.discount }}% discount)</span>
        </p>
        <p class="mb-0" v-else>
          No agent
        </p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs2>Preferred method: </v-flex>
      <v-flex xs10>
        <p class="mb-0" v-if="preferredMethod">
          {{ preferredMethod }}
        </p>
      </v-flex>
    </v-layout>
  </meeting-overview-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';

export default {
  name: 'CustomerCard',
  components: {
    MeetingOverviewCard
  },
  data() {
    return {
      saving: false,
      customer: {
        billTo: '',
        billingContact: '',
        billingEmail: '',
        purchaseOrder: ''
      }
    };
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('meetings/billing', [
      'billingSettings',
      'tax',
      'discount',
      'agent'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    account() {
      return this.accountByKey(this.currentMeeting.accountKey);
    },
    preferredMethod() {
      const preferredMethod = this.account.data.billingContact?.preferredMethod;
      const otherMethod = this.account.data.billingContact?.otherMethod;

      if (preferredMethod === 'letter_mail') {
        return 'Letter Mail';
      } else if (preferredMethod === 'other') {
        return otherMethod;
      } else {
        return 'Email';
      }
    }
  },
  mounted() {
    this.setState();
  },
  watch: {
    // fills state with computed values
    billingSettings() {
      this.setState();
    }
  },
  methods: {
    ...mapActions('meetings/billing', ['updateBillingSettings']),
    handleUpdate(key, value) {
      // Lodash search state object for matching key
      _.set(this.customer, key, value);
    },
    setState() {
      this.customer.billTo = this.billingSettings.billTo;
      this.customer.billingContact = this.billingSettings.billingContact;
      this.customer.billingEmail = this.billingSettings.billingEmail;
      this.customer.purchaseOrder = this.billingSettings.purchaseOrder;
    },
    async onSave() {
      this.saving = true;
      try {
        const payload = {
          ...this.customer,
          enabled: this.billingSettings.enabled
        };
        await this.updateBillingSettings({
          shortCode: this.$route.params.shortcode,
          payload
        });
        this.$events.$emit('toastEvent', 'Billing customer updated');
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.saving = false;
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
