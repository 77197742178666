<template>
  <v-dialog persistent :value="value" max-width="550px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <div class="title">Open Live Voting?</div>
      </v-card-title>

      <v-card-text>
        <p class="mt-3">
          Do you want to open live voting?
        </p>
        <ul class="mt-3">
          <li>This will allow you to open voting on Live Questions.</li>
          <li>
            If a voter has submitted a proxy and has not yet revoked it, they
            will no longer be able to revoke their proxy and they will not be
            able to vote live.
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-btn flat @click="emitCancel">cancel</v-btn>
        <v-spacer />
        <v-btn class="primary" @click="emitOpenVoting">
          Open Voting
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OpenVotingToggle',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
    emitOpenVoting() {
      this.$emit('openVoting');
    }
  }
};
</script>
