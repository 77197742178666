<template>
  <v-dialog persistent :value="isOpen" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        <div class="title primary--text text--darken-1">
          <slot name="title" />
        </div>
      </v-card-title>
      <v-card-text>
        <slot name="description" />
      </v-card-text>

      <!-- current job state indicator -->
      <v-card-text class="text-xs-center caption mb-4">
        <v-chip
          v-bind:disabled="getStatusFocus('DRAFT')"
          class="pa-1"
          v-bind:color="getStatusColor('DRAFT')"
          v-bind:text-color="getStatusText('DRAFT')"
        >
          <v-icon left>{{ statusInfo['DRAFT'].icon }}</v-icon>
          {{ statusInfo['DRAFT'].value }}
        </v-chip>
        <v-icon center>arrow_forward</v-icon>
        <v-chip
          v-bind:disabled="getStatusFocus('SENT')"
          class="pa-1"
          v-bind:color="getStatusColor('SENT')"
          v-bind:text-color="getStatusText('SENT')"
        >
          <v-icon left>{{ statusInfo['SENT'].icon }}</v-icon>
          {{ statusInfo['SENT'].value }}
        </v-chip>
        <v-icon center>arrow_forward</v-icon>
        <v-chip
          v-bind:disabled="getStatusFocus('IN_PROGRESS')"
          class="pa-1"
          v-bind:color="getStatusColor('IN_PROGRESS')"
          v-bind:text-color="getStatusText('IN_PROGRESS')"
        >
          <v-icon left>{{ statusInfo['IN_PROGRESS'].icon }}</v-icon>
          {{ statusInfo['IN_PROGRESS'].value }}
        </v-chip>
        <v-icon center>arrow_forward</v-icon>
        <v-chip
          v-bind:disabled="getStatusFocus('COMPLETED')"
          class="pa-1"
          v-bind:color="getStatusColor('COMPLETED')"
          v-bind:text-color="getStatusText('COMPLETED')"
        >
          <v-icon left>{{ statusInfo['COMPLETED'].icon }}</v-icon>
          {{ statusInfo['COMPLETED'].value }}
        </v-chip>
      </v-card-text>

      <v-flex class="ma-3" v-if="processAction.value.toUpperCase() === 'SENT'">
        <v-card-text class="ma-0 pa-0">Recipients:</v-card-text>
        <v-card-text
          class="ml-2 pt-1"
          v-for="recipient in vendorAddresses"
          :key="recipient"
        >
          <v-icon small>email</v-icon>
          <a class="ml-2" :href="`mailto:${recipient}`">{{ recipient }}</a>
        </v-card-text>
      </v-flex>

      <v-card-actions class="mx-4">
        <v-checkbox
          v-if="processAction.value.toUpperCase() === 'SENT'"
          v-model="urgent"
        >
          <template slot="label">
            <v-tooltip bottom>
              <div slot="activator">
                Mark print job as urgent
              </div>
              <span class="caption"
                >Appends [URGENT] to email subject line</span
              >
            </v-tooltip>
          </template>
        </v-checkbox>
        <v-spacer />
        <div>
          <v-btn color="primary" @click.native="close" flat>Close</v-btn>
          <v-btn @click.native="processPrintJob" color="primary">
            <v-icon left>{{ currentIcon }}</v-icon>
            {{ currentAction }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ProcessPrintJobDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '500px';
      }
    },
    statusInfo: {
      type: Object,
      required: true
    },
    processAction: {
      type: Object,
      required: false
    },
    vendorAddresses: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      order: ['DRAFT', 'SENT', 'IN_PROGRESS', 'COMPLETED'],
      urgent: false
    };
  },
  computed: {
    currentAction() {
      return this.processAction ? this.processAction.label : '';
    },
    currentIcon() {
      return this.processAction ? this.processAction.icon : 'null';
    },
    currentColor() {
      return this.processAction ? this.processAction.color : '';
    }
  },
  methods: {
    processPrintJob() {
      this.$emit('process', {
        urgent:
          this.processAction.value.toUpperCase() === 'SENT' ? this.urgent : null
      });

      this.close();
    },
    close() {
      this.$emit('close-dialog');
    },
    getStatusColor(status) {
      const activeColor = this.statusInfo[status].color;
      const inactiveColor =
        this.order.findIndex(o => o == status) <
        this.order.findIndex(o => o == this.processAction.value)
          ? 'grey'
          : '';
      const statusColor =
        this.processAction.value === this.statusInfo[status].value
          ? activeColor
          : inactiveColor;
      return statusColor;
    },
    getStatusText(status) {
      const activeTextColor = 'white';
      const inactiveTextColor = '';
      return this.processAction.value === this.statusInfo[status].value
        ? activeTextColor
        : inactiveTextColor;
    },
    getStatusFocus(status) {
      return this.processAction.value === this.statusInfo[status].value
        ? false
        : true;
    }
  }
};
</script>

<style scoped></style>
