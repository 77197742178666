<template>
  <v-dialog v-model="isOpen" max-width="500px">
    <v-card>
      <v-card-title>
        <div class="title red--text text--darken-1">
          File size limit
        </div>
      </v-card-title>
      <v-card-text>
        <v-alert outline color="error" icon="warning" :value="true">
          <strong>ERROR</strong>: The specified file is larger than the maximum
          supported file size.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="close">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UploadFileSizeErrorDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    }
  }
};
</script>

<style scoped></style>
