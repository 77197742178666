<template>
  <subscription-list-table :subscriptions="subscriptions" />
</template>

<script>
import { mapGetters } from 'vuex';

import SubscriptionListTable from '@/components/SubscriptionListTable';

export default {
  name: 'AdminAccountViewSubscriptionTable',
  components: {
    SubscriptionListTable
  },
  computed: {
    ...mapGetters('accounts', ['subscription']),

    subscriptions() {
      if (this.subscription) {
        return [this.subscription];
      }
      return [];
    }
  }
};
</script>

<style scoped></style>
