'use strict';

/**
 * @fileoverview Vuex module for meeting certificates
 */
import _ from 'lodash';
import Vue from 'vue';

import {
  getAllCertificates,
  postCertificate,
  postCertificatePreview,
  deleteCertificate,
  sendCertificate
} from '@/api/certificates';

const state = {
  certificateList: {},
  dataLastFetchedAt: null,
  preview: ''
};

const getters = {
  meetingCertificatesList: state => state.certificateList,
  meetingCertificatesListData: state => Object.values(state.certificateList),
  meetingCertificatePreviewContent: state => state.preview
};

const actions = {
  /**
   * Get all certificates for this meeting
   *
   * @param  {String} shortCode - the meeting's shortCode
   */
  async getMeetingCertificates({commit}, shortCode) {
    try {
      const res = await getAllCertificates(shortCode);
      commit('SET_MEETING_CERTIFICATE_LIST_DATA', res.data);
    } catch (err) {
      console.error('Error: getMeetingCertificates action', err);
      throw err;
    }
  },

  /**
   * Create a certificate report
   *
   * @param  {Object} {payload}   - the new cert payload
   */
  async createMeetingCertificate({commit}, payload) {
    try {
      const res = await postCertificate(payload);
      commit('SET_MEETING_CERTIFICATE_IN_LIST', res.data);
      return res;
    } catch (err) {
      console.error('Error: createMeetingCertificate action', err);
      throw err;
    }
  },

  /**
   * Deletes a cert that has not already been sent
   * @param  {String} {shortCode} - the meeting's shortCode
   * @param  {String} {cerKey}    - the cert key to delete
   */
  async deleteMeetingCertificate({dispatch}, {shortCode, certKey}) {
    try {
      await deleteCertificate(certKey);
      dispatch('getMeetingCertificates', shortCode);
    } catch (err) {
      console.error('Error: deleteMeetingCertificate action', err);
      throw err;
    }
  },

  /**
   * Gets the preview of the certificate
   * @param  {Object} {payload}   - the new cert payload
   */
  async previewMeetingCertificate({commit}, payload) {
    try {
      const res = await postCertificatePreview(payload);
      commit('SET_MEETING_CERTIFICATE_PREVIEW', res.data);
    } catch (err) {
      console.error('Error: previewMeetingCertificate action', err);
      throw err;
    }
  },

  /**
   * Send the certificate email
   * @param  {String} {shortCode} - the meeting shortCode
   * @param  {String} {certKey}   - the cert to send in email
   * @param  {Object} {payload}   - the recipient payload
   */
  async sendMeetingCertificate({dispatch}, {shortCode, certKey, payload}) {
    try {
      await sendCertificate(certKey, payload);
      dispatch('getMeetingCertificates', shortCode);
    } catch (err) {
      console.error('Error: sendMeetingCertificate action', err);
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_CERTIFICATE_LIST_DATA(state, data) {
    state.certificateList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  // Adds/updates a certificate to the list
  SET_MEETING_CERTIFICATE_IN_LIST(state, certificate) {
    Vue.set(state.certificateList, certificate.key, certificate);
  },

  SET_MEETING_CERTIFICATE_PREVIEW(state, html) {
    state.preview = html;
  },

  // Clear the state
  CLEAR_STATE(state) {
    state.certificateList = {};
    state.dataLastFetchedAt = null;
    state.preview = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
