import moment from 'moment-timezone';
import holidays from '@/assets/holidays.json';

// Standard format for checklist payload
const timestampFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ';

/**
 * Return the relevant deadline based on checklist type
 *
 * @param  {Number} deadline           - Number of days before/after (+/-) meeting date that checklist is due
 * @param  {Date}   meetingDate        - Meeting date
 * @param  {String} meetingTimezone    - Meeting timezone
 * @return {Date}                      - Returns the deadline (moment date)
 */
export function getDefaultDate(
  deadline,
  meetingDate,
  meetingTimezone = 'America/Toronto'
) {
  // No deadline means the default date is today
  if (!deadline) {
    return moment()
      .tz(meetingTimezone)
      .format(timestampFormat);
  }
  // Otherwise, subtract the deadline from the meeting date
  else {
    return moment(meetingDate)
      .tz(meetingTimezone)
      .subtract(parseInt(deadline), 'days')
      .format(timestampFormat);
  }
}

export function isWeekend(date) {
  let day = moment(date).format('ddd');
  return day === 'Sat' || day === 'Sun';
}

export function isHoliday(date) {
  const isHoliday = holidays.some(holiday =>
    moment(holiday.date).isSame(moment(date), 'date')
  );
  return isHoliday;
}

export function isExpired(date) {
  return moment(date).isBefore(moment());
}

export function getChecklistColor(checklistType) {
  switch (checklistType) {
    case 'vm':
      return 'purple';
    case 'vm-onboard':
      return 'pink';
    case 'vm-post':
      return 'cyan';
    case 'service':
      return 'red';
    case 'pre-notice':
      return 'light-blue';
    case 'pre-consent':
      return 'orange';
    case 'cs-onboard':
      return 'green';
    case 'billing':
      return 'blue';
    default:
      return 'grey';
  }
}

/**
 * Returns an array of intials for multiple people
 * @param  {Object[]}  assignees  names
 * @return {String[]}             initials
 */
export function getAssigneeInitials(assignees = []) {
  if (!assignees || assignees.length <= 0) {
    return '';
  }

  const names = assignees.map(a => a.name);

  const peopleInitials = names.map(name => {
    const initials = [];
    const nArray = name.split(' ');
    for (let x = 0; x < nArray.length; x++) {
      initials.push(nArray[x].charAt(0).toUpperCase());
    }
    return initials.join('');
  });

  return peopleInitials.join(', ');
}

/**
 * Returns an array of names for multiple people
 * @param  {Object[]}  assignees  assignees
 * @return {String[]}             initials
 */
export function getAssigneeNames(assignees = []) {
  if (!assignees || assignees.length <= 0) {
    return '';
  }

  const names = assignees.map(a => a.name);
  return names.join(', ');
}

export function payloadDateFormat(args) {
  const { date, hour = 0, min = 0, timezone } = args;

  return moment(date)
    .hour(hour)
    .minute(min)
    .tz(timezone ? timezone : 'America/Toronto')
    .format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
}
