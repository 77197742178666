<template>
  <!-- Tooltip is disabled when a file exists -->
  <v-tooltip bottom :disabled="!isNull" class="mr-2">
    <span slot="activator">
      <v-btn
        outline
        small
        class="pr-1"
        color="grey"
        :disabled="isNull"
        @click="clickHandler"
      >
        View (PDF)
      </v-btn>
    </span>
    <span>
      No file record of this submission was provided
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ViewPdfButton',
  props: {
    url: { type: String, default: '' }
  },
  computed: {
    isNull() {
      return !this.url;
    }
  },
  methods: {
    clickHandler() {
      // Open proxy in a new window
      return window.open(this.url, '_blank');
    }
  }
};
</script>

<style scoped></style>
