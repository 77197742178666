<template>
  <router-view
    :current-meeting="currentMeeting"
    :units-list-data="meetingUnitsListData"
    :units-list="meetingUnitsList"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'MeetingUnitsMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('meetings/units', [
      'meetingUnitsList',
      'meetingUnitsListData'
    ])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/proxies', ['getMeetingProxyList']),
    init() {
      this.getMeetingProxyList({ shortCode: this.shortCode }).catch(err =>
        this.$events.$emit('showErrorDialog', err.response)
      );
    }
  }
};
</script>
