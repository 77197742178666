<template>
  <router-link v-if="!isEmpty || isDisabled" :to="route" target="_blank">
    <v-chip
      v-if="!isEmpty || isDisabled"
      small
      :color="status.color"
      text-color="white"
    >
      <span class="small capitalize">{{ status.text }}</span>
    </v-chip>
    <v-tooltip top>
      <v-icon slot="activator" small>open_in_new</v-icon>
      <span>Open invoice page in new window</span>
    </v-tooltip>
  </router-link>
</template>

<script>
export default {
  name: 'InvoiceStatusChip',
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      }
    },
    billingEnabled: {
      type: Boolean,
      default() {
        return true;
      }
    },
    route: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    numDrafts() {
      return this.stats.draft;
    },
    hasSent() {
      return this.stats.sent && this.stats.sent > 0;
    },
    isEmpty() {
      return !this.stats.draft && !this.stats.sent;
    },
    hasDrafts() {
      return this.stats.draft && this.stats.draft > 0;
    },
    isBilled() {
      return !this.hasDrafts && this.hasSent;
    },
    isDisabled() {
      return !this.billingEnabled;
    },
    status() {
      if (this.isDisabled) {
        return { text: 'Disabled', color: 'amber' };
      } else if (this.hasDrafts) {
        return { text: 'Draft', color: 'grey' };
      } else if (this.isBilled) {
        return { text: 'Billed', color: 'blue' };
      } else {
        return { text: 'Unknown', color: 'amber' };
      }
    }
  }
};
</script>

<style scoped>
.small {
  font-size: 12px;
}
</style>
