<template>
  <v-dialog :value="isOpen" :max-width="maxWidth" @input="close">
    <v-card>
      <div v-if="processing" class="text-xs-center title pa-4">
        Processing ...
        <v-progress-circular indeterminate color="primary" />
      </div>
      <div v-else-if="hasError">
        <v-card-title class="horizontal-flex">
          <v-icon color="red" class="pr-1">error_outline</v-icon>
          <div class="headline red--text">Error</div>
        </v-card-title>
        <v-card-text>
          <div class="body mb-1">
            {{ errorResponse.data }}
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="caption grey--text pl-2">
            {{ errorResponse.status }}: {{ errorResponse.statusText }}
          </div>
          <v-spacer />
          <v-btn color="primary" flat @click.native="close">Okay</v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-title>
          <div class="title">Exporting File(s)...</div>
        </v-card-title>
        <v-card-text>
          <p>
            The files will be <b>emailed</b> to
            <span class="accent--text">{{ emailTo }}</span
            >.
          </p>
          <p>
            This process takes a few minutes to complete. An email with a link
            to download the file will be sent to your inbox.
          </p>
          <p>
            You should expect the email within 5 to 10 minutes. If you still
            haven't received the email, please check your Spam folder or contact
            support at
            <span class="accent--text body-2">support@getquorum.com</span>.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" flat @click.native="close">close</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExportFilesDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default() {
        return '650px';
      }
    },
    processing: {
      type: Boolean,
      required: true
    },
    emailTo: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  computed: {
    hasError() {
      return !!this.error;
    },
    errorResponse() {
      if (this.error) {
        const { status, statusText, data } = this.error;
        return {
          status,
          statusText,
          data
        };
      }
      return {};
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    }
  }
};
</script>

<style scoped></style>
