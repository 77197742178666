<template>
  <v-layout row wrap>
    <!-- If not an admin user -->
    <v-flex xs12 v-if="currentUser.type !== 'admin'">
      <h3 class="subheading">Accounts</h3>
      <p class="body-2">
        The user has top-level permissions to all meetings for the following
        accounts.
      </p>
      <v-toolbar dark color="grey lighten-4 elevation-1">
        <v-text-field
          v-model="searchAccount"
          light
          prepend-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        />
        <v-spacer />
        <v-btn
          flat
          icon
          color="secondary"
          @click.native="showAddMembershipDialog = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="membershipData"
        :rows-per-page-items="config.rowsPerPageItems"
        :pagination.sync="config.pagination"
        :search="searchAccount"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <td class="text-xs-left">{{ props.item.account.nameShort }}</td>
          <td class="text-xs-left">{{ props.item.account.name }}</td>
          <td class="text-xs-left">{{ formatDate(props.item.createdAt) }}</td>
          <td>
            <v-btn
              flat
              icon
              color="secondary"
              @click.native="openMembershipDeleteDialog(props.item)"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-flex>

    <v-flex class="mt-3" xs12 v-if="currentUser.type !== 'admin'">
      <h3 class="subheading">Collaborations</h3>
      <p class="body-2">
        Meetings where the user has access as a collaborator.
      </p>
      <v-toolbar dark color="grey lighten-4 elevation-1">
        <v-text-field
          v-model="searchCollaboration"
          light
          prepend-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        />
        <v-spacer />
      </v-toolbar>

      <v-data-table
        :headers="meetingHeaders"
        :items="meetingsData"
        :rows-per-page-items="config.rowsPerPageItems"
        :pagination.sync="config.pagination"
        :search="searchCollaboration"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <td class="text-xs-left">{{ props.item.meeting.shortCode }}</td>
          <td class="text-xs-left">{{ props.item.meeting.name }}</td>
          <td class="text-xs-left">{{ formatDate(props.item.createdAt) }}</td>
          <td>
            <v-btn
              flat
              icon
              color="secondary"
              @click.native="openMeetingDeleteDialog(props.item)"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-flex>

    <v-flex class="mt-3" xs12 v-if="currentUser.type !== 'admin'">
      <h3 class="subheading">Agents</h3>
      <v-toolbar dark color="grey lighten-4 elevation-1">
        <v-text-field
          v-model="searchAgent"
          light
          prepend-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        />
        <v-spacer />
      </v-toolbar>

      <v-data-table
        :headers="agentHeaders"
        :items="agentsData"
        :rows-per-page-items="config.rowsPerPageItems"
        :pagination.sync="config.pagination"
        :search="searchAgent"
        class="elevation-1"
      >
        <template slot="items" slot-scope="props">
          <td class="text-xs-left">
            <span slot="activator">
              <router-link
                :to="{
                  name: 'editAgent',
                  params: { agentKey: props.item.key }
                }"
              >
                {{ props.item.name }}
              </router-link>
            </span>
          </td>
          <td class="text-xs-left">{{ props.item.agentType }}</td>
          <td class="text-xs-left">{{ props.item.partnerType }}</td>
          <td class="text-xs-left">
            {{ formatDate(props.item.createdAt) }}
          </td>
          <td>
            <v-btn
              flat
              icon
              color="secondary"
              @click.native="openAgentDeleteDialog(props.item)"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-flex>

    <v-flex class="mt-3" xs12 v-if="currentUser.type !== 'admin'">
      <h3 class="subheading">User top level permissions</h3>
      <p class="body-2">Permission levels given to {{ currentUser.name }}</p>

      <user-permissions-card :current-user="currentUser" />
    </v-flex>

    <!-- If admin user -->
    <v-flex xs12 v-if="currentUser.type === 'admin'">
      <p class="subheading">
        This user is an admin and has access to all accounts
      </p>
    </v-flex>

    <admin-user-membership-add-dialog
      :is-open="showAddMembershipDialog"
      :current-user="currentUser"
      :memberships="currentUser.memberships"
      @add-membership="addMembership"
      @close-dialog="showAddMembershipDialog = false"
    />

    <delete-resource-dialog
      :is-open="showDeleteMembershipDialog"
      resource-name="membership"
      :resource-key="membershipToDelete.account.key"
      @delete="deleteMembership"
      @close-dialog="closeDeleteDialog"
    >
      <span slot="title">Delete Membership?</span>
      <p slot="description">
        Remove access to <b>{{ membershipToDelete.account.nameShort }}</b> for
        user <b>{{ membershipToDelete.user.email }}</b
        >?
      </p>
    </delete-resource-dialog>

    <delete-resource-dialog
      :is-open="showDeleteMeetingDialog"
      resource-name="meeting"
      :resource-key="meetingToDelete.meeting.shortCode"
      @delete="deleteMeeting"
      @close-dialog="closeDeleteDialog"
    >
      <span slot="title">Delete Meeting?</span>
      <p slot="description">
        Remove access to <b>{{ meetingToDelete.meeting.name }}</b> for user
        <b>{{ meetingToDelete.user.email }}</b
        >?
      </p>
    </delete-resource-dialog>

    <delete-resource-dialog
      :is-open="showDeleteAgentDialog"
      resource-name="agent"
      :resource-key="agentToDelete"
      @delete="deleteAgent"
      @close-dialog="closeDeleteDialog"
    >
      <span slot="title">Delete Agent?</span>
      <p slot="description">
        Remove agent user <b>{{ agentToDelete.name }}</b
        >?
      </p>
    </delete-resource-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

import DeleteResourceDialog from '@/components/dialogs/DeleteResourceDialog';
import AdminUserMembershipAddDialog from '@/components/dialogs/AddUserMembershipDialog';
import UserPermissionsCard from '@/components/UserPermissionsCard';

export default {
  name: 'AdminUserViewUserMemberships',
  components: {
    AdminUserMembershipAddDialog,
    DeleteResourceDialog,
    UserPermissionsCard
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      },
      headers: [
        { text: 'Account', value: 'account.nameShort', align: 'left' },
        { text: 'Name', value: 'account.name', align: 'left' },
        { text: 'Added', value: 'createdAt', align: 'left' },
        { text: '', value: '', align: 'left', sortable: false }
      ],
      meetingHeaders: [
        { text: 'Short Code', value: 'meeting.shortCode', align: 'left' },
        { text: 'Name', value: 'meeting.name', align: 'left' },
        { text: 'Added', value: 'createdAt', align: 'left' },
        { text: '', value: '', align: 'left', sortable: false }
      ],
      agentHeaders: [
        { text: 'Agent Name', value: 'name', align: 'left' },
        { text: 'Type', value: 'agentType', align: 'left' },
        { text: 'Partner', value: 'partnerType', align: 'left' },
        { text: 'Added', value: 'createdAt', align: 'left' },
        { text: '', value: '', align: 'left', sortable: false }
      ],
      membershipToDelete: {
        key: '',
        account: {},
        user: {}
      },
      meetingToDelete: {
        key: '',
        meeting: {},
        user: {}
      },
      agentToDelete: { agentKey: '', agentUserKey: '', name: '' },
      showDeleteMembershipDialog: false,
      showDeleteMeetingDialog: false,
      showAddMembershipDialog: false,
      showDeleteAgentDialog: false,
      searchAgent: '',
      searchAccount: '',
      searchCollaboration: ''
    };
  },
  computed: {
    membershipData() {
      return this.currentUser?.memberships || [];
    },
    meetingsData() {
      return this.currentUser?.meetings || [];
    },
    agentsData() {
      return this.currentUser?.agents || [];
    }
  },
  methods: {
    ...mapActions('accounts', ['addAccountUser', 'removeAccountUser']),
    ...mapActions('users', ['getUser']),
    ...mapActions('meetings/collaborators', ['removeCollaborator']),
    ...mapActions('agents', ['removeAgentUser']),
    async deleteMembership(accountKey) {
      try {
        await this.removeAccountUser({
          accountKey,
          email: this.currentUser.email
        });
        this.$events.$emit('toastEvent', 'Account removed from user');
        this.closeDeleteDialog();
        this.getUser(this.currentUser.key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteMeeting(shortCode) {
      try {
        await this.removeCollaborator({
          shortCode,
          email: this.currentUser.email
        });
        this.$events.$emit(
          'toastEvent',
          'User as collaborator removed from meeting'
        );
        this.closeDeleteDialog();
        this.getUser(this.currentUser.key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteAgent(agent) {
      try {
        const agentUserKey = agent.agentUserKey;
        const agentKey = agent.key;
        await this.removeAgentUser({ agentKey, agentUserKey });

        this.$events.$emit('toastEvent', 'User removed from agent');
      } catch (err) {
        console.log(err);
      } finally {
        this.closeDeleteDialog();
        this.getUser(this.currentUser.key);
      }
    },
    async addMembership(accountKey) {
      try {
        await this.addAccountUser({
          accountKey,
          email: this.currentUser.email
        });
        this.$events.$emit('toastEvent', 'Account added to User');
        this.showAddMembershipDialog = false;
        this.getUser(this.currentUser.key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    openMembershipDeleteDialog(membership) {
      this.membershipToDelete = membership;
      this.showDeleteMembershipDialog = true;
    },
    openMeetingDeleteDialog(meeting) {
      this.meetingToDelete = meeting;
      this.showDeleteMeetingDialog = true;
    },
    openAgentDeleteDialog(agent) {
      const { key: agentKey, agentUserKey, name } = agent;
      this.agentToDelete = { agentKey, agentUserKey, name };
      this.showDeleteAgentDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteMembershipDialog = false;
      this.showDeleteMeetingDialog = false;
      this.showDeleteAgentDialog = false;
      this.membershipToDelete = {
        key: '',
        account: {},
        user: {}
      };
      this.meetingToDelete = {
        key: '',
        meeting: {},
        user: {}
      };
      this.agentToDelete = { agentKey: '', agentUserKey: '', name: '' };
    }
  }
};
</script>

<style scoped></style>
