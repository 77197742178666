<template>
  <v-container>
    <v-dialog :value="isOpen" persistent scrollable max-width="1200px">
      <v-card>
        <v-card-title class="headline">
          Add and Assign Checklists
        </v-card-title>
        <v-card-text>
          <v-alert type="info" outline :value="true">
            <li>
              You will need to adjust due dates after the checklist has been
              added.
            </li>
            <li>
              Due dates are calculated based on the
              <strong>{{ formatDate(meetingDate, meetingTimezone) }}</strong>
              meeting date.
            </li>
            <li>
              For timezone considerations, checklist due dates are by default
              7pm EST.
            </li>
            <li>
              If a Preferred CSM or VMM exists, the "Assignee" field will be
              auto-filled with their name.
            </li>
            <li>
              Leave the "Assignee" field blank to skip assigning anyone to this
              checklist for now.
            </li>
          </v-alert>

          <v-layout justify-center>
            <v-flex xs6>
              <div class="my-3">
                <v-select
                  :items="checklistGroups"
                  item-text="name"
                  item-value="key"
                  label="Checklist Group"
                  v-model="groupKey"
                  outline
                ></v-select>
              </div>
            </v-flex>
          </v-layout>

          <v-data-table
            v-if="groupKey"
            v-model="selected"
            :headers="headers"
            :items="checklists"
            :rows-per-page-items="config.rowsPerPageItems"
            item-key="name"
            select-all
            hide-triggers
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details
                    :disabled="loading"
                  />
                </td>
                <td>
                  <checklist-chip :checklist="props.item" />
                </td>
                <td>
                  <v-menu
                    v-model="props.item.menu"
                    :close-on-content-click="false"
                    offset-y
                    full-width
                    transition="scale-transition"
                  >
                    <v-text-field
                      slot="activator"
                      :value="formatDate(props.item.dueAt, meetingTimezone)"
                      :hint="
                        props.item.legalDeadline
                          ? 'Legal Deadline: ' + getLegalDeadline(props.item)
                          : ''
                      "
                      persistent-hint
                      readonly
                    >
                      <v-tooltip
                        v-if="showDateWarning(props.item.dueAt)"
                        slot="append"
                        right
                      >
                        <v-icon slot="activator" color="accent">warning</v-icon>
                        <span
                          >This date either: falls on a weekend, falls on a
                          holiday, or has passed. Please adjust.</span
                        >
                      </v-tooltip>
                    </v-text-field>

                    <v-date-picker
                      no-title
                      scrollable
                      triggers
                      :value="getDateString(props.item.dueAt)"
                      @input="updateDueAt(props.item, $event)"
                    >
                      <v-spacer />
                      <v-btn
                        color="primary"
                        small
                        @click="props.item.menu = false"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </td>
                <td>
                  <v-select
                    v-model="props.item.assignee"
                    item-value="email"
                    item-text="name"
                    :items="assignees"
                    multiple
                    hide-selected
                    chips
                    deletable-chips
                    dense
                    return-object
                  />
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <p>
                There are no more checklists that can be added to this meeting.
              </p>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click.native="close" flat :disabled="loading">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click.native="save"
            :loading="loading"
            :disabled="selected.length <= 0 || loading"
            color="primary"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import {
  isWeekend,
  isHoliday,
  isExpired,
  getDefaultDate,
  payloadDateFormat
} from '@/lib/checklist-helpers';

import ChecklistChip from '@/components/ChecklistChip';

export default {
  name: 'MeetingChecklistDialogAddAndAssign',
  components: { ChecklistChip },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    stages: {
      type: Array,
      default() {
        return [];
      }
    },
    meetingDate: {
      type: [String, Date],
      required: true
    },
    meetingOptions: {
      type: Object,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    },
    corpNameShort: {
      type: String,
      required: true
    },
    existingChecklists: {
      type: Array,
      default() {
        return [];
      }
    },
    assignees: {
      type: Array,
      default() {
        return [];
      }
    },
    preferredCsm: {
      type: Object,
      default() {
        return {};
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    checklistGroups: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {
    isOpen() {
      this.initChecklists();
      this.setState();
    },
    preferredCsm() {
      this.initChecklists();
      this.setState();
    },
    stages() {
      this.initChecklists();
      this.setState();
    },
    assignees() {
      this.initChecklists();
      this.setState();
    },
    groupKey() {
      this.initChecklists();
      this.setState();
    },
    checklistGroups() {
      const defaultGroup = this.checklistGroups.find(
        group => group.isDefaultGroup
      );
      this.groupKey = defaultGroup.key;
    }
  },
  data() {
    return {
      headers: [
        { text: 'Checklist Stage', value: 'stage', sortable: false },
        { text: 'Due At', value: 'dueAt', sortable: false },
        { text: 'Assignee', value: 'assignee', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, 100, { text: 'All', value: -1 }]
      },
      groupKey: '',
      checklists: [],
      selected: [],
      dialog: {
        addConversations: false
      }
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountList']),
    existingNames() {
      return this.existingChecklists.map(cl => cl.name);
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  created() {
    this.setState();
  },
  methods: {
    initChecklists() {
      const group = this.checklistGroups.find(
        group => group.key === this.groupKey
      );

      if (group) {
        this.checklists = group.checklistTemplates
          .filter(
            template =>
              !this.existingNames.includes(
                this.checklistName(template.checklistLabel)
              )
          )
          .map(template => {
            return {
              name: `${this.corpNameShort} - ${template.checklistLabel}`,
              shortCode: this.shortCode,
              stage: template.stage,
              dueAt: payloadDateFormat({
                date: getDefaultDate(
                  template.deadline,
                  this.meetingDate,
                  this.meetingTimezone
                    ? this.meetingTimezone
                    : 'America/Toronto'
                ),
                hour: 19,
                min: 0,
                timezone: this.meetingTimezone
                  ? this.meetingTimezone
                  : 'America/Toronto'
              }),
              legalDeadline: template.legalDeadline,
              items: template.items,
              meta: {},
              assignee:
                this.preferredCsm && !this.preferredCsm.disabledAt
                  ? [this.preferredCsm]
                  : [],
              menu: false
            };
          });
      } else {
        this.checklists = [];
      }
    },
    formatDate(date, meetingTimezone) {
      return date
        ? moment(date)
            .tz(meetingTimezone)
            .format('ddd, MMM Do YYYY')
        : '';
    },
    getLegalDeadline(checklist) {
      const date = payloadDateFormat({
        date: getDefaultDate(
          checklist.legalDeadline,
          this.meetingDate,
          this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
        ),
        hour: 19,
        min: 0,
        timezone: this.meetingTimezone
          ? this.meetingTimezone
          : 'America/Toronto'
      });

      return this.formatDate(date, this.meetingTimezone);
    },
    showDateWarning(date) {
      return isWeekend(date) || isHoliday(date) || isExpired(date);
    },
    setState() {
      this.selected = [];
    },
    async save() {
      this.$emit('save', this.selected);
    },
    close() {
      this.$emit('close');
      this.setState();
    },
    updateDueAt(checklist, date) {
      checklist.dueAt = payloadDateFormat({
        date: moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ'),
        hour: 19,
        min: 0
      });
    },
    getDateString(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    checklistName(name) {
      return `${this.corpNameShort} - ${name}`;
    }
  }
};
</script>

<style scoped></style>
