<template>
  <v-chip 
    label 
    small
    :color="color"
    class="chip"
    :class="textColor">
    <v-icon left>{{ icon }}</v-icon>
    {{ label }}
    <span 
      v-if="signedAt"
      class="chip-date">({{ signedAt | formatDateHumanReadable }})</span>
  </v-chip>
</template>

<script>
import * as filters from '@/filters';

export default {
  name: 'AgreementCardChip',
  props: {
    isSigned: {
      type: Boolean,
      required: true
    },
    signedAt: {
      type: [Date, String],
      default() {
        return null;
      }
    }
  },
  filters: {
    ...filters
  },
  computed: {
    color() {
      return this.isSigned ? 'green' : 'red';
    },
    label() {
      return this.isSigned ? 'Signed' : 'Unsigned';
    },
    textColor() {
      return 'white--text';
    },
    icon() {
      return this.isSigned ? 'check' : 'warning';
    }
  }
};
</script>

<style scoped>
.chip {
  margin-left: 10px;
  padding-left: 5px;
}
.chip-date {
  padding-left: 5px;
}
</style>

