<template>
  <router-view
    :template-groups-list="checklistTemplateGroupsList"
    :template-groups-data="checklistTemplateGroupsListData"
    :last-data-fetched="checklistTemplateGroupDataLastFetched"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminChecklistTemplateGroupsMain',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('checklistTemplateGroups', [
      'checklistTemplateGroupsList',
      'checklistTemplateGroupsListData',
      'checklistTemplateGroupDataLastFetched'
    ])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('checklistTemplateGroups', ['getChecklistTemplateGroups']),
    async init() {
      try {
        await this.getChecklistTemplateGroups();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
