<template>
  <v-container grid-list-sm>
    <v-card v-if="isBillingAddress" class="mb-4" tile>
      <v-card-text>
        <v-layout row align-center justify-center>
          <v-flex xs6>
            <div>
              Select an option to pre-populate the billing address:
            </div></v-flex
          >
          <v-flex xs6>
            <v-select
              :items="billingAddressOptions"
              v-model="prepopulatedBillingAddress"
              item-text="text"
              item-value="value"
              dense
            ></v-select>
          </v-flex> </v-layout
      ></v-card-text>
    </v-card>

    <div v-if="!isBillingAddress || prepopulatedBillingAddress">
      <v-text-field
        label="address"
        :value="address.address"
        :rules="requiredRule"
        @input="emitUpdate('address', $event)"
      />

      <v-layout row>
        <v-flex xs6>
          <v-text-field
            label="City"
            :value="address.city"
            :rules="requiredRule"
            @input="emitUpdate('city', $event)"
          />
        </v-flex>
        <v-flex xs6>
          <v-autocomplete
            label="Province"
            :value="address.prov"
            :items="provinceItems"
            item-text="regionName"
            item-value="regionCode"
            :rules="requiredRule"
            @input="emitUpdate('prov', $event)"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs6>
          <v-text-field
            label="Postal code"
            :value="address.postal"
            :rules="postalRule"
            @input="emitUpdate('postal', $event)"
          />
        </v-flex>
        <v-flex xs6>
          <v-autocomplete
            label="Country"
            :value="address.country"
            :items="countryItems"
            :rules="requiredRule"
            @input="emitUpdate('country', $event)"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import {
  alpha2CountryCode,
  countryNames,
  provinceNames
} from '@/lib/address-validators';

import validator from 'validator';

export default {
  name: 'InputAddress',
  props: {
    address: {
      type: Object,
      default() {
        return {};
      }
    },
    isBillingAddress: {
      type: Boolean,
      default: false
    },
    agents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    prepopulatedBillingAddress() {
      if (this.isBillingAddress) {
        if (this.prepopulatedBillingAddress === 'custom') {
          this.prepopulate({
            address: 'TBD',
            city: 'Toronto',
            prov: 'ON',
            postal: 'TBD',
            country: 'Canada'
          });
        } else if (this.prepopulatedBillingAddress === 'mailing') {
          this.$emit('setBillingAsMailing');
        } else {
          const agent = this.agents.find(
            a => a.key === this.prepopulatedBillingAddress
          );
          this.prepopulate({
            address: agent.billingAddress,
            city: agent.billingCity,
            prov: agent.billingProv,
            postal: agent.billingPostal,
            country: agent.billingCountry
          });
        }
      }
    }
  },
  computed: {
    countryCode() {
      const code = alpha2CountryCode(this.address.country);
      return code;
    },
    countryItems() {
      const countryArray = countryNames();
      return countryArray;
    },
    provinceItems() {
      const provArray = provinceNames(this.countryCode);
      return provArray;
    },
    postalRule() {
      // Must be a valid postal code based on the country selected
      return [
        v =>
          v === 'TBD' ||
          validator.isPostalCode(this.address.postal, this.countryCode) ||
          'Must be a valid postal code'
      ];
    },
    requiredRule() {
      if (this.isBillingAddress) {
        return [v => !!v || 'Required field'];
      }
      return [];
    },
    billingAddressOptions() {
      // Create list of options for "pre-populate" dropdown menu
      const defaultOptions = [
        { text: `Use account's mailing address`, value: 'mailing' },
        { text: 'Enter new billing address', value: 'custom' }
      ];
      const agentOptions = this.agents.map(agt => {
        return {
          text: `Use ${agt.name}'s billing address`,
          value: agt.key
        };
      });

      return [...agentOptions, ...defaultOptions];
    }
  },
  data() {
    return {
      prepopulatedBillingAddress: null
    };
  },
  methods: {
    prepopulate(addressObj) {
      this.$emit('update', {
        key: 'billingAddress.address',
        value: addressObj.address || 'TBD'
      });
      this.$emit('update', {
        key: 'billingAddress.city',
        value: addressObj.city || 'Toronto'
      });
      this.$emit('update', {
        key: 'billingAddress.prov',
        value: addressObj.prov || 'ON'
      });
      this.$emit('update', {
        key: 'billingAddress.postal',
        value: addressObj.postal || 'TBD'
      });
      this.$emit('update', {
        key: 'billingAddress.country',
        value: addressObj.country || 'Canada'
      });
    },
    emitUpdate(key, value) {
      let formattedKey = '';

      if (this.isBillingAddress) {
        formattedKey = `billingAddress.${key}`;
      } else {
        formattedKey = `address.${key}`;
      }

      this.$emit('update', { key: formattedKey, value });
    }
  }
};
</script>

<style scoped></style>
