<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">Proxy Options</div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Displayed on proxy form home page
    </div>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="blockUnverifiedVoters"
          @change="onChange('options.blockUnverifiedVoters', $event)"
        />
      </div>
      <div>
        <div class="subheading">Block Unverified Voters</div>
        <div class="grey--text text--darken-2">
          Prevents unverified voters from being able to get past the home page
          on the voting tool.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="blockVoteRecasting"
          @change="onChange('options.blockVoteRecasting', $event)"
        />
      </div>
      <div>
        <div class="subheading">Block Vote Re-casting</div>
        <div class="grey--text text--darken-2">
          Do not allow voters to re-cast their votes (ie. cannot change their
          minds later). If a voter has a verified submission, it must be revoked
          before they can vote again. Use this setting for e-ballots, avoid
          using for proxies.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="blockUnitVoteRecasting"
          @change="onChange('options.blockUnitVoteRecasting', $event)"
        />
      </div>
      <div>
        <div class="subheading">Block Unit Vote Re-casting</div>
        <div class="grey--text text--darken-2">
          Do not allow voters to re-cast their votes if there is a submission
          for their unit (ie. neither Voter A nor Voter B can re-cast their
          votes if one of them has a prior submission for their shared unit). If
          a unit voter has a verified submission, it must be revoked before
          either can vote again. Use this setting for e-ballots, avoid using for
          proxies.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="disableSubmissionDownloads"
          @change="onChange('options.disableProxySubmissionDownloads', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Prevent voters from downloading their proxy submissions
        </div>
        <div class="grey--text text--darken-2">
          Click this to deny voters the ability to download a copy of their
          submission, once their proxy is completed.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="disableProxyDeferrals"
          @change="onChange('options.disableProxyDeferrals', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Disable Proxy Deferrals
        </div>
        <div class="grey--text text--darken-2">
          Click this to remove the "My Proxy Can Choose" and/or "I give my proxy
          authority to vote" options from all matters of business. Recommended
          for Virtual Meetings.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="allowPortalRevocations"
          @change="onChange('options.allowPortalRevocations', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Allow voters to revoke proxies inside the Meeting Portal
        </div>
        <div class="grey--text text--darken-2">
          Click this to allow voters to revoke their unit's proxy submission
          inside the Meeting Portal.<br />If enabled, proxy revocations have to
          be manually CLOSED (via toggle on the Questions tab) before live
          voting can be started.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="displayAdvanceToggle"
          @change="onChange('options.displayAdvanceToggle', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display Advance Voting Toggle
        </div>
        <div class="grey--text text--darken-2">
          Click this to show the toggle to open or close Advance Voting.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="newProxyUrl"
          @change="onChange('options.useGeneralProxyApp', $event)"
        />
      </div>
      <div>
        <h5 class="subheading">
          (Experimental) Use General Proxy app
        </h5>
        <p class="grey--text text--darken-2">
          Click this to collect proxies through the General Proxy app. This app
          should be used for meetings with no advance voting. It will force
          voters to enter their proxy holder's name AND email address.
        </p>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displayCustomGeneralProxyContent"
          @change="onChange('options.displayCustomGeneralProxyContent', $event)"
        />
      </div>

      <div>
        <h5 class="subheading">
          Enable custom content for General Proxy
        </h5>
        <p class="grey--text text--darken-2">
          Check this to add a custom content for general proxy in the proxy
          page.
        </p>
      </div>
    </v-layout>

    <v-layout v-if="displayCustomGeneralProxyContent">
      <v-flex xs12>
        <rich-text-editor
          :hide-merge-menu="true"
          :body="customContent"
          @update="onChange('options.customGeneralProxyContent', $event)"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import defaultContent from '@/lib/default-general-proxy-content';

export default {
  name: 'MeetingSettingsProxyOptionsInput',
  components: { RichTextEditor },
  props: {
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      blockUnverifiedVoters: false,
      blockVoteRecasting: false,
      blockUnitVoteRecasting: false,
      disableSubmissionDownloads: false,
      disableProxyDeferrals: false,
      displayAdvanceToggle: false,
      allowPortalRevocations: false,
      newProxyUrl: false,
      customContent: '',
      displayCustomGeneralProxyContent: false
    };
  },
  mounted() {
    this.blockUnverifiedVoters = this.options.blockUnverifiedVoters;
    this.blockVoteRecasting = this.options.blockVoteRecasting;
    this.blockUnitVoteRecasting = _.get(
      this.options,
      'blockUnitVoteRecasting',
      false
    );
    this.disableSubmissionDownloads = this.options.disableProxySubmissionDownloads;
    this.disableProxyDeferrals = this.options.disableProxyDeferrals;
    this.allowPortalRevocations = this.options.allowPortalRevocations;
    this.displayAdvanceToggle = this.options.displayAdvanceToggle;
    this.newProxyUrl = _.get(this.options, `useGeneralProxyApp`, false);
    this.displayCustomGeneralProxyContent = _.get(
      this.options,
      'displayCustomGeneralProxyContent',
      false
    );

    this.customContent = _.get(
      this.options,
      'customGeneralProxyContent',
      defaultContent
    );
  },
  computed: {},
  methods: {
    onChange(key, value) {
      if (key === 'options.displayCustomGeneralProxyContent' && value) {
        this.$emit('update', {
          key: 'options.customGeneralProxyContent',
          value: this.customContent
        });
      }

      this.$emit('update', { key, value });
    }
  }
};
</script>

<style lang="scss" scoped></style>
