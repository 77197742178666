<template>
  <div>
    <app-header>
      <div slot="top-left-action">
        <router-link to="/meetings">
          <img class="mt-2" src="../assets/gq_navbar_logo.svg" />
        </router-link>
      </div>
    </app-header>

    <v-layout justify-center align-center>
      <h1>404 <br /></h1>
    </v-layout>
    <v-layout justify-center align-center>
      <h3>Page not found <br /></h3>
    </v-layout>
    <v-layout justify-center align-center>
      <v-img
        src="https://www.getquorum.com/assets/images/illustrations/404.png"
        max-height="400"
        max-width="400"
      />
    </v-layout>
    <v-layout justify-center align-center>
      <h2>
        Oops! The page you were looking for does not exist or you do not have
        the required permissions
      </h2>
    </v-layout>
    <v-layout justify-center align-center>
      <v-btn class="primary mt-4" x-large @click.native="handleClick">
        <v-icon> home </v-icon>
        Take me home
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import AppHeader from '@/components/Header';

export default {
  name: 'UnauthenticatedView',
  components: {
    AppHeader
  },
  methods: {
    handleClick() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 170px;
  font-family: 'century gothic';
  font-weight: lighter;
  text-align: center;
  color: #606060;
}
h2 {
  font-size: 30px;
  padding: 20px;
  color: gray;
}
h3 {
  font-size: 70px;
  font-family: 'century gothic';
  font-weight: lighter;
  text-align: center;
  color: #606060;
}
@media only screen and (max-width: 900px) {
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 30px;
  }
}
</style>
