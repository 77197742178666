import http from './requestType';

/**
 * Get partner for the meeting
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function fetchPartner(shortCode) {
  return http._get(`Meetings/${shortCode}/partners`);
}

/**
 * Insert partner in partner meeting model
 * @param  {string}  shortCode    - the meeting shortcode
 * @param  {string}  partnerKey   - partner key
 * @return {Promise}              - Promise
 */
export function insertPartnerMeeting(shortCode, partnerKey) {
  return http._post(`Meetings/${shortCode}/partners/${partnerKey}`);
}

/**
 * Update partner in partner meeting model
 * @param  {string}  shortCode    - the meeting shortcode
 * @param  {string}  partnerKey   - partner key
 * @return {Promise}              - Promise
 */
export function updatePartnerMeeting(shortCode, partnerKey) {
  return http._put(`Meetings/${shortCode}/partners/${partnerKey}`);
}

/**
 * Remove partner meeting model
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function removePartnerMeeting(shortCode) {
  return http._delete(`Meetings/${shortCode}/partners`);
}
