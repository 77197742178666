<template>
  <v-container>
    <v-layout>
      <v-spacer />
      <div>
        <v-switch
          :label="`Advanced Options: ${advancedToggle ? 'ON' : 'OFF'}`"
          v-model="advancedToggle"
        />
      </div>
    </v-layout>

    <!-- Add/Update Question Form -->
    <v-card>
      <v-card-text>
        <v-layout>
          <v-chip
            v-if="isCombinedTally"
            small
            :color="'light-blue'"
            label
            class="white--text mb-3"
          >
            <strong>Advanced and Live Vote tallies will be combined</strong>
          </v-chip>

          <!-- Test Question -->
          <v-chip
            v-if="question.isTest"
            small
            :color="'light-blue'"
            label
            class="white--text mb-3"
            >Test question</v-chip
          >
          <v-spacer></v-spacer>
          <v-chip v-if="votingPeriodExists" small label outline class="mb-3">
            <strong
              >Voting Period:
              {{ question.startAt | formatDate(meetingTimezone) }}
              <timezone-helper :date="question.startAt"/> -
              {{ question.endAt | formatDate(meetingTimezone) }}
              <timezone-helper :date="question.endAt"
            /></strong>
          </v-chip>
        </v-layout>

        <!-- Label -->
        <!-- Given that a live vote question was copied from advanced votes, editing is disabled -->
        <v-text-field
          label="Question"
          placeholder="ex. 'Select the candidate you wish to vote for...'"
          v-model="question.label"
          :rules="rules.question"
          :counter="255"
        />

        <!-- MaxChoices -->
        <v-text-field
          label="Number Of Choices"
          v-model="question.maxChoices"
          :rules="rules.numChoices"
          type="number"
        />

        <!-- Choices -->
        <div>
          <label class="caption">Choices (One option per line)</label>
          <div class="codemirror-container">
            <code-mirror
              :code-array="question.choices"
              @update="onUpdate('choices', $event)"
            />
          </div>
        </div>

        <!-- Owner-Occupied -->
        <div class="mb-3">
          <v-switch
            hide-details
            color="primary"
            label="Owner-Occupied Question"
            v-model="question.ownerOccupied"
            @change="handleToggle('ownerOccupied')"
          />
          <div class="grey--text font-weight-medium">
            {{
              question.ownerOccupied
                ? 'This question is limited to owner-occupied units'
                : 'This question is open to all units (default)'
            }}
          </div>
        </div>

        <div v-if="question.ownerOccupied" class="mb-3">
          <v-switch
            hide-details
            color="primary"
            label="Allow all units to self-declare as Owner-Occupied"
            v-model="defaultOwnerOcc"
            @change="handleToggle('defaultOwnerOcc')"
          />

          <div class="grey--text font-weight-medium">
            <li :class="defaultOwnerOcc ? 'font-weight-bold' : ''">
              ON: all units will be asked "Do you occupy this unit?"
            </li>
            <li :class="defaultOwnerOcc ? '' : 'font-weight-bold'">
              OFF: only units with the custom field "owner_occupied: true" will
              be asked "Do you occupy this unit?"
            </li>
          </div>
        </div>

        <!-- Override Proxy - Experimental Mode Flag-->
        <div
          class="mb-3"
          v-if="
            currentMeeting.options.votingOption === 'Proxy-Ballot' ||
              currentMeeting.options.enableRtvProxyOverride
          "
        >
          <v-switch
            hide-details
            color="primary"
            label="Allow Vote to Override Proxy"
            v-model="question.overrideProxy"
            @change="onUpdate('overrideProxy', $event)"
            :disabled="isOverrideDisabled"
          />
          <div class="grey--text font-weight-medium">
            Enabling this setting will allow units to vote live even if there is
            a proxy on file.
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Delete/Cancel/Update Question buttons -->
    <v-layout class="mt-5">
      <v-btn color="error" flat @click="dialog.delete = true">
        <v-icon>delete_forever</v-icon>delete question
      </v-btn>
      <v-spacer />
      <v-btn :disabled="isButtonDisabled" @click="dialog.cancel = true"
        >cancel</v-btn
      >
      <v-btn
        class="primary"
        :disabled="isButtonDisabled"
        @click="dialog.update = true"
        >update</v-btn
      >
    </v-layout>

    <!-- Restriction Card -->
    <div v-if="advancedToggle" class="mb-3">
      <v-divider class="mt-3" />
      <div class="gq-cell--section-title">Advanced Options</div>
      <question-restrictions-card
        :restrictions="restrictions"
        :custom-data-keys="customData"
        :is-disabled="question.ownerOccupied || defaultOwnerOcc"
        @update="updateRestriction"
        @remove="removeRestriction"
        @removeAll="removeRestrictions"
      />
    </div>

    <!-- Confirmation Dialogs -->
    <!-- Delete button -->
    <v-dialog max-width="500px" v-model="dialog.delete">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <div class="title red--text text--darken-1">
            Delete Question?
          </div>
        </v-card-title>
        <v-card-text>
          <v-alert outline color="error" icon="warning" :value="true">
            <strong>WARNING</strong>: You are about to delete this question.
            Once deleted you cannot undo this action. Click <b>DELETE</b> to
            confirm delete, or click <b>CLOSE</b> to return to editing.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.delete = false">Close</v-btn>
          <v-btn class="error" @click="deleteQuestion">DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Cancel button -->
    <v-dialog max-width="500px" v-model="dialog.cancel">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <div class="title">Cancel Changes?</div>
        </v-card-title>
        <v-card-text>
          Your changes will not be saved. Click
          <b>CANCEL CHANGES</b> to discard unsaved changes, or <b>CLOSE</b> to
          return to editing.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.cancel = false">close</v-btn>
          <v-btn class="error" @click="cancelUpdate">cancel changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update button -->
    <v-dialog max-width="500px" v-model="dialog.update">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <div class="title">Confirm Update?</div>
        </v-card-title>
        <v-card-text>
          Click
          <b>UPDATE</b> to confirm changes, or <b>CLOSE</b> to return to
          editing.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialog.update = false">Close</v-btn>
          <v-btn class="primary" @click="updateQuestion">UPDATE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      title="Are you sure?"
      text="This will determine which owners can self declare as owner occupied."
      :dialog="dialog.defaultOwnerOcc"
      confirm-text="Yes"
      decline-text="No"
      @confirm="handleOwnerOccupiedRestriction($event)"
      @decline="handleOwnerOccupiedRestriction($event)"
    />

    <confirm-dialog
      title="Are you sure?"
      text="This will toggle the Owner-Occupied Question."
      :dialog="dialog.ownerOccupied"
      confirm-text="Yes"
      decline-text="No"
      @confirm="handleOwnerOccupied($event)"
      @decline="handleOwnerOccupied($event)"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CodeMirror from '@/components/CodeMirror';
import TimezoneHelper from '@/components/TimezoneHelper';
import moment from 'moment-timezone';
import _ from 'lodash';

import QuestionRestrictionsCard from '@/components/QuestionRestrictionsCard';

import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

export default {
  name: 'RTVQuestionEditView',
  components: {
    CodeMirror,
    QuestionRestrictionsCard,
    TimezoneHelper,
    ConfirmDialog
  },
  props: {
    rtvQuestionsObject: {
      type: Object,
      required: true
    },
    currentMeeting: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('meetings/rtvQuestions', ['rtvQuestionRestrictions']),
    isButtonDisabled() {
      const num = this.question.maxChoices;
      return (
        !this.question.label ||
        this.question.choices.length === 0 ||
        isNaN(parseInt(num)) ||
        parseInt(num) <= 0
      );
    },
    isCombinedTally() {
      return this.question.question_key;
    },
    votingPeriodExists() {
      return this.question.startAt && this.question.endAt;
    },
    isUnsavedChanges() {
      return !_.isEqual(
        _.omit(this.question, ['startAt', 'endAt']),
        _.omit(this.rtvQuestionsObject[this.questionId], [
          'startAt',
          'endAt',
          'restrictions'
        ])
      );
    },
    hasRestrictions() {
      return this.restrictions.length > 0;
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    isOverrideDisabled() {
      // allow if question has question key value otherwise disable it
      return !_.isEmpty(this.question.question_key);
    }
  },
  created() {
    this.initQuestion();
    this.initRestrictions();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsavedChanges && !this.skipUnsavedChangesCheck) {
      const answer = confirm(
        'You have unsaved changes. Do you want to discard changes and leave this page?'
      );
      answer ? next() : next(false);
    } else {
      next();
    }
  },
  filters: {
    formatDate(date, timeZone) {
      return moment(date)
        .tz(timeZone)
        .format('MMM D, h:mm A zz');
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      questionId: this.$route.params.questionId,
      question: {},
      defaultOwnerOcc: false,
      restrictions: [],
      customData: this.currentMeeting.options.customUnitFields,
      advancedToggle: false,
      dialog: {
        cancel: false,
        update: false,
        delete: false,
        defaultOwnerOcc: false,
        ownerOccupied: false
      },
      skipUnsavedChangesCheck: false,
      rules: {
        question: [v => !!v || 'Question is required'],
        numChoices: [
          v => !isNaN(parseInt(v)) || 'Must be a valid number',
          v => parseInt(v) > 0 || 'Must be greater than zero',
          v => (v.includes && !v.includes('.')) || 'Whole numbers only'
        ]
      }
    };
  },
  methods: {
    ...mapActions('meetings/rtvQuestions', [
      'updateMeetingRTVQuestion',
      'deleteMeetingRTVQuestion',
      'getRTVQuestionRestrictions',
      'createRTVQuestionRestriction',
      'removeRTVQuestionRestriction'
    ]),
    initQuestion() {
      this.question = _.cloneDeep(
        _.omit(this.rtvQuestionsObject[this.questionId], ['restrictions'])
      );
    },
    async initRestrictions() {
      this.restrictions = [];

      // Get available custom keys and existing restrictions
      await this.getRTVQuestionRestrictions({
        shortCode: this.shortCode,
        questionKey: this.question.key
      });

      // If restrictions exist, prepare them for displaying/editing
      if (this.rtvQuestionRestrictions.length > 0) {
        this.advancedToggle = true;

        this.rtvQuestionRestrictions.forEach(restriction => {
          const obj = {
            key: restriction.customField,
            value: restriction.fieldValue
          };
          this.restrictions.push(obj);
        });
      }

      // If the question is OO and open to anyone to self-declare,
      // set the secondary toggle to true
      if (
        this.question.ownerOccupied &&
        !this.restrictions.some(r => r.key === 'owner_occupied')
      ) {
        this.defaultOwnerOcc = true;
      }
    },
    async onUpdate(key, value) {
      switch (key) {
        case 'choices':
          this.question.choices = value.filter(Boolean);
          break;
        case 'ownerOccupied':
          this.question.ownerOccupied = value;
          this.defaultOwnerOcc = value;

          // if question is no longer OO, and the OO custom restriction
          // currently exists, remove it
          if (
            value === false &&
            this.restrictions.some(r => r.key === 'owner_occupied')
          ) {
            const ownerOccRestriction = {
              key: 'owner_occupied',
              value: 'true'
            };
            this.removeRestriction(ownerOccRestriction);
          }
          break;
        case 'ownerOccupiedRestriction':
          if (value) {
            // option one: OO self-declaration open to everyone
            this.question.ownerOccupied = true;
            if (this.rtvQuestionRestrictions.length > 0) {
              await this.removeRestrictions();
            }
          } else {
            // option two: OO self-declaration only open to units w/ 'owner_occupied: true' custom field
            if (this.rtvQuestionRestrictions.length > 0) {
              await this.removeRestrictions();
            }

            await this.updateRestriction(
              {},
              { key: 'owner_occupied', value: 'true' }
            );
            this.advancedToggle = true;
          }
          break;
      }
    },
    async updateRestriction(existingRestriction, newRestriction) {
      try {
        // Determine if we are adding new restriction,
        // or updating existing restriction
        const updating = !_.isEmpty(existingRestriction);

        if (updating) {
          await this.removeRTVQuestionRestriction({
            shortCode: this.shortCode,
            questionKey: this.question.key,
            customField: existingRestriction.key,
            fieldValue: existingRestriction.value
          });
        }

        await this.createRTVQuestionRestriction({
          shortCode: this.shortCode,
          questionKey: this.question.key,
          restriction: {
            questionKey: this.question.key,
            customField: newRestriction.key,
            fieldValue: newRestriction.value
          }
        });

        this.initRestrictions();

        if (updating) {
          this.$events.$emit(
            'toastEvent',
            `Restriction Updated (${newRestriction.key.toUpperCase()}: ${newRestriction.value.toUpperCase()})`
          );
        } else {
          this.$events.$emit(
            'toastEvent',
            `Restriction Added (${newRestriction.key.toUpperCase()}: ${newRestriction.value.toUpperCase()})`
          );
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async removeRestriction(restriction) {
      try {
        await this.removeRTVQuestionRestriction({
          shortCode: this.shortCode,
          questionKey: this.question.key,
          customField: restriction.key,
          fieldValue: restriction.value
        });

        this.initRestrictions();
        this.$events.$emit(
          'toastEvent',
          `Restriction Removed (${restriction.key.toUpperCase()}: ${restriction.value.toUpperCase()})`
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async removeRestrictions() {
      try {
        for (const restriction of this.restrictions) {
          await this.removeRTVQuestionRestriction({
            shortCode: this.shortCode,
            questionKey: this.question.key,
            customField: restriction.key,
            fieldValue: restriction.value
          });
        }

        this.initRestrictions();
        this.$events.$emit('toastEvent', 'Restrictions Removed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateQuestion() {
      try {
        await this.updateMeetingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: this.question.key,
          question: this.question
        });

        this.$events.$emit('toastEvent', 'Question Updated');
        this.returnToListView();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async deleteQuestion() {
      try {
        // First, remove question's restrictions
        await this.removeRestrictions();

        // Second, remove question
        await this.deleteMeetingRTVQuestion({
          shortCode: this.shortCode,
          questionKey: this.question.key
        });

        this.$events.$emit('toastEvent', 'Question Deleted');
        this.returnToListView();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    cancelUpdate() {
      try {
        this.$events.$emit('toastEvent', 'Changes Cancelled');
        this.returnToListView();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    returnToListView() {
      // This function is only called when user has clicked "Delete Question", "Cancel", or "Update".
      // In these 3 cases, we don't have to prompt user with the "You have unsaved changes" dialog
      this.skipUnsavedChangesCheck = true;

      this.$router.push({
        name: 'meetingBusiness',
        params: {
          shortcode: this.shortCode
        }
      });
    },
    handleToggle(key) {
      this.dialog[key] = true;
    },
    async handleOwnerOccupiedRestriction(value) {
      if (value) {
        await this.onUpdate('ownerOccupiedRestriction', this.defaultOwnerOcc);
      } else {
        this.defaultOwnerOcc = !this.defaultOwnerOcc;
      }
      this.dialog.defaultOwnerOcc = false;
    },
    async handleOwnerOccupied(value) {
      if (value) {
        await this.onUpdate('ownerOccupied', this.question.ownerOccupied);
      } else {
        this.question.ownerOccupied = !this.question.ownerOccupied;
      }
      this.dialog.ownerOccupied = false;
    }
  }
};
</script>

<style scoped></style>
