<template>
  <div>
    <v-chip
      disabled
      label
      class="ml-0 mt-3 full-width lighten-1"
      :class="alertExpectedUnits.style"
      text-color="white"
    >
      <v-avatar>
        <v-icon>{{ alertExpectedUnits.icon }}</v-icon>
      </v-avatar>
      {{ stats.totalUnits || '?' }} units imported,
      {{ accountData.numberOfUnits || '?' }} units expected
    </v-chip>
    <v-chip
      disabled
      label
      class="ml-0 mt-0 full-width lighten-1"
      :class="alertValidUnits.style"
      text-color="white"
    >
      <v-avatar>
        <v-icon>{{ alertValidUnits.icon }}</v-icon>
      </v-avatar>
      {{ stats.totalValidUnits || '?' }} of {{ stats.totalUnits || '?' }} units
      have an email
      <div v-if="stats && stats.totalUnits > 0" class="ml-1">
        ({{ ((stats.totalValidUnits / stats.totalUnits) * 100).toFixed(2) }}%)
      </div>
    </v-chip>
    <v-chip
      v-if="
        stats &&
          stats.testVoterStats &&
          stats.testVoterStats.totalTestVoters > 0
      "
      disabled
      label
      class="ml-0 mt-0 full-width lighten-1 error"
      text-color="white"
    >
      <v-avatar>
        <v-icon>warning</v-icon>
      </v-avatar>
      Warning: {{ stats.testVoterStats.totalTestVoters }} test voter{{
        stats.testVoterStats.totalTestVoters === 1 ? '' : 's'
      }}
    </v-chip>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingAlerts',
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      }
    },
    accountData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    alertExpectedUnits() {
      if (this.stats.totalUnits === 0) {
        return {
          style: 'error',
          icon: 'error'
        };
      } else if (
        this.stats.totalUnits === parseInt(this.accountData.numberOfUnits, 10)
      ) {
        return {
          style: 'success',
          icon: 'check'
        };
      } else {
        return {
          style: 'warning',
          icon: 'warning'
        };
      }
    },
    alertValidUnits() {
      if (this.stats.totalUnits === 0) {
        return {
          style: 'error',
          icon: 'error'
        };
      } else if (
        ((this.stats.totalValidUnits / this.stats.totalUnits) * 100).toFixed(
          2
        ) >= 50.0
      ) {
        return {
          style: 'success',
          icon: 'check'
        };
      } else {
        return {
          style: 'primary',
          icon: 'info'
        };
      }
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
