<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="title">Payments</div>
        <v-spacer />
        <div>
          <v-btn outline color="primary" @click.native="addPaymentClicked()"
            >Add Payment</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data.details"
          class="elevation-0"
          hide-actions
          hide-headers
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left capitalize">
              {{ paidOnDate(props.item.createdAt) }}
            </td>
            <td class="text-xs-left capitalize">{{ props.item.category }}</td>
            <td class="text-xs-left">{{ props.item.description }}</td>
            <td class="text-xs-right table-cell--values-width">
              {{ formatValue(props.item.value) }}
              <v-btn
                flat
                icon
                color="grey"
                @click="deleteInvoiceItemClicked(props.item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </template>
          <template slot="footer">
            <td colspan="4" class="text-xs-center grey lighten-5">
              <strong>Total Payments: {{ formatValue(data.total) }}</strong>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <delete-item-dialog
      :is-open="showDeleteDialog"
      resource-name="invoiceItem"
      :resource-key="itemToDelete.key"
      @delete="handleDeleteItem"
      @close-dialog="showDeleteDialog = false"
    >
      <span slot="title">Delete payment?</span>
      <span slot="description">
        <p class="capitalize">
          <b>{{ itemToDelete.type }}:</b> {{ itemToDelete.category }}
        </p>
        <p><b>Description:</b> {{ itemToDelete.description }}</p>
        <p><b>Value:</b> {{ itemToDelete.value }}</p>
      </span>
    </delete-item-dialog>

    <add-item-dialog
      :is-open="showAddDialog"
      :invoice-key="invoiceKey"
      item-type="payment"
      :item-options="itemOptions"
      :amount-owing="amountOwing"
      @paymentEmail="openPaymentReceivedDialog"
      @close="closeDialogs"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

import { formatCurrencyValue } from '@/helpers';
import selectOptions from '@/lib/billing-select-options.json';

import AddItemDialog from './AdminInvoiceViewPaymentsAddDialog';
import DeleteItemDialog from '@/components/dialogs/DeleteResourceDialog';

export default {
  name: 'AdminInvoiceViewPaymentsTable',
  components: {
    AddItemDialog,
    DeleteItemDialog
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    invoiceKey: {
      type: String,
      default: ''
    },
    amountOwing: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      showAddDialog: false,
      showDeleteDialog: false,
      itemToDelete: { key: '' },
      headers: [
        { text: 'Date', value: 'createdAt', align: 'left' },
        { text: 'Paid By', value: 'category', align: 'left' },
        { text: 'Description', value: 'description', align: 'left' },
        { text: 'Amount', value: 'value', align: 'left' }
      ]
    };
  },
  created() {
    this.itemOptions = selectOptions['payment'];
  },
  methods: {
    ...mapActions('invoices', ['deleteItemFromInvoice']),
    paidOnDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    addPaymentClicked() {
      this.showAddDialog = true;
    },
    deleteInvoiceItemClicked(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    closeDialogs() {
      this.showDeleteDialog = false;
      this.showAddDialog = false;
      this.itemToDelete = { key: '' };
    },
    formatValue(val) {
      return formatCurrencyValue(val);
    },
    async handleDeleteItem(itemKey) {
      try {
        await this.deleteItemFromInvoice({
          itemKey,
          key: this.invoiceKey
        });
        this.$events.$emit('toastEvent', 'Item Deleted');
        this.closeDialogs();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    openPaymentReceivedDialog() {
      this.$emit('paymentDialog');
    }
  }
};
</script>

<style scoped></style>
