<template>
  <v-dialog scrollable :max-width="maxWidth" :value="value" @input="emitInput">
    <v-card>
      <!-- Header: toggle between recipients -->
      <v-card-title v-if="!showTestVoters">
        <span v-if="subject !== ''" class="subject-truncate body-2"
          >Subject: {{ subject }}</span
        >
        <v-spacer />
        <div v-if="showPrevButton">
          <v-btn icon flat @click="emitPreview('prev')"
            ><v-icon>skip_previous</v-icon></v-btn
          >
        </div>
        <div v-if="recipientTotal == 0" class="flex xs2">
          <span class="body-2" v-if="!recipientInfoHidden"
            >** Sample Voter **</span
          >
        </div>
        <div v-else>
          <span class="body-2"
            >{{ recipientCount + 1 }} of {{ recipientTotal }}</span
          >
        </div>
        <div v-if="showNextButton">
          <v-btn icon flat @click="emitPreview('next')"
            ><v-icon>skip_next</v-icon></v-btn
          >
        </div>
        <v-btn icon flat @click.stop="emitClose"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <!-- Header: show test voters dropdown -->
      <v-card-title v-else>
        <v-layout row align-center>
          <v-flex xs5 class="mr-3">
            <span v-if="subject !== ''" class="body-2"
              >Subject: {{ subject }}</span
            >
          </v-flex>

          <v-flex xs6>
            <v-select
              v-if="isAdmin || testVoters.length > 0"
              v-model="testVoter"
              :items="testVoters"
              label="Test Voter"
              placeholder="No test voters exist for this campaign."
              item-text="email"
              item-value="key"
              return-object
              dense
              hint="This is the voter used for testing any proxy or nominations tools."
              persistent-hint
              @change="emitSetTestVoter"
            ></v-select>
          </v-flex>

          <v-flex xs1>
            <v-btn icon flat @click.stop="emitClose">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider />

      <iframe
        ref="previewIframe"
        class="html-preview-iframe"
        :class="containerStyles"
        :srcdoc="html"
        @load="onIframeLoad"
      />

      <v-divider />
      <v-card-text>
        <div class="mb-3" v-if="attachments">
          <v-layout row v-for="file in attachments" :key="file.key">
            <v-chip
              small
              color="grey lighten-4"
              @click="emitDownloadFile(file.package)"
            >
              <v-icon left>attachment</v-icon>
              {{ file.package.name }}
            </v-chip>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewPdfButton from '@/components/ViewPdfButton';

export default {
  name: 'HtmlPreviewDialog',
  components: {
    ViewPdfButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '850px'
    },
    subject: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    },
    attachments: {
      type: [Array, Object],
      default: null
    },
    recipientCount: {
      type: Number,
      default: null
    },
    recipientTotal: {
      type: Number,
      default: null
    },
    containerStyles: {
      type: String,
      default: ''
    },
    recipientInfoHidden: {
      type: Boolean,
      default: false
    },
    showTestVoters: {
      type: Boolean,
      default: false
    },
    testVoters: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    showPrevButton() {
      if (!this.recipientCount == 0) {
        return true;
      } else {
        return false;
      }
    },
    showNextButton() {
      if (
        this.recipientTotal >= 1 &&
        this.recipientCount + 1 != this.recipientTotal
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    value() {
      // On dialog open, set the default test voter
      if (this.testVoters.length > 0) {
        this.setDefaultTestVoter();
      }
    }
  },
  data() {
    return {
      testVoter: {}
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitPreview(direction) {
      // Retrieve the requested preview
      if (direction === 'prev') {
        this.$emit('previewPrev');
      } else if (direction === 'next') {
        this.$emit('previewNext');
      }
    },
    emitDownloadFile(file) {
      this.$emit('downloadFile', file);
    },
    emitSetTestVoter(testVoter) {
      this.$emit('setTestVoter', testVoter);
    },
    setDefaultTestVoter() {
      this.testVoter = this.testVoters[0];
      this.emitSetTestVoter(this.testVoter);
    },
    onIframeLoad() {
      this.$nextTick(() => {
        const iframe = this.$refs.previewIframe;
        if (!iframe) return;

        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const scrollHeight = iframeDocument.documentElement.scrollHeight;

        iframe.style.height = `${scrollHeight}px`;
      });
    }
  }
};
</script>

<style scoped>
.html-preview-iframe {
  border: 0px;
  width: 100%;
  min-height: 200px;
  max-height: 900px;
  overflow-y: auto;
}
.subject-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
</style>

<style>
table .btn.btn-primary {
  background-color: #ffffff !important;
  box-shadow: none !important;
}
</style>
