<template>
  <div id="meetings-view">
    <!-- New my dashbaord layout (booking system)-->
    <v-container v-if="newLayoutAccess" fluid grid-list-lg>
      <!-- Intro Component -->
      <v-layout row wrap align-center>
        <v-flex xs12>
          <meetings-view-intro />
        </v-flex>
      </v-layout>

      <v-layout row gab>
        <v-flex xs7>
          <meetings-view-toolbar
            v-if="isAdmin"
            :search-query="meetingListSearchQuery"
            :results="meetingListData.length"
            placeholder="Search for a meeting"
            @search="updateSearchQuery"
          />

          <!-- Loaded and no data -->
          <div v-if="!isLoading && meetingListData.length === 0">
            <v-layout
              v-show="meetingListData.length === 0"
              row
              class="fill-height"
            >
              <v-flex xs12 class="text-xs-center fill-height-center">
                <p class="title">
                  No campaigns found.
                </p>
                <p>
                  Please contact
                  <a
                    class="accent--text"
                    target="_blank"
                    href="mailto:support@getquorum.com"
                    >support@getquorum.com</a
                  >
                  if you require assistance.
                </p>
              </v-flex>
            </v-layout>
          </div>

          <!-- Loaded and data -->
          <div v-else>
            <!-- Meeting Card View -->
            <meetings-view-cards
              v-if="meetingListData.length > 0"
              :campaigns="meetingListData"
              :new-layout="true"
              :search-query="meetingListSearchQuery"
            />
          </div>
        </v-flex>
        <v-spacer />
        <v-flex xs5>
          <meetings-requested-view :requests-list="requestsList" />
        </v-flex>
      </v-layout>
    </v-container>

    <!-- Intro content -->
    <v-container v-else style="max-width: 1200px;">
      <!-- toolbar -->
      <!-- TODO: Add back for non-admins -->
      <meetings-view-toolbar
        v-if="isAdmin"
        :search-query="meetingListSearchQuery"
        :results="meetingListData.length"
        @search="updateSearchQuery"
      />

      <!-- Loaded and no data -->
      <div v-if="!isLoading && meetingListData.length === 0">
        <v-layout v-show="meetingListData.length === 0" row class="fill-height">
          <v-flex xs12 class="text-xs-center fill-height-center">
            <p class="title">
              No campaigns found.
            </p>
            <p>
              Please contact
              <a
                class="accent--text"
                target="_blank"
                href="mailto:support@getquorum.com"
                >support@getquorum.com</a
              >
              if you require assistance.
            </p>
          </v-flex>
        </v-layout>
      </div>

      <!-- Loaded and data -->
      <div v-else class="mt-3">
        <!-- Meeting Card View -->
        <meetings-view-cards
          class="mt-3"
          v-if="meetingListData.length > 0"
          :campaigns="meetingListData"
          :search-query="meetingListSearchQuery"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import MeetingsViewIntro from './MeetingsViewIntro';
import MeetingsViewToolbar from './MeetingsViewToolbar';
import MeetingsViewCards from './MeetingsViewCards';
import MeetingsRequestedView from './MeetingsRequestedView';

import { handleError } from '@/helpers';

export default {
  name: 'MeetingsView',
  components: {
    MeetingsViewIntro,
    MeetingsViewToolbar,
    MeetingsViewCards,
    MeetingsRequestedView
  },
  props: {},
  data() {
    return {
      isLoading: false,
      initialLoadClock: null
    };
  },
  computed: {
    ...mapGetters(['login']),
    ...mapGetters('meetings', [
      'meetingListSearchQuery',
      'meetingListData',
      'meetingListDataLastFetchedAt'
    ]),
    ...mapGetters(['isAdmin']),
    ...mapGetters('accounts', ['accountListData']),
    ...mapGetters('meetings/requests', ['requestsList']),

    newLayoutAccess() {
      const onAccounts = this.accountListData.filter(a => a.region === 'ON');
      return (
        this.accountListData.length > 0 &&
        onAccounts.length > 0 &&
        this.requestsList &&
        this.requestsList.length > 0 &&
        !this.isAdmin
      );
    }
  },
  created() {
    this.init();
    this.clearMeetingState();
  },
  mounted() {
    let self = this;
    // Update the data age clock every minute
    setInterval(function() {
      self.updateInitialLoadedClock();
    }, 60000);

    self.fetchActiveRequests();
  },
  methods: {
    ...mapActions('meetings', [
      'getMeetingListData',
      'setSearchQuery',
      'clearMeetingState',
      'resetMeetingListToInitial'
    ]),
    ...mapActions('meetings/requests', ['getMeetingRequests']),

    async init() {
      try {
        // Check if we already have cached data, if so do not fetch
        // from API
        if (this.meetingListDataLastFetchedAt) {
          return this.updateInitialLoadedClock();
        }

        // Otherwise it's not cached, we'll want to fetch the initial data
        // from API
        await this.fetchInitialData();
      } catch (err) {
        console.error('Error loading meeting list data', err);
        handleError(err);
        throw err;
      }
    },
    async fetchInitialData() {
      try {
        this.isLoading = true;

        // Clear the search query so as not to confuse anyone when the results
        // re-renders
        this.setSearchQuery('');

        // Initial data contains recently completed meetings
        await this.getMeetingListData({
          dateRangeStart: moment()
            .add(-2, 'days')
            .format('YYYY-MM-DD'),
          limit: 15,
          isInitial: true
        });
        this.updateInitialLoadedClock();
      } catch (err) {
        console.error('Error refreshing meeting list data', err);
        handleError(err);
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    async updateSearchQuery(query) {
      try {
        await this.setSearchQuery(query);

        // If we're reseting/clearing the query, switch back to
        // using the initial data load to reduce the need to
        // fetch from the API again.
        if (!query || query.trim() === '') {
          return await this.resetMeetingListToInitial();
        }

        // Otherwise we're searching
        this.loading = true;
        await this.getMeetingListData({ searchText: query, limit: 15 });
        this.updateInitialLoadedClock();
      } catch (err) {
        console.error('Error searching for meetings', err);
        handleError(err);
        throw err;
      } finally {
        this.loading = false;
      }
    },
    updateInitialLoadedClock() {
      this.initialLoadClock = moment(
        this.meetingListDataLastFetchedAt
      ).fromNow();
    },
    async fetchActiveRequests() {
      try {
        // Initial data contains recently completed meetings
        this.activeRequests = await this.getMeetingRequests({
          status: 'pending'
        });
      } catch (err) {
        console.error('Error getting active requests', err);
        handleError(err);
        throw err;
      }
    }
  }
};
</script>

<!-- Include stylesheet for multiselect -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/common.scss';

.fill-height {
  height: 50vh;
}

.fill-height-center {
  align-self: center;
}
</style>
