'use strict';

/** Initialize the Helpscout Beacon and attach it to the global
 * `window` scope.
 */
function init() {
  // Lazy load the Helpscout Beacon Code
  if (typeof window.Beacon == 'undefined') {
    console.log('Initializing Helpscout Beacon');

    var script = document.createElement('script');
    script.id = 'beacon';
    script.text = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
    document.head.appendChild(script);

    window.Beacon('init', process.env.VUE_APP_HELPSCOUT_BEACON_ID);

    window.Beacon('config', {
      mode: 'askFirst',
      enableFabAnimation: false,
      color: '#0861a1',
      display: {
        style: 'manual',
        position: 'right'
      },
      hideFABOnMobile: true,
      messaging: {
        chatEnabled: true,
        contactForm: {
          showName: true,
          showSubject: true,
          allowAttachments: true
        }
      }
    });
  }
}

/** Identify the user on Beacon
 * @param {Object}  user
 * @param {String}  user.name       user name
 * @param {String}  user.email      user email
 */
function identify({ name = 'name', email } = {}) {
  if (typeof window.Beacon !== 'undefined') {
    console.log('Identifying beacon user', name, email);
    window.Beacon('identify', { name, email });
  }
}

/**
 * Destroy the beacon and remove from global scope in case we need to
 * login again.
 */
function destroy() {
  if (typeof window.Beacon !== 'undefined') {
    window.Beacon('destroy');
    delete window.Beacon;
  }
}

/**
 * Popup a beacon message
 */
function showMessage(messageId) {
  if (typeof window.Beacon !== 'undefined') {
    console.log('Show beacon message', messageId);
    window.Beacon('show-message', messageId, {
      force: true,
      delay: 1000
    });
  }
}

function toggle() {
  if (typeof window.Beacon !== 'undefined') {
    window.Beacon('toggle');
  }
}

export default { init, identify, destroy, showMessage, toggle };
