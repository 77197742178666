<template>
  <v-card>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="polls"
      item-key="id"
      select-all
      :loading="loading"
      :expand="expand"
      hide-actions
    >
      <template slot="progress">
        <v-progress-linear
          height="2"
          color="blue"
          indeterminate
        ></v-progress-linear>
      </template>
      <template slot="items" slot-scope="props">
        <tr
          :class="{ 'expand-row-style': props.expanded === true }"
          @click="props.expanded = !props.expanded"
        >
          <td>
            <v-checkbox
              v-model="props.selected"
              primary
              hide-details
              @click.native.stop=""
            />
          </td>
          <td>{{ props.item.title }}</td>
          <td>
            {{ props.item.questions ? props.item.questions.length : 0 }}
            question(s)
          </td>
          <td class="text-xs-right">
            <v-btn @click.native.stop="editPoll(props.item)" icon>
              <v-icon color="grey darken-2">
                edit
              </v-icon>
            </v-btn>
            <v-btn @click.native.stop="removePoll(props.item)" icon>
              <v-icon color="grey darken-2">
                delete
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-card flat>
          <v-card-text>
            <v-layout class="ml-5">
              <ol>
                <li
                  v-for="question in props.item.questions"
                  :key="question.name"
                >
                  <p>
                    <b>{{ question.name }}</b> ({{ question.type }} choice)
                    <br />
                    Answers: {{ question.answers.join(', ') }}
                  </p>
                </li>
              </ol>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:no-data>
        No polls yet. If you think this is a mistake, please check the Webinar
        directly in Zoom.
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="accent"
        @click.native="bulkRemovePolls"
        :loading="loading"
        :disabled="selected.length <= 0"
        >Delete Polls</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MeetingWebcastViewPollsTable',
  components: {},
  props: {
    polls: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  watch: {},
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      selected: [],
      expand: true,
      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Total Questions', value: '', sortable: false },
        { text: '', value: '', align: 'right', sortable: false }
      ],
      config: {
        pagination: { sortBy: 'title' }
      }
    };
  },
  methods: {
    editPoll(poll) {
      this.$emit('edit', poll);
    },
    removePoll(poll) {
      this.$emit('remove', poll);
    },
    bulkRemovePolls() {
      this.$emit('bulk-remove', this.selected);
    },
    addDefaultPolls() {
      this.$emit('add-defaults', this.selected);
    }
  }
};
</script>

<style scoped>
.expand-row-style {
  background-color: #eeeeee;
}
</style>
