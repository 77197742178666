<template>
  <v-container fluid grid-list-lg>
    <v-layout row wrap v-if="loading.meeting">
      <v-flex xs12 class="h-flex">
        <v-progress-circular indeterminate color="primary" />
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else-if="!loading.meeting && currentMeeting">
      <v-flex xs12 sm6 md3>
        <submissions-card
          :hasSecretBusiness="hasSecretBusiness"
          @openReportOptions="dialogs.reportOptions = true"
        />
      </v-flex>
      <v-flex xs12 sm6 md3>
        <quorum-card :quorumMinimum="currentMeeting.quorumMinimum" />
      </v-flex>
      <v-flex xs12 sm12 md6>
        <meeting-stats
          :options="currentMeeting.options"
          @openReportOptions="dialogs.reportOptions = true"
        />
      </v-flex>
      <v-flex xs12 sm12 md6>
        <dates-webcast-card
          :loading="loading.webcast"
          :webcast="webcast"
          :expiryDate="currentMeeting.expiryDate"
          :hasWebcast="hasWebcast"
          :isIntegratedPortalEnabled="isIntegratedPortalEnabled"
          :meetingDate="currentMeeting.meetingDate"
          :meetingDuration="currentMeeting.duration"
          :meetingTimezone="currentMeeting.meetingTimezone"
          :can-see-webcast="canSeeWebcast"
          :can-edit-webcast="canEditWebcast"
        />
      </v-flex>
      <v-flex v-if="canSeeDocuments" xs12 sm12 md6>
        <details-documents-card
          :meetingLocation="currentMeeting.location"
          :contactEmail="contact.email"
          :canEditDocuments="canEditDocuments"
        />
      </v-flex>
      <v-flex xs12>
        <meeting-business :can-view-early="canSeeSecretTallyData" />
      </v-flex>
    </v-layout>

    <report-options-dialog
      v-if="currentMeeting"
      :is-open="dialogs.reportOptions"
      :contact-name="contact.name"
      :contact-email="contact.email"
      :is-secret="hasSecretBusiness"
      @send="sendVoteClosedReport"
      @close="dialogs.reportOptions = false"
    />

    <export-files-dialog
      :is-open="showExportDialog"
      :processing="isExportProcessing"
      :error="exportDialogError"
      :email-to="recipientEmail"
      @close-dialog="closeExportDialog"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import checkScope from '@/lib/check-user-scopes';
import SubmissionsCard from './SubmissionsCard';
import QuorumCard from './QuorumCard';
import MeetingStats from './MeetingStats';
import DatesWebcastCard from './DatesWebcastCard';
import DetailsDocumentsCard from './DetailsDocumentsCard';
import MeetingBusiness from './MeetingBusiness';
import ExportFilesDialog from '@/components/dialogs/ExportFilesDialog';
import ReportOptionsDialog from '@/components/dialogs/ReportOptionsDialog';

export default {
  name: 'MeetingOverviewV2',
  components: {
    SubmissionsCard,
    QuorumCard,
    MeetingStats,
    DatesWebcastCard,
    DetailsDocumentsCard,
    MeetingBusiness,
    ExportFilesDialog,
    ReportOptionsDialog
  },
  data() {
    return {
      dialogs: {
        reportOptions: false
      },
      loading: {
        meeting: true,
        webcast: true
      },
      recipientEmail: '',
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('v2/meetings', ['meetingByShortCode']),
    ...mapGetters('v2/webcasts', ['webcast']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),
    ...mapGetters('meetings/reports', [
      'showExportDialog',
      'isExportProcessing',
      'exportDialogError'
    ]),
    currentMeeting() {
      return this.meetingByShortCode(this.$route.params.shortcode);
    },
    canSeeDocuments() {
      return this.isAdmin || this.handleScope('meeting.documents.read');
    },
    canSeeWebcast() {
      return this.isAdmin || this.handleScope('meeting.webcast.read');
    },
    canEditWebcast() {
      return this.isAdmin || this.handleScope('meeting.webcast.update');
    },
    canSeeSecretTallyData() {
      return this.isAdmin || this.handleScope('meeting.elections.view.early');
    },
    contact() {
      return this.currentMeeting ? this.currentMeeting.contact : {};
    },
    isVotingClosed() {
      return (
        moment(this.currentMeeting.expiryDate).isBefore() ||
        moment(this.currentMeeting.meetingDate).isBefore()
      );
    },
    hasSecretBusiness() {
      return (
        _.find(this.meetingBusinessData, { isSecret: true }) &&
        !this.isVotingClosed
      );
    },
    hasWebcast() {
      return !!this.webcast;
    },
    isIntegratedPortalEnabled() {
      return _.get(this.currentMeeting, 'options.enableRtvZoom', false);
    },
    canEditDocuments() {
      const isMeeting =
        _.get(this.currentMeeting, 'options.type', '') === 'meeting';
      const canUploadFiles =
        this.isAdmin || this.handleScope('meeting.documents.create');
      return isMeeting && canUploadFiles;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('v2/meetings', ['fetchMeeting']),
    ...mapActions('meetings/webcast', ['fetchWebcastDetails']),
    ...mapActions('meetings/reports', ['exportReport', 'closeExportDialog']),
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    async init() {
      try {
        this.loading.meeting = true;
        await this.fetchMeeting({ shortCode: this.shortCode });

        // Don't await for faster load times
        // Get initial business data
        this.getMeetingBusiness({ shortCode: this.shortCode });

        if (this.canSeeWebcast) {
          this.loading.webcast = true;
          await this.fetchWebcastDetails({ shortCode: this.shortCode });
          this.loading.webcast = false;
        }
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      } finally {
        this.loading.meeting = false;
      }
    },
    sendVoteClosedReport({
      type,
      mode,
      recipient,
      includeVoters,
      includeVote,
      exportAsBallot
    } = {}) {
      this.recipientEmail = recipient.email;
      this.exportReport({
        shortCode: this.shortCode,
        type,
        mode,
        recipient,
        includeVoters,
        includeVote,
        exportAsBallot
      });
      this.dialogs.reportOptions = false;
    },
    handleScope(scope) {
      return checkScope(this.scopes, this.shortCode, scope);
    }
  }
};
</script>

<style scoped>
.h-flex {
  display: flex;
  justify-content: center;
}
</style>
