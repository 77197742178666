<template>
  <div>
    <h3>Subscription Details</h3>
    <v-divider class="primary mt-2 mb-4" />
    <div class="body-2 grey--text">Status</div>
    <span class="capitalize">{{ formatStatus(subscription.status) }}</span>

    <div class="body-2 grey--text mt-2">Product</div>
    {{ subscription.productName }} ({{ subscription.productQuantity }} units)

    <div class="body-2 grey--text mt-2">
      Base Price ({{ subscription.currency }})
    </div>
    {{ formatCurrency(subscription.price) }}

    <div class="body-2 grey--text mt-2">Discount</div>
    {{ subscription.discountDescription }}

    <div class="body-2 grey--text mt-2">
      Total Price w/ Discount ({{ subscription.currency }})
    </div>
    {{ formatCurrency(total) }}

    <div class="body-2 grey--text mt-2">Start Date</div>
    {{ subscription.startDate | formatDateHumanReadableAlt }}

    <div class="body-2 grey--text mt-2">End Date</div>
    <span>
      {{ subscription.endDate | formatDateHumanReadableAlt }}
    </span>

    <div class="body-2 grey--text mt-2">Purchase Order</div>
    <span>
      {{ subscription.purchaseOrder || '-' }}
    </span>
    <div class="body-2 grey--text mt-2">Remaining Periods</div>
    <span>
      {{ subscription.remainingTerms || 0 }}
    </span>
    <div class="body-2 grey--text mt-2">Next Billing</div>
    <span>
      {{ subscription.nextBillingAt | formatDateHumanReadableAlt }}
    </span>
  </div>
</template>

<script>
import { formatDateHumanReadableAlt } from '@/filters';

export default {
  name: 'SubscriptionDetailsCard',
  props: {
    subscription: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  filters: {
    formatDateHumanReadableAlt
  },
  computed: {
    total() {
      if (!this.subscription.invoiceDiscount) {
        return this.subscription.price;
      }
      return this.subscription.price - this.subscription.invoiceDiscount;
    }
  },
  methods: {
    formatCurrency(value) {
      return value
        ? value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })
        : 0;
    },
    formatStatus(value) {
      if (value === 'non_renewing') {
        return 'Active (Non-Renewing)';
      }
      return value;
    }
  }
};
</script>
