<template>
  <div>
    <!-- hubspot info -->
    <div class="mb-3">
      <admin-account-hubspot-view-info :company-id="companyId" />
    </div>

    <v-layout row nowrap align-center class="ma-0 pa-0">
      <h4 class="body-2">Account Information</h4>
      <v-btn small icon light @click.native="emitEdit">
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Customer Name</div>
          {{ nameShort }} <br />
          <span class="caption">{{ name }}</span>
        </v-layout>
        <v-layout v-if="customerType" column class="mb-3">
          <div class="body-2 grey--text">Customer Type</div>
          <span class="capitalize">
            {{ customerType }}
          </span>
        </v-layout>
        <v-layout v-if="region" column class="mb-3">
          <div class="body-2 grey--text">Region</div>
          <span class="uppercase">
            {{ region }}
          </span>
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Nickname</div>
          {{ data.nickname || 'n/a' }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Neighbourhood</div>
          {{ data.neighbourhood || 'n/a' }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Size</div>
          {{ data.numberOfUnits || 'n/a' }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">URL</div>
          {{ data.url || 'n/a' }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Fiscal Year End</div>
          {{ fiscalYearEnd }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Client Logo</div>
          <img :src="logoUrl" width="100" />
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Main Contact</div>
          {{ contact.name }} ({{ contact.preferredName }})<br />
          E: {{ contact.email }} <br />
          P: {{ contact.phone || 'n/a' }}<br />
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Billing Currency</div>
          {{ currency }}<br />
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Billing Contact</div>
          {{ (data.billingContact && data.billingContact.name) || 'n/a' }} ({{
            (data.billingContact && data.billingContact.preferredName) || 'n/a'
          }})<br />
          E: {{ (data.billingContact && data.billingContact.email) || 'n/a' }}
          <br />
          P: {{ (data.billingContact && data.billingContact.phone) || 'n/a'
          }}<br />
          Preferred billing method:
          {{ preferredBillingMethod }}<br />
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Billing instructions</div>
          {{
            (data.billingContact && data.billingContact.instructions) || 'n/a'
          }}
        </v-layout>

        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Timezone</div>
          {{ contact.timezone || 'n/a' }}
        </v-layout>
        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Address</div>
          {{ address.address }} <br />{{ address.city }} {{ address.prov }}
          {{ address.country }}<br />
          {{ address.postal }}
        </v-layout>

        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Return Address</div>
          {{ returnAddress.address }} <br />{{ returnAddress.city }}
          {{ returnAddress.prov }} {{ returnAddress.country }}<br />
          {{ returnAddress.postal }}
        </v-layout>

        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Billing Address</div>
          {{ billingAddress.address }} <br />{{ billingAddress.city }}
          {{ billingAddress.prov }} {{ billingAddress.country }}<br />
          {{ billingAddress.postal }}
        </v-layout>

        <v-layout column class="mb-3">
          <div class="body-2 grey--text">Email Signature</div>
          <div v-html="data.signature"></div>
        </v-layout>

        <v-layout column class="mb-3">
          <v-layout align-center class="body-2 grey--text">
            <v-icon v-if="vbmTrial.value">
              check_box
            </v-icon>
            Virtual Board Meeting Trial
          </v-layout>
          <template v-if="vbmTrial.value">
            <div v-if="vbmTrial.startDate">
              Start date: {{ vbmTrial.startDate | dateFormatReadable }}
            </div>
            <div v-if="vbmTrial.endDate">
              End date: {{ vbmTrial.endDate | dateFormatReadable }}
            </div>
          </template>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import AdminAccountHubspotViewInfo from './AdminAccountHubspotViewInfo';
import * as fyeHelper from '@/lib/fiscal-year-end';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'AdminAccountViewInfo',
  components: {
    AdminAccountHubspotViewInfo
  },
  props: {
    name: { type: String, default: '' },
    nameShort: { type: String, default: '' },
    customerType: { type: String, default: '' },
    region: { type: String, default: '' },
    logoUrl: { type: String, default: '' },
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    address: {
      type: Object,
      default() {
        return {};
      }
    },
    returnAddress: {
      type: Object,
      default() {
        return {};
      }
    },
    billingAddress: {
      type: Object,
      default() {
        return {};
      }
    },
    currency: {
      type: String,
      default() {
        return {};
      }
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    companyId: {
      type: String,
      default: ''
    }
  },
  filters: {
    dateFormatReadable
  },
  computed: {
    fiscalYearEnd() {
      const fye = _.get(this.data, 'fiscalYearEndMonth', null);

      // No Fiscal Year end month supplied
      if (!fye) {
        return 'n/a';
      }

      try {
        return fyeHelper.getFyeFromMonthNum(fye);
      } catch (err) {
        throw err;
      }
    },
    preferredBillingMethod() {
      const preferredMethod = this.data.billingContact?.preferredMethod;
      const otherMethod = this.data.billingContact?.otherMethod;

      if (preferredMethod === 'letter_mail') {
        return 'Letter Mail';
      } else if (preferredMethod === 'other') {
        return otherMethod;
      } else {
        return 'Email';
      }
    },
    vbmTrial() {
      return _.get(this.data, 'vbmTrial', {
        value: false,
        startDate: null,
        endDate: null
      });
    }
  },
  methods: {
    emitEdit() {
      this.$emit('edit');
    }
  }
};
</script>

<style scoped>
.greyed {
  color: rgba(0, 0, 0, 0.54);
}
</style>
