<template>
  <v-layout row wrap align-center>
    <v-flex xs12>
      <v-card class="round-card">
        <v-card-text>
          <div class="h-flex ">
            <v-avatar size="30" color="#e7f2fb" class="mr-2 start">
              <v-icon color="#3874CB" size="20">mark_email_read</v-icon>
            </v-avatar>
            <div>
              <h6 class="subheading font-weight-bold">RSVPs</h6>
              <div class="h-flex py-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.attendingCount }}</b> Total RSVPs
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  <b>({{ stats.attendingSharesCount }}</b> by Shares)
                </p>
                <p class="mb-0 grey--text " v-if="showGroups">
                  <b>({{ stats.attendingCountGroups }}</b> by Groups)
                </p>
              </div>
              <div class="h-flex">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.attendingNotVotedCount }}</b> Eligible RSVPs
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  (<b>{{ stats.attendingNotVotedSharesCount }}</b> by Shares)
                </p>
                <p class="mb-0 grey--text " v-if="showGroups">
                  (<b>{{ stats.attendingNotVotedCountGroups }}</b> by Groups)
                </p>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12>
      <v-card class="round-card">
        <v-card-text>
          <div class="h-flex ">
            <v-avatar size="30" color="#e7f2fb" class="mr-2 start">
              <v-icon color="#3874CB" size="20">event_available</v-icon>
            </v-avatar>
            <div>
              <h6 class="subheading font-weight-bold">Attendance</h6>
              <div class="h-flex my-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.attendanceCount }}</b> Units in attendance
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  (<b>{{ stats.attendanceSharesCount }}</b> by Shares)
                </p>
                <p class="mb-0 grey--text " v-if="showGroups">
                  (<b>{{ stats.attendanceCountGroups }}</b> by Groups)
                </p>
              </div>
              <div class="h-flex my-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.attendedNotVotedCount }}</b> Eligible units in
                  attendance
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  (<b>{{ stats.attendedNotVotedSharesCount }}</b> by Shares)
                </p>
                <p class="mb-0 grey--text " v-if="showGroups">
                  (<b>{{ stats.attendedNotVotedCountGroups }}</b> by Groups)
                </p>
              </div>
              <div class="h-flex my-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{
                    (stats.rtvPresence && stats.rtvPresence.numUnits) || 0
                  }}</b>
                  Units Online (Live App)
                </p>
              </div>
              <div class="h-flex my-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.totalArrearsUnitAttendance }}</b> Total eligible
                  arrears units in attendance
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  (<b>{{ stats.totalArrearsShareAttendance }}</b> by Shares)
                </p>
              </div>
              <div class="h-flex">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.totalInPersonUnits }}</b> Eligible In-Person Units
                </p>
                <p class="mb-0 grey--text pr-2" v-if="showSecondary">
                  (<b>{{ stats.totalInPersonShares }}</b> by Shares)
                </p>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12>
      <v-card class="round-card">
        <v-card-text>
          <div class="h-flex ">
            <v-avatar size="30" color="#e7f2fb" class="mr-2 start">
              <v-icon color="#3874CB" size="20">inventory</v-icon>
            </v-avatar>
            <div>
              <h6 class="subheading font-weight-bold">Other</h6>
              <div class="h-flex py-2">
                <p class="mb-0 subheading pr-2">
                  <b>{{ stats.totalConsents }}</b> Total E-Consents
                </p>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingStats',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('v2/meetings', ['stats']),
    showSecondary() {
      return this.options.displaySecondaryMetric;
    },
    showGroups() {
      return this.options.displayCustomGroups;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('v2/meetings', ['fetchMeetingStats']),
    async init() {
      try {
        this.fetchMeetingStats({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.h-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.start {
  align-self: flex-start;
}

.round-card {
  border-radius: 7px;
}
</style>
