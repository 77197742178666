<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">Custom Fields</div>
    <div class="body-1 grey--text text--darken-2 mb-1">
      Create custom fields for use in Notices or the Real Time Voting app.
      Select from a common example below, or type your own:
    </div>

    <!-- "Common example" buttons -->
    <span
      class="text-center mb-3"
      v-for="commonField in commonFields"
      :key="commonField"
    >
      <v-btn
        round
        :disabled="disableCommonFieldButton(`${commonField}`)"
        color="success"
        @click="addField(`${commonField}`)"
        >{{ commonField }}</v-btn
      >
    </span>

    <div class="codemirror-container mt-3">
      <code-mirror
        :code-array="fieldArray"
        @update="onChange('options.customUnitFields', $event)"
      />
    </div>
  </v-container>
</template>

<script>
import CodeMirror from '@/components/CodeMirror';
import _ from 'lodash';

export default {
  name: 'MeetingSettingsCustomDataInput',
  components: {
    CodeMirror
  },
  props: {
    fieldArray: {
      type: Array,
      default() {
        return [];
      }
    },
    commonFields: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    disableCommonFieldButton(field) {
      return _.includes(this.fieldArray, field);
    },
    onChange(key, value) {
      let filteredArray = value.filter(Boolean);
      this.$emit('update', { key, value: filteredArray });
    },
    addField(field) {
      const newArray = [...this.fieldArray, field];
      this.$emit('update', {
        key: 'options.customUnitFields',
        value: newArray
      });
    }
  }
};
</script>

<style scoped></style>
