<template>
  <div class="caption">
    <div v-if="type === 'check'">
      <span
        v-if="status"
        class="green--text">{{ text }} ✓</span>
      <span
        v-else
        class="grey--text lighten-4">No {{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IssuesStatus',
  props: {
    type: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>

</style>
