'use strict';

/**
 * @fileoverview Vuex module for Campaigns
 */

import _ from 'lodash';
import moment from 'moment-timezone';

import { handleError } from '@/helpers';

// API
import * as campApi from '@/api/campaigns';

const TZ = process.env.VUE_APP_DEFAULT_TIMEZONE;

const state = {
  campaignList: {},
  showBilled: false,
  showIgnored: false,
  fromDate: moment()
    .tz(TZ)
    .add(-1, 'months')
    .toISOString(),
  toDate: moment()
    .tz(TZ)
    .toISOString(),
  dataLastFetchedAt: null,
  searchText: '',
  subFilter: 'all',
  pagination: {
    descending: true,
    sortBy: 'meetingDate',
    page: 1,
    rowsPerPage: 15,
    totalItems: 0,
    rowsPerPageItems: [15, 30, 50, 100]
  }
};

const getters = {
  campaignList: state => state.campaignList,
  campaignListData: state => Object.values(state.campaignList),
  showBilled: state => state.showBilled,
  showIgnored: state => state.showIgnored,
  fromDate: state => state.fromDate,
  toDate: state => state.toDate,
  toggles: getters => {
    let array = [];
    if (getters.showBilled) {
      array.push('showBilled');
    }
    if (getters.showIgnored) {
      array.push('showIgnored');
    }
    return array;
  },
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  subFilter: state => state.subFilter,
  pagination: state => state.pagination
};

const actions = {
  /**
   * Return the list of campaign billing data
   */
  async getCampaignList({ state, commit, getters }) {
    try {
      commit('SET_CAMPAIGN_DATA_FETCHED_AT', null);
      const hideBilled = !getters.showBilled;
      const hideIgnored = !getters.showIgnored;

      // Date range
      const fromDate = getters.fromDate;
      const toDate = getters.toDate;

      // Search and subscription filter
      const searchText = state.searchText;
      const subscriptions = state.subFilter;

      // Pagination
      const page = state.pagination.page;
      const size = state.pagination.rowsPerPage;

      const res = await campApi.getCampaignList({
        hideBilled,
        hideIgnored,
        fromDate,
        toDate,
        searchText,
        subscriptions,
        page,
        size
      });

      const { campaigns = [], totalNum = 0 } = res.data;

      commit('SET_CAMPAIGN_LIST_DATA', { campaigns, totalNum });
      commit('SET_CAMPAIGN_DATA_FETCHED_AT', new Date());
    } catch (err) {
      handleError(err);
      throw err;
    }
  },

  /**
   * Set the toggle to filter data by only unbilled campaigns
   * @param {boolean}  toggle  - the toggle value
   */
  updateToggles({ commit, dispatch }, toggles) {
    const hasShowBilled = toggles.includes('showBilled');
    commit('SET_SHOW_BILLED_TOGGLE', hasShowBilled);

    const hasShowIgnored = toggles.includes('showIgnored');
    commit('SET_SHOW_IGNORED_TOGGLE', hasShowIgnored);

    dispatch('getCampaignList');
  },

  /**
   * Set the toggle to filter data by only unbilled campaigns
   * @param {Object}   params
   * @param {Date}     params.fromDate  - starting date
   * @param {Date}     params.toDate    - ending date
   */

  updateSearchDates({ commit }, { fromDate, toDate }) {
    commit('SET_SEARCH_DATES', { fromDate, toDate });
  },

  /**
   * Set the string to search
   * @param {Object}   params.searchText - campaign search string
   */
  setSearchText({ commit }, { searchText }) {
    commit('SET_SEARCH_TEXT', { searchText });
  },

  /**
   * Update server-side filters
   *
   * @param  {String} subFilter - filter for subscriptions
   */
  async setFilters({ commit }, { subFilter }) {
    commit('SET_SUB_FILTER', subFilter);
  },

  /**
   * Update server-side pagination
   *
   * @param  {String} options    - pagination payload
   */
  async setPagination({ commit }, options) {
    commit('SET_PAGINATION', options);
  }
};

const mutations = {
  SET_CAMPAIGN_LIST_DATA(state, { campaigns, totalNum }) {
    state.campaignList = _.keyBy(campaigns, 'key');
    state.pagination.totalItems = parseInt(totalNum);
  },
  SET_CAMPAIGN_DATA_FETCHED_AT(state, value) {
    state.dataLastFetchedAt = value;
  },
  SET_SHOW_BILLED_TOGGLE(state, toggle) {
    state.showBilled = toggle;
  },
  SET_SHOW_IGNORED_TOGGLE(state, toggle) {
    state.showIgnored = toggle;
  },
  SET_SEARCH_DATES(state, { fromDate, toDate }) {
    if (fromDate) {
      state.fromDate = moment(fromDate)
        .tz(TZ)
        .toISOString();
    }
    if (toDate) {
      state.toDate = moment(toDate)
        .tz(TZ)
        .toISOString();
    }
  },
  SET_SEARCH_TEXT(state, { searchText }) {
    state.searchText = searchText;
  },
  SET_SUB_FILTER(state, filter) {
    state.subFilter = filter;
  },
  SET_PAGINATION(state, options) {
    state.pagination = options;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
