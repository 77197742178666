'use strict';

/**
 * Calculates the password strength
 */

import zxcvbn from 'zxcvbn';

export default function getPasswordStrength(password) {
  if (!password) {
    return {
      percentage: 0,
      score: 0,
      message: 'no password',
      color: 'grey'
    };
  }

  let message;
  let percentage;
  let color;
  let icon;

  const score = zxcvbn(password).score;

  switch (score) {
    case 0:
      message = 'terrible';
      percentage = 0;
      color = 'red';
      icon = 'thumb_down';
      break;
    case 1:
      message = 'weak';
      percentage = 25;
      color = 'red';
      icon = 'thumb_down';
      break;
    case 2:
      message = 'ok';
      percentage = 50;
      color = 'yellow';
      icon = 'thumbs_up_down';
      break;
    case 3:
      message = 'good';
      percentage = 75;
      color = 'light-green';
      icon = 'thumb_up';
      break;
    case 4:
      message = 'excellent';
      percentage = 100;
      color = 'green';
      icon = 'thumb_up';
      break;
  }

  return {score, percentage, message, color, icon};
}
