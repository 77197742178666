<template>
  <v-dialog :value="isOpen" @input="$emit('close')">
    <v-card>
      <v-card-title class="mb-2 pb-2">
        <span class="title">{{ meeting.name }}</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-card-text class="mt-2 pt-0">
        <span class="info-item">
          <v-layout>
            <div class="pr-1">
              <b>{{ meeting.corporation.legalName }}</b>
            </div>
            (<a
              class="accent--text"
              :href="accountsUrl"
              target="_blank"
              rel="noopener"
              >Go to accounts page</a
            >)
          </v-layout>
        </span>
        <span class="info-item">
          <b>{{ meeting.shortCode }}</b>
        </span>

        <v-container fluid grid-list-md>
          <!-- Campaign Info -->
          <p class="subheading info-item">Campaign Info</p>
          <v-layout row wrap class="mb-4">
            <v-flex xs12 md6 class="mt-1 mb-1">
              <span class="info-item">
                <b>Meeting Date:</b>
                {{
                  meeting.meetingDate
                    | formatDateHumanReadable(true, meeting.meetingTimezone)
                }}
                <timezone-helper :date="meeting.meetingDate" />
                ({{ meeting.meetingDate | dateFromNow }})
              </span>

              <span class="info-item">
                <b>Proxy Expiry:</b>
                {{
                  meeting.expiryDate
                    | formatDateHumanReadable(true, meeting.meetingTimezone)
                }}
                <timezone-helper :date="meeting.expiryDate" />
                ({{ meeting.expiryDate | dateFromNow }})
              </span>
              <span class="info-item">
                <b>Building Name:</b> {{ buildingName }}</span
              >
              <span class="info-item">
                <b>Location:</b> {{ meeting.location }}
              </span>
              <span class="info-item capitalize" v-if="meeting.castId">
                <b>Webcast Info:</b> {{ meeting.castProvider }} -
                {{ meeting.castId }}
              </span>
              <span class="info-item">
                <b>URL:</b> &nbsp;
                <a
                  class="accent--text"
                  :href="meeting.url"
                  target="_blank"
                  rel="noopener"
                  >{{ meeting.url }}</a
                >
              </span>
              <span class="info-item">
                <b>Contact:</b> {{ meeting.contact.name }} &lt;<a
                  class="accent--text"
                  href="#"
                  >{{ meeting.contact.email }}</a
                >&gt;
              </span>
              <span class="info-item">
                <b>Meeting Documents:</b> &nbsp;
                <ul v-if="meeting.documents">
                  <li v-for="(pkg, index) in meeting.documents" :key="index">
                    <a
                      class="accent--text"
                      :href="pkg.document.url"
                      target="_blank"
                      rel="noopener"
                    >
                      {{ formatDocumentInfo(pkg.document) }}
                    </a>
                  </li>
                </ul>
                <span v-else>No Documents Uploaded</span>
              </span>
            </v-flex>

            <v-flex xs12 md6 class="mt-1 mb-1">
              <span class="info-item">
                <b>Units/Size:</b> {{ stats.totalUnits }} units ({{
                  stats.totalVoters
                }}
                voters)
              </span>
              <span class="info-item">
                <b>Consents:</b> {{ stats.totalConsents }} ({{
                  percentageConsented
                }}% of total units)
              </span>
              <span class="info-item">
                <b>Proxies/Votes:</b> {{ stats.totalProxies }} in total
                <span v-if="stats.unverifiedProxyCount > 0"
                  >({{ stats.unverifiedProxyCount }} unverified)</span
                >
              </span>
            </v-flex>
          </v-layout>
          <!-- END Campaign Info -->
          <!-- Checklist Legal Deadline Info -->
          <p class="subheading info-item">Legal Deadlines (Checklists)</p>
          <v-layout row wrap class="mb-4">
            <v-flex v-if="legalDeadlines.length > 0" xs12 sm6 class="mt-1 mb-1">
              <span
                v-for="checklist in legalDeadlines"
                :key="checklist.key"
                class="info-item"
              >
                <b>{{ checklist.name }}:</b> {{ checklist.legalDeadline }}
              </span>
            </v-flex>
            <v-flex v-else wrap class="mb-4">
              <span>N/A</span>
            </v-flex>
          </v-layout>
          <!-- End Checklist Legal Deadline Info -->
          <!-- Business Info -->
          <p class="subheading info-item">Business Info</p>
          <v-layout row wrap class="mb-4">
            <v-flex
              v-for="business in meeting.business"
              :key="business.id"
              xs12
              sm6
              md4
              class="mt-1 mb-1"
            >
              <span class="info-item text-truncate">
                <b>{{ business.name }}</b>
              </span>
              <span class="info-item"> <b>Type:</b> {{ business.type }} </span>
              <span class="info-item">
                <b>Status:</b> &nbsp;
                <span :class="getBusinessStatusColor(business)">{{
                  isBusinessActive(business) ? 'Active' : 'Disabled'
                }}</span>
              </span>
              <span class="info-item"> <b>Votes:</b> </span>
              <ul class="vote-list">
                <li
                  v-for="(vote, index) in business.tally"
                  :key="index"
                  class="vote-item"
                >
                  {{ vote.option }}: {{ vote.num_votes }}
                </li>
              </ul>
            </v-flex>
          </v-layout>
          <!-- END Business Info -->
          <v-layout row wrap class="mb-4">
            <!-- Contact Info -->
            <v-flex xs12 sm6 md4 class="mt-1 mb-1">
              <p class="subheading info-item">Contact Info</p>
              <span class="info-item"> <b>Name:</b> {{ accountName }} </span>
              <span class="info-item"> <b>Email:</b> {{ accountEmail }} </span>
              <span class="info-item">
                <b>Phone:</b> {{ accountPhone || 'n/a' }}
              </span>
            </v-flex>
            <!-- END Contact Info -->
            <v-spacer></v-spacer>
            <!-- Signature -->
            <v-flex xs12 sm6 md4 class="mt-1 mb-1">
              <p class="subheading info-item">Meeting Signature</p>
              <span class="info-item" v-html="meetingSignature"> </span>
            </v-flex>
            <!-- END Signature -->
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import filesize from 'filesize';
import * as filters from '@/filters';
import TimezoneHelper from '@/components/TimezoneHelper';

import { getDefaultDate, payloadDateFormat } from '@/lib/checklist-helpers';

export default {
  name: 'MeetingQuickInfoDialog',
  components: { TimezoneHelper },
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    meeting: {
      type: Object,
      default() {
        return {};
      }
    },
    account: {
      type: Object,
      default() {
        return {};
      }
    },
    stats: {
      type: Object,
      default() {
        return {};
      }
    },
    checklists: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  filters: {
    ...filters
  },
  computed: {
    accountsUrl() {
      let baseurl =
        process.env.NODE_ENV === 'production'
          ? `https://dashboard.getquorum.com`
          : 'http://localhost:8080';

      let url = `${baseurl}/admin/accounts/${this.meeting.accountKey}`;
      return url;
    },
    percentageConsented() {
      return ((this.stats.totalConsents / this.stats.totalUnits) * 100).toFixed(
        2
      );
    },
    buildingName() {
      return _.get(this.account, 'data.nickname', '');
    },
    accountName() {
      return _.get(this.account, 'contact.name', '');
    },
    accountEmail() {
      return _.get(this.account, 'contact.email', '');
    },
    accountPhone() {
      return _.get(this.account, 'contact.phone', '');
    },
    meetingSignature() {
      return _.get(this.meeting, 'options.signature', 'n/a');
    },
    meetingTimezone() {
      return this.meeting.meetingTimezone
        ? this.meeting.meetingTimezone
        : 'America/Toronto';
    },
    legalDeadlines() {
      return this.checklists
        .filter(checklist => checklist.legalDeadline)
        .sort((a, b) => {
          return parseInt(b.legalDeadline) - parseInt(a.legalDeadline);
        })
        .map(checklist => {
          return {
            name: checklist.name,
            legalDeadline: moment(
              payloadDateFormat({
                date: getDefaultDate(
                  checklist.legalDeadline,
                  this.meeting.meetingDate,
                  this.meetingTimezone
                ),
                hour: 19,
                min: 0,
                timezone: this.meetingTimezone
              })
            )
              .tz(this.meetingTimezone)
              .format('ddd, MMM Do YYYY')
          };
        });
    }
  },
  methods: {
    isBusinessActive(business) {
      return !business.disabledAt;
    },
    getBusinessStatusColor(business) {
      return this.isBusinessActive(business) ? 'green--text' : 'red--text';
    },
    formatDocumentInfo(document) {
      if (document.size && !isNaN(document.size)) {
        return `${document.name} (${filesize(document.size)})`;
      }
      return `${document.name} (Invalid filesize)`;
    }
  }
};
</script>

<style scoped>
.subheading {
  margin-bottom: 8px;
  display: block;
}
.info-item {
  display: block;
}
.vote-list {
  padding-top: 2px;
}
.vote-item {
  line-height: 1;
}
</style>
