<template>
  <div>
    <v-layout row class="mt-3">
      <v-flex xs12>
        <p class="secondary--text">
          - Consents are stored by email because it's the only way we can
          <b><i>key</i></b> an owner.<br />
          - Consents carry over to all campaigns under the associated
          Account.<br />
          - A <b>rejection</b> is like an unsubscribe, no emails will be sent to
          that address.<br />
          - You can enter the same email again to change it's status (eg.
          consented to rejected).
        </p>
      </v-flex>
    </v-layout>

    <v-layout row class="mb-3 mt-3">
      <v-flex xs12>
        <account-consents-add
          v-if="dataLastFetchedAt"
          :current-account-key="currentAccount.key"
        />
      </v-flex>
    </v-layout>

    <v-layout row class="mb-3 mt-3">
      <v-flex xs12>
        <account-consents-list
          :current-account="currentAccount"
          :data-last-fetched-at="dataLastFetchedAt"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AccountConsentsAdd from './AdminAccountConsentsViewAdd';
import AccountConsentsList from './AdminAccountConsentsViewList';
export default {
  name: 'AdminAccountConsentsContent',
  components: {
    AccountConsentsAdd,
    AccountConsentsList
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
