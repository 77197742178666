<template>
  <v-tooltip top v-if="showIcon">
    <span slot="activator">
      <v-icon small>info</v-icon>
    </span>
    <div class="ml-2">
      <b>My Time:</b>
      {{ calcDate({}) | format }}
      <v-icon small class="mr-1">access_time</v-icon>
    </div>
    <v-divider class="mt-2 mb-2" />
    <v-layout>
      <ul>
        Other Time zones:
        <li>
          <b>Pacific:</b>
          {{ calcDate({ tz: 'America/Vancouver' }) | format }}
        </li>
        <li>
          <b>Mountain:</b>
          {{ calcDate({ tz: 'America/Edmonton' }) | format }}
        </li>
        <li>
          <b>Central:</b>
          {{ calcDate({ tz: 'America/Winnipeg' }) | format }}
        </li>
        <li>
          <b>Eastern:</b>
          {{ calcDate({ tz: 'America/Toronto' }) | format }}
        </li>
        <li>
          <b>Atlantic:</b> {{ calcDate({ tz: 'America/Halifax' }) | format }}
        </li>
        <li>
          <b>Newfoundland :</b>
          {{ calcDate({ tz: 'America/St_Johns' }) | format }}
        </li>
        <li>
          <b>London, UK :</b>
          {{ calcDate({ tz: 'Europe/London' }) | format }}
        </li>
        <li>
          <b>UTC:</b>
          {{ calcDate({ tz: 'utc' }) | formatUtc }}
        </li>
      </ul>
    </v-layout>
  </v-tooltip>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'TimezoneHelper',
  props: {
    date: {
      type: String,
      default: null
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    format(mom) {
      return mom.format('YYYY-MM-DD hh:mm a zz');
    },
    formatUtc(mom) {
      return mom.format('YYYY-MM-DD hh:mm zz');
    }
  },
  methods: {
    calcDate({ tz }) {
      if (!tz) {
        return moment(this.date);
      }
      return moment(this.date).tz(tz);
    }
  }
};
</script>

<style scoped>
.v-tooltip__content {
  font-size: 12px !important;
  color: #000;
  background: white;
  opacity: 1 !important;
}
ul {
  list-style-type: circle;
  padding-left: 10px;
}
</style>
