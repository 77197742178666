<template>
  <div>
    <v-checkbox
      v-model="partnerModel"
      label="Set as Partner model"
      @change="getAgents"
    ></v-checkbox>
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <v-autocomplete
      v-if="!loading && isPartnerModel"
      :value="partnerData"
      label="Partner"
      placeholder="Start typing to search for an Agent"
      :items="partnerListData"
      item-text="name"
      item-value="key"
      hide-details
      :outline="isOutline"
      dense
      return-object
      @change="changeHandler"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingCreatePartner',
  props: {
    isOutline: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('meetings/partners', [
      'partnerData',
      'isPartnerModel',
      'partnerListData'
    ]),
    partnerModel: {
      get() {
        return this.isPartnerModel;
      },
      set(newValue) {
        return this.setPartnerModel(newValue);
      }
    }
  },
  async created() {
    this.resetPartner();
    // If create partner is under existing campaign, app will try to fetch partner
    if (this.shortCode) {
      await this.getPartner({ shortCode: this.shortCode });
    }
    // If partner there is a partner in campaign, app will try to fetch all agents who are partners
    if (this.isPartnerModel) {
      await this.getPartnerList();
    }
  },
  methods: {
    ...mapActions('meetings/partners', [
      'getPartnerList',
      'setPartner',
      'setPartnerModel',
      'getPartner',
      'resetPartner'
    ]),
    async getAgents(value) {
      if (!this.partnerListData.length && value) {
        this.loading = true;
        await this.getPartnerList();
        this.loading = false;
      } else if (!value) {
        this.setPartner(null);
      }
    },
    changeHandler(val) {
      this.setPartner(val);
    }
  }
};
</script>

<style></style>
