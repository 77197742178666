<template>
  <v-card flat>
    <v-card-text>
      <!-- Polls -->
      <h2>Welcome Slide Generator</h2>
      <p>
        Generate a welcome slide to share during the meeting's registration and
        housekeeping periods.
      </p>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            label="Title"
            placeholder="eg. TSCC 1234"
            v-model="legalNameShort"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            label="Subtitle"
            placeholder="eg. Virtual AGM"
            v-model="typeOfMeeting"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            label="Meeting Date"
            placeholder="eg. Monday, January 25 2021"
            v-model="meetingDateString"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            label="Meeting Time"
            placeholder="eg. 7:00 PM EST"
            v-model="meetingTimeString"
          />
        </v-flex>
      </v-layout>

      <v-textarea v-model="notes" auto-focus box rows="7">
        <template v-slot:label>
          <div>
            <b> Notes Section </b> (Formatting and white spaces are preserved)
          </div>
        </template>
      </v-textarea>

      <v-btn class="primary" flat @click.native="openTitlepage()">
        Generate Slide
      </v-btn>
      <span
        >Note: Will open in a new tab. Press "F11" on your keyboard to maximize
        it.</span
      >

      <!-- Slide Links -->
      <v-divider class="mt-4 mb-4" />

      <h2>Slide Links</h2>

      <div class="d-flex">
        <v-flex xs4>
          <v-select
            label="Slides"
            item-text="label"
            item-value="value"
            v-model="selectedSlides"
            :items="slideOptions"
            @change="changeSlides"
          >
          </v-select>
        </v-flex>
        <v-flex xs4 v-if="this.changedSlides()">
          <v-btn
            @click.native="save"
            color="primary"
            class="error"
            v-show="hidebutton"
            @click="snackbar = true"
          >
            Save
            <v-icon right>save</v-icon>
          </v-btn>
        </v-flex>
      </div>
      <div class="text-center">
        <v-snackbar v-model="snackbar">
          Slide Preference Saved
          <v-btn flat class="red--text" @click="snackbar = false"> Close</v-btn>
        </v-snackbar>
      </div>

      <p>Below are links to the slides you may be using during the meeting</p>
      <ul>
        <li v-for="(link, i) in slideLinks" :key="`slide-links-${i}`">
          <a :href="link.url" target="_blank">{{ link.text }}</a>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import InputSendDate from '@/components/input/InputSendDate';

import { getRecipe } from '@/api/recipes';

import { getMeetingMeta } from '@/api/meetings';

export default {
  name: 'MeetingWebcastViewSlides',
  components: {
    InputSendDate
  },
  props: {
    meetingName: {
      type: String,
      required: true
    },
    condoCode: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    meetingTimezone: {
      type: String
    }
  },

  data() {
    return {
      legalNameShort: this.condoCode,
      typeOfMeeting: 'Virtual AGM',
      meetingDate: this.date,
      meetingDateString: '',
      meetingTimeString: '',
      notes:
        "Please note that: \n     - We're in a quiet registration period. \n     - Nothing is needed of you to register. \n     - Your microphone and camera will be \n       disabled unless permitted at certain \n       points. \n    - Thank you.",
      max: 255,
      html: '',
      menu: '',
      shortCode: this.$route.params.shortcode,
      countdownDate: '',
      slideOptions: [],
      selectedSlides: 'default',
      hidebutton: false,
      snackbar: false,
      toast: {
        snackbar: false,
        y: 'bottom',
        x: null,
        timeout: 5000,
        mode: '',
        text: ''
      }
    };
  },

  mounted() {
    this.init();
    this.getSlideOptions();
    this.changedSlides();
  },

  computed: {
    ...mapGetters('meetings/webcast', ['slideLinks']),
    ...mapGetters('meetings', ['meetingByShortCode']),
    currentMeeting() {
      return this.meetingByShortCode(this.shortCode);
    }
  },

  methods: {
    ...mapActions('meetings/webcast', ['fetchSlideLinks']),
    ...mapActions('v2/meetings', ['updateMeeting']),
    async init() {
      const meta = await this.fetchMeetingMeta();
      this.selectedSlides = meta.data.webcastSlides;
      if (this.selectedSlides === undefined) {
        this.selectedSlides = this.defaultSlides();
      }
      this.changeSlides();
      this.typeOfMeeting = this.splitMeetingName(this.meetingName);
      // Set date and time strings
      this.meetingDateString = this.date
        ? moment(this.date)
            .tz('America/Toronto')
            .format('dddd, MMMM Do YYYY')
        : '';

      this.meetingTimeString = this.date
        ? moment(this.date)
            .tz(this.meetingTimezone ? this.meetingTimezone : 'America/Toronto')
            .format('h:mm A zz')
        : '';

      this.countdownDate = this.date ? moment(this.date).format() : '';
    },
    // Return the part of the meeting name after a '-' if  one exists. Otherwise
    // return the meeting name.
    splitMeetingName(meetingName) {
      if (meetingName.indexOf('-') > 0) {
        const split = meetingName.split('-');
        return split.length > 0 ? split[1] : meetingName;
      }
      return meetingName;
    },
    computedHTMLFile() {
      return `
          <html>
            <head>
              <title>${this.legalNameShort} - Welcome Slide</title>
              <meta name="viewport" content="width=device-width,initial-scale=1.0">
              <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&display=swap" rel="stylesheet">
              <link href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Slab:wght@400;500&display=swap" rel="stylesheet"> 
              <style type="text/css">
                h1, h2, h3, i, pre, .countdown, .date, .time {
                  font-family: 'Roboto Slab', serif;
                  font-weight: normal;
                  color: white;
                }
                h1 {
                  font-size: 10vh;
                  padding-bottom: 8vh;
                }

                h2 {
                  font-size: 5vh;
                }

                h3 {
                  font-size: 3vh;
                }

                .date{
                  margin: 0px;
                  padding-left: 1vh;
                  font-size: 4vh;
                }

                .material-icons {
                  font-size: 4vh; !important
                  position: relative;
                  vertical-align: sub; 
                }

                .countdown {
                  padding: 1vh;
                  background-color: #063d63;
                  border-radius: 5px;
                  position: absolute;
                  font-size: 4.5vh;
                  margin-left: -.5vw;
                  margin-top: 2vh;
                }

                .timer {
                  min-width: 5.5vw;
                  display: inline-block;
                }

                .container {
                  overflow: hidden !important;
                  background-color: #0862A1;
                  min-width: 100%;
                  margin: 0;
                  padding: 8vw 17vw 5vh 20vh;
                }

                .vm-image{
                  position: absolute; 
                  right: 1vw;
                  bottom: 0px;
                  width: 40vw;
                }

                .logo {
                  left: 8.6vw;
                  top: 10px;
                  position: fixed;
                  width: 13vw;
                }

                .space {
                  padding-left: 1vw;
                }

                pre {
                  line-height: 1.5;
                  font-size: 2.5vh;
                  font-family: 'Open Sans', sans-serif;
                }

                @media only screen and (max-width: 900px){
                  body {
                    padding: 0vmin 5vmin 5vmin 5vmin;
                    text-align: center;
                  }

                  .container {
                    padding: 0;
                  }

                  .countdown {
                    position: relative;
                    background-color: #0862A1;
                  }
                  
                  h1 {
                    margin-top: 5vh;
                    padding-bottom: 0; !important
                  }

                  .logo {
                    left: 10px;
                  }
                  
                  .vm-image {
                    display: none;
                  }
                }
              </style>
            </head>
              <body class="container">
                <h1>${this.legalNameShort}
                <div class="countdown">The meeting will start in <div class="timer">00:00</div></div><br/>
                </h1>
                <h2>${this.typeOfMeeting}
                <div class="date">
                <i class="material-icons">event</i>
                ${this.meetingDateString}
                <br/>
                <i class="material-icons">schedule</i>
                ${this.meetingTimeString}
                </div>
                </h2>
                
                <h3><pre>${this.notes}</pre></h3>
                <img 
                  src="https://www.getquorum.com/assets/images/illustrations/virtual_meetings.png" 
                  class="vm-image"
                />
                <img
                  src="https://www.getquorum.com/assets/images/gq_navbar_logo.svg"
                  class="logo"
                />
              </body>
              <script>
                var meetingDate = '${this.countdownDate}';
                var countDownDate = new Date(meetingDate).getTime();
                // Update the count down every 1 second
                var interval = setInterval(function() {

                  // Get today's date and time
                  var now = new Date().getTime();
                    
                  // Find the distance between now and the count down date
                  var distance = countDownDate - now;
                  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) + (days*24*60) + (hours*60);
                  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                  // pad both min and sec
                  minutes = minutes.toString().padStart(2, '0');
                  seconds = seconds.toString().padStart(2, '0');

                  document.getElementsByClassName("timer")[0].innerText = \`\${minutes}:\${seconds}\`;

                  // If the count down is over, stop the timer 
                  if (distance < 0) {
                    clearInterval(interval);
                    document.getElementsByClassName("countdown")[0].innerHTML = "The meeting is in progress";
                  }
                }, 1000);
              <\/script>
          </html>      
        `;
    },
    openTitlepage() {
      const win = window.open(
        'Titlepage',
        '_blank',
        'toolbar=0,location=0,menubar=0'
      );
      const html = this.computedHTMLFile();
      win.document.title = 'Meeting Titlepage';
      win.document.write(html);
      win.focus();
      window.close();
    },
    async getSlideOptions() {
      const { data } = await getRecipe('webcast.slide.dropdown');
      if (data?.data?.recipes) {
        const options = _.keyBy(data.data.recipes, 'value');
        this.slideOptions = _.map(options, option => option);
      }
    },
    changeSlides(input) {
      this.fetchSlideLinks({
        shortCode: this.shortCode,
        webSlides: this.selectedSlides
      });
    },
    async save(input) {
      const updates = {
        name: this.meetingName,
        meetingMeta: {
          webcast_slides: this.selectedSlides
        }
      };
      await this.updateMeeting({ shortCode: this.shortCode, updates });
      this.changedSlides();
    },
    async fetchMeetingMeta() {
      const values = await getMeetingMeta({
        shortCode: this.shortCode,
        type: 'webcast_slides'
      });
      return values;
    },
    async changedSlides() {
      const meta = await this.fetchMeetingMeta();
      if (meta.data.webcastSlides === undefined) {
        this.hidebutton = !(this.selectedSlides === this.defaultSlides());
      } else {
        this.hidebutton = !(this.selectedSlides === meta.data.webcastSlides);
      }
    },
    defaultSlides() {
      let defaultSlides = 'default';
      if (this.currentMeeting.accountCustomerType === 'assoc') {
        if (this.currentMeeting.options.virutalMode === 'Hybrid') {
          defaultSlides = 'assoc_hybrid';
        } else {
          defaultSlides = 'assoc_ip';
        }
      } else {
        if (this.currentMeeting.region === 'ON') {
          if (this.currentMeeting.options.virtualMode === 'Hybrid') {
            defaultSlides = 'ontario_hybrid';
          } else if (this.currentMeeting.options.enableRtvZoom === true) {
            defaultSlides = 'ontario_ip';
          } else {
            defaultSlides = 'ontario_no_ip';
          }
        } else if (this.currentMeeting.region === 'AB') {
          if (this.currentMeeting.options.virtualMode === 'Hybrid') {
            defaultSlides = 'alberta_hybrid';
          } else {
            defaultSlides = 'alberta_ip';
          }
        } else {
          defaultSlides = 'assoc_ip';
        }
      }
      return defaultSlides;
    }
  }
};
</script>
<style scoped></style>
