<template>
  <v-expansion-panel class="elevation-0">
    <v-expansion-panel-content
      v-for="(item, category, index) in mergeFields"
      :key="index"
    >
      <template v-if="!hide">
        <div slot="header">
          <div class="font-weight-medium">{{ category | startCase }}</div>
          <div class="caption grey--text text--darken-2">
            {{ item.description }}
          </div>
        </div>

        <v-card :class="type === 'meetingConversations' ? 'panel-body' : ''">
          <v-card-text>
            <span
              v-for="(description, mergeTag, index) in item.tags"
              :key="index"
            >
              <v-tooltip left>
                <span slot="activator">
                  <v-chip @click="emitInsert(mergeTag)" small>{{
                    mergeTag
                  }}</v-chip>
                </span>
                <span>{{ description }}</span>
              </v-tooltip>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="showCustomFields">
      <div slot="header">
        <div class="font-weight-medium">Custom</div>
        <div class="caption grey--text text--darken-2">
          {{ customLabel }}
        </div>
      </div>
      <v-card>
        <v-card-text>
          <span v-for="(field, index) in customFields" :key="index">
            <v-tooltip left>
              <span slot="activator">
                <v-chip
                  v-if="field.rawHtml"
                  @click="emitInsert(`{{{${field.tag ? field.tag : field}}}}`)"
                  small
                  >{{ field.tag ? field.tag : field }}</v-chip
                >
                <v-chip
                  v-else
                  @click="emitInsert(`{{${field.tag ? field.tag : field}}}`)"
                  small
                  >{{ field.tag ? field.tag : field }}</v-chip
                >
              </span>
              <span>{{ field.description ? field.description : field }}</span>
            </v-tooltip>
          </span>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import * as filters from '@/filters';

export default {
  name: 'MarkdownHelp',
  props: {
    customLabel: {
      type: String,
      default: ''
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    },
    showCustomFields: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    showButtonOptions: { type: Boolean, default: false }
  },
  filters: {
    ...filters
  },
  computed: {
    ...mapGetters('meetings/notices', ['noticeMergeFields']),
    ...mapGetters('meetings/conversations', ['conversationMergeFields']),
    type() {
      return this.$route.name;
    },
    mergeFields() {
      if (this.type === 'meetingConversations') {
        return this.conversationMergeFields;
      }
      if (this.type === 'meetingSettings' && !this.showButtonOptions) {
        return this.conversationMergeFields;
      }
      return this.noticeMergeFields;
    }
  },
  methods: {
    emitInsert(mergeTag) {
      this.$emit('insert', mergeTag);
    }
  }
};
</script>

<style scoped>
.panel-body {
  max-height: 200px;
  overflow-y: auto;
}
</style>
