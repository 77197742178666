<template>
  <div class="price-calculator">
    <v-layout
      row
      nowrap>
      <v-flex
        xs4
        class="mr-2 ml-2">
        <v-text-field
          type="number"
          name="input-num-pages"
          label="Num of pages"
          id="input-num-pages"
          min="0"
          v-model="numPages"
          @input="update" />
      </v-flex>
      <v-flex
        xs4
        class="mr-2 ml-2">
        <v-text-field
          type="number"
          name="input-num-packages"
          label="Num of packages"
          id="input-num-packages"
          min="0"
          v-model="numPackages"
          @input="update" />
      </v-flex>      
      <v-flex
        xs4
        class="mr-2 ml-2">
        <v-text-field
          type="number"
          name="input-postage"
          label="Total postage ($)"
          id="input-postage"
          min="0"
          v-model="postage"
          @input="update" />
      </v-flex>    
    </v-layout>
    <v-layout
      row
      nowrap>
      <v-flex
        xs4
        class="mr-2 ml-2">      
        <v-select
          label="Package type"
          :items="item.items"
          v-model="packageType"
          @input="update" />        
      </v-flex>      
      <v-flex
        xs4
        class="mr-2 ml-2">
        <v-text-field
          type="number"
          name="input-cost-page"
          label="Cost per page ($)"
          id="input-cost-page"
          min="0"
          v-model="state.costPerPage"
          @input="update" />
      </v-flex>
      <v-flex
        xs4
        class="mr-2 ml-2">
        <v-text-field
          type="number"
          name="input-cost-package"
          label="Cost per package ($)"
          id="input-cost-package"
          min="0"
          v-model="state.costPerPackage"
          @input="update" />
      </v-flex>
    </v-layout>    
  </div>
</template>

<script>
import {calculatePrinting} from '@/lib/invoice-helpers';
export default {
  name: 'PrintPriceCalculator',
  props: {
    item: {
      type: Object,
      default() {
        return {
          costPerPage: 0,
          costPerPackage: 0,
          items: []
        };
      }
    }
  },
  data() {
    return {
      numPages: 0,
      numPackages: 0,
      postage: 0,
      packageType: 'AGM',
      baseValue: 0,
      state: {
        costPerPage: 0,
        costPerPackage: 0
      }
    };
  },
  computed: {
    amount() {
      return calculatePrinting(
        this.numPages,
        this.numPackages,
        this.state.costPerPage,
        this.state.costPerPackage,
        this.postage
      );
    },
    description() {
      return `Print ${this.numPackages} copies of a ${this.numPages} page ${
        this.packageType
      } package + postage`;
    }
  },
  mounted() {
    this.state = {
      costPerPage: this.item.costPerPage,
      costPerPackage: this.item.costPerPackage
    };
  },
  methods: {
    update() {
      this.$emit('update', {
        baseValue: this.amount,
        description: this.description
      });
    }
  }
};
</script>


<style scoped>
.price-calculator {
  background-color: #eee;
}
.small {
  font-size: 10px;
}
</style>
