'use strict';

/** @fileoverview Filters out non-admin meeting routes which require a specific scope to access */

/**
 * Function to check non-"adminOnly" meeting routes, to determine if they are limited by
 * scope, and if so, to determine if the current user has access or not.
 * If a meeting route has a 'scope' meta property, the user must have matching
 * scope in order to see this route in the navbar.
 *
 * @param {String}   shortCode      - meeting shortcode
 * @param {Object}   routes         - meeting routes
 * @param {Array}    userScopes     - user's scopes
 * @return {Object}
 */
export default function checkRouteScopes(shortCode, routes, userScopes) {
  if (!shortCode) {
    throw new Error('Missing shortcode');
  }
  if (!routes) {
    throw new Error('Invalid Meeting Routes');
  }
  if (!userScopes) {
    throw new Error('Missing user scopes');
  }

  // Get user's scopes for this meeting
  const meetingScopes = userScopes.campaigns[shortCode];

  if (!meetingScopes) {
    throw new Error('No scopes for this meeting');
  }

  // Filter out routes for which user does not have scope/permission
  const navRoutes = routes.filter(route => {
    // If route is protected by a scope, only include it if user has matching scope
    if (route.scope && meetingScopes.actions.includes(route.scope)) {
      return route;
    }
    // If route is not protected by a scope, include it
    if (!route.scope) {
      return route;
    }
  });

  return navRoutes;
}
