import { render, staticRenderFns } from "./ShareRecordingsDialog.vue?vue&type=template&id=05369216&scoped=true"
import script from "./ShareRecordingsDialog.vue?vue&type=script&lang=js"
export * from "./ShareRecordingsDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05369216",
  null
  
)

export default component.exports