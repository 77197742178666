<template>
  <div>
    <v-btn
      v-if="currentPrintJob.status === 'DRAFT'"
      flat
      color="accent"
      @click.native="emitOpenDialog('delete')"
    >
      <v-icon left>delete</v-icon>Delete Draft
    </v-btn>

    <v-btn @click="emitBack">
      <v-icon left>arrow_back</v-icon>Back to Print Jobs
    </v-btn>

    <!-- disabled state is always true -->
    <!-- <v-btn
      :disabled="true"
      color="accent"
      @click.native="showCancelDialog = true"
      v-if="currentPrintJob.status === 'SENT'"
    >
      <v-icon left>cancel</v-icon>Cancel Print Job
    </v-btn> -->

    <v-btn
      v-if="currentPrintJob.status === 'DRAFT'"
      @click.native="emitSaveDraft"
    >
      <v-icon left>save</v-icon>Save Draft
    </v-btn>
    <!-- <v-btn
      :disabled="true"
      v-if="
        currentPrintJob.status === 'SENT' ||
          currentPrintJob.status === 'IN_PROGRESS'
      "
      color="warning"
      @click.native="hold"
    >
      <v-icon left>pause</v-icon>Stop/Hold
    </v-btn> -->
    <v-btn
      :disabled="!formIsValid"
      v-if="currentPrintJob.status === 'DRAFT'"
      @click.native="emitOpenDialog('email')"
    >
      <v-icon left>email</v-icon>Send Test
    </v-btn>
    <v-btn
      color="info"
      v-if="currentPrintJob.status !== 'COMPLETED'"
      @click.native="emitProcess"
      :disabled="!formIsValid"
    >
      <v-icon left>{{ nextStatus.icon }}</v-icon>
      {{ nextStatus.label }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'MeetingPrintJobActions',
  props: {
    currentPrintJob: {
      type: Object,
      default() {
        return {};
      }
    },
    formIsValid: {
      type: Boolean,
      default: false
    },
    nextStatus: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    emitOpenDialog(dialogType) {
      this.$emit('openDialog', dialogType);
    },
    emitBack() {
      this.$emit('back');
    },
    emitSaveDraft() {
      this.$emit('saveDraft');
    },
    emitProcess() {
      this.$emit('process');
    }
  }
};
</script>

<style scoped></style>
