<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Voting Options
    </div>

    <div class="mt-3">
      <div class="subheading">
        Select the required voting configuration for this campaign
      </div>
    </div>

    <v-layout>
      <div class="pr-3">
        <v-radio-group
          v-model="votingOption"
          @change="changeVotingOption($event)"
        >
          <v-radio label="Proxy only" value="Proxy"></v-radio>
          <v-radio label="Ballot only" value="Ballot"></v-radio>

          <div class="ml-5 mt-2 mb-3">
            <v-layout>
              <div class="pr-3 mb-3">
                <v-checkbox
                  v-model="openBallot"
                  @change="onChange('options.openBallot', $event)"
                />
              </div>
              <div>
                <div class="subheading">
                  Open Ballot?
                </div>
                <div class="grey--text text--darken-2 mb-3">
                  Click this button to update Meeting Settings, custom Proxy
                  Template, and all future Business to serve as an Open Ballot.
                  Note: disabling this checkbox will cause Meeting settings to
                  reset to default, and Proxy Template to reset to Bill-106.
                  Refresh page after Saving to see changes reflected on all
                  Settings tabs.
                </div>
              </div>
            </v-layout>
          </div>

          <v-radio label="Proxy and Ballot" value="Proxy-Ballot"></v-radio>
          <div class="ml-5 mt-2">
            <v-layout>
              <div class="pr-3">
                <v-checkbox
                  v-model="collectRtvBallots"
                  @change="onChange('options.collectRtvBallots', $event)"
                />
              </div>
              <div>
                <div class="subheading">
                  Collect Advance Ballots in RTV
                </div>
                <div
                  v-if="rtvBallotsNotionDoc"
                  class="grey--text text--darken-2"
                >
                  Use RTV to collect Ballots in advance of the meeting. For more
                  info:
                  <a :href="rtvBallotsNotionDoc" target="_blank">Notion link</a>
                </div>
                <div v-else class="grey--text text--darken-2">
                  Use RTV to collect Ballots in advance of the meeting.
                </div>
              </div>
            </v-layout>
          </div>
          <v-radio label="No voting" value="No-voting"></v-radio>

          <v-divider class="mb-4 mt-2"></v-divider>

          <div class="title grey--text text--darken-4 ">
            Live Voting Option
          </div>
          <v-layout class="mt-3">
            <div class="pr-3">
              <v-checkbox
                v-model="enableRtvProxyOverride"
                @change="onChange('options.enableRtvProxyOverride', $event)"
              />
            </div>
            <div>
              <div class="subheading">
                Enable RTV Question Proxy Override
              </div>
              <div class="grey--text text--darken-2">
                Show the setting on an RTV Questions to allow voters to override
                their proxy for that particular question.
              </div>
            </div>
          </v-layout>
        </v-radio-group>
      </div>
    </v-layout>

    <v-divider class="mb-4"></v-divider>

    <template v-if="votingOption !== 'No-voting'">
      <div class="title grey--text text--darken-4">
        Template
      </div>
      <div class="body-1 grey--text text--darken-2 mb-3">
        Define the proxy form template, by default is Bill-106 compliant
      </div>

      <v-layout row justify-space-between>
        <v-flex xs4>
          <v-select
            :label="templateTypeLabel"
            :items="templateType"
            item-text="name"
            :value="proxyTemplateValue"
            @input="selectChange($event)"
          />
        </v-flex>
        <v-flex xs4>
          <v-select
            v-if="proxyTemplateValue === 'alberta'"
            label="Sub-Template"
            :items="subTemplateTypes"
            item-text="name"
            :value="proxySubTemplateValue"
            @input="updateTemplate($event)"
          />
        </v-flex>
        <v-btn
          v-if="proxyTemplateValue === 'custom'"
          flat
          @click.native="dialog.confirm = true"
          >Fill Sample Form <v-icon right>filter_none</v-icon></v-btn
        >
      </v-layout>

      <div v-if="proxyTemplateValue !== 'on-bill-106' || showEditor">
        <rich-text-editor
          v-if="showEditor"
          :body="templateContent"
          :key="renderKey"
          :hide-merge-menu="true"
          :show-custom-fields="true"
          custom-label="Proxy Merge Variables"
          :custom-fields="customFields"
          @update="onChange('proxyTemplate.content', $event)"
        ></rich-text-editor>
      </div>
      <div v-else>
        <v-alert outline color="info" icon="info" :value="true">
          <strong>Bill-106 template</strong> uses the default proxy form and
          ignores custom legal text on the business tab.<br />With the exception
          to define an optional
          <strong>Custom Revocation Text</strong> below.<br />This will replace
          the text <i>"I (we) revoke all proxies previously given."</i> on the
          proxy form.
        </v-alert>

        <div class="pr-3 mt-3">
          <v-textarea
            label="Custom Revocation Text"
            :value="template.custom_revocation"
            box
            @input="onChange('proxyTemplate.custom_revocation', $event)"
          />
        </div>
      </div>
    </template>

    <open-ballot-checked-dialog
      @confirm="openBallotCheckedAction()"
      @close="
        dialog.openBallotChecked = false;
        openBallot = !openBallot;
      "
      :dialog="dialog.openBallotChecked"
    />

    <open-ballot-unchecked-dialog
      @confirm="openBallotUncheckedAction()"
      @close="
        dialog.openBallotUnchecked = false;
        openBallot = !openBallot;
      "
      :dialog="dialog.openBallotUnchecked"
    />

    <confirm-dialog
      title="Are you sure?"
      text="The action (Fill sample form) will overwrite the existing changes."
      :dialog="dialog.confirm"
      confirm-text="Yes"
      decline-text="No"
      @confirm="confirmFillSampleAction($event)"
      @close="dialog.confirm = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { form } from '@/lib/default-proxy-templates.js';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import OpenBallotCheckedDialog from '@/components/dialogs/OpenBallotCheckedDialog';
import OpenBallotUncheckedDialog from '@/components/dialogs/OpenBallotUncheckedDialog';

import { getRecipe } from '@/api/recipes';

export default {
  name: 'MeetingSettingsTemplateInput',
  components: {
    RichTextEditor,
    ConfirmDialog,
    OpenBallotCheckedDialog,
    OpenBallotUncheckedDialog
  },
  props: {
    template: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      templateType: [
        { name: 'Bill-106', value: 'on-bill-106' },
        { name: 'Alberta', value: 'alberta' },
        { name: 'Custom', value: 'custom' }
      ],
      subTemplateTypes: [
        { name: 'General', value: 'general' },
        { name: 'Limited', value: 'limited' }
      ],
      openBallot: false,
      templateContent: '',
      renderKey: 0,
      showEditor: false,
      customFields: [],
      proxyTemplateValue: null,
      proxySubTemplateValue: null,
      dialog: {
        confirm: false,
        openBallotChecked: false,
        openBallotUnchecked: false
      },
      votingOption: null,
      enableRtvProxyOverride: false,
      collectRtvBallots: false
    };
  },
  async mounted() {
    this.openBallot = this.options.openBallot;
    this.enableRtvProxyOverride = this.options.enableRtvProxyOverride;
    this.collectRtvBallots = this.options.collectRtvBallots;
    this.votingOption = this.options.votingOption;

    this.proxyTemplateValue = this.getProxyTemplateValue();
    this.proxySubTemplateValue = this.options.proxySubTemplate || 'general';
    this.templateContent = this.customProxyTemplate;
    await this.setProxyMergeFields();
    this.showEditor =
      this.proxyTemplateValue === 'custom' ||
      this.proxyTemplateValue === 'alberta';
  },
  computed: {
    customProxyTemplate() {
      return this.proxyTemplateValue
        ? this.template.content
          ? this.template.content
          : `${this.template.header}${this.template.to}${this.template.proxyBody}${this.template.terms}${this.template.termsEmail}${this.template.signature}${this.template.footer}`
        : '';
    },
    rtvBallotsNotionDoc() {
      return process.env.VUE_APP_NOTION_DOC_RTV_BALLOTS || '';
    },
    templateTypeLabel() {
      return this.votingOption === 'Ballot'
        ? 'Ballot Template'
        : 'Proxy Template';
    }
  },
  methods: {
    getProxyTemplateValue() {
      if (_.has(this.options, 'proxyTemplate')) {
        return this.options.proxyTemplate;
      }
      return null;
    },
    selectChange(value) {
      this.proxyTemplateValue = value;
      switch (value) {
        case 'alberta':
          this.showEditor = true;
          this.updateTemplate('general');
          break;
        case 'custom':
          this.templateContent = this.customProxyTemplate;
          this.renderKey++;
          this.showEditor = true;
          break;
        default:
          this.showEditor = false;
          break;
      }
      this.onChange('options.proxyTemplate', value);
    },
    async updateTemplate(value) {
      try {
        this.onChange('options.proxySubTemplate', value);
        const recipe = await getRecipe(
          `meetings.settings.proxy_template.${this.proxyTemplateValue}_${value}`
        );
        const recipeData = recipe.data.data;

        this.templateContent = recipeData.default;
        this.onChange('proxyTemplate.content', this.templateContent);
        this.renderKey++;
      } catch (err) {
        console.error('Error getting alberta templates', err);
      }
    },

    resetOverrideAndCollectBallots() {
      this.enableRtvProxyOverride = false;
      this.collectRtvBallots = false;
      this.onChange('options.enableRtvProxyOverride', false);
      this.onChange('options.collectRtvBallots', false);
    },

    onChange(key, value) {
      if (key === 'options.openBallot' && value === true) {
        this.dialog.openBallotChecked = true;
        return;
      } else if (key === 'options.openBallot' && value === false) {
        this.dialog.openBallotUnchecked = true;
      }

      this.$emit('update', { key, value });
    },
    getFormObj(formNum) {
      let formObj = _.find(form, function(o) {
        return o.formNum === formNum;
      });
      return formObj;
    },
    fillFormDefault(num) {
      let formObj = this.getFormObj(num);

      let template = '';
      for (let prop in formObj) {
        if (formObj.hasOwnProperty(prop)) {
          if (prop === 'formNum') {
            continue;
          }
          template += formObj[prop];
        }
      }
      this.onChange('proxyTemplate.content', template);
      this.templateContent = template;
      this.renderKey++;
    },
    setProxyTemplate(value) {
      this.onChange('options.proxyTemplate', value);
    },
    async setProxyMergeFields() {
      try {
        const recipe = await getRecipe('meetings.settings.proxy_merge_fields');
        const recipeData = recipe.data.data;

        this.customFields = recipeData.fields;
      } catch (err) {
        console.error('Error getting merge fields', err);
      }
    },
    confirmFillSampleAction(value) {
      if (value) {
        this.fillFormDefault(8);
      }

      this.dialog.confirm = false;
    },
    changeVotingOption(value) {
      if (value === 'Proxy' || value === 'Ballot' || value === 'No-voting') {
        this.resetOverrideAndCollectBallots();
      }
      this.onChange('options.votingOption', value);
    },
    async openBallotCheckedAction() {
      try {
        this.openBallot = true;
        const recipe = await getRecipe('meetings.settings.open_ballot');
        const template = recipe.data.data.Meeting.proxyTemplate;

        this.proxyTemplateValue = 'custom';
        this.onChange('options.proxyTemplate', 'custom');

        const templateContent = `${template.header}${template.to}${template.proxyBody}${template.terms}${template.termsEmail}${template.signature}${template.footer}`;
        this.renderKey++;

        this.templateContent = templateContent;
        this.onChange('proxyTemplate.content', templateContent);

        this.showEditor = true;

        this.$emit('update', { key: 'options.openBallot', value: true });
        this.dialog.openBallotChecked = false;
      } catch (err) {
        console.log(err);
      }
    },
    openBallotUncheckedAction() {
      this.openBallot = false;
      this.selectChange('on-bill-106');

      this.$emit('update', { key: 'options.openBallot', value: false });
      this.dialog.openBallotUnchecked = false;
    }
  }
};
</script>

<style scoped></style>
