'use strict';

/**
 * @fileoverview Vuex module for v-tour product tour library
 */

const state = {
  /** Mock Data
   * Needed to make page elements visible for highlight in tour
   */

  // Voting Matters
  mockDataMeetingRTVQuestionsArray: [
    {
      active: false,
      choices: ['option 1', 'option 2'],
      id: 0,
      label: 'Test Question',
      maxChoices: 1,
      startAt: null,
      endAt: null,
      timeLeft: null,
      tally: []
    }
  ],
  // Members panel
  mockDataMeetingUnitsListData: [],

  /** Vue-tour step data
   * Used as params for vbm product tour
   * See <v-tour> "vbmTour" component
   * Documentation: https://github.com/pulsardev/vue-tour/wiki/Features
   **/
  vbmSteps: [
    // Member Panel
    {
      target: '[data-v-step="vbm-members-container"]', // We're using document.querySelector() under the hood
      header: {
        title: 'Manage members'
      },
      content: `Add, invite, and manage board members and special guests.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-members-add-import-container"]',
      header: {
        title: 'Import Members'
      },
      content: `Import a guest list from a previous meeting or upload your own list.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-add-members-btn"]',
      header: {
        title: 'Add Members'
      },
      content: `Add members one by one here.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-members-invite-all-btn"]',
      header: {
        title: 'Invite Members'
      },
      content: `Send invites to all members at once.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-members-add-import-container"]',
      header: {
        title: 'Invite Members'
      },
      content: `You can also invite members one by one`,
      imgUrl: `https://d1v53h6w11vaa4.cloudfront.net/downloads/vbm_tour_invite.gif`,
      params: {
        placement: 'right',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-members-add-import-container"]',
      header: {
        title: 'Edit Members'
      },
      content: `Select any guest to edit their information or exclude them from voting (if applicable)`,
      imgUrl: `https://d1v53h6w11vaa4.cloudfront.net/downloads/vbm_tour_edit_member.gif`,
      params: {
        placement: 'right',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-members-add-import-container"]',
      header: {
        title: 'Meeting Portal'
      },
      content: `All invited members will be emailed a link to their Meeting Portal, where they can access Meeting Documents and vote on any matters you launch.`,
      imgUrl: `https://d1v53h6w11vaa4.cloudfront.net/downloads/vbm_tour_meeting_portal.png`,
      params: {
        placement: 'right',
        enableScrolling: false,
        highlight: true
      }
    },

    // Your Meeting panel
    {
      target: '[data-v-step="vbm-your-meeting-edit-btn"]',
      header: {
        title: ''
      },
      content: `Select Edit to change the meeting name, date, time, or cancel the meeting.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-your-meeting-recording-switch"]',
      header: {
        title: 'Enable or disable a meeting recording'
      },
      content: `Before the meeting, decide whether you want it recorded. The file will be uploaded to the Private Documents tab after your meeting and will only be visible to you.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-your-meeting-start-btn"]',
      header: {
        title: ''
      },
      content: `Click here to start your meeting.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },

    // Meeting Spectator Panel
    {
      target: '[data-v-step="vbm-spectator-container"]',
      header: {
        title: ''
      },
      content: `Invite guests such as owners using the information below.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },

    // Documents Panel
    {
      target: '[data-v-step="vbm-documents-tab"]',
      header: {
        title: 'Meeting documents'
      },
      content: `Upload documents you want to share with members here. Documents will be visible in the Meeting Portal.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-private-documents-tab"]',
      header: {
        title: 'Private documents'
      },
      content: `Upload documents for your eyes only. If you enable live recording, it will be uploaded here after your meeting.`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    },

    // Voting Matters Panel
    {
      target: '[data-v-step="vbm-voting-matters"]',
      header: {
        title: 'Set up voting matters for your Members'
      },
      content: `The Meeting Portal that members can see will automatically refresh when a new question is launched.`,
      imgUrl: `https://d1v53h6w11vaa4.cloudfront.net/downloads/vbm_tour_meeting_portal_vote.gif`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-add-voting-matter-btn"]',
      header: {
        title: 'Add a new voting matter'
      },
      content: `Set up your question, how many choices members can make, and the possible answers.`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-voting-matter-table-row"]',
      header: {
        title: 'Edit a question'
      },
      content: `Click anywhere on a question to edit it.`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="voting-matter-start-btn"]',
      header: {
        title: 'Start and stop a vote'
      },
      content: `Select Start and choose how many minutes your members have to vote. Press the timer to stop a vote early.`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="voting-matter-timer-btn"]',
      header: {
        title: 'Add more time'
      },
      content: `Select the clock to add more time to your questions`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-voting-matter-results-btn"]',
      header: {
        title: 'See results'
      },
      content: `View the vote results. Only you can see these details.`,
      params: {
        placement: 'top',
        enableScrolling: true,
        highlight: true
      }
    },
    {
      target: '[data-v-step="vbm-view-tour"]',
      header: {
        title: 'View tour again'
      },
      content: `If you'd like to view this product tour again, click here!`,
      params: {
        placement: 'top',
        enableScrolling: false,
        highlight: true
      }
    }
  ]
};

const getters = {
  vbmSteps: state => state.vbmSteps,
  mockDataMeetingRTVQuestionsArray: state =>
    state.mockDataMeetingRTVQuestionsArray,
  mockDataMeetingUnitsListData: state => state.mockDataMeetingUnitsListData
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
