<template>
  <v-layout align-baseline>
    <v-flex :xs5="showPrintOptions" :xs6="!showPrintOptions">
      <v-select
        label="Name"
        :value="name"
        :items="nameList"
        class="my-1 mr-1"
        v-if="actionType === 'addSingle'"
        @change="updateName($event)"
      />
      <v-text-field
        label="Name"
        hide-details
        class="my-1 mr-1"
        :value="name"
        v-else
        @input="emitUpdate({ key: 'name', value: $event })"
      />
    </v-flex>
    <v-flex xs6>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        lazy
        full-width
        transition="scale-transition"
        max-width="290px"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          label="Due At"
          :value="
            `${formatDate(dueAt)} ${
              isValidDate(dueAt) ? '' : invalidDateMessage(dueAt)
            }`
          "
          persistent-hint
          readonly
        />

        <!-- if no dueAt, you will need to add -->
        <v-date-picker
          no-title
          scrollable
          hide-details
          clearable
          actions
          :value="dueAt"
          @input="emitUpdate({ key: 'dueAt', value: $event })"
        >
          <v-btn
            v-if="dueAt"
            small
            flat
            @click="emitUpdate({ key: 'dueAt', value: '' })"
          >
            <v-icon>close</v-icon> Clear Date
          </v-btn>
          <v-btn v-if="!dueAt" flat small @click="emitAddDefault">
            + default ({{ getDefaultDays(type, stage) }})
          </v-btn>
          <v-spacer />
          <v-btn color="primary" small @click="menu = false">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex v-if="showPrintOptions" xs1>
      <span v-if="stage === 'pre-notice' || stage === 'service'">
        <v-tooltip left>
          <span slot="activator">
            <v-checkbox
              :append-icon="meta.noMailing ? 'print_disabled' : 'print'"
              :input-value="meta.noMailing ? meta.noMailing : false"
              :true-value="false"
              :false-value="true"
              @change="
                emitUpdate({ key: 'meta', value: { noMailing: $event } })
              "
            />
          </span>
          <span>{{
            meta.noMailing
              ? 'No printing required'
              : 'This notice requires printing'
          }}</span>
        </v-tooltip>
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  isWeekend,
  isHoliday,
  getDefaultDate,
  payloadDateFormat
} from '@/lib/checklist-helpers';
import moment from 'moment';

export default {
  name: 'ChecklistInputItem',
  props: {
    meetingDate: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    nameList: {
      type: Array,
      default() {
        return [];
      }
    },
    corpNameShort: {
      type: String,
      default: ''
    },
    dueAt: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    stage: {
      type: String,
      default: ''
    },
    group: {
      type: Object,
      default() {
        return {};
      }
    },
    meta: {
      type: Object,
      default() {
        return {};
      }
    },
    showPrintOptions: {
      type: Boolean,
      default: true
    },
    actionType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menu: false
    };
  },
  methods: {
    emitUpdate(payload) {
      payload.stage = this.stage;

      // If dueAt is being updated and the value is truthy, format entry to 7pm EST
      if (payload.key === 'dueAt' && payload.value) {
        payload.value = payloadDateFormat({
          date: moment(payload.value).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ'),
          hour: 19,
          min: 0
        });
      }

      this.$emit('update', payload);
    },
    formatDate(date) {
      if (date) {
        return moment(date)
          .tz(this.meetingTimezone ? this.meetingTimezone : 'America/Toronto')
          .format('ddd, MMM Do YYYY');
      }
      return '';
    },
    isValidDate(date) {
      return !isWeekend(date) && !isHoliday(date);
    },
    invalidDateMessage(date) {
      if (isWeekend(date)) {
        return '(Date is a weekend)';
      }
      if (isHoliday(date)) {
        return '(Date is a holiday)';
      }
      return '';
    },
    emitAddDefault() {
      let dueAt = getDefaultDate(
        this.getDefaultDays(this.type, this.stage).split(' ')[0],
        this.meetingDate,
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
      );
      this.emitUpdate({ key: 'dueAt', value: dueAt });
    },
    getDefaultDays(eventType, checklistType) {
      if (eventType === 'agm' || eventType === 'meeting') {
        switch (checklistType) {
          case 'pre-consent':
            return '45 Days';
          case 'pre-notice':
            return '38 Days';
          case 'service':
            return '18 Days';
          default:
            return '';
        }
      } else {
        switch (checklistType) {
          case 'pre-consent':
            return '7 Days';
          case 'service':
            return '0 Days';
          default:
            return '';
        }
      }
    },
    updateName(name) {
      if (this.group) {
        // Get the checklist template for this group/stage
        const checklist = this.group.checklistTemplates.find(
          checklist => checklist.name === name
        );

        // Update checklist label
        this.emitUpdate({
          key: 'name',
          value: name
        });

        // Update checklist due date
        this.emitUpdate({
          key: 'dueAt',
          value: payloadDateFormat({
            date: getDefaultDate(
              checklist.deadline,
              this.meetingDate,
              this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
            ),
            hour: 19,
            min: 0,
            timezone: this.meetingTimezone
              ? this.meetingTimezone
              : 'America/Toronto'
          })
        });

        // Update checklist items
        this.emitUpdate({
          key: 'items',
          value: checklist.items
        });
      }
    }
  }
};
</script>

<style scoped></style>
