<template>
  <v-dialog max-width="450px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Delete All Units?</span>
      </v-card-title>
      <v-card-text>
        You are about to delete
        <b>all units ({{ totalItems }} units)</b>. Type "delete all" to confirm.
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="confirmText"
          placeholder="Type here..."
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          color="error"
          @click.native="emitDelete"
          :disabled="confirmText !== 'delete all'"
          >CONFIRM</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsDeleteAllUnitsDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    totalItems: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      confirmText: ''
    };
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('delete');
    }
  }
};
</script>

<style scoped></style>
