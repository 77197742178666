import http from './requestType';

/**
 * Returns Zoom info
 * @return {Promise} - Promise
 */
export function getZoomInfo() {
  return http._get(`Zoom/info`);
}

/**
 * Test if token is valid
 * @return {Promise} - Promise
 */
export function zoomTokenTest() {
  return http._get(`Zoom/check`);
}

/**
 * Refresh zoom token
 * @return {Promise} - Promise
 */
export function zoomRefreshToken() {
  return http._post(`Zoom/refresh`);
}

/**
 * Get zoom account plan
 * @return {Promise} - Promise
 */
export function zoomPlan() {
  return http._get(`Zoom/plan`);
}
