import http from './requestType';

/**
 * List of all notices
 * @param  {DateTime} fromDate  - the start date of the search. Defaults to now.
 * @param  {DateTime} toDate    - the end date of the search. Defaults to 7 days from now.
 * @param  {Array} statuses     - statuses to include in the search. Defaults to ['sent', 'scheduled', 'draft']. Draft notices without a sendOn date will only be included if the include_unscheduled_drafts param is set to true.
 * @param  {Boolean} includeUnscheduledDrafts - If including draft notices, set this to true to also return unscheduled drafts. Defaults to false.
 * @return {Promise}            - Promise
 */
export function getNoticeList(
  fromDate,
  toDate,
  statuses,
  includeUnscheduledDrafts
) {
  return http._get(
    `Notices${statuses}&from=${fromDate}&to=${toDate}&include_unscheduled_drafts=${includeUnscheduledDrafts}`
  );
}

/**
 * Get the stats for a notice
 *
 * @param  {String} noticeKey the notice key
 * @return {Object}           the notice's stats
 */
export function getNoticeStats(noticeKey) {
  return http._get(`Notices/${noticeKey}/stats`);
}

/**
 * Returns a list of all standard merge variables available for all notices
 * @return {Promise} - Promise
 */
export function getNoticeFields() {
  return http._get(`Notices/fields`);
}

/**
 * Create a Notice (email reminder) in Draft Mode
 * @param  {String} notice  - the new notice
 * @return {Promise}        - Promise
 */
export function postNotice(notice) {
  return http._post(`Notices`, notice);
}

/**
 * Create the list of notices provided and schedule
 * @param  {String} notices  - the list of notices to create and schedule
 * @return {Promise}        - Promise
 */
export function scheduleNoticeList(scheduleParams) {
  return http._post(`Notices/actions/scheduleNotices`, scheduleParams);
}

/**
 * Delete a Meeting's notice, but only if it's status is equal to 'draft'. Sent or sending notices cannot be deleted.
 * @param  {String} noticeKey - notice key
 * @return {Promise}          - Promise
 */
export function deleteNotice(noticeKey) {
  return http._delete(`Notices/${noticeKey}`);
}

/**
 * Returns the Notice associated with the noticeKey
 * @param  {String} noticeKey - notice key
 * @return {Promise}          - Promise
 */
export function getNoticeDetails(noticeKey) {
  return http._get(`Notices/${noticeKey}`);
}

/**
 * Update a meeting notice, but only if it's status is equal to 'draft'. Sending or sent notices cannot be changed.
 * @param  {String}  noticeKey  - the Notice key
 * @param  {Boolean} force      - whether to force update or not (for sent/sending notices)
 * @param  {String}  notice     - new notice
 * @return {Promise}            - Promise
 */
export function putNotice(noticeKey, force = false, notice) {
  return http._put(`Notices/${noticeKey}?force=${force}`, notice);
}

/**
 * Gets a preview of a notice to be sent to a voter.
 * Optionally specify a `voterKey` to preview what the voter would be sent.
 * When no voterKey is provided, it will utilize a sample test voter.
 * The preview contains the subject, unit attachments and body of the email to be sent.
 * This purpose of the route is for previewing the content as a user builds the email in an editor.
 * @param  {String} noticeKey - the Notice key
 * @param  {String} voterKey  - the Voter key (optional)
 * @return {Promise}          - Promise
 */
export function getNoticePreview(noticeKey, voterKey) {
  return http._get(`Notices/${noticeKey}/preview?voterKey=${voterKey}`);
}

/**
 * Sends the notice
 * @param  {String} noticeKey - the Notice key
 * @return {Promise}          - Promise
 */
export function postSendNotice(noticeKey) {
  return http._post(`Notices/${noticeKey}/send`);
}

/**
 * Sets a notice to be automatically sent on the Notice's sendOn date.
 * Notices must have a valid sendOn date already set and saved.
 * Meetings that are expired cannot have new notices scheduled.
 * @param  {String} noticeKey - the Notice key
 * @return {Promise}          - Promise
 */
export function postScheduleNotice(noticeKey) {
  return http._post(`Notices/${noticeKey}/schedule`);
}

/**
 * Unschedules a notice that has been scheduled.
 * @param  {String} noticeKey - the Notice key
 * @return {Promise}          - Promise
 */
export function postUnscheduleNotice(noticeKey) {
  return http._post(`Notices/${noticeKey}/unschedule`);
}

/**
 * Sends a test of the specified email notice to the provided email addresses.
 * It will try to include the merge fields, however the call-to-action buttons will not work since
 * it does not do merging on voter data. This is meant to be used to preview the layout in an actual email client.
 * @param  {String} noticeKey - the Notice key
 * @param  {Array}  payloadObj - emails and preview obj
 * @return {Promise}          - Promise
 */
export function postSendTestNotice(noticeKey, payload) {
  return http._post(`Notices/${noticeKey}/test`, payload);
}

/**
 * Returns the list of notices with suggested dates
 * @param  {Object} generateParams - parameters required for generating date suggestions
 * @return {Promise} - Promise
 */
export function generateNoticeListSchedule(generateParams) {
  return http._post(`Notices/actions/generateNoticeSchedule`, generateParams);
}
