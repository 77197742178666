<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Invoices</p>
        <p class="secondary--text">
          View and manage all GetQuorum Invoices. A
          <a href="/admin/campaigns">campaign</a> may have multiple invoices
          attached to it. An invoice must be tied to a campaign.
        </p>
        <v-alert :value="true" type="info" outline
          >All subscription invoices have been migrated to Chargebee. They will
          not appear on this page.</v-alert
        >
      </v-flex>
    </v-layout>

    <admin-invoices-summaries />

    <v-layout row class="ma-3">
      <v-flex xs12>
        <invoice-list-table :invoice-list-data="invoiceListData" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatCurrencyValue } from '@/helpers';
import { dateFormatReadable } from '@/filters';

import AdminInvoicesSummaries from './AdminInvoicesListViewSummary';
import InvoiceListTable from '@/components/InvoiceListTable';

export default {
  name: 'AdminInvoicesListView',
  components: {
    AdminInvoicesSummaries,
    InvoiceListTable
  },
  data() {
    return {
      dataLoaded: true
    };
  },
  filters: {
    formatCurrencyValue,
    dateFormatReadable
  },
  computed: {
    ...mapGetters('invoices', ['invoiceListData'])
  },
  methods: {}
};
</script>

<style lang="scss"></style>
