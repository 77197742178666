<template>
  <v-dialog max-width="600px" :value="value" persistent>
    <v-card>
      <v-card-title>
        <span class="title">Set / change {{ type }} contact</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-select
          ref="associationSelect"
          v-model="selected"
          :items="contacts"
          :label="`Set ${type} contact`"
          :placeholder="`Select ${type} contact`"
          :item-text="getLabel"
          return-object
          dense
          clearable
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose" :disabled="loading">Cancel</v-btn>
        <v-btn
          class="primary"
          @click.native="onSave()"
          :loading="loading"
          :disabled="!isSelected"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'UpdateAccountContactAssociationDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    contacts: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'main'
    }
  },

  data() {
    return {
      typeRefs: {
        main: 'main_contact',
        billing: 'main_billing_contact'
      },
      accountKey: this.$route.params.accountKey,
      selected: null,
      loading: false
    };
  },
  computed: {
    isSelected() {
      return !_.isEmpty(this.selected);
    }
  },
  methods: {
    ...mapActions('accounts', [
      'updateContactAssociation',
      'getAccountContacts'
    ]),
    emitClose() {
      this.$emit('close');
      this.selected = null;
    },
    getLabel(contact) {
      const firstName = contact.firstName || '';
      const lastName = contact.lastName || '';
      return `${firstName} ${lastName} - ${contact.email}`;
    },
    async onSave() {
      this.loading = true;
      try {
        const type = this.typeRefs[this.type];
        // check if there are already others contact with the same type
        const typeContacts = _.filter(
          this.contacts,
          contact =>
            contact.types.includes(type) && contact.id !== this.selected.id
        );

        if (typeContacts.length > 0) {
          // reset other type contact to force just 1 [primary, billing] contact
          const promises = _.map(typeContacts, contact => {
            return this.updateContactAssociation({
              key: this.accountKey,
              contactIds: [contact.id],
              types: contact.types.filter(t => t !== type)
            });
          });

          await Promise.all(promises);
        }

        // check if the contact has already the type
        const hasType = _.includes(this.selected.types, type);

        if (!hasType) {
          // update contact selected with the new type
          await this.updateContactAssociation({
            key: this.accountKey,
            contactIds: [this.selected.id],
            types: [...this.selected.types, type]
          });
        }

        await this.getAccountContacts({ key: this.accountKey });

        this.$events.$emit('toastEvent', `${this.type} contact set`);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
        this.emitClose();
      }
    }
  }
};
</script>

<style scoped></style>
