<template>
  <v-menu v-model="menu" :close-on-content-click="false" bottom left>
    <template v-slot:activator="{ on }">
      <v-btn light icon v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <v-list>
      <!-- Proxy link (visible for non-admins) -->
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>Proxy URL</v-list-tile-title>
          <v-list-tile-sub-title>
            <input
              ref="proxyurlRef"
              class="url-input"
              :value="proxyUrlPreview"
              readonly
            />
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn
            class="pa-0 ma-0"
            icon
            slot="activator"
            @click="handleCopy('proxyurlRef')"
          >
            <v-icon color="blue lighten-2">file_copy</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
      <!-- Consent link (hidden from non-admins) -->
      <v-list-tile
        v-if="consentUrlPreview && hasRealEmail && displayConsentDetails"
      >
        <v-list-tile-content>
          <v-list-tile-title>Consent URL</v-list-tile-title>
          <v-list-tile-sub-title>
            <input
              ref="consenturlRef"
              class="url-input"
              :value="consentUrlPreview"
              readonly
            />
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn
            class="pa-0 ma-0"
            icon
            slot="activator"
            @click="handleCopy('consenturlRef')"
          >
            <v-icon color="blue lighten-2">file_copy</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
      <!-- Nomination link (hidden from non-admins) -->
      <v-list-tile v-if="nominationUrlPreview">
        <v-list-tile-content>
          <v-list-tile-title>Nomination URL</v-list-tile-title>
          <v-list-tile-sub-title>
            <input
              ref="nominationurlRef"
              class="url-input"
              :value="nominationUrlPreview"
              readonly
            />
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn
            class="pa-0 ma-0"
            icon
            slot="activator"
            @click="handleCopy('nominationurlRef')"
          >
            <v-icon color="blue lighten-2">file_copy</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
      <!-- Live Vote link (visible for non-admins) -->
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>Live Vote URL</v-list-tile-title>
          <v-list-tile-sub-title>
            <input
              ref="liveVoteUrlRef"
              class="url-input"
              :value="liveVoteUrlPreview"
              readonly
            />
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn
            class="pa-0 ma-0"
            icon
            slot="activator"
            @click="handleCopy('liveVoteUrlRef')"
          >
            <v-icon color="blue lighten-2">file_copy</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'MeetingUnitsEditVoterDialogVoterLinksMenu',
  props: {
    voterSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    displayConsentDetails: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      menu: false,
      proxyUrlPreview: '',
      consentUrlPreview: '',
      nominationUrlPreview: '',
      liveVoteUrlPreview: ''
    };
  },
  watch: {
    voterSelected: function(value) {
      this.setLinks(value);
    }
  },
  computed: {
    hasRealEmail() {
      return this.voterSelected.email !== 'noemail@getquorum.com';
    }
  },
  methods: {
    ...mapActions('meetings/units', ['getVoterLinks']),
    async setLinks(voterSelected) {
      const gpUrl = _.get(this.options, 'useGeneralProxyApp', false);
      try {
        const links = await this.getVoterLinks({
          shortCode: this.$route.params.shortcode,
          unitId: voterSelected.unit_id,
          email: voterSelected.email
        });
        this.proxyUrlPreview = gpUrl ? links.proxyUrl : links.votingUrl;
        this.consentUrlPreview = links.consentUrl;
        this.nominationUrlPreview = links.nominationsUrl;
        this.liveVoteUrlPreview = links.liveVoteUrl;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    handleCopy(ref) {
      this.$refs[ref].select();
      document.execCommand('copy');
    }
  }
};
</script>

<style scoped>
.url-input {
  min-width: 250px;
}
</style>
