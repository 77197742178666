<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <list-ballots-view-table
          :ballot-list-data="ballotListData"
          :current-meeting="currentMeeting"
          @openDialog="openDialog"
          @closeDialog="closeDialog"
          @selected="setSelected"
          @targetEmail="editBallot"
        />

        <!-- Add ballot dialog -->
        <add-ballot-dialog
          v-model="dialog.addBallot"
          @addBallot="add"
          @close="dialog.addBallot = false"
        />

        <!-- Reset ballot(s) dialog -->
        <delete-ballots-dialog
          v-model="dialog.deleteBallot"
          :selected="selected"
          @deleteBallot="deleteSelected"
          @close="dialog.deleteBallot = false"
        />

        <!-- Edit Ballot -->
        <edit-ballot-dialog
          v-model="dialog.editBallot"
          :ballot="state.thisBallot"
          :ballotLink="meetingBallotLink"
          :ballot-unit-list-data="meetingBallotUnitListData"
          @addUnit="addUnit"
          @removeUnit="removeUnit"
          @deleteBallot="deleteBallot"
          @sendBallot="sendSelected"
          @close="dialog.editBallot = false"
        />

        <!-- Send Ballot Email -->
        <send-ballot-dialog
          v-model="dialog.sendBallot"
          :selected="selected"
          @sendBallot="sendSelected"
          @close="dialog.sendBallot = false"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ListBallotsViewTable from './MeetingRtvBallotsListViewTable';
import AddBallotDialog from '@/views/meetings/meeting/rtv/MeetingRtvBallotAddDialog';
import DeleteBallotsDialog from '@/views/meetings/meeting/rtv/MeetingRtvBallotsDeleteDialog';
import EditBallotDialog from '@/views/meetings/meeting/rtv/MeetingRtvBallotEditDialog';
import SendBallotDialog from '@/views/meetings/meeting/rtv/MeetingRtvBallotSendDialog';

export default {
  name: 'MeetingRtvBallotsListView',
  components: {
    ListBallotsViewTable,
    AddBallotDialog,
    DeleteBallotsDialog,
    EditBallotDialog,
    SendBallotDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    ballotListData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    ballotList: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      state: {},
      dialog: {
        addBallot: false,
        deleteBallot: false,
        editBallot: false,
        sendBallot: false
      },
      shortCode: this.$route.params.shortcode,
      selected: []
    };
  },
  computed: {
    ...mapGetters('meetings/ballots', [
      'meetingBallotStatusData',
      'meetingBallotLink',
      'meetingBallotUnitListData'
    ]),
    currentBallotStatus() {
      return this.meetingBallotStatusData;
    }
  },
  methods: {
    ...mapActions('meetings/ballots', [
      'getMeetingBallots',
      'getMeetingBallotStatus',
      'getMeetingBallotUnits',
      'getMeetingBallotLink',
      'createMeetingBallot',
      'deleteMeetingBallot',
      'deleteMeetingBallotKeys',
      'sendBallotEmails',
      'addMeetingBallotUnit',
      'deleteMeetingBallotUnit'
    ]),
    setSelected(selected) {
      this.selected = selected;
    },
    openDialog(key) {
      this.dialog[key] = true;
    },
    closeDialog(key) {
      this.dialog[key] = false;
    },
    // Add a ballot
    async add(ballot) {
      try {
        /* DEPRECATED - Needs updating
        await this.createMeetingBallot({
          shortCode: this.$route.params.shortcode,
          newBallot: ballot
        });
        */
        this.$events.$emit('toastEvent', 'Ballot Created');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('addBallot');
      }
    },
    // View the current status of a ballot - option to reset or add/remove units
    async editBallot(email) {
      try {
        this.state.thisBallot = _.filter(this.ballotListData, {
          email: email
        })[0];

        // Set the ballot link if a claim request exists
        if (this.state.thisBallot.claimRequestKey) {
          await this.getMeetingBallotLink({
            shortCode: this.shortCode,
            email: this.state.thisBallot.email
          });
        }

        // Retrieve the status of each voter eligible unit
        if (this.state.thisBallot.ballotKey) {
          await this.getMeetingBallotUnits({
            shortCode: this.shortCode,
            ballotKey: this.state.thisBallot.ballotKey
          });
        }
        this.dialog['editBallot'] = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Delete a single ballot
    async deleteBallot({ ballotKey }) {
      try {
        await this.deleteMeetingBallot({
          shortCode: this.shortCode,
          ballotKey
        });

        this.$events.$emit('toastEvent', 'Ballot Reset Completed');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Reset multiple ballot(s) in the list
    async deleteSelected(selectedItems) {
      let ballotKeys = this.selected.map(item => item.ballotKey);
      try {
        await this.deleteMeetingBallotKeys({
          shortCode: this.shortCode,
          ballotKeys
        });
        this.$events.$emit(
          'toastEvent',
          `${ballotKeys.length} Ballot(s) Reset`
        );
        // Clear selected after deletion
        this.selected.splice(0, this.selected.length);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('deleteBallot');
      }
    },
    // Add unit claim from target ballot
    async addUnit(unitClaim) {
      try {
        const { ballotKey, email, unitKey } = unitClaim;
        await this.addMeetingBallotUnit({
          shortCode: this.shortCode,
          email,
          ballotKey,
          unitKey
        });
        this.state.thisBallot.unitsClaimed++;

        this.$events.$emit('toastEvent', 'Unit Added to Ballot');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Remove unit claim from a ballot
    async removeUnit(unitClaim) {
      try {
        this.state.thisBallot.unitsClaimed--;

        // Close the dialog when the last unit claim has been removed
        if (this.state.thisBallot.unitsClaimed == 0) {
          this.closeDialog('editBallot');
        }
        const { ballotKey, email, unitKey } = unitClaim;
        await this.deleteMeetingBallotUnit({
          shortCode: this.shortCode,
          email,
          ballotKey,
          unitKey
        });

        this.$events.$emit('toastEvent', 'Unit Removed from Ballot');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Send one or many ballot email(s)
    async sendSelected(voters) {
      try {
        await this.sendBallotEmails({
          shortCode: this.shortCode,
          voters
        });
        this.$events.$emit(
          'toastEvent',
          `${voters.length} Ballot Email(s) Sent`
        );
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.closeDialog('sendBallot');
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
