<template>
  <div>
    <issues-status
      type="check"
      :status="isAttachment"
      text="File attached" />
    <issues-status
      type="check"
      :status="isSnippet"
      text="Signature merge tag included" />
    <issues-status
      type="check"
      :status="isMonthlyFeeIncluded"
      text="Monthly fee merge tag included" />
  </div>
</template>

<script>
import issuesStatus from '@/components/IssuesStatus';

export default {
  name: 'NoticeSendChecklist',
  components: {
    issuesStatus
  },
  props: {
    body: {type: String, default: ''},
    attachments: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    isAttachment() {
      if (this.attachments) {
        return this.attachments.length !== 0;
      }
      return false;
    },
    isSnippet() {
      return this.body.indexOf('{{{signature}}}') > -1;
    },
    isMonthlyFeeIncluded() {
      return this.body.indexOf('{{customUnitFields.monthly_fee}}') > -1;
    }
  }
};
</script>

<style scoped>
</style>
