<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Templates</p>
        <p class="secondary--text">
          Manage common notice or conversation templates types used through
          campaigns. Click on a notice or conversation to view and edit it.
        </p>
      </v-flex>
    </v-layout>

    <v-layout class="ml-3 mr-3 mb-3" row>
      <v-flex xs12>
        <notice-templates-table
          :state="noticeTemplatesData"
          @new-button-clicked="dialog.newNotice = true"
          @row-clicked="goNotice"
        />
      </v-flex>
    </v-layout>

    <!-- Dialogs -->
    <v-dialog v-model="dialog.newNotice" persistent max-width="500px">
      <v-card>
        <v-card-title class="title">Create New Template</v-card-title>
        <v-card-text>
          <v-text-field
            label="Template Name"
            v-model="newNotice.name"
            placeholder="eg. Proxy Deadline Reminder"
          />
          <v-text-field
            label="Subject for Email"
            v-model="newNotice.subject"
            placeholder="eg. Proxy Deadline"
            :rules="[
              () => newNotice.subject.length > 0 || 'This field is required'
            ]"
            required
          />
          <v-combobox
            :items="templateTypeItems"
            v-model="newNotice.meta.templateType"
            clearable
            label="Set template type"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.newNotice = false">Cancel</v-btn>
          <v-btn class="primary" @click.native="addNewNoticeClicked">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NoticeTemplatesTable from './AdminNoticeTemplatesTable';

export default {
  name: 'AdminNoticeTemplatesListView',
  components: {
    NoticeTemplatesTable
  },
  props: {
    noticeTemplatesList: {
      type: Object,
      required: true
    },
    noticeTemplatesData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    }
  },
  data() {
    return {
      state: {},
      newNotice: {
        name: '',
        subject: '',
        meta: {
          templateType: ''
        }
      },
      dialog: {
        newNotice: false
      }
    };
  },
  computed: {
    ...mapGetters('noticeTemplates', ['templateTypeItems'])
  },
  methods: {
    ...mapActions('noticeTemplates', ['createNoticeTemplate']),
    async addNewNoticeClicked() {
      try {
        const key = await this.createNoticeTemplate({
          name: this.newNotice.name,
          subject: this.newNotice.subject,
          templateType: this.newNotice.meta.templateType
        });
        this.$events.$emit('toastEvent', 'New Notice Template Created');
        this.dialog.newNotice = false;
        this.goNotice(key);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    goNotice(noticeTemplateKey) {
      this.$router.push({
        name: 'adminNoticeTemplate',
        params: { noticeTemplateKey }
      });
    }
  }
};
</script>

<style scoped></style>
