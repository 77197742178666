<template>
  <v-dialog max-width="450px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title> <span class="title">Add Member</span> </v-card-title>
      <v-card-text>
        <label for="name">Name</label>
        <v-text-field
          name="name"
          maxlength="64"
          counter="64"
          v-model="newMember.name"
          :rules="rules.name"
          outline
          single-line
          required
        />
        <label for="email">Email</label>
        <v-text-field
          name="email"
          outline
          single-line
          v-model="newMember.email"
          :rules="rules.email"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Cancel</v-btn>
        <v-btn
          :disabled="!(newMember.name && newMember.email)"
          :loading="saving"
          color="primary"
          @click.native="addMember"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import validator from 'email-validator';

export default {
  name: 'MeetingVbmAddMemberDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    shortCode: {
      type: String,
      required: true
    },
    // Possible types are 'boardMember' and 'spectators'
    type: {
      type: String,
      default() {
        return 'boardMember';
      }
    }
  },
  data() {
    return {
      newMember: {
        name: '',
        email: ''
      },
      rules: {
        name: [v => !!v || 'Name is required'],
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ]
      },
      saving: false
    };
  },
  methods: {
    ...mapActions('meetings/units', [
      'createMeetingUnit',
      'addMeetingUnitVoter',
      'getTotalUnits'
    ]),
    ...mapActions('meetings/webcast', [
      'fetchWebcastPanelists',
      'addWebcastPanelists'
    ]),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');

      // Clear value
      this.newMember.name = '';
      this.newMember.email = '';
    },
    async addMember() {
      try {
        this.saving = true;

        // When adding a member, look up the latest unit id
        const currentUnitId = await this.getTotalUnits({
          shortCode: this.shortCode
        });

        // create a unit
        let { id } = await this.createMeetingUnit({
          shortCode: this.shortCode,
          newUnit: {
            unit: `vbm${currentUnitId}`,
            address: 'VBM Meeting',
            customData:
              this.type === 'boardMember' ? { join_as: 'board_member' } : {}
          }
        });

        // add voter to that unit
        await this.addMeetingUnitVoter({
          shortCode: this.shortCode,
          unitId: id,
          newVoter: this.newMember
        });

        // If boardMember type, add as panelist
        if (this.type === 'boardMember') {
          const { name, email } = this.newMember;
          await this.addWebcastPanelists({
            shortCode: this.shortCode,
            name,
            email
          });
        }

        await this.fetchWebcastPanelists({
          shortCode: this.shortCode
        });

        await this.getMeetingVoters({ shortCode: this.shortCode });

        this.$events.$emit('toastEvent', 'Member Added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
        this.emitClose();
      }
    }
  }
};
</script>

<style scoped></style>
