'use strict';

export default function getEventColor(type, subType) {
  if (type === 'campaign' && subType === 'meeting') {
    return 'green';
  }

  if (type === 'checklist') {
    switch (subType) {
      case 'service':
        return 'red';
      case 'pre-notice':
        return 'light-blue';
      case 'pre-consent':
        return 'orange';
      case 'vm':
        return 'purple';
      case 'vm-onboard':
        return 'pink';
      case 'vm-post':
        return 'cyan';
      case 'meeting':
        return 'green';
      case 'invoice':
        return 'teal';
      case 'general':
        return 'blue';
      case 'collaborator':
        return 'indigo';
      case 'agent':
        return 'blue-grey';
      case 'complaint':
        return 'red';
      default:
        return 'grey';
    }
  }

  return 'grey';
}
