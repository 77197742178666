'use strict';

/**
 * @fileoverview Meeting Voters Vuex submodule.
 * Used to store state for the current meeting's voter data
 */

import { getMeetingsVotersList } from '@/api/meetings-voters';

const state = {
  meetingVotersList: {}
};

const getters = {
  votersList: state => state.meetingVotersList,
  votersListData: state => Object.values(state.meetingVotersList)
};

const actions = {
  /**
   * Get the meeting's voter list
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getMeetingVoters({ commit }, { shortCode, search = '' }) {
    try {
      const res = await getMeetingsVotersList({ shortCode, search });

      return commit('SET_CURRENT_MEETING_VOTERS_INFORMATION', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingVoters action', err);
      throw err;
    }
  }
};

const mutations = {
  // Reset the state
  CLEAR_STATE(state) {
    state.meetingVotersList = {};
  },

  SET_CURRENT_MEETING_VOTERS_INFORMATION(state, voters) {
    state.meetingVotersList = voters;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
