<template>
  <div class="flex">
    <v-icon>{{ icon }}</v-icon>
    <div class="label-header">{{ labelheader }}</div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'VoteTallyListLabel',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon() {
      switch (this.label) {
        case 'yes':
          return 'check';
        case 'no':
          return 'close';
        case 'abstain':
          return 'block';
        case 'non-resident':
          return 'close';
        default:
          return 'person';
      }
    },
    labelheader() {
      return _.capitalize(this.label);
    }
  }
};
</script>

<style scoped>
.label-header {
  padding-left: 20px;
  font-size: 15px;
  text-transform: capitalize;
}
.flex {
  display: flex;
}
</style>
