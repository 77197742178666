<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Format Option</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-card-text>Choose the format to download.</v-card-text>
      <v-container class="pt-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-radio-group
              name="formats"
              :mandatory="true"
              @change="handleUpdate({ value: $event })"
            >
              <v-radio
                v-for="format in formats"
                :label="format.label"
                :value="format.value"
                :key="format.value"
              />
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn flat @click.native="$emit('close')">Cancel</v-btn>
          <v-spacer />
          <v-btn
            v-if="formatOption === 'download'"
            :disabled="!this.selectedFormat"
            @click.native="handleDownload()"
            color="primary"
            class="white--text"
            >Download</v-btn
          >
          <v-btn
            v-else
            :disabled="!this.selectedFormat"
            @click.native="handleSend()"
            color="primary"
            class="white--text"
            >Send</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FormatOptionDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    formatOption: {
      type: String
    }
  },
  data() {
    return {
      selectedFormat: null,
      formats: [
        { label: 'Default', value: 'default' },
        {
          label: 'Florida',
          value: 'florida'
        }
      ]
    };
  },
  methods: {
    handleUpdate({ value }) {
      this.selectedFormat = value;
    },
    handleDownload() {
      this.$emit('download', { format: this.selectedFormat });
    },
    handleSend() {
      this.$emit('send', { format: this.selectedFormat });
    }
  }
};
</script>

<style scoped>
.black-text {
  color: rgba(0, 0, 0, 0.87);
}
</style>
