'use strict';

/**
 * @fileoverview Vuex module for ZOOM
 */

import * as zoomApi from '@/api/zoom';

const state = {
  zoomInfo: {},
  zoomPlan: {}
};

const getters = {
  zoomInfo: state => state.zoomInfo,
  zoomPlan: state => state.zoomPlan
};

const actions = {
  async getZoomInfo({ commit }) {
    try {
      const res = await zoomApi.getZoomInfo();
      commit('SET_ZOOM_INFO', res?.data);

      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async testZoomToken({ commit }) {
    try {
      const res = await zoomApi.zoomTokenTest();
      return res.data;
    } catch (err) {
      commit('SET_ZOOM_INFO', {});
      return err.response?.data;
    }
  },
  async refreshZoomToken() {
    try {
      return await zoomApi.zoomRefreshToken();
    } catch (err) {
      throw err;
    }
  },
  async getZoomPlan({ commit }) {
    try {
      const res = await zoomApi.zoomPlan();
      commit('SET_ZOOM_PLAN', res?.data);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_ZOOM_INFO(state, data) {
    state.zoomInfo = data;
  },
  SET_ZOOM_PLAN(state, data) {
    state.zoomPlan = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
