'use strict';

import axios from 'axios';

const instance = axios.create();

/**
 * Forces a file to download
 * @param {string} url   the url of the file
 * @param {string} name  the file name
 */
export default async function forceDownloadUrl({url, name}) {
  try {
    // Retrieve the file contents from the URL
    const {data} = await instance({
      url,
      method: 'get',
      responseType: 'arraybuffer'
    }).catch(err => {
      console.error('Error retrieving file from URL', url, err);
      throw err;
    });

    // Create the link to force browser to download
    const windowUurl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = windowUurl;
    link.setAttribute('download', name);
    document.body.appendChild(link);

    // Force the download
    link.click();

    // Cleanup the link
    link.remove();
  } catch (err) {
    console.error('Error forcing file download', err);
    throw err;
  }
}
