<template>
  <v-dialog max-width="450px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title">Mark Unit as Eligible?</span>
      </v-card-title>

      <v-card-text>
        <p>
          To remove this unit's <strong>ineligible_reason</strong> field, click
          <strong>CONFIRM</strong>:
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn color="primary" @click.native="emitUpdate">confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsMarkEligibleDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unit: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitUpdate() {
      // copy unit object and delete `ineligible_reason` property
      const updatedUnit = _.cloneDeep(this.unit);
      delete updatedUnit.customData.ineligible_reason;

      this.$emit('update', {
        ...updatedUnit,
        actionLogTag: 'delete',
        targetLogTag: 'unit-tag'
      });
      this.emitClose();
    }
  }
};
</script>

<style scoped></style>
