<template>
  <div>
    <h5 class="headline font-weight-bold mt-3">
      Voting Matters
    </h5>
    <v-divider class="gq-divider" />

    <div v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <voting-matters
      v-else
      data-v-step="vbm-voting-matters"
      :questions="
        tourActive ? mockDataMeetingRTVQuestionsArray : meetingRTVQuestionsArray
      "
      :current-meeting="currentMeeting"
      :tour-active="tourActive"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import VotingMatters from './MeetingVbmVotingMatters.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MeetingVbmBusiness',
  components: {
    VotingMatters
  },
  props: {
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    tourActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      dialogType: null,
      loading: true,
      shortCode: this.$route.params.shortcode
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('meetings/rtvQuestions', ['meetingRTVQuestionsArray']),
    ...mapGetters('vTour', ['mockDataMeetingRTVQuestionsArray'])
  },
  methods: {
    ...mapActions('meetings/rtvQuestions', ['getMeetingRTVQuestions']),
    async init() {
      try {
        this.loading = true;
        await this.getMeetingRTVQuestions({ shortCode: this.shortCode });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
.round-button {
  border-radius: 5px;
}
.round-card {
  border-radius: 7px;
}
</style>
