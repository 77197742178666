<template>
  <div>
    <main-nav-drawer
      v-model="drawer"
      :routes="adminRoutes"
      :local-profile="localProfile"
    />

    <!-- Recieve dialog and toast events -->
    <global-events />

    <!-- application header -->
    <v-toolbar color="primary" dark clipped fixed app class="elevation-0">
      <v-toolbar-side-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <v-icon
          v-if="isNestedRoute"
          @click="goRoute($route.meta.headerRouteName)"
          >chevron_left</v-icon
        >
        <a
          @click="goRoute($route.meta.headerRouteName)"
          class="white--text clickable"
          >{{ $route.meta.headerLabel }}</a
        >
      </v-toolbar-title>
      <v-spacer />
      <apps-menu />

      <v-menu bottom left>
        <v-btn fab dark small slot="activator" class="primary">
          <v-icon>person</v-icon>
        </v-btn>
        <v-list two-line>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon x-large>account_box</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ localProfile.email }}</v-list-tile-title>
              <v-list-tile-sub-title>
                <a class="accent--text" @click="logout">Log Out</a>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- The Route View -->
    <v-content>
      <transition name="slide-fade" mode="out-in"> <router-view /> </transition>
    </v-content>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GlobalEvents from '@/components/GlobalEvents';
import MainNavDrawer from './AdminNavDrawer';
import AppsMenu from '@/components/AppsMenu';
import routes from '@/routes/admin';

export default {
  name: 'AdminMain',
  components: {
    GlobalEvents,
    MainNavDrawer,
    AppsMenu
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        window.document.title = `Admin - ${to.meta.title}` || 'Admin';
      }
    }
  },
  data() {
    return {
      error: null,
      loading: true,
      drawer: null,
      routes
    };
  },
  created() {},
  computed: {
    localProfile() {
      return JSON.parse(sessionStorage.getItem('profile'));
    },
    isNestedRoute() {
      return !!this.$route.meta.headerRouteName;
    },
    adminRoutes() {
      // check for admin scopes
      const scopes = this.localProfile.permissions || [];

      // Filter out routes for which user does not have scope/permission
      const navRoutes = this.routes.filter(route => {
        // if there is not a scope defined return it
        if (!route.meta || (route.meta && !route.meta.scope)) {
          return route;
        }

        // If route is protected by a scope, only include it if user has matching scope
        if (scopes.includes(route.meta.scope)) {
          return route;
        }
      });

      return navRoutes;
    }
  },
  methods: {
    ...mapActions(['doLogout']),
    goRoute(name) {
      return this.$router.push({ name });
    },
    logout() {
      this.doLogout();
    }
  }
};
</script>

<style scoped lang="scss">
// Transitions
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.15s;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
.clickable:hover {
  text-decoration: underline !important;
}
</style>
