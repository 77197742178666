<template>
  <v-btn
    flat
    color="blue"
    class="import-units-button"
    @click.native="clickImportUnits"
  >
    <v-icon class="mr-1">file_upload</v-icon>Import Data
  </v-btn>
</template>

<script>
export default {
  name: 'ImportUnitsButton',
  methods: {
    clickImportUnits() {
      this.$router.push({ name: 'meetingImportUnits' });
    }
  }
};
</script>

<style scoped></style>
