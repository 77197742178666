<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Security Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Settings about meeting security
    </div>

    <div class="subheading">
      Enable Account Users to view/download the e-proxy PDF and Live Ballots
      Export report:
    </div>

    <v-radio-group
      name="security-setting-type"
      v-model="enableProxyDetails"
      @change="onChange('options.enableProxyDetails', $event)"
    >
      <v-radio value="after">
        <template v-slot:label>
          <div>
            <h5 class="subheading">
              After the submission deadline
            </h5>
          </div>
        </template>
      </v-radio>
      <v-radio value="always">
        <template v-slot:label>
          <div>
            <h5 class="subheading">
              Always
            </h5>
          </div>
        </template>
      </v-radio>
      <v-radio value="never">
        <template v-slot:label>
          <div>
            <h5 class="subheading">Never</h5>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingSettingsSecurityInput',
  components: {},
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      enableProxyDetails: 'after'
    };
  },
  methods: {
    initState() {
      this.enableProxyDetails = _.get(
        this.options,
        'enableProxyDetails',
        'after'
      );
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style></style>
