<template>
  <v-container v-if="lastDataFetched" grid-list-md fluid>
    <v-layout row class="ma-3">
      <v-flex xs12>
        <p class="title">Edit: {{ currentNoticeTemplate.name }}</p>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ml-3 mr-3">
        <v-card>
          <v-card-text>
            <input-info
              :name="state.name"
              :subject="state.subject"
              @update="handleUpdate"
            />
            <v-radio-group class="ma-0 pa-0" v-model="state.category">
              <div slot="label">
                Template Category
              </div>
              <v-radio label="Notice" value="notice" />
              <v-radio label="Conversation" value="internal" />
            </v-radio-group>

            <v-select
              v-if="isNotice"
              :items="combinedSegments"
              v-model="state.segment"
              label="Recipient segment to target"
              item-text="label"
              item-value="value"
            >
              <template v-slot:item="{ item }">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-chip
                      small
                      label
                      class="mr-2"
                      v-if="item.value.startsWith('mc-')"
                    >
                      multi-consent </v-chip
                    ><span>{{ item.label }}</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>
            </v-select>

            <v-checkbox
              v-if="isNotice"
              label="Set a reminder for attaching files"
              hint="Enable this option to show a warning message if the notice has no attachments"
              persistent-hint
              v-model="state.meta.attachmentReminder"
              @change="
                handleUpdate({ key: 'meta.attachmentReminder', value: $event })
              "
            />

            <v-checkbox
              v-if="isNotice"
              label="Set as Urgent: Include unsubscribed and bounced recipients (bypass list supressions)"
              hint="(For Important Emails) Enable this to ignore a voter's communication preferences and Sendgrid's suppression list"
              persistent-hint
              v-model="state.meta.urgent"
              @change="handleUpdate({ key: 'meta.urgent', value: $event })"
            />

            <v-select
              v-if="isNotice"
              v-model="state.meta.replyTo"
              @input="handleUpdate({ key: 'meta.replyTo', value: $event })"
              :items="replyToItems"
              item-value="value"
              item-text="text"
              label="Set Reply To field"
            />

            <v-combobox
              :items="templateTypeItems"
              :value="state.meta.templateType"
              clearable
              label="Set template type"
              @input="handleUpdate({ key: 'meta.templateType', value: $event })"
            />

            <v-text-field
              label="Default Cc email"
              :value="state.meta.defaultCcEmail"
              :rules="rules.email"
              @input="
                handleUpdate({ key: 'meta.defaultCcEmail', value: $event })
              "
            />

            <v-text-field
              label="Default bcc email"
              :value="state.meta.defaultBccEmail"
              :rules="rules.email"
              @input="
                handleUpdate({ key: 'meta.defaultBccEmail', value: $event })
              "
            />

            <div class="row" v-if="!isNotice">
              <span class="right grey--text">
                <v-icon size="18px" class="pl-2">warning</v-icon>
                Owner merge tags and buttons won't be replaced.
              </span>
            </div>
            <rich-text-editor
              :body="state.body"
              @update="handleUpdate({ key: 'body', value: $event })"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn flat class="accent--text" @click="deleteSampleNotice">
              <v-icon>delete_forever</v-icon>Delete Template
            </v-btn>
            <v-spacer />
            <v-btn
              class="accent"
              @click="updateSampleNotice"
              :disabled="!isChanged || !isInvalidEmails"
            >
              <v-icon left>save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import validator from 'email-validator';

import { mapActions, mapGetters } from 'vuex';
import InputInfo from '@/components/input/InputNoticeInfo';
import segmentTypes from '@/lib/segment-types';
import segmentTypesMulti from '@/lib/segment-types-multi';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

export default {
  name: 'AdminNoticeTemplateView',
  components: {
    InputInfo,
    RichTextEditor
  },
  props: {
    noticeTemplatesList: {
      type: Object,
      required: true
    },
    noticeTemplatesData: {
      type: Array,
      required: true
    },
    lastDataFetched: {
      type: Date,
      default: () => null
    },
    templateTypeItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      noticeTemplateKey: this.$route.params.noticeTemplateKey,
      segmentTypes,
      segmentTypesMulti,
      state: {
        name: '',
        subject: '',
        body: '',
        segment: '',
        description: '',
        category: '',
        meta: {
          replyTo: false,
          urgent: false,
          defaultCcEmail: '',
          defaultBccEmail: ''
        }
      },
      replyToItems: [
        { text: 'Default (ex: notices+tscc1234@getquorum.com)', value: false },
        { text: 'Use PM contact email', value: true }
      ],
      noticeCategory: this.isNotice,
      rules: {
        email: [v => !v || validator.validate(v) || 'Invalid email address']
      }
    };
  },
  computed: {
    ...mapGetters('noticeTemplates', ['noticeTemplateByKey']),
    currentNoticeTemplate() {
      return this.noticeTemplateByKey(this.noticeTemplateKey) || {};
    },
    isChanged() {
      return !_.isEqual(this.state, this.currentNoticeTemplate);
    },
    isInvalidEmails() {
      // eslint-disable-next-line no-useless-escape
      if (
        this.state.meta.defaultCcEmail &&
        !validator.validate(this.state.meta.defaultCcEmail)
      ) {
        return false;
      }
      if (
        this.state.meta.defaultBccEmail &&
        !validator.validate(this.state.meta.defaultBccEmail)
      ) {
        return false;
      }
      return true;
    },
    isNotice() {
      return !this.state || this.state.category === 'notice';
    },
    // Combine both segments without duplicates
    combinedSegments() {
      return _.uniqBy(
        [...this.segmentTypes, ...this.segmentTypesMulti],
        'value'
      );
    }
  },
  watch: {
    currentNoticeTemplate: function() {
      this.setState();
    }
  },
  created() {
    this.setState();
  },
  methods: {
    ...mapActions('noticeTemplates', [
      'deleteNoticeTemplate',
      'updateNoticeTemplate'
    ]),
    handleUpdate(obj) {
      const newState = { ...this.state };
      // Lodash search state object for matching key
      _.set(newState, obj.key, obj.value);
      this.$set(this, 'state', newState);
    },
    async deleteSampleNotice() {
      const isConfirm = confirm('Delete this Template?');
      if (!isConfirm) {
        return;
      }

      try {
        await this.deleteNoticeTemplate(this.noticeTemplateKey);
        this.$events.$emit('toastEvent', 'Template Deleted');
        this.$router.push({ name: 'adminNoticeTemplates' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateSampleNotice() {
      try {
        // Set segment 'all' for conversation templates by default
        if (!this.isNotice) {
          this.state.segment = 'all';
        }
        const payload = this.makeNoticePayload(this.state);

        await this.updateNoticeTemplate({
          key: this.noticeTemplateKey,
          payload: payload
        });
        this.$events.$emit('toastEvent', 'Template Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    makeNoticePayload(state) {
      let payload = {
        body: state.body,
        key: state.key,
        meta: state.meta,
        name: state.name,
        segment: state.segment,
        subject: state.subject,
        category: state.category,
        description: state.description ? state.description : state.name
      };
      return payload;
    },
    setState() {
      this.state = _.cloneDeep(this.currentNoticeTemplate);
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  align-items: center;
}
.right {
  margin-left: auto;
}
.v-select__selections .v-list__tile__title,
.v-list__tile__title {
  height: auto !important;
}
</style>
