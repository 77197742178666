<template>
  <v-layout>
    <v-flex xs12>
      <admin-user-card
        :user-key="userKey"
        :current-user="currentUser"
        @send-reset-email="sendResetEmail"
        @send-welcome-email="sendWelcome"
        @delete-user="deleteUser"
        @suspend-user="setSuspendUser"
        @reactivate-user="setReactivateUser"
        @pause-mfa="pauseMfa"
        @enable-mfa="enableMfa"
      />
    </v-flex>

    <!-- deprecated -->
    <!-- <admin-user-set-password-dialog
      :is-open="showPasswordDialog"
      :current-user="currentUser"
      :user-key="userKey"
      @update-password="updatePassword"
      @close-dialog="showPasswordDialog = false"
    /> -->
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

import AdminUserCard from '@/components/UserCard';
// import AdminUserSetPasswordDialog from '@/components/dialogs/SetPasswordDialog';

export default {
  name: 'AdminUserViewUserInfo',
  components: {
    AdminUserCard
    // AdminUserSetPasswordDialog
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    dataLastFetchedAt: {
      type: Date,
      default: null
    },
    userKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPasswordDialog: false
    };
  },
  methods: {
    ...mapActions('users', [
      'removeUser',
      'sendPasswordResetEmail',
      'sendWelcomeEmail',
      // 'updateUser',
      'suspendUser',
      'reactivateUser',
      'enableMultifactor',
      'pauseMultifactor'
    ]),
    async deleteUser() {
      const isConfirm = confirm(
        `Delete ${this.currentUser.email}?\n\nOnly the user login will be deleted, all meeting and corporation data will remain.`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.removeUser(this.userKey);
        this.$events.$emit('toastEvent', 'User Deleted');
        this.showDeleteUserDialog = false;
        this.$router.push({ name: 'adminUsers' });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendResetEmail() {
      const isConfirm = confirm(
        `Send a password reset email to ${this.currentUser.email}?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.sendPasswordResetEmail(this.userKey);
        this.$events.$emit('toastEvent', 'Password Reset Email Sent');
        this.showResetEmailDialog = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async sendWelcome() {
      const isConfirm = confirm(
        `Resend the welcome email to ${this.currentUser.email}?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.sendWelcomeEmail({ userKey: this.userKey });
        this.$events.$emit('toastEvent', 'Welcome email re-sent');
        this.showResetEmailDialog = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // deprecated
    // async updatePassword(password) {
    //   try {
    //     const user = { password };
    //     await this.updateUser({ userKey: this.userKey, user });
    //     this.$events.$emit('toastEvent', 'Password Changed');
    //     this.showPasswordDialog = false;
    //   } catch (err) {
    //     this.$events.$emit('showErrorDialog', err.response);
    //   }
    // },
    async setSuspendUser() {
      const isConfirm = confirm(
        `Suspend ${this.currentUser.email}?\n\nThis will revoke all current sessions and prevent this account from logging in. Suspended users can be reactivated at a later time.`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.suspendUser({ userKey: this.userKey });
        this.$events.$emit('toastEvent', 'User Suspended');
        this.showSuspendUserDialog = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async setReactivateUser() {
      const isConfirm = confirm(
        `Reactivate ${this.currentUser.email} and allow them to login?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.reactivateUser({ userKey: this.userKey });
        this.$events.$emit('toastEvent', 'User Reactivated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async enableMfa() {
      const isConfirm = confirm(
        `Turn on Two Factor Auth for ${this.currentUser.email}?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.enableMultifactor({ userKey: this.userKey });
        this.$events.$emit('toastEvent', 'User MFA Activated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async pauseMfa() {
      const isConfirm = confirm(
        `Turn off Two Factor Auth for ${this.currentUser.email} for 24 hours?`
      );
      if (!isConfirm) {
        return;
      }
      try {
        await this.pauseMultifactor({ userKey: this.userKey });
        this.$events.$emit('toastEvent', 'User MFA Paused for 24 hours');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>
