<template>
  <v-dialog max-width="650px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title">Mark Units as Ineligible?</span>
      </v-card-title>
      <v-card-text>
        <p>
          To mark <strong>{{ selected.length }}</strong> unit(s) as
          <strong>ineligible</strong>, select a reason from the options below
          and click <strong>CONFIRM</strong>:
        </p>

        <v-layout justify-center wrap>
          <v-btn
            color="primary"
            :outline="reason !== 'arrears'"
            @click="selectReason('arrears')"
            >Arrears</v-btn
          >
          <v-btn
            color="primary"
            :outline="reason !== 'paper-proxy'"
            @click="selectReason('paper-proxy')"
            >Paper Proxy</v-btn
          >
          <v-btn
            v-if="isAdmin"
            color="primary"
            :outline="reason !== 'in-person'"
            @click="selectReason('in-person')"
            >In-Person</v-btn
          >
          <v-btn
            v-if="isAdmin"
            color="primary"
            :outline="reason !== 'custom'"
            @click="selectReason('custom')"
            >Custom Reason</v-btn
          >
        </v-layout>

        <v-layout v-if="reason === 'custom'" justify-center>
          <v-flex xs6>
            <v-text-field
              class="mt-2"
              label="Custom Reason"
              v-model="customReason"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn :disabled="isDisabled" color="primary" @click.native="emitUpdate"
          >confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingUnitsBulkIneligibleDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    isDisabled() {
      return (
        this.reason === '' ||
        (this.reason === 'custom' && this.customReason === '')
      );
    },
    updatedReason() {
      return this.reason === 'custom' ? this.customReason : this.reason;
    }
  },
  data() {
    return {
      reason: '',
      customReason: ''
    };
  },
  methods: {
    selectReason(reason) {
      this.reason = reason;
    },
    emitClose() {
      this.reason = '';
      this.customReason = '';
      this.$emit('close');
    },
    emitUpdate() {
      this.$emit('update', this.updatedReason);
      this.emitClose();
    }
  }
};
</script>

<style scoped></style>
