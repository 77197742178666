'use strict';

/**
 * @fileoverview Vuex module for meeting requests
 */
import Vue from 'vue';
import _ from 'lodash';
import {
  getRequests,
  getRequest,
  updateRequest,
  assignRequest,
  updateRequestStatus,
  updateRequestShortcode
} from '@/api/meetings-requests';
import { getChecklistAssignees } from '@/api/checklists';

const state = {
  requests: [],
  assignees: []
};

const getters = {
  requestsList: state => Object.values(state.requests),
  requestByKey: state => requestKey => state.requests[requestKey],
  assigneesList: state => state.assignees
};

const actions = {
  /**
   * Get requests
   * @param {String} {status} Meeting status
   */
  async getMeetingRequests({ commit }, { status }) {
    try {
      const { data } = await getRequests({ status });
      commit('SET_MEETING_REQUEST_LIST', data);
    } catch (err) {
      throw err;
    }
  },
  /**
   * Get meeting request
   * @param {String} {requestKey}      Request key
   */
  async getMeetingRequest({ commit }, { requestKey }) {
    try {
      const { data } = await getRequest({ requestKey });
      commit('SET_MEETING_REQUEST_IN_LIST', data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update meeting request
   * @param {String} {request}      Request payload
   */
  async updateMeetingRequest({ dispatch }, { request }) {
    try {
      await updateRequest({ request });

      dispatch('getMeetingRequest', { requestKey: request.key });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get list of csm
   *
   */
  async getAssignees({ commit }) {
    try {
      const res = await getChecklistAssignees();

      // Filter all assignees down to just CS and with the request read scope
      const assignees = res.data.filter(a => a.data.team === 'cs');

      commit('SET_ASSIGNEES', assignees);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Assign a csm user to meeting request
   * @param {String} {request}      Request payload
   */
  async assignRequest({ dispatch }, { request }) {
    try {
      await assignRequest({ request });

      dispatch('getMeetingRequest', { requestKey: request.key });
    } catch (err) {
      throw err;
    }
  },

  /**
   * update meeting request status
   * @param {String} {requestKey}   request key
   * @param {String} {status}       new request status
   * @param {String} {comment}      request comment
   */
  async updateMeetingRequestStatus(
    { dispatch },
    { requestKey, status, comment }
  ) {
    try {
      await updateRequestStatus({ requestKey, status, comment });

      dispatch('getMeetingRequest', { requestKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * update meeting request shortcode
   * @param {String} {requestKey}   request key
   * @param {String} {shortcode}    meeting shortcode
   */
  async updateMeetingRequestShortcode({ dispatch }, { requestKey, shortcode }) {
    try {
      await updateRequestShortcode({ requestKey, shortcode });

      dispatch('getMeetingRequest', { requestKey });
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_REQUEST_LIST(state, data) {
    state.requests = _.keyBy(data, 'key');
  },
  // Set one request to store
  SET_MEETING_REQUEST_IN_LIST(state, data) {
    Vue.set(state.requests, data.key, data);
  },
  SET_ASSIGNEES(state, assignees) {
    state.assignees = assignees;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
