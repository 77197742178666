<template>
  <v-dialog max-width="650px" :value="value" persistent @input="emitInput">
    <v-card>
      <v-card-title> <span class="title">Import Members</span> </v-card-title>
      <v-flex xs12 px-3>
        <v-radio-group row v-model="importMethod">
          <v-radio label="From my computer" value="local"></v-radio>
          <v-radio label="From another meeting" value="campaign"></v-radio>
        </v-radio-group>

        <div v-if="importMethod === 'local'">
          <div>
            <span class="body-1"> The import file must be: <br /> </span>
            - a formatted <code>csv</code> or <code>xlsx</code> <br />
            - <b>one name</b> and <b>one email</b> per row <br />
            -
            <a
              href="https://d1v53h6w11vaa4.cloudfront.net/downloads/vbm_import_sample.csv"
              download
              >Download sample file here</a
            >
          </div>

          <v-card class="pa-3 mb-3">
            <file-upload-form
              :attachment="importFile"
              accept=".xlsx, .csv"
              @upload="handleUpload"
            />
          </v-card>
        </div>

        <div v-else>
          <p class="body-1">
            Import membership data from other
            <span v-if="currentAccount">{{ currentAccount.nameShort }}</span>
            meetings
          </p>
          <v-select
            :items="importCampaignItems"
            item-text="text"
            item-value="value"
            box
            :disabled="success"
            label="Import data from meeting"
            v-model="importCampaign"
            @change="submitted = false"
          ></v-select>
        </div>
      </v-flex>

      <v-flex xs12 px-3>
        <div v-if="submitted">
          <user-task-events-tracker
            :user-task-key="userTask.key"
            :pusher-props="userTask._pusher"
            @update="updateTaskStatus"
            class="d-none"
          />

          <div v-if="importStatus === 'completed'">
            <v-alert outline type="success" :value="true"
              >Import successful</v-alert
            >
          </div>

          <div v-else-if="importStatus === 'error'">
            <v-alert outline type="warning" :value="true">
              Import failed. For assistance please contact
              <a href="mailto:support@getquorum.com">support@getquorum.com</a>
            </v-alert>
          </div>
        </div>
      </v-flex>
      <v-card-actions>
        <v-btn flat @click.stop="emitClose">Go back</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!canSubmit"
          :loading="this.processing"
          @click.native="importMembers"
          >Import Members</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';

import { postImportUnits } from '@/api/meetings-units';
import { getExportImportReport } from '@/api/meetings-reports';

import FileUploadForm from '@/components/FileUploadForm';
import UserTaskEventsTracker from '@/components/UserTaskEventsTracker';

export default {
  name: 'MeetingVbmImportMembersDialog',
  components: {
    FileUploadForm,
    UserTaskEventsTracker
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    shortCode: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default() {
        return 'boardMember';
      }
    },
    currentAccount: {
      type: Object,
      default() {
        return {
          // Default return empty meetings array
          meetings: []
        };
      }
    }
  },
  data() {
    return {
      success: false,
      submitted: false,
      importMethod: 'local',
      importFile: {},
      processing: false,
      generateControlNumber: false,
      importOptions: {
        isReplaceExisting: false,
        ignoreCustom: false,
        addCovers: false
      },
      userTask: {},
      importCampaign: null,
      importStatus: ''
    };
  },
  // If conditional required as the importCampaign will be set and won't run indefinitely
  // Note: Do not mutate state in updated lifecycle without conditional
  updated() {
    // Check if import campaign selected or currentMeeting is the only meeting
    if (this.importCampaign || this.currentAccount.meetings.length === 1) {
      return;
    }

    // Will default select the most recent VBM by createdAt
    this.selectMostRecentMeeting();
  },
  computed: {
    ...mapGetters('accounts', ['accountByKey']),
    isFileUploaded() {
      if (_.isEmpty(this.importFile)) {
        return false;
      }
      return true;
    },
    canSubmit() {
      if (this.success) {
        return false;
      }

      // Can submit when using local mode and file is uploaded
      if (this.importMethod === 'local' && this.isFileUploaded) {
        return true;
      }

      // Can submit if using campaign import and a campaign is selected
      if (this.importMethod === 'campaign' && this.importCampaign) {
        return true;
      }

      // Otherwise cannot upload
      return false;
    },
    // Populate drop down list when importing from another meeting
    importCampaignItems() {
      // Check if no meetings
      if (!this.currentAccount.meetings) {
        return [
          {
            text: 'No campaigns available for this account',
            value: null
          }
        ];
      }

      // Format the list item
      const items = this.currentAccount.meetings
        // Exclude this current meeting and non-vbms
        .filter(m => m.shortCode !== this.shortCode && m.options.type === 'vbm')
        // Build up the item text and label
        .map(m => {
          const mtgDate = moment(m.meetingDate)
            .tz(m.meetingTimezone)
            .format('YYYY-MM-DD');
          return {
            text: `${mtgDate} --- ${m.name} `,
            value: m.shortCode
          };
        });

      return items;
    }
  },
  methods: {
    ...mapActions('meetings/units', ['getMeetingUnits']),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    async importMembers() {
      try {
        this.processing = true;
        this.importOptions.controlNumberDigits = this.generateControlNumber
          ? this.controlNumberDigits
          : 0;

        let file;
        // Use uploaded file Bucket and Key info if local
        if (this.importMethod === 'local') {
          file = this.importFile;
        } else {
          // Trigger an import/export file generation and get the Key and Bucket
          // to it.
          const exImpRes = await getExportImportReport(this.importCampaign);
          file = exImpRes.data;
        }

        // Perform the import
        const res = await postImportUnits(
          this.shortCode,
          file,
          null,
          this.importOptions
        );

        // Get the user task that was created so we can subscribe to progress
        this.userTask = res.data;
        this.submitted = true;
      } catch (err) {
        this.processing = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    // Output the import task events
    updateTaskStatus({ status }) {
      this.userTask.status = status;
      if (status === 'completed' || status === 'error') {
        this.processing = false;
        this.importStatus = status;
        this.getMeetingUnits({ shortCode: this.shortCode });
        this.getMeetingVoters({ shortCode: this.shortCode });
      }

      if (status === 'completed') {
        this.success = true;
      }
    },
    // Filters and sorts to find the most recent meeting and select it
    selectMostRecentMeeting() {
      // 1. Filter out currentMeeting and non-vbm
      // 2. Sort the meetings
      const meetings = this.currentAccount.meetings
        .filter(m => m.shortCode !== this.shortCode && m.options.type === 'vbm')
        .sort(
          (a, b) =>
            moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
        );

      // First element is the most recent meeting
      if (meetings.length !== 0) {
        this.importCampaign = meetings[0].shortCode;
      }
    },
    // File uploads
    handleUpload(res) {
      // If the object is empty, it's a deletion
      if (_.isEmpty(res)) {
        this.submitted = false;
        this.importStatus = '';
      }

      this.importFile = res;
    },
    // Emit events
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.success = false;
      this.submitted = false;
      this.importStatus = '';
      this.userTask = {};
      this.importFile = {};
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
