'use strict';

/**
 * @fileoverview Vuex module for a meeting's webcasts
 */

import _ from 'lodash';

import { meetingsConversations } from '@/api';
import { makeNewNoticeObj } from '@/lib/make-notice-object';

const state = {
  conversations: {},
  dataLastFetchedAt: null,
  conversationFields: {},
  preview: {
    body: ''
  }
};

const getters = {
  conversations: state => {
    const data = Object.values(state.conversations)
      .map(conv => {
        let statusColor = 'grey';
        let datedAt = Infinity;

        if (conv.sentAt) {
          statusColor = 'green';
          datedAt = conv.sentAt;
        } else if (conv.scheduledAt && conv.status === 'scheduled') {
          statusColor = 'orange';
          datedAt = conv.scheduledAt;
        }

        return {
          ...conv,
          statusColor,
          datedAt
        };
      })
      .sort((a, b) => {
        if (a.datedAt < b.datedAt) {
          return -1;
        }
        if (a.datedAt > b.datedAt) {
          return 1;
        }
        return 0;
      });
    return data;
  },
  conversationsList: state => state.conversations,
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  conversationMergeFields: state => state.conversationFields,
  conversationPreview: state => state.preview.body
};

const actions = {
  /**
   * Get list of all conversations for a specific meeting key
   *
   * @param  {String} shortCode - The meeting key
   * @return {Void}
   */
  async fetchConversations({ commit }, shortCode) {
    try {
      const res = await meetingsConversations.listConversations(shortCode);
      commit('SET_CONVERSATIONS', res.data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get a conversation for this meeting
   * @param  {String} {shortCode} the meeting who's units we're looking at
   * @param  {String} {conversationId} the conversation ID
   * @return {Void}
   */
  async fetchConversation({ commit }, { shortCode, conversationId }) {
    try {
      const res = await meetingsConversations.getConversation(
        shortCode,
        conversationId
      );
      commit('SET_CURRENT_CONVERSATION', res.data);
    } catch (err) {
      console.error('ERROR: fetchMeetingConversation action', err);
      throw err;
    }
  },

  /**
   * Get a conversation merge tags field
   * @return {Void}
   */
  async getConversationFields({ commit }) {
    try {
      const res = await meetingsConversations.getConversationFields();
      commit('SET_CONVERSATION_MERGE_FIELDS', res.data);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a new conversation to the current meeting
   *
   * @param  {String} shortCode - The meeting key
   * @param  {Object} {payload} - Payload of the new conversation
   * @return {Void}
   */
  async addConversation({ dispatch }, { shortCode, payload }) {
    try {
      const res = await meetingsConversations.postMeetingConversation(
        shortCode,
        payload
      );
      dispatch('fetchConversations', shortCode);

      return res;
    } catch (err) {
      console.error('ERROR: createMeetingConversation action', err);
      throw err;
    }
  },

  /**
   * Updates the conversation
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {conversationKey}  the conversation key to update
   * @param {Object} {conversation}     update conversation payload
   */
  async updateMeetingConversation(
    { dispatch },
    { shortCode, conversationKey, payload }
  ) {
    try {
      const res = await meetingsConversations.putConversation(
        shortCode,
        conversationKey,
        payload
      );
      dispatch('fetchConversations', shortCode);
      return res;
    } catch (err) {
      console.error('ERROR: updateMeetingConversation action', err);
      throw err;
    }
  },

  /**
   * Deletes the conversation
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {conversationKey}  the notice key to update
   */
  async deleteMeetingConversation(
    { dispatch },
    { shortCode, conversationKey }
  ) {
    try {
      await meetingsConversations.deleteConversation(
        shortCode,
        conversationKey
      );
      dispatch('fetchConversations', shortCode);
    } catch (err) {
      console.error('ERROR: deleteMeetingsConversation action', err);
      throw err;
    }
  },

  /**
   * Create and schedule the list of notices provided with the assigned send dates
   *
   * @param {Object} {scheduleParams}  object containing various parameters required for scheduling group notices

   */
  async scheduleMeetingConversationList({ dispatch }, scheduleParams) {
    try {
      let notices = scheduleParams.conversations;
      const meeting = scheduleParams.meeting;
      const contactEmail =
        meeting.options.replyToEmail ||
        `notices+${meeting.shortCode}@getquorum.com` ||
        meeting.contact.email;

      const noticesCreated = _.map(notices, function(notice) {
        notice.recipients = { segment: notice.segment };
        return makeNewNoticeObj(meeting.shortCode, notice, contactEmail);
      });

      const res = await meetingsConversations.scheduleConversationList({
        conversations: noticesCreated,
        status: scheduleParams.status,
        meeting: meeting,
        recipients: scheduleParams.recipients,
        fromName: scheduleParams.fromName,
        fromEmail: scheduleParams.fromEmail
      });

      dispatch('fetchConversations', meeting.shortCode);
      return res;
    } catch (err) {
      console.error('ERROR: scheduleMeetingConversationList action', err);
      throw err;
    }
  },

  /**
   * Gets the html preview on the conversation
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param  {Object} {conversationKey} - the conversation key
   */
  async getMeetingConversationPreview(
    { commit },
    { shortCode, conversationKey }
  ) {
    try {
      const res = await meetingsConversations.getConversationPreview(
        shortCode,
        conversationKey
      );
      commit('SET_MEETING_CONVERSATION_PREVIEW', res.data);
    } catch (err) {
      console.error(
        'ERROR: getMeetingConversationPreview action',
        err,
        shortCode
      );
      throw err;
    }
  }
};

const mutations = {
  SET_CONVERSATIONS(state, data) {
    state.conversations = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  CLEAR_STATE(state) {
    state.conversations = {};
    state.dataLastFetchedAt = null;
  },

  SET_CONVERSATION_MERGE_FIELDS(state, data) {
    state.conversationFields = data;
  },

  SET_MEETING_CONVERSATION_PREVIEW(state, preview) {
    state.preview.body = preview;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
