import http from './requestType';

/**
 * Get meeting requests list
 *
 * @param  {String} status      request status
 * @return {Promise}            Promise
 */
export function getRequests({ status }) {
  return http._get(`Meetings/requests`, {
    status
  });
}

/**
 * Get a meeting request
 *
 * @param  {String} requestKey  request key
 * @return {Promise}            Promise
 */
export function getRequest({ requestKey }) {
  return http._get(`Meetings/requests/${requestKey}`);
}

/**
 * Update a meeting request
 *
 * @param  {String} requestKey  request key
 * @param  {Object} request  new request object
 * @return {Promise}            Promise
 */
export function updateRequest({ request }) {
  return http._put(`Meetings/requests/${request.key}`, request);
}

/**
 * Assign CSM
 *
 * @param  {String} requestKey  request key
 * @return {Promise}            Promise
 */
export function assignRequest({ request }) {
  return http._put(`Meetings/requests/${request.key}/assign`, request);
}

/**
 * Update a meeting request
 *
 * @param  {String} requestKey  request key
 * @param  {String} status      status
 * @param  {String} comment     request comment
 * @return {Promise}            Promise
 */
export function updateRequestStatus({ requestKey, status, comment }) {
  return http._put(`Meetings/requests/${requestKey}/request-status`, {
    status,
    comment
  });
}

/**
 * Update a meeting request shortcode
 *
 * @param  {String} requestKey  request key
 * @param  {String} shortcode   meeting shortcode
 * @return {Promise}            Promise
 */
export function updateRequestShortcode({ requestKey, shortcode }) {
  return http._put(`Meetings/requests/${requestKey}/shortcode`, {
    shortcode
  });
}
