<template>
  <div class="panel-title">
    <h3 class="mr-3 d-flex items-center">
      <span class="mr-3">{{ title }}</span>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">{{ icon }}</v-icon>
        </template>
        <span>{{ description }}</span>
      </v-tooltip>
    </h3>
    <v-btn
      v-if="editAction && isAdmin && isOpen"
      flat
      color="blue"
      class="mr-3 my-0"
      dark
      small
      @click.native.stop="handleUpdate"
      ><v-icon small class="mr-1">edit</v-icon>edit</v-btn
    >
    <v-btn
      v-if="addAction && isAdmin && isOpen"
      flat
      color="blue"
      class="mr-3 my-0"
      dark
      small
      @click.native.stop="handleAdd"
    >
      <v-icon>add</v-icon>Add
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PanelTitle',
  props: {
    title: {
      type: String,
      default: 'NA'
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'help_outline'
    },
    addAction: {
      type: Boolean,
      default: false
    },
    editAction: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    },
    handleUpdate() {
      this.$emit('update');
    }
  }
};
</script>

<style scoped>
.panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
