'use strict';

/**
 * @fileoverview Vuex module for agents
 */

import _ from 'lodash';
import Vue from 'vue';

// API Calls
import * as agentApi from '@/api/agents';

const state = {
  agentList: [],
  agentByKeys: {},
  agentUsersList: [],
  pagination: {
    descending: true,
    sortBy: 'createdAt',
    page: 1,
    rowsPerPage: 25,
    totalItems: 0,
    rowsPerPageItems: [25, 50, 100, 200]
  }
};

const getters = {
  agentList: state => state.agentList,
  partnerList: state =>
    state.agentList.filter(agent => agent.partnerType === 'partner'),
  agentByKey: state => key => state.agentByKeys[key],
  agentUsers: state => key => state.agentUsersList[key],
  pagination: state => state.pagination
};

const actions = {
  /**
   * Get the latest Agents from API
   *
   */
  async getAgentListData({ commit }, search = '') {
    try {
      const res = await agentApi.getAgents({
        page: state.pagination.page,
        size: state.pagination.rowsPerPage,
        search,
        descending: state.pagination.descending
      });
      commit('SET_AGENT_LIST_DATA', {
        agents: res.data.agents,
        total: res.data.total || 0
      });
    } catch (err) {
      console.error('ERROR: getagentListData action', err);
      throw err;
    }
  },

  /**
   * Get the agent associated by key from API
   *
   * @param {key} key - The agent key
   */
  async getAgent({ commit, dispatch }, key) {
    try {
      const res = await agentApi.getAgent(key);
      dispatch('getAgentUsers', key);
      dispatch('getAgentAccounts', key);
      commit('SET_AGENT_IN_LIST', { key, agent: res.data });
    } catch (err) {
      console.error('ERROR: getAgent action', err);
      throw err;
    }
  },

  /**
   * Get the list of users associated with this agent
   * store.
   *
   * @param  {String} key  - the agent key
   */
  async getAgentUsers({ commit }, key) {
    try {
      const res = await agentApi.getAgentUsers(key);
      commit('SET_AGENT_USERS_LIST', { key, users: res.data });
    } catch (err) {
      console.error('ERROR: getAccountUsers action', key, err);
      throw err;
    }
  },

  /**
   * Get the list of accounts associated with this agent
   * store.
   *
   * @param  {String} key  - the agent key
   */
  async getAgentAccounts({ commit }, key) {
    try {
      const res = await agentApi.getAgentAccounts(key);
      commit('SET_AGENT_ACCOUNTS_LIST', { key, accounts: res.data });
    } catch (err) {
      console.error('ERROR: getAccountUsers action', key, err);
      throw err;
    }
  },

  /**
   * Creates a new agent with the given data
   *
   * @param {Object} agentPayload - New agent data to create
   * @returns
   */
  async createAgent({ dispatch }, agentPayload) {
    try {
      await agentApi.createAgent(agentPayload);
      // Whenever we successfully create an agent, hot reload the data
      dispatch('getAgentListData');
    } catch (err) {
      console.error('ERROR: createAgent action', err);
      throw err;
    }
  },

  /**
   * Adds/Creates user(s) to agent associated with agentKey
   *
   * @param {Object} agentUserPayload
   * @returns
   */
  async createAgentUser({ dispatch }, agentUserPayload) {
    try {
      const { agentKey } = agentUserPayload;

      await agentApi.createAgentUser(agentUserPayload);
      dispatch('getAgentUsers', agentKey);
    } catch (err) {
      console.error('ERROR addAgentUser action', err);
      throw err;
    }
  },

  /**
   * Removes a user from agent associated with agentKey
   *
   * @param {string} agentUserKey
   * @returns
   */
  async removeAgentUser({ dispatch }, agentUserPayload) {
    try {
      const { agentKey, agentUserKey } = agentUserPayload;
      await agentApi.deleteAgentUser(agentUserKey);
      dispatch('getAgentUsers', agentKey);
    } catch (err) {
      console.error('ERROR removeAgentUser action', err);
      throw err;
    }
  },

  /**
   * Updates an agent associated with agentKey
   *
   * @param {string} key     - The agent key
   * @param {Object} payload - The updated payload
   * @returns
   */
  async updateAgent({ dispatch }, { key, payload }) {
    try {
      await agentApi.putAgent(key, payload);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Return list of preferred managers for an agent
   *
   * @param  {String} agentKey    - agent key
   */
  async getAgentPreferredManagers({ commit, dispatch }, agentKey) {
    try {
      const res = await agentApi.getAgentPreferredManagers(agentKey);

      commit('SET_AGENT_PREFERRED_MANAGERS', {
        key: agentKey,
        managers: res.data
      });
    } catch (err) {
      console.error('ERROR: getAgentPreferredManagers', err);
      throw err;
    }
  },

  /**
   * Delete an agent's preferred manager
   *
   * @param  {String} agentKey    - agent key
   */
  async removeAgentPreferredManager({ dispatch }, { agentKey }) {
    try {
      await agentApi.deleteAgentPreferredManager({ agentKey });
      dispatch('getAgentPreferredManagers', agentKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a preferred manager to an agent
   *
   * @param  {String} agentKey    - agent key
   * @param  {String} userKey       - user key
   */
  async addAgentPreferredManager({ dispatch }, { agentKey, userKey }) {
    try {
      await agentApi.addAgentPreferredManager({
        agentKey,
        userKey
      });
      dispatch('getAgentPreferredManagers', agentKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update the preferred manager tied to an agent
   * (Delete old manager, add new manager)
   *
   * @param  {String} agentKey    - agent key
   * @param  {String} userKey       - user key
   */
  async updateAgentPreferredManager({ dispatch }, { agentKey, userKey }) {
    try {
      await agentApi.deleteAgentPreferredManager({ agentKey });
      await agentApi.addAgentPreferredManager({
        agentKey,
        userKey
      });
      dispatch('getAgentPreferredManagers', agentKey);
    } catch (err) {
      throw err;
    }
  },

  setPagination({ commit, dispatch }, pagination) {
    commit('SET_PAGINATION', pagination);
  }
};

const mutations = {
  SET_AGENT_LIST_DATA(state, { agents, total }) {
    state.agentList = agents;
    state.agentByKeys = _.keyBy(agents, 'key');
    state.pagination.totalItems = Number(total);
  },
  SET_AGENT_IN_LIST(state, { key, agent }) {
    Vue.set(state.agentByKeys, key, agent);
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  SET_AGENT_USERS_LIST(state, { key, users }) {
    Vue.set(state.agentByKeys[key], 'users', users);
  },
  SET_AGENT_ACCOUNTS_LIST(state, { key, accounts }) {
    Vue.set(state.agentByKeys[key], 'accounts', accounts);
  },
  SET_AGENT_PREFERRED_MANAGERS(state, { key, managers }) {
    Vue.set(state.agentByKeys[key], 'preferredManagers', managers);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
