<template>
  <v-dialog max-width="500px" persistent :value="value" @input="emitInput">
    <v-card v-if="!formSubmitted">
      <v-card-title
        ><span class="title">Proxy submission confirmation</span></v-card-title
      >
      <v-card-text v-if="selectedVoterExists">
        <p>
          <span class="font-weight-bold"
            >This proxy is being submitted for:</span
          ><br />
          Unit {{ selectedVoter.unit }} - {{ selectedVoter.name }} ({{
            selectedVoter.email
          }})
        </p>

        <v-alert
          v-if="existingProxy"
          class="mb-4"
          :value="true"
          color="error"
          icon="error"
          outline
        >
          A proxy already exists for this unit, submitted by
          <strong
            >{{ existingProxySource.name }} ({{
              existingProxySource.email
            }})</strong
          >
          on
          <strong>{{
            existingProxy.createdAt | dateFormatReadable(true)
          }}</strong
          >.<br />This proxy will be revoked if you continue with this
          submission.
        </v-alert>

        <v-alert :value="true" color="orange darken-1" icon="warning" outline>
          <b>Warning:</b> Please verify that the proxy you are submitting is
          legitimate. It is up to you to maintain record of this proxy in the
          case of a challenge. GetQuorum takes no responsibility for the proxy
          that you submit.
        </v-alert>
        <!-- <p class="red--text">
          
        </p> -->
      </v-card-text>

      <v-card-actions>
        <v-btn flat @click.native="emitClose"
          ><v-icon>chevron_left</v-icon> Back</v-btn
        >
        <v-spacer />
        <v-btn
          class="primary"
          :disabled="!isFormValid"
          :loading="loadingSubmit"
          @click.native="emitSubmit"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-text class="text-xs-center">
        <p class="title text-xs-center">
          Proxy submitted successfully <v-icon color="success">check</v-icon>
        </p>

        <span v-if="!hideSubmitAnother">
          <p>
            To add another proxy, click the button below
          </p>
          <v-btn class="primary text-xs-center" @click="emitReset"
            >Submit another</v-btn
          >
        </span>

        <p class="mt-3">
          <span v-if="!hideSubmitAnother">
            To return to the proxy page
          </span>
          <span v-else>
            To return to the previous page, click the button below
          </span>
        </p>

        <v-btn @click="emitBack">Go back</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'MeetingProxiesCreateSubmitDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selectedVoter: {
      type: Object,
      default() {
        return {};
      }
    },
    proxyHolder: {
      type: String,
      default: ''
    },
    existingProxy: {
      default() {
        return null;
      }
    },
    formSubmitted: {
      type: Boolean,
      default() {
        return false;
      }
    },
    loadingSubmit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideSubmitAnother: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  filters: {
    dateFormatReadable
  },
  computed: {
    selectedVoterExists() {
      return !_.isEmpty(this.selectedVoter);
    },
    isFormValid() {
      // Form can be submitted if owner is selected and proxyholder selected
      return this.selectedVoter && this.proxyHolder ? true : false;
    },
    // Use the metaData source name and email when proxy is created via dashboard
    existingProxySource() {
      if (_.has(this.existingProxy, 'metaData.source')) {
        return {
          name: this.existingProxy.metaData.source.name,
          email: this.existingProxy.metaData.source.email
        };
      } else {
        return {
          name: this.existingProxy.name,
          email: this.existingProxy.email
        };
      }
    }
  },
  methods: {
    emitSubmit() {
      this.$emit('submit');
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitReset() {
      this.$emit('reset');
    },
    emitBack() {
      this.$emit('back');
    }
  }
};
</script>

<style scoped></style>
