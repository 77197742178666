'use strict';

import moment from 'moment-timezone';

const ST_JOHNS_TZ = 'America/St_Johns';
const HALIFAX_TZ = 'America/Halifax';
const TORONTO_TZ = 'America/Toronto';
const WINNIPEG_TZ = 'America/Winnipeg';
const EDMONTON_TZ = 'America/Edmonton';
const VANCOUVER_TZ = 'America/Vancouver';
const LONDON_TZ = 'Europe/London';
export default [
  {
    text: `${ST_JOHNS_TZ} (${moment.tz(ST_JOHNS_TZ).format('z')})`,
    value: ST_JOHNS_TZ
  },
  {
    text: `${HALIFAX_TZ} (${moment.tz(HALIFAX_TZ).format('z')})`,
    value: HALIFAX_TZ
  },
  {
    text: `${TORONTO_TZ} (${moment.tz(TORONTO_TZ).format('z')})`,
    value: TORONTO_TZ
  },
  {
    text: `${WINNIPEG_TZ} (${moment.tz(WINNIPEG_TZ).format('z')})`,
    value: WINNIPEG_TZ
  },
  {
    text: `${EDMONTON_TZ} (${moment.tz(EDMONTON_TZ).format('z')})`,
    value: EDMONTON_TZ
  },
  {
    text: `${VANCOUVER_TZ} (${moment.tz(VANCOUVER_TZ).format('z')})`,
    value: VANCOUVER_TZ
  },
  {
    text: `${LONDON_TZ} (${moment.tz(LONDON_TZ).format('z')})`,
    value: LONDON_TZ
  }
];
