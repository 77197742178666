<template>
  <v-container fluid>
    <v-toolbar dense color="elevation-1">
      <v-btn color="primary" flat @click.native="dialog.addSingle = true">
        Add Checklist (Single)
      </v-btn>
      <v-btn color="primary" flat @click.native="dialog.create = true">
        Add Checklist (Custom)
      </v-btn>

      <v-spacer />
      <v-btn
        color="primary"
        @click.native="dialog.assign = true"
        :disabled="selected.length <= 0"
        >Assign</v-btn
      >
      <v-btn
        color="primary"
        @click.native="dialog.add = true"
        :loading="loading"
      >
        + Add Checklists
      </v-btn>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="meetingChecklistData"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="config.pagination"
      select-all
      :loading="loading"
      class="elevation-1"
    >
      <template slot="progress">
        <v-progress-linear
          height="2"
          color="blue"
          indeterminate
        ></v-progress-linear>
      </template>
      <template slot="items" slot-scope="props">
        <tr :key="props.index">
          <td>
            <v-checkbox v-model="props.selected" primary hide-details />
          </td>
          <td>{{ props.item.stage }}</td>
          <td @click="goToChecklist(props.item)" class="pointer">
            <checklist-chip :checklist="props.item" />
            <v-btn icon class="ml-0 pl-0"
              ><v-icon color="grey">exit_to_app</v-icon></v-btn
            >
          </td>
          <td class="print-col">
            <span v-if="hasPrintingItems(props.item)">
              <span v-if="isPrintItemsComplete(props.item)">
                <v-tooltip left>
                  <span slot="activator">
                    <v-layout>
                      <v-icon>print</v-icon>
                      <v-icon small color="green">check_circle</v-icon>
                    </v-layout>
                  </span>
                  <span>All print items completed</span>
                </v-tooltip>
              </span>
            </span>
            <span v-else>
              <span>
                <v-tooltip left>
                  <span slot="activator">
                    <v-icon color="red">print_disabled</v-icon>
                  </span>
                  <span>No printing required</span>
                </v-tooltip>
              </span>
            </span>
          </td>
          <td>{{ props.item.numCompleted }} / {{ props.item.numItems }}</td>
          <td>
            <span
              >{{ formatDate(props.item.dueAt, meetingTimezone) }}
              <v-tooltip
                v-if="
                  !isChecklistComplete(props.item) &&
                    showDateWarning(props.item.dueAt)
                "
                right
              >
                <v-icon slot="activator" color="accent">warning</v-icon>
                <span
                  >This date either: falls on a weekend, falls on a holiday, or
                  has passed. Please adjust.</span
                >
              </v-tooltip></span
            ><br />
            <span v-if="props.item.legalDeadline" class="caption fade"
              >Legal Deadline: {{ getLegalDeadline(props.item) }}</span
            >
          </td>
          <td>
            <v-tooltip bottom>
              <span slot="activator">
                {{ props.item.assignees | getAssigneeInitials }}
              </span>
              <span>{{ props.item.assignees | getAssigneeNames }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-right">
            <v-icon @click="editItem(props.item)" color="grey">
              edit
            </v-icon>
            <v-icon @click="deleteItem(props.item)" color="grey" class="ml-2">
              delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <meeting-checklist-dialog-assign
      v-model="dialog.assign"
      :assignees="checklistAssignees"
      @save="handleAssign"
      @close="dialog.assign = false"
    />

    <meeting-checklist-dialog-add-custom
      :value="dialog.create"
      :meeting-date="currentMeeting.meetingDate"
      :meeting-timezone="meetingTimezone"
      :stage="newChecklist.stage"
      :name="newChecklist.name"
      :due-at="newChecklist.dueAt"
      :meta="newChecklist.meta"
      :stages="checklistStages"
      @update="handleUpdate"
      @add="handleAdd"
      @close="dialog.create = false"
    />

    <meeting-checklist-dialog-add-single
      :value="dialog.addSingle"
      :meeting-date="currentMeeting.meetingDate"
      :meeting-timezone="meetingTimezone"
      :corp-name-short="currentMeeting.corporation.legalNameShort"
      :name="newChecklist.name"
      :due-at="newChecklist.dueAt"
      :checklist-groups="checklistTemplateGroupsListData"
      @update="handleUpdate"
      @add="handleAdd"
      @close="dialog.addSingle = false"
    />

    <meeting-checklist-dialog-add-and-assign
      :is-open="dialog.add"
      :stages="checklistStages"
      :meeting-date="currentMeeting.meetingDate"
      :meeting-timezone="meetingTimezone"
      :meeting-options="currentMeeting.options"
      :corp-name-short="currentMeeting.corporation.legalNameShort"
      :short-code="currentMeeting.shortCode"
      :existing-checklists="meetingChecklistData"
      :assignees="checklistAssignees"
      :preferred-csm="preferredCsm"
      :current-meeting="currentMeeting"
      :checklist-groups="checklistTemplateGroupsListData"
      :loading="loading"
      @save="bulkAddAndAssign"
      @close="dialog.add = false"
    />

    <meeting-checklist-dialog-edit
      v-model="dialog.edit"
      :event-type="currentMeeting.options.type"
      :meeting-date="currentMeeting.meetingDate"
      :meeting-timezone="meetingTimezone"
      :name="editChecklist.name"
      :due-at="editChecklist.dueAt"
      :short-code="editChecklist.shortCode"
      :stage="editChecklist.stage"
      :meta="editChecklist.meta"
      :assignees="checklistAssignees"
      @update="handleUpdate"
      @save="handleSave"
      @close="dialog.edit = false"
    />
  </v-container>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import { getRecipe } from '@/api/recipes';
import {
  isWeekend,
  isHoliday,
  isExpired,
  getAssigneeInitials,
  getAssigneeNames,
  getDefaultDate,
  payloadDateFormat
} from '@/lib/checklist-helpers';

import ChecklistChip from '@/components/ChecklistChip';
import MeetingChecklistDialogAddAndAssign from './MeetingChecklistDialogAddAndAssign';
import MeetingChecklistDialogAddSingle from './MeetingChecklistDialogAddSingle';
import MeetingChecklistDialogAddCustom from './MeetingChecklistDialogAddCustom';
import MeetingChecklistDialogAssign from './MeetingChecklistDialogAssign';
import MeetingChecklistDialogEdit from './MeetingChecklistDialogEdit';

export default {
  name: 'MeetingChecklistView',
  components: {
    ChecklistChip,
    MeetingChecklistDialogAddAndAssign,
    MeetingChecklistDialogAddSingle,
    MeetingChecklistDialogAddCustom,
    MeetingChecklistDialogAssign,
    MeetingChecklistDialogEdit
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    launchAddDialog: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    getAssigneeInitials,
    getAssigneeNames
  },
  watch: {},
  data() {
    return {
      loading: false,
      shortCode: this.$route.params.shortcode,
      selected: [],
      headers: [
        { text: 'Stage', value: 'stage', align: 'left' },
        { text: 'Name', value: 'name', align: 'left' },
        { text: '', value: '', align: 'left', sortable: false },
        { text: 'Steps', value: 'name', align: 'left' },
        { text: 'Due At', value: 'dueAt', align: 'left' },
        { text: 'Assigned To', value: 'meta.assignees' },
        { text: '', value: '', align: 'right', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: { sortBy: 'dueAt' }
      },
      newChecklist: {
        stage: '',
        name: '',
        dueAt: null,
        items: [],
        meta: {}
      },
      editChecklist: {
        stage: '',
        name: '',
        dueAt: null,
        key: '',
        meta: {}
      },
      dialog: {
        add: false,
        addSingle: false,
        create: false,
        assign: false,
        edit: false
      }
    };
  },
  computed: {
    ...mapGetters('meetings/checklists', ['meetingChecklistData']),
    ...mapGetters('checklists', ['checklistStages', 'checklistAssignees']),
    ...mapGetters('checklistTemplateGroups', [
      'checklistTemplateGroupsListData'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    ...mapGetters('agents', ['agentByKey']),
    preferredCsm() {
      return this.getPreferredManager('csm');
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  created() {
    if (this.launchAddDialog) {
      this.dialog.add = true;
    }
    this.init();
  },
  methods: {
    ...mapActions('meetings', ['getMeeting']),
    ...mapActions('checklists', [
      'getChecklistStages',
      'getChecklistAssignees'
    ]),
    ...mapActions('meetings/checklists', [
      'updateChecklist',
      'createChecklist',
      'removeChecklist',
      'updateBulkAssignments',
      'bulkAddAndAssignChecklists',
      'getChecklists'
    ]),
    ...mapActions('checklistTemplateGroups', ['getChecklistTemplateGroups']),
    ...mapActions('agents', ['getAgentPreferredManagers', 'getAgent']),
    ...mapActions('accounts', ['getAccountAgents']),
    handleUpdate(obj) {
      _.set(this.$data[obj.prop], obj.key, obj.value);
    },
    async init() {
      this.getChecklists(this.currentMeeting.key);
      this.getChecklistStages();
      this.getChecklistAssignees();
      this.getChecklistTemplateGroups();
      await this.getAccountAgents(this.currentMeeting.accountKey);
      await this.getAgentPreferredManager();
    },
    getPreferredManager() {
      // use account's preferred manager if defined
      const acct = this.accountByKey(this.currentMeeting.accountKey);
      if (acct.preferredCsm) {
        return acct.preferredCsm;
      }
      // use meeting partner's preferred manager if defined
      let agent = null;
      if (this.currentMeeting.partner) {
        agent = this.agentByKey(this.currentMeeting.partner.key);
        if (agent) {
          if (!_.isEmpty(agent.preferredManagers)) {
            return agent.preferredManagers[0].user;
          }
        }
      }
      // use the preferred manager of the first agent assigned to this account if defined
      if (acct.agents && !_.isEmpty(acct.agents)) {
        agent = this.agentByKey(acct.agents.at(-1).key);
        if (agent) {
          if (!_.isEmpty(agent.preferredManagers)) {
            return agent.preferredManagers[0].user;
          }
        }
      }
      // return null if no preferred managers are defined
      return null;
    },
    async handleAdd(checklistItems = []) {
      try {
        let newChecklist;

        if (this.newChecklist.name) {
          this.newChecklist.name = `${this.currentMeeting.corporation.legalNameShort} - ${this.newChecklist.name}`;
        }

        if (checklistItems.length > 0) {
          // If we're adding a custom checklist, we have to
          // map the checklist items to the correct format
          let hasPrintItem = false;
          let items = checklistItems.map((item, index) => {
            const isPrintType = item.includes('[p]');
            if (isPrintType) {
              hasPrintItem = true;
            }
            return {
              label: item.replace('[p]', ''),
              printType: isPrintType
            };
          });

          // If the custom checklist is a pre-notice / service stage,
          // and no print items are included, add the `noMailing` meta property
          // to the new checklist
          if (
            (this.newChecklist.stage === 'pre-notice' ||
              this.newChecklist.stage === 'service') &&
            !hasPrintItem
          ) {
            this.newChecklist.meta.noMailing = true;
          }

          newChecklist = { ...this.newChecklist, items };
        } else {
          newChecklist = this.newChecklist;
        }

        const checklist = this.makeNewChecklistPayload(newChecklist);
        await this.createChecklist(checklist);
        this.init();

        // Clear inputs
        this.handleClear();
        this.$events.$emit('toastEvent', 'Checklist added');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },

    /**
     * Add checklists in bulk
     */
    async bulkAddAndAssign(checklists) {
      try {
        this.loading = true;
        await this.bulkAddAndAssignChecklists({
          checklistArray: checklists,
          meetingKey: this.currentMeeting.key
        });
        this.$events.$emit('toastEvent', 'Checklists added');
        this.dialog.add = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },

    async handleSave() {
      try {
        this.loading = true;
        const checklist = this.makeEditChecklistPayload(this.editChecklist);
        await this.updateChecklist(checklist);
        this.init();
        this.dialog.edit = false;
        this.$events.$emit('toastEvent', 'Saved');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    async handleAssign(assignees) {
      try {
        this.loading = true;
        await this.updateBulkAssignments({
          assignees,
          checklists: this.selected
        });
        this.init();
        this.dialog.assign = false;
        this.selected = [];
        this.$events.$emit('toastEvent', 'Assignments Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(item) {
      let isConfirm = confirm(
        'Are you sure you want to delete this checklist?'
      );

      if (isConfirm) {
        try {
          this.loading = true;
          await this.removeChecklist(item.key);
          this.init();
          this.$events.$emit('toastEvent', 'Checklist removed');
        } catch (err) {
          this.$events.$emit('showErrorDialog', err.response);
        } finally {
          this.loading = false;
        }
      }
    },
    handleClear() {
      this.newChecklist.stage = '';
      this.newChecklist.name = '';
      this.newChecklist.dueAt = null;
      this.newChecklist.items = [];
      this.newChecklist.meta = {};
    },
    editItem(item) {
      // init dialog contents
      this.editChecklist.key = item.key;
      this.editChecklist.name = item.name;
      this.editChecklist.dueAt = item.dueAt;
      this.editChecklist.stage = item.stage;
      this.editChecklist.meta = item.meta;

      this.dialog.edit = true;
    },

    /**
     * @TODO DEPRECATE THIS
     */
    makeNewChecklistPayload(item) {
      const payload = {
        name: item.name,
        shortCode: this.shortCode,
        dueAt: payloadDateFormat({
          date: item.dueAt,
          hour: 19,
          minute: 0
        }),
        stage: item.stage,
        items: item.items,
        meta: item.meta
      };
      return payload;
    },
    makeEditChecklistPayload(item) {
      const payload = {
        key: item.key,
        name: item.name,
        shortCode: this.shortCode,
        dueAt: payloadDateFormat({
          date: item.dueAt,
          hour: 19,
          minute: 0
        }),
        legalDeadline: item.legalDeadline,
        stage: item.stage,
        meta: item.meta
      };
      return payload;
    },
    goToChecklist(item) {
      let key = item.key;

      let baseurl =
        process.env.NODE_ENV === 'production'
          ? 'https://calendar.getquorum.com'
          : 'http://localhost:8082';

      let url = `${baseurl}/checklists/${key}`;

      window.open(url, '_blank');
    },
    hasPrintingItems(checklist) {
      let index = _.findIndex(checklist.items, function(item) {
        return item.printType;
      });

      if (index > -1) {
        return true;
      }
      return false;
    },
    isPrintItemsComplete(checklist) {
      let index = _.findIndex(checklist.items, function(item) {
        if (item.printType) {
          return !item.checked;
        }
      });

      // If unchecked print items are found, print items are not complete
      if (index > -1) {
        return false;
      }
      return true;
    },
    isChecklistComplete(checklist) {
      return (
        checklist.numItems > 0 && checklist.numCompleted === checklist.numItems
      );
    },
    showDateWarning(date) {
      return isWeekend(date) || isHoliday(date) || isExpired(date);
    },
    formatDate(date, meetingTimezone) {
      return moment(date)
        .tz(meetingTimezone)
        .format('ddd, MMM Do YYYY');
    },
    getLegalDeadline(checklist) {
      const date = payloadDateFormat({
        date: getDefaultDate(
          checklist.legalDeadline,
          this.currentMeeting.meetingDate,
          this.meetingTimezone ? this.meetingTimezone : 'America/Toronto'
        ),
        hour: 19,
        min: 0,
        timezone: this.meetingTimezone
          ? this.meetingTimezone
          : 'America/Toronto'
      });

      return this.formatDate(date, this.meetingTimezone);
    },
    async getAgentPreferredManager() {
      if (this.currentMeeting.partner) {
        await this.getAgent(this.currentMeeting.partner.key);
        await this.getAgentPreferredManagers(this.currentMeeting.partner.key);
      }
      const acct = this.accountByKey(this.currentMeeting.accountKey);
      if (!_.isEmpty(acct.agents)) {
        await this.getAgent(acct.agents.at(-1).key);
        await this.getAgentPreferredManagers(acct.agents.at(-1).key);
      }
    }
  }
};
</script>

<style scoped>
.print-col {
  width: 15px;
}
.pointer {
  cursor: pointer;
}
.fade {
  opacity: 0.74;
}
</style>
