<template>
  <meeting-overview-card
    icon="subject"
    title="Additional Discounts / Credits"
    :hide-save="true"
    :hide-edit="true"
    :saving="saving"
    @save="onSave"
  >
    <div v-if="hasDiscounts">
      <!-- Display custom discounts -->
      <v-layout row v-for="discount in discounts" :key="discount.key">
        <v-flex xs10>
          {{ discount.name || discount.description }}

          <v-tooltip bottom max-width="400px">
            <v-icon small slot="activator">info</v-icon>
            <div>
              Product #: {{ discount.productCode }}<br />
              Category: {{ discount.category }} <br />
              {{ discount.description }}
            </div>
          </v-tooltip>
        </v-flex>
        <v-flex class="v-flex center">
          <span class="nowrap"> ${{ discount.value | formatCurrency }} </span>
        </v-flex>
        <v-flex xs2 class="v-flex center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">more_vert</v-icon>
            </template>

            <v-list>
              <v-list-tile @click="handleEdit({ discount })">
                <v-list-tile-content>Edit</v-list-tile-content></v-list-tile
              >
              <v-list-tile @click="onDelete({ itemKey: discount.key })">
                <v-list-tile-content>Remove</v-list-tile-content></v-list-tile
              >
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <v-layout row>
        <v-flex xs10>No discounts or credits.</v-flex>
      </v-layout>
    </div>

    <v-btn @click="handleOpen" flat color="blue" class="mt-4 mx-0" dark small>
      <v-icon>add</v-icon>Add item
    </v-btn>

    <v-divider class="primary" />

    <v-layout row>
      <v-flex xs9 class="text-xs-right">Subtotal: </v-flex>
      <v-flex xs3 class="nowrap text-xs-right pr-4">
        ${{ billingSettings.subtotalItemDiscounts || 0 }}
      </v-flex>
    </v-layout>

    <add-meeting-billing-item-dialog
      title="Additional Discounts / Credits"
      :is-open="dialog.isOpen"
      :item="state"
      :item-selected="itemSelected"
      :products="products"
      :description-editable="true"
      :currency="billingSettings.billingCurrency"
      :isChargebee="false"
      @update="handleUpdate"
      @save="onSave"
      @close-dialog="handleClose"
    />
  </meeting-overview-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { formatCurrency } from '@/filters';
import MeetingOverviewCard from '@/components/MeetingOverviewCard';
import AddMeetingBillingItemDialog from './AddMeetingBillingItemDialog';

export default {
  name: 'DiscountsCard',
  components: {
    MeetingOverviewCard,
    AddMeetingBillingItemDialog
  },
  filters: {
    formatCurrency
  },
  data() {
    return {
      saving: false,
      dialog: { isOpen: false },
      state: { description: '', productCode: '', value: '' },
      itemSelected: null
    };
  },
  computed: {
    ...mapGetters('meetings/billing', ['billingSettings', 'discounts']),
    ...mapGetters('products', ['productsData']),
    products() {
      return _.map(this.productsData, product => {
        return {
          value: product.productCode,
          label: `${product.name} - ${product.productCode}`
        };
      });
    },
    hasDiscounts() {
      return this.discounts.length > 0;
    }
  },
  methods: {
    ...mapActions('products', ['getProducts']),
    ...mapActions('meetings/billing', [
      'addBillingItem',
      'deleteBillingItem',
      'updateBillingItem'
    ]),
    handleUpdate(obj) {
      // Lodash search state object for matching key
      _.set(this.state, obj.key, obj.value);
    },
    async handleOpen() {
      await this.getProducts({ category: 'discount' });
      this.dialog.isOpen = true;
    },
    handleClose() {
      this.dialog.isOpen = false;
      // reset state
      this.state = { description: '', productCode: '', value: '' };
      this.itemSelected = null;
    },
    async onSave() {
      try {
        const payload = {
          type: 'discount',
          description: this.state.description,
          category: 'discount',
          productCode: Number(this.state.productCode),
          value: Number(this.state.value)
        };

        if (this.itemSelected) {
          // if item selected call update item function
          await this.updateBillingItem({
            shortCode: this.$route.params.shortcode,
            itemKey: this.itemSelected,
            payload
          });
          this.$events.$emit('toastEvent', 'Item updated');
        } else {
          await this.addBillingItem({
            shortCode: this.$route.params.shortcode,
            payload
          });
          this.$events.$emit('toastEvent', 'Item added');
        }

        //reset state
        this.state = { description: '', productCode: '', value: '' };
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
        this.dialog.isOpen = false;
        this.itemSelected = null;
      } finally {
        this.dialog.isOpen = false;
        this.itemSelected = null;
      }
    },
    handleEdit({ discount }) {
      this.itemSelected = discount.key;
      this.state = {
        description: discount.description,
        productCode: discount.productCode,
        value: discount.value
      };
      this.dialog.isOpen = true;
    },
    async onDelete({ itemKey }) {
      try {
        this.saving = true;
        await this.deleteBillingItem({
          shortCode: this.$route.params.shortcode,
          itemKey
        });
      } catch (err) {
        console.error('Error', err);
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
