'use strict';

import _ from 'lodash';

/**
 * Determine if proxy question is eligible based on restrictions
 * Determine if question meets eligibility requirements for check-voter-subgroup middleware in the gq-api project
 *
 * @param  {Object} question the meeting
 * @param  {Object} currentUnitCustomData the current unit custom data
 * @return {String} boolean
 */
export function isQuestionEligible({ question, currentUnitCustomData }) {
  // If question has no restrictions, question is always eligible
  if (!question.restrictions || question.restrictions.length === 0) {
    return true;
  }

  // If the question has restrictions, check if customData of unit fulfills restriction requirement
  // Loop through every customData property to see if matching value exists, flag eligible to true;
  let eligible = false;

  const restrictions = {};

  // Convert array of object to an object
  question.restrictions.forEach(obj => {
    for (const key in obj) {
      const value = obj[key];
      restrictions[key] = value;
    }
  });

  for (const [key, value] of Object.entries(restrictions)) {
    if (key === 'owner_occupied') {
      eligible = true;
    } else if (
      currentUnitCustomData[key] &&
      currentUnitCustomData[key] === value
    ) {
      // Check if the key value pair exist in current unit custom data
      eligible = true;
    }
  }

  return eligible;
}
