<template>
  <v-data-table
    :headers="headers"
    :items="sortedItems"
    :pagination.sync="pagination"
    :item-key="`table-key-${question.id}`"
    hide-actions
    class="elevation-1"
  >
    <template slot="items" slot-scope="props">
      <!-- START: EXISTING - For options that include advance or live vote entries -->
      <tr
        v-if="props.item.answerId > 0"
        :key="props.item.answerId"
        :class="getRowClass(props.item)"
        class="hover-row"
      >
        <td v-if="showWinnerStatus" class="text-xs-left">
          <v-tooltip v-if="!props.item.isExcluded" right>
            <v-icon
              slot="activator"
              :disabled="props.item.isPreviousQuestionWinner"
              :class="
                props.item.isCurrentQuestionWinner ||
                props.item.isPreviousQuestionWinner
                  ? ''
                  : 'winner-icon'
              "
              @click="emitToggleWinner(props.item.answerId)"
              >emoji_events</v-icon
            >
            <span>WINNER STATUS / CLICK TO TOGGLE</span>
          </v-tooltip>
        </td>

        <td class="text-xs-left">
          {{ formatVoteLabel(props.item.vote) }}

          <!-- PAPER VOTE REMOVAL: Paper totals can be removed for candidates when no advance and live votes have been received -->
          <v-tooltip
            right
            v-if="
              props.item.hasCustomEntry &&
                !props.item.numVotesAdv &&
                !props.item.numVotes
            "
          >
            <v-icon
              slot="activator"
              small
              class="pl-6"
              color="red"
              @click="emitDeleteOption(question.question_key, props.item.vote)"
              >close</v-icon
            >
            <span>Paper vote summary<br />** Click to remove row **</span>
          </v-tooltip>
        </td>

        <!-- TOGGLE: Candidate eligibility -->
        <td class="text-xs-center">
          <v-tooltip
            bottom
            v-if="
              (props.item.isCandidate || props.item.isExcluded) &&
                !props.item.isCurrentQuestionWinner &&
                !props.item.isPreviousQuestionWinner
            "
          >
            <v-btn
              slot="activator"
              small
              outline
              depressed
              :color="getVoteOptionStatusColor(props.item.answerId)"
              class="pl-2 pr-2"
              @click="emitVoteExclusion(props.item.answerId)"
            >
              <v-icon small class="pr-2">{{
                props.item.isExcluded
                  ? 'thumb_down_off_alt'
                  : 'thumb_up_off_alt'
              }}</v-icon>
              {{ props.item.isExcluded ? 'INELIGIBLE' : 'ELIGIBLE' }}
            </v-btn>
            <span>
              {{ props.item.isExcluded ? 'INELIGIBLE' : 'ELIGIBLE' }}
              &nbsp;CANDIDATE<br />** Click to toggle status **</span
            >
          </v-tooltip>
        </td>

        <td class="text-xs-right">
          <div class="float-right">
            {{
              props.item.isExcluded || props.item.isPreviousQuestionWinner
                ? '-'
                : formatVotes(props.item.numVotesAdv)
            }}
          </div>
          <!-- INDICATOR: For write-in votes -->
          <v-tooltip left v-if="props.item.isWriteIn">
            <div slot="activator" class="float-right pr-4">
              <v-icon
                small
                color="#ffb347"
                @click="
                  emitViewWriteInVote(question.question_key, props.item.vote)
                "
                >warning</v-icon
              >
            </div>
            <span>Validate WRITE-IN votes<br />** Click icon to view **</span>
          </v-tooltip>
        </td>
        <td class="text-xs-right">
          {{
            props.item.isExcluded || props.item.isPreviousQuestionWinner
              ? '-'
              : formatVotes(props.item.numVotes)
          }}
        </td>
        <!-- Only admins have access to paper votes entries -->
        <td class="text-xs-right">
          <!-- OPTION EDIT VOTE TOTAL -->
          <div class="float-right">
            <v-text-field
              v-if="question.question_key"
              v-model="props.item.numVotesPaperEdit"
              single-line
              :rules="[rules.number]"
              :disabled="props.item.isPreviousQuestionWinner"
              @keyup.exact="
                changeDetection({
                  questionId: question.id,
                  answerId: props.item.answerId
                })
              "
            />
          </div>
          <v-tooltip left>
            <div slot="activator" class="float-right pt-4 pr-2">
              <!-- INDICATOR: Net new changes -->
              <v-icon
                v-if="hasChanged(question, props.item.answerId)"
                size="14px"
                class="pl-1"
                color="primary"
                >warning</v-icon
              >
            </div>
            <span>Unsaved change</span>
          </v-tooltip>
        </td>

        <td class="text-xs-right pr-0">
          <div
            v-if="
              isTie(props.item) &&
                !props.item.isCurrentQuestionWinner &&
                !props.item.isPreviousQuestionWinner
            "
            class="pl-3 pr-3 pb-1 pt-1 float-right tie-indicator"
          >
            <span>TIE</span>
          </div>
        </td>

        <td class="text-xs-right">
          {{
            props.item.isExcluded || props.item.isPreviousQuestionWinner
              ? '-'
              : voteTotal(props.item)
          }}
        </td>
      </tr>
      <!-- END: For options that include advance or live vote entries -->

      <!-- START: PAPER VOTE ENTRY -->
      <tr
        v-if="props.item.newOptionKey"
        class="new-option"
        :key="props.item.newOptionKey"
      >
        <td v-if="showWinnerStatus"></td>
        <td
          class="text-xs-left"
          :colspan="showWinnerStatus ? headers.length - 4 : headers.length - 3"
        >
          <v-text-field
            class="line-vote"
            v-model="props.item.vote"
            placeholder="Type vote option here"
            single-line
            clearable
            @keyup.exact="
              changeDetection({
                questionId: question.id,
                newOptionKey: props.item.newOptionKey
              })
            "
          ></v-text-field>
        </td>
        <td class="text-xs-right">
          <div align="right">
            <v-text-field
              v-model="props.item.numVotesPaperEdit"
              single-line
              :rules="[rules.number]"
              @keyup.exact="
                changeDetection({
                  questionId: question.id,
                  newOptionKey: props.item.newOptionKey
                })
              "
            />
          </div>
        </td>
        <td class="text-xs-right" :colspan="2">
          <v-icon
            @click="emitCancelOption(question.id, props.item.newOptionKey)"
            >cancel</v-icon
          >
        </td>
      </tr>
      <!-- END: PAPER VOTE ENTRY -->
    </template>
    <!-- Add Option Footer - available only for live questions with advance references -->
    <template v-if="question.question_key" v-slot:footer>
      <tr>
        <td v-if="showWinnerStatus"></td>
        <td class="pl-0">
          <v-btn flat color="blue" @click="emitAddOption(question.id)" dark>
            <v-icon>add</v-icon>Add Option
          </v-btn>
        </td>
        <td class="text-xs-right" :colspan="headers.length - 1">
          <v-btn
            flat
            color="blue"
            dark
            @click="emitSaveAllChanges(question.id)"
            :disabled="question.canSave ? false : true"
            ><v-icon small left>
              warning
            </v-icon>
            Save Changes
          </v-btn>

          <v-btn
            flat
            color="black"
            dark
            @click="emitCancelAllChanges(question.id)"
            :disabled="question.canSave ? false : true"
          >
            Cancel Changes
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'VoteTallyCombinedTableV2',
  components: {},
  props: {
    question: {
      type: Object,
      default() {
        return null;
      }
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default() {
        return '0';
      }
    },
    collectRtvBallots: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      state: {},
      pagination: {
        sortBy: ['isCurrentQuestionWinner'],
        descending: true,
        rowsPerPage: -1
      },
      rules: {
        number: value => {
          // Accept empty/undefined/null field (equivalent to zero)
          if (!value) {
            return true;
          } else {
            return !isNaN(value) || 'Invalid';
          }
        }
      }
    };
  },

  computed: {
    headers() {
      if (this.showWinnerStatus) {
        return [
          {
            text: 'Winner',
            value: 'winner',
            sortable: false,
            width: '2%'
          },
          {
            text: 'Vote',
            value: 'vote',
            width: '34%'
          },
          {
            text: '',
            sortable: false,
            width: '12%'
          },
          {
            text: this.collectRtvBallots ? 'Proxy' : 'Advance',
            value: 'numVotesAdv',
            align: 'right',
            width: '12%'
          },
          {
            text: this.collectRtvBallots ? 'Ballot' : 'Live',
            value: 'numVotes',
            align: 'right',
            width: '12%'
          },
          {
            text: 'Additional',
            value: 'numVotesPaper',
            align: 'right',
            width: '12%'
          },
          {
            text: '',
            sortable: false,
            width: '4%'
          },
          {
            text: 'Total',
            value: 'numVotesTotal',
            align: 'right',
            width: '12%'
          }
        ];
      }

      return [
        {
          text: 'Vote',
          value: 'vote',
          width: '36%'
        },
        {
          text: '',
          sortable: false,
          width: '12%'
        },
        {
          text: this.collectRtvBallots ? 'Proxy' : 'Advance',
          value: 'numVotesAdv',
          align: 'right',
          width: '12%'
        },
        {
          text: this.collectRtvBallots ? 'Ballot' : 'Live',
          value: 'numVotes',
          align: 'right',
          width: '12%'
        },
        {
          text: 'Additional',
          value: 'numVotesPaper',
          align: 'right',
          width: '12%'
        },
        {
          text: '',
          sortable: false,
          width: '4%'
        },
        {
          text: 'Total',
          value: 'numVotesTotal',
          align: 'right',
          width: '12%'
        }
      ];
    },

    arrayTotalVotes() {
      return this.question.tally.map(m => {
        if (!this.isNonCandidateOption(m.vote) && !m.isPreviousQuestionWinner) {
          return this.voteTotal(m);
        }
      });
    },
    sortedItems() {
      const tallies = this.question.tally;

      const currentWinners = [];
      const previousWinners = [];
      const excludedItems = [];
      const others = [];

      tallies.forEach(item => {
        if (item.isCurrentQuestionWinner) {
          currentWinners.push(item);
        } else if (item.isPreviousQuestionWinner) {
          previousWinners.push(item);
        } else if (item.isExcluded) {
          excludedItems.push(item);
        } else {
          others.push(item);
        }
      });

      currentWinners.sort((a, b) => b.numVotesTotal - a.numVotesTotal);
      others.sort((a, b) => b.numVotesTotal - a.numVotesTotal);

      return [
        ...currentWinners,
        ...others,
        ...excludedItems,
        ...previousWinners
      ];
    },
    showWinnerStatus() {
      return (
        this.question &&
        (this.question.type === 'general-elections' ||
          this.question.type === 'owner-occupied')
      );
    }
  },
  methods: {
    hasChanged(question, answerId) {
      // Skip check for custom live-vote questions that do not have advance questions
      if (question.question_key) {
        const option = _.find(question.tally, { answerId });
        const savedVoteValue = !option.numVotesPaper ? 0 : option.numVotesPaper;
        const editVoteValue = !option.numVotesPaperEdit
          ? 0
          : parseFloat(option.numVotesPaperEdit) || 0;

        // When the saved value and edited value has changed
        if (savedVoteValue !== editVoteValue) {
          return true;
        }
        return false;
      }
    },
    changeDetection(param) {
      const { questionId, answerId, newOptionKey } = param;
      let voteLineItem;
      if (answerId) {
        voteLineItem = _.find(this.question.tally, { answerId });
      } else if (newOptionKey) {
        voteLineItem = _.find(this.question.tally, { newOptionKey });
      }

      this.emitEnableSave(questionId, voteLineItem);
      this.$forceUpdate();
    },
    getVoteOptionStatusColor(answerId) {
      const voteLineItem = _.find(this.question.tally, { answerId });
      if (voteLineItem.isExcluded) {
        return '#FD5C63'; // Red - Ineligible
      } else if (!voteLineItem.isExcluded) {
        return '#74C365'; // Green - Eligible
      }
      return '#BFC1C2';
    },
    emitAddOption(questionId) {
      this.$emit('add-paper-option', questionId);
    },
    emitCancelOption(questionId, optionKey) {
      this.$emit('cancel-paper-option', { questionId, optionKey });
    },
    emitDeleteOption(questionKey, vote) {
      this.$emit('delete-paper-option', {
        questionKey,
        vote,
        liveQuestionKey: this.question.live_question_key
      });
    },
    emitSaveAllChanges(questionId) {
      this.$emit('save-paper-changes', questionId);
    },
    emitCancelAllChanges(questionId) {
      this.$emit('cancel-paper-changes', questionId);
    },
    emitEnableSave(questionId, lineItem) {
      const editVoteValue = !lineItem.numVotesPaperEdit
        ? 0
        : parseFloat(lineItem.numVotesPaperEdit) || 0;
      const savedVoteValue = !lineItem.numVotesPaper
        ? 0
        : lineItem.numVotesPaper;

      // When paper vote and total votes has been provided, enable the save button
      if (lineItem.vote && savedVoteValue !== editVoteValue) {
        this.$emit('enable-save-changes', questionId);
      }
    },
    emitViewWriteInVote(questionKey, vote) {
      this.$emit('view-write-in-vote', { questionKey, vote });
    },
    emitVoteExclusion(answerId) {
      const voteLineItem = _.find(this.question.tally, { answerId });
      const {
        vote: exclusion,
        isCustomEntry,
        numVotesAdv,
        isExcluded,
        isCandidate
      } = voteLineItem;

      // Toggle reserved for candidates or when a vote has already been excluded
      if (isCandidate || isExcluded) {
        this.$emit('toggle-exclude-vote', {
          questionKey: this.question.question_key,
          liveQuestionKey: this.question.live_question_key,
          exclusion
        });
        this.$forceUpdate();
      }
    },
    formatVotes(value) {
      return this.displayRoundedResults && value
        ? parseFloat(Number(value)).toFixed(
            Number(this.roundedResultsPrecision)
          )
        : value;
    },
    formatVoteLabel(vote) {
      const custom_meta = JSON.parse(this.question.custom_meta);
      let voteLower = vote.toLowerCase();
      if (!_.isEmpty(custom_meta) && custom_meta.options.toggles) {
        const {
          options: { toggles = {} }
        } = custom_meta;

        if (voteLower === 'i give my proxy authority to vote') {
          voteLower = 'defer';
        } else if (voteLower === 'i choose not to vote') {
          voteLower = 'abstain';
        }

        const customVoteLabel = _.find(
          toggles,
          (toggle, index) => index === voteLower && toggle.visible
        );

        if (customVoteLabel) {
          return `${customVoteLabel.label} (${vote})`;
        }
      }
      return vote;
    },
    countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split('.')[1].length || 0;
    },
    maxNumberOfDigits(num1, num2, num3) {
      const val1 = num1 ? this.countDecimals(num1) : 0;
      const val2 = num2 ? this.countDecimals(num2) : 0;
      const val3 = num3 ? this.countDecimals(num3) : 0;

      const maxDigits = Math.max(val1, val2, val3);
      return Number(`1e${maxDigits}`);
    },
    voteTotal(option) {
      if (option.isExcluded) {
        return '-';
      }

      const num1 = option.numVotes;
      const num2 = option.numVotesAdv;
      const num3 = parseFloat(option.numVotesPaperEdit);

      return this.formatVotes(
        !option.numVotesPaperEdit
          ? num1 + num2
          : Math.round(
              (num1 + num2 + num3) * this.maxNumberOfDigits(num1, num2, num3)
            ) / this.maxNumberOfDigits(num1, num2, num3)
      );
    },
    isNonCandidateOption(optionName) {
      const restrictions = [
        'Abstain',
        'I Abstain From Voting And My Proxy Shall Not Vote On This Matter',
        'Not Owner Occupied',
        'I Give My Proxy Authority To Vote',
        'I Choose Not To Vote'
      ];

      return restrictions.includes(optionName);
    },
    isTie(question) {
      if (question.isExcluded) {
        return false;
      }

      const vote = this.voteTotal(question);

      const amountOfNum = this.arrayTotalVotes.filter(votes => votes === vote);

      if (this.isNonCandidateOption(question.vote)) {
        return false;
      }

      if (amountOfNum.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    emitToggleWinner(answerId) {
      const voteLineItem = _.find(this.question.tally, { answerId });
      const { vote: winner, isCandidate } = voteLineItem;

      if (isCandidate) {
        this.$emit('toggle-winner', {
          questionKey: this.question.question_key,
          liveQuestionKey: this.question.live_question_key,
          winner
        });
        this.$forceUpdate();
      }
    },
    getRowClass(item) {
      if (item.isCurrentQuestionWinner) {
        return 'winner-row';
      } else if (item.isPreviousQuestionWinner) {
        return 'previous-winner-row';
      } else if (this.isTie(item)) {
        return 'tie-row';
      }
    }
  }
};
</script>
<style scoped>
.v-text-field {
  width: 40px;
}
.line-vote {
  width: 50%;
}
.new-option {
  background-color: rgba(217, 250, 255, 0.747);
}
.tie-indicator {
  color: rgba(236, 95, 89, 1);
  background-color: rgba(246, 204, 202, 1);
  letter-spacing: 2px;
  font-weight: bold;
  border-radius: 5px;
}

.tie-row {
  background-color: #fee9ea;
  color: rgba(236, 95, 89, 1);
}

.tie-row td {
  font-weight: bold;
  font-size: 14px;
}

.winner-row {
  background-color: #8ce4a5;
  color: rgb(0, 0, 0);
}

.winner-row td {
  font-weight: bold;
  font-size: 14px;
}

.previous-winner-row {
  background-color: #ecf3ee;
  color: rgb(0, 0, 0);
}

.previous-winner-row td {
  text-decoration: line-through;
}

.hover-row:hover .winner-icon {
  visibility: visible;
}

.winner-icon {
  visibility: hidden;
}
</style>
